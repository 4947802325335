/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {PieChart, Pie, Cell, Tooltip, ResponsiveContainer} from 'recharts';
import {TextVariant} from '../../atoms/TextVariant';
import {PillLabel} from '../../molecules/PillLabel';
import {THEME} from '../../../styles/theme';
import {useTranslation} from 'react-i18next';
import {CustomTurnOverToolTip} from './CustomTurnOverToolTip';
import {IStatChannel, IStatConsumption} from '../../../api/fetchStats';
import {helperFormatPrice} from '../../../helpers/helperFormatPrice';
import {
  M_SCREEN_LARGE_DESKTOP,
  M_SCREEN_TABLET,
} from '../../../constants/ResponsiveSize';
import {helperFormatNumber} from '../../../helpers/helperFormatNumber';

interface ISalesTurnOverPieChartProps {
  data: (IStatConsumption | IStatChannel)[];
  FilterComponent: React.FC<any>;
  width?: number | string;
  title: string;
  isLoading?: boolean;
}

export const SalesTurnOverPieChart = ({
  data,
  FilterComponent,
  width = '100%',
  title,
  isLoading,
}: ISalesTurnOverPieChartProps) => {
  //
  const {t} = useTranslation();

  return (
    <div css={styles.container(width)}>
      {!isLoading && (
        <>
          <div css={css({alignSelf: 'flex-end'})}>
            <FilterComponent withBorder marginRight={0} />
          </div>
          <div css={styles.titleContainer}>
            <TextVariant
              text={t(title)}
              variant="textMedium"
              style={styles.title}
            />
            <div className="flex" css={styles.pills}>
              {data?.map((item, index) => (
                <PillLabel
                  key={index}
                  text={item.name || ''}
                  backgroundColor={item.color}
                  marginRight={'1rem'}
                />
              ))}
            </div>
          </div>
        </>
      )}
      <div css={styles.pieChart} className="flex-center">
        {data && data.length > 0 ? (
          <ResponsiveContainer height={290} width="100%">
            <PieChart>
              <Tooltip
                content={props => <CustomTurnOverToolTip {...props} />}
              />
              <Pie
                data={data}
                innerRadius={55}
                outerRadius={95}
                paddingAngle={1}
                dataKey="orders.value">
                {data?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <div css={styles.noData} className="textSemiBold">
            Aucune donnée
          </div>
        )}
      </div>
      {!isLoading && (
        <>
          <TextVariant
            text={t('TURNOVER')}
            variant="textSemiBold"
            style={{
              position: 'relative',
              textAlign: 'center',
              top: '-1rem',
              textTransform: 'uppercase',
              color: THEME.colors.lightGray,
            }}
          />
          <div css={styles.footer} className="flex-space-between">
            {data?.slice(0, 3).map((item: any, index: number) => {
              return (
                <div key={index}>
                  <TextVariant
                    text={
                      helperFormatPrice(item?.value?.TTC?.total_price || 0) +
                      `${item?.suffix || '€'}`
                    }
                    variant="textSemiBold"
                    style={{
                      fontSize: '12.5px',
                    }}
                  />
                  <TextVariant
                    text={helperFormatNumber(item?.orders?.value || 0)}
                    variant="textSemiBold"
                    style={{
                      margin: '0.3rem 0',
                    }}
                  />
                  <PillLabel
                    text={item.name || ''}
                    backgroundColor={item.color}
                    size={10}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  container: (w: string | number) =>
    css({
      display: 'flex',
      flexDirection: 'column',
      width: w,
      backgroundColor: 'white',
      padding: '2vh',
      height: '100%',
      [M_SCREEN_LARGE_DESKTOP]: {
        minWidth: 282,
      },
      [M_SCREEN_TABLET]: {
        minHeight: 300,
      },
    }),
  titleContainer: css({
    marginTop: '1rem',
  }),
  title: css({
    textTransform: 'uppercase',
    marginBottom: '0.5rem',
  }),
  pieChart: css({
    width: '100%',
    height: '60%',
    minHeight: 80,
  }),
  pills: css({
    flexWrap: 'wrap',
    gap: '0.2rem',
  }),
  footer: css({
    // marginTop: '1rem',
  }),
  noData: css({
    color: '#A7A7AB',
  }),
};

import {ILabel} from '@bill-app-types/b-types';
import {axiosInstance} from './axiosInstance';

interface IParams {
  type?: number;
  languageId?: number;
  search?: string;
}

export interface ITranslationCommon {
  [key: string]: {
    translationUuid: string;
    content: ILabel[];
  };
}

export const fetchItemsTranslations = async (params: IParams) => {
  const res = await axiosInstance.get(
    'bcatalog/backoffice/translations/items',
    {
      params: params,
    },
  );

  return res?.data?.data;
};

export const fetchCategoriesTranslations = async (params: IParams) => {
  const res = await axiosInstance.get(
    'bcatalog/backoffice/translations/categories',
    {
      params: {
        type: params?.type,
        languageId: params?.languageId,
      },
    },
  );

  return res?.data?.data;
};

export const fetchModifiersTranslations = async (params: IParams) => {
  const res = await axiosInstance.get(
    'bcatalog/backoffice/translations/modifiers',
    {
      params,
    },
  );

  return res?.data?.data;
};

export const fetchSuggestionCategoriesTranslations = async (
  params: IParams,
) => {
  const res = await axiosInstance.get(
    'bcatalog/backoffice/translations/suggestion-categories',
    {
      params,
    },
  );

  return res?.data?.data;
};

export const fetchBkioskParameterTranslations = async () => {
  const res = await axiosInstance.get(
    'bcore/protected/backoffice/translations/b-order-parameters',
  );

  return res?.data?.data;
};

export const fetchBGlobalParameterTranslations = async () => {
  const res = await axiosInstance.get(
    'bcore/protected/backoffice/translations/b-global-parameters',
  );

  return res?.data?.data;
};

export const fetchBWebAppParameterTranslations = async () => {
  const res = await axiosInstance.get(
    'bcore/protected/backoffice/translations/b-webapp-parameters',
  );

  return res?.data?.data;
};

export const fetchAreasTranslations = async () => {
  const res = await axiosInstance.get(
    'bcore/protected/backoffice/translations/areas',
  );

  return res?.data?.data;
};

export const updateTranslation = async (
  translationUuid: string | undefined,
  data: {
    languageId: number;
    content: string;
  },
) => {
  const res = await axiosInstance.put(
    'bcatalog/backoffice/translations/' + translationUuid,
    data,
  );

  return res;
};

export const updateBcoreTranslation = async (
  translationUuid: string | undefined,
  data: {
    languageId: number;
    content: string;
  },
) => {
  const res = await axiosInstance.put(
    'bcore/protected/backoffice/translations/' + translationUuid,
    data,
  );

  return res;
};

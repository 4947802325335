import {MODIFIER_TYPES, PRODUCT_TYPES} from '@bill-app-types/b-types';
import i18n from '../services/i18n';

export const helperTranslateField = ({
  category,
  categoryType,
  field,
}: {
  category?: string;
  categoryType?: number;
  field?: string;
}) => {
  let labelField = '';
  let descriptionField = '';
  let compositionField = '';

  switch (category) {
    case 'Items':
      if (categoryType === PRODUCT_TYPES.COURSE) {
        labelField = i18n.t('PRODUCT_NAME');
        descriptionField = i18n.t('PRODUCT_DESCRIPTION');
        compositionField = i18n.t('PRODUCT_COMPOSITION');
      } else if (categoryType === PRODUCT_TYPES.MENU) {
        labelField = i18n.t('MENU_NAME');
        descriptionField = i18n.t('MENU_DESCRIPTION');
      } else {
        labelField = i18n.t('OPTION_NAME');
      }
      break;

    case 'Categories':
      labelField = i18n.t('CATEGORY_NAME');
      break;

    case 'Modifiers':
      if (categoryType === MODIFIER_TYPES.OPTION) {
        labelField = i18n.t('CATEGORY_OPTION_NAME');
      } else {
        labelField = i18n.t('SUBMENU_NAME');
      }
      break;

    case 'Suggestions':
      labelField = i18n.t('CATEGORY_SUGGESTION_NAME');
      break;

    case 'Areas':
      labelField = i18n.t('AREA_NAME');
      break;

    default:
      break;
  }

  switch (field) {
    case 'label':
      return labelField;
    case 'description':
      return descriptionField;
    case 'composition':
      return compositionField;
    case 'home_title':
      return i18n.t('TITLE');
    case 'home_desc':
      return i18n.t('DESCRIPTION');
    case 'slider_title':
      return i18n.t('SLIDER_TITLE');
    case 'slider_desc':
      return i18n.t('SLIDER_DESCRIPTION');
    case 'location_label':
      return i18n.t('LOCATION_LABEL');
    case 'out_of_stock_label':
      return i18n.t('OUT_OF_STOCK_LABEL');

    default:
      return field;
  }
};

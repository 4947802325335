export const BProdIcon = ({
  size,
  isDisabled = false,
  isActive = false,
}: {
  size: string;
  isDisabled?: boolean;
  isActive?: boolean;
}) => (
  <svg
    width={size === 'small' ? '38' : '58'}
    height={size === 'small' ? '15' : '24'}
    viewBox="0 0 58 24"
    fill="none">
    <path
      d="M7.65833 5.54769C6.78047 5.54769 5.94011 5.70429 5.16261 5.98772L6.92053 9.2797C7.15874 9.22786 7.40468 9.19919 7.65943 9.19919C9.5883 9.19919 11.151 10.7365 11.151 12.6345C11.151 14.5325 9.5883 16.0699 7.65943 16.0699C5.73057 16.0699 4.26049 14.6163 4.17557 12.7933L4.16785 12.8022V6.43658C4.16785 6.43658 4.16233 6.43989 4.15902 6.44099L4.16785 0.5H0.45459V19.7214H4.13917V18.8181C5.18136 19.3927 6.38014 19.7214 7.65833 19.7214C11.6374 19.7214 14.8621 16.5485 14.8621 12.6345C14.8621 8.72056 11.6374 5.54769 7.65833 5.54769Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#151A30' : '#3C6AF6'}
    />
    <path
      d="M22.0301 9.27063C22.7436 9.27063 23.4186 9.40628 24.0527 9.67648C24.6868 9.94778 25.2394 10.3183 25.7081 10.787C26.1768 11.2568 26.5473 11.8016 26.8186 12.4225C27.0899 13.0445 27.2256 13.7118 27.2256 14.4253C27.2256 15.1388 27.0899 15.8336 26.8186 16.4678C26.5473 17.1019 26.1779 17.6544 25.7081 18.1231C25.2383 18.5918 24.6868 18.9624 24.0527 19.2337C23.4175 19.505 22.7436 19.6406 22.0301 19.6406C21.0519 19.6406 20.2391 19.3781 19.5906 18.8543V18.8344L20.5821 17.0236L20.6019 17.0434C20.9592 17.2022 21.3221 17.2817 21.6926 17.2817C22.0632 17.2817 22.4392 17.2089 22.7833 17.0622C23.1263 16.9166 23.4274 16.7126 23.6855 16.4534C23.9435 16.1932 24.1442 15.891 24.2909 15.5447C24.4365 15.1984 24.5093 14.8256 24.5093 14.4264C24.5093 14.0272 24.4365 13.6776 24.2909 13.339C24.1453 12.9993 23.9435 12.7027 23.6855 12.4501C23.4274 12.1965 23.1263 11.9969 22.7833 11.8502C22.4392 11.7046 22.0753 11.6307 21.6926 11.6307C21.3099 11.6307 20.9295 11.7035 20.592 11.8491C20.2556 11.9946 19.9601 12.1943 19.7097 12.4457C19.4583 12.6972 19.2598 12.9916 19.1153 13.3302C18.9697 13.6677 18.8969 14.0283 18.8969 14.4121V23.3109H16.2192V9.42834H18.8969V10.7639C19.6105 9.76801 20.6549 9.26953 22.0301 9.26953V9.27063Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M36.3043 9.82536L36.0661 10.2224L35.0548 12.0266L35.0349 12.0068C34.5993 11.7553 34.1295 11.6307 33.6277 11.6307C33.2836 11.6307 32.9594 11.687 32.6561 11.7983C32.3517 11.9108 32.0804 12.0696 31.8433 12.2737C31.6051 12.4788 31.4033 12.7203 31.239 12.9982C31.0724 13.275 30.9643 13.5728 30.9114 13.8904V19.344H28.2139V9.42834H30.9114V10.7176C31.6117 9.75257 32.6362 9.26953 33.985 9.26953C34.778 9.26953 35.5444 9.45481 36.2855 9.82426H36.3054L36.3043 9.82536Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M40.75 9.27155C41.4647 9.27155 42.1319 9.40719 42.7528 9.67739C43.3748 9.94869 43.9163 10.3159 44.3784 10.778C44.8416 11.2412 45.2088 11.7827 45.479 12.4036C45.7492 13.0245 45.886 13.6862 45.886 14.3865C45.886 15.0868 45.7492 15.7485 45.479 16.3694C45.2077 16.9914 44.8405 17.5329 44.3784 17.995C43.9152 18.4582 43.3737 18.8254 42.7528 19.0956C42.1319 19.3658 41.4647 19.5015 40.75 19.5015C40.0354 19.5015 39.3693 19.3658 38.7484 19.0956C38.1264 18.8243 37.5849 18.4582 37.1228 17.995C36.6596 17.5318 36.2924 16.9903 36.0222 16.3694C35.7509 15.7485 35.6152 15.0868 35.6152 14.3865C35.6152 13.6862 35.7509 13.0245 36.0222 12.4036C36.2935 11.7827 36.6596 11.2401 37.1228 10.778C37.5849 10.3148 38.1275 9.94759 38.7484 9.67739C39.3693 9.40719 40.0365 9.27155 40.75 9.27155ZM40.75 17.1436C41.0809 17.1436 41.3875 17.0719 41.6731 16.9252C41.9577 16.7797 42.208 16.5779 42.4264 16.3209C42.6447 16.0628 42.8168 15.7651 42.9425 15.4276C43.0682 15.0912 43.1311 14.724 43.1311 14.327C43.1311 13.9564 43.0682 13.6024 42.9425 13.266C42.8168 12.9297 42.6447 12.6385 42.4264 12.3937C42.208 12.15 41.9577 11.9548 41.6731 11.8092C41.3886 11.6636 41.0809 11.5908 40.75 11.5908C40.4192 11.5908 40.1093 11.6636 39.8182 11.8092C39.527 11.9548 39.2722 12.15 39.055 12.3937C38.8366 12.6385 38.6646 12.9297 38.54 13.266C38.4142 13.6024 38.3514 13.9564 38.3514 14.327C38.3514 14.7229 38.4142 15.0901 38.54 15.4276C38.6657 15.7651 38.8377 16.0628 39.055 16.3209C39.2733 16.5779 39.527 16.7797 39.8182 16.9252C40.1082 17.0708 40.4192 17.1436 40.75 17.1436Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M57.5428 5.46564V19.346H54.886L54.9059 14.3688C54.9059 13.9983 54.8298 13.6487 54.6787 13.3178C54.5265 12.987 54.328 12.6992 54.0832 12.4543C53.8394 12.2106 53.5483 12.0187 53.2108 11.8797C52.8734 11.7408 52.5127 11.6713 52.13 11.6713C51.7474 11.6713 51.39 11.7408 51.0592 11.8797C50.7283 12.0187 50.4405 12.2139 50.1968 12.4654C49.9519 12.7168 49.7567 13.0079 49.6112 13.3377C49.4656 13.6685 49.3928 14.0248 49.3928 14.4086C49.3928 14.7923 49.4645 15.1486 49.6112 15.4794C49.7567 15.8103 49.9519 16.0981 50.1968 16.3418C50.4405 16.5867 50.7283 16.7819 51.0592 16.9274C51.39 17.073 51.7463 17.1458 52.13 17.1458C52.3153 17.1458 52.5006 17.1337 52.6859 17.1061C52.8711 17.0796 53.0432 17.0267 53.202 16.9473H53.2417L54.2133 18.7317L54.1934 18.7515C53.545 19.2533 52.752 19.5048 51.8135 19.5048C51.1 19.5048 50.4328 19.3724 49.8119 19.1088C49.1899 18.8442 48.6484 18.4813 48.1863 18.0181C47.7231 17.5549 47.3559 17.0135 47.0857 16.3926C46.8144 15.7717 46.6787 15.11 46.6787 14.4097C46.6787 13.7094 46.8144 13.0476 47.0857 12.4256C47.357 11.8047 47.7231 11.2633 48.1863 10.8001C48.6484 10.338 49.191 9.97404 49.8119 9.70936C50.4328 9.44468 51.1 9.31344 51.8135 9.31344C53.1623 9.31344 54.1934 9.81633 54.907 10.8199V5.46564H57.545H57.5428Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
  </svg>
);

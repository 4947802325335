/** @jsxImportSource @emotion/react */
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addExternalConnectionModalAction} from '../../redux/modals';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {helperDisplayToast} from '../../helpers/helperToast';
import {ChangeEvent, useEffect, useState} from 'react';
import {SelectList} from '../molecules/SelectList';
import {
  CUSTOM_ORDER_BY_FIELDS,
  EXTERNAL_PROVIDER,
  PRICE_RULE_TYPES,
  SORT_ENUM,
} from '@bill-app-types/b-types';
import {
  createExternalConnection,
  createExternalConnectionHasStructure,
  fetchExternalStructures,
  fetchStructureFromExternalConnection,
  updateExternalConnection,
  updateExternalConnectionHasStructure,
  updateExternalConnectionImage,
} from '../../api/fetchExternalConnections';
import {InputLabel} from '../atoms/InputLabel';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {
  externalConnectionSetSelectedAction,
  externalConnectionsUpdateMode,
} from '../../redux/myModulesSection/externalConnections';
import {Loader} from '../atoms/Loader';
import {getPriceRules} from '../../api/fetchPriceRules';
import {helperFormatPrice} from '../../helpers/helperFormatPrice';
import {UploadButton} from '../atoms/UploadButton';

export const AddExternalConnectionModal = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();

  const updateMode = useAppSelector(
    state => state.myModulesSection.externalConnections.updateMode,
  );

  // Fetch datas
  const updatedExternalConnection = useAppSelector(
    s => s.myModulesSection.externalConnections.selected,
  );

  const {data: dataExternalStructures, isLoading: isLoadingExternalStructures} =
    useQuery(['fetchExternalStructures', updatedExternalConnection], () =>
      fetchExternalStructures(),
    );

  const {data: dataSelectedStructure, isLoading: isLoadingSelectedStructure} =
    useQuery(
      ['fetchStructureFromExternalConnection', updatedExternalConnection],
      () =>
        fetchStructureFromExternalConnection(updatedExternalConnection?.uuid),
    );

  const {data: dataPriceRules, isLoading: isLoadingPriceRules} = useQuery(
    ['fetchPriceRulesPercent', updatedExternalConnection],
    () =>
      getPriceRules({
        type: PRICE_RULE_TYPES.PERCENT,
        orderBy: [{field: CUSTOM_ORDER_BY_FIELDS.AMOUNT, sort: SORT_ENUM.ASC}],
      }),
  );

  const EXTERNAL_PROVIDERS = Object.entries(EXTERNAL_PROVIDER)
    .slice(2)
    .map(([key, value]) => ({
      label: t(key),
      value: Number(value),
    }));

  const STRUCTURES =
    dataExternalStructures?.map(({label, uuid}) => ({
      label,
      value: uuid ?? '',
    })) || [];

  const PRICE_RULES = dataPriceRules
    ? dataPriceRules?.reduce(
        (priceRules: any, pr) => {
          return [
            ...priceRules,
            {
              label: `${
                Math.sign(pr.amount || 0) !== -1 ? '+' : ''
              }${helperFormatPrice(pr.amount)}% | ${pr.label}`,
              value: pr.uuid,
            },
          ];
        },
        [{label: t('NONE'), value: ''}],
      )
    : [];

  interface IImage {
    url: string;
    file: File;
  }

  const [form, setForm] = useState<any>({
    connection_label: '',
    external_id: '',
    external_provider_id: EXTERNAL_PROVIDERS[0]?.value.toString(),
    structureUuid: '',
    price_rule_uuid: '',
  });
  const [image, setImage] = useState<IImage | null>(null);

  useEffect(() => {
    if (
      !isLoadingExternalStructures &&
      STRUCTURES.length &&
      !form.structureUuid
    ) {
      setForm((f: any) => ({
        ...f,
        structureUuid: STRUCTURES[0].value.toString(),
      }));
    }
  }, [isLoadingExternalStructures]);

  useEffect(() => {
    if (updateMode) {
      if (updatedExternalConnection)
        setForm({
          connection_label: updatedExternalConnection.connection_label,
          external_id: updatedExternalConnection?.credentials.external_id,
          external_provider_id:
            updatedExternalConnection?.external_provider_id.toString(),
          structureUuid: dataSelectedStructure?.uuid || '',
          price_rule_uuid: updatedExternalConnection?.price_rule_uuid || '',
        });
    }
  }, [updatedExternalConnection, dataSelectedStructure]);

  const handleOnCancel = () => {
    if (updateMode) {
      dispatch(externalConnectionSetSelectedAction(null));
      dispatch(externalConnectionsUpdateMode(false));
    }
    dispatch(addExternalConnectionModalAction(false));
  };

  const handleChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const imageURL = e.target.result as string;

        const img = new Image();

        img.onload = () => {
          if (img.width < 1920 || img.height < 1080) {
            return helperDisplayToast(
              "L'image doit avoir une résolution de 1920x1080 minimum avec un ratio de 16/9",
              false,
            );
          }
          setImage({url: imageURL, file});
        };

        img.src = imageURL;
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUpdateImage = async (externalConnectionId: number) => {
    if (image) {
      const res = await updateExternalConnectionImage(
        externalConnectionId,
        image.file,
      );
      if (res?.status !== 204) {
        helperDisplayToast("Impossible de modifier l'image", false);
      }
    }
    clientQuery.invalidateQueries(['fetchExternalConnections']);
  };

  const deliveroHasNotPicture =
    form.external_provider_id === EXTERNAL_PROVIDER.DELIVEROO.toString() &&
    !image &&
    !updatedExternalConnection?.banner_image;

  const handleSubmit = async () => {
    if (
      !form.connection_label.trim() ||
      !form.external_id.trim() ||
      !form.external_provider_id.trim() ||
      !form.structureUuid.trim() ||
      deliveroHasNotPicture
    )
      return helperDisplayToast(t('FILL_ALL_FIELDS'));

    // Create mode
    if (!updateMode) {
      // Create external connection bCore
      const {status, data: newExternalConnection} =
        await createExternalConnection(form);
      if (status === 200) {
        helperDisplayToast(t('EXTERNAL_CONNEXION_CREATED'), true);
        if (
          image &&
          form?.external_provider_id === EXTERNAL_PROVIDER.DELIVEROO.toString()
        ) {
          handleUpdateImage(newExternalConnection.id);
        } else {
          clientQuery.invalidateQueries(['fetchExternalConnections']);
        }
        // Create external connection has structure bCatalog
        const res = await createExternalConnectionHasStructure({
          externalConnectionUuid: newExternalConnection.uuid,
          data: form,
        });
        if (res?.status !== 204)
          helperDisplayToast(t('EXTERNAL_CONNEXION_CREATED_ERROR_ASSOCIATION'));

        handleOnCancel();
      } else helperDisplayToast(t('EXTERNAL_CONNEXION_CREATED_ERROR'));
    }

    // Update mode
    if (updateMode && updatedExternalConnection) {
      let hasError = false;
      // Update external connection bCore
      const res = await updateExternalConnection({
        externalConnectionId: updatedExternalConnection.id,
        data: form,
      });
      if (res.status === 200) {
        if (
          image &&
          form?.external_provider_id === EXTERNAL_PROVIDER.DELIVEROO.toString()
        ) {
          handleUpdateImage(updatedExternalConnection.id);
        } else {
          clientQuery.invalidateQueries(['fetchExternalConnections']);
        }
      } else {
        hasError = true;
        helperDisplayToast(t('EXTERNAL_CONNEXION_UPDATE_ERROR'));
      }

      // Update external connection has structure bCatalog
      if (form.structureUuid !== dataSelectedStructure?.uuid) {
        const associationRes = await updateExternalConnectionHasStructure({
          externalConnectionUuid: updatedExternalConnection.uuid,
          data: {structureUuid: form.structureUuid},
        });
        if (associationRes?.status !== 204) {
          hasError = true;
          helperDisplayToast(t('EXTERNAL_CONNEXION_UPDATED_ERROR_ASSOCIATION'));
        }
      }

      if (!hasError)
        helperDisplayToast(t('EXTERNAL_CONNEXION_UPDATED_SUCCESS'), true);
      handleOnCancel();
    }
  };

  return (
    <ModalWrapper
      title={
        updateMode
          ? t('EXTERNAL_CONNECTION_MODE_EDIT')
          : t('EXTERNAL_CONNECTION_MODE_CREATE')
      }
      minWidth={updateMode ? '50%' : '28%'}
      validateLabel={t('SAVE')}
      cancelLabel={t('CANCEL')}
      onCancel={handleOnCancel}
      onSubmit={handleSubmit}>
      {isLoadingExternalStructures ||
      isLoadingSelectedStructure ||
      isLoadingPriceRules ? (
        <Loader size={80} />
      ) : (
        <>
          <SelectList
            label={t('PLATFORM')}
            list={EXTERNAL_PROVIDERS}
            placeHolder={t('SELECT_PLATFORM')}
            onChange={e =>
              setForm({...form, external_provider_id: e.target.value})
            }
            value={form.external_provider_id}
            manageByFormik={false}
            required
          />
          <InputLabel
            label={t('EXTERNAL_CONNECTION_NAME')}
            placeholder={t('NAME')}
            onChange={e => setForm({...form, connection_label: e.target.value})}
            value={form.connection_label}
            required
          />
          <InputLabel
            label={t('STORE_ID')}
            placeholder={t('ID')}
            onChange={e => setForm({...form, external_id: e.target.value})}
            value={form.external_id}
            required
          />
          <SelectList
            label={t('STRUCTURE')}
            placeHolder={t('STRUCTURE')}
            list={STRUCTURES}
            onChange={e => setForm({...form, structureUuid: e.target.value})}
            value={form.structureUuid}
            manageByFormik={false}
            required
          />
          <SelectList
            label={t('PRICE_RULES')}
            placeHolder={t('PRICE_RULES')}
            list={PRICE_RULES}
            onChange={e => setForm({...form, price_rule_uuid: e.target.value})}
            value={form.price_rule_uuid}
            manageByFormik={false}
          />
          {parseInt(form?.external_provider_id) ===
            EXTERNAL_PROVIDER.DELIVEROO && (
            <UploadButton
              required
              label={`${t('BANNER_IMAGE')} - jpeg / png`}
              aspectRatio="16 / 9"
              style={{margin: 'auto', marginTop: 25}}
              width={194}
              x={1920}
              y={1080}
              handleChange={handleChangeImage}
              value={
                !!image
                  ? image?.url
                  : updatedExternalConnection?.banner_image || undefined
              }
              smallIcon
              name="img"
            />
          )}
          <FieldsRequired />
        </>
      )}
    </ModalWrapper>
  );
};

import {IGetGapPaymentMethodsBackofficeResponse} from '@bill-app-types/b-types';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {helperFormatPrice} from '../../../../helpers/helperFormatPrice';
import {THEME} from '../../../../styles/theme';

export type FormattedRowGapPos = {
  id: number;
  label: string;
  posTerminals: {id: number; delta: number}[];
  totalDeltaByPaymentMethod: number;
};

export const columnsTVA = [
  {
    headerName: 'Taux',
    flex: 1,
    valueGetter: (row: any) => {
      return `${helperFormatPrice(row.tax?.amount)}%`;
    },
  },
  {
    headerName: 'HT',
    flex: 1,
    valueGetter: (row: any) => {
      return `${helperFormatPrice(row.totalHT)}€`;
    },
  },
  {
    headerName: 'TVA',
    flex: 1,
    valueGetter: (row: any) => {
      return `${helperFormatPrice(row.totalTVA)}€`;
    },
  },
  {
    headerName: 'TTC',
    flex: 1,
    valueGetter: (row: any) => {
      return `${helperFormatPrice(row.totalTTC)}€`;
    },
  },
];

export const columnsGapOld = [
  {
    headerName: 'Nom de la caisse',
    flex: 1,
    field: 'label',
  },
  {
    headerName: 'Écart',
    flex: 1,

    renderCell: (
      row: IGetGapPaymentMethodsBackofficeResponse['posTerminals'][0],
    ) => {
      const value = row.countingEvents?.reduce(
        (_acc, _curr) => _acc + _curr.delta,
        0,
      );

      return (
        <p
          className="textSemiBold"
          style={{color: value > 0 ? 'var(--text-color)' : THEME.colors.red}}>
          {`${helperFormatPrice(value)}€`}
        </p>
      );
    },
  },
];

export const columnsGap = (
  posTerminals: IGetGapPaymentMethodsBackofficeResponse['posTerminals'],
) => {
  const dynamicColumns = posTerminals.map(posTerminal => ({
    headerName: posTerminal.label,
    flex: 3,
    field: '',
    renderCell: (row: FormattedRowGapPos) => {
      const value =
        row.posTerminals.find(pT => pT.id === posTerminal.id)?.delta || 0;

      return (
        <p
          className="textSemiBold"
          style={{color: value > 0 ? 'var(--text-color)' : THEME.colors.red}}>
          {`${helperFormatPrice(value)}€`}
        </p>
      );
    },
  }));

  return [
    {
      headerName: '',
      flex: 3,
      field: '',
      valueGetter: (row: FormattedRowGapPos) => {
        return row.label;
      },
    },
    ...dynamicColumns,
    {
      headerName: 'Total',
      flex: 3,
      field: '',
      valueGetter: (row: FormattedRowGapPos) => {
        return `${helperFormatPrice(row.totalDeltaByPaymentMethod)}€`;
      },
    },
  ];
};

export const columnsPaymentMode = [
  {
    headerName: 'Mode de règlement',
    flex: 2,

    renderCell: (row: any) => (
      <TextVariant
        text={row.paymentMethod?.label}
        variant="textSemiBold"
        style={{
          color: !row.lastItem ? 'var(--text-color)' : THEME.colors.primary,
        }}
      />
    ),
  },
  {
    headerName: 'Code',
    flex: 1,
    valueGetter: (row: any) => {
      return row.paymentMethod?.code;
    },
  },
  {
    headerName: 'Compte',
    flex: 1,
    valueGetter: (row: any) => {
      return row.accountManagement?.code;
    },
  },
  {
    headerName: 'Montant',
    flex: 1,

    renderCell: (row: any) => (
      <TextVariant
        text={`${helperFormatPrice(row.totalTTC)}€`}
        variant="textSemiBold"
        style={{
          color: !row.lastItem ? 'var(--text-color)' : THEME.colors.primary,
        }}
      />
    ),
  },
];

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {CheckboxCustom} from '../atoms/CheckBoxCustom';
import {useTranslation} from 'react-i18next';

export const DataGridRow = ({
  row,
  index,
  columns,
  rowHeight = 45,
  rowLength = 0,
  onRowClick,
  dropdown = false,
  opened = null,
  setOpened,
  DatagridDropdown,
  checkBoxSelection = false,
  onCheckBoxClick,
  selectedCheckbox,
  bordered = false,
  selectedRow,
  showBorderBottom,
}: {
  row?: any;
  index?: number;
  columns?: any[];
  rowHeight?: string | number;
  rowLength?: number;
  onRowClick?: any;
  dropdown?: boolean;
  opened?: string | null;
  setOpened?: any;
  DatagridDropdown?: any;
  checkBoxSelection?: boolean;
  onCheckBoxClick?: any;
  selectedCheckbox?: any;
  bordered?: boolean;
  selectedRow?: any;
  showBorderBottom?: boolean;
}) => {
  //
  const {t} = useTranslation();

  const handleSetOpened = (uuid: string) => {
    if (opened === uuid) {
      setOpened(null);
    } else {
      setOpened(uuid);
    }
  };

  const isChecked = checkBoxSelection
    ? selectedCheckbox?.find((item: any) => item.uuid === row?.uuid)
    : false;

  const dropOpened = dropdown && opened === row?.uuid;

  return (
    <div css={styles.container({isLast: index === rowLength - 1})}>
      <div className="flex">
        {checkBoxSelection && (
          <div
            className="flex"
            style={{height: rowHeight, alignItems: 'center'}}>
            <CheckboxCustom
              marginRight={10}
              setChecked={() => onCheckBoxClick(row)}
              checked={isChecked}
            />
          </div>
        )}
        <div style={{flex: 1}}>
          <div
            onClick={() => {
              if (dropdown) {
                if (row.isActive && !checkBoxSelection) {
                  handleSetOpened(row?.uuid);
                  onRowClick && onRowClick(row);
                }
              } else {
                onRowClick && onRowClick(row);
              }
            }}
            className="flex-center"
            css={styles.row({
              rowHeight,
              cursor: onRowClick || dropdown ? 'pointer' : 'default',
              backgroundColor: THEME.colors.white,
              bordered,
              selectedRow,
              row,
              showBorderBottom,
            })}>
            {columns?.map((column, idx) => {
              const render = () => {
                return column.renderCell
                  ? column.renderCell(row, t)
                  : column.valueGetter
                  ? column.valueGetter(row, t)
                  : row[column.field];
              };

              return (
                <div
                  key={`${index}-${idx}`}
                  className="flex-center textMedium"
                  css={styles.rowCol({
                    flex: column?.flex,
                    width: column?.width,
                    color:
                      dropdown && !row.isActive
                        ? '#A7A7AB'
                        : THEME.colors.darkBlue,
                  })}>
                  {column.field === 'iconArrow' ? (
                    <span
                      style={{
                        transform: dropOpened
                          ? 'rotate(270deg)'
                          : 'rotate(180deg)',
                        transition: 'all 0.3s ease-in-out',
                      }}>
                      {render()}
                    </span>
                  ) : (
                    render()
                  )}
                </div>
              );
            })}
          </div>
          {dropOpened && (
            <div css={styles.dropdownContent}>{<DatagridDropdown />}</div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: ({isLast = false}: {isLast: boolean}) =>
    css({
      marginBottom: isLast ? 0 : 5,
      alignItems: 'center',
    }),
  row: ({
    rowHeight,
    cursor = 'default',
    backgroundColor = THEME.colors.white,
    bordered = false,
    selectedRow,
    row,
    showBorderBottom,
  }: {
    rowHeight: number | string;
    cursor?: string;
    backgroundColor?: string;
    bordered?: boolean;
    selectedRow?: any;
    row?: any;
    showBorderBottom?: boolean;
  }) =>
    css({
      backgroundColor,
      height: rowHeight,
      width: '100%',
      cursor: cursor,
      border: `1px solid ${
        selectedRow && JSON.stringify(selectedRow) === JSON.stringify(row)
          ? THEME.colors.primary
          : bordered
          ? THEME.colors.bgLight
          : backgroundColor
      }`,
      borderBottom: showBorderBottom
        ? `1px solid ${THEME.colors.lightGray}`
        : 'none',
      '&:hover': {
        backgroundColor: THEME.colors.bgLight,
        opacity: 0.6,
      },
    }),
  rowCol: ({
    flex = 1,
    width = 'none',
    color = THEME.colors.darkBlue,
  }: {
    flex?: number | string;
    width?: number | string;
    color?: string;
  }) =>
    css({
      flex,
      width,
      color,
    }),
  dropdownContent: css({
    backgroundColor: THEME.colors.white,
  }),
};

import {Title} from '../../../../../components/atoms/Title';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {AddPictogram} from './AddPictogram';
import {CatalogOfPictograms} from './CatalogOfPictograms';
import {ProductPictograms} from './ProductPictograms';

export const Pictograms = () => {
  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);
  return (
    <div>
      <Title title="Pictogrammes" />
      <ProductPictograms />
      {hasAuthorization && (
        <>
          <CatalogOfPictograms />
          <AddPictogram />
        </>
      )}
    </div>
  );
};

import {SVGProps} from 'react';

export const ArchiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    fill="none"
    {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M13.875 3.125h-6.75V5H6V3.125C6 2.504 6.504 2 7.125 2h6.75C14.496 2 15 2.504 15 3.125v9.75c0 .621-.504 1.125-1.125 1.125H8.937c.042-.117.063-.244.063-.375v-.75h4.875v-9.75Zm-.727 6.164a.562.562 0 0 1-.795.795l-.538-.54v1.643c0 .312-.25.563-.563.563a.561.561 0 0 1-.562-.563V9.546l-.54.539a.562.562 0 0 1-.795-.795l1.501-1.5a.56.56 0 0 1 .795 0l1.5 1.5h-.003ZM1.125 6.875V8h6.75V6.875h-6.75ZM0 8.375V6.5a.75.75 0 0 1 .75-.75h7.5A.75.75 0 0 1 9 6.5v1.875a.75.75 0 0 1-.75.75v4.125a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75V9.125a.75.75 0 0 1-.75-.75Zm1.875.75v3.75h5.25v-3.75h-5.25Zm1.688.75h1.874c.312 0 .563.25.563.563 0 .311-.25.562-.563.562H3.563A.561.561 0 0 1 3 10.437c0-.311.25-.562.563-.562Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 2h15v12H0z" />
      </clipPath>
    </defs>
  </svg>
);

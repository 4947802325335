import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {useQueryClient} from '@tanstack/react-query';
import {showAreaProductionProductsAction} from '../../../../redux/myRestaurantSection/areaProductions';
import {
  associateAreaProductionItem,
  dissociateAreaProductionItem,
} from '../../../../api/fetchAreaProductions';
import {useBrandAccess} from '../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../constants/frontPolicies';
import {ProductsCatalogWithCategories} from '../../../../components/organisms/ProductsCatalogWithCategories';

export const LeftBlock = () => {
  const dispatch = useAppDispatch();
  const showProducts = useAppSelector(
    s => s.myRestaurantSection.areaProductions.showProducts,
  );
  const areaProductionSelected = useAppSelector(
    s => s.myRestaurantSection.areaProductions.areaProductionSelected,
  );
  const areaProductionsItems = useAppSelector(
    s => s.myRestaurantSection.areaProductions.areaProductionsItems,
  );
  const clientQuery = useQueryClient();
  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const handleShowProducts = () => {
    dispatch(showAreaProductionProductsAction(!showProducts));
  };

  const handleClickProduct = async (product: any) => {
    if (areaProductionSelected && hasAuthorization) {
      const isExist = areaProductionsItems?.find(
        (item: any) => item.uuid === product.uuid,
      );

      if (!isExist) {
        await associateAreaProductionItem(
          areaProductionSelected?.uuid,
          product?.uuid,
        );
      } else {
        await dissociateAreaProductionItem(
          areaProductionSelected?.uuid,
          product?.uuid,
        );
      }

      await clientQuery.invalidateQueries([
        `fetchAreaProductionItems-${areaProductionSelected?.uuid}`,
      ]);
    }
  };

  return (
    <ProductsCatalogWithCategories
      hideCategories
      showProducts={showProducts}
      onToggleShowProducts={handleShowProducts}
      onClickProduct={handleClickProduct}
      productsChecked={areaProductionsItems}
    />
  );
};

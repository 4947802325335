/* @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ColumnsHoursList} from './ColumnsHoursList';
import {ColumnsDataList} from './ColumnsDataList';
import {IStatHourlyByDOW} from '../../../../../../api/fetchStats';

export const CalendarChart = ({data}: {data?: IStatHourlyByDOW}) => {
  //
  return (
    <div css={styles.container}>
      <ColumnsHoursList />
      <ColumnsDataList data={data} />
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '2rem',
    gap: '0.5rem',
    overflowX: 'auto',
  }),
};

import {IVendorStructure} from '@bill-app-types/b-types';
import {axiosInstance} from './axiosInstance';
import {helperLog} from '../helpers/helperLog';

export const getAllVendorStructureOld = async (brand_id: string) => {
  const res = await axiosInstance.get<{
    data: IVendorStructure[];
  }>(`/bcore/protected/admin/${brand_id}/vendor-structure/all`);

  return res?.data.data;
};

export const getAllVendorStructure = async () => {
  const res = await axiosInstance.get<{
    data: IVendorStructure[];
  }>(`bcatalog/backoffice/vendor-structures`);

  return res.data.data;
};

export const createVendorStructure = async (data: {
  label: string;
  taxUuid: string;
  position: number;
  parentCategoryUuid: string | null;
}) => {
  helperLog({message: 'createVendorStructure'});
  const res: any = await axiosInstance
    .post(`/bcatalog/backoffice/vendor-structures`, data)
    .catch(err => {
      helperLog({
        message: 'createVendorStructure',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res;
};

export const updateVendorStructure = async (
  data: Partial<{
    label: string;
    taxUuid: string;
    position: number;
    parentCategoryUuid: string | null;
  }>,
  vendorStructureUuid: string,
) => {
  helperLog({message: 'updateVendorStructure'});
  const res: any = await axiosInstance
    .put(`/bcatalog/backoffice/vendor-structures/${vendorStructureUuid}`, data)
    .catch(err => {
      helperLog({
        message: 'updateVendorStructure',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res;
};

export const deleteVendorStructure = async (vendorStructureUuid: string) => {
  helperLog({message: 'deleteVendorStructure'});
  const res: any = await axiosInstance
    .delete(`/bcatalog/backoffice/vendor-structures/${vendorStructureUuid}`)
    .catch(err => {
      helperLog({
        message: 'deleteVendorStructure',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res;
};

export const getVendorStructureItems = async (vendorStructureUuid: string) => {
  const res = await axiosInstance.get(
    `/bcatalog/backoffice/vendor-structures/${vendorStructureUuid}/items`,
  );

  return res.data.data;
};

export const addVendorStructureItem = async (
  vendorStructureUuid: string,
  itemUuid: string,
) => {
  helperLog({message: 'addVendorStructureItem'});
  const res: any = await axiosInstance
    .put(
      `/bcatalog/backoffice/vendor-structures/${vendorStructureUuid}/items/${itemUuid}`,
    )
    .catch(err => {
      helperLog({
        message: 'addVendorStructureItem',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res;
};

export const removeVendorStructureItem = async (
  vendorStructureUuid: string,
  itemUuid: string,
) => {
  helperLog({message: 'removeVendorStructureItem'});
  const res: any = await axiosInstance
    .delete(
      `/bcatalog/backoffice/vendor-structures/${vendorStructureUuid}/items/${itemUuid}`,
    )
    .catch(err => {
      helperLog({
        message: 'removeVendorStructureItem',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res;
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Loader} from '../atoms/Loader';
import {DatagridPagination} from './DatagridPagination';
import {DataGridRow} from './DatagridRow';
import {DatagridHeader} from './DatagridHeader';
import {useEffect, useState} from 'react';

export type DataGridColumn<T> = {
  field: keyof T | string;
  headerName: string;
  headerClassName?: string;
  flex?: number;
  headerAlign?: 'left' | 'center' | 'right';
  align?: 'left' | 'center' | 'right';
  renderCell?: (row: T) => JSX.Element;
  valueGetter?: (row: T) => string;
}[];

interface IDataGrid {
  columns?: Array<any>;
  rows?: Array<any>;
  rowHeight?: string | number;
  loading?: boolean;
  hasPagination?: boolean;
  setPage?: any;
  page?: number;
  totalRows?: number;
  rowPerPage?: number;
  footer?: any;
  stickyFooter?: boolean;
  minHeight?: number | string;
  onRowClick?: any;
  dropdown?: boolean;
  DatagridDropdown?: any;
  checkBoxSelection?: boolean;
  onCheckBoxClick?: any;
  selectedCheckbox?: any;
  hideHeader?: boolean;
  bordered?: boolean;
  selectedRow?: any;
  showBorderBottom?: boolean;
  paginationShowMore?: boolean;
  updateLimit?: (type: 'incr' | 'decr') => void;
  totalLimit?: number;
  limit?: number;
  listenedKeyForCloseDropdown?: any;
}

export const NewDataGrid = ({
  columns = [],
  rows = [],
  rowHeight = 45,
  loading = false,
  hasPagination = false,
  setPage,
  page = 0,
  totalRows = 0,
  rowPerPage = 0,
  footer,
  stickyFooter = false,
  minHeight,
  onRowClick,
  dropdown = false,
  DatagridDropdown,
  checkBoxSelection = false,
  onCheckBoxClick,
  selectedCheckbox,
  hideHeader = false,
  bordered = false,
  selectedRow,
  showBorderBottom,
  paginationShowMore = false,
  updateLimit,
  totalLimit,
  limit,
  listenedKeyForCloseDropdown = undefined,
}: IDataGrid) => {
  //
  const [opened, setOpened] = useState(null);

  useEffect(() => {
    if (checkBoxSelection) {
      setOpened(null);
    }
  }, [checkBoxSelection]);

  useEffect(() => {
    if (listenedKeyForCloseDropdown === null) {
      setOpened(null);
    }
  }, [listenedKeyForCloseDropdown]);

  return (
    <div css={styles.dataGrid(stickyFooter)}>
      {!hideHeader && (
        <DatagridHeader
          checkBoxSelection={checkBoxSelection}
          columns={columns}
        />
      )}
      <div css={styles.rows(!rows.length, minHeight, stickyFooter)}>
        {loading && (
          <div css={styles.loader}>
            <Loader size={35} />
          </div>
        )}
        {!rows.length && !loading && (
          <div css={styles.noData} className="textRegular">
            Aucun résultat
          </div>
        )}

        {rows?.map((row, index) => {
          return (
            <DataGridRow
              key={index}
              row={row}
              index={row?.uuid || row?.id || index}
              columns={columns}
              rowHeight={rowHeight}
              rowLength={rows?.length}
              onRowClick={onRowClick}
              dropdown={dropdown}
              opened={opened}
              setOpened={setOpened}
              DatagridDropdown={DatagridDropdown}
              checkBoxSelection={checkBoxSelection}
              onCheckBoxClick={onCheckBoxClick}
              selectedCheckbox={selectedCheckbox}
              bordered={bordered}
              selectedRow={selectedRow}
              showBorderBottom={showBorderBottom}
            />
          );
        })}
        {footer && footer}
      </div>
      {hasPagination && !loading && (
        <DatagridPagination
          page={page}
          totalRows={totalRows}
          rowPerPage={rowPerPage}
          setPage={setPage}
          isLoading={loading}
          paginationShowMore={paginationShowMore}
          updateLimit={updateLimit}
          totalLimit={totalLimit}
          limit={limit}
        />
      )}
    </div>
  );
};

const styles = {
  dataGrid: (stickyFooter = false) =>
    css({
      height: stickyFooter ? 'auto' : '100%',
      maxHeight: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    }),
  rows: (
    empty: boolean = false,
    minHeight: number | string = 55,
    stickyFooter: boolean = false,
  ) =>
    css({
      overflow: 'auto',
      overflowX: 'hidden',
      minHeight: empty ? minHeight : 'auto',
      flex: stickyFooter ? 'none' : 1,
    }),
  loader: css({
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  }),
  noData: css({
    backgroundColor: '#f6f7f9',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: '50px 0',
  }),
};

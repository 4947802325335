import dayjs from 'dayjs';
import {helperDate, helperResetDate} from './helperDate';

interface DateState {
  fromDate: string | Date | null;
  fromTime: string | Date | null;
  toDate: string | Date | null;
  toTime: string | Date | null;
  periodType: string;
  sortFilterDate: string | null;
  typeFilterDate: string | null;
  filterToDate?: string | Date | null;
  filterFromDate?: string | Date | null;
  forCompare?: boolean;
}

interface FilterDate {
  from: Date | null;
  to: Date | null;
  type: string | null;
  sort: string | null;
}

export const helperFilterDate = ({
  fromDate,
  fromTime,
  toDate,
  toTime,
  periodType,
  sortFilterDate,
  typeFilterDate,
  filterToDate,
  filterFromDate,
  forCompare,
}: DateState): FilterDate => {
  // date from date and time
  const newFromDate = new Date(
    helperDate(fromDate, 'MM/DD/YYYY') + ' ' + helperDate(fromTime, 'HH:mm'),
  );
  // date to date and time
  const newToDate = new Date(
    helperDate(toDate, 'MM/DD/YYYY') + ' ' + helperDate(toTime, 'HH:mm'),
  );

  if (typeFilterDate !== 'period' && !forCompare) {
    switch (typeFilterDate) {
      case 'equal':
        return {
          from: isInvalidDate(newFromDate) ? null : newFromDate,
          to: isInvalidDate(newFromDate) ? null : newFromDate,
          type: typeFilterDate,
          sort: sortFilterDate,
        };

      default:
        return {
          from: isInvalidDate(newFromDate) ? null : newFromDate,
          to: isInvalidDate(newToDate) ? null : newToDate,
          type: typeFilterDate,
          sort: sortFilterDate,
        };
    }
  } else {
    let date = newFromDate;
    let nextDay = dayjs(
      new Date(
        helperDate(newFromDate, 'MM/DD/YYYY') +
          ' ' +
          helperDate(toTime, 'HH:mm'),
      ),
    ).toDate();

    if (typeFilterDate === 'between') {
      date = new Date(new Date(newFromDate));

      const diff = dayjs(helperDate(filterToDate, 'YYYY-MM-DD')).diff(
        helperDate(filterFromDate, 'YYYY-MM-DD'),
        'day',
      );
      nextDay = helperResetDate(new Date(date)).add(diff, 'day').toDate();
    } else {
      switch (periodType) {
        case 'week':
          nextDay = helperResetDate(newFromDate).add(6, 'day').toDate();
          break;
        case 'month':
          date = new Date(
            new Date(
              forCompare ? newFromDate : newFromDate.setDate(1),
            ).setHours(0, 0, 0, 0),
          );
          nextDay = helperResetDate(new Date(date))
            .add(1, 'month')
            .add(-1, 'day')
            .toDate();
          break;

        case 'year':
          date = new Date(
            new Date(
              forCompare ? newFromDate : newFromDate.setMonth(0),
            ).setHours(0, 0, 0, 0),
          );
          nextDay = helperResetDate(new Date(date))
            .add(1, 'year')
            .add(-1, 'day')
            .toDate();
          break;

        default:
          break;
      }
    }

    const nextDayFormat = new Date(
      helperDate(nextDay, 'MM/DD/YYYY') +
        ' ' +
        helperDate(
          periodType !== 'day'
            ? new Date(new Date(nextDay).setHours(23, 59, 59))
            : nextDay,
          'HH:mm:ss',
        ),
    );

    return {
      from: isInvalidDate(date) ? null : date,
      to: isInvalidDate(nextDayFormat) ? null : nextDayFormat,
      type: typeFilterDate,
      sort: sortFilterDate,
    };
  }
};

const isInvalidDate = (date: any) => {
  return date.toString().includes('Invalid');
};

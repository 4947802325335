/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {updateBrandAccountingManagement} from '../../../../api/fetchBrandAccount';
import {Title} from '../../../../components/atoms/Title';
import {Select} from './Select';
import {ToggleButton} from '../../../../components/atoms/ToggleButton';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {THEME} from '../../../../styles/theme';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {usePaymentMethods} from '../../../../hooks/usePaymentMethods';

export const PaymentsMethodsSection = () => {
  //
  const {paymentMethods, refetch} = usePaymentMethods();

  const handleToggle = async (item: any) => {
    const res = await updateBrandAccountingManagement({
      id: item.method_id,
      body: {
        is_active: !item.is_active,
        account_management_id: item.account_management?.id,
      },
    });
    if (res?.status === 200) {
      helperDisplayToast('Mode de règlement modifié', true);
    } else {
      helperDisplayToast(
        res?.data?.message || 'Impossible de modifier le mode de règlement',
        false,
      );
    }
    refetch();
  };

  return (
    <div css={styles.content}>
      <Title title="Configuration des modes de règlement" />
      {!!paymentMethods ? (
        <div css={styles.form}>
          {paymentMethods?.map((item: any, id: number) => (
            <div key={id} css={styles.row}>
              <div className="flex">
                <TextVariant
                  variant="textSemiBold"
                  text={item.label || item?.paymentMethodProperties?.label}
                  style={styles.label}
                />
                <TextVariant
                  variant="textSemiBold"
                  text={`(${item.code || item?.paymentMethodProperties?.code})`}
                  style={styles.code}
                />
              </div>
              <div className="flex-align-center" css={styles.select}>
                <ToggleButton
                  active={item.is_active}
                  onToggle={() => handleToggle(item)}
                />
                <Select key={id} item={item} />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex-center textRegular" css={styles.noData}>
          Aucune donnée
        </div>
      )}
    </div>
  );
};

const styles = {
  content: css({
    padding: '2vh',
    width: '80%',
    height: '84%',
    alignSelf: 'center',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
  }),
  form: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1% 0',
    flexWrap: 'wrap',
  }),
  row: css({
    width: '49%',
  }),
  label: css({
    color: THEME.colors.gray,
    textTransform: 'lowercase',
    margin: '0.5rem 0',
    ':first-letter': {
      textTransform: 'uppercase',
    },
  }),
  code: css({
    color: THEME.colors.gray,
    textTransform: 'uppercase',
    margin: '0.5rem 0.25rem',
  }),
  select: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 3,
    paddingLeft: 10,
    '&:hover': {
      backgroundColor: THEME.colors.bgLight,
      opacity: 0.6,
    },
  }),
  noData: css({
    backgroundColor: '#f6f7f9',
    flex: 1,
  }),
};

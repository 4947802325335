import { SCALES } from "../constants/scales";

export const helperFindScales = (hours: number) => {
    const scales = [];
    for (const config of SCALES) {
      if (hours <= config.hours) {
        scales.push(...config.scales);
        break;
      }
    }
    return scales;
  }
import {useQuery} from '@tanstack/react-query';
import {getStatHourlyByDOW} from '../api/fetchStats';
import {useAppSelector} from './useReduxHook';

export const useStatHourlyByDOW = ({
  dates,
  isEnabled = true,
}: {
  dates?: {
    from: Date | null;
    to: Date | null;
  };
  isEnabled?: boolean;
} = {}) => {
  const datesFilter = useAppSelector(s => s.filter.dates);

  const {data, isLoading} = useQuery(
    ['getStatHourlyByDOW', dates, datesFilter],
    () =>
      getStatHourlyByDOW({
        from: (dates ?? datesFilter).from,
        to: (dates ?? datesFilter).to,
      }),
    {
      enabled: isEnabled,
    },
  );

  return {
    data,
    isLoading,
  };
};

import {SVGProps} from 'react';

export const TicketIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={23}
    fill="none"
    {...props}>
    <path
      fill={props.color || '#F7C761'}
      d="M13.303 15.272H8.337c-.22 0-.43.094-.585.262a.936.936 0 0 0-.242.635c0 .237.087.465.242.634a.797.797 0 0 0 .585.262h4.966c.22 0 .43-.094.585-.262a.936.936 0 0 0 .243-.634.936.936 0 0 0-.242-.635.797.797 0 0 0-.586-.262ZM8.337 13.479h2.483c.22 0 .43-.095.585-.263a.936.936 0 0 0 .243-.634.936.936 0 0 0-.242-.634.796.796 0 0 0-.586-.263H8.337c-.22 0-.43.095-.585.263a.936.936 0 0 0-.242.634c0 .238.087.466.242.634a.797.797 0 0 0 .585.263Z"
    />
    <path
      fill={props.color || '#F7C761'}
      d="m17.226 8.394-4.502-5.38a.835.835 0 0 0-.277-.219.774.774 0 0 0-.336-.078H6.318a1.953 1.953 0 0 0-.803.163c-.256.11-.488.274-.685.483a2.284 2.284 0 0 0-.462.73 2.432 2.432 0 0 0-.169.866v13.452c.003.298.06.592.17.866.107.274.264.522.461.73.197.208.43.372.685.483.255.111.528.166.803.163h9.005a1.95 1.95 0 0 0 .803-.163c.255-.11.488-.275.685-.483.196-.208.353-.456.462-.73.108-.274.165-.568.168-.866V8.995a.94.94 0 0 0-.215-.601Zm-4.75-2.986 2.267 2.69h-1.655a.615.615 0 0 1-.25-.072.663.663 0 0 1-.204-.17.762.762 0 0 1-.159-.52V5.408Zm2.847 13.451H6.318A.408.408 0 0 1 6 18.74a.507.507 0 0 1-.146-.328V4.959A.507.507 0 0 1 6 4.631a.407.407 0 0 1 .318-.12h4.502v2.825c-.013.66.215 1.3.636 1.778.42.48.998.759 1.607.777h2.723v8.52a.506.506 0 0 1-.146.328.408.408 0 0 1-.318.12Z"
    />
  </svg>
);

export const UserIcon = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8622_147080)">
      <path
        d="M7 8.375C9.17465 8.375 10.9375 6.61215 10.9375 4.4375C10.9375 2.26285 9.17465 0.5 7 0.5C4.82562 0.5 3.0625 2.26285 3.0625 4.4375C3.0625 6.61215 4.82617 8.375 7 8.375ZM7 1.8125C8.44758 1.8125 9.625 2.98992 9.625 4.4375C9.625 5.8848 8.44758 7.0625 7 7.0625C5.5527 7.0625 4.375 5.8848 4.375 4.4375C4.375 2.98992 5.55352 1.8125 7 1.8125ZM9.60039 9.25H4.375C1.95891 9.25 0 11.2086 0 13.625C0 14.1082 0.391836 14.5 0.850391 14.5H13.125C13.6082 14.5 13.9754 14.1082 13.9754 13.625C14 11.2078 12.0422 9.25 9.60039 9.25ZM1.34367 13.1875C1.5566 11.7052 2.835 10.5625 4.37609 10.5625H9.60148C11.1418 10.5625 12.4206 11.7052 12.6339 13.1875H1.34367Z"
        fill="#A7A7AB"
      />
    </g>
    <defs>
      <clipPath id="clip0_8622_147080">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

const FILES_TYPE = {
  csv: 'text/csv',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const helperDownloadFile = ({
  data,
  fileName,
  type,
}: {
  data: BlobPart;
  fileName: string;
  type: 'csv' | 'xlsx';
}) => {
  const blob = new Blob([data], {type: FILES_TYPE[type]});
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileName}_${new Date().toISOString()}.${type}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

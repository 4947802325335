/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext, useEffect, useRef, useState} from 'react';
import {helperDate} from '../../helpers/helperDate';
import {
  getFilterIcon,
  helperDateLabel,
  helperShowResetDate,
} from '../../helpers/helperFilter';
import {FilterIcon} from '../../assets/svgs/FilterIcon';
import {THEME} from '../../styles/theme';
import {UtilsContext} from '../../contexts/UtilsContext';
import {TextVariant} from './TextVariant';
import {FilterSquare} from '../../assets/svgs/filterSquare';
import {
  resetFilterBirthdateAction,
  resetFilterCompareDatesAction,
  resetFilterCreatedOrderDateAction,
  resetFilterDateAction,
  resetFilterLastOrderDateAction,
} from '../../redux/filter';
import {typeFilterDateAction} from '../../redux/date';
import {CrossFilterIcon} from '../../assets/svgs/CrossFilterIcon';
import {CompareDatesFilter} from '../molecules/CompareDateFilter';
import {DateFilter} from '../molecules/DateFilter';

interface IFilterButtonProps {
  label?: string;
  disabled?: boolean;
  marginLeft?: string | number;
  marginRight?: string | number;
  icon?: string | null;
  valueType?: string;
  children?: any;
  values?: any;
  width?: any;
  hideFilterIcon?: boolean;
  showResetButton?: boolean;
  onReset?: () => void;
  forceClose?: boolean;
  withBorder?: boolean;
  dateShowingOptions?: {
    disabledFilterTypes: string[];
    showSortFilter?: boolean;
    forStats?: boolean;
  };
  sortFilter?: null | string;
}

export const FilterButton = ({
  label = '',
  disabled = false,
  marginLeft = 0,
  marginRight = 0,
  icon = null,
  valueType = 'list',
  children,
  values,
  width = 'fit-content',
  hideFilterIcon = false,
  showResetButton = false,
  forceClose = false,
  onReset,
  withBorder,
  dateShowingOptions,
  sortFilter,
}: IFilterButtonProps) => {
  //
  const [isOpen, setIsOpen] = useState(false);
  const btn: any = useRef(null);
  // Condition for hide or not cross compare date
  const noReset = valueType === 'dateCompare' && (!values.from || !values.to);

  const {t, dispatch} = useContext<any>(UtilsContext);

  const handleClickOutside = (e: any) => {
    if (btn.current && !btn.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (forceClose) {
      setIsOpen(false);
    }
  }, [forceClose]);

  const displayValues = () => {
    switch (valueType) {
      case 'list':
        return (
          <div
            css={[styles.values, styles.list, {alignItems: 'center'}]}
            className="filterList">
            {values.map((item: any, index: number) => (
              <div key={index} css={styles.value}>
                <TextVariant
                  text={t(item.name)}
                  variant="textBold"
                  style={styles.valueText}
                />
                {index < values?.length - 1 && (
                  <TextVariant
                    text={'-'}
                    variant="title03"
                    style={[styles.separator, {marginRight: 0}]}
                  />
                )}
              </div>
            ))}
            {sortFilter && (
              <TextVariant
                text={t(sortFilter === 'ASC' ? 'CROISSANT' : 'DECREASING')}
                variant="textBold"
                style={styles.valueText}
              />
            )}
          </div>
        );
      case 'dates':
      case 'createdCustomerDates':
      case 'birthdate':
      case 'lastOrderDates':
        return (
          <div css={styles.values}>
            <div css={styles.value}>
              <TextVariant
                text={helperDate(values?.from, 'DD-MM-YYYY HH:mm')}
                variant="textBold"
                style={styles.valueText}
              />
              {!!(values.from && values.to) &&
                ['between', 'period', null].includes(values?.type) && (
                  <TextVariant
                    text={'au'}
                    variant="title03"
                    style={styles.separator}
                  />
                )}
              {values?.type !== 'equal' && (
                <TextVariant
                  text={helperDate(values?.to, 'DD-MM-YYYY HH:mm')}
                  variant="textBold"
                  style={[styles.valueText, {marginLeft: 0}]}
                />
              )}
            </div>
          </div>
        );

      case 'dateCompare':
        return (
          <div css={styles.values}>
            <div css={styles.value}>
              <TextVariant
                text={helperDate(values?.from, 'DD-MM-YYYY HH:mm')}
                variant="textBold"
                style={[styles.valueText, {marginLeft: 0}]}
              />

              {!!(values.from && values.to) && (
                <TextVariant
                  text={'à'}
                  variant="title03"
                  style={styles.separator}
                />
              )}

              <TextVariant
                text={helperDate(values?.to, 'DD-MM-YYYY HH:mm')}
                variant="textBold"
                style={[
                  styles.valueText,
                  {marginLeft: 0, color: THEME.colors.black},
                ]}
              />
            </div>
          </div>
        );

      case 'value':
        return (
          <div css={styles.values}>
            <div css={styles.value}>
              {' '}
              <TextVariant
                text={values}
                variant="textBold"
                style={[styles.valueText, {marginLeft: 5}]}
              />
            </div>
          </div>
        );
      default:
        return;
    }
  };

  const handleOnClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleResetFilterDate = () => {
    if (valueType === 'dateCompare') {
      // FIXME : for date compare
      dispatch(resetFilterCompareDatesAction());
    } else {
      if (valueType === 'dates') {
        dispatch(resetFilterDateAction());
      } else if (valueType === 'createdCustomerDates') {
        dispatch(resetFilterCreatedOrderDateAction());
      } else if (valueType === 'birthdate'){
        dispatch(resetFilterBirthdateAction());
      } else {
        dispatch(resetFilterLastOrderDateAction());
      }
      dispatch(typeFilterDateAction('between'));
    }
  };

  const handleOnReset = (e: any) => {
    e.stopPropagation();
    if (onReset) {
      onReset();
    }
  };

  const title = valueType.toLocaleLowerCase().includes('date')
    ? valueType === 'dateCompare'
      ? t('COMPARE') + '  '
      : (valueType !== 'dates' ? label + ' ' : '') +
        (!!values?.from ? helperDateLabel(values?.type) : '')
    : label;

  return (
    <div
      ref={btn}
      css={styles.filter(marginLeft, marginRight, width, withBorder)}>
      <button
        onClick={handleOnClick}
        disabled={disabled}
        css={styles.button(width)}>
        {!!icon && <div css={styles.leftIcon}>{getFilterIcon(icon)}</div>}

        <TextVariant
          text={title}
          style={[
            styles.label,
            valueType === 'dateCompare' && {marginRight: 5},
          ]}
          variant="title03"
        />
        {(valueType.toLocaleLowerCase().includes('date') ||
          !hideFilterIcon) && (
            <div css={styles.filterIcon} className="flex-center">
              <FilterIcon />
            </div>
          )}

        {values && displayValues()}

        <>
          {(!noReset && valueType.toLocaleLowerCase().includes('date')
            ? (values.from || values.to) &&
              helperShowResetDate(values.from, values.to)
            : showResetButton) && (
            <div
              css={styles.filterIcon}
              className="flex-center"
              onClick={
                valueType.toLocaleLowerCase().includes('date')
                  ? handleResetFilterDate
                  : handleOnReset
              }>
              <CrossFilterIcon />
            </div>
          )}
        </>
      </button>

      {isOpen && (
        <div css={styles.dropdown}>
          <div css={styles.chevron}>
            <FilterSquare />
          </div>
          {children}
          {valueType.toLocaleLowerCase().includes('date') && (
            <DatesView
              valueType={valueType}
              handleClose={handleClose}
              dateShowingOptions={dateShowingOptions}
            />
          )}
        </div>
      )}
    </div>
  );
};

const DatesView = ({
  valueType,
  handleClose,
  dateShowingOptions,
}: {
  valueType: string;
  handleClose: () => void;
  dateShowingOptions: any;
}) => {
  if (valueType !== 'dateCompare') {
    return (
      <DateFilter
        initType={valueType}
        {...dateShowingOptions}
        handleClose={handleClose}
      />
    );
  }

  return <CompareDatesFilter handleClose={handleClose} />;
};

const styles = {
  filter: (
    marginLeft: string | number,
    marginRight: string | number,
    width: string | number,
    withBorder?: boolean,
  ) =>
    css({
      position: 'relative',
      marginLeft,
      marginRight,
      minWidth: width,
      border: withBorder ? `1px solid ${THEME.colors.lightGray}` : 'none',
    }),
  button: (width?: string | number) =>
    css({
      backgroundColor: 'var(--bg-color)',
      border: `1px solid var(--bg-color)`,
      padding: '0 12px',
      height: 28,
      cursor: 'pointer',
      alignItems: 'center',
      // justifyContent: 'center',
      display: 'flex',
      position: 'relative',
      minWidth: width,
    }),
  label: css({
    color: THEME.colors.lightGray,
    fontSize: 10,
  }),
  filterIcon: css({
    paddingLeft: 5,
  }),
  leftIcon: () =>
    css({
      paddingRight: 5,
    }),
  values: () =>
    css({
      display: 'flex',
    }),
  list: () =>
    css({
      maxWidth: 200,
      overflow: 'hidden',
    }),
  value: () =>
    css({
      display: 'flex',
      alignItems: 'center',
      // marginLeft: 5,
    }),
  valueText: css({
    color: THEME.colors.primary,
    fontWeight: '700',
    fontSize: 10,
    marginLeft: 5,
  }),
  separator: css({
    color: THEME.colors.lightGray,
    marginLeft: 5,
    marginRight: 5,
  }),
  dropdown: () =>
    css({
      backgroundColor: THEME.colors.white,
      position: 'absolute',
      top: 36,
      left: 0,
      minWidth: '100%',
      zIndex: 99,
      boxShadow: '0px 4px 30px 0px #0000002B',
      borderRadius: 2,
    }),
  chevron: css({
    position: 'absolute',
    top: -10,
    left: 7,
    display: 'flex',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../../../components/atoms/TextVariant';
import {helperUnderTen} from '../../../../../../helpers/helperUnderTen';
import {THEME} from '../../../../../../styles/theme';

export const ColumnsHoursList = () => {
  return (
    <div css={styles.container}>
      <div>
        {Array.from({length: 24}).map((_, index) => {
          return (
            <div key={index} css={styles.rowElem(index === 23)}>
              <TextVariant
                text={`${helperUnderTen(index)}`}
                variant="textSemiBold"
                style={{color: THEME.colors.gray2}}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    flex: 0.5,
    marginTop: '2.25rem',
  }),
  rowElem: (isLast: boolean) =>
    css({
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '0.2rem',
      marginBottom: isLast ? 0 : '0.2rem',
      justifyContent: 'flex-end',
      height: '24px',
      borderRadius: '0.13rem',
      backgroundColor: 'transparent',
    }),
};

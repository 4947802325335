import {SVGProps} from 'react';

export const TranslationsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="10"
    viewBox="0 0 15 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6875 2.35146C10.9453 2.35146 11.1562 2.54193 11.1562 2.82175V2.91581H12.5625C12.8203 2.91581 13.0312 3.10628 13.0312 3.3861C13.0312 3.64476 12.8203 3.85639 12.5625 3.85639H12.5156L12.4781 3.96221C12.2695 4.51715 11.9508 5.05799 11.5477 5.50006C11.5688 5.51182 11.5898 5.50477 11.6109 5.53769L12.0539 5.8034C12.2766 5.93744 12.3469 6.22667 12.2133 6.45005C12.082 6.67344 11.7938 6.74399 11.5711 6.60995L11.1281 6.34424C11.025 6.28075 10.9008 6.21491 10.8211 6.14436C10.575 6.32072 10.3078 6.47357 10.0266 6.60055L9.93984 6.63817C9.70312 6.74399 9.42656 6.63582 9.32109 6.39832C9.21563 6.16082 9.32344 5.88335 9.56016 5.77754L9.64453 5.73991C9.79453 5.67172 9.93984 5.57531 10.0781 5.50947L9.79453 5.22259C9.60938 5.03918 9.60938 4.74289 9.79453 4.55948C9.97734 4.37372 10.2727 4.37372 10.4555 4.55948L10.7977 4.90044L10.8117 4.89339C11.1023 4.60651 11.3391 4.24909 11.5102 3.83523H9C8.72109 3.83523 8.53125 3.64476 8.53125 3.36494C8.53125 3.10628 8.72109 2.89465 9 2.89465H10.2188V2.80059C10.2188 2.54193 10.4086 2.3303 10.6875 2.3303V2.35146ZM3.28359 4.9851L3.75 3.97867L4.19531 4.9851H3.28359ZM13.5 0C14.3273 0 15 0.673693 15 1.50493V7.52467C15 8.35474 14.3273 9.02961 13.5 9.02961H1.5C0.671484 9.02961 0 8.35474 0 7.52467V1.50493C0 0.673693 0.671484 0 1.5 0H13.5ZM13.5 1.1287H7.5V7.90091H13.5C13.7063 7.90091 13.875 7.7316 13.875 7.52467V1.50493C13.875 1.29801 13.7063 1.1287 13.5 1.1287ZM4.17891 2.63128C4.10391 2.46198 3.93516 2.35146 3.75 2.35146C3.56484 2.35146 3.39609 2.46198 3.32109 2.63128L1.82156 6.01739C1.71656 6.23372 1.82297 6.53236 2.05969 6.63817C2.29617 6.74399 2.57344 6.63582 2.67891 6.39832L2.8875 5.90452H4.6125L4.82109 6.39832C4.92656 6.63582 5.20312 6.74399 5.43984 6.63817C5.67656 6.53236 5.78438 6.23372 5.67891 6.01739L4.17891 2.63128Z"
      fill="white"
    />
  </svg>
);

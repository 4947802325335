import {FilterButton} from '../../../../../../components/atoms/FilterButton';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';

export const Bottom = () => {
  const dates = useAppSelector(s => s.filter.dates);

  return (
    <>
      <FilterButton
        icon={'calendar'}
        valueType="dates"
        marginRight={'1vh'}
        // width={'32vh'}
        values={dates}
        hideFilterIcon
      />
    </>
  );
};

import {useContext, useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {getSubscriptions} from '../api/fetchSubscription';
import {UtilsContext} from '../contexts/UtilsContext';
import { subscriptionsAction } from '../redux/myModulesSection/subscriptions';

export const useSubscription = () => {
  const {dispatch} = useContext<any>(UtilsContext);

  const {data, isLoading, error} = useQuery(['subscriptions'], () =>
    getSubscriptions(),
  );

  useEffect(() => {
    if (data) {
      dispatch(subscriptionsAction(data));
    }
  }, [data, dispatch]);

  return {
    data,
    isLoading,
    error,
  };
};

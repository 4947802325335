/** @jsxImportSource @emotion/react */
import {deleteBrandAccountingManagement} from '../../../../api/fetchBrandAccount';
import {DataGridActions} from '../../../../components/newDatagrid/DataGridActions';
import {helperConfirmDialog} from '../../../../helpers/helperConfirmDialog';
import {helperDate} from '../../../../helpers/helperDate';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {addAccountingModalAction} from '../../../../redux/modals';
import {
  updateModeAction,
  updatedAccountAction,
} from '../../../../redux/myAccountingSection/parameters';

export const columns = [
  {
    field: 'label',
    headerName: 'Nom du compte',
    flex: 1,
  },
  {
    field: 'code',
    headerName: 'Code',
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: "Date d'ajout",
    flex: 1,
    valueGetter: (row: any) => {
      return helperDate(row?.created_at, 'Le DD/MM/YYYY à HH:mm');
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Date de modification',
    flex: 1,
    valueGetter: (row: any) => {
      return helperDate(row?.updated_at, 'Le DD/MM/YYYY à HH:mm');
    },
  },
  {
    field: 'actions',
    headerName: '',
    align: 'right',
    flex: 0.5,
    renderCell: (row: any) => {
      const handleClickEdit = ({dispatch}: any) => {
        dispatch(addAccountingModalAction(true));
        dispatch(updatedAccountAction(row));
        dispatch(updateModeAction(true));
      };

      const handleClickDelete = ({refreshing}: any) => {
        helperConfirmDialog({
          onSubmit: async () => {
            const res = await deleteBrandAccountingManagement(row.id);
            if (res && res.status === 200) {
              helperDisplayToast('Compte supprimé', true);
              refreshing('getBrandAccountingManagement');
            } else {
              helperDisplayToast(
                res.data?.message || 'Impossible de supprimer le compte',
                false,
              );
            }
          },
        });
      };

      return (
        <DataGridActions
          handleClickEdit={handleClickEdit}
          handleClickDelete={handleClickDelete}
        />
      );
    },
  },
];

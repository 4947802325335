import {Text, View, StyleSheet} from '@react-pdf/renderer';
import {THEME} from '../../styles/theme';

export const ArrayPdf = ({
  header = [],
  rows = [],
  footer = [],
  sizes = [],
}: any) => {
  return (
    <View style={styles.array}>
      <View
        style={[styles.sectionFlex, {backgroundColor: THEME.colors.bgDark}]}>
        {header.map((item: any, index: number) => (
          <View
            style={[
              styles.col,
              {
                flex: sizes[index],
                alignItems:
                  index === header?.length - 1 ? 'flex-end' : 'flex-start',
              },
            ]}
            key={index}>
            <Text style={styles.headerText}>{item}</Text>
          </View>
        ))}
      </View>
      <View>
        {rows?.map((item: any, index: number) => {
          if (!item) return null;
          const keys = Object.keys(item);
          return (
            <View
              key={index}
              style={[
                styles.sectionFlex,
                {
                  backgroundColor:
                    index % 2 ? THEME.colors.bgLight : THEME.colors.white,
                },
              ]}>
              {keys.map((k, idx) => (
                <View
                  key={`${index}-${idx}`}
                  style={[
                    styles.col,
                    {
                      flex: sizes[idx],
                      alignItems:
                        idx === keys?.length - 1 ? 'flex-end' : 'flex-start',
                    },
                  ]}>
                  <Text style={styles.valueText}>{item[k]}</Text>
                </View>
              ))}
            </View>
          );
        })}
      </View>
      <View
        style={[
          styles.sectionFlex,
          {borderTopWidth: 1, borderColor: THEME.colors.primary},
        ]}>
        {footer.map((item: any, index: number) => (
          <View
            style={[
              styles.col,
              {
                flex: sizes[index],
                alignItems:
                  index === footer?.length - 1 ? 'flex-end' : 'flex-start',
              },
            ]}
            key={index}>
            <Text style={styles.footerText}>{item}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  array: {
    marginBottom: '7%',
  },
  sectionFlex: {
    flexDirection: 'row',
  },
  col: {
    padding: '5px 18px',
    // alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 7.63,
    color: THEME.colors.white,
    fontWeight: 'bold',
  },
  valueText: {
    color: THEME.colors.gray,
    fontSize: 7.63,
    fontWeight: 'bold',
  },
  footerText: {
    color: THEME.colors.bgDark,
    fontSize: 7.63,
    fontWeight: 'bold',
  },
});

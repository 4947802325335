/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

export const Loader = ({
  size = 25,
  borderWidth = 3,
  color = '#3C6AF6',
}: {
  size?: number;
  borderWidth?: number;
  color?: string;
}) => {
  return (
    <span
      className="loader"
      css={css({
        width: size,
        height: size,
        borderWidth: borderWidth,
        borderColor: color,
        borderTopColor: 'transparent',
      })}
    />
  );
};

import {helperLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';

interface IProductData {
  search: string;
  limit: number;
  offset: number;
  orderBy?: object[];
  isArchived?: boolean | null;
  isOutOfStock?: boolean | null;
}

export const fetchProducts = async (data: IProductData) => {
  
  helperLog({message: 'fetchProducts'});
  const query = helperGenerateQuery(data);

  const res: any = await axiosInstance
    .get(`/bcatalog/backoffice/courses${query}`, {
      params: { orderBy: data.orderBy, isArchived:data.isArchived, isOutOfStock: data.isOutOfStock }
    })
    .catch(err => {
      helperLog({message: 'fetchProducts', type: 'API_ERROR', data: err});
    });

  return res?.data?.data;
};

export const fetchProductsOld = async (data: IProductData) => {
  
  helperLog({message: 'fetchProducts'});
  const query = helperGenerateQuery(data);

  const res: any = await axiosInstance
    .get(`/bcore/protected/admin/course/all${query}`)
    .catch(err => {
      helperLog({message: 'fetchProducts', type: 'API_ERROR', data: err});
    });

  return res?.data?.data;
};

export const fetchProductDetails = async (courseUuid: string) => {
  helperLog({message: 'fetchProductDetails'});
  const res: any = await axiosInstance
    .get(`/bcatalog/backoffice/courses/${courseUuid}/informations`)
    .catch(err => {
      helperLog({message: 'fetchProductDetails', type: 'API_ERROR', data: err});
    });

  return res?.data?.data;
};

export const createProduct = async (data: any) => {
  const res: any = await axiosInstance
    .post('/bcatalog/backoffice/courses', data)
    .catch(err => {
      helperLog({message: 'createProduct', type: 'API_ERROR', data: err});
    });

  return res;
};

export const updateProduct = async (courseUuid: string, data: any) => {
  const res: any = await axiosInstance
    .put(`/bcatalog/backoffice/courses/${courseUuid}`, data)
    .catch(err => {
      helperLog({message: 'updateProduct', type: 'API_ERROR', data: err});
    });

  return res;
};

export const duplicateProduct = async (courseUuid: string) => {
  //
  console.log('### duplicateProduct ###');
  const res: any = await axiosInstance
    .put(`/bcatalog/backoffice/courses/${courseUuid}/duplicate`)
    .catch(err => {
      console.log('❌ duplicateProduct : ', err);
    });
  return res;
};
import {PDFDownloadLink} from '@react-pdf/renderer';
import {useContext, useState} from 'react';
import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {ExportSalesPDF} from '../../../../../components/templates/ExportSalesPDF';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {helperDate} from '../../../../../helpers/helperDate';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {exportGapPosExcel} from '../../../../../api/fetchGapPos';
import {helperDownloadFile} from '../../../../../helpers/helperDownloadFile';
import {useFlagBCatalog} from '../../../../../hooks/useFlagBCatalog';

export const Center = () => {
  const {t} = useContext<any>(UtilsContext);
  const totalTva = useAppSelector(s => s.exportPdfData.totalTva);
  const paymentTotal = useAppSelector(s => s.exportPdfData.paymentTotal);
  const dates = useAppSelector(s => s.filter.dates);
  const brand = useAppSelector(s => s.brand.brand);

  const {flagBCatalogEnabled} = useFlagBCatalog();

  const [isLoading, setIsLoading] = useState(false);

  const handleExportExcelData = async () => {
    setIsLoading(true);
    const data = await exportGapPosExcel(dates);

    if (data) {
      const filename = `export_sales`;
      helperDownloadFile({data, fileName: filename, type: 'xlsx'});
    }

    setIsLoading(false);
  };

  return (
    <>
      <PDFDownloadLink
        document={
          <ExportSalesPDF
            totalTva={totalTva}
            paymentTotal={paymentTotal}
            dates={dates}
            brand={brand}
          />
        }
        fileName={`sales-${helperDate(new Date(), 'DD-MM-YYYY')}.pdf`}>
        <ButtonCustom label={t('DOWNLOAD_PDF')} marginRight={'1vh'} />
      </PDFDownloadLink>
      {flagBCatalogEnabled && (
        <ButtonCustom
          label={t('EXPORT_DATA')}
          onClick={handleExportExcelData}
          isLoading={isLoading}
          disabled={isLoading}
        />
      )}
    </>
  );
};

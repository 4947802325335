import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';

type FetchQuery = {
  from: string | Date;
  to: string | Date;
  amountFrom?: number | null;
  amountTo?: number | null;
  search?: string;
};

export type SqsTransaction = {
  amount: string;
  mode: string;
  code: string;
  created_at: string;
  id: number;
  process_type: string;
  quantity: number;
  receipt_id: string;
  receipt_number: string;
  brand_id: string;
};

export const fetchSqsTransactions = async (query: FetchQuery) => {
  const q = helperGenerateQuery(query);

  const res = await axiosInstance.get<{
    data: SqsTransaction[];
  }>(`bcore/protected/admin/sqs-history/transactions${q}`);

  return res.data;
};

export type SqsReceipt = {
  amount_ht: string;
  amount_ttc: string;
  nb_lines: number;
  receipt_id: string;
  order_id: [string];
  receipt_number: string;
  brand_id: string;
  created_at: string;
};

export const fetchSqsReceipts = async (query: FetchQuery) => {
  const q = helperGenerateQuery(query);

  const res = await axiosInstance.get<{
    data: SqsReceipt[];
  }>(`bcore/protected/admin/sqs-history/receipts${q}`);

  return res.data;
};

export const fetchSqsReceiptDetail = async (receiptId: string) => {
  const res = await axiosInstance.get<{
    data: any;
  }>(`bcore/protected/admin/sqs-history/receipts/${receiptId}`);

  return res.data?.data;
};

export type SqsTotalByPaymentMethods = {
  mode: string;
  code: string;
  amount: string;
  total_receipts: string;
};

export const fetchSqsTotalByPaymentMethods = async (
  data: Pick<FetchQuery, 'from' | 'to'>,
) => {
  const q = helperGenerateQuery(data);
  const res = await axiosInstance.get<{
    data: SqsTotalByPaymentMethods[];
  }>(`bcore/protected/admin/sqs-history/totals-by-payment-method${q}`);

  return res?.data;
};

export type SqsTotalByTva = {
  tva_rate: string;
  amount_ttc: string;
  amount_ht: string;
  total_receipts: string;
};

export const fetchSqsTotalByTva = async (
  data: Pick<FetchQuery, 'from' | 'to'>,
) => {
  const q = helperGenerateQuery(data);
  const res = await axiosInstance.get<{
    data: SqsTotalByTva[];
  }>(`bcore/protected/admin/sqs-history/totals-by-tva${q}`);

  return res?.data;
};

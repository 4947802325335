/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useQuery} from '@tanstack/react-query';
import {Loader} from '../../../../components/atoms/Loader';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {THEME} from '../../../../styles/theme';
import {getOrderDetailsV2} from '../../../../api/fetchOrders';
import {TicketContent} from './TicketContent';

export const OrderTicket = ({
  TicketHeader,
  TicketFooter,
}: {
  TicketHeader?: any;
  TicketFooter?: any;
}) => {
  //
  const selectedOrder = useAppSelector(s => s.order.selectedOrder);

  const {data, isLoading} = useQuery(
    ['getOrderDetails', selectedOrder],
    () => (selectedOrder ? getOrderDetailsV2(selectedOrder?.uuid) : null),
    {
      enabled: !!selectedOrder?.uuid,
    },
  );

  return (
    <div css={styles.ticket}>
      {isLoading ? (
        <div className="flex-center" css={styles.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {TicketHeader && <TicketHeader order={data} />}
          <TicketContent data={data} />
          {TicketFooter && <TicketFooter order={data} loyalty={0} />}
        </>
      )}
    </div>
  );
};

const styles = {
  ticket: css({
    backgroundColor: 'var(--bg-color)',
    fontSize: '20px',
    height: '3Ovh',
    width: '24%',
    color: 'var(--text-color)',
    border: `1px solid ${THEME.colors.lightGray}`,
    marginRight: '1%',
    flexDirection: 'column',
    display: 'flex',
  }),
  loaderContainer: css({
    flex: 1,
    margin: 'auto',
    alignSelf: 'auto',
    flexDirection: 'column',
    height: '100%',
  }),
};

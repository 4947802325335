/* @jsxImportSource @emotion/react */
import {SalesByCardsColumns} from '../DataColumns/SalesByCardsColumns';
import {SalesPieChartList} from '../../../../../components/organisms/SalesPieChartList';
import {useStatCard} from '../../../../../hooks/useStatCard';
import {useAppSelector} from '../../../../../hooks/useReduxHook';

export const SalesByCards = () => {
  const compareDates = useAppSelector(s => s.filter.compareDates);
  const isCompare = useAppSelector(s => s.filter.isCompare);
  const dates = useAppSelector(s => s.filter.dates);

  const {data, isLoading} = useStatCard({
    dates,
  });

  const {data: dataCompare} = useStatCard({
    dates: compareDates,
    enabled: isCompare,
  });

  const dataWithCompare = data?.map((item, index) => {
    const findItem = dataCompare?.find(_item => {
      return _item.label === item.label;
    });

    return {
      ...item,
      forCompareOrders: findItem?.orders || {
        value: 0,
        percent: 0,
      },
      forCompareSales: findItem?.sales || {
        TTC: {
          total_price: 0,
          total_percent: 0,
        },
      },
    };
  });

  return (
    <SalesPieChartList
      title="SALES_BY_CARDS"
      showHeaderFilters={false}
      data={isCompare ? dataWithCompare : data}
      isLoading={isLoading}
      columns={SalesByCardsColumns}
      marginTop={'2rem'}
    />
  );
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import LeftBlock from './LeftBlock';
import CenterBlock from './CenterBlock';
import RightBlock from './RightBlock';
import BottomBlock from './BottomBlock';
import {THEME} from '../../../../../../styles/theme';

const Pos = () => {
  return (
    <div css={styles.container}>
      <div css={styles.contentColumn}>
        <LeftBlock />
        <CenterBlock />
        <RightBlock />
      </div>
      <BottomBlock />
    </div>
  );
};

export default Pos;

const styles = {
  container: css({
    flex: 1,
    border: `10px solid ${THEME.colors.lightGray}`,
    borderRadius: '10px',
    padding: '25px 50px 0px 50px',
    height: '92%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),
  contentColumn: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: '1.5rem',
    maxHeight: '93%',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ChevronDownIcon} from '../../assets/svgs/ChevronDownIcon';
import {THEME} from '../../styles/theme';
import {TextVariant} from '../atoms/TextVariant';

export const DropDownOptions = ({
  active,
  dropDown,
  onOpen,
  children,
  headerBgColor = THEME.colors.primary,
  title,
  contentHeight,
}: {
  active: boolean;
  dropDown: string | number;
  onOpen: any;
  children?: React.ReactNode;
  headerBgColor?: string;
  title: string;
  contentHeight?: number | string;
}) => {
  const color =
    headerBgColor === 'white' || headerBgColor === '#fff'
      ? THEME.colors.bgDark
      : 'white';

  return (
    <div>
      <div
        css={[
          styles.dropDownHeader,
          {
            backgroundColor: headerBgColor,
          },
        ]}
        className="flex-space-between"
        onClick={() => onOpen(dropDown)}>
        <TextVariant text={title} variant="textSemiBold" style={{color}} />

        <p css={styles.dropDownIcon} className={active ? 'open' : ''}>
          <ChevronDownIcon color={color} />
        </p>
      </div>
      <div
        css={styles.dropDownContent(contentHeight)}
        className={active ? 'open' : ''}>
        <div
          style={{
            padding: '0.5rem',
          }}
          className="full-height">
          {children}
        </div>
      </div>
    </div>
  );
};

const styles = {
  dropDownHeader: css({
    height: 39,
    width: '100%',
    backgroundColor: THEME.colors.primary,
    padding: '0 0.5rem',
    color: 'white',
    cursor: 'pointer',
  }),
  dropDownContent: (contentHeight: number | string = '60vh') =>
    css(`
    width: 100%;
    height:0;
    background-color: white;
    overflow-y: auto;
    transition: height 0.3s ease-in-out;

    &.open {
        height: ${contentHeight};
        transition: height 0.3s ease-in-out;
    }

    `),

  dropDownIcon: css(`
        transition: transform 0.3s ease-in-out;

        &.open {
            transform: rotate(180deg);
            transition: transform 0.3s ease-in-out;
        }
    `),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {CardLargeIcon} from '../../../assets/svgs/CardIcon';
import {CartIcon} from '../../../assets/svgs/CartIcon';
import {TicketIcon} from '../../../assets/svgs/TicketIcon';
import {THEME} from '../../../styles/theme';
import {useTranslation} from 'react-i18next';
import {IStatGlobal} from '../../../api/fetchStats';
import {helperFormatPrice} from '../../../helpers/helperFormatPrice';
import {M_SCREEN_TABLET} from '../../../constants/ResponsiveSize';
import {helperFormatNumber} from '../../../helpers/helperFormatNumber';
import {MetricBox} from '../../molecules/MetricBox';

export const Footer = ({
  data,
  dataCompare,
}: {
  data: IStatGlobal | undefined;
  dataCompare?: IStatGlobal;
}) => {
  const {t} = useTranslation();
  return (
    <div className="flex-center" css={styles.footer}>
      <MetricBox
        label={t('DAILY_TURNOVER')}
        Icon={CardLargeIcon}
        value={helperFormatPrice(data?.sales?.total || 0)}
        secondValue={
          dataCompare
            ? helperFormatPrice(dataCompare?.sales?.total || 0)
            : undefined
        }
        item="TURNOVER"
        suffix={data?.sales?.suffix || '€'}
      />

      <MetricBox
        label={t('NUMBER_OF_ORDERS')}
        Icon={TicketIcon}
        value={helperFormatNumber(data?.orders?.total || 0)}
        secondValue={
          dataCompare
            ? helperFormatNumber(dataCompare?.orders?.total || 0)
            : undefined
        }
        borderColor={THEME.colors.yellowDark}
        suffix={data?.orders?.suffix || ''}
        item="ORDERS"
      />

      <MetricBox
        label={t('MEDIUM_BASKET')}
        Icon={CartIcon}
        value={helperFormatPrice(data?.averageCart?.total || 0)}
        secondValue={
          dataCompare
            ? helperFormatPrice(dataCompare?.averageCart?.total || 0)
            : undefined
        }
        borderColor={THEME.colors.green}
        suffix={data?.averageCart?.suffix || '€'}
        item="MEDIUM"
      />
    </div>
  );
};

const styles = {
  footer: css({
    marginTop: '1rem',
    columnGap: '2rem',
    padding: '1vh 2vh',
    [M_SCREEN_TABLET]: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      gap: '1rem',
    },
  }),
  footerItem: css({
    cursor: 'pointer',
    [M_SCREEN_TABLET]: {
      width: '100%',
    },
  }),
  footerItemIcon: ({
    backgroundColor,
    borderColor,
  }: {
    backgroundColor?: string;
    borderColor?: string;
  }) =>
    css({
      width: '2.5rem',
      height: '2.5rem',
      backgroundColor,
      border: borderColor ? '1px solid' : 'none',
      borderColor,
      borderRadius: '5px',
    }),
  footerTexts: css({
    marginLeft: '1rem',
  }),
};

import {FilterButton} from '../../../../../components/atoms/FilterButton';
import {useAppSelector} from '../../../../../hooks/useReduxHook';

export const Bottom = () => {
  const dates = useAppSelector(s => s.filter.dates);

  return (
    <>
      <FilterButton
        icon={'calendar'}
        marginRight={'1vh'}
        valueType="dates"
        values={dates}
        hideFilterIcon
        showResetButton
      />
    </>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext, useEffect, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {orderByAction, resetOrderByAction} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
import {MinusIcon} from '../../assets/svgs/MinusIcon';
import {ArrowDownIcon} from '../../assets/svgs/ArrowDown';
import {ArrowUpIcon} from '../../assets/svgs/ArrowUp';
import {TextVariant} from '../atoms/TextVariant';
import {THEME} from '../../styles/theme';
import {ButtonCustom} from '../atoms/ButtonCustom';

export const OrderByFilter = ({
  marginRight,
  withBorder = false,
  label = '',
  icon,
  field,
}: {
  marginRight?: string;
  withBorder?: boolean;
  label?: string;
  icon?: string;
  field: string;
}) => {
  const {t, dispatch} = useContext<any>(UtilsContext);

  const LIST = [
    {
      label: 'Croisant',
      value: 'ASC',
    },
    {
      label: 'Décroissant',
      value: 'DESC',
    },
  ] as const;

  const orderByArray = useAppSelector(state => state.filter.orderBy);

  const [orderBy, setOrderBy] = useState<any>(null);

  const [forceClose, setForceClose] = useState(false);

  const orderByLabel = LIST.find(item => item.value === orderBy?.sort)?.label;
  const selectedLabel = LIST.find(item => item.value === orderBy?.sort)?.label;

  const handleResetFilter = () => {
    dispatch(resetOrderByAction(field));
  };

  const handleSubmit = () => {
    //
    dispatch(
      orderByAction(
        !!orderBy
          ? orderBy
          : {
              field,
              sort: null,
            },
      ),
    );
    setForceClose(true);
  };

  useEffect(() => {
    const selectedOrderBy = orderByArray.find(item => item.field === field);

    if (selectedOrderBy) {
      setOrderBy(selectedOrderBy);
    } else {
      setOrderBy(null);
    }
    return () => {
      setForceClose(false);
    };
  }, [orderByArray]);

  const handleUpdateSort = () => {
    if (!orderBy) {
      setOrderBy({
        field: field,
        sort: LIST[0].value,
      });
    } else if (orderBy.sort === 'ASC') {
      setOrderBy({
        field: field,
        sort: LIST[1].value,
      });
    } else {
      setOrderBy(null);
    }
  };

  return (
    <FilterButton
      marginRight={marginRight}
      label={label}
      valueType="value"
      values={selectedLabel}
      showResetButton={!!selectedLabel}
      onReset={handleResetFilter}
      withBorder={withBorder}
      hideFilterIcon={!!selectedLabel}
      icon={icon}
      forceClose={forceClose}>
      <div css={styles.container}>
        <div className="flex-space-between">
          <TextVariant text={label} variant="textSemiBold" />
          <div css={styles.sortSelectedContainer}>
            <div
              css={[
                styles.sortItem,
                orderBy && {backgroundColor: THEME.colors.primary},
              ]}
              className="flex-space-between"
              onClick={handleUpdateSort}>
              <TextVariant
                text={orderByLabel || 'Par défaut'}
                variant="textSemiBold"
                style={{
                  color: orderBy ? '#fff' : '#A7A7AB',
                }}
              />
              {!!orderBy ? (
                orderBy.sort === 'ASC' ? (
                  <ArrowUpIcon />
                ) : (
                  <ArrowDownIcon />
                )
              ) : (
                <MinusIcon color="#A7A7AB" />
              )}
            </div>
          </div>
        </div>
        <ButtonCustom
          label={t('APPLY')}
          width={'100%'}
          marginTop={'0.5rem'}
          onClick={handleSubmit}
        />
      </div>
    </FilterButton>
  );
};

const styles = {
  container: css({
    minWidth: '14rem',
    maxWidth: 240,
    background: THEME.colors.white,
    top: '0',
    left: '0',
    padding: 12,
    borderRadius: 2,
  }),
  sortSelectedContainer: css({
    position: 'relative',
  }),
  sortItem: css({
    minWidth: '7rem',
    padding: '5px',
    height: 25,
    background: THEME.colors.bgLight,
    cursor: 'pointer',
    borderRadius: 2,
    userSelect: 'none',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import React, {useContext} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {THEME} from '../../styles/theme';
import {TextVariant} from '../atoms/TextVariant';
import {IObject} from '../../redux/filter';
import {CheckboxCustom} from '../atoms/CheckBoxCustom';

export const FilterRadioButtonItem = ({
  entry,
  checked,
  handleCheckedChange,
  isCard,
}: {
  entry: [string, any] | IObject;
  checked: boolean;
  handleCheckedChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isCard?: boolean;
}) => {
  const {t} = useContext<any>(UtilsContext);

  const name = Array.isArray(entry) ? entry[1] : entry.name || entry.label;
  const value = Array.isArray(entry) ? entry[0] : entry.value;

  const handleClick = () => {
    handleCheckedChange({
      target: {
        name,
        value,
      },
    } as any);
  };

  return (
    <label
      className="flex-align-center"
      htmlFor="checked"
      style={{paddingBottom: '0.3rem', cursor: 'pointer'}}
      onClick={handleClick}>
      <CheckboxCustom
        setChecked={() => handleClick}
        type="rounded"
        checked={checked}
        name="checked"
      />
      <TextVariant
        text={t(name)}
        variant="title03"
        style={{
          marginLeft: '0.6rem',
          color: checked ? THEME.colors.primary : '#A7A7AB',
        }}
      />
      {isCard && <span css={styles.pill()} />}
    </label>
  );
};

const styles = {
  label: (checked: boolean) =>
    css({
      fontSize: '10px',
      color: checked ? THEME.colors.primary : '#A7A7AB',
      marginLeft: '0.5rem',
    }),

  checkbox: css({
    width: '0.8rem',
    height: '0.8rem',
    border: `1px solid #A7A7AB`,
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),

  checkboxChecked: css({
    backgroundColor: THEME.colors.primary,
    border: `1px solid ${THEME.colors.primary}`,
  }),

  pill: (color: string = THEME.colors.red) =>
    css({
      display: 'block',
      width: '0.65rem',
      aspectRatio: '1/1',
      borderRadius: '50%',
      backgroundColor: color,
      marginLeft: '0.5rem',
    }),
};

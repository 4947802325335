export const BPosIcon = ({
  size,
  isDisabled = false,
  isActive = false,
}: {
  size: string;
  isDisabled?: boolean;
  isActive?: boolean;
}) => (
  <svg
    width={size === 'small' ? '32' : '48'}
    height={size === 'small' ? '16' : '24'}
    viewBox="0 0 48 24"
    fill="none">
    <path
      d="M7.88177 5.57967C6.9984 5.57967 6.15252 5.73738 5.37061 6.02301L7.13956 9.33594C7.37887 9.28411 7.62701 9.25543 7.88287 9.25543C9.82276 9.25543 11.3965 10.8027 11.3965 12.7117C11.3965 14.6207 9.82387 16.168 7.88287 16.168C5.94188 16.168 4.46297 14.7057 4.37805 12.8705L4.37033 12.8794V6.47297C4.37033 6.47297 4.36482 6.47628 4.36151 6.47738L4.37033 0.5H0.632812V19.8427H4.34055V18.9339C5.38935 19.5118 6.59586 19.8427 7.88177 19.8427C11.8851 19.8427 15.1307 16.65 15.1307 12.7106C15.1307 8.77129 11.8851 5.57967 7.88177 5.57967Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#151A30' : '#3C6AF6'}
    />
    <path
      d="M22.9866 9.43793C23.7123 9.43793 24.3983 9.57578 25.0434 9.85149C25.6886 10.1272 26.2499 10.5033 26.7275 10.9808C27.205 11.4583 27.5811 12.0131 27.8568 12.6439C28.1325 13.2758 28.2703 13.954 28.2703 14.6808C28.2703 15.4076 28.1325 16.1123 27.8568 16.7575C27.5811 17.4026 27.205 17.964 26.7275 18.4415C26.2499 18.919 25.6886 19.2951 25.0434 19.5708C24.3983 19.8465 23.7123 19.9844 22.9866 19.9844C21.9919 19.9844 21.1648 19.7175 20.5064 19.1848V19.165L21.5144 17.3232L21.5342 17.3431C21.897 17.5041 22.2665 17.5846 22.6437 17.5846C23.0208 17.5846 23.4035 17.5107 23.7531 17.3607C24.1027 17.2118 24.4082 17.0056 24.6707 16.7409C24.9332 16.4773 25.1372 16.1685 25.2861 15.8167C25.4338 15.4649 25.5077 15.0856 25.5077 14.6797C25.5077 14.2739 25.4338 13.9188 25.2861 13.5736C25.1383 13.2284 24.9332 12.9273 24.6707 12.6703C24.4082 12.4134 24.1027 12.2105 23.7531 12.0605C23.4035 11.9116 23.0341 11.8366 22.6437 11.8366C22.2533 11.8366 21.8673 11.9105 21.5243 12.0583C21.1813 12.2061 20.8824 12.409 20.6266 12.6648C20.3707 12.9207 20.1689 13.2207 20.0211 13.5636C19.8733 13.9066 19.7994 14.2739 19.7994 14.6643V23.7131H17.0776V9.59894H19.7994V10.9576C20.5251 9.94523 21.5871 9.43793 22.9855 9.43793H22.9866Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M33.9979 9.43811C34.7236 9.43811 35.403 9.57597 36.0349 9.85167C36.6668 10.1274 37.2171 10.5001 37.688 10.9711C38.159 11.442 38.5317 11.9923 38.8074 12.6242C39.0831 13.2561 39.221 13.9278 39.221 14.6402C39.221 15.3526 39.0831 16.0254 38.8074 16.6562C38.5317 17.2881 38.159 17.8395 37.688 18.3093C37.2171 18.7792 36.6668 19.153 36.0349 19.4287C35.403 19.7044 34.7236 19.8423 33.9979 19.8423C33.2723 19.8423 32.5929 19.7044 31.961 19.4287C31.3291 19.153 30.7777 18.7803 30.3078 18.3093C29.8369 17.8384 29.4642 17.2881 29.1885 16.6562C28.9128 16.0243 28.7749 15.3526 28.7749 14.6402C28.7749 13.9278 28.9128 13.2561 29.1885 12.6242C29.4642 11.9923 29.8369 11.442 30.3078 10.9711C30.7788 10.5001 31.3291 10.1274 31.961 9.85167C32.5929 9.57597 33.2712 9.43811 33.9979 9.43811ZM33.9979 17.4436C34.3343 17.4436 34.6464 17.3697 34.9354 17.2219C35.2243 17.0742 35.4802 16.869 35.7018 16.6066C35.9235 16.3441 36.0977 16.0419 36.2257 15.6989C36.3536 15.3559 36.4176 14.9832 36.4176 14.5795C36.4176 14.2035 36.3536 13.8439 36.2257 13.501C36.0977 13.158 35.9235 12.8624 35.7018 12.6132C35.4802 12.365 35.2243 12.1665 34.9354 12.0188C34.6464 11.871 34.3332 11.7971 33.9979 11.7971C33.6627 11.7971 33.3462 11.871 33.0506 12.0188C32.755 12.1665 32.4959 12.365 32.2742 12.6132C32.0525 12.8624 31.8772 13.158 31.7504 13.501C31.6224 13.8439 31.5585 14.2035 31.5585 14.5795C31.5585 14.9832 31.6224 15.3559 31.7504 15.6989C31.8783 16.0419 32.0525 16.3441 32.2742 16.6066C32.4959 16.869 32.755 17.0742 33.0506 17.2219C33.3462 17.3697 33.6627 17.4436 33.9979 17.4436Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M44.3024 13.6929C44.7457 13.8406 45.1527 13.9917 45.5221 14.1461C45.8916 14.3005 46.2147 14.4858 46.4904 14.7009C46.7661 14.9159 46.9812 15.1751 47.1356 15.4773C47.29 15.7794 47.3672 16.139 47.3672 16.5558V16.5757C47.3672 17.598 47.0275 18.3976 46.3493 18.9755C45.6699 19.5533 44.7193 19.8423 43.4962 19.8423C42.7297 19.8423 41.9765 19.7099 41.2376 19.4464C40.4976 19.1817 39.8061 18.8056 39.161 18.3171L39.1411 18.2972L39.161 18.2763L40.5318 16.1114L40.5516 16.1312C40.9553 16.5613 41.4119 16.8878 41.9225 17.1095C42.4331 17.3311 42.9779 17.4425 43.5558 17.4425C44.3487 17.4425 44.7457 17.1878 44.7457 16.6782C44.7457 16.4367 44.6178 16.2559 44.3631 16.1356C44.1072 16.0154 43.805 15.9007 43.4554 15.7938C43.3881 15.7673 43.3242 15.7508 43.2635 15.7441C43.2029 15.7375 43.1389 15.721 43.0716 15.6934C42.668 15.5864 42.2655 15.4684 41.8618 15.3416C41.4582 15.2148 41.0953 15.0394 40.7733 14.8189C40.4502 14.5972 40.1855 14.316 39.9771 13.9741C39.7686 13.6322 39.665 13.1933 39.665 12.6562V12.6165C39.665 12.1467 39.7521 11.7177 39.9274 11.3295C40.1017 10.9402 40.3509 10.6049 40.6741 10.3237C40.9972 10.0425 41.3865 9.8241 41.8442 9.66971C42.3007 9.51531 42.8047 9.43811 43.3562 9.43811C44.0013 9.43811 44.6531 9.53626 45.3126 9.73036C45.971 9.92557 46.5831 10.2112 47.1477 10.5873V10.6281L45.9181 12.8668L45.8982 12.847C45.5883 12.5382 45.2056 12.2923 44.749 12.1114C44.2914 11.9294 43.8083 11.839 43.2977 11.839C43.204 11.839 43.0959 11.8456 42.9746 11.8588C42.8533 11.8721 42.743 11.9063 42.6415 11.9592C42.5412 12.0132 42.4562 12.0838 42.389 12.1709C42.3217 12.2581 42.2886 12.3827 42.2886 12.5437C42.2886 12.665 42.3427 12.7753 42.4496 12.8768C42.5566 12.9771 42.6945 13.072 42.8632 13.1591C43.0308 13.2462 43.2194 13.33 43.4278 13.4116C43.6363 13.4921 43.8414 13.566 44.0432 13.6333C44.084 13.6333 44.127 13.6399 44.1745 13.6532C44.2219 13.6664 44.2649 13.6807 44.3057 13.694L44.3024 13.6929Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
  </svg>
);

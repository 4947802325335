import { SortableListVerticalContainer } from '../../../../../components/organisms/SortableListVerticalContainer';
import { OptionCard } from './OptionCard';

export const OptionsList = ({data, handleDragAndDrop}: any) => {

 

  return (
    <SortableListVerticalContainer
      data={data}
      handleDragAndDrop={handleDragAndDrop}
      ItemComponent={({item})=><OptionCard option={item} showBorder showBadge isRemove />}
    />
  );
};

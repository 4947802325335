/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {columnsZones} from './columns';
import {Bottom} from './HeaderSections/Bottom';
import {Center} from './HeaderSections/Center';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {useAreas} from '../../../../hooks/useAreas';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {areaSelectedAction} from '../../../../redux/myRestaurantSection/areas';
import {deleteAreaModalAction} from '../../../../redux/modals';
import {updateArea} from '../../../../api/fetchAreas';
import {helperDisplayToast} from '../../../../helpers/helperToast';

export const ServiceZones = withRedirectAndNavigation(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const brand = useAppSelector(s => s.brand.brand);
  const {areas, isLoading, refetch} = useAreas({isAll: false});

  const newAreas = areas?.map((area: any) => {
    return {
      ...area,
      onEdit: () => {
        dispatch(areaSelectedAction(area));
        navigate('area/' + area.id);
      },
      onToggle: async () => {
        const res = await updateArea(brand.id, area.id, {
          archived: area?.archived ? 0 : 1,
        });
        if (res.status === 200) {
          helperDisplayToast(res.message, true);
          await refetch();
        }
      },

      onDelete: () => {
        dispatch(areaSelectedAction(area));
        dispatch(deleteAreaModalAction(true));
      },
    };
  });

  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} bottomSection={<Bottom />} />
      <div css={styles.content}>
        <NewDataGrid
          columns={columnsZones}
          rows={newAreas || []}
          rowHeight={60}
          loading={isLoading}
        />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: {
    padding: '2vh',
    height: '87%',
  },
};

import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {usePeriodicalPrices} from '../../../../../hooks/usePeriodicalPrices';
import {columnsEvents} from './columns';

export const Events = () => {
  const {data, isLoading} = usePeriodicalPrices();

  return (
    <NewDataGrid
      columns={columnsEvents}
      rows={data}
      rowHeight={60}
      loading={isLoading}
    />
  );
};

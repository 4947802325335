import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {usePriceRule} from '../../../../../hooks/usePriceRule';
import {columnsPrices} from './columns';

export const Prices = () => {
  const {data, isLoading} = usePriceRule();
  return (
    <NewDataGrid
      columns={columnsPrices}
      rows={data}
      rowHeight={60}
      loading={isLoading}
    />
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../atoms/TextVariant';
import {helperFormatPrice} from '../../helpers/helperFormatPrice';
import {helperGenerateTaxs, helperTaxType} from '../../helpers/helperTax';

export const TicketContentFooter = ({
  data,
  isReceipt = false,
}: {
  data: any;
  isReceipt?: boolean;
}) => {
  const taxs = helperGenerateTaxs(
    data?.amountInclTaxByTax,
    data?.amountExclTaxByTax,
  );

  const taxsValues = taxs?.map(item => item.type);
  return (
    <>
      <div>
        {isReceipt && (
          <>
            <div css={styles.sectionFlex}>
              <TextVariant text={'Total HT'} variant="textRegular" />
              <TextVariant
                text={`${helperFormatPrice(data?.amountExclTax)}€`}
                variant="textRegular"
              />
            </div>

            <div css={styles.sectionFlex}>
              <TextVariant text={'Total TTC'} variant="textSemiBold" />
              <TextVariant
                text={`${helperFormatPrice(data?.amountInclTax)}€`}
                variant="textSemiBold"
              />
            </div>
          </>
        )}
      </div>
      {isReceipt && (
        <>
          <div>
            <TextVariant
              variant="textBold"
              text="CODES TVA"
              style={styles.subTitle}
            />
            {taxs?.map((item: any, index: number) => (
              <div key={index} css={styles.transaction}>
                <TextVariant
                  text={helperTaxType(taxsValues[index])}
                  variant={'title03'}
                  style={[styles.title, {width: '10%'}]}
                />

                <TextVariant
                  text={`${item.type / 100}%`}
                  variant={'title03'}
                  style={[styles.title, {width: '75%'}]}
                />

                <TextVariant
                  text={`${helperFormatPrice(item.value)}€`}
                  variant={'title03'}
                  style={[styles.title, {width: '15%', textAlign: 'right'}]}
                />
              </div>
            ))}
          </div>
          <div>
            <TextVariant
              variant="textBold"
              text="ENCAISSEMENTS"
              style={[styles.subTitle, {marginTop: 5}]}
            />
            {data?.transactions?.map((item: any, index: number) => {
              return (
                <div key={index} css={styles.transaction}>
                  <TextVariant
                    text={item?.paymentMethod?.label}
                    variant={'title03'}
                    style={[styles.title, {width: '75%'}]}
                  />

                  <TextVariant
                    text={`${helperFormatPrice(item.amount)}€`}
                    variant={'title03'}
                    style={[styles.title, {width: '15%', textAlign: 'right'}]}
                  />
                </div>
              );
            })}
          </div>
          <div css={styles.footerInfos}>
            <TextVariant
              text={`bill-app bpos ${data?.header?.posVersion}`}
              variant="textRegular"
            />
          </div>
        </>
      )}
    </>
  );
};

const styles = {
  title: css({
    fontSize: 12,
    fontWeight: '600',
  }),

  subTitle: css({
    paddingLeft: 10,
    marginBottom: 5,
  }),

  footerInfos: css({
    padding: 20,
    textAlign: 'center',
  }),

  transaction: css({
    borderBottom: '1px solid var(--bg-main-color)',
    padding: '10px 10px 10px 20px',
    display: 'flex',
    justifyContent: 'space-between',

    '&:first-of-type': {
      borderTop: '1px solid var(--bg-main-color)',
    },
  }),
  sectionFlex: css({
    padding: '5px 10px 5px 20px',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  headCol: css({
    padding: '5px 15px',
    width: '15%',
  }),
  headerTitle: css({
    fontSize: 10,
    fontWeight: '700',
    color: '#A7A7AB',
  }),
  headerList: css({
    padding: '0px 5px',
    display: 'flex',
    borderBottom: '1px solid var(--bg-main-color)',
    justifyContent: 'space-between',
  }),
  scrollView: ({isReceipt = false}: {isReceipt?: boolean}) =>
    css({
      overflowY: 'scroll',
      overflowX: 'hidden',
      height: isReceipt ? '58%' : '65%',
      '@media print': {
        height: 'auto',
      },
    }),
};

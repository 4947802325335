
export const BDeliveryIcon = ({
  size,
  isDisabled = false,
  isActive = false,
}: {
  size: 'small' | 'medium' | 'large';
  isDisabled?: boolean;
  isActive?: boolean;
}) => (
  <svg     
    width={size === 'small' ? '56' : size === 'medium' ? '90' : '95'}
    height={size === 'small' ? '20' : size ==='medium' ? '25'  : '30'} 
    viewBox="0 0 60 16">
      <path d="M5.58827 4.25097C4.98482 4.25097 4.408 4.35903 3.87379 4.55384L5.08146 6.81546C5.24507 6.78045 5.41401 6.75991 5.58903 6.75991C6.91389 6.75991 7.98763 7.81614 7.98763 9.11969C7.98763 10.4232 6.91389 11.4795 5.58903 11.4795C4.26417 11.4795 3.25436 10.4811 3.19577 9.22851L3.19044 9.2346V4.86127C3.19044 4.86127 3.18663 4.86355 3.18435 4.86432L3.19044 0.783203H0.639648V13.9877H3.17065V13.3675C3.88673 13.7616 4.71011 13.9877 5.58827 13.9877C8.3217 13.9877 10.5369 11.8082 10.5369 9.11893C10.5369 6.42964 8.32093 4.25021 5.58827 4.25021V4.25097Z" fill={isDisabled ? '#CCD6E0' : isActive ? '#151A30' : '#3C6AF6'}/>
      <g clipPath="url(#clip0_9783_14167)">
      <path d="M19.0991 4.41406V13.5926H17.3781V10.3036C17.3888 10.0632 17.346 9.82282 17.2391 9.60428C17.1429 9.38575 17.0146 9.19999 16.8543 9.03609C16.694 8.87219 16.5122 8.75199 16.2878 8.65365C16.074 8.56624 15.8388 8.5116 15.5929 8.5116C15.3471 8.5116 15.1119 8.55531 14.8981 8.65365C14.6843 8.74107 14.5026 8.87219 14.3423 9.03609C14.1819 9.19999 14.0537 9.39667 13.9681 9.61521C13.8719 9.83374 13.8292 10.0741 13.8292 10.3254C13.8292 10.5768 13.8719 10.8172 13.9681 11.0357C14.0644 11.2542 14.1926 11.44 14.3423 11.6039C14.5026 11.7678 14.6843 11.8989 14.8981 11.9863C15.1119 12.0847 15.3471 12.1284 15.5929 12.1284C15.7105 12.1284 15.8281 12.1284 15.9564 12.1065C16.074 12.0847 16.1915 12.0519 16.2878 11.9972H16.3091L16.9398 13.1773H16.9291C16.5122 13.527 15.9991 13.6909 15.3898 13.6909C14.9302 13.6909 14.4919 13.6035 14.0964 13.4287C13.6902 13.2538 13.3482 13.0134 13.0489 12.7075C12.7496 12.4015 12.5144 12.041 12.3327 11.6367C12.1509 11.2324 12.0654 10.7844 12.0654 10.3254C12.0654 9.86652 12.1509 9.42945 12.3327 9.01424C12.5144 8.59902 12.7496 8.24936 13.0489 7.94341C13.3482 7.63746 13.7009 7.39707 14.0964 7.22224C14.5026 7.04742 14.9302 6.96 15.3898 6.96C16.2664 6.96 16.9291 7.2878 17.3888 7.95434V4.41406H19.0991Z" fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}/>
      <path d="M23.0537 6.92694C23.5027 6.92694 23.9303 7.01436 24.3365 7.20011C24.7427 7.37494 25.0847 7.62626 25.384 7.93221C25.6833 8.23816 25.9185 8.59874 26.1002 9.00303C26.2713 9.41825 26.3675 9.85532 26.3675 10.3252C26.3675 10.5765 26.3461 10.8278 26.2926 11.0573V11.0791H23.0217L22.1986 9.74605H24.6358C24.5182 9.37454 24.3151 9.07952 24.0372 8.83913C23.7592 8.59874 23.4279 8.48947 23.0537 8.48947C22.8186 8.48947 22.6048 8.53318 22.4017 8.63152C22.1986 8.72986 22.0169 8.86098 21.8672 9.01396C21.7176 9.17786 21.6 9.36361 21.5145 9.59308C21.429 9.81161 21.3862 10.052 21.3862 10.3142C21.3862 10.5109 21.429 10.7185 21.5038 10.9371C21.5786 11.1556 21.6962 11.3414 21.8352 11.5162C21.9848 11.691 22.1558 11.8331 22.3589 11.9533C22.562 12.0735 22.7972 12.1281 23.0537 12.1281C23.2889 12.1281 23.492 12.0953 23.6844 12.0297C23.8768 11.9642 24.0586 11.8221 24.2296 11.5927H24.2403H24.2617L25.7475 12.2592L25.7261 12.2811C25.4161 12.7181 25.0313 13.0678 24.5503 13.3082C24.0799 13.5595 23.5775 13.6797 23.0431 13.6797C22.5834 13.6797 22.1451 13.5923 21.7389 13.4174C21.3327 13.2426 20.98 13.0022 20.6807 12.6963C20.3814 12.3903 20.1462 12.0297 19.9645 11.6255C19.7828 11.2212 19.6973 10.7732 19.6973 10.3142C19.6973 9.85532 19.7828 9.39639 19.9645 8.9921C20.1355 8.57689 20.3814 8.22723 20.6807 7.92128C20.98 7.61533 21.3327 7.37494 21.7389 7.18919C22.1451 7.01436 22.5834 6.91602 23.0431 6.91602L23.0537 6.92694Z" fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}/>
      <path d="M28.7191 4.41406V13.5926H27.0088V4.41406H28.7191Z" fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}/>
      <path d="M29.4893 4.41406H31.1996V5.94381H29.4893V4.41406ZM29.4893 13.5926V7.03649H31.1996V13.5926H29.4893Z" fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}/>
      <path d="M33.5407 7.04724L35.1975 11.7894L34.0645 12.9804L31.7021 7.02539H33.5407V7.04724ZM37.0575 7.04724H38.8747L36.2451 13.6032H34.7593L35.2831 12.0954L37.0575 7.04724Z" fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}/>
      <path d="M41.7607 6.92694C42.2097 6.92694 42.6373 7.01436 43.0434 7.20011C43.4496 7.37493 43.7917 7.62625 44.091 7.93219C44.3903 8.23814 44.6255 8.59872 44.8072 9.003C44.9782 9.41822 45.0744 9.85528 45.0744 10.3251C45.0744 10.5764 45.053 10.8278 44.9996 11.0572V11.0791H41.7287L40.9056 9.74601H43.3428C43.2252 9.37451 43.0221 9.07949 42.7442 8.8391C42.4662 8.59872 42.1349 8.48945 41.7607 8.48945C41.5256 8.48945 41.3118 8.53316 41.1087 8.6315C40.9056 8.72984 40.7239 8.86096 40.5742 9.01393C40.4246 9.17783 40.307 9.36358 40.2215 9.59304C40.136 9.81157 40.0932 10.052 40.0932 10.3142C40.0932 10.5109 40.136 10.7185 40.2108 10.937C40.2856 11.1556 40.4032 11.3413 40.5422 11.5161C40.6918 11.691 40.8628 11.833 41.0659 11.9532C41.269 12.0734 41.5042 12.128 41.7607 12.128C41.9959 12.128 42.199 12.0952 42.3914 12.0297C42.5838 11.9641 42.7655 11.8221 42.9366 11.5926H42.9472H42.9686L44.4544 12.2591L44.4331 12.281C44.1231 12.7181 43.7383 13.0677 43.2572 13.3081C42.7869 13.5594 42.2845 13.6796 41.75 13.6796C41.2904 13.6796 40.8521 13.5922 40.446 13.4174C40.0398 13.2425 39.687 13.0022 39.3877 12.6962C39.0884 12.3903 38.8532 12.0297 38.6715 11.6254C38.4898 11.2211 38.4043 10.7731 38.4043 10.3142C38.4043 9.85528 38.4898 9.39636 38.6715 8.99208C38.8426 8.57686 39.0884 8.22721 39.3877 7.92127C39.687 7.61532 40.0398 7.37493 40.446 7.18918C40.8521 7.01436 41.2904 6.91602 41.75 6.91602L41.7607 6.92694Z" fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}/>
      <path d="M50.9419 7.29924L50.7923 7.56148L50.1402 8.75248H50.1295C49.8516 8.57766 49.5416 8.49024 49.2209 8.49024C48.9965 8.49024 48.7934 8.52302 48.5903 8.59951C48.3872 8.676 48.2161 8.77434 48.0665 8.91638C47.9168 9.05843 47.7779 9.2114 47.671 9.39715C47.5641 9.58291 47.4893 9.77959 47.4572 9.98719V13.593H45.7148V7.037H47.4572V7.88928C47.9061 7.25553 48.5689 6.92773 49.4454 6.92773C49.9585 6.92773 50.4502 7.04793 50.9312 7.29924H50.9419Z" fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}/>
      <path d="M53.444 12.6952L51.1992 7.03516H53.0378L54.6946 11.8101L53.4547 12.7061L53.444 12.6952ZM58.361 7.03516V7.05701C58.2007 7.4613 58.0617 7.85465 57.9228 8.22616C57.7838 8.60859 57.6556 8.95824 57.538 9.29697C57.1104 10.5208 56.7256 11.5697 56.3942 12.4548C56.0522 13.3289 55.6994 14.0501 55.3146 14.5964C54.9298 15.1646 54.4915 15.5798 54.0105 15.8311C53.5295 16.0824 52.9202 16.2135 52.1933 16.2135H52.1719V14.4325H52.1933C52.4178 14.4325 52.6102 14.4325 52.7919 14.4106C52.9629 14.3888 53.1233 14.356 53.2729 14.3014C53.4226 14.2467 53.5509 14.1703 53.6791 14.061C53.8074 13.9626 53.925 13.8315 54.0319 13.6567C54.1388 13.5037 54.2564 13.307 54.3632 13.0776C54.4701 12.8481 54.5877 12.575 54.7053 12.269C54.8229 11.9521 54.9618 11.5916 55.1008 11.1764C55.2505 10.7611 55.4108 10.2913 55.5925 9.76682C55.7315 9.37346 55.8704 8.95824 56.0308 8.51025C56.1804 8.06226 56.3515 7.58149 56.5332 7.05701L58.3717 7.04608L58.361 7.03516Z" fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}/>
      </g>
      <defs>
      <clipPath id="clip0_9783_14167">
      <rect width="46.2953" height="11.8008" fill="white" transform="translate(12.0654 4.41406)"/>
      </clipPath>
      </defs>
  </svg>    
)
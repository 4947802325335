import {
  ICategoryCreationPayload,
  ICategoryUpdatePayload,
} from '@bill-app-types/b-types';
import {helperLog} from '../helpers/helperLog';
import {helperDisplayToast} from '../helpers/helperToast';
import {axiosInstance} from './axiosInstance';

export type CategoryPayload = {
  label: string;
  showBill: boolean;
  structureUuid: string | null;
  parentCategoryUuid: string | null;
  position: number;
};

export const fetchCategories = async () => {
  helperLog({message: 'fetchCategories'});
  const res: any = await axiosInstance
    .get(`/bcatalog/backoffice/categories`)
    .catch(err => {
      helperLog({message: 'fetchCategories', type: 'API_ERROR', data: err});
    });

  return res?.data?.data;
};

export const createCategory = async (data: ICategoryCreationPayload) => {
  helperLog({message: 'createPosCategory'});
  const res: any = await axiosInstance
    .post(`/bcatalog/backoffice/categories`, data)
    .catch(err => {
      helperDisplayToast(err?.response?.data?.message || 'Error');
    });

  return res;
};

export const updateCategory = async (
  data: Partial<ICategoryUpdatePayload & {isArchived: boolean}>,
  categoryUuid: string,
) => {
  helperLog({message: 'updateCategory'});
  const res: any = await axiosInstance
    .put(`/bcatalog/backoffice/categories/${categoryUuid}`, data)
    .catch(err => {
      helperDisplayToast(err?.response?.data?.message || 'Error');
    });

  return res;
};

export const deleteCategory = async (categoryUuid: string) => {
  helperLog({message: 'deleteCategory'});
  const res: any = await axiosInstance
    .delete(`/bcatalog/backoffice/categories/${categoryUuid}`)
    .catch(err => {
      helperDisplayToast(err?.response?.data?.message || 'Error');
    });

  return res;
};

export const fetchCategoryProducts = async (
  categoryUuid: string,
  search?: string,
) => {
  helperLog({message: 'fetchCategoryProducts'});
  const res: any = await axiosInstance
    .get(
      `/bcatalog/backoffice/categories/${categoryUuid}/items${
        search ? `?search=${search?.toLocaleLowerCase()}` : ''
      }`,
    )
    .catch(err => {
      helperLog({
        message: 'fetchCategoryProducts',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res?.data?.data;
};

export const addCategoryProduct = async (data: {
  categoryUuid: string;
  itemUuid: string;
  type: number;
}) => {
  const res: any = await axiosInstance
    .post(`/bcatalog/backoffice/categories/${data.categoryUuid}/items`, {
      itemUuid: data.itemUuid,
      type: data.type || 1,
      color: '#ffffff',
      position: 1,
    })
    .catch(err => {
      helperDisplayToast(err?.response?.data?.message || 'Error');
    });
  return res;
};

export const updateCategoryProductPosition = async (
  query: {
    categoryUuid: string;
    itemUuid: string;
  },
  data: {
    type: number;
    position: number;
  },
) => {
  const res: any = await axiosInstance
    .put(
      `/bcatalog/backoffice/categories/${query.categoryUuid}/items/${query.itemUuid}`,
      data,
    )
    .catch(err => {
      helperDisplayToast(err?.response?.data?.message || 'Error');
    });
  return res;
};

export const deleteCategoryProduct = async (
  categoryUuid: string,
  itemUuid: string,
) => {
  helperLog({message: 'deleteCategoryProduct'});
  const res: any = await axiosInstance
    .delete(
      `/bcatalog/backoffice/categories/${categoryUuid}/items/${itemUuid}`,
      {
        data: {
          type: 1,
        },
      },
    )
    .catch(err => {
      helperLog({
        message: 'deleteCategoryProduct',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res;
};

export const updateCategoriesPosition = async (categoryUuids: string[]) => {
  const res = await axiosInstance.put(
    '/bcatalog/backoffice/categories/positions',
    {
      categoryUuids,
    },
  );

  return res;
};

export const updateCategoryItemsPosition = async (
  categoryUuid: string,
  items: {uuid: string; type: number; image?: string | null}[],
) => {
  const res = await axiosInstance.put(
    `/bcatalog/backoffice/categories/${categoryUuid}/items/positions`,
    {
      items,
    },
  );

  return res;
};

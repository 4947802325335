/** @jsxImportSource @emotion/react */
import {ModalWrapper} from '../atoms/ModalWrapper';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {useTranslation} from 'react-i18next';
import {viewAreaLocationQRCodeModalAction} from '../../redux/modals';
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {QrCodeCard} from '../molecules/QrCodeCard';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {areaLocationSelectedAction} from '../../redux/myRestaurantSection/areas';
import {helperGenerateQrCodeUrl} from '../../helpers/helperGenerateQrCodeUrl';
import {useBrandsHierarchy} from '../../hooks/useBrandsHierarchy';
import {useRef, useState} from 'react';

export const ViewAreaLocationQRCodeModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const {data: brandHierachy} = useBrandsHierarchy();
  const {areaSelected, areaLocationSelected} = useAppSelector(
    s => s.myRestaurantSection.areas,
  );

  const downloadBtnRef = useRef<HTMLParagraphElement>(null);

  /* ON CANCEL */
  const handleOnCancel = () => {
    console.log('handleOnCancel');
    dispatch(areaLocationSelectedAction(null));
    dispatch(viewAreaLocationQRCodeModalAction(false));
  };

  /* HANDLE SUBMIT */
  const handleDownload = async () => {
    if (!downloadBtnRef.current) return;
    setIsLoading(true);
    await downloadBtnRef.current.click();

    setIsLoading(false);
  };

  if (!areaLocationSelected) return null;

  return (
    <ModalWrapper
      title={t('VIEW_QR_CODE')}
      onCancel={handleOnCancel}
      showBtns={false}>
      <div css={styles.viewAreaLocationQRCodeModalContent}>
        <div css={styles.qrCodeContainer}>
          <QrCodeCard
            width={['65%']}
            areaLocation={{
              ...areaLocationSelected,
              area: areaSelected,
              qr_code_url: helperGenerateQrCodeUrl(
                brandHierachy,
                areaLocationSelected,
              ),
            }}
            downloadBtnRef={downloadBtnRef}
          />
        </div>
        <ButtonCustom
          backgroundColor={THEME.colors.green}
          label={t('DOWNLOAD_AREA_LOCATION_QR_CODE')}
          onClick={handleDownload}
          isLoading={isLoading}
        />
      </div>
    </ModalWrapper>
  );
};

const styles = {
  viewAreaLocationQRCodeModalContent: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  qrCodeContainer: css({
    display: 'flex',
    flexDirection: 'row',
    margin: '30px 0',
    justifyContent: 'center',
    alignItems: 'center',
  }),
};

import {useContext, useEffect, useRef, useState} from 'react';
import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {exportTransactionsCsv} from '../../../../../api/fetchCompta';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {helperDate} from '../../../../../helpers/helperDate';
import {helperFormatPrice} from '../../../../../helpers/helperFormatPrice';
import {CSVLink} from 'react-csv';

export const Center = ({limit}: {limit: number}) => {
  const {t} = useContext<any>(UtilsContext);
  const [csvData, setCsvData] = useState<string[][]>([]);
  const dates = useAppSelector(s => s.filter.dates);
  const cvsRef = useRef<any>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');

  const handleExportExcelData = async () => {
    setIsLoading(true);
    const res = await exportTransactionsCsv({
      createdAtStart: dates?.from,
      createdAtEnd: dates?.to,
      limit,
    });

    if (res?.status === 200) {
      const header = [
        'id',
        'Created Date(UTC)',
        'Amount',
        'Amount Refunded',
        'Currency',
        'Captured',
        'Fee',
        'Status',
        'Card ID',
        'Customer Email',
        'order_id(metadata)',
      ];

      const paymentIntents = res?.data?.data?.paymentIntents?.map(
        (payment: any) => [
          payment.latest_charge,
          helperDate(new Date(payment.created), 'DD/MM/YYYY HH:mm'),
          helperFormatPrice(payment.amount),
          helperFormatPrice(payment.amount_received),
          payment.currency,
          !!payment.amount_capturable ? 'VRAI' : 'FAUX',
          helperFormatPrice(payment.application_fee_amount),
          payment.status === 'succeeded' ? 'Paid' : 'Not Paid',
          payment.payment_method,
          ' ',
          payment.description?.split('n°')[1] || '',
        ],
      );

      setFileName(`export_stripe_${new Date().toISOString()}.csv`);
      setCsvData([header, ...paymentIntents]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (csvData.length > 0 && cvsRef.current) {
      cvsRef.current.link.click();
      setCsvData([]);
    }
  }, [csvData]);

  return (
    <>
      <ButtonCustom
        label={t('EXPORT STRIPE CSV')}
        onClick={handleExportExcelData}
        isLoading={isLoading}
      />

      {csvData.length > 0 ? (
        <CSVLink
          data={csvData}
          filename={fileName || 'export_stripe.csv'}
          ref={cvsRef}
        />
      ) : undefined}
    </>
  );
};

import {SVGProps} from 'react';

export const CopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.0625 0C6.02832 0 5.1875 0.84082 5.1875 1.875V8.4375C5.1875 9.47168 6.02832 10.3125 7.0625 10.3125H13.625C14.6592 10.3125 15.5 9.47168 15.5 8.4375V1.875C15.5 0.84082 14.6592 0 13.625 0H7.0625ZM2.375 4.6875C1.34082 4.6875 0.5 5.52832 0.5 6.5625V13.125C0.5 14.1592 1.34082 15 2.375 15H8.9375C9.97168 15 10.8125 14.1592 10.8125 13.125V11.25H8.9375V13.125H2.375V6.5625H4.25V4.6875H2.375Z"
      fill="#151A30"
    />
  </svg>
);

export const CopyIcon2 = () => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2145_39699)">
      <path
        d="M2.45085 12.2812H8.28418C8.51335 12.2812 8.70085 12.0984 8.70085 11.875V10.25H9.95085V11.875C9.95085 12.7713 9.20345 13.5 8.28418 13.5H2.45085C1.53027 13.5 0.78418 12.7713 0.78418 11.875V6.1875C0.78418 5.29121 1.53027 4.5625 2.45085 4.5625H4.11751V5.78125H2.45085C2.22064 5.78125 2.03418 5.96406 2.03418 6.1875V11.875C2.03418 12.0984 2.22064 12.2812 2.45085 12.2812ZM4.95085 2.125C4.95085 1.22744 5.69824 0.5 6.61751 0.5H12.4508C13.3701 0.5 14.1175 1.22744 14.1175 2.125V7.8125C14.1175 8.70879 13.3701 9.4375 12.4508 9.4375H6.61751C5.69824 9.4375 4.95085 8.70879 4.95085 7.8125V2.125ZM6.61751 8.21875H12.4508C12.68 8.21875 12.8675 8.03594 12.8675 7.8125V2.125C12.8675 1.90055 12.68 1.71875 12.4508 1.71875H6.61751C6.38835 1.71875 6.20085 1.90055 6.20085 2.125V7.8125C6.20085 8.03594 6.38835 8.21875 6.61751 8.21875Z"
        fill="#3C6AF6"
      />
    </g>
    <defs>
      <clipPath id="clip0_2145_39699">
        <rect y="0.5" width="15" height="13" rx="1.31429" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {selectedLanguagesAction} from '../../../../redux/languages';
import {THEME} from '../../../../styles/theme';
import {AxiosResponse} from 'axios';
import {
  deleteBrandLanguage,
  putBrandLanguage,
} from '../../../../api/fetchBrandInfos';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {useState} from 'react';
import {AbsoluteLoader} from '../../../../components/layouts/AbsoluteLoader';

export const LanguageCard = ({language}: {language: any}) => {
  //
  const brand = useAppSelector(s => s.brand.brand);
  const dispatch = useAppDispatch();
  const selectLanguages = useAppSelector(s => s.languages.selectedLanguages);
  const [loading, setLoading] = useState(false);

  const handleSelect = async () => {
    //
    let res: AxiosResponse | void;

    setLoading(true);
    if (selectLanguages.includes(language.id)) {
      res = await deleteBrandLanguage(brand.id, language.id);
    } else {
      res = await putBrandLanguage(brand.id, language.id);
    }
    setLoading(false);

    if (res?.status === 200) {
      helperDisplayToast(res.data.message, true);
      dispatch(selectedLanguagesAction(language.id));
    } else {
      helperDisplayToast(res?.data?.message || 'ERROR', false);
    }
  };

  return (
    <div
      css={[
        styles.container,
        {
          border: selectLanguages.some((item: any) => item === language.id)
            ? `3px solid ${THEME.colors.primary}`
            : 'none',
        },
      ]}
      onClick={handleSelect}>
      <img
        css={styles.image}
        src={language.icon_path}
        onError={({currentTarget}) => {
          currentTarget.onerror = null;
          currentTarget.src =
            'https://t4.ftcdn.net/jpg/04/72/65/73/360_F_472657366_6kV9ztFQ3OkIuBCkjjL8qPmqnuagktXU.jpg';
        }}
        alt="flag language"
      />
      <TextVariant variant="title02" text={language.label} />
      {loading && <AbsoluteLoader />}
    </div>
  );
};

const styles = {
  container: css({
    position: 'relative',
    backgroundColor: THEME.colors.white,
    width: '180px',
    height: '180px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    margin: '20px 10px',
    borderRadius: 2,
    cursor: 'pointer',
  }),
  image: css({
    borderRadius: 200,
    height: '81px',
    width: '81px',
  }),
};

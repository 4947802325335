import {useQuery} from '@tanstack/react-query';
import {getStatGlobal} from '../api/fetchStats';
import {useAppSelector} from './useReduxHook';
import {helperJoinFilterValues} from '../helpers/helperFilter';
import {useFlagBCatalog} from './useFlagBCatalog';

export const useStatGlobal = ({
  dates,
  isEnabled = true,
}: {
  dates?: {
    from: Date | null;
    to: Date | null;
  };
  isEnabled?: boolean;
} = {}) => {
  const datesFilter = useAppSelector(s => s.filter.dates);
  const scale = useAppSelector(s => s.filter.scale);
  const channels = useAppSelector(s => s.filter.channels);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const token = useAppSelector(s => s.auth?.auth?.token);
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {data, isLoading, error} = useQuery(
    [
      'getStatGlobal',
      flagBCatalogEnabled,
      dates,
      channels,
      consumptionModes,
      scale,
      datesFilter,
      token
    ],
    () =>
      getStatGlobal({
        from: (dates ?? datesFilter).from,
        to: (dates ?? datesFilter).to,
        scale: scale,
        channelIds: helperJoinFilterValues(channels, true),
        consumptionModeIds: helperJoinFilterValues(consumptionModes, true),
        forStats: !flagBCatalogEnabled,
      }),
    {
      enabled: !!scale && isEnabled && flagBCatalogReady,
    },
  );

  return {
    data: data,
    isLoading,
    error,
  };
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {Title} from '../../../../components/atoms/Title';
import {InputLabel} from '../../../../components/atoms/InputLabel';

export const Footer = ({
  formikValues,
  formikHandleChange,
}: {
  formikValues: any;
  formikHandleChange: (e: any) => void;
}) => {
  return (
    <div css={styles.container}>
      <Title title="Informations administratives" />
      <InputLabel
        label="Nom de l'entreprise"
        placeholder="Nom de l'entreprise"
        value={formikValues?.legal_entity}
        name="legal_entity"
        onChange={formikHandleChange}
      />
    </div>
  );
};

const styles = {
  container: css(`
    width:33.33%;
    height: 25%;
    @media (max-width: 1024px) {
      width: 100%;
    }
  `),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {PlusIcon} from '../../assets/svgs/PlusIcon';
import {THEME} from '../../styles/theme';

export const ClosedProductsContainer = ({onClick}: {onClick?: () => void}) => {
  return (
    <div css={styles.closedContainer}>
      <button onClick={onClick} css={styles.closedBtn}>
        <PlusIcon />
      </button>
      <div css={styles.closedContent}></div>
    </div>
  );
};

const styles = {
  closedContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }),
  closedBtn: css({
    backgroundColor: THEME.colors.primary,
    border: `1px solid ${THEME.colors.primary}`,
    width: 25,
    height: 25,
    borderRadius: 2,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  closedContent: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 2,
    width: 25,
    height: '100%',
  }),
};

import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {helperResetDate} from '../../helpers/helperDate';
import {CUSTOM_ORDER_BY_FIELDS, SORT_ENUM} from '@bill-app-types/b-types';

export interface IObject {
  name?: string;
  label?: string;
  value: string | number;
  isActive?: boolean;
  childs?: IObject[];
}

type MinMax = {
  min: number | null;
  max: number | null;
  sort?: null | 'ASC' | 'DESC';
};

export type CurrentMetricMode = 'total' | 'average';

export interface IOrderBy {
  field: CUSTOM_ORDER_BY_FIELDS;
  sort: SORT_ENUM;
}

type DateType =
  | null
  | 'equal'
  | 'between'
  | 'period'
  | 'before'
  | 'after'
  | 'inferior'
  | 'superior';

type TDate = {
  type: DateType;
  from: Date;
  to: Date;
  sort: null | 'ASC' | 'DESC';
};

export interface IFilterSlice {
  compare: object[];

  search: string;
  posEntity: string;
  posTerminal: string;

  // LIST
  paymentMethods: IObject[];
  channels: IObject[];
  consumptionModes: IObject[];
  vendorStructure: IObject[];
  paymentStatus: IObject[];
  customerStatus: IObject[];
  groups: IObject[];
  users: IObject[];
  cards: IObject[];
  types: IObject[];
  salesPicker: IObject[];
  parentBarcodes: IObject[];

  // NUMBER
  amount: MinMax;
  reportedPoints: MinMax;
  pointsForFree: MinMax;
  numberOfLocation: MinMax;
  numberOfOrders: MinMax;

  // DATE
  dates: TDate;
  compareDates: {
    type: DateType;
    from: Date | null;
    to: Date | null;
  };
  createdCustomerDates: any;
  lastOrderDates: any;
  birthdate: any;

  scale: null | string;
  optionType: null | number;
  orderBy: IOrderBy[];
  isArchived: null | boolean;
  isOutOfStock: null | boolean;
  isCompare: boolean;
  currentMode: 'TTC' | 'HT';
  currentMetricMode: CurrentMetricMode;
  // BOOLEAN
  userArchived: boolean;
  isSyncPos: null | boolean;
  itemType: null | number;
  customerActive: boolean;
}

const resetMinMaxValue = {
  min: null,
  max: null,
};

const initialState: IFilterSlice = {
  compare: [],
  channels: [],
  consumptionModes: [],
  paymentMethods: [],
  types: [
    {
      name: '1',
      value: '1',
    },
  ],
  search: '',
  posEntity: '',
  posTerminal: '',
  amount: {
    min: null,
    max: null,
  },
  reportedPoints: {
    min: null,
    max: null,
  },
  pointsForFree: {
    min: null,
    max: null,
  },
  numberOfLocation: {
    min: null,
    max: null,
  },
  numberOfOrders: {
    min: null,
    max: null,
  },
  dates: {
    type: 'between',
    from: helperResetDate(new Date()).toDate(),
    to: helperResetDate(new Date(), {
      hour: 23,
      minute: 59,
      second: 59,
    }).toDate(),
    sort: null,
  },
  compareDates: {
    type: 'between',
    from: null,
    to: null,
  },
  createdCustomerDates: {
    type: 'between',
    from: null,
    to: null,
    sort: 'DESC',
  },
  lastOrderDates: {
    type: 'between',
    from: null,
    to: null,
    sort: null,
  },
  birthdate: {
    type: 'between',
    from: null,
    to: null,
    sort: null,
  },
  scale: null,
  paymentStatus: [],
  customerStatus: [],
  groups: [],
  users: [],
  cards: [],
  salesPicker: [],
  vendorStructure: [],
  orderBy: [],
  parentBarcodes: [],
  optionType: null,
  isArchived: null,
  isOutOfStock: null,
  isCompare: false,
  currentMode: 'TTC',
  currentMetricMode: 'total',
  userArchived: false,
  isSyncPos: null,
  itemType: null,
  customerActive: true,
};

const slice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    filterAction: (
      state,
      action: PayloadAction<{
        name: keyof Pick<
          IFilterSlice,
          | 'optionType'
          | 'isOutOfStock'
          | 'search'
          | 'isArchived'
          | 'isSyncPos'
          | 'itemType'
        >;
        values: IFilterSlice[keyof Pick<
          IFilterSlice,
          | 'optionType'
          | 'isOutOfStock'
          | 'search'
          | 'isArchived'
          | 'isSyncPos'
          | 'itemType'
        >];
      }>,
    ) => {
      const {name, values} = action.payload;
      if (name === 'search') {
        state[name] = String(values);
      } else if (
        (name === 'isOutOfStock' ||
          name === 'optionType' ||
          name === 'isSyncPos' ||
          'itemType') &&
        values === null
      ) {
        state[name] = null;
      } else if (
        name === 'isOutOfStock' ||
        name === 'isArchived' ||
        name === 'isSyncPos'
      ) {
        state[name] = Boolean(values);
      } else {
        state[name] = Number(values);
      }
    },
    filterDateAction: (state, action) => {
      const {from, type, to, sort} = action.payload;
      if (from !== undefined) {
        state.dates.from = from;
      }
      if (to !== undefined) {
        state.dates.to = to;
      }
      state.dates.type = type;
      state.dates.sort = sort;
      state.scale = null;
      state.isCompare = !!state.compareDates.from && !!state.compareDates.to;
    },
    filterComparesDateAction: (state, action) => {
      const {from, to} = action.payload;
      if (from !== undefined) {
        state.compareDates.from = from;
      }
      if (to !== undefined) {
        state.compareDates.to = to;
      }

      if (from && to) {
        state.isCompare = true;
      }
    },
    filterCreatedOrderDateAction: (state, action) => {
      const {from, type, to, sort} = action.payload;
      if (from !== undefined) {
        state.createdCustomerDates.from = from;
      }
      if (to !== undefined) {
        state.createdCustomerDates.to = to;
      }
      state.createdCustomerDates.type = type;
      state.createdCustomerDates.sort = sort;
    },
    filterLastOrderDateAction: (state, action) => {
      const {from, type, to, sort} = action.payload;
      if (from !== undefined) {
        state.lastOrderDates.from = from;
      }
      if (to !== undefined) {
        state.lastOrderDates.to = to;
      }
      state.lastOrderDates.type = type;
      state.lastOrderDates.sort = sort;
    },
    filterBirthdateAction: (state, action) => {
      const {from, type, to, sort} = action.payload;
      if (from !== undefined) {
        state.birthdate.from = from;
      }
      if (to !== undefined) {
        state.birthdate.to = to;
      }
      state.birthdate.type = type;
      state.birthdate.sort = sort;
    },
    filterDateScaleAction: (state, action) => {
      state.scale = action.payload;
    },
    initFilterChannelsAction: (state, action) => {
      state.channels = action.payload;
    },

    initFilterConsumptionModeAction: (state, action) => {
      state.consumptionModes = action.payload;
    },

    initFilterPaymentMethodsAction: (state, action) => {
      state.paymentMethods = action.payload;
    },
    filterPosEntityAction: (state, action) => {
      state.posEntity = action.payload;
    },
    filterPosTerminalAction: (state, action) => {
      state.posTerminal = action.payload;
    },
    filterAmountAction: (state, action: PayloadAction<Partial<MinMax>>) => {
      const {sort, min, max} = action.payload;
      state.amount.sort = sort ?? null;
      state.amount.min = min ?? null;
      state.amount.max = max ?? null;
    },
    filterReportedPointsAction: (
      state,
      action: PayloadAction<Partial<MinMax>>,
    ) => {
      const {sort, min, max} = action.payload;
      state.reportedPoints.sort = sort ?? null;
      state.reportedPoints.min = min ?? null;
      state.reportedPoints.max = max ?? null;
    },
    filterPointsForFreeAction: (
      state,
      action: PayloadAction<Partial<MinMax>>,
    ) => {
      const {sort, min, max} = action.payload;
      state.pointsForFree.sort = sort ?? null;
      state.pointsForFree.min = min ?? null;
      state.pointsForFree.max = max ?? null;
    },
    filterNumberOfLocationAction: (
      state,
      action: PayloadAction<Partial<MinMax>>,
    ) => {
      const {sort, min, max} = action.payload;
      state.numberOfLocation.sort = sort ?? null;
      state.numberOfLocation.min = min ?? null;
      state.numberOfLocation.max = max ?? null;
    },
    filterNumberOfOrdersAction: (
      state,
      action: PayloadAction<Partial<MinMax>>,
    ) => {
      const {sort, min, max} = action.payload;
      state.numberOfOrders.sort = sort ?? null;
      state.numberOfOrders.min = min ?? null;
      state.numberOfOrders.max = max ?? null;
    },

    filterVendorStructureAction: (state, action) => {
      state.vendorStructure = action.payload;
    },
    filterParentBarcodesAction: (state, action) => {
      state.parentBarcodes = action.payload;
    },

    paymentStatusAction: (state, action) => {
      state.paymentStatus = action.payload;
    },
    customerStatusAction: (state, action) => {
      state.customerStatus = action.payload;
    },
    usersAction: (state, action) => {
      state.users = action.payload;
    },
    groupsAction: (state, action) => {
      state.groups = action.payload;
    },
    cardsAction: (state, action) => {
      state.cards = action.payload;
    },
    salesPickerAction: (state, action) => {
      state.salesPicker = action.payload;
    },
    userArchivedAction: (state, action) => {
      state.userArchived = action.payload;
    },

    customerActiveAction: (state, action) => {
      state.customerActive = action.payload;
    },

    currentModeAction: (state, action) => {
      state.currentMode = action.payload;
    },

    currentMetricModeAction: (state, action) => {
      state.currentMetricMode = action.payload;
    },

    resetFilterVendorStructureAction(state) {
      state.vendorStructure = [];
    },

    resetSalesPickerAction(state) {
      state.salesPicker = [];
    },

    resetCardsAction(state) {
      state.cards = [];
    },

    resetUsersAction(state) {
      state.users = [];
    },

    resetFilterGroupsAction(state) {
      state.groups = [];
    },

    resetCustomerStatusAction(state) {
      state.customerStatus = [];
    },

    resetPaymentStatusAction(state) {
      state.paymentStatus = [];
    },

    resetFilterAmountAction(state) {
      state.amount = resetMinMaxValue;
    },
    resetFilterChannelsAction(state) {
      state.channels = [];
    },
    resetFilterConsumptionModesAction(state) {
      state.consumptionModes = [];
    },
    resetFilterPaymentMethodsAction(state) {
      state.paymentMethods = [];
    },
    resetFilterReportedPointsAction(state) {
      state.reportedPoints = resetMinMaxValue;
    },
    resetFilterPointsForFreeAction(state) {
      state.pointsForFree = resetMinMaxValue;
    },
    resetFilterNumberOfLocationAction(state) {
      state.numberOfLocation = resetMinMaxValue;
    },
    resetfilterNumberOfOrdersAction(state) {
      state.numberOfOrders = resetMinMaxValue;
    },
    resetFilterDateAction(state) {
      state.dates.type = null;
      state.dates.from = helperResetDate(new Date()).toDate();
      state.dates.to = helperResetDate(new Date(), {
        hour: 23,
        minute: 59,
        second: 59,
      }).toDate();
      state.dates.sort = null;
      state.scale = null;
      state.isCompare = false;
      if (state.compareDates) {
        state.compareDates.from = null;
        state.compareDates.to = null;
      }
    },

    resetFilterCreatedOrderDateAction(state) {
      state.createdCustomerDates.type = null;
      state.createdCustomerDates.from = null;
      state.createdCustomerDates.to = null;
      state.createdCustomerDates.sort = null;
    },

    resetFilterLastOrderDateAction(state) {
      state.lastOrderDates.type = null;
      state.lastOrderDates.from = null;
      state.lastOrderDates.to = null;
      state.lastOrderDates.sort = null;
    },

    resetFilterCompareDatesAction(state) {
      state.isCompare = false;
      state.compareDates.from = null;
      state.compareDates.to = null;
    },

    resetFilterBirthdateAction(state) {
      state.birthdate.type = null;
      state.birthdate.from = null;
      state.birthdate.to = null;
      state.birthdate.sort = null;
    },

    orderByAction(state, action) {
      const orderByIndex = state.orderBy.findIndex(
        (item: IOrderBy) => item.field === action.payload.field,
      );
      if (orderByIndex > -1) {
        if (action.payload.sort === null) {
          state.orderBy.splice(orderByIndex, 1);
        } else {
          state.orderBy[orderByIndex] = action.payload;
        }
      } else {
        state.orderBy.push(action.payload);
      }
    },
    resetOrderByAction(state, action) {
      const orderByIndex = state.orderBy.findIndex(
        (item: IOrderBy) => item.field === action.payload,
      );
      if (orderByIndex > -1) {
        state.orderBy.splice(orderByIndex, 1);
      }
    },
    resetIsArchivedAction(state) {
      state.isArchived = null;
    },
    resetIsOutOfStockAction(state) {
      state.isOutOfStock = null;
    },
    resetParentBarcodesAction(state) {
      state.parentBarcodes = [];
    },
    resetIsSyncPosAction(state) {
      state.isSyncPos = null;
    },
  },
});

export const {
  filterAction,
  filterDateAction,
  filterDateScaleAction,
  filterAmountAction,
  resetFilterAmountAction,
  resetFilterChannelsAction,
  resetFilterConsumptionModesAction,
  resetFilterPaymentMethodsAction,
  initFilterChannelsAction,
  initFilterConsumptionModeAction,
  initFilterPaymentMethodsAction,
  cardsAction,
  usersAction,
  groupsAction,
  customerStatusAction,
  paymentStatusAction,
  filterReportedPointsAction,
  filterPointsForFreeAction,
  filterNumberOfLocationAction,
  filterNumberOfOrdersAction,
  filterParentBarcodesAction,
  filterVendorStructureAction,
  filterCreatedOrderDateAction,
  filterLastOrderDateAction,
  resetFilterReportedPointsAction,
  resetFilterPointsForFreeAction,
  resetFilterNumberOfLocationAction,
  resetfilterNumberOfOrdersAction,
  resetFilterCreatedOrderDateAction,
  resetFilterLastOrderDateAction,
  resetCardsAction,
  resetUsersAction,
  resetCustomerStatusAction,
  resetPaymentStatusAction,
  salesPickerAction,
  resetSalesPickerAction,
  resetFilterDateAction,
  resetFilterVendorStructureAction,
  orderByAction,
  resetOrderByAction,
  resetIsArchivedAction,
  resetIsOutOfStockAction,
  filterComparesDateAction,
  currentModeAction,
  currentMetricModeAction,
  userArchivedAction,
  resetParentBarcodesAction,
  resetFilterCompareDatesAction,
  resetIsSyncPosAction,
  resetFilterGroupsAction,
  customerActiveAction,
  filterBirthdateAction,
  resetFilterBirthdateAction
} = slice.actions;

export default slice.reducer;

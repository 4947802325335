import {BRAND_AUTHORIZATION} from '../../../constants/frontPolicies';
import {brandHasAuthorizationForRouting} from '../../../helpers/helperFrontPolicies';
import {Languages} from './Languages';
import {Translations} from './Translations';

export const MyTranslations = {
  path: 'translations',
  children: [
    {
      path: 'languages',
      element: <Languages />,
      loader: () => brandHasAuthorizationForRouting(BRAND_AUTHORIZATION.CHILD),
    },
    {
      path: 'translations',
      element: <Translations />,
    },
  ],
};

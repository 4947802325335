import {ModalWrapper} from '../atoms/ModalWrapper';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addAreaModalAction} from '../../redux/modals';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {useTranslation} from 'react-i18next';
import {createArea} from '../../api/fetchAreas';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';

export const AddAreaModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const brand = useAppSelector(state => state.brand.brand);
  const clientQuery = useQueryClient();

  const initialValues = {
    label: '',
    location_label: '',
    language_id: 1,
  };

  /* ON CANCEL */
  const handleOnCancel = () => {
    dispatch(addAreaModalAction(false));
  };

  /* HANDLE SUBMIT */
  const handleOnSubmit = async (data: any, {setSubmitting}: any) => {
    setSubmitting(true);

    const res = await createArea(brand.id, data);

    if (res.area_id) {
      helperDisplayToast(t('AREA_ADDED_SUCCESSFULLY'), true);
      dispatch(addAreaModalAction(false));
      clientQuery.invalidateQueries(['fetchAreas']);
    }

    setSubmitting(false);
  };

  return (
    <ModalWrapper
      title={t('ADD_AN_AREA')}
      validateLabel={t('ADD_THE_AREA')}
      cancelLabel={t('CANCEL')}
      onCancel={handleOnCancel}
      onSubmit={handleOnSubmit}
      initialValues={initialValues}>
      <FieldCustomInput
        label={t('AREA_NAME')}
        placeholder={t('AREA_NAME')}
        name="label"
        required
      />
      <FieldCustomInput
        label={t('AREA_LOCATIONS_NAME')}
        placeholder={t('AREA_LOCATIONS_NAME')}
        name="location_label"
        required
      />
      <FieldsRequired />
    </ModalWrapper>
  );
};

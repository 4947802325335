/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useEffect, useRef, useState} from 'react';
import {BrandDropdownIcon} from '../../../../assets/svgs/BrandDropdownIcon';
// import {UtilsContext} from '../../contexts/UtilsContext';
import {useBrandsHierarchy} from '../../../../hooks/useBrandsHierarchy';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {THEME} from '../../../../styles/theme';
import {brandAction} from '../../../../redux/brand';
import {swapToken} from '../../../../api/auth';
import {authAction, frontPoliciesAction} from '../../../../redux/auth';
import {getUserFrontPolicies} from '../../../../helpers/helperFrontPolicies';
import {getBrandInfos} from '../../../../api/fetchBrandInfos';
import {AbsoluteLoader} from '../../../layouts/AbsoluteLoader';
import {useNavigate} from 'react-router-dom';
import {
  CHILD_DEFAULT_SCREEN,
  PARENT_DEFAULT_SCREEN,
} from '../../../../constants/global';
import {sidebarDropdownOpenedAction} from '../../../../redux/sidebar';

export const BrandsSelect = () => {
  const {data} = useBrandsHierarchy();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const brand = useAppSelector(s => s.brand.brand);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const btn: any = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: any) => {
    if (btn.current && !btn.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleOnClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOption = async (item: any) => {
    if (parseInt(item.brandId) === parseInt(brand?.id)) return;
    setIsLoading(true);
    const res = await swapToken(item.brandId);
    if (res?.data?.data?.data?.token) {
      localStorage.setItem('auth_token', res?.data?.data?.data?.token);
      localStorage.setItem(
        'auth_refresh_token',
        res?.data?.data?.data?.refreshToken,
      );
      dispatch(authAction(res?.data?.data?.data));
      const brandInfos = await getBrandInfos(item.brandId);
      dispatch(brandAction(brandInfos));
      const userFrontPolicies = getUserFrontPolicies(
        res?.data?.data?.data?.frontPolicies,
      );
      dispatch(frontPoliciesAction(userFrontPolicies));

      const isMainBrand = res?.data?.data?.data?.isMainBrand;

      navigate(isMainBrand ? PARENT_DEFAULT_SCREEN : CHILD_DEFAULT_SCREEN);

      dispatch(
        sidebarDropdownOpenedAction(
          isMainBrand ? 'Mon restaurant' : 'Tableau de bord',
        ),
      );
    }
    setIsOpen(false);
    setIsLoading(false);
  };

  const BrandItem = ({item}: any) => (
    <>
      <div
        onClick={() => handleClickOption(item)}
        className="title03"
        css={styles.option(parseInt(item.brandId) === parseInt(brand?.id))}>
        {item.label}
      </div>
      <SubBrandItem item={item} />
    </>
  );

  const SubBrandItem = ({item}: any) => {
    if (item.childrenBrand?.length === 0) return <></>;
    return (
      <>
        {item.childrenBrand?.map((subBrand: any) => {
          return <BrandItem item={subBrand} />;
        })}
      </>
    );
  };

  return (
    <>
      <div ref={btn} css={styles.dropdown}>
        <div
          className="flex title03"
          css={styles.dropdownHeader}
          onClick={handleOnClick}>
          {brand?.title}
          <BrandDropdownIcon />
        </div>
        {isOpen && (
          <div css={styles.openedArea}>
            {isLoading && <AbsoluteLoader />}
            {data && (
              <BrandItem handleClickOption={handleClickOption} item={data} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

const styles = {
  dropdown: css({
    fontSize: '10px',
    color: THEME.colors.primary,
    fontWeight: '700',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    gap: 5,
  }),
  dropdownHeader: css({
    alignItems: 'center',
    cursor: 'pointer',
    paddingTop: 7,
    justifyContent: 'space-between',
  }),
  openedArea: css({
    position: 'absolute',
    backgroundColor: THEME.colors.white,
    zIndex: 100,
  }),
  option: (current: boolean) =>
    css({
      backgroundColor: THEME.colors.white,
      color: current ? THEME.colors.primary : THEME.colors.bgDark,
      height: 28,
      lineHeight: '28px',
      padding: '0px 10px',
      opacity: current ? 1 : 0.6,
      cursor: 'pointer',
    }),
};

/** @jsxImportSource @emotion/react  */
import {ImageCustom} from '../../../../components/atoms/ImageCustom';
import {css} from '@emotion/react';
import {helperDate} from '../../../../helpers/helperDate';
import {ToggleButton} from '../../../../components/atoms/ToggleButton';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {helperFormatPrice} from '../../../../helpers/helperFormatPrice';
import {THEME} from '../../../../styles/theme';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {duplicateMenu, updateMenu} from '../../../../api/fetchMenus';
import {helperConfirmDialog} from '../../../../helpers/helperConfirmDialog';
import {deleteItem} from '../../../../api/fetchItems';
import {BRAND_AUTHORIZATION} from '../../../../constants/frontPolicies';
import {DataGridActions} from '../../../../components/newDatagrid/DataGridActions';
import {helperLabelTranslation} from '../../../../helpers/helperLabel';

export const columnsMenus = [
  {
    field: 'icon',
    headerName: '',
    flex: 1,
    renderCell: (row: any) => {
      return (
        <div
          className="flex-align-center"
          style={{
            width: '100%',
            marginLeft: 5,
          }}>
          <div css={styles.productImgContainer}>
            <ImageCustom src={row?.image} alt={row?.label} />
          </div>
        </div>
      );
    },
  },

  {
    field: 'product',
    headerName: 'Produit',
    flex: 1,
    align: 'center',
    headerClassName: 'textRegular',
    valueGetter(row: any) {
      return helperLabelTranslation(row);
    },
  },
  {
    field: 'price',
    headerName: 'Prix',
    flex: 1,
    headerClassName: 'textRegular',
    renderCell(row: any) {
      return (
        <TextVariant
          text={`${helperFormatPrice(row.price)}€`}
          variant="textSemiBold"
          style={{
            color: THEME.colors.primary,
          }}
        />
      );
    },
  },
  {
    field: 'visibility',
    headerName: 'Visibilité',
    flex: 1,
    headerClassName: 'textRegular',
    renderCell: (row: any) => {
      const handleToggleArchived = async () => {
        const res = await updateMenu(row.uuid, {
          isArchived: !row.isArchived,
        });
        if (res.status === 204) {
          helperDisplayToast('Visibilité modifiée', true);
        } else {
          helperDisplayToast(
            res.data?.message || 'Impossible de modifier la visibilité',
            false,
          );
        }
      };
      return (
        <ToggleButton
          active={!row.isArchived}
          onToggle={handleToggleArchived}
        />
      );
    },
  },

  {
    field: 'createdAt',
    headerName: "Date d'ajout",
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter(row: any) {
      return `Le ${helperDate(row.createdAt, 'DD/MM/YYYY')}`;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Date de modification',
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter(row: any) {
      return `Le ${helperDate(row.updatedAt, 'DD/MM/YYYY')}`;
    },
  },

  {
    field: 'actions',
    headerName: '',
    headerClassName: 'textRegular',
    headerAlign: 'right',
    align: 'right',

    renderCell: (row: any) => {
      const handleClickCopy = ({refreshing}: any) => {
        helperConfirmDialog({
          validateLabel: 'Dupliquer',
          onSubmit: async () => {
            const res = await duplicateMenu(row.uuid);
            if (res && res.status === 204) {
              helperDisplayToast('Menu dupliqué', true);
              refreshing('fetchMenus');
            } else {
              helperDisplayToast(
                res.data?.message || 'Impossible de dupliquer le menu',
                false,
              );
            }
          },
        });
      };
      const handleClickDelete = ({refreshing}: any) => {
        helperConfirmDialog({
          onSubmit: async () => {
            const res = await deleteItem(row.uuid);
            if (res && res.status === 204) {
              helperDisplayToast('Menu supprimé', true);
              refreshing('fetchMenus');
            } else {
              helperDisplayToast(
                res.data?.message || 'Impossible de supprimer le menu',
                false,
              );
            }
          },
        });
      };
      return (
        <DataGridActions
          showCopy
          handleClickEdit={row.handleClickEdit}
          handleClickCopy={handleClickCopy}
          handleClickDelete={handleClickDelete}
          authorization={{
            edit: BRAND_AUTHORIZATION.ALL,
            copy: BRAND_AUTHORIZATION.PARENT,
            delete: BRAND_AUTHORIZATION.PARENT,
          }}
        />
      );
    },
  },
];

const styles = {
  productImgContainer: css({
    width: 74,
    height: 74,
    position: 'relative',
    borderRadius: 2,
    padding: '0.25rem',
    border: 'none',
  }),
  productImg: css({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 2,
  }),
  pictureIcon: css({
    position: 'absolute',
    inset: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};

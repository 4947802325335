/** @jsxImportSource @emotion/react */
import {PropsWithChildren} from 'react';
import {css} from '@emotion/react';
import {BillIcon} from '../Icons/BillIcon';
import {LoginAnim} from '../Animations/LoginAnim';
import {PasswordAnim} from '../Animations/PasswordAnim';
import {NewPasswordAnim} from '../Animations/NewPasswordAnim';
import {FormHeader} from '../FormHeader';
import {useTranslation} from 'react-i18next';
import {ButtonCustom} from '../ButtonCustom';
import {M_SCREEN_DESKTOP} from '../../../../constants/ResponsiveSize';

const ANIMATIONS = {
  login: LoginAnim,
  password: PasswordAnim,
  newPassword: NewPasswordAnim,
};
const HEADER = {
  login: {
    title: 'GOOD_TO_SEE_YOU_AGAIN',
    description: 'LOG_IN_YOUR_SPACE',
  },
  password: {
    title: 'PASSWORD_FORGOTTEN',
    description: 'A_LINK_SENT_YOU',
  },

  newPassword: {
    title: 'NEW_PASSWORD',
    description: 'RESET_MY_PASSWORD',
  },
};

const BUTTON_LABEL = {
  login: 'TO_LOG_IN',
  password: 'SEND',
  newPassword: 'VALIDATE',
};

type AuthLayoutProps = PropsWithChildren<{
  animType: 'login' | 'password' | 'newPassword';
  onSubmit?: () => void;
  isSubmitting?: boolean;
}>;

export const AuthLayout = ({
  children,
  animType,
  onSubmit,
  isSubmitting,
}: AuthLayoutProps) => {
  const {t} = useTranslation();

  const Anim = ANIMATIONS[animType];

  return (
    <div css={styles.container}>
      <BillIcon css={styles.billIcon} />
      <div css={styles.blockLeft} className="flex flex-center">
        <Anim />
      </div>

      <div css={styles.middle} />

      <div css={styles.blockRight} className="flex flex-center">
        <form
          css={styles.form}
          className="flex-col flex-center"
          onSubmit={e => {
            e.preventDefault();
            if (onSubmit) {
              onSubmit();
            }
          }}>
          <FormHeader
            title={HEADER[animType].title}
            description={HEADER[animType].description}
          />

          {/* FORM INPUTS */}
          <div className="flex-col-center" css={styles.inputs}>
            {children}
          </div>

          <ButtonCustom
            marginLeft="auto"
            marginRight="auto"
            width="80%"
            type="submit"
            label={t(BUTTON_LABEL[animType])}
            disabled={isSubmitting}
            isLoading={isSubmitting}
          />
        </form>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    position: 'relative',
    display: 'flex',
    background: 'url(./bg.jpg) no-repeat center/cover',
    height: '100dvh',
    width: '100%',
  }),
  billIcon: css({
    position: 'absolute',
    top: '4.37rem',
    left: '4.75rem',
  }),
  blockLeft: css({
    flex: 2,
    backgroundColor: 'white',
    [M_SCREEN_DESKTOP]: {
      display: 'none',
    },
  }),
  middle: css({
    position: 'absolute',
    width: '26.125rem',
    left: '52%',
    top: 0,
    bottom: 0,
    marginLeft: '-2.5rem',
    background:
      'linear-gradient(91deg, #FFF 22.26%, rgba(255, 255, 255, 0.00) 99.15%)',
    zIndex: 1,
    [M_SCREEN_DESKTOP]: {
      display: 'none',
    },
  }),
  blockRight: css({
    flex: 1.8,
    zIndex: 2,
  }),
  form: css({
    width: '85%',
    height: '90%',
    backgroundColor: 'white',
    borderRadius: '2px',
    gap: '3rem',
    boxShadow: '0px 0px 40px 0px rgba(0, 0, 0, 0.12)',
    [M_SCREEN_DESKTOP]: {
      width: '100%',
      height: '100%',
    },
  }),
  inputs: css({
    width: '80%',
    margin: '0 auto',
  }),
};

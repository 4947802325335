import {useContext} from 'react';
import {FilterInput} from '../../../../../../components/atoms/FilterInput';
import {UtilsContext} from '../../../../../../contexts/UtilsContext';
import {useAppDispatch} from '../../../../../../hooks/useReduxHook';
import {filterAction} from '../../../../../../redux/filter';
import {OrderByFilter} from '../../../../../../components/filters/OrderByFilter';

export const CardHeaderBottom = () => {
  const {t} = useContext<any>(UtilsContext);
  const dispatch = useAppDispatch();

  const handleSearch = (e: any) => {
    dispatch(filterAction({name: e.target.name, values: e.target.value}));
  };

  return (
    <>
      <FilterInput
        name="search"
        marginRight={'1vh'}
        placeholder="Rechercher..."
        onChange={handleSearch}
      />
      <OrderByFilter
        label={t('ITEMS')}
        field="articleNumber"
        marginRight={'1vh'}
      />
      <OrderByFilter
        icon={'calendar'}
        label={t('DATE_ADDED')}
        field="createdAt"
        marginRight={'1vh'}
      />
      <OrderByFilter
        icon={'calendar'}
        label={t('MODIFICATION_DATE')}
        field="updatedAt"
      />
    </>
  );
};

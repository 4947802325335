/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {useQuery} from '@tanstack/react-query';
import {
  addModuleSlider,
  deleteModuleSlider,
  fetchModuleSliders,
} from '../../../../api/fetchModuleDetails';
import {memo, useState} from 'react';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {useTranslation} from 'react-i18next';
import {CrossIconContainer} from '../../../../components/atoms/CrossIconContainer';
import {PictureResolution} from '../../../../components/atoms/PictureResolution';
import {AbsoluteLoader} from '../../../../components/layouts/AbsoluteLoader';
import {MoreSquareIcon} from '../../../../assets/svgs/MoreSquareIcon';

export const Sliders = memo(({id}: {id: string}) => {
  const {t} = useTranslation();
  const [sliderLoading, setSliderLoading] = useState(false);
  const brandId = useAppSelector(s => s.brand.brand?.id);

  const moduleSelected = useAppSelector(
    s => s.myModulesSection.subscriptions.moduleSelected,
  );

  const {data: sliders, refetch: refetchSliders} = useQuery(
    ['fetchBWebApp-sliders', brandId, id],
    () =>
      fetchModuleSliders({
        moduleCode: moduleSelected?.code,
        brandId: String(brandId),
        parameterId: String(id),
      }),
    {
      enabled: !!brandId && !!id,
    },
  );

  const handleUploadSliders = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {files} = e.target;

    setSliderLoading(true);

    if (files) {
      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append('image', files[i]);
        formData.append('b_webapp_parameter_id', String(id));
        formData.append('b_dedicated_parameter_id', '0');
        formData.append('b_menu_parameter_id', '0');
        formData.append('b_order_parameter_id', '0');
        formData.append('position', String(Number(sliders?.length) + 1));

        const res = await addModuleSlider(String(brandId), formData);

        if (res?.status === 200) {
          helperDisplayToast(res.message, true);
        }
      }

      await refetchSliders();
    }

    e.target.value = '';

    setSliderLoading(false);
  };

  const handleRemoveSlider = async (id: string) => {
    setSliderLoading(true);
    const res = await deleteModuleSlider(String(brandId), id);
    if (res?.status === 200) {
      helperDisplayToast(res.message, true);
    }
    await refetchSliders();
    setSliderLoading(false);
  };

  return (
    <div css={styles.container}>
      <div>
        <p className="textLabel">{t('HOME_SCREEN_SLIDER')}</p>

        {sliders && (
          <div css={styles.images} className="hide-scroll-bar">
            {sliders?.map(slider => {
              return (
                <div css={styles.slider} key={slider.id}>
                  <img src={slider.image} alt="background" css={styles.img} />
                  <CrossIconContainer
                    onClick={() => handleRemoveSlider(slider.id)}
                  />
                </div>
              );
            })}
          </div>
        )}
        <label
          htmlFor="sliderImage"
          css={styles.sliderContentImage}
          className="flex-center">
          <PictureResolution height={500} width={1920} Icon={MoreSquareIcon} />
        </label>
        <input
          type="file"
          hidden
          id="sliderImage"
          onChange={handleUploadSliders}
          name="slide_image"
          accept="image/*"
          multiple
        />
      </div>
      {sliderLoading && <AbsoluteLoader />}
    </div>
  );
});

const styles = {
  container: css({
    width: '45%',
  }),

  img: css({
    width: 350,
    height: '100%',
    objectFit: 'cover',
    borderRadius: 5,
  }),

  images: css({
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '1rem',
    height: 200,
    marginBottom: '1rem',
    overflowX: 'auto',
    // overflowY: 'hidden',
  }),

  sliderContentImage: css({
    width: '100%',
    height: 150,
    backgroundColor: 'white',
    marginBottom: 10,
  }),

  slider: css({
    position: 'relative',
    '&:hover': {
      '.icon': {
        opacity: 1,
        top: -10,
      },
    },
  }),
};

import {CardList} from './CardList';
import {SelectedCard} from './SelectedCard';

export const MyCardRouter = {
  path: 'cards',
  children: [
    {
      path: '',
      element: <CardList />,
    },
    {
      path: ':uuid',
      element: <SelectedCard />,
    },
  ],
};

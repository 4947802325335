import {axiosInstance} from './axiosInstance';

export const fetchPrintingParameters = async () => {
  const res = await axiosInstance.get('/bcore/protected/admin/b_printing_parameter');

  return res?.data?.data;
};

export const createPrintingParameter = async (data: any) => {
  const res = await axiosInstance.post(
    '/bcore/protected/admin/b_printing_parameter',
    data,
  );

  return res;
};

export const updatePrintingParameter = async (data: any, parameterId: number) => {
  const res = await axiosInstance.put(
    `/bcore/protected/admin/b_printing_parameter/${parameterId}`,
    data,
  );

  return res;
};

export const deletePrintingParameter = async (parameterId: number) => {
  const res = await axiosInstance.delete(
    `/bcore/protected/admin/b_printing_parameter/${parameterId}`,
  );

  return res;
};

/**@jsxImportSource @emotion/react */
import {useContext} from 'react';
import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {THEME} from '../../../../../styles/theme';
import {useNavigate} from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {updateProduct} from '../../../../../api/fetchProducts';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {productPreRegisterAction} from '../../../../../redux/myCatalogSection/products';
import {updateItemImage} from '../../../../../api/fetchImage';
import {useQueryClient} from '@tanstack/react-query';
import {css} from '@emotion/react';

export const HeaderTopButtons = () => {
  const {t} = useContext<any>(UtilsContext);
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();
  const productPreRegister = useAppSelector(
    s => s.myCatalogSection.products.productPreRegister,
  );

  const productPreRegisterSelected = useAppSelector(
    s => s.myCatalogSection.products.productPreRegisterSelected,
  );

  const navigate = useNavigate();

  const handleCancel = () => {
    if (
      JSON.stringify(productPreRegisterSelected) !==
      JSON.stringify(productPreRegister)
    ) {
      const res = window.confirm(t('YOU_HAVE_UNSAVED_CHANGES'));

      if (!res) {
        return;
      }
    }
    dispatch(productPreRegisterAction(null));
    navigate('/online/catalog/products');
  };

  const handleSaveProduct = async () => {
    if (
      productPreRegister?.label &&
      productPreRegister?.backofficeLabel &&
      productPreRegister?.productionLabel &&
      productPreRegister?.description &&
      productPreRegister?.composition
    ) {
      const res = await updateProduct(productPreRegister?.uuid, {
        ...productPreRegister,
        price: Math.round((productPreRegister?.price || 0) * 100),
      });

      if (res?.status === 204) {
        helperDisplayToast('Produit modifié', true);
        navigate('/online/catalog/products');
        dispatch(productPreRegisterAction(null));
        if (productPreRegister?.updatedImage) {
          const res = await updateItemImage(
            productPreRegister?.uuid,
            productPreRegister?.updatedImage?.file,
          );
          if (res?.status === 204) {
            clientQuery.invalidateQueries(['fetchProducts']);
          } else {
            helperDisplayToast(
              res?.data?.message || "Impossible de modifier l'image",
              false,
            );
          }
        }
      } else if (res) {
        helperDisplayToast(
          res?.data?.message || 'Une erreur est survenue',
          false,
        );
      }

      return true;
    } else {
      helperDisplayToast(
        'Tous les champs obligatoires doivent être complétés ',
        false,
      );
    }
  };

  const canSubmit =
    productPreRegister?.label &&
    productPreRegister?.backofficeLabel &&
    productPreRegister?.productionLabel &&
    productPreRegister?.description &&
    productPreRegister?.composition;

  return (
    <div css={styles.container}>
      <ButtonCustom
        label={t('CANCEL')}
        backgroundColor={THEME.colors.red}
        onClick={handleCancel}
      />
      <ButtonCustom
        label={t('SAVE')}
        backgroundColor={THEME.colors.green}
        onClick={handleSaveProduct}
        disabled={!canSubmit}
      />
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '0.7rem',
    width: '100%',
    padding: 0,
  }),
};

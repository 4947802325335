export const EuroIcon = ({color}: {color?: string}) => {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4588_62950)">
        <path
          d="M1.5313 5.75349C1.20778 5.75349 0.945312 5.47716 0.945312 5.13369C0.945312 4.79023 1.20778 4.5139 1.5313 4.5139H2.34386C3.01579 2.12435 5.10825 0.381958 7.58647 0.381958H8.17246C8.49719 0.381958 8.75844 0.659573 8.75844 1.00175C8.75844 1.34393 8.49719 1.62154 8.17246 1.62154H7.58647C5.76504 1.62154 4.20729 2.81981 3.56027 4.5139H7.39115C7.71588 4.5139 7.97713 4.79023 7.97713 5.13369C7.97713 5.47716 7.71588 5.75349 7.39115 5.75349H3.30634C3.29414 5.89036 3.28925 6.02723 3.28925 6.16668C3.28925 6.30613 3.29414 6.443 3.30634 6.57987H7.39115C7.71588 6.57987 7.97713 6.8562 7.97713 7.19967C7.97713 7.54313 7.71588 7.81946 7.39115 7.81946H3.56027C4.20729 9.51356 5.76504 10.7118 7.58647 10.7118H8.17246C8.49719 10.7118 8.75844 10.9881 8.75844 11.3316C8.75844 11.6751 8.49719 11.9514 8.17246 11.9514H7.58647C5.10825 11.9514 3.01579 10.2082 2.34386 7.81946H1.5313C1.20778 7.81946 0.945312 7.54313 0.945312 7.19967C0.945312 6.8562 1.20778 6.57987 1.5313 6.57987H2.13096C2.12192 6.443 2.11728 6.30613 2.11728 6.16668C2.11728 6.02723 2.12192 5.89036 2.13096 5.75349H1.5313Z"
          fill={color || '#3C6AF6'}
        />
      </g>
      <defs>
        <clipPath id="clip0_4588_62950">
          <rect
            width="9.37576"
            height="13.2222"
            fill="white"
            transform="translate(0.164062 -0.444458)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const EuroLargeIcon = ({color}: {color?: string}) => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9053_185525)">
      <path
        d="M2.81933 6.53571C2.36699 6.53571 2 6.22522 2 5.83929C2 5.45335 2.36699 5.14286 2.81933 5.14286H3.95546C4.89496 2.45783 7.82064 0.5 11.2857 0.5H12.105C12.5591 0.5 12.9244 0.811942 12.9244 1.19643C12.9244 1.58092 12.5591 1.89286 12.105 1.89286H11.2857C8.73897 1.89286 6.56093 3.23929 5.65625 5.14286H11.0126C11.4667 5.14286 11.8319 5.45335 11.8319 5.83929C11.8319 6.22522 11.4667 6.53571 11.0126 6.53571H5.30121C5.28414 6.68951 5.27731 6.8433 5.27731 7C5.27731 7.1567 5.28414 7.31049 5.30121 7.46429H11.0126C11.4667 7.46429 11.8319 7.77478 11.8319 8.16071C11.8319 8.54665 11.4667 8.85714 11.0126 8.85714H5.65625C6.56093 10.7607 8.73897 12.1071 11.2857 12.1071H12.105C12.5591 12.1071 12.9244 12.4176 12.9244 12.8036C12.9244 13.1895 12.5591 13.5 12.105 13.5H11.2857C7.82064 13.5 4.89496 11.5413 3.95546 8.85714H2.81933C2.36699 8.85714 2 8.54665 2 8.16071C2 7.77478 2.36699 7.46429 2.81933 7.46429H3.65777C3.64514 7.31049 3.63866 7.1567 3.63866 7C3.63866 6.8433 3.64514 6.68951 3.65777 6.53571H2.81933Z"
        fill={color || '#3C6AF6'}
      />
    </g>
    <defs>
      <clipPath id="clip0_9053_185525">
        <rect y="0.5" width="15" height="13" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const EuroSmallIcon = ({color}: {color?: string}) => (
  <svg
    width="8"
    height="10"
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.19179 4.67857C0.92039 4.67857 0.700195 4.46362 0.700195 4.19643C0.700195 3.92924 0.92039 3.71429 1.19179 3.71429H1.87347C2.43717 1.85542 4.19258 0.5 6.27162 0.5H6.76322C7.03565 0.5 7.25482 0.71596 7.25482 0.982143C7.25482 1.24833 7.03565 1.46429 6.76322 1.46429H6.27162C4.74358 1.46429 3.43675 2.39643 2.89395 3.71429H6.10776C6.38019 3.71429 6.59936 3.92924 6.59936 4.19643C6.59936 4.46362 6.38019 4.67857 6.10776 4.67857H2.68092C2.67068 4.78504 2.66658 4.89152 2.66658 5C2.66658 5.10848 2.67068 5.21496 2.68092 5.32143H6.10776C6.38019 5.32143 6.59936 5.53638 6.59936 5.80357C6.59936 6.07076 6.38019 6.28571 6.10776 6.28571H2.89395C3.43675 7.60357 4.74358 8.53571 6.27162 8.53571H6.76322C7.03565 8.53571 7.25482 8.75067 7.25482 9.01786C7.25482 9.28504 7.03565 9.5 6.76322 9.5H6.27162C4.19258 9.5 2.43717 8.14397 1.87347 6.28571H1.19179C0.92039 6.28571 0.700195 6.07076 0.700195 5.80357C0.700195 5.53638 0.92039 5.32143 1.19179 5.32143H1.69486C1.68728 5.21496 1.68339 5.10848 1.68339 5C1.68339 4.89152 1.68728 4.78504 1.69486 4.67857H1.19179Z"
      fill="white"
    />
  </svg>
);

import {redirect} from 'react-router-dom';
import {ForgetPassword} from '../views/Offline/ForgetPassword';
import {InitPassword} from '../views/Offline/InitPassword';
import {Login} from '../views/Offline/Login';
import {DEFAULT_CONNECTED_SCREEN} from '../constants/global';

export const OfflineRouter = {
  path: '',
  loader: async () => {
    const token = localStorage.getItem('auth_token');
    const refreshToken = localStorage.getItem('auth_refresh_token');

    if (token && refreshToken) {
      return redirect(DEFAULT_CONNECTED_SCREEN);
    }
    return null;
  },
  children: [
    {
      path: '',
      element: <Login />,
    },
    {
      path: 'forget-password',
      element: <ForgetPassword />,
    },
    {
      path: 'init-password',
      element: <InitPassword />,
    },
  ],
};

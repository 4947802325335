const helperFormatHoursToInt = (time: string) => {
  const parts = time.split(':');
  const hours = parseInt(parts[0]);
  const minutes = parseInt(parts[1]);
  return hours * 100 + minutes;
};

const helperFormatTime = (time: string) => {
  return !time ? '00' : ('0' + time).slice(-2);
};

const helperFormatHoursToString = (time: string) => {
  const hoursString = time.toString();
  const hours = helperFormatTime(hoursString.substr(0, hoursString.length - 2));
  const minutes = helperFormatTime(hoursString.substr(-2));
  return hours + ':' + minutes;
};

export const helperFormatDays = (days: any) => {
  const transformedData: any = {};

  days.forEach((day: any) => {
    const id = day.dates[0].id;
    transformedData[id] = [];

    day.dates.forEach((date: any) => {
      if (date.start && date.end) {
        const start = helperFormatHoursToInt(date.start);
        const end = helperFormatHoursToInt(date.end);

        transformedData[id].push({start, end});
      }
    });
  });

  return transformedData;
};

export const helperFormatTimingsToDays = (days: any, timings: any) => {
  days.forEach((day: any, index: number) => {
    const id = day.dates[0].id;
    days[index].dates =
      timings[id]?.length > 0
        ? timings[id].map((item: any) => ({
            id,
            start: helperFormatHoursToString(item.start),
            end: helperFormatHoursToString(item.end),
          }))
        : [{id, start: '', end: ''}];
  });
  return days;
};

import {SVGProps} from 'react';

export const OptionsOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={68}
    height={68}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect width={68} height={68} rx={2} fill="#EEF1F6" />
    <path
      d="M48.875 17H59.5m-51 0h31.875m8.5 34H59.5m-51 0h31.875m-12.75-17H59.5m-51 0h10.625M44.625 21.25a4.25 4.25 0 1 0 0-8.5 4.25 4.25 0 0 0 0 8.5ZM23.375 38.25a4.25 4.25 0 1 0 0-8.5 4.25 4.25 0 0 0 0 8.5ZM44.625 55.25a4.25 4.25 0 1 0 0-8.5 4.25 4.25 0 0 0 0 8.5Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SmallOptionsOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={35}
    height={35}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect width={35} height={35} rx={2} fill="#EEF1F6" />
    <path
      d="M25.156 8.75h5.469m-26.25 0h16.406m4.375 17.5h5.469m-26.25 0h16.406M14.22 17.5h16.406m-26.25 0h5.469M22.969 10.938a2.187 2.187 0 1 0 0-4.375 2.187 2.187 0 0 0 0 4.375ZM12.031 19.688a2.188 2.188 0 1 0 0-4.376 2.188 2.188 0 0 0 0 4.376ZM22.969 28.438a2.187 2.187 0 1 0 0-4.375 2.187 2.187 0 0 0 0 4.375Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TicketContent} from './TicketContent';
import {Loader} from '../../../../../../components/atoms/Loader';
import {TicketHeader} from './TicketHeader';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {useQuery} from '@tanstack/react-query';
import {fetchSqsReceiptDetail} from '../../../../../../api/fetchSqsHistory';

export const ReceiptTicket = () => {
  //
  const selectedReceipt = useAppSelector(
    s => s.myAccountingSection.archive.selectedArchiveReceipt,
  );

  const {data: receiptDetails, isLoading} = useQuery(
    ['receiptDetails', selectedReceipt?.receipt_id],
    () =>
      selectedReceipt
        ? fetchSqsReceiptDetail(selectedReceipt?.receipt_id)
        : null,
    {
      enabled: !!selectedReceipt?.receipt_id,
    },
  );

  return (
    <div css={styles.ticket}>
      {isLoading && (
        <div className="flex-center" css={styles.loaderContainer}>
          <Loader />
        </div>
      )}
      {!isLoading && (
        <>
          {<TicketHeader receiptDetails={receiptDetails} />}

          <TicketContent receiptDetails={receiptDetails} />
        </>
      )}
    </div>
  );
};

const styles = {
  ticket: css({
    backgroundColor: 'var(--bg-color)',
    fontSize: '20px',
    height: '80%',
    marginLeft: 10,
    width: '40vh',
    color: 'var(--text-color)',
  }),
  loaderContainer: css({
    flex: 1,
    height: '91%',
  }),
};

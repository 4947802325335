import {useQuery} from '@tanstack/react-query';
import {getItemCards} from '../api/fetchItems';

export const useItemCard = (itemUuid: string) => {

  const {data, isLoading, error} = useQuery(['getItemCards'], () =>
    getItemCards(itemUuid),
    {
      enabled: !!itemUuid
    }
  );


  return {
    data,
    isLoading,
    error,
  };
};

import {CHANNELS} from '@bill-app-types/b-types';
import {helperFormatPrice} from './helperFormatPrice';
import {helperObjectToArray} from './helperObjectToArray';
import {CONSUMPTION_MODE} from '../constants/global';

export const helperGenerateDayDetailData = (data: any) => {
  const datas = [
    {
      title: "Chiffre d'affaires",
      header: ['Montant HT', 'Montant TVA', 'Montant TTC'],
      rows: data?.totalAmount
        ? [data?.totalAmount]?.map(item => ({
            HT: `${helperFormatPrice(item?.HT, 'en')}`,
            TVA: `${helperFormatPrice(item?.TVA, 'en')}`,
            TTC: `${helperFormatPrice(item?.TTC, 'en')}`,
          }))
        : [],
      sizes: [2, 2, 1],
    },
    {
      title: "Chiffre d'affaires par taux de TVA",
      header: ['Taux de TVA', 'Montant HT', 'Montant TVA', 'Montant TTC'],
      rows: data?.totalAndTotalByTva
        ? helperObjectToArray(data?.totalAndTotalByTva, 'rate')?.map(
            (item: any) => ({
              rate: `${item?.rate / 100}`,
              HT: `${helperFormatPrice(item?.HT, 'en')}`,
              TVA: `${helperFormatPrice(item?.TVA, 'en')}`,
              TTC: `${helperFormatPrice(item?.TTC, 'en')}`,
            }),
          )
        : [],
      sizes: [1, 1, 1, 1],
    },
    {
      title: "Chiffre d'affaires par mode de règlement",
      header: ['Mode', 'Montant TTC'],
      rows: data?.totalsByPaymentMethod
        ? helperObjectToArray(data?.totalsByPaymentMethod, 'payment')?.map(
            (item: any) => ({
              label: item?.paymentMethod?.label,
              totalTTC: `${helperFormatPrice(item?.totalTTC)}`,
            }),
          )
        : [],
    },
    {
      title: "Chiffre d'affaires par canal de commande",
      header: ['Canal', 'Montant HT', 'Montant TVA', 'Montant TTC'],
      rows: data?.totalsByChannel
        ? helperObjectToArray(data?.totalsByChannel, 'channel')?.map(
            (item: any) => ({
              channel: CHANNELS[parseInt(item?.channel)],
              HT: `${helperFormatPrice(item?.HT, 'en')}`,
              TVA: `${helperFormatPrice(item?.TVA, 'en')}`,
              TTC: `${helperFormatPrice(item?.TTC, 'en')}`,
            }),
          )
        : [],
      sizes: [1, 1, 1, 1],
    },

    {
      title: "Chiffre d'affaires par mode de consommation",
      header: ['Mode', 'Montant HT', 'Montant TVA', 'Montant TTC'],
      rows: data?.totalsByConsumption
        ? helperObjectToArray(data?.totalsByConsumption, 'consumption')?.map(
            (item: any) => ({
              consumption: CONSUMPTION_MODE[parseInt(item?.consumption)],
              HT: `${helperFormatPrice(item?.HT, 'en')}`,
              TVA: `${helperFormatPrice(item?.TVA, 'en')}`,
              TTC: `${helperFormatPrice(item?.TTC, 'en')}`,
            }),
          )
        : [],
      sizes: [1, 1, 1, 1],
    },

    {
      title: 'Annulations',
      header: ['NB commande', 'Montant HT', 'Montant TVA', 'Montant TTC'],
      rows: data?.totalsCanceledOrders
        ? [data?.totalsCanceledOrders]?.map(item => ({
            nbOrder: `${item?.nbOrders}`,
            HT: `${helperFormatPrice(item?.HT, 'en')}`,
            TVA: `${helperFormatPrice(item?.TVA, 'en')}`,
            TTC: `${helperFormatPrice(item?.TTC, 'en')}`,
          }))
        : [],
      sizes: [1, 1, 1, 1],
    },
    {
      title: 'Offerts',
      header: ['NB commande', 'Montant HT', 'Montant TVA', 'Montant TTC'],
      rows: data?.totalsOfferedOrders
        ? [data?.totalsOfferedOrders]?.map(item => ({
            nbOrder: `${item?.nbOrders}`,
            HT: `${helperFormatPrice(item?.HT, 'en')}`,
            TVA: `${helperFormatPrice(item?.TVA, 'en')}`,
            TTC: `${helperFormatPrice(item?.TTC, 'en')}`,
          }))
        : [],
      sizes: [1, 1, 1, 1],
    },
    {
      title: 'Remises',
      header: ['NB commande', 'Montant HT', 'Montant TVA', 'Montant TTC'],
      rows: data?.totalsDiscountOrders
        ? [data?.totalsDiscountOrders]?.map(item => ({
            nbOrder: `${item?.nbOrders}`,
            HT: `${helperFormatPrice(item?.HT, 'en')}`,
            TVA: `${helperFormatPrice(item?.TVA, 'en')}`,
            TTC: `${helperFormatPrice(item?.TTC, 'en')}`,
          }))
        : [],

      sizes: [1, 1, 1, 1],
    },
  ];

  return datas;
};

/** @jsxImportSource @emotion/react  */
import {css} from '@emotion/react';
import {OptionsOutlineIcon} from '../../../../../../assets/svgs/OptionsOutline';
import {ToggleButton} from '../../../../../../components/atoms/ToggleButton';
import {THEME} from '../../../../../../styles/theme';
import {
  deleteCatalogOptionCategory,
  duplicateCatalogOptionCategory,
} from '../../../../../../api/fetchCatalogOptions';
import {helperDisplayToast} from '../../../../../../helpers/helperToast';
import {helperConfirmDialog} from '../../../../../../helpers/helperConfirmDialog';
import {updateCatalogModifier} from '../../../../../../api/fetchItems';
import {helperLabelTranslation} from '../../../../../../helpers/helperLabel';
import {helperDate} from '../../../../../../helpers/helperDate';
import {OPTION_TYPES} from '../../../../../../constants/global';
import {BRAND_AUTHORIZATION} from '../../../../../../constants/frontPolicies';
import {DataGridActions} from '../../../../../../components/newDatagrid/DataGridActions';
import {DataGridColumn} from '../../../../../../components/newDatagrid/NewDataGrid';
import {IModifierGetReturn} from '@bill-app-types/b-types';

export const columnsOptionsProducts: DataGridColumn<IModifierGetReturn> = [
  {
    field: 'icon',
    headerName: '',
    headerClassName: 'textRegular',
    renderCell: () => {
      return (
        <div
          className="flex-align-center"
          style={{
            width: '100%',
            paddingLeft: 10,
          }}>
          <OptionsOutlineIcon />
        </div>
      );
    },
  },
  {
    field: 'label',
    headerName: 'Produit',
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter: row => {
      return helperLabelTranslation(row);
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    headerClassName: 'textRegular',
    flex: 1,
    renderCell: row => {
      return (
        <p css={styles.optionType}>
          {OPTION_TYPES?.find(item => item.value === row.optionType)?.label}
        </p>
      );
    },
  },
  {
    field: 'totalItems',
    headerName: 'Options',
    headerClassName: 'textRegular',
    flex: 1,
  },
  {
    field: '',
    headerName: 'Visibilité',
    headerClassName: 'textRegular',
    renderCell: row => {
      const handleToogleArchived = async () => {
        const res = await updateCatalogModifier(row.uuid, {
          isArchived: !row.isArchived,
        });
        if (res.status === 204) {
          helperDisplayToast('Visibilité modifiée', true);
        } else {
          helperDisplayToast(
            res.data?.message || 'Impossible de modifier la visibilité',
            false,
          );
        }
      };

      return (
        <ToggleButton
          onToggle={handleToogleArchived}
          active={!row.isArchived}
        />
      );
    },
  },
  {
    field: 'createdAt',
    headerName: "Date d'ajout",
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter: row => {
      return helperDate(row.createdAt, 'DD/MM/YYYY');
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Date de modification',
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter: row => {
      return helperDate(row.updatedAt, 'DD/MM/YYYY');
    },
  },
  {
    field: 'actions',
    headerName: '',
    headerAlign: 'right',
    align: 'right',
    headerClassName: 'textRegular',
    renderCell: row => {
      const handleClickCopy = ({refreshing}: any) => {
        helperConfirmDialog({
          validateLabel: 'Dupliquer',
          onSubmit: async () => {
            const res = await duplicateCatalogOptionCategory(row.uuid);
            if (res && res.status === 204) {
              helperDisplayToast('Catégorie dupliquée', true);
              refreshing('getCatalogOptionCategories');
            } else {
              helperDisplayToast(
                res?.data?.message || 'Impossible de dupliquer la catégorie',
                false,
              );
            }
          },
        });
      };

      const handleClickDelete = ({refreshing}: any) => {
        helperConfirmDialog({
          onSubmit: async () => {
            const res = await deleteCatalogOptionCategory(row.uuid);
            if (res && res.status === 204) {
              helperDisplayToast('Catégorie supprimée', true);
              refreshing('getCatalogOptionCategories');
            } else {
              helperDisplayToast(
                res.data?.message || 'Impossible de supprimer la catégorie',
                false,
              );
            }
          },
        });
      };

      const handleClickEdit = ({navigate}: any) => {
        navigate(`/online/catalog/options/${row.uuid}`);
      };

      return (
        <DataGridActions
          handleClickCopy={handleClickCopy}
          handleClickDelete={handleClickDelete}
          handleClickEdit={handleClickEdit}
          showCopy
          authorization={{
            edit: BRAND_AUTHORIZATION.ALL,
            copy: BRAND_AUTHORIZATION.PARENT,
            delete: BRAND_AUTHORIZATION.PARENT,
          }}
        />
      );
    },
  },
];

const styles = {
  optionType: css({
    color: THEME.colors.primary,
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {CloseIcon} from '../../assets/svgs/CloseIcon';
import {THEME} from '../../styles/theme';
import {ButtonCustom} from './ButtonCustom';
import {Formik} from 'formik';
import React, {useEffect} from 'react';
import {TextVariant} from './TextVariant';
import {useAppSelector} from '../../hooks/useReduxHook';

interface IProps {
  children: React.ReactNode;
  maxWidth?: number | string;
  maxHeight?: number | string;
  minHeight?: number | string;
  minWidth?: number | string;
  title?: any;
  subTitle?: string;
  cancelLabel?: string;
  removeLabel?: string;
  validateLabel?: string;
  onCancel: any;
  onRemove?: () => void;
  onSave?: any;
  saveLabel?: string;
  initialValues?: any;
  validate?: (values: any) => void;
  onSubmit?: any;
  showBtns?: boolean;
  titleMarginBottom?: string | number;
  hideCancelButton?: boolean;
  btnCancelWidth?: string | number;
  btnValidateWidth?: string | number;
  btnSaveWidth?: string | number;
  btnCancelBgColor?: string;
  onClose?: () => void;
  disabled?: boolean;
  showMiniBtns?: boolean;
  isLoading?: boolean;
  validateSchema?: any;
  isSaving?: boolean;
}

export const ModalWrapper = ({
  children,
  maxWidth = '100vw',
  maxHeight = '100vh',
  minHeight = '100px',
  minWidth = '400px',
  title,
  subTitle,
  cancelLabel,
  validateLabel = 'Valider',
  removeLabel = 'Supprimer',
  onCancel,
  onSave,
  saveLabel = 'Sauvegarder',
  initialValues = {},
  validate,
  onRemove,
  onClose,
  onSubmit,
  showBtns = true,
  titleMarginBottom,
  hideCancelButton = false,
  btnCancelWidth = '48%',
  btnValidateWidth = '48%',
  btnSaveWidth = '48%',
  btnCancelBgColor = THEME.colors.red,
  disabled = false,
  showMiniBtns = false,
  isLoading = false,
  validateSchema,
  isSaving,
}: IProps) => {
  const {t} = useTranslation();
  const isOpen = useAppSelector(s => s.sidebar.isOpen);

  useEffect(() => {
    const closeOnEscapePressed = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onCancel();
    };
    window.addEventListener('keydown', closeOnEscapePressed);
    return () => window.removeEventListener('keydown', closeOnEscapePressed);
  }, []);

  return (
    <div css={[styles.container, {paddingLeft: isOpen ? 190 : 50}]}>
      <div
        css={[
          styles.children,
          {
            maxWidth,
            maxHeight,
            minWidth,
            minHeight,
          },
        ]}>
        <button css={styles.btnClose} onClick={onClose || onCancel}>
          <CloseIcon />
        </button>
        {(title || subTitle) && (
          <div
            css={[
              styles.headerTitle,
              {
                marginBottom: titleMarginBottom,
              },
            ]}>
            {!!title && (
              <TextVariant
                variant="title02"
                text={title}
                style={styles.title}
              />
            )}

            {!!subTitle && (
              <TextVariant
                variant="textRegular"
                text={subTitle}
                style={css({
                  color: THEME.colors.gray2,
                })}
              />
            )}
          </div>
        )}
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
          validationSchema={validateSchema}>
          {({isSubmitting, handleSubmit}) => {
            return (
              <form
                onSubmit={handleSubmit}
                css={showMiniBtns ? styles.content : {}}>
                <div>{children}</div>
                {showBtns && (
                  <div
                    className="flex-space-between"
                    style={{
                      marginTop: 20,
                    }}>
                    {!hideCancelButton && (
                      <ButtonCustom
                        label={cancelLabel || t('CANCEL_CREATE')}
                        backgroundColor={btnCancelBgColor}
                        width={btnCancelWidth}
                        onClick={onCancel}
                      />
                    )}
                    {onSave && (
                      <ButtonCustom
                        label={saveLabel}
                        backgroundColor={THEME.colors.primary}
                        width={btnSaveWidth || btnValidateWidth}
                        onClick={onSave}
                        isLoading={isSaving}
                        disabled={isSaving || disabled}
                      />
                    )}
                    <ButtonCustom
                      label={validateLabel}
                      backgroundColor={THEME.colors.green}
                      width={btnValidateWidth}
                      padding={'0'}
                      disabled={isSubmitting || isLoading || disabled}
                      isLoading={isSubmitting || isLoading}
                      type={'submit'}
                    />
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
        {onRemove && (
          <div style={{marginTop: 10}}>
            <ButtonCustom
              onClick={onRemove}
              label={removeLabel}
              backgroundColor={THEME.colors.red}
              width="100%"
              padding={'0'}
            />
          </div>
        )}

        {!showBtns && showMiniBtns && (
          <div className="flex-center" css={styles.btns}>
            <ButtonCustom
              label={cancelLabel}
              backgroundColor={THEME.colors.red}
              onClick={onCancel}
            />
            <ButtonCustom
              label={validateLabel}
              backgroundColor={THEME.colors.green}
              onClick={onSave}
              isLoading={isLoading}
              disabled={isLoading || disabled}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(21, 26, 48, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    transitionDuration: '0.5s',
  }),
  content: css({
    flex: 1,
    overflowY: 'auto',
    margin: '0.5rem 0',
  }),
  headerTitle: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  children: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    justifyContent: 'space-between',
    backgroundColor: THEME.colors.bgLight,
    minHeight: '300px',
    minWidth: '300px',
    borderRadius: 2,
  }),
  btnClose: css({
    alignSelf: 'flex-end',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  }),
  title: css({
    textAlign: 'center',
    whiteSpace: 'pre-line',
    // marginBottom: '40px',
    lineHeight: '24px',
  }),
  btn: css({
    width: '48%',
    height: 28,
    color: 'white',
    border: 'none',
    borderRadius: 2,
    textTransform: 'uppercase',
  }),
  btns: css({
    gap: '0.5rem',
    marginTop: '1rem',
  }),
};

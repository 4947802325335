import {useQuery} from '@tanstack/react-query';
import {getStatByCard} from '../api/fetchStats';
import {helperGetStatColorById} from '../helpers/helperStats';
import {useAppSelector} from './useReduxHook';
import {useFlagBCatalog} from './useFlagBCatalog';

export const useStatCard = ({
  dates,
  enabled = true,
}: {
  dates?: {
    from: Date | null;
    to: Date | null;
  };
  enabled?: boolean;
}) => {
  const datesFilter = useAppSelector(s => s.filter.dates);
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {data: stats, isLoading} = useQuery(
    ['getStatByCard', flagBCatalogEnabled, dates],
    () =>
      getStatByCard({
        from: (dates || datesFilter).from,
        to: (dates || datesFilter).to,
        forStats: !flagBCatalogEnabled,
        limit: 100,
      }),
    {
      enabled: enabled && !!flagBCatalogReady,
    },
  );

  const data =
    stats && Array.isArray(stats)
      ? stats?.map((category, index) => ({
          ...category,
          color: helperGetStatColorById(index),
          name: category?.label,
          value: category?.sales?.TTC?.total_price,
          valueOrder: category.orders?.value,
        }))
      : [];

  return {
    data,
    isLoading,
  };
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useEffect, useState} from 'react';
import {EtcIcon} from '../../assets/svgs/EtcIcon';
import {PaginationLeft} from '../../assets/svgs/PaginationLeft';
import {PaginationRight} from '../../assets/svgs/PaginationRight';
import {THEME} from '../../styles/theme';
import {TextVariant} from '../atoms/TextVariant';
import {useTranslation} from 'react-i18next';

export const DatagridPagination = ({
  page,
  setPage,
  totalRows,
  rowPerPage,
  isLoading = false,
  paginationShowMore = true,
  moreCount = 10,
  totalLimit,
  limit = 10,
  updateLimit,
}: {
  page: number;
  setPage: any;
  totalRows: number;
  rowPerPage: number;
  isLoading?: boolean;
  paginationShowMore?: boolean;
  moreCount?: number;
  totalLimit?: number;
  limit?: number;
  updateLimit?: (type: 'incr' | 'decr') => void;
}) => {
  const [isLeftHover, setIsLeftHover] = useState(false);
  const [isRightHover, setIsRightHover] = useState(false);
  const [previousTotalRows, setPreviousTotalRows] = useState(0);

  const {t} = useTranslation();

  useEffect(() => {
    if (!isLoading && totalRows) {
      setPreviousTotalRows(totalRows);
    }
  }, [isLoading, totalRows]);

  const totalPages = isLoading
    ? Math.ceil(previousTotalRows / rowPerPage)
    : Math.ceil(totalRows / rowPerPage);

  const handleClickLeft = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleClickRight = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handleClickPage = (newPage: number) => {
    if (page !== newPage) {
      setPage(newPage);
    }
  };

  const ContentButtons = () => {
    let pages = [];

    for (let index = 2; index <= totalPages - 1; index++) {
      pages.push(index);
    }
    return pages.map((element, index) => {
      if (element === page) {
        return (
          <div
            key={index}
            onClick={() => handleClickPage(element)}
            css={styles.numberBtn(page === element)}>
            {element}
          </div>
        );
      } else if (element === page + 1) {
        return (
          <div
            key={index}
            onClick={() => handleClickPage(element)}
            css={styles.numberBtn(page === element)}>
            {element}
          </div>
        );
      } else if (element === page - 1) {
        return (
          <div
            key={index}
            onClick={() => handleClickPage(element)}
            css={styles.numberBtn(page === element)}>
            {element}
          </div>
        );
      } else if (element === page - 2 && page > totalPages - 1) {
        return (
          <div
            key={index}
            onClick={() => handleClickPage(element)}
            css={styles.numberBtn(page === element)}>
            {element}
          </div>
        );
      } else if (element === page + 2 && page < 2) {
        return (
          <div
            key={index}
            onClick={() => handleClickPage(element)}
            css={styles.numberBtn(page === element)}>
            {element}
          </div>
        );
      } else return null;
    });
  };

  const handleClickShowMore = (type: 'incr' | 'decr') => {
    if (updateLimit) {
      updateLimit(type);
    }
  };

  return (
    <div css={styles.pagination}>
      {paginationShowMore && limit > moreCount && (
        <button
          css={styles.btnShowMore()}
          onClick={() => handleClickShowMore('decr')}>
          <TextVariant
            variant="textSemiBold"
            text={
              t('SEE_LESS_THAN').slice(0, 4) +
              ' ' +
              moreCount +
              t('SEE_LESS_THAN').slice(4)
            }
            style={{
              marginRight: 5,
            }}
          />
          {/* <LessIcon /> */}
        </button>
      )}
      {!paginationShowMore && (
        <>
          <div
            onMouseEnter={() => setIsLeftHover(true)}
            onMouseLeave={() => setIsLeftHover(false)}
            css={styles.btn(page > 1)}
            onClick={handleClickLeft}>
            {page > 1 && (
              <PaginationLeft
                color={isLeftHover ? THEME.colors.lightGray : THEME.colors.gray}
              />
            )}
          </div>

          <div
            onClick={() => handleClickPage(1)}
            css={styles.numberBtn(page === 1)}>
            1
          </div>

          {page > 3 && (
            <div css={styles.btn(false)}>
              <EtcIcon />
            </div>
          )}
          {ContentButtons()}

          {page < totalPages - 2 && (
            <div css={styles.btn(false)}>
              <EtcIcon />
            </div>
          )}

          {totalPages > 1 && (
            <div
              onClick={() => handleClickPage(totalPages)}
              css={styles.numberBtn(page === totalPages)}>
              {totalPages}
            </div>
          )}

          <div
            onMouseEnter={() => setIsRightHover(true)}
            onMouseLeave={() => setIsRightHover(false)}
            css={styles.btn(page < totalPages)}
            onClick={handleClickRight}>
            {page < totalPages && (
              <PaginationRight
                color={
                  isRightHover ? THEME.colors.lightGray : THEME.colors.gray
                }
              />
            )}
          </div>
        </>
      )}

      {!!totalLimit && paginationShowMore && totalLimit === limit && (
        <button
          css={[styles.btnShowMore(true), css({marginLeft: '1rem'})]}
          onClick={() => handleClickShowMore('incr')}>
          <TextVariant
            variant="textSemiBold"
            text={
              t('SEE_MORE_THAN').slice(0, 4) +
              ' ' +
              moreCount +
              t('SEE_MORE_THAN').slice(4)
            }
            style={{
              marginRight: 5,
            }}
          />
          {/* <MoreIcon /> */}
        </button>
      )}
    </div>
  );
};

const styles = {
  pagination: css({
    alignSelf: 'flex-end',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  }),
  btn: (isActive: boolean = true) =>
    css({
      width: 24,
      height: 24,
      fontSize: 12,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      cursor: isActive ? 'pointer' : 'default',
      borderRadius: 25,
      userSelect: 'none',
    }),
  numberBtn: (isActive: boolean) =>
    css({
      minWidth: 24,
      height: 24,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      fontWeight: '700',
      color: isActive ? THEME.colors.white : THEME.colors.gray,
      backgroundColor: isActive ? THEME.colors.primary : 'transparent',
      cursor: isActive ? 'default' : 'pointer',
      borderRadius: 25,
      userSelect: 'none',
      ':hover': {
        color: isActive ? THEME.colors.white : THEME.colors.lightGray,
      },
    }),
  btnShowMore: (isRight?: boolean) =>
    css({
      height: 22,
      backgroundColor: isRight ? THEME.colors.primary : THEME.colors.red,
      color: THEME.colors.white,
      borderRadius: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      border: 'none',
      padding: '0 10px',
    }),
};

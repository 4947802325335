import {SVGProps} from 'react';

export const DaysIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.21783 1.875H9.43658V0.703125C9.43658 0.314941 9.75006 0 10.1397 0C10.5294 0 10.8428 0.314941 10.8428 0.703125V1.875H12.0147C13.0489 1.875 13.8897 2.71436 13.8897 3.75V13.125C13.8897 14.1592 13.0489 15 12.0147 15H2.63971C1.60407 15 0.764709 14.1592 0.764709 13.125V3.75C0.764709 2.71436 1.60407 1.875 2.63971 1.875H3.81158V0.703125C3.81158 0.314941 4.12506 0 4.51471 0C4.90436 0 5.21783 0.314941 5.21783 0.703125V1.875ZM2.17096 13.125C2.17096 13.3828 2.38073 13.5938 2.63971 13.5938H12.0147C12.2725 13.5938 12.4835 13.3828 12.4835 13.125V5.625H2.17096V13.125Z"
      fill="white"
    />
  </svg>
);

/** @jsxImportSource @emotion/react */
import {useContext} from 'react';
import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {addProductModalAction} from '../../../../../redux/modals';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';

export const Center = () => {
  const {t, dispatch} = useContext<any>(UtilsContext);
  const {brandHasAuthorization} = useBrandAccess();

  const handleOpenModalAddProduct = () => {
    dispatch(addProductModalAction(true));
  };

  return (
    <>
      <ButtonCustom
        label={t('ADD_PRODUCT')}
        onClick={handleOpenModalAddProduct}
        disabled={!brandHasAuthorization(BRAND_AUTHORIZATION.PARENT)}
      />
    </>
  );
};

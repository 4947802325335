/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import SimpleCard from './SimpleCard';
import {ButtonCustom} from '../../../../../../components/atoms/ButtonCustom';
import {useTranslation} from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useReduxHook';
import {addPosCategoryModalAction} from '../../../../../../redux/modals';
import {
  SortableListVerticalContainer,
  THandleDragAndDrop,
} from '../../../../../../components/organisms/SortableListVerticalContainer';
import {helperDisplayToast} from '../../../../../../helpers/helperToast';
import {useBrandAccess} from '../../../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../../../constants/frontPolicies';
import {updateCategoriesPosition} from '../../../../../../api/fetchCategories';

const RightBlock = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {brandHasAuthorization} = useBrandAccess();
  const hasParentAuthorization = brandHasAuthorization(
    BRAND_AUTHORIZATION.PARENT,
  );

  const treePreRegister = useAppSelector(
    s => s.myModulesSection.pos.treePreRegister,
  );

  const handleClickAdd = () => {
    if (!hasParentAuthorization) return;

    dispatch(addPosCategoryModalAction(true));
  };

  const handleDragAndDrop: THandleDragAndDrop = async ({items}) => {
    const categoryUuids = items?.map(item => item?.uuid) || [];

    const res = await updateCategoriesPosition(categoryUuids);

    if (res?.status === 204) {
      helperDisplayToast('Position modifiée', true);
    } else {
      helperDisplayToast('Impossible de modifier la position', false);
    }
  };

  const categories = treePreRegister?.categories?.map(
    (item: any, index: number) => ({
      ...item,
      position: index + 1,
    }),
  );

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <ButtonCustom
          marginBottom={12}
          label={t('ADD_CATEGORY')}
          onClick={handleClickAdd}
          disabled={!hasParentAuthorization}
        />
      </div>

      <div css={styles.list}>
        <SortableListVerticalContainer
          data={categories || []}
          // @ts-ignore
          ItemComponent={SimpleCard}
          handleDragAndDrop={
            hasParentAuthorization ? handleDragAndDrop : undefined
          }
        />
      </div>
    </div>
  );
};

export default RightBlock;

const styles = {
  container: css({
    width: '20%',
    paddingLeft: 5,
  }),
  header: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  list: css({height: 'calc(100% - 40px)', overflowY: 'auto'}),
};

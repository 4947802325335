import {useEffect} from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {useAppDispatch} from '../hooks/useReduxHook';
import {currentThemeAction} from '../redux/app';
import {OfflineRouter} from './OfflineRouter';
import {OnlineRouter} from './OnlineRouter';

const router = createBrowserRouter([
  {
    path: '/',
    children: [OfflineRouter, OnlineRouter],
  },
]);

export const AppRouter = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');

    const prefersDark =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;

    const defaultDark =
      storedTheme === 'dark' || (storedTheme === null && prefersDark);

    dispatch(currentThemeAction(defaultDark ? 'dark' : 'light'));
  }, [dispatch]);

  return <RouterProvider router={router} />;
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useEffect} from 'react';
import {ChevronDownIcon} from '../../../../../assets/svgs/ChevronDownIcon';
import {ChevronUpIcon} from '../../../../../assets/svgs/ChevronUpIcon';
import {EditIcon} from '../../../../../assets/svgs/EditIcon';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {THEME} from '../../../../../styles/theme';
import {ActionButton} from '../../../../../components/atoms/ActionButton';
import {ToggleButton} from '../../../../../components/atoms/ToggleButton';
import {ProductList} from '../../../../../components/molecules/ProductList';
import {addVendorStructureModalAction} from '../../../../../redux/modals';
import {Loader} from '../../../../../components/atoms/Loader';
import {useQuery} from '@tanstack/react-query';
import {helperLabelTranslation} from '../../../../../helpers/helperLabel';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {IVendorStructure} from '@bill-app-types/b-types';
import {
  updateModeAction,
  updatedVendorStructureAction,
  vendorStructureProductsAction,
  vendorStructureSelectedAction,
} from '../../../../../redux/myAccountingSection/vendorStructures';
import {getVendorStructureItems} from '../../../../../api/fetchVendorStructure';
import {SortableElement} from 'react-sortable-hoc';
import {
  SortableListVerticalContainer,
  THandleDragAndDrop,
} from '../../../../../components/organisms/SortableListVerticalContainer';

export const CategoryItem = SortableElement(
  ({
    item,
    level,
    toggle,
    handleDragAndDrop,
  }: {
    item: any;
    level: number;
    toggle?: boolean;
    handleDragAndDrop: THandleDragAndDrop;
  }) => {
    const dispatch = useAppDispatch();

    const {brandHasAuthorization} = useBrandAccess();

    const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

    const vendorStructureSelected = useAppSelector(
      s => s.myCatalogSection.vendorStructures.vendorStructureSelected,
    );

    const {data, isLoading} = useQuery(
      [
        `getVendorStructureItems-${vendorStructureSelected?.uuid}`,
        vendorStructureSelected?.uuid,
      ],
      () => getVendorStructureItems(vendorStructureSelected?.uuid),
      {
        enabled: !!vendorStructureSelected?.uuid,
      },
    );

    useEffect(() => {
      if (data) {
        dispatch(vendorStructureProductsAction(data));
      }
    }, [data, dispatch]);

    const handleOpenCategory = (vendorStructure: IVendorStructure) => {
      dispatch(vendorStructureSelectedAction(vendorStructure));
    };

    const handleClickEdit = (vendorStructure: IVendorStructure) => {
      dispatch(addVendorStructureModalAction(true));
      dispatch(updateModeAction(true));
      dispatch(
        updatedVendorStructureAction({
          ...vendorStructure,
          label: helperLabelTranslation(vendorStructure),
        }),
      );
    };

    const isOpen =
      vendorStructureSelected && item.uuid === vendorStructureSelected?.uuid;

    return (
      <div style={{marginBottom: 5}}>
        <div
          css={[
            styles.dropdown,
            {
              marginBottom:
                item?.subCategories && item?.subCategories?.length > 0 ? 5 : 0,
            },
          ]}>
          <ActionButton
            onClick={() => handleClickEdit(item)}
            Icon={EditIcon}
            disabled={!hasAuthorization}
          />
          <div
            css={styles.dropdownHeader}
            onClick={() => handleOpenCategory(item)}>
            <div className="textMedium" css={styles.title}>
              {helperLabelTranslation(item)}
              <div css={styles.dot} />
              <span css={styles.tax}>{item.tax.label}</span>
            </div>
            <div css={styles.toggle}>{toggle && <ToggleButton active />}</div>
            <div css={styles.chevron}>
              {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </div>
          </div>
        </div>

        {data && isOpen && (
          <div css={styles.dropdownContent}>
            {data?.length > 0 ? (
              <ProductList
                data={data}
                style={{backgroundColor: THEME.colors.white}}
                showProductType
              />
            ) : (
              <p className="textSemiBold">Aucun produits</p>
            )}
          </div>
        )}
        {(isLoading || !data) && isOpen && (
          <div className="flex-center" css={styles.dropdownContent}>
            <Loader />
          </div>
        )}

        {item?.subCategories && item?.subCategories?.length > 0 && (
          <>
            <SortableListVerticalContainer
              data={item?.subCategories}
              level={level + 1}
              // @ts-ignore
              ItemComponent={CategoryItem}
              handleDragAndDrop={handleDragAndDrop}
            />
          </>
        )}
      </div>
    );
  },
);

const styles = {
  dropdown: css({
    backgroundColor: THEME.colors.white,
    display: 'flex',
    borderRadius: 2,
    padding: '0px 10px',
    alignItems: 'center',
  }),
  dropdownHeader: css({
    backgroundColor: THEME.colors.white,
    display: 'flex',
    padding: '7px 10px',
    borderRadius: 2,
    alignItems: 'center',
    cursor: 'pointer',
    flex: 1,
  }),
  dropdownContent: css({
    backgroundColor: THEME.colors.white,
    padding: 10,
    marginBottom: 10,
  }),
  title: css({
    paddingLeft: 10,
    alignItems: 'center',
    display: 'flex',
  }),
  edit: css({
    backgroundColor: THEME.colors.bgLight,
    width: 25,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 2,
  }),
  chevron: css({
    // marginLeft: 'auto',
  }),
  toggle: css({
    marginLeft: 'auto',
    marginRight: 10,
  }),
  dot: css({
    width: 3,
    height: 3,
    borderRadius: 10,
    margin: '0 5px',
    backgroundColor: THEME.colors.darkBlue,
  }),
  tax: css({
    color: THEME.colors.primary,
  }),
};

/** @jsxImportSource @emotion/react  */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {ToggleButton} from '../../../../components/atoms/ToggleButton';
import {helperDate} from '../../../../helpers/helperDate';
import {THEME} from '../../../../styles/theme';
import {helperFormatPrice} from '../../../../helpers/helperFormatPrice';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {updateProduct} from '../../../../api/fetchProducts';
import {ImageCustom} from '../../../../components/atoms/ImageCustom';
import store from '../../../../redux/storeConfig';
import {updateMenu} from '../../../../api/fetchMenus';
import {updateCatalogOption} from '../../../../api/fetchCatalogOptions';
import {helperLabelTranslation} from '../../../../helpers/helperLabel';

export const columnsProducts = [
  {
    field: 'icon',
    headerName: '',
    headerClassName: 'textRegular',
    renderCell: (row: any) => {
      return (
        <div
          className="flex-align-center"
          style={{
            width: '100%',
            marginLeft: 5,
          }}>
          <div css={styles.productImgContainer}>
            <ImageCustom src={row?.image} alt={row?.label} />
          </div>
        </div>
      );
    },
  },
  {
    field: 'product',
    headerName: 'Produit',
    flex: 1,
    align: 'center',
    headerClassName: 'textRegular',
    renderCell(row: any) {
      return (
        <div className="flex-col-center" css={styles.productLabel}>
          <p>{helperLabelTranslation(row)} </p>
        </div>
      );
    },
  },
  {
    field: 'price',
    headerName: 'Prix',
    flex: 1,
    headerClassName: 'textRegular',
    renderCell(row: any) {
      return (
        <TextVariant
          text={`${helperFormatPrice(row.price)}€`}
          variant="textSemiBold"
        />
      );
    },
  },
  {
    field: 'availability',
    headerName: 'Disponibilité',
    flex: 1,
    headerClassName: 'textRegular',
    renderCell: (row: any) => {
      const handleToggleOutOfStock = async () => {
        const showSection = store.getState().app.sectionShow;

        const updateFunc =
          showSection === 'MENUS'
            ? updateMenu
            : showSection === 'PRODUCTS'
            ? updateProduct
            : updateCatalogOption;

        const res = await updateFunc(row.uuid, {
          isOutOfStock: !row.isOutOfStock,
        });
        if (res.status === 204) {
          helperDisplayToast('Disponibilité modifiée', true);
        } else {
          helperDisplayToast(
            res.data?.message || 'Impossible de modifier la disponibilité',
            false,
          );
        }
      };

      return (
        <ToggleButton
          active={!row.isOutOfStock}
          onToggle={handleToggleOutOfStock}
        />
      );
    },
  },
  {
    field: 'visibility',
    headerName: 'Visibilité',
    flex: 1,
    headerClassName: 'textRegular',
    renderCell: (row: any) => {
      const handleToggleArchived = async () => {
        const showSection = store.getState().app.sectionShow;

        const updateFunc =
          showSection === 'MENUS'
            ? updateMenu
            : showSection === 'PRODUCTS'
            ? updateProduct
            : updateCatalogOption;

        const res = await updateFunc(row.uuid, {
          isArchived: !row.isArchived,
        });
        if (res.status === 204) {
          helperDisplayToast('Visibilité modifiée', true);
        } else {
          helperDisplayToast(
            res.data?.message || 'Impossible de modifier la visibilité',
            false,
          );
        }
      };

      return (
        <ToggleButton
          active={!row.isArchived}
          onToggle={handleToggleArchived}
        />
      );
    },
  },

  {
    field: 'createdAt',
    headerName: "Date d'ajout",
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter(row: any) {
      return `Le ${helperDate(row.createdAt, 'DD/MM/YYYY')}`;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Date de modification',
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter(row: any) {
      return `Le ${helperDate(row.updatedAt, 'DD/MM/YYYY')}`;
    },
  },
];

const styles = {
  productImgContainer: css({
    width: 68,
    height: 68,
    position: 'relative',
    borderRadius: 2,
    border: 'none',
  }),
  productImg: css({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 2,
  }),
  pictureIcon: css({
    position: 'absolute',
    inset: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  optionType: css({
    color: THEME.colors.primary,
  }),
  productLabel: css({
    width: '100%',
  }),
  star: css({
    backgroundColor: THEME.colors.yellow,
    width: 25,
    height: 25,
    borderRadius: 2,
    marginLeft: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};

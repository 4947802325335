import {BAppIcon} from '../../../assets/svgs/BAppIcon';
import {BCallScreenIcon} from '../../../assets/svgs/BCallScreenIcon';
import {BDeliveryIcon} from '../../../assets/svgs/BDeliveryIcon';
import {BDisplayIcon} from '../../../assets/svgs/BDisplayIcon';
import {BDriveIcon} from '../../../assets/svgs/BDriveIcon';
import {BKiosk} from '../../../assets/svgs/BKioskIcon';
import {BMenuIcon} from '../../../assets/svgs/BMenuIcon';
import {BPosIcon} from '../../../assets/svgs/BPosIcon';
import {BProdIcon} from '../../../assets/svgs/BProdIcon';
import {BStaffIcon} from '../../../assets/svgs/BStaffIcon';
import {BTrackIcon} from '../../../assets/svgs/BTrackIcon';
import {BWebAppIcon} from '../../../assets/svgs/BwebAppIcon';

export const MODULES_LISTS = [
  {name: 'bcollect', Icon: <></>},
  {
    name: 'bapp',
    Icon: <></>,
  },

  {name: 'border', Icon: <></>},
  {
    name: 'bwebapp',
    Icon: <></>,
  },
  {name: 'bdisplay', Icon: <></>},
  {name: 'bmenu', Icon: <></>},
  {name: 'bpad', Icon: <></>},
  {name: 'bmonetics', Icon: <></>},
  {name: 'bstaff', Icon: <></>},
  {name: 'bprod', Icon: <></>},
  {name: 'bdelivery', Icon: <></>},
  {name: 'bstock', Icon: <></>},
  {name: 'breport', Icon: <></>},
  {name: 'bloyalty', Icon: <></>},
  {name: 'bpos', Icon: <></>},
  {name: 'bdrive', Icon: <></>},
  {name: 'breplica', Icon: <></>},
  {name: 'binteractive', Icon: <></>},
  {name: 'bcallscreen', Icon: <></>},
  {name: 'bbip', Icon: <></>},
  {name: 'btrack', Icon: <></>},
];

export const MODULES_ICONS: any = {
  b_menu: BMenuIcon,
  b_app: BAppIcon,
  b_kiosk: BKiosk,
  b_webapp: BWebAppIcon,
  b_staff: BStaffIcon,
  b_prod: BProdIcon,
  b_order: BKiosk,
  b_track: BTrackIcon,
  b_drive: BDriveIcon,
  b_pos: BPosIcon,
  b_display: BDisplayIcon,
  b_callscreen: BCallScreenIcon,
  b_dedicated: BAppIcon,
  b_connect: BDeliveryIcon,
};

export const MODULES_ALLOWED = [
  'b_kiosk',
  'b_order',
  'b_webapp',
  'b_pos',
  'b_delivery',
  'b_connect',
];

import {useTranslation} from 'react-i18next';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addSubMenuModalAction} from '../../redux/modals';
import {helperDisplayToast} from '../../helpers/helperToast';
import {createSubmenu} from '../../api/fetchSubmenus';
import {useQueryClient} from '@tanstack/react-query';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {updateModeAction} from '../../redux/myCatalogSection/menus';
import {updateCatalogModifier} from '../../api/fetchItems';

export const AddSubMenuModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const clientQuery = useQueryClient();

  const updateMode = useAppSelector(s => s.myCatalogSection.menus.updateMode);
  const updatedSubmenu = useAppSelector(
    s => s.myCatalogSection.menus.updatedSubmenu,
  );

  const initialValues = {
    label: '',
  };

  const handleClose = () => {
    dispatch(addSubMenuModalAction(false));
    dispatch(updateModeAction(false));
  };

  const handleConfirm = async (
    data: {
      label: string;
      backofficeLabel: string;
    },
    setSubmitting: any,
  ) => {
    if (data.label) {
      const payload = {
        label: data.label,
        backofficeLabel: data.backofficeLabel,
        minimum: 0,
        maximum: 1,
        allowMultiple: false,
        hideOnConsumptionMode: [],
      }

      if (updateMode) {
        const res = await updateCatalogModifier(updatedSubmenu.uuid, payload);
        if (res?.status === 204) {
          helperDisplayToast('Sous-menu modifié', true);
          clientQuery.invalidateQueries(['fetchSubmenus']);
          handleClose();
        } else {
          helperDisplayToast('Impossible de modifier le sous-menu', false);
        }
      } else {
        const res = await createSubmenu(payload);
        if (res?.status === 200) {
          helperDisplayToast('Sous-menu ajouté', true);
          clientQuery.invalidateQueries(['fetchSubmenus']);
          handleClose();
        } else {
          helperDisplayToast('Impossible de créer le sous-menu', false);
        }
      }
    } else {
      helperDisplayToast(
        'Tous les champs obligatoires doivent être complétés ',
        false,
      );
    }
    setSubmitting(false);
  };

  return (
    <ModalWrapper
      title={updateMode ? 'Modifier un sous-menu' : t('ADD_SUBMENU')}
      cancelLabel={t('CANCEL')}
      btnCancelWidth={'28%'}
      btnValidateWidth={'70%'}
      onCancel={handleClose}
      onSubmit={handleConfirm}
      validateLabel={
        updateMode ? 'Modifier un sous-menu' : t('ADD_A_ONE_SUBMENU')
      }
      titleMarginBottom={'1.5rem'}
      initialValues={
        updateMode ? {label: updatedSubmenu.label} : initialValues
      }>
      <FieldCustomInput label={t('SUBMENU_NAME')} name="label" required />
      <FieldCustomInput
        label={t('BACKOFFICE_DISPLAY_NAME')}
        name="backofficeLabel"
        required
      />
      <FieldsRequired />
    </ModalWrapper>
  );
};

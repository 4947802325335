/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {THEME} from '../../styles/theme';

export const FieldsRequired = () => {
  const {t} = useTranslation();
  return (
    <p className="title03" css={styles.fieldsRequired}>
      <span
        style={{
          color: THEME.colors.red,
        }}>
        *
      </span>{' '}
      {t('FIELDS_REQUIRED')}
    </p>
  );
};

const styles = {
  fieldsRequired: css({
    color: '#A7A7AB',
    textAlign: 'right',
    margin: '20px 0',
  }),
};

/** @jsxImportSource @emotion/react */
import {useTranslation} from 'react-i18next';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {addPrinterConfigModalAction} from '../../redux/modals';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {SelectMultiList} from '../molecules/SelectMultiList';
import {configPrinterSelectedAction} from '../../redux/myRestaurantSection/printers';
import {ChangeEvent, useEffect, useState} from 'react';
import {
  createPrintingParameter,
  updatePrintingParameter,
} from '../../api/fetchPrintingParameters';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {usePrinters} from '../../hooks/usePrinters';
import {
  CHANNELS,
  CONSUMPTION_MODE,
  PRINTER_TEMPLATES,
  TEMPLATE_MODE,
} from '@bill-app-types/b-types';
import {ButtonList} from '../atoms/ButtonList';
import {useAreaProduction} from '../../hooks/useAreaProduction';
import {helperLabelTranslation} from '../../helpers/helperLabel';

interface IForm {
  printerSelected: number | null;
  eventSelected: number | null;
  selected_consumption_modes: CONSUMPTION_MODE[];
  selected_channels: number[];
  highlighted_prod_area_uuids: string[];
}

export const AddPrinterConfigModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const clientQuery = useQueryClient();

  const {data} = useAreaProduction();

  const [form, setForm] = useState<IForm>({
    printerSelected: null,
    eventSelected: null,
    selected_consumption_modes: [],
    selected_channels: [],
    highlighted_prod_area_uuids: [],
  });

  const configPrinterSelected = useAppSelector(
    s => s.myRestaurantSection.printers.configPrinterSelected,
  );

  useEffect(() => {
    if (!!configPrinterSelected) {
      setForm({
        printerSelected: configPrinterSelected.brand_has_printer_id,
        eventSelected: configPrinterSelected.printer_template_id,
        selected_consumption_modes:
          configPrinterSelected.selected_consumption_modes,
        selected_channels: configPrinterSelected.selected_channels,
        highlighted_prod_area_uuids:
          configPrinterSelected.highlighted_prod_area_uuids,
      });
    }
  }, [configPrinterSelected]);

  const {printers} = usePrinters();

  const handleCancel = () => {
    dispatch(configPrinterSelectedAction(null));
    dispatch(addPrinterConfigModalAction(false));
  };

  const handleChangePrinter = (e: ChangeEvent<HTMLSelectElement>) => {
    setForm({
      ...form,
      printerSelected: parseInt(e.target?.value),
    });
  };

  const handleChangeEvent = (e: ChangeEvent<HTMLSelectElement>) => {
    setForm({
      ...form,
      eventSelected: parseInt(e.target?.value),
    });
  };

  const handleChangeAreaProduction = (e: ChangeEvent<HTMLSelectElement>) => {
    const areaProduction = e.target?.value;

    if (form.highlighted_prod_area_uuids.includes(areaProduction)) {
      setForm({
        ...form,
        highlighted_prod_area_uuids: form.highlighted_prod_area_uuids.filter(
          item => item !== areaProduction,
        ),
      });
    } else {
      setForm({
        ...form,
        highlighted_prod_area_uuids: [
          ...form.highlighted_prod_area_uuids,
          areaProduction,
        ],
      });
    }
  };

  const handleSubmit = async () => {
    let res;
    if (configPrinterSelected) {
      res = await updatePrintingParameter(
        {
          highlighted_prod_area_uuids: form.highlighted_prod_area_uuids,
          selected_channels: form.selected_channels,
          selected_consumption_modes: form.selected_consumption_modes,
          brand_has_printer_id: form.printerSelected,
          printer_template_id: form.eventSelected,
        },
        configPrinterSelected.id,
      );
    } else {
      res = await createPrintingParameter({
        brand_has_printer_id: form.printerSelected,
        printer_template_id: form.eventSelected,
        highlighted_prod_area_uuids: form.highlighted_prod_area_uuids,
        selected_channels: form.selected_channels,
        selected_consumption_modes: form.selected_consumption_modes,
      });
    }
    if (res && [200, 204].includes(res?.status)) {
      clientQuery.invalidateQueries(['fetchPrintingParameters']);
      helperDisplayToast('Configuration enregistrée', true);
      handleCancel();
    } else {
      helperDisplayToast('Impossible de sauvegarder la configuration', false);
    }
  };

  const channels = Object.entries(CHANNELS)
    .slice(7)
    .map(entry => ({
      label: t(entry[0]),
      value: entry[1],
    }));

  const consumption_modes = Object.entries(CONSUMPTION_MODE)
    .slice(3)
    .map(entry => ({
      label: t(entry[0]),
      value: entry[1],
    }));

  const area_productions = data?.map((item: any) => ({
    label: helperLabelTranslation(item),
    value: item.uuid,
  }));

  const handleClickConsumptionMode = (consumptionMode: number | string) => {
    if (form.selected_consumption_modes.includes(Number(consumptionMode))) {
      setForm({
        ...form,
        selected_consumption_modes: form.selected_consumption_modes.filter(
          item => item !== consumptionMode,
        ),
      });
    } else {
      setForm({
        ...form,
        selected_consumption_modes: [
          ...form.selected_consumption_modes,
          Number(consumptionMode),
        ],
      });
    }
  };

  const handleClickChannel = (channel: number | string) => {
    if (form.selected_channels.includes(Number(channel))) {
      setForm({
        ...form,
        selected_channels: form.selected_channels.filter(
          item => item !== channel,
        ),
      });
    } else {
      setForm({
        ...form,
        selected_channels: [...form.selected_channels, Number(channel)],
      });
    }
  };

  return (
    <ModalWrapper
      title={t('CONFIG_A_NETWORK_PRINTER')}
      cancelLabel={t('CANCEL')}
      validateLabel={t('REGISTER_PRINTER_CONFIG')}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      minWidth={'30%'}>
      <div css={styles.container}>
        <SelectMultiList
          placeHolder={t('SELECT_PRINTER')}
          list={printers?.map((item: {label: string; id: number}) => ({
            label: item.label,
            value: item.id,
          }))}
          listChecked={
            form.printerSelected
              ? [
                  {
                    label:
                      printers?.find(
                        (item: any) => item.id === form.printerSelected,
                      )?.label || '',
                    value: form.printerSelected,
                  },
                ]
              : []
          }
          onChange={handleChangePrinter}
        />
        <SelectMultiList
          placeHolder={t('EVENT_TRIGGERING_PRINTING')}
          list={PRINTER_TEMPLATES?.map(
            (item: {
              label: string;
              id: number;
              position: number;
              type: TEMPLATE_MODE;
            }) => ({
              label: `${item.label}`,
              value: item.id,
            }),
          )}
          onChange={handleChangeEvent}
          listChecked={
            form.eventSelected
              ? [
                  {
                    label:
                      PRINTER_TEMPLATES?.find(
                        item => item.id === form.eventSelected,
                      )?.label || '',
                    value: form.eventSelected,
                  },
                ]
              : []
          }
        />
        <SelectMultiList
          placeHolder={'Zones de production mises en avant'}
          list={area_productions}
          onChange={handleChangeAreaProduction}
          listChecked={
            form?.highlighted_prod_area_uuids?.map((prod: string) => ({
              label: area_productions?.find((item: any) => item.value === prod)
                ?.label,
              value: prod,
            })) || []
          }
        />
        <ButtonList
          label="Modes de consommation"
          list={consumption_modes}
          selectedValues={form.selected_consumption_modes}
          handleClickButton={handleClickConsumptionMode}
        />
        <ButtonList
          label="Canaux de commande"
          list={channels}
          selectedValues={form.selected_channels}
          handleClickButton={handleClickChannel}
        />
      </div>
    </ModalWrapper>
  );
};

const styles = {
  container: {
    marginTop: '2rem',
  },
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {RequiredLabel} from '../atoms/RequiredLabel';
import {Field} from 'formik';
import {THEME} from '../../styles/theme';

interface ISelectListProps {
  label?: string;
  required?: boolean;
  name?: string;
  manageByFormik?: boolean;
  onChange?: (e: any) => void;
  placeHolder?: string;
  value?: string | number;
  list?: {
    value: string | number;
    label: string;
  }[];
  disabled?: boolean;
}

export const SelectList = ({
  label = '',
  required = false,
  name = '',
  manageByFormik = true,
  list = [],
  onChange = () => {},
  value = '',
  placeHolder = '',
  disabled = false,
}: ISelectListProps) => {
  return (
    <div css={styles.container}>
      <RequiredLabel label={label} required={required} />
      <div css={styles.selectContainer(disabled)}>
        {manageByFormik && (
          <Field
            name={name}
            as="select"
            css={styles.select}
            className="textSemiBold">
            {/* <option>Selectionner</option> */}
            <option value="" disabled>
              Selectionner
            </option>
            {list.map((item: any, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </Field>
        )}

        {!manageByFormik && (
          <select
            disabled={disabled}
            css={styles.select}
            onChange={onChange}
            name={name}
            value={value}
            defaultValue={''}
            className="textSemiBold">
            <option value="" disabled>
              {placeHolder}
            </option>

            {list.map((item: any, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </select>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
    // margin: '10px 0',
  }),
  selectContainer: (disabled: boolean) =>
    css({
      backgroundColor: THEME.colors.white,
      paddingRight: '8px',
      borderRadius: 2,
      opacity: disabled ? 0.5 : 1,
    }),
  select: css({
    width: '100%',
    height: 39,
    border: 'none',
    borderRadius: 2,
    backgroundColor: THEME.colors.white,
    padding: '0 10px',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {OrderDetailsRightBlock} from './OrderDetailsRightBlock';
import {OrderTicket} from './Ticket';
import {TicketFooter} from './Ticket/TicketFooter';
import {TicketHeader} from './Ticket/TicketHeader';

export const OrderDetails = () => {
  //
  return (
    <div css={styles.container} className="flex textSemiBold">
      <OrderTicket TicketFooter={TicketFooter} TicketHeader={TicketHeader} />
      <OrderDetailsRightBlock />
    </div>
  );
};

const styles = {
  container: css({
    padding: '1.5% 2%',
  }),
};

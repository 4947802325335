/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../../components/layouts/Header';
import {StatsSalesHeaderTabs} from '../Headers/StatsSalesHeaderTabs';
import {StatsSalesHeaderBottom} from '../Headers/StatsSalesHeaderBottom';
import {helperGetStatColorById} from '../../../../../helpers/helperStats';
import {SalesPieChartList} from '../../../../../components/organisms/SalesPieChartList';
import {OptionsColumns} from '../DataColumns/OptionsColumns';
import {exportSaleByOption} from '../../../../../api/fetchStats';
import {PRODUCT_TYPES} from '@bill-app-types/b-types';
import {useState} from 'react';
import {useStatItems} from '../../../../../hooks/useStatItems';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {useNavigate} from 'react-router-dom';
import {SearchFilter} from '../../../../../components/filters/SearchFilter';
import {useSearch} from '../../../../../hooks/useSearch';
import {M_SCREEN_DESKTOP} from '../../../../../constants/ResponsiveSize';

export const Options = withRedirectAndNavigation(() => {
  const compareDates = useAppSelector(s => s.filter.compareDates);
  const isCompare = useAppSelector(s => s.filter.isCompare);
  const salesGraphSection = useAppSelector(
    s => s.myStatsSection.sales.salesGraphSection,
  );
  const parentBarCodes = useAppSelector(s => s.filter.parentBarcodes);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const navigate = useNavigate();

  const [limit, setLimit] = useState(10);
  const {search, handleChangeSearch, debounceValue} = useSearch();

  const {data: stats, isLoading} = useStatItems({
    limit,
    types: [PRODUCT_TYPES.OPTION_ITEM],
    parentBarcodes: parentBarCodes.map((m: any) => m.value),
    search: debounceValue,
    consumptionModes,
  });

  const {data: statsCompare} = useStatItems({
    limit,
    types: [PRODUCT_TYPES.OPTION_ITEM],
    dates: compareDates,
    enabled: isCompare,
    consumptionModes,
    parentBarcodes: parentBarCodes.map((m: any) => m.value),
    search: debounceValue,
  });

  const totalLimit = stats?.length || 0;

  const data =
    stats && Array.isArray(stats)
      ? stats?.map((option, index: number) => {
          const findOption = statsCompare?.find(
            (m: any) => m.itemId === option.itemId,
          );

          return {
            ...option,
            color: helperGetStatColorById(index),
            name: option.itemName,
            value: option.sales?.TTC.total_price,
            valueOrder: option.orders?.value,
            percent_sales:
              salesGraphSection === 'ORDERS'
                ? option?.orders?.percent
                : option?.sales?.TTC?.total_percent,
            suffix: '€',
            forCompareOrders: isCompare
              ? findOption?.orders || {
                  value: 0,
                  percent: 0,
                }
              : null,
            forCompareSales: isCompare
              ? findOption?.sales || {
                  TTC: {
                    total_price: 0,
                    total_percent: 0,
                  },
                }
              : null,

            handleDetail: () => {
              navigate(`detail/${option.itemId}`);
            },
          };
        })
      : [];

  const handleExport = async () => {
    await exportSaleByOption({
      from: new Date(),
      to: new Date(),
      limit: 5,
    });
  };

  const handleUpdateLimit = (type: 'incr' | 'decr') => {
    if (type === 'incr') {
      setLimit(_limit => _limit + 10);
    } else {
      setLimit(_limit => (_limit >= 20 ? _limit - 10 : 10));
    }
  };

  return (
    <section css={styles.container}>
      <Header
        centerSection={<StatsSalesHeaderTabs />}
        bottomSection={<StatsSalesHeaderBottom showParentBarCodeFilter />}
      />
      <div css={styles.content}>
        <SalesPieChartList
          data={data}
          title="TURNOVER_OPTIONS"
          Filters={
            <>
              <SearchFilter
                onChange={handleChangeSearch}
                value={search}
                withBorder
              />
            </>
          }
          showHeaderFilters
          isLoading={isLoading}
          columns={OptionsColumns}
          handleExport={handleExport}
          hasPagination
          paginationShowMore
          totalLimit={totalLimit}
          limit={limit}
          updateLimit={handleUpdateLimit}
        />
      </div>
    </section>
  );
});

const styles = {
  container: css(`
        height:100vh;
        overflow-y:auto;
        @media (max-width: 1024px) {
          height: auto;
        }
      `),
  content: css({
    padding: '1.5rem 2vh',
    overflowY: 'auto',
    [M_SCREEN_DESKTOP]: {
      padding: '1rem 1vh',
    },
  }),
};

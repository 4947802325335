/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {SmallSettingsIcon} from '../../assets/svgs/SettingsIcon';
import {THEME} from '../../styles/theme';
import {TextVariant} from './TextVariant';
import {Overlay} from './Overlay';

export const ModuleOption = ({
  option,
  onClick,
  onToggle,
}: {
  option: any;
  onClick?: (option: any) => void;
  onToggle?: (option: any) => void;
}) => {
  const allowToggle = !!option.consumption_mode_id && option?.allowed;

  if (
    !option?.consumption_mode?.label &&
    !option?.timings_enabled &&
    !option?.cards_enabled
  )
    return null;

  return (
    <div className="flex-center" css={styles.container(allowToggle)}>
      <div
        css={styles.moduleOption__container}
        onClick={allowToggle && onToggle ? () => onToggle(option) : undefined}>
        <TextVariant
          text={
            option.consumption_mode?.label ||
            (option?.timings_enabled && !option?.cards_enabled
              ? 'HORAIRES'
              : 'CONSULTATION')
          }
          variant="title03"
          style={[
            styles.moduleOption__container__name,
            {
              background: option?.consumption_mode
                ? option.activated
                  ? THEME.colors.green
                  : THEME.colors.red
                : THEME.colors.primary,
            },
          ]}
        />
      </div>
      <button
        className="flex-center"
        css={styles.moduleOption__setting}
        onClick={onClick ? () => onClick(option) : undefined}>
        <SmallSettingsIcon />
      </button>

      {!option?.allowed && <Overlay />}
    </div>
  );
};

const styles = {
  container: (allowed: boolean) =>
    css({
      position: 'relative',
      cursor: allowed ? 'pointer' : 'not-allowed',
    }),
  moduleOption__container: css({
    background: THEME.colors.bgLight,
    padding: '0.2rem',
    borderRadius: 2,
    marginRight: '0.2rem',
  }),
  moduleOption__container__name: css({
    color: 'white',
    background: THEME.colors.green,
    padding: '0.2rem 0.4rem',
    textTransform: 'uppercase',
    borderRadius: 2,
  }),
  moduleOption__setting: css({
    border: 'none',
    backgroundColor: 'white',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {
  helperFindItemPillByType,
  helperFindItemPillColorByType,
} from '../../helpers/helperFindItemPillByType';

export const ProductType = ({type}: {type: number; bgColor?: string}) => {
  return (
    <p
      className="flex-center textRegular"
      css={[
        styles.container,
        {
          backgroundColor: helperFindItemPillColorByType(type),
        },
      ]}>
      {helperFindItemPillByType(type)}
    </p>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.primary,
    color: 'white',
    height: 14,
    width: 14,
    marginLeft: '2px',
    borderRadius: '50%',
    fontSize: 7,
    fontWeight: '700',
  }),
};

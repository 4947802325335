import {SVGProps} from 'react';

export const ZoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11.838 8.998a2.816 2.816 0 0 0-2.82 2.82 2.86 2.86 0 0 0 2.86 2.861c1.581 0 2.861-1.238 2.861-2.82 0-1.583-1.278-2.861-2.901-2.861Zm.04 3.576a.715.715 0 1 1 0-1.43.715.715 0 0 1 0 1.43Zm9.585 5.503a2.503 2.503 0 0 1-3.828 3.035 2.512 2.512 0 0 1-.687-3.05 2.51 2.51 0 0 1 2.827-1.342C22 13.133 21.563 8.356 18.456 5.245A9.313 9.313 0 0 0 8.264 3.254a4.006 4.006 0 0 0-.941-1.509c-.09-.095-.197-.162-.292-.24C11.39-.511 16.566.354 19.973 3.768c3.9 3.898 4.395 9.906 1.49 14.31Zm-5.03 3.894c.09.095.197.162.292.24-4.358 2.016-9.535 1.151-12.937-2.263-3.903-3.896-4.4-9.904-1.493-14.348a2.503 2.503 0 0 1 .704-3.044 2.503 2.503 0 0 1 3.124.01 2.511 2.511 0 0 1 .687 3.051c-.514 1.05-1.687 1.57-2.827 1.301-2.224 3.587-1.788 8.364 1.318 11.476a9.313 9.313 0 0 0 10.193 1.99c.188.63.506 1.153.94 1.587ZM17.95 5.793c-.743-.743-1.878-.788-2.716-.212-1.05-.56-2.195-.873-3.352-.873a7.15 7.15 0 0 0-6.906 5.302c-.839 3.124.525 6.424 3.33 8.007a7.153 7.153 0 0 0 8.632-1.14c2.28-2.24 2.666-5.704 1.224-8.409a2.07 2.07 0 0 0-.212-2.675Zm-2.53 9.606a5.033 5.033 0 0 1-6.79.353 5.023 5.023 0 0 1-.93-6.732c1.48-2.105 4.358-2.798 6.624-1.508.045.48.224.95.588 1.312.363.364.833.543 1.308.587 1.073 1.912.822 4.366-.8 5.988Z"
      fill="#fff"
    />
  </svg>
);

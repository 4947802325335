import {
  SqsTotalByPaymentMethods,
  SqsTotalByTva,
} from '../../../../../api/fetchSqsHistory';
import {helperFormatPrice} from '../../../../../helpers/helperFormatPrice';

export const columnsTVA = [
  {
    headerName: 'Taux',
    flex: 1,
    valueGetter: (row: SqsTotalByTva) => {
      return `${helperFormatPrice(Number(row.tva_rate))}%`;
    },
  },
  {
    headerName: 'HT',
    flex: 1,
    valueGetter: (row: SqsTotalByTva) => {
      return `${helperFormatPrice(Number(row.amount_ht))}€`;
    },
  },
  {
    headerName: 'TTC',
    flex: 1,
    valueGetter: (row: SqsTotalByTva) => {
      return `${helperFormatPrice(Number(row.amount_ttc))}€`;
    },
  },
  {
    headerName: 'Tickets',
    flex: 1,
    valueGetter: (row: SqsTotalByTva) => {
      return `${Number(row.total_receipts)}`;
    },
  },
];

export const columnsPaymentMode = [
  {
    headerName: 'Mode de règlement',
    field: 'mode',
    flex: 2,
  },
  {
    headerName: 'Code',
    flex: 1,
    field: 'code',
  },
  {
    headerName: 'Montant',
    flex: 1,
    valueGetter: (row: SqsTotalByPaymentMethods) => {
      return helperFormatPrice(Number(row.amount));
    },
  },
  {
    headerName: 'Tickets',
    flex: 1,
    field: 'total_receipts',
  },
];

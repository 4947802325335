import {FilterButton} from '../../../../../components/atoms/FilterButton';
import {AmountMaxFilter} from '../../../../../components/filters/AmountMaxFilter';
import {ChannelIdFilter} from '../../../../../components/filters/ChannelIdFilter';
import {ConsumptionModeFilter} from '../../../../../components/filters/ConsumptionModeFilter';
import {PaymentMethodFilter} from '../../../../../components/filters/PaymentMethodFilter';
import {SearchFilter} from '../../../../../components/filters/SearchFilter';
import {UsersFilter} from '../../../../../components/filters/UsersFilter';
import {useAppSelector} from '../../../../../hooks/useReduxHook';

export const Bottom = () => {
  const dates = useAppSelector(s => s.filter.dates);

  return (
    <>
      <SearchFilter marginRight={'1vh'} />
      <FilterButton
        icon={'calendar'}
        valueType="dates"
        label={'Du :'}
        marginRight={'1vh'}
        // marginLeft={'1vh'}
        // width={'32vh'}
        values={dates}
        hideFilterIcon
      />
      <ChannelIdFilter marginRight={'1vh'} />
      <ConsumptionModeFilter />
      <PaymentMethodFilter />
      <UsersFilter />
      <AmountMaxFilter />
    </>
  );
};

/** @jsxImportSource @emotion/react */

import {Interpolation} from '@emotion/react';
import {CSSProperties} from 'react';

export type TextVariantType =
  | 'bigTitle'
  | 'title01'
  | 'title02'
  | 'title03'
  | 'textSemiBold'
  | 'textMedium'
  | 'textMedium2'
  | 'textBold'
  | 'textRegular'
  | 'textLabel';

interface Props {
  variant: TextVariantType;
  style?: Interpolation<CSSProperties>;
  text: string | number;
}

// .title01 {
// }
// .title02 {
//   font-size: 18px;
//   font-weight: 700;
//   font-family: Montserrat-SemiBold, sans-serif;
//}
// .title03 {
//   font-size: 10px;
//   font-weight: 600;
//   font-family: Montserrat-SemiBold, sans-serif;
// }
// .textSemiBold {
//   font-size: 12px;
//   font-weight: 600;
//   font-family: Montserrat-SemiBold, sans-serif;
// }
// .textRegular {
//   font-size: 12px;
//   font-family: Montserrat-Regular, sans-serif;
// }

// .textLabel {
//   display: block;
//   font-size: 14px;
//   padding: 0 10px;
//   color: #626262;
//   margin-bottom: 5px;
//   font-weight: 600;
//   margin-top: 15px;
//   font-family: Montserrat-SemiBold, sans-serif;
// }

export const TextVariant = ({variant = 'textRegular', style, text}: Props) => {
  //
  return (
    <p css={style} className={variant}>
      {text}
    </p>
  );
};

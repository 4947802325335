/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';

interface IActionButtonProps {
  Icon: any;
  margin?: string | number;
  backgroundColor?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const ActionButton = ({
  Icon,
  margin = 0,
  backgroundColor = THEME.colors.bgLight,
  onClick,
  disabled = false,
}: IActionButtonProps) => {
  const handleClick = (e: any) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      css={styles.button(margin, backgroundColor, disabled)}
      disabled={disabled}
      onClick={handleClick}>
      <Icon />
    </button>
  );
};

const styles = {
  button: (
    margin: string | number,
    backgroundColor: string,
    disabled: boolean,
  ) =>
    css({
      backgroundColor: backgroundColor,
      border: `1px solid ${backgroundColor}`,
      width: 25,
      height: 25,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      borderRadius: 2,
      cursor: 'pointer',
      margin,
      opacity: disabled ? 0.5 : 1,
    }),
};

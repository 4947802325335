/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {columnsOrders} from '../columns';
import {Bottom} from '../HeaderSections/Bottom';
import {useOrders} from '../../../../hooks/useOrders';
import {ORDER_STATUS} from '@bill-app-types/b-types';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {IOrder, selectedOrderAction} from '../../../../redux/order';
import {OrderDetails} from '../OrderDetails';

export const NotCompleted = withRedirectAndNavigation(() => {
  //
  const dispatch = useAppDispatch();
  const selectedOrder = useAppSelector(s => s.order.selectedOrder);

  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [limit] = useState(20);

  const offset = (page - 1) * limit;

  const {data, isLoading, totalOrders} = useOrders({
    orderStatus: [ORDER_STATUS.CREATED],
    offset,
    limit,
  });

  useEffect(() => {
    setRowCount(oldRowCount =>
      totalOrders !== undefined ? totalOrders : oldRowCount,
    );
  }, [totalOrders]);

  const handleRowClick = (order: IOrder) => {
    dispatch(selectedOrderAction(order));
  };

  return (
    <div css={styles.container}>
      <Header bottomSection={<Bottom />} />
      <div css={styles.content}>
        <NewDataGrid
          columns={columnsOrders}
          rows={data}
          rowHeight={35}
          loading={isLoading}
          hasPagination
          page={page}
          setPage={setPage}
          totalRows={rowCount}
          rowPerPage={limit}
          dropdown
          DatagridDropdown={OrderDetails}
          onRowClick={handleRowClick}
          listenedKeyForCloseDropdown={selectedOrder}
        />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: {
    padding: '2vh',
    height: '87%',
  },
};

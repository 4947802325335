/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../../styles/theme';
import {TextVariant} from '../../atoms/TextVariant';
import {Pill} from '../../atoms/Pill';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

export const CustomGraphsTooltip = ({
  active,
  payload,
  label,
  mustBeFormated = false,
}: {
  active?: boolean;
  payload?: any;
  label?: string;
  mustBeFormated?: boolean;
}) => {
  const {t} = useTranslation();

  if (active && payload && payload.length) {
    return (
      <div css={styles.container}>
        {payload.map((item: any, index: number) => {
          const itemTitle =
            item.name === 'sales'
              ? 'TURNOVER'
              : item.name === 'orders'
              ? 'ORDERS'
              : 'MEDIUM_BASKET';
          const bgColor =
            item.name === 'sales'
              ? 'primary'
              : item.name === 'orders'
              ? 'yellowDark'
              : 'green';

          const suffixKey =
            item.name === 'orders'
              ? 'orderSuffix'
              : item.name === 'sales'
              ? 'salesSuffix'
              : 'averageCartSuffix';

          const value =
            mustBeFormated && suffixKey !== 'orderSuffix'
              ? parseFloat(payload[index].value).toLocaleString('fr', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : payload[index].value;
          const suffix = payload[index].payload[suffixKey] || '';

          return (
            <Fragment key={index}>
              <TextVariant
                text={t(itemTitle)}
                variant="textSemiBold"
                style={{
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  margin: '0.2rem 0',
                }}
              />
              <div className="flex flex-align-center" css={styles.content}>
                <Pill backgroundColor={THEME.colors[bgColor]} />
                <div>
                  <TextVariant text={`${label}`} variant="textSemiBold" />
                  <TextVariant
                    text={`${value} ${suffix}`}
                    variant="textSemiBold"
                  />
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  }

  return null;
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.bgDark,
    color: 'white',
    padding: '0.5rem',
    borderRadius: '5px',
  }),
  content: css({
    marginTop: '0.2rem',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Loader} from '../atoms/Loader';
import {THEME} from '../../styles/theme';

export const AbsoluteLoader = ({size}: {size?: number}) => {
  return (
    <div css={styles.container} className='flex-center'>
      <Loader size={size} />
    </div>
  );
};

const styles = {
  container: css({
    background: THEME.colors.white,
    position: 'absolute',
    top: 0,
    width: '100%',
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    opacity: 0.5,
    zIndex: 99,
    flexDirection: 'column',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useEffect, useRef, useState} from 'react';
import {THEME} from '../../styles/theme';
import {CheckboxCustom} from './CheckBoxCustom';
import {TextVariant} from './TextVariant';
import {ChevronDownIcon} from '../../assets/svgs/ChevronDownIcon';

export const MultiSelectCustom = ({
  options,
  values,
  handleSelectItem,
  label = '',
  disabled = false,
}: {
  options?: any;
  values?: number[];
  handleSelectItem?: any;
  label?: string;
  disabled?: boolean;
}) => {
  const btn: any = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleOnClick = () => {
    if (disabled) return;
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: any) => {
    if (btn.current && !btn.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  return (
    <div css={styles.container}>
      {label && <p className="textLabel">{label}</p>}
      <div ref={btn} css={styles.dropdown}>
        <div
          css={styles.dropdownHeader(disabled)}
          className="flex-space-between textRegular"
          onClick={handleOnClick}>
          <div className="flex">
            {values?.length === 0
              ? 'Sélectionner'
              : values?.map((item: any, index: number) => (
                  <TextVariant
                    key={index}
                    text={
                      options.find((option: any) => option.value === item)
                        ?.label + `${index < values.length - 1 ? ',' : ''}`
                    }
                    variant="textSemiBold"
                    style={{paddingRight: index < values.length - 1 ? 5 : 0}}
                  />
                ))}
          </div>
          <div>
            <ChevronDownIcon width={13} height={6} color="black" />
          </div>
        </div>
        {isOpen && (
          <div css={styles.openedArea}>
            {options.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  css={styles.item}
                  className="flex"
                  onClick={() => handleSelectItem(item)}>
                  <CheckboxCustom
                    checked={values?.includes(item.value) || false}
                  />
                  <TextVariant
                    text={item.label}
                    variant="textRegular"
                    style={{paddingLeft: 10}}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
    marginBottom: 15,
  }),
  dropdown: css({
    width: '100%',
    position: 'relative',
  }),
  dropdownHeader: (disabled: boolean) =>
    css({
      height: 39,
      backgroundColor: THEME.colors.white,
      opacity: disabled ? 0.5 : 1,
      cursor: 'pointer',
      borderRadius: 2,
      alignItems: 'center',
      padding: '0 10px',
    }),
  openedArea: css({
    position: 'absolute',
    backgroundColor: THEME.colors.white,
    width: '100%',
    borderRadius: 2,
    zIndex: 1,
  }),
  item: css({
    height: 28,
    backgroundColor: THEME.colors.white,
    cursor: 'pointer',
    borderRadius: 2,
    alignItems: 'center',
    padding: '0 10px',
  }),
};

import {ToggleButtonSections} from '../../../../../components/molecules/ToggleButtonSections';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {useFlagBCatalog} from '../../../../../hooks/useFlagBCatalog';

export const Bottom = () => {
  //
  const {brandHasAuthorization} = useBrandAccess();
  const {flagBCatalogEnabled} = useFlagBCatalog();

  const isChild = brandHasAuthorization(BRAND_AUTHORIZATION.CHILD);

  const sections = isChild
    ? flagBCatalogEnabled
      ? ['PAYMENT_METHODS']
      : ['PAYMENT_METHODS', 'BANKS_AND_ACCCOUNTS']
    : ['VENDOR_STRUCTURES'];

  return (
    <ToggleButtonSections
      sections={sections}
      defaultSection={isChild ? 'PAYMENT_METHODS' : 'VENDOR_STRUCTURES'}
    />
  );
};

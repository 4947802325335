import {IGetGapPaymentMethodsBackofficeResponse} from '@bill-app-types/b-types';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';

export const fetchGapPosTerminalOld = async (data: {
  paymentMethodId?: number;
  consumptionModes: string;
  channels: string;
  from: Date;
  to: Date;
  brandId: Number;
}) => {
  const query = helperGenerateQuery(data);
  const res = await axiosInstance
    .get<{
      data: IGetGapPaymentMethodsBackofficeResponse['posTerminals'];
    }>(`/pos/backoffice/counting-amount${query}`)
    .catch(err => {
      console.log('❌ fetchGapPosTerminal : ', err);
    });

  return res?.data?.data;
};

export const fetchGapPosTerminal = async (data: {
  from: Date;
  to: Date;
  brandId: Number;
}) => {
  const query = helperGenerateQuery(data);
  const res = await axiosInstance
    .get<{
      data: IGetGapPaymentMethodsBackofficeResponse;
    }>(`/pos/backoffice/counting-amount/v2${query}`)
    .catch(err => {
      console.log('❌ fetchGapPosTerminal : ', err);
    });

  return res?.data?.data;
};

export const exportGapPosExcel = async (data: {from: Date; to: Date}) => {
  const query = helperGenerateQuery(data);
  const res = await axiosInstance
    .get<BlobPart>(`/pos/backoffice/counting-amount/export${query}`, {
      responseType: 'blob',
    })
    .catch(err => {
      console.log('❌ exportGapPosExcel : ', err);
    });

  return res?.data;
};

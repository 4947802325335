export const MoreSquareIcon = () => {
  return (
    <svg
      width="36"
      height="35"
      viewBox="0 0 36 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" width="35" height="35" rx="2" fill="#CCD6E0" />
      <path
        d="M24.8779 17.4996C24.8779 17.9932 24.5014 18.3903 24.0334 18.3903H18.4035V24.3278C18.4035 24.8195 18.0253 25.2188 17.5591 25.2188C17.0928 25.2188 16.7146 24.8213 16.7146 24.3278V18.3903H11.0847C10.6185 18.3903 10.2402 17.9917 10.2402 17.5C10.2402 17.0098 10.6185 16.609 11.0847 16.609H16.7146V10.6715C16.7146 10.1798 17.0928 9.78125 17.5591 9.78125C18.0253 9.78125 18.4035 10.1798 18.4035 10.6715V16.609H24.0334C24.5014 16.609 24.8779 17.0098 24.8779 17.4996Z"
        fill="white"
      />
    </svg>
  );
};

import {SVGProps} from 'react';

export const MinusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={15}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#a)">
      <path
        d="M14.005 7.5c0 .39-.331.703-.743.703H1.872c-.41 0-.743-.315-.743-.703 0-.387.333-.703.743-.703h11.39c.412 0 .743.316.743.703Z"
        fill={props.color || '#fff'}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill={props.color || '#fff'}
          transform="translate(.634)"
          d="M0 0h13.867v15H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export const MinusIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 6.82389C15 7.24351 14.6142 7.5811 14.1346 7.5811H0.865385C0.38762 7.5811 0 7.24225 0 6.82421C0 6.40743 0.38762 6.06668 0.865385 6.06668H14.1346C14.6142 6.06668 15 6.40743 15 6.82389Z"
      fill={props.color || '#3C6AF6'}
    />
  </svg>
);

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext} from 'react';
import {ButtonCustom} from '../../../components/atoms/ButtonCustom';
import {UtilsContext} from '../../../contexts/UtilsContext';
import {THEME} from '../../../styles/theme';
import {MODULES_ALLOWED, MODULES_ICONS} from './data';
import {Link} from 'react-router-dom';
import {useAppSelector} from '../../../hooks/useReduxHook';

export const ModuleHeader = ({
  moduleActiveName,
  onSubmit,
  onCancel,
  loading,
  hideButtons = false,
  marginBottom = 0,
}: {
  moduleActiveName: string | null;
  onSubmit?: () => void;
  onCancel?: () => void;
  loading?: boolean;
  hideButtons?: boolean;
  marginBottom?: string | number;
}) => {
  const {t} = useContext<any>(UtilsContext);
  const subscriptions = useAppSelector(
    s => s.myModulesSection.subscriptions.subscriptions,
  );
  return (
    <>
      <div css={[styles.container, {marginBottom}]}>
        {subscriptions.map((module: any, index: number) => {
          const notAllowed = module.subscription_managements.every(
            (subscription: any) => !subscription.allowed,
          );
          const Icon = MODULES_ICONS[module.code as keyof typeof MODULES_ICONS];
          const newModule =
            module.code === 'b_order'
              ? 'b_kiosk'
              : module.code === 'b_connect'
              ? 'b_delivery'
              : module.code;
          return (
            <Link
              key={index}
              to={
                MODULES_ALLOWED.includes(newModule) && !notAllowed
                  ? `/online/modules/${newModule.replace('_', '')}`
                  : '#'
              }
              css={[
                styles.moduleItem,
                newModule.replace('_', '') === moduleActiveName &&
                  styles.moduleItemActive,
              ]}>
              {Icon && (
                <Icon
                  size={'small'}
                  isDisabled={newModule.replace('_', '') !== moduleActiveName}
                  isActive={newModule.replace('_', '') === moduleActiveName}
                />
              )}
            </Link>
          );
        })}
      </div>
      {!hideButtons && (
        <div className="flex flex-end" css={styles.btns}>
          <ButtonCustom
            backgroundColor={THEME.colors.red}
            label={t('CANCEL')}
            onClick={onCancel}
          />
          <ButtonCustom
            backgroundColor={THEME.colors.green}
            label={t('REGISTER')}
            onClick={onSubmit}
            isLoading={loading}
          />
        </div>
      )}
    </>
  );
};

const styles = {
  container: css({
    backgroundColor: 'white',
    display: 'flex',
    gap: '0.3rem',
    overflowX: 'auto',
    minHeight: 35,
    padding: 3,

    // marginBottom: '3rem',
  }),
  moduleItem: css({
    padding: '0 0.6rem',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#CCD6E0',
    fontSize: '12px',
    textAlign: 'center',
    borderRadius: 2,
  }),
  moduleItemActive: css({
    color: '#fff',
    backgroundColor: THEME.colors.primary,
  }),
  btns: css({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '0.5rem',
    alignSelf: 'flex-end',
    margin: '1rem 0',
  }),
};

/* @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {Header} from '../../../../../components/layouts/Header';
import {StatsSalesHeaderBottom} from './StatsSalesHeaderBottom';
import {StatsSalesHeaderTabs} from './StatsSalesHeaderTabs';
// import {ToggleButtonSections} from '../../../../../components/molecules/ToggleButtonSections';
import {ArrowLeft} from '../../../../../assets/svgs/ArrowLeft';
import {TextVariant} from '../../../../../components/atoms/TextVariant';
import {useNavigate} from 'react-router-dom';
import {M_SCREEN_DESKTOP} from '../../../../../constants/ResponsiveSize';
// import {
//   useAppDispatch,
//   useAppSelector,
// } from '../../../../../hooks/useReduxHook';
// import {currentModeAction} from '../../../../../redux/filter';

export const DetailHeader = () => {
  const navigate = useNavigate();
  const goToBack = () => {
    navigate(-1);
  };

  return (
    <div css={styles.container}>
      <Header
        topSection={
          <div css={styles.topSection}>
            <ButtonCustom label="EXPORT DES DONNÉES XLSX" />
          </div>
        }
        centerSection={<StatsSalesHeaderTabs lengthToGetRootLink={-3} />}
        bottomSection={<BottomSection />}
      />

      <div css={styles.btnBack} onClick={goToBack}>
        <ArrowLeft color={'#626262'} />
        <TextVariant
          text={'Retour'}
          variant="title03"
          style={{
            color: '#626262',
          }}
        />
      </div>
    </div>
  );
};

const BottomSection = () => {
  // const dispatch = useAppDispatch();
  // const currentMode = useAppSelector(s => s.filter.currentMode);
  return <StatsSalesHeaderBottom showDateCompare={false} marginTop="0rem" />;
};

const styles = {
  container: css({
    padding: '1.5rem 0',
  }),
  topSection: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  bottomSection: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  }),
  btnBack: css({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '2vh',
    marginTop: '1rem',
    gap: '0.3rem',
    [M_SCREEN_DESKTOP]: {
      paddingLeft: '1vh',
    },
  }),
};

import {SVGProps} from 'react';

export const EyeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1617_8374)">
      <g clipPath="url(#clip1_1617_8374)">
        <path
          d="M5.21648 6.97118C5.21648 5.18404 6.61039 3.73563 8.33028 3.73563C10.0502 3.73563 11.4441 5.18404 11.4441 6.97118C11.4441 8.75832 10.0502 10.2067 8.33028 10.2067C6.61039 10.2067 5.21648 8.75832 5.21648 6.97118ZM8.33028 8.9934C9.40552 8.9934 10.2764 8.08846 10.2764 6.97118C10.2764 5.8539 9.40552 4.94896 8.33028 4.94896C8.31325 4.94896 8.29866 4.94896 8.25974 4.94896C8.31325 5.07788 8.33028 5.21438 8.33028 5.3534C8.33028 6.24571 7.63211 6.97118 6.77338 6.97118C6.63958 6.97118 6.50822 6.95349 6.38415 6.89788C6.38415 6.93832 6.38415 6.95349 6.38415 6.94843C6.38415 8.08846 7.25505 8.9934 8.33028 8.9934ZM3.64547 3.34635C4.79076 2.24019 6.36469 1.30896 8.33028 1.30896C10.2959 1.30896 11.8698 2.24019 13.0156 3.34635C14.1541 4.4434 14.9155 5.7351 15.2755 6.66027C15.3558 6.85996 15.3558 7.0824 15.2755 7.2821C14.9155 8.18451 14.1541 9.47621 13.0156 10.596C11.8698 11.7032 10.2959 12.6334 8.33028 12.6334C6.36469 12.6334 4.79076 11.7032 3.64547 10.596C2.50698 9.47621 1.74605 8.18451 1.38409 7.2821C1.30426 7.0824 1.30426 6.85996 1.38409 6.66027C1.74605 5.7351 2.50698 4.4434 3.64547 3.34635ZM8.33028 2.52229C6.74419 2.52229 5.44028 3.27052 4.44046 4.2336C3.50389 5.13854 2.85728 6.1901 2.52693 6.97118C2.85728 7.72952 3.50389 8.80382 4.44046 9.70877C5.44028 10.6718 6.74419 11.4201 8.33028 11.4201C9.91638 11.4201 11.2203 10.6718 12.2201 9.70877C13.1567 8.80382 13.7819 7.72952 14.1346 6.97118C13.7819 6.1901 13.1567 5.13854 12.2201 4.2336C11.2203 3.27052 9.91638 2.52229 8.33028 2.52229Z"
          fill={props.color || '#3C6AF6'}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1617_8374">
        <rect
          width="14.0121"
          height="13.8667"
          fill="white"
          transform="translate(1.32422 0.5)"
        />
      </clipPath>
      <clipPath id="clip1_1617_8374">
        <rect
          width="14.0121"
          height="12.9422"
          fill="white"
          transform="translate(1.32422 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const EyeMediumIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.05529 4.17121C4.05529 2.9755 5.32433 2.00641 6.89015 2.00641C8.45596 2.00641 9.72501 2.9755 9.72501 4.17121C9.72501 5.36693 8.45596 6.33601 6.89015 6.33601C5.32433 6.33601 4.05529 5.36693 4.05529 4.17121ZM6.89015 5.52421C7.86906 5.52421 8.66193 4.91874 8.66193 4.17121C8.66193 3.42368 7.86906 2.81821 6.89015 2.81821C6.87465 2.81821 6.86136 2.81821 6.82592 2.81821C6.87465 2.90447 6.89015 2.99579 6.89015 3.08881C6.89015 3.68582 6.25452 4.17121 5.47272 4.17121C5.35091 4.17121 5.23131 4.15937 5.11836 4.12217C5.11836 4.14923 5.11836 4.15937 5.11836 4.15599C5.11836 4.91874 5.91124 5.52421 6.89015 5.52421ZM2.62502 1.74596C3.66771 1.00587 5.10065 0.382812 6.89015 0.382812C8.67965 0.382812 10.1126 1.00587 11.1557 1.74596C12.1922 2.47996 12.8854 3.34419 13.2132 3.96319C13.2863 4.0968 13.2863 4.24563 13.2132 4.37924C12.8854 4.98301 12.1922 5.84724 11.1557 6.59646C10.1126 7.33723 8.67965 7.95961 6.89015 7.95961C5.10065 7.95961 3.66771 7.33723 2.62502 6.59646C1.58852 5.84724 0.895756 4.98301 0.566226 4.37924C0.49355 4.24563 0.49355 4.0968 0.566226 3.96319C0.895756 3.34419 1.58852 2.47996 2.62502 1.74596ZM6.89015 1.19461C5.44614 1.19461 4.25905 1.69522 3.34879 2.33959C2.49612 2.94506 1.90745 3.64862 1.60668 4.17121C1.90745 4.67859 2.49612 5.39737 3.34879 6.00284C4.25905 6.6472 5.44614 7.14781 6.89015 7.14781C8.33415 7.14781 9.52125 6.6472 10.4315 6.00284C11.2842 5.39737 11.8534 4.67859 12.1745 4.17121C11.8534 3.64862 11.2842 2.94506 10.4315 2.33959C9.52125 1.69522 8.33415 1.19461 6.89015 1.19461Z"
      fill="#61D295"
    />
  </svg>
);

export const EyeSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.28331 4.16313C3.28331 3.06243 4.18601 2.17036 5.29981 2.17036C6.4136 2.17036 7.3163 3.06243 7.3163 4.16313C7.3163 5.26382 6.4136 6.1559 5.29981 6.1559C4.18601 6.1559 3.28331 5.26382 3.28331 4.16313ZM5.29981 5.40861C5.99613 5.40861 6.56012 4.85126 6.56012 4.16313C6.56012 3.475 5.99613 2.91765 5.29981 2.91765C5.28878 2.91765 5.27933 2.91765 5.25412 2.91765C5.28878 2.99705 5.29981 3.08112 5.29981 3.16674C5.29981 3.71631 4.84767 4.16313 4.29156 4.16313C4.20491 4.16313 4.11984 4.15223 4.0395 4.11798C4.0395 4.14289 4.0395 4.15223 4.0395 4.14912C4.0395 4.85126 4.60349 5.40861 5.29981 5.40861ZM2.26593 1.9306C3.00762 1.24933 4.0269 0.675781 5.29981 0.675781C6.57272 0.675781 7.59199 1.24933 8.334 1.9306C9.07128 2.60628 9.56438 3.40183 9.79753 3.97164C9.84952 4.09463 9.84952 4.23163 9.79753 4.35462C9.56438 4.91042 9.07128 5.70597 8.334 6.39565C7.59199 7.07755 6.57272 7.65048 5.29981 7.65048C4.0269 7.65048 3.00762 7.07755 2.26593 6.39565C1.52865 5.70597 1.03587 4.91042 0.801468 4.35462C0.749771 4.23163 0.749771 4.09463 0.801468 3.97164C1.03587 3.40183 1.52865 2.60628 2.26593 1.9306ZM5.29981 1.42307C4.27266 1.42307 3.42825 1.8839 2.78077 2.47706C2.17424 3.03441 1.75551 3.68206 1.54157 4.16313C1.75551 4.63018 2.17424 5.29185 2.78077 5.8492C3.42825 6.44236 4.27266 6.90319 5.29981 6.90319C6.32696 6.90319 7.17137 6.44236 7.81885 5.8492C8.42537 5.29185 8.83025 4.63018 9.05868 4.16313C8.83025 3.68206 8.42537 3.03441 7.81885 2.47706C7.17137 1.8839 6.32696 1.42307 5.29981 1.42307Z"
      fill={props.color || 'white'}
    />
  </svg>
);

/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { BDeliveryIcon } from "../../../../assets/svgs/BDeliveryIcon";
import { TextVariant } from "../../../../components/atoms/TextVariant";
import { THEME } from "../../../../styles/theme";

export const TopBlock = () => {
  return (
    <div css={styles.container} >
      <img css={styles.image} src='/images/b_connect.jpeg' alt='b_delivery' />
      <BDeliveryIcon size='medium' />
      <TextVariant text={`INTÉGRER LES PLATEFORMES DE LIVRAISON`} variant="title03" style={styles.description}/>
    </div>
  )
}

const styles = {
  container: css({
    display: 'flex',
    backgroundColor: THEME.colors.white,
    marginTop: 20,
    marginBottom: 20,
    alignContent: 'center',
    alignItems: 'center',
    height: 65,
  }),
  image: css({
    width: 65,
    marginRight: 15,
  }),
  description: css({
    color: THEME.colors.gray2,
    marginLeft: 10,
  }),
};
  
export const API_SERVICES = {
  BCORE: '/bcore/protected',
  BUSER: '/buser',
  BCATALOG: '/bcatalog',
  BORDER: '/border',
}


export const FOLDERS ={
  ADMIN: '/admin',
  BACKOFFICE: '/backoffice',
}

export const PATHS = {
  PERIODICAL_PRICES: '/periodical-prices',
  ITEMS: '/items',
  CUSTOMERS: '/customers',
  ORDERS: '/orders',
  ORDER: '/order',
  LOYALTY_GAINS: '/loyalty-gains',
  CARDS: '/cards',
  CARD: '/card',
}

export const ACTIONS = {
  DELETE: '/delete',
  DUPLICATE: '/duplicate',
} 
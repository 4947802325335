/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Center} from './HeaderSections/Center';
import {Header} from '../../../../../components/layouts/Header';
import {useNavigate, useParams} from 'react-router-dom';
import {css} from '@emotion/react';
import {AreaFormContainer} from './AreaFormContainer';
import {AreaLocationsListContainer} from './AreaLocationListContainer';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {useEffect} from 'react';

export const Area = withRedirectAndNavigation(() => {
  const params = useParams();
  const navigate = useNavigate();
  const areaSelected = useAppSelector(
    s => s.myRestaurantSection.areas.areaSelected,
  );

  useEffect(() => {
    if (!areaSelected || areaSelected?.id !== params.id) {
      navigate('/online/restaurant/service-areas');
    }
  }, [areaSelected]);

  if (!areaSelected) return null;

  return (
    <div css={styles.areaContainer}>
      <Header centerSection={<Center />} />
      <div css={styles.areaContent}>
        <AreaFormContainer />
        <AreaLocationsListContainer />
      </div>
    </div>
  );
});

const styles = {
  areaContainer: css({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  }),
  areaContent: css({
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    padding: '0 2vh',
    overflow: 'hidden',
    justifyContent: 'space-between',
  }),
};

import {useTranslation} from 'react-i18next';
import {DeleteModal} from './DeleteModal';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {deleteStaffGroupModalAction} from '../../redux/modals';
import {useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';
import {deleteStaffGroup} from '../../api/fetchStaff';
import {staffGroupSelectedAction} from '../../redux/myStaffSection/groups';

export const DeleteStaffGroupModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);

  const clientQuery = useQueryClient();

  const groupSelected = useAppSelector(
    s => s.myStaffSection.groups.groupSelected,
  );

  const handleSubmit = async () => {
    setLoading(true);
    if (groupSelected) {
      const res = await deleteStaffGroup(groupSelected.uuid);

      if (res && [200, 204].includes(res.status)) {
        clientQuery.invalidateQueries(['fetchStaffGroups']);
        helperDisplayToast('groupe supprimée avec succès', true);
        handleCancel();
      } else {
        helperDisplayToast('Une erreur est survenue', false);
      }
    }
    setLoading(false);
  };

  const handleCancel = () => {
    dispatch(deleteStaffGroupModalAction(false));
    dispatch(staffGroupSelectedAction(null));
  };

  if (!groupSelected) return null;

  return (
    <DeleteModal
      text={groupSelected?.label}
      title={t('DELETION')}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      isLoading={loading}
    />
  );
};

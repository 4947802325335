/* @jsxImportSource @emotion/react */
import {SalesByProductColumns} from '../DataColumns/SalesByProductColumns';
import {SalesPieChartList} from '../../../../../components/organisms/SalesPieChartList';
import {exportSaleByProduct} from '../../../../../api/fetchStats';
import {useStatItems} from '../../../../../hooks/useStatItems';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {useState, useEffect} from 'react';
import {VendorStructureFilter} from '../../../../../components/filters/VendorStructureFilter';
import {CardFilter} from '../../../../../components/filters/CardFilter';
import {PRODUCT_TYPES} from '@bill-app-types/b-types';
import {SearchFilter} from '../../../../../components/filters/SearchFilter';
import {useSearch} from '../../../../../hooks/useSearch';
import {css} from '@emotion/react';
import {M_SCREEN_DESKTOP} from '../../../../../constants/ResponsiveSize';

export const SalesByProduct = () => {
  const compareDates = useAppSelector(s => s.filter.compareDates);
  const isCompare = useAppSelector(s => s.filter.isCompare);
  const dates = useAppSelector(s => s.filter.dates);
  const salesGraphSection = useAppSelector(
    s => s.myStatsSection.sales.salesGraphSection,
  );
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const vendorStructures = useAppSelector(
    state => state.filter.vendorStructure,
  );
  const [limit, setLimit] = useState(10);
  const {search, handleChangeSearch, debounceValue} = useSearch();

  const cards = useAppSelector(state => state.filter.cards);

  const {data, isLoading} = useStatItems({
    limit,
    types: [PRODUCT_TYPES.COURSE],
    consumptionModes,
    vendorStructures,
    dates,
    search: debounceValue,
  });

  const {data: dataCompare} = useStatItems({
    limit,
    types: [PRODUCT_TYPES.COURSE],
    dates: compareDates,
    enabled: !!isCompare,
    consumptionModes,
    vendorStructures,
    search: debounceValue,
  });

  const totalLimit = data?.length || 0;

  const dataWithCompare = data?.map(item => {
    const findItem = dataCompare?.find(_item => {
      return _item.itemId === item.itemId;
    });

    return {
      ...item,
      suffix: '€',
      percent_sales:
        salesGraphSection === 'ORDERS'
          ? item?.orders?.percent
          : item?.sales?.TTC?.total_percent,
      forCompareOrders: findItem?.orders || {
        value: 0,
        percent: 0,
      },
      forCompareSales: findItem?.sales || {
        TTC: {
          total_price: 0,
          total_percent: 0,
        },
      },
    };
  });

  const handleExport = async () => {
    await exportSaleByProduct({from: new Date(), to: new Date()});
  };

  const handleUpdateLimit = (type: 'incr' | 'decr') => {
    if (type === 'incr') {
      setLimit(_limit => _limit + 10);
    } else {
      setLimit(_limit => (_limit >= 20 ? _limit - 10 : 10));
    }
  };

  useEffect(() => {
    setLimit(10);
  }, [vendorStructures, cards]);

  return (
    <SalesPieChartList
      title={'SALES_BY_PRODUCT'}
      data={(isCompare ? dataWithCompare : data) || []}
      isLoading={isLoading}
      columns={SalesByProductColumns}
      marginTop={'2rem'}
      handleExport={handleExport}
      Filters={
        <div css={styles.filters} className="flex">
          <CardFilter withBorder />
          <VendorStructureFilter withBorder />
          <SearchFilter
            onChange={handleChangeSearch}
            value={search}
            withBorder
          />
        </div>
      }
      hasPagination
      paginationShowMore
      totalLimit={totalLimit}
      limit={limit}
      updateLimit={handleUpdateLimit}
    />
  );
};

const styles = {
  filters: css({
    marginLeft: 'auto',
    flexWrap: 'wrap',
    [M_SCREEN_DESKTOP]: {
      marginTop: '1vh',
    },
  }),
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {SettingsIcon} from '../../assets/svgs/SettingsIcon';
import {THEME} from '../../styles/theme';
import {ModuleOption} from '../atoms/ModuleOption';
import {TextVariant} from '../atoms/TextVariant';

export const ModuleDetails = ({
  module,
}: {
  module: {
    Icon: any;
    image: string;
    title: string;
    options: {name: string; color: string}[];
  };
}) => {
  const {Icon, image, title, options} = module;

  return (
    <div css={styles.moduleDetails} className="flex-space-between">
      <div className="flex" css={styles.moduleDetails__blockLeft}>
        <img css={styles.moduleImg} alt="bModuleImg" src={image} />
        <Icon />
        <TextVariant
          variant="title03"
          text={title}
          style={styles.moduleDetails__title}
        />
        <div
          className="flex"
          style={{
            columnGap: '0.5rem',
          }}>
          {options.map((option, index) => {
            return <ModuleOption key={index} option={option} />;
          })}
        </div>
      </div>
      <button className="flex-center" css={styles.moduleDetails__setting}>
        <SettingsIcon />
      </button>
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: 'white',
  }),
  moduleDetails: css({
    background: 'white',
    height: 60,
    paddingRight: 10,
  }),
  moduleDetails__title: css({
    textTransform: 'uppercase',
    color: '#A7A7AB',
  }),
  moduleDetails__blockLeft: css({
    height: 60,
    alignItems: 'center',
    columnGap: 10,
  }),
  moduleDetails__setting: css({
    backgroundColor: THEME.colors.bgLight,
    border: 'none',
    width: 35,
    height: 35,
  }),
  moduleImg: css({
    height: '100%',
    width: 62,
    objectFit: 'cover',
    marginRight: '1rem',
  }),
};

import {combineReducers} from '@reduxjs/toolkit';
import parameters from './parameters';
import archive from './archive';
import vendorStructures from './vendorStructures';

export const myAccountingSection = combineReducers({
  parameters,
  archive,
  vendorStructures
});

import {helperLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';

interface ISubmenuData {
  limit: number;
  offset: number;
}


export const fetchSubmenus = async (data: ISubmenuData) => {
  helperLog({message: 'fetchSubmenus'});
  const query = helperGenerateQuery(data);
  
  const res: any = await axiosInstance
  .get(`/bcatalog/backoffice/modifiers/submenus${query}`)
  .catch(err => {
    helperLog({message: 'fetchSubmenus', type: 'API_ERROR', data: err});
  });
  
  return res?.data?.data;
};

export const createSubmenu = async (data: {
  label: string;
  backofficeLabel: string;
  minimum: number;
  maximum: number;
  allowMultiple: boolean;
  hideOnConsumptionMode: number[];
}) => {
  helperLog({message: 'createSubmenu'});
  
  const res: any = await axiosInstance
  .post(`/bcatalog/backoffice/modifiers/submenus`, data)
  .catch(err => {
    helperLog({message: 'createSubmenu', type: 'API_ERROR', data: err});
  });
  
  return res;
};

export const duplicateSubmenu = async (modifierUuid: string) => {
  helperLog({message: 'duplicateSubmenu'});
  
  const res: any = await axiosInstance
  .put(`/bcatalog/backoffice/modifiers/submenus/${modifierUuid}/duplicate`)
  .catch(err => {
    helperLog({message: 'duplicateSubmenu', type: 'API_ERROR', data: err});
  });
  
  return res;
};

export const deleteSubmenu = async (modifierUuid: string) => {
  helperLog({message: 'deleteSubmenu'});
  
  const res: any = await axiosInstance
  .delete(`/bcatalog/backoffice/modifiers/submenus/${modifierUuid}`)
  .catch(err => {
    helperLog({message: 'deleteSubmenu', type: 'API_ERROR', data: err});
  });
  
  return res;
};

export const fetchSubmenuProducts = async (modifierUuid: string) => {
  helperLog({message: 'fetchSubmenuProducts'});
  
  const res: any = await axiosInstance
  .get(`/bcatalog/backoffice/modifiers/submenus/${modifierUuid}/courses`)
  .catch(err => {
    helperLog({message: 'fetchSubmenuProducts', type: 'API_ERROR', data: err});
  });
  
  return res?.data?.data;
};

export const addSubmenuProducts = async (modifierUuid: string, data: {
  itemUuid: string,
  price: any,
  position: number
}) => {
  helperLog({message: 'addSubmenuProducts'});
  
  const res: any = await axiosInstance
  .post(`/bcatalog/backoffice/modifiers/submenus/${modifierUuid}/courses`, data)
  .catch(err => {
    helperLog({message: 'addSubmenuProducts', type: 'API_ERROR', data: err});
  });
  
  return res;
};

export const removeSubmenuProducts = async (modifierUuid: string, iteemUuid: string) => {
  helperLog({message: 'removeSubmenuProducts'});
  
  const res: any = await axiosInstance
  .delete(`/bcatalog/backoffice/modifiers/submenus/${modifierUuid}/courses/${iteemUuid}`)
  .catch(err => {
    helperLog({message: 'removeSubmenuProducts', type: 'API_ERROR', data: err});
  });
  
  return res;
};

export const updateSubmenuProduct = async (modifierUuid: string | null, itemUuid: string, data: {
  price?: number;
  position?: number
}) => {
  helperLog({message: 'updateSubmenuProduct'});
  
  const res: any = await axiosInstance
  .put(`/bcatalog/backoffice/modifiers/submenus/${modifierUuid}/courses/${itemUuid}`, data)
  .catch(err => {
    helperLog({message: 'updateSubmenuProduct', type: 'API_ERROR', data: err});
  });
  
  return res;
};
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../hooks/useReduxHook';
import {createCardModalAction} from '../../redux/modals';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {helperDisplayToast} from '../../helpers/helperToast';
import {createCard} from '../../api/fetchCards';
import {useQueryClient} from '@tanstack/react-query';
import {ICardCreationPayload} from '@bill-app-types/b-types';

export const AddCardModal = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();

  const initialValues = {
    label: '',
    timings: null,
  };

  const onCancel = () => {
    dispatch(createCardModalAction(false));
  };
  const onSubmit = async (
    data: ICardCreationPayload,
    {setSubmitting, resetForm}: any,
  ) => {
    if (data.label) {
      const res = await createCard(data);
      if (res && res.status === 204) {
        resetForm();
        onCancel();
        clientQuery.invalidateQueries(['fetchCards']);
        helperDisplayToast('Carte ajoutée', true);
      } else {
        helperDisplayToast(
          res?.data?.message || 'Impossible de créer la carte',
          false,
        );
      }
    } else {
      helperDisplayToast('Tous les champs doivent être complétés ', false);
    }
  };

  return (
    <ModalWrapper
      title={t('MAP')}
      minWidth="25%"
      validateLabel={t('REGISTER_MAP')}
      onCancel={onCancel}
      onSubmit={onSubmit}
      initialValues={initialValues}>
      <FieldCustomInput label={t('NAME_OF_MAP')} name="label" required />
      <FieldsRequired />
    </ModalWrapper>
  );
};

import {useQuery} from '@tanstack/react-query';
import {fetchGapPosTerminalOld} from '../api/fetchGapPos';
import {useAppSelector} from './useReduxHook';
//import {useFlagBCatalog} from './useFlagBCatalog';
import {helperJoinFilterValues} from '../helpers/helperFilter';
import {PAYMENT_METHODS} from '@bill-app-types/b-types';

export const useGapPosOld = () => {
  const dates = useAppSelector(s => s.filter.dates);
  const brandId = useAppSelector(s => s.brand.brand?.id);
  //const {flagBCatalogEnabled} = useFlagBCatalog();
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const channels = useAppSelector(s => s.filter.channels);

  // TODO : provisoire

  const {data, isLoading} = useQuery(
    ['gapPosOld', brandId, dates, consumptionModes, channels],
    () =>
      fetchGapPosTerminalOld({
        ...dates,
        consumptionModes: helperJoinFilterValues(consumptionModes, true),
        channels: helperJoinFilterValues(channels),
        paymentMethodId: PAYMENT_METHODS.ESPECES,
        brandId: brandId,
      }),
    {
      enabled: !!brandId,
    },
  );

  return {
    gapPosLoading: isLoading,
    gapPos: data,
  };
};

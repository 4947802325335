import {THEME} from '../../styles/theme';
import {ValueSeparatorPercent} from '../atoms/ValueSeparatorPercent';

export const CompareValues = ({
  row,
  compareKey,
}: {
  row: any;
  compareKey: 'orders' | 'sales';
}) => {
  const forCompareKey =
    compareKey === 'orders' ? 'forCompareOrders' : 'forCompareSales';

  return (
    <div className="flex" style={{gap: '0.5rem'}}>
      <ValueSeparatorPercent
        value={
          compareKey === 'orders'
            ? row[compareKey]?.value
            : row[compareKey]?.TTC.total_price
        }
        percent={
          compareKey === 'orders'
            ? row[compareKey]?.percent
            : row[compareKey]?.TTC.total_percent
        }
        color={THEME.colors[compareKey === 'orders' ? 'yellowDark' : 'primary']}
        formatValue={compareKey !== 'orders'}
        suffix={compareKey === 'orders' ? '' : '€'}
      />
      {row[forCompareKey] && (
        <ValueSeparatorPercent
          value={
            forCompareKey === 'forCompareOrders'
              ? row.forCompareOrders?.value
              : row.forCompareSales?.TTC.total_price
          }
          percent={
            forCompareKey === 'forCompareOrders'
              ? row.forCompareOrders?.percent
              : row.forCompareSales?.TTC.total_percent
          }
          color={THEME.colors.lightGray}
          formatValue={forCompareKey !== 'forCompareOrders'}
          suffix={forCompareKey === 'forCompareOrders' ? '' : '€'}
        />
      )}
    </div>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useState} from 'react';
import {THEME} from '../../styles/theme';
import {TextVariant} from '../atoms/TextVariant';
import {ArrowUpIcon} from '../../assets/svgs/ArrowUp';
import {ArrowMixVerticalIcon} from '../../assets/svgs/ArrowMixVertical';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {
  fromDateAction,
  fromTimeAction,
  toDateAction,
  toTimeAction,
  typeFilterDateAction,
} from '../../redux/date';

export const DateTypeFilter = ({
  list,
  type,
}: {
  list: {label: string; value: string}[];
  type?: string;
}) => {
  //
  const dispatch = useAppDispatch();
  const [showTypeDateList, setShowTypeDateList] = useState(false);
  const typeFilterDate = useAppSelector(s => s.date.typeFilterDate);
  const typeSelected = list.find(item => item.value === typeFilterDate);

  const handleSelectTypeDate = (value: string) => {
    dispatch(typeFilterDateAction(value));
    let fromDate: Date | null = new Date();
    let toDate: Date | null = new Date();
    if (type === 'date') {
      switch (value) {
        case 'between':
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(23, 59, 59, 0);
          toDate.setDate(toDate.getDate() + 1);
          break;

        case 'before':
          fromDate = null;
          break;

        case 'after':
          fromDate.setHours(0, 0, 0, 0);
          toDate = null;
          break;
        case 'period':
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(23, 59, 59, 0);
          toDate.setDate(toDate.getDate() + 1);
          break;
        default:
          break;
      }

      dispatch(fromDateAction(fromDate));
      dispatch(fromTimeAction(fromDate));

      dispatch(toDateAction(toDate));
      dispatch(toTimeAction(toDate));
    }
    setShowTypeDateList(false);
  };

  return (
    <div css={styles.dateTypeContainer}>
      <div
        css={[styles.dateTypeItem, styles.dateTypeItemSelected]}
        className="flex-space-between"
        onClick={() => setShowTypeDateList(!showTypeDateList)}>
        <TextVariant
          text={typeSelected ? typeSelected.label : 'Choisir'}
          variant="textSemiBold"
          style={{
            color: typeSelected ? THEME.colors.primary : '#A7A7AB',
            marginRight: '0.5rem',
          }}
        />
        <ArrowMixVerticalIcon
          color={typeSelected ? THEME.colors.primary : undefined}
        />
      </div>

      <div
        css={[
          styles.dateTypeItems,
          showTypeDateList && styles.dateTypeItemsActive,
        ]}>
        {list.map((item, index) => {
          return (
            <div
              css={styles.dateTypeItem}
              key={index}
              onClick={() => handleSelectTypeDate(item.value)}
              className="flex-space-between">
              <TextVariant
                text={item.label}
                variant="textSemiBold"
                style={{
                  color: THEME.colors.primary,
                }}
              />
              <ArrowUpIcon />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  dateTypeContainer: css({
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '0.5rem 0',
  }),
  dateTypeItem: css({
    minWidth: '7rem',
    padding: '5px 10px',

    width: 'auto',
    backgroundColor: '#fff',
    cursor: 'pointer',
  }),
  dateTypeItemSelected: css({
    border: `1px solid ${THEME.colors.bgLight}`,
    borderRadius: 2,
  }),
  dateTypeItems: css({
    zIndex: 1,
    position: 'absolute',
    height: 0,
    overflow: 'hidden',
    transition: 'all 0.3s ease',
    top: '1.7rem',
  }),
  dateTypeItemsActive: css({
    height: 150,
    transition: 'all 0.3s ease',
  }),
};

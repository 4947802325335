/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';

export const HeaderForm = ({title}: {title: string}) => {
  return (
    <div css={styles.container}>
      <h2 className="title02" css={styles.title}>
        {title}
      </h2>
    </div>
  );
};

const styles = {
  container: css({
    padding: '1rem 5px',
    borderBottom: `2px solid ${THEME.colors.lightGray}`,
  }),
  title: css({
    color: 'var(--text-color)',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {columnsUsers} from './column';
import {Bottom} from './HeaderSections/Bottom';
import {Center} from './HeaderSections/Center';
import {useStaffUsers} from '../../../../hooks/useStaffUsers';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {useAppDispatch} from '../../../../hooks/useReduxHook';
import {
  isUpdatedModeAction,
  userSelectedAction,
} from '../../../../redux/myStaffSection/users';
import {
  addUserModalAction,
  deleteUserModalAction,
} from '../../../../redux/modals';
import {updateStaffUser} from '../../../../api/fetchStaff';
import {helperDisplayToast} from '../../../../helpers/helperToast';

export const Users = withRedirectAndNavigation(() => {
  const {staffUsersData, staffUsersLoading, refetch} = useStaffUsers();
  const dispatch = useAppDispatch();

  const handleUpdateUser = (user: any) => {
    dispatch(isUpdatedModeAction(true));
    dispatch(userSelectedAction(user));
    dispatch(addUserModalAction(true));
  };

  const handleDeleteUser = (user: any) => {
    dispatch(userSelectedAction(user));
    dispatch(deleteUserModalAction(true));
  };

  const handleToggleArchiveUser = async (user: any) => {
    try {
      const res = await updateStaffUser(user?.uuid, {
        ...user,
        isArchived: !user?.isArchived,
      });

      if (res?.status === 200 || res?.status === 204) {
        helperDisplayToast(
          !user?.isArchived ? 'Utilisateur archivé' : 'Utilisateur désarchivé',
          true,
        );
        refetch();
      }
    } catch (error: any) {
      helperDisplayToast(error?.message, false);
    }
  };

  const staffUsersDataWithActions = staffUsersData?.map((user: any) => {
    return {
      ...user,
      actions: {
        onUpdate: () => {
          handleUpdateUser(user);
        },
        onDelete: () => {
          handleDeleteUser(user);
        },
        onToggle: () => {
          handleToggleArchiveUser(user);
        },
      },
    };
  });

  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} bottomSection={<Bottom />} />
      <div css={styles.content}>
        <NewDataGrid
          columns={columnsUsers}
          rows={staffUsersDataWithActions}
          rowHeight={60}
          loading={staffUsersLoading}
        />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: {
    padding: '2vh',
    height: '87%',
  },
};

import {IFetchAdminUserDetailsResponse} from '@bill-app-types/b-types';
import {createSlice} from '@reduxjs/toolkit';

export interface IStaffUser {
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  groupId: string;
  isArchived?: boolean;
  companyIdGuid?: string;
  uuid?: string;
}

const initialState: {
  userSelected: IFetchAdminUserDetailsResponse | null;
  isUpdatedMode: boolean;
} = {
  userSelected: null,
  isUpdatedMode: false,
};

export const slice = createSlice({
  name: 'myStaffSection',
  initialState: initialState,
  reducers: {
    userSelectedAction: (state, action) => {
      state.userSelected = action.payload;
    },
    isUpdatedModeAction: (state, action) => {
      state.isUpdatedMode = action.payload;
    },
  },
});

export const {userSelectedAction, isUpdatedModeAction} = slice.actions;

export default slice.reducer;

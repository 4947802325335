/** @jsxImportSource @emotion/react */
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {columnsMenus} from './columns';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {css} from '@emotion/react';
import {useQuery} from '@tanstack/react-query';
import {useDebounce} from '../../../../hooks/useDebounce';
import {useEffect, useState} from 'react';
import {fetchMenuDetails, fetchMenus} from '../../../../api/fetchMenus';
import {useNavigate} from 'react-router-dom';
import {menuPreRegisterAction} from '../../../../redux/myCatalogSection/menus';
import {helperFormatPrice} from '../../../../helpers/helperFormatPrice';

export const Menus = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const search = useAppSelector(s => s.filter.search);
  const orderBy = useAppSelector(s => s.filter.orderBy);
  const isArchived = useAppSelector(s => s.filter.isArchived);

  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [limit] = useState(20);

  const offset = (page - 1) * limit;

  const debouncedSearchTerm = useDebounce(search, 500);

  const {data, isLoading} = useQuery(
    ['fetchMenus', debouncedSearchTerm, offset, limit, orderBy, isArchived],
    () =>
      fetchMenus({
        search: debouncedSearchTerm,
        offset,
        limit,
        orderBy,
        isArchived,
      }),
  );

  useEffect(() => {
    setRowCount(oldRowCount =>
      data?.totalItems !== undefined ? data?.totalItems : oldRowCount,
    );
  }, [data?.totalItems]);

  const dataItems = data?.items?.map((item: any) => {
    return {
      ...item,
      handleClickEdit: async () => {
        const data = await fetchMenuDetails(item.uuid);
        if (data) {
          dispatch(
            menuPreRegisterAction({
              ...data,
              price: helperFormatPrice(data.price, 'en'),
            }),
          );
          navigate('/online/catalog/menus/edit');
        }
      },
    };
  });

  return (
    <div css={styles.container}>
      <NewDataGrid
        rows={dataItems}
        hasPagination
        page={page}
        loading={isLoading}
        setPage={setPage}
        totalRows={rowCount}
        rowPerPage={limit}
        columns={columnsMenus}
        rowHeight={88}
      />
    </div>
  );
};

const styles = {
  container: css({
    padding: '2vh',
    height: '87%',
  }),
};

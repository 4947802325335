import {
  IFetchRecipesResponse,
  IRecipePatchPayload,
  IRecipePutPayload,
  IRecipesFetchQuery,
} from '@bill-app-types/b-types';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';

export const fetchRecipes = async (data: IRecipesFetchQuery) => {
  const query = helperGenerateQuery(data);
  const res = await axiosInstance
    .get<{
      data: IFetchRecipesResponse;
    }>(`bcatalog/backoffice/recipes${query}`, {
      params: {orderBy: data?.orderBy, isArchived: data?.isArchived},
    })
    .catch(err => {
      console.log('❌ fetchRecipes : ', err);
    });

  return res?.data?.data;
};

export const createRecipe = async (data: IRecipePutPayload) => {
  const res = await axiosInstance
    .post(`bcatalog/backoffice/recipes`, data)
    .catch(err => {
      console.log('❌ createRecipe : ', err);
    });

  return res;
};

export const updateRecipe = async (uuid: string, data: IRecipePatchPayload) => {
  const res = await axiosInstance
    .put(`bcatalog/backoffice/recipes/${uuid}`, data)
    .catch(err => {
      console.log('❌ updateRecipe : ', err);
    });

  return res;
};

export const deleteRecipe = async (uuid: string) => {
  const res = await axiosInstance
    .delete(`bcatalog/backoffice/recipes/${uuid}`)
    .catch(err => {
      console.log('❌ deleteRecipe : ', err);
    });

  return res;
};

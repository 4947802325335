/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext} from 'react';
import {Title} from '../../../../../components/atoms/Title';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {usePosGiftsByUser} from '../../../../../hooks/usePosGiftsByUser';
import {columnsOffersWaiter} from './columns';

export const GiftsByWaiter = () => {
  const {t}: any = useContext(UtilsContext);

  const {data, isLoading} = usePosGiftsByUser();

  return (
    <div css={styles.container}>
      <Title title={t('GIFTS_BY_WAITER')} />
      <div css={styles.content}>
        <NewDataGrid
          columns={columnsOffersWaiter}
          rows={data || []}
          loading={isLoading}
          rowHeight={35}
          // minHeight={200}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    height: '50%',
    paddingTop: '2vh',
    '@media (max-width: 1024px)': {
      maxHeight: 300,
      marginBottom: '5%',
    },
  }),
  content: css({
    height: '84%',
  }),
};

// TODO : https://v5.reactrouter.com/web/example/query-parameters
// for gestion route and section
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ArrowLeft} from '../../../../assets/svgs/ArrowLeft';
import {MenuIcon} from '../../../../assets/svgs/MenuIcon';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {isOpenAction} from '../../../../redux/sidebar';
import {THEME} from '../../../../styles/theme';
import {flexCenter} from '../../../../styles/utils';
import {SideBarContent} from './SideBarContent';
import {SideBarFooter} from './SideBarFooter';
import {SideBarHeader} from './SideBarHeader';
import {SideBarContentOld} from './SideBarContentOld';
import {useFlagBCatalog} from '../../../../hooks/useFlagBCatalog';

export const SideBar = () => {
  //
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(s => s.sidebar.isOpen);
  const {flagBCatalogEnabled} = useFlagBCatalog();

  return (
    <div css={styles.container(isOpen)}>
      <button
        onClick={() => dispatch(isOpenAction(!isOpen))}
        css={[styles.toggleButton(isOpen), flexCenter]}>
        {isOpen ? <ArrowLeft /> : <MenuIcon />}
      </button>
      <SideBarHeader />
      {flagBCatalogEnabled ? <SideBarContent /> : <SideBarContentOld />}
      <SideBarFooter />
    </div>
  );
};

const styles = {
  container: (isOpen: boolean) =>
    css(`
      display: flex;
      flex-direction: column;
      justifyContent: space-between;
      position: relative;
      background-color: var(--sidebar-bg-color);
      width: ${isOpen ? '190px' : '50px'};
      transition-duration: 0.5s;

      @media (max-width: 1024px) {
        position: fixed;
        height: 100vh;
        z-index: 100;
        width:250px;
        left:${isOpen ? '0' : '-250px'};
      }

      @media print {
        display: none;
      }
    `),
  toggleButton: (isOpen: boolean) =>
    css(`
    position: absolute;
    top: 0;
    right: -3vh;
    width: 3vh;
    z-index: 4;
    height: 3vh;
    border: none;
    border-radius: 0px 2px 2px 0px;
    background-color:${THEME.colors.primary};
    cursor: pointer;
    z-index: 1000;

    @media (max-width: 1024px) {
      display: ${!isOpen ? 'none' : 'flex'};
    }
  `),
};

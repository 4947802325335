import {
  ICategoryFetchReturn,
  IFetchStructureDetailsResponse,
  STRUCTURE_TYPES,
} from '@bill-app-types/b-types';
import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  categorySelected: (ICategoryFetchReturn & {type: STRUCTURE_TYPES}) | null;
  subCategorySelected: ICategoryFetchReturn | null;
  categoryProducts: any[];
  updateMode: boolean;
  updatedCategory: any;
  structureSelected: IFetchStructureDetailsResponse | null;
}

const initialState: ISlice = {
  categorySelected: null,
  subCategorySelected: null,
  categoryProducts: [],
  updateMode: false,
  updatedCategory: null,
  structureSelected: null,
};

const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    categorySelectedAction(state, action) {
      state.categorySelected =
        state.categorySelected?.uuid &&
        state.categorySelected?.uuid === action.payload.uuid
          ? null
          : action.payload;
    },
    subCategorySelectedAction(state, action) {
      state.subCategorySelected =
        state.subCategorySelected?.uuid === action.payload.uuid
          ? null
          : action.payload;

      if (!state.subCategorySelected) {
        state.categoryProducts = [];
      }
    },
    categoryProductsAction(state, action) {
      state.categoryProducts = action.payload;
    },
    updateModeAction(state, action) {
      state.updateMode = action.payload;
    },
    updatedCategoryAction(state, action) {
      state.updatedCategory = action.payload;
    },
    structureSelectedAction(state, action) {
      state.structureSelected = action.payload;
    },
    resetCategorySelectedAction(state) {
      state.categorySelected = null;
      state.categoryProducts = [];
    },
  },
});

export const {
  categorySelectedAction,
  subCategorySelectedAction,
  categoryProductsAction,
  updateModeAction,
  updatedCategoryAction,
  structureSelectedAction,
  resetCategorySelectedAction,
} = slice.actions;

export default slice.reducer;

import {SVGProps} from 'react';

export const CustomerFileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="11"
    height="16"
    viewBox="0 0 11 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.30559 10.834H3.38322C3.20984 10.834 3.04356 10.9086 2.92096 11.0414C2.79837 11.1743 2.72949 11.3545 2.72949 11.5423C2.72949 11.7302 2.79837 11.9103 2.92096 12.0432C3.04356 12.176 3.20984 12.2507 3.38322 12.2507H7.30559C7.47896 12.2507 7.64524 12.176 7.76784 12.0432C7.89044 11.9103 7.95931 11.7302 7.95931 11.5423C7.95931 11.3545 7.89044 11.1743 7.76784 11.0414C7.64524 10.9086 7.47896 10.834 7.30559 10.834Z"
      fill="#CCD6E0"
    />
    <path
      d="M3.38322 9.41667H5.3444C5.51778 9.41667 5.68406 9.34204 5.80666 9.2092C5.92926 9.07636 5.99813 8.8962 5.99813 8.70833C5.99813 8.52047 5.92926 8.3403 5.80666 8.20747C5.68406 8.07463 5.51778 8 5.3444 8H3.38322C3.20984 8 3.04356 8.07463 2.92096 8.20747C2.79837 8.3403 2.72949 8.52047 2.72949 8.70833C2.72949 8.8962 2.79837 9.07636 2.92096 9.2092C3.04356 9.34204 3.20984 9.41667 3.38322 9.41667V9.41667Z"
      fill="#CCD6E0"
    />
    <path
      d="M10.4039 5.39989L6.84766 1.14989C6.78654 1.07652 6.71192 1.01781 6.6286 0.977552C6.54529 0.937295 6.45512 0.916377 6.3639 0.916143H1.7878C1.5706 0.913336 1.35502 0.956915 1.15336 1.04439C0.951702 1.13187 0.767923 1.26153 0.612517 1.42597C0.457111 1.59041 0.333122 1.78641 0.24763 2.00277C0.162138 2.21914 0.116818 2.45163 0.114258 2.68698V13.312C0.116818 13.5473 0.162138 13.7798 0.24763 13.9962C0.333122 14.2125 0.457111 14.4085 0.612517 14.573C0.767923 14.7374 0.951702 14.8671 1.15336 14.9546C1.35502 15.042 1.5706 15.0856 1.7878 15.0828H8.90036C9.11756 15.0856 9.33315 15.042 9.5348 14.9546C9.73646 14.8671 9.92024 14.7374 10.0756 14.573C10.2311 14.4085 10.355 14.2125 10.4405 13.9962C10.526 13.7798 10.5713 13.5473 10.5739 13.312V5.87448C10.5735 5.69893 10.5129 5.5298 10.4039 5.39989V5.39989ZM6.65154 3.04114L8.44275 5.16614H7.1353C7.06684 5.16167 6.99989 5.14246 6.93835 5.10964C6.87682 5.07682 6.82194 5.03106 6.7769 4.97501C6.73187 4.91896 6.69759 4.85376 6.67607 4.7832C6.65454 4.71264 6.6462 4.63815 6.65154 4.56406V3.04114ZM8.90036 13.6661H1.7878C1.74229 13.669 1.69669 13.6621 1.65364 13.6459C1.61059 13.6296 1.57092 13.6043 1.53691 13.5714C1.5029 13.5385 1.47522 13.4986 1.45545 13.4541C1.43568 13.4096 1.42422 13.3613 1.42171 13.312V2.68698C1.42422 2.63765 1.43568 2.58934 1.45545 2.54483C1.47522 2.50031 1.5029 2.46046 1.53691 2.42755C1.57092 2.39465 1.61059 2.36935 1.65364 2.35309C1.69669 2.33683 1.74229 2.32994 1.7878 2.33281H5.34408V4.56406C5.33353 5.08583 5.51389 5.59095 5.8458 5.96916C6.1777 6.34737 6.63421 6.56797 7.11568 6.58281H9.26645V13.312C9.26395 13.3613 9.25248 13.4096 9.23271 13.4541C9.21295 13.4986 9.18526 13.5385 9.15125 13.5714C9.11724 13.6043 9.07758 13.6296 9.03452 13.6459C8.99147 13.6621 8.94588 13.669 8.90036 13.6661Z"
      fill="#CCD6E0"
    />
  </svg>
);

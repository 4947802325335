import {useQuery} from '@tanstack/react-query';
import {TranslationItem} from './TranslationItem';
import {fetchItemsTranslations} from '../../../../api/fetchTranslations';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {useTranslations} from '../../../../hooks/useTranslations';

export const Items = ({title, type}: {title: string; type: number}) => {
  const {
    isOpened,
    handleToggleIsOpened,
    handleChangeSearch,
    handleToggleShowAll,
    showAll,
    debounceSearch,
  } = useTranslations();
  const languageSelected = useAppSelector(
    s => s.myTranslationsSection.translations.languageSelected,
  );

  const {data, isLoading} = useQuery(
    [
      `fetchItemsTranslations-${type}`,
      type,
      debounceSearch,
      !showAll ? languageSelected?.id : undefined,
      showAll,
    ],
    () =>
      fetchItemsTranslations({
        type,
        search: debounceSearch,
        languageId:
          !showAll && languageSelected
            ? Number(languageSelected?.id)
            : undefined,
      }),
    {
      enabled: !!type && isOpened && !!languageSelected,
    },
  );

  return (
    <TranslationItem
      title={title}
      items={data || []}
      isLoading={isLoading}
      isOpened={isOpened}
      showAll={showAll}
      onToggleIsOpened={handleToggleIsOpened}
      onToggleShowAll={handleToggleShowAll}
      onChange={handleChangeSearch}
      category={'Items'}
      categoryType={type}
    />
  );
};

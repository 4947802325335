export enum BRAND_AUTHORIZATION {
  ALL = 'ALL',
  PARENT = 'PARENT',
  CHILD = 'CHILD'
}

export const GLOBAL_FRONT_POLICIES = [
  {
    code: 'STATS',
    policies: [
      //Acces 0
      ['view-stats', 'update-stats'],
      //Access 1
      ['delete-stats']
    ]
  },
  {
    code: 'BRAND',
    policies: [
      //Acces 0
      ['view-brand', 'update-brand'],
      //Acces 1
      ['delete-brand']
    ]
  }
]

import {BRAND_AUTHORIZATION} from '../../../constants/frontPolicies';
import {brandHasAuthorizationForRouting} from '../../../helpers/helperFrontPolicies';
import {GestionMenu} from './GestionMenu';
import {Recipes} from './Recipes';
import {MyCardRouter} from './Cards';
import {CreateProductRouter} from './ProductCreate';
import {Products} from './Products';
import {MyOptionRouter} from './Options';
import {MyStructureRouter} from './TreeStructure';
import {EditMenuRouter} from './GestionMenu/EditMenu';
import {RuptureGestion} from './RuptureGestion';

export const MyCatalogRouter = {
  path: 'catalog',
  children: [
    {
      path: 'products',
      element: <Products />,
      loader: () => brandHasAuthorizationForRouting(BRAND_AUTHORIZATION.PARENT),
    },
    {
      path: 'rupture-gestion',
      loader: () => brandHasAuthorizationForRouting(BRAND_AUTHORIZATION.CHILD),
      element: <RuptureGestion />,
    },
    {
      path: 'recipes',
      element: <Recipes />,
      loader: () => brandHasAuthorizationForRouting(BRAND_AUTHORIZATION.PARENT),
    },
    {
      path: 'menus',
      element: <GestionMenu />,
    },
    EditMenuRouter,
    CreateProductRouter,
    MyCardRouter,
    MyOptionRouter,
    MyStructureRouter,
  ],
};

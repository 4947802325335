import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  updatedItem: any;
}

const initialState: ISlice = {
  updatedItem: null,
};

const subscriptionsSlice = createSlice({
  name: 'loyalties',
  initialState: initialState,
  reducers: {
    updatedItemAction: (state, action) => {
      state.updatedItem = action.payload;
    },
  },
});

export const {
  updatedItemAction,
} =
  subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;

/**  @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {CumulPrice} from './CumulPrice';
import {useTranslation} from 'react-i18next';
import {IStatItemDetail} from '../../api/fetchStats';
import {useAppSelector} from '../../hooks/useReduxHook';
import {TextVariant} from '../atoms/TextVariant';
import {PillLabel} from './PillLabel';
import {THEME} from '../../styles/theme';
import {helperGetChannelNamebyId} from '../../helpers/helperChannel';
import {M_SCREEN_DESKTOP} from '../../constants/ResponsiveSize';

export const ChannelsModeDetail = ({
  channels,
}: {
  channels: IStatItemDetail['channels'];
}) => {
  const currentMode = useAppSelector(s => s.filter.currentMode);
  const {t} = useTranslation();

  return (
    <div css={styles.container}>
      <div>
        <TextVariant
          text={t('ORDER_CHANNELS')}
          variant="textSemiBold"
          style={styles.title}
        />
        <PillLabel
          text={`CA ${currentMode}`}
          backgroundColor={THEME.colors.primary}
        />
      </div>

      <div css={styles.channels}>
        {channels?.map(channel => {
          if (!isNaN(Number(channel))) return null;
          return (
            <CumulPrice
              label={helperGetChannelNamebyId(channel.id)}
              value={channel.sales?.[currentMode].total_price}
              secondValue={channel.sales.orders}
            />
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    minHeight: '13.337rem',
    flex: 1,
    borderRadius: '0.13rem',
    padding: '0.94rem',
  }),

  title: css({marginBottom: '0.4rem'}),

  channels: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: '1.5rem',
    [M_SCREEN_DESKTOP]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  }),
};

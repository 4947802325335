/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext, useState} from 'react';
import {DownLoadIcon} from '../../../../../assets/svgs/DownloadIcon';
import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {InputLabel} from '../../../../../components/atoms/InputLabel';
import {RequiredLabel} from '../../../../../components/atoms/RequiredLabel';
import {TextVariant} from '../../../../../components/atoms/TextVariant';
import {Title} from '../../../../../components/atoms/Title';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {createCatalogPictogram} from '../../../../../api/fetchCatalogPictograms';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';

export const AddPictogram = () => {
  const {t} = useContext<any>(UtilsContext);
  const [label, setLabel] = useState('');
  const [file, setFile] = useState<File | null>(null);

  const clientQuery = useQueryClient();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {files} = e.target;
    if (files && files[0]) {
      setFile(files[0]);
    }
  };

  const handleSubmit = async () => {
    if (label && file) {
      const res = await createCatalogPictogram({
        label,
        image: file,
      });
      if (res.status === 204) {
        setLabel('');
        setFile(null);
        clientQuery.invalidateQueries(['getCatalogPictograms']);
        helperDisplayToast('Pictogramme enregistré', true);
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible d'ajouter le pictogramme",
          false,
        );
      }
    } else {
      helperDisplayToast('Tous les champs doivent être complétés ', false);
    }
  };

  return (
    <div>
      <Title title={t('ADD_PICTOGRAM')} />
      <div className="flex-align-center" css={styles.content}>
        <InputLabel
          label={t('PICTOGRAM_NAME')}
          required
          marginBottom={0}
          value={label}
          onChange={e => setLabel(e.target.value)}
        />
        <div css={styles.updloadPictoPicture}>
          <RequiredLabel
            label={t('IMAGE_OF_PICTOGRAM') + ' (60x60 pixels)'}
            required
          />
          <label
            htmlFor="pictoPicture"
            className="flex-space-between"
            style={styles.updloadPictoPictureLabel}>
            <TextVariant
              variant="textSemiBold"
              text={file ? file.name : t('DOWNLOAD')}
            />
            <DownLoadIcon />
          </label>

          <input
            type={'file'}
            hidden
            id="pictoPicture"
            onChange={handleFileChange}
            accept="image/*"
          />
        </div>
        <ButtonCustom onClick={handleSubmit} label={t('ADD_TO_CATALOG')} />
      </div>
    </div>
  );
};

const styles = {
  content: css(`
          gap: 1rem;
          align-items: flex-end;
          padding-bottom: 0.5rem;
         
         p {
            font-size:10px;
        }
        
        `),

  updloadPictoPicture: {
    width: '100%',
  },
  updloadPictoPictureLabel: {
    width: '100%',
    background: '#FFFF',
    height: 39,
    padding: '0 0.5rem',
    borderRadius: 2,
  },
};

import {useQuery} from '@tanstack/react-query';
import {getBrandsHierarchy} from '../api/fetchBrandInfos';
import {useSearchParams} from 'react-router-dom';

export const useBrandsHierarchy = () => {
  const [searchParams] = useSearchParams();
  const queryToken = searchParams.get('token');
  const authToken = localStorage.getItem('auth_token');

  const {data, isLoading, error, refetch} = useQuery(
    ['getBrandsHierarchy'],
    () => getBrandsHierarchy(),
    {
      enabled: !queryToken && !!authToken,
    },
  );

  return {
    data,
    isLoading,
    error,
    refetch,
  };
};

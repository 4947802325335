/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {useContext} from 'react';
import {TextAreaLabel} from '../../../components/atoms/TextAreaLabel';
import {UtilsContext} from '../../../contexts/UtilsContext';
import {THEME} from '../../../styles/theme';
import {InputLabel} from '../../../components/atoms/InputLabel';

export const FormInputs = ({
  values,
  onChange,
  width = '30%',
}: {
  values: any;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  width?: string | number;
}) => {
  const maxLengthDescription = 500;

  const {t} = useContext<any>(UtilsContext);

  return (
    <div css={[styles.formInputs, {width}]} className="flex-col">
      <InputLabel
        label={t('TITLE')}
        name="home_title"
        value={values?.home_title}
        onChange={onChange}
      />
      <InputLabel
        label={t('DESCRIPTION')}
        name="home_desc"
        value={values?.home_desc}
        onChange={onChange}
      />
      <InputLabel
        label={t('SLIDER_TITLE')}
        name="slider_title"
        value={values?.slider_title}
        onChange={onChange}
      />
      <TextAreaLabel
        maxLengthDescription={maxLengthDescription}
        onChange={onChange}
        label={t('DESCRIPTION_OF_HOME_SCREEN_SLIDER')}
        name="slider_desc"
        value={values?.slider_desc}
      />

      <div className="flex" css={styles.themes}>
        <button
          onClick={() =>
            onChange({
              target: {
                name: 'theme',
                value: 1,
              },
            } as any)
          }
          className={`title03${values.theme === 1 ? ' active' : ''}`}>
          {t('DARK_THEME')}
        </button>
        <button
          onClick={() =>
            onChange({
              target: {
                name: 'theme',
                value: 0,
              },
            } as any)
          }
          className={`title03${values.theme !== 1 ? ' active' : ''}`}>
          {t('LIGHT_THEME')}
        </button>
      </div>
    </div>
  );
};

const styles = {
  formInputs: css({
    width: '30%',
  }),

  textarea: css({
    width: '100%',
    resize: 'none',
    border: 'none',
    borderRadius: 4,
    height: 249,
    padding: 10,
    marginTop: 10,
  }),

  textareaMaxLength: css({
    color: '#CCD6E0',
    position: 'absolute',
    bottom: 10,
    right: 15,
  }),

  themes: css(`
    justify-content: flex-end;
    align-items: center;
    background-color: #FFFFFF;
    padding:0.2rem;
    align-self: flex-end;
    border-radius: 4px;
    margin-top: 5px;
   

    button {
      border: none;
      padding: 5px 10px;
      border-radius: none;
      background-color: transparent;
      color:#A7A7AB;
      text-transform: uppercase;
      cursor: pointer;


      &.active{
        background-color:${THEME.colors.primary};
        color: white;
        border-radius: 4px;
      }
    }
    
  `),
};

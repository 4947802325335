/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useReduxHook';
import {helperLabelTranslation} from '../../../../../../helpers/helperLabel';
import {THEME} from '../../../../../../styles/theme';
import {
  resetSelectedButtonsAction,
  selectedButtonsAction,
} from '../../../../../../redux/myModulesSection/pos';

export const Breadcrumb = () => {
  //
  const dispatch = useAppDispatch();

  const selectedPosCategory = useAppSelector(
    s => s.myModulesSection.pos.selectedPosCategory,
  );
  const selectedButtons = useAppSelector(
    s => s.myModulesSection.pos.selectedButtons,
  );

  const handleClickRoot = () => {
    dispatch(resetSelectedButtonsAction());
  };

  const handleClickOnButton = (button: any) => {
    if (selectedButtons[selectedButtons.length - 1].uuid === button.uuid) {
      return;
    }

    const buttonIndex = selectedButtons.findIndex(
      (item: any) => item.uuid === button.uuid,
    );
    if (buttonIndex > -1) {
      console.log(selectedButtons.slice(0, buttonIndex + 1));
      let copyArray = JSON.parse(JSON.stringify(selectedButtons));
      const newArray = copyArray.splice(0, buttonIndex + 1);

      dispatch(selectedButtonsAction(newArray));
    }
  };

  return (
    <div css={styles.line} className="title02">
      {selectedPosCategory && selectedButtons?.length > 0 && (
        <span css={styles.link} onClick={handleClickRoot}>
          {helperLabelTranslation(selectedPosCategory)}
        </span>
      )}
      {selectedButtons?.map((button: any, index: number) => (
        <>
          <span css={styles.chevron} key={index}>
            {' > '}
          </span>
          <span css={styles.link} onClick={() => handleClickOnButton(button)}>
            {helperLabelTranslation(button)}
          </span>
        </>
      ))}
    </div>
  );
};

const styles = {
  line: css({
    color: THEME.colors.bgDark,
  }),
  link: css({
    cursor: 'pointer',
  }),
  chevron: css({
    color: THEME.colors.primary,
  }),
};

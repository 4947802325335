/** @jsxImportSource @emotion/react */
import {helperDate} from '../../../../../helpers/helperDate';
import {DataGridActions} from '../../../../../components/newDatagrid/DataGridActions';
import {ColumnAmount} from './ColumnAmount';
import {IPriceRule, deletePriceRule} from '../../../../../api/fetchPriceRules';
import {priceRuleSelectedAction} from '../../../../../redux/myOffersSection/priceRules';
import {addPriceRuleModalAction} from '../../../../../redux/modals';
import {helperConfirmDialog} from '../../../../../helpers/helperConfirmDialog';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';

export const columnsPrices = [
  {
    field: 'label',
    headerName: 'Règle de prix',
    headerClassName: 'textRegular',
    flex: 1,
  },
  {
    field: 'price',
    headerName: 'Prix',
    headerClassName: 'textRegular',
    flex: 1,
    renderCell: (row: IPriceRule) => <ColumnAmount row={row} key={row.uuid} />,
  },
  {
    field: 'createdAt',
    headerName: "Date d'ajout",
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter: (row: IPriceRule) => {
      return `Le ${helperDate(row?.createdAt, 'DD/MM/YYYY')}`;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Date de modification',
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter: (row: IPriceRule) => {
      return `Le ${helperDate(row?.updatedAt, 'DD/MM/YYYY')}`;
    },
  },
  {
    field: 'actions',
    headerName: '',
    flex: 0,
    renderCell: (row: any) => {
      const handleClickEdit = ({dispatch}: any) => {
        dispatch(priceRuleSelectedAction(row));
        dispatch(addPriceRuleModalAction(true));
      };

      const handleClickDelete = ({refreshing}: any) => {
        helperConfirmDialog({
          title: 'Suppression de la règle de prix',
          text: row.label,
          onSubmit: async () => {
            const res = await deletePriceRule(row.uuid);

            if (res && res.status === 204) {
              refreshing('getPriceRules');
              helperDisplayToast('Règle de prix supprimée', true);
            } else {
              helperDisplayToast('Une erreur est survenue', false);
            }
          },
        });
      };

      return (
        <DataGridActions
          handleClickEdit={handleClickEdit}
          handleClickDelete={handleClickDelete}
          authorization={{
            edit: BRAND_AUTHORIZATION.PARENT,
            delete: BRAND_AUTHORIZATION.PARENT,
          }}
        />
      );
    },
  },
];

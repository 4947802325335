import {axiosInstance} from './axiosInstance';

export const fetchBcoreSynchro = async () => {
  const res = await axiosInstance.post('/bcore/protected/admin/synch');
  return res.data;
};

export const fetchBcatalogSynchro = async () => {
  const res = await axiosInstance.post('/bcatalog/backoffice/synchro');
  return res.data;
};

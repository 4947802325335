/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
// import {CardsFilter} from '../../../../../components/filters/CardsFilter';
import {SearchFilter} from '../../../../../components/filters/SearchFilter';
// import {OrderByFilter} from '../../../../../components/filters/OrderByFilter';
// import {VisibilityFilter} from '../../../../../components/filters/VIsibilityFilter';
// import {useTranslation} from 'react-i18next';

export const Bottom = () => {
  // const {t} = useTranslation();

  return (
    <div css={styles.container}>
      <SearchFilter marginRight={0} />
      {/* <OrderByFilter label={'Emplacements'} field="locationCount" />
      <CardsFilter marginLeft={'0'} />
      <VisibilityFilter />
      <OrderByFilter label={t('DATE_ADDED')} field="createdAt" />
      <OrderByFilter label={t('MODIFICATION_DATE')} field="updatedAt" /> */}
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    gap: '1vh',
  }),
};

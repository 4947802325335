/**  @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';
import {getStatItemDetail} from '../../../../../../api/fetchStats';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {helperJoinFilterValues} from '../../../../../../helpers/helperFilter';
import {ProductDetailInfo} from '../../../../../../components/molecules/ProductDetailInfos';
import {ConsumptionModeDetail} from '../../../../../../components/molecules/ConsumptionModeDetail';
import {ChannelsModeDetail} from '../../../../../../components/molecules/ChannelsModeDetail';
import {useFlagBCatalog} from '../../../../../../hooks/useFlagBCatalog';

export const ContentHeader = () => {
  const dates = useAppSelector(s => s.filter.dates);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const channels = useAppSelector(s => s.filter.channels);
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {id} = useParams();
  const {data} = useQuery(
    [
      'getStatItemDetail',
      flagBCatalogEnabled,
      id,
      dates,
      channels,
      consumptionModes,
    ],
    () =>
      getStatItemDetail(String(id), {
        ...dates,
        consumptionModeIds: helperJoinFilterValues(consumptionModes, true),
        channelIds: helperJoinFilterValues(channels, true),
        forStats: !flagBCatalogEnabled,
      }),
    {
      enabled: !!id && flagBCatalogReady,
    },
  );

  return (
    <div css={styles.container}>
      <ProductDetailInfo item={data} />
      <ConsumptionModeDetail consumptionModes={data?.consumptionModes || []} />
      <ChannelsModeDetail channels={data?.channels || []} />
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1.2rem',
  }),
};

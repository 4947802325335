/* @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  BarChart as BarChartModule,
  ResponsiveContainer,
} from 'recharts';
import {THEME} from '../../../../../../styles/theme';
import {IStatHourlyByDOW} from '../../../../../../api/fetchStats';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {helperWeekDaysWithWeekName} from '../../../../../../helpers/helperCombineDataDOW';

export const BarChart = ({
  weekdays,
}: {
  weekdays: IStatHourlyByDOW['weekdays'];
}) => {
  const isCompare = useAppSelector(s => s.filter.isCompare);
  const salesGraphSection = useAppSelector(
    s => s.myStatsSection.sales.salesGraphSection,
  );
  const currentMetricMode = useAppSelector(s => s.filter.currentMetricMode);

  const dataKey =
    salesGraphSection === 'TURNOVER'
      ? `sales.TTC.${currentMetricMode}`
      : salesGraphSection === 'ORDERS'
      ? `orders.${currentMetricMode}`
      : 'average_cart';

  const compareDataKey =
    salesGraphSection === 'TURNOVER'
      ? `compareSales.TTC.${currentMetricMode}`
      : salesGraphSection === 'ORDERS'
      ? `compareOrders.TTC.${currentMetricMode}`
      : 'compareAverageCart';

  return (
    <div css={styles.container}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChartModule data={helperWeekDaysWithWeekName(weekdays || [])}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="weekName"
            tick={{
              fill: '#A7A7AB',
              fontWeight: '700',
              fontSize: 13,
            }}
          />
          <YAxis
            tick={{
              fill: THEME.colors.primary,
              fontWeight: '500',
            }}
          />
          {/* <Tooltip /> */}
          {isCompare && (
            <Bar
              dataKey={compareDataKey}
              fill={THEME.colors.lightGray}
              label={{
                position: 'top',
                fontSize: 15,
                fill: THEME.colors.bgDark,
                fontFamily: 'Montserrat-Regular',
              }}
            />
          )}
          <Bar
            dataKey={dataKey}
            fill={THEME.colors.primary}
            label={{
              position: 'top',
              fontSize: 15,
              fill: THEME.colors.bgDark,
              fontFamily: 'Montserrat-Regular',
            }}
          />
        </BarChartModule>
      </ResponsiveContainer>
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
    marginTop: '2rem',
    paddingBottom: '2rem',
    gap: '0.5rem',
    height: '70vh',
  }),
};

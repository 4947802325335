import {useQuery} from '@tanstack/react-query';
import {fetchCards} from '../api/fetchCards';
import {useAppSelector} from './useReduxHook';
import {useDebounce} from './useDebounce';

export const useCards = ({
  offset,
  limit,
  forFilter,
}: {
  offset?: number;
  limit?: number;
  forFilter?: boolean;
}) => {
  const search = useAppSelector(s => s.filter.search);
  const orderBy = useAppSelector(s => s.filter.orderBy);

  const debouncedSearchTerm = useDebounce(search, 500);

  const {data, error, isLoading} = useQuery(
    [
      'fetchCards',
      debouncedSearchTerm,
      forFilter ? null : orderBy,
      offset,
      limit,
    ],
    () =>
      fetchCards({
        search: forFilter ? '' : debouncedSearchTerm,
        orderBy: forFilter ? [] : orderBy,
        offset,
        limit,
      }),
  );

  return {data, error, isLoading};
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../../components/layouts/Header';
import {StatsSalesHeaderTabs} from '../Headers/StatsSalesHeaderTabs';
import {TurnOverProducts} from './TurnOverProducts';
import {DetailsByProducts} from './DetailsByProducts';
import {StatsSalesHeaderBottom} from '../Headers/StatsSalesHeaderBottom';
import {M_SCREEN_DESKTOP} from '../../../../../constants/ResponsiveSize';

export const Products = withRedirectAndNavigation(() => {
  //
  return (
    <div css={styles.container}>
      <Header
        centerSection={<StatsSalesHeaderTabs />}
        bottomSection={<StatsSalesHeaderBottom />}
      />
      <div css={styles.content}>
        <TurnOverProducts />
        <DetailsByProducts />
      </div>
    </div>
  );
});
const styles = {
  container: css({
    height: '100vh',
    overflowY: 'auto',
    '@media (max-width: 1024px)': {
      height: 'auto',
    },
  }),
  content: css({
    padding: '1.5rem 2vh',
    overflowY: 'auto',
    [M_SCREEN_DESKTOP]: {
      padding: '1rem 1vh',
    },
  }),
};

import {SVGProps} from 'react';

export const GroupsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.25 6.5C6.90703 6.5 8.25 5.1568 8.25 3.5C8.25 1.8432 6.90703 0.5 5.25 0.5C3.59297 0.5 2.25 1.8432 2.25 3.5C2.25 5.1568 3.59297 6.5 5.25 6.5ZM5.25 1.625C6.28383 1.625 7.125 2.46617 7.125 3.5C7.125 4.53383 6.28383 5.375 5.25 5.375C4.21617 5.375 3.375 4.53359 3.375 3.5C3.375 2.46617 4.21641 1.625 5.25 1.625ZM6.43828 7.625H4.06172C1.81805 7.625 0 9.44375 0 11.6867C0 12.1367 0.36375 12.5 0.812344 12.5H9.68672C10.1367 12.5 10.5 12.1367 10.5 11.6867C10.5 9.44375 8.68125 7.625 6.43828 7.625ZM1.14164 11.375C1.29797 9.90078 2.54766 8.75 4.06172 8.75H6.43828C7.95258 8.75 9.18281 9.90148 9.35859 11.375H1.14164ZM11.2289 8H9.49805C10.575 8.88359 11.25 10.2055 11.25 11.6867C11.25 11.9867 11.1609 12.2633 11.0156 12.5H14.25C14.6648 12.5 15 12.1625 15 11.7289C15 9.67813 13.3219 8 11.2289 8ZM10.125 6.5C11.5758 6.5 12.75 5.32578 12.75 3.875C12.75 2.42422 11.5758 1.25 10.125 1.25C9.53648 1.25 8.99906 1.45051 8.56125 1.77758C8.83125 2.29602 9 2.87656 9 3.5C9 4.3325 8.72039 5.09703 8.25961 5.71977C8.73516 6.2 9.39375 6.5 10.125 6.5Z"
      fill="white"
    />
  </svg>
);

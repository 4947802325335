/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useEffect} from 'react';
import {ChevronDownIcon} from '../../../../assets/svgs/ChevronDownIcon';
import {ChevronUpIcon} from '../../../../assets/svgs/ChevronUpIcon';
import {EditIcon} from '../../../../assets/svgs/EditIcon';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {THEME} from '../../../../styles/theme';
import {ActionButton} from '../../../../components/atoms/ActionButton';
import {ToggleButton} from '../../../../components/atoms/ToggleButton';
import {Loader} from '../../../../components/atoms/Loader';
import {helperLabelTranslation} from '../../../../helpers/helperLabel';
import {useQuery} from '@tanstack/react-query';
import {TrashIcon} from '../../../../assets/svgs/TrashIcon';
import {ProductTypeLabel} from '../../../../components/molecules/ProductTypeLabel';
import {
  fetchAreaProductionItems,
  dissociateAreaProductionItem,
} from '../../../../api/fetchAreaProductions';
import {
  areaProductionSelectedAction,
  areaProductionUpdatedAction,
  areaProductionsItemsAction,
} from '../../../../redux/myRestaurantSection/areaProductions';
import {ProductList} from '../../../../components/molecules/ProductList';
import {HorizontalBar} from '../../../../components/atoms/HorizontalBar';
import {
  addProductionAreaModalAction,
  deleteProductionAreaModalAction,
} from '../../../../redux/modals';
import {PRODUCT_TYPES} from '@bill-app-types/b-types';
import {useBrandAccess} from '../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../constants/frontPolicies';
import {
  SortableListVerticalContainer,
  THandleDragAndDrop,
} from '../../../../components/organisms/SortableListVerticalContainer';

export const AreaCategoryItem = ({
  item,

  level = 0,
  toggle,
  handleDragAndDrop,
}: {
  item: any;
  level: number;
  toggle: boolean;
  handleDragAndDrop: THandleDragAndDrop;
}) => {
  const dispatch = useAppDispatch();
  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const areaProductionSelected = useAppSelector(
    s => s.myRestaurantSection.areaProductions.areaProductionSelected,
  );

  const {data, isLoading, refetch} = useQuery(
    [
      `fetchAreaProductionItems-${areaProductionSelected?.uuid}`,
      areaProductionSelected?.uuid,
    ],
    () => fetchAreaProductionItems(areaProductionSelected?.uuid),
    {
      enabled: !!areaProductionSelected?.uuid,
    },
  );

  useEffect(() => {
    if (data) {
      dispatch(areaProductionsItemsAction(data));
    }
  }, [data, dispatch]);

  const handleOpenCategory = (category: any) => {
    dispatch(
      areaProductionSelectedAction(
        category.uuid === areaProductionSelected?.uuid ? null : category,
      ),
    );
  };

  const handleRemoveProduct = async (item: any) => {
    if (!areaProductionSelected || !hasAuthorization) {
      return;
    }

    await dissociateAreaProductionItem(
      areaProductionSelected?.uuid,
      item?.uuid,
    );

    await refetch();
  };

  const handleClickEdit = (category: any) => {
    dispatch(areaProductionUpdatedAction(category));
    dispatch(addProductionAreaModalAction(true));
  };

  const handleClickDelete = async (category: any) => {
    dispatch(areaProductionUpdatedAction(category));
    dispatch(deleteProductionAreaModalAction(true));
  };

  const isOpen =
    areaProductionSelected && item.uuid === areaProductionSelected?.uuid;

  return (
    <div style={{marginBottom: 5}}>
      <div
        css={[
          styles.dropdown,
          {
            marginBottom: item?.subCategories?.length > 0 ? 5 : 0,
            gap: '0.3rem',
          },
        ]}>
        <HorizontalBar color={item.color} />
        <div css={styles.actions}>
          <ActionButton
            onClick={() => handleClickEdit(item)}
            Icon={EditIcon}
            disabled={!hasAuthorization}
          />
          <ActionButton
            onClick={() => handleClickDelete(item)}
            Icon={TrashIcon}
            disabled={!hasAuthorization}
          />
        </div>

        <div
          css={styles.dropdownHeader}
          onClick={() => handleOpenCategory(item)}>
          <div className="textSemiBold" css={styles.title}>
            <span>{helperLabelTranslation(item)}</span>
            <ProductTypeLabel
              type={
                item?.isRecipe
                  ? PRODUCT_TYPES.OPTION_ITEM
                  : PRODUCT_TYPES.COURSE
              }
            />
          </div>
          <div css={styles.toggle}>{toggle && <ToggleButton active />}</div>
          <div css={styles.chevron}>
            {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </div>
        </div>
      </div>

      {data && isOpen && (
        <div css={styles.dropdownContent}>
          {data?.length > 0 ? (
            <ProductList
              data={[...data]?.sort(
                (a: any, b: any) => a.position - b.position,
              )}
              handleOnClick={handleRemoveProduct}
              style={{backgroundColor: THEME.colors.white}}
              showProductType
              draggable={false}
              showCross
            />
          ) : (
            <p className="textSemiBold">Aucun produits</p>
          )}
        </div>
      )}
      {isLoading && isOpen && (
        <div className="flex-center" css={styles.dropdownContent}>
          <Loader />
        </div>
      )}

      {item?.subCategories?.length > 0 && (
        <>
          <SortableListVerticalContainer
            //@ts-ignore
            data={item?.subCategories?.map(subItem => ({
              ...subItem,
              parentCategoryUuid: item.uuid,
            }))}
            level={level + 1}
            handleDragAndDrop={handleDragAndDrop}
            ItemComponent={AreaCategoryItem}
          />
        </>
      )}
    </div>
  );
};

const styles = {
  dropdown: css({
    backgroundColor: THEME.colors.white,
    display: 'flex',
    borderRadius: 2,
    paddingLeft: '0',
    paddingRight: '10px',
  }),
  actions: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
  dropdownHeader: css({
    backgroundColor: THEME.colors.white,
    display: 'flex',
    padding: '7px 0',
    borderRadius: 2,
    alignItems: 'center',
    cursor: 'pointer',
    flex: 1,
  }),
  dropdownContent: css({
    backgroundColor: THEME.colors.white,
    padding: 10,
    marginBottom: 10,
  }),
  title: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
    paddingLeft: 10,
  }),
  edit: css({
    backgroundColor: THEME.colors.bgLight,
    width: 25,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 2,
  }),
  chevron: css({
    // marginLeft: 'auto',
  }),
  toggle: css({
    marginLeft: 'auto',
    marginRight: 10,
  }),
};

import {useQuery} from '@tanstack/react-query';
import {fetchStaffGroups} from '../api/fetchStaff';
import {useAppSelector} from './useReduxHook';
import {IFetchAdminGroupsQuery} from '@bill-app-types/b-types';
import {useDebounce} from './useDebounce';

export const useStaffGroups = () => {
  const brand = useAppSelector(s => s.brand.brand);
  const isArchived = useAppSelector(s => s.filter.isArchived);
  const orderBy = useAppSelector(s => s.filter.orderBy);
  const search = useAppSelector(s => s.filter.search);

  const debounceSearch = useDebounce(search, 500);

  const {data, isLoading, error} = useQuery(
    ['fetchStaffGroups', brand?.id, isArchived, debounceSearch, orderBy],
    () => {
      let filter: IFetchAdminGroupsQuery = {
        companyIdGuid: brand.id,
        search: debounceSearch,
        // orderBy: orderBy.filter(
        //   item =>
        //     ['label', 'createdAt', 'updatedAt'].includes(item.field) && item.sort,
        // ) as any,
      };

      if (isArchived !== null) {
        filter.isArchived = isArchived;
      }
      return fetchStaffGroups(filter);
    },
    {
      enabled: !!brand?.id,
    },
  );

  return {
    staffGroupsData: data || [],
    staffGroupsLoading: isLoading,
    staffGroupsError: error,
  };
};

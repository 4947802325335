/** @jsxImportSource @emotion/react */
import {AdditionalSalesCatalog} from '../../../../../../components/organisms/AdditionalSalesCatalog';
import {AdditionalSales} from '../../../../../../components/organisms/AdditionalSalesCatalog/AdditionalSales';
import {BRAND_AUTHORIZATION} from '../../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../../hooks/useBrandAccess';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';

export const MenuAdditionnalSales = () => {
  const menuPreRegister = useAppSelector(
    s => s.myCatalogSection.menus.menuPreRegister,
  );

  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <div css={styles(hasAuthorization).container}>
      {hasAuthorization && (
        <AdditionalSalesCatalog itemUuid={menuPreRegister?.uuid} />
      )}
      <AdditionalSales itemUuid={menuPreRegister?.uuid} />
    </div>
  );
};

const styles = (hasAuthorization: boolean = false) => ({
  container: {
    display: hasAuthorization ? 'grid' : 'block',
    gridTemplateColumns: '1fr 1fr',
    gap: '2rem',
  },
});

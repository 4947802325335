import {useQuery} from '@tanstack/react-query';
import {fetchPosReceiptCancelByReasons} from '../api/fetchPosReceiptCancel';
import {useAppSelector} from './useReduxHook';

export const usePosReceiptCancelByReasons = () => {
  const dates = useAppSelector(s => s.filter.dates);

  const {data, isLoading, error} = useQuery(
    ['fetchPosReceiptCancelByReasons', dates],
    () =>
      fetchPosReceiptCancelByReasons({
        ...dates,
      }),
  );

  return {
    data,
    isLoading,
    error,
  };
};

import React, {useEffect, useState} from 'react';
import {INIT_DAYS} from '../constants/global';
import {helperDisplayToast} from '../helpers/helperToast';
import {useTranslation} from 'react-i18next';
import {helperDeepCopyObject} from '../helpers/helperDeepCopyObject';

export interface IDay {
  name: string;
  dates: {id: number; start: string; end: string}[];
}

export const useDateManage = (initDays = INIT_DAYS) => {
  const {t} = useTranslation();
  const [days, setDays] = useState<IDay[]>(initDays);

  const handleAddDay = (index: number) => {
    const newDays = helperDeepCopyObject(days);
    const lastDate = newDays[index].dates.at(-1);
    newDays[index].dates.push({
      id: lastDate ? lastDate.id + 1 : 1,
      start: '',
      end: '',
    });
    setDays(newDays);
  };

  const handleRemoveDay = (dayIndex: number, dateIndex: number) => {
    const newDays = helperDeepCopyObject(days);
    newDays[dayIndex].dates.splice(dateIndex, 1);
    setDays(newDays);
  };

  const handleChangeDate = ({
    dayIndex,
    dateIndex,
    e,
  }: {
    dayIndex: number;
    dateIndex: number;
    e: React.ChangeEvent<HTMLInputElement>;
  }) => {
    const {name, value} = e.target;
    const newDays: IDay[] = helperDeepCopyObject(days);

    if (newDays.length > 1) {
      const timesAlreadyIncludes = newDays[dayIndex].dates.filter(
        (date, index) => {
          return (
            index !== dateIndex && date.start <= value && date.end >= value
          );
        },
      );

      if (timesAlreadyIncludes.length > 0) {
        helperDisplayToast(t('THIS_TIME_SLOT_IS_ALREADY_TAKEN'), false);
        return;
      }
    }

    if (name === 'start') {
      newDays[dayIndex].dates[dateIndex].start = value;
    } else if (name === 'end') {
      newDays[dayIndex].dates[dateIndex].end = value;
    }

    setDays(newDays);
  };

  const handleBlurDate = ({
    dayIndex,
    dateIndex,
    e,
  }: {
    dayIndex: number;
    dateIndex: number;
    e: React.ChangeEvent<HTMLInputElement>;
  }) => {
    const {name, value} = e.target;

    const newDays: IDay[] = helperDeepCopyObject(days);

    if (
      (name === 'start' &&
        newDays[dayIndex].dates[dateIndex].end &&
        value > newDays[dayIndex].dates[dateIndex].end) ||
      (name === 'end' && value < newDays[dayIndex].dates[dateIndex].start)
    ) {
      if (name === 'start') {
        newDays[dayIndex].dates[dateIndex].start = '';
      } else {
        newDays[dayIndex].dates[dateIndex].end = '';
      }
      helperDisplayToast(
        t(
          name === 'start'
            ? 'START_TIME_MUST_BE_LESS_THAN_END_TIME'
            : 'END_TIME_MUST_BE_GREATER_THAN_START_TIME',
        ),
        false,
      );
    }

    setDays(newDays);
  };

  const handleReset = () => {
    setDays(INIT_DAYS);
  };

  useEffect(() => {
    if (initDays) {
      setDays(initDays);
    }
  }, [initDays]);

  return {
    days,
    handleAddDay,
    handleRemoveDay,
    handleChangeDate,
    handleBlurDate,
    setDays,
    handleReset,
  };
};

/** @jsxImportSource @emotion/react */
//import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addPosEntityModalAction} from '../../redux/modals';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';

import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {useEffect, useState} from 'react';
import {
  updateModeAction,
  updatedPosEntityAction,
} from '../../redux/myModulesSection/pos';
import {
  createPosEntity,
  createPosEntityChannel,
  deletePosEntityChannel,
  updatePosEntity,
} from '../../api/fetchPosEntities';
import {ButtonList} from '../atoms/ButtonList';
import {CHANNELS} from '@bill-app-types/b-types';

export const AddPosEntityModal = () => {
  const initialValues = {
    name: '',
  };
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();

  const [selectedChannels, setSelectedChannels] = useState<number[]>([]);

  const updateMode = useAppSelector(
    state => state.myModulesSection.pos.updateMode,
  );
  const updatedPosEntity = useAppSelector(
    state => state.myModulesSection.pos.updatedPosEntity,
  );

  const brandId = useAppSelector(s => s.brand.brand?.id);

  useEffect(() => {
    if (updatedPosEntity) {
      setSelectedChannels(updatedPosEntity.channels);
    }
  }, [updatedPosEntity]);

  const handleOnCancel = () => {
    dispatch(addPosEntityModalAction(false));
    dispatch(updatedPosEntityAction(null));
    dispatch(updateModeAction(false));
  };

  const handleSuccess = (res: {data: {message: string}}) => {
    handleOnCancel();
    helperDisplayToast(res?.data?.message || 'Entité enregistrée', true);
  };

  const handleSubmit = async (
    data: {
      name: string;
    },
    {setSubmitting, resetForm}: any,
  ) => {
    if (data.name) {
      let res: any;
      if (updateMode) {
        res = await updatePosEntity(brandId, updatedPosEntity.uuid, {
          ...data,
          channels: selectedChannels,
        });
      } else {
        res = await createPosEntity(brandId, {
          ...data,
          channels: selectedChannels,
        });
      }
      if ([200, 204].includes(res.status)) {
        const channelsToAdd: number[] = updateMode
          ? selectedChannels?.filter(
              item => !updatedPosEntity.channels?.includes(item),
            )
          : selectedChannels;

        const channelsToRemove: number[] = updateMode
          ? updatedPosEntity.channels?.filter(
              (item: any) => !selectedChannels.includes(item),
            )
          : [];

        let success = true;
        await Promise.all(
          channelsToAdd.map(async channel => {
            const result = await createPosEntityChannel(
              brandId,
              updateMode ? updatedPosEntity.uuid : res?.data?.data?.uuid,
              channel,
            );
            if (![200, 204].includes(result.status)) {
              success = false;
            }
          }),
        );

        await Promise.all(
          channelsToRemove.map(async channel => {
            const result = await deletePosEntityChannel(
              brandId,
              updateMode ? updatedPosEntity.uuid : res?.data?.data?.uuid,
              channel,
            );
            if (![200, 204].includes(result.status)) {
              success = false;
            }
          }),
        );

        handleSuccess(res);
        resetForm();
        clientQuery.invalidateQueries(['getEntities']);
        if (!success) {
          helperDisplayToast(
            res?.data?.message ||
              'Erreur dans la modification des canaux de commandes',
            false,
          );
        }
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible de créer l'entité",
          false,
        );
      }
    } else {
      helperDisplayToast('Tous les champs doivent être complétés', false);
    }
  };

  const handleClickChannel = (channel: number | string) => {
    if (selectedChannels.includes(Number(channel))) {
      setSelectedChannels(selectedChannels.filter(item => item !== channel));
    } else {
      setSelectedChannels([...selectedChannels, Number(channel)]);
    }
  };

  const channels = Object.entries(CHANNELS)
    .slice(7)
    .map(entry => ({
      label: t(entry[0]),
      value: entry[1],
    }));

  return (
    <ModalWrapper
      title={updateMode ? "Modifier l'entité" : 'Ajouter une entité'}
      minWidth="28%"
      validateLabel={"Enregistrer l'entité"}
      cancelLabel={t(updateMode ? 'Annuler la modification' : 'CANCEL_CREATE')}
      onCancel={handleOnCancel}
      onSubmit={handleSubmit}
      initialValues={updateMode ? updatedPosEntity : initialValues}>
      <FieldCustomInput label={"Nom de l'entité"} name="name" required />
      <ButtonList
        label="Canaux de commande"
        list={channels}
        selectedValues={selectedChannels}
        handleClickButton={handleClickChannel}
      />
      <FieldsRequired />
    </ModalWrapper>
  );
};

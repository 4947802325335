/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {SubMenuHeader} from './SubMenuHeader';
import {DropDownBodyAnim} from '../../../../../components/layouts/DropDownBodyAnim';
import {removeSubmenuProducts} from '../../../../../api/fetchSubmenus';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {useSubmenuProduct} from '../../../../../hooks/useSubmenuProduct';
import {useDispatch} from 'react-redux';
import {editAdditionalPriceModalAction} from '../../../../../redux/modals';
import {
  submenuProductListAction,
  updatedCourseAction,
} from '../../../../../redux/myCatalogSection/menus';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {ProductList} from '../../../../../components/molecules/ProductList';
import {updateItemsPosition} from '../../../../../api/fetchItems';
import {THandleDragAndDrop} from '../../../../../components/organisms/SortableListVerticalContainer';

export const SubMenuCard = ({
  submenu,
  showProducts,
}: {
  submenu?: any;
  showProducts: boolean;
}) => {
  const dispatch = useDispatch();

  const {brandHasAuthorization} = useBrandAccess();

  const openedSubmenu = useAppSelector(
    s => s.myCatalogSection.menus.openedSubmenu,
  );
  const isOpened = submenu.uuid === openedSubmenu;

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const {data, refetch} = useSubmenuProduct({
    isOpened,
    submenu,
  });

  if (data && isOpened) {
    dispatch(submenuProductListAction(data));
  }

  const handleRemoveProduct = async (item: any) => {
    if (!hasAuthorization) return;
    const res = await removeSubmenuProducts(submenu?.uuid, item?.uuid);
    if (res?.status === 204) {
      helperDisplayToast('Produit retiré', true);
      refetch();
    } else {
      helperDisplayToast(
        res?.data?.message || 'Impossible de retirer le produit',
        false,
      );
    }
  };

  const handleDragAndDrop: THandleDragAndDrop = async ({items}) => {
    if (!hasAuthorization || !items?.length) return;

    const res = await updateItemsPosition(submenu?.uuid, items);

    if (res && res?.status === 204) {
      helperDisplayToast('Position modifiée', true);
    } else {
      helperDisplayToast('Impossible de modifier la position', false);
    }
  };

  const handleClickAdditional = (item: any, e: any) => {
    if (!hasAuthorization) return;
    e.stopPropagation();
    dispatch(editAdditionalPriceModalAction(true));
    dispatch(updatedCourseAction(item));
  };

  return (
    <div css={styles.container}>
      <SubMenuHeader
        withActions
        showProducts={showProducts}
        submenu={submenu}
        isOpened={isOpened}
      />
      <DropDownBodyAnim isOpened={isOpened}>
        <div css={styles.productList}>
          <ProductList
            data={data || []}
            showCross={hasAuthorization}
            draggable
            bg="white"
            showAdditionalPrice
            handleOnClick={handleRemoveProduct}
            handleClickAdditional={handleClickAdditional}
            handleDragAndDrop={handleDragAndDrop}
          />
        </div>
      </DropDownBodyAnim>
    </div>
  );
};

const styles = {
  container: css({
    marginBottom: 5,
  }),
  productList: css({
    marginTop: '0.313rem',
    padding: '0.625rem 2rem',
    backgroundColor: 'white',
    maxHeight: '50vh',
    overflowY: 'auto',
  }),
};

import {SVGProps} from 'react';

export const ComingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="12"
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.4375 0.938379V10.3104C8.4375 10.875 8.95496 11.25 9.49878 11.25C9.72949 11.25 9.96515 11.1824 10.1675 11.033L16.4956 6.37189C16.9983 5.99484 16.9983 5.30637 16.4956 4.92932L10.1675 0.268184C9.9668 0.0673828 9.72949 0 9.49878 0C8.95496 0 8.4375 0.375293 8.4375 0.938379ZM10.0195 1.93652L14.9996 5.625L10.0195 9.31348V1.93652ZM0 0.938379V10.3104C0 10.6529 0.210114 10.9682 0.547778 11.1328C0.705355 11.2097 0.88396 11.2484 1.05831 11.2484C1.25505 11.2484 1.44921 11.1992 1.62125 11.1012L7.01664 8.0485C7.38545 7.84022 7.49454 7.40543 7.25971 7.0776C7.02686 6.75293 6.53577 6.65625 6.16663 6.86426L1.58203 9.45703V1.79297L6.16663 4.38633C6.53544 4.59507 7.02455 4.49848 7.25856 4.17073C7.49339 3.8429 7.3842 3.40813 7.01548 3.19983L1.62026 0.14707C1.29562 -0.0357423 0.885937 -0.0489259 0.548108 0.115723C0.210278 0.280371 0 0.595898 0 0.938379Z"
      fill="white"
    />
  </svg>
);

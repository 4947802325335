import {createSlice} from '@reduxjs/toolkit';
import {SqsReceipt} from '../../api/fetchSqsHistory';

interface ISlice {
  selectedArchiveReceipt: SqsReceipt | null;
}

const initialState: ISlice = {
  selectedArchiveReceipt: null,
};

const slice = createSlice({
  name: 'archive',
  initialState,
  reducers: {
    selectedArchiveReceiptAction(state, action) {
      state.selectedArchiveReceipt = action.payload;
    },
  },
});

export const {selectedArchiveReceiptAction} = slice.actions;

export default slice.reducer;

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {TextVariant} from './TextVariant';

export const BadgeCounter = ({count = 1}: {count?: number | string}) => {
  return (
    <div css={styles.badge}>
      <TextVariant text={count} variant="title03" />
    </div>
  );
};

const styles = {
  badge: css({
    minWidth: 20,
    height: 20,
    position: 'absolute',
    top: -4,
    left: -4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: THEME.colors.primary,
    color: THEME.colors.white,
    borderRadius: 2,
    fontSize: 10,
    fontWeight: 700,
    zIndex: 2,
    padding: '0 4px',
  }),
};

import {axiosInstance} from './axiosInstance';

export const getTaxes = async () => {
  //
  console.log('### getTaxes ###');
  const res: any = await axiosInstance.get('/bcatalog/backoffice/taxes').catch(err => {
    console.log('❌ getTaxs : ', err);
  });
  return res?.data?.data;
};

import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {useAppDispatch} from '../../../../../hooks/useReduxHook';
import {addAdditionalCategoryModalAction} from '../../../../../redux/modals';

export const Center = () => {
  const dispatch = useAppDispatch();
  const {brandHasAuthorization} = useBrandAccess();

  const isParentAuth = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const handleClickAddCategory = () => {
    dispatch(addAdditionalCategoryModalAction(true));
  };

  return (
    <>
      <ButtonCustom
        label={'AJOUTER UNE CATÉGORIE DE VENTES ADDITIONNELLES'}
        onClick={handleClickAddCategory}
        disabled={!isParentAuth}
      />
    </>
  );
};

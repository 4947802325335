import {
  IBrandInfos,
  // ILanguages
} from '@bill-app-types/b-types';
import {helperJustLog} from '../helpers/helperLog';
import {helperDisplayToast} from '../helpers/helperToast';
import {axiosInstance} from './axiosInstance';

export const getBrandInfos = async (brandId: string | number) => {
  //
  helperJustLog('### getBrandInfos ###');
  const res = await axiosInstance
    .get<{data: IBrandInfos}>(`/bcore/protected/admin/${brandId}/information`)
    .catch(err => {
      console.log('❌ getBrandInfos : ', err);
    });
  return res?.data?.data;
};

export const getBrandLanguages = async (brandId: string | number) => {
  //
  helperJustLog('### getBrandLanguages ###');
  const res = await axiosInstance
    .get<{
      data: string[];
    }>(`bcore/protected/admin/${brandId}/languages?language_id=1`)
    .catch(err => {
      console.log('❌ getBrandLanguages : ', err);
    });
  return res?.data?.data;
};

export const putBrandInfos = async (brandId: string, body: any) => {
  //
  helperJustLog('### putBrandInfos ###');
  const res = await axiosInstance
    .put(`/bcore/protected/admin/${brandId}/information`, body)
    .catch(err => {
      helperDisplayToast('Error message');
      console.log('❌ putBrandInfos : ', err);
      return;
    });
  return res;
};

export const putBrandLanguage = async (brandId: string, languageId: string) => {
  //
  helperJustLog('### putBrandLanguage ###');
  const res = await axiosInstance
    .put(`/bcore/protected/admin/${brandId}/languages/${languageId}`)
    .catch(err => {
      console.log('❌ putBrandLanguage : ', err);
    });

  return res;
};

export const deleteBrandLanguage = async (
  brandId: string,
  languageId: string,
) => {
  //
  helperJustLog('### deleteBrandLanguage ###');
  const res = await axiosInstance
    .delete(`/bcore/protected/admin/${brandId}/languages/${languageId}/delete`)
    .catch(err => {
      console.log('❌ deleteBrandLanguage : ', err);
    });

  return res;
};

export const getBrandsHierarchy = async () => {
  //
  helperJustLog('### getBrandsHierarchy ###');
  const res: any = await axiosInstance
    .get(`/bcore/protected/admin/brand`)
    .catch(err => {
      console.log('❌ getBrandsHierarchy : ', err);
    });
  return res?.data?.data;
};

export const uploadBrandAsset = async (brandId: string, body: FormData) => {
  //
  helperJustLog('### uploadBrandAssets ###');
  const res: any = await axiosInstance
    .post(`/bcore/protected/admin/${brandId}/asset`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .catch(err => {
      console.log('❌ uploadBrandAssets : ', err);
    });
  return res?.data?.data;
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {CrossIconContainer} from '../../../../components/atoms/CrossIconContainer';
import {memo, useState} from 'react';
import {
  addModuleScreenSaver,
  deleteModuleScreenSaver,
  fetchModuleScreenSavers,
} from '../../../../api/fetchModuleDetails';
import {useQuery} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {PictureResolution} from '../../../../components/atoms/PictureResolution';
import {MoreSquareIcon} from '../../../../assets/svgs/MoreSquareIcon';
import {AbsoluteLoader} from '../../../../components/layouts/AbsoluteLoader';
import {helperDisplayToast} from '../../../../helpers/helperToast';

export const ScreenSavers = memo(
  ({brandId, id}: {brandId?: string | number; id: string}) => {
    const {t} = useTranslation();
    const [screenSaverLoading, setScreenSaverLoading] = useState(false);

    const {data: screenSavers, refetch} = useQuery(
      ['fetchBOrder-screenSavers', brandId, id],
      () => fetchModuleScreenSavers(String(brandId), 'b_order'),
      {
        enabled: !!brandId && !!id,
      },
    );

    const handleUploadScreenSaver = async (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const {files} = e.target;

      setScreenSaverLoading(true);

      if (files) {
        for (let i = 0; i < files.length; i++) {
          const formData = new FormData();
          formData.append('image', files[i]);
          formData.append('b_webapp_parameter_id', '0');
          formData.append('b_dedicated_parameter_id', '0');
          formData.append('b_menu_parameter_id', '0');
          formData.append('b_order_parameter_id', String(id));
          formData.append('position', String(Number(screenSavers?.length) + 1));

          const res = await addModuleScreenSaver(String(brandId), formData);

          if (res?.status === 200) {
            helperDisplayToast(res.message, true);
          }
        }

        await refetch();
      }

      e.target.value = '';

      setScreenSaverLoading(false);
    };

    const handleRemoveScreenSaver = async (id: string) => {
      setScreenSaverLoading(true);
      const res = await deleteModuleScreenSaver(String(brandId), id);
      if (res?.status === 200) {
        helperDisplayToast(res.message, true);
      }
      await refetch();
      setScreenSaverLoading(false);
    };

    return (
      <div css={styles.container}>
        <div>
          <p className="textLabel">{t('SCREEN_SAVER_SLIDER')}</p>

          {screenSavers && (
            <div css={styles.images} className="hide-scroll-bar">
              {screenSavers?.map(screenSaver => {
                return (
                  <div css={styles.screenSaver} key={screenSaver.id}>
                    <img
                      src={screenSaver.image}
                      alt="background"
                      css={styles.img}
                    />
                    <CrossIconContainer
                      onClick={() => handleRemoveScreenSaver(screenSaver.id)}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <label
            htmlFor="screenSaver"
            css={styles.screenSaverContentImage}
            className="flex-center">
            <PictureResolution
              height={500}
              width={1920}
              Icon={MoreSquareIcon}
            />
          </label>
          <input
            type="file"
            hidden
            id="screenSaver"
            onChange={handleUploadScreenSaver}
            name="slide_image"
            accept="image/*"
            multiple
          />
        </div>
        {screenSaverLoading && <AbsoluteLoader />}
      </div>
    );
  },
);

const styles = {
  container: css({
    width: '100%',
  }),
  img: css({
    width: 113,
    height: '100%',
    objectFit: 'contain',
    borderRadius: 5,
  }),
  screenSaverContentImage: css({
    width: '100%',
    height: 150,
    backgroundColor: 'white',
    marginBottom: 10,
  }),
  images: css({
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '1rem',
    height: 200,
    marginBottom: '1rem',
    overflowX: 'auto',
    overflowY: 'hidden',
  }),
  screenSaver: css({
    position: 'relative',
    '&:hover': {
      '.icon': {
        opacity: 1,
        top: -10,
      },
    },
  }),
};

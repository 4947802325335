import {useQuery} from '@tanstack/react-query';
import {fetchPeriodicalPrices} from '../api/fetchPeriodicalPrices';
import {useAppSelector} from './useReduxHook';
import {useDebounce} from './useDebounce';

export const usePeriodicalPrices = () => {
  const search = useAppSelector(s => s.filter.search);
  const orderBy = useAppSelector(s => s.filter.orderBy);
  const debounceSearch = useDebounce(search, 500);

  const {data, isLoading} = useQuery(
    ['fetchPeriodicalPrices', debounceSearch, orderBy],
    () =>
      fetchPeriodicalPrices({
        search: debounceSearch,
        orderBy:
          orderBy.filter(
            item =>
              ['createdAt', 'updatedAt'].includes(item.field) && item.sort,
          ) || [],
      }),
  );

  return {
    data,
    isLoading,
  };
};

/** @jsxImportSource @emotion/react */
import {MenuGeneral} from './General';
import {MenuComposition} from './Composition';
import {MenuAdditionnalSales} from './AdditionalSales';
import {Outlet} from 'react-router-dom';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {css} from '@emotion/react';
import {Header} from '../../../../../components/layouts/Header';
import {EditMenuHeaderCenter} from './HeaderSections/Center';
import {EditMenuHeaderBottom} from './HeaderSections/Bottom';

const Root = withRedirectAndNavigation(() => {
  return (
    <>
      <Header
        centerSection={<EditMenuHeaderCenter />}
        bottomSection={<EditMenuHeaderBottom />}
      />
      <div css={styles.container}>
        <Outlet />
      </div>
    </>
  );
});

export const EditMenuRouter = {
  path: 'menus/edit',
  element: <Root />,
  children: [
    {
      path: '',
      element: <MenuGeneral />,
    },
    {
      path: 'composition',
      element: <MenuComposition />,
    },
    {
      path: 'additionnal-sales',
      element: <MenuAdditionnalSales />,
    },
    // {
    //   path: 'price-rules',
    //   element: <MenuPriceRules />,
    // },
  ],
};

const styles = {
  container: css({
    padding: '1rem 2vh',
  }),
};

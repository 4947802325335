/** @jsxImportSource @emotion/react */
import {useAppDispatch} from '../../../hooks/useReduxHook';
import {filterAction} from '../../../redux/filter';
import {FilterInput} from '../../atoms/FilterInput';
import {Title} from '../../atoms/Title';

export const Header = () => {
  //TODO : discompose this component
  const dispatch = useAppDispatch();

  return (
    <>
      <Title title="Catalogue d'options" />
      <div style={{paddingTop: 10}}>
        <FilterInput
          width={'100%'}
          name="search"
          marginRight={'1vh'}
          placeholder="Rechercher..."
          onChange={(e: any) =>
            dispatch(
              filterAction({name: e.target.name, values: e.target.value}),
            )
          }
        />
      </div>
    </>
  );
};

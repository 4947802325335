import {DataGridActions} from '../../../../../components/newDatagrid/DataGridActions';
import {
  addPrinterModalAction,
  deletePrinterModalAction,
} from '../../../../../redux/modals';
import {printerSelectedAction} from '../../../../../redux/myRestaurantSection/printers';

export const columnsPrinters = [
  {
    field: 'label',
    headerName: "Nom de l'imprimante",
    flex: 1,
  },
  {
    field: 'ip_address',
    headerName: 'Adresse IP',
    flex: 1,
  },
  {
    field: '',
    headerName: '',
    flex: 0,
    renderCell: (row: any) => {
      const handleClickDelete = ({dispatch}: any) => {
        dispatch(printerSelectedAction(row));
        dispatch(deletePrinterModalAction(true));
      };

      const handleClickEdit = ({dispatch}: any) => {
        dispatch(printerSelectedAction(row));
        dispatch(addPrinterModalAction(true));
      };

      return (
        <DataGridActions
          handleClickDelete={handleClickDelete}
          handleClickEdit={handleClickEdit}
        />
      );
    },
  },
];

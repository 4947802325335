/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';

export const Overlay = () => {
  return <div css={styles.container} />;
};

const styles = {
  container: css({
    position: 'absolute',
    inset: 0,
    backgroundColor: THEME.colors.bgLight + '80',
  }),
};

import {Provider} from 'react-redux';
import {AppRouter} from './router';
import store, {persistor} from './redux/storeConfig';
import {GlobalStyle} from './components/atoms/GlobalStyle';
import {UtilsProvider} from './contexts/UtilsContext';
import {ToastContainer} from 'react-toastify';
import {PersistGate} from 'redux-persist/integration/react';
import {QueryClientProvider, QueryClient} from '@tanstack/react-query';
import 'react-toastify/dist/ReactToastify.css';
import {EnvIndicator} from './components/atoms/EnvIndicator';
import {isDevelopment} from './app.config';
import {useEffect} from 'react';
import {useUnleashContext} from '@unleash/proxy-client-react';
import {useAppSelector} from './hooks/useReduxHook';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      cacheTime: 0,
    },
  },
});

function App() {
  //

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <UtilsProvider>
            <GlobalStyle />
            <AppRouter />
            <InitFF />
            <ToastContainer />
          </UtilsProvider>
        </PersistGate>
      </Provider>
      {isDevelopment && <EnvIndicator />}
    </QueryClientProvider>
  );
}

const InitFF = () => {
  const updateContext = useUnleashContext();
  const brand = useAppSelector(state => state.brand.brand);

  useEffect(() => {
    if (brand) {
      console.log('#### FC => brand.id', brand.id);
      updateContext({userId: brand.id});
    }
  }, [brand, updateContext]);

  return null;
};

export default App;

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useLanguages} from '../../../../../hooks/useLanguages';
import {THEME} from '../../../../../styles/theme';
import {useEffect} from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {
  ICustomLanguage,
  languageSelectedAction,
} from '../../../../../redux/myTranslationsSection/translations';
import {TranslationLangImg} from '../../../../../components/molecules/TranslationLangImg';

export const Center = () => {
  const dispatch = useAppDispatch();
  const {languages} = useLanguages();
  const languageSelected = useAppSelector(
    s => s.myTranslationsSection.translations.languageSelected,
  );

  const handleClickLanguage = (language: ICustomLanguage) => {
    dispatch(languageSelectedAction(language));
  };

  useEffect(() => {
    if (languages && languages?.length > 0) {
      dispatch(languageSelectedAction(languages[0]));
    }
  }, [languages, dispatch]);

  return (
    <div css={styles.container}>
      {languages?.map(language => {
        return (
          <TranslationLangImg
            onClick={() => handleClickLanguage(language)}
            key={language.id}
            language={language}
            active={languageSelected?.id === language.id}
          />
        );
      })}
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '1vh',
  }),
  img: (active: boolean) => ({
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '2px solid',
    borderColor: active ? THEME.colors.primary : 'transparent',
  }),
};

import {IExternalConnectionCreatePayload, IExternalConnectionHasStructurePayload, IExternalConnectionUpdatePayload, IFetchExternalCategoryHasStructureResponse, IFetchExternalConnectionsResponse, IFetchStructuresReturn, STRUCTURE_TYPES} from '@bill-app-types/b-types';
import {axiosInstance} from './axiosInstance';
import { helperLog } from '../helpers/helperLog';

export const fetchExternalConnections = async () : Promise<IFetchExternalConnectionsResponse['data']> => {
  const res = await axiosInstance.get<IFetchExternalConnectionsResponse>('bcore/protected/admin/external/connection')

  return res?.data?.data;
};

export const fetchExternalStructures = async () : Promise<IFetchStructuresReturn[]> => {
  const res = await axiosInstance.get<{data: IFetchStructuresReturn[]}>('/bcatalog/backoffice/structures', {
    params: {
      type: [STRUCTURE_TYPES.EXTERNAL]
    }
  });

  return res.data?.data;
};

export const fetchStructureFromExternalConnection = async (externalConnectionUuid?: string): Promise<IFetchExternalCategoryHasStructureResponse['data']> => {
  if (!externalConnectionUuid) return null;
  const res = await axiosInstance.get<IFetchExternalCategoryHasStructureResponse>('/bcatalog/backoffice/external-connections/' + externalConnectionUuid + '/structure');
  return res.data?.data ?? null;
};

export const syncExternalConnections = async () => {
  const res = await axiosInstance
    .post('/bcatalog/backoffice/external/synchro')
    .catch(err => {
      helperLog({message: 'syncExternalConnections', type: 'API_ERROR', data: err});
    });

  return res;
};

export const createExternalConnection = async (data: IExternalConnectionCreatePayload) => {
  if (data.price_rule_uuid === '' || data.price_rule_uuid === null) delete data.price_rule_uuid;
  const res = await axiosInstance
    .post('/bcore/protected/admin/external/connection/create', data)
    .catch(err => {
      helperLog({message: 'createExternalConnection', type: 'API_ERROR', data: err});
    });

  return res?.data;
};

export const createExternalConnectionHasStructure = async ({
  externalConnectionUuid, 
  data
}: {
  externalConnectionUuid: string, 
  data: IExternalConnectionHasStructurePayload
}) => {
  const res = await axiosInstance
    .post(`/bcatalog/backoffice/external-connections/${externalConnectionUuid}/structure`, data)
    .catch(err => {
      helperLog({message: 'createExternalConnection', type: 'API_ERROR', data: err});
    });

  return res;
};

export const updateExternalConnectionHasStructure = async ({
  externalConnectionUuid, 
  data
}: {
  externalConnectionUuid: string, 
  data: IExternalConnectionHasStructurePayload
}) => {
  const res = await axiosInstance
    .put(`/bcatalog/backoffice/external-connections/${externalConnectionUuid}/structure`, {
      structureUuid: data.structureUuid
    })
    .catch(err => {
      helperLog({message: 'updateExternalConnectionHasStructure', type: 'API_ERROR', data: err});
    });

  return res;
};

export const updateExternalConnection = async ({
  externalConnectionId, 
  data
}: {
  externalConnectionId: number, 
  data: IExternalConnectionUpdatePayload
}) => {
  if (data.price_rule_uuid === '') data.price_rule_uuid = null;
  const res = await axiosInstance
    .put(`/bcore/protected/admin/external/connection/${externalConnectionId}`, data)
    .catch(err => {
      helperLog({message: 'updateExternalConnection', type: 'API_ERROR', data: err});
    });

  return res?.data;
}

export const deleteExternalConnection = async (externalConnectionId: number) => {
  const res = await axiosInstance
    .delete(`/bcore/protected/admin/external/connection/${externalConnectionId}`)
    .catch(err => {
      helperLog({message: 'deleteExternalConnection', type: 'API_ERROR', data: err});
    });

  return res?.data;
}

export const deleteExternalConnectionHasStructure = async (externalConnectionUuid: string) => {
  const res = await axiosInstance
    .delete(`/bcatalog/backoffice/external-connections/${externalConnectionUuid}/structure`)
    .catch(err => {
      helperLog({message: 'deleteExternalConnectionHasStructure', type: 'API_ERROR', data: err});
    });

  return res?.data;
}

export const updateExternalConnectionImage = async (externalConnectionId: number, bannerImage: File) => {
  //
  console.log('### updateExternalConnectionImage ###');

  const formData = new FormData();
  formData.append('banner_image', bannerImage);

  const res = await axiosInstance
    .put(`/bcore/protected/admin/external/connection/${externalConnectionId}/banner`, formData)
    .catch(err => {
      console.log('❌ updateExternalConnectionImage : ', err);
    });
  return res;
};
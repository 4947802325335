/** @jsxImportSource @emotion/react */
import DatePicker, {registerLocale} from 'react-datepicker';
import {TextVariant} from '../atoms/TextVariant';
import {CalendarMediumIcon} from '../../assets/svgs/CalendarIcon';
import {css} from '@emotion/react';
import {
  helperDate,
  helperResetDate,
  helperSameDay,
  helperSameHour,
} from '../../helpers/helperDate';
import Fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {
  fromDateAction,
  fromTimeAction,
  toDateAction,
  toTimeAction,
} from '../../redux/date';
import {DateTimeInput} from '../atoms/DateTimeInput';
import {THEME} from '../../styles/theme';
import {useRef} from 'react';

registerLocale('fr', Fr);

// TODO : feat/BFRONT-473-filtre-de-type-date https://bill-app.atlassian.net/browse/BFRONT-473?atlOrigin=eyJpIjoiYTVlNTk0ZTIzZDRjNDI0Y2FmZjg1NTMwYjlkYzA2YjAiLCJwIjoiaiJ9
//INFO : state => typeFilterDate : null equal between period after
//INFO : state => sortFilterDate : null asc desc

interface IDateAndHourProps {
  dateType?: 'from' | 'to';
  // useRef type
  toRef?: React.MutableRefObject<DatePicker | null>;
  fromRef?: React.MutableRefObject<DatePicker | null>;
}

export const DateAndHour = ({dateType, toRef, fromRef}: IDateAndHourProps) => {
  const dispatch = useAppDispatch();
  const typeFilterDate = useAppSelector(s => s.date.typeFilterDate);
  const fromDate = useAppSelector(s => s.date.fromDate);
  const toDate = useAppSelector(s => s.date.toDate);
  const fromTime = useAppSelector(s => s.date.fromTime);
  const toTime = useAppSelector(s => s.date.toTime);
  const dates = useAppSelector(s => s.filter.dates);

  const date = dateType === 'from' ? fromDate : toDate;
  const time = dateType === 'from' ? fromTime : toTime;

  const oldDate = dateType === 'from' ? dates.from : dates.to;

  const isSameDate = helperSameDay(oldDate, date || new Date());
  const isSameTime = helperSameHour(oldDate, time || new Date());

  const timeRef = useRef<DatePicker | null>(null);

  const handleChangeDate = (date: Date | null) => {
    if (!date) return;
    if (dateType === 'from') {
      dispatch(fromDateAction(date));

      // reset toDate after fromDate change only if condition is true
      if (toDate === null || date > toDate) {
        dispatch(toDateAction(date));
      }

      dispatch(
        toTimeAction(
          helperResetDate(new Date(), {
            hour: 23,
            minute: 59,
            second: 59,
          }).toDate(),
        ),
      );
    } else {
      dispatch(toDateAction(date));
    }
    timeRef.current?.setOpen(true);
  };

  const handleChangeTime = (date: Date | null) => {
    if (dateType === 'from') {
      dispatch(fromTimeAction(date));
    } else {
      dispatch(toTimeAction(date));
    }

    if (dateType === 'from' && toRef) {
      toRef.current?.setOpen(true);
    }
  };

  const minDate =
    dateType === 'to' && typeFilterDate === 'between' ? fromDate : null;
  // const maxDate =
  //   dateType === 'from' && typeFilterDate === 'between' ? toDate : null;

  const minTime =
    dateType === 'to' && typeFilterDate === 'between' ? fromTime : null;
  const maxTime =
    dateType === 'from' && typeFilterDate === 'between' ? toTime : null;

  return (
    <div className="flex-space-between">
      <div>
        <DatePicker
          selected={date}
          onChange={date => handleChangeDate(date)}
          minDate={minDate}
          // maxDate={maxDate}
          ref={fromRef || toRef}
          locale={'fr'}
          customInput={
            <div className="flex-align-center" css={styles.dateInput}>
              <CalendarMediumIcon />
              <TextVariant
                text={helperDate(date || new Date(), 'DD/MM/YYYY')}
                variant="textSemiBold"
                style={{
                  marginLeft: '0.5rem',
                  color: isSameDate
                    ? THEME.colors.lightGray
                    : THEME.colors.primary,
                }}
              />
            </div>
          }
        />
      </div>
      <TextVariant text="À" variant="textSemiBold" />

      <DateTimeInput
        minTime={minTime}
        maxTime={maxTime}
        time={time}
        handleChangeTime={handleChangeTime}
        timeRef={timeRef}
        isSameTime={isSameTime}
      />
    </div>
  );
};

const styles = {
  dateInput: css({
    border: `1px solid ${THEME.colors.bgLight}`,
    borderRadius: 2,
    padding: '0.3rem 0.5rem',
    minWidth: '7rem',
  }),
};

import {useContext} from 'react';
import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {
  addAccountingModalAction,
  addVendorStructureModalAction,
} from '../../../../../redux/modals';
import {useAppSelector} from '../../../../../hooks/useReduxHook';

export const Center = () => {
  const {t, dispatch} = useContext<any>(UtilsContext);
  const showSection = useAppSelector(s => s.app.sectionShow);

  const handleOpenModalAddAccounting = () => {
    dispatch(addAccountingModalAction(true));
  };

  const handleOpenModalAddVendorStructure = () => {
    dispatch(addVendorStructureModalAction(true));
  };

  return (
    <>
      {showSection === 'BANKS_AND_ACCCOUNTS' && (
        <ButtonCustom
          label={t('ADD_ACCOUNT')}
          onClick={handleOpenModalAddAccounting}
        />
      )}
      {showSection === 'VENDOR_STRUCTURES' && (
        <ButtonCustom
          label={'Ajouter une famille de taxe'}
          onClick={handleOpenModalAddVendorStructure}
        />
      )}
    </>
  );
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import Header from './Header';
import Pos from './Pos';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';

const PosPreview = withRedirectAndNavigation(() => {
  return (
    <div css={styles.container}>
      <Header />
      <Pos />
    </div>
  );
});

export default PosPreview;

const styles = {
  container: css({
    height: '100vh',
    padding: '2vh',
  }),
};

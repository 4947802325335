/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../../../styles/theme';
import {IGetPaymentSettingsResponse} from '@bill-app-types/b-types';

export const PaymentLine = ({item}: {item: IGetPaymentSettingsResponse}) => {
  //
  return (
    <div css={styles.container} className="textSemiBold flex">
      {item.account && item.account}
    </div>
  );
};

const styles = {
  container: css({
    height: '40px',
    backgroundColor: THEME.colors.white,
    padding: '0 10px',
    border: 'none',
    borderRadius: '2px',
    width: '100%',
    alignItems: 'center',
  }),
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';

import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../../components/layouts/Header';
import {Center} from './HeaderSections/Center';
import {OptionsCatalog} from '../../../../../components/organisms/OptionsCatalog';
import {OptionCategoryContent} from './OptionCategoryContent';
import {useNavigate, useParams} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';
import {useDispatch} from 'react-redux';
import {updatedCategoryAction} from '../../../../../redux/myCatalogSection/options';
import {
  createItemOptionItem,
  getCatalogModifierDetails,
} from '../../../../../api/fetchCatalogOptions';
import {helperLabelTranslation} from '../../../../../helpers/helperLabel';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {IGetModifierDetailsResponse} from '@bill-app-types/b-types';

export const SelectedOptionCategory = withRedirectAndNavigation(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const {brandHasAuthorization} = useBrandAccess();

  const {data, refetch} = useQuery(
    ['getCatalogModifierDetails'],
    () => getCatalogModifierDetails(String(params?.uuid)),
    {
      enabled: !!params.uuid,
    },
  );

  if (params?.uuid && data) {
    dispatch(
      updatedCategoryAction({
        ...data,
        minimumValue: data?.minimum,
        maximumValue: data?.maximum,
        label: helperLabelTranslation(data),
      }),
    );
  } else if (params?.uuid && !data) {
    navigate('/online/catalog/options');
  }

  const handleClickOption = async (
    item: IGetModifierDetailsResponse['modifierHasItems'][0],
  ) => {
    if (params.uuid) {
      const optionFinded = data?.modifierHasItems?.find(
        o => o.uuid === item.uuid,
      );
      if (!optionFinded) {
        const res = await createItemOptionItem(params.uuid, {
          itemUuid: item.uuid,
        });
        refetch();
        if (res?.status === 204) {
          helperDisplayToast(
            res?.data?.message || 'Item ajouté à la catégorie',
            true,
          );
        } else {
          helperDisplayToast(
            res?.data?.message || "Impossible d'ajouter l'item à la catégorie",
            false,
          );
        }
      }
    } else helperDisplayToast("Aucune catégorie d'options séléctionnée", false);
  };

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <div css={styles.container}>
      <Header centerSection={<Center categorySelected={data} />} />
      <div className="flex" css={styles.content}>
        {hasAuthorization && (
          <OptionsCatalog
            selectedItems={data?.modifierHasItems}
            onClickProduct={handleClickOption}
          />
        )}
        <OptionCategoryContent selectedItems={data?.modifierHasItems} />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: css({
    padding: '2vh',
    paddingBottom: 0,
    height: '87%',
    gap: '2rem',
  }),
};

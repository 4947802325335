/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {EqualIcon} from '../../assets/svgs/EqualIcon';

interface IButtonCustomProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  backgroundColor?: string;
  marginLeft?: string | number;
  marginRight?: string | number;
  children: any;
}

export const SquareButton = ({
  onClick,
  disabled = false,
  backgroundColor = THEME.colors.primary,
  marginLeft = 0,
  marginRight = 0,
  children,
}: IButtonCustomProps) => (
  <button
    onClick={onClick}
    disabled={disabled}
    css={styles.button(backgroundColor, marginLeft, marginRight)}>
    {children}
  </button>
);

export const SquareButton2 = ({
  Icon = EqualIcon,
  isActive = false,
  disabled = false,
  onClick,
  color,
  bgColor,
}: {
  Icon?: React.FC<{color: string}>;
  isActive?: boolean;
  disabled?: boolean;
  color?: string;
  bgColor?: string;
  onClick?: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      css={styles.btn({
        isActive,
        disabled,
        bgColor,
      })}
      disabled={disabled}
      type="button">
      <Icon color={color || (isActive ? 'white' : THEME.colors.primary)} />
    </button>
  );
};

const styles = {
  button: (
    backgroundColor: string,
    marginLeft: string | number,
    marginRight: string | number,
  ) =>
    css({
      backgroundColor,
      border: '1px solid' + backgroundColor,
      borderRadius: 2,
      padding: '0 10px',
      height: 28,
      marginLeft,
      marginRight,
      cursor: 'pointer',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  btn: ({
    isActive,
    disabled,
    bgColor,
  }: {
    isActive: boolean;
    disabled: boolean;
    bgColor?: string;
  }) =>
    css({
      width: '25px',
      height: '25px',
      borderRadius: '1.31px',
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: bgColor || (isActive ? THEME.colors.primary : 'white'),
      opacity: disabled ? 0.5 : 1,
      cursor: 'pointer',
    }),
};

import {useQuery} from '@tanstack/react-query';
import {getItems} from '../api/fetchItems';
import { useAppSelector } from './useReduxHook';
import { useDebounce } from './useDebounce';

export const useItems = ({offset, limit}: {offset?: number, limit?: number}) => {
  const search = useAppSelector(s => s.filter.search);
  const orderBy = useAppSelector(s => s.filter.orderBy);
  const itemType = useAppSelector(s => s.filter.itemType);

  const debouncedSearchTerm = useDebounce(search, 500);

  const {data, isLoading, refetch} = useQuery(
    ['getItems', debouncedSearchTerm, orderBy, offset, limit, itemType],
    () => getItems({
      search: debouncedSearchTerm,
      offset,
      limit,
      orderBy,
      type: itemType
    }),
  );

  return {
    data: data || [],
    isLoading,
    refetch
  };
};

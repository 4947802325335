import {useQuery} from '@tanstack/react-query';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {useTranslations} from '../../../../hooks/useTranslations';
import {TranslationItem} from './TranslationItem';
import {fetchBGlobalParameterTranslations} from '../../../../api/fetchTranslations';
import {useTranslation} from 'react-i18next';

export const General = () => {
  const {t} = useTranslation();
  const {isOpened, handleToggleIsOpened} = useTranslations();

  const languageSelected = useAppSelector(
    s => s.myTranslationsSection.translations.languageSelected,
  );

  const {data, isLoading} = useQuery(
    ['fetchBGlobalParameterTranslations'],
    () => fetchBGlobalParameterTranslations(),
    {
      enabled: isOpened && !!languageSelected,
    },
  );

  return (
    <TranslationItem
      title={t('PARAMETERS_GENERAL')}
      items={data || []}
      isLoading={isLoading}
      isOpened={isOpened}
      onToggleIsOpened={handleToggleIsOpened}
      category={'BGlobal'}
      fields={['out_of_stock_label']}
      showHeader={false}
    />
  );
};

import {SVGProps} from 'react';
// import {useAppSelector} from '../../hooks/useReduxHook';

// import {THEME} from '../../styles/theme';

export const CloseIcon = (props: SVGProps<SVGSVGElement>) => {
  // const currentTheme = useAppSelector(s => s.app.currentTheme);
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.85857 1.04974C10.1685 0.733775 10.6767 0.728843 10.9926 1.03873C11.3086 1.34863 11.3135 1.85682 11.0036 2.17279L6.80395 6.45225L11.0834 10.6519C11.3994 10.9618 11.4043 11.47 11.0944 11.786C10.7845 12.1019 10.2763 12.1069 9.96037 11.797L5.6809 7.59731L1.48123 11.8768C1.17134 12.1927 0.66314 12.1977 0.347175 11.8878C0.031209 11.5779 0.0262768 11.0697 0.336168 10.7537L4.53584 6.47426L0.25638 2.27459C-0.0595856 1.9647 -0.0644935 1.459 0.245398 1.14304C0.55529 0.827073 1.06349 0.822141 1.37945 1.13203L5.65889 5.3292L9.85857 1.04974Z"
        /*fill={
          props.color ||
          THEME.colors[currentTheme === 'dark' ? 'gray' : 'black']
        }*/
        fill={props.color || '#A7A7AB'}
      />
    </svg>
  );
};

/** @jsxImportSource @emotion/react */
import {ModalWrapper} from '../atoms/ModalWrapper';
import {useAppDispatch} from '../../hooks/useReduxHook';
import {addPosStructureModalAction} from '../../redux/modals';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';
import {createStructure} from '../../api/fetchStructures';
import {useState} from 'react';
import {STRUCTURE_TYPES} from '@bill-app-types/b-types';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {InputLabel} from '../atoms/InputLabel';
import {useNavigate} from 'react-router-dom';
import {
  selectedPosCategoryAction,
  treePreRegisterAction,
} from '../../redux/myModulesSection/pos';

export const AddPosStructureModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    label: '',
    type: STRUCTURE_TYPES.STAFF,
  });

  const clientQuery = useQueryClient();

  /* ON CANCEL */
  const handleOnCancel = () => {
    dispatch(addPosStructureModalAction(false));
  };

  const handleCreateCategory = async (isSave?: boolean) => {
    if (form.label && form.type) {
      const res = await createStructure(form);
      if (res && res?.status === 200) {
        handleOnCancel();
        helperDisplayToast(res?.data?.message || 'Arborescence créée', true);
        if (isSave) {
          clientQuery.invalidateQueries(['fetchStructures']);
        } else {
          dispatch(selectedPosCategoryAction(null));
          dispatch(
            treePreRegisterAction({
              uuid: res?.data?.data?.uuid,
              categories: [],
            }),
          );
          navigate(`/online/modules/bpos/tree/edit`);
          handleOnCancel();
        }
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible de créer l'arborescence",
          false,
        );
      }
    } else {
      helperDisplayToast('Tous les champs doivent être complétés', false);
    }
  };

  const handleOnSubmit = () => {
    handleCreateCategory();
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;
    setForm({...form, [name]: value});
  };

  const handleOnSave = async () => {
    handleCreateCategory(true);
  };

  return (
    <ModalWrapper
      title={t('Arborescence')}
      validateLabel={t('CONTINUE_CREATION')}
      onSave={handleOnSave}
      saveLabel={t('REGISTER_AND_CONTINUE_CREATION_LATER')}
      onCancel={handleOnCancel}
      onSubmit={handleOnSubmit}
      hideCancelButton
      initialValues={form}
      titleMarginBottom={35}
      minWidth="40%">
      <InputLabel
        onChange={handleChange}
        label={"Nom de l'arborescence"}
        name="label"
        required
        value={form.label}
      />
      <FieldsRequired />
    </ModalWrapper>
  );
};

/** @jsxImportSource @emotion/react */
import {useState} from 'react';
import {fetchBcatalogSynchro, fetchBcoreSynchro} from '../../api/fetchSynchro';
import {THEME} from '../../styles/theme';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {css} from '@emotion/react';
import {useBrandAccess} from '../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../constants/frontPolicies';
import {useTranslation} from 'react-i18next';
import {helperDisplayToast} from '../../helpers/helperToast';

export const ButtonSynchro = () => {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const {brandHasAuthorization} = useBrandAccess();

  const isParentBrand = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const handleSynchro = async () => {
    try {
      setIsLoading(true);
      await Promise.all([fetchBcoreSynchro(), fetchBcatalogSynchro()]);
      setIsLoading(false);
    } catch (err: any) {
      helperDisplayToast(err?.response?.data?.message || err?.message, false);
    }
  };

  if (isParentBrand) {
    return null;
  }

  return (
    <div css={styles.container}>
      <ButtonCustom
        label={t('SYNCHRONISATION')}
        backgroundColor={THEME.colors.red}
        color={THEME.colors.white}
        onClick={handleSynchro}
        isLoading={isLoading}
      />
    </div>
  );
};

const styles = {
  container: css({
    position: 'absolute',
    right: '2vh',
    top: '1rem',
    '@media (max-width: 1024px)': {
      top: '3rem',
    },
  }),
};

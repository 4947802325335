/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ProductList} from '../../../../../components/molecules/ProductList';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {useQueryClient} from '@tanstack/react-query';
import {deleteItemOptionItem} from '../../../../../api/fetchCatalogOptions';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {THandleDragAndDrop} from '../../../../../components/organisms/SortableListVerticalContainer';
import {IGetModifierHasItemsDetailsResponse} from '@bill-app-types/b-types';
import {updateItemsPosition} from '../../../../../api/fetchItems';

export const OptionOfOptionCategory = ({
  selectedItems,
}: {
  selectedItems?: IGetModifierHasItemsDetailsResponse[];
}) => {
  const clientQuery = useQueryClient();

  const categoryPreRegister = useAppSelector(
    s => s.myCatalogSection.options.updatedCategory,
  );

  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const handleClickRemoveOptionItem = async (option: {uuid: string}) => {
    if (!hasAuthorization || !categoryPreRegister) {
      return;
    }
    if (categoryPreRegister.uuid) {
      const res = await deleteItemOptionItem(
        categoryPreRegister.uuid,
        option?.uuid,
      );
      await clientQuery.invalidateQueries(['getCatalogModifierDetails']);
      if (res && res.status === 204) {
        helperDisplayToast(
          res?.data?.message || 'Item retiré de la carte',
          true,
        );
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible de retirer l'item de la carte",
          false,
        );
      }
    }
  };

  const handleDragAndDrop: THandleDragAndDrop = async ({items}) => {
    if (!hasAuthorization || !categoryPreRegister) {
      return;
    }

    const res = await updateItemsPosition(categoryPreRegister.uuid, items);

    if (res && res?.status === 204) {
      helperDisplayToast('Position modifiée', true);
    } else {
      helperDisplayToast('Impossible de modifier la position', false);
    }
  };

  return (
    <div css={styles.container}>
      <div css={styles.productListContainer}>
        <ProductList
          showProductType
          draggable
          bg="white"
          data={selectedItems || []}
          handleOnClick={handleClickRemoveOptionItem}
          handleDragAndDrop={handleDragAndDrop}
          showCross={hasAuthorization}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  }),
  productListContainer: css({
    backgroundColor: 'white',
    padding: '10px 9%',
    marginTop: 20,
    width: '100%',
    overflow: 'auto',
  }),
};

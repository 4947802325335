import {CONSUMPTION_MODE} from '@bill-app-types/b-types';
import {THEME} from '../styles/theme';
import {MotoBikeIcon} from '../assets/svgs/MotoBikeIcon';
import {TakeAwayIcon} from '../assets/svgs/TakeAwayIcon';
import {OnSpotIcon} from '../assets/svgs/OnSpotIcon';

export const helperOrderConsumptionModeBadge = (
  consumptionMode: CONSUMPTION_MODE,
) => {
  switch (consumptionMode) {
    case CONSUMPTION_MODE.DELIVERY:
      return {
        background: THEME.colors.red,
        Icon: MotoBikeIcon,
      };
    case CONSUMPTION_MODE.TAKE_AWAY:
      return {
        background: THEME.colors.yellowDark,
        Icon: TakeAwayIcon,
      };
    case CONSUMPTION_MODE.ON_THE_SPOT:
      return {
        background: THEME.colors.green,
        Icon: OnSpotIcon,
      };
    default:
      return {
        background: THEME.colors.green,
        Icon: MotoBikeIcon,
      };
  }
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {CopyIcon} from '../../assets/svgs/CopyIcon';
import {mq} from '../../helpers/helperMediaQueries';
import {THEME} from '../../styles/theme';
import {TextVariant} from '../atoms/TextVariant';
import {ArrowDownLoadIcon} from '../../assets/svgs/DownloadIcon';
import {QRCode} from 'react-qrcode-logo';
import {useRef, RefObject} from 'react';
import {helperDownloadQrCode} from '../../helpers/helperDownloadQrCode';

export const QrCodeCard = ({
  width = ['16%', '24%', '32%', '49%', '100%'],
  areaLocation,
  downloadBtnRef,
}: {
  width?: string | string[];
  areaLocation?: any;
  showDownloadIcon?: boolean;
  downloadBtnRef?: RefObject<HTMLParagraphElement>;
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const pictureName = `${areaLocation.area.label}_${areaLocation.label}.png`;

  const copyLink = (link = '') => {
    navigator.clipboard.writeText(link);
  };

  const handleDownloadPng = async () => {
    if (divRef.current === null) {
      return;
    }

    helperDownloadQrCode(divRef.current, pictureName);
  };

  const url = areaLocation.qr_code_url;

  return (
    <div css={[styles.qrCode, mq({width: width})]} className="flex-col">
      <div
        ref={divRef}
        style={{background: 'white'}}
        className="qr_code_card"
        data-picture-name={pictureName}>
        <div css={styles.qrCode__img__container}>
          <QRCode value={url} logoImage="/images/qr_b!.jpeg" size={138} />
        </div>
        <div className="flex-col-center">
          <TextVariant
            variant="title03"
            text="Identifiant de l’emplacement"
            style={styles.localy_label}
          />
          <TextVariant
            variant="textSemiBold"
            text={`${areaLocation.area?.label}: ${areaLocation.label}`}
          />
        </div>
      </div>
      <div className="flex" css={styles.qrCode__footer}>
        <p
          ref={downloadBtnRef}
          css={[styles.downloadBtn]}
          className="flex"
          onClick={handleDownloadPng}>
          <ArrowDownLoadIcon />
        </p>

        <TextVariant variant="title03" text={url} style={styles.url} />

        <button
          css={styles.copyBtn}
          type="button"
          title="Copier le lien"
          onClick={() => copyLink(url)}>
          <CopyIcon />
        </button>
      </div>
    </div>
  );
};

const styles = {
  qrCode: mq({
    // width: ['16%', '24%', '32%', '49%', '100%'],
    backgroundColor: 'white',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 270,
  }),
  qrCode__img__container: css({
    height: 'auto',
    width: 'auto',
    padding: 5,
    border: `1px solid ${THEME.colors.lightGray}`,
  }),
  localy_label: css({
    color: '#A7A7AB',
  }),
  qrCode__footer: css({
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 3,
    borderRadius: 2,
    border: `1px solid ${THEME.colors.lightGray}`,
  }),
  downloadBtn: css({
    padding: 5,
    borderRadius: 2,
    backgroundColor: THEME.colors.bgLight,
  }),
  copyBtn: css({
    border: 'none',
    backgroundColor: 'transparent',
  }),
  url: css({
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: '0 5px',
  }),
};

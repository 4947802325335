export const BKiosk = ({
  size,
  isDisabled = false,
  isActive = false,
}: {
  size: string;
  isDisabled?: boolean;
  isActive?: boolean;
}) => (
  <svg
    width={size === 'small' ? '42' : '64'}
    height={size === 'small' ? '13' : '24'}
    viewBox="0 0 64 20"
    fill="none">
    <path
      d="M7.11961 5.48903C6.252 5.48903 5.42121 5.64392 4.65325 5.92446L6.39064 9.17827C6.62569 9.12737 6.8694 9.0992 7.12069 9.0992C9.02597 9.0992 10.5716 10.6189 10.5716 12.4938C10.5716 14.3688 9.02705 15.8884 7.12069 15.8884C5.21433 15.8884 3.76181 14.4522 3.67841 12.6498L3.67083 12.6585V6.36639C3.67083 6.36639 3.66541 6.36964 3.66216 6.37072L3.67083 0.5H0L0 19.4975H3.64158V18.605C4.67167 19.1726 5.85664 19.4975 7.11961 19.4975C11.0515 19.4975 14.2392 16.3618 14.2392 12.4927C14.2392 8.6237 11.0515 5.48903 7.11961 5.48903Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#151A30' : '#3C6AF6'}
    />
    <g clipPath="url(#clip0_8897_7629)">
      <path
        d="M27.1251 9.50171C25.8147 10.7205 24.6091 11.8405 23.5433 12.8617L19.2277 16.9299V19.3841H16.4321V5.54877H19.2277V13.5205L23.6656 9.50171H27.1251ZM22.0756 14.7394C22.7221 15.3488 23.2463 15.8264 23.6306 16.2052C24.0325 16.5676 24.347 16.8805 24.6091 17.1111L25.2031 17.6711C25.3429 17.8029 25.5002 17.9511 25.6574 18.0829C25.8147 18.2147 25.9894 18.3958 26.199 18.5935C26.4087 18.7911 26.6883 19.0546 27.0552 19.3841H23.5957L21.3243 17.2264L22.0756 14.7394Z"
        fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
      />
      <path
        d="M28.2085 5.54907H31.004V7.85495H28.2085V5.54907ZM28.2085 19.3844V9.50201H31.004V19.3844H28.2085Z"
        fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
      />
      <path
        d="M37.4861 9.33752C38.2374 9.33752 38.9537 9.46929 39.6002 9.74929C40.2641 10.0128 40.8233 10.3916 41.3125 10.8528C41.8017 11.314 42.1861 11.8575 42.4831 12.4669C42.7627 13.0928 42.9199 13.7516 42.9199 14.4434C42.9199 15.1352 42.7801 15.794 42.4831 16.4199C42.1861 17.0458 41.8017 17.5728 41.3125 18.034C40.8233 18.4952 40.2467 18.8575 39.6002 19.1375C38.9363 19.4011 38.2374 19.5493 37.4861 19.5493C36.7348 19.5493 36.0184 19.4175 35.3719 19.1375C34.708 18.874 34.1489 18.4952 33.6597 18.034C33.1705 17.5728 32.7861 17.0293 32.489 16.4199C32.192 15.8105 32.0522 15.1352 32.0522 14.4434C32.0522 13.7516 32.192 13.0928 32.489 12.4669C32.7686 11.8411 33.1705 11.314 33.6597 10.8528C34.1489 10.3916 34.7255 10.0293 35.3719 9.74929C36.0359 9.48576 36.7348 9.33752 37.4861 9.33752ZM37.4861 17.1775C37.8355 17.1775 38.1675 17.1116 38.4645 16.9634C38.7615 16.8152 39.0236 16.6175 39.2682 16.354C39.4954 16.0905 39.6876 15.794 39.8099 15.4646C39.9322 15.1352 40.0021 14.7563 40.0021 14.3611C40.0021 13.9987 39.9322 13.6363 39.8099 13.3069C39.6701 12.9775 39.4954 12.6811 39.2682 12.434C39.0411 12.1869 38.779 11.9893 38.4645 11.8575C38.1675 11.7093 37.8355 11.6434 37.4861 11.6434C37.1366 11.6434 36.8047 11.7093 36.5076 11.8575C36.1931 12.0058 35.9311 12.2034 35.7039 12.434C35.4768 12.6811 35.2846 12.9611 35.1623 13.3069C35.0225 13.6363 34.9701 13.9987 34.9701 14.3611C34.9701 14.7563 35.04 15.1187 35.1623 15.4646C35.3021 15.794 35.4768 16.0905 35.7039 16.354C35.9311 16.6175 36.2106 16.8152 36.5076 16.9634C36.8221 17.1116 37.1366 17.1775 37.4861 17.1775Z"
        fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
      />
      <path
        d="M48.5109 13.5208C48.9652 13.6691 49.402 13.8173 49.7864 13.9655C50.1708 14.1138 50.5027 14.2949 50.7998 14.5091C51.0968 14.7232 51.3065 14.9702 51.4637 15.2667C51.6209 15.5632 51.7083 15.9091 51.7083 16.3208C51.7083 17.342 51.3589 18.1326 50.6425 18.6926C49.9436 19.2526 48.9477 19.5491 47.6723 19.5491C46.8685 19.5491 46.0998 19.4173 45.331 19.1538C44.5622 18.8902 43.8459 18.5279 43.1644 18.0502H43.147L43.1644 18.0173L44.5972 15.8926H44.6146C45.034 16.3373 45.5057 16.6502 46.0473 16.8643C46.5715 17.0785 47.1481 17.1938 47.7421 17.1938C48.5633 17.1938 48.9827 16.9467 48.9827 16.4361C48.9827 16.2055 48.8429 16.0243 48.5808 15.9091C48.3187 15.7938 48.0042 15.6785 47.6373 15.5796C47.5674 15.5467 47.4975 15.5302 47.4451 15.5302C47.3752 15.5302 47.3228 15.5138 47.2529 15.4808C46.8336 15.382 46.4143 15.2667 45.9949 15.1349C45.5756 15.0032 45.1912 14.8385 44.8592 14.6243C44.5273 14.4102 44.2477 14.1302 44.0381 13.8008C43.8284 13.4714 43.7061 13.0432 43.7061 12.5161V12.4832C43.7061 12.022 43.7934 11.6102 43.9856 11.2149C44.1778 10.8196 44.4224 10.5067 44.7544 10.2267C45.0864 9.9467 45.4882 9.73258 45.9775 9.58435C46.4667 9.43611 46.9734 9.35376 47.5499 9.35376C48.2139 9.35376 48.8953 9.45258 49.5767 9.63376C50.2581 9.83141 50.9046 10.1114 51.4812 10.4738V10.5067L50.2057 12.6973H50.1882C49.8737 12.3679 49.4719 12.1373 49.0001 11.9561C48.5284 11.7749 48.0217 11.6926 47.4975 11.6926C47.3927 11.6926 47.2879 11.6926 47.1656 11.7091C47.0433 11.7091 46.9209 11.7585 46.8161 11.8079C46.7113 11.8573 46.6239 11.9232 46.554 12.022C46.4841 12.1043 46.4492 12.2361 46.4492 12.3843C46.4492 12.4996 46.5016 12.6149 46.6239 12.7138C46.7288 12.8126 46.886 12.9114 47.0607 12.9938C47.2354 13.0761 47.4276 13.1585 47.6548 13.2408C47.8819 13.3232 48.0916 13.3891 48.3012 13.4549C48.3362 13.4549 48.3886 13.4549 48.441 13.4714C48.4934 13.4879 48.5284 13.5043 48.5808 13.5043L48.5109 13.5208Z"
        fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
      />
      <path
        d="M63.4322 9.50201C62.1218 10.7208 60.9162 11.8408 59.8504 12.862L55.5348 16.9302V19.3844H52.7393V5.54907H55.5348V13.5208L59.9727 9.50201H63.4322ZM58.3828 14.7397C59.0292 15.3491 59.5534 15.8267 59.9378 16.2055C60.3396 16.5679 60.6541 16.8808 60.9162 17.1114L61.5103 17.6714C61.65 17.8032 61.8073 17.9514 61.9645 18.0832C62.1218 18.215 62.2965 18.3961 62.5062 18.5938C62.7158 18.7914 62.9954 19.055 63.3623 19.3844H59.9028L57.6314 17.2267L58.3828 14.7397Z"
        fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
      />
    </g>
    <defs>
      <clipPath id="clip0_8897_7629">
        <rect
          width="47"
          height="14"
          fill="white"
          transform="translate(16.4321 5.54877)"
        />
      </clipPath>
    </defs>
  </svg>
);

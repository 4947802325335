/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CloseIcon} from '../../assets/svgs/CloseIcon';
import {DownLoadIcon} from '../../assets/svgs/DownloadIcon';
import {PictureIcon} from '../../assets/svgs/PictureIcon';
import {addUserModalAction} from '../../redux/modals';
import {THEME} from '../../styles/theme';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {SelectList} from '../molecules/SelectList';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {useStaffGroups} from '../../hooks/useStaffGroups';
import {createStaffUser, updateStaffUser} from '../../api/fetchStaff';
import {useQueryClient} from '@tanstack/react-query';
import * as Yup from 'yup';
import {helperDisplayToast} from '../../helpers/helperToast';
import {
  IStaffUser,
  isUpdatedModeAction,
  userSelectedAction,
} from '../../redux/myStaffSection/users';

const createUserSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Champ requis'),
  lastname: Yup.string()
    .min(2, 'trop court!')
    .max(50, 'trop long!')
    .required('Champ requis'),
  email: Yup.string().email('Invalid email').required('Champ requis'),
  phone: Yup.string().required('Champ requis'),
  groupUuid: Yup.string().required('Champ requis'),
});

export const AddUserModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {staffGroupsData} = useStaffGroups();

  const isUpdatedMode = useAppSelector(
    s => s.myStaffSection.users.isUpdatedMode,
  );
  const brand = useAppSelector(s => s.brand.brand);
  const userSelected = useAppSelector(s => s.myStaffSection.users.userSelected);
  const [avatar, setAvatar] = useState<string | null>(null);

  const groupsList = staffGroupsData?.map(group => ({
    label: group.label,
    value: group.uuid,
  }));

  const initialValues = {
    ...userSelected,
    groupUuid: userSelected?.group?.uuid,
  } || {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    groupUuid: '',
  };
  const clientQuery = useQueryClient();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => {
        setAvatar(e.target?.result as string);
      };
      reader.readAsDataURL(e.target.files[0]);
      return;
    }
  };
  //TODO dynamic submit
  const handleSubmit = async (data: IStaffUser, {setSubmitting}: any) => {
    try {
      setSubmitting(true);
      let res;
      // TODO add avatar after api is ready

      if (isUpdatedMode && userSelected?.uuid) {
        res = await updateStaffUser(userSelected.uuid, data);
      } else {
        res = await createStaffUser({
          ...data,
          companyIdGuid: brand.company_id_guid,
        });
      }

      if (res?.status === 200 || res?.status === 204) {
        clientQuery.invalidateQueries(['fetchStaffUsers']);
        handleCancel();
        helperDisplayToast(
          isUpdatedMode
            ? 'Utilisateur modifié avec succès'
            : 'Utilisateur ajouté avec succès',
          true,
        );
      } else if (res?.status === 409) {
        helperDisplayToast('Cet email est déjà utilisé', false);
      } else {
        helperDisplayToast(
          res?.data?.message || "Une erreur s'est produite",
          false,
        );
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
    // console.log(data);
  };

  const handleRemoveImg = () => {
    setAvatar(null);
  };

  const handleCancel = () => {
    dispatch(addUserModalAction(false));
    dispatch(userSelectedAction(null));
    dispatch(isUpdatedModeAction(false));
  };

  return (
    <ModalWrapper
      title="Utilisateur"
      cancelLabel={t(isUpdatedMode ? 'CANCEL' : 'CANCEL_CREATE')}
      validateLabel={t('REGISTER_USER')}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validateSchema={createUserSchema}>
      <div css={styles.container}>
        <div css={styles.uploadImg} className="flex-col-center">
          <label
            className="flex-col-center"
            htmlFor="user_img"
            css={styles.uploadImgLabel}>
            {!avatar && (
              <>
                <PictureIcon />
                <div css={styles.imgResolution}>
                  <p className="title03">{t('RECOMMANDED_RESOLUTION')}</p>
                  <p className="title03">60 x 60</p>
                </div>
                <DownLoadIcon />
              </>
            )}

            {avatar && <img src={avatar} css={styles.img} alt="avatar" />}
          </label>

          {avatar && (
            <>
              <button
                css={styles.btnRemoveImg}
                className="flex-center"
                onClick={handleRemoveImg}>
                <CloseIcon />
              </button>
            </>
          )}
        </div>

        <input hidden type="file" id="user_img" onChange={handleChange} />
        <div>
          <div className="flex-space-between">
            <FieldCustomInput
              label="Nom"
              name="firstname"
              required
              width={'48%'}
            />
            <FieldCustomInput
              label="Prénom"
              name="lastname"
              required
              width={'48%'}
            />
          </div>
          <FieldCustomInput label="Email" name="email" required />
          <FieldCustomInput label="Téléphone" name="phone" required />
          <SelectList
            label="Groups"
            name="groupUuid"
            list={[
              {
                label: 'Aucune sélection',
                value: '',
              },
              ...groupsList,
            ]}
            required
          />
        </div>
        <FieldsRequired />
        {isUpdatedMode && <ButtonCustom label={t('RESET_PASSWORD')} />}
      </div>
    </ModalWrapper>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
  }),
  uploadImg: css({
    alignSelf: 'center',
    width: 194,
    height: 196,
    borderRadius: '50%',
    backgroundColor: 'white',
    marginBottom: 20,
    position: 'relative',
  }),
  uploadImgLabel: css({
    width: '100%',
    height: '100%',
  }),
  imgResolution: css({
    marginTop: '10px',
    marginBottom: '15px',
    textAlign: 'center',
    color: THEME.colors.gray,
  }),

  img: css({
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
  }),
  btnRemoveImg: css({
    position: 'absolute',
    top: 5,
    right: 30,
    backgroundColor: THEME.colors.red,
    border: 'none',
    padding: '2px 3px',
  }),
  resetBtn: css({
    marginTop: 20,
    backgroundColor: THEME.colors.primary,
    color: 'white',
    border: 'none',
    padding: '10px 0',
    borderRadius: 2,
    height: 28,
  }),
};

import {SVGProps} from 'react';

export const CustomerEuroIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.61255 7.46875C1.16398 7.46875 0.800049 7.11348 0.800049 6.67188C0.800049 6.23027 1.16398 5.875 1.61255 5.875H2.73922C3.67088 2.80271 6.57218 0.5625 10.0084 0.5625H10.8209C11.2711 0.5625 11.6334 0.919434 11.6334 1.35938C11.6334 1.79932 11.2711 2.15625 10.8209 2.15625H10.0084C7.48286 2.15625 5.32297 3.69688 4.42583 5.875H9.73755C10.1878 5.875 10.55 6.23027 10.55 6.67188C10.55 7.11348 10.1878 7.46875 9.73755 7.46875H4.07375C4.05682 7.64473 4.05005 7.8207 4.05005 8C4.05005 8.1793 4.05682 8.35527 4.07375 8.53125H9.73755C10.1878 8.53125 10.55 8.88652 10.55 9.32812C10.55 9.76973 10.1878 10.125 9.73755 10.125H4.42583C5.32297 12.3031 7.48286 13.8438 10.0084 13.8438H10.8209C11.2711 13.8438 11.6334 14.199 11.6334 14.6406C11.6334 15.0822 11.2711 15.4375 10.8209 15.4375H10.0084C6.57218 15.4375 3.67088 13.1963 2.73922 10.125H1.61255C1.16398 10.125 0.800049 9.76973 0.800049 9.32812C0.800049 8.88652 1.16398 8.53125 1.61255 8.53125H2.44401C2.43148 8.35527 2.42505 8.1793 2.42505 8C2.42505 7.8207 2.43148 7.64473 2.44401 7.46875H1.61255Z"
      fill="#CCD6E0"
    />
  </svg>
);

import {useQuery} from '@tanstack/react-query';
import { fetchSubmenuProducts } from '../api/fetchSubmenus';

export const useSubmenuProduct = ({
  submenu,
  isOpened = false
}:{
  submenu: any,
  isOpened: boolean
}) => {

  const {data, isLoading, refetch} = useQuery(
    [`fetchSubmenuProducts-${submenu.uuid}`],
    () => fetchSubmenuProducts(submenu.uuid),
    {
      enabled: isOpened && !!submenu.uuid,
    },
  );

  return {
    data: data?.items || [],
    isLoading,
    refetch
  };
};

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { ButtonCustom } from "../../../../components/atoms/ButtonCustom"
import { t } from "i18next"
import { THEME } from "../../../../styles/theme"
import { M_SCREEN_TABLET } from "../../../../constants/ResponsiveSize"
import { useState } from "react"
import { syncExternalConnections } from "../../../../api/fetchExternalConnections"
import { helperDisplayToast } from "../../../../helpers/helperToast"
import { useAppDispatch } from "../../../../hooks/useReduxHook"
import { addExternalConnectionModalAction } from "../../../../redux/modals"

export const DeliveryButtons = () => {
  const [isLoadingSync, setIsLoadingSync] = useState(false);

  const dispatch = useAppDispatch();

  const handleSync = async () => {
    setIsLoadingSync(true);
    const res = await syncExternalConnections();
    if (res?.status === 204) 
      helperDisplayToast(t('SYNC_STARTED'), true);
    else
      helperDisplayToast(t('SYNC_ERROR_HAPPENED'), false);
    setIsLoadingSync(false);
  }
  
  const handleAddExternalConnection = () => {
    dispatch(addExternalConnectionModalAction(true));
  }

  return (
    <div css={styles.container}>
      <ButtonCustom 
        label={t('SYNC')} 
        onClick={handleSync} 
        isLoading={isLoadingSync} 
        marginRight={10} 
      />
      <ButtonCustom 
        label={t('ADD_EXTERNAL_CONNECTION')} 
        onClick={handleAddExternalConnection} 
        backgroundColor={THEME.colors.green} 
      />
    </div>
  )
}

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20,
    [M_SCREEN_TABLET]: {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })
}
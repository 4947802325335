import {useQuery} from '@tanstack/react-query';
import {fetchAreaProductions} from '../api/fetchAreaProductions';

export const useAreaProduction = () => {
  const {data, isLoading, error} = useQuery(
    ['fetchAreaProductions'],
    fetchAreaProductions,
  );

  return {
    data,
    isLoading,
    error,
  };
};

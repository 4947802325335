/** @jsxImportSource @emotion/react */
import {useQueryClient} from '@tanstack/react-query';
import {associateProductToSuggestionCategory} from '../../../../api/fetchSuggestion';
import {ProductsAndMenuCatalog} from '../../../../components/organisms/ProductsAndMenuCatalog';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {AbsoluteLoader} from '../../../../components/layouts/AbsoluteLoader';
import {useState} from 'react';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {useBrandAccess} from '../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../constants/frontPolicies';

export const LeftBlock = () => {
  //
  const [loading, setLoading] = useState(false);
  const {brandHasAuthorization} = useBrandAccess();
  const isParentAuth = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const categorySelected = useAppSelector(
    s => s.myOffersSection.categories.categorySelected,
  );
  const categoryProducts = useAppSelector(
    s => s.myOffersSection.categories.categoryProducts,
  );

  const clientQuery = useQueryClient();

  const handleClickAddProduct = async (product: any) => {
    if (!isParentAuth) return;

    if (!categorySelected)
      return helperDisplayToast('Aucune catégorie séléctionée', false);

    setLoading(true);
    const isProductExist = categoryProducts.find(
      (item: any) => item.uuid === product.uuid,
    );
    if (!isProductExist) {
      const res = await associateProductToSuggestionCategory(
        categorySelected.uuid,
        {
          itemUuid: product.uuid,
          position: 1,
        },
      );

      if ([200, 204].includes(res.status)) {
        helperDisplayToast('Item ajouté', true);
        await clientQuery.invalidateQueries([
          `fetchSuggestionCategoriesDetail-${categorySelected?.uuid}`,
          categorySelected?.uuid,
        ]);
      }
    }
    setLoading(false);
  };

  const handleIsDisabled = (product: any) => {
    return (
      (categoryProducts?.find((item: any) => item?.uuid === product.uuid) &&
        !!categorySelected) ||
      false
    );
  };

  return (
    <>
      <ProductsAndMenuCatalog
        hiddenProducts={false}
        onClickProduct={handleClickAddProduct}
        productsChecked={categoryProducts}
        handleIsDisabled={handleIsDisabled}
      />
      {loading && <AbsoluteLoader />}
    </>
  );
};

/** @jsxImportSource @emotion/react */

import {css, Interpolation} from '@emotion/react';
import {CSSProperties} from 'react';
import {THEME} from '../../styles/theme';

export const ColorPickerLabel = ({
  required = false,
  placeholder = '',
  label = '',
  width = '100%',
  inputStyle,
  name,
  onChange,
  value,
}: {
  required?: boolean;
  placeholder?: string;
  label?: string;
  width?: string | number;
  inputStyle?: Interpolation<CSSProperties>;
  name?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  //
  return (
    <div css={styles.container(width)}>
      {label && (
        <p className="textLabel">
          {label}
          {required && <span css={styles.required}>*</span>}
        </p>
      )}
      <div css={styles.input}>
        <input
          placeholder={placeholder}
          type="color"
          css={[styles.color, inputStyle]}
          name={name}
          value={value}
          onChange={onChange}
          className="textSemiBold"
        />
        {value}
      </div>
    </div>
  );
};

const styles = {
  container: (width: string | number) =>
    css({
      width,
      marginBottom: 10,
    }),

  required: css({
    color: THEME.colors.red,
    marginLeft: 5,
  }),
  color: css({
    borderWidth: 0,
    backgroundColor: THEME.colors.white,
    marginRight: 10,
  }),
  input: {
    width: '100%',
    border: 'none',
    height: 39,
    padding: '0 10px',
    alignItems: 'center',
    display: 'flex',
    borderRadius: 2,
    backgroundColor: THEME.colors.white,
  },
};

export const EyesIcon = () => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1617_8374)">
      <g clipPath="url(#clip1_1617_8374)">
        <path
          d="M5.21648 6.97118C5.21648 5.18404 6.61039 3.73563 8.33028 3.73563C10.0502 3.73563 11.4441 5.18404 11.4441 6.97118C11.4441 8.75832 10.0502 10.2067 8.33028 10.2067C6.61039 10.2067 5.21648 8.75832 5.21648 6.97118ZM8.33028 8.9934C9.40552 8.9934 10.2764 8.08846 10.2764 6.97118C10.2764 5.8539 9.40552 4.94896 8.33028 4.94896C8.31325 4.94896 8.29866 4.94896 8.25974 4.94896C8.31325 5.07788 8.33028 5.21438 8.33028 5.3534C8.33028 6.24571 7.63211 6.97118 6.77338 6.97118C6.63958 6.97118 6.50822 6.95349 6.38415 6.89788C6.38415 6.93832 6.38415 6.95349 6.38415 6.94843C6.38415 8.08846 7.25505 8.9934 8.33028 8.9934ZM3.64547 3.34635C4.79076 2.24019 6.36469 1.30896 8.33028 1.30896C10.2959 1.30896 11.8698 2.24019 13.0156 3.34635C14.1541 4.4434 14.9155 5.7351 15.2755 6.66027C15.3558 6.85996 15.3558 7.0824 15.2755 7.2821C14.9155 8.18451 14.1541 9.47621 13.0156 10.596C11.8698 11.7032 10.2959 12.6334 8.33028 12.6334C6.36469 12.6334 4.79076 11.7032 3.64547 10.596C2.50698 9.47621 1.74605 8.18451 1.38409 7.2821C1.30426 7.0824 1.30426 6.85996 1.38409 6.66027C1.74605 5.7351 2.50698 4.4434 3.64547 3.34635ZM8.33028 2.52229C6.74419 2.52229 5.44028 3.27052 4.44046 4.2336C3.50389 5.13854 2.85728 6.1901 2.52693 6.97118C2.85728 7.72952 3.50389 8.80382 4.44046 9.70877C5.44028 10.6718 6.74419 11.4201 8.33028 11.4201C9.91638 11.4201 11.2203 10.6718 12.2201 9.70877C13.1567 8.80382 13.7819 7.72952 14.1346 6.97118C13.7819 6.1901 13.1567 5.13854 12.2201 4.2336C11.2203 3.27052 9.91638 2.52229 8.33028 2.52229Z"
          fill={'#CCD6E0'}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1617_8374">
        <rect
          width="14.0121"
          height="13.8667"
          fill="white"
          transform="translate(1.32422 0.5)"
        />
      </clipPath>
      <clipPath id="clip1_1617_8374">
        <rect
          width="14.0121"
          height="12.9422"
          fill="white"
          transform="translate(1.32422 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

import {CalendarIcon} from '../assets/svgs/CalendarIcon';
import {helperResetDate} from './helperDate';

export const getFilterIcon = (icon: string | null) => {
  switch (icon) {
    case 'calendar':
      return <CalendarIcon />;
    default:
      return;
  }
};

export const getFilterValueType = (type: string) => {
  switch (type) {
    case 'list':
      return <CalendarIcon />;
    default:
      return;
  }
};

export const helperJoinFilterValues = (values: any, addBracket?: boolean) => {
  const value = values.map((item: any) => item.value).join(',');
  return addBracket && value.length ? `[${value}]` : value;
};

export const helperDateLabel = (typeFilterDate: string | null) => {
  switch (typeFilterDate) {
    case 'equal':
      return 'Le :';
    case 'between':
      return 'Du :';
    case 'before':
      return 'Avant le :';
    case 'after':
      return 'Après le :';
    default:
      return 'Du :';
  }
};

const datesAreEqual = (date1: any, date2: any) => {
  const diff = Math.abs(
    Math.floor(date1?.getTime() / 1000) - Math.floor(date2?.getTime() / 1000),
  );
  return diff === 0;
};

export const helperShowResetDate = (from?: Date, to?: Date) => {
  if (!from && !to) return false;

  return (
    !datesAreEqual(from, helperResetDate(new Date()).toDate()) ||
    !datesAreEqual(
      to,
      helperResetDate(new Date(), {
        hour: 23,
        minute: 59,
        second: 59,
      }).toDate(),
    )
  );
};

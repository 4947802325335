export const BOfficeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="36"
      viewBox="0 0 120 36"
      fill="none">
      <path
        d="M12.927 9.91545C11.3531 9.91545 9.8485 10.1966 8.45476 10.7054L11.6045 16.605C12.0302 16.512 12.4737 16.4605 12.927 16.4605C16.3817 16.4605 19.183 19.2163 19.183 22.6156C19.183 26.0148 16.3817 28.7706 12.927 28.7706C9.47235 28.7706 6.8373 26.1652 6.68486 22.8987L6.671 22.9145V11.5091C6.671 11.5091 6.66111 11.5151 6.65517 11.5171L6.671 0.871948H0.019043V35.3137H6.62151V33.6962C8.48841 34.7257 10.6384 35.3137 12.927 35.3137C20.0561 35.3137 25.835 29.6278 25.835 22.6156C25.835 15.6033 20.0561 9.91545 12.927 9.91545Z"
        fill="#3C6AF6"
      />
      <path
        d="M38.4937 17.1106C39.7647 17.1106 40.9506 17.3521 42.0573 17.8332C43.162 18.3163 44.1261 18.9676 44.9497 19.7912C45.7733 20.6148 46.4266 21.5789 46.9077 22.6836C47.3887 23.7903 47.6303 24.9662 47.6303 26.2115C47.6303 27.4568 47.3887 28.6347 46.9077 29.7394C46.4246 30.8441 45.7733 31.8083 44.9497 32.6318C44.1261 33.4554 43.162 34.1087 42.0573 34.5898C40.9506 35.0729 39.7647 35.3124 38.4937 35.3124C37.2227 35.3124 36.0369 35.0709 34.9302 34.5898C33.8255 34.1087 32.8613 33.4554 32.0378 32.6318C31.2142 31.8083 30.5609 30.8441 30.0798 29.7394C29.5967 28.6347 29.3572 27.4588 29.3572 26.2115C29.3572 24.9643 29.5987 23.7903 30.0798 22.6836C30.5609 21.5789 31.2142 20.6148 32.0378 19.7912C32.8613 18.9676 33.8255 18.3163 34.9302 17.8332C36.0349 17.3501 37.2227 17.1106 38.4937 17.1106ZM38.4937 31.1154C39.0817 31.1154 39.6281 30.9867 40.1349 30.7273C40.6398 30.468 41.0872 30.1096 41.4752 29.6523C41.8633 29.195 42.1681 28.6644 42.3919 28.0646C42.6156 27.4647 42.7264 26.8134 42.7264 26.1066C42.7264 25.4493 42.6156 24.8197 42.3919 24.2199C42.1681 23.62 41.8633 23.1033 41.4752 22.6678C41.0872 22.2322 40.6398 21.8858 40.1349 21.6264C39.6301 21.3671 39.0817 21.2384 38.4937 21.2384C37.9057 21.2384 37.3534 21.369 36.8347 21.6264C36.318 21.8858 35.8646 22.2322 35.4766 22.6678C35.0886 23.1033 34.7817 23.62 34.56 24.2199C34.3362 24.8197 34.2254 25.4493 34.2254 26.1066C34.2254 26.8134 34.3362 27.4647 34.56 28.0646C34.7837 28.6644 35.0886 29.195 35.4766 29.6523C35.8646 30.1096 36.316 30.47 36.8347 30.7273C37.3514 30.9867 37.9057 31.1154 38.4937 31.1154Z"
        fill="#151A30"
      />
      <path
        d="M62.0283 10.3339V14.4616H58.1837C57.5719 14.4616 57.1007 14.6972 56.7721 15.1684C56.4435 15.6396 56.2791 16.285 56.2791 17.1086V35.0293H51.5871V24.3406H49.0471V20.4246H51.5871V16.8967C51.5871 16.0039 51.7574 15.1565 52.0999 14.3567C52.4404 13.5569 52.9116 12.858 53.5115 12.2582C54.1113 11.6583 54.8102 11.1871 55.61 10.8466C56.4098 10.5061 57.269 10.3339 58.1856 10.3339H62.0303H62.0283ZM56.8434 20.4246H62.0283V24.3406H58.9954L56.8434 20.4246Z"
        fill="#151A30"
      />
      <path
        d="M76.4126 10.3339V14.4616H72.5679C71.9562 14.4616 71.485 14.6972 71.1564 15.1684C70.8277 15.6396 70.6634 16.285 70.6634 17.1086V35.0293H65.9714V24.3406H63.4314V20.4246H65.9714V16.8967C65.9714 16.0039 66.1417 15.1565 66.4842 14.3567C66.8247 13.5569 67.2959 12.858 67.8957 12.2582C68.4956 11.6583 69.1944 11.1871 69.9943 10.8466C70.7941 10.5061 71.6533 10.3339 72.5699 10.3339H76.4146H76.4126ZM71.2277 20.4246H76.4126V24.3406H73.3796L71.2277 20.4246Z"
        fill="#151A30"
      />
      <path
        d="M78.1775 10.3339H82.8695V14.4616H78.1775V10.3339ZM78.1775 35.0293V17.3897H82.8695V35.0293H78.1775Z"
        fill="#151A30"
      />
      <path
        d="M97.8613 28.2905L101.67 30.7949L101.635 30.8305C100.811 32.2421 99.7005 33.3488 98.3008 34.1466C96.9011 34.9464 95.3906 35.3464 93.7672 35.3464C92.4962 35.3464 91.3083 35.1048 90.2036 34.6237C89.0969 34.1407 88.1348 33.4894 87.3112 32.6658C86.4876 31.8422 85.8343 30.88 85.3532 29.7734C84.8702 28.6687 84.6306 27.4927 84.6306 26.2454C84.6306 24.9982 84.8702 23.8222 85.3532 22.7175C85.8363 21.6108 86.4876 20.6487 87.3112 19.8251C88.1348 19.0015 89.0969 18.3502 90.2036 17.8671C91.3083 17.3841 92.4962 17.1445 93.7672 17.1445C95.3886 17.1445 96.9011 17.5444 98.3008 18.3443C99.6985 19.1441 100.811 20.2488 101.635 21.6603L101.67 21.696L97.8257 24.2023L97.79 24.1667C97.4139 23.2976 96.8675 22.5908 96.1508 22.0504C95.4321 21.5099 94.6383 21.2387 93.7691 21.2387C93.1336 21.2387 92.5397 21.3753 91.9874 21.6445C91.435 21.9157 90.9579 22.28 90.56 22.7373C90.1601 23.1966 89.8413 23.7252 89.6077 24.3251C89.3721 24.925 89.2553 25.5664 89.2553 26.2474C89.2553 26.9285 89.3721 27.564 89.6077 28.1519C89.8413 28.7399 90.1601 29.2527 90.56 29.6863C90.9599 30.1218 91.437 30.4623 91.9874 30.7098C92.5397 30.9573 93.1336 31.08 93.7691 31.08C94.6383 31.08 95.4321 30.8385 96.1508 30.3574C96.8675 29.8763 97.4139 29.1992 97.79 28.3281L97.8257 28.2589L97.8613 28.2945V28.2905Z"
        fill="#151A30"
      />
      <path
        d="M110.88 17.1118C112.127 17.1118 113.303 17.3533 114.408 17.8344C115.513 18.3155 116.477 18.9688 117.3 19.7924C118.124 20.6159 118.775 21.5801 119.258 22.6848C119.739 23.7915 119.981 24.9773 119.981 26.2483C119.981 26.9313 119.91 27.5886 119.769 28.2241L119.733 28.2954H110.773L108.514 24.6962H115.218C114.889 23.7083 114.341 22.8966 113.576 22.2631C112.812 21.6276 111.911 21.3108 110.878 21.3108C110.243 21.3108 109.643 21.4395 109.078 21.6969C108.514 21.9542 108.025 22.3007 107.613 22.7343C107.202 23.1678 106.877 23.6845 106.643 24.2804C106.408 24.8783 106.291 25.5277 106.291 26.2305C106.291 26.769 106.398 27.3253 106.61 27.8994C106.822 28.4736 107.126 28.9942 107.526 29.4634C107.926 29.9326 108.407 30.3246 108.972 30.6394C109.536 30.9562 110.171 31.1145 110.876 31.1145C111.512 31.1145 112.094 31.0255 112.622 30.8493C113.151 30.6731 113.65 30.2791 114.121 29.6674L114.157 29.6317L114.226 29.6674L118.318 31.4689L118.247 31.5402C117.399 32.7261 116.324 33.6506 115.02 34.3138C113.715 34.977 112.333 35.3096 110.874 35.3096C109.603 35.3096 108.411 35.0741 107.293 34.6029C106.176 34.1337 105.206 33.4863 104.382 32.6627C103.559 31.8391 102.906 30.877 102.425 29.7703C101.941 28.6656 101.702 27.4896 101.702 26.2424C101.702 24.9951 101.941 23.7855 102.425 22.6788C102.908 21.5741 103.559 20.61 104.382 19.7864C105.206 18.9628 106.174 18.3115 107.293 17.8284C108.409 17.3454 109.603 17.1058 110.874 17.1058L110.88 17.1118Z"
        fill="#151A30"
      />
    </svg>
  );
};

import {BRAND_AUTHORIZATION} from '../../../constants/frontPolicies';
import {brandHasAuthorizationForRouting} from '../../../helpers/helperFrontPolicies';
import {History} from './History';
import {InComing} from './InComing';
import {InProgress} from './InProgress';
import {NotCompleted} from './NotCompleted';

export const MyOrdersRouter = {
  path: 'orders',
  loader: () => brandHasAuthorizationForRouting(BRAND_AUTHORIZATION.CHILD),
  children: [
    {
      path: 'in-progress',
      element: <InProgress />,
    },
    {
      path: 'in-coming',
      element: <InComing />,
    },
    {
      path: 'not-completed',
      element: <NotCompleted />,
    },
    {
      path: 'history',
      element: <History />,
    },
  ],
};

import {createSlice} from '@reduxjs/toolkit';

export type Printer = {
  uuid: string;
  label: string;
  ip_address: string;
  brandHasPrinterUuid?: string;
};

export type ConfigPrinter = {
  id: number;
  brand_has_printer_id: number;
  printer_template_id: number;
  label?: string;
  uuid: string;
  printer: Printer;
  type: string;
  selected_consumption_modes: number[];
  selected_channels: number[];
  highlighted_prod_area_uuids: string[];
};

interface IState {
  printerSelected: Printer | null;
  configPrinterSelected: ConfigPrinter | null;
}

const initialState: IState = {
  printerSelected: null,
  configPrinterSelected: null,
};

const slice = createSlice({
  name: 'printers',
  initialState,
  reducers: {
    printerSelectedAction: (state, action) => {
      state.printerSelected = action.payload;
    },
    configPrinterSelectedAction: (state, action) => {
      state.configPrinterSelected = action.payload;
    },
  },
});

export const {printerSelectedAction, configPrinterSelectedAction} =
  slice.actions;

export default slice.reducer;

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {FormInputs} from '../FormInputs';
import {UploadBackgroundPicture} from '../UploadBackgroundPicture';
import {IModuleParameter} from '../../../../api/fetchModuleDetails';
import {Sliders} from './Sliders';
import {ToggleButtonLabel} from '../../../../components/molecules/ToggleButtonLabel';
import {THEME} from '../../../../styles/theme';

export const BWebAppForm = ({
  form,
  onChange,
}: {
  form: IModuleParameter;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}) => {
  //

  return (
    <div className="flex" css={styles.form}>
      {/* FORM INPUTS */}
      <div
        style={{
          width: '30%',
          gap: 10,
          display: 'flex',
          flexDirection: 'column',
        }}>
        <FormInputs width={'100%'} values={form} onChange={onChange} />
        <div className="flex" css={styles.themes}>
          <button
            onClick={() =>
              onChange({
                target: {
                  name: 'navigation_type',
                  value: 'classic',
                },
              } as any)
            }
            className={`title03${
              form.navigation_type === 'classic' ? ' active' : ''
            }`}>
            {'Navigation classique'}
          </button>
          <button
            onClick={() =>
              onChange({
                target: {
                  name: 'navigation_type',
                  value: 'intermediate',
                },
              } as any)
            }
            className={`title03${
              form.navigation_type !== 'classic' ? ' active' : ''
            }`}>
            {'Navigation intermédiaires'}
          </button>
        </div>
        <ToggleButtonLabel
          label="Informations textuelles sur la page d'accueil"
          marginBottom={'1rem'}
          active={form.has_home_infos}
          onToggle={() =>
            onChange({
              target: {
                name: 'has_home_infos',
                value: !form.has_home_infos,
              },
            } as any)
          }
        />
        <ToggleButtonLabel
          label="Slider d'images en haut de page"
          marginBottom={'1rem'}
          active={form.has_top_slider}
          onToggle={() =>
            onChange({
              target: {
                name: 'has_top_slider',
                value: !form.has_top_slider,
              },
            } as any)
          }
        />
        <ToggleButtonLabel
          label="Afficher la sélection d'allergènes"
          marginBottom={'1rem'}
          active={form.has_allergens_selection}
          onToggle={() =>
            onChange({
              target: {
                name: 'has_allergens_selection',
                value: !form.has_allergens_selection,
              },
            } as any)
          }
        />
        <ToggleButtonLabel
          label="Commentaire sur la commande"
          marginBottom={'1rem'}
          active={form.has_order_comment}
          onToggle={() =>
            onChange({
              target: {
                name: 'has_order_comment',
                value: !form.has_order_comment,
              },
            } as any)
          }
        />
      </div>

      {/* BACKGROUND PICTURE 100X100 */}
      <UploadBackgroundPicture
        title="BACKGROUND_IMAGE"
        name="background"
        images={form}
        onChange={onChange}
      />

      {/* SLIDER IMAGES 100X100 */}
      <Sliders id={String(form.id)} />
    </div>
  );
};

const styles = {
  form: css({
    gap: 20,
    width: '100%',
  }),
  themes: css(`
  justify-content: flex-end;
  align-items: center;
  background-color: #FFFFFF;
  padding:0.2rem;
  align-self: flex-end;
  border-radius: 4px;
  margin-top: 5px;
 

  button {
    border: none;
    padding: 5px 10px;
    border-radius: none;
    background-color: transparent;
    color:#A7A7AB;
    text-transform: uppercase;
    cursor: pointer;


    &.active{
      background-color:${THEME.colors.primary};
      color: white;
      border-radius: 4px;
    }
  }
  
`),
};

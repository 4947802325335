import {useState} from 'react';
import {useDebounce} from './useDebounce';

export const useSearch = () => {
  const [search, setSearch] = useState('');
  const debounceValue = useDebounce(search, 500);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return {
    search,
    debounceValue,
    handleChangeSearch,
  };
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {useState} from 'react';
import {columnsMovements} from './columns';
import {useSqsTransactions} from '../../../../../hooks/useSqsTransactions';

export const ArchiveMovements = () => {
  const [page, setPage] = useState(0);
  const [limit] = useState(0);
  const [rowCount] = useState(0);

  const {data, isLoading} = useSqsTransactions();

  const transactions = data || [];

  return (
    <div css={styles.container}>
      <NewDataGrid
        columns={columnsMovements}
        rows={transactions}
        loading={isLoading}
        hasPagination
        page={page}
        setPage={setPage}
        totalRows={rowCount}
        rowPerPage={limit}
      />
    </div>
  );
};

const styles = {
  container: css({
    height: '100%',
  }),
};

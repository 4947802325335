/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addGroupModalAction} from '../../redux/modals';
import {THEME} from '../../styles/theme';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {useQueryClient} from '@tanstack/react-query';
import {createStaffGroup, updateStaffGroup} from '../../api/fetchStaff';
import {staffGroupSelectedAction} from '../../redux/myStaffSection/groups';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useState} from 'react';

export const CreateGroupModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const staffGroupSelected = useAppSelector(
    s => s.myStaffSection.groups.groupSelected,
  );

  const inititalValues = {
    label: staffGroupSelected?.label || '',
  };

  const clientQuery = useQueryClient();

  const handleSubmit = async (values: any) => {
    setLoading(true);
    let res;

    if (staffGroupSelected) {
      res = await updateStaffGroup(staffGroupSelected.uuid, values);
    } else {
      res = await createStaffGroup(values);
    }

    if (res?.status === 204) {
      clientQuery.invalidateQueries(['fetchStaffGroups']);
      onCloseModal();
    } else if (res?.data?.additionalInfo) {
      helperDisplayToast(res?.data?.additionalInfo, false);
    }

    setLoading(false);
  };

  const onCloseModal = () => {
    dispatch(addGroupModalAction(false));
    dispatch(staffGroupSelectedAction(null));
  };

  return (
    <ModalWrapper
      title={t('USER_GROUP')}
      initialValues={inititalValues}
      onSubmit={handleSubmit}
      showBtns={false}
      onCancel={onCloseModal}
      maxWidth={'90%'}
      minWidth={'30%'}
      minHeight={'20%'}
      maxHeight={'100%'}>
      <FieldCustomInput
        label={t('GROUP_NAME')}
        placeholder={t('GROUP_NAME')}
        name="label"
        required
      />
      <FieldsRequired />

      <div css={styles.btns}>
        <ButtonCustom
          label={t('CANCEL')}
          backgroundColor={THEME.colors.red}
          marginRight={'2%'}
          onClick={onCloseModal}
          width={'50%'}
        />
        <ButtonCustom
          backgroundColor={THEME.colors.green}
          label={t(staffGroupSelected ? 'SAVE' : 'VALIDATE')}
          type="submit"
          isLoading={loading}
          width={'50%'}
        />
      </div>
    </ModalWrapper>
  );
};

const styles = {
  btnAdd: (isLastIndex: boolean) =>
    css({
      padding: 5,
      border: 'none',
      backgroundColor: THEME.colors[isLastIndex ? 'primary' : 'red'],
    }),
  btns: css({
    marginTop: 20,
    textAlign: 'center',
    display: 'flex',
  }),
  policies: css({
    marginTop: '5%',
    overflow: 'auto',
    height: '50vh',
  }),
};

import {axiosInstance} from './axiosInstance';

export interface IModuleParameter {
  id?: number | string;
  brand_id?: number | string;
  home_title: string;
  home_desc: string;
  slider_title: string;
  slider_desc: string;
  background: string | File | null;
  language_id: number;
  font_id: number;
  color_light: string;
  color_dark: string;
  content_background: string | null;
  out_of_stock_label: string;
  suggested_course_label: string;
  additional_sale_label: string;
  theme: number;
  navigation_type?: string;
  has_home_infos?: boolean;
  has_top_slider?: boolean;
  has_allergens_selection?: boolean;
  has_order_comment?: boolean;
}

export const fetchModuleParameter = async ({
  brandId,
  moduleCode,
}: {
  brandId: string | number;
  moduleCode: string;
}) => {
  // bcore/protected/admin/{{brand_id}}/b_order/parameter/all?language_id=1

  const res = await axiosInstance.get<{
    data: IModuleParameter;
  }>(
    `bcore/protected/admin/${brandId}/${moduleCode}/parameter/all?language_id=1`,
  );

  return res.data.data;
};

interface ISlider {
  image: string;
  id: string;
}

export const fetchModuleSliders = async ({
  brandId,
  moduleCode,
  parameterId,
}: {
  brandId: string | number;
  moduleCode: string;
  parameterId: string | number;
}) => {
  // /bcore/protected/admin/28/slider/b_webapp/46

  const res = await axiosInstance.get<{
    data: ISlider[];
  }>(`bcore/protected/admin/${brandId}/slider/${moduleCode}/${parameterId}`);

  return res?.data?.data;
};

export const fetchModuleScreenSavers = async (
  brandId: string,
  moduleCode: string,
) => {
  // https://api.bill-app.fr/bcore/protected/admin/{{brand_id}}/b_order/screensaver/all
  const res = await axiosInstance.get<{data: ISlider[]}>(`
bcore/protected/admin/${brandId}/${moduleCode}/screensaver/all
`);

  return res?.data?.data;
};

export const addModuleSlider = async (brandId: string, data: FormData) => {
  // /bcore/protected/admin/28/slider/b_webapp/46

  const res = await axiosInstance.post<{
    status: number;
    message: string;
  }>(`bcore/protected/admin/${brandId}/slider`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res?.data;
};

export const addModuleScreenSaver = async (brandId: string, data: FormData) => {
  // /bcore/protected/admin/28/slider/b_webapp/46
  // /bcore/protected/admin/{{brand_id}}/b_order/screensaver
  const res = await axiosInstance.post<{
    status: number;
    message: string;
  }>(`bcore/protected/admin/${brandId}/b_order/screensaver`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res?.data;
};

export const updateModuleParameter = async ({
  brandId,
  moduleCode,
  data,
  parameterId,
}: {
  brandId: string;
  moduleCode: string;
  parameterId: string | number;
  data: FormData;
}) => {
  ///bcore/protected/admin/{{brand_id}}/b_order/parameter/{{id}}
  const res = await axiosInstance.put<{
    status: number;
    message: string;
  }>(
    `bcore/protected/admin/${brandId}/${moduleCode}/parameter/${parameterId}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return res?.data;
};

export const deleteModuleSlider = async (brandId: string, sliderId: string) => {
  // /bcore/protected/admin/{{brand_id}}/slider/{{slider_id}}/delete

  const res = await axiosInstance.delete<{
    status: number;
    message: string;
  }>(`bcore/protected/admin/${brandId}/slider/${sliderId}/delete`);

  return res?.data;
};

export const deleteModuleScreenSaver = async (
  brandId: string,
  screenSaverId: string,
) => {
  // /bcore/protected/admin/{{brand_id}}/b_order/screensaver/{{slider_id}}/delete

  const res = await axiosInstance.delete<{
    status: number;
    message: string;
  }>(
    `bcore/protected/admin/${brandId}/b_order/screensaver/${screenSaverId}/delete`,
  );

  return res?.data;
};

/**  @jsxImportSource @emotion/react */
import {IStatHourlyByDOW} from '../../../../../../../api/fetchStats';
import {HeatMaps} from './HeatMaps';
import {Metrics} from './Metrics';
import {CumulAndAverageMode} from './CumulAverage';

export const Header = ({
  tabs,
  setTabs,
  data,
}: {
  data?: IStatHourlyByDOW;
  tabs: 'graph' | 'columns';
  setTabs: (value: 'graph' | 'columns') => void;
}) => {
  return (
    <>
      <HeatMaps tabs={tabs} setTabs={setTabs} heatmaps={data?.heatmaps} />

      <Metrics weekdays={data?.weekdays} />

      <CumulAndAverageMode />
    </>
  );
};

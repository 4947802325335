/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ToggleButtonSections} from '../../../../../components/molecules/ToggleButtonSections';

export const Center = () => {
  return (
    <div css={styles.container}>
      <ToggleButtonSections
        sections={['SUMMARY', 'HISTORIQUES']}
        defaultSection={'SUMMARY'}
      />
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
    padding: '2rem 0',
    display: 'flex',
    justifyContent: 'flex-start',
  }),
};

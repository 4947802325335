import {useLocation} from 'react-router-dom';
import {FilterButton} from '../../../../components/atoms/FilterButton';
import {AmountMaxFilter} from '../../../../components/filters/AmountMaxFilter';
import {SearchFilter} from '../../../../components/filters/SearchFilter';
import {useAppSelector} from '../../../../hooks/useReduxHook';

export const Filters = () => {
  const dates = useAppSelector(s => s.filter.dates);
  const {pathname} = useLocation();

  const isVentitation = pathname.split('/').at(-1) === 'archive';

  return (
    <>
      {!isVentitation && <SearchFilter />}
      <FilterButton
        marginRight={'1vh'}
        marginLeft={isVentitation ? 0 : '1vh'}
        valueType="dates"
        values={dates}
      />
      {!isVentitation && <AmountMaxFilter />}
    </>
  );
};

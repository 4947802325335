/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../../components/atoms/TextVariant';
import {helperDate} from '../../../../../helpers/helperDate';
import {THEME} from '../../../../../styles/theme';
import {DataGridActions} from '../../../../../components/newDatagrid/DataGridActions';
import {
  deletePeriodicalPrice,
  fetchPeriodicalPrice,
} from '../../../../../api/fetchPeriodicalPrices';
import {eventSelectedAction} from '../../../../../redux/myOffersSection/priceRules';
import {eventModalAction} from '../../../../../redux/modals';
import {helperConfirmDialog} from '../../../../../helpers/helperConfirmDialog';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';

export const columnsEvents = [
  {
    field: 'label',
    headerName: "Nom de l'événement",
    headerClassName: 'textRegular',
    renderCell: (row: any) => (
      <div css={styles.eventName}>
        <TextVariant
          text={row.label}
          variant="textMedium"
          style={styles.name}
        />

        {row.start && row.end && (
          <TextVariant
            text={`du ${helperDate(row?.start, 'DD/MM/YYYY')} au ${helperDate(
              row?.end,
              'DD/MM/YYYY',
            )}`}
            variant="textMedium"
          />
        )}
      </div>
    ),
    flex: 1,
  },
  {
    field: '',
    headerName: 'Nom des règles de prix',
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter: (row: any) => {
      return row?.priceRuleLabel || row?.priceRule?.label;
    },
  },

  // {
  //   field: 'isActive',
  //   headerName: 'Visibilité',
  //   headerClassName: 'textRegular',
  //   flex: 1,
  //   renderCell: (row: any) => (
  //     <ToggleButton
  //       active={!row?.isArchived}
  //       authorize={BRAND_AUTHORIZATION.PARENT}
  //     />
  //   ),
  // },
  {
    field: 'createdAt',
    headerName: "Date d'ajout",
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter: (row: any) => {
      return `Le ${helperDate(row?.createdAt, 'DD/MM/YYYY')}`;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Date de modification',
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter: (row: any) => {
      return `Le ${helperDate(row?.updatedAt, 'DD/MM/YYYY')}`;
    },
  },
  {
    field: 'actions',
    headerName: '',
    flex: 0,
    renderCell: (row: any) => {
      const handleClickEdit = async ({dispatch}: any) => {
        const res = await fetchPeriodicalPrice(row.uuid);
        if (res) {
          dispatch(eventSelectedAction(res));
          dispatch(eventModalAction(true));
        }
      };

      const handleClickDelete = async ({refreshing}: any) => {
        helperConfirmDialog({
          title: 'Suppression de l’événement',
          text: row?.label,
          onSubmit: async () => {
            const res = await deletePeriodicalPrice(row.uuid);
            if (res?.status === 204) {
              refreshing('fetchPeriodicalPrices');
            }
          },
        });
      };
      return (
        <DataGridActions
          handleClickEdit={handleClickEdit}
          handleClickDelete={handleClickDelete}
          authorization={{
            edit: BRAND_AUTHORIZATION.PARENT,
            delete: BRAND_AUTHORIZATION.PARENT,
          }}
        />
      );
    },
  },
];

const styles = {
  eventName: css({
    textAlign: 'center',
  }),
  name: css({
    color: THEME.colors.primary,
  }),
};

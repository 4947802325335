/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../atoms/TextVariant';
import {OrderItem} from './OrderItem';
import {TicketContentHeader} from './TicketContentHeader';
import {TicketContentFooter} from './TicketContentFooter';

export const TicketContent = ({
  data,
  isReceipt = false,
}: {
  data: any;
  isReceipt?: boolean;
}) => {
  const items = data?.billItems || data?.orderItems || data?.order_items || [];

  return (
    <div css={styles.scrollView({isReceipt})}>
      <div>
        <TextVariant
          variant="textBold"
          text="DÉTAILS"
          style={styles.subTitle}
        />
        <TicketContentHeader data={data} isReceipt={isReceipt} />
        {items?.map((item: any, index: number) => (
          <OrderItem key={index} item={item} isReceipt={isReceipt} />
        ))}
      </div>
      <TicketContentFooter data={data} isReceipt={isReceipt} />
    </div>
  );
};

const styles = {
  title: css({
    fontSize: 12,
    fontWeight: '600',
  }),

  subTitle: css({
    paddingLeft: 10,
    marginBottom: 5,
  }),

  footerInfos: css({
    padding: 20,
    textAlign: 'center',
  }),

  transaction: css({
    borderBottom: '1px solid var(--bg-main-color)',
    padding: '10px 10px 10px 20px',
    display: 'flex',
    justifyContent: 'space-between',

    '&:first-of-type': {
      borderTop: '1px solid var(--bg-main-color)',
    },
  }),
  sectionFlex: css({
    padding: '5px 10px 5px 20px',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  headCol: css({
    padding: '5px 15px',
    width: '15%',
  }),
  headerTitle: css({
    fontSize: 10,
    fontWeight: '700',
    color: '#A7A7AB',
  }),
  headerList: css({
    padding: '0px 5px',
    display: 'flex',
    borderBottom: '1px solid var(--bg-main-color)',
    justifyContent: 'space-between',
  }),
  scrollView: ({isReceipt = false}: {isReceipt?: boolean}) =>
    css({
      overflowY: 'scroll',
      overflowX: 'hidden',
      height: isReceipt ? '58%' : '65%',
      '@media print': {
        height: 'auto',
      },
    }),
};

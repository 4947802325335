/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {PillLabel} from '../../../../../../components/molecules/PillLabel';
import {ValueSeparatorPercent} from '../../../../../../components/atoms/ValueSeparatorPercent';
import {THEME} from '../../../../../../styles/theme';

export const OptionsColumns = [
  {
    field: 'name',
    headerName: 'PRODUIT',
    textAlign: 'left',
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      console.log('ieuhdeiurfjheiufj ', row);
      return (
        <div className="flex" css={styles.name(row)}>
          <PillLabel
            text={row.label}
            variant="textSemiBold"
            backgroundColor={THEME.colors.pink}
            textTransform="capitalize"
          />
        </div>
      );
    },
  },
  {
    field: '',
    headerName: 'NOMBRE DE COMMANDES',
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      if (!row.isItem) return null;
      return (
        <ValueSeparatorPercent
          value={row.orders.value}
          percent={row.orders?.percent || 0}
          color={THEME.colors.yellowDark}
          formatValue={false}
        />
      );
    },
  },
  {
    field: '',
    headerName: 'CA TTC',
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      if (!row.isItem) return null;
      return (
        <ValueSeparatorPercent
          value={row.sales.TTC.value}
          // percent={row.sales?.TTC}
          color={THEME.colors.primary}
          suffix="€"
        />
      );
    },
  },
];

const styles = {
  name: (row: any) =>
    css({
      justifyContent: 'flex-start',
      width: '100%',
      paddingLeft: row.isItem ? '1.5rem' : 0,
    }),
};

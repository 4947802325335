/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {ProductType} from '../../../../../../../components/atoms/ProductType';
// import {HorizontalBar} from '../../../../../../../components/atoms/HorizontalBar';
import {THEME} from '../../../../../../../styles/theme';

const Bottom = ({item}: {item: any}) => {
  return (
    <div css={styles.container}>
      {/* <HorizontalBar color={item.color} /> */}
      <div css={styles.content}>
        <div className="flex flex-center" css={styles.productInfos}>
          <p className="textMedium" css={styles.productName}>
            {item.backofficeLabel}
          </p>
          <ProductType type={item.type} />
        </div>
        <p className="title03" css={styles.elements}></p>
      </div>
    </div>
  );
};

export default Bottom;

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    background: 'white',
    width: '100%',
    // minHeight: 44,
    height: '30%',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.25rem',
    width: 'calc(100% - 9px)',
  }),
  productInfos: css({
    width: '100%',
  }),
  productName: css({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '90%',
    color: THEME.colors.black,
  }),
  elements: css({
    color: THEME.colors.gray2,
  }),
};

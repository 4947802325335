/* @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {TextVariant} from '../../../../../components/atoms/TextVariant';
import {EyeSmallIcon} from '../../../../../assets/svgs/EyeIcon';
import {BurgerIcon} from '../../../../../assets/svgs/BurgerIcon';
import {THEME} from '../../../../../styles/theme';
import {ActionsContainer} from '../../../../../components/molecules/ActionsContainer';
import {ToggleButtonLabel} from '../../../../../components/molecules/ToggleButtonLabel';
import {helperLabelTranslation} from '../../../../../helpers/helperLabel';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {helperConfirmDialog} from '../../../../../helpers/helperConfirmDialog';
import {
  deleteSubmenu,
  duplicateSubmenu,
} from '../../../../../api/fetchSubmenus';
import {useQueryClient} from '@tanstack/react-query';
import {addSubMenuModalAction} from '../../../../../redux/modals';
import {useAppDispatch} from '../../../../../hooks/useReduxHook';
import {
  openedSubmenuAction,
  updateModeAction,
  updatedSubmenuAction,
} from '../../../../../redux/myCatalogSection/menus';
import {updateCatalogModifier} from '../../../../../api/fetchItems';
import {ActionButton} from '../../../../../components/atoms/ActionButton';
import {TrashIcon} from '../../../../../assets/svgs/TrashIcon';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';

export const SubMenuHeader = ({
  miniCard = false,
  withActions = false,
  showProducts = false,
  submenu,
  isOpened = false,
  disabled = false,
  withDeleteAction = false,
  handleDeleteAction,
}: {
  miniCard?: boolean;
  withActions?: boolean;
  showProducts?: boolean;
  submenu?: any;
  isOpened?: boolean;
  disabled?: boolean;
  withDeleteAction?: boolean;
  handleDeleteAction?: any;
}) => {
  //
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();

  const {brandHasAuthorization} = useBrandAccess();

  const handleToggle = async () => {
    const res = await updateCatalogModifier(submenu.uuid, {
      isArchived: !submenu.isArchived,
    });
    if (res.status === 204) {
      helperDisplayToast('Visibilité modifiée', true);
    } else {
      helperDisplayToast(
        res.data?.message || 'Impossible de modifier la visibilité',
        false,
      );
    }
  };

  const handleClickEdit = () => {
    dispatch(addSubMenuModalAction(true));
    dispatch(
      updatedSubmenuAction({
        ...submenu,
        label: helperLabelTranslation(submenu),
      }),
    );
    dispatch(updateModeAction(true));
  };

  const handleClickCopy = async () => {
    helperConfirmDialog({
      validateLabel: 'Dupliquer',
      onSubmit: async () => {
        const res = await duplicateSubmenu(submenu.uuid);
        if (res?.status === 204) {
          helperDisplayToast('Sous-menu dupliqué', true);
          clientQuery.invalidateQueries([`fetchSubmenus`]);
        } else {
          helperDisplayToast('Impossible de dupliquer le sous-menu', false);
        }
      },
    });
  };

  const handleClickDelete = async () => {
    helperConfirmDialog({
      onSubmit: async () => {
        const res = await deleteSubmenu(submenu.uuid);
        if (res && res.status === 204) {
          helperDisplayToast('Sous-menu supprimé', true);
          clientQuery.invalidateQueries([`fetchSubmenus`]);
        } else {
          helperDisplayToast(
            res.data?.message || 'Impossible de supprimer le sous-menu',
            false,
          );
        }
      },
    });
  };

  const handleClickSubmenu = () => {
    if (disabled) return;
    if (isOpened) {
      dispatch(openedSubmenuAction(null));
    } else {
      dispatch(openedSubmenuAction(submenu.uuid));
    }
  };

  return (
    <div css={styles.container} onClick={handleClickSubmenu}>
      {!miniCard && !withActions && <SquareButton label={submenu?.position} />}
      <TextVariant
        variant="textMedium"
        text={helperLabelTranslation(submenu)}
        style={[
          styles.itemName({
            showProducts,
          }),
        ]}
      />
      {!miniCard && !withActions && (
        <div
          className="flex-center"
          css={styles.item({miniCard, withActions, showProducts})}>
          <SquareButton
            backgroundColor={
              submenu?.isArchived ? THEME.colors.red : THEME.colors.green
            }
            Icon={EyeSmallIcon}
            size="small"
          />
          <TextVariant variant="textMedium" text={'Visibilité'} />
        </div>
      )}

      {withActions && (
        <div
          css={styles.item({
            withActions,
            showProducts,
          })}>
          <ToggleButtonLabel
            active={!submenu?.isArchived}
            label="Visibilité"
            labelPosition="left"
            onToggle={handleToggle}
          />
        </div>
      )}

      <div
        className="flex-center"
        css={styles.item({miniCard, withActions, showProducts})}>
        <div
          style={{
            alignItems: 'center',
            width: 70,
            display: 'flex',
            justifyContent: 'flex-start',
          }}>
          <SquareButton Icon={BurgerIcon} size="small" />
          <TextVariant
            variant="textMedium"
            text={
              submenu?.totalItems < 10
                ? `0${submenu?.totalItems}`
                : submenu?.totalItems || '0'
            }
            style={{
              color: THEME.colors.primary,
            }}
          />
        </div>
      </div>

      {/* <div
        className="flex-center"
        css={styles.item({miniCard, withActions, showProducts})}>
        <SquareButton Icon={PlusIcon} size="small" />
        <TextVariant variant="textMedium" text={'00'} />
      </div> */}

      {/* <div
        className="flex-center"
        css={[
          styles.item({miniCard, withActions, showProducts}),
          {
            marginRight: withActions
              ? showProducts
                ? '1.5%'
                : '5%'
              : '6.5rem',
          },
        ]}>
        <SquareButton Icon={EuroSmallIcon} size="small" />
        <TextVariant variant="textMedium" text={'00 €'} />
      </div> */}

      {withActions && (
        <div
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            display: 'flex',
          }}>
          {/* <div
            css={styles.item({
              withActions,
              showProducts,
            })}>
            <TextVariant variant="textMedium" text={'de 00.00€ à 00.00€'} />
          </div> */}

          <ActionsContainer
            showCopy
            direction="row"
            handleClickEdit={handleClickEdit}
            handleClickCopy={handleClickCopy}
            handleClickDelete={handleClickDelete}
            authorization={{
              edit: BRAND_AUTHORIZATION.PARENT,
              copy: BRAND_AUTHORIZATION.PARENT,
              delete: BRAND_AUTHORIZATION.PARENT,
            }}
          />
        </div>
      )}
      {withDeleteAction && (
        <ActionButton
          onClick={() => handleDeleteAction && handleDeleteAction(submenu)}
          Icon={TrashIcon}
          disabled={!brandHasAuthorization(BRAND_AUTHORIZATION.PARENT)}
        />
      )}
    </div>
  );
};

const SquareButton = ({
  label = '',
  onClick = () => {},
  size = 'large',
  Icon,
  backgroundColor = THEME.colors.primary,
}: {
  label?: string;
  onClick?: () => void;
  size?: 'large' | 'small';
  Icon?: any;
  backgroundColor?: string;
}) => {
  const sizeStyle =
    size === 'large' ? styles.squareButtonLarge : styles.squareButtonSmall;

  return (
    <button
      css={[styles.squareButton, sizeStyle, {backgroundColor}]}
      className="flex-center">
      {!!label && <TextVariant variant="textMedium" text={label} />}
      {!!Icon && <Icon />}
    </button>
  );
};

const styles = {
  container: css({
    flex: 1,
    height: '2.25rem',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.2rem',
    cursor: 'pointer',
  }),
  itemName: ({
    showProducts,
  }: Partial<{
    withActions: boolean;
    showProducts: boolean;
  }>) =>
    css({
      flex: 2,
      textAlign: 'left',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingLeft: showProducts ? '0.2rem' : '0.4rem',
    }),
  squareButton: css({
    marginRight: '0.5rem',
    borderRadius: '0.188rem',
    border: 'none',
    color: 'white',
  }),
  squareButtonLarge: css({
    width: '1.563rem',
    height: '1.563rem',
  }),
  squareButtonSmall: css({
    width: '1.25rem',
    height: '1.25rem',
  }),

  item: ({
    miniCard,
    withActions,
    showProducts,
  }: Partial<{
    miniCard: boolean;
    withActions: boolean;
    showProducts: boolean;
  }>) =>
    css({
      flex: 1,
      marginRight: withActions
        ? showProducts
          ? '1.5%'
          : '5%'
        : miniCard
        ? '2rem'
        : '6.5rem',
    }),
};

import {SVGProps} from 'react';

export const PaginationRight = (props: SVGProps<SVGSVGElement>) => {
  
  return (
  <svg
    width="8"
    height="12"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.73903 5.53653C5.99402 5.28154 5.99402 4.86744 5.73903 4.61245L1.82236 0.695778C1.56737 0.440786 1.15327 0.440786 0.898274 0.695778C0.643283 0.950769 0.643283 1.36488 0.898274 1.61987L4.35392 5.07551L0.900314 8.53115C0.645323 8.78614 0.645323 9.20025 0.900314 9.45524C1.15531 9.71023 1.56941 9.71023 1.8244 9.45524L5.74107 5.53857L5.73903 5.53653Z"
      fill={props.color || '#CCD6E0'}
    />
  </svg>
)}

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import ReactSlider from 'react-slider';
import {TextVariant} from './TextVariant';

export const SliderCustomLabel = ({
  value = [1, 4],
  min = 0,
  max = 5,
  label = '',
  handleChange,
}: {
  value?: any[];
  min?: number;
  max?: number;
  label?: string;
  handleChange?: any;
}) => {
  return (
    <div>
      {label && <p className="textLabel">{label}</p>}
      <div className="flex-space-between" css={styles.values}>
        <TextVariant text={min ?? 0} variant="textRegular" />
        <TextVariant text={max} variant="textRegular" />
      </div>
      <ReactSlider
        min={0}
        max={max}
        value={value}
        minDistance={0}
        onAfterChange={value => handleChange(value)}
        className="horizontal-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
        pearling
      />
    </div>
  );
};

const styles = {
  values: css({
    marginBottom: 5,
  }),
};

/** @jsxImportSource @emotion/react */
import {useContext, useEffect, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {IObject, resetUsersAction, usersAction} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
import {FilterCheckboxs} from './FilterCheckboxs';
import {useQuery} from '@tanstack/react-query';
import {getPosUsers} from '../../api/fetchPosUsers';

export const UsersFilter = () => {
  const users = useAppSelector(state => state.filter.users);
  const {t, dispatch} = useContext<any>(UtilsContext);

  const [usersSelected, setUsersSelected] = useState<IObject[]>([]);
  const [forceClose, setForceClose] = useState(false);

  const {data} = useQuery(['getPosUsers'], getPosUsers);

  const usersList =
    data?.map((item: any) => ({
      name: item.username,
      value: item?.uuid,
    })) || [];

  const handleResetUsers = () => {
    dispatch(resetUsersAction());
  };

  const handleCheckedChange = (e: any) => {
    if (e.target.name === 'ALL') {
      if (usersSelected.length === usersList.length) {
        setUsersSelected([]);
      } else {
        setUsersSelected(usersList);
      }
    } else {
      if (
        !!usersSelected.find(
          userSelected => userSelected.name === e.target.name,
        )
      ) {
        setUsersSelected(
          usersSelected.filter(
            userSelected => userSelected.name !== e.target.name,
          ),
        );
      } else {
        setUsersSelected([
          ...usersSelected,
          {
            name: e.target.name,
            value: e.target.value,
          },
        ]);
      }
    }
  };

  const handleSubmit = () => {
    //
    dispatch(usersAction(usersSelected));
    setForceClose(true);
  };

  useEffect(() => {
    setUsersSelected(users);
    return () => {
      setForceClose(false);
    };
  }, [users]);

  return (
    <FilterButton
      marginRight={'1vh'}
      label={t('USERS')}
      valueType="list"
      values={users}
      showResetButton={users?.length > 0}
      onReset={handleResetUsers}
      hideFilterIcon={users?.length > 0}
      forceClose={forceClose}>
      <FilterCheckboxs
        title={t('USERS')}
        list={usersList}
        selectedValues={usersSelected}
        onChange={handleCheckedChange}
        onSubmit={handleSubmit}
      />
    </FilterButton>
  );
};

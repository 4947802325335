/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../../components/layouts/Header';
import {ConfigurationCard} from './ConfigurationCard';
import {SelectedCardHeaderCenter} from './HeaderSections/SelectedCardHeaderCenter';
import {ProductsAndMenuOfCard} from './ProductsAndMenuOfCard';
import {ProductsAndMenuCatalog} from '../../../../../components/organisms/ProductsAndMenuCatalog';
import {useNavigate, useParams} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';
import {
  createCardItem,
  deleteCardItem,
  fetchCardItems,
  getCardDetails,
} from '../../../../../api/fetchCards';
import {useAppDispatch} from '../../../../../hooks/useReduxHook';
import {updatedCardAction} from '../../../../../redux/myCatalogSection/cards';
import {useEffect} from 'react';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {IFetchCardItemsResponse} from '@bill-app-types/b-types';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';

export const SelectedCard = withRedirectAndNavigation(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const params = useParams();

  if (!params.uuid) navigate('/online/catalog/cards');

  const {data} = useQuery(['getCardDetails'], () =>
    getCardDetails(params?.uuid),
  );

  useEffect(() => {
    if (data) {
      dispatch(updatedCardAction(data));
    }
  }, [data, dispatch]);

  const {
    data: items,
    // isLoading: itemIsLoading,
    refetch,
  } = useQuery(['fetchCardItems'], () => fetchCardItems(params?.uuid || ''));

  const handleClickAddProduct = async (product: IFetchCardItemsResponse) => {
    const productFinded = items?.find(p => p.uuid === product.uuid);
    if (!productFinded && params.uuid) {
      const data = {
        itemUuid: product.uuid,
        cardUuid: params.uuid,
      };
      const res = await createCardItem(data);
      if (res && res.status === 204) {
        helperDisplayToast(
          res?.data?.message || 'Item ajoutée à la carte',
          true,
        );
        refetch();
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible d'ajouter l'item à la carte",
          false,
        );
      }
    }
  };

  const handleClickRemoveProduct = async (product: IFetchCardItemsResponse) => {
    if (!hasAuthorization) {
      return;
    }
    if (params.uuid) {
      const res = await deleteCardItem(params.uuid, product?.uuid);
      if (res && res.status === 204) {
        helperDisplayToast(
          res?.data?.message || 'Item retiré de la carte',
          true,
        );
        refetch();
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible de retirer l'item de la carte",
          false,
        );
      }
    }
  };

  const handleIsDisabled = (card: IFetchCardItemsResponse) => {
    return items?.find(item => item?.uuid === card.uuid) || false;
  };

  if (!params.uuid) return null;

  return (
    <div css={styles.container}>
      <Header
        centerSection={<SelectedCardHeaderCenter cardUuid={params?.uuid} />}
      />
      <div css={styles.content}>
        <ConfigurationCard />
        <div css={styles.products}>
          {hasAuthorization && (
            <ProductsAndMenuCatalog
              hiddenProducts={false}
              onClickProduct={handleClickAddProduct}
              handleIsDisabled={handleIsDisabled}
            />
          )}
          <ProductsAndMenuOfCard
            data={items}
            onClickProduct={handleClickRemoveProduct}
          />
        </div>
      </div>
    </div>
  );
});

const styles = {
  container: css({
    height: '100vh',
  }),
  content: {
    padding: '2vh',
    height: '87%',
  },
  products: css({
    display: 'flex',
    // display: 'grid',
    // gridTemplateColumns: '1fr 1fr',
    gap: '2rem',
    width: '100%',
    height: '72%',
    // overflow: 'auto',
  }),
};

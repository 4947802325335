/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {TextVariant} from '../atoms/TextVariant';

export const DataGridFooter = ({
  columns = ['TOTAL'],
  height = 45,
  size = undefined,
}: {
  columns: string[];
  height?: number;
  size?: number[] | undefined;
}) => {
  return (
    <div css={styles.footerRow}>
      {columns?.map((c: string, index: number) => (
        <div
          css={[
            styles.col,
            {height: height, flex: size && index === 0 ? 2 : 1},
          ]}
          key={index}>
          <TextVariant text={c} style={styles.span} variant="textSemiBold" />
        </div>
      ))}
    </div>
  );
};

const styles = {
  footerRow: css({
    display: 'flex',
    backgroundColor: THEME.colors.white,
    marginTop: 5,
    position: 'sticky',
    bottom: 0,
  }),
  col: css({
    flex: 1,
    height: 45,
    padding: '0 10px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  }),
  span: css({
    color: THEME.colors.primary,
    fontWeight: '600',
  }),
};

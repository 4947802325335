/** @jsxImportSource @emotion/react  */
import {css} from '@emotion/react';
import {ImageCustom} from '../../../../components/atoms/ImageCustom';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {DataGridActions} from '../../../../components/newDatagrid/DataGridActions';
import {THEME} from '../../../../styles/theme';
import {addExternalConnectionModalAction, deleteExternalConnectionModalAction} from '../../../../redux/modals';
import dayjs from 'dayjs';
import { externalConnectionSetSelectedAction, externalConnectionsUpdateMode } from '../../../../redux/myModulesSection/externalConnections';
import { EXTERNAL_PROVIDER } from '@bill-app-types/b-types';
import { t } from 'i18next';

export const columns = [
  {
    field: 'icon',
    headerName: '',
    headerClassName: 'textRegular',
    flex: 0.3,
    renderCell: (params: any) => {
      return (
        <div
          className="flex-align-center"
          style={{
            width: '100%',
            marginLeft: '10px',
          }}>
          <div className="flex-center" css={styles.icon}>
            {
              parseInt(params.external_provider_id) === EXTERNAL_PROVIDER.UBEREATS ?
                <ImageCustom src='/images/uberEats.png' isUpload={true} />
              : parseInt(params.external_provider_id) === EXTERNAL_PROVIDER.DELIVEROO ?
                <ImageCustom src='/images/deliveroo.png' isUpload={true} />
              : <ImageCustom src={null}/>
            }
          </div>
        </div>
      );
    },
  },
  {
    field: 'connection_label',
    headerName: "Nom de l’intégration",
    flex: 1,
    renderCell: (params: any) => {
      return (
        <div className="flex-align-center">
          <TextVariant
            text={params.connection_label || '--'}
            variant="textMedium"
            style={{marginLeft: 8}}
          />
        </div>
      );
    },
  },
  {
    field: 'credentials.external_id',
    headerName: 'Identifiant du store',
    flex: 1,
    renderCell: (params: any) => (
      <div className='flex-align-center'>
        <TextVariant
          text={params.credentials.external_id || '--'}
          variant="textMedium"
          style={{color: THEME.colors.black, marginLeft: 8}}
        />
      </div>
    ),
  },
  {
    field: 'sync_status',
    headerName: 'Statut de la synchronisation',
    flex: 1,
    renderCell: (params: any) => {
      const status = 
        params.sync_status === -1 ?
          t('FAILED') 
        : params.sync_status === 0 ?
          t('IN_PROGRESS_SHORT') 
        : params.sync_status === 1 ?
        t('SUCCESS') : '';
      const color = 
        params.sync_status === -1 ?
          THEME.colors.red
        : params.sync_status === 0 ?
          THEME.colors.yellow
        : params.sync_status === 1 ?
          THEME.colors.green : 
          THEME.colors.black;
      return (
        <div className='flex-align-center'>
          <TextVariant
            text={status || '--'}
            style={{color, marginRight: 5}}
            variant="textMedium"
          />
        </div>
      );
    },
  },
  {
    field: 'sync_date',
    headerName: 'Dernière synchronisation',
    flex: 1,
    renderCell: (params: any) => {
      return (
        <div className='flex-align-center'>
          <TextVariant
            text={params.sync_date ? dayjs(params.sync_date).format('DD/MM/YYYY à hh:mm:ss') : '--'}
            style={{color: THEME.colors.black, marginRight: 5}}
            variant="textMedium"
          />
        </div>
      );
    },
  },
  {
    field: 'actions',
    headerName: '',
    align: 'right',
    flex: 0.3,
    renderCell: (row: any) => {
      const handleClickEdit = ({dispatch}: any) => {
        dispatch(externalConnectionsUpdateMode(true));
        dispatch(externalConnectionSetSelectedAction(row));
        dispatch(addExternalConnectionModalAction(true))
      };

      const handleClickDelete = ({dispatch}: any) => {
        dispatch(externalConnectionSetSelectedAction(row));
        dispatch(deleteExternalConnectionModalAction(true));
      }

      return (
        <div css={styles.actions}>
          <DataGridActions
            handleClickEdit={handleClickEdit}
            handleClickDelete={handleClickDelete}
          />
        </div>
      );
    },
  },
];

const styles = {
  icon: css({
    height: 40.25,
    width: 40.25,
    borderRadius: 2,
  }),
  actions: css({
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 10,
  }),
};

export const BMenuIcon = ({
  size,
  isDisabled = false,
  isActive = false,
}: {
  size: string;
  isDisabled?: boolean;
  isActive?: boolean;
}) => (
  <svg
    width={size === 'small' ? '43' : '65'}
    height={size === 'small' ? '13' : '21'}
    viewBox="0 0 65 21"
    fill="none">
    <path
      d="M7.24896 5.57967C6.36558 5.57967 5.51971 5.73738 4.73779 6.02301L6.50675 9.33594C6.74606 9.28411 6.9942 9.25543 7.25006 9.25543C9.18995 9.25543 10.7637 10.8027 10.7637 12.7117C10.7637 14.6207 9.19105 16.168 7.25006 16.168C5.30906 16.168 3.83016 14.7057 3.74524 12.8705L3.73752 12.8794V6.47297C3.73752 6.47297 3.732 6.47628 3.7287 6.47738L3.73752 0.5H0L0 19.8427H3.70774V18.9339C4.75654 19.5118 5.96305 19.8427 7.24896 19.8427C11.2523 19.8427 14.4979 16.65 14.4979 12.7106C14.4979 8.77129 11.2523 5.57967 7.24896 5.57967Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#151A30' : '#3C6AF6'}
    />
    <path
      d="M16.3984 9.83337H19.0596V19.8416H16.3984V9.83337ZM27.2471 9.67346C27.8757 9.67346 28.4039 9.79698 28.8407 10.044C29.2741 10.2911 29.6226 10.6142 29.8895 11.0145C30.1563 11.4148 30.3493 11.8648 30.4696 12.3655C30.5898 12.8651 30.6515 13.3702 30.6515 13.8764V19.8416H27.9871L28.0102 13.8609C28.0102 13.647 27.9683 13.4463 27.89 13.2588C27.8084 13.0713 27.6981 12.9037 27.5581 12.757C27.418 12.6092 27.2515 12.4923 27.0596 12.4052C26.8644 12.3192 26.6626 12.2751 26.4475 12.2751C26.0075 12.2751 25.6325 12.4328 25.327 12.7471C25.0204 13.0625 24.8671 13.433 24.8671 13.8609V19.8416H22.206V13.8609C22.206 13.647 22.1674 13.4463 22.0858 13.2588C22.0042 13.0713 21.8939 12.9037 21.7472 12.757C21.5972 12.6092 21.4285 12.4956 21.2355 12.4162C21.0403 12.3357 20.8252 12.296 20.5837 12.296C20.5705 12.296 20.512 12.1968 20.4051 11.9982C20.2981 11.7997 20.1768 11.5814 20.0433 11.3421C19.8966 11.0641 19.718 10.7465 19.5029 10.3892L19.5261 10.3495C20.0996 9.89954 20.7315 9.67346 21.4252 9.67346C22.174 9.67346 22.7806 9.83999 23.246 10.1697C23.7147 10.5006 24.0731 10.9119 24.3278 11.4016C24.3797 11.309 24.4348 11.2296 24.4878 11.1634C24.8627 10.6737 25.2862 10.3032 25.7582 10.0517C26.2336 9.80029 26.7287 9.67346 27.2504 9.67346H27.2471Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M36.852 9.67297C37.5589 9.67297 38.2272 9.81083 38.8525 10.0832C39.4812 10.3567 40.0282 10.7273 40.4947 11.1938C40.9634 11.6614 41.3317 12.2073 41.6052 12.8348C41.8787 13.4623 42.0155 14.1362 42.0155 14.8574C42.0155 15.2445 41.9769 15.6173 41.8953 15.9779L41.8754 16.0176H36.7935L35.5098 13.9762H39.3146C39.126 13.416 38.8161 12.9561 38.3827 12.5944C37.9493 12.2349 37.4376 12.054 36.852 12.054C36.4903 12.054 36.1517 12.1268 35.8319 12.2735C35.5098 12.419 35.2319 12.6153 35.0014 12.8613C34.7665 13.1072 34.5846 13.3995 34.4511 13.738C34.3177 14.0777 34.2493 14.4472 34.2493 14.8453C34.2493 15.1519 34.3111 15.4673 34.4313 15.7926C34.5515 16.1191 34.7246 16.4146 34.9496 16.6804C35.1779 16.9462 35.4514 17.169 35.7701 17.3487C36.0921 17.5285 36.4506 17.6178 36.852 17.6178C37.2137 17.6178 37.5424 17.5682 37.8423 17.4678C38.1423 17.3675 38.4257 17.1447 38.6926 16.7962L38.7125 16.7764L38.7544 16.7962L41.0737 17.8196L41.0351 17.8604C40.5564 18.5332 39.9432 19.0581 39.2043 19.4353C38.4643 19.8114 37.6802 20.0011 36.852 20.0011C36.1318 20.0011 35.4547 19.8676 34.8195 19.6007C34.1875 19.3349 33.6372 18.9666 33.1707 18.5001C32.702 18.0336 32.3304 17.4866 32.0602 16.8591C31.7867 16.2316 31.6499 15.5654 31.6499 14.8574C31.6499 14.1494 31.7867 13.4634 32.0602 12.8348C32.3304 12.2073 32.702 11.6614 33.1707 11.1938C33.6361 10.7273 34.1875 10.3567 34.8195 10.0832C35.4547 9.81083 36.1318 9.67297 36.852 9.67297Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M43.019 9.8335H45.7397V19.8418H43.019V9.8335ZM52.2652 10.9937C52.5972 11.4072 52.8387 11.9278 52.9854 12.5553C53.1321 13.1828 53.2071 13.957 53.2071 14.8768V19.8407H50.4831V14.8635C50.4831 14.4886 50.4731 14.1313 50.4533 13.7905C50.4334 13.4497 50.3617 13.1486 50.2448 12.8884C50.1246 12.627 49.9283 12.423 49.6614 12.2763C49.3945 12.1296 49.0163 12.0557 48.5211 12.0557C48.0557 12.0557 47.6223 12.1561 47.2209 12.3568V12.3766L47.201 12.3568L46.2206 10.6264L46.2404 10.6066C46.9341 9.98458 47.8009 9.67358 48.8398 9.67358C50.4422 9.67358 51.5826 10.1147 52.2641 10.9948L52.2652 10.9937Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M60.2279 17.3207L61.1885 19.0631V19.083C60.4948 19.6973 59.6346 20.0039 58.6079 20.0039C56.979 20.0039 55.8254 19.5627 55.1449 18.6827C54.5163 17.8952 54.2031 16.6413 54.2031 14.9187V9.83459H56.9459V14.9187C56.9459 15.2793 56.9558 15.6267 56.9757 15.9598C56.9955 16.2939 57.0672 16.5873 57.194 16.8409C57.3208 17.0946 57.5194 17.2975 57.7873 17.4508C58.0542 17.6052 58.4325 17.6813 58.9277 17.6813C59.1527 17.6813 59.3743 17.6482 59.5894 17.5809C59.8011 17.5137 59.9996 17.4276 60.1893 17.3207H60.2279ZM64.4308 9.83459V19.9024H61.6914V9.83459H64.4308Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
  </svg>
);

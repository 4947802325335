/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useEffect} from 'react';
import {ChevronDownIcon} from '../../../../../assets/svgs/ChevronDownIcon';
import {ChevronUpIcon} from '../../../../../assets/svgs/ChevronUpIcon';
import {EditIcon} from '../../../../../assets/svgs/EditIcon';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {
  categoryProductsAction,
  categorySelectedAction,
  updatedCategoryAction,
  updateModeAction,
} from '../../../../../redux/myCatalogSection/categories';
import {THEME} from '../../../../../styles/theme';
import {ActionButton} from '../../../../../components/atoms/ActionButton';
import {ToggleButton} from '../../../../../components/atoms/ToggleButton';
import {ProductList} from '../../../../../components/molecules/ProductList';
import {addProductCategoryModalAction} from '../../../../../redux/modals';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {Loader} from '../../../../../components/atoms/Loader';
import {
  deleteCategoryProduct,
  fetchCategoryProducts,
  updateCategory,
  updateCategoryItemsPosition,
} from '../../../../../api/fetchCategories';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {helperLabelTranslation} from '../../../../../helpers/helperLabel';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {
  SortableListVerticalContainer,
  THandleDragAndDrop,
} from '../../../../../components/organisms/SortableListVerticalContainer';
import {deleteExternalCategoryItem} from '../../../../../api/fetchExternalCategories';
import {ICategoryFetchReturn, STRUCTURE_TYPES} from '@bill-app-types/b-types';
import {AxiosResponse} from 'axios';

export const RecursiveCategoriesDropdown = ({
  toggle = false,
  level = 0,
  item,
  handleDragAndDrop,
}: // rows,
{
  toggle?: boolean;
  showProductType?: boolean;
  categories?: any;
  level?: number;
  item?: any;
  handleDragAndDrop: THandleDragAndDrop;
}) => {
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();

  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const categorySelected = useAppSelector(
    s => s.myCatalogSection.categories.categorySelected,
  );

  const {data, isLoading, refetch} = useQuery(
    [`fetchCategoryProducts-${categorySelected?.uuid}`, categorySelected?.uuid],
    () =>
      categorySelected?.uuid
        ? fetchCategoryProducts(categorySelected?.uuid)
        : null,
    {
      enabled: !!categorySelected?.uuid,
    },
  );

  useEffect(() => {
    if (data) {
      dispatch(categoryProductsAction(data));
    }
  }, [data, dispatch]);

  const handleOpenCategory = (category: ICategoryFetchReturn) => {
    dispatch(categorySelectedAction(category));
  };

  const handleRemoveProduct = async (item: {uuid: string}) => {
    if (!hasAuthorization || !categorySelected) {
      return;
    }

    let res: AxiosResponse;

    if (categorySelected?.type !== STRUCTURE_TYPES.EXTERNAL) {
      res = await deleteCategoryProduct(categorySelected?.uuid, item?.uuid);
    } else {
      res = await deleteExternalCategoryItem(
        categorySelected?.uuid,
        item?.uuid,
      );
    }

    if (res && res.status === 204) {
      helperDisplayToast(
        res?.data?.message || 'Item retiré de la catégorie',
        true,
      );
      refetch();
    } else {
      helperDisplayToast(
        res?.data?.message || "Impossible de retirer l'item de la catégorie",
        false,
      );
    }
  };

  const handleClickEdit = (category: ICategoryFetchReturn) => {
    dispatch(addProductCategoryModalAction(true));
    dispatch(updateModeAction(true));
    dispatch(
      updatedCategoryAction({
        ...category,
        label: helperLabelTranslation(category),
      }),
    );
  };

  const handleProductDragAndDrop: THandleDragAndDrop = async ({items}) => {
    if (!hasAuthorization) {
      return;
    }
    if (categorySelected && items) {
      const newItems = items?.map(item => ({
        uuid: item.uuid,
        type: 1,
        image: null,
      }));

      const res = await updateCategoryItemsPosition(
        categorySelected?.uuid,
        newItems,
      );

      if (res?.status === 204) {
        helperDisplayToast('Position modifiée', true);
      } else {
        helperDisplayToast(
          res?.data?.message || 'Impossible de modifier la position',
          false,
        );
      }
    }
  };

  const handleUpdateCategory = async () => {
    if (!hasAuthorization) {
      return;
    }
    const res = await updateCategory(
      {
        isArchived: !item?.isArchived,
      },
      item?.uuid,
    );

    if ([200, 204].includes(res?.status)) {
      helperDisplayToast('Catégorie modifiée', true);
      clientQuery.invalidateQueries(['getStructureDetails']);
    } else {
      helperDisplayToast(
        res?.data?.message || 'Impossible de modifier la catégorie',
        false,
      );
    }
  };

  const isOpen = categorySelected && item.uuid === categorySelected?.uuid;

  return (
    <div style={{marginBottom: 5}}>
      <div
        css={[
          styles.dropdown,
          {marginBottom: item?.subCategories?.length > 0 ? 5 : 0},
        ]}>
        <div css={styles.actions}>
          <ActionButton
            onClick={() => handleClickEdit(item)}
            Icon={EditIcon}
            disabled={!hasAuthorization}
          />
          <ToggleButton
            onToggle={handleUpdateCategory}
            active={!item?.isArchived}
          />
        </div>
        <div
          css={styles.dropdownHeader}
          onClick={() => handleOpenCategory(item)}>
          <div className="textMedium" css={styles.title}>
            <span>{helperLabelTranslation(item)}</span>
          </div>
          <div css={styles.toggle}>{toggle && <ToggleButton active />}</div>
          <div css={styles.chevron}>
            {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </div>
        </div>
      </div>

      {data && isOpen && (
        <div css={styles.dropdownContent}>
          {data?.length > 0 ? (
            <ProductList
              data={[...data]?.sort(
                (a: any, b: any) => a.position - b.position,
              )}
              handleOnClick={handleRemoveProduct}
              handleDragAndDrop={handleProductDragAndDrop}
              style={{backgroundColor: THEME.colors.white}}
              showProductType
              draggable
              showCross={hasAuthorization}
            />
          ) : (
            <p className="textSemiBold">Aucun produits</p>
          )}
        </div>
      )}
      {(isLoading || !data) && isOpen && (
        <div className="flex-center" css={styles.dropdownContent}>
          <Loader />
        </div>
      )}

      {item?.subCategories?.length > 0 && (
        <>
          <SortableListVerticalContainer
            ItemComponent={RecursiveCategoriesDropdown}
            data={item?.subCategories}
            level={level + 1}
            handleDragAndDrop={handleDragAndDrop}
          />
        </>
      )}
    </div>
  );
};

const styles = {
  dropdown: css({
    backgroundColor: THEME.colors.white,
    display: 'flex',
    borderRadius: 2,
    padding: '0px 10px',
    alignItems: 'center',
  }),
  actions: css({
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  }),
  dropdownHeader: css({
    backgroundColor: THEME.colors.white,
    display: 'flex',
    padding: '7px 10px',
    borderRadius: 2,
    alignItems: 'center',
    cursor: 'pointer',
    flex: 1,
  }),
  dropdownContent: css({
    backgroundColor: THEME.colors.white,
    padding: 10,
    marginBottom: 10,
  }),
  title: css({
    paddingLeft: 10,
  }),
  edit: css({
    backgroundColor: THEME.colors.bgLight,
    width: 25,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 2,
  }),
  chevron: css({
    // marginLeft: 'auto',
  }),
  toggle: css({
    marginLeft: 'auto',
    marginRight: 10,
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Title} from '../../../../../components/atoms/Title';
import {DataGridFooter} from '../../../../../components/newDatagrid/DataGridFooter';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {columnsPaymentMode} from './columns';
import {helperFormatPrice} from '../../../../../helpers/helperFormatPrice';
import {useSqsPaymentMethods} from '../../../../../hooks/useSqsPaymentMethods';

export const SectionPaymentMode = () => {
  const {data, isLoading} = useSqsPaymentMethods();
  const rows = data || [];

  return (
    <div css={styles.container}>
      <Title title="Ventilation par mode de règlement" />
      <NewDataGrid
        columns={columnsPaymentMode}
        rows={rows}
        rowHeight={35}
        loading={isLoading}
        minHeight={100}
        footer={
          <DataGridFooter
            columns={[
              'TOTAL',
              '', // `${helperFormatPrice(totalTva?.totalHT)}€`,
              '',
              `${helperFormatPrice(
                rows?.reduce((t, row) => t + Number(row.amount), 0),
              )}`,
              `${rows?.reduce((t, row) => t + Number(row.total_receipts), 0)}`,
            ]}
            height={35}
          />
        }
        stickyFooter
      />
    </div>
  );
};

const styles = {
  container: css({
    flex: 1,
    height: '45%',
    '@media (max-width: 1024px)': {
      flex: 'none',
      marginBottom: '5%',
    },
  }),
};

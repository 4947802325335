import {useContext} from 'react';
import {OrderByFilter} from '../../../../../components/filters/OrderByFilter';
import {SearchFilter} from '../../../../../components/filters/SearchFilter';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {VisibilityFilter} from '../../../../../components/filters/VIsibilityFilter';

export const Bottom = () => {
  const {t} = useContext<any>(UtilsContext);
  return (
    <>
      <SearchFilter marginRight={'1vh'} />
      <VisibilityFilter marginRight="1vh" />
      <OrderByFilter
        icon={'calendar'}
        label={t('DATE_ADDED')}
        field="createdAt"
        marginRight={'1vh'}
      />
      <OrderByFilter
        icon={'calendar'}
        label={t('MODIFICATION_DATE')}
        field="updatedAt"
      />
    </>
  );
};

import {useEffect, useState} from 'react';
import {arrayMoveImmutable} from 'array-move';
import {SortableList} from './SortableList';
import {THEME} from '../../../../../../styles/theme';

interface IProps {
  bg?: string;
  showProductType?: boolean;
  handleOnClick?: any;
  data?: any;
  style?: any;
  loading?: boolean;
  handleDragAndDrop?: any;
  specificImage?: string;
  productsChecked?: any[];
}

export const ProductList = ({
  bg = THEME.colors.bgLight,
  showProductType = false,
  handleOnClick,
  data,
  style,
  loading = false,
  handleDragAndDrop,
  productsChecked,
}: IProps) => {
  const [copyData, setCopyData] = useState<any>([...data]);

  const onSortEnd = async ({oldIndex, newIndex}: any) => {
    if (!copyData[oldIndex] || !handleDragAndDrop) return;

    const items = arrayMoveImmutable(copyData, oldIndex, newIndex);

    setCopyData(() => items);

    // reset all draggable
    const draggablesItems = document.querySelectorAll(
      'body > .button-card--draggable',
    );

    if (draggablesItems.length > 0) {
      draggablesItems.forEach(item => {
        item.remove();
      });
    }

    handleDragAndDrop({
      items,
    });
  };

  useEffect(() => {
    if (data) {
      setCopyData([...data]);
    }
  }, [data]);

  if (!loading && data && data?.length === 0) {
    return <div className="flex-center textRegular">Aucune donnée</div>;
  }

  const onSortMove = () => {
    // force to fix bug when drag and drop
    const draggablesItems = document.querySelectorAll(
      'body > .button-card--draggable',
    ) as NodeListOf<HTMLDivElement>;

    if (draggablesItems.length > 1) {
      for (let i = 0; i < draggablesItems.length; i++) {
        if (i !== draggablesItems.length - 1) {
          draggablesItems[i].remove();
        }
      }
      draggablesItems[draggablesItems.length - 1].style.opacity = '1';
      draggablesItems[draggablesItems.length - 1].style.visibility = 'visible';
    }
  };

  return (
    <SortableList
      //@ts-ignore
      items={copyData}
      onSortEnd={onSortEnd}
      onSortMove={onSortMove}
      axis="xy"
      bg={bg}
      style={style}
      handleOnClick={handleOnClick}
      distance={1}
      showProductType={showProductType}
      productsChecked={productsChecked}
    />
  );
};

export const BDriveIcon = ({
  size,
  isDisabled = false,
  isActive = false,
}: {
  size: string;
  isDisabled?: boolean;
  isActive?: boolean;
}) => (
  <svg
    width={size === 'small' ? '43' : '64'}
    height={size === 'small' ? '12' : '20'}
    viewBox="0 0 64 20"
    fill="none">
    <path
      d="M8.02698 5.54769C7.14912 5.54769 6.30876 5.70429 5.53126 5.98772L7.28918 9.2797C7.5274 9.22786 7.77333 9.19919 8.02809 9.19919C9.95695 9.19919 11.5197 10.7365 11.5197 12.6345C11.5197 14.5325 9.95695 16.0699 8.02809 16.0699C6.09922 16.0699 4.62914 14.6163 4.54422 12.7933L4.5365 12.8022V6.43658C4.5365 6.43658 4.53099 6.43989 4.52768 6.44099L4.5365 0.5H0.823242V19.7214H4.50783V18.8181C5.55001 19.3927 6.74879 19.7214 8.02698 19.7214C12.006 19.7214 15.2307 16.5485 15.2307 12.6345C15.2307 8.72056 12.006 5.54769 8.02698 5.54769Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#151A30' : '#3C6AF6'}
    />
    <path
      d="M28.3431 5.30542V19.5585H25.6147L25.6345 14.4479C25.6345 14.0675 25.5562 13.709 25.4007 13.3694C25.2441 13.0297 25.0412 12.7341 24.7897 12.4827C24.5394 12.2323 24.2405 12.0349 23.8942 11.8927C23.5479 11.7504 23.1774 11.6787 22.7848 11.6787C22.3922 11.6787 22.0249 11.7493 21.6853 11.8927C21.3456 12.0349 21.05 12.2357 20.7997 12.4937C20.5482 12.7518 20.3475 13.0507 20.1986 13.3892C20.0486 13.7289 19.9748 14.095 19.9748 14.4888C19.9748 14.8825 20.0486 15.2486 20.1986 15.5883C20.3475 15.928 20.5482 16.2235 20.7997 16.4739C21.05 16.7253 21.3456 16.926 21.6853 17.0749C22.0249 17.2249 22.3911 17.2988 22.7848 17.2988C22.9756 17.2988 23.1653 17.2855 23.3549 17.258C23.5457 17.2315 23.7211 17.1764 23.8843 17.0959H23.9251L24.9232 18.9277L24.9022 18.9486C24.2372 19.4637 23.4222 19.7217 22.4583 19.7217C21.725 19.7217 21.0401 19.5861 20.4027 19.3148C19.7641 19.0435 19.2072 18.6696 18.733 18.1954C18.2576 17.7201 17.8805 17.1642 17.6025 16.5257C17.3246 15.8883 17.1846 15.2089 17.1846 14.4899C17.1846 13.7708 17.3235 13.0915 17.6025 12.4529C17.8805 11.8155 18.2576 11.2596 18.733 10.7832C19.2072 10.309 19.7641 9.93513 20.4027 9.66384C21.0401 9.39254 21.7261 9.25689 22.4583 9.25689C23.8435 9.25689 24.9022 9.77302 25.6345 10.8042V5.30542H28.3431Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M37.8502 9.78501L37.6053 10.1931L36.5676 12.0458L36.5466 12.0249C36.0989 11.7668 35.6169 11.6389 35.1019 11.6389C34.7479 11.6389 34.4159 11.6962 34.1038 11.8109C33.7917 11.9267 33.5127 12.0888 33.269 12.2995C33.0241 12.5101 32.8168 12.7583 32.6481 13.0428C32.4771 13.3273 32.3657 13.6328 32.3117 13.9593V19.5584H29.5425V9.37806H32.3117V10.7015C33.0308 9.71112 34.0829 9.21484 35.468 9.21484C36.283 9.21484 37.0694 9.40564 37.8303 9.78391H37.8502V9.78501Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M38.4438 5.30554H41.1524V7.68767H38.4438V5.30554ZM38.4438 19.5586V9.37832H41.1524V19.5586H38.4438Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M44.8754 9.39809L47.5023 16.7684L45.7102 18.6211L41.9639 9.37714H44.8754V9.39809ZM50.4546 9.37714H53.3452L49.172 19.5574H46.8097L47.6446 17.2161L50.4546 9.37714Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M57.923 9.21484C58.6421 9.21484 59.3214 9.3538 59.9589 9.63172C60.5974 9.90963 61.1532 10.2868 61.6286 10.7621C62.1039 11.2374 62.4811 11.7944 62.759 12.4318C63.0369 13.0693 63.1769 13.7552 63.1769 14.4886C63.1769 14.8823 63.1361 15.2617 63.0545 15.629L63.0336 15.6698H57.8613L56.5577 13.5931H60.4265C60.2357 13.0229 59.9203 12.5553 59.4802 12.1881C59.038 11.822 58.5197 11.6389 57.9219 11.6389C57.5558 11.6389 57.2095 11.7128 56.8841 11.8617C56.5577 12.0105 56.2765 12.2102 56.0394 12.4605C55.8012 12.7108 55.6148 13.0075 55.4802 13.3527C55.3435 13.6968 55.2762 14.0728 55.2762 14.4787C55.2762 14.7897 55.3369 15.1117 55.4593 15.4426C55.5817 15.7745 55.757 16.0756 55.9886 16.3458C56.2191 16.616 56.4982 16.8432 56.8235 17.0251C57.1499 17.2071 57.5161 17.2986 57.923 17.2986C58.2892 17.2986 58.6255 17.2479 58.931 17.1465C59.2376 17.045 59.5243 16.8178 59.7967 16.4649L59.8177 16.4439L59.8585 16.4649L62.2208 17.5049L62.18 17.5468C61.6903 18.2316 61.0705 18.7643 60.3162 19.1481C59.5629 19.5319 58.7656 19.7227 57.9241 19.7227C57.1907 19.7227 56.5026 19.587 55.8574 19.3157C55.2122 19.0444 54.652 18.6706 54.1778 18.1964C53.7025 17.721 53.3253 17.1652 53.0474 16.5267C52.7695 15.8892 52.6294 15.2099 52.6294 14.4908C52.6294 13.7718 52.7684 13.0715 53.0474 12.434C53.3253 11.7966 53.7025 11.2397 54.1778 10.7643C54.652 10.289 55.2122 9.91184 55.8574 9.63392C56.5026 9.35601 57.1907 9.21705 57.9241 9.21705L57.923 9.21484Z"
      fill={isDisabled ? '#CCD6E0' : '#151A30'}
    />
  </svg>
);

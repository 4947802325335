/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useNavigate} from 'react-router-dom';
import {ChevronLeft} from '../../../../../assets/svgs/ChevronLeft';
import {THEME} from '../../../../../styles/theme';
import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {useTranslation} from 'react-i18next';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {ExportDayDetailPDF} from '../../../../../components/templates/ExportDayDetailPDF';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {helperDate} from '../../../../../helpers/helperDate';

export const Header = ({data}: {data: any}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const dates = useAppSelector(s => s.filter.dates);
  const brand = useAppSelector(s => s.brand.brand);

  const handleClick = () => {
    navigate('/online/accounting/days');
  };

  return (
    <div css={styles.header} className="flex-space-between">
      <div
        className="flex-center textSemiBold"
        css={styles.actions}
        onClick={handleClick}>
        <ChevronLeft />
        Retour
      </div>
      <PDFDownloadLink
        document={
          <ExportDayDetailPDF data={data} brand={brand} dates={dates} />
        }
        fileName={`day-${helperDate(new Date(), 'DD-MM-YYYY')}.pdf`}>
        <ButtonCustom label={t('DOWNLOAD_PDF')} />
      </PDFDownloadLink>
    </div>
  );
};

const styles = {
  header: css({
    height: '7.5vh',
    width: '100%',
    alignItems: 'center',
    padding: '0 2vh',
    // paddingBottom: '2vh',
    backgroundColor: THEME.colors.bgLight,
  }),
  actions: css({
    gap: 10,
    color: THEME.colors.gray,
    cursor: 'pointer',
    marginRight: 10,
  }),
};

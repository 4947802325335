/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useEffect, useState} from 'react';
import {THEME} from '../../styles/theme';
import {TextVariant} from './TextVariant';
import {useBrandAccess} from '../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../constants/frontPolicies';

interface IToggleButtonProps {
  active: boolean;
  onToggle?: any;
  showBorder?: boolean;
  order?: number;
  disabled?: boolean;
  authorize?: BRAND_AUTHORIZATION;
}

export const ToggleButton = ({
  active = false,
  onToggle,
  showBorder,
  order,
  disabled = false,
  authorize,
}: IToggleButtonProps) => {
  const [isActive, setIsActive] = useState(false);
  const {brandHasAuthorization} = useBrandAccess();

  const cannotChangeValue = !!(
    disabled ||
    (authorize && !brandHasAuthorization(authorize))
  );

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const handleToggle = (e: any) => {
    e.stopPropagation();
    if (cannotChangeValue) return;
    if (onToggle) {
      onToggle();
      setIsActive(!isActive);
    }
  };

  return (
    <div
      css={[
        styles.container(cannotChangeValue),
        {order},
        showBorder && {
          border: `2px solid white`,
          borderRadius: 3,
        },
      ]}
      onClick={handleToggle}>
      {isActive ? (
        <div css={styles.on}>
          <TextVariant text={'ON'} variant={'title03'} style={styles.label} />
        </div>
      ) : (
        <div css={styles.off}>
          <TextVariant text={'OFF'} variant={'title03'} style={styles.label} />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: (disabled: boolean) =>
    css({
      backgroundColor: THEME.colors.bgLight,
      borderRadius: 2,
      width: 64,
      height: 29,
      display: 'flex',
      padding: 2,
      cursor: 'pointer',
      opacity: disabled ? 0.5 : 1,
    }),
  on: css({
    backgroundColor: THEME.colors.green,
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    height: '100%',
    display: 'flex',
    borderRadius: 2,
    marginLeft: 'auto',
  }),
  off: css({
    backgroundColor: THEME.colors.red,
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    height: '100%',
    display: 'flex',
    borderRadius: 2,
    marginRight: 'auto',
  }),
  label: css({
    color: THEME.colors.white,
    fontSize: 10,
    fontWeight: 700,
    userSelect: 'none',
  }),
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {RequiredLabel} from './RequiredLabel';
import {TextVariant} from './TextVariant';

export const TextAreaLabel = ({
  label,
  value,
  onChange,
  maxLengthDescription = 500,
  height = 165,
  required = false,
  placeholder = '',
  disabled = false,
  ...props
}: {
  label: string;
  value: string;
  onChange: any;
  height?: number | string;
  maxLengthDescription?: number;
  name?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
}) => {
  return (
    <div
      style={{
        position: 'relative',
      }}>
      <RequiredLabel label={label} required={required} />
      <div
        css={[
          styles.textareaContainer,
          {height: height, opacity: disabled ? 0.5 : 1},
        ]}>
        <textarea
          disabled={disabled}
          css={[styles.textarea2]}
          maxLength={maxLengthDescription}
          value={value}
          onChange={onChange}
          name="slide_description"
          id="textarea"
          className="textSemiBold"
          placeholder={placeholder}
          {...props}
        />
        <TextVariant
          variant="title03"
          style={styles.textareaMaxLength}
          text={`${value?.length}/${maxLengthDescription}`}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    flex: 1,
    marginTop: 15,
    marginBottom: 10,
    height: '50%',
  }),
  required: css({
    color: THEME.colors.red,
    marginLeft: 5,
  }),
  textarea: {
    width: '100%',
    border: 'none',
    height: '100%',
    sizing: 'border-box',
    padding: 10,
    borderRadius: 2,
  },
  textareaContainer: css({
    position: 'relative',
    backgroundColor: THEME.colors.white,
  }),
  textarea2: css({
    position: 'relative',
    width: '100%',
    resize: 'none',
    border: 'none',
    borderRadius: 4,
    // height: 165,
    padding: 10,
    height: '100%',
    // marginTop: 10,
  }),
  textareaMaxLength: css({
    color: '#CCD6E0',
    position: 'absolute',
    bottom: 10,
    right: 15,
  }),
  placeholder: css`
    &::placeholder {
      font-size: 12px;
      font-weight: 600;
      color: ${THEME.colors.bgDark};
    }
  `,
};

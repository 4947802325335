/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {CONSUMPTION_MODE} from '@bill-app-types/b-types';
import {THEME} from '../../../styles/theme';
import {helperOrderConsumptionModeBadge} from '../../../helpers/helperOrderConsumptionModeBadge';

export const ConsumptionModeBadge = ({
  consumptionMode,
}: {
  consumptionMode: CONSUMPTION_MODE;
}) => {
  const badge = helperOrderConsumptionModeBadge(consumptionMode);

  return (
    <div css={[styles.card, {backgroundColor: badge.background}]}>
      {badge.Icon && <badge.Icon />}
    </div>
  );
};

const styles = {
  card: css({
    borderRadius: 2,
    background: THEME.colors.bgLight,
    marginRight: 5,
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {BanksAndAccountsSection} from './BanksAndAccountsSection';
import {Bottom} from './HeaderSections/Bottom';
import {Center} from './HeaderSections/Center';
import {PaymentsMethodsSection} from './PaymentsMethodsSection';
import {VendorStructuresSection} from './VendorStructuresSection';
import {useFlagBCatalog} from '../../../../hooks/useFlagBCatalog';
import {PARENT_DEFAULT_SCREEN} from '../../../../constants/global';
import {PaymentsMethodsSectionV2} from './PaymentsMethodsSectionV2';

export const Parameters = withRedirectAndNavigation(() => {
  //
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();
  const showSection = useAppSelector(s => s.app.sectionShow);

  if (
    showSection === 'VENDOR_STRUCTURES' &&
    !flagBCatalogEnabled &&
    flagBCatalogReady
  ) {
    window.location.href = PARENT_DEFAULT_SCREEN;
    return null;
  }

  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} bottomSection={<Bottom />} />
      {showSection === 'PAYMENT_METHODS' ? (
        flagBCatalogEnabled ? (
          <PaymentsMethodsSectionV2 />
        ) : (
          <PaymentsMethodsSection />
        )
      ) : null}
      {showSection === 'BANKS_AND_ACCCOUNTS' && !flagBCatalogEnabled && (
        <BanksAndAccountsSection />
      )}
      {flagBCatalogEnabled && showSection === 'VENDOR_STRUCTURES' && (
        <VendorStructuresSection />
      )}
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  form: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1% 0',
    flexWrap: 'wrap',
  }),
  section: {
    width: '49%',
  },
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ButtonCustom} from '../../../../../../components/atoms/ButtonCustom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useReduxHook';
import {useNavigate, useParams} from 'react-router-dom';
import {helperDisplayToast} from '../../../../../../helpers/helperToast';
import {updateCatalogModifier} from '../../../../../../api/fetchItems';
import {BRAND_AUTHORIZATION} from '../../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../../hooks/useBrandAccess';
import {
  IGetModifierDetailsResponse,
  OPTION_TYPES,
} from '@bill-app-types/b-types';
import {helperLabelTranslation} from '../../../../../../helpers/helperLabel';
import {useTranslation} from 'react-i18next';
import {updatedCategoryAction} from '../../../../../../redux/myCatalogSection/options';
import {THEME} from '../../../../../../styles/theme';

export const Center = ({
  categorySelected,
}: {
  categorySelected?: IGetModifierDetailsResponse;
}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const {brandHasAuthorization} = useBrandAccess();

  const data = useAppSelector(s => s.myCatalogSection.options.updatedCategory);

  const handleCancel = () => {
    const _getData = (obj: any) => ({
      label: obj?.label || helperLabelTranslation(obj),
      backofficeLabel: obj?.backofficeLabel,
      optionType: obj?.optionType,
      allowMultiple: obj?.allowMultiple,
      hideOnConsumptionMode: obj?.hideOnConsumptionMode,
      minimum: obj?.minimumValue ?? obj.minimum,
      maximum: obj?.maximumValue ?? obj.maximum,
    });

    const categorySelectedData = _getData(categorySelected);

    const updatedCategoryData = _getData(data);

    if (
      JSON.stringify(categorySelectedData) !==
      JSON.stringify(updatedCategoryData)
    ) {
      const res = window.confirm(t('YOU_HAVE_UNSAVED_CHANGES'));

      if (!res) {
        return;
      }
    }

    dispatch(updatedCategoryAction(null));
    navigate('/online/catalog/options');
  };

  const handleSaveOptionCategory = async () => {
    if (data?.label && data?.optionType) {
      if (params?.uuid) {
        const {minimumValue, maximumValue, ...rest} = data;
        const res = await updateCatalogModifier(params?.uuid, {
          ...rest,

          minimum: [OPTION_TYPES.SUPPLEMENT, OPTION_TYPES.WITHDRAWAL].includes(
            Number(data?.optionType),
          )
            ? 0
            : minimumValue,
          maximum: maximumValue,
        });
        if (res.status === 204) {
          helperDisplayToast("Catégorie d'option modifiée", true);
          navigate('/online/catalog/options');
        } else {
          helperDisplayToast(
            res?.data?.message ||
              "Impossible de modifier la catégorie d'option",
            false,
          );
        }
      }
    } else {
      helperDisplayToast('Tous les champs doivent être complétés ', false);
    }
  };

  return (
    <div css={styles.container} className="flex">
      <ButtonCustom
        label={t('CANCEL')}
        onClick={handleCancel}
        backgroundColor={THEME.colors.red}
      />
      <ButtonCustom
        label={"ENREGISTRER LA CATÉGORIE D'OPTIONS"}
        onClick={handleSaveOptionCategory}
        disabled={!brandHasAuthorization(BRAND_AUTHORIZATION.PARENT)}
      />
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
    marginTop: '3vh',
    marginBottom: '2vh',
    justifyContent: 'flex-end',
    gap: '0.7rem',
  }),
};

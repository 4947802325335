export const HexaExclamation = (props: {color?: string}) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.98242 2.516C5.26583 2.516 5.47939 2.72955 5.47939 2.995V5.23036C5.47939 5.49581 5.26583 5.70936 4.98242 5.70936C4.73494 5.70936 4.50342 5.49581 4.50342 5.23036V2.995C4.50342 2.72955 4.73494 2.516 4.98242 2.516ZM5.63906 6.98671C5.63906 7.33998 5.35365 7.62538 4.98242 7.62538C4.64712 7.62538 4.34375 7.33998 4.34375 6.98671C4.34375 6.63344 4.64712 6.34804 4.98242 6.34804C5.35365 6.34804 5.63906 6.63344 5.63906 6.98671ZM0.209535 4.32424L2.0565 1.28974C2.31596 0.861433 2.78299 0.599976 3.26599 0.599976H6.71682C7.21778 0.599976 7.68481 0.861433 7.94427 1.28974L9.79044 4.32424C10.0699 4.78329 10.0699 5.35809 9.79044 5.81714L7.94427 8.85084C7.68481 9.27995 7.21778 9.5414 6.71682 9.5414H3.26599C2.78299 9.5414 2.31596 9.27995 2.0565 8.85084L0.209535 5.81714C-0.0698449 5.35809 -0.0698449 4.78329 0.209535 4.32424ZM1.02784 4.82121C0.934629 4.97489 0.934629 5.16649 1.02784 5.32017L2.8748 8.35387C2.96062 8.49558 3.1163 8.58339 3.26599 8.58339H6.71682C6.88447 8.58339 7.04015 8.49558 7.12597 8.35387L8.97214 5.32017C9.06594 5.16649 9.06594 4.97489 8.97214 4.82121L7.12597 1.78791C7.04015 1.6452 6.88447 1.55799 6.71682 1.55799H3.26599C3.1163 1.55799 2.96062 1.6452 2.8748 1.78791L1.02784 4.82121Z"
        fill="#EC5174"
      />
    </svg>
  );
};

import {useContext} from 'react';
import {UtilsContext} from '../../../../../../contexts/UtilsContext';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {OrderByFilter} from '../../../../../../components/filters/OrderByFilter';
import {OptionTypeFilter} from '../../../../../../components/filters/OptionTypeFilter';
import {VisibilityFilter} from '../../../../../../components/filters/VIsibilityFilter';
import {SearchFilter} from '../../../../../../components/filters/SearchFilter';

export const Bottom = () => {
  const {t} = useContext<any>(UtilsContext);
  const showSection = useAppSelector(s => s.app.sectionShow);

  return (
    <>
      <SearchFilter marginRight={'1vh'} />
      {showSection === 'OPTION_CATEGORY' && (
        <>
          <OptionTypeFilter marginRight={'1vh'} />
          <OrderByFilter
            label={t('OPTIONS')}
            field="totalItems"
            marginRight={'1vh'}
          />
        </>
      )}
      {showSection !== 'OPTION_CATEGORY' && (
        <OrderByFilter label={t('PRICE')} field="price" marginRight={'1vh'} />
      )}
      <VisibilityFilter marginRight={'1vh'} />
      <OrderByFilter
        icon={'calendar'}
        label={t('DATE_ADDED')}
        field="createdAt"
        marginRight={'1vh'}
      />
      <OrderByFilter
        icon={'calendar'}
        label={t('MODIFICATION_DATE')}
        field="updatedAt"
      />
    </>
  );
};

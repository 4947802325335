import {SVGProps} from 'react';

export const CrossFilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{cursor: 'pointer'}}>
    <path
      d="M6 11A5 5 0 1 0 6 1a5 5 0 0 0 0 10Z"
      fill={props.color || '#EC5174'}
    />
    <path
      d="m7.5 7.5-3-3M7.5 4.5l-3 3"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

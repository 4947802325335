import {useQuery} from '@tanstack/react-query';
import {getExercices} from '../api/fetchCompta';
import {useAppSelector} from './useReduxHook';
import { IExercicesHistoryFetch } from '@bill-app-types/b-types';

export const useExercice = (offset: number, limit: number): {
  data: IExercicesHistoryFetch[],
  hasLocalCloudDifferentTotals: boolean,
  isLoading: boolean,
  error: any,
  totalExercices: number,
} => {
  const dates = useAppSelector(s => s.filter.dates);
  const {data, isLoading, error} = useQuery(
    ['getExercices', dates, offset, limit],
    () =>
      getExercices({
        ...dates,
        offset,
        limit,
      }),
  );

  return {
    data: data?.exercices || [],
    hasLocalCloudDifferentTotals: data?.hasLocalCloudDifferentTotals,
    isLoading,
    error,
    totalExercices: data?.totalExercices || 0,
  };
};

/** @jsxImportSource @emotion/react */
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {Title} from '../../atoms/Title';
import {ProductCard} from '../../atoms/ProductCard';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {getAdditionals} from '../../../api/fetchAdditional';
import {useAppSelector} from '../../../hooks/useReduxHook';
import {ISuggestedItem, SUGGESTION_TYPES} from '@bill-app-types/b-types';
import {helperDisplayToast} from '../../../helpers/helperToast';
import {
  createItemAdditionalSale,
  createItemDetailsAdditionalSale,
} from '../../../api/fetchItems';
import {SearchFilter} from '../../filters/SearchFilter';
import {useDebounce} from '../../../hooks/useDebounce';

export const AdditionalSalesCatalog = ({itemUuid}: {itemUuid: string}) => {
  const {t} = useTranslation();
  const clientQuery = useQueryClient();

  const search = useAppSelector(s => s.filter.search);

  const debouncedSearchTerm = useDebounce(search, 500);

  const {data} = useQuery(['getAdditionals', debouncedSearchTerm], () =>
    getAdditionals({search: debouncedSearchTerm}),
  );

  const selectedAdditionalType = useAppSelector(
    s => s.myCatalogSection.products.selectedAdditionalType,
  );

  const selectedAdditionalList = useAppSelector(
    s => s.myCatalogSection.products.selectedAdditionalList,
  );

  const handleClickAddAdditional = async (additional: ISuggestedItem) => {
    if (!selectedAdditionalType) {
      return helperDisplayToast('Aucun type sélectionné');
    }
    const additionalFinded = selectedAdditionalList?.find(
      p => p.uuid === additional.uuid,
    );

    if (!additionalFinded) {
      if (selectedAdditionalType === SUGGESTION_TYPES.PRODUCT_DETAIL) {
        const res = await createItemDetailsAdditionalSale(itemUuid, {
          suggestionCategoryUuid: additional.uuid,
          position: 1,
        });
        if (res?.status === 204) {
          helperDisplayToast(
            res?.data?.message || 'Vente additionnelle ajoutée',
            true,
          );
          clientQuery.invalidateQueries(['getItemDetailsAdditionalSales']);
        } else {
          helperDisplayToast(
            res?.data?.message || "Impossible d'ajouter la vente additionnelle",
            false,
          );
        }
      } else if (selectedAdditionalType === SUGGESTION_TYPES.ADDITIONAL_SALE) {
        const res = await createItemAdditionalSale(itemUuid, {
          suggestionCategoryUuid: additional.uuid,
          position: 1,
        });
        if (res?.status === 204) {
          helperDisplayToast(
            res?.data?.message || 'Vente additionnelle ajoutée',
            true,
          );
          clientQuery.invalidateQueries(['getItemAdditionalSales']);
        } else {
          helperDisplayToast(
            res?.data?.message || "Impossible d'ajouter la vente additionnelle",
            false,
          );
        }
      }
    }
  };

  const checkIfIsDisabled = (additional: any) =>
    !!selectedAdditionalList?.find(
      (item: any) => item?.uuid === additional?.uuid,
    ) || false;

  return (
    <div>
      <Title title={t('ADDITIONAL_SALES_CATALOG')} />

      <div css={styles.content}>
        <SearchFilter />
        <div css={styles.list}>
          {data?.map((additional: any, index: number) => {
            const isDisabled = checkIfIsDisabled(additional);
            return (
              <ProductCard
                key={index}
                item={additional}
                handleOnClick={() => handleClickAddAdditional(additional)}
                showPrice={false}
                showAdd
                imageObjectFit="contain"
                specificImage="/images/cart.svg"
                disabled={isDisabled}
                noTruncImage
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const styles = {
  content: css({
    paddingTop: 10,
  }),
  list: css({
    display: 'grid',
    padding: '1.5rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px,1fr))',
    gap: '1rem',
    height: '70vh',
    overflow: 'auto',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext} from 'react';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {UtilsContext} from '../../contexts/UtilsContext';
import {addAdditionalCategoryModalAction} from '../../redux/modals';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {useAppSelector} from '../../hooks/useReduxHook';
import {useQueryClient} from '@tanstack/react-query';
import {
  createSuggestionCategory,
  updateSuggestionCategory,
} from '../../api/fetchSuggestion';
import {helperLabelTranslation} from '../../helpers/helperLabel';
import {updatedCategoryAction} from '../../redux/myOffersSection/categories';
import {helperDisplayToast} from '../../helpers/helperToast';

interface IData {
  uuid?: string;
  label: string;
  backofficeLabel: string;
  showStaff: boolean;
  isArchived: boolean;
  languageId: number;
}

export const AddAdditionalCategory = () => {
  const {t, dispatch} = useContext<any>(UtilsContext);

  const clientQuery = useQueryClient();

  const updatedCategory = useAppSelector(
    s => s.myOffersSection.categories.updatedCategory,
  );

  const initialValues = {
    uuid: updatedCategory?.uuid || '',
    label:
      helperLabelTranslation({...updatedCategory, backofficeLabel: null}) || '',
    backofficeLabel: updatedCategory?.backofficeLabel || '',
    showStaff: updatedCategory?.showStaff || true,
    isArchived: updatedCategory?.isArchived || true,
    languageId: 1,
  };

  /* ON CANCEL */
  const handleOnCancel = () => {
    dispatch(updatedCategoryAction(null));

    dispatch(addAdditionalCategoryModalAction(false));
  };

  /* HANDLE SUBMIT */
  const handleOnSubmit = async (data: IData) => {
    try {
      let res;
      if (data.uuid) {
        const {uuid, ...rest} = data;
        res = await updateSuggestionCategory(data.uuid, rest);
      } else {
        res = await createSuggestionCategory(data);
      }

      if ([200, 204].includes(res.status)) {
        await clientQuery.invalidateQueries(['fetchSuggestionCategories']);
        helperDisplayToast('Vente additionnelle enregistrée', true);
      } else {
        helperDisplayToast('Action impossible', false);
      }

      handleOnCancel();
    } catch (error) {
      helperDisplayToast('Une erreur est survenue ', false);
    }
  };

  return (
    <ModalWrapper
      title={'Categorie de vente additionnelle'}
      validateLabel={'Enregistrer la catégorie'}
      cancelLabel={t('CANCEL')}
      onCancel={handleOnCancel}
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      minWidth={'450px'}>
      <div css={styles.container}>
        <FieldCustomInput label={t('CATEGORY_NAME')} name="label" required />
        <FieldCustomInput
          label={t('BACKOFFICE_DISPLAY_NAME')}
          name="backofficeLabel"
          required
        />

        <FieldsRequired />
      </div>
    </ModalWrapper>
  );
};

const styles = {
  container: css({paddingTop: '2rem'}),
};

export const BTrackIcon = ({
  size,
  isDisabled = false,
  isActive = false,
}: {
  size: string;
  isDisabled: boolean;
  isActive?: boolean;
}) => (
  <svg
    width={size === 'small' ? '41' : '64'}
    height={size === 'small' ? '12' : '20'}
    viewBox="0 0 64 20"
    fill="none">
    <path
      d="M7.52532 5.57967C6.64195 5.57967 5.79607 5.73738 5.01416 6.02301L6.78311 9.33594C7.02243 9.28411 7.27057 9.25543 7.52643 9.25543C9.46632 9.25543 11.0401 10.8027 11.0401 12.7117C11.0401 14.6207 9.46742 16.168 7.52643 16.168C5.58543 16.168 4.10652 14.7057 4.02161 12.8705L4.01389 12.8794V6.47297C4.01389 6.47297 4.00837 6.47628 4.00506 6.47738L4.01389 0.5H0.276367V19.8427H3.98411V18.9339C5.03291 19.5118 6.23941 19.8427 7.52532 19.8427C11.5286 19.8427 14.7743 16.65 14.7743 12.7106C14.7743 8.77129 11.5286 5.57967 7.52532 5.57967Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#151A30' : '#3C6AF6'}
    />
    <path
      d="M20.7047 16.2817C20.7047 16.5838 20.8128 16.8386 21.0289 17.0481C21.2451 17.2577 21.5043 17.3624 21.8053 17.3624H23.7121V19.6431H21.8053C21.2936 19.6431 20.8128 19.5483 20.3595 19.3575C19.9074 19.1678 19.5147 18.9053 19.1806 18.5711C18.8464 18.237 18.5817 17.8466 18.3843 17.401C18.188 16.9555 18.0899 16.4769 18.0899 15.9662V13.0172H16.6738V10.8347H18.0899V7.27698H20.7047V16.2817ZM23.7132 10.8358V13.0184H22.2586L21.04 10.8358H23.7132Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M32.7148 10.2036L32.4788 10.5973L31.4763 12.3861L31.4565 12.3662C31.0241 12.117 30.5587 11.9935 30.0614 11.9935C29.7195 11.9935 29.3986 12.0486 29.0975 12.16C28.7953 12.2714 28.5273 12.4291 28.2913 12.632C28.0553 12.836 27.8557 13.0754 27.6925 13.35C27.5281 13.6246 27.4201 13.9201 27.3682 14.2344V19.6417H24.6938V9.81096H27.3682V11.0892C28.0619 10.133 29.0787 9.65436 30.4154 9.65436C31.2017 9.65436 31.9616 9.83853 32.696 10.2047H32.7148V10.2036Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M42.588 9.81225V19.643H40.0129V14.7155C40.0129 14.3339 39.9412 13.9799 39.7967 13.6501C39.6523 13.3215 39.4526 13.0325 39.1968 12.7833C38.9409 12.533 38.6465 12.34 38.3123 12.2021C37.9781 12.0631 37.6274 11.9948 37.2602 11.9948C36.893 11.9948 36.5257 12.0665 36.1993 12.2109C35.8717 12.3554 35.5795 12.5495 35.3247 12.7932C35.0689 13.037 34.8692 13.3259 34.7248 13.6612C34.5803 13.9975 34.5086 14.3548 34.5086 14.7364C34.5086 15.118 34.5803 15.4533 34.7248 15.783C34.8692 16.1117 35.0689 16.4006 35.3247 16.651C35.5795 16.9002 35.8717 17.0976 36.1993 17.2432C36.5268 17.3877 36.8797 17.4593 37.2602 17.4593C37.666 17.4593 38.0134 17.3877 38.3024 17.2432H38.3211V17.2818L39.3049 19.032L39.285 19.0518C38.6421 19.5492 37.8502 19.7985 36.9062 19.7985C36.2114 19.7985 35.5563 19.6672 34.9398 19.4058C34.3233 19.1434 33.7863 18.7827 33.3275 18.324C32.8676 17.8652 32.5048 17.3281 32.2357 16.7116C31.9666 16.0962 31.832 15.44 31.832 14.7453C31.832 14.0505 31.9666 13.3755 32.2357 12.759C32.5048 12.1437 32.8676 11.6055 33.3275 11.1467C33.7852 10.6879 34.3233 10.324 34.9398 10.0549C35.5552 9.78689 36.2103 9.65234 36.9062 9.65234C37.6021 9.65234 38.1833 9.77917 38.695 10.0317C39.2056 10.2854 39.6456 10.6526 40.0118 11.1335V9.80895H42.5869L42.588 9.81225Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M50.7875 15.8887L52.9116 17.2838L52.8917 17.3036C52.4329 18.09 51.8143 18.7064 51.0345 19.152C50.2537 19.5975 49.4123 19.8203 48.5079 19.8203C47.7999 19.8203 47.1382 19.6858 46.5228 19.4167C45.9063 19.1487 45.3693 18.7847 44.9105 18.326C44.4506 17.8672 44.0878 17.3301 43.8187 16.7136C43.5496 16.0982 43.415 15.442 43.415 14.7473C43.415 14.0525 43.5496 13.3974 43.8187 12.7809C44.0878 12.1655 44.4506 11.6284 44.9105 11.1685C45.3682 10.7109 45.9063 10.3469 46.5228 10.0778C47.1382 9.80984 47.7999 9.67419 48.5079 9.67419C49.4123 9.67419 50.2548 9.89807 51.0345 10.3425C51.8143 10.7881 52.4329 11.4034 52.8917 12.1909L52.9116 12.2107L50.7688 13.6069L50.7489 13.5871C50.5394 13.1018 50.235 12.7092 49.8347 12.4081C49.4354 12.106 48.9921 11.956 48.5068 11.956C48.1528 11.956 47.822 12.031 47.5143 12.182C47.2055 12.3331 46.9408 12.5361 46.718 12.7919C46.4953 13.0478 46.3188 13.3422 46.1876 13.6764C46.0563 14.0106 45.9913 14.3679 45.9913 14.7484C45.9913 15.1288 46.0574 15.4817 46.1876 15.8093C46.3177 16.1368 46.4953 16.4225 46.718 16.664C46.9408 16.9066 47.2066 17.0963 47.5143 17.2342C47.822 17.3709 48.1528 17.4404 48.5068 17.4404C48.9921 17.4404 49.4343 17.3058 49.8347 17.0367C50.235 16.7677 50.5394 16.3916 50.7489 15.9063L50.7688 15.8677L50.7875 15.8876V15.8887Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M63.7238 9.81187C62.4919 11.0316 61.3714 12.1455 60.3612 13.1535L56.3116 17.2042V19.6415H53.6968V5.88025H56.3116V13.803L60.4792 9.81187H63.7238ZM58.986 15.0217C59.5892 15.6249 60.0767 16.1102 60.4506 16.4763C60.8244 16.8436 61.1288 17.1446 61.3648 17.3806C61.6008 17.6167 61.7883 17.803 61.9251 17.9409C62.0629 18.0787 62.203 18.2155 62.3485 18.3533C62.493 18.4912 62.6595 18.6577 62.8503 18.8551C63.04 19.0514 63.3058 19.3139 63.6466 19.6415H60.4219L60.402 19.6216L58.2791 17.4986L58.9871 15.0217H58.986Z"
      fill={isDisabled ? '#CCD6E0' : '#151A30'}
    />
  </svg>
);

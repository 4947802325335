/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {helperDate} from '../../../../helpers/helperDate';
import {CloseIcon} from '../../../../assets/svgs/CloseIcon';
import {selectedReceiptAction} from '../../../../redux/receipt';
import {useAppDispatch} from '../../../../hooks/useReduxHook';
import {TextVariant} from '../../../../components/atoms/TextVariant';

export const TicketHeader = ({receiptDetails}: {receiptDetails: any}) => {
  const dispatch = useAppDispatch();

  return (
    <div css={styles.header}>
      <div css={styles.headerContent}>
        <TextVariant
          variant="textSemiBold"
          text={`Ticket ${receiptDetails?.posReceiptId}`}
        />
        <p
          css={styles.headerContentClose}
          id="ticketHeaderClose"
          onClick={() => dispatch(selectedReceiptAction(null))}
          className="flex">
          <CloseIcon />
        </p>
      </div>
      <div className="flex-col flex-align-center">
        <TextVariant
          variant="title02"
          text={receiptDetails?.header?.clientName}
          style={styles.brand}
        />

        <TextVariant
          variant="textRegular"
          text={`${receiptDetails?.header?.address} ${receiptDetails?.header?.zipCode}`}
        />
        <TextVariant
          variant="textRegular"
          text={`${receiptDetails?.header?.city} ${receiptDetails?.header?.country}`}
        />
        <TextVariant
          variant="textRegular"
          text={`${receiptDetails?.header?.siret}`}
        />
        <TextVariant
          variant="textRegular"
          text={`${receiptDetails?.header?.tvaCode}`}
        />

        <TextVariant
          variant="textRegular"
          text={`Code NAF/APE : ${receiptDetails?.header?.nodeNafApe}`}
        />

        <TextVariant
          variant="title03"
          text={helperDate(receiptDetails?.createdAt)}
          style={{
            margin: '5px 0',
          }}
        />

        <TextVariant
          variant="textRegular"
          text={
            receiptDetails?.consumptionMode === 1 ? 'Sur place' : 'A emporter'
          }
          style={{
            textTransform: 'uppercase',
          }}
        />

        <TextVariant
          variant="textRegular"
          text={`Commande n° ${receiptDetails?.orderId}`}
        />
        <TextVariant
          variant="textRegular"
          text={`Ticket: ${receiptDetails?.posReceiptId}`}
        />
      </div>
    </div>
  );
};

const styles = {
  header: css({
    padding: 10,
    height: '35%',
    zIndex: 10,
    overflow: 'hidden',
  }),
  title: css({
    fontSize: 12,
    fontWeight: '600',
  }),
  brand: css({
    marginTop: '2%',
  }),
  infos: css({
    fontSize: 12,
    fontWeight: '500',
    display: 'block',
    textAlign: 'center',
  }),

  subTitle: css({
    paddingLeft: 10,
    marginBottom: 5,
  }),

  headerContent: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.2rem',
  }),

  headerContentClose: css({
    cursor: 'pointer',
    '@media print': {
      display: 'none',
    },
  }),
};

import {useQuery} from '@tanstack/react-query';
import {getPaymentMethods} from '../api/fetchBrandAccount';

export const usePaymentMethods = () => {
  const {
    data: paymentMethods,
    isLoading,
    refetch,
  } = useQuery(['getPaymentMethods'], getPaymentMethods);

  return {
    paymentMethods,
    refetch,
    isLoading,
  };
};

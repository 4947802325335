import { IFetchExternalConnectionsResponse } from '@bill-app-types/b-types';
import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  selected: IFetchExternalConnectionsResponse['data'][0] | null
  updateMode: boolean;
}

const initialState: ISlice = {
  selected: null,
  updateMode: false,
};

const externalConnectionsSlice = createSlice({
  name: 'externalConnections',
  initialState: initialState,
  reducers: {
    externalConnectionSetSelectedAction: (state, action) => {
      state.selected = action.payload;
    },
    externalConnectionsUpdateMode: (state, action) => {
      state.updateMode = action.payload;
    }
  },
});

export const {
  externalConnectionSetSelectedAction,
  externalConnectionsUpdateMode
} =
  externalConnectionsSlice.actions;

export default externalConnectionsSlice.reducer;

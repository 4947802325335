import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';

export const fetchCategoryOptions = async (data: {
  limit?: number;
  offset?: number;
  archived?: number;
}) => {
  const q = helperGenerateQuery(data);
  const res = await axiosInstance.get(`bcore/protected/admin/option${q}`);

  return res?.data?.data;
};

import {SVGProps} from 'react';
import { THEME } from '../../styles/theme';

export const EtcIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="6"
    viewBox="0 0 8 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.38422 2.08C1.11755 2.08 0.887552 1.99 0.694219 1.81C0.507552 1.62333 0.414219 1.38667 0.414219 1.1C0.414219 0.813333 0.507552 0.583333 0.694219 0.41C0.887552 0.23 1.11755 0.14 1.38422 0.14C1.65755 0.14 1.88755 0.23 2.07422 0.41C2.26089 0.583333 2.35422 0.813333 2.35422 1.1C2.35422 1.38667 2.26089 1.62333 2.07422 1.81C1.88755 1.99 1.65755 2.08 1.38422 2.08ZM4.00141 2.08C3.73474 2.08 3.50474 1.99 3.31141 1.81C3.12474 1.62333 3.03141 1.38667 3.03141 1.1C3.03141 0.813333 3.12474 0.583333 3.31141 0.41C3.50474 0.23 3.73474 0.14 4.00141 0.14C4.27474 0.14 4.50474 0.23 4.69141 0.41C4.87807 0.583333 4.97141 0.813333 4.97141 1.1C4.97141 1.38667 4.87807 1.62333 4.69141 1.81C4.50474 1.99 4.27474 2.08 4.00141 2.08ZM6.61859 2.08C6.35193 2.08 6.12193 1.99 5.92859 1.81C5.74193 1.62333 5.64859 1.38667 5.64859 1.1C5.64859 0.813333 5.74193 0.583333 5.92859 0.41C6.12193 0.23 6.35193 0.14 6.61859 0.14C6.89193 0.14 7.12193 0.23 7.30859 0.41C7.49526 0.583333 7.58859 0.813333 7.58859 1.1C7.58859 1.38667 7.49526 1.62333 7.30859 1.81C7.12193 1.99 6.89193 2.08 6.61859 2.08Z"
      fill={THEME.colors.gray}
    />
  </svg>
);

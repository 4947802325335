import {SVGProps} from 'react';

export const CrossIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}>
    <path
      fill={props.color || '#fff'}
      d="M28 14.064c0 .875-.72 1.58-1.615 1.58h-10.77v10.531c0 .872-.723 1.58-1.615 1.58s-1.615-.704-1.615-1.58V15.644H1.615c-.891 0-1.615-.707-1.615-1.58 0-.87.724-1.58 1.615-1.58h10.77V1.952c0-.872.723-1.579 1.615-1.579s1.615.707 1.615 1.58v10.531h10.77c.895 0 1.615.71 1.615 1.58Z"
    />
  </svg>
);

export const CrossMediumIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1729 0.81386C13.5914 0.39538 14.271 0.39538 14.6895 0.81386C15.108 1.23234 15.108 1.91195 14.6895 2.33043L9.01825 7.99833L14.6861 13.6696C15.1046 14.088 15.1046 14.7677 14.6861 15.1861C14.2677 15.6046 13.588 15.6046 13.1696 15.1861L7.50167 9.5149L1.83043 15.1828C1.41195 15.6013 0.73234 15.6013 0.31386 15.1828C-0.10462 14.7643 -0.10462 14.0847 0.31386 13.6662L5.9851 7.99833L0.317208 2.32708C-0.101272 1.9086 -0.101272 1.23234 0.317208 0.81386C0.735688 0.39538 1.4153 0.39538 1.83378 0.81386L7.50167 6.48175L13.1729 0.81386Z"
      fill={props.color || '#151A30'}
    />
  </svg>
);

export const SmallCrossIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9584 0.276563C12.3143 0.0015622 12.8922 0.0015622 13.2481 0.276563C13.604 0.551565 13.604 0.998167 13.2481 1.27317L8.42516 4.99778L13.2452 8.7246C13.6011 8.9996 13.6011 9.4462 13.2452 9.72121C12.8894 9.99621 12.3114 9.99621 11.9555 9.72121L7.13543 5.99439L2.3125 9.71901C1.95661 9.99401 1.37866 9.99401 1.02277 9.71901C0.666888 9.444 0.666888 8.9974 1.02277 8.7224L5.84571 4.99778L1.02562 1.27097C0.669735 0.995966 0.669735 0.551565 1.02562 0.276563C1.3815 0.0015622 1.95946 0.0015622 2.31534 0.276563L7.13543 4.00118L11.9584 0.276563Z"
      fill="#EC5174"
    />
  </svg>
);

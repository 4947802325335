/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {LogOutIcon} from '../../../../assets/svgs/LogOutIcon';
import {MoonIcon} from '../../../../assets/svgs/MoonIcon';
import {ReloadIcon} from '../../../../assets/svgs/ReloadIcon';
import {SunIcon} from '../../../../assets/svgs/SunIcon';
import {UtilsContext} from '../../../../contexts/UtilsContext';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {currentThemeAction} from '../../../../redux/app';
import {logoutAction} from '../../../../redux/auth';
import {brandAction} from '../../../../redux/brand';

export const SideBarFooter = () => {
  const isOpen = useAppSelector(s => s.sidebar.isOpen);
  const auth = useAppSelector(s => s.auth.auth);
  const brand = useAppSelector(s => s.brand.brand);
  const currentTheme = useAppSelector(s => s.app.currentTheme);

  const {dispatch} = useContext<any>(UtilsContext);

  const navigate = useNavigate();

  const handleToggleTheme = useCallback(
    (theme: string) => {
      dispatch(currentThemeAction(theme));
      localStorage.setItem('theme', theme);
      document.documentElement.setAttribute('data-theme', theme);
    },
    [dispatch],
  );

  const handleLogout = () => {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('auth_refresh_token');
    localStorage.clear();
    dispatch(logoutAction());
    dispatch(brandAction(null));
    navigate('/');
  };

  return (
    <div css={styles.navBarFooter}>
      {isOpen && (
        <div css={styles.userInfo}>
          {/* <img
            src={auth?.profile_pic}
            alt="user profil"
            css={styles.userImg}
          /> */}
          <p css={styles.username}>{`${auth?.firstname} ${auth?.lastname}`}</p>
          <p css={styles.userEmail}>{brand?.email}</p>
        </div>
      )}
      <div css={styles.navBarFooterIcons(isOpen)}>
        <button css={styles.btn}>
          <ReloadIcon />
        </button>
        <button css={styles.btn} onClick={handleLogout}>
          <LogOutIcon />
        </button>
        {currentTheme === 'light' ? (
          <button css={styles.btn} onClick={() => handleToggleTheme('dark')}>
            <MoonIcon />
          </button>
        ) : (
          <button css={styles.btn} onClick={() => handleToggleTheme('light')}>
            <SunIcon />
          </button>
        )}
      </div>
    </div>
  );
};

const styles = {
  navBarFooter: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '150px',
  }),

  userInfo: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'var(--sidebar-color)',
    marginTop: '3px',
  }),

  username: css({
    fontSize: '11px',
    fontWeight: '600',
  }),

  userImg: css({
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    marginBottom: '5px',
    objectFit: 'cover',
  }),

  userEmail: css({
    fontSize: '8px',
    fontWeight: '500',
  }),

  btn: css({
    cursor: 'pointer',
  }),

  navBarFooterIcons: (isOpen: boolean) =>
    css(`
        display: flex;
        flex-direction: ${isOpen ? 'flex' : 'column'};
    
        button {
          border: none;
          background-color: transparent;
          margin-right: ${isOpen ? '15' : '0'}px;
          margin-bottom: ${isOpen ? '0' : '10'}px;
          justify-content: center;
          align-items: center;
    
          &:last-child {
            margin-right: 0;
          }
        }
      `),
};

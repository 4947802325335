import {
  helperFindItemPillColorByType,
  helperFindItemPillLabelByType,
} from '../../helpers/helperFindItemPillByType';
import {ProductType} from '../atoms/ProductType';

export const ProductTypeLabel = ({type}: {type: number}) => {
  return (
    <div className="flex">
      <ProductType type={type} />

      <span
        className="title03"
        style={{
          color: helperFindItemPillColorByType(type),
          marginLeft: '0.5rem',
        }}>
        {helperFindItemPillLabelByType(type)}
      </span>
    </div>
  );
};

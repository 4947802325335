import {PayloadAction, createSlice} from '@reduxjs/toolkit';

interface ISlice {
  isOpen: boolean;
  sidebarDropdownOpened: string | null;
}

const initialState: ISlice = {
  isOpen: false,
  sidebarDropdownOpened: null,
};

const slice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    isOpenAction(state, action: PayloadAction<ISlice['isOpen']>) {
      state.isOpen = action.payload;
    },
    sidebarDropdownOpenedAction(
      state,
      action: PayloadAction<ISlice['sidebarDropdownOpened']>,
    ) {
      state.sidebarDropdownOpened = action.payload;
    },
  },
});

export const {isOpenAction, sidebarDropdownOpenedAction} = slice.actions;

export default slice.reducer;

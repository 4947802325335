/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {Center, Bottom} from './HeaderSections';
import {RecipesList} from './RecipesList';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {useDebounce} from '../../../../hooks/useDebounce';
import {useQuery} from '@tanstack/react-query';
import {fetchRecipes} from '../../../../api/fetchRecipes';
import {DatagridPagination} from '../../../../components/newDatagrid/DatagridPagination';
import {useEffect, useState} from 'react';

export const Recipes = withRedirectAndNavigation(() => {
  const search = useAppSelector(s => s.filter.search);
  const orderBy = useAppSelector(s => s.filter.orderBy);
  const isArchived = useAppSelector(s => s.filter.isArchived);

  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [limit] = useState(60);

  const offset = (page - 1) * limit;

  const debouncedSearchTerm = useDebounce(search, 500);

  const {data, isLoading} = useQuery(
    ['fetchRecipes', debouncedSearchTerm, orderBy, isArchived, limit, offset],
    () =>
      fetchRecipes({
        search: debouncedSearchTerm,
        orderBy,
        isArchived: isArchived === null ? undefined : isArchived,
        limit,
        offset,
      }),
  );

  useEffect(() => {
    setRowCount(oldRowCount =>
      data?.totalRecipes !== undefined ? data?.totalRecipes : oldRowCount,
    );
  }, [data?.totalRecipes]);

  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} bottomSection={<Bottom />} />
      <div css={styles.content}>
        <RecipesList data={data?.recipes || []} isLoading={isLoading} />
      </div>
      <DatagridPagination
        page={page}
        totalRows={rowCount}
        rowPerPage={limit}
        setPage={setPage}
        isLoading={isLoading}
        paginationShowMore={false}
      />
    </div>
  );
});

const styles = {
  container: css({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '2vh',
    paddingBottom: '2vh',
  }),
  content: css({
    padding: '3vh 2vh',
    overflow: 'auto',
    height: '100%',
  }),
};

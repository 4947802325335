/* @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Header} from './Header';
import {useState} from 'react';
import {CalendarChart} from './CalendarChart';
import {BarChart} from './BarChart';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {useStatHourlyByDOW} from '../../../../../../hooks/useStatHourlyByDOW';
import {helperCombineDataDOW} from '../../../../../../helpers/helperCombineDataDOW';

export const SalesByHours = () => {
  const [tabs, setTabs] = useState<'graph' | 'columns'>('columns');

  const comparesDates = useAppSelector(s => s.filter.compareDates);
  const isCompare = useAppSelector(s => s.filter.isCompare);

  const {data} = useStatHourlyByDOW();

  const {data: compareData} = useStatHourlyByDOW({
    isEnabled: !!isCompare,
    dates: comparesDates,
  });

  const weekdays =
    data && compareData
      ? isCompare
        ? helperCombineDataDOW(data, compareData)?.weekdays
        : data?.weekdays || []
      : data?.weekdays || [];

  const _data = data
    ? {
        ...data,
        weekdays,
      }
    : undefined;

  return (
    <div css={styles.container}>
      <Header tabs={tabs} setTabs={setTabs} data={_data} />
      {tabs === 'graph' ? (
        <BarChart weekdays={weekdays} />
      ) : (
        <CalendarChart data={_data} />
      )}
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
    backgroundColor: 'white',
    padding: '2vh',
    overflow: 'hidden',
    marginTop: '2rem',
  }),
};

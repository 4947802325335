/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {OptionCard} from './OptionCard';
import {IFetchItemsReturnItem} from '@bill-app-types/b-types';

export const OptionsList = ({data}: {data: IFetchItemsReturnItem[]}) => {
  return (
    <>
      {data?.length === 0 ? (
        <div className="flex-center textRegular" style={{padding: '2vh 0'}}>
          Aucune donnée
        </div>
      ) : (
        <>
          <div css={styles.list}>
            {data?.map(option => (
              <OptionCard key={option.uuid} item={option} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

const styles = {
  list: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: '10px',
    padding: '10px',
    overflowX: 'hidden',
  }),
};

export const CheckIcon = () => {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.82915 0.495812C10.057 0.713951 10.057 1.06669 9.82915 1.26394L3.933 7.39041C3.74316 7.62711 3.40369 7.62711 3.19375 7.39041L0.156985 4.23435C-0.0523283 4.0371 -0.0523283 3.68436 0.156985 3.46622C0.366276 3.2504 0.705751 3.2504 0.91502 3.46622L3.57342 6.23009L9.08989 0.495812C9.29983 0.279065 9.63931 0.279065 9.82915 0.495812Z"
        fill="white"
      />
    </svg>
  );
};

export const GLOBAL_FRONT_ACCESSES = [
  {
    brandType: 'PARENT',
    accesses: [
      {
        code: 'STATS',
        access: 0,
      },
      {
        code: 'BRAND',
        access: 3,
      },
      {
        code: 'CUSTOMER',
        access: 2,
      },
      {
        code: 'CATALOG',
        access: 3,
      },
      {
        code: 'AREA',
        access: 0,
      },
    ],
  },
  {
    brandType: 'CHILD',
    accesses: [
      {
        code: 'STATS',
        access: 3,
      },
      {
        code: 'BRAND',
        access: 0,
      },
      {
        code: 'CUSTOMER',
        access: 2,
      },
      {
        code: 'CATALOG',
        access: 1,
      },
      {
        code: 'AREA',
        access: 4,
      },
    ],
  },
];

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Title} from '../../../../../../components/atoms/Title';
import {InputLabel} from '../../../../../../components/atoms/InputLabel';
import {FieldsRequired} from '../../../../../../components/atoms/FieldsRequired';
import {SelectMultiList} from '../../../../../../components/molecules/SelectMultiList';
import {useTranslation} from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useReduxHook';
import {menuPreRegisterChangeAction} from '../../../../../../redux/myCatalogSection/menus';
import {helperFormatSelectItem} from '../../../../../../helpers/helperFormatSelectItem';
import {createCardItem, deleteCardItem} from '../../../../../../api/fetchCards';
import {helperDisplayToast} from '../../../../../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {useItemCard} from '../../../../../../hooks/useItemCard';
import {ICardItem} from '@bill-app-types/b-types';

import {useBrandAccess} from '../../../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../../../constants/frontPolicies';

export const Classification = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();

  const {brandHasAuthorization} = useBrandAccess();

  const menuPreRegister = useAppSelector(
    s => s.myCatalogSection.menus.menuPreRegister,
  );

  const {data: itemCards} = useItemCard(menuPreRegister?.uuid);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;

    dispatch(
      menuPreRegisterChangeAction({
        name,
        value,
      }),
    );
  };

  const handleClickCard = async (item: any) => {
    const isCardItemExist = itemCards?.find(
      (card: ICardItem) => card.uuid === item.target.value,
    )?.active;
    const res = isCardItemExist
      ? await deleteCardItem(item.target.value, menuPreRegister?.uuid)
      : await createCardItem({
          cardUuid: item.target.value,
          itemUuid: menuPreRegister?.uuid,
        });

    const successMessage =
      res?.status === 204
        ? res?.data?.message || 'Cartes modifiées'
        : 'Impossible de modifier les cartes';
    const isSuccess = res?.status === 204;

    helperDisplayToast(successMessage, isSuccess);
    clientQuery.invalidateQueries(['getItemCards']);
  };

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <div css={styles.container}>
      <Title title="Classification" />
      <div css={styles.content}>
        <div css={styles.section}>
          <SelectMultiList
            label={t('MENU_CARD')}
            onChange={handleClickCard}
            list={itemCards?.map(helperFormatSelectItem)}
            listChecked={itemCards
              ?.filter((card: any) => card.active)
              ?.map(helperFormatSelectItem)}
            disabled={!hasAuthorization}
          />
        </div>

        <div css={styles.section}>
          <InputLabel
            label={t('BACKOFFICE_DISPLAY_NAME')}
            required
            value={menuPreRegister?.backofficeLabel}
            onChange={handleChange}
            name="backofficeLabel"
            disabled={!hasAuthorization}
          />
          <InputLabel
            label={t('PRODUCTION_DISPLAY_NAME')}
            required
            value={menuPreRegister?.productionLabel}
            onChange={handleChange}
            name="productionLabel"
            disabled={!hasAuthorization}
          />
          <FieldsRequired />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    marginTop: '1rem',
  }),
  content: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  section: css({
    width: '49%',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {Bottom} from './HeaderSections/Bottom';
import {Center} from './HeaderSections/Center';
import {SectionTva} from './SectionTva';
import {SectionPaymentMode} from './SectionPaymentMode';
// import {SectionGapByPosTerminal} from './SectionGapByPosTerminal';
// import {useFlagBCatalog} from '../../../../hooks/useFlagBCatalog';
import {SectionGapByPosTerminalOld} from './SectionGapByPosTerminalOld';

export const Sales = withRedirectAndNavigation(() => {
  //  const {flagBCatalogEnabled} = useFlagBCatalog();

  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} bottomSection={<Bottom />} />
      <div css={styles.content}>
        <div css={styles.section}>
          <SectionTva />
          {/* {flagBCatalogEnabled ? (
            <SectionGapByPosTerminal />
          ) : ( */}
          <SectionGapByPosTerminalOld />
          {/* )} */}
        </div>
        <div css={styles.section}>
          <SectionPaymentMode />
        </div>
      </div>
    </div>
  );
});

const styles = {
  container: css({
    height: '100vh',
    '@media (max-width: 1024px)': {
      height: 'auto',
    },
  }),
  content: css({
    padding: '2vh',
    display: 'flex',
    height: '76.6%',
    justifyContent: 'space-between',
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
      height: 'auto',
      padding: '1vh',
    },
  }),
  section: css({
    width: '49%',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  }),
};

/* @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../../../../../styles/theme';
import {TendanceCompareValues} from '../../../../../../components/molecules/TendanceCompareValues';
import {helperFormatPriceRoundToString} from '../../../../../../helpers/helperFormatPrice';
import {helperHourItemColor} from '../../../../../../helpers/helperSalesByHourColor';
import {daysList} from '../data';
import {TextVariant} from '../../../../../../components/atoms/TextVariant';
import {IStatHourlyByDOW} from '../../../../../../api/fetchStats';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {helperDowValue} from '../../../../../../helpers/helperDowValue';

export const ColumnsDataList = ({data}: {data?: IStatHourlyByDOW}) => {
  //
  const salesGraphSection = useAppSelector(
    s => s.myStatsSection.sales.salesGraphSection,
  );
  const currentMetricMode = useAppSelector(s => s.filter.currentMetricMode);

  return (
    <>
      {data?.weekdays?.map((item, index) => {
        const total = helperDowValue({
          data: item,
          currentMetricMode,
          salesGraphSection,
        });
        const compareTotal = helperDowValue({
          data: item,
          currentMetricMode,
          salesGraphSection,
          isCompare: true,
        });

        return (
          <div key={item.weekday} css={styles.column}>
            <div className="flex-col-center" style={{marginBottom: '0.3rem'}}>
              <TextVariant
                text={daysList[index]}
                variant="title03"
                style={styles.columnTitle}
              />
              <TendanceCompareValues
                value={
                  salesGraphSection === 'ORDERS'
                    ? total
                    : helperFormatPriceRoundToString(total)
                }
                secondValue={
                  compareTotal !== undefined
                    ? salesGraphSection === 'ORDERS'
                      ? compareTotal
                      : helperFormatPriceRoundToString(compareTotal)
                    : undefined
                }
                variant="textSemiBold"
                color={THEME.colors.primary}
                suffix={salesGraphSection === 'ORDERS' ? '' : '€'}
              />
            </div>

            <div>
              {item?.hours?.map((elem, index) => {
                return (
                  <Item
                    key={elem.hour}
                    elem={elem}
                    isLast={index === item?.hours?.length - 1}
                    heatmaps={data?.heatmaps}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

const Item = ({
  elem,
  isLast,
  heatmaps,
}: {
  elem: IStatHourlyByDOW['weekdays'][0]['hours'][0];
  isLast: boolean;
  heatmaps?: IStatHourlyByDOW['heatmaps'];
}) => {
  const salesGraphSection = useAppSelector(
    s => s.myStatsSection.sales.salesGraphSection,
  );
  const currentMetricMode = useAppSelector(s => s.filter.currentMetricMode);

  const total = helperDowValue({
    data: elem,
    currentMetricMode,
    salesGraphSection,
  });
  const compareTotal = helperDowValue({
    data: elem,
    currentMetricMode,
    salesGraphSection,
    isCompare: true,
  });

  const bgColor = helperHourItemColor({
    value: total,
    heatmaps,
    salesGraphSection,
  });

  //
  return (
    <div
      css={[
        styles.rowElem(isLast),
        css({
          backgroundColor: bgColor,
          border: `2px ${bgColor} solid`,
        }),
      ]}>
      <TendanceCompareValues
        value={
          salesGraphSection === 'ORDERS' ? total : helperFormatPriceRoundToString(total)
        }
        secondValue={
          compareTotal !== undefined
            ? salesGraphSection === 'ORDERS'
              ? compareTotal
              : helperFormatPriceRoundToString(compareTotal)
            : undefined
        }
        variant="title03"
        color={THEME.colors.black}
        secondValueColor={THEME.colors.white}
      />
    </div>
  );
};

const styles = {
  columnTitle: css({
    textAlign: 'center',
    marginBottom: '0.3rem',
    color: THEME.colors.gray2,
  }),
  column: css({
    width: '100%',
  }),
  rowElem: (isLast?: boolean) =>
    css({
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '0.2rem',
      marginBottom: isLast ? 0 : '0.2rem',
      justifyContent: 'flex-end',
      borderRadius: '0.13rem',
    }),
};

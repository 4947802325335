/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {AreaLocation} from './AreaLocation';
import {Title} from '../../../../../../components/atoms/Title';
import {ButtonCustom} from '../../../../../../components/atoms/ButtonCustom';
import {THEME} from '../../../../../../styles/theme';
import {addAreaLocationModalAction} from '../../../../../../redux/modals';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useReduxHook';
import {useQuery} from '@tanstack/react-query';
import {fetchAreaLocations} from '../../../../../../api/fetchAreas';
import {useTranslation} from 'react-i18next';

export const AreaLocationsListContainer = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const brand = useAppSelector(state => state.brand.brand);
  const areaSelected = useAppSelector(
    s => s.myRestaurantSection.areas.areaSelected,
  );
  const handleOpenAddAreaLocationModal = () => {
    dispatch(addAreaLocationModalAction(true));
  };

  const {data} = useQuery(
    ['fetchAreaLocations', brand.id, areaSelected?.id],
    () => fetchAreaLocations(brand.id, String(areaSelected?.id)),
    {
      enabled: !!brand.id && !!areaSelected?.id,
    },
  );

  return (
    <div css={styles.areaLocationsListContainer}>
      <Title title={'Emplacements'}></Title>
      <div css={styles.addAreaLocationButton}>
        <ButtonCustom
          label={t('ADD_AN_AREA_LOCATION')}
          backgroundColor={THEME.colors.green}
          onClick={handleOpenAddAreaLocationModal}
        />
      </div>
      <div css={styles.areaLocationsList}>
        {data?.map(areaLocation => (
          <AreaLocation areaLocation={areaLocation} key={areaLocation.id} />
        ))}
      </div>
    </div>
  );
};

const styles = {
  areaLocationsListContainer: css({
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
    overflow: 'hidden',
  }),
  addAreaLocationButton: css({
    textAlign: 'end',
    padding: '2vh 2vh 2vh 0',
  }),
  areaLocationsList: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: 1,
    overflow: 'auto',
    padding: '0 2vh 0',
    marginBottom: '2vh',
    gap: '10px',
  }),
};

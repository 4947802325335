import {axiosInstance} from './axiosInstance';
import {CategoryPayload} from './fetchCategories';

export const createExternalCategory = async (data: CategoryPayload) => {
  const res = await axiosInstance.post(
    'bcatalog/backoffice/external-categories',
    data,
  );

  return res;
};

export const addExternalCategoryItem = async (
  categoryUuid: string,
  body: {
    itemUuid: string;
    type: number;
    categoryUuid: string;
  },
) => {
  const res = await axiosInstance.post(
    `bcatalog/backoffice/external-categories/${categoryUuid}/items`,
    body,
  );

  return res;
};

export const deleteExternalCategoryItem = async (
  categoryUuid: string,
  itemUuid: string,
) => {
  const res = await axiosInstance.delete(
    `bcatalog/backoffice/external-categories/${categoryUuid}/items/${itemUuid}`,
  );

  return res;
};

export const updateExternalCategory = async (
  data: Partial<CategoryPayload>,
  categoryUuid: string,
) => {
  const res = await axiosInstance.put(
    `bcatalog/backoffice/external-categories/${categoryUuid}`,
    data,
  );

  return res;
};

export const updateExternalCategoryItemPosition = async (
  params: {
    categoryUuid: string;
    itemUuid: string;
  },
  data: {
    type: number;
    position: number;
  },
) => {
  const res = await axiosInstance.put(
    `bcatalog/backoffice/external-categories/${params.categoryUuid}/items/${params.itemUuid}`,
    data,
  );

  return res;
};

export const deleteExternalCategory = async (categoryUuid: string) => {
  const res = await axiosInstance.delete(
    `bcatalog/backoffice/external-categories/${categoryUuid}`,
  );

  return res;
};

/** @jsxImportSource @emotion/react */
import {CHANNELS} from '@bill-app-types/b-types';
import {DataGridActions} from '../../../../../components/newDatagrid/DataGridActions';
import {helperConfirmDialog} from '../../../../../helpers/helperConfirmDialog';
import {helperDate} from '../../../../../helpers/helperDate';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {addPosEntityModalAction} from '../../../../../redux/modals';
import {
  updateModeAction,
  updatedPosEntityAction,
} from '../../../../../redux/myModulesSection/pos';
import {deletePosEntity} from '../../../../../api/fetchPosEntities';

export const columns = [
  {
    field: 'name',
    headerName: "Nom de l'entité",
    flex: 1,
  },
  {
    field: 'channels',
    headerName: 'Canaux de commande',
    flex: 1,
    align: 'center',
    renderCell: (params: any) => {
      // const channelsList = Object.entries(CHANNELS)
      //   .slice(7)
      //   .map(entry => ({
      //     name: entry[0],
      //     value: entry[1],
      //   }));
      return (
        <span className="textMedium" style={{textAlign: 'center'}}>
          {params.channels.map((channel: any) => CHANNELS[channel]).join(', ')}
        </span>
      );
    },
  },
  {
    field: 'createdAt',
    headerName: "Date d'ajout",
    flex: 1,
    valueGetter(row: any) {
      return `Le ${helperDate(row.createdAt, 'DD/MM/YYYY')} à ${helperDate(
        row.created_at,
        'HH:mm',
      )}`;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Date de modification',
    flex: 1,
    valueGetter(row: any) {
      return `Le ${helperDate(row.updatedAt, 'DD/MM/YYYY')} à ${helperDate(
        row.created_at,
        'HH:mm',
      )}`;
    },
  },
  {
    field: 'actions',
    headerName: '',
    align: 'right',
    flex: 0.5,
    renderCell: (row: any) => {
      const handleClickEdit = ({dispatch}: any) => {
        dispatch(addPosEntityModalAction(true));
        dispatch(updatedPosEntityAction(row));
        dispatch(updateModeAction(true));
      };

      const handleClickDelete = ({refreshing, brandId}: any) => {
        helperConfirmDialog({
          onSubmit: async () => {
            const res = await deletePosEntity(brandId, row.uuid);
            if ([200, 204].includes(res.status)) {
              helperDisplayToast('Entité supprimée', true);
              refreshing('getEntities');
            } else {
              helperDisplayToast(
                res.data?.message || "Impossible de supprimer l'entité",
                false,
              );
            }
          },
        });
      };

      return (
        <DataGridActions
          handleClickEdit={handleClickEdit}
          handleClickDelete={handleClickDelete}
        />
      );
    },
  },
];

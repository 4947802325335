/** @jsxImportSource @emotion/react  */
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {THEME} from '../styles/theme';
import {ButtonCustom} from '../components/atoms/ButtonCustom';
import {TextVariant} from '../components/atoms/TextVariant';
import {css} from '@emotion/react';
import {useState} from 'react';

interface IProps {
  title?: string;
  cancelLabel?: string;
  validateLabel?: string;
  onSubmit?: any;
  text?: string;
  subTitle?: string;
  onClose?: any;
}

export const helperConfirmDialog = (props: IProps) => {
  confirmAlert({
    overlayClassName: 'dialog-overlay',
    customUI: ({onClose}) => <CustomUI onClose={onClose} {...props} />,
  });
};

const CustomUI = ({
  title = '',
  cancelLabel = 'Annuler',
  validateLabel = 'Supprimer',
  subTitle = 'Êtes-vous sûr de vouloir faire cela ?',
  onSubmit,
  text = '',
  onClose,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    await onSubmit();
    setIsLoading(false);
    onClose();
  };

  return (
    <div css={styles.container}>
      <TextVariant
        variant="title02"
        text={title ?? ''}
        style={{textAlign: 'center', marginBottom: 20}}
      />
      <div css={styles.deleteText}>
        <TextVariant
          text={subTitle ?? ''}
          style={styles.text}
          variant="textSemiBold"
        />
        <TextVariant
          text={text ?? ''}
          style={[styles.text, {color: THEME.colors.primary, marginTop: 10}]}
          variant="textSemiBold"
        />
      </div>

      <div className="flex-space-between">
        <ButtonCustom
          label={cancelLabel}
          backgroundColor={THEME.colors.red}
          width="28%"
          onClick={onClose}
        />
        <ButtonCustom
          label={validateLabel}
          backgroundColor={THEME.colors.green}
          width="70%"
          type="submit"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: THEME.colors.bgLight,
    padding: 30,
    minWidth: 370,
    borderRadius: 2,
  },
  label: {
    padding: 0,
  },
  deleteText: css({
    textAlign: 'center',
    marginBottom: '20px',
  }),
  text: css({
    textAlign: 'center',
  }),
};

import {HeaderTabsLinks} from '../../../../../components/molecules/HeaderTabsLinks';

export const BottomSection = () => {
  return (
    <HeaderTabsLinks
      data={[
        {label: 'Configuration', link: ''},
        {label: 'Mes imprimantes', link: '/printers'},
      ]}
      path="restaurant/network-printers"
      lengthToGetRootLink={3}
    />
  );
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {InputLabel} from '../../../../components/atoms/InputLabel';

export const CenterBlock = ({
  formikValues,
  formikHandleChange,
}: {
  formikValues: any;
  formikHandleChange: (e: any) => void;
}) => {
  return (
    <div css={styles.container}>
      <InputLabel
        label="Adresse"
        placeholder="Adresse"
        name="address"
        value={formikValues?.address}
        onChange={formikHandleChange}
        required
      />
      <div css={styles.section}>
        <InputLabel
          placeholder="Code postal"
          width={'32%'}
          value={formikValues?.zip}
          name="zip"
          onChange={formikHandleChange}
        />
        <InputLabel
          placeholder="Ville"
          width={'32%'}
          value={formikValues?.city}
          name="city"
          onChange={formikHandleChange}
        />
        <InputLabel placeholder="Pays" width={'32%'} value={'France'} />
      </div>
      <div css={styles.section}>
        <InputLabel
          placeholder="Longitude"
          width={'49%'}
          value={formikValues?.longitude}
          name="longitude"
          onChange={formikHandleChange}
        />
        <InputLabel
          placeholder="Latitude"
          width={'49%'}
          value={formikValues?.latitude}
          name="latitude"
          onChange={formikHandleChange}
        />
      </div>

      <InputLabel
        placeholder="SIRET"
        value={formikValues?.siret}
        name="siret"
        onChange={formikHandleChange}
      />

      <InputLabel
        placeholder="Code APE"
        value={formikValues?.ape}
        name="ape"
        onChange={formikHandleChange}
      />

      <InputLabel
        placeholder="TVA"
        value={formikValues?.tva_code}
        name="tva_code"
        onChange={formikHandleChange}
      />
    </div>
  );
};

const styles = {
  container: css(`
    width:33.33%;
    padding-left: 20px;
    padding-right: 20px;
    @media (max-width: 1024px) {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
  `),
  section: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
};

import {SVGProps} from 'react';

export const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5 6.53014C15.5 6.9366 15.1142 7.2636 14.6346 7.2636H8.86538V12.1533C8.86538 12.5582 8.47776 12.8871 8 12.8871C7.52224 12.8871 7.13462 12.5598 7.13462 12.1533V7.2636H1.36538C0.88762 7.2636 0.5 6.93537 0.5 6.53045C0.5 6.12674 0.88762 5.79668 1.36538 5.79668H7.13462V0.906978C7.13462 0.50205 7.52224 0.173828 8 0.173828C8.47776 0.173828 8.86538 0.50205 8.86538 0.906978V5.79668H14.6346C15.1142 5.79668 15.5 6.12674 15.5 6.53014Z"
      fill={props.color || 'white'}
    />
  </svg>
);

export const PlusIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9053_185527)">
      <path
        d="M15 6.52965C15 6.93611 14.6142 7.26311 14.1346 7.26311H8.36538V12.1528C8.36538 12.5577 7.97776 12.8866 7.5 12.8866C7.02224 12.8866 6.63462 12.5593 6.63462 12.1528V7.26311H0.865385C0.38762 7.26311 0 6.93489 0 6.52996C0 6.12625 0.38762 5.7962 0.865385 5.7962H6.63462V0.90649C6.63462 0.501561 7.02224 0.17334 7.5 0.17334C7.97776 0.17334 8.36538 0.501561 8.36538 0.90649V5.7962H14.1346C14.6142 5.7962 15 6.12625 15 6.52965Z"
        fill={props.color || '#3C6AF6'}
      />
    </g>
    <defs>
      <clipPath id="clip0_9053_185527">
        <rect width="15" height="13" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import {SVGProps} from 'react';

export const TablesColumnsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none">
    <g clipPath="url(#clip0_7798_131753)">
      <path
        d="M11.0645 2.72656V5.30469H14.1113V3.19531C14.1113 2.9375 13.9004 2.72656 13.6426 2.72656H11.0645ZM9.6582 2.72656H6.37695V5.30469H9.6582V2.72656ZM4.9707 2.72656H2.39258C2.13477 2.72656 1.92383 2.9375 1.92383 3.19531V5.30469H4.9707V2.72656ZM1.92383 6.71094V9.05469H4.9707V6.71094H1.92383ZM1.92383 10.4609V12.5703C1.92383 12.8281 2.13477 13.0391 2.39258 13.0391H4.9707V10.4609H1.92383ZM6.37695 13.0391H9.6582V10.4609H6.37695V13.0391ZM11.0645 13.0391H13.6426C13.9004 13.0391 14.1113 12.8281 14.1113 12.5703V10.4609H11.0645V13.0391ZM14.1113 9.05469V6.71094H11.0645V9.05469H14.1113ZM0.517578 3.19531C0.517578 2.16113 1.3584 1.32031 2.39258 1.32031H13.6426C14.6768 1.32031 15.5176 2.16113 15.5176 3.19531V12.5703C15.5176 13.6045 14.6768 14.4453 13.6426 14.4453H2.39258C1.3584 14.4453 0.517578 13.6045 0.517578 12.5703V3.19531ZM6.37695 9.05469H9.6582V6.71094H6.37695V9.05469Z"
        fill={props.color || 'white'}
      />
    </g>
    <defs>
      <clipPath id="clip0_7798_131753">
        <rect
          width="15"
          height="15"
          fill={props.color || 'white'}
          transform="translate(0.517578 0.382812)"
        />
      </clipPath>
    </defs>
  </svg>
);

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {Link} from 'react-router-dom';
import {THEME} from '../../styles/theme';

export const HeaderTabsLinks = ({
  data,
  path,
  lengthToGetRootLink,
  onClick,
}: {
  data?: {label: string; link: string}[];
  path?: string;
  onClick?: () => void;
  lengthToGetRootLink?: number;
}) => {
  const link = !lengthToGetRootLink
    ? window.location.href.split('/').pop()
    : window.location.href.split('/').slice(-lengthToGetRootLink).join('/');
  const currentLink = '/' + link;

  const currentPath = window.location.href.split('/').slice(-2).join('/');

  return (
    <ul css={styles.container}>
      {data?.map((item, index) => {
        const itemActive =
          ((currentLink === `/${path}` || currentPath === path) &&
            !item.link) ||
          (currentLink?.includes(item.link) && item.link);

        return (
          <div key={index} css={styles.item}>
            <Link
              to={`/online/${path}${item.link}`}
              key={item.link}
              onClick={onClick}
              css={[
                styles.itemLink(!!itemActive),
                itemActive && {
                  backgroundColor: THEME.colors.primary,
                  color: 'white!important',
                },
              ]}
              className="textMedium">
              {item.label}
            </Link>
          </div>
        );
      })}
    </ul>
  );
};

const styles = {
  container: css({
    alignSelf: 'flex-start',
    width: '100%',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    justifyItems: 'center',
    overflowX: 'auto',
    '::-webkit-scrollbar': {
      height: 0,
    },
  }),

  item: css({
    backgroundColor: '#FFF',
    cursor: 'pointer',
    padding: '1rem 0.2rem',
    height: '35px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    '& a': {
      color: '#A7A7AB',
    },
  }),

  itemLink: (itemActive: boolean) =>
    css({
      height: 29,
      padding: '0 1.5rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '2px',
      '&:hover': {
        backgroundColor: itemActive
          ? THEME.colors.primary
          : THEME.colors.lightGray,
        opacity: 0.6,
        color: 'white',
      },
    }),
};

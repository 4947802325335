/** @jsxImportSource @emotion/react */
import {useTranslation} from 'react-i18next';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {TextVariant} from '../atoms/TextVariant';
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {PRICE_RULE_TYPES} from '@bill-app-types/b-types';
import {InputLabel} from '../atoms/InputLabel';
import {useEffect, useState} from 'react';
import {
  AmountSign,
  helperFormatPriceRuleAmount,
} from '../../helpers/helperPriceRule';
import {FormikHelpers} from 'formik';
import {
  PRICE_RULE_SYMBOLS_LEFT,
  PRICE_RULE_SYMBOLS_RIGHT,
} from '../../constants/priceRuleSymbol';
import {createPriceRule, updatePriceRule} from '../../api/fetchPriceRules';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addPriceRuleModalAction} from '../../redux/modals';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';
import {SquareButton2} from '../atoms/SquareButton';
import {priceRuleSelectedAction} from '../../redux/myOffersSection/priceRules';
import {helperFormatPrice} from '../../helpers/helperFormatPrice';

export const AddPriceRuleModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const [form, setForm] = useState<{
    amount: number | string;
    label: string;
    type: PRICE_RULE_TYPES | null;
    sign?: '€' | '%' | null;
  }>({
    amount: '',
    label: '',
    type: null,
  });
  const [amountSign, setAmountSign] = useState<AmountSign>(null);
  const priceRuleSelected = useAppSelector(
    s => s.myOffersSection.priceRules.priceRuleSelected,
  );

  const clientQuery = useQueryClient();

  const handleCancel = () => {
    dispatch(addPriceRuleModalAction(false));
    dispatch(priceRuleSelectedAction(null));
  };

  const handleSelectAmountSign = (sign: AmountSign) => {
    if (amountSign === sign) {
      setAmountSign(null);
      return;
    }

    setAmountSign(sign);

    if (sign === '=') {
      setForm({...form, type: PRICE_RULE_TYPES.EQUAL, sign: '€'});
    } else {
      setForm({
        ...form,
        amount: helperFormatPriceRuleAmount(Number(form.amount), sign),
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;

    if (name === 'label') {
      setForm({
        ...form,
        [name]: value,
      });
      return;
    }

    setForm({
      ...form,
      [name]: helperFormatPriceRuleAmount(Number(value), amountSign),
    });
  };

  const handleSelectSign = (sign: '€' | '%', type: PRICE_RULE_TYPES) => {
    setForm(_form => ({
      ..._form,
      type,
      sign: _form.sign === sign ? null : sign,
    }));
  };

  const handleSubmit = async (_: any, {setSubmitting}: FormikHelpers<any>) => {
    if (
      !form.amount ||
      !form.label ||
      !form.type ||
      !form.sign ||
      !amountSign
    ) {
      setSubmitting(false);
      return;
    }

    const data = {
      label: form.label,
      amount: Number(form.amount) * 100,
      type:
        amountSign && amountSign !== '=' && form.sign === '€'
          ? PRICE_RULE_TYPES.ADDITION
          : form.type,
    };

    try {
      let res;

      if (priceRuleSelected) {
        res = await updatePriceRule(priceRuleSelected.uuid, data);
      } else {
        res = await createPriceRule(data);
      }

      if (res && res.status === 204) {
        helperDisplayToast(
          `Règle de prix ${
            priceRuleSelected ? 'modifiée' : 'créee'
          } avec succès`,
          true,
        );
        clientQuery.invalidateQueries(['getPriceRules']);
        handleCancel();
      } else {
        helperDisplayToast(
          `Erreur lors de la ${
            priceRuleSelected ? 'modification' : 'création'
          } de la règle de prix`,
          false,
        );
      }
    } catch (error) {
      helperDisplayToast(
        'Erreur lors de la création de la règle de prix',
        false,
      );
    }

    setSubmitting(false);
  };

  useEffect(() => {
    if (priceRuleSelected) {
      setForm({
        amount: Number(helperFormatPrice(priceRuleSelected.amount, 'en')),
        label: priceRuleSelected.label,
        type: priceRuleSelected.type,
        sign: [PRICE_RULE_TYPES.ADDITION, PRICE_RULE_TYPES.EQUAL].includes(
          priceRuleSelected.type,
        )
          ? '€'
          : '%',
      });

      setAmountSign(
        PRICE_RULE_TYPES.EQUAL === priceRuleSelected.type
          ? '='
          : priceRuleSelected.amount < 0
          ? '-'
          : '+',
      );
    }
  }, [priceRuleSelected]);

  return (
    <ModalWrapper
      title={t(priceRuleSelected ? 'UPDATE_PRICE_RULE' : 'CREATE_PRICE_RULE')}
      maxWidth={'45%'}
      onSubmit={handleSubmit}
      disabled={
        !form.amount || !form.label || !form.type || !form.sign || !amountSign
      }
      onCancel={handleCancel}
      cancelLabel={priceRuleSelected ? t('CANCEL') : undefined}>
      <InputLabel
        label={t('NAME_OF_PRICE_RULE')}
        name="label"
        required
        onChange={handleChange}
        value={form?.label}
      />
      <div className="flex" css={styles.content}>
        <div css={styles.blockLeft}>
          <div css={styles.symbols}>
            {PRICE_RULE_SYMBOLS_LEFT.map(symbol => (
              <SquareButton2
                key={symbol.value}
                Icon={symbol.Icon}
                isActive={amountSign === symbol.value}
                onClick={() => {
                  handleSelectAmountSign(symbol.value);
                }}
              />
            ))}
          </div>

          <InputLabel
            label={t('NUMBER')}
            name="amount"
            required
            marginBottom={'1.3rem'}
            placeholder={t('NUMBER')}
            type="number"
            step="0.01"
            value={form?.amount}
            onChange={handleChange}
            min={amountSign && ['=', '+'].includes(amountSign) ? 0 : undefined}
          />

          <div css={styles.symbols}>
            {PRICE_RULE_SYMBOLS_RIGHT.map(symbol => (
              <SquareButton2
                key={symbol.value}
                Icon={symbol.Icon}
                isActive={form.sign === symbol.value}
                disabled={amountSign === '=' && symbol.value !== '€'}
                onClick={() => {
                  handleSelectSign(symbol.value, symbol.type);
                }}
              />
            ))}
          </div>
        </div>

        <div css={styles.blockRight}>
          <TextVariant
            text={t('PLEASE_SELECT_VALUE_AND_TYPE_YOUR_AMOUNT')}
            variant="textRegular"
            style={{color: THEME.colors.gray2, whiteSpace: 'pre-line'}}
          />
        </div>
      </div>
      <FieldsRequired />
    </ModalWrapper>
  );
};

const styles = {
  content: css({
    gap: '1rem',
  }),
  blockLeft: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '48%',
    gap: '1rem',
    '.textLabel': {
      marginTop: 0,
    },

    input: {
      height: '31px',
    },
  }),
  symbols: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '22px',
  }),
  blockRight: css({
    width: '48%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px dashed ${THEME.colors.lightGray}`,
    padding: '8px 16px',
  }),
};

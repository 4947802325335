/** @jsxImportSource @emotion/react */
import {useQuery} from '@tanstack/react-query';
import {NewDataGrid} from '../../../../../../components/newDatagrid/NewDataGrid';
import {columnsOptionsProducts} from './columns';
import {getCatalogOptionCategories} from '../../../../../../api/fetchCatalogOptions';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {useDebounce} from '../../../../../../hooks/useDebounce';
import {AddOptionCategoryModal} from '../../../../../../components/modals/AddOptionCategoryModal';
//import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';

export const OptionCategoriesSection = () => {
  //
  //const navigate = useNavigate();
  const search = useAppSelector(s => s.filter.search);
  const optionCategoryModal = useAppSelector(s => s.modals.optionCategoryModal);
  const orderBy = useAppSelector(s => s.filter.orderBy);
  const optionType = useAppSelector(s => s.filter.optionType);
  const isArchived = useAppSelector(s => s.filter.isArchived);

  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [limit] = useState(20);

  const offset = (page - 1) * limit;

  const debouncedSearchTerm = useDebounce(search, 500);

  const {data, isLoading} = useQuery(
    [
      'getCatalogOptionCategories',
      debouncedSearchTerm,
      offset,
      limit,
      orderBy,
      optionType,
      isArchived,
    ],
    () =>
      getCatalogOptionCategories({
        search: debouncedSearchTerm,
        offset,
        limit,
        orderBy,
        optionType,
        isArchived,
      }),
  );

  useEffect(() => {
    setRowCount(oldRowCount =>
      data?.totalModifiers !== undefined ? data?.totalModifiers : oldRowCount,
    );
  }, [data?.totalModifiers]);

  return (
    <div css={styles.content}>
      <NewDataGrid
        rows={data?.modifiers || []}
        columns={columnsOptionsProducts}
        loading={isLoading}
        rowHeight={88}
        hasPagination
        page={page}
        setPage={setPage}
        totalRows={rowCount}
        rowPerPage={limit}
      />
      {optionCategoryModal && <AddOptionCategoryModal />}
    </div>
  );
};

const styles = {
  content: {
    padding: '2vh',
    height: '87%',
  },
};

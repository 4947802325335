/** @jsxImportSource @emotion/react */

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {helperDate} from '../../../helpers/helperDate';
import {helperFormatPrice} from '../../../helpers/helperFormatPrice';
import {THEME} from '../../../styles/theme';
import {ArrayPdf} from '../Array';

export const ExportSalesPDF = ({totalTva, paymentTotal, dates, brand}: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={{flex: 1}}>
            <Image style={{width: 65}} src={require('../logo.png')} />
          </View>
          <View style={styles.infos}>
            <Image style={{width: 45}} src={brand?.asset.logo} />
            <Text style={styles.brand}>{brand?.brand_label}</Text>
            <Text style={styles.text}>Ventilation CA et Règlements</Text>
            <Text style={styles.text}>
              Du {helperDate(new Date(dates?.from), 'DD/MM/YYYY')} au{' '}
              {helperDate(new Date(dates?.to), 'DD/MM/YYYY')}
            </Text>
          </View>
        </View>
        <View style={styles.contain}>
          <Text style={styles.title}>Ventilation par taux de TVA</Text>
          <ArrayPdf
            header={['Taux', 'H.T', 'T.V.A', 'T.T.C']}
            rows={totalTva?.amountByTva}
            footer={[
              'Total CA',
              `${helperFormatPrice(totalTva?.totalHT, 'en')}€`,
              `${helperFormatPrice(totalTva?.totalTVA, 'en')}`,
              `${helperFormatPrice(totalTva?.totalTTC, 'en')}`,
            ]}
            sizes={[7, 3, 3, 2]}
          />
          <Text style={styles.title}>Ventilation par modes de règlement</Text>
          <ArrayPdf
            header={['Mode de règlement', 'Code', 'Compte', 'Total']}
            rows={paymentTotal?.totalsByPaymentMethod}
            footer={[
              'Total règlements',
              '',
              '',
              `${helperFormatPrice(paymentTotal?.totalAmount, 'en') || 0}`,
            ]}
            sizes={[7, 3, 3, 2]}
          />
        </View>
        <View style={styles.footer}>
          <View style={styles.footerBlock}>
            <Text style={styles.text}>
              Généré le {helperDate(new Date(), 'DD/MM/YYYY')} à{' '}
              {helperDate(new Date(), 'HH:mm')}
            </Text>
            <Text style={styles.text}>Société {brand?.company_label}</Text>
          </View>
          <View
            style={[
              styles.footerBlock,
              {alignItems: 'center', justifyContent: 'center'},
            ]}>
            <Image style={{width: 15}} src={require('../small_logo.png')} />
            <Text style={styles.copy}>© Copyright BILL</Text>
          </View>
          <View style={[styles.footerBlock, {textAlign: 'right'}]}>
            {/* <Text style={styles.text}>
              Utilisateur {brand?.firstname} {brand?.lastname}
            </Text> */}
            <Text style={styles.text}>Page 1/1</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: THEME.colors.white,
    padding: '7%',
    flex: 1,
  },
  header: {
    flexDirection: 'row',
  },
  contain: {
    flex: 1,
    paddingTop: '5%',
  },
  footer: {
    flexDirection: 'row',
    borderTop: 1,
    borderColor: THEME.colors.primary,
    paddingTop: 5,
    alignItems: 'flex-end',
  },
  footerBlock: {
    flex: 1,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  infos: {
    flex: 1,
    textAlign: 'right',
  },
  brand: {
    fontSize: 13.14,
    color: THEME.colors.primary,
  },
  title: {
    color: THEME.colors.bgDark,
    fontSize: 9,
    marginBottom: 5,
  },
  text: {
    color: THEME.colors.bgDark,
    fontSize: 7.63,
  },
  copy: {
    color: THEME.colors.primary,
    fontSize: 7.63,
  },
});

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {helperFormatPrice} from '../../helpers/helperFormatPrice';
import {TextVariantType} from './TextVariant';
import {M_SCREEN_DESKTOP} from '../../constants/ResponsiveSize';

interface IProps {
  color: string;
  value: number;
  percent?: number;
  suffix?: string;
  formatValue?: boolean;
  variant?: TextVariantType;
  percentSuffix?: string;
  percentColor?: string;
  seperatorColor?: string;
  fontSize?: string;
}

export const ValueSeparatorPercent = ({
  value,
  percent,
  suffix,
  color,
  formatValue = true,
  variant = 'textSemiBold',
  percentSuffix = '%',
  percentColor,
  seperatorColor,
  fontSize,
}: IProps) => {
  return (
    <p
      className={variant}
      css={styles.values}
      style={{
        color,
        fontSize,
      }}>
      <span css={[styles.nbOrders, percent !== undefined && styles.nbUnd]}>
        {((formatValue ? helperFormatPrice(value) : value) || 0) +
          ' ' +
          (suffix || '')}{' '}
      </span>

      {percent !== undefined && (
        <>
          <span css={[styles.separator, css({color: seperatorColor})]}>
            {' '}
            |{' '}
          </span>
          <span css={[styles.nbOrdersPercent, css({color: percentColor})]}>
            {(percent || 0) + ` ${percentSuffix}`}
          </span>
        </>
      )}
    </p>
  );
};

const styles = {
  nbOrders: css({
    fontWeight: 500,
    display: 'inline-block',
  }),
  nbUnd: css({
    minWidth: '5rem',
    textAlign: 'right',
    [M_SCREEN_DESKTOP]: {
      minWidth: '0rem',
    },
  }),
  separator: css({
    color: THEME.colors.black,
    fontWeight: 400,
    display: 'inline-block',

    margin: '0 0.5rem',
    textAlign: 'center',
  }),
  nbOrdersPercent: css({
    fontWeight: 400,
    minWidth: '5rem',
    display: 'inline-block',
    textAlign: 'left',
    [M_SCREEN_DESKTOP]: {
      minWidth: '0rem',
    },
  }),
  values: css({
    display: 'flex',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {columnsProducts} from './columns';
import {Bottom} from './HeaderSections/Bottom';
import {Center} from './HeaderSections/Center';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {useEffect, useState} from 'react';
import {AddProductModal} from '../../../../components/modals/AddProductModal';
import {useRuptureGestion} from '../../../../hooks/useRuptureGestion';

export const RuptureGestion = withRedirectAndNavigation(() => {
  const addProductModal = useAppSelector(state => state.modals.addProductModal);
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [limit] = useState(20);

  const sectionShow = useAppSelector(s => s.app.sectionShow);

  const {data, isLoading} = useRuptureGestion({
    limit,
    offset: (page - 1) * limit,
  });

  useEffect(() => {
    setRowCount(oldRowCount =>
      data?.totalItems !== undefined ? data?.totalItems : oldRowCount,
    );
  }, [data?.totalItems]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setPage(1);
    }
    return () => {
      isMounted = false;
    };
  }, [sectionShow]);

  const dataItems = data?.items?.map((item: any) => {
    return {
      ...item,
    };
  });

  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} bottomSection={<Bottom />} />
      <div css={styles.content}>
        <NewDataGrid
          rows={dataItems}
          columns={columnsProducts}
          rowHeight={85}
          hasPagination
          page={page}
          loading={isLoading}
          setPage={setPage}
          totalRows={rowCount}
          rowPerPage={limit}
        />
      </div>
      {addProductModal && <AddProductModal />}
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: {
    padding: '2vh',
    height: '87%',
  },
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {FC} from 'react';
import {Header} from './Sidebar/Header';
import {SideBar} from './Sidebar';
import {ModalList} from '../../modals';

export const withNavigation = (WrappedComponent: FC) => (props: any) => {
  //
  return (
    <div css={styles.container}>
      <SideBar />
      <div css={styles.body}>
        <Header />
        <WrappedComponent {...props} />
      </div>
      <ModalList />
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    height: '100vh',
    flexDirection: 'row',
    backgroundColor: '#f5f5f5',
    '@media print': {
      flexDirection: 'column',
      height: 'auto',
    },
  }),
  body: css({
    position: 'relative',
    backgroundColor: 'var(--bg-main-color)',
    flex: 1,
    overflowY: 'auto',

    '@media print': {
      backgroundColor: 'white',
    },
    // position: 'relative',
  }),
};

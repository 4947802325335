import {SVGProps} from 'react';

export const CheckCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.23763 9.12773C6.95378 9.40449 6.49023 9.40449 6.20638 9.12773L4.53971 7.50273C4.25586 7.22598 4.25586 6.77402 4.53971 6.49727C4.82357 6.22051 5.28711 6.22051 5.57096 6.49727L6.722 7.61953L9.53971 4.87227C9.82357 4.59551 10.2871 4.59551 10.571 4.87227C10.8548 5.14902 10.8548 5.60098 10.571 5.87773L7.23763 9.12773ZM14.222 7C14.222 10.5902 11.2376 13.5 7.55534 13.5C3.87305 13.5 0.888672 10.5902 0.888672 7C0.888672 3.40977 3.87305 0.5 7.55534 0.5C11.2376 0.5 14.222 3.40977 14.222 7ZM7.55534 1.71875C4.56315 1.71875 2.13867 4.08262 2.13867 7C2.13867 9.91738 4.56315 12.2812 7.55534 12.2812C10.5475 12.2812 12.972 9.91738 12.972 7C12.972 4.08262 10.5475 1.71875 7.55534 1.71875Z"
      fill="#3C6AF6"
    />
  </svg>
);

export const CheckCircleSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.54994 6.61222C5.31386 6.82207 4.92833 6.82207 4.69225 6.61222L3.30609 5.38008C3.07001 5.17023 3.07001 4.82754 3.30609 4.61769C3.54217 4.40784 3.9277 4.40784 4.16378 4.61769L5.12109 5.46864L7.46457 3.38555C7.70065 3.1757 8.08618 3.1757 8.32226 3.38555C8.55834 3.5954 8.55834 3.93809 8.32226 4.14794L5.54994 6.61222ZM11.3588 4.99888C11.3588 7.72115 8.87672 9.92746 5.81417 9.92746C2.75163 9.92746 0.269531 7.72115 0.269531 4.99888C0.269531 2.27662 2.75163 0.0703125 5.81417 0.0703125C8.87672 0.0703125 11.3588 2.27662 11.3588 4.99888ZM5.81417 0.99442C3.32558 0.99442 1.30915 2.7868 1.30915 4.99888C1.30915 7.21097 3.32558 9.00335 5.81417 9.00335C8.30277 9.00335 10.3192 7.21097 10.3192 4.99888C10.3192 2.7868 8.30277 0.99442 5.81417 0.99442Z"
      fill="#61D295"
    />
  </svg>
);

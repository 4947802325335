/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ActionsContainer} from '../molecules/ActionsContainer';

export const DataGridActions = (props: any) => {
  return (
    <div css={styles.actions}>
      <ActionsContainer {...props} />
    </div>
  );
};

const styles = {
  actions: css({
    marginLeft: 'auto',
    padding: '0.8vh 0',
  }),
};

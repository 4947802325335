/** @jsxImportSource @emotion/react */

import {IVendorStructure} from '@bill-app-types/b-types';
import {ProductsAndMenuCatalog} from '../../../../../components/organisms/ProductsAndMenuCatalog';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {addVendorStructureProduct} from '../../../../../api/fetchCatalogVendorStructure';
import {RightBlock} from './RightBlock';

export const VendorStructuresSection = () => {
  const clientQuery = useQueryClient();

  const vendorStructureSelected = useAppSelector(
    s => s.myCatalogSection.vendorStructures.vendorStructureSelected,
  );
  const vendorStructureProducts = useAppSelector(
    s => s.myCatalogSection.vendorStructures.vendorStructureProducts,
  );

  const handleProductClick = async (product: any) => {
    if (!vendorStructureSelected) {
      return helperDisplayToast('Aucune catégorie sélectionnée');
    }
    const productFinded = vendorStructureProducts?.find(
      p => p.uuid === product.uuid,
    );

    const data = {
      itemUuid: product.uuid,
      vendorStructureUuid: vendorStructureSelected?.uuid,
      type: 1,
    };

    if (!productFinded) {
      const res = await addVendorStructureProduct(data);
      if (res && res.status === 204) {
        helperDisplayToast(
          res?.data?.message || 'Item ajoutée à la famille de taxe',
          true,
        );
        clientQuery.invalidateQueries([
          `getVendorStructureItems-${vendorStructureSelected?.uuid}`,
        ]);
      } else {
        helperDisplayToast(
          res?.data?.message ||
            "Impossible d'ajouter l'item à la famille de taxe",
          false,
        );
      }
    }
  };

  const handleIsDisabled = (vendorStructure: IVendorStructure) => {
    return (
      (vendorStructureProducts?.find(
        (item: IVendorStructure) => item?.uuid === vendorStructure.uuid,
      ) &&
        !!vendorStructureSelected) ||
      false
    );
  };

  return (
    <div css={styles.content}>
      <ProductsAndMenuCatalog
        hideProductType
        onClickProduct={handleProductClick}
        handleIsDisabled={handleIsDisabled}
      />
      <RightBlock />
    </div>
  );
};

const styles = {
  content: {
    padding: '2vh',
    height: '84%',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

import {useQuery} from '@tanstack/react-query';
import {fetchSqsTotalByPaymentMethods} from '../api/fetchSqsHistory';
import {useAppSelector} from './useReduxHook';

export const useSqsPaymentMethods = () => {
  const dates = useAppSelector(s => s.filter.dates);
  const {data, isLoading} = useQuery(
    ['fetchSqsTotalByPaymentMethods', dates],
    () => fetchSqsTotalByPaymentMethods(dates),
  );
  return {
    data: data?.data,
    isLoading,
  };
};

import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';

export const fetchPosGiftsByUsers = async (data: {
  from: Date;
  to: Date;
}) => {
  const query = helperGenerateQuery(data, {
    dateFormat: 'YYYY-MM-DD HH:mm',
  });
  const res: any = await axiosInstance
    .get(`/pos/backoffice/stats/global-gift/pos-users${query}`)
    .catch(err => {
      console.log('❌ fetchPosGiftsByUsers : ', err);
    });

  return res?.data?.data;
};

export const fetchPosGiftsByReasons = async (data: {
  from: Date;
  to: Date;
}) => {
  const query = helperGenerateQuery(data, {
    dateFormat: 'YYYY-MM-DD HH:mm',
  });
  const res: any = await axiosInstance
    .get(`/pos/backoffice/stats/global-gift/reasons${query}`)
    .catch(err => {
      console.log('❌ fetchPosGiftsByReasons : ', err);
    });

  return res?.data?.data;
};

import {axiosInstance} from './axiosInstance';

export const fetchPaymentMethods = async () => {
  //
  console.log('### fetchPaymentMethods ###');
  const res: any = await axiosInstance
    .get(`/bcore/protected/admin/payment-methods`)
    .catch(err => {
      console.log('❌ getBrandAccount : ', err);
    });
  return res?.data?.data;
};

import {AdditionalSalesSection} from './AdditionalSalesSection';
import {GeneralSection} from './GeneralSection';
import {RecipesSection} from './RecipesSection';
import {OptionsSection} from './OptionsSection';
import {PictogramsAndAllergensSection} from './PictogramsAndAllergensSection';
// import {RulesPriceSection} from './RulesPriceSection';

export const CreateProductRouter = {
  path: 'products/edit',
  children: [
    {
      path: '',
      element: <GeneralSection />,
    },
    {
      path: 'pictograms-and-allergens',
      element: <PictogramsAndAllergensSection />,
    },
    {
      path: 'options',
      element: <OptionsSection />,
    },
    {
      path: 'product-recipes',
      element: <RecipesSection />,
    },
    // {
    //   path: 'rules-price',
    //   element: <RulesPriceSection />,
    // },
    {
      path: 'additional-sales',
      element: <AdditionalSalesSection />,
    },
  ],
};

/** @jsxImportSource @emotion/react */ import {
  useContext,
  useEffect,
} from 'react';
import {UtilsContext} from '../../../contexts/UtilsContext';
import {useAppSelector} from '../../../hooks/useReduxHook';
import {useInfiniteQuery} from '@tanstack/react-query';
import {showProductsAction} from '../../../redux/posCategories';
import {PlusIcon} from '../../../assets/svgs/PlusIcon';
import {Header} from './Header';
import {InfiniteScrollCustom} from '../../atoms/InfiniteScrollCustom';
import {ProductList} from '../../molecules/ProductList';
import {css} from '@emotion/react';
import {THEME} from '../../../styles/theme';
import {useDebounce} from '../../../hooks/useDebounce';
import {getCatalogOptions} from '../../../api/fetchCatalogOptions';
import {IModifierGetReturn} from '@bill-app-types/b-types';

interface IOptionsCatalog {
  onClickProduct?: (product: any) => void;
  hiddenProducts?: boolean;
  selectedItems: any;
}

export const OptionsCatalog = ({
  onClickProduct,
  hiddenProducts = false,
  selectedItems,
}: IOptionsCatalog) => {
  const {dispatch} = useContext<any>(UtilsContext);
  const showProducts = useAppSelector(s => s.posCategories.showProducts);
  const search = useAppSelector(s => s.filter.search);

  const debouncedSearchTerm = useDebounce(search, 500);

  const handleOpenLeftBlock = () => {
    dispatch(showProductsAction(!showProducts));
  };

  const {data, refetch, fetchNextPage, hasNextPage, isLoading} =
    useInfiniteQuery(
      ['getCatalogOptions', debouncedSearchTerm],
      ({pageParam = 0}) =>
        getCatalogOptions({
          limit: 50,
          offset: pageParam,
          search: debouncedSearchTerm,
        }),
      {
        getNextPageParam: (lastPage, pages) => {
          const currentTotalProducts = pages?.reduce(
            (acc, page) => acc + (page?.items?.length || 1),
            0,
          );

          if (Number(lastPage?.totalItems) === currentTotalProducts) {
            return undefined;
          }

          return currentTotalProducts + 1;
        },
      },
    );

  useEffect(() => {
    refetch();
  }, [debouncedSearchTerm, refetch]);

  const options = data?.pages.flatMap(data => data?.items) || [];

  const handleIsDisabled = (modifier: IModifierGetReturn) => {
    return (
      selectedItems?.find(
        (item: IModifierGetReturn) => item?.uuid === modifier?.uuid,
      ) || false
    );
  };

  return (
    <>
      {!showProducts && hiddenProducts ? (
        <div css={styles.closedContainer}>
          <button onClick={handleOpenLeftBlock} css={styles.closedBtn}>
            <PlusIcon />
          </button>
          <div css={styles.closedContent}></div>
        </div>
      ) : (
        <div css={styles.container}>
          <Header />
          <div css={styles.content} id="scrollableDiv">
            <InfiniteScrollCustom
              items={options || []}
              refresh={refetch}
              fetchData={fetchNextPage}
              scrollableTarget="scrollableDiv"
              isLoading={!!hasNextPage}>
              <ProductList
                data={options}
                handleOnClick={onClickProduct}
                showProductType
                showAdd
                loading={isLoading}
                handleIsDisabled={handleIsDisabled}
              />
            </InfiniteScrollCustom>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  container: css({
    width: '49%',
    display: 'flex',
    flexDirection: 'column',
  }),
  content: css({
    paddingTop: 20,
    overflow: 'auto',
  }),
  closedContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }),
  closedBtn: css({
    backgroundColor: THEME.colors.primary,
    border: `1px solid ${THEME.colors.primary}`,
    width: 25,
    height: 25,
    borderRadius: 2,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  closedContent: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 2,
    width: 25,
    height: '100%',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';

interface IErrorMessageProps {
  message: string;
}

export const ErrorMessage = ({
  message
}: IErrorMessageProps) => {
  return (
    <div
      css={styles.container}>
      <p>{message}</p>
    </div>
  );
};

const styles = {
  container:
    css({
      backgroundColor: THEME.colors.red,
      margin: '10px auto',
      border: `1px solid ${THEME.colors.gray}`,
      color: THEME.colors.white,
      fontSize: '0.9rem',
      width: '70%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
    }),
};

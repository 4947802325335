import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addRecipeModalAction} from '../../redux/modals';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {ToggleButtonLabel} from '../molecules/ToggleButtonLabel';
import {createRecipe, updateRecipe} from '../../api/fetchRecipes';
import {useEffect, useState} from 'react';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {
  recipeSelectedAction,
  recipeUpdateModeAction,
} from '../../redux/myCatalogSection/recipes';
import {
  IRecipeCreationPayload,
  IRecipePutPayload,
} from '@bill-app-types/b-types';

export const AddRecipeModal = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const [isArchived, setIsArchived] = useState(true);
  const {updateMode, recipeSelected} = useAppSelector(
    s => s.myCatalogSection.recipes,
  );

  const clientQuery = useQueryClient();

  const onCancel = () => {
    dispatch(recipeUpdateModeAction(false));
    dispatch(addRecipeModalAction(false));
    dispatch(recipeSelectedAction(null));
  };

  const onSubmit = async (
    data: IRecipeCreationPayload,
    {setSubmitting}: any,
  ) => {
    setSubmitting(true);
    if (data.label) {
      const res = await createRecipe({
        ...data,
        isArchived,
      });

      if (res && [200, 204].includes(res.status)) {
        clientQuery.invalidateQueries(['fetchRecipes']);
        helperDisplayToast('Ingredient ajoutée avec succès', true);
        onCancel();
      } else {
        helperDisplayToast('Une erreur est survenue', false);
      }
    }
    setSubmitting(false);
  };

  const onSave = async (data: IRecipePutPayload, {setSubmitting}: any) => {
    if (!recipeSelected) return;
    setSubmitting(true);

    const res = await updateRecipe(recipeSelected.uuid, {
      ...data,
      isArchived,
    });

    if (res && [200, 204].includes(res.status)) {
      clientQuery.invalidateQueries(['fetchRecipes']);
      helperDisplayToast('Ingredient modifiée avec succès', true);
      onCancel();
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (recipeSelected) {
      setIsArchived(recipeSelected.isArchived);
    }
  }, [recipeSelected]);

  return (
    <ModalWrapper
      title={t('RECIPE')}
      minWidth={'28%'}
      minHeight="38%"
      btnCancelWidth={updateMode ? '30%' : undefined}
      btnValidateWidth={updateMode ? '65%' : undefined}
      cancelLabel={updateMode ? t('CANCEL') : ''}
      validateLabel={t(updateMode ? 'SAVE_CHANGES' : 'SAVE_RECIPE')}
      onCancel={onCancel}
      onSubmit={updateMode ? onSave : onSubmit}
      initialValues={{
        label: updateMode && recipeSelected ? recipeSelected.label : '',
      }}>
      <FieldCustomInput label={t('RECIPE_NAME')} name="label" required />

      <ToggleButtonLabel
        label={t('VISIBILITY')}
        active={!isArchived}
        onToggle={() => setIsArchived(!isArchived)}
        labelPosition={'left'}
      />

      <FieldsRequired />
    </ModalWrapper>
  );
};

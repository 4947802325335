/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {useBrandAccess} from '../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../constants/frontPolicies';
import {ProductList} from '../../molecules/ProductList';
import {Title} from '../../atoms/Title';
import {useTranslation} from 'react-i18next';
import {AssociatedItem} from '@bill-app-types/b-types';

export const ProductsAndMenuOfEvent = ({
  data = [],
  onClickItem,
}: {
  data?: AssociatedItem[];
  onClickItem: (item: AssociatedItem) => void;
}) => {
  const {t} = useTranslation();

  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <div css={styles(hasAuthorization).container}>
      <Title title={t('PRODUCTS_AND_MENU_OF_EVENT')} />
      <div css={styles().productListContainer}>
        <ProductList
          showCross={hasAuthorization}
          showProductType
          bg="white"
          data={data}
          handleOnClick={onClickItem}
        />
      </div>
    </div>
  );
};

const styles = (hasAuthorization: boolean = false) => ({
  container: css({
    width: hasAuthorization ? '49%' : '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  productListContainer: css({
    backgroundColor: 'white',
    padding: '10px 9%',
    marginTop: 10,
    width: '100%',
    height: '100%',
    overflow: 'auto',
  }),
});

import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../hooks/useReduxHook';
import {addMenuModalAction} from '../../redux/modals';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {useNavigate} from 'react-router-dom';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import {InputLabel} from '../atoms/InputLabel';
import {createMenu} from '../../api/fetchMenus';
import {menuPreRegisterAction} from '../../redux/myCatalogSection/menus';

export const AddMenuModal = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clientQuery = useQueryClient();

  const [form, setForm] = useState({
    label: '',
    price: 0,
  });

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;
    setForm({...form, [name]: value});
  };

  const onCancel = () => {
    dispatch(addMenuModalAction(false));
  };

  const handleCreateMenu = async (isSave?: boolean) => {
    if (form.label) {
      const newData = {
        ...form,
        backofficeLabel: form.label,
        productionLabel: form.label,
        description: form.label,
        loyaltyCost: 0,
        loyaltyGain: 0,
      };
      const res = await createMenu({
        ...newData,
        price: Math.round((newData.price || 0) * 100),
      });
      if (res?.status === 200) {
        helperDisplayToast('Menu créé', true);
        onCancel();
        if (isSave) {
          clientQuery.invalidateQueries(['fetchMenus']);
        } else {
          dispatch(
            menuPreRegisterAction({...newData, uuid: res?.data?.data?.uuid}),
          );
          navigate('/online/catalog/menus/edit');
        }
      } else {
        helperDisplayToast(
          res?.data?.message || 'Impossible de créer le menu',
          false,
        );
      }
    } else {
      helperDisplayToast(
        'Tous les champs obligatoires doivent être complétés ',
        false,
      );
    }
  };

  const handleSave = () => {
    handleCreateMenu(true);
  };

  const handleSubmit = () => {
    handleCreateMenu();
  };

  return (
    <ModalWrapper
      title={t('MENU_CREATION')}
      minWidth="35%"
      cancelLabel={t('REGISTER_AND_CONTINUE_CREATION_LATER')}
      saveLabel={t('REGISTER_AND_CONTINUE_CREATION_LATER')}
      subTitle={t('GENERAL_INFORMATION')}
      validateLabel={t('GO_ADD')}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      onSave={handleSave}
      hideCancelButton
      titleMarginBottom={'2rem'}
      initialValues={form}>
      <InputLabel
        label={t('MENU_NAME')}
        name="label"
        value={form.label}
        onChange={handleChange}
        required
      />
      <InputLabel
        label={t('PRICE')}
        name="price"
        value={form.price.toString()}
        onChange={handleChange}
        required
      />

      <FieldsRequired />
    </ModalWrapper>
  );
};

export const UsersIcon = (props: any) => {
  return (
    <svg
      width="23"
      height="35"
      viewBox="0 0 23 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2145_27746)">
        <path
          d="M8.88021 16.692C11.1948 16.692 13.0707 14.8158 13.0707 12.5016C13.0707 10.1873 11.1948 8.3111 8.88021 8.3111C6.56563 8.3111 4.68973 10.1873 4.68973 12.5016C4.68973 14.8158 6.56563 16.692 8.88021 16.692ZM8.88021 9.88252C10.3243 9.88252 11.4993 11.0575 11.4993 12.5016C11.4993 13.9457 10.3243 15.1206 8.88021 15.1206C7.43613 15.1206 6.26116 13.9453 6.26116 12.5016C6.26116 11.0575 7.43646 9.88252 8.88021 9.88252ZM10.54 18.2635H7.22039C4.08637 18.2635 1.54688 20.804 1.54688 23.937C1.54688 24.5656 2.05497 25.073 2.68158 25.073H15.0775C15.7061 25.073 16.2135 24.5656 16.2135 23.937C16.2135 20.804 13.6731 18.2635 10.54 18.2635ZM3.14155 23.5016C3.35991 21.4423 5.10551 19.8349 7.22039 19.8349H10.54C12.6552 19.8349 14.3737 21.4433 14.6192 23.5016H3.14155ZM17.2317 18.7873H14.814C16.3183 20.0215 17.2612 21.8679 17.2612 23.937C17.2612 24.356 17.1368 24.7423 16.9338 25.073H21.4516C22.0311 25.073 22.4993 24.6016 22.4993 23.9959C22.4993 21.1313 20.1552 18.7873 17.2317 18.7873ZM15.6897 16.692C17.7162 16.692 19.3564 15.0519 19.3564 13.0254C19.3564 10.9989 17.7162 9.35872 15.6897 9.35872C14.8677 9.35872 14.117 9.63879 13.5054 10.0956C13.8826 10.8198 14.1183 11.6307 14.1183 12.5016C14.1183 13.6644 13.7277 14.7323 13.0841 15.6022C13.7484 16.273 14.6683 16.692 15.6897 16.692Z"
          fill={props.color || 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2145_27746">
          <rect
            width="23.0476"
            height="34.8103"
            fill={props.color || 'white'}
            transform="translate(-0.548828)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

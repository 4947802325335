/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {BlockTopLeft} from './BlockTopLeft';
import {BlockTopRight} from './BlockTopRight';
import {M_SCREEN_DESKTOP} from '../../../../constants/ResponsiveSize';

export const BlockTop = () => {
  return (
    <div css={styles.container}>
      <BlockTopLeft />
      <BlockTopRight />
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    width: '100%',
    gap: '1rem',
    [M_SCREEN_DESKTOP]: {
      flexDirection: 'column',
    },
  }),
};

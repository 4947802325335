import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {DeleteModal} from './DeleteModal';
import {deleteProductionAreaModalAction} from '../../redux/modals';
import {useState} from 'react';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {deleteAreaProduction} from '../../api/fetchAreaProductions';
import {useTranslation} from 'react-i18next';
import {areaProductionUpdatedAction} from '../../redux/myRestaurantSection/areaProductions';
import {helperLabelTranslation} from '../../helpers/helperLabel';

export const DeleteProductionAreaModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const areaProductionUpdated = useAppSelector(
    s => s.myRestaurantSection.areaProductions.areaProductionUpdated,
  );

  const clientQuery = useQueryClient();

  if (!areaProductionUpdated) return null;

  const handleCancel = () => {
    dispatch(areaProductionUpdatedAction(null));
    dispatch(deleteProductionAreaModalAction(false));
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const res = await deleteAreaProduction(areaProductionUpdated?.uuid);

    if ([200, 204].includes(res?.status)) {
      helperDisplayToast(t('SUCCESS'), true);
      await clientQuery.invalidateQueries(['fetchAreaProductions']);
      handleCancel();
    } else {
      helperDisplayToast(res?.data?.message, false);
    }
    setIsLoading(false);
  };

  return (
    <DeleteModal
      title=""
      isLoading={isLoading}
      text={helperLabelTranslation(areaProductionUpdated)}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};

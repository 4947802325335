/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../../../styles/theme';
import {helperFormatPriceWithoutDivide} from '../../../../helpers/helperFormatPrice';
import {CustomerFileIcon} from '../../../../assets/svgs/CustomerFileIcon';
import {CustomerEuroIcon} from '../../../../assets/svgs/CustomerEuroIcon';
import {CustomerCardIcon} from '../../../../assets/svgs/CustomerCardIcon';
import {CustomerStarIcon} from '../../../../assets/svgs/CustomerStarIcon';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {helperDate} from '../../../../helpers/helperDate';
import {helperFormatNumber} from '../../../../helpers/helperFormatNumber';
import dayjs from 'dayjs';

export const CustomerDetailsInfos = () => {
  const selectedCustomer = useAppSelector(s => s.customer.selectedCustomer);
  const totalSpent = helperFormatPriceWithoutDivide(
    Number(selectedCustomer?.totalSpent) || 0,
  );

  const averageBasket =
    (selectedCustomer?.totalSpent || 0) / (selectedCustomer?.nbOrder || 0);

  const averageBasketFormated = helperFormatPriceWithoutDivide(averageBasket);

  return (
    <div css={styles.container}>
      <div>
        <p className="textRegular">INFORMATIONS CLIENT:</p>
        <div className="flex" style={{flexWrap: 'wrap', marginTop: 5, gap: 5}}>
          <div className="flex textRegular" css={styles.infos}>
            Nom:{' '}
            <p css={styles.infos} className="title03">
              {selectedCustomer?.lastname}
            </p>
          </div>
          <div className="flex textRegular" css={styles.infos}>
            Prénom:{' '}
            <p css={styles.infos} className="title03">
              {selectedCustomer?.firstname}
            </p>
          </div>
          <div className="flex textRegular" css={styles.infos}>
            Date de naissance:{' '}
            <p css={styles.infos} className="title03">
              {selectedCustomer?.birthdate
                ? dayjs(selectedCustomer?.birthdate).format('DD/MM/YYYY')
                : '--'}
            </p>
          </div>
          <div className="flex textRegular" css={styles.infos}>
            Code postal:{' '}
            <p css={styles.infos} className="title03">
              {selectedCustomer?.billingAddressPostCode}
            </p>
          </div>
          <div className="flex textRegular" css={styles.infos}>
            Téléphone:{' '}
            <p css={styles.infos} className="title03">
              {selectedCustomer?.phone}
            </p>
          </div>
          <div className="flex textRegular" css={styles.infos}>
            Mail:{' '}
            <p css={styles.infos} className="title03">
              {selectedCustomer?.email}
            </p>
          </div>
          <div className="flex textRegular" css={styles.infos}>
            Date de création:{' '}
            <p css={styles.infos} className="title03">
              {helperDate(
                selectedCustomer?.createdAt || new Date(),
                'Le DD/MM/YYYY à HH:mm',
              )}
            </p>
          </div>
        </div>
      </div>
      <div css={styles.card}>
        <p css={styles.cardTitle} className="textSemiBold">
          STATISTIQUE CLIENTS
        </p>
        <div className="flex-space-between">
          <div style={{textAlign: 'end'}}>
            <div className="flex textSemiBold" style={{gap: 10}}>
              <CustomerFileIcon /> Nombre de commandes
            </div>
            <p className="textSemiBold">
              (dernière:{' '}
              {selectedCustomer?.lastOrderDate
                ? helperDate(selectedCustomer?.lastOrderDate)
                : '--'}
              )
            </p>
            <b className="textBold" css={styles.value('#F7C761')}>
              {helperFormatNumber(selectedCustomer?.nbOrder || 0)}
            </b>
          </div>

          <div style={{textAlign: 'end'}}>
            <div className="flex textSemiBold" style={{gap: 10}}>
              <CustomerEuroIcon /> Total TTC dépensé
            </div>
            <b
              className="textBold"
              css={styles.value(THEME.colors.primary)}>{`${totalSpent}€`}</b>
          </div>

          <div style={{textAlign: 'end'}}>
            <div className="flex textSemiBold" style={{gap: 10}}>
              <CustomerCardIcon /> Panier moyen
            </div>
            <b
              className="textBold"
              css={styles.value(
                THEME.colors.primary,
              )}>{`${averageBasketFormated}€`}</b>
          </div>

          <div style={{textAlign: 'end'}}>
            <div className="flex textSemiBold" style={{gap: 10}}>
              <CustomerStarIcon /> Points de fidélité gagnés
            </div>
            <b className="textBold" css={styles.value(THEME.colors.yellow)}>
              {helperFormatPriceWithoutDivide(
                selectedCustomer?.loyaltyBalance || 0,
              )}
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    padding: '2%',
    flexDirection: 'column',
    justifyContent: 'space-around',
    display: 'flex',
    height: '100%',
  }),
  card: css({
    border: `1px solid ${THEME.colors.lightGray}`,
    borderRadius: 2,
    width: '70%',
    margin: '0 auto',
    padding: '2%',
  }),
  cardTitle: css({
    marginBottom: '3%',
  }),
  value: (color: string) =>
    css({
      fontSize: 32,
      fontWeight: '800',
      color,
    }),
  infos: css({
    gap: 3,
    fontSize: 10,
  }),
};

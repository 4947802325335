/** @jsxImportSource @emotion/react  */
import {css} from '@emotion/react';
import {LargeMapIcon} from '../../../../../assets/svgs/MapIcon';
import {TextVariant} from '../../../../../components/atoms/TextVariant';
import {THEME} from '../../../../../styles/theme';
import {helperDate} from '../../../../../helpers/helperDate';
import {helperConfirmDialog} from '../../../../../helpers/helperConfirmDialog';
import {deleteCard, duplicateCard} from '../../../../../api/fetchCards';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {DataGridActions} from '../../../../../components/newDatagrid/DataGridActions';
import {DataGridColumn} from '../../../../../components/newDatagrid/NewDataGrid';
import {ICardItem} from '@bill-app-types/b-types';

export const columnsCardList: DataGridColumn<ICardItem> = [
  {
    field: 'icon',
    headerName: '',
    headerClassName: 'textRegular',
    flex: 0.4,
    renderCell: () => {
      return (
        <div
          className="flex-align-center"
          style={{
            width: '100%',
            marginLeft: '10px',
          }}>
          <div className="flex-center" css={styles.icon}>
            <LargeMapIcon />
          </div>
        </div>
      );
    },
  },
  {
    field: 'label',
    headerName: 'Carte',
    headerClassName: 'textRegular',
    flex: 1,
  },
  {
    field: 'articleNumber',
    headerName: 'Articles',
    headerClassName: 'textRegular',
    flex: 0.5,
    renderCell: row => {
      return (
        <TextVariant
          text={row.articleNumber}
          style={styles.articles}
          variant="textSemiBold"
        />
      );
    },
  },
  {
    field: 'createdAt',
    headerName: "Date d'ajout",
    headerClassName: 'textRegular',
    flex: 0.8,
    valueGetter: row => {
      return helperDate(row.createdAt, 'DD/MM/YYYY');
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Date de modification',
    headerClassName: 'textRegular',
    flex: 0.8,
    valueGetter: row => {
      return helperDate(row.updatedAt, 'DD/MM/YYYY');
    },
  },
  {
    field: 'actions',
    headerName: '',
    flex: 0.4,
    renderCell: row => {
      const handleClickCopy = ({refreshing}: any) => {
        helperConfirmDialog({
          validateLabel: 'Dupliquer',
          onSubmit: async () => {
            const res = await duplicateCard(row.uuid);
            if (res && res.status === 204) {
              helperDisplayToast('Carte dupliquée', true);
              refreshing('fetchCards');
            } else {
              helperDisplayToast(
                res?.data?.message || 'Impossible de dupliquer la carte',
                false,
              );
            }
          },
        });
      };

      const handleClickDelete = ({refreshing}: any) => {
        helperConfirmDialog({
          onSubmit: async () => {
            const res = await deleteCard(row.uuid);
            if (res && res.status === 204) {
              helperDisplayToast('Carte supprimée', true);
              refreshing('fetchCards');
            } else {
              helperDisplayToast(
                res?.data?.message || 'Impossible de supprimer la carte',
                false,
              );
            }
          },
        });
      };

      return (
        <DataGridActions
          handleClickCopy={handleClickCopy}
          handleClickDelete={handleClickDelete}
          navigate={`/online/catalog/cards/${row.uuid}`}
          refreshing={'fetchCards'}
          showCopy
          authorization={{
            edit: BRAND_AUTHORIZATION.ALL,
            copy: BRAND_AUTHORIZATION.PARENT,
            delete: BRAND_AUTHORIZATION.PARENT,
          }}
        />
      );
    },
  },
];

const styles = {
  icon: css({
    backgroundColor: THEME.colors.bgLight,
    height: 68,
    width: 68,
    borderRadius: 2,
  }),
  articles: css({
    color: THEME.colors.primary,
  }),
  menus: css({
    color: THEME.colors.yellow,
  }),
};

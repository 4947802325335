import {
  IFetchAdminGroupResponse,
  IFetchAdminGroupsQuery,
  IFetchAdminUsersFiltersV2,
} from '@bill-app-types/b-types';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {helperLog} from '../helpers/helperLog';
import {IStaffGroup} from '../redux/myStaffSection/groups';
import {IStaffUser} from '../redux/myStaffSection/users';
import {axiosInstance} from './axiosInstance';

// USERS

export const fetchStaffUsers = async (data: IFetchAdminUsersFiltersV2) => {
  const query = helperGenerateQuery(data);
  const res = await axiosInstance
    .get(`/buser/backoffice/users${query}`, {
      params: {
        // orderBy: data?.orderBy,
        isArchived: data?.isArchived,
      },
    })
    .catch(err => {
      console.log('❌ fetchStaffUsers : ', err);
    });
  return res?.data?.data;
};

export const createStaffUser = async (data: IStaffUser) => {
  const res = await axiosInstance
    .post('/buser/backoffice/users', data)
    .catch(err => {
      console.log('❌ createStaffUser : ', err);
    });
  return res;
};

export const updateStaffUser = async (userUuid: string, data: IStaffUser) => {
  const res = await axiosInstance
    .put(`/buser/backoffice/users/${userUuid}`, data)
    .catch(err => {
      console.log('❌ updateStaffUser : ', err);
    });
  return res;
};

export const deleteStaffUser = async (userUuid: string) => {
  const res = await axiosInstance
    .delete(`/buser/backoffice/users/${userUuid}`)
    .catch(err => {
      console.log('❌ deleteStaffUser : ', err);
    });
  return res;
};

// GROUPS

export const fetchStaffGroups = async (data: IFetchAdminGroupsQuery) => {
  const query = helperGenerateQuery(data);
  const res = await axiosInstance
    .get<{
      data: IFetchAdminGroupResponse[];
    }>(`/buser/backoffice/groups${query}`, {
      params: {
        // orderBy: data?.orderBy,
        isArchived: data?.isArchived,
      },
    })
    .catch(err => {
      console.log('❌ fetchStaffGroups : ', err);
    });

  return res?.data?.data;
};

export const createStaffGroup = async (data: {
  label: string;
  isArchived?: boolean;
}) => {
  const res = await axiosInstance
    .post('/buser/backoffice/groups', data)
    .catch(err => {
      console.log('❌ createStaffGroup : ', err);
    });
  return res;
};

export const updateStaffPolicies = async (data: {
  groupUuid: string;
  policies: string[];
}) => {
  const res = await axiosInstance
    .post('/buser/backoffice/groups/policies', data)
    .catch(err => {
      helperLog({message: '❌ updateStaffPolicies : ', type: 'API_ERROR'});
    });
  return res?.data;
};

export const fetchStaffGroup = async (groupUuid: string) => {
  const res = await axiosInstance
    .get<{
      data: IStaffGroup[];
    }>(`/buser/backoffice/groups/${groupUuid}`)
    .catch(err => {
      helperLog({message: '❌ fetchStaffGroup : ', type: 'API_ERROR'});
    });

  return res?.data?.data;
};

export const updateStaffGroup = async (
  groupUuid: string,
  data: Partial<{label: string; isArchived?: boolean}>,
) => {
  const res = await axiosInstance
    .put(`/buser/backoffice/groups/${groupUuid}`, data)
    .catch(err => {
      helperLog({message: '❌ updateStaffGroup : ', type: 'API_ERROR'});
    });
  return res;
};

export const deleteStaffGroup = async (groupUuid: string) => {
  const res = await axiosInstance
    .delete(`/buser/backoffice/groups/${groupUuid}`)
    .catch(err => {
      helperLog({message: '❌ deleteStaffGroup : ', type: 'API_ERROR'});
    });
  return res;
};

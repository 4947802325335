import {SVGProps} from 'react';

export const BookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={13} height={13} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path
        d="M13 8.938v-7.72C13 .548 12.376 0 11.607 0H2.321C1.041 0 0 .911 0 2.031v8.938C0 12.089 1.041 13 2.321 13h9.983c.384 0 .696-.273.696-.61 0-.336-.312-.609-.696-.609h-.233v-1.7c.54-.168.929-.615.929-1.143Zm-2.321 2.843H2.32c-.511 0-.928-.364-.928-.812 0-.449.417-.813.928-.813h8.358v1.625Zm.928-2.844H2.321c-.33 0-.644.06-.928.17V2.031c0-.448.417-.812.928-.812h9.286v7.718Zm-6.99-5.28.36.755c.043.031.09.057.104.057.015 0 .086-.025.104-.057l.36-.756.891-.315c.009-.038.038-.055.038-.091 0-.035-.03-.075-.064-.09l-.865-.316-.333-.756c-.044-.032-.064-.057-.131-.057s-.06.025-.078.057l-.386.756-.839.315c-.06.015-.064.055-.064.091 0 .035.03.075.064.09l.839.316ZM6.036 6.07c0 .057.048.126.107.152l1.433.522.011.012.597 1.252c.03.051.107.093.172.093a.217.217 0 0 0 .174-.093l.6-1.26 1.441-.526c.058-.005.108-.07.108-.13 0-.057-.048-.125-.107-.15l-1.44-.527-.6-1.26a.217.217 0 0 0-.174-.094.218.218 0 0 0-.173.093l-.601 1.26-1.44.526c-.059.026-.108.094-.108.13Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={props.color} d="M0 0h13v13H0z" />
      </clipPath>
    </defs>
  </svg>
);

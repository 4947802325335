/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Center} from '../Structures/HeaderSection/Center';
import {Header} from '../../../../../components/layouts/Header';
import {ProductsAndMenuCatalog} from '../../../../../components/organisms/ProductsAndMenuCatalog';
import {RightBlock} from './RightBlock';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {addCategoryProduct} from '../../../../../api/fetchCategories';
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {structureSelectedAction} from '../../../../../redux/myCatalogSection/categories';
import {getStructureDetails} from '../../../../../api/fetchStructures';
import {ICategoryFetchReturn, STRUCTURE_TYPES} from '@bill-app-types/b-types';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import store from '../../../../../redux/storeConfig';
import {addExternalCategoryItem} from '../../../../../api/fetchExternalCategories';

export const SelectedStructure = withRedirectAndNavigation(() => {
  //
  const dispatch = useDispatch();
  const params = useParams();
  const clientQuery = useQueryClient();

  const {brandHasAuthorization} = useBrandAccess();

  const categorySelected = useAppSelector(
    s => s.myCatalogSection.categories.categorySelected,
  );
  const categoryProducts = useAppSelector(
    s => s.myCatalogSection.categories.categoryProducts,
  );

  const {data: structure} = useQuery(
    ['getStructureDetails', params?.uuid],
    () => getStructureDetails(params?.uuid),
    {
      enabled: !!params.uuid,
    },
  );

  useEffect(() => {
    if (structure) {
      dispatch(structureSelectedAction(structure));
    }
  }, [structure, dispatch]);

  const handleProductClick = async (product: any) => {
    if (!categorySelected) {
      return helperDisplayToast('Aucune catégorie sélectionnée');
    }
    const productFinded = categoryProducts?.find(p => p.uuid === product.uuid);
    const structureSelected =
      store.getState().myCatalogSection.categories.structureSelected;

    const data = {
      itemUuid: product.uuid,
      categoryUuid: categorySelected?.uuid,
      type: 1,
    };

    if (!productFinded) {
      let res;
      if (structureSelected?.type !== STRUCTURE_TYPES.EXTERNAL) {
        res = await addCategoryProduct(data);
      } else {
        res = await addExternalCategoryItem(categorySelected?.uuid, data);
      }

      if (res && res.status === 204) {
        helperDisplayToast(
          res?.data?.message || 'Item ajoutée à la catégorie',
          true,
        );

        clientQuery.invalidateQueries([
          `fetchCategoryProducts-${categorySelected?.uuid}`,
        ]);
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible d'ajouter l'item à la catégorie",
          false,
        );
      }
    }
  };

  const handleIsDisabled = (category: ICategoryFetchReturn) => {
    return (
      (categoryProducts?.find(
        (item: ICategoryFetchReturn) => item?.uuid === category.uuid,
      ) &&
        !!categorySelected) ||
      false
    );
  };

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} />
      <div css={styles.content}>
        {hasAuthorization && (
          <ProductsAndMenuCatalog
            onClickProduct={handleProductClick}
            handleIsDisabled={handleIsDisabled}
          />
        )}
        <RightBlock categories={structure?.categories || []} />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: css({
    padding: '2vh',
    height: '87%',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  section: css({
    width: '49%',
    overflow: 'auto',
    transition: 'all 0.3s ease',
  }),
};

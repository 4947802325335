export const SCALES = [
  {
    hours: 6, // 6 heures
    scales: ['30m', '1h'],
  },
  {
    hours: 25, // 25 heures
    scales: ['1h'],
  },
  {
    hours: 744, // 4 semaines => 744 heures
    scales: ['1d', '1w'],
  },
  {
    hours: 4386, // 6 mois => 4386 heures
    scales: ['1w', '1M'],
  },
  {
    hours: 17520, // 2 ans => 17520 heures
    scales: ['1M', '1y'],
  },
  {
    hours: Infinity,
    scales: ['1y'],
  },
];

export const SCALES_LABELS: any = {
  // '15m': '15 minutes',
  '30m': '30 minutes',
  '1h': 'Heure',
  '1d': 'Journée',
  '1w': 'Semaine',
  '1M': 'Mois',
  '1y': 'Année',
};

/** @jsxImportSource @emotion/react */
import {Link} from 'react-router-dom';
import {helperFormatPrice} from '../../../../../helpers/helperFormatPrice';
import {THEME} from '../../../../../styles/theme';
import {SqsTransaction} from '../../../../../api/fetchSqsHistory';

export const columnsMovements = [
  {
    field: 'paiment_method',
    headerName: 'Mode de paiement',
    flex: 1.5,
    valueGetter: (row: SqsTransaction) => {
      return row?.mode;
    },
  },
  {
    field: 'code',
    headerName: 'Code',
    flex: 1,
  },

  {
    field: 'posReceiptId',
    headerName: 'N° de ticket',
    flex: 1,
    renderCell: (row: SqsTransaction) => {
      const value = row.amount;

      return (
        <Link
          to={`/online/accounting/archive/receipts?receiptId=${row.receipt_id}`}
          css={styles.posReceiptId(value)}
          className="textSemiBold">
          {row.receipt_id}
        </Link>
      );
    },
  },
  {
    field: 'date',
    headerName: "Date d'opération",
    flex: 1,
    valueGetter: (row: SqsTransaction) => {
      return row?.created_at.split(' ')[0];
    },
  },
  {
    field: 'time',
    headerName: "Heure d'opération",
    flex: 1,
    valueGetter: (row: SqsTransaction) => {
      return row?.created_at.split(' ')[1];
    },
  },
  {
    field: 'amount',
    headerName: 'Montant',
    flex: 1,
    renderCell: (row: SqsTransaction) => {
      const amount = row.amount;
      return (
        <span
          css={{
            color: Number(amount) > 0 ? THEME.colors.green : THEME.colors.red,
          }}>
          {helperFormatPrice(Number(amount))}€
        </span>
      );
    },
  },
];

const styles = {
  posReceiptId: (value: any) => ({
    color: value >= 0 ? 'var(--text-color)' : THEME.colors.red,
    textDecoration: value >= 0 ? 'none' : 'line-through',
  }),
};

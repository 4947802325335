export const WarningCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
      <rect width={20} height={20} fill="#EC5174" rx={10} />
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M9.974 6.274c.425 0 .745.32.745.719v3.353c0 .398-.32.718-.745.718a.735.735 0 0 1-.719-.718V6.993c0-.399.347-.719.719-.719Zm.985 6.706c0 .53-.428.958-.985.958a.975.975 0 0 1-.958-.958c0-.53.455-.958.958-.958.556 0 .985.428.985.958ZM2.814 8.986l2.77-4.551A2.143 2.143 0 0 1 7.4 3.4h5.176c.752 0 1.452.392 1.841 1.035l2.77 4.551c.419.689.419 1.551 0 2.24l-2.77 4.55a2.153 2.153 0 0 1-1.84 1.036H7.398a2.142 2.142 0 0 1-1.814-1.036l-2.77-4.55a2.152 2.152 0 0 1 0-2.24Zm1.228.746a.72.72 0 0 0 0 .748l2.77 4.55a.709.709 0 0 0 .587.345h5.176a.719.719 0 0 0 .614-.344l2.77-4.55a.716.716 0 0 0 0-.75l-2.77-4.55a.716.716 0 0 0-.614-.344H7.4a.706.706 0 0 0-.587.345l-2.77 4.55Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M2.5 2.5h15v15h-15z" />
        </clipPath>
      </defs>
    </svg>
  );
};

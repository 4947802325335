import {useTranslation} from 'react-i18next';
import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {useAppDispatch} from '../../../../../hooks/useReduxHook';
import {addRecipeModalAction} from '../../../../../redux/modals';

export const Center = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const handleOpenModal = () => {
    dispatch(addRecipeModalAction(true));
  };

  return (
    <>
      <ButtonCustom label={t('ADD_RECIPE')} onClick={handleOpenModal} />
    </>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Title} from '../../../../components/atoms/Title';
import {DataGridFooter} from '../../../../components/newDatagrid/DataGridFooter';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {helperFormatPrice} from '../../../../helpers/helperFormatPrice';
import {usePaymentTotal} from '../../../../hooks/usePaymentTotal';
import {columnsPaymentMode} from './columns';

export const SectionPaymentMode = () => {
  const {paymentTotal, paymentTotalLoading} = usePaymentTotal();

  const paymentTotalList = paymentTotal?.totalsByPaymentMethod || [];

  return (
    <div css={styles.container}>
      <Title title="Ventilation par mode de règlement" />
      <NewDataGrid
        columns={columnsPaymentMode}
        rows={paymentTotalList || []}
        rowHeight={35}
        loading={paymentTotalLoading}
        minHeight={100}
        footer={
          <DataGridFooter
            columns={[
              'TOTAL',
              '',
              '',
              `${helperFormatPrice(paymentTotal?.totalAmount)}€`,
            ]}
            height={35}
            size={[2, 1, 1, 1]}
          />
        }
        stickyFooter
      />
    </div>
  );
};

const styles = {
  container: css({
    height: '95%',
    '@media (max-width: 1024px)': {
      marginBottom: '5%',
    },
  }),
};

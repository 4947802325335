import {useTranslation} from 'react-i18next';
import {ButtonCustom} from '../../../../../../components/atoms/ButtonCustom';
import {THEME} from '../../../../../../styles/theme';
import {useLocation, useNavigate} from 'react-router-dom';
import {EDIT_MENU_TABS_DATA} from './Bottom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useReduxHook';
import {updateMenu} from '../../../../../../api/fetchMenus';
import {helperDisplayToast} from '../../../../../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {menuPreRegisterAction} from '../../../../../../redux/myCatalogSection/menus';
import {updateItemImage} from '../../../../../../api/fetchImage';

export const EditMenuHeaderCenter = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();

  const menuPreRegister = useAppSelector(
    s => s.myCatalogSection.menus.menuPreRegister,
  );

  const link = '/' + location.pathname.split('/').pop();
  const linkIndex = EDIT_MENU_TABS_DATA.findIndex(
    item => item.link === link || (link === '/edit' && item.link === ''),
  );
  const hasNextLink = linkIndex < EDIT_MENU_TABS_DATA.length - 1;

  const handleSave = () => {
    if (hasNextLink) {
      navigate(
        '/online/catalog/menus/edit' +
          EDIT_MENU_TABS_DATA[(linkIndex || 0) + 1].link,
      );
      return;
    }
  };

  const handleUpdate = async () => {
    if (
      menuPreRegister.label &&
      menuPreRegister.backofficeLabel &&
      menuPreRegister.productionLabel &&
      menuPreRegister.description
    ) {
      const res = await updateMenu(menuPreRegister?.uuid, {
        ...menuPreRegister,
        price: Math.round((menuPreRegister?.price || 0) * 100),
      });
      if (res?.status === 204) {
        helperDisplayToast('Menu modifié', true);
        navigate('/online/catalog/menus');
        dispatch(menuPreRegisterAction(null));
        if (menuPreRegister.updatedImage) {
          const res = await updateItemImage(
            menuPreRegister.uuid,
            menuPreRegister?.updatedImage?.file,
          );
          if (res?.status === 204) {
            clientQuery.invalidateQueries(['fetchMenus']);
          } else {
            helperDisplayToast(
              res?.data?.message || "Impossible de modifier l'image",
              false,
            );
          }
        }
      } else {
        helperDisplayToast(
          res?.data?.message || 'Une erreur est survenue',
          false,
        );
      }
    } else {
      helperDisplayToast(
        'Tous les champs obligatoires doivent être complétés ',
        false,
      );
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '1rem',
        marginBottom: '1rem',
      }}>
      <ButtonCustom
        onClick={handleUpdate}
        label={t('REGISTER_AND_CONTINUE_CREATION_LATER')}
      />
      <ButtonCustom
        label={t(!hasNextLink ? 'SAVE' : 'CONTINUE_CREATION')}
        backgroundColor={THEME.colors.green}
        onClick={!hasNextLink ? handleUpdate : handleSave}
      />
    </div>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {YellowStarIcon} from '../../assets/svgs/YellowStarIcon';
import {THEME} from '../../styles/theme';

export const BadgeStar = () => {
  return (
    <div className="flex-center" css={styles.badge}>
      <YellowStarIcon color={THEME.colors.white} />
    </div>
  );
};

const styles = {
  badge: css({
    width: 25,
    height: 25,
    backgroundColor: THEME.colors.yellow,
    borderRadius: 2,
  }),
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {Title} from '../../../../../components/atoms/Title';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {useVendorStructure} from '../../../../../hooks/useVendorStructure';
import {
  SortableListVerticalContainer,
  THandleDragAndDrop,
} from '../../../../../components/organisms/SortableListVerticalContainer';
import {CategoryItem} from './CategoryItem';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {updateCategoriesPosition} from '../../../../../api/fetchCategories';

export const RightBlock = () => {
  const {t} = useTranslation();
  const showProducts = useAppSelector(s => s.posCategories.showProducts);
  const {vendorStructure: vendorStructures} = useVendorStructure();

  const handleDragAndDrop: THandleDragAndDrop = async ({items}) => {
    if (!items?.length) {
      return;
    }

    const categoryUuids = items.map(item => item.uuid);

    const res = await updateCategoriesPosition(categoryUuids);

    if (res?.status === 204) {
      helperDisplayToast(t('SUCCESS'), true);
    }
  };

  return (
    <div
      css={[
        styles.container,
        {
          width: showProducts ? '49%' : '97%',
        },
      ]}>
      <Title title={t('VENDOR_STRUCTURES')} />

      <div css={styles.content}>
        {vendorStructures && (
          <SortableListVerticalContainer
            data={vendorStructures}
            // @ts-ignore
            ItemComponent={CategoryItem}
            handleDragAndDrop={handleDragAndDrop}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    height: '100%',
  }),
  content: css({
    paddingTop: 10,
    overflow: 'auto',
    height: '95%',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {editLoyaltyModalAction} from '../../redux/modals';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {updatedItemAction} from '../../redux/myModulesSection/loyalties';
import {ImageCustom} from '../atoms/ImageCustom';
import {helperFormatPrice} from '../../helpers/helperFormatPrice';
import {updateItemLoyalty} from '../../api/fetchItems';
import {ITEM_TYPES} from '@bill-app-types/b-types';
import {BadgeStar} from '../atoms/BadgeStar';

export const EditLoyaltyModal = () => {
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();

  const updatedItem = useAppSelector(
    state => state.myModulesSection.loyalties.updatedItem,
  );

  const handleOnCancel = () => {
    dispatch(editLoyaltyModalAction(false));
    dispatch(updatedItemAction(null));
  };

  const handleSuccess = (res: {data: {message: string}}) => {
    handleOnCancel();
    helperDisplayToast(res?.data?.message || 'Fidélité enregistrée', true);
  };

  const handleSubmit = async (
    data: {
      loyaltyCost: number;
      loyaltyGain: number;
    },
    {setSubmitting, resetForm}: any,
  ) => {
    const res = await updateItemLoyalty(updatedItem.uuid, {
      loyaltyCost: data.loyaltyCost,
      loyaltyGain: data.loyaltyGain,
    });
    if (res && res.status === 204) {
      handleSuccess(res);
      resetForm();
      clientQuery.invalidateQueries(['getItems']);
    } else {
      helperDisplayToast(
        res?.data?.message || 'Impossible de modifier la fidélité',
        false,
      );
    }
  };

  return (
    <ModalWrapper
      title={'Points de fidélité'}
      minWidth="28%"
      validateLabel={'Enregistrer'}
      cancelLabel={'Annuler'}
      onCancel={handleOnCancel}
      onSubmit={handleSubmit}
      initialValues={updatedItem}>
      <p css={styles.title} className="textRegular">
        {`${
          updatedItem.type === ITEM_TYPES.COURSE ? 'Produit' : 'Menu'
        } ${helperFormatPrice(updatedItem.price)}€`}
      </p>
      <div css={styles.img}>
        <ImageCustom src={updatedItem?.image} />
      </div>
      <FieldCustomInput
        iconLeft={<BadgeStar />}
        label={'Points rapportés'}
        name="loyaltyGain"
      />
      <FieldCustomInput
        iconLeft={<BadgeStar />}
        label={'Points pour gratuité'}
        name="loyaltyCost"
      />
    </ModalWrapper>
  );
};

const styles = {
  title: css({
    color: '#A7A7AB',
    textAlign: 'center',
  }),
  img: css({
    width: 104,
    height: 104,
    border: '3px solid #fff',
    borderRadius: 3,
    margin: 'auto',
    marginTop: 25,
    marginBottom: 35,
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {helperDisplayToast} from '../../../helpers/helperToast';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {Formik} from 'formik';
import {AuthLayout} from '../components/Layout/AuthLayout';
import {useTranslation} from 'react-i18next';
import {InputLabel} from '../components/InputLabel';
import {EyesIcon} from '../components/Icons/EyesIcon';
import {postInitPassword} from '../../../api/auth';
import {useEffect} from 'react';

export const InitPassword = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryToken = searchParams.get('token');

  useEffect(() => {
    if (!queryToken) navigate('/');
  });

  const handleSubmit = async (
    values: {
      password: string;
      confirmPassword: string;
    },
    {setSubmitting, resetForm}: any,
  ) => {
    try {
      const {password, confirmPassword} = values;

      if (!password || !confirmPassword) {
        helperDisplayToast(t('PASSWORD_IS_EMPTY'));
        setSubmitting(false);
        return;
      }
      if (password !== confirmPassword) {
        helperDisplayToast(t('PASSWORDS_ARE_NOT_IDENTICAL'));
        setSubmitting(false);
        return;
      }
      if (!queryToken) return;
      setSubmitting(true);
      const res = await postInitPassword(password, queryToken);
      if (res.status === 204) {
        helperDisplayToast(t('PASSWORD_INIT_SUCCESSFULLY'), true);
        resetForm();
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } else {
        if (!res.data.code && res.status === 401)
          helperDisplayToast(t('USER_NOT_FOUND'));
        else
          switch (res.data.code) {
            case 'use-use-001':
              helperDisplayToast(t('USER_NOT_FOUND'));
              break;
            case 'use-use-002':
              helperDisplayToast(t('PASSWORD_ALREADY_INIT'));
              break;
            default:
              helperDisplayToast(t('PASSWORD_INIT_FAILED'));
              break;
          }
        setSubmitting(false);
      }
    } catch (error) {
      helperDisplayToast(t('PASSWORD_INIT_FAILED'));
    }
  };

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
        showPassword: false,
        showPasswordConfirm: false,
      }}
      onSubmit={handleSubmit}>
      {({values, handleChange, isSubmitting, handleSubmit}) => (
        <AuthLayout
          animType="newPassword"
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}>
          <InputLabel
            label={t('NEW_PASSWORD')}
            name="password"
            type={values.showPassword ? 'text' : 'password'}
            placeholder={t('YOUR_PASSWORD')}
            showIcon
            Icon={EyesIcon}
            value={values.password}
            onChange={handleChange}
            onClickIcon={() =>
              handleChange({
                target: {
                  name: 'showPassword',
                  value: !values.showPassword,
                },
              })
            }
          />
          <InputLabel
            label={t('PASSWORD_CONFIRMATION')}
            name="confirmPassword"
            type={values.showPasswordConfirm ? 'text' : 'password'}
            placeholder={t('YOUR_PASSWORD')}
            showIcon
            Icon={EyesIcon}
            value={values.confirmPassword}
            onChange={handleChange}
            onClickIcon={() =>
              handleChange({
                target: {
                  name: 'showPasswordConfirm',
                  value: !values.showPasswordConfirm,
                },
              })
            }
          />

          <div className="flex-justify-end" css={styles.formInputsFooter}>
            <Link to="/">{t('RETURN_TO_THE_CONNECTION')}</Link>
          </div>
        </AuthLayout>
      )}
    </Formik>
  );
};

const styles = {
  formInputsFooter: css(`
    width: 100%;
    margin: 1rem 0 2.5rem;

    p,a{
      font-size: 10px;
    }
    

    p{

      span{
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid #A7A7AB;
        margin-right: 5px;

        &.active {
          background: #A7A7AB;
        }
      }
      font-family: 'Montserrat-SemiBold', sans-serif;
      color:#A7A7AB;
    }


    a{
      text-decoration: underline;
    }
  `),

  title: css({
    textAlign: 'center',
  }),

  logo: css({
    width: '80px',
    height: '80px',
    objectFit: 'contain',
  }),
};

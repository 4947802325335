import axios, { AxiosInstance } from 'axios';
import {env} from '../app.config';
import {renewToken} from './auth';
import {cancelRefreshToken} from '../helpers/helperRefreshToken';

export const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.request.use(async config => {
  const authToken = localStorage.getItem('auth_token');

  config.baseURL = env.API.BASE_URL;
  if (config.headers) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  return config;
});

let refreshingFunc:any = undefined;

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {    
    const originalConfig = error.config;
    if (
      error?.response?.status === 401 &&
      error?.response?.data === 'invalidToken'
    ) {

      try {
        
        if (!refreshingFunc)
            refreshingFunc = renewToken();
            
        const [newToken, newRefreshToken] = await refreshingFunc;
        
        localStorage.setItem("auth_token", newToken);
        localStorage.setItem("auth_refresh_token", newRefreshToken);

        originalConfig.headers.Authorization = `Bearer ${newToken}`;

        // retry original request
        try {
            return await axios.request(originalConfig);
        } catch(error:any) {
          if(  
            error?.response?.status === 401 &&
            error?.response?.data === 'invalidToken'
            ){
            cancelRefreshToken();
          }
        }
      } catch (err) {
        cancelRefreshToken();
      } finally {
          refreshingFunc = undefined;
      }
    }
    return error.response;
  },
);

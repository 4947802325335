/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext, useEffect, useState} from 'react';
import {Title} from '../../../../../../components/atoms/Title';
import {UtilsContext} from '../../../../../../contexts/UtilsContext';
import {OptionsList} from './OptionsList';
import {DatagridPagination} from '../../../../../../components/newDatagrid/DatagridPagination';
import {getCatalogOptions} from '../../../../../../api/fetchCatalogOptions';
import {useQuery} from '@tanstack/react-query';
import {useDebounce} from '../../../../../../hooks/useDebounce';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';

export const OptionsSection = () => {
  const {t} = useContext<any>(UtilsContext);

  const search = useAppSelector(s => s.filter.search);
  const orderBy = useAppSelector(s => s.filter.orderBy);
  const isArchived = useAppSelector(s => s.filter.isArchived);

  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [limit] = useState(60);

  const offset = (page - 1) * limit;

  const debouncedSearchTerm = useDebounce(search, 500);

  const {data, isLoading} = useQuery(
    [
      'getCatalogOptions',
      debouncedSearchTerm,
      offset,
      limit,
      orderBy,
      isArchived,
    ],
    () =>
      getCatalogOptions({
        limit,
        offset,
        search: debouncedSearchTerm,
        orderBy,
        isArchived,
      }),
  );

  useEffect(() => {
    setRowCount(oldRowCount =>
      data?.totalItems !== undefined ? data?.totalItems : oldRowCount,
    );
  }, [data?.totalItems]);

  return (
    <div css={styles.container}>
      <Title title={t('OPTIONS')} />
      <div css={styles.content} id="scrollableDiv">
        <OptionsList data={data?.items || []} />
      </div>
      <DatagridPagination
        page={page}
        totalRows={rowCount}
        rowPerPage={limit}
        setPage={setPage}
        isLoading={isLoading}
        paginationShowMore={false}
        // updateRowPerPage={}
      />
    </div>
  );
};

const styles = {
  container: css({
    padding: '2vh',
    height: '87vh',
    display: 'flex',
    flexDirection: 'column',
  }),
  content: css({
    overflow: 'auto',
    height: '100%',
  }),
};

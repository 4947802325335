import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  policiesList: any[];
}

const initialState: ISlice = {
  policiesList: [],
};

const slice = createSlice({
  name: 'policies',
  initialState,
  reducers: {
    policiesListAction: (state, action) => {
      state.policiesList = action.payload;
    },
  },
});

export const {policiesListAction} = slice.actions;

export default slice.reducer;

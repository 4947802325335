import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';

export const fetchPosDiscountsByUsers = async (data: {
  from: Date;
  to: Date;
}) => {
  const query = helperGenerateQuery(data, {
    dateFormat: 'YYYY-MM-DD HH:mm',
  });
  const res: any = await axiosInstance
    .get(`/pos/backoffice/stats/global-discount/pos-users${query}`)
    .catch(err => {
      console.log('❌ fetchPosDiscountsByUsers : ', err);
    });

  return res?.data?.data;
};

export const fetchPosDiscountsByReasons = async (data: {
  from: Date;
  to: Date;
}) => {
  const query = helperGenerateQuery(data, {
    dateFormat: 'YYYY-MM-DD HH:mm',
  });
  const res: any = await axiosInstance
    .get(`/pos/backoffice/stats/global-discount/reasons${query}`)
    .catch(err => {
      console.log('❌ fetchPosDiscountsByReasons : ', err);
    });

  return res?.data?.data;
};

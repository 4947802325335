/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {SubMenuHeader} from '../../SubMenus/SubMenuHeader';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {DropDownBodyAnim} from '../../../../../../components/layouts/DropDownBodyAnim';
import {useSubmenuProduct} from '../../../../../../hooks/useSubmenuProduct';
import {IModifierGetReturn} from '@bill-app-types/b-types';
import {helperConfirmDialog} from '../../../../../../helpers/helperConfirmDialog';
import {helperDisplayToast} from '../../../../../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {
  deleteItemModifier,
  updateItemsPosition,
} from '../../../../../../api/fetchItems';
import {Loader} from '../../../../../../components/atoms/Loader';
import {ProductList} from '../../../../../../components/molecules/ProductList';
import {THandleDragAndDrop} from '../../../../../../components/organisms/SortableListVerticalContainer';

export const SubMenuList = ({
  item,
  index,
}: {
  item: IModifierGetReturn;
  index: number;
}) => {
  const clientQuery = useQueryClient();

  const menuPreRegister = useAppSelector(
    s => s.myCatalogSection.menus.menuPreRegister,
  );

  const openedSubmenu = useAppSelector(
    s => s.myCatalogSection.menus.openedSubmenu,
  );
  const isOpened = item?.uuid === openedSubmenu;

  const {data, isLoading} = useSubmenuProduct({
    isOpened,
    submenu: item,
  });

  const handleClickDeleteComposition = async (submenu: any) => {
    helperConfirmDialog({
      onSubmit: async () => {
        const res = await deleteItemModifier(
          menuPreRegister.uuid,
          submenu.uuid,
        );
        if (res && res.status === 204) {
          helperDisplayToast('Sous-menu retiré', true);
          clientQuery.invalidateQueries([`getItemSubmenus`]);
        } else {
          helperDisplayToast(
            res.data?.message || 'Impossible de retirer le sous-menu',
            false,
          );
        }
      },
    });
  };

  const handleDragAndDrop: THandleDragAndDrop = async ({items}) => {
    if (!items?.length) return;

    const res = await updateItemsPosition(item?.uuid, items);

    if (res?.status === 204) {
      helperDisplayToast('Position modifiée', true);
    } else {
      helperDisplayToast('Impossible de modifier la position', false);
    }
  };

  return (
    <div css={styles.container}>
      <SubMenuHeader
        submenu={{...item, position: index + 1}}
        isOpened={isOpened}
        withDeleteAction
        handleDeleteAction={handleClickDeleteComposition}
      />
      <DropDownBodyAnim isOpened={isOpened}>
        <div css={styles.productList}>
          {isLoading && <Loader />}

          {!isLoading && (
            <ProductList
              data={data || []}
              // showCross={hasAuthorization}
              draggable
              bg="white"
              showAdditionalPrice
              showActions={false}
              showOverlay={false}
              handleOnClick={() => {}}
              handleDragAndDrop={handleDragAndDrop}
            />
          )}
        </div>
      </DropDownBodyAnim>
    </div>
  );
};

const styles = {
  container: css({
    marginBottom: 5,
  }),
  productList: css({
    marginTop: '0.313rem',
    padding: '0.625rem 2rem',
    backgroundColor: 'white',
  }),
};

/**  @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../../../../components/atoms/TextVariant';
import {THEME} from '../../../../../../../styles/theme';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../hooks/useReduxHook';
import {currentMetricModeAction} from '../../../../../../../redux/filter';

export const CumulAndAverageMode = () => {
  const dispatch = useAppDispatch();

  const salesGraphSection = useAppSelector(
    s => s.myStatsSection.sales.salesGraphSection,
  );

  const currentMetricMode = useAppSelector(s => s.filter.currentMetricMode);

  const handleSelectCurrentMetricMode = (value: 'total' | 'average') => {
    dispatch(currentMetricModeAction(value));
  };

  if (salesGraphSection === 'MEDIUM') {
    return null;
  }

  return (
    <div className="flex flex-center" css={styles.container}>
      <div
        css={styles.switch(currentMetricMode === 'average')}
        className="flex-center"
        onClick={() => handleSelectCurrentMetricMode('average')}>
        <TextVariant
          text="Moyenne"
          variant="textSemiBold"
          style={{
            color: currentMetricMode === 'average' ? 'white' : '#A7A7AB',
          }}
        />
      </div>
      <div
        css={styles.switch(currentMetricMode === 'total')}
        className="flex-center"
        onClick={() => handleSelectCurrentMetricMode('total')}>
        <TextVariant
          text="Cumulé"
          variant="textSemiBold"
          style={{
            color: currentMetricMode === 'total' ? 'white' : '#A7A7AB',
          }}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    border: `1px solid ${THEME.colors.lightGray}`,
    borderRadius: '5px',
    padding: '0.15rem 0.2rem',
    gap: '0.13rem',
    width: '20%',
    margin: '1rem auto',
  }),
  switch: (active: boolean) =>
    css({
      flex: 1,
      padding: '0.29rem',
      backgroundColor: active ? THEME.colors.primary : 'transparent',
      borderRadius: '5px',
      cursor: 'pointer',
    }),
};

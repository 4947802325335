import {useNavigate, useParams} from 'react-router-dom';

import {BWebAppForm} from './BWebAppForm';
import {ModuleHeader} from '../ModuleHeader';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {useQuery} from '@tanstack/react-query';
import {
  IModuleParameter,
  fetchModuleParameter,
  updateModuleParameter,
} from '../../../../api/fetchModuleDetails';
import {useEffect, useState} from 'react';
import {AbsoluteLoader} from '../../../../components/layouts/AbsoluteLoader';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {moduleSelectedAction} from '../../../../redux/myModulesSection/subscriptions';

const BWebAppSection = () => {
  const {pathname} = useParams();
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form, setForm] = useState<IModuleParameter>({
    home_title: '',
    home_desc: '',
    slider_title: '',
    slider_desc: '',
    background: '',
  } as IModuleParameter);

  const brandId = useAppSelector(s => s.brand.brand?.id);

  const subscriptions = useAppSelector(
    s => s.myModulesSection.subscriptions.subscriptions,
  );

  const {data, isLoading, refetch} = useQuery(
    ['fetchModule', brandId, pathname],
    () =>
      fetchModuleParameter({
        brandId,
        moduleCode: 'b_webapp',
      }),
    {
      enabled: !!brandId && pathname === 'bwebapp',
    },
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const {name, value} = e.target;

    if (name === 'background' && 'files' in e.target && e.target.files?.[0]) {
      setForm({
        ...form,
        [name]: e.target.files[0],
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
    }
  };

  const handleCancel = () => {
    navigate('/online/modules');
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    for (const key in form) {
      if (key === 'background') {
        formData.append(
          key,
          form.background instanceof File ? form.background : String(null),
        );
      } else {
        formData.append(key, String(form[key as keyof typeof form] ?? null));
      }
    }

    setLoading(true);

    const res = await updateModuleParameter({
      brandId,
      moduleCode: 'b_webapp',
      parameterId: String(form.id),
      data: formData,
    });

    if (res?.status === 200) {
      await refetch();
      helperDisplayToast(res.message, true);
      handleCancel();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      setForm({
        id: data?.id,
        brand_id: data?.brand_id,
        home_title: data?.home_title,
        home_desc: data?.home_desc,
        slider_title: data?.slider_title,
        slider_desc: data?.slider_desc,
        background: data?.background,
        out_of_stock_label: data?.out_of_stock_label,
        theme: data?.theme,
        suggested_course_label: data?.suggested_course_label,
        additional_sale_label: data?.additional_sale_label,
        content_background: data?.content_background,
        language_id: data?.language_id || 1,
        color_light: data?.color_light,
        color_dark: data?.color_dark,
        font_id: data?.font_id,
        navigation_type: data?.navigation_type,
        has_home_infos: data?.has_home_infos,
        has_top_slider: data?.has_top_slider,
        has_allergens_selection: data?.has_allergens_selection,
        has_order_comment: data?.has_order_comment,
      });
    }
  }, [data]);

  useEffect(() => {
    if (pathname !== 'bwebapp') {
      navigate('/online/modules');
    } else {
      const module = subscriptions?.find(
        (item: any) => item.code === 'b_webapp',
      );
      dispatch(moduleSelectedAction(module));
    }
  }, [pathname, navigate, subscriptions]);

  if (!pathname) return null;

  return (
    <>
      <ModuleHeader
        moduleActiveName={String(pathname)}
        loading={loading}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      <div>
        <BWebAppForm form={form} onChange={handleChange} />
      </div>
      {isLoading && <AbsoluteLoader />}
    </>
  );
};

export default BWebAppSection;

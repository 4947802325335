/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {ToggleButtonSections} from '../../../../../components/molecules/ToggleButtonSections';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {
  addMenuModalAction,
  addSubMenuModalAction,
} from '../../../../../redux/modals';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';

export const Center = () => {
  const dispatch = useAppDispatch();
  const sectionShow = useAppSelector(s => s.app.sectionShow);

  const {brandHasAuthorization} = useBrandAccess();

  const data = ['Menus', 'Sous-Menu'];

  const handleClick = () => {
    if (sectionShow === 'Menus') {
      dispatch(addMenuModalAction(true));
    } else {
      dispatch(addSubMenuModalAction(true));
    }
  };

  return (
    <div css={styles.container} className="flex-space-between">
      <ToggleButtonSections sections={data} defaultSection={'Menus'} />
      <ButtonCustom
        label={`Ajouter un ${sectionShow === 'Menus' ? 'menu' : 'sous-menu'}`}
        onClick={handleClick}
        disabled={!brandHasAuthorization(BRAND_AUTHORIZATION.PARENT)}
      />
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
    marginTop: '3vh',
    marginBottom: '2vh',
  }),
};

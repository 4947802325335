import {IGetModifierDetailsResponse} from '@bill-app-types/b-types';
import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  showSection: string;
  updateMode: boolean;
  updatedCategory:
    | (IGetModifierDetailsResponse & {
        label: string;
        backofficeLabel: string;
        maximumValue: number;
        minimumValue: number;
      })
    | null;
  updatedOption: any;
}

const initialState: ISlice = {
  showSection: 'OPTION_CATEGORY',
  updateMode: false,
  updatedOption: null,
  updatedCategory: null,
};

const slice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    showSectionAction(state: any, action: any) {
      state.showSection = action.payload;
    },
    updateModeAction(state, action) {
      state.updateMode = action.payload;
    },
    updatedOptionAction(state, action) {
      state.updatedOption = action.payload;
    },
    updatedCategoryAction(state, action) {
      state.updatedCategory = action.payload;
    },
    updatedCategoryChangeAction(state: any, action) {
      const {name, value} = action.payload;
      if (state.updatedCategory) {
        state.updatedCategory[name] = value;
      }
    },
  },
});

export const {
  showSectionAction,
  updateModeAction,
  updatedOptionAction,
  updatedCategoryAction,
  updatedCategoryChangeAction,
} = slice.actions;

export default slice.reducer;

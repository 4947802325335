/** @jsxImportSource @emotion/react */
import {forwardRef} from 'react';
import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {THEME} from '../../styles/theme';
import {LoopIcon} from '../../assets/svgs/LoopIcon';
import {CrossFilterIcon} from '../../assets/svgs/CrossFilterIcon';

interface IFilterInputProps {
  placeholder?: string;
  type?: string;
  name: string;
  marginLeft?: string | number;
  marginRight?: string | number;
  width?: any;
  onChange: any;
  value?: string;
  withBorder?: boolean;
  onReset?: () => void;
}

export const FilterInput = forwardRef(
  (
    {
      placeholder = '',
      type = 'text',
      name,
      marginLeft = 0,
      marginRight = 0,
      width = 'fit-content',
      onChange,
      value,
      withBorder,
      onReset,
    }: IFilterInputProps,
    ref: any,
  ) => {
    const {t} = useTranslation();

    return (
      <div
        css={styles.inputContainer(marginLeft, marginRight, width, withBorder)}>
        <LoopIcon />
        <input
          type={type}
          id={name}
          placeholder={t(placeholder)}
          value={value}
          name={name}
          css={[styles.input(width, withBorder), styles.placeholder]}
          ref={ref}
          onChange={onChange}
          className="textSemiBold"
        />
        {!!value && <CrossFilterIcon onClick={onReset} />}
      </div>
    );
  },
);

const styles = {
  inputContainer: (
    marginLeft: string | number,
    marginRight: string | number,
    width: string | number,
    withBorder?: boolean,
  ) =>
    css({
      display: 'flex',
      backgroundColor: 'var(--bg-color)',
      height: 28,
      marginLeft,
      marginRight,
      width,
      padding: '0 12px',
      alignItems: 'center',
      border: withBorder ? `1px solid ${THEME.colors.lightGray}` : 'none',
    }),
  input: (width: string | number, withBorder?: boolean) =>
    css({
      padding: '0 10px',
      backgroundColor: 'var(--bg-color)',

      marginLeft: 5,
      height: withBorder ? 24 : 28,
      color: THEME.colors.primary,
      fontWeight: '700',
      fontSize: '10px',
      lineHeight: '12px',
      width,
      border: 'none',
      borderLeft: 'none',
    }),
  placeholder: css`
    &::placeholder {
      color: ${THEME.colors.lightGray};
    }
  `,
};

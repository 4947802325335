import {IStatHourlyByDOW} from '../api/fetchStats';
import {CurrentMetricMode} from '../redux/filter';
import {SalesGraphSectionType} from '../redux/myStatsSection/sales';

export const helperDowValue = ({
  data,
  salesGraphSection,
  currentMetricMode,
  isCompare,
}: {
  data:
    | IStatHourlyByDOW['weekdays'][0]
    | IStatHourlyByDOW['weekdays'][0]['hours'][0];
  salesGraphSection: SalesGraphSectionType;
  currentMetricMode: CurrentMetricMode;
  isCompare?: boolean;
}) => {
  const total =
    salesGraphSection === 'TURNOVER'
      ? data.sales.TTC[`${currentMetricMode}`]
      : salesGraphSection === 'ORDERS'
      ? data.orders[`${currentMetricMode}`]
      : data.average_cart;

  const compareTotal =
    salesGraphSection === 'TURNOVER'
      ? data?.compareSales?.TTC?.[`${currentMetricMode}`]
      : salesGraphSection === 'ORDERS'
      ? data?.compareOrders?.[`${currentMetricMode}`]
      : data?.compareAverageCart;

  return isCompare ? compareTotal : total;
};

export const helperDowHeatmapValue = ({
  heatmaps,
  salesGraphSection,
  key,
}: {
  heatmaps?: IStatHourlyByDOW['heatmaps'];
  salesGraphSection: SalesGraphSectionType;
  key: number;
}) => {
  const value =
    salesGraphSection === 'TURNOVER'
      ? heatmaps?.sales.TTC[key]
      : salesGraphSection === 'ORDERS'
      ? heatmaps?.orders[key]
      : heatmaps?.average_sales.TTC[key];

  return value || 0;
};

import {SVGProps} from 'react';

export const CustomerCardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.56421 11.9845C6.56421 12.3741 6.25073 12.6876 5.86108 12.6876C5.47144 12.6876 5.15796 12.3741 5.15796 11.9845V9.64073C5.15796 9.25108 5.47144 8.93761 5.86108 8.93761C6.25073 8.93761 6.56421 9.25108 6.56421 9.64073V11.9845ZM8.43921 8.93761C8.82886 8.93761 9.14233 9.25108 9.14233 9.64073V11.9845C9.14233 12.3741 8.82886 12.6876 8.43921 12.6876C8.04956 12.6876 7.73608 12.3741 7.73608 11.9845V9.64073C7.73608 9.25108 8.04956 8.93761 8.43921 8.93761ZM11.7205 11.9845C11.7205 12.3741 11.407 12.6876 11.0173 12.6876C10.6277 12.6876 10.3142 12.3741 10.3142 11.9845V9.64073C10.3142 9.25108 10.6277 8.93761 11.0173 8.93761C11.407 8.93761 11.7205 9.25108 11.7205 9.64073V11.9845ZM7.12378 0.579442C7.46655 0.758592 7.60132 1.18302 7.42261 1.52755L5.03198 6.12511H11.8464L9.45581 1.52755C9.2771 1.18302 9.41187 0.758592 9.75464 0.579442C10.1003 0.400291 10.5251 0.534354 10.7039 0.878914L13.4314 6.12511H16.1736C16.5632 6.12511 16.8767 6.43858 16.8767 6.82823C16.8767 7.21788 16.5632 7.53136 16.1736 7.53136H15.5876L13.95 14.0792C13.7156 14.9142 12.9919 15.5001 12.1306 15.5001H4.72144C3.88647 15.5001 3.13647 14.9142 2.92788 14.0792L1.29077 7.53136H0.704834C0.31665 7.53136 0.00170898 7.21788 0.00170898 6.82823C0.00170898 6.43858 0.31665 6.12511 0.704834 6.12511H3.44702L6.17456 0.878914C6.35327 0.534354 6.77808 0.400291 7.12378 0.579442ZM4.29077 13.7394C4.34351 13.9474 4.53101 14.0939 4.72144 14.0939H12.1306C12.3474 14.0939 12.5349 13.9474 12.5876 13.7394L14.1375 7.53136H2.74038L4.29077 13.7394Z"
      fill="#CCD6E0"
    />
  </svg>
);

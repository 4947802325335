import {SVGProps} from 'react';

export const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_554_391)">
      <rect width="10" height="10" fill="white" />
      <path
        d="M7.91667 1.66675H2.08333C1.6231 1.66675 1.25 2.03984 1.25 2.50008V8.33341C1.25 8.79365 1.6231 9.16675 2.08333 9.16675H7.91667C8.3769 9.16675 8.75 8.79365 8.75 8.33341V2.50008C8.75 2.03984 8.3769 1.66675 7.91667 1.66675Z"
        stroke="#CCD6E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66663 0.833252V2.49992"
        stroke="#CCD6E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33337 0.833252V2.49992"
        stroke="#CCD6E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 4.5H8.75"
        stroke="#CCD6E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_554_391">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CalendarMediumIcon = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4435_20482)">
      <path
        d="M5.21777 1.65283H9.43652V0.480957C9.43652 0.0927734 9.75 -0.222168 10.1396 -0.222168C10.5293 -0.222168 10.8428 0.0927734 10.8428 0.480957V1.65283H12.0146C13.0488 1.65283 13.8896 2.49219 13.8896 3.52783V12.9028C13.8896 13.937 13.0488 14.7778 12.0146 14.7778H2.63965C1.604 14.7778 0.764648 13.937 0.764648 12.9028V3.52783C0.764648 2.49219 1.604 1.65283 2.63965 1.65283H3.81152V0.480957C3.81152 0.0927734 4.125 -0.222168 4.51465 -0.222168C4.9043 -0.222168 5.21777 0.0927734 5.21777 0.480957V1.65283ZM2.1709 12.9028C2.1709 13.1606 2.38066 13.3716 2.63965 13.3716H12.0146C12.2725 13.3716 12.4834 13.1606 12.4834 12.9028V5.40283H2.1709V12.9028Z"
        fill="#CCD6E0"
      />
    </g>
    <defs>
      <clipPath id="clip0_4435_20482">
        <rect
          width="14"
          height="14.7778"
          fill="white"
          transform="translate(0 0.777832)"
        />
      </clipPath>
    </defs>
  </svg>
);

/**  @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {IStatItemDetailGraph} from '../../../api/fetchStats';
import {useAppSelector} from '../../../hooks/useReduxHook';
import {TextVariant} from '../../atoms/TextVariant';
import {PillLabel} from '../../molecules/PillLabel';
import {THEME} from '../../../styles/theme';
import {helperFormatPrice} from '../../../helpers/helperFormatPrice';
import {MetricBox} from '../../molecules/MetricBox';
import {CardLargeIcon} from '../../../assets/svgs/CardIcon';
import {TicketIcon} from '../../../assets/svgs/TicketIcon';
import {ScaleFilter} from '../../filters/ScaleFilter';
import {M_SCREEN_DESKTOP} from '../../../constants/ResponsiveSize';

export const Header = ({
  data,
  forProduct,
}: {
  data?: IStatItemDetailGraph;
  forProduct?: boolean;
}) => {
  const {t} = useTranslation();
  const currentMode = useAppSelector(s => s.filter.currentMode);

  const total_orders = data?.orders?.reduce((acc, curr) => acc + curr.y, 0);
  const total_price =
    data?.sales?.[currentMode]?.reduce((acc, curr) => acc + curr.y, 0) || 0;

  return (
    <div css={styles.header}>
      <div className="flex" style={{gap: '1rem'}}>
        <TextVariant text="COMMANDES" variant="textMedium" />

        <div className="flex" style={{gap: '1rem'}}>
          <div>
            <PillLabel
              text={t('TOTAL_SOLD')}
              variant="textSemiBold"
              backgroundColor={THEME.colors.primary}
            />
            <TextVariant
              text={helperFormatPrice(total_price)}
              variant="title02"
              style={css({paddingLeft: '1rem', color: THEME.colors.primary})}
            />
          </div>
          {forProduct && (
            <>
              <div>
                <PillLabel
                  text={t('PRODUCT_ALONE')}
                  variant="textSemiBold"
                  backgroundColor="#3EA7FC"
                />
                <TextVariant
                  text="0000000"
                  variant="title02"
                  style={css({paddingLeft: '1rem', color: '#3EA7FC'})}
                />
              </div>

              <div>
                <PillLabel
                  text={t('ON_MENU')}
                  variant="textSemiBold"
                  backgroundColor={THEME.colors.purple}
                />
                <TextVariant
                  text="00000000"
                  variant="title02"
                  style={css({paddingLeft: '1rem', color: THEME.colors.purple})}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div css={styles.blockRight}>
        <div className="flex" css={css({gap: '1rem'})}>
          <MetricBox
            label={`CA ${currentMode}`}
            Icon={CardLargeIcon}
            value={helperFormatPrice(total_price)}
            item="TURNOVER"
            suffix={'€'}
          />

          <MetricBox
            label={t('NUMBER_OF_ORDERS')}
            Icon={TicketIcon}
            value={total_orders}
            item="ORDERS"
            borderColor={THEME.colors.yellowDark}
          />
        </div>

        <div css={styles.scale}>
          <ScaleFilter withBorder />
        </div>
      </div>
    </div>
  );
};

const styles = {
  header: css({
    display: 'flex',
    padding: '2vh',
    paddingBottom: 0,
    justifyContent: 'space-between',
    [M_SCREEN_DESKTOP]: {
      flexDirection: 'column',
    },
  }),
  blockRight: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  scale: css({
    marginTop: '0.5rem',
    alignSelf: 'flex-end',
  }),
};

import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {DeleteModal} from './DeleteModal';
import {areaSelectedAction} from '../../redux/myRestaurantSection/areas';
import {deleteAreaModalAction} from '../../redux/modals';
import {useState} from 'react';
import {deleteArea, deleteAreaLocation} from '../../api/fetchAreas';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';

export const DeleteAreaModal = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const brand = useAppSelector(state => state.brand.brand);
  const areaSelected = useAppSelector(
    s => s.myRestaurantSection.areas.areaSelected,
  );

  const clientQuery = useQueryClient();

  if (!areaSelected) return null;

  const handleCancel = () => {
    dispatch(areaSelectedAction(null));
    dispatch(deleteAreaModalAction(false));
  };
  const handleSubmit = async () => {
    setIsLoading(true);

    if (areaSelected.location_details.length > 0) {
      for (let area_location of areaSelected.location_details) {
        const resLocation = await deleteAreaLocation(
          brand.id,
          areaSelected?.id,
          area_location.id,
        );

        if (resLocation.status === 200) {
          helperDisplayToast(resLocation.message, true);
        }
      }
    }

    const res = await deleteArea(brand.id, areaSelected?.id);

    if (res.status === 200) {
      helperDisplayToast(res.message, true);
      await clientQuery.invalidateQueries(['fetchAreas']);
      handleCancel();
    }
    setIsLoading(false);
  };

  return (
    <DeleteModal
      title=""
      isLoading={isLoading}
      text={areaSelected?.label}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};

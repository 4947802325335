import { IFetchSuggestionCategoriesResponse } from '@bill-app-types/b-types';
import { helperGenerateQuery } from '../helpers/helperGenerateQuery';
import {helperLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';

interface IAdditional {
  search: string;
}

export const getAdditionals = async (data: IAdditional) => {
  
  helperLog({message: 'getAdditionals'});

  const query = helperGenerateQuery(data);

  const res = await axiosInstance
    .get<{data:IFetchSuggestionCategoriesResponse[]}>(`/bcatalog/backoffice/suggestion-categories${query}`)
    .catch(err => {
      helperLog({message: 'getAdditionals', type: 'API_ERROR', data: err});
    });

  return res?.data?.data;
};

import {helperJustLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';
import {IProductionAreaGetReturn} from '@bill-app-types/b-types';
interface IAreaProduction {
  label: string;
  productionType?: number;
  parentCategoryUuid?: string | null;
  color?: string;
  position: number;
  isRecipe?: boolean;
}

export const createAreaProduction = async (data: IAreaProduction) => {
  const res = await axiosInstance.post(
    `/bcatalog/backoffice/production-areas`,
    data,
  );

  return res;
};

export const fetchAreaProductions = async () => {
  helperJustLog('### fetchAreaProductions ###');
  const res = await axiosInstance
    .get<{data: IProductionAreaGetReturn[]}>(`/bcatalog/backoffice/production-areas`)
    .catch(err => {
      console.log('❌ fetchAreaProductions : ', err);
    });

  return res?.data?.data;
};

export const fetchAreaProductionItems = async (areaProductionUuid: string) => {
  const res = await axiosInstance
    .get(`/bcatalog/backoffice/production-areas/${areaProductionUuid}/items`)
    .catch(err => {
      console.log('❌ fetchAreaProductionItems : ', err);
    });

  return res?.data?.data;
};

export const updateAreaProduction = async (
  areaProductionUuid: string,
  data: Partial<IAreaProduction>,
) => {
  const res = await axiosInstance.put(
    `/bcatalog/backoffice/production-areas/${areaProductionUuid}`,
    data,
  );

  return res;
};

export const associateAreaProductionItem = async (
  areaProductionUuid: string,
  itemUuid: string,
) => {
  const res = await axiosInstance.put(
    `/bcatalog/backoffice/production-areas/${areaProductionUuid}/items/${itemUuid}`,
  );

  return res;
};

export const dissociateAreaProductionItem = async (
  areaProductionUuid: string,
  itemUuid: string,
) => {
  const res = await axiosInstance.delete(
    `/bcatalog/backoffice/production-areas/${areaProductionUuid}/items/${itemUuid}`,
  );

  return res;
};

export const updateAreaProductionItem = async (
  areaProductionUuid: string,
  itemUuid: string,
) => {
  const res = await axiosInstance.put(
    `/bcatalog/backoffice/production-areas/${areaProductionUuid}/items/${itemUuid}`,
  );

  return res;
};

export const deleteAreaProduction = async (areaProductionUuid: string) => {
  const res = await axiosInstance.delete(
    `/bcatalog/backoffice/production-areas/${areaProductionUuid}`,
  );

  return res;
};

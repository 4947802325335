import {SVGProps} from 'react';

export const OptProdIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.37305 0.5C2.80975 0.5 3.16406 0.814941 3.16406 1.20312V2.60938C3.16406 2.99902 2.80975 3.3125 2.37305 3.3125V5.89062H4.21875C4.21875 5.50098 4.57141 5.1875 5.00977 5.1875H6.5918C7.03015 5.1875 7.38281 5.50098 7.38281 5.89062V7.29688C7.38281 7.68652 7.03015 8 6.5918 8H5.00977C4.57141 8 4.21875 7.68652 4.21875 7.29688H2.37305V10.3438C2.37305 10.4727 2.49104 10.5781 2.63672 10.5781H4.21875C4.21875 10.1885 4.57141 9.875 5.00977 9.875H6.5918C7.03015 9.875 7.38281 10.1885 7.38281 10.5781V11.9844C7.38281 12.374 7.03015 12.6875 6.5918 12.6875H5.00977C4.57141 12.6875 4.21875 12.374 4.21875 11.9844H2.63672C1.6173 11.9844 0.791016 11.249 0.791016 10.3438V3.3125C0.354309 3.3125 0 2.99902 0 2.60938V1.20312C0 0.814941 0.354309 0.5 0.791016 0.5H2.37305ZM5.27344 1.90625C5.27344 1.51807 5.6261 1.20312 6.06445 1.20312H16.084C16.5223 1.20312 16.875 1.51807 16.875 1.90625C16.875 2.2959 16.5223 2.60938 16.084 2.60938H6.06445C5.6261 2.60938 5.27344 2.2959 5.27344 1.90625ZM9.49219 6.59375C9.49219 6.2041 9.84485 5.89062 10.2832 5.89062H16.084C16.5223 5.89062 16.875 6.2041 16.875 6.59375C16.875 6.9834 16.5223 7.29688 16.084 7.29688H10.2832C9.84485 7.29688 9.49219 6.9834 9.49219 6.59375ZM9.49219 11.2812C9.49219 10.8916 9.84485 10.5781 10.2832 10.5781H16.084C16.5223 10.5781 16.875 10.8916 16.875 11.2812C16.875 11.6709 16.5223 11.9844 16.084 11.9844H10.2832C9.84485 11.9844 9.49219 11.6709 9.49219 11.2812Z"
      fill="white"
    />
  </svg>
);

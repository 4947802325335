/**  @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../../../../components/atoms/TextVariant';
import {PillLabel} from '../../../../../../../components/molecules/PillLabel';
import {THEME} from '../../../../../../../styles/theme';
import {useTranslation} from 'react-i18next';
import {ChartIcon} from '../../../../../../../assets/svgs/ChartIcon';
import {TablesColumnsIcon} from '../../../../../../../assets/svgs/TablesColumnsIcon';
import {M_SCREEN_TABLET} from '../../../../../../../constants/ResponsiveSize';
import {useAppSelector} from '../../../../../../../hooks/useReduxHook';
import {IStatHourlyByDOW} from '../../../../../../../api/fetchStats';
import {helperDowHeatmapValue} from '../../../../../../../helpers/helperDowValue';
import {helperFormatPriceRoundToString} from '../../../../../../../helpers/helperFormatPrice';

export const HeatMaps = ({
  tabs,
  setTabs,
  heatmaps,
}: {
  heatmaps?: IStatHourlyByDOW['heatmaps'];
  tabs: 'graph' | 'columns';
  setTabs: (value: 'graph' | 'columns') => void;
}) => {
  const salesGraphSection = useAppSelector(
    s => s.myStatsSection.sales.salesGraphSection,
  );

  const {t} = useTranslation();

  return (
    <div className="flex flex-space-between" css={styles.container}>
      <div>
        <TextVariant
          text={t('HOURLY_SALES')}
          variant="textMedium"
          style={{textTransform: 'uppercase'}}
        />
        <div className="flex" css={styles.pills}>
          <PillLabel
            text={`0${salesGraphSection === 'ORDERS' ? '' : '€ TTC'}`}
            variant="textSemiBold"
          />

          {Array.from({length: 3}).map((_, index) => (
            <HeatMapItem index={index} heatmaps={heatmaps} key={index} />
          ))}
        </div>
      </div>

      <div className="flex" css={styles.switchContainer}>
        <div
          css={styles.switch(tabs === 'graph')}
          className="flex-center"
          onClick={() => setTabs('graph')}>
          <ChartIcon color={tabs === 'graph' ? 'white' : '#A7A7AB'} />
        </div>
        <div
          css={styles.switch(tabs === 'columns')}
          className="flex-center"
          onClick={() => setTabs('columns')}>
          <TablesColumnsIcon color={tabs === 'columns' ? 'white' : '#A7A7AB'} />
        </div>
      </div>
    </div>
  );
};

const HeatMapItem = ({
  heatmaps,
  index,
}: {
  heatmaps?: IStatHourlyByDOW['heatmaps'];
  index: number;
}) => {
  const {t} = useTranslation();

  const salesGraphSection = useAppSelector(
    s => s.myStatsSection.sales.salesGraphSection,
  );

  const firstValue = helperDowHeatmapValue({
    heatmaps,
    salesGraphSection,
    key: index,
  });

  const secondValue = helperDowHeatmapValue({
    heatmaps,
    salesGraphSection,
    key: index + 1,
  });

  const firstText =
    index === 0
      ? salesGraphSection === 'ORDERS'
        ? 1
        : '0,1'
      : salesGraphSection === 'ORDERS'
      ? firstValue
      : helperFormatPriceRoundToString(firstValue);

  const secondText = `${
    salesGraphSection === 'ORDERS'
      ? secondValue
      : helperFormatPriceRoundToString(secondValue)
  }${
    salesGraphSection === 'ORDERS'
      ? index === 2
        ? '+'
        : ''
      : `€${index === 2 ? '+' : ''} TTC`
  }`;

  return (
    <PillLabel
      text={`${t('BETWEEN')} ${firstText} ${
        salesGraphSection === 'ORDERS' ? '' : '€'
      } et ${secondText}`}
      backgroundColor={
        THEME.colors[index === 0 ? 'red' : index === 1 ? 'yellowDark' : 'green']
      }
      variant="textSemiBold"
    />
  );
};

const styles = {
  container: css({
    marginBottom: '1rem',
  }),
  pills: css({
    marginTop: '1rem',
    gap: '0.5rem',
    [M_SCREEN_TABLET]: {
      flexDirection: 'column',
    },
  }),
  switchContainer: css({
    border: `1px solid ${THEME.colors.lightGray}`,
    borderRadius: '5px',
    padding: '0.15rem 0.2rem',
    gap: '0.13rem',
  }),
  switch: (active: boolean) =>
    css({
      flex: 1,
      padding: '0.29rem',
      backgroundColor: active ? THEME.colors.primary : 'transparent',
      borderRadius: '5px',
      cursor: 'pointer',
    }),
};

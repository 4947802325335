export const EqualIcon = ({color}: {color?: string}) => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.865385 3.25003C0.385817 3.25003 0 3.58441 0 4.00003C0 4.41566 0.385817 4.75003 0.865385 4.75003H14.1346C14.6142 4.75003 15 4.41566 15 4.00003C15 3.58441 14.6142 3.25003 14.1346 3.25003H0.865385ZM0.865385 8.75003C0.385817 8.75003 0 9.08441 0 9.50003C0 9.91566 0.385817 10.25 0.865385 10.25H14.1346C14.6142 10.25 15 9.91566 15 9.50003C15 9.08441 14.6142 8.75003 14.1346 8.75003H0.865385Z"
        fill={color || '#3C6AF6'}
      />
    </svg>
  );
};

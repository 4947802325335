/** @jsxImportSource @emotion/react */
import {DataGridEye} from '../../../../../components/newDatagrid/DataGridEye';
import {helperDate} from '../../../../../helpers/helperDate';
import {helperFormatPrice} from '../../../../../helpers/helperFormatPrice';

export const columns = [
  {
    headerName: 'Ouvert',
    flex: 1,
    valueGetter: (row: any) => {
      return `${helperDate(row?.openedAt, 'Le DD/MM/YYYY à HH:mm')}`;
    },
  },
  {
    headerName: 'Ouvert par',
    flex: 1,
    valueGetter: (row: any) => {
      return row?.openedByUserPos?.username;
    },
  },
  {
    headerName: 'Fermé',
    flex: 1,
    renderCell: (row: any) => {
      return (
        <>
          {!!row?.closedAt
            ? `${helperDate(row?.closedAt, 'Le DD/MM/YYYY à HH:mm')}`
            : 'Non fermé'}
        </>
      );
    },
  },
  {
    headerName: 'Fermé par',
    flex: 1,
    valueGetter: (row: any) => {
      return row?.openedByUserPos?.username;
    },
  },
  {
    headerName: 'Montant TTC',
    flex: 1,
    renderCell: (row: any) => {
      const amount = row?.totalTTC;
      return <span>{helperFormatPrice(amount)}€</span>;
    },
  },
  {
    headerName: '',
    flex: 'none',
    width: 45,
    renderCell: (row: any) => {
      return (
        <div>
          <DataGridEye path={`/online/accounting/days/${row?.uuid}`} />
        </div>
      );
    },
  },
];

export const BStaffIcon = ({
  size,
  isDisabled,
  isActive = false,
}: {
  size: string;
  isDisabled?: boolean;
  isActive?: boolean;
}) => (
  <svg
    width={size === 'small' ? '41' : '62'}
    height={size === 'small' ? '13' : '20'}
    viewBox="0 0 62 20"
    fill="none">
    <path
      d="M7.6825 5.52563C6.80795 5.52563 5.972 5.68224 5.1978 5.96456L6.94801 9.2422C7.18512 9.19147 7.42995 9.16169 7.6836 9.16169C9.60364 9.16169 11.1597 10.6924 11.1597 12.5816C11.1597 14.4708 9.60364 16.0015 7.6836 16.0015C5.76356 16.0015 4.30009 14.5546 4.21518 12.7393L4.20746 12.7481V6.41011C4.20746 6.41011 4.20194 6.41342 4.19863 6.41452L4.20746 0.5H0.510742V19.6364H4.17878V18.7376C5.21655 19.3089 6.40982 19.6364 7.6825 19.6364C11.6439 19.6364 14.8543 16.4779 14.8543 12.5805C14.8543 8.68306 11.6428 5.52453 7.6825 5.52453V5.52563Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#151A30' : '#3C6AF6'}
    />
    <path
      d="M21.9069 13.6393C22.348 13.7871 22.7528 13.9371 23.1211 14.0915C23.4895 14.2459 23.8093 14.4289 24.0839 14.6429C24.3585 14.8568 24.5725 15.1149 24.7258 15.4149C24.8791 15.716 24.9563 16.0744 24.9563 16.4879V16.5078C24.9563 17.5235 24.6188 18.3198 23.9428 18.8943C23.2667 19.47 22.3205 19.7568 21.104 19.7568C20.3409 19.7568 19.5921 19.6255 18.8565 19.363C18.1209 19.0995 17.4327 18.7256 16.7908 18.2404L16.771 18.2205L16.7908 18.2007L18.1551 16.0468L18.1749 16.0667C18.5763 16.4946 19.0318 16.8188 19.5391 17.0394C20.0475 17.2599 20.589 17.3702 21.1647 17.3702C21.9532 17.3702 22.348 17.1166 22.348 16.6104C22.348 16.3699 22.2212 16.1902 21.9665 16.07C21.7128 15.9498 21.4117 15.8362 21.0643 15.7292C20.9971 15.7027 20.9342 15.6862 20.8736 15.6796C20.8129 15.6729 20.75 15.6564 20.6828 15.6288C20.2813 15.523 19.881 15.4061 19.4796 15.2792C19.0781 15.1524 18.7175 14.9793 18.3966 14.7587C18.0757 14.5392 17.811 14.2591 17.6036 13.9183C17.3963 13.5787 17.2926 13.1408 17.2926 12.6071V12.5674C17.2926 12.0998 17.3798 11.673 17.554 11.2859C17.7272 10.8988 17.9753 10.5657 18.2962 10.2856C18.6171 10.0055 19.0042 9.78931 19.4586 9.63491C19.913 9.48161 20.4148 9.40552 20.964 9.40552C21.6058 9.40552 22.2543 9.50257 22.9094 9.69556C23.5645 9.88966 24.1732 10.1742 24.7346 10.5481V10.5878L23.5104 12.8144L23.4906 12.7945C23.1829 12.4868 22.8013 12.2431 22.3469 12.0623C21.8926 11.8814 21.4106 11.791 20.9033 11.791C20.8096 11.791 20.7026 11.7976 20.5824 11.8108C20.4622 11.8251 20.3519 11.8582 20.2516 11.9112C20.1512 11.9641 20.0674 12.0347 20.0012 12.1218C19.9339 12.2089 19.9008 12.3325 19.9008 12.4924C19.9008 12.6126 19.9538 12.7229 20.0619 12.8232C20.1677 12.9236 20.3056 13.0173 20.4721 13.1044C20.6386 13.1916 20.8272 13.2743 21.0335 13.3548C21.2408 13.4353 21.4448 13.5092 21.6455 13.5753C21.6852 13.5753 21.7283 13.582 21.7757 13.5952C21.8231 13.6084 21.8661 13.6228 21.9058 13.6349L21.9069 13.6393Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M29.8713 16.1686C29.8713 16.4763 29.9816 16.7377 30.2021 16.9506C30.4227 17.1645 30.6874 17.2715 30.9951 17.2715H32.9405V19.5985H30.9951C30.4734 19.5985 29.9816 19.5014 29.5206 19.3073C29.0596 19.1132 28.6582 18.8463 28.3174 18.5045C27.9766 18.1637 27.7064 17.7656 27.5046 17.3112C27.3039 16.8568 27.2035 16.3683 27.2035 15.8466V12.8381H25.7588V10.6114H27.2035V6.9798H29.8713V16.1675V16.1686ZM32.9405 10.6114V12.8381H31.4561L30.2132 10.6114H32.9416H32.9405Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M44.7164 9.56562V19.5959H42.0883V14.5681C42.0883 14.1788 42.0144 13.8171 41.8678 13.4818C41.72 13.1465 41.5171 12.8521 41.2557 12.5973C40.9943 12.3415 40.6944 12.1452 40.3536 12.004C40.0117 11.8628 39.6544 11.7923 39.2805 11.7923C38.9067 11.7923 38.5317 11.8661 38.1975 12.0128C37.8634 12.1606 37.5645 12.358 37.3042 12.6072C37.0429 12.8554 36.8388 13.1509 36.6922 13.4928C36.5455 13.8358 36.4716 14.2009 36.4716 14.5902C36.4716 14.9795 36.5455 15.3224 36.6922 15.6577C36.8388 15.993 37.0429 16.2885 37.3042 16.5433C37.5645 16.798 37.8623 16.9999 38.1975 17.1476C38.5317 17.2954 38.8923 17.3682 39.2805 17.3682C39.6952 17.3682 40.0492 17.2954 40.3437 17.1476H40.3635V17.1873L41.3671 18.9728L41.3472 18.9927C40.6921 19.5011 39.8827 19.7547 38.9199 19.7547C38.2108 19.7547 37.5424 19.6213 36.9138 19.3533C36.2852 19.0864 35.736 18.7181 35.2684 18.2505C34.7997 17.7829 34.4291 17.2348 34.1545 16.605C33.8799 15.9764 33.7432 15.3081 33.7432 14.599C33.7432 13.8898 33.8799 13.2006 34.1545 12.5731C34.4291 11.9444 34.7997 11.3963 35.2684 10.9276C35.736 10.46 36.2841 10.0884 36.9138 9.81376C37.5424 9.54026 38.2108 9.4035 38.9199 9.4035C39.629 9.4035 40.2234 9.53253 40.7451 9.7906C41.2667 10.0498 41.7145 10.4236 42.0894 10.9144V9.56341H44.7175L44.7164 9.56562Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M53.3053 5.55567V7.90251H51.1183C50.7709 7.90251 50.5029 8.03705 50.3166 8.30394C50.1291 8.57083 50.0353 8.93918 50.0353 9.40678V19.597H47.3676V13.5182H45.9229V11.2915H47.3676V9.28547C47.3676 8.77706 47.4646 8.29512 47.6587 7.84075C47.8517 7.38638 48.1197 6.98826 48.4605 6.64748C48.8024 6.3067 49.1994 6.03981 49.6538 5.84461C50.1081 5.65051 50.5967 5.55457 51.1183 5.55457H53.3053V5.55567ZM50.3563 11.2926H53.3053V13.5193H51.5793L50.3552 11.2926H50.3563Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M61.4893 5.55567V7.90251H59.3024C58.955 7.90251 58.687 8.03705 58.5006 8.30394C58.3132 8.57083 58.2194 8.93918 58.2194 9.40678V19.597H55.5517V13.5182H54.1069V11.2915H55.5517V9.28547C55.5517 8.77706 55.6487 8.29512 55.8428 7.84075C56.0358 7.38638 56.3038 6.98826 56.6446 6.64748C56.9864 6.3067 57.3835 6.03981 57.8378 5.84461C58.2922 5.65051 58.7808 5.55457 59.3024 5.55457H61.4893V5.55567ZM58.5403 11.2926H61.4893V13.5193H59.7634L58.5392 11.2926H58.5403Z"
      fill={isDisabled ? '#CCD6E0' : '#151A30'}
    />
  </svg>
);

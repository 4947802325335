/** @jsxImportSource @emotion/react */
import {ModalWrapper} from '../atoms/ModalWrapper';
import {useAppDispatch} from '../../hooks/useReduxHook';
import {addStructureModalAction} from '../../redux/modals';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';
import {createStructure} from '../../api/fetchStructures';
import {useState} from 'react';
import {STRUCTURE_TYPES} from '@bill-app-types/b-types';
import {SelectList} from '../molecules/SelectList';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {useNavigate} from 'react-router-dom';
import {InputLabel} from '../atoms/InputLabel';

export const AddStructureModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [form, setForm] = useState({
    label: '',
    type: 1,
  });

  const clientQuery = useQueryClient();

  /* ON CANCEL */
  const handleOnCancel = () => {
    dispatch(addStructureModalAction(false));
  };

  const handleCreateCategory = async (isSave?: boolean) => {
    if (form.label && form.type) {
      const res = await createStructure(form);
      if (res && res?.status === 200) {
        handleOnCancel();
        helperDisplayToast(res?.data?.message || 'Arborescence créée', true);
        if (isSave) {
          clientQuery.invalidateQueries(['fetchStructures']);
        } else {
          navigate(`/online/catalog/tree/${res?.data?.data?.uuid}`);
        }
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible de créer l'arborescence",
          false,
        );
      }
    } else {
      helperDisplayToast('Tous les champs doivent être complétés', false);
    }
  };

  const handleOnSubmit = () => {
    handleCreateCategory();
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;
    setForm({...form, [name]: value});
  };

  const handleOnSave = async () => {
    handleCreateCategory(true);
  };

  const TYPES = Object.entries(STRUCTURE_TYPES)
    .slice(6)
    .map(item => ({
      label: t('STRUCTURE_' + item[0]),
      value: item[1],
    }));

  const structureTypes = TYPES.filter((item: any) =>
    [1, 4].includes(item.value),
  );

  return (
    <ModalWrapper
      title={t('Arborescence')}
      validateLabel={t('CONTINUE_CREATION')}
      onSave={handleOnSave}
      saveLabel={t('REGISTER_AND_CONTINUE_CREATION_LATER')}
      onCancel={handleOnCancel}
      onSubmit={handleOnSubmit}
      hideCancelButton
      initialValues={form}
      titleMarginBottom={35}
      minWidth="40%">
      <InputLabel
        onChange={handleChange}
        label={"Nom de l'arborescence"}
        name="label"
        required
        value={form.label}
      />

      <SelectList
        label={t('TYPE')}
        name="type"
        list={structureTypes}
        value={form.type}
        onChange={handleChange}
        manageByFormik={false}
        required
      />
      <FieldsRequired />
    </ModalWrapper>
  );
};

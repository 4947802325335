/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
// import {useAppSelector} from '../../../../hooks/useReduxHook';
import {CancellationsByReasons} from './CancellationByReasons';
import {CancellationsByWaiter} from './CancellationsByWaiter';
import {Bottom} from './HeaderSections/Bottom';
import {Center} from './HeaderSections/Center';
import {Top} from './HeaderSections/Top';
// import {Historiques} from './Historiques';

export const CancellationJournal = withRedirectAndNavigation(() => {
  // const sectionShow = useAppSelector(s => s.app.sectionShow);

  return (
    <div css={styles.container}>
      <Header
        topSection={<Top />}
        centerSection={<Center />}
        bottomSection={<Bottom />}
      />
      <div css={styles.content}>
        {/* {sectionShow === 'SUMMARY' && (
          <>
            <CancellationsByWaiter />
            <CancellationsByReasons />
          </>
        )} */}
        <>
          <CancellationsByWaiter />
          <CancellationsByReasons />
        </>
        {/* {sectionShow === 'HISTORIQUES' && <Historiques />} */}
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
    paddingTop: '2rem',
  }),
  content: css({
    padding: '0 2vh',
    display: 'flex',
    justifyContent: 'space-between',
  }),
};

import {combineReducers} from '@reduxjs/toolkit';
import subscriptions from './subscriptions';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import pos from './pos';
import loyalties from './loyalties';
import externalConnections from './externalConnections';

const persistPosConfig = {
  key: 'catalogMenus',
  storage,
};

export const myModulesSection = combineReducers({
  subscriptions,
  pos: persistReducer(persistPosConfig, pos),
  externalConnections,
  loyalties
});

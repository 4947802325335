export const UserTicketIcon = () => (
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.92503 4.4375C8.28062 4.4375 8.5667 4.73008 8.5667 5.09375C8.5667 5.45742 8.28062 5.75 7.92503 5.75H4.07503C3.71944 5.75 3.43337 5.45742 3.43337 5.09375C3.43337 4.73008 3.71944 4.4375 4.07503 4.4375H7.92503ZM7.92503 9.25C8.28062 9.25 8.5667 9.54258 8.5667 9.90625C8.5667 10.2699 8.28062 10.5625 7.92503 10.5625H4.07503C3.71944 10.5625 3.43337 10.2699 3.43337 9.90625C3.43337 9.54258 3.71944 9.25 4.07503 9.25H7.92503ZM3.43337 7.5C3.43337 7.13633 3.71944 6.84375 4.07503 6.84375H7.92503C8.28062 6.84375 8.5667 7.13633 8.5667 7.5C8.5667 7.86367 8.28062 8.15625 7.92503 8.15625H4.07503C3.71944 8.15625 3.43337 7.86367 3.43337 7.5ZM3.00559 1.60441L4.08573 0.657992C4.32635 0.447336 4.67927 0.447336 4.91989 0.657992L6.00003 1.60441L7.08017 0.657992C7.3208 0.447336 7.67371 0.447336 7.91434 0.657992L8.99448 1.60441L10.0746 0.657992C10.2644 0.491212 10.5318 0.452969 10.7591 0.560047C10.9863 0.667152 11.1334 0.900039 11.1334 1.15625V13.8438C11.1334 14.1008 10.9863 14.3332 10.7591 14.4398C10.5318 14.5465 10.2644 14.5082 10.0746 14.3414L8.99448 13.3953L7.91434 14.3414C7.67371 14.552 7.3208 14.552 7.08017 14.3414L6.00003 13.3953L4.91989 14.3414C4.67927 14.552 4.32635 14.552 4.08573 14.3414L3.00559 13.3953L1.92598 14.3414C1.73562 14.5082 1.46799 14.5465 1.2402 14.4398C1.01257 14.3332 0.866699 14.1008 0.866699 13.8438V1.15625C0.866699 0.900039 1.01257 0.667125 1.2402 0.560047C1.46799 0.452969 1.73562 0.491212 1.92598 0.657992L3.00559 1.60441ZM2.58797 12.0336C2.82833 11.823 3.18285 11.823 3.42321 12.0336L4.50281 12.9797L5.58295 12.0336C5.82357 11.823 6.17649 11.823 6.41712 12.0336L7.49725 12.9797L8.57739 12.0336C8.81802 11.823 9.17094 11.823 9.41156 12.0336L9.85003 12.4164V2.58305L9.41156 2.96695C9.17094 3.17777 8.81802 3.17777 8.57739 2.96695L7.49725 2.02059L6.41712 2.96695C6.17649 3.17777 5.82357 3.17777 5.58295 2.96695L4.50281 2.02059L3.42321 2.96695C3.18285 3.17777 2.82833 3.17777 2.58797 2.96695L2.15003 2.58305V12.4164L2.58797 12.0336Z"
      fill="#A7A7AB"
    />
  </svg>
);

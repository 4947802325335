/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../atoms/TextVariant';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {useTranslation} from 'react-i18next';
import {IObject} from '../../redux/filter';
import {THEME} from '../../styles/theme';
import {FilterRadioButtonItem} from './FilterRadioButtonItem';

export const FilterRadioButtons = ({
  title,
  list,
  onChange,
  selectedValue,
  onSubmit,
  isCard,
}: {
  title: string;
  list: IObject[];
  onChange: (value: any) => void;
  selectedValue: string | null;
  onSubmit?: () => void;
  isCard?: boolean;
}) => {
  const {t} = useTranslation();

  return (
    <div css={styles.container}>
      <TextVariant
        text={title}
        variant="textSemiBold"
        style={{
          marginBottom: '0.5rem',
        }}
      />
      <div>
        {list.map((entry, index) => {
          const checked = selectedValue === entry.value;
          return (
            <FilterRadioButtonItem
              entry={entry}
              checked={checked}
              handleCheckedChange={onChange}
              key={index}
              isCard={isCard}
            />
          );
        })}
      </div>

      <ButtonCustom
        label={t('APPLY')}
        width={'100%'}
        marginTop={'0.5rem'}
        onClick={onSubmit}
      />
    </div>
  );
};

const styles = {
  container: css({
    minWidth: '8.5rem',
    background: THEME.colors.white,
    top: '0',
    left: '0',
    padding: 12,
    borderRadius: 2,
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {CardIcon} from '../../../assets/svgs/CardIcon';
import {SmallReloadIcon} from '../../../assets/svgs/ReloadIcon';
import {TextVariant} from '../../../components/atoms/TextVariant';
import {helperDate} from '../../../helpers/helperDate';
import {THEME} from '../../../styles/theme';
import {helperFormatPrice} from '../../../helpers/helperFormatPrice';
import {CHANNELS, ORDER_STATUS, PAYMENT_STATUS} from '@bill-app-types/b-types';
import {ConsumptionModeBadge} from './ConsumptionModeBadge';
import {ArrowLeft} from '../../../assets/svgs/ArrowLeft';

export const columnsOrders: any = [
  {
    field: 'status',
    headerName: 'Statut',
    headerClassName: 'textRegular',
    renderCell: (params: any, t: any) => (
      <div
        className="flex-center"
        style={{
          width: '100%',
        }}>
        {params.orderStatus === ORDER_STATUS.CANCEL_REFUND ? (
          <div css={styles.canceled} className="flex-center">
            {t('CANCEL_REFUND')}
          </div>
        ) : (
          <>
            <p css={styles.card} className="flex-center">
              <SmallReloadIcon
                color={params.isSyncPos ? THEME.colors.green : THEME.colors.red}
              />
            </p>
            <p css={styles.card} className="flex-center">
              <CardIcon
                color={
                  params.paymentStatus === PAYMENT_STATUS.PAYMENT_SUCCESS
                    ? THEME.colors.green
                    : THEME.colors.red
                }
              />
            </p>
          </>
        )}
      </div>
    ),
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Reçue le',
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter: (params: any) => {
      return (
        <span
          style={{
            color:
              params.orderStatus === ORDER_STATUS.CANCEL_REFUND
                ? THEME.colors.red
                : THEME.colors.bgDark,
          }}>{`${helperDate(
          params?.createdAt,
          'Le DD/MM/YYYY à HH:mm',
        )}`}</span>
      );
    },
  },

  {
    field: 'wantedAt',
    headerName: 'Pour le',
    headerClassName: 'textRegular',
    flex: 1,

    valueGetter: (params: any) => {
      return (
        <span
          style={{
            color:
              params.orderStatus === ORDER_STATUS.CANCEL_REFUND
                ? THEME.colors.red
                : THEME.colors.bgDark,
          }}>{`${helperDate(
          params?.wantedAt || params?.createdAt,
          'Le DD/MM/YYYY à HH:mm',
        )}`}</span>
      );
    },
  },
  {
    field: 'shortCode',
    headerName: 'Commande',
    headerClassName: 'textRegular',
    flex: 1,
    renderCell: (params: any) => {
      return (
        <TextVariant
          text={`${params?.shortCode} / ${params?.publicId}`}
          variant="textSemiBold"
          style={{
            color:
              params.orderStatus === ORDER_STATUS.CANCEL_REFUND
                ? THEME.colors.red
                : THEME.colors.primary,
          }}
        />
      );
    },
  },
  {
    field: 'consumptionMode',
    headerName: 'Mode de consommation',
    headerClassName: 'textRegular',
    flex: 1,
    renderCell: (params: any) => {
      return <ConsumptionModeBadge consumptionMode={params.consumptionMode} />;
    },
  },
  {
    field: 'channelId',
    headerName: 'Canal de commande',
    headerClassName: 'textRegular',
    flex: 1,
    renderCell: (row: any, t: any) => {
      return (
        <span
          style={{
            color:
              row.orderStatus === ORDER_STATUS.CANCEL_REFUND
                ? THEME.colors.red
                : THEME.colors.bgDark,
          }}>
          {t(CHANNELS[row?.channelId])}
        </span>
      );
    },
  },
  {
    field: 'priceTtc',
    headerName: 'Prix TTC',
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter: (params: any) => {
      return (
        <span
          style={{
            color:
              params.orderStatus === ORDER_STATUS.CANCEL_REFUND
                ? THEME.colors.red
                : THEME.colors.bgDark,
          }}>{`${helperFormatPrice(params?.totalPrice)} €`}</span>
      );
    },
  },
  {
    field: 'iconArrow',
    flex: 0.1,
    align: 'right',
    renderCell: () => <ArrowLeft color="black" />,
  },
];

const styles = {
  canceled: css({
    height: 24,
    padding: '0 5px',
    color: THEME.colors.red,
    backgroundColor: THEME.colors.bgLight,
    textTransform: 'uppercase',
  }),
  card: css({
    borderRadius: 2,
    background: THEME.colors.bgLight,
    marginRight: 5,
    width: 24,
    height: 24,
  }),
  name: css({
    color: THEME.colors.primary,
  }),
};

import {IStatHourlyByDOW} from '../api/fetchStats';
import {SalesGraphSectionType} from '../redux/myStatsSection/sales';
import {THEME} from '../styles/theme';
import {helperDowHeatmapValue} from './helperDowValue';

export const helperHourItemColor = ({
  value,
  heatmaps,
  salesGraphSection,
}: {
  value: number;
  heatmaps?: IStatHourlyByDOW['heatmaps'];
  salesGraphSection: SalesGraphSectionType;
}) => {
  if (!heatmaps) return THEME.colors.lightGray;

  if (
    value > 0 &&
    value <= helperDowHeatmapValue({heatmaps, salesGraphSection, key: 1})
  ) {
    return 'rgba(236, 81, 116, 0.30)';
  } else if (
    helperDowHeatmapValue({heatmaps, salesGraphSection, key: 1}) < value &&
    value <= helperDowHeatmapValue({heatmaps, salesGraphSection, key: 2})
  ) {
    return 'rgba(247, 199, 97, 0.30)';
  } else if (
    helperDowHeatmapValue({heatmaps, salesGraphSection, key: 2}) < value ||
    value >= helperDowHeatmapValue({heatmaps, salesGraphSection, key: 3})
  ) {
    return 'rgba(97, 210, 149, 0.30)';
  } else {
    return THEME.colors.lightGray;
  }
};

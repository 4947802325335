import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addPosTerminalModalAction} from '../../redux/modals';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';

import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {
  updateModeAction,
  updatedPosTerminalAction,
} from '../../redux/myModulesSection/pos';
import {
  createPosTerminal,
  updatePosTerminal,
} from '../../api/fetchPosTerminals';

export const AddPosTerminalModal = () => {
  const initialValues = {
    label: '',
  };
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();

  const updateMode = useAppSelector(
    state => state.myModulesSection.pos.updateMode,
  );
  const updatedPosTerminal = useAppSelector(
    state => state.myModulesSection.pos.updatedPosTerminal,
  );
  const brandId = useAppSelector(s => s.brand.brand?.id);

  const handleOnCancel = () => {
    dispatch(addPosTerminalModalAction(false));
    dispatch(updatedPosTerminalAction(null));
    dispatch(updateModeAction(false));
  };

  const handleSuccess = (res: {data: {message: string}}) => {
    handleOnCancel();
    helperDisplayToast(res?.data?.message || 'Terminal enregistré', true);
  };

  const handleSubmit = async (
    data: {
      label: string;
    },
    {setSubmitting, resetForm}: any,
  ) => {
    if (data.label) {
      let res;
      if (updateMode) {
        res = await updatePosTerminal(brandId, updatedPosTerminal.uuid, data);
      } else {
        res = await createPosTerminal(brandId, data);
      }
      if ([200, 204].includes(res.status)) {
        handleSuccess(res);
        resetForm();
        clientQuery.invalidateQueries(['getPosTerminals']);
      } else {
        helperDisplayToast(
          res?.data?.message || 'Impossible de créer le terminal',
          false,
        );
      }
    } else {
      helperDisplayToast('Tous les champs doivent être complétés', false);
    }
  };

  return (
    <ModalWrapper
      title={updateMode ? 'Modifier le terminal' : 'Nouveau terminal'}
      minWidth="28%"
      validateLabel={'Enregistrer le terminal'}
      cancelLabel={t(updateMode ? 'Annuler la modification' : 'CANCEL_CREATE')}
      onCancel={handleOnCancel}
      onSubmit={handleSubmit}
      initialValues={updateMode ? updatedPosTerminal : initialValues}>
      <FieldCustomInput label={'Nom du terminal'} name="label" required />
      <FieldsRequired />
    </ModalWrapper>
  );
};

export const FilterSquare = () => (
  <svg
    width="19"
    height="12"
    viewBox="0 0 19 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.115 1.41429C8.89605 0.633246 10.1624 0.633246 10.9434 1.41429L17.6438 8.11468C18.9037 9.37461 18.0114 11.5289 16.2296 11.5289L2.82883 11.5289C1.04702 11.5289 0.154681 9.37462 1.41461 8.11469L8.115 1.41429Z"
      fill="white"
    />
  </svg>
);

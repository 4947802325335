/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {SettingsIcon} from '../../assets/svgs/SettingsIcon';
import {ModuleOption} from '../atoms/ModuleOption';
import {THEME} from '../../styles/theme';
import {
  MODULES_ALLOWED,
  MODULES_ICONS,
} from '../../views/Online/MyModules/data';
import {Overlay} from '../atoms/Overlay';
import {editConsumptionModalAction} from '../../redux/modals';
import {useAppDispatch} from '../../hooks/useReduxHook';
import {
  moduleSelectedAction,
  subscriptionSelectedAction,
} from '../../redux/myModulesSection/subscriptions';
import {useNavigate} from 'react-router-dom';
import {
  activateSubscription,
  deactivateSubscription,
} from '../../api/fetchSubscription';
import {useQueryClient} from '@tanstack/react-query';
import {AbsoluteLoader} from '../layouts/AbsoluteLoader';
import {useState} from 'react';
import {helperDisplayToast} from '../../helpers/helperToast';

export const ModuleCard = ({
  module,
  moduleDetails,
}: {
  module: any;
  moduleDetails?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const Icon = MODULES_ICONS[module.code as keyof typeof MODULES_ICONS];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const notAllowed = module.subscription_managements.every(
    (subscription: any) => !subscription.allowed,
  );

  const clientQuery = useQueryClient();

  const handleClickConsumptionSetting = (subscription: any) => {
    dispatch(moduleSelectedAction(module));
    dispatch(subscriptionSelectedAction(subscription));
    dispatch(editConsumptionModalAction(true));
  };

  const handleClickSettings = () => {
    const newModule = module.code === 'b_order' 
      ? 'b_kiosk' 
      : module.code === 'b_connect' 
      ? 'b_delivery' 
      : module.code;
    navigate(newModule.replace('_', ''));
  };

  const handleToggleConsumption = async (subscription: any) => {
    let res;

    setLoading(true);

    if (subscription.activated) {
      res = await deactivateSubscription(
        subscription.brand_id,
        subscription.id,
      );
    } else {
      res = await activateSubscription(subscription.brand_id, subscription.id);
    }

    await clientQuery.invalidateQueries(['subscriptions']);

    if (res?.status === 200) {
      helperDisplayToast(res.message, true);
    }

    setLoading(false);
  };

  return (
    <div
      className="flex"
      css={[styles.container, moduleDetails && {height: 60}]}>
      <div css={[styles.imgContainer, moduleDetails && {width: '10%'}]}>
        <img
          src={`/images/${module.code}.${
            module.code === 'b_drive' ? 'png' : 'jpeg'
          }`}
          alt=""
        />
      </div>

      <div css={styles.content}>
        {!!Icon && <Icon />}
        <p css={styles.description} className="title03">
          {module.description}
        </p>
        <div className="flex" css={styles.consumptions}>
          {[...module.subscription_managements]
            ?.sort((a, b) => a.consumption_mode_id - b.consumption_mode_id)
            .map((subscription: any) => {
              return (
                <ModuleOption
                  key={subscription.id}
                  option={subscription}
                  onClick={handleClickConsumptionSetting}
                  onToggle={handleToggleConsumption}
                />
              );
            })}
        </div>
      </div>

      {!moduleDetails && MODULES_ALLOWED.includes(module.code) && (
        <div
          css={styles.settings}
          className="flex-center"
          onClick={handleClickSettings}>
          <SettingsIcon />
        </div>
      )}
      {notAllowed && module.code !== 'b_pos' && <Overlay />}
      {loading && <AbsoluteLoader />}
    </div>
  );
};

const styles = {
  container: css({
    position: 'relative',
    backgroundColor: 'white',
    minHeight: '6.625rem',
    alignItems: 'flex-start',
    borderRadius: '0.31rem',
  }),
  imgContainer: css({
    width: '25%',
    height: '100%',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  }),
  content: css({
    flex: 1,
    padding: '0.8rem',
    paddingRight: '0.5rem',
  }),
  description: css({
    margin: '0.5rem 0',
    textTransform: 'uppercase',
    color: '#A7A7AB',
  }),
  consumptions: css({
    gap: '0.3rem',
    flexWrap: 'wrap',
  }),
  settings: css({
    backgroundColor: THEME.colors.bgLight,
    marginTop: '0.8rem',
    marginRight: '0.5rem',
    padding: '0.62rem',
    borderRadius: '0.13rem',
    cursor: 'pointer',
  }),
};

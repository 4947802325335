/** @jsxImportSource @emotion/react */

import {useQuery} from '@tanstack/react-query';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {THEME} from '../../../../styles/theme';
import {ProductList} from '../../../../components/molecules/ProductList';
import {Loader} from '../../../../components/atoms/Loader';
import {css} from '@emotion/react';
import {ActionButton} from '../../../../components/atoms/ActionButton';
import {EditIcon} from '../../../../assets/svgs/EditIcon';
import {helperLabelTranslation} from '../../../../helpers/helperLabel';
import {ToggleButton} from '../../../../components/atoms/ToggleButton';
import {ChevronUpIcon} from '../../../../assets/svgs/ChevronUpIcon';
import {ChevronDownIcon} from '../../../../assets/svgs/ChevronDownIcon';
import {
  dissociateProductToSuggestionCategory,
  fetchSuggestionCategoriesDetail,
  updateSuggestionCategory,
} from '../../../../api/fetchSuggestion';
import {
  ISuggestCategory,
  categoryProductsAction,
  categorySelectedAction,
  updatedCategoryAction,
} from '../../../../redux/myOffersSection/categories';
import {
  addAdditionalCategoryModalAction,
  deleteAdditionalCategoryModalAction,
} from '../../../../redux/modals';
import {useEffect} from 'react';
import {TrashIcon} from '../../../../assets/svgs/TrashIcon';
import {useBrandAccess} from '../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../constants/frontPolicies';
import {updateCategoryItemsPosition} from '../../../../api/fetchCategories';
import {THandleDragAndDrop} from '../../../../components/organisms/SortableListVerticalContainer';

export const Categories = ({
  categories,
  setLoading,
}: {
  categories?: ISuggestCategory[];
  setLoading: React.Dispatch<boolean>;
}) => {
  const dispatch = useAppDispatch();
  const {brandHasAuthorization} = useBrandAccess();

  const isParentAuth = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const categorySelected = useAppSelector(
    s => s.myOffersSection.categories.categorySelected,
  );

  const {data, isLoading, refetch} = useQuery(
    [
      `fetchSuggestionCategoriesDetail-${categorySelected?.uuid}`,
      categorySelected?.uuid,
    ],
    () => fetchSuggestionCategoriesDetail(String(categorySelected?.uuid)),
    {
      enabled: !!categorySelected?.uuid,
    },
  );

  const handleOpenCategory = (category: any) => {
    dispatch(categorySelectedAction(category));
  };

  const handleRemoveProduct = async (item: any) => {
    try {
      if (!isParentAuth) return;
      setLoading(true);
      const res = await dissociateProductToSuggestionCategory(
        String(categorySelected?.uuid),
        item.uuid,
      );

      if ([200, 204].includes(res.status)) {
        helperDisplayToast('Item retiré', true);
        refetch();
      } else {
        helperDisplayToast("Impossible de retirer l'item", false);
      }

      setLoading(false);
    } catch (error) {
      helperDisplayToast('Une erreur est survenue', false);
    }
  };

  const handleClickEdit = (category: ISuggestCategory) => {
    dispatch(updatedCategoryAction(category));
    dispatch(addAdditionalCategoryModalAction(true));
  };

  const handleClickDelete = (category: ISuggestCategory) => {
    dispatch(updatedCategoryAction(category));
    dispatch(deleteAdditionalCategoryModalAction(true));
  };

  const handleToggleCategory = async (category: ISuggestCategory) => {
    const res = await updateSuggestionCategory(category.uuid, {
      ...category,
      label: helperLabelTranslation(category),
      isArchived: !category.isArchived,
    });
    if ([200, 204].includes(res.status)) {
      helperDisplayToast('Catégorie mise à jour', true);
    } else {
      helperDisplayToast('Impossible de mettre à jour la catégorie', false);
    }
  };

  const handleDragAndDrop: THandleDragAndDrop = async ({items}) => {
    if (categorySelected && items) {
      const newItems = items.map(item => ({
        uuid: item.uuid,
        type: 1,
        image: null,
      }));

      const res = await updateCategoryItemsPosition(
        categorySelected?.uuid,
        newItems,
      );

      if (res?.status === 204) {
        helperDisplayToast('Position modifiée', true);
      } else {
        helperDisplayToast('Impossible de modifier la position', false);
      }
    }
  };

  useEffect(() => {
    if (data) {
      dispatch(categoryProductsAction(data.suggestedItems));
    }
  }, [data, dispatch]);

  return (
    <div>
      {categories?.map(category => {
        const isOpen =
          categorySelected && category.uuid === categorySelected?.uuid;

        return (
          <div style={{marginBottom: 5}} key={category.uuid}>
            <div css={styles.dropdown}>
              <div className="flex" style={{gap: '0.3rem'}}>
                <ActionButton
                  onClick={() => handleClickEdit(category)}
                  Icon={EditIcon}
                  disabled={!isParentAuth}
                />
                <ActionButton
                  onClick={() => handleClickDelete(category)}
                  Icon={TrashIcon}
                  disabled={!isParentAuth}
                />
              </div>
              <div
                css={styles.dropdownHeader}
                onClick={() => handleOpenCategory(category)}>
                <div className="textSemiBold" css={styles.title}>
                  <span>{category.backofficeLabel}</span>
                </div>
                <div css={styles.toggle}>
                  <ToggleButton
                    active={!category.isArchived}
                    onToggle={() => handleToggleCategory(category)}
                    disabled={!isParentAuth}
                  />
                </div>
                <div css={styles.chevron}>
                  {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </div>
              </div>
            </div>

            {data && isOpen && (
              <div css={styles.dropdownContent}>
                {data?.suggestedItems?.length > 0 ? (
                  <ProductList
                    data={[...data?.suggestedItems]?.sort(
                      (a, b) => a.position - b.position,
                    )}
                    handleOnClick={handleRemoveProduct}
                    handleDragAndDrop={handleDragAndDrop}
                    style={{backgroundColor: THEME.colors.white}}
                    showProductType
                    draggable
                    showCross
                  />
                ) : (
                  <p className="textSemiBold">Aucun produits</p>
                )}
              </div>
            )}
            {(isLoading || !data) && isOpen && (
              <div className="flex-center" css={styles.dropdownContent}>
                <Loader />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  dropdown: css({
    backgroundColor: THEME.colors.white,
    display: 'flex',
    borderRadius: 2,
    padding: '0px 10px',
    alignItems: 'center',
  }),
  dropdownHeader: css({
    backgroundColor: THEME.colors.white,
    display: 'flex',
    padding: '7px 10px',
    borderRadius: 2,
    alignItems: 'center',
    cursor: 'pointer',
    flex: 1,
  }),
  dropdownContent: css({
    backgroundColor: THEME.colors.white,
    padding: 10,
    marginBottom: 10,
  }),
  title: css({
    paddingLeft: 10,
  }),
  edit: css({
    backgroundColor: THEME.colors.bgLight,
    width: 25,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 2,
  }),
  chevron: css({
    // marginLeft: 'auto',
  }),
  toggle: css({
    marginLeft: 'auto',
    marginRight: 10,
  }),
};

/** @jsxImportSource @emotion/react */

import {useContext, useEffect} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {filterAction} from '../../redux/filter';
import {FilterInput} from '../atoms/FilterInput';
import {useAppSelector} from '../../hooks/useReduxHook';

export const SearchFilter = ({
  width = 'auto',
  value,
  onChange,
  withBorder,
  marginRight,
}: {
  width?: string | number;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  withBorder?: boolean;
  marginRight?: string | number;
}) => {
  const {dispatch} = useContext<any>(UtilsContext);
  const search = useAppSelector(s => s.filter.search);

  const formValue = onChange ? value : search;

  const handleResetSearch = () => {
    if (onChange) {
      onChange({
        target: {name: 'search', value: ''},
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      dispatch(filterAction({name: 'search', values: ''}));
    }
  };

  const handleSearch = (e: any) => {
    if (onChange) {
      onChange(e);
    } else {
      dispatch(filterAction({name: e.target.name, values: e.target.value}));
    }
  };

  useEffect(() => {
    return () => {
      handleResetSearch();
    };
  }, []);

  return (
    <FilterInput
      name="search"
      marginRight={marginRight}
      placeholder="Rechercher..."
      value={formValue}
      onChange={handleSearch}
      width={width}
      withBorder={withBorder}
      onReset={handleResetSearch}
    />
  );
};

/* @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {TextVariant} from '../atoms/TextVariant';
import {THEME} from '../../styles/theme';
import {useTranslation} from 'react-i18next';

export const DeleteModal = ({
  title,
  text,
  onSubmit,
  onCancel,
  isLoading,
}: {
  title: string;
  text: string;
  onSubmit: () => void;
  onCancel: () => void;
  isLoading: boolean;
}) => {
  const {t} = useTranslation();

  return (
    <ModalWrapper
      //   title={`Suppression ${title}`}
      onCancel={onCancel}
      showBtns={false}
      minWidth={'370px'}>
      <TextVariant
        variant="title02"
        text={title}
        style={{textAlign: 'center', marginBottom: 20}}
      />
      <div css={styles.deleteText}>
        <TextVariant
          text={t('ARE_YOU_SURE_TO_DELETE')}
          style={styles.text}
          variant="textSemiBold"
        />
        <TextVariant
          text={text}
          style={[styles.text, {color: THEME.colors.primary, marginTop: 10}]}
          variant="textSemiBold"
        />
      </div>

      <div className="flex-space-between">
        <ButtonCustom
          label={t('CANCEL')}
          backgroundColor={THEME.colors.red}
          width="28%"
          onClick={onCancel}
        />
        <ButtonCustom
          label={t('CONFIRM_DELETE')}
          backgroundColor={THEME.colors.green}
          width="70%"
          type="submit"
          onClick={onSubmit}
          disabled={isLoading}
          isLoading={isLoading}
        />
      </div>
    </ModalWrapper>
  );
};

const styles = {
  deleteText: css({
    textAlign: 'center',
    marginBottom: '20px',
  }),
  text: css({
    textAlign: 'center',
  }),
};

import {helperTruncImgUrl} from '../../helpers/helperTruncImgUrl';

interface IImageCustom {
  src: any;
  alt?: string;
  placeholderSrc?: string;
  styles?: React.CSSProperties;
  isUpload?: boolean;
  noTrunc?: boolean;
}

export const ImageCustom = ({
  src,
  alt = 'image',
  placeholderSrc = '/images/placeholder.svg',
  styles = {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    objectFit: 'cover',
  },
  isUpload = false,
  noTrunc = false,
}: IImageCustom) => {
  return (
    <img
      style={styles}
      src={
        !!src
          ? isUpload || noTrunc
            ? src
            : helperTruncImgUrl(src)
          : placeholderSrc
      }
      alt={alt}
      onError={({currentTarget}) => {
        currentTarget.onerror = null;
        currentTarget.src = placeholderSrc;
      }}
    />
  );
};

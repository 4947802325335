import {IRecipeFetchReturn} from '@bill-app-types/b-types';
import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  updateMode: boolean;
  recipeSelected: IRecipeFetchReturn | null;
}

const initialState: ISlice = {
  updateMode: false,
  recipeSelected: null,
};

const slice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    recipeUpdateModeAction(state, action) {
      state.updateMode = action.payload;
    },
    recipeSelectedAction(state, action) {
      state.recipeSelected = action.payload;
    },
  },
});

export const {recipeUpdateModeAction, recipeSelectedAction} = slice.actions;

export default slice.reducer;

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {Center} from './HeaderSections/Center';
import {RightBlock} from './RightBlock';
import {LeftBlock} from './LeftBlock';

export const Additional = withRedirectAndNavigation(() => {
  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} />
      <div css={styles.content}>
        <LeftBlock />
        <div css={styles.section}>
          <RightBlock />
        </div>
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: {
    padding: '2vh',
    height: '87%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  section: {
    width: '49%',
    overflow: 'auto',
  },
};

/** @jsxImportSource @emotion/react */
import {useContext, useEffect, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {filterAction, resetIsSyncPosAction} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
import {FilterClick} from './FilterClick';
import {useTranslation} from 'react-i18next';

export const SyncFilter = ({marginRight}: {marginRight?: string}) => {
  const {t} = useTranslation();
  const isSyncPos = useAppSelector(state => state.filter.isSyncPos);
  const {dispatch} = useContext<any>(UtilsContext);

  const [selectedIsSyncPos, setSelectedIsSyncPos] = useState<null | boolean>(
    null,
  );
  const [forceClose, setForceClose] = useState(false);

  const handleClickValue = () => {
    if (selectedIsSyncPos === null) {
      setSelectedIsSyncPos(true);
    } else if (selectedIsSyncPos) {
      setSelectedIsSyncPos(false);
    } else {
      setSelectedIsSyncPos(null);
    }
  };

  const handleResetFilter = () => {
    dispatch(resetIsSyncPosAction());
  };

  const handleSubmit = () => {
    dispatch(filterAction({name: 'isSyncPos', values: selectedIsSyncPos}));
    setForceClose(true);
  };

  useEffect(() => {
    setSelectedIsSyncPos(isSyncPos);
    return () => {
      setForceClose(false);
    };
  }, [isSyncPos]);

  return (
    <FilterButton
      marginRight={marginRight}
      label={t('POS_SYNC')}
      valueType="value"
      values={
        isSyncPos === null
          ? ''
          : isSyncPos
          ? 'Synchronisée'
          : 'Non Synchronisée'
      }
      showResetButton={isSyncPos !== null}
      onReset={handleResetFilter}
      hideFilterIcon={!!isSyncPos}
      forceClose={forceClose}>
      <FilterClick
        title={t('POS_SYNC')}
        selectedValue={selectedIsSyncPos}
        selectedText={
          selectedIsSyncPos === null
            ? 'Voir tout'
            : selectedIsSyncPos
            ? 'Synchronisée'
            : 'Non synchronisée'
        }
        onSubmit={handleSubmit}
        handleClickValue={handleClickValue}
      />
    </FilterButton>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
//import {ButtonsSwitch} from '../../../../../components/atoms/ButtonsSwitch';
import {InputLabel} from '../../../../../components/atoms/InputLabel';
import {TextAreaLabel} from '../../../../../components/atoms/TextAreaLabel';
import {Title} from '../../../../../components/atoms/Title';
import {ToggleButtonLabel} from '../../../../../components/molecules/ToggleButtonLabel';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {productPreRegisterChangeAction} from '../../../../../redux/myCatalogSection/products';
import {UploadButton} from '../../../../../components/atoms/UploadButton';
import {SelectList} from '../../../../../components/molecules/SelectList';
import {useTranslation} from 'react-i18next';
import {helperFormatSelectItem} from '../../../../../helpers/helperFormatSelectItem';
import {fetchCatalogVendorStructure} from '../../../../../api/fetchCatalogVendorStructure';
import {useQuery} from '@tanstack/react-query';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';

export const InfoGeneral = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const {brandHasAuthorization} = useBrandAccess();

  const productPreRegister = useAppSelector(
    s => s.myCatalogSection.products.productPreRegister,
  );

  const {data: vendorStructureData} = useQuery(
    ['fetchCatalogVendorStructure'],
    fetchCatalogVendorStructure,
  );

  const image = productPreRegister?.image;

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;

    dispatch(
      productPreRegisterChangeAction({
        name,
        value,
      }),
    );
  };

  const handleChangeVisualPicture = (e: any) => {
    const {name, files} = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const imageURL = e.target.result;

        dispatch(
          productPreRegisterChangeAction({
            name,
            value: {url: imageURL, file},
          }),
        );
      };

      reader.readAsDataURL(file);
    }
  };

  const handleClickBarcode = () => {
    navigator.clipboard.writeText(productPreRegister?.barcode);
  };

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <div>
      <Title title="Informations générales" />
      <div
        className="flex"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: '1rem',
        }}>
        <div>
          <InputLabel
            label="Nom du produit"
            value={productPreRegister?.label}
            name="label"
            onChange={handleChange}
            required
            disabled={!hasAuthorization}
          />
          <div className="flex" css={styles.flexGap}>
            <InputLabel
              label="Prix"
              required
              name="price"
              value={productPreRegister?.price}
              onChange={handleChange}
            />
            <SelectList
              label={t('VENDOR_STRUCTURE')}
              placeHolder={t('VENDOR_STRUCTURE')}
              name="vendorStructureUuid"
              value={productPreRegister?.vendorStructureUuid}
              list={vendorStructureData?.map(helperFormatSelectItem)}
              onChange={handleChange}
              manageByFormik={false}
              disabled={!hasAuthorization}
            />
          </div>
          <InputLabel
            label="Code Article"
            onClickIcon={handleClickBarcode}
            value={productPreRegister?.barcode}
            disabled
            showIcon
          />

          <ToggleButtonLabel
            label="Suggestion des menus associés"
            marginBottom={'1rem'}
            active={productPreRegister?.hasSuggestedMenu}
            onToggle={() => {
              dispatch(
                productPreRegisterChangeAction({
                  name: 'hasSuggestedMenu',
                  value: !productPreRegister?.hasSuggestedMenu,
                }),
              );
            }}
            disabled={!hasAuthorization}
          />
          <ToggleButtonLabel
            label="Visibilité"
            active={!productPreRegister?.isArchived}
            onToggle={() => {
              dispatch(
                productPreRegisterChangeAction({
                  name: 'isArchived',
                  value: !productPreRegister?.isArchived,
                }),
              );
            }}
            disabled={!hasAuthorization}
          />
        </div>

        <div>
          <TextAreaLabel
            label="Description du produit"
            name="description"
            value={productPreRegister?.description || ''}
            onChange={handleChange}
            height={150}
            required
            disabled={!hasAuthorization}
          />
          <TextAreaLabel
            label="Composition du produit"
            name="composition"
            value={productPreRegister?.composition || ''}
            onChange={handleChange}
            height={150}
            required
            disabled={!hasAuthorization}
          />
        </div>

        <div className="flex-col">
          <label className="textLabel">Visuel</label>

          <UploadButton
            width={194}
            x={1920}
            y={1080}
            name="updatedImage"
            handleChange={handleChangeVisualPicture}
            value={
              !!productPreRegister?.updatedImage
                ? productPreRegister?.updatedImage?.url
                : image || undefined
            }
            disabled={!hasAuthorization}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
  }),
  flexGap: css({
    gap: '1rem',
  }),
  pictures: css(`
    display: grid;
    grid-template-columns: 1fr 60%;
    gap: 0.5rem;
    height: 204px;

    & > div {
        background-color: #FFF;
        maxHeight: 204px;
        overFlow: hidden;
    }
    
  `),
};

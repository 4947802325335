import {useCallback, useState} from 'react';
import {useDebounce} from './useDebounce';
import {useQueryClient} from '@tanstack/react-query';

export const useTranslations = () => {
  const [search, setSearch] = useState('');
  const [showAll, setShowAll] = useState(true);
  const debounceSearch = useDebounce(search, 500);
  const [isOpened, setIsOpened] = useState(false);

  const clientQuery = useQueryClient();

  const handleToggleIsOpened = useCallback(() => {
    setIsOpened(prev => !prev);
  }, []);

  const handleToggleShowAll = useCallback((v: boolean) => {
    setShowAll(v);
  }, []);

  const handleChangeSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [],
  );

  const invalidQuery = useCallback(
    (category: string, type?: number) => {
      let query: any[] = [];
      switch (category) {
        case 'Items':
          query = [`fetchItemsTranslations-${type}`];
          break;

        case 'Categories':
          query = ['fetchCategoriesTranslations'];
          break;

        case 'Modifiers':
          query = [`fetchModifiersTranslations-${type}`];
          break;

        case 'Suggestions':
          query = ['fetchSuggestionCategoriesTranslations'];
          break;

        case 'Bkiosk':
          query = ['fetchBkioskParameterTranslations'];
          break;
        case 'BWebApp':
          query = ['fetchBWebAppParameterTranslations'];
          break;
        case 'BGlobal':
          query = ['fetchBGlobalParameterTranslations'];
          break;

        case 'Areas':
          query = ['fetchAreasTranslations'];
          break;

        default:
          return;
      }

      clientQuery.invalidateQueries(query);
    },
    [clientQuery],
  );

  return {
    search,
    showAll,
    debounceSearch,
    isOpened,
    handleToggleIsOpened,
    handleToggleShowAll,
    handleChangeSearch,
    invalidQuery,
  };
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useEffect} from 'react';
import {
  deletePosCategoryProductOld,
  fetchPosCategoryProductsOld,
  updatePosCategoryProductPositionOld,
} from '../../../../api/fetchPosCategories';
import {ChevronDownIcon} from '../../../../assets/svgs/ChevronDownIcon';
import {ChevronUpIcon} from '../../../../assets/svgs/ChevronUpIcon';
import {EditIcon} from '../../../../assets/svgs/EditIcon';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {
  categoryProductsAction,
  categorySelectedAction,
  updatedCategoryAction,
  updateModeAction,
} from '../../../../redux/posCategories';
import {THEME} from '../../../../styles/theme';
import {ActionButton} from '../../../../components/atoms/ActionButton';
import {ToggleButton} from '../../../../components/atoms/ToggleButton';
import {ProductListOld} from '../../../../components/molecules/ProductListOld';
import {addPosCategoryModalAction} from '../../../../redux/modals';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {Loader} from '../../../../components/atoms/Loader';
import {helperLabelTranslation} from '../../../../helpers/helperLabel';
import {useQuery} from '@tanstack/react-query';

export const RecursiveCategoriesDropdown = ({
  toggle = false,
  categories,
  level = 0,
}: // rows,
{
  toggle?: boolean;
  showProductType?: boolean;
  categories?: any;
  level?: number;
}) => {
  const dispatch = useAppDispatch();

  const categorySelected = useAppSelector(
    s => s.posCategories.categorySelected,
  );

  const {data, isLoading, refetch} = useQuery(
    [`fetchPosCategoryProducts-${categorySelected?.id}`, categorySelected?.id],
    () => fetchPosCategoryProductsOld(categorySelected?.id),
    {
      enabled: !!categorySelected?.id,
    },
  );

  useEffect(() => {
    if (data) {
      dispatch(categoryProductsAction(data));
    }
  }, [data, dispatch]);

  const handleOpenCategory = (category: any) => {
    dispatch(categorySelectedAction(category));
  };

  const handleRemoveProduct = async (item: any) => {
    const data = {
      product_id: item?.id,
      category_id: categorySelected?.id,
      type: item.product_type,
    };
    const res = await deletePosCategoryProductOld(data);
    if (res && res.status === 200) {
      helperDisplayToast('Item retiré', true);
    } else {
      helperDisplayToast("Impossible de retirer l'item de la catégorie", false);
    }
    refetch();
  };

  const handleClickEdit = (category: any) => {
    dispatch(addPosCategoryModalAction(true));
    dispatch(updateModeAction(true));
    dispatch(
      updatedCategoryAction({
        ...category,
        label: helperLabelTranslation(category),
      }),
    );
  };

  const handleDragAndDrop = async ({
    product,
    newIndex,
  }: {
    product: any;
    newIndex: number;
  }) => {
    if (categorySelected) {
      const res = await updatePosCategoryProductPositionOld(
        {
          pos_category_id: categorySelected?.id,
          product_id: product.id,
        },
        {
          type: product.product_type,
          position: newIndex + 1,
        },
      );
      if (res?.status === 200) {
        helperDisplayToast('Position modifiée', true);
      } else {
        helperDisplayToast('Impossible de modifier la position', false);
      }
    }
  };

  return (
    <div style={{marginLeft: level === 0 ? 0 : 20}}>
      {categories?.map((category: any, index: number) => {
        const isOpen = categorySelected && category.id === categorySelected?.id;

        return (
          <div style={{marginBottom: 5}} key={index}>
            <div
              css={[
                styles.dropdown,
                {marginBottom: category?.subPosCategories?.length > 0 ? 5 : 0},
              ]}>
              <ActionButton
                onClick={() => handleClickEdit(category)}
                Icon={EditIcon}
              />
              <div
                css={styles.dropdownHeader}
                onClick={() => handleOpenCategory(category)}>
                <div className="textSemiBold" css={styles.title}>
                  <span>{helperLabelTranslation(category)}</span>
                </div>
                <div css={styles.toggle}>
                  {toggle && <ToggleButton active />}
                </div>
                <div css={styles.chevron}>
                  {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </div>
              </div>
            </div>

            {data && isOpen && (
              <div css={styles.dropdownContent}>
                {data?.length > 0 ? (
                  <ProductListOld
                    data={[...data]?.sort(
                      (a: any, b: any) => a.position - b.position,
                    )}
                    handleOnClick={handleRemoveProduct}
                    handleDragAndDrop={handleDragAndDrop}
                    style={{backgroundColor: THEME.colors.white}}
                    showProductType
                    draggable
                    showCross
                  />
                ) : (
                  <p className="textSemiBold">Aucun produits</p>
                )}
              </div>
            )}
            {isLoading && isOpen && (
              <div className="flex-center" css={styles.dropdownContent}>
                <Loader />
              </div>
            )}

            {category?.subPosCategories?.length > 0 && (
              <>
                <RecursiveCategoriesDropdown
                  categories={[...category?.subPosCategories]?.sort(
                    (a: any, b: any) => a?.position - b?.position,
                  )}
                  level={level + 1}
                />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  dropdown: css({
    backgroundColor: THEME.colors.white,
    display: 'flex',
    borderRadius: 2,
    padding: '0px 10px',
    alignItems: 'center',
  }),
  dropdownHeader: css({
    backgroundColor: THEME.colors.white,
    display: 'flex',
    padding: '7px 10px',
    borderRadius: 2,
    alignItems: 'center',
    cursor: 'pointer',
    flex: 1,
  }),
  dropdownContent: css({
    backgroundColor: THEME.colors.white,
    padding: 10,
    marginBottom: 10,
  }),
  title: css({
    paddingLeft: 10,
  }),
  edit: css({
    backgroundColor: THEME.colors.bgLight,
    width: 25,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 2,
  }),
  chevron: css({
    // marginLeft: 'auto',
  }),
  toggle: css({
    marginLeft: 'auto',
    marginRight: 10,
  }),
};

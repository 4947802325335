import {IGetCustomersQueries} from '@bill-app-types/b-types';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';

export const getCustomers = async (data: IGetCustomersQueries) => {
  //
  const query = helperGenerateQuery(data);

  console.log('### getCustomers ###');
  const res: any = await axiosInstance
    .get(`/buser/backoffice/customers${query}`, {
      params: {
        orderBy: JSON.stringify(data.orderBy),
      },
    })
    .catch(err => {
      console.log('❌ getCustomers : ', err);
    });
  return res?.data?.data;
};

export const getCustomerDetails = async (customerUuid?: string) => {
  //
  console.log('### getCustomersDetails ###');
  const res: any = await axiosInstance
    .get(`/buser/backoffice/customers/${customerUuid}`)
    .catch(err => {
      console.log('❌ getCustomersDetails : ', err);
    });
  return res?.data?.data;
};

export const updateCustomersBalance = async (data: {
  customerUuids: string[];
  loyaltyPoints: number;
  notif: boolean;
}) => {
  //

  console.log('### updateCustomersBalance ###');
  const res: any = await axiosInstance
    .put(`/buser/backoffice/customers/balances`, data)
    .catch(err => {
      console.log('❌ updateCustomersBalance : ', err);
    });
  return res;
};

export const getCustomerOrders = async (
  data: {
    offset: number;
    limit: number;
  },
  customerUuid?: string,
) => {
  //
  const query = helperGenerateQuery(data);

  console.log('### getCustomerOrders ###');
  const res: any = await axiosInstance
    .get(`/bcore/protected/admin/customers/${customerUuid}/orders${query}`)
    .catch(err => {
      console.log('❌ getCustomerOrders : ', err);
    });
  return res?.data?.data;
};

export const exportCustomersCsv = async (data: IGetCustomersQueries) => {
  //
  const query = helperGenerateQuery(data);

  console.log('### exportCustomersCsv ###');
  const res: any = await axiosInstance
    .get(`/buser/backoffice/customers/export${query}`, {
      params: {
        orderBy: JSON.stringify(data.orderBy),
      },
    })
    .catch(err => {
      console.log('❌ exportCustomersCsv : ', err);
    });
  return res;
};

import {useContext} from 'react';
import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {THEME} from '../../../../../styles/theme';
import {addProductionAreaModalAction} from '../../../../../redux/modals';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {areaProductionUpdatedAction} from '../../../../../redux/myRestaurantSection/areaProductions';

export const Center = () => {
  const {t, dispatch} = useContext<any>(UtilsContext);
  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const handleAddProductionZone = () => {
    dispatch(areaProductionUpdatedAction(null));
    dispatch(addProductionAreaModalAction(true));
  };

  return (
    <>
      <ButtonCustom
        label={t('ADD_A_PRODUCTION_AREA')}
        backgroundColor={THEME.colors.primary}
        onClick={handleAddProductionZone}
        disabled={!hasAuthorization}
      />
    </>
  );
};

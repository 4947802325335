import {SVGProps} from 'react';

export const TreeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.875 8.03571C16.875 8.92299 16.0652 9.64286 15.067 9.64286H1.80804C0.809473 9.64286 0 8.92299 0 8.03571V6.96429C0 6.07701 0.809473 5.35714 1.80804 5.35714H15.067C16.0652 5.35714 16.875 6.07701 16.875 6.96429V8.03571ZM1.80804 6.96429V8.03571H15.067V6.96429H1.80804ZM9.64286 13.3929C9.64286 14.2801 8.83301 15 7.83482 15H1.80804C0.809473 15 0 14.2801 0 13.3929V12.3214C0 11.4342 0.809473 10.7143 1.80804 10.7143H7.83482C8.83301 10.7143 9.64286 11.4342 9.64286 12.3214V13.3929ZM1.80804 12.3214V13.3929H7.83482V12.3214H1.80804ZM1.80804 4.28571C0.809473 4.28571 0 3.56585 0 2.67857V1.60714C0 0.719531 0.809473 0 1.80804 0H12.6563C13.6544 0 14.4643 0.719531 14.4643 1.60714V2.67857C14.4643 3.56585 13.6544 4.28571 12.6563 4.28571H1.80804ZM12.6563 2.67857V1.60714H1.80804V2.67857H12.6563Z"
      fill="white"
    />
  </svg>
);

/** @jsxImportSource @emotion/react */
import {useContext, useEffect, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {filterAction, resetIsArchivedAction} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
import {FilterClick} from './FilterClick';

export const VisibilityFilter = ({
  marginRight,
  title = 'Visibilité',
  values = ['Non visible', 'Visible'],
}: {
  marginRight?: string;
  title?: string;
  values?: string[];
}) => {
  const isArchived = useAppSelector(state => state.filter.isArchived);
  const {dispatch} = useContext<any>(UtilsContext);

  const [selectedIsArchived, setSelectedIsArchived] = useState<null | boolean>(
    null,
  );
  const [forceClose, setForceClose] = useState(false);

  const handleClickValue = () => {
    if (selectedIsArchived === null) {
      setSelectedIsArchived(false);
    } else if (!selectedIsArchived) {
      setSelectedIsArchived(true);
    } else {
      setSelectedIsArchived(null);
    }
  };

  const handleResetFilter = () => {
    dispatch(resetIsArchivedAction());
  };

  const handleSubmit = () => {
    console.log('handleSubmit');

    dispatch(filterAction({name: 'isArchived', values: selectedIsArchived}));
    setForceClose(true);
  };

  useEffect(() => {
    setSelectedIsArchived(isArchived);
    return () => {
      setForceClose(false);
    };
  }, [isArchived]);

  return (
    <FilterButton
      marginRight={marginRight}
      label={title}
      valueType="value"
      values={isArchived === null ? '' : isArchived ? values[0] : values[1]}
      showResetButton={isArchived !== null}
      onReset={handleResetFilter}
      hideFilterIcon={!!isArchived}
      forceClose={forceClose}>
      <FilterClick
        title={title}
        selectedValue={selectedIsArchived}
        selectedText={
          selectedIsArchived === null
            ? 'Voir tout'
            : selectedIsArchived
            ? values[0]
            : values[1]
        }
        onSubmit={handleSubmit}
        handleClickValue={handleClickValue}
      />
    </FilterButton>
  );
};

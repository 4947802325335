/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../../../components/atoms/TextVariant';
import {ActionsContainer} from '../../../../../../components/molecules/ActionsContainer';
import {THEME} from '../../../../../../styles/theme';
import {useContext} from 'react';
import {UtilsContext} from '../../../../../../contexts/UtilsContext';
import {addOptionModalAction} from '../../../../../../redux/modals';
import {duplicateCatalogOption} from '../../../../../../api/fetchCatalogOptions';
import {helperConfirmDialog} from '../../../../../../helpers/helperConfirmDialog';
import {helperDisplayToast} from '../../../../../../helpers/helperToast';
import {
  updateModeAction,
  updatedOptionAction,
} from '../../../../../../redux/myCatalogSection/options';
import {useQueryClient} from '@tanstack/react-query';
import {deleteItem} from '../../../../../../api/fetchItems';
import {ImageCustom} from '../../../../../../components/atoms/ImageCustom';
import {helperFormatPrice} from '../../../../../../helpers/helperFormatPrice';
import {BRAND_AUTHORIZATION} from '../../../../../../constants/frontPolicies';
import {IFetchItemsReturnItem} from '@bill-app-types/b-types';
import {helperLabelTranslation} from '../../../../../../helpers/helperLabel';

interface IOptionCard {
  item: IFetchItemsReturnItem;
}

export const OptionCard = ({item}: IOptionCard) => {
  const {dispatch} = useContext<any>(UtilsContext);
  const clientQuery = useQueryClient();

  const handleClickEdit = () => {
    dispatch(addOptionModalAction(true));
    dispatch(updatedOptionAction(item));
    dispatch(updateModeAction(true));
  };

  const handleClickCopy = () => {
    helperConfirmDialog({
      validateLabel: 'Dupliquer',
      onSubmit: async () => {
        const res = await duplicateCatalogOption(item.uuid);
        if (res && res.status === 204) {
          helperDisplayToast('Catégorie dupliquée', true);
          clientQuery.invalidateQueries([`getCatalogOptions`]);
        } else {
          helperDisplayToast(
            res?.data?.message || "Impossible de dupliquer l'option",
            false,
          );
        }
      },
    });
  };

  const handleClickDelete = () => {
    helperConfirmDialog({
      onSubmit: async () => {
        const res = await deleteItem(item.uuid);
        if (res && res.status === 204) {
          helperDisplayToast('Option supprimée', true);
          clientQuery.invalidateQueries([`getCatalogOptions`]);
        } else {
          helperDisplayToast(
            res.data?.message || "Impossible de supprimer l'option",
            false,
          );
        }
      },
    });
  };

  return (
    <div css={styles.card}>
      <div css={styles.contain}>
        <div css={styles.imgContainer}>
          <ImageCustom src={item.image} alt={helperLabelTranslation(item)} />
        </div>
        <div>
          <ActionsContainer
            handleClickEdit={handleClickEdit}
            handleClickCopy={handleClickCopy}
            handleClickDelete={handleClickDelete}
            showCopy
            authorization={{
              edit: BRAND_AUTHORIZATION.ALL,
              copy: BRAND_AUTHORIZATION.PARENT,
              delete: BRAND_AUTHORIZATION.PARENT,
            }}
          />
        </div>
      </div>
      <div css={styles.footer}>
        <TextVariant
          text={helperLabelTranslation(item)}
          variant="textRegular"
          style={styles.label}
        />
        <TextVariant
          text={`${helperFormatPrice(item.price || 0)} €`}
          variant="title03"
          style={styles.price}
        />
      </div>
    </div>
  );
};

const styles = {
  card: css({
    aspectRatio: '1/1',
    backgroundColor: THEME.colors.white,
    border: `1px solid ${THEME.colors.lightGray}`,
    width: 120,
  }),
  contain: css({
    display: 'flex',
    flex: 1,
  }),
  imgContainer: css({
    flex: 1,
  }),
  label: css({
    color: THEME.colors.bgDark,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginBottom: '0.2rem',
  }),
  price: css({
    color: THEME.colors.primary,
    textAlign: 'end',
  }),
  footer: css({
    padding: '0px 3px',
  }),
};

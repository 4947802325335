/** @jsxImportSource @emotion/react */
import {SqsReceipt} from '../../../../../api/fetchSqsHistory';
import {TextVariant} from '../../../../../components/atoms/TextVariant';
import {helperFormatPrice} from '../../../../../helpers/helperFormatPrice';
import {THEME} from '../../../../../styles/theme';

export const columns = [
  {
    field: 'receipt_id',
    headerName: 'Ticket',
    flex: 1,
    renderCell: (row: SqsReceipt) => {
      const value = row.amount_ttc;
      return (
        <p css={styles.posReceiptId(value)} className="textSemiBold">
          {row.receipt_id}
        </p>
      );
    },
  },
  {
    // field: 'order_id',
    headerName: 'N° de Commande',
    flex: 1,
    valueGetter: (row: SqsReceipt) => {
      return row?.order_id?.[0] || '';
    },
  },
  {
    field: 'total',
    headerName: 'Montant HT',
    flex: 1,
    renderCell: (row: SqsReceipt) => {
      return (
        <TextVariant
          text={`${helperFormatPrice(Number(row.amount_ht))}€`}
          variant="textSemiBold"
        />
      );
    },
  },
  {
    field: 'total',
    headerName: 'Montant TTC',
    flex: 1,
    renderCell: (row: SqsReceipt) => {
      return (
        <TextVariant
          text={`${helperFormatPrice(Number(row.amount_ttc))}€`}
          style={{
            color:
              Number(row.amount_ttc) >= 0
                ? THEME.colors.green
                : THEME.colors.red,
          }}
          variant="textSemiBold"
        />
      );
    },
  },
  {
    headerName: "Date d'opération",
    flex: 1,
    valueGetter: (row: SqsReceipt) => {
      return row?.created_at?.split(' ')[0];
    },
  },
  {
    headerName: 'Heure d’opération',
    flex: 1,
    valueGetter: (row: SqsReceipt) => {
      return row?.created_at?.split(' ')[1];
    },
  },
];

const styles = {
  posReceiptId: (value: any) => ({
    color: value >= 0 ? 'var(--text-color)' : THEME.colors.red,
    textDecoration: value >= 0 ? 'none' : 'line-through',
  }),
};

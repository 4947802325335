import {createSlice} from '@reduxjs/toolkit';

const inititialState: {
  brand: any;
} = {
  brand: null,
};

const slice = createSlice({
  name: 'brand',
  initialState: inititialState,
  reducers: {
    brandAction(state, action) {
      state.brand = action.payload;
    },
  },
});

export const {brandAction} = slice.actions;

export default slice.reducer;

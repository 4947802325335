/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {EditIcon} from '../../../../assets/svgs/EditIcon';
import {TrashIcon} from '../../../../assets/svgs/TrashIcon';
import {THEME} from '../../../../styles/theme';
import {useAppDispatch} from '../../../../hooks/useReduxHook';
import {
  addRecipeModalAction,
  deleteRecipeModalAction,
} from '../../../../redux/modals';
import {
  recipeSelectedAction,
  recipeUpdateModeAction,
} from '../../../../redux/myCatalogSection/recipes';
import {IRecipeFetchReturn} from '@bill-app-types/b-types';
import {helperLabelTranslation} from '../../../../helpers/helperLabel';

export const RecipeItem = ({recipe}: {recipe: IRecipeFetchReturn}) => {
  const dispatch = useAppDispatch();

  const handleEdit = () => {
    dispatch(recipeSelectedAction(recipe));
    dispatch(recipeUpdateModeAction(true));
    dispatch(addRecipeModalAction(true));
  };

  const handleDelete = () => {
    dispatch(recipeSelectedAction(recipe));
    dispatch(deleteRecipeModalAction(true));
  };

  return (
    <div css={styles.recipeItem}>
      <div css={styles.recipeItem__img_container}>
        <img src="/images/recipe.svg" alt="" css={styles.recipeItem__img} />
        <div css={styles.recipeItem__actions}>
          <div css={styles.recipeItem__action} onClick={handleEdit}>
            <EditIcon />
          </div>
          <div css={styles.recipeItem__action} onClick={handleDelete}>
            <TrashIcon />
          </div>
        </div>
      </div>

      <TextVariant
        variant={'textRegular'}
        text={helperLabelTranslation(recipe)}
        style={styles.recipeItem_label}
      />
    </div>
  );
};

const styles = {
  recipeItem: css({
    backgroundColor: '#fff',
    border: `1px solid ${THEME.colors.lightGray}`,
    height: '120px',
  }),
  recipeItem__img_container: css({
    display: 'flex',
    position: 'relative',
    height: '75%',
  }),
  recipeItem__img: css({
    width: 'calc(100% - 29px)',
    height: '100%',
    objectFit: 'cover',
  }),
  recipeItem__actions: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '0.2rem 0.1rem',
    rowGap: '0.25rem',
  }),
  recipeItem__action: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
    height: 25,
    backgroundColor: THEME.colors.bgLight,
    borderRadius: 2,
    cursor: 'pointer',
  }),
  recipeItem_label: css({
    textAlign: 'center',
    padding: '0.4rem 0.1rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
};

/** @jsxImportSource @emotion/react  */
import {css} from '@emotion/react';
import {BadgeStar} from '../../../../components/atoms/BadgeStar';
import {ImageCustom} from '../../../../components/atoms/ImageCustom';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {DataGridActions} from '../../../../components/newDatagrid/DataGridActions';
import {THEME} from '../../../../styles/theme';
import {editLoyaltyModalAction} from '../../../../redux/modals';
import {updatedItemAction} from '../../../../redux/myModulesSection/loyalties';
import {helperFormatPrice} from '../../../../helpers/helperFormatPrice';
import {ProductType} from '../../../../components/atoms/ProductType';
import {BRAND_AUTHORIZATION} from '../../../../constants/frontPolicies';

export const columns = [
  {
    field: 'icon',
    headerName: '',
    headerClassName: 'textRegular',
    flex: 0.4,
    renderCell: (params: any) => {
      return (
        <div
          className="flex-align-center"
          style={{
            width: '100%',
            marginLeft: '10px',
          }}>
          <div className="flex-center" css={styles.icon}>
            <ImageCustom src={params.image} />
          </div>
        </div>
      );
    },
  },
  {
    field: 'label',
    headerName: 'Produit & Menus',
    flex: 1,
    renderCell: (params: any) => {
      return (
        <div className="flex-align-center">
          <ProductType type={params.type} />
          <TextVariant
            text={params.label}
            variant="textMedium"
            style={{marginLeft: 8}}
          />
        </div>
      );
    },
  },
  {
    field: 'price',
    headerName: 'Prix',
    flex: 1,
    valueGetter: (params: any) => {
      return params.price > 0 ? `${helperFormatPrice(params.price)}€` : '--';
    },
  },
  {
    field: 'loyaltyGain',
    headerName: 'Points rapportés',
    flex: 1,
    renderCell: (row: any) => {
      return (
        <div
          className="flex-center"
          style={{
            width: 65,
            justifyContent: 'flex-end',
          }}>
          <TextVariant
            text={`${row.loyaltyGain || 0} `}
            style={{color: THEME.colors.yellow, marginRight: 5}}
            variant="textMedium"
          />
          <BadgeStar />
        </div>
      );
    },
  },
  {
    field: 'loyaltyCost',
    headerName: 'Points pour gratuité',
    flex: 1,
    renderCell: (row: any) => {
      return (
        <div
          className="flex-center"
          style={{
            width: 65,
            justifyContent: 'flex-end',
          }}>
          <TextVariant
            text={`${row.loyaltyCost || 0} `}
            style={{color: THEME.colors.yellow, marginRight: 5}}
            variant="textMedium"
          />
          <BadgeStar />
        </div>
      );
    },
  },
  {
    field: 'actions',
    headerName: '',
    align: 'right',
    flex: 0.5,
    renderCell: (row: any) => {
      const handleClickEdit = ({dispatch}: any) => {
        dispatch(editLoyaltyModalAction(true));
        dispatch(updatedItemAction(row));
      };

      return (
        <div css={styles.actions}>
          <DataGridActions
            handleClickEdit={handleClickEdit}
            hideDelete
            authorization={{
              edit: BRAND_AUTHORIZATION.PARENT,
            }}
          />
        </div>
      );
    },
  },
];

const styles = {
  icon: css({
    height: 40.25,
    width: 40.25,
    borderRadius: 2,
  }),
  actions: css({
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 10,
  }),
};

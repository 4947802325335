import {useQuery} from '@tanstack/react-query';
import {getTransactions} from '../api/fetchCompta';
import {helperJoinFilterValues} from '../helpers/helperFilter';
import {useAppSelector} from './useReduxHook';
import {useDebounce} from './useDebounce';
import {helperFormatAmountSort} from '../helpers/helperFormatAmountSort';

export const useTransaction = (offset: number, limit: number) => {
  const dates = useAppSelector(s => s.filter.dates);
  const channels = useAppSelector(s => s.filter.channels);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const {min, max} = useAppSelector(s => s.filter.amount);

  const search = useAppSelector(s => s.filter.search);
  const paymentMethods = useAppSelector(state => state.filter.paymentMethods);
  const orderBy = useAppSelector(state => state.filter.orderBy);

  const debouncedSearchTerm = useDebounce(search, 500);

  const {data, isLoading, error} = useQuery(
    [
      'transactions',
      dates,
      channels,
      debouncedSearchTerm,
      min,
      max,
      consumptionModes,
      paymentMethods,
      orderBy,
      offset,
      limit,
    ],
    () =>
      getTransactions({
        ...dates,
        offset,
        limit,
        channels: helperJoinFilterValues(channels, true),
        consumptionModes: helperJoinFilterValues(consumptionModes, true),
        paymentMethods: helperJoinFilterValues(paymentMethods, true),
        search: debouncedSearchTerm,
        amountMin: min ? min * 100 : null,
        amountMax: max ? max * 100 : null,
        orderBy: helperFormatAmountSort(orderBy, 'amount'),
      }),
  );

  return {
    totalAmount: data?.totalAmount,
    transations: data?.transactions || [],
    isTransactionsLoading: isLoading,
    transationsError: error,
    totalTransactions: data?.totalTransactions || 0,
  };
};

import {helperJustLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';

export const getPaymentMethods = async () => {
  //
  helperJustLog('### getPaymentMethods ###');
  const res: any = await axiosInstance
    .get(`/bcore/protected/admin/payment-methods`)
    .catch(err => {
      console.log('❌ getPaymentMethods : ', err);
    });
  return res?.data.data;
};

export const getBrandAccountingManagement = async () => {
  //
  helperJustLog('### getBrandAccountingManagement ###');
  const res: any = await axiosInstance
    .get(`/bcore/protected/admin/accounting_management`)
    .catch(err => {
      console.log('❌ getBrandAccountingManagement : ', err);
    });
  return res?.data.data;
};

export const createBrandAccountingManagement = async (body: object) => {
  //
  helperJustLog('### createBrandAccountingManagement ###');
  const res: any = await axiosInstance
    .post(`/bcore/protected/admin/accounting_management`, body)
    .catch(err => {
      console.log('❌ createBrandAccountingManagement : ', err);
    });
  return res;
};

export const updateBrandAccountingManagement = async ({
  id,
  body,
}: {
  id: number;
  body: object;
}) => {
  //
  helperJustLog('### updateBrandAccountingManagement ###');
  const res: any = await axiosInstance
    .put(`/bcore/protected/admin/accounting_management/${id}`, body)
    .catch(err => {
      console.log('❌ updateBrandAccountingManagement : ', err);
    });
  return res;
};

export const deleteBrandAccountingManagement = async (id: number) => {
  //
  helperJustLog('### deleteBrandAccountingManagement ###');
  const res: any = await axiosInstance
    .delete(`/bcore/protected/admin/accounting_management/${id}`)
    .catch(err => {
      console.log('❌ deleteBrandAccountingManagement : ', err);
    });
  return res;
};

export const putPaymentMethod = async ({
  methodId,
  body,
}: {
  methodId: string;
  body: object;
}) => {
  helperJustLog('### putPaymentMethod ###');
  const res = await axiosInstance
    .put(`/bcore/protected/admin/payment-methods/${methodId}`, body)
    .catch(err => {
      console.log('❌ putPaymentMethod : ', err);
    });
  return res;
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../../../components/atoms/TextVariant';
import {helperFormatPrice} from '../../../../../../helpers/helperFormatPrice';

export const OrderItem = ({item}: any) => {
  return (
    <div css={styles.orderItem}>
      <Row
        label={item.label}
        quantity={item.quantity}
        pu={helperFormatPrice(item.unit_price_ttc)}
        tva={Number(item.tva_rate) / 100}
        price={helperFormatPrice(item.amount_ttc)}
        color={item?.label.includes('*') ? '#A7A7AB' : 'var(--text-color)'}
      />
    </div>
  );
};

const Row = ({
  quantity = '',
  label = '',
  price = '',
  pu = '',
  tva = 1,
  paddingLeft = '0%',
  color = 'var(--text-color)',
}: {
  quantity?: string;
  label: string;
  price?: string;
  pu?: string;
  tva?: number;
  paddingLeft?: string;
  color?: string;
}) => (
  <div css={styles.product}>
    <div css={[styles.col, {width: '10%'}]}>
      <TextVariant
        text={quantity}
        variant="textBold"
        style={{
          color,
        }}
      />
    </div>
    <div css={[styles.col, {width: '45%'}]}>
      <TextVariant
        text={label}
        variant="textBold"
        style={{
          paddingLeft,
          color,
        }}
      />
    </div>
    <div css={styles.col}>
      <TextVariant
        text={`${pu}€`}
        variant="textBold"
        style={{
          color,
        }}
      />
    </div>
    <div css={[styles.col, {width: '10%'}]}>
      <TextVariant
        text={tva}
        variant="textBold"
        style={{
          color,
        }}
      />
    </div>
    <div css={[styles.col, {width: '20%'}]}>
      <TextVariant
        text={`${price}€`}
        variant="textBold"
        style={{
          color,
        }}
      />
    </div>
  </div>
);

const styles = {
  orderItem: css({
    backgroundColor: 'var(--bg-color)',
    height: '100%',
    width: '40vh',
    borderBottom: '1px solid var(--bg-main-color)',
    paddingBottom: '8px',
  }),
  product: css({
    display: 'flex',
    padding: '8px 5px',
  }),
  col: css({
    width: '15%',
    padding: '0px 15px',
  }),
};

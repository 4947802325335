export const ClockIcon = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1069 6.16671C12.1069 7.42372 11.5742 8.62926 10.6261 9.5181C9.67804 10.4069 8.39214 10.9063 7.05132 10.9063C5.71051 10.9063 4.42461 10.4069 3.47651 9.5181C2.52841 8.62926 1.99577 7.42372 1.99577 6.16671C1.99577 4.90969 2.52841 3.70416 3.47651 2.81532C4.42461 1.92647 5.71051 1.42712 7.05132 1.42712C8.39214 1.42712 9.67804 1.92647 10.6261 2.81532C11.5742 3.70416 12.1069 4.90969 12.1069 6.16671ZM0.829102 6.16671C0.829102 7.7138 1.48466 9.19754 2.65155 10.2915C3.81844 11.3855 5.40109 12 7.05132 12C8.70156 12 10.2842 11.3855 11.4511 10.2915C12.618 9.19754 13.2735 7.7138 13.2735 6.16671C13.2735 4.61961 12.618 3.13588 11.4511 2.04192C10.2842 0.947956 8.70156 0.333374 7.05132 0.333374C5.40109 0.333374 3.81844 0.947956 2.65155 2.04192C1.48466 3.13588 0.829102 4.61961 0.829102 6.16671ZM6.46799 3.06775V6.16671C6.46799 6.349 6.56521 6.5199 6.72806 6.62244L9.06139 8.08077C9.32875 8.24939 9.69091 8.18103 9.87077 7.9281C10.0506 7.67517 9.97771 7.33793 9.70792 7.16931L7.63466 5.87504V3.06775C7.63466 2.76469 7.37459 2.52087 7.05132 2.52087C6.72806 2.52087 6.46799 2.76469 6.46799 3.06775Z"
        fill="#CCD6E0"
      />
    </svg>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useEffect, useState} from 'react';
import {columns} from './columns';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {ReceiptTicket} from './ReceiptTicket';
import {useSqsReceipts} from '../../../../../hooks/useSqsReceipts';
import {selectedArchiveReceiptAction} from '../../../../../redux/myAccountingSection/archive';

export const ArchiveReceipts = () => {
  //
  const dispatch = useAppDispatch();
  const selectedReceipt = useAppSelector(
    s => s.myAccountingSection.archive.selectedArchiveReceipt,
  );

  const [page, setPage] = useState(1);
  const [rowCount] = useState(0);
  const [limit] = useState(20);

  const {data, isLoading} = useSqsReceipts();

  const handleRowClick = (row: any) => {
    dispatch(selectedArchiveReceiptAction(row));
  };

  // Note: If i have receiptId in url, i want to select that receipt
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const receiptId = urlParams.get('receiptId');
    if (receiptId) {
      dispatch(
        selectedArchiveReceiptAction({
          receipt_id: receiptId,
        }),
      );
    }
  }, [dispatch]);

  return (
    <div css={styles.content}>
      <div
        style={{
          flex: 1,
        }}>
        <NewDataGrid
          columns={columns}
          rows={data || []}
          loading={isLoading}
          onRowClick={handleRowClick}
          hasPagination
          page={page}
          setPage={setPage}
          totalRows={rowCount}
          rowPerPage={limit}
          selectedRow={selectedReceipt}
        />
      </div>

      {selectedReceipt && <ReceiptTicket />}
    </div>
  );
};

const styles = {
  content: css({
    height: '100%',
    display: 'flex',
    '@media (max-width: 1024px)': {
      height: '84%',
    },
  }),
};

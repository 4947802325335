import {SVGProps} from 'react';

export const InfosIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.4375 0C3.7771 0 0 3.35742 0 7.5C0 11.6426 3.7771 15 8.4375 15C13.0979 15 16.875 11.6426 16.875 7.5C16.875 3.35742 13.0979 0 8.4375 0ZM8.4375 13.5938C4.6571 13.5938 1.58203 10.8601 1.58203 7.5C1.58203 4.13994 4.6571 1.40625 8.4375 1.40625C12.2179 1.40625 15.293 4.13994 15.293 7.5C15.293 10.8601 12.2179 13.5938 8.4375 13.5938ZM9.75586 9.84375H9.22852V7.26562C9.22852 6.87891 8.87585 6.5625 8.4375 6.5625H7.38281C6.94775 6.5625 6.5918 6.87891 6.5918 7.26562C6.5918 7.65234 6.94775 7.96875 7.38281 7.96875H7.64648V9.84375H7.11914C6.68408 9.84375 6.32812 10.1602 6.32812 10.5469C6.32812 10.9336 6.68408 11.25 7.11914 11.25H9.75586C10.1926 11.25 10.5469 10.9351 10.5469 10.5469C10.5469 10.1587 10.1942 9.84375 9.75586 9.84375ZM8.4375 5.625C9.01989 5.625 9.49219 5.20518 9.49219 4.6875C9.49219 4.16982 9.01989 3.75 8.4375 3.75C7.85511 3.75 7.38281 4.16895 7.38281 4.6875C7.38281 5.20605 7.85413 5.625 8.4375 5.625Z"
      fill={'white'}
    />
  </svg>
);

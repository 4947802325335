import {SVGProps} from 'react';

export const NotCompletedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.40784 3.77402C8.88609 3.77402 9.24647 4.09436 9.24647 4.49253V7.84557C9.24647 8.24374 8.88609 8.56408 8.40784 8.56408C7.9902 8.56408 7.59951 8.24374 7.59951 7.84557V4.49253C7.59951 4.09436 7.9902 3.77402 8.40784 3.77402ZM9.51591 10.4801C9.51591 11.01 9.03429 11.4381 8.40784 11.4381C7.84201 11.4381 7.33007 11.01 7.33007 10.4801C7.33007 9.9502 7.84201 9.52209 8.40784 9.52209C9.03429 9.52209 9.51591 9.9502 9.51591 10.4801ZM0.35359 6.48639L3.47034 1.93464C3.90818 1.29218 4.69629 0.899994 5.51135 0.899994H11.3346C12.18 0.899994 12.9681 1.29218 13.406 1.93464L16.5214 6.48639C16.9929 7.17496 16.9929 8.03717 16.5214 8.72574L13.406 13.2763C12.9681 13.92 12.18 14.3121 11.3346 14.3121H5.51135C4.69629 14.3121 3.90818 13.92 3.47034 13.2763L0.35359 8.72574C-0.117863 8.03717 -0.117863 7.17496 0.35359 6.48639ZM1.73447 7.23184C1.57719 7.46236 1.57719 7.74977 1.73447 7.98029L4.85122 12.5308C4.99605 12.7434 5.25875 12.8751 5.51135 12.8751H11.3346C11.6175 12.8751 11.8802 12.7434 12.0251 12.5308L15.1405 7.98029C15.2988 7.74977 15.2988 7.46236 15.1405 7.23184L12.0251 2.68189C11.8802 2.46784 11.6175 2.33701 11.3346 2.33701H5.51135C5.25875 2.33701 4.99605 2.46784 4.85122 2.68189L1.73447 7.23184Z"
      fill="white"
    />
  </svg>
);

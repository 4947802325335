import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';

export const fetchPosReceiptCancelByUsers = async (data: {
  from: Date;
  to: Date;
}) => {
  const query = helperGenerateQuery(data, {
    dateFormat: 'YYYY-MM-DD HH:mm',
  });
  const res: any = await axiosInstance
    .get(`/pos/backoffice/stats/receipt-cancel/pos-users${query}`)
    .catch(err => {
      console.log('❌ fetchPosReceiptCancel : ', err);
    });

  return res?.data?.data;
};

export const fetchPosReceiptCancelByReasons = async (data: {
  from: Date;
  to: Date;
}) => {
  const query = helperGenerateQuery(data, {
    dateFormat: 'YYYY-MM-DD HH:mm',
  });
  const res: any = await axiosInstance
    .get(`/pos/backoffice/stats/receipt-cancel/reasons${query}`)
    .catch(err => {
      console.log('❌ fetchPosReceiptCancel : ', err);
    });

  return res?.data?.data;
};

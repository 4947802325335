import {createSlice} from '@reduxjs/toolkit';

export interface IStaffGroup {
  label: string;
  isArchived: boolean;
  createdAt: string;
  updatedAt: string;
  uuid: string;
  users: any[];
  frontPolicies: any[];
}

const initialState: {
  groupSelected: IStaffGroup | null;
} = {
  groupSelected: null,
};

export const slice = createSlice({
  name: 'groups',
  initialState: initialState,
  reducers: {
    staffGroupSelectedAction: (state, action) => {
      state.groupSelected = action.payload;
    },
  },
});

export const {staffGroupSelectedAction} = slice.actions;

export default slice.reducer;

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import ProductCard from './ProductCard';

interface ISortableList {
  items: any;
  style?: any;
  bg?: string;
  showCross?: boolean;
  showAdd?: boolean;
  handleOnClick?: any;
  showProductType?: boolean;
  showAdditionalPrice?: boolean;
  handleClickAdditional?: any;
}

export const SortableList = SortableContainer(
  ({
    items,
    style,
    bg,
    showCross,
    showAdd,
    handleOnClick,
    showProductType,
    showAdditionalPrice = false,
    handleClickAdditional,
  }: ISortableList) => {
    return (
      <div css={[styles.list, {backgroundColor: bg}, style]}>
        {items.map((value: any, index: number) => (
          //@ts-ignore
          <SortableItem
            key={`item-${value.uuid || value.id || value._id || index}`}
            index={index}
            // @ts-ignore
            value={{...value, position: index + 1}}
            showCross={showCross}
            showAdd={showAdd}
            handleOnClick={() => handleOnClick(value)}
            showProductType={showProductType}
            showAdditionalPrice={showAdditionalPrice}
            handleClickAdditional={handleClickAdditional}
          />
        ))}
      </div>
    );
  },
);

// ignore typescript to this line
//@ts-ignore
const SortableItem = SortableElement(({value}: any) => (
  <ProductCard item={value} />
));

const styles = {
  list: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
    gap: '10px',
    paddingTop: '10px',
  }),
};

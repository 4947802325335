import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  addUserModal: false,
  categoryModal: false,
  optionCategoryModal: false,
  addOptionModal: false,
  eventModal: false,
  qrCodesListModal: false,
  addAreaModal: false,
  addAreaLocationModal: false,
  viewAreaLocationQRCodeModal: false,
  createGroupModal: false,
  addAccountingModal: false,
  addProductModal: false,
  createCardModal: false,
  addPosCategoryModal: false,
  addUsersGroupModal: false,
  addCustomerLoyaltiesModal: false,
  addProductCategoryModal: false,
  addRecipeModal: false,
  deleteRecipeModal: false,
  addStructureModal: false,
  addMenuModal: false,
  addSubMenuModal: false,
  addPrinterConfigModal: false,
  addPrinterModal: false,
  addCompositionSubMenuModal: false,
  addAdditionalCategoryModal: false,
  addVendorStructureModal: false,
  addPosStructureModal: false,
  addPosUserModal: false,
  addProductionAreaModal: false,
  addPosCategoryModalOld: false,
  addPosButtonModal: false,
  addPriceRuleModal: false,
  addPosTerminalModal: false,
  addPosEntityModal: false,
  addExternalConnectionModal: false,

  editSubMenuModal: false,
  editSupplementModal: false,
  editConsumptionModal: false,
  editAdditionalPrice: false,
  editLoyaltyModal: false,

  deleteMenuModal: false,
  deleteUsersGroupModal: false,
  deleteUserModal: false,
  deleteAreaLocationModal: false,
  deleteAreaModal: false,
  deletePrinterModal: false,
  deletePrinterConfigModal: false,
  deleteStaffGroupModal: false,
  deleteAdditionalCategoryModal: false,
  deleteProductionAreaModal: false,
  deleteExternalConnectionModal: false,
};

const slice = createSlice({
  name: 'modals',
  initialState: initialState,
  reducers: {
    addUserModalAction: (state, action) => {
      state.addUserModal = action.payload;
    },
    categoryModalAction: (state, action) => {
      state.categoryModal = action.payload;
    },
    optionCategoryModalAction: (state, action) => {
      state.optionCategoryModal = action.payload;
    },
    eventModalAction: (state, action) => {
      state.eventModal = action.payload;
    },
    qrCodesListModalAction: (state, action) => {
      state.qrCodesListModal = action.payload;
    },
    addAreaModalAction: (state, action) => {
      state.addAreaModal = action.payload;
    },
    addAreaLocationModalAction: (state, action) => {
      state.addAreaLocationModal = action.payload;
    },
    viewAreaLocationQRCodeModalAction: (state, action) => {
      state.viewAreaLocationQRCodeModal = action.payload;
    },
    addGroupModalAction: (state, action) => {
      state.createGroupModal = action.payload;
    },
    addAccountingModalAction: (state, action) => {
      state.addAccountingModal = action.payload;
    },
    addOptionModalAction: (state, action) => {
      state.addOptionModal = action.payload;
    },
    addProductModalAction: (state, action) => {
      state.addProductModal = action.payload;
    },
    createCardModalAction: (state, action) => {
      state.createCardModal = action.payload;
    },
    addPosCategoryModalAction: (state, action) => {
      state.addPosCategoryModal = action.payload;
    },
    addUsersGroupModalAction: (state, action) => {
      state.addUsersGroupModal = action.payload;
    },
    addAdditionalCategoryModalAction: (state, action) => {
      state.addAdditionalCategoryModal = action.payload;
    },
    addProductionAreaModalAction: (state, action) => {
      state.addProductionAreaModal = action.payload;
    },
    deleteUsersGroupModalAction: (state, action) => {
      state.deleteUsersGroupModal = action.payload;
    },
    deleteUserModalAction: (state, action) => {
      state.deleteUserModal = action.payload;
    },
    addCustomerLoyaltiesModalAction: (state, action) => {
      state.addCustomerLoyaltiesModal = action.payload;
    },
    addProductCategoryModalAction: (state, action) => {
      state.addProductCategoryModal = action.payload;
    },
    addRecipeModalAction: (state, action) => {
      state.addRecipeModal = action.payload;
    },
    addMenuModalAction: (state, action) => {
      state.addMenuModal = action.payload;
    },
    deleteRecipeModalAction: (state, action) => {
      state.deleteRecipeModal = action.payload;
    },
    addStructureModalAction: (state, action) => {
      state.addStructureModal = action.payload;
    },
    addPriceRuleModalAction: (state, action) => {
      state.addPriceRuleModal = action.payload;
    },
    deleteMenuModalAction: (state, action) => {
      state.deleteMenuModal = action.payload;
    },
    addSubMenuModalAction: (state, action) => {
      state.addSubMenuModal = action.payload;
    },
    addCompositionSubMenuModalAction: (state, action) => {
      state.addCompositionSubMenuModal = action.payload;
    },
    editSubMenuModalAction: (state, action) => {
      state.editSubMenuModal = action.payload;
    },
    editSupplementModalAction: (state, action) => {
      state.editSupplementModal = action.payload;
    },
    deleteAreaModalAction: (state, action) => {
      state.deleteAreaModal = action.payload;
    },
    deleteAreaLocationModalAction: (state, action) => {
      state.deleteAreaLocationModal = action.payload;
    },
    editConsumptionModalAction: (state, action) => {
      state.editConsumptionModal = action.payload;
    },
    editAdditionalPriceModalAction: (state, action) => {
      state.editAdditionalPrice = action.payload;
    },
    addPrinterConfigModalAction: (state, action) => {
      state.addPrinterConfigModal = action.payload;
    },
    addPrinterModalAction: (state, action) => {
      state.addPrinterModal = action.payload;
    },
    deletePrinterModalAction: (state, action) => {
      state.deletePrinterModal = action.payload;
    },
    deletePrinterConfigModalAction: (state, action) => {
      state.deletePrinterConfigModal = action.payload;
    },
    deleteStaffGroupModalAction: (state, action) => {
      state.deleteStaffGroupModal = action.payload;
    },
    deleteAdditionalCategoryModalAction: (state, action) => {
      state.deleteAdditionalCategoryModal = action.payload;
    },
    addVendorStructureModalAction: (state, action) => {
      state.addVendorStructureModal = action.payload;
    },
    addPosStructureModalAction: (state, action) => {
      state.addPosStructureModal = action.payload;
    },
    addPosUserModalAction: (state, action) => {
      state.addPosUserModal = action.payload;
    },
    deleteProductionAreaModalAction: (state, action) => {
      state.deleteProductionAreaModal = action.payload;
    },
    addPosCategoryModalOldAction: (state, action) => {
      state.addPosCategoryModalOld = action.payload;
    },
    addPosButtonModalAction: (state, action) => {
      state.addPosButtonModal = action.payload;
    },
    editLoyaltyModalAction: (state, action) => {
      state.editLoyaltyModal = action.payload;
    },
    addPosTerminalModalAction: (state, action) => {
      state.addPosTerminalModal = action.payload;
    },
    addPosEntityModalAction: (state, action) => {
      state.addPosEntityModal = action.payload;
    },
    addExternalConnectionModalAction: (state, action) => {
      state.addExternalConnectionModal = action.payload;
    },
    editExternalConnectionModalAction: (state, action) => {
      state.addExternalConnectionModal = action.payload;
    },
    deleteExternalConnectionModalAction: (state, action) => {
      state.deleteExternalConnectionModal = action.payload;
    }
  },
});

export const {
  addUserModalAction,
  categoryModalAction,
  optionCategoryModalAction,
  eventModalAction,
  qrCodesListModalAction,
  addAreaModalAction,
  addAreaLocationModalAction,
  viewAreaLocationQRCodeModalAction,
  addGroupModalAction,
  addAccountingModalAction,
  addOptionModalAction,
  addProductModalAction,
  createCardModalAction,
  addPosCategoryModalAction,
  addUsersGroupModalAction,
  deleteUsersGroupModalAction,
  deleteUserModalAction,
  addCustomerLoyaltiesModalAction,
  addProductCategoryModalAction,
  addRecipeModalAction,
  deleteRecipeModalAction,
  addStructureModalAction,
  deleteMenuModalAction,
  addMenuModalAction,
  addSubMenuModalAction,
  addCompositionSubMenuModalAction,
  editSubMenuModalAction,
  editSupplementModalAction,
  deleteAreaLocationModalAction,
  deleteAreaModalAction,
  editConsumptionModalAction,
  editAdditionalPriceModalAction,
  addPrinterConfigModalAction,
  addPrinterModalAction,
  deletePrinterModalAction,
  deletePrinterConfigModalAction,
  deleteStaffGroupModalAction,
  addAdditionalCategoryModalAction,
  deleteAdditionalCategoryModalAction,
  addVendorStructureModalAction,
  addPosStructureModalAction,
  addPosUserModalAction,
  addProductionAreaModalAction,
  deleteProductionAreaModalAction,
  addPosCategoryModalOldAction,
  addPosButtonModalAction,
  editLoyaltyModalAction,
  addPriceRuleModalAction,
  addPosTerminalModalAction,
  addPosEntityModalAction,
  addExternalConnectionModalAction,
  deleteExternalConnectionModalAction,
} = slice.actions;

export default slice.reducer;

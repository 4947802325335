import {useTranslation} from 'react-i18next';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {editAdditionalPriceModalAction} from '../../redux/modals';
import {updatedCourseAction} from '../../redux/myCatalogSection/menus';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {helperFormatPrice} from '../../helpers/helperFormatPrice';
import {updateSubmenuProduct} from '../../api/fetchSubmenus';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';

export const EditAdditionalPriceModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const clientQuery = useQueryClient();

  const updatedCourse = useAppSelector(
    s => s.myCatalogSection.menus.updatedCourse,
  );
  const openedSubmenu = useAppSelector(
    s => s.myCatalogSection.menus.openedSubmenu,
  );

  const handleClose = () => {
    dispatch(editAdditionalPriceModalAction(false));
    dispatch(updatedCourseAction(null));
  };

  const handleConfirm = async (data: {price: number}) => {
    const res = await updateSubmenuProduct(openedSubmenu, updatedCourse.uuid, {
      price: Math.round(data.price * 100),
    });
    if (res?.status === 204) {
      helperDisplayToast('Supplément modifié', true);
      clientQuery.invalidateQueries([`fetchSubmenuProducts-${openedSubmenu}`]);
      handleClose();
    } else {
      helperDisplayToast('Impossible de modifier le supplément', false);
    }
  };

  return (
    <ModalWrapper
      title={t('EDIT_ADDITIONAL')}
      cancelLabel={t('CANCEL')}
      btnCancelWidth={'28%'}
      btnValidateWidth={'70%'}
      onCancel={handleClose}
      onSubmit={handleConfirm}
      validateLabel={'METTRE À JOUR LE SUPPLÉMENT'}
      titleMarginBottom={'1.5rem'}
      initialValues={
        !!updatedCourse
          ? {price: helperFormatPrice(updatedCourse.additionalPrice, 'en')}
          : {price: ''}
      }>
      <FieldCustomInput name="price" label={t('SUPPLEMENT')} required />
      <FieldsRequired />
    </ModalWrapper>
  );
};

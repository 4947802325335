import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {DeleteModal} from './DeleteModal';
import {areaLocationSelectedAction} from '../../redux/myRestaurantSection/areas';
import {deleteAreaLocationModalAction} from '../../redux/modals';
import {useState} from 'react';
import {deleteAreaLocation} from '../../api/fetchAreas';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';

export const DeleteAreaLocationModal = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const brand = useAppSelector(state => state.brand.brand);
  const {areaLocationSelected, areaSelected} = useAppSelector(
    s => s.myRestaurantSection.areas,
  );

  const clientQuery = useQueryClient();

  if (!areaLocationSelected || !areaSelected) return null;

  const handleCancel = () => {
    dispatch(areaLocationSelectedAction(null));
    dispatch(deleteAreaLocationModalAction(false));
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    console.log('handleSubmit');
    const res = await deleteAreaLocation(
      brand.id,
      areaSelected?.id,
      areaLocationSelected?.id,
    );

    if (res.status === 200) {
      helperDisplayToast(res.message, true);
      await clientQuery.invalidateQueries(['fetchAreaLocations']);
      handleCancel();
    }
    setIsLoading(false);
  };

  return (
    <DeleteModal
      title=""
      isLoading={isLoading}
      text={`${t('AREA_LOCATION')} ${areaLocationSelected?.label}`}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};

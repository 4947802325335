/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {useTranslation} from 'react-i18next';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {
  createPosCategoryOld,
  deletePosCategoryOld,
  fetchPosCategoriesOld,
  updatePosCategoryOld,
} from '../../api/fetchPosCategories';
import {ColorPickerLabel} from '../atoms/ColorPickerLabel';
import {useEffect, useState} from 'react';
import {RequiredLabel} from '../atoms/RequiredLabel';
import {Field} from 'formik';
import {helperDisplayToast} from '../../helpers/helperToast';
import {updateModeAction} from '../../redux/posCategories';
import {helperLabelTranslation} from '../../helpers/helperLabel';
import {addPosCategoryModalOldAction} from '../../redux/modals';

export const AddPosCategoryModalOld = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const [color, setColor] = useState<string>('#000000');

  const initialValues = {
    label: '',
    parent_category: null,
    color: '#000000',
    position: 1,
  };

  const {data} = useQuery(['fetchPosCategoriesOld'], fetchPosCategoriesOld);

  const clientQuery = useQueryClient();
  // TODO: UPDATE MODE
  const updateMode = useAppSelector(state => state.posCategories.updateMode);
  const updatedCategory = useAppSelector(
    state => state.posCategories.updatedCategory,
  );

  useEffect(() => {
    if (updateMode && updatedCategory) {
      setColor(updatedCategory?.color);
    }
  }, [updateMode, updatedCategory]);

  /* ON CANCEL */
  const handleOnCancel = () => {
    dispatch(addPosCategoryModalOldAction(false));
    dispatch(updateModeAction(false));
  };

  /* HANDLE SUBMIT */
  const handleOnSubmit = async (
    data: {
      label: string;
      parent_category: string;
      position: number;
    },
    {setSubmitting, resetForm}: any,
  ) => {
    if (data.label && color) {
      if (updateMode) {
        const res = await updatePosCategoryOld(
          {
            ...data,
            parent_category:
              data.parent_category === 'Aucun parent'
                ? null
                : data.parent_category,
            color,
          },
          updatedCategory?.id,
        );

        if (res && res?.status === 200) {
          resetForm();
          handleOnCancel();
          clientQuery.invalidateQueries(['fetchPosCategoriesOld']);
          helperDisplayToast('Catégorie POS modifiée', true);
        } else {
          helperDisplayToast('Impossible de modifier la Catégorie POS', false);
        }
      } else {
        const res = await createPosCategoryOld({
          ...data,
          color,
          parent_category:
            data.parent_category === 'Aucun parent'
              ? null
              : data.parent_category,
        });

        if (res && res?.status === 200) {
          resetForm();
          handleOnCancel();
          clientQuery.invalidateQueries(['fetchPosCategoriesOld']);
          helperDisplayToast('Catégorie POS créée', true);
        } else {
          helperDisplayToast('Impossible de créer la catégorie POS', false);
        }
      }
      dispatch(updateModeAction(false));
    }
  };

  const handleOnRemove = async () => {
    const res = await deletePosCategoryOld(updatedCategory?.id);
    if (res && res.status === 200) {
      helperDisplayToast('Catégorie supprimée', true);
      handleOnCancel();
      clientQuery.invalidateQueries(['fetchPosCategoriesOld']);
    } else {
      helperDisplayToast('impossible de supprimer la catégorie POS', false);
    }
  };

  return (
    <ModalWrapper
      initialValues={updateMode ? updatedCategory : initialValues}
      title={t(updateMode ? 'UPDATE_AN_POS_CATEGORY' : 'ADD_AN_POS_CATEGORY')}
      validateLabel={t(
        updateMode ? 'UPDATE_THE_POS_CATEGORY' : 'ADD_THE_POS_CATEGORY',
      )}
      cancelLabel={t('CANCEL')}
      onCancel={handleOnCancel}
      onRemove={updateMode ? handleOnRemove : undefined}
      onSubmit={handleOnSubmit}>
      <FieldCustomInput
        label={t('CATEGORY_NAME')}
        placeholder={t('CATEGORY_NAME')}
        name="label"
        required
      />
      {/* TODO: STYYYYYYYLE PLEASE AND STATE GESTION */}
      <RequiredLabel label={t('PARENT_CATEGORY')} required={true} />
      <Field name="parent_category" as="select" css={styles.select}>
        <option value={undefined}>{t('NO_PARENT')}</option>
        {data?.data?.map((category: any) => {
          return <CatItem item={category} />;
        })}
      </Field>

      <ColorPickerLabel
        label={t('COLOR')}
        name="color"
        value={color}
        onChange={e => setColor(e.currentTarget?.value)}
      />

      {/* <Custom */}
    </ModalWrapper>
  );
};

const CatItem = ({item}: any) => {
  return (
    <>
      <option value={item.id}>{helperLabelTranslation(item)}</option>
      <SubCatItem item={item} />
    </>
  );
};

const SubCatItem = ({item}: any) => {
  if (item.subPosCategories?.length === 0) return <></>;
  return (
    <>
      {item.subPosCategories?.map((subCat: any) => {
        return <CatItem item={subCat} />;
      })}
    </>
  );
};

const styles = {
  select: css({
    width: '100%',
    height: 39,
    border: 'none',
    borderRadius: 2,
    backgroundColor: 'white',
    padding: '0 10px',
  }),
};

import AnimData from './password.json';
import Lottie from 'react-lottie';

export const PasswordAnim = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} />
    </div>
  );
};

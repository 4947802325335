import {ILabel} from '@bill-app-types/b-types';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

export interface ISuggestCategory {
  uuid: string;
  backofficeLabel: string;
  isArchived: boolean;
  showStaff: boolean;
  createdAt: Date | string;
  updatedAt: Date | string;
  labelTranslationEntries: ILabel[];
  suggestedItems: {
    uuid: string;
    image: string;
    backofficeLabel: string;
    labelTranslationEntries: ILabel[];
    position: number;
    type: number;
    price: number;
  }[];
}

interface ISlice {
  categorySelected: ISuggestCategory | null;
  categoryProducts: ISuggestCategory['suggestedItems'];
  updatedCategory: ISuggestCategory | null;
}

const initialState: ISlice = {
  categorySelected: null,
  updatedCategory: null,
  categoryProducts: [],
};

const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    categorySelectedAction(
      state,
      action: PayloadAction<ISlice['categorySelected']>,
    ) {
      state.categorySelected =
        state.categorySelected?.uuid === action.payload?.uuid
          ? null
          : action.payload;
    },
    categoryProductsAction(
      state,
      action: PayloadAction<ISlice['categoryProducts']>,
    ) {
      state.categoryProducts = action.payload;
    },
    updatedCategoryAction(
      state,
      action: PayloadAction<ISlice['updatedCategory']>,
    ) {
      state.updatedCategory = action.payload;
    },
  },
});

export const {
  categorySelectedAction,
  categoryProductsAction,
  updatedCategoryAction,
} = slice.actions;

export default slice.reducer;

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Title} from '../../../../../components/atoms/Title';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {helperFormatPrice} from '../../../../../helpers/helperFormatPrice';
import {helperObjectToArray} from '../../../../../helpers/helperObjectToArray';
import {THEME} from '../../../../../styles/theme';

export const DaysCAByPaymentSection = ({
  data,
  isLoading = false,
}: {
  data: any;
  isLoading?: boolean;
}) => {
  const columns = [
    {
      field: 'payment',
      headerName: 'Mode',
      headerClassName: 'textRegular',
      flex: 1,
      renderCell: (row: any) => {
        const payment = row?.paymentMethod?.label;
        return <span>{payment}</span>;
      },
    },
    {
      field: '',
      headerName: '',
      flex: 4,
    },
    {
      field: 'ttc',
      headerName: 'Montant TTC',
      headerClassName: 'textRegular',
      flex: 1,
      renderCell: (row: any) => {
        const amount = row.totalTTC;
        return (
          <span style={{color: THEME.colors.green}}>
            {helperFormatPrice(amount)}€
          </span>
        );
      },
    },
  ];

  return (
    <div css={styles.container}>
      <Title title={"Chiffre d'affaires par mode de règlement"} />
      <NewDataGrid
        rows={data ? helperObjectToArray(data, 'payment') : []}
        columns={columns}
        rowHeight={35}
        loading={isLoading}
      />
    </div>
  );
};

const styles = {
  container: css({
    // flex: 2,
    paddingTop: '2vh',
    marginTop: '2vh',
  }),
};

/** @jsxImportSource @emotion/react */
import {useContext, useEffect, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {filterAction} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
import {FilterRadioButtons} from './FilterRadioButtons';
import {ITEM_TYPES_MEAL} from '../../constants/global';

export const ItemTypeFilter = ({marginRight}: {marginRight?: string}) => {
  const {dispatch} = useContext<any>(UtilsContext);

  const [selectedType, setSelectedType] = useState<any>(null);
  const [forceClose, setForceClose] = useState(false);

  const itemType: any = useAppSelector(s => s.filter.itemType);

  const handleRadioChange = (e: any) => {
    const {value} = e.target;
    setSelectedType(value);
  };

  const handleSubmit = () => {
    dispatch(filterAction({name: 'itemType', values: selectedType}));
    setForceClose(true);
  };

  const handleResetFilter = () => {
    dispatch(filterAction({name: 'itemType', values: null}));
  };

  useEffect(() => {
    if (itemType) {
      setSelectedType(itemType);
    }
    return () => {
      setForceClose(false);
    };
  }, [itemType]);

  return (
    <FilterButton
      marginRight={marginRight}
      label={'Type'}
      valueType="value"
      values={ITEM_TYPES_MEAL.find(item => item.value === itemType)?.label}
      hideFilterIcon={!!itemType}
      showResetButton={!!itemType}
      onReset={handleResetFilter}
      forceClose={forceClose}>
      <FilterRadioButtons
        title="Type"
        list={ITEM_TYPES_MEAL}
        selectedValue={selectedType}
        onChange={handleRadioChange}
        onSubmit={handleSubmit}
      />
    </FilterButton>
  );
};

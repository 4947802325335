/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {FilterButton} from '../../../../components/atoms/FilterButton';
import {useAppSelector} from '../../../../hooks/useReduxHook';

export const DashboardHeaderBottom = () => {
  const dates = useAppSelector(s => s.filter.dates);

  return (
    <div css={styles.container} className="flex">
      <FilterButton
        icon={'calendar'}
        valueType="dates"
        values={dates}
        dateShowingOptions={{
          showSortFilter: false,
          disabledFilterTypes: ['before', 'after', 'equal'],
          forStats: true,
        }}
      />
    </div>
  );
};

const styles = {
  container: css({
    marginTop: '1.5rem',
    gap: '1vh',
  }),
};

import {useQuery} from '@tanstack/react-query';
import {getPriceRules} from '../api/fetchPriceRules';
import {useAppSelector} from './useReduxHook';
import {useDebounce} from './useDebounce';

export const usePriceRule = ({type}: {type?: number} = {}) => {
  const search = useAppSelector(s => s.filter.search);
  const orderBy = useAppSelector(s => s.filter.orderBy);
  const debounceSearch = useDebounce(search, 500);

  const {data, isLoading, error} = useQuery(
    ['getPriceRules', type, orderBy, debounceSearch],
    () =>
      getPriceRules({
        type,
        orderBy:
          orderBy.filter(
            item =>
              ['createdAt', 'updatedAt'].includes(item.field) && item.sort,
          ) || [],
      }),
  );

  return {
    data,
    isLoading,
    error,
  };
};

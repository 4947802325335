/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {SearchFilter} from '../../../../components/filters/SearchFilter';
import {OrderByFilter} from '../../../../components/filters/OrderByFilter';
import {useTranslation} from 'react-i18next';
import {ItemTypeFilter} from '../../../../components/filters/ItemTypeFilter';

export const FiltersBlock = () => {
  //
  const {t} = useTranslation();
  return (
    <div css={styles.container} className="flex">
      <SearchFilter />
      <ItemTypeFilter />
      <OrderByFilter label={t('PRICE')} field="price" />
      <OrderByFilter label={'Points rapportés'} field="loyaltyGain" />
      <OrderByFilter label={'Points pour gratuité'} field="loyaltyCost" />
    </div>
  );
};

const styles = {
  container: css({
    marginTop: '2vh',
    marginBottom: '2vh',
    gap: '1vh',
  }),
};

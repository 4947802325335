import {useTranslation} from 'react-i18next';
import {FilterButton} from '../atoms/FilterButton';
import {FilterCheckboxs} from './FilterCheckboxs';
import {
  IObject,
  filterParentBarcodesAction,
  resetParentBarcodesAction,
} from '../../redux/filter';
import {useEffect, useState} from 'react';
import {InfiniteScrollCustom} from '../atoms/InfiniteScrollCustom';
import {useInfiniteQuery} from '@tanstack/react-query';
import {fetchCategoryOptions} from '../../api/fetchCategoryOptions';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {getCatalogOptionCategories} from '../../api/fetchCatalogOptions';
import {useFlagBCatalog} from '../../hooks/useFlagBCatalog';
import {helperLabelTranslation} from '../../helpers/helperLabel';

export const ParentBarCodesFilter = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const parentBarCodes = useAppSelector(s => s.filter.parentBarcodes);
  const [forceClose, setForceClose] = useState(false);
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {data, hasNextPage, fetchNextPage, refetch} = useInfiniteQuery(
    ['fetchCategoryOptions'],
    ({pageParam = 0}) =>
      flagBCatalogEnabled
        ? getCatalogOptionCategories({
            offset: pageParam,
            limit: 20,
          })
        : fetchCategoryOptions({
            archived: 0,
            limit: 20,
            offset: pageParam,
          }),
    {
      enabled: flagBCatalogReady,
      getNextPageParam: (lastPage, pages) => {
        const currentTotalProducts = pages?.reduce(
          (acc, page) =>
            acc + (page.modifiers?.length || page.options?.length || 1),
          0,
        );

        if (
          Number(lastPage?.totalModifiers || lastPage?.optionsTotal) ===
          currentTotalProducts
        ) {
          return undefined;
        }

        return currentTotalProducts + 1;
      },
    },
  );

  const [parentBarCodesSelected, setParentBarCodesSelected] = useState<
    IObject[]
  >([]);

  const dataInfinite =
    data?.pages.flatMap(data => data.modifiers || data.options) || [];

  const parentBarCodeList =
    dataInfinite?.map(option => ({
      name: helperLabelTranslation(option),
      value: option.barcode,
    })) || [];

  const handleResetCategoryOptions = () => {
    //
    dispatch(resetParentBarcodesAction());
  };

  const handleCheckedChange = (e: any) => {
    if (e.target.name === 'ALL') {
      if (parentBarCodesSelected.length === parentBarCodeList?.length) {
        setParentBarCodesSelected([]);
      } else {
        setParentBarCodesSelected(parentBarCodeList);
      }
    } else {
      if (
        !!parentBarCodesSelected.find(channel => channel.name === e.target.name)
      ) {
        setParentBarCodesSelected(
          parentBarCodesSelected.filter(
            channel => channel.name !== e.target.name,
          ),
        );
      } else {
        setParentBarCodesSelected([
          ...parentBarCodesSelected,
          {
            name: e.target.name,
            value: e.target.value,
          },
        ]);
      }
    }
  };

  const handleSubmit = () => {
    dispatch(filterParentBarcodesAction(parentBarCodesSelected));
    setForceClose(true);
  };

  useEffect(() => {
    setParentBarCodesSelected(parentBarCodes);

    return () => {
      setForceClose(false);
    };
  }, [parentBarCodes]);

  return (
    <FilterButton
      marginLeft={0}
      label={t('CATEGORY_OPTIONS')}
      valueType="list"
      values={parentBarCodesSelected}
      showResetButton={parentBarCodesSelected?.length > 0}
      onReset={handleResetCategoryOptions}
      hideFilterIcon={parentBarCodesSelected.length > 0}
      forceClose={forceClose}>
      <InfiniteScrollCustom
        items={parentBarCodeList || []}
        refresh={refetch}
        fetchData={hasNextPage ? fetchNextPage : () => {}}
        scrollableTarget="checkboxs"
        isLoading={!!hasNextPage}>
        <FilterCheckboxs
          title={t('CATEGORY_OPTIONS')}
          list={parentBarCodeList}
          selectedValues={parentBarCodesSelected}
          onChange={handleCheckedChange}
          onSubmit={handleSubmit}
          maxHeight={200}
        />
      </InfiniteScrollCustom>
    </FilterButton>
  );
};

import {SVGProps} from 'react';

export const CardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.536 6.04c.273 0 .493.206.493.462s-.22.462-.493.462H2.55c-.273 0-.493-.206-.493-.462s.22-.462.493-.462h.986Zm3.943 0c.273 0 .493.206.493.462s-.22.462-.493.462h-2.3c-.273 0-.493-.206-.493-.462s.22-.462.493-.462h2.3ZM10.6.187c.725 0 1.315.552 1.315 1.233v6.16c0 .68-.59 1.232-1.315 1.232H1.4C.674 8.813.086 8.26.086 7.58V1.42C.086.74.674.188 1.4.188h9.2Zm0 .925H1.4c-.181 0-.328.137-.328.308v.616h9.857V1.42c0-.17-.148-.308-.329-.308Zm.329 2.772H1.072V7.58c0 .17.147.308.328.308h9.2c.18 0 .329-.138.329-.308V3.884Z"
      fill={props.color || '#EC5174'}
    />
  </svg>
);

export const CardLargeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={23}
    fill="none"
    {...props}>
    <path
      fill={props.color || '#fff'}
      d="M16.172 4.878H4.67c-.654 0-1.28.286-1.743.796a2.869 2.869 0 0 0-.722 1.923v7.253c0 .721.26 1.413.722 1.923.462.51 1.09.796 1.743.796h11.502c.653 0 1.28-.286 1.742-.796.462-.51.722-1.202.722-1.923V7.598c0-.722-.26-1.414-.722-1.924a2.353 2.353 0 0 0-1.742-.796ZM3.848 7.598c0-.24.087-.471.24-.642a.784.784 0 0 1 .582-.265h11.502c.217 0 .426.095.58.265.154.17.241.401.241.641v.907H3.848v-.906Zm13.145 7.252c0 .24-.087.47-.24.64a.785.785 0 0 1-.581.266H4.67a.785.785 0 0 1-.581-.265.956.956 0 0 1-.24-.641v-4.533h13.144v4.533Z"
    />
    <path
      fill={props.color || '#fff'}
      d="M6.314 13.944H9.6a.784.784 0 0 0 .58-.266c.155-.17.242-.4.242-.64a.957.957 0 0 0-.241-.642.785.785 0 0 0-.581-.265H6.314a.785.785 0 0 0-.581.265c-.154.17-.24.4-.24.641 0 .24.086.471.24.641.154.17.363.266.58.266ZM12.886 13.944h1.643a.784.784 0 0 0 .581-.266c.154-.17.24-.4.24-.64a.956.956 0 0 0-.24-.642.784.784 0 0 0-.58-.265h-1.644a.785.785 0 0 0-.58.265c-.155.17-.241.4-.241.641 0 .24.086.471.24.641.154.17.363.266.581.266Z"
    />
  </svg>
);

import {useContext} from 'react';
import {OrderByFilter} from '../../../../../components/filters/OrderByFilter';
import {SearchFilter} from '../../../../../components/filters/SearchFilter';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {VisibilityFilter} from '../../../../../components/filters/VIsibilityFilter';
import {useAppSelector} from '../../../../../hooks/useReduxHook';

export const Bottom = () => {
  const {t} = useContext<any>(UtilsContext);

  const sectionShow = useAppSelector(s => s.app.sectionShow);

  return (
    <>
      {sectionShow === 'Menus' && (
        <>
          <SearchFilter marginRight={'1vh'} />
          <OrderByFilter label={t('PRICE')} field="price" marginRight={'1vh'} />
          <VisibilityFilter marginRight={'1vh'} />
          <OrderByFilter
            icon={'calendar'}
            label={t('DATE_ADDED')}
            field="createdAt"
            marginRight={'1vh'}
          />
          <OrderByFilter
            icon={'calendar'}
            label={t('MODIFICATION_DATE')}
            field="updatedAt"
          />
        </>
      )}
    </>
  );
};

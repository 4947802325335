import {CATEGORY_HAS_ITEM_TYPES, PRODUCT_TYPES} from '@bill-app-types/b-types';
import {THEME} from '../styles/theme';

export const helperFindItemPillByType = (type: number) => {
  switch (type) {
    case PRODUCT_TYPES.COURSE:
      return 'P';
    case PRODUCT_TYPES.MENU:
      return 'M';
    case PRODUCT_TYPES.OPTION:
      return 'O';
    case PRODUCT_TYPES.OPTION_ITEM:
      return 'I';
    case null:
      return 'C';
    default:
      return 'C';
  }
};

export const helperFindItemPillByTypeForPOS = (type: number) => {
  switch (type) {
    case CATEGORY_HAS_ITEM_TYPES.ITEM:
      return 'C';
    case CATEGORY_HAS_ITEM_TYPES.CATEGORY:
      return 'P';
    default:
      return 'C';
  }
};

export const helperFindItemPillColorByType = (type: number) => {
  switch (type) {
    case PRODUCT_TYPES.COURSE:
      return THEME.colors.blueLight;
    case PRODUCT_TYPES.MENU:
      return THEME.colors.purple;
    case PRODUCT_TYPES.OPTION:
      return THEME.colors.pink;
    case PRODUCT_TYPES.OPTION_ITEM:
      return THEME.colors.magenta;
    case null:
      return THEME.colors.black;
    default:
      return THEME.colors.black;
  }
};

export const helperFindItemPillColorByTypeForPOS = (type: number) => {
  switch (type) {
    case CATEGORY_HAS_ITEM_TYPES.ITEM:
      return THEME.colors.blueLight;
    case CATEGORY_HAS_ITEM_TYPES.CATEGORY:
      return THEME.colors.black;
    default:
      return THEME.colors.black;
  }
};

export const helperFindItemPillLabelByType = (type: number) => {
  switch (type) {
    case PRODUCT_TYPES.COURSE:
      return 'Produit';
    case PRODUCT_TYPES.MENU:
      return 'Menu';
    case PRODUCT_TYPES.OPTION:
      return 'Option';
    case PRODUCT_TYPES.OPTION_ITEM:
      return 'Ingrédient';
    default:
      return 'Catégorie';
  }
};

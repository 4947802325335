/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

interface IPillProps {
  backgroundColor?: string;
  size?: string | number;
}

export const Pill = ({backgroundColor = '', size = '10px'}: IPillProps) => {
  return <span css={styles.pill(backgroundColor, size)} />;
};

const styles = {
  pill: (backgroundColor: string, size: string | number) =>
    css({
      backgroundColor,
      borderRadius: '50%',
      width: size,
      height: size,
      marginRight: '0.4rem',
    }),
};

import {SVGProps} from 'react';

export const ReloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={15}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M7.625 1.875a5.625 5.625 0 1 0 5.564 4.794c-.05-.34.199-.669.543-.669.278 0 .522.192.565.467a6.75 6.75 0 1 1-2.171-3.998v-.781a.563.563 0 0 1 1.124 0v2.25c0 .31-.252.562-.563.562h-2.25a.563.563 0 0 1 0-1.125h1.012a5.605 5.605 0 0 0-3.824-1.5Z"
      fill="#61D295"
    />
  </svg>
);

export const SmallReloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={10}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.87.958v2.464c0 .256-.22.462-.493.462H6.748c-.27 0-.492-.205-.492-.462 0-.12.05-.238.144-.327l1.071-1.003a3.749 3.749 0 0 0-2.53-.98c-2.01 0-3.614 1.503-3.614 3.388s1.622 3.388 3.614 3.388c1.668 0 2.2-.892 2.61-.892.21 0 .494.154.494.462 0 .484-1.543 1.353-3.124 1.353-2.536 0-4.598-1.933-4.598-4.31C.323 2.123 2.385.206 4.921.206c1.233 0 2.382.473 3.226 1.25l.844-.791a.51.51 0 0 1 .35-.135c.311-.034.529.174.529.428Z"
      fill={props.color || '#61D295'}
    />
  </svg>
);

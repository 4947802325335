/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {BlockRight} from './BlockRight';
import {BlockLeft} from './BlockLeft';
import {useState} from 'react';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';

export const SubMenus = () => {
  const [showProducts, setShowProducts] = useState(false);

  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const handleToggleShowProducts = () => {
    setShowProducts(!showProducts);
  };

  return (
    <div css={styles.container}>
      {hasAuthorization && (
        <BlockLeft
          showProducts={showProducts}
          onToggleShowProducts={handleToggleShowProducts}
        />
      )}
      <BlockRight showProducts={showProducts} />
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'space-between',
    // gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1rem',
    padding: '2vh',
    height: '86vh',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {ImageCustom} from './ImageCustom';
import {BRAND_AUTHORIZATION} from '../../constants/frontPolicies';
import {useBrandAccess} from '../../hooks/useBrandAccess';

export const PictogramCard = ({
  pictogram,
  showBorder = false,
  handleClick,
}: {
  pictogram: any;
  showBorder?: boolean;
  handleClick: (pictogramUuid: string) => any;
}) => {
  const {brandHasAuthorization} = useBrandAccess();

  return (
    <button
      disabled={!brandHasAuthorization(BRAND_AUTHORIZATION.PARENT)}
      onClick={() => handleClick(pictogram?.uuid)}
      css={[styles.pictoCard, showBorder && styles.pictoCardBorder]}>
      <ImageCustom src={pictogram.image} alt="picto_img" noTrunc />
    </button>
  );
};

const styles = {
  pictoCard: css({
    width: 75,
    height: 75,
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 4,
    cursor: 'pointer',
  }),

  pictoCardBorder: css({
    border: `1px solid ${THEME.colors.lightGray}`,
  }),

  pictoImg: css({
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  }),
};

/** @jsxImportSource @emotion/react */
import {Outlet, RouteObject} from 'react-router-dom';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {css} from '@emotion/react';
import {Header} from '../../../../components/layouts/Header';
import {Center} from './HeaderSections/Center';
import {Bottom} from './HeaderSections/Bottom';
import {Events} from './Events';
import {Top} from './HeaderSections/Top';
import {Prices} from './Prices';

export const Root = withRedirectAndNavigation(() => {
  return (
    <div css={styles.container}>
      <Header
        topSection={<Top />}
        centerSection={<Center />}
        bottomSection={<Bottom />}
      />
      <div css={styles.content}>
        <Outlet />
      </div>
    </div>
  );
});

export const PriceRulesRouter: RouteObject = {
  path: 'price-rules',
  element: <Root />,
  children: [
    {
      path: '',
      element: <Events />,
    },
    {
      path: 'prices',
      element: <Prices />,
    },
  ],
};

const styles = {
  container: css({
    height: '96vh',
  }),
  content: {
    padding: '2vh',
    height: '87%',
  },
};

/** @jsxImportSource @emotion/react */
import {PillLabel} from '../../../../../components/molecules/PillLabel';
import {THEME} from '../../../../../styles/theme';
import {css} from '@emotion/react';
import {CompareValues} from '../../../../../components/molecules/CompareValues';
import {M_SCREEN_DESKTOP} from '../../../../../constants/ResponsiveSize';

export const SalesByCategoriesColumns = [
  {
    headerName: 'CATEGORIE',
    flex: 1,
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      return (
        <div className="flex" css={styles.name}>
          <PillLabel
            text={String(row.name || '').toLowerCase()}
            variant="textSemiBold"
            backgroundColor={row.color}
            textTransform="capitalize"
          />
        </div>
      );
    },
  },
  {
    headerName: 'ARTICLES VENDUS',
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      return <CompareValues row={row} compareKey="orders" />;
    },
  },

  {
    headerName: 'CA TTC',
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      return <CompareValues row={row} compareKey="sales" />;
    },
  },
];

const styles = {
  name: css({
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: '1rem',
    [M_SCREEN_DESKTOP]: {
      paddingLeft: '0.5rem',
    },
  }),
};

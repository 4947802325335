/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Title} from '../../../../components/atoms/Title';
import {DataGridFooter} from '../../../../components/newDatagrid/DataGridFooter';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {helperFormatPrice} from '../../../../helpers/helperFormatPrice';
import {useTotalTva} from '../../../../hooks/useTotalTva';
import {columnsTVA} from './columns';

export const SectionTva = () => {
  const {totalTva, totalTvaLoading} = useTotalTva();

  return (
    <div css={styles.container}>
      <Title title="Ventilation par TVA" />
      <NewDataGrid
        columns={columnsTVA}
        rows={totalTva?.amountByTva || []}
        rowHeight={35}
        loading={totalTvaLoading}
        minHeight={100}
        footer={
          <DataGridFooter
            columns={[
              'TOTAL',
              `${helperFormatPrice(totalTva?.totalHT)}€`,
              `${helperFormatPrice(totalTva?.totalTVA)}€`,
              `${helperFormatPrice(totalTva?.totalTTC)}€`,
            ]}
            height={35}
          />
        }
        stickyFooter
      />
    </div>
  );
};

const styles = {
  container: css({
    height: '45%',
    '@media (max-width: 1024px)': {
      flex: 'none',
      marginBottom: '5%',
    },
  }),
};

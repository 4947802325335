export const BWebAppIcon = ({
  size,
  isDisabled = false,
}: {
  size: string;
  isDisabled?: boolean;
}) => (
  <svg
    width={size === 'small' ? '56' : '88'}
    height={size === 'small' ? '17' : '24'}
    viewBox="0 0 88 24"
    fill="none">
    <path
      d="M7.27484 5.57967C6.39146 5.57967 5.54558 5.73738 4.76367 6.02301L6.53262 9.33594C6.77194 9.28411 7.02008 9.25543 7.27594 9.25543C9.21583 9.25543 10.7896 10.8027 10.7896 12.7117C10.7896 14.6207 9.21693 16.168 7.27594 16.168C5.33494 16.168 3.85604 14.7057 3.77112 12.8705L3.7634 12.8794V6.47297C3.7634 6.47297 3.75788 6.47628 3.75458 6.47738L3.7634 0.5H0.0258789V19.8427H3.73362V18.9339C4.78242 19.5118 5.98893 19.8427 7.27484 19.8427C11.2781 19.8427 14.5238 16.65 14.5238 12.7106C14.5238 8.77129 11.2781 5.57967 7.27484 5.57967Z"
      fill={isDisabled ? '#CCD6E0' : '#3C6AF6'}
    />
    <path
      d="M19.154 10.0356L20.5105 16.9537L18.7812 18.7425L18.7614 18.7028L16.4233 10.0169H19.1551V10.0367L19.154 10.0356ZM27.8201 10.0157H30.5319L30.5121 10.0356L27.8785 19.842H24.7542L23.4771 14.0257L22.1603 19.842H20.1366V19.8023L22.1019 10.0157H24.8137L26.307 17.4643L27.8201 10.0157Z"
      fill={isDisabled ? '#CCD6E0' : '#151A30'}
    />
    <path
      d="M35.4653 9.86C36.1601 9.86 36.8152 9.99454 37.4306 10.2625C38.0459 10.5316 38.583 10.8945 39.0418 11.3532C39.5006 11.812 39.8634 12.3491 40.1325 12.9645C40.4005 13.581 40.535 14.2416 40.535 14.9496C40.535 15.3301 40.4953 15.6962 40.417 16.0502L40.3972 16.0899H35.4057L34.1474 14.085H37.8816C37.6985 13.5347 37.3931 13.0825 36.9674 12.7296C36.5417 12.3756 36.0399 12.1991 35.4642 12.1991C35.1102 12.1991 34.776 12.2708 34.4617 12.4142C34.1474 12.5575 33.875 12.7505 33.6456 12.9921C33.4162 13.2336 33.2354 13.5214 33.1052 13.8534C32.9751 14.1853 32.9089 14.5482 32.9089 14.9397C32.9089 15.2396 32.9685 15.5495 33.0865 15.8694C33.2045 16.1892 33.3743 16.4792 33.5971 16.7406C33.8199 17.002 34.0879 17.2203 34.4022 17.3957C34.7165 17.5721 35.0705 17.6604 35.4631 17.6604C35.8171 17.6604 36.1413 17.6107 36.4358 17.5126C36.7303 17.4144 37.0082 17.195 37.2706 16.8542L37.2905 16.8343L37.3291 16.8542L39.6087 17.8578L39.569 17.8975C39.0969 18.5581 38.4981 19.0731 37.7713 19.4426C37.0446 19.812 36.2748 19.9973 35.462 19.9973C34.754 19.9973 34.0901 19.866 33.467 19.6036C32.845 19.3422 32.3046 18.9816 31.8458 18.5228C31.387 18.064 31.0231 17.528 30.7551 16.9115C30.486 16.2962 30.3525 15.6411 30.3525 14.9463C30.3525 14.2515 30.486 13.5777 30.7551 12.9612C31.0242 12.3458 31.387 11.8087 31.8458 11.3499C32.3046 10.8912 32.8438 10.5283 33.467 10.2592C34.089 9.99013 34.754 9.85669 35.462 9.85669L35.4653 9.86Z"
      fill={isDisabled ? '#CCD6E0' : '#151A30'}
    />
    <path
      d="M47.1952 9.89845C47.89 9.89845 48.5451 10.0297 49.1605 10.2922C49.7759 10.5546 50.313 10.9142 50.7717 11.3729C51.2305 11.8317 51.5934 12.3688 51.8625 12.9842C52.1304 13.5996 52.265 14.2547 52.265 14.9495C52.265 15.6442 52.1304 16.2993 51.8625 16.9147C51.5934 17.5312 51.2305 18.0672 50.7717 18.526C50.313 18.9847 49.7759 19.3454 49.1605 19.6067C48.5451 19.8692 47.89 20.0005 47.1952 20.0005C45.8454 20.0005 44.8175 19.5031 44.1095 18.5072V19.8438H41.5156V6.08594H44.1294V14.9495C44.1294 15.3299 44.201 15.6828 44.3455 16.0104C44.4889 16.3379 44.6819 16.6236 44.9256 16.8651C45.1682 17.1077 45.4561 17.3007 45.7902 17.4452C46.1244 17.5896 46.4806 17.6613 46.8611 17.6613C47.2416 17.6613 47.5779 17.5896 47.9121 17.4452C48.2462 17.3007 48.5341 17.1088 48.7767 16.8651C49.0193 16.6214 49.2123 16.3379 49.3568 16.0104C49.5013 15.6828 49.573 15.3299 49.573 14.9495C49.573 14.569 49.5013 14.2161 49.3568 13.8885C49.2123 13.561 49.0193 13.2731 48.7767 13.0239C48.5341 12.7758 48.2462 12.5817 47.9121 12.4438C47.5779 12.3059 47.2272 12.2376 46.8611 12.2376C46.4949 12.2376 46.1531 12.3093 45.8002 12.4537L45.7803 12.4736L45.7604 12.4339L44.7977 10.6649L44.8175 10.6451C45.4594 10.1477 46.2523 9.89845 47.1952 9.89845Z"
      fill={isDisabled ? '#CCD6E0' : '#151A30'}
    />
    <path
      d="M63.8066 10.0171V19.8434H61.2326V14.9181C61.2326 14.5365 61.1598 14.1825 61.0164 13.8538C60.872 13.5263 60.6724 13.2363 60.4165 12.987C60.1606 12.7378 59.8662 12.5437 59.532 12.4058C59.1979 12.268 58.8472 12.1985 58.4799 12.1985C58.1127 12.1985 57.7465 12.2713 57.419 12.4157C57.0914 12.5602 56.8003 12.7543 56.5444 12.998C56.2886 13.2418 56.089 13.5307 55.9445 13.866C55.8 14.2012 55.7283 14.5597 55.7283 14.9412C55.7283 15.3228 55.8 15.6581 55.9445 15.9867C56.089 16.3154 56.2886 16.6043 56.5444 16.8547C56.8003 17.105 57.0914 17.3024 57.419 17.4469C57.7465 17.5914 58.0994 17.6641 58.4799 17.6641C58.8858 17.6641 59.2331 17.5925 59.521 17.448H59.5408V17.4877L60.5235 19.2368L60.5036 19.2566C59.8618 19.754 59.0688 20.0033 58.1259 20.0033C57.4311 20.0033 56.776 19.872 56.1606 19.6096C55.5442 19.3482 55.0082 18.9876 54.5494 18.5288C54.0906 18.07 53.7267 17.534 53.4587 16.9175C53.1896 16.3021 53.0562 15.6471 53.0562 14.9523C53.0562 14.2575 53.1896 13.5836 53.4587 12.9672C53.7278 12.3518 54.0906 11.8147 54.5494 11.3559C55.0082 10.8971 55.5442 10.5343 56.1606 10.2652C56.776 9.99611 57.4311 9.86267 58.1259 9.86267C58.8207 9.86267 59.403 9.9895 59.9147 10.2431C60.4253 10.4957 60.8642 10.8629 61.2315 11.3438V10.0204H63.8055L63.8066 10.0171Z"
      fill={isDisabled ? '#CCD6E0' : '#151A30'}
    />
    <path
      d="M70.9372 9.85975C71.6442 9.85975 72.3125 9.99429 72.9422 10.2623C73.5708 10.5314 74.1178 10.8975 74.5821 11.3629C75.0464 11.8283 75.4148 12.3687 75.6828 12.9841C75.9507 13.5995 76.0853 14.2612 76.0853 14.9681C76.0853 15.675 75.9507 16.3632 75.6828 16.9929C75.4137 17.6215 75.0475 18.1685 74.5821 18.6328C74.1178 19.0982 73.5708 19.4655 72.9422 19.7335C72.3125 20.0014 71.6453 20.136 70.9372 20.136C69.9678 20.136 69.1617 19.8757 68.5198 19.3563V19.3364L69.5025 17.541L69.5223 17.5609C69.8763 17.7186 70.2358 17.7969 70.6031 17.7969C70.9703 17.7969 71.3431 17.7241 71.6839 17.5796C72.0246 17.4351 72.3224 17.2333 72.5783 16.9753C72.8341 16.7183 73.0337 16.4183 73.1782 16.0753C73.3227 15.7324 73.3944 15.3629 73.3944 14.967C73.3944 14.5711 73.3227 14.2248 73.1782 13.8884C73.0337 13.552 72.8341 13.2587 72.5783 13.0072C72.3224 12.7558 72.0246 12.5584 71.6839 12.4139C71.3431 12.2694 70.9836 12.1956 70.6031 12.1956C70.2226 12.1956 69.8465 12.2683 69.5124 12.4117C69.1782 12.5562 68.8871 12.7536 68.6378 13.0028C68.3886 13.2521 68.1923 13.5443 68.0478 13.8785C67.9033 14.2126 67.8317 14.57 67.8317 14.9515V23.7709H65.1782V10.0163H67.8317V11.3398C68.5397 10.3527 69.5741 9.85864 70.9372 9.85864V9.85975Z"
      fill={isDisabled ? '#CCD6E0' : '#151A30'}
    />
    <path
      d="M82.8254 9.85975C83.5323 9.85975 84.2007 9.99429 84.8304 10.2623C85.459 10.5314 86.006 10.8975 86.4703 11.3629C86.9357 11.8283 87.3029 12.3687 87.5709 12.9841C87.8389 13.5995 87.9735 14.2612 87.9735 14.9681C87.9735 15.675 87.8389 16.3632 87.5709 16.9929C87.3018 17.6215 86.9357 18.1685 86.4703 18.6328C86.006 19.0982 85.459 19.4655 84.8304 19.7335C84.2007 20.0014 83.5335 20.136 82.8254 20.136C81.856 20.136 81.0499 19.8757 80.408 19.3563V19.3364L81.3906 17.541L81.4105 17.5609C81.7645 17.7186 82.124 17.7969 82.4913 17.7969C82.8585 17.7969 83.2313 17.7241 83.572 17.5796C83.9128 17.4351 84.2106 17.2333 84.4664 16.9753C84.7223 16.7183 84.9219 16.4183 85.0664 16.0753C85.2109 15.7324 85.2826 15.3629 85.2826 14.967C85.2826 14.5711 85.2109 14.2248 85.0664 13.8884C84.9219 13.552 84.7223 13.2587 84.4664 13.0072C84.2106 12.7558 83.9128 12.5584 83.572 12.4139C83.2313 12.2694 82.8717 12.1956 82.4913 12.1956C82.1108 12.1956 81.7347 12.2683 81.4006 12.4117C81.0664 12.5562 80.7753 12.7536 80.526 13.0028C80.2768 13.2521 80.0805 13.5443 79.936 13.8785C79.7915 14.2126 79.7198 14.57 79.7198 14.9515V23.7709H77.0664V10.0163H79.7198V11.3398C80.4279 10.3527 81.4623 9.85864 82.8254 9.85864V9.85975Z"
      fill={isDisabled ? '#CCD6E0' : '#151A30'}
    />
  </svg>
);

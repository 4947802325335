/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {ButtonCustom} from '../../../../Offline/components/ButtonCustom';
import {useTranslation} from 'react-i18next';
import {THEME} from '../../../../../styles/theme';
import {useNavigate} from 'react-router-dom';
import {treePreRegisterAction} from '../../../../../redux/myModulesSection/pos';
import {useAppDispatch} from '../../../../../hooks/useReduxHook';

const Header = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    navigate('/online/modules/bpos');
    dispatch(treePreRegisterAction(null));
  };
  const handleSubmit = () => {
    navigate('/online/modules/bpos');
    dispatch(treePreRegisterAction(null));
  };

  return (
    <div className="flex flex-end" css={styles.btns}>
      <ButtonCustom
        backgroundColor={THEME.colors.red}
        label={t('CANCEL')}
        onClick={handleCancel}
      />
      <ButtonCustom
        backgroundColor={THEME.colors.green}
        label={t('REGISTER')}
        onClick={handleSubmit}
      />
    </div>
  );
};

export default Header;

const styles = {
  btns: css({
    height: 72,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '0.5rem',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useEffect, useState} from 'react';
import {TotalPrice} from '../../../../components/atoms/TotalPrice';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {useReceipt} from '../../../../hooks/useReceipt';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {selectedReceiptAction} from '../../../../redux/receipt';
import {columns} from './columns';
import {Bottom} from './HeaderSections/Bottom';
import {Center} from './HeaderSections/Center';
import {ReceiptTicket} from './ReceiptTicket';
import {TicketHeader} from './TicketHeader';
import {TicketFooter} from './TicketFooter';

export const ReceiptHistory = withRedirectAndNavigation(() => {
  //
  const dispatch = useAppDispatch();
  const selectedReceipt = useAppSelector(s => s.receipt.selectedReceipt);

  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [limit] = useState(20);

  const offset = (page - 1) * limit;

  const {isReceiptsLoading, receipts, totalReceipts, totalAmount} = useReceipt(
    offset,
    limit,
  );

  useEffect(() => {
    setRowCount(oldRowCount =>
      totalReceipts !== undefined ? totalReceipts : oldRowCount,
    );
  }, [totalReceipts]);

  const handleRowClick = (row: any) => {
    dispatch(selectedReceiptAction(row));
  };

  // Note: If i have receiptId in url, i want to select that receipt
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const receiptId = urlParams.get('receiptId');
    if (receiptId) {
      dispatch(selectedReceiptAction(receiptId));
    }
  }, [dispatch]);

  return (
    <div css={[styles.container]}>
      <Header centerSection={<Center />} bottomSection={<Bottom />} />
      <div css={styles.content}>
        <div
          style={{
            flex: 1,
          }}>
          <NewDataGrid
            columns={columns}
            rows={receipts}
            loading={isReceiptsLoading}
            onRowClick={handleRowClick}
            hasPagination
            page={page}
            setPage={setPage}
            totalRows={rowCount}
            rowPerPage={limit}
            selectedRow={selectedReceipt}
          />
          <TotalPrice price={totalAmount} />
        </div>

        {selectedReceipt && (
          <ReceiptTicket
            TicketHeader={TicketHeader}
            TicketFooter={TicketFooter}
          />
        )}
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    '@media print': {
      padding: '0',
      height: 'auto',
    },
  }),
  content: css({
    padding: '2vh',
    height: '84%',
    display: 'flex',
    '@media (max-width: 1024px)': {
      height: '79%',
    },

    '@media print': {
      padding: '0',
      height: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '>div:first-of-type': {
        display: 'none',
      },
    },
  }),
};

import {ILanguages} from '@bill-app-types/b-types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type ICustomLanguage = ILanguages & {
  icon_path: string;
  id: string;
};

interface ISlice {
  languageSelected: ICustomLanguage | null;
}

const initialState: ISlice = {
  languageSelected: null,
};

const slice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    languageSelectedAction(
      state,
      action: PayloadAction<ICustomLanguage | null>,
    ) {
      state.languageSelected = action.payload;
    },
  },
});

export const {languageSelectedAction} = slice.actions;

export default slice.reducer;

import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  showProducts: boolean;
  categorySelected: any | null;
  subCategorySelected: any | null;
  categoryProducts: any[];
  searchInput: string;
  updateMode: boolean;
  updatedCategory: any;
  sectionShow: string;
}

const initialState: ISlice = {
  showProducts: false,
  categorySelected: null,
  subCategorySelected: null,
  categoryProducts: [],
  searchInput: '',
  updateMode: false,
  updatedCategory: null,
  sectionShow: ''
};

const slice = createSlice({
  name: 'posCategories',
  initialState,
  reducers: {
    showProductsAction(state, action) {
      state.showProducts = action.payload;
    },
    categorySelectedAction(state, action) {
      if(state.categorySelected?.id === action.payload.id){
        state.categorySelected = null;
        state.categoryProducts = [];
      } else {
        state.categorySelected = action.payload;
      }
    },
    subCategorySelectedAction(state, action) {
      state.subCategorySelected =
        state.subCategorySelected?.id === action.payload.id
          ? null
          : action.payload;
      state.showProducts = true;

      if (!state.subCategorySelected) {
        state.categoryProducts = [];
      }
    },
    categoryProductsAction(state, action) {
      state.categoryProducts = action.payload;
    },
    searchInputAction(state, action) {
      state.searchInput = action.payload;
    },
    updateModeAction(state, action) {
      state.updateMode = action.payload;
    },
    updatedCategoryAction(state, action) {
      state.updatedCategory = action.payload
    },
    sectionShowAction(state, action) {
      state.sectionShow = action.payload;
    },
  },
});

export const {
  showProductsAction,
  categorySelectedAction,
  subCategorySelectedAction,
  categoryProductsAction,
  searchInputAction,
  updateModeAction,
  updatedCategoryAction,
  sectionShowAction
} = slice.actions;

export default slice.reducer;

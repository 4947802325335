/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../../components/layouts/Header';
import {HeaderTabs} from '../HeaderSections/HeaderTabs';
import {HeaderTopButtons} from '../HeaderSections/HeaderTopButtons';
import {Recipes} from './Recipes';
import {RecipesCatalog} from '../../../../../components/organisms/RecipesCatalog';
import {createItemRecipe} from '../../../../../api/fetchItems';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';

export const RecipesSection = withRedirectAndNavigation(() => {
  //
  const clientQuery = useQueryClient();

  const {brandHasAuthorization} = useBrandAccess();

  const productPreRegister = useAppSelector(
    s => s.myCatalogSection.products.productPreRegister,
  );
  const productRecipes = useAppSelector(
    s => s.myCatalogSection.products.productRecipes,
  );

  const handleClickAddRecipe = async (item: any) => {
    if (
      !productRecipes.find((recipe: any) => recipe?.recipe?.uuid === item.uuid)
    ) {
      const res = await createItemRecipe(productPreRegister?.uuid, item?.uuid);
      if (res?.status === 204) {
        helperDisplayToast(res?.data?.message || 'Ingrédient ajouté', true);
        clientQuery.invalidateQueries(['getItemRecipes']);
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible d'ajouter l'ingrédient",
          false,
        );
      }
    }
  };

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <div css={styles.container}>
      <Header
        centerSection={<HeaderTopButtons />}
        bottomSection={<HeaderTabs />}
      />
      <div className="flex" css={styles.content}>
        {hasAuthorization && (
          <RecipesCatalog onClickRecipe={handleClickAddRecipe} />
        )}
        <Recipes />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    height: '100vh',
  }),
  content: css({
    paddingBottom: 0,
    height: '85%',
    width: '100%',
    gap: '1rem',
    padding: '2vh',
  }),
};

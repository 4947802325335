/* @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {PieChart, Pie, Cell, Tooltip, Label} from 'recharts';
import {CustomTurnOverToolTip} from '../SalesTurnOverPieChart/CustomTurnOverToolTip';
import {THEME} from '../../../styles/theme';
import {useAppSelector} from '../../../hooks/useReduxHook';

export const SalesPieChart = ({data}: {data: any[]}) => {
  const salesGraphSection = useAppSelector(
    s => s.myStatsSection.sales.salesGraphSection,
  );

  const key = salesGraphSection === 'ORDERS' ? 'valueOrder' : 'value';
  const percentKey = salesGraphSection === 'ORDERS' ? 'orders' : 'sales';

  const newData = data?.filter(
    item =>
      (percentKey === 'orders'
        ? item[percentKey].value
        : item[percentKey]?.TTC?.total_price) > 1,
  );
  return (
    <div css={styles.pieChart} className="flex-center">
      <PieChart width={300} height={170}>
        <Tooltip content={props => <CustomTurnOverToolTip {...props} />} />
        <Pie
          data={newData || []}
          innerRadius={50}
          outerRadius={80}
          paddingAngle={1}
          dataKey={key}>
          {newData?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
          <Label
            value="CA"
            className="textSemiBold"
            color={THEME.colors.primary}
            position="center"
          />
        </Pie>
      </PieChart>
    </div>
  );
};

const styles = {
  pieChart: css({
    position: 'relative',
    width: 'min(18%,300px)',
  }),
};

import {CHANNELS} from '@bill-app-types/b-types';
import {THEME} from '../styles/theme';

export const helperGetChannelById = (channelId: number) => {
  switch (channelId) {
    case 1:
      return THEME.colors.yellowDark;
    case 2:
      return THEME.colors.primary;
    case 3:
      return THEME.colors.blueLight;
    case 4:
      return THEME.colors.red;
    case 5:
      return THEME.colors.green;
    case 6:
      return THEME.colors.purple;
    default:
      return THEME.colors.primary;
  }
};

export const helperGetChannelNamebyId = (channelId: CHANNELS) => {
  switch (channelId) {
    case 1:
      return 'BORNE';
    case 2:
      return 'CAISSE';
    case 3:
      return 'WEB';
    case 4:
      return 'DRIVE';
    case 5:
      return 'EXTERNAL';
    case 6:
      return 'APP';
    default:
      return 'LIVRAISON';
  }
};

export const PrevIcon = () => {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.08765 6.04455C0.721435 6.41077 0.721435 7.00549 1.08765 7.3717L6.71265 12.9967C7.07886 13.3629 7.67358 13.3629 8.03979 12.9967C8.40601 12.6305 8.40601 12.0358 8.03979 11.6696L3.0769 6.70666L8.03687 1.74377C8.40308 1.37756 8.40308 0.782837 8.03687 0.416626C7.67065 0.050415 7.07593 0.050415 6.70972 0.416626L1.08472 6.04163L1.08765 6.04455Z"
        fill="#626262"
      />
    </svg>
  );
};

import {SVGProps} from 'react';

export const CustomerStarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.05958 0.5C8.32911 0.5 8.57521 0.653809 8.69239 0.896094L10.7022 5.03516L15.1934 5.70019C15.457 5.73828 15.6738 5.92285 15.7588 6.1748C15.8408 6.42969 15.7734 6.70801 15.5859 6.89258L12.3281 10.1211L13.0986 14.6768C13.1426 14.9404 13.0342 15.21 12.8145 15.3652C12.5977 15.5205 12.2842 15.541 12.0733 15.415L8.05958 13.2705L4.02247 15.415C3.81153 15.541 3.52443 15.5205 3.3047 15.3652C3.0879 15.21 2.9795 14.9404 2.99708 14.6768L3.79103 10.1211L0.536437 6.89258C0.347179 6.70801 0.280089 6.42969 0.363292 6.1748C0.446202 5.92285 0.66505 5.73828 0.928429 5.70019L5.417 5.03516L7.42677 0.896094C7.54689 0.653779 7.79005 0.5 8.05958 0.5ZM8.05958 2.81299L6.5215 5.98437C6.41896 6.19238 6.21974 6.33887 5.99122 6.37402L2.52482 6.88379L5.042 9.37695C5.20314 9.53808 5.27638 9.76953 5.23829 9.99512L4.6465 13.499L7.72853 11.8525C7.93653 11.7412 8.18556 11.7412 8.39064 11.8525L11.4727 13.499L10.8809 9.99512C10.8428 9.76953 10.919 9.53808 11.0801 9.37695L13.5967 6.88379L10.1309 6.37402C9.89942 6.33887 9.70021 6.19238 9.6006 5.98437L8.05958 2.81299Z"
      fill="#CCD6E0"
    />
  </svg>
);

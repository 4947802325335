/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Title} from '../../../../../../components/atoms/Title';
import {TextAreaLabel} from '../../../../../../components/atoms/TextAreaLabel';
import {InputLabel} from '../../../../../../components/atoms/InputLabel';
import {TextVariant} from '../../../../../../components/atoms/TextVariant';
import {useTranslation} from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useReduxHook';
import {menuPreRegisterChangeAction} from '../../../../../../redux/myCatalogSection/menus';
import {UploadButton} from '../../../../../../components/atoms/UploadButton';
import {THEME} from '../../../../../../styles/theme';
import {SelectList} from '../../../../../../components/molecules/SelectList';
import {useQuery} from '@tanstack/react-query';
import {fetchCatalogVendorStructure} from '../../../../../../api/fetchCatalogVendorStructure';
import {helperFormatSelectItem} from '../../../../../../helpers/helperFormatSelectItem';
import {useBrandAccess} from '../../../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../../../constants/frontPolicies';

export const InfosGeneral = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const {brandHasAuthorization} = useBrandAccess();

  const menuPreRegister = useAppSelector(
    s => s.myCatalogSection.menus.menuPreRegister,
  );

  const {data: vendorStructureData} = useQuery(
    ['fetchCatalogVendorStructure'],
    fetchCatalogVendorStructure,
  );

  const image = menuPreRegister?.image;

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;

    dispatch(
      menuPreRegisterChangeAction({
        name,
        value,
      }),
    );
  };

  const handleChangeVisualPicture = (e: any) => {
    const {name, files} = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const imageURL = e.target.result;

        dispatch(
          menuPreRegisterChangeAction({
            name,
            value: {url: imageURL, file},
          }),
        );
      };

      reader.readAsDataURL(file);
    }
  };

  const handleClickBarcode = () => {
    navigator.clipboard.writeText(menuPreRegister?.barcode);
  };

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <div css={styles.container}>
      <Title title="Informations générales" />
      <div css={styles.content}>
        <div>
          <InputLabel
            label={t('MENU_NAME')}
            required
            value={menuPreRegister?.label}
            name="label"
            onChange={handleChange}
            disabled={!hasAuthorization}
          />
          <div className="flex" css={styles.flexGap}>
            <InputLabel
              label={t('MENU_PRICE')}
              required
              value={menuPreRegister?.price}
              onChange={handleChange}
              name="price"
            />
            <SelectList
              label={t('VENDOR_STRUCTURE')}
              placeHolder={t('VENDOR_STRUCTURE')}
              name="vendorStructureUuid"
              value={menuPreRegister?.vendorStructureUuid}
              list={vendorStructureData?.map(helperFormatSelectItem)}
              onChange={handleChange}
              manageByFormik={false}
              disabled={!hasAuthorization}
            />
          </div>

          <InputLabel
            label={t('ARTICLE_CODE')}
            required
            value={menuPreRegister?.barcode}
            onClickIcon={handleClickBarcode}
            showIcon
            disabled
          />
        </div>

        <TextAreaLabel
          label={t('DESCRIPTION_OF_MENU')}
          value={menuPreRegister?.description}
          onChange={handleChange}
          height={'83%'}
          disabled={!hasAuthorization}
        />

        <div>
          <TextVariant variant="textLabel" text={t('VISUAL')} />
          <div css={styles.uploadPicture}>
            <UploadButton
              style={{marginBottom: 0}}
              width={194}
              x={1920}
              y={1080}
              name="updatedImage"
              handleChange={handleChangeVisualPicture}
              value={
                !!menuPreRegister?.updatedImage
                  ? menuPreRegister?.updatedImage?.url
                  : image || undefined
              }
              disabled={!hasAuthorization}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({}),
  content: css({
    display: 'grid',
    gridTemplateColumns: '2.5fr 2fr 1fr',
    gridAutoRows: '1fr',
    gap: '1rem',
  }),
  flexGap: css({
    gap: '1rem',
  }),
  uploadPicture: css({
    backgroundColor: THEME.colors.white,

    height: '83%',
    width: 194,
    borderRadius: 3,
    alignItems: 'end',
    justifyContent: 'center',
    display: 'flex',
  }),
};

export type AmountSign = '=' | '-' | '+' | null;

export const helperFormatPriceRuleAmount = (
  amount: number,
  sign: AmountSign,
) => {
  if (!sign) {
    return amount;
  }

  if (['=', '+'].includes(sign)) {
    return Math.abs(amount);
  }

  if (sign === '-') {
    return Number(amount) < 0 ? amount : -amount;
  }

  return amount;
};

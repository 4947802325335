import {BRAND_AUTHORIZATION} from '../../../constants/frontPolicies';
import {brandHasAuthorizationForRouting} from '../../../helpers/helperFrontPolicies';
import {Informations} from './Informations';
import {NetworkPrinterRouter} from './NetworkPrinters';
import {PosCategories} from './PosCategories';
import {ProductionArea} from './ProductionArea';
import {ServiceZones} from './ServiceZones';
import {Area} from './ServiceZones/Area';

export const MyRestaurantRouter = {
  path: 'restaurant',
  children: [
    {
      path: 'informations',
      element: <Informations />,
    },
    {
      path: 'service-areas',
      element: <ServiceZones />,
      loader: () => brandHasAuthorizationForRouting(BRAND_AUTHORIZATION.CHILD),
    },
    {
      path: 'service-areas/area/:id',
      element: <Area />,
      loader: () => brandHasAuthorizationForRouting(BRAND_AUTHORIZATION.CHILD),
    },
    {
      path: 'production-areas',
      element: <ProductionArea />,
    },
    {
      path: 'pos-categories',
      element: <PosCategories />,
    },
    NetworkPrinterRouter,
  ],
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ClockIcon} from '../../assets/svgs/ClockIcon';
import {TextVariant} from './TextVariant';
import {helperDate} from '../../helpers/helperDate';
import DatePicker from 'react-datepicker';
import {THEME} from '../../styles/theme';

export const DateTimeInput = ({
  time,
  handleChangeTime,
  maxTime,
  minTime,
  timeRef,
  isSameTime = true,
}: {
  time: Date | null;
  handleChangeTime: (time: Date | null) => void;
  maxTime?: Date | null;
  minTime?: Date | null;
  timeRef?: any;
  isSameTime: boolean;
}) => {
  const filterPassedTime = (time: Date) => {
    const selectedDate = new Date(time);

    if (maxTime) {
      return maxTime.getTime() > selectedDate.getTime();
    } else if (minTime) {
      return minTime.getTime() < selectedDate.getTime();
    }

    return true;
  };

  return (
    <div>
      <DatePicker
        ref={timeRef}
        selected={time}
        onChange={date => handleChangeTime(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={5}
        timeCaption="Time"
        dateFormat="h:mm aa"
        filterTime={filterPassedTime}
        locale={'fr'}
        customInput={
          <div className="flex-align-center" css={styles.dateTimeInput}>
            <ClockIcon />
            <TextVariant
              text={helperDate(time || new Date(), 'HH:mm')}
              variant="textSemiBold"
              style={{
                marginLeft: '0.5rem',
                color: isSameTime
                  ? THEME.colors.lightGray
                  : THEME.colors.primary,
              }}
            />
          </div>
        }
      />
    </div>
  );
};

const styles = {
  dateTimeInput: css({
    border: `1px solid ${THEME.colors.bgLight}`,
    borderRadius: 2,
    padding: '0.3rem 0.5rem',
    minWidth: '4.5rem',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {OrderItem} from './OrderItem';

export const TicketContent = ({data}: {data: any}) => {
  const items = data?.orderItems;
  return (
    <div css={styles.scrollView}>
      {items?.map((item: any, index: number) => (
        <OrderItem key={index} item={item} />
      ))}
    </div>
  );
};

const styles = {
  scrollView: css({
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '25vh',
    '@media print': {
      height: 'auto',
    },
  }),
};

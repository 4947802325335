/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {TextVariant} from '../atoms/TextVariant';
import {ValueSeparatorPercent} from '../atoms/ValueSeparatorPercent';
import {ProductTypePrice} from './ProductTypePrice';

export const CumulPrice = ({
  label,
  value,
  secondValue,
  showPriceByMenu = false,
}: {
  label?: string;
  value?: number | string;
  secondValue?: number | string;
  showPriceByMenu?: boolean;
}) => {
  return (
    <div
      className="flex flex-align-center  justify-space-between"
      css={styles.container}>
      <TextVariant
        text={String(label)}
        variant="textSemiBold"
        style={{flex: 1}}
      />

      <div css={styles.blockRight}>
        <ValueSeparatorPercent
          value={Number(value)}
          percent={Number(secondValue)}
          color={THEME.colors.primary}
          variant="bigTitle"
          suffix="€"
          percentSuffix=""
          formatValue={true}
          seperatorColor={THEME.colors.lightGray}
          percentColor={THEME.colors.yellowDark}
        />

        {showPriceByMenu && (
          <div className="flex items-center" style={{gap: '0.4rem'}}>
            <ProductTypePrice type={2} />
            <ProductTypePrice type={1} bgColor={THEME.colors.purple} />
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    marginBottom: '0.8rem',
  }),
  blockRight: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  }),
};

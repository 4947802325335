import {HeaderTabsLinks} from '../../../../../../components/molecules/HeaderTabsLinks';

export const EDIT_MENU_TABS_DATA = [
  {
    label: 'Généralités',
    link: '',
  },
  {
    label: 'Composition',
    link: '/composition',
  },
  // {
  //   label: 'Règles du prix',
  //   link: '/price-rules',
  // },
  {
    label: 'Ventes additionnelles',
    link: '/additionnal-sales',
  },
];

export const EditMenuHeaderBottom = () => {
  return (
    <HeaderTabsLinks
      data={EDIT_MENU_TABS_DATA}
      path="catalog/menus/edit"
      lengthToGetRootLink={3}
    />
  );
};

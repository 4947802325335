/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Header} from '../../../../components/layouts/Header';
import {Center} from './HeaderSections/Center';
import {Bottom} from './HeaderSections/Bottom';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {columns} from './columns';
import {useCustomer} from '../../../../hooks/useCustomer';
import {useEffect, useState} from 'react';
import {CustomerDetails} from './CustomerDetails';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {
  checkedCustomersAction,
  selectedCustomerAction,
} from '../../../../redux/customer';
import {ICustomer} from '@bill-app-types/b-types';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';

export const Customers = withRedirectAndNavigation(() => {
  //
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [limit] = useState(20);

  const offset = (page - 1) * limit;

  const multiSelection = useAppSelector(s => s.customer.multiSelection);
  const checkedCustomers = useAppSelector(s => s.customer.checkedCustomers);

  const {data, isLoading, totalCustomers} = useCustomer(offset, limit);

  useEffect(() => {
    setRowCount(oldRowCount =>
      totalCustomers !== undefined ? totalCustomers : oldRowCount,
    );
  }, [totalCustomers]);

  const handleSelectCheckBox = (customer: ICustomer) => {
    dispatch(checkedCustomersAction(customer));
  };

  const handleRowClick = (customer: ICustomer) => {
    dispatch(selectedCustomerAction(customer));
  };

  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} bottomSection={<Bottom />} />
      <div css={styles.content}>
        <NewDataGrid
          columns={columns}
          rows={data}
          rowHeight={35}
          loading={isLoading}
          hasPagination
          page={page}
          setPage={setPage}
          totalRows={rowCount}
          rowPerPage={limit}
          dropdown
          DatagridDropdown={CustomerDetails}
          checkBoxSelection={multiSelection}
          onCheckBoxClick={handleSelectCheckBox}
          selectedCheckbox={checkedCustomers}
          onRowClick={handleRowClick}
        />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    height: '100vh',
  }),
  content: css({
    padding: '2vh',
    display: 'flex',
    height: '87vh',
  }),
};

import React, {useMemo, useState} from 'react';
import {arrayMoveImmutable} from 'array-move';
import {SortableVerticalList} from '../molecules/SortableVerticalList';
export type THandleDragAndDrop = (p: {
  items: {
    uuid: string;
    price?: number;
    image?: string;
    type?: number;
  }[];
  itemUuid?: string;
  newIndex?: number;
}) => void;

interface IProps {
  data: unknown[];
  handleDragAndDrop?: THandleDragAndDrop;
  ItemComponent: React.FC<any>;
  level?: number;
}

export const SortableListVerticalContainer = ({
  data,
  handleDragAndDrop,
  level = 0,
  ItemComponent,
}: IProps) => {
  const [copyData, setCopyData] = useState<any[]>([]);

  const onSortEnd = async ({oldIndex, newIndex}: any) => {
    if (!copyData[oldIndex] || !handleDragAndDrop) return;

    const items = arrayMoveImmutable(copyData, oldIndex, newIndex);

    setCopyData(() => items);

    handleDragAndDrop({
      items,
      itemUuid: copyData[oldIndex]?.uuid,
      newIndex,
    });
  };

  useMemo(() => {
    if (data) {
      setCopyData(
        [...data]?.sort((a: any, b: any) => a?.position - b?.position),
      );
    }
  }, [data]);

  return (
    <SortableVerticalList
      // @ts-ignore
      data={[...copyData]}
      axis="y"
      onSortEnd={onSortEnd}
      pressDelay={200}
      ItemComponent={ItemComponent}
      level={level}
      handleDragAndDrop={handleDragAndDrop}
    />
  );
};

import {useQuery} from '@tanstack/react-query';
import {fetchAreas} from '../api/fetchAreas';
import {useAppSelector} from './useReduxHook';

export const useAreas = ({isAll = true}: {isAll?: boolean} = {}) => {
  const brand = useAppSelector(s => s.brand.brand);
  const orderBy = useAppSelector(s => s.filter.orderBy);
  const search = useAppSelector(s => s.filter.search);
  const cards = useAppSelector(s => s.filter.cards);
  const isArchived = useAppSelector(s => s.filter.isArchived);

  const queryKey = isAll ? [] : [brand?.id, orderBy, search, cards, isArchived];
  const filter = isAll
    ? {}
    : {
        search,
        isArchived,
        orderBy: orderBy.filter(
          item =>
            ['createdAt', 'updatedAt', 'locationCount'].includes(item.field) &&
            item.sort,
        ),
        cardUuids: cards?.map(item => String(item.value)),
      };

  const {data, isLoading, refetch} = useQuery(
    ['fetchAreas', ...queryKey],
    () => fetchAreas(brand.id, isAll ? {} : filter),
    {
      enabled: !!brand.id,
    },
  );

  return {
    areas: data,
    isLoading,
    refetch,
  };
};

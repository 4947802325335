import {useLayoutEffect, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {refreshUserDetails} from '../../api/auth';
import {useAppDispatch} from '../../hooks/useReduxHook';
import {authAction, frontPoliciesAction, logoutAction} from '../../redux/auth';
import {getBrandInfos} from '../../api/fetchBrandInfos';
import {brandAction} from '../../redux/brand';
import {getUserFrontPolicies} from '../../helpers/helperFrontPolicies';
import {AbsoluteLoader} from '../layouts/AbsoluteLoader';
import {useFlagBCatalog} from '../../hooks/useFlagBCatalog';
import {helperGenerateRedirectUrl} from '../../helpers/helperGenerateRedirectUrl';
import {helperExternalUrl} from '../../helpers/helperExternalUrl';

export const withRedirectionBO =
  (WrappedComponent: React.ComponentType) => (props: any) => {
    //
    const auth_token = localStorage.getItem('auth_token');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const queryToken = searchParams.get('token');
    const {flagBCatalogReady, flagBCatalogEnabled} = useFlagBCatalog();

    const [isLoading, setIsLoading] = useState(true);

    const refreshUser = async (token: string) => {
      setIsLoading(true);
      const res = await refreshUserDetails(token);
      if (res?.status === 200) {
        dispatch(authAction(res?.data?.data?.data));

        localStorage.setItem('auth_token', token);
        localStorage.setItem(
          'auth_refresh_token',
          res?.data?.data?.data?.refreshToken,
        );
        const brandInfos = await getBrandInfos(res?.data?.data?.data?.brandId);
        dispatch(brandAction(brandInfos));

        const userFrontPolicies = getUserFrontPolicies(
          res?.data?.data?.data?.frontPolicies,
        );
        dispatch(frontPoliciesAction(userFrontPolicies));
        navigate(location.pathname);
      } else if (!auth_token) {
        navigate('/');
      } else {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('auth_refresh_token');
        dispatch(logoutAction());
        dispatch(brandAction(null));
        navigate('/');
      }
      setIsLoading(false);
    };

    const redirect_url = helperGenerateRedirectUrl(location.pathname);

    useLayoutEffect(() => {
      if (!!queryToken) {
        refreshUser(queryToken);
      } else if (!auth_token) {
        setIsLoading(false);
        navigate('/');
      } else {
        setIsLoading(false);
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryToken, auth_token, navigate]);

    if (!flagBCatalogEnabled && flagBCatalogReady && redirect_url) {
      window.location.href = helperExternalUrl(redirect_url);
      return null;
    }

    if (isLoading || !flagBCatalogReady) {
      return <AbsoluteLoader size={45} />;
    }

    return <WrappedComponent {...props} />;
  };

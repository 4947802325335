/** @jsxImportSource @emotion/react */
import {useContext, useEffect, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {filterAction} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
import {FilterRadioButtons} from './FilterRadioButtons';
import {OPTION_TYPES} from '../../constants/global';

export const OptionTypeFilter = ({marginRight}: {marginRight?: string}) => {
  const {dispatch} = useContext<any>(UtilsContext);

  const [selectedType, setSelectedType] = useState<any>(null);
  const [forceClose, setForceClose] = useState(false);

  const optionType: any = useAppSelector(s => s.filter.optionType);

  const handleRadioChange = (e: any) => {
    const {value} = e.target;
    setSelectedType(value);
  };

  const handleSubmit = () => {
    dispatch(filterAction({name: 'optionType', values: selectedType}));
    setForceClose(true);
  };

  const handleResetFilter = () => {
    dispatch(filterAction({name: 'optionType', values: null}));
  };

  useEffect(() => {
    if (optionType) {
      setSelectedType(optionType);
    }
    return () => {
      setForceClose(false);
    };
  }, [optionType]);

  return (
    <FilterButton
      marginRight={marginRight}
      label={'Type'}
      valueType="value"
      values={OPTION_TYPES.find(item => item.value === optionType)?.label}
      hideFilterIcon={!!optionType}
      showResetButton={!!optionType}
      onReset={handleResetFilter}
      forceClose={forceClose}>
      <FilterRadioButtons
        title="Type"
        list={OPTION_TYPES}
        selectedValue={selectedType}
        onChange={handleRadioChange}
        onSubmit={handleSubmit}
      />
    </FilterButton>
  );
};

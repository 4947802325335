/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {THEME} from '../../../../../../../styles/theme';
import {helperLabelTranslation} from '../../../../../../../helpers/helperLabel';

const Center = ({category}: {category: any}) => {
  return (
    <div css={styles.container}>
      <div className="flex" css={styles.infos}>
        <p className="textMedium" css={styles.name}>
          {helperLabelTranslation(category)}
        </p>
      </div>
      <p className="title03" css={styles.elements}></p>
    </div>
  );
};

export default Center;

const styles = {
  container: css({
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0px 15px 0px 25px',
  }),
  infos: css({
    width: '100%',
  }),
  name: css({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '90%',
    color: THEME.colors.black,
  }),
  elements: css({
    color: THEME.colors.gray2,
  }),
};

/** @jsxImportSource @emotion/react */
import {CHANNELS} from '@bill-app-types/b-types';
import {useContext, useEffect, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {
  IObject,
  initFilterChannelsAction,
  resetFilterChannelsAction,
} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
import {FilterCheckboxs} from './FilterCheckboxs';

// TERMINAL = 1,
// POS = 2,
// WEB = 3,
// DRIVE = 4,
// EXTERNAL = 5,
// APP = 6

export const ChannelIdFilter = ({
  marginRight,
  withBorder = false,
}: {
  marginRight?: string;
  withBorder?: boolean;
}) => {
  const channels = useAppSelector(state => state.filter.channels);
  const {t, dispatch} = useContext<any>(UtilsContext);

  const [channelsSelected, setChannelsSelected] = useState<IObject[]>([]);
  const [forceClose, setForceClose] = useState(false);

  const channelsList = Object.entries(CHANNELS)
    .slice(7)
    .map(entry => ({
      name: entry[0],
      value: entry[1],
    }));

  const handleCheckedChange = (e: any) => {
    if (e.target.name === 'ALL') {
      if (channelsSelected.length === channelsList.length) {
        setChannelsSelected([]);
      } else {
        setChannelsSelected(channelsList);
      }
    } else {
      if (!!channelsSelected.find(channel => channel.name === e.target.name)) {
        setChannelsSelected(
          channelsSelected.filter(channel => channel.name !== e.target.name),
        );
      } else {
        setChannelsSelected([
          ...channelsSelected,
          {
            name: e.target.name,
            value: e.target.value,
          },
        ]);
      }
    }
  };

  const handleResetFilterChannels = () => {
    dispatch(resetFilterChannelsAction());
  };

  const handleSubmit = () => {
    //
    dispatch(initFilterChannelsAction(channelsSelected));
    setForceClose(true);
  };

  useEffect(() => {
    setChannelsSelected(channels);
    return () => {
      setForceClose(false);
    };
  }, [channels]);

  return (
    <FilterButton
      marginRight={marginRight}
      label={t('CHANNELS')}
      valueType="list"
      values={channels}
      showResetButton={channels?.length > 0}
      onReset={handleResetFilterChannels}
      withBorder={withBorder}
      hideFilterIcon={channels?.length > 0}
      forceClose={forceClose}>
      <FilterCheckboxs
        title="Canaux de commande"
        list={channelsList}
        selectedValues={channelsSelected}
        onChange={handleCheckedChange}
        onSubmit={handleSubmit}
      />
    </FilterButton>
  );
};
// function useFocusEffect(arg0: () => void, arg1: never[]) {
//   throw new Error('Function not implemented.');
// }

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Title} from '../../../../../../components/atoms/Title';
import {FieldsRequired} from '../../../../../../components/atoms/FieldsRequired';
import {useTranslation} from 'react-i18next';
import {THEME} from '../../../../../../styles/theme';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useReduxHook';
import {SelectMultiList} from '../../../../../../components/molecules/SelectMultiList';
import {useQuery} from '@tanstack/react-query';
import {
  disabledAreaCard,
  enabledAreaCard,
  fetchAreaCards,
} from '../../../../../../api/fetchAreas';
import {useEffect, useState} from 'react';
import {InputLabel} from '../../../../../../components/atoms/InputLabel';
import {updateAreaSelectedFormAction} from '../../../../../../redux/myRestaurantSection/areas';
import {
  disabledAreaHasCardV2,
  enabledAreaHasCardV2,
  fetchAreaHasCards,
} from '../../../../../../api/fetchCards';
import {useFlagBCatalog} from '../../../../../../hooks/useFlagBCatalog';
import {helperDisplayToast} from '../../../../../../helpers/helperToast';

export const AreaFormContainer = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const brand = useAppSelector(state => state.brand.brand);
  const areaSelected = useAppSelector(
    s => s.myRestaurantSection.areas.areaSelected,
  );
  const {flagBCatalogReady, flagBCatalogEnabled} = useFlagBCatalog();

  const [areaCards, setAreaCards] = useState<any[]>([]);
  const [areaCardsChecked, setAreaCardsChecked] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const {data, refetch} = useQuery(
    ['fetchAreaCards', brand.id, areaSelected],
    () =>
      flagBCatalogEnabled
        ? fetchAreaHasCards(areaSelected?.uuid)
        : fetchAreaCards(brand.id, String(areaSelected?.id)),
    {
      enabled: !!brand.id && !!areaSelected && flagBCatalogReady,
    },
  );

  const handleSelectCard = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!areaSelected) return;

    const {value} = e.target;

    setLoading(true);
    const findCard = areaCards.find(
      card => card.uuid === value || card.id === value,
    );
    const findCardChecked = areaCardsChecked.find(
      card => card.uuid === value || card.id === value,
    );
    let res;
    if (findCardChecked) {
      res = flagBCatalogEnabled
        ? await disabledAreaHasCardV2(areaSelected?.uuid, value)
        : await disabledAreaCard(brand.id, areaSelected?.id, value);
    } else if (findCard) {
      res = flagBCatalogEnabled
        ? await enabledAreaHasCardV2(areaSelected?.uuid, value)
        : await enabledAreaCard(brand.id, areaSelected?.id, value);
    }
    if ([200, 204].includes(res?.status)) {
      await refetch();
      helperDisplayToast('Carte modifiée', true);
    } else {
      helperDisplayToast('Impossible de modifier la carte', false);
    }

    setLoading(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    dispatch(
      updateAreaSelectedFormAction({
        key: name,
        value,
      }),
    );
  };

  useEffect(() => {
    const cards = flagBCatalogEnabled ? data : data?.areaHasCards;
    if (cards) {
      setAreaCards(cards);
      setAreaCardsChecked(
        cards?.filter((card: any) => card.activate || card.isActive),
      );
    }
  }, [data]);

  return (
    <div css={styles.areaFormContainer}>
      <Title title={t('AREA_CONFIGURATION')}></Title>

      <form>
        <InputLabel
          type="text"
          label={t('AREA_NAME')}
          placeholder={t('AREA_NAME')}
          name="label"
          value={areaSelected?.label}
          onChange={handleChange}
          required
        />
        <InputLabel
          type="text"
          label={t('AREA_LOCATIONS_NAME')}
          placeholder={t('AREA_LOCATIONS_NAME')}
          name="location_label"
          value={areaSelected?.location_label}
          onChange={handleChange}
          required
        />

        <SelectMultiList
          label={t('AREA_CARDS')}
          name="areaCards"
          required
          listChecked={areaCardsChecked?.map((card: any) => ({
            value: card.uuid || card.id,
            label: card.label,
          }))}
          list={areaCards?.map((card: any) => ({
            value: card.uuid || card.id,
            label: card.label,
          }))}
          onChange={handleSelectCard}
          isLoading={loading}
        />

        {/* <Field
            as="select"
            name="areaCards"
            css={styles.areaCardsSelect}
            required>
            <option css={styles.areaCardsSelectOption} value="">
              {t('AREA_CARDS')}
            </option>
            <option css={styles.areaCardsSelectOption} value="red">
              Red
            </option>
            <option css={styles.areaCardsSelectOption} value="green">
              Green
            </option>
            <option css={styles.areaCardsSelectOption} value="blue">
              Blue
            </option>
          </Field> */}
      </form>

      <FieldsRequired />
    </div>
  );
};

const styles = {
  areaFormContainer: css({
    width: '49%',
    // marginRight: '2vh',
  }),
  areaCardsLabel: css({
    width: '100%',
    fontWeight: 600,
    fontSize: '14px',
    color: THEME.colors.gray,
    margin: '15px 0 5px',
    padding: '0 10px',
  }),
  required: css({
    color: THEME.colors.red,
  }),
  areaCardsSelect: css({
    width: '100%',
    height: '39px',
    border: 'none',
    borderRadius: '2px',
    padding: '0 10px',
    cursor: 'pointer',
    fontWeight: '600',
    fontSize: '12px',
  }),
  areaCardsSelectOption: css({
    fontWeight: '600',
    fontSize: '12px',
  }),
};

/** @jsxImportSource @emotion/react */
import {useNavigate, useParams} from 'react-router-dom';
import {ModuleHeader} from '../ModuleHeader';
import {BKioskForm} from './BKioskForm';
import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {useQuery} from '@tanstack/react-query';
import {
  IModuleParameter,
  fetchModuleParameter,
  updateModuleParameter,
} from '../../../../api/fetchModuleDetails';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {AbsoluteLoader} from '../../../../components/layouts/AbsoluteLoader';
import {moduleSelectedAction} from '../../../../redux/myModulesSection/subscriptions';

const BKioskSection = () => {
  const dispatch = useAppDispatch();
  const {pathname} = useParams();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<IModuleParameter>({
    home_title: '',
    home_desc: '',
    slider_title: '',
    slider_desc: '',
    background: '',
  } as IModuleParameter);
  const brandId = useAppSelector(s => s.brand.brand?.id);
  const subscriptions = useAppSelector(
    s => s.myModulesSection.subscriptions.subscriptions,
  );
  const moduleSelected = useAppSelector(
    s => s.myModulesSection.subscriptions.moduleSelected,
  );

  const navigate = useNavigate();

  const {data, isLoading, refetch} = useQuery(
    ['fetchModule', brandId, pathname],
    () =>
      fetchModuleParameter({
        brandId,
        moduleCode: pathname === 'bkiosk' ? 'b_order' : pathname || '',
      }),
    {
      enabled: !!brandId && pathname === 'bkiosk',
    },
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const {name, value} = e.target;

    if (
      ['background', 'content_background'].includes(name) &&
      'files' in e.target &&
      e.target.files?.[0]
    ) {
      setForm({
        ...form,
        [name]: e.target.files[0],
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    for (const key in form) {
      if (['background', 'content_background'].includes(key)) {
        const file = form[key as keyof typeof form];
        formData.append(
          key,
          (file instanceof File
            ? form[key as 'background' | 'content_background']
            : String(null)) as any,
        );
      } else {
        formData.append(key, String(form[key as keyof typeof form] ?? null));
      }
    }

    setLoading(true);

    const res = await updateModuleParameter({
      brandId,
      moduleCode: 'b_order',
      parameterId: String(form.id),
      data: formData,
    });

    if (res?.status === 200) {
      await refetch();
      helperDisplayToast(res.message, true);
      handleCancel();
    }

    setLoading(false);
  };

  const handleCancel = () => {
    navigate('/online/modules');
  };

  useEffect(() => {
    if (data) {
      setForm({
        id: data?.id,
        brand_id: data?.brand_id,
        home_title: data?.home_title,
        home_desc: data?.home_desc,
        slider_title: data?.slider_title,
        slider_desc: data?.slider_desc,
        background: data?.background,
        out_of_stock_label: data?.out_of_stock_label,
        theme: data?.theme,
        suggested_course_label: data?.suggested_course_label,
        additional_sale_label: data?.additional_sale_label,
        content_background: data?.content_background,
        language_id: data?.language_id || 1,
        color_light: data?.color_light,
        color_dark: data?.color_dark,
        font_id: data?.font_id,
      });
    }
  }, [data]);

  useEffect(() => {
    if (pathname !== 'bkiosk') {
      navigate('/online/modules');
    } else {
      const module = subscriptions?.find(
        (item: any) => item.code === 'b_order',
      );
      dispatch(moduleSelectedAction(module));
    }
  }, [navigate, subscriptions]);

  if (!moduleSelected) return null;

  return (
    <>
      <ModuleHeader
        moduleActiveName={String(pathname)}
        loading={loading}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      <div>
        <BKioskForm form={form} onChange={handleChange} />
      </div>

      {isLoading && <AbsoluteLoader />}
    </>
  );
};

export default BKioskSection;

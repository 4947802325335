/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {useContext, useEffect} from 'react';
import {FieldsRequired} from '../../../../../components/atoms/FieldsRequired';
import {InputLabel} from '../../../../../components/atoms/InputLabel';
import {Title} from '../../../../../components/atoms/Title';
import {DaysContainer} from '../../../../../components/organisms/DaysContainer';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {useDateManage} from '../../../../../hooks/useDateManage';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {
  IUpdatedCardKey,
  newTimingsAction,
  updatedCardKeyAction,
} from '../../../../../redux/myCatalogSection/cards';
import {ToggleButton} from '../../../../../components/atoms/ToggleButton';
import {TextVariant} from '../../../../../components/atoms/TextVariant';
import {
  helperFormatDays,
  helperFormatTimingsToDays,
} from '../../../../../helpers/helperFormatDays';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';

export const ConfigurationCard = () => {
  const {t} = useContext<any>(UtilsContext);
  const dispatch = useAppDispatch();

  const {brandHasAuthorization} = useBrandAccess();

  const updatedCard = useAppSelector(s => s.myCatalogSection.cards.updatedCard);

  const {days, handleAddDay, handleChangeDate, handleRemoveDay, setDays} =
    useDateManage();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    dispatch(updatedCardKeyAction({key: name as IUpdatedCardKey, value}));
  };

  useEffect(() => {
    if (days && !!updatedCard) {
      const data = helperFormatDays(days);
      dispatch(newTimingsAction(data));
    }
  }, [days]);

  useEffect(() => {
    if (!!updatedCard && JSON.stringify(updatedCard) !== JSON.stringify(days)) {
      const newArray = Array.from(days);
      const newDays = helperFormatTimingsToDays(newArray, updatedCard.timings);
      setDays(newDays);
    }
  }, [updatedCard]);

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <div>
      <Title title={t('CONFIGURATION_OF_MAP')} />
      <div className="flex" css={styles.flexGap}>
        <div css={styles.mapInfo}>
          <InputLabel
            value={updatedCard?.label}
            label={t('NAME_OF_MAP')}
            name="label"
            onChange={handleChange}
            required
            disabled={!hasAuthorization}
          />
          <div className="flex-align-center">
            <ToggleButton
              active={!!updatedCard?.hasTimings}
              onToggle={() =>
                dispatch(
                  updatedCardKeyAction({
                    key: 'hasTimings',
                    value: !updatedCard?.hasTimings,
                  }),
                )
              }
              showBorder
              disabled={!hasAuthorization}
            />
            <TextVariant
              variant="textSemiBold"
              style={styles.switchText}
              text={t('SPECIFIC_SCHEDULES')}
            />
          </div>
          <FieldsRequired />
        </div>
        <div
          className="flex"
          css={[styles.flexGap, {marginTop: '15px', width: '100%'}]}>
          {updatedCard?.hasTimings && (
            <DaysContainer
              days={days}
              handleAddDay={handleAddDay}
              handleChangeDate={handleChangeDate}
              handleRemoveDay={handleRemoveDay}
              disabled={!hasAuthorization}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  mapInfo: css({
    width: '38%',
  }),
  flexGap: css({
    gap: '15px',
  }),
  switchText: css({
    marginLeft: '1rem',
  }),
};

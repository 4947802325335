/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../../components/layouts/Header';
import {StatsSalesHeaderTabs} from '../Headers/StatsSalesHeaderTabs';
import {StatsSalesHeaderBottom} from '../Headers/StatsSalesHeaderBottom';
import {GeneralSales} from './Sales';
import {SalesByProduct} from './SalesByProduct';
import {SalesByCategories} from './SalesByCategories';
import {SalesByCards} from './SalesByCards';
import {SalesByHours} from './SalesByHours';
import {M_SCREEN_DESKTOP} from '../../../../../constants/ResponsiveSize';
import {useFlag} from '@unleash/proxy-client-react';
import {FEATURE_FLAGS} from '@bill-app-types/b-types';

export const SalesGeneral = withRedirectAndNavigation(() => {
  //
  const enabled = useFlag(FEATURE_FLAGS.SALES_BY_DOW);
  return (
    <div css={styles.container}>
      <Header
        centerSection={<StatsSalesHeaderTabs />}
        bottomSection={<StatsSalesHeaderBottom />}
      />
      <div css={styles.content}>
        <GeneralSales />
        <SalesByProduct />
        <SalesByCategories />
        <SalesByCards />
        {enabled && <SalesByHours />}
      </div>
    </div>
  );
});

const styles = {
  container: css({
    height: '100vh',
    overflowY: 'auto',
    [M_SCREEN_DESKTOP]: {
      height: 'auto',
    },
  }),
  content: css({
    padding: '1.5rem 2vh',
    overflowY: 'auto',
    [M_SCREEN_DESKTOP]: {
      padding: '1rem 1vh',
    },
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../../../components/atoms/TextVariant';
import {OrderItem} from './OrderItem';
import {helperFormatPrice} from '../../../../../../helpers/helperFormatPrice';
import {
  helperGenerateSqsTaxs,
  helperTaxType,
} from '../../../../../../helpers/helperTax';

export const TicketContent = ({receiptDetails}: {receiptDetails: any}) => {
  const taxs = helperGenerateSqsTaxs(receiptDetails?.sqsReceiptsLineHistory);

  return (
    <div css={styles.scrollView} id="ticketContent">
      <div>
        <TextVariant
          variant="textBold"
          text="DÉTAILS"
          style={styles.subTitle}
        />
        <div css={styles.headerList}>
          <div css={[styles.headCol, {width: '10%'}]}>
            <TextVariant
              text="Qté"
              variant="title03"
              style={styles.headerTitle}
            />
          </div>
          <div css={[styles.headCol, {width: '45%'}]}>
            <TextVariant
              text="Désignation"
              variant="title03"
              style={styles.headerTitle}
            />
          </div>
          <div css={styles.headCol}>
            <TextVariant
              text="P.U"
              variant="title03"
              style={styles.headerTitle}
            />
          </div>
          <div css={[styles.headCol, {width: '10%'}]}>
            <TextVariant
              text="TVA"
              variant="title03"
              style={styles.headerTitle}
            />
          </div>
          <div css={[styles.headCol, {width: '20%'}]}>
            <TextVariant
              text="Total"
              variant="title03"
              style={styles.headerTitle}
            />
          </div>
        </div>
        {receiptDetails?.sqsReceiptsLineHistory?.map(
          (item: any, index: number) => (
            <OrderItem key={index} item={item} />
          ),
        )}
      </div>
      <div>
        <div css={styles.sectionFlex}>
          <TextVariant text={'Total HT'} variant="textRegular" />
          <TextVariant
            text={`${helperFormatPrice(receiptDetails?.amount_ht)}€`}
            variant="textRegular"
          />
        </div>
        <div css={styles.sectionFlex}>
          <TextVariant text={'Total TTC'} variant="textSemiBold" />
          <TextVariant
            text={`${helperFormatPrice(receiptDetails?.amount_ttc)}€`}
            variant="textSemiBold"
          />
        </div>
      </div>
      <div>
        <TextVariant
          variant="textBold"
          text="CODES TVA"
          style={styles.subTitle}
        />
        {taxs?.map((item: any, index: number) => (
          <div key={index} css={styles.transaction}>
            <TextVariant
              text={helperTaxType(item.tax)}
              variant={'title03'}
              style={[styles.title, {width: '10%'}]}
            />

            <TextVariant
              text={`${item.type / 100}%`}
              variant={'title03'}
              style={[styles.title, {width: '75%'}]}
            />

            <TextVariant
              text={`${helperFormatPrice(item.value)}€`}
              variant={'title03'}
              style={[styles.title, {width: '15%', textAlign: 'right'}]}
            />
          </div>
        ))}
      </div>
      <div>
        <TextVariant
          variant="textBold"
          text="ENCAISSEMENTS"
          style={[styles.subTitle, {marginTop: 5}]}
        />
        {receiptDetails?.sqsTransactionsHistory?.map(
          (item: any, index: number) => {
            return (
              <div key={index} css={styles.transaction}>
                <TextVariant
                  text={item?.mode}
                  variant={'title03'}
                  style={[styles.title, {width: '75%'}]}
                />

                <TextVariant
                  text={`${helperFormatPrice(item.amount)}€`}
                  variant={'title03'}
                  style={[styles.title, {width: '15%', textAlign: 'right'}]}
                />
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};

const styles = {
  title: css({
    fontSize: 12,
    fontWeight: '600',
  }),

  subTitle: css({
    paddingLeft: 10,
    marginBottom: 5,
  }),

  transaction: css({
    borderBottom: '1px solid var(--bg-main-color)',
    padding: '10px 10px 10px 20px',
    display: 'flex',
    justifyContent: 'space-between',

    '&:first-of-type': {
      borderTop: '1px solid var(--bg-main-color)',
    },
  }),
  sectionFlex: css({
    padding: '5px 10px 5px 20px',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  headCol: css({
    padding: '5px 15px',
    width: '15%',
  }),
  headerTitle: css({
    fontSize: 10,
    fontWeight: '700',
    color: '#A7A7AB',
  }),
  headerList: css({
    padding: '0px 5px',
    display: 'flex',
    borderBottom: '1px solid var(--bg-main-color)',
  }),
  scrollView: css({
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '90%',
  }),
};

import {SVGProps} from 'react';

export const MoonIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.716 16.736h-.072a7.368 7.368 0 0 1-2.866-.604 7.41 7.41 0 0 1-2.41-1.675 7.596 7.596 0 0 1-2.025-5.03 7.6 7.6 0 0 1 1.838-5.1 7.28 7.28 0 0 1 2.875-1.984.714.714 0 0 1 .762.16.725.725 0 0 1 .173.723 6.117 6.117 0 0 0-.225 3.392 6.09 6.09 0 0 0 1.62 2.983 6.076 6.076 0 0 0 2.97 1.626 6.05 6.05 0 0 0 3.376-.222.714.714 0 0 1 .75.175.725.725 0 0 1 .163.758 7.358 7.358 0 0 1-4.09 4.24c-.901.372-1.866.562-2.84.558Zm-3.48-12.36a5.712 5.712 0 0 0-.984.884 6.132 6.132 0 0 0-1.495 4.123 6.13 6.13 0 0 0 1.639 4.068 5.96 5.96 0 0 0 1.946 1.353c.732.316 1.52.48 2.316.485h.058c.953 0 1.892-.23 2.74-.67a6.005 6.005 0 0 0 2.133-1.856 7.406 7.406 0 0 1-3.356-.336 7.446 7.446 0 0 1-2.866-1.792 7.512 7.512 0 0 1-1.787-2.88 7.552 7.552 0 0 1-.343-3.378Z"
      fill="#3C6AF6"
    />
  </svg>
);

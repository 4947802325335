import {helperLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';

interface IMenuData {
  search: string;
  limit: number;
  offset: number;
  orderBy?: object[];
  isArchived?: boolean | null;
  isOutOfStock?: boolean | null;
}


export const fetchMenus = async (data: IMenuData) => {
  helperLog({message: 'fetchMenus'});
  const query = helperGenerateQuery(data);
  
  const res: any = await axiosInstance
  .get(`/bcatalog/backoffice/menus${query}`, {
    params: { orderBy: data.orderBy, isArchived:data.isArchived, isOutOfStock: data.isOutOfStock}
  })
  .catch(err => {
    helperLog({message: 'fetchMenus', type: 'API_ERROR', data: err});
  });
  
  return res?.data?.data;
};

export const fetchMenusOld = async (data: IMenuData) => {
  helperLog({message: 'fetchMenus'});
  const query = helperGenerateQuery(data);
  
  const res: any = await axiosInstance
  .get(`/bcore/protected/admin/menu/all${query}`)
  .catch(err => {
    helperLog({message: 'fetchMenus', type: 'API_ERROR', data: err});
  });
  
  return res?.data?.data;
};

export const createMenu = async (data: any) => {
  const res: any = await axiosInstance
    .post('/bcatalog/backoffice/menus', data)
    .catch(err => {
      helperLog({message: 'createMenu', type: 'API_ERROR', data: err});
    });

  return res;
};

export const updateMenu = async (menuUuid: string, data: any) => {
  const res: any = await axiosInstance
    .put(`/bcatalog/backoffice/menus/${menuUuid}`, data)
    .catch(err => {
      helperLog({message: 'updateMenu', type: 'API_ERROR', data: err});
    });

  return res;
};

export const duplicateMenu = async (menuUuid: string) => {
  //
  console.log('### duplicateMenu ###');
  const res: any = await axiosInstance
    .put(`/bcatalog/backoffice/menus/${menuUuid}/duplicate`)
    .catch(err => {
      console.log('❌ duplicateMenu : ', err);
    });
  return res;
};

export const fetchMenuDetails = async (menuUuid: string) => {
  helperLog({message: 'fetchMenuDetails'});
  const res: any = await axiosInstance
    .get(`/bcatalog/backoffice/menus/${menuUuid}/informations`)
    .catch(err => {
      helperLog({message: 'fetchMenuDetails', type: 'API_ERROR', data: err});
    });

  return res?.data?.data;
};
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {Center} from './HeaderSections/Center';
import {Bottom} from './HeaderSections/Bottom';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {addPosCategoryProductOld} from '../../../../api/fetchPosCategories';
import {RightBlock} from './RightBlock';
import {ProductsAndMenuCatalogOld} from '../../../../components/organisms/ProductsAndMenuCatalogOld';
import {useAppSelector} from '../../../../hooks/useReduxHook';

export const PosCategories = withRedirectAndNavigation(() => {
  const clientQuery = useQueryClient();
  const categorySelected = useAppSelector(
    s => s.posCategories.categorySelected,
  );
  const categoryProducts = useAppSelector(
    s => s.posCategories.categoryProducts,
  );

  const handleProductClick = async (product: any) => {
    if (!categorySelected)
      return helperDisplayToast('Aucune catégorie sélectionnée');
    const productFinded = categoryProducts?.find(p => p.id === product.id);

    const data = {
      product_id: product.id,
      category_id: categorySelected?.id,
      type: product.type,
    };

    if (!productFinded) {
      const res = await addPosCategoryProductOld(data);
      if (res && res.status === 200) {
        helperDisplayToast('Item ajouté à la catégorie', true);
        clientQuery.invalidateQueries([
          `fetchPosCategoryProducts-${categorySelected?.id}`,
        ]);
      } else {
        helperDisplayToast("Impossible d'ajouter l'item à la catégorie", false);
      }
    }
  };

  const handleIsDisabled = (item: any) => {
    return (
      categoryProducts?.find((catProd: any) => item?.id === catProd.id) || false
    );
  };

  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} bottomSection={<Bottom />} />
      <div css={styles.content}>
        <ProductsAndMenuCatalogOld
          onClickProduct={handleProductClick}
          handleIsDisabled={handleIsDisabled}
        />
        <RightBlock />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: css({
    padding: '2vh',
    height: '87%',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  section: css({
    width: '49%',
    overflow: 'auto',
    transition: 'all 0.3s ease',
  }),
};

import {useState} from 'react';
import {DeleteModal} from './DeleteModal';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {deletePrinterModalAction} from '../../redux/modals';
import {deletePrinter} from '../../api/fetchPrinters';
import {useTranslation} from 'react-i18next';
import {printerSelectedAction} from '../../redux/myRestaurantSection/printers';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';

export const DeletePrinterModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const clientQuery = useQueryClient();

  const printerSelected = useAppSelector(
    s => s.myRestaurantSection.printers.printerSelected,
  );

  const handleCancel = () => {
    dispatch(printerSelectedAction(null));
    dispatch(deletePrinterModalAction(false));
  };
  const handleSubmit = async () => {
    try {
      if (!printerSelected) {
        return;
      }
      setIsLoading(true);
      const res = await deletePrinter(printerSelected.uuid);

      if (res && [200, 204].includes(res.status)) {
        clientQuery.invalidateQueries(['fetchPrinters']);
        helperDisplayToast('Imprimante suprimée', true);
        handleCancel();
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  if (!printerSelected) {
    return null;
  }

  return (
    <DeleteModal
      title={t('DELETE_PRINTER')}
      text={printerSelected?.label}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    />
  );
};

import {Outlet} from 'react-router-dom';
import {SalesGeneral} from './General';
import {Menus} from './Menus';
import {Options} from './Options';
import {Products} from './Products';
import {ProductDetail} from './ProductDetail';
import {MenuDetail} from './MenuDetail';
import {OptionDetail} from './OptionDetail';

export const SalesRouter = {
  path: 'Sales',
  children: [
    {
      path: '',
      element: <SalesGeneral />,
    },
    {
      path: 'products',
      element: (
        <>
          <Outlet />
        </>
      ),
      children: [
        {
          path: '',
          element: <Products />,
        },
        {
          path: 'detail/:id',
          element: <ProductDetail />,
        },
      ],
    },
    {
      path: 'options',
      element: (
        <>
          <Outlet />
        </>
      ),
      children: [
        {
          path: '',
          element: <Options />,
        },
        {
          path: 'detail/:id',
          element: <OptionDetail />,
        },
      ],
    },
    {
      path: 'menus',
      element: (
        <>
          <Outlet />
        </>
      ),
      children: [
        {
          path: '',
          element: <Menus />,
        },
        {
          path: 'detail/:id',
          element: <MenuDetail />,
        },
      ],
    },
  ],
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {
  getBrandAccountingManagement,
  putPaymentMethod,
} from '../../../../api/fetchBrandAccount';
import {THEME} from '../../../../styles/theme';
import {helperDisplayToast} from '../../../../helpers/helperToast';

export const Select = ({item}: {item: any}) => {
  //
  const clientQuery = useQueryClient();

  const {data} = useQuery(['accountingManagement'], () =>
    getBrandAccountingManagement(),
  );

  const handleChange = async (e: any) => {
    const id = e.target.value;
    const accountManagement = data.find(
      (elem: any) => Number(elem.id) === Number(id),
    );

    const res = await putPaymentMethod({
      methodId: item.method_id,
      body: {
        account_management_id: accountManagement?.id || null,
        is_active: item.is_active,
      },
    });

    if (res?.status === 200) {
      helperDisplayToast('Mode de règlement modifié', true);
      clientQuery.invalidateQueries(['getPaymentMethods']);
    } else {
      helperDisplayToast(
        res?.data?.message || 'Impossible de modifier le mode de règlement',
        false,
      );
    }
  };

  return (
    <div css={styles.section}>
      <div css={styles.selectContainer}>
        <select
          css={styles.select}
          name=""
          value={item?.account_management?.id}
          onChange={handleChange}
          className="textSemiBold">
          {item.account_management && (
            <option value={undefined}>Aucun compte</option>
          )}
          {data?.map((accManage: any, index: number) => {
            return (
              <option key={index} value={accManage.id}>
                {`${accManage.label} | ${accManage.code}`}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: {
    padding: '2vh',
    width: '70%',
    alignSelf: 'center',
    margin: 'auto',
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1% 0',
  },
  section: css({
    width: '100%',
  }),
  label: css({
    color: THEME.colors.gray,
    textTransform: 'lowercase',
    margin: '0.5rem 0',
    ':first-letter': {
      textTransform: 'uppercase',
    },
  }),
  selectContainer: css({
    backgroundColor: 'white',
    padding: '0 10px',
    borderRadius: '2px',
  }),
  select: css({
    height: '40px',
    backgroundColor: THEME.colors.white,
    border: 'none',
    borderRadius: '2px',
    width: '100%',
  }),
};

import {SVGProps} from 'react';

export const AdditionalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.67186 0C3.00877 0 3.29881 0.21328 3.36326 0.50807L4.59373 6.24997H13.8515L15.1142 2.08332H13.6874C13.2978 2.08332 12.9843 1.80338 12.9843 1.45833C12.9843 1.11328 13.2978 0.833329 13.6874 0.833329H16.0312C16.2509 0.833329 16.456 0.923693 16.5878 1.07812C16.7226 1.23255 16.7665 1.43307 16.7108 1.62082L15.0702 7.03642C14.9882 7.30986 14.7099 7.49996 14.3905 7.49996H4.86033L5.12986 8.74996H14.1562C14.5458 8.74996 14.8593 9.0286 14.8593 9.37495C14.8593 9.7213 14.5458 9.99995 14.1562 9.99995H4.52048C4.20994 9.99995 3.9199 9.78641 3.85545 9.49214L2.09003 1.24999H0.562496C0.174315 1.24999 -0.140625 0.970047 -0.140625 0.624997C-0.140625 0.279946 0.174315 0 0.562496 0H2.67186ZM3.60936 12.0833C3.60936 11.3932 4.23923 10.8333 5.0156 10.8333C5.79196 10.8333 6.42184 11.3932 6.42184 12.0833C6.42184 12.7734 5.79196 13.3333 5.0156 13.3333C4.23923 13.3333 3.60936 12.7734 3.60936 12.0833ZM14.8593 12.0833C14.8593 12.7734 14.2294 13.3333 13.4531 13.3333C12.6767 13.3333 12.0468 12.7734 12.0468 12.0833C12.0468 11.3932 12.6767 10.8333 13.4531 10.8333C14.2294 10.8333 14.8593 11.3932 14.8593 12.0833ZM6.65621 3.33332C6.65621 2.98696 6.96969 2.70832 7.35934 2.70832H8.53121V1.66666C8.53121 1.32161 8.84468 1.04166 9.23433 1.04166C9.62397 1.04166 9.93745 1.32161 9.93745 1.66666V2.70832H11.1093C11.499 2.70832 11.8124 2.98696 11.8124 3.33332C11.8124 3.67967 11.499 3.95831 11.1093 3.95831H9.93745V4.99997C9.93745 5.34633 9.62397 5.62497 9.23433 5.62497C8.84468 5.62497 8.53121 5.34633 8.53121 4.99997V3.95831H7.35934C6.96969 3.95831 6.65621 3.67967 6.65621 3.33332Z"
      fill="white"
    />
  </svg>
);

import {useQuery} from '@tanstack/react-query';
import {useAppSelector} from './useReduxHook';
import {useDebounce} from './useDebounce';
import {fetchMenus} from '../api/fetchMenus';
import {fetchProducts} from '../api/fetchProducts';
import {getCatalogOptions} from '../api/fetchCatalogOptions';

export const useRuptureGestion = ({
  offset,
  limit,
}: {
  offset: number;
  limit: number;
}) => {
  const search = useAppSelector(s => s.filter.search);
  const orderBy = useAppSelector(s => s.filter.orderBy);
  const isArchived = useAppSelector(s => s.filter.isArchived);
  const isOutOfStock = useAppSelector(s => s.filter.isOutOfStock);
  const debouncedSearchTerm = useDebounce(search, 500);
  const sectionShow = useAppSelector(s => s.app.sectionShow);

  const {data, isLoading} = useQuery(
    [
      'fetchRuptureGestion',
      debouncedSearchTerm,
      offset,
      limit,
      orderBy,
      isArchived,
      isOutOfStock,
      sectionShow,
    ],
    () => {
      const func =
        sectionShow === 'MENUS'
          ? fetchMenus
          : sectionShow === 'PRODUCTS'
          ? fetchProducts
          : getCatalogOptions;

      return func({
        search: debouncedSearchTerm,
        offset,
        limit,
        orderBy,
        isArchived,
        isOutOfStock: isOutOfStock === null ? undefined : !isOutOfStock,
      });
    },
  );

  return {
    data,
    isLoading,
  };
};

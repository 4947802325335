import {useLocation} from 'react-router-dom';
import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {useAppDispatch} from '../../../../../hooks/useReduxHook';
import {
  addPrinterConfigModalAction,
  addPrinterModalAction,
} from '../../../../../redux/modals';

export const CenterSection = () => {
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();

  const isConfigRoute = pathname.split('/').at(-1) === 'network-printers';

  const handlePress = () => {
    if (isConfigRoute) {
      dispatch(addPrinterConfigModalAction(true));
    } else {
      dispatch(addPrinterModalAction(true));
    }
  };

  return (
    <ButtonCustom
      label={
        isConfigRoute ? 'Ajouter une configuration' : 'Ajouter une imprimante'
      }
      onClick={handlePress}
    />
  );
};

import {useContext, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {optionCategoryModalAction} from '../../redux/modals';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {SelectList} from '../molecules/SelectList';
import {helperDisplayToast} from '../../helpers/helperToast';
import {CONSUMPTION_MODE, OPTION_TYPES} from '../../constants/global';
import {useNavigate} from 'react-router-dom';
import {createCatalogOptionCategory} from '../../api/fetchCatalogOptions';
import {useQueryClient} from '@tanstack/react-query';
import {InputLabel} from '../atoms/InputLabel';
import {MultiSelectCustom} from '../atoms/MultiSelectCustom';
import {IModifierCreationPayload} from '@bill-app-types/b-types';

export const AddOptionCategoryModal = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [form, setForm] = useState<IModifierCreationPayload>({
    optionType: 1,
    label: '',
    minimum: 0,
    maximum: 0,
    allowMultiple: false,
    hideOnConsumptionMode: [],
    type: 2,
  });

  const {t, dispatch} = useContext<any>(UtilsContext);
  const navigate = useNavigate();
  const clientQuery = useQueryClient();

  const handleCreateCategory = async (isSave?: boolean) => {
    if (form.optionType && form.label) {
      if (isSave) {
        setSaving(true);
      } else {
        setLoading(true);
      }
      const res = await createCatalogOptionCategory(form);
      if (res?.status === 200) {
        handleOnCancel();
        helperDisplayToast("Catégorie d'option ajoutée", true);
        if (isSave) {
          clientQuery.invalidateQueries(['getCatalogOptionCategories']);
        } else {
          navigate(`/online/catalog/options/${res?.data?.data?.uuid}`);
        }
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible d'ajouter la catégorie d'option",
          false,
        );
      }

      if (isSave) {
        setSaving(false);
      } else {
        setLoading(false);
      }
    } else {
      helperDisplayToast('Tous les champs doivent être complétés ', false);
    }
  };

  const handleOnCancel = async () => {
    dispatch(optionCategoryModalAction(false));
  };

  const handleSave = async () => {
    handleCreateCategory(true);
  };

  const handleSubmit = async () => {
    handleCreateCategory();
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;
    setForm({...form, [name]: value});
  };

  const handleSelectItem = (option: {value: number}) => {
    const index = form.hideOnConsumptionMode.findIndex(
      item => item === option.value,
    );
    if (index > -1) {
      const newArray = [
        ...form.hideOnConsumptionMode.filter(item => item !== option.value),
      ];
      setForm({...form, hideOnConsumptionMode: newArray});
    } else {
      const newArray = [...form.hideOnConsumptionMode, option.value];
      setForm({...form, hideOnConsumptionMode: newArray});
    }
  };

  return (
    <ModalWrapper
      title={t('OPTION_CATEGORY')}
      minWidth="40%"
      minHeight={450}
      validateLabel={t('CONTINUE_CREATION')}
      onCancel={handleOnCancel}
      hideCancelButton
      onSave={handleSave}
      saveLabel={'ENREGISTRER ET CONTINUER PLUS TARD'}
      initialValues={form}
      onSubmit={handleSubmit}
      isSaving={saving}
      isLoading={loading}>
      <SelectList
        label={t('OPTION_CATEGORY_TYPE')}
        name="optionType"
        list={OPTION_TYPES}
        value={form.optionType}
        manageByFormik={false}
        onChange={handleChange}
        required
      />
      <InputLabel
        label={t('OPTION_CATEGORY_NAME')}
        name="label"
        onChange={handleChange}
        value={form.label}
        required
      />
      <MultiSelectCustom
        label="Ne pas afficher"
        options={CONSUMPTION_MODE}
        values={form.hideOnConsumptionMode}
        handleSelectItem={handleSelectItem}
      />
      <FieldsRequired />
    </ModalWrapper>
  );
};

import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';

interface IBody {
  from: Date;
  to: Date;
  limit?: number;
  offset?: number;
  channels?: string;
  search?: string;
  consumptionModes?: string;
  paymentMethods?: string;
  amountMin?: number | null;
  amountMax?: number | null;
  orderBy?: object[];
}

export const getTotalsByTva = async (data: IBody) => {
  //
  const query = helperGenerateQuery(data);

  console.log('### getTotalsByTva ###');
  const res: any = await axiosInstance
    .get(`/pos/backoffice/total-tva${query}`)
    .catch(err => {
      console.log('❌ getTotalsByTva : ', err);
    });
  return res?.data?.data;
};

export const getTotalsByPaymentMethod = async (data: IBody) => {
  //

  const query = helperGenerateQuery(data);
  console.log('### getTotalsByPaymentMethod ###');
  const res: any = await axiosInstance
    .get(`/pos/backoffice/total-reglements${query}`)
    .catch(err => {
      console.log('❌ getTotalsByPaymentMethod : ', err);
    });
  return res?.data?.data;
};

export const getTransactions = async (data: IBody) => {
  //
  const query = helperGenerateQuery(data);

  const res: any = await axiosInstance
    .get(`/pos/backoffice/transactions${query}`, {
      params: {
        orderBy: JSON.stringify(data.orderBy),
      },
    })
    .catch(err => {
      console.log('❌ getTransactions : ', err);
    });
  return res?.data?.data;
};

export const exportTransactionsCsv = async (queryData: {
  createdAtStart: Date;
  createdAtEnd: Date;
  limit?: number;
}) => {
  const query = helperGenerateQuery(queryData);

  const res: any = await axiosInstance
    .get(`/border/backoffice/payment-settings/transactions${query}`)
    .catch(err => {
      console.log('❌ exportTransactionsCsv : ', err);
    });
  return res;
};

export const getExercices = async (data: IBody) => {
  //
  console.log('### getExercices ###');
  const query = helperGenerateQuery(data);

  const res: any = await axiosInstance
    .get(`/pos/backoffice/exercices${query}`)
    .catch(err => {
      console.log('❌ getExercices : ', err);
    });
  return res?.data?.data;
};

export const getExerciceDetails = async (uuid?: string) => {
  //
  console.log('### getExerciceDetails ###');
  const res: any = await axiosInstance
    .get(`/pos/backoffice/exercices/${uuid}`)
    .catch(err => {
      console.log('❌ getExerciceDetails : ', err);
    });
  return res?.data?.data;
};

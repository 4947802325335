/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../../components/layouts/Header';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {Bottom} from './HeaderSections/Bottom';
import {Center} from './HeaderSections/Center';
import {OptionCategoriesSection} from './OptionCategories';
import {OptionsSection} from './OptionItems';

export const Options = withRedirectAndNavigation(() => {
  const showSection = useAppSelector(s => s.app.sectionShow);

  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} bottomSection={<Bottom />} />
      {showSection === 'OPTION_CATEGORY' && <OptionCategoriesSection />}
      {showSection === 'OPTIONS' && <OptionsSection />}
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../../../../../styles/theme';
import {TextVariant} from '../../../../../../components/atoms/TextVariant';
import {PillLabel} from '../../../../../../components/molecules/PillLabel';
import {MetricBox} from '../../../../../../components/molecules/MetricBox';
import {CardLargeIcon} from '../../../../../../assets/svgs/CardIcon';
import {TicketIcon} from '../../../../../../assets/svgs/TicketIcon';
import {useTranslation} from 'react-i18next';
import {MenuCard} from './MenuCard';
import {useQuery} from '@tanstack/react-query';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {useParams} from 'react-router-dom';
import {getStatItemDetailMenusChilds} from '../../../../../../api/fetchStats';
import {helperFormatPrice} from '../../../../../../helpers/helperFormatPrice';
import {helperJoinFilterValues} from '../../../../../../helpers/helperFilter';
import {M_SCREEN_DESKTOP} from '../../../../../../constants/ResponsiveSize';
import {useFlagBCatalog} from '../../../../../../hooks/useFlagBCatalog';

export const Footer = () => {
  const {t} = useTranslation();
  const dates = useAppSelector(s => s.filter.dates);
  const currentMode = useAppSelector(s => s.filter.currentMode);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const channels = useAppSelector(s => s.filter.channels);
  const {id} = useParams();
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {data} = useQuery(
    [
      'getStatItemDetailMenusChilds',
      flagBCatalogEnabled,
      id,
      dates,
      consumptionModes,
      channels,
    ],
    () =>
      getStatItemDetailMenusChilds(String(id), {
        ...dates,
        consumptionModeIds: helperJoinFilterValues(consumptionModes, true),
        channelIds: helperJoinFilterValues(channels, true),
        forStats: !flagBCatalogEnabled,
      }),
    {
      enabled: !!id && flagBCatalogReady,
    },
  );

  const total_ttc =
    data?.reduce(
      (t, sale) =>
        t +
        sale.items?.reduce((_t, item: any) => _t + item?.sales?.TTC?.value, 0),
      0,
    ) || 0;

  const total_ht =
    data?.reduce(
      (t, sale) =>
        t +
        sale.items?.reduce((_t, item: any) => _t + item?.sales?.HT?.value, 0),
      0,
    ) || 0;

  const total_orders = data?.reduce(
    (t, sale) =>
      t + sale.items?.reduce((_t, item: any) => _t + item.orders.value, 0),
    0,
  );

  return (
    <div css={styles.container}>
      <div css={styles.header} className="flex flex-space-between">
        <div>
          <TextVariant
            text="VENTES PRODUITS ASSOCIES PAR ETAPE"
            variant="textSemiBold"
          />
          <div className="flex" css={styles.pills}>
            <PillLabel
              text={`CA ${currentMode}`}
              backgroundColor={THEME.colors.primary}
              variant="textSemiBold"
            />
            <PillLabel
              text="NB DE COMMANDES"
              backgroundColor={THEME.colors.yellowDark}
              variant="textSemiBold"
            />
          </div>
        </div>

        <div css={styles.metrics} className="flex">
          <MetricBox
            label={`CA ${currentMode}`}
            Icon={CardLargeIcon}
            value={helperFormatPrice(
              currentMode === 'TTC' ? total_ttc : total_ht,
            )}
            item="TURNOVER"
            suffix={'€'}
          />

          <MetricBox
            label={t('NUMBER_OF_ORDERS')}
            Icon={TicketIcon}
            value={total_orders}
            item="ORDERS"
            borderColor={THEME.colors.yellowDark}
          />
        </div>
      </div>

      <div css={styles.menus(data?.length)}>
        {data?.map((menu, index) => {
          return <MenuCard key={index} menu={menu} />;
        })}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    padding: '2vh',
    width: '100%',
  }),
  header: css({
    [M_SCREEN_DESKTOP]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  }),
  metrics: css({
    gap: '1rem',
    [M_SCREEN_DESKTOP]: {
      flexDirection: 'column',
      marginTop: '1rem',
    },
  }),
  pills: css({
    marginTop: '0.5rem',
    gap: '0.5rem',
  }),
  menus: (menuLength?: number) =>
    css({
      display: 'flex',
      overflowX: 'auto',
      gap: '1rem',
      marginTop: '2rem',
      padding: '1rem 0',
      justifyContent: Number(menuLength) >= 5 ? 'flex-start' : 'center',
    }),
  menu: css({
    minWidth: '150px',
  }),
};

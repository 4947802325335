import {IFetchAllergensReturn} from '@bill-app-types/b-types';
import {helperJustLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';

export const fetchAllergens = async () => {
  helperJustLog('### fetchAllergens ###');
  const res = await axiosInstance
    .get<{data: IFetchAllergensReturn[]}>('/bcatalog/backoffice/allergens')
    .catch(err => {
      console.log('❌ fetchAllergens : ', err);
    });

  return res?.data?.data;
};

export const getItemAllergens = async (itemUuid: string) => {
  //
  helperJustLog('### getItemAllergens ###');

  const res = await axiosInstance
    .get<{data: IFetchAllergensReturn[]}>(`/bcatalog/backoffice/items/${itemUuid}/allergens`)
    .catch(err => {
      console.log('❌ getItemAllergens : ', err);
    });
  return res?.data?.data;
};

export const createItemAllergen = async (
  itemUuid: string,
  allergenUuid: any,
) => {
  //
  helperJustLog('### createItemAllergen ###');
  const res: any = await axiosInstance
    .post(`/bcatalog/backoffice/items/${itemUuid}/allergens`, {allergenUuid})
    .catch(err => {
      console.log('❌ createItemAllergen : ', err);
    });
  return res;
};

export const deleteItemAllergen = async (
  itemUuid: string | null,
  allergenUuid: string | null,
) => {
  //
  helperJustLog('### deleteItemAllergen ###');
  const res: any = await axiosInstance
    .delete(`/bcatalog/backoffice/items/${itemUuid}/allergens/${allergenUuid}`)
    .catch(err => {
      console.log('❌ deleteItemAllergen : ', err);
    });
  return res;
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import React, {useContext} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {IDay} from '../../hooks/useDateManage';
import {TextVariant} from '../atoms/TextVariant';
import {DateItem} from '../molecules/DateItem';
import {THEME} from '../../styles/theme';

interface IDaysContainer {
  days: IDay[];
  handleChangeDate: (props: {
    dayIndex: number;
    dateIndex: number;
    e: React.ChangeEvent<HTMLInputElement>;
  }) => void;
  handleBlurDate?: (props: {
    dayIndex: number;
    dateIndex: number;
    e: React.ChangeEvent<HTMLInputElement>;
  }) => void;
  handleAddDay?: (dayIndex: number) => void;
  handleRemoveDay?: (dayIndex: number, dateIndex: number) => void;
  width?: string;
  contentMaxHeight?: string;
  showAddMoreButton?: boolean;
  disabled?: boolean;
}

export const DaysContainer = ({
  days,
  handleChangeDate,
  handleAddDay,
  handleRemoveDay,
  handleBlurDate,
  width = '13.8%',
  contentMaxHeight,
  showAddMoreButton = true,
  disabled = false,
}: IDaysContainer) => {
  const {t} = useContext<any>(UtilsContext);

  const renderDays = () =>
    days.map((day, dayIndex) => {
      return (
        <div
          style={{
            width,
          }}
          key={dayIndex}>
          <TextVariant
            text={t(day.name)}
            variant="textSemiBold"
            style={styles.dateName}
          />

          <div
            css={[
              styles.dayContainer,
              {
                maxHeight: contentMaxHeight,
              },
            ]}
            className="hide-scroll-bar">
            {day.dates.map((date, dateIndex) => {
              const isLastIndex = day?.dates?.length - 1 === dateIndex;

              return (
                <DateItem
                  handleChangeDate={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeDate({dayIndex, dateIndex, e})
                  }
                  handleBlurDate={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleBlurDate?.({dayIndex, dateIndex, e})
                  }
                  date={date}
                  key={`${day.name}-${dateIndex}`}
                  isLastIndex={isLastIndex}
                  onClickButton={() =>
                    isLastIndex
                      ? handleAddDay && handleAddDay(dayIndex)
                      : handleRemoveDay &&
                        handleRemoveDay(dayIndex, dateIndex + 1)
                  }
                  showAddMoreButton={showAddMoreButton}
                  disabled={disabled}
                />
              );
            })}
          </div>
        </div>
      );
    });

  return <>{renderDays()}</>;
};

const styles = {
  dayContainer: css({
    overflowY: 'scroll',
    // maxHeight: '230px',
  }),
  dateName: css({
    marginBottom: 5,
    color: THEME.colors.gray,
  }),
};

import {SVGProps} from 'react';

export const LoyaltyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.43631 0.200012C8.73042 0.200012 8.99895 0.349195 9.12682 0.584194L11.3198 4.59878L16.2205 5.24382C16.5082 5.28076 16.7447 5.45978 16.8375 5.70416C16.927 5.95137 16.8534 6.22132 16.6488 6.40034L13.094 9.53177L13.9348 13.9504C13.9827 14.2062 13.8644 14.4676 13.6247 14.6182C13.3881 14.7688 13.0461 14.7887 12.8159 14.6665L8.43631 12.5865L4.03115 14.6665C3.80098 14.7887 3.4877 14.7688 3.24794 14.6182C3.01138 14.4676 2.8931 14.2062 2.91228 13.9504L3.77861 9.53177L0.227303 6.40034C0.0207911 6.22132 -0.0524152 5.95137 0.0383734 5.70416C0.128842 5.45978 0.367642 5.28076 0.655032 5.24382L5.55282 4.59878L7.74581 0.584194C7.87688 0.349167 8.14221 0.200012 8.43631 0.200012ZM8.43631 2.44344L6.75801 5.51945C6.64612 5.72121 6.42874 5.86329 6.17939 5.89738L2.39696 6.39182L5.14363 8.81001C5.31945 8.96629 5.39937 9.19078 5.35782 9.40958L4.71207 12.8081L8.07508 11.2111C8.30205 11.1032 8.57378 11.1032 8.79755 11.2111L12.1606 12.8081L11.5148 9.40958C11.4733 9.19078 11.5564 8.96629 11.7322 8.81001L14.4782 6.39182L10.6964 5.89738C10.4439 5.86329 10.2265 5.72121 10.1178 5.51945L8.43631 2.44344Z"
      fill="white"
    />
  </svg>
);

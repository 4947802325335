import {useContext, useState} from 'react';
import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {getTotalsByPaymentMethod} from '../api/fetchCompta';
import {UtilsContext} from '../contexts/UtilsContext';
import {helperJoinFilterValues} from '../helpers/helperFilter';
import {exportDataAction} from '../redux/exportPdfData';
import {useAppSelector} from './useReduxHook';
import {usePaymentMethods} from './usePaymentMethods';
import {helperFormatPrice} from '../helpers/helperFormatPrice';

export const usePaymentTotal = () => {
  const {dispatch} = useContext<any>(UtilsContext);
  const dates = useAppSelector(s => s.filter.dates);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const channels = useAppSelector(s => s.filter.channels);
  const [totalsByPaymentMethod, setTotalsByPaymentMethod] = useState<any>([]);

  const {paymentMethods, isLoading: paymentMethodsLoading} =
    usePaymentMethods();

  const {data, isLoading, error} = useQuery(
    ['totalPayment', dates, consumptionModes, channels],
    () =>
      getTotalsByPaymentMethod({
        ...dates,
        consumptionModes: helperJoinFilterValues(consumptionModes, true),
        channels: helperJoinFilterValues(channels, true),
      }),
  );

  useEffect(() => {
    if (!paymentMethodsLoading && !isLoading) {
      const newTotalsByPaymentMethod = paymentMethods
        ?.map((item: any) => {
          const elementFinded = data?.totalsByPaymentMethod.find(
            (el: any) => el.paymentMethod?.type === item.type,
          );

          const paymentMethod = {
            code: item.code,
            label: item.label,
            type: item.type,
          };

          const accountManagement = {
            code: item.account_management?.code || '',
          };

          if (elementFinded) {
            return {
              ...elementFinded,
              paymentMethod,
              accountManagement,
            };
          } else {
            return {
              paymentMethod,
              accountManagement,
              totalTTC: 0,
            };
          }
        })
        .sort((a: any, b: any) =>
          a.paymentMethod?.label.localeCompare(b.paymentMethod?.label),
        );

      setTotalsByPaymentMethod(newTotalsByPaymentMethod);
    }
  }, [data, paymentMethods, paymentMethodsLoading, isLoading]);

  useEffect(() => {
    if (data && totalsByPaymentMethod.length > 0) {
      dispatch(
        exportDataAction({
          name: 'paymentTotal',
          values: {
            ...data,
            totalsByPaymentMethod: totalsByPaymentMethod.map((item: any) => {
              return {
                mode: item.paymentMethod?.label,
                code: item.paymentMethod.code,
                account: item.accountManagement.code,
                amount: helperFormatPrice(item.totalTTC, 'de-DE'),
              };
            }),
          },
        }),
      );
    }
  }, [data, totalsByPaymentMethod, dispatch]);

  const paymentTotalLoading = isLoading || paymentMethodsLoading;

  return {
    paymentTotal: paymentTotalLoading
      ? undefined
      : {
          ...data,
          totalsByPaymentMethod: totalsByPaymentMethod || [],
        },
    paymentTotalLoading,
    paymentTotalError: error,
  };
};

/** @jsxImportSource @emotion/react */
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addPosCategoryModalAction} from '../../redux/modals';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {helperDisplayToast} from '../../helpers/helperToast';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {useEffect, useState} from 'react';
import {ColorPickerLabel} from '../atoms/ColorPickerLabel';
import {
  treePreRegisterAction,
  updateModeAction,
  updatedPosCategoryAction,
} from '../../redux/myModulesSection/pos';
import {getStructureDetails} from '../../api/fetchStructures';
import {
  createPosCategory,
  updatePosCategory,
} from '../../api/fetchPosCategories';

export const AddPosCategoryModal = () => {
  const initialValues = {
    img: '',
    label: '',
    price: 0,
  };
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const [color, setColor] = useState<string>('#000000');
  const [parentCategoryUuid, setParentCategoryUuid] = useState(null);

  const updateMode = useAppSelector(
    state => state.myModulesSection.pos.updateMode,
  );
  const updatedPosCategory = useAppSelector(
    state => state.myModulesSection.pos.updatedPosCategory,
  );

  const treePreRegister = useAppSelector(
    s => s.myModulesSection.pos.treePreRegister,
  );

  useEffect(() => {
    if (updateMode && updatedPosCategory) {
      setColor(updatedPosCategory?.color);
      setParentCategoryUuid(updatedPosCategory?.parentCategoryUuid);
    }
  }, [updateMode, updatedPosCategory]);

  const handleOnCancel = () => {
    dispatch(addPosCategoryModalAction(false));
    dispatch(updatedPosCategoryAction(null));
    dispatch(updateModeAction(false));
  };

  const handleSuccess = (res: {data: {message: string}}) => {
    handleOnCancel();
    helperDisplayToast(res?.data?.message || 'Catégorie enregistrée', true);
  };

  const handleRefresh = async () => {
    const data = await getStructureDetails(treePreRegister?.uuid);
    if (data) {
      dispatch(treePreRegisterAction(data));
    }
  };

  const handleSubmit = async (
    data: {
      img: string;
      label: string;
      price: number;
      isArchived: boolean;
      isGroupable: boolean;
    },
    {setSubmitting, resetForm}: any,
  ) => {
    if (data.label) {
      let res;
      if (updateMode) {
        res = await updatePosCategory(updatedPosCategory.uuid, {
          label: data.label,
          parentCategoryUuid:
            parentCategoryUuid === 'Aucun parent' ? null : parentCategoryUuid,
          structureUuid: treePreRegister?.uuid,
          color,
          position: updatedPosCategory.position,
        });
      } else {
        res = await createPosCategory({
          label: data.label,
          parentCategoryUuid:
            parentCategoryUuid === 'Aucun parent' ? null : parentCategoryUuid,
          structureUuid: treePreRegister?.uuid,
          color,
          position: 1,
        });
      }
      if (res && [200, 204].includes(res.status)) {
        handleSuccess(res);
        resetForm();
        handleRefresh();
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible d'enregistrer la catégorie",
          false,
        );
      }
    } else {
      helperDisplayToast('Tous les champs doivent être complétés ', false);
    }
  };

  return (
    <ModalWrapper
      title={updateMode ? 'Modifier la catégorie' : 'Ajouter une catégorie'}
      minWidth="28%"
      validateLabel={'Enregistrer'}
      cancelLabel={t(updateMode ? 'Annuler la modification' : 'CANCEL_CREATE')}
      onCancel={handleOnCancel}
      onSubmit={handleSubmit}
      initialValues={updateMode ? updatedPosCategory : initialValues}>
      <FieldCustomInput label={'Nom de la catégorie'} name="label" required />
      <ColorPickerLabel
        label={t('COLOR')}
        name="color"
        value={color}
        onChange={e => setColor(e.currentTarget?.value)}
      />
      <FieldsRequired />
    </ModalWrapper>
  );
};

import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {useAppDispatch} from '../../../../../hooks/useReduxHook';
import {addPosCategoryModalOldAction} from '../../../../../redux/modals';
import {useTranslation} from 'react-i18next';

export const Center = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const handleOpenModalAddProductCategory = () => {
    dispatch(addPosCategoryModalOldAction(true));
  };

  return (
    <>
      <ButtonCustom
        label={t('ADD_PRODUCT_CATEGORY')}
        onClick={handleOpenModalAddProductCategory}
      />
    </>
  );
};

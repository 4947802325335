export const StatSalesIcon = () => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3576 5.81416H9.96404V1.90881C12.1904 2.27139 13.9497 3.83519 14.3576 5.81416ZM9.96404 7.1427H15.4256C15.7058 7.1427 15.9425 6.94895 15.9425 6.69985C15.9425 3.27609 12.8194 0.5 8.96764 0.5C8.6874 0.5 8.46944 0.710352 8.46944 0.959453V5.81416V7.1427H9.96404ZM5.91926 9.40952L9.85195 12.9052C9.12333 13.1876 8.31998 13.3425 7.47303 13.3425C4.17244 13.3425 1.49461 10.9622 1.49461 8.02839C1.49461 5.71452 3.15736 3.74662 5.48022 3.01592V8.47124C5.48022 8.82275 5.63903 9.16041 5.91926 9.40952ZM6.44549 1.44935C2.8055 1.8922 0 4.67106 0 8.02839C0 11.6957 3.3473 14.6711 7.47303 14.6711C9.08596 14.6711 10.5806 14.2172 11.8012 13.445C12.0409 13.2927 12.0565 12.9883 11.8479 12.8056L6.97483 8.47124V1.87282C6.97483 1.61819 6.73196 1.41337 6.44549 1.44658V1.44935ZM16.3909 8.47124H8.96764L13.9092 12.8637C14.0898 13.0243 14.3825 13.0381 14.5693 12.8831C15.793 11.8673 16.6306 10.489 16.8704 8.94453C16.9109 8.68989 16.6804 8.474 16.3909 8.474V8.47124Z"
        fill="white"
      />
    </svg>
  );
};

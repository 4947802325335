/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@tanstack/react-query';
import {fetchPosCategoriesOld} from '../../../../api/fetchPosCategories';

import {Title} from '../../../../components/atoms/Title';
import {RecursiveCategoriesDropdown} from './RecursiveCategoriesDropdown';
import {useAppSelector} from '../../../../hooks/useReduxHook';

export const RightBlock = () => {
  const {t} = useTranslation();
  const showProducts = useAppSelector(s => s.posCategories.showProducts);

  const {data} = useQuery(['fetchPosCategoriesOld'], fetchPosCategoriesOld);

  return (
    <div css={[styles.container(showProducts)]}>
      <Title title={t('PRODUCTS_CATEGORIES')} />
      <div css={styles.content}>
        {data?.data && (
          <RecursiveCategoriesDropdown
            categories={[...data?.data]?.sort(
              (a: any, b: any) => a?.position - b?.position,
            )}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: (showProducts: boolean) =>
    css({
      width: showProducts ? '49%' : '97%',
    }),
  content: css({
    paddingTop: 10,
    maxHeight: '95%',
    overflow: 'auto',
  }),
};

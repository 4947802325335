/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {MenuIcon} from '../../../../assets/svgs/MenuIcon';
import {useAppDispatch} from '../../../../hooks/useReduxHook';
import {isOpenAction} from '../../../../redux/sidebar';
import {SideBarHeader} from './SideBarHeader';

export const Header = () => {
  const dispatch = useAppDispatch();
  return (
    <div css={styles.container}>
      <button onClick={() => dispatch(isOpenAction(true))} css={styles.btn}>
        <MenuIcon />
      </button>
      <SideBarHeader usingInHeader />
    </div>
  );
};

const styles = {
  container: css(`
    display: none;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding: 0 2%;
      background-color: var(--sidebar-bg-color);
    }

    @media print {
      display: none;
    }
  `),

  btn: {
    backgroundColor: 'transparent',
    border: 'none',
  },
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {THEME} from '../../../../../../styles/theme';
import {TextVariant} from '../../../../../../components/atoms/TextVariant';
import {ValueSeparatorPercent} from '../../../../../../components/atoms/ValueSeparatorPercent';
//import {IStatItemDetailMenusChilds} from '../../../../../../api/fetchStats';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {ImageCustom} from '../../../../../../components/atoms/ImageCustom';
import {useFlagBCatalog} from '../../../../../../hooks/useFlagBCatalog';
import {helperTruncImgUrl} from '../../../../../../helpers/helperTruncImgUrl';

export const MenuProductCard = ({item}: {item: any}) => {
  const currentMode = useAppSelector(s => s.filter.currentMode);
  const {flagBCatalogEnabled} = useFlagBCatalog();

  return (
    <div css={styles.container}>
      <ImageCustom
        src={
          flagBCatalogEnabled
            ? helperTruncImgUrl(item.imgUrl, 'M')
            : item.img_url_set.M
        }
        alt="product"
        styles={{
          width: '30%',
          objectFit: 'cover',
        }}
      />

      <div css={styles.content}>
        <TextVariant
          variant="textSemiBold"
          text={'' + item?.label}
          style={styles.productName}
        />
        <div css={styles.values}>
          <ValueSeparatorPercent
            value={Number(item.sales[currentMode].value)}
            percent={Number(item.sales[currentMode].percent)}
            color={THEME.colors.primary}
            variant="textSemiBold"
            suffix="€"
          />
          <ValueSeparatorPercent
            value={Number(item.orders.value)}
            percent={Number(item.orders.percent)}
            color={THEME.colors.yellowDark}
            variant="textSemiBold"
            formatValue={false}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    height: '4.625rem',
    border: `1px solid ${THEME.colors.lightGray}`,
    marginBottom: '0.5rem',
    borderRadius: '0.13rem',
  }),
  img: css({
    width: '30%',
    objectFit: 'cover',
  }),
  content: css({
    width: '70%',
    padding: '0.5rem 1rem',
  }),
  productName: css({
    textTransform: 'uppercase',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  values: css({
    marginTop: '0.5rem',
  }),
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {Title} from '../../../../../components/atoms/Title';
import {SubMenuCard} from './SubMenuCard';
import {useTranslation} from 'react-i18next';
import {DatagridPagination} from '../../../../../components/newDatagrid/DatagridPagination';
import {useEffect, useState} from 'react';
import {useSubmenu} from '../../../../../hooks/useSubmenu';
import {IModifierGetReturn} from '@bill-app-types/b-types';

export const BlockRight = ({showProducts = false}) => {
  const {t} = useTranslation();

  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [limit] = useState(20);

  const offset = (page - 1) * limit;

  const {data, isLoading} = useSubmenu({offset, limit});

  useEffect(() => {
    setRowCount(oldRowCount =>
      data?.totalModifiers !== undefined ? data?.totalModifiers : oldRowCount,
    );
  }, [data?.totalModifiers]);

  return (
    <div css={styles.container}>
      <Title title={t('SUBMENUS')} />
      <div css={styles.content}>
        {data?.modifiers?.map((submenu: IModifierGetReturn, index: number) => (
          <SubMenuCard
            key={index}
            submenu={submenu}
            showProducts={showProducts}
          />
        ))}
      </div>
      <DatagridPagination
        page={page}
        totalRows={rowCount}
        rowPerPage={limit}
        setPage={setPage}
        isLoading={isLoading}
        paginationShowMore={false}
      />
    </div>
  );
};

const styles = {
  container: css({
    flex: 1,
    // padding: '2vh',
    // height: '87vh',
    display: 'flex',
    flexDirection: 'column',
  }),
  content: css({
    padding: '1.25rem 0',
    overflowY: 'auto',
    height: '100%',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {useQuery} from '@tanstack/react-query';
import {columns} from './columns';
import {getPosTerminals} from '../../../../../api/fetchPosTerminals';

export const TerminalsSection = () => {
  const {data, isLoading} = useQuery(['getPosTerminals'], getPosTerminals);

  return (
    <div css={styles.container}>
      <NewDataGrid
        rows={data}
        columns={columns}
        loading={isLoading}
        rowHeight={60}
      />
    </div>
  );
};

const styles = {
  container: css({
    height: '100%',
  }),
};

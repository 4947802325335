import {PayloadAction, createSlice} from '@reduxjs/toolkit';

interface ISlice {
  totalTva: any;
  paymentTotal: any;
}

const initialState: ISlice = {
  totalTva: [],
  paymentTotal: [],
};

const slice = createSlice({
  name: 'exportPdfdata',
  initialState,
  reducers: {
    exportDataAction: (
      state,
      action: PayloadAction<{
        name: keyof ISlice;
        values: ISlice[keyof ISlice];
      }>,
    ) => {
      const {name, values} = action.payload;
      state[name] = values;
    },
  },
});

export const {exportDataAction} = slice.actions;

export default slice.reducer;

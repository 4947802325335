/** @jsxImportSource @emotion/react */
import {ButtonCustom} from '../atoms/ButtonCustom';
import {DateTypeFilter} from '../atoms/DateTypeFilter';
import {SortFilterDate} from '../atoms/SortFilterDate';
import ReactDatePicker, {registerLocale} from 'react-datepicker';
import {TextVariant} from '../atoms/TextVariant';
import {css} from '@emotion/react';
import Fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {PeriodDate} from '../atoms/PeriodDate';
import {
  IFilterSlice,
  filterBirthdateAction,
  filterComparesDateAction,
  filterCreatedOrderDateAction,
  filterDateAction,
  filterLastOrderDateAction,
} from '../../redux/filter';
import {useTranslation} from 'react-i18next';
import {THEME} from '../../styles/theme';
import {DateAndHour} from '../atoms/DateAndHour';
import {useEffect, useRef} from 'react';
import {
  fromDateAction,
  fromTimeAction,
  sortFilterDateAction,
  toDateAction,
  toTimeAction,
} from '../../redux/date';
import {DATE_FILTER_TYPES} from '../../constants/global';
import store from '../../redux/storeConfig';
import {helperFilterDate} from '../../helpers/helperFilterDate';

registerLocale('fr', Fr);

type TInitType = keyof Pick<
  IFilterSlice,
  'dates' | 'createdCustomerDates' | 'lastOrderDates' | 'birthdate'
>;

// TODO : feat/BFRONT-473-filtre-de-type-date https://bill-app.atlassian.net/browse/BFRONT-473?atlOrigin=eyJpIjoiYTVlNTk0ZTIzZDRjNDI0Y2FmZjg1NTMwYjlkYzA2YjAiLCJwIjoiaiJ9
//INFO : state => typeFilterDate : null equal between period after
//INFO : state => sortFilterDate : null asc desc
export const DateFilter = ({
  showSortFilter,
  disabledFilterTypes = [],
  initType,
  handleClose,
}: {
  handleClose: () => void;
  showSortFilter?: boolean;
  disabledFilterTypes?: string[];
  initType: TInitType;
}) => {
  //
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const typeFilterDate = useAppSelector(s => s.date.typeFilterDate);

  const fromDateRef = useRef<ReactDatePicker | null>(null);
  const toDateRef = useRef<ReactDatePicker | null>(null);

  const handleSubmit = () => {
    //TODO : dispatch
    const fromDate = store.getState().date.fromDate;

    const fromTime = store.getState().date.fromTime;

    const toDate = store.getState().date.toDate;

    const toTime = store.getState().date.toTime;

    const periodType = store.getState().date.periodType;

    const sortFilterDate = store.getState().date.sortFilterDate;

    const isCompare = store.getState().filter.isCompare;

    const filterDate = helperFilterDate({
      fromDate,
      fromTime,
      toDate,
      toTime,
      periodType,
      typeFilterDate,
      sortFilterDate,
    });

    switch (initType) {
      case 'dates':
        dispatch(filterDateAction(filterDate));
        break;
      case 'createdCustomerDates':
        dispatch(filterCreatedOrderDateAction(filterDate));
        break;
      case 'birthdate':
        dispatch(filterBirthdateAction(filterDate));
        break;

      case 'lastOrderDates':
        dispatch(filterLastOrderDateAction(filterDate));
        break;
      default:
        break;
    }

    if (isCompare && initType === 'dates') {
      const comparefromDate = store.getState().filter.compareDates.from;
      const comparetoDate = store.getState().filter.compareDates.to;

      if (fromDate) {
        const {from, to} = helperFilterDate({
          fromDate: comparefromDate,
          fromTime: comparetoDate,
          toDate,
          toTime,
          periodType,
          typeFilterDate,
          sortFilterDate,
          filterFromDate: fromDate,
          filterToDate: toDate,
          forCompare: true,
        });

        dispatch(
          filterComparesDateAction({
            from,
            to,
          }),
        );
      }
    }

    handleClose();
  };

  const listFiltered = DATE_FILTER_TYPES.filter(item => {
    if (disabledFilterTypes.includes(item.value)) {
      return false;
    }

    return true;
  });

  return (
    <div css={styles.container}>
      <InitialDateFilter initType={initType} />
      <SortFilterDate label={'Date et Heure'} showSortFilter={showSortFilter} />
      <DateTypeFilter list={listFiltered} type="date" />

      {typeFilterDate && typeFilterDate !== 'period' && (
        <>
          <DateAndHour
            dateType={typeFilterDate === 'before' ? 'to' : 'from'}
            fromRef={fromDateRef}
            toRef={typeFilterDate === 'between' ? toDateRef : undefined}
          />
          {typeFilterDate === 'between' && (
            <>
              <TextVariant
                text="Et le"
                variant="textSemiBold"
                style={{
                  margin: '0.5rem 0',
                }}
              />
              <DateAndHour
                dateType="to"
                toRef={toDateRef}
                fromRef={fromDateRef}
              />
            </>
          )}
        </>
      )}

      {typeFilterDate === 'period' && <PeriodDate />}
      <ButtonCustom
        label={t('APPLY')}
        width={'100%'}
        marginTop={'0.5rem'}
        onClick={handleSubmit}
      />
    </div>
  );
};

const InitialDateFilter = ({initType}: {initType: TInitType}) => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(s => s.filter[initType]);

  useEffect(() => {
    if (!dates) return;

    if (dates.from) {
      dispatch(fromDateAction(dates.from));
      dispatch(fromTimeAction(dates.from));
    }

    if (dates.to) {
      dispatch(toDateAction(dates.to));
      dispatch(toTimeAction(dates.to));
    }

    dispatch(sortFilterDateAction(dates.sort));
  }, [dates.from, dates.to, dispatch]);

  return null;
};

const styles = {
  container: css({
    minWidth: 240,
    maxWidth: 284,
    backgroundColor: THEME.colors.white,
    left: 0,
    top: 0,
    padding: 12,
    borderRadius: 2,
    margin: 'auto',
  }),
};

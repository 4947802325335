/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {
  addModuleSlider,
  deleteModuleSlider,
  fetchModuleSliders,
} from '../../../../api/fetchModuleDetails';
import {memo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@tanstack/react-query';
import {PictureResolution} from '../../../../components/atoms/PictureResolution';
import {CrossIconContainer} from '../../../../components/atoms/CrossIconContainer';
import {MoreSquareIcon} from '../../../../assets/svgs/MoreSquareIcon';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {AbsoluteLoader} from '../../../../components/layouts/AbsoluteLoader';

export const Sliders = memo(
  ({brandId, id}: {brandId?: string | number; id: string}) => {
    const {t} = useTranslation();
    const [sliderLoading, setSliderLoading] = useState(false);

    const {data: sliders, refetch: refetchSliders} = useQuery(
      ['fetchBOrder-sliders', brandId, id],
      () =>
        fetchModuleSliders({
          moduleCode: 'b_order',
          brandId: String(brandId),
          parameterId: id,
        }),
      {
        enabled: !!brandId && !!id,
      },
    );

    const handleUploadSliders = async (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const {files} = e.target;

      setSliderLoading(true);

      if (files) {
        for (let i = 0; i < files.length; i++) {
          const formData = new FormData();
          formData.append('image', files[i]);
          formData.append('b_webapp_parameter_id', '0');
          formData.append('b_dedicated_parameter_id', '0');
          formData.append('b_menu_parameter_id', '0');
          formData.append('b_order_parameter_id', String(id));
          formData.append('position', String(Number(sliders?.length) + 1));

          const res = await addModuleSlider(String(brandId), formData);

          if (res?.status === 200) {
            helperDisplayToast(res.message, true);
          }
        }

        await refetchSliders();
      }

      e.target.value = '';

      setSliderLoading(false);
    };

    const handleRemoveSlider = async (id: string) => {
      setSliderLoading(true);
      const res = await deleteModuleSlider(String(brandId), id);
      if (res?.status === 200) {
        helperDisplayToast(res.message, true);
      }
      await refetchSliders();
      setSliderLoading(false);
    };

    return (
      <div css={styles.container}>
        <div>
          <p className="textLabel">{t('HOME_SCREEN_SLIDER')}</p>

          {sliders && (
            <div css={styles.images} className="hide-scroll-bar">
              {sliders?.map(slider => {
                return (
                  <div css={styles.slider} key={slider.id}>
                    <img src={slider.image} alt="background" css={styles.img} />
                    <CrossIconContainer
                      onClick={() => handleRemoveSlider(slider.id)}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <label
            htmlFor="sliderImage"
            css={styles.sliderContentImage}
            className="flex-center">
            <PictureResolution
              height={500}
              width={1920}
              Icon={MoreSquareIcon}
            />
          </label>
          <input
            type="file"
            hidden
            id="sliderImage"
            onChange={handleUploadSliders}
            name="slide_image"
            accept="image/*"
            multiple
          />
        </div>
        {sliderLoading && <AbsoluteLoader />}
      </div>
    );
  },
);

const styles = {
  container: css({
    width: '100%',
  }),

  img: css({
    width: 270,
    height: '100%',
    objectFit: 'contain',
    borderRadius: 5,
  }),

  images: css({
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '1rem',
    height: 100,
    marginBottom: '1rem',
    overflowX: 'auto',
    overflowY: 'hidden',
  }),

  sliderContentImage: css({
    width: '100%',
    height: 150,
    backgroundColor: 'white',
    marginBottom: 10,
  }),

  slider: css({
    position: 'relative',
    '&:hover': {
      '.icon': {
        opacity: 1,
        top: -10,
      },
    },
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {BrandsSelect} from './BrandsSelect';
import {THEME} from '../../../../styles/theme';
import {Version} from '../../../atoms/Version';

export const SideBarHeader = ({usingInHeader = false}) => {
  const isOpen = useAppSelector(s => s.sidebar.isOpen);
  return (
    <div css={styles.navBarHeader(usingInHeader)}>
      <div css={styles.navBarHeaderTop}>
        <img
          src="/images/billLogo.svg"
          alt="bill logo"
          css={[styles.navBarLogo, {marginTop: isOpen ? 0 : 7}]}
        />
        {isOpen && (
          <div style={{flex: 1}}>
            <p className="title03" css={styles.navHeaderTitle}>
              Backoffice
            </p>
            <Version color={THEME.colors.white} />
          </div>
        )}
      </div>
      {isOpen && <BrandsSelect />}
    </div>
  );
};

const styles = {
  navBarHeader: (usingInHeader: boolean) =>
    css(`
    aling-items: center;
    padding:${usingInHeader ? '0' : '0 20px'};
    color: white;
    padding-top: ${usingInHeader ? '0' : '10px'};
    padding-left:${usingInHeader ? '0' : '15px'};
    overflow-x: hidden;

    @media print {
      display: none;
    }
  `),
  navBarHeaderTop: css({
    display: 'flex',
    alignItems: 'center',
    gap: 7,
  }),
  navBarLogo: css({
    marginRight: '5px',
    height: '20px',
  }),

  navHeaderTitle: css({
    color: 'var(--sidebar-color)',
    fontWeight: '700',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  }),
  version: css({
    fontSize: '10px',
    fontFamily: 'Montserrat-Medium',
    color: THEME.colors.white,
    textDecoration: 'underline',
  }),
};

/** @jsxImportSource @emotion/react */
import {Outlet, RouteObject} from 'react-router-dom';
import {Printers} from './Printers';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {CenterSection} from './Headers/CenterSection';
import {BottomSection} from './Headers/BottomSection';
import {Configurations} from './Configurations';
import {brandHasAuthorizationForRouting} from '../../../../helpers/helperFrontPolicies';
import {BRAND_AUTHORIZATION} from '../../../../constants/frontPolicies';

const Root = withRedirectAndNavigation(() => {
  return (
    <>
      <Header
        centerSection={<CenterSection />}
        bottomSection={<BottomSection />}
      />
      <div style={styles.container}>
        <Outlet />
      </div>
    </>
  );
});

export const NetworkPrinterRouter: RouteObject = {
  path: 'network-printers',
  loader: () => brandHasAuthorizationForRouting(BRAND_AUTHORIZATION.CHILD),
  element: <Root />,
  children: [
    {
      path: '',
      element: <Configurations />,
    },
    {
      path: 'printers',
      element: <Printers />,
    },
  ],
};

const styles = {
  container: {
    padding: '0 2vh',
    paddingTop: '2rem',
    height: '84vh',
  },
};

import {ICustomer} from '@bill-app-types/b-types';
import {helperDate} from '../../../../helpers/helperDate';
import {helperFormatPriceWithoutDivide} from '../../../../helpers/helperFormatPrice';
import {THEME} from '../../../../styles/theme';
import {helperFormatNumber} from '../../../../helpers/helperFormatNumber';
import dayjs from 'dayjs';
import {helperFormatFrToEnDate} from '../../../../helpers/helperFormatFrToEnDate';

export const columns = [
  {
    headerName: 'Nom',
    flex: 1,
    valueGetter: (row: ICustomer) => {
      return row?.lastname;
    },
  },
  {
    headerName: 'Prénom',
    flex: 1,
    valueGetter: (row: ICustomer) => {
      return row?.firstname;
    },
  },
  {
    headerName: 'Date de naissance',
    flex: 1,
    valueGetter: (row: ICustomer) => {
      return row?.birthdate ? dayjs(row?.birthdate).format('DD/MM/YYYY') : '--';
    },
  },
  {
    headerName: 'Code postal',
    flex: 1,
    valueGetter: (row: ICustomer) => {
      return row?.billingAddressPostCode;
    },
  },
  {
    headerName: 'Commandes',
    flex: 1,
    valueGetter: (row: ICustomer) => {
      return (
        <span style={{color: row?.isActive ? '#F7C761' : '#A7A7A7'}}>
          {row?.isActive ? helperFormatNumber(row?.nbOrder) : '--'}
        </span>
      );
    },
  },
  {
    headerName: 'Dernière commande',
    flex: 1,
    valueGetter: (row: ICustomer) => {
      return row?.lastOrderDate
        ? `${helperDate(
            helperFormatFrToEnDate(row?.lastOrderDate),
            'Le DD/MM/YYYY à HH:mm',
          )}`
        : '--';
    },
  },
  {
    headerName: 'Points de fidélité',
    flex: 1,
    valueGetter: (row: ICustomer) => {
      return (
        <span style={{color: row?.isActive ? THEME.colors.yellow : '#A7A7A7'}}>
          {row?.isActive ? helperFormatNumber(row?.loyaltyBalance) : '--'}
        </span>
      );
    },
  },
  {
    headerName: 'Total dépensé',
    flex: 1,
    renderCell: (row: ICustomer) => {
      return <OrderTotalSpent row={row} />;
    },
  },
  {
    headerName: 'Panier moyen',
    flex: 1,
    valueGetter: (row: ICustomer) => {
      return <OrderAverageBasket row={row} />;
    },
  },
  {
    headerName: 'Création du compte',
    flex: 1,
    valueGetter: (row: ICustomer) => {
      return `${helperDate(
        helperFormatFrToEnDate(row?.createdAt),
        'Le DD/MM/YYYY à HH:mm',
      )}`;
    },
  },
];

const OrderTotalSpent = ({row}: {row: ICustomer}) => {
  return (
    <span style={{color: row?.isActive ? THEME.colors.primary : '#A7A7A7'}}>
      {row?.isActive
        ? `${helperFormatPriceWithoutDivide(Number(row?.totalSpent))}€`
        : '--'}
    </span>
  );
};

const OrderAverageBasket = ({row}: {row: ICustomer}) => {
  const averageBasket = (Number(row?.totalSpent) || 0) / (row?.nbOrder || 0);

  const averageBasketFormated = helperFormatPriceWithoutDivide(averageBasket);
  return (
    <span style={{color: row?.isActive ? THEME.colors.green : '#A7A7A7'}}>
      {row?.isActive ? `${averageBasketFormated}€` : '--'}
    </span>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {Center} from './HeaderSections/Center';
import {LeftBlock} from './LeftBlock';
import {RightBlock} from './RightBlock';

export const ProductionArea = withRedirectAndNavigation(() => {
  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} />
      <div css={styles.content}>
        <div css={styles.main}>
          <LeftBlock />
          <RightBlock />
        </div>
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: 'calc(96vh - 20px)',
  }),
  content: css({
    padding: '2vh',
    height: '90%',
  }),
  main: css({
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '100%',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import React from 'react';
import {PlusIcon} from '../../assets/svgs/PlusIcon';
import {TrashIcon} from '../../assets/svgs/TrashIcon';
import {THEME} from '../../styles/theme';

export const MiniButton = ({
  onClick,
  defaultBgColor = THEME.colors.primary,
  isRemove = false,
  Icon = TrashIcon,
  size = 'small',
  marginBottom,
  marginTop,
  disabled = false,
}: {
  onClick: () => void;
  defaultBgColor?: string;
  isRemove?: boolean;
  Icon?: React.FC<{color?: string}>;
  size?: 'small' | 'large';
  marginBottom?: number | string;
  marginTop?: number | string;
  disabled?: boolean;
}) => {
  return (
    <button
      disabled={disabled}
      css={styles.btnAdd({
        isRemove,
        defaultBgColor,
        size,
        marginBottom,
        marginTop,
        disabled,
      })}
      className="flex-center"
      onClick={onClick}
      type="button">
      {isRemove ? <Icon color="white" /> : <PlusIcon />}
    </button>
  );
};

const styles = {
  btnAdd: ({
    isRemove,
    defaultBgColor,
    size,
    marginBottom,
    marginTop,
    disabled,
  }: {
    isRemove: boolean;
    defaultBgColor: string;
    size: 'small' | 'large';
    marginBottom?: number | string;
    marginTop?: number | string;
    disabled: boolean;
  }) =>
    css({
      border: 'none',
      backgroundColor: isRemove ? THEME.colors['red'] : defaultBgColor,
      width: size === 'small' ? 25 : 35,
      height: size === 'small' ? 25 : 35,
      borderRadius: 2,
      cursor: 'pointer',
      marginBottom,
      marginTop,
      opacity: disabled ? 0.5 : 1,
    }),
};

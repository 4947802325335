export const ITEM_TYPES = {
  MENU: 1,
  PRODUCT: 2,
  OPTION: 3,
  OPTION_ITEM: 4,
};

export const DEFAULT_CONNECTED_SCREEN = '/online/accounting/parameters';
export const PARENT_DEFAULT_SCREEN = '/online/restaurant/informations';
export const CHILD_DEFAULT_SCREEN = '/online/dashboard/sales';

export const MODIFIER_TYPES = [
  {
    value: 1,
    label: 'Sous menu',
  },
  {
    value: 2,
    label: 'Option',
  },
];

export const OPTION_TYPES = [
  {
    value: 1,
    label: 'Composition',
  },
  {
    value: 2,
    label: 'Supplément',
  },
  {
    value: 3,
    label: 'Retrait',
  },
];

export const ITEM_TYPES_MEAL = [
  {
    value: 2,
    label: 'Produit',
  },
  {
    value: 1,
    label: 'Menu',
  },
];

export const TAXS = [
  {
    label: '5.5%',
    value: 5.5,
  },
  {
    label: '10%',
    value: 10,
  },
  {
    label: '20%',
    value: 20,
  },
];

export const STATS_COLORS = [
  '#3C6AF6',
  '#0A9449',
  '#9747FF',
  '#F7C761',
  '#1C49D3',
  '#61D295',
  '#EC5174',
  '#6D5C60',
  '#E229B9',
  '#3C6AF6',
];

export const BASE_HOUR = new Date(
  'Tue Jun 06 2023 23:59:00 GMT+0200 (heure d’été d’Europe centrale)',
);

export const CONSUMPTION_MODE = [
  {
    value: 1,
    label: 'Sur place',
  },
  {
    value: 2,
    label: 'À emporter',
  },
  {
    value: 3,
    label: 'En livraison',
  },
];

export const DATE_FILTER_TYPES = [
  {
    label: 'Égal à',
    value: 'equal',
  },
  {
    label: 'Entre le',
    value: 'between',
  },
  {
    label: 'Avant le',
    value: 'before',
  },
  {
    label: 'Après le',
    value: 'after',
  },
  {
    label: 'Par période',
    value: 'period',
  },
];

export const INIT_DAYS = [
  {
    name: 'MONDAY',
    dates: [{id: 1, start: '', end: ''}],
  },
  {
    name: 'TUESDAY',
    dates: [{id: 2, start: '', end: ''}],
  },
  {
    name: 'WEDNESDAY',
    dates: [{id: 3, start: '', end: ''}],
  },
  {
    name: 'THURSDAY',
    dates: [{id: 4, start: '', end: ''}],
  },
  {
    name: 'FRIDAY',
    dates: [{id: 5, start: '', end: ''}],
  },
  {
    name: 'SATURDAY',
    dates: [{id: 6, start: '', end: ''}],
  },
  {
    name: 'SUNDAY',
    dates: [{id: 0, start: '', end: ''}],
  },
];

export const DURATION_OF_SLOTS_LIST = [
  {
    label: '0 min',
    value: 0,
  },
  {
    label: '5 min',
    value: 5,
  },
  {
    label: '10 min',
    value: 10,
  },
  {
    label: '15 min',
    value: 15,
  },
  {
    label: '20 min',
    value: 20,
  },
  {
    label: '30 min',
    value: 30,
  },
  {
    label: '60 min',
    value: 60,
  },
];

export const REDIRECT_URL = {
  restaurant: {
    'production-areas': '/pages/restaurant/production-area',
  },
  catalog: {
    tree: '/pages/catering/categories',
    products: '/pages/catering/products',
    options: '/pages/catering/options',
    menus: '/pages/catering/menu-management',
    cards: '/pages/catering/cards',
    recipes: '/pages/catering/recipes',
  },
  orders: {
    'in-progress': '/pages/order/in-progress',
    'in-coming': '/pages/order/up-next',
    history: '/pages/order/history',
    'not-completed': '/pages/order/not-completed',
  },
  offers: {
    events: '/pages/promotion/event',
    loyalty: '/pages/promotion/loyalty',
    additional: '/pages/promotion/additional-sales',
  },
  translations: {
    languages: '/pages/translations/language-management',
    translations: '/pages/translations/translation-management',
  },
};

import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {ISuggestCategory} from '../redux/myOffersSection/categories';
import {axiosInstance} from './axiosInstance';

export const createSuggestionCategory = async (
  data: Partial<ISuggestCategory> & {label?: string},
) => {
  const res = await axiosInstance.post<{
    data: ISuggestCategory;
  }>('/bcatalog/backoffice/suggestion-categories', data);

  return res;
};

export const updateSuggestionCategory = async (
  uuid: string,
  data: Partial<ISuggestCategory> & {label?: string},
) => {
  const res = await axiosInstance.put<{
    data: ISuggestCategory;
  }>('/bcatalog/backoffice/suggestion-categories/' + uuid, data);

  return res;
};

export const updateSuggestionItemPosition = async ({
  categoryUuid,
  itemUuid,
  position,
}: {
  categoryUuid: string;
  itemUuid: string;
  position: number;
}) => {
  const res = await axiosInstance.put<{
    data: ISuggestCategory;
  }>(
    `bcatalog/backoffice/suggestion-categories/${categoryUuid}/items/${itemUuid}`,
    {
      position,
    },
  );
  return res;
};

export const associateProductToSuggestionCategory = async (
  categoryUuid: string,
  data: {
    itemUuid: string;
    position: number;
  },
) => {
  const res = await axiosInstance.post<{
    data: ISuggestCategory;
  }>(`/bcatalog/backoffice/suggestion-categories/${categoryUuid}/items`, data);

  return res;
};

export const dissociateProductToSuggestionCategory = async (
  categoryUuid: string,
  itemUuid: string,
) => {
  const res = await axiosInstance.delete<{
    data: ISuggestCategory;
  }>(
    `/bcatalog/backoffice/suggestion-categories/${categoryUuid}/items/${itemUuid}`,
  );

  return res;
};

export const fetchSuggestionCategories = async (
  data: Partial<{
    search: string;
    type: number;
  }> = {},
) => {
  const q = helperGenerateQuery(data);

  const res = await axiosInstance.get<{
    data: ISuggestCategory[];
  }>(`/bcatalog/backoffice/suggestion-categories${q}`);

  return res.data?.data;
};

export const fetchSuggestionCategoriesDetail = async (uuid: string) => {
  const res = await axiosInstance.get<{
    data: ISuggestCategory;
  }>(`/bcatalog/backoffice/suggestion-categories/${uuid}`);

  return res.data?.data;
};

export const deleteSuggestionCategory = async (uuid: string) => {
  const res = await axiosInstance.delete<{
    data: ISuggestCategory;
  }>(`/bcatalog/backoffice/suggestion-categories/${uuid}`);

  return res;
};

/** @jsxImportSource @emotion/react */
import {useTranslation} from 'react-i18next';
import {FilterButton} from '../../../../../components/atoms/FilterButton';
import {AmountMaxFilter} from '../../../../../components/filters/AmountMaxFilter';
import {OrderNumberFilter} from '../../../../../components/filters/OrderNumberFilter';
import {PointsFreeFilter} from '../../../../../components/filters/PointsFreeFilter';
import {SearchFilter} from '../../../../../components/filters/SearchFilter';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {css} from '@emotion/react';
import {ToggleButton} from '../../../../../components/atoms/ToggleButton';
import {customerActiveAction} from '../../../../../redux/filter';

export const Bottom = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const createdCustomerDates = useAppSelector(
    s => s.filter.createdCustomerDates,
  );
  const customerActive = useAppSelector(s => s.filter.customerActive);
  const lastOrderDates = useAppSelector(s => s.filter.lastOrderDates);
  const birthdateFilter = useAppSelector(s => s.filter.birthdate);

  const handleToggleCustomerActive = () => {
    dispatch(customerActiveAction(!customerActive));
  };

  return (
    <div css={styles.container}>
      <SearchFilter />
      <ToggleButton
        showBorder
        active={customerActive}
        onToggle={handleToggleCustomerActive}
      />
      <AmountMaxFilter />
      <OrderNumberFilter />
      <PointsFreeFilter />

      <FilterButton
        icon={'calendar'}
        label={t('CREATED_DATE')}
        valueType="createdCustomerDates"
        values={createdCustomerDates}
        hideFilterIcon
      />
      <FilterButton
        label={t('LAST_ORDER')}
        icon={'calendar'}
        valueType="lastOrderDates"
        values={lastOrderDates}
        hideFilterIcon
      />
      <FilterButton
        label={t('BIRTHDATE')}
        valueType="birthdate"
        values={birthdateFilter}
      />
    </div> 
  );
};

const styles = {
  container: css({
    display: 'flex',
    gap: '1vh',
  }),
};

/** @jsxImportSource @emotion/react */
//import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {HeaderTabsLinks} from '../../../../../components/molecules/HeaderTabsLinks';
// import {useTranslation} from 'react-i18next';
// import {exportSalesRecap} from '../../../../../api/fetchStats';
// import {useAppSelector} from '../../../../../hooks/useReduxHook';
// import {helperDate} from '../../../../../helpers/helperDate';
// import {helperDisplayToast} from '../../../../../helpers/helperToast';

const data = [
  {label: 'Général', link: ''},
  {label: 'Produits', link: '/products'},
  {label: 'Options', link: '/options'},
  {label: 'Menus', link: '/menus'},
];

export const StatsSalesHeaderTabs = ({
  lengthToGetRootLink,
}: {
  lengthToGetRootLink?: number;
}) => {
  // const {t} = useTranslation();
  // const brand = useAppSelector(s => s.brand.brand);
  // const dates = useAppSelector(state => state.filter.dates);

  // const handleExport = async () => {
  //   const res = await exportSalesRecap({
  //     from: helperDate(dates.from, 'YYYY-MM-DD HH:mm:ss', true),
  //     to: helperDate(dates.to, 'YYYY-MM-DD HH:mm:ss', true),
  //     brandId: brand?.id,
  //   });
  //   if (res.status !== 200) {
  //     helperDisplayToast("Impossible d'exporter les données", false);
  //   }
  // };

  return (
    <div css={styles.container} className="flex-space-between">
      <HeaderTabsLinks
        data={data}
        path="stats/sales"
        lengthToGetRootLink={lengthToGetRootLink}
      />
      {/* <ButtonCustom onClick={handleExport} label={t('EXPORT_DATA')} /> */}
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
  },
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext, useEffect} from 'react';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {UtilsContext} from '../../contexts/UtilsContext';
import {addCustomerLoyaltiesModalAction} from '../../redux/modals';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {NewDataGrid} from '../newDatagrid/NewDataGrid';
import {useAppSelector} from '../../hooks/useReduxHook';
import {THEME} from '../../styles/theme';
import {
  checkedCustomersAction,
  multiSelectionAction,
} from '../../redux/customer';
import {MinusIcon} from '../../assets/svgs/MinusIcon';
import {helperDisplayToast} from '../../helpers/helperToast';
// import {FieldCheckbox} from '../atoms/FieldCheckbox';
import {updateCustomersBalance} from '../../api/fetchCustomers';
import {useQueryClient} from '@tanstack/react-query';

export const AddLoyaltiesModal = () => {
  const {t, dispatch} = useContext<any>(UtilsContext);

  const clientQuery = useQueryClient();

  const checkedCustomers = useAppSelector(s => s.customer.checkedCustomers);

  const initialValues = {
    loyaltyPoints: '',
    code: '',
    notif: false,
  };

  useEffect(() => {
    if (checkedCustomers && checkedCustomers.length === 0) {
      dispatch(addCustomerLoyaltiesModalAction(false));
    }
  }, [checkedCustomers, dispatch]);

  /* ON CANCEL */
  const handleOnCancel = () => {
    dispatch(addCustomerLoyaltiesModalAction(false));
  };

  /* HANDLE SUBMIT */
  const handleOnSubmit = async (
    data: {
      customerUuids: string[];
      loyaltyPoints: number;
      code: string;
      notif: boolean;
    },
    {setSubmitting, resetForm}: any,
  ) => {
    if (parseInt(data.code) === 270921) {
      if (data.loyaltyPoints && data.loyaltyPoints > 0) {
        const customerUuids = checkedCustomers.map(item => item.uuid);
        const res = await updateCustomersBalance({
          customerUuids,
          loyaltyPoints: data.loyaltyPoints,
          notif: data.notif,
        });
        if (res && res?.status === 204) {
          resetForm();
          clientQuery.invalidateQueries(['getCustomers']);
          handleOnCancel();
          dispatch(multiSelectionAction(false));
          helperDisplayToast(
            res?.data?.message || 'Les points ont bien été crédités',
            true,
          );
        } else {
          helperDisplayToast('Error', false);
        }
      }
    } else {
      helperDisplayToast('Code incorrect', false);
    }
    // setSubmitting(false);
  };

  const columns = [
    {
      headerName: 'Firstname',
      flex: 1,
      valueGetter: (row: any) => {
        return row?.firstname;
      },
    },
    {
      headerName: 'Lastname',
      flex: 1,
      valueGetter: (row: any) => {
        return row?.lastname;
      },
    },
    {
      headerName: 'Email',
      flex: 1,
      valueGetter: (row: any) => {
        return row?.email;
      },
    },
    {
      headerName: '',
      flex: 'none',
      width: 30,
      renderCell: (row: any) => {
        return (
          <div
            css={styles.removeBtn}
            className="flex-center"
            onClick={() => dispatch(checkedCustomersAction(row))}>
            <MinusIcon />
          </div>
        );
      },
    },
  ];

  return (
    <ModalWrapper
      title={'Créditer des points de fidélité'}
      validateLabel={'Créditer les points'}
      cancelLabel={t('CANCEL')}
      onCancel={handleOnCancel}
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      minWidth={'665px'}>
      <p css={styles.title}>Récapitulatif des comptes à créditer</p>
      <div style={{height: '20vh'}}>
        <NewDataGrid
          columns={columns}
          rows={checkedCustomers}
          rowHeight={35}
          hideHeader
        />
      </div>
      <FieldCustomInput
        label="Nombre de points à créditer"
        name="loyaltyPoints"
        required
      />
      <FieldCustomInput
        label="Code de sécurité"
        name="code"
        type="password"
        required
      />
      {/* <p className="title03" css={styles.notif}>
        <FieldCheckbox name="notif" marginRight={10} />
        Activer la notification par mail
      </p> */}

      <FieldsRequired />
    </ModalWrapper>
  );
};

const styles = {
  title: css({
    marginTop: 25,
    fontSize: 12,
    padding: '0 10px',
    color: THEME.colors.gray,
    marginBottom: 5,
    fontWeight: 600,
  }),
  removeBtn: css({
    backgroundColor: THEME.colors.red,
    borderRadius: 2,
    width: 25,
    height: 25,
    border: `1px solid ${THEME.colors.red}`,
    marginRight: 5,
    cursor: 'pointer',
  }),
  notif: css({
    alignItems: 'center',
    color: '#A7A7AB',
    marginTop: 10,
    display: 'inline-flex',
  }),
};

import {SVGProps} from 'react';

export const SendIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8309 5.75342L1.45591 0.878164C1.35282 0.833984 1.24415 0.789551 1.13649 0.789551C0.917551 0.789551 0.702315 0.878393 0.544893 1.04473C0.310835 1.31539 0.256887 1.6833 0.409231 1.98824L2.76099 6.4999L0.409307 11.0118C0.256963 11.317 0.310919 11.6847 0.544995 11.9327C0.702112 12.099 0.917221 12.1879 1.13609 12.1879C1.244 12.1879 1.3527 12.1665 1.45576 12.122L12.8309 7.24639C13.13 7.11865 13.3237 6.82465 13.3237 6.49965C13.3006 6.1749 13.1305 5.88037 12.8309 5.75342ZM2.02263 2.44705L10.0583 5.89053H3.81724L2.02263 2.44705ZM3.81724 7.10928H10.0557L2.02212 10.5522L3.81724 7.10928Z"
      fill="white"
    />
  </svg>
);

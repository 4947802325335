import {PRICE_RULE_TYPES} from '@bill-app-types/b-types';
import {EqualIcon} from '../assets/svgs/EqualIcon';
import {EuroLargeIcon} from '../assets/svgs/EuroIcon';
import {MinusIcon} from '../assets/svgs/MinusIcon';
import {PercentIcon} from '../assets/svgs/PercentIcon';
import {PlusIconSmall} from '../assets/svgs/PlusIcon';

export const PRICE_RULE_SYMBOLS_LEFT = [
  {
    Icon: EqualIcon,
    value: '=',
  },
  {
    Icon: PlusIconSmall,
    value: '+',
  },
  {
    Icon: MinusIcon,
    value: '-',
  },
] as const;

export const PRICE_RULE_SYMBOLS_RIGHT = [
  {
    Icon: EuroLargeIcon,
    value: '€',
    type: PRICE_RULE_TYPES.ADDITION,
  },
  {
    Icon: PercentIcon,
    value: '%',
    type: PRICE_RULE_TYPES.PERCENT,
  },
] as const;

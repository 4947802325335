import {FEATURE_FLAGS} from '@bill-app-types/b-types';
import {useFlag, useFlagsStatus} from '@unleash/proxy-client-react';

export const useFlagBCatalog = () => {
  const enabled = useFlag(FEATURE_FLAGS.BCATALOG);
  const {flagsReady, flagsError} = useFlagsStatus();

  return {
    flagBCatalogEnabled: enabled,
    flagBCatalogReady: flagsReady,
    flagsBCatalogError: flagsError,
  };
};

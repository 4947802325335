import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {useAppDispatch} from '../../../../../hooks/useReduxHook';
import {addUserModalAction} from '../../../../../redux/modals';

export const Center = () => {
  const dispatch = useAppDispatch();

  const handleOpenModalAddUser = () => {
    dispatch(addUserModalAction(true));
  };

  return (
    <>
      <ButtonCustom
        label={'AJOUTER UN UTILISATEUR'}
        onClick={handleOpenModalAddUser}
      />
    </>
  );
};

import {SVGProps} from 'react';

export const ChevronUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.20776 1.08789C7.84155 0.721679 7.24683 0.721679 6.88062 1.08789L1.25561 6.71289C0.889403 7.0791 0.889403 7.67383 1.25561 8.04004C1.62183 8.40625 2.21655 8.40625 2.58276 8.04004L7.54565 3.07715L12.5085 8.03711C12.8748 8.40332 13.4695 8.40332 13.8357 8.03711C14.2019 7.6709 14.2019 7.07617 13.8357 6.70996L8.21069 1.08496L8.20776 1.08789Z"
      fill="#151A30"
    />
  </svg>
);

import {
  ICardCreationPayload,
  IFetchCardsQueryParams,
  ICardFetchAllReturn,
  ICardItemCreationPayload,
  ICardPatchPayload,
  IFetchCardItemsResponse,
  ICardFetchReturn,
} from '@bill-app-types/b-types';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {helperJustLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';
import { ACTIONS, API_SERVICES, FOLDERS, PATHS } from '../constants/routes';

const cardsUrl = '/bcatalog/backoffice/cards';
const areasUrl = '/bcatalog/backoffice/areas';

export const fetchCards = async (data?: IFetchCardsQueryParams) => {
  const query = helperGenerateQuery(data);

  helperJustLog('### fetchCards ###');
  const res = await axiosInstance
    .get<{
      data: ICardFetchAllReturn;
    }>(`${cardsUrl}${query}`, {
      params: {orderBy: data?.orderBy},
    })
    .catch(err => {
      console.log('❌ fetchCards : ', err);
    });

  return res?.data?.data;
};

export const fetchCardsOld = async (data?: IFetchCardsQueryParams) => {
  helperJustLog('### fetchCards ###');
  const res = await axiosInstance
    .get(`${API_SERVICES.BCORE}${FOLDERS.ADMIN}${PATHS.CARD}`, {
      params: {orderBy: data?.orderBy},
    })
    .catch(err => {
      console.log('❌ fetchCards : ', err);
    });

  return res?.data?.data;
};

export const deleteCard = async (cardUuid: string) => {
  helperJustLog('### deleteCard ###');
  const res = await axiosInstance
    .delete(`${cardsUrl}/${cardUuid}`)
    .catch(err => {
      console.log('❌ deleteCard : ', err);
    });

  return res;
};

export const createCard = async (data: ICardCreationPayload) => {
  //
  helperJustLog('### createCard ###');
  const res = await axiosInstance
    .post(`${cardsUrl}`, data)
    .catch(err => {
      console.log('❌ createCard : ', err);
    });
  return res;
};

export const updateCard = async (data: ICardPatchPayload, cardUuid: string) => {
  //
  helperJustLog('### updateCard ###');
  const res = await axiosInstance
    .put(`${cardsUrl}/${cardUuid}`, data)
    .catch(err => {
      console.log('❌ updateCard : ', err);
    });
  return res;
};

export const duplicateCard = async (cardUuid: string) => {
  //
  helperJustLog('### duplicateCard ###');
  const res = await axiosInstance
    .put(`${cardsUrl}/${cardUuid}${ACTIONS.DUPLICATE}`)
    .catch(err => {
      console.log('❌ duplicateCard : ', err);
    });
  return res;
};

export const getCardDetails = async (cardUuid?: string) => {
  //
  helperJustLog('### getCardDetails ###');
  const res = await axiosInstance
    .get<{
      data: ICardFetchReturn;
    }>(`${cardsUrl}/${cardUuid}`)
    .catch(err => {
      console.log('❌ getCardDetails : ', err);
    });
  return res?.data?.data;
};

export const fetchCardItems = async (cardUuid: string) => {
  helperJustLog('### fetchCardItems ###');
  const res = await axiosInstance
    .get<{data: IFetchCardItemsResponse[]}>(
      `${cardsUrl}/${cardUuid}${PATHS.ITEMS}`,
    )
    .catch(err => {
      console.log('❌ fetchCardItems : ', err);
    });

  return res?.data?.data;
};

export const createCardItem = async (data: ICardItemCreationPayload) => {
  //
  helperJustLog('### createCard ###');
  const res = await axiosInstance
    .post(`${cardsUrl}${PATHS.ITEMS}`, {
      cardUuid: data.cardUuid,
      itemUuid: data.itemUuid,
    })
    .catch(err => {
      console.log('❌ createCard : ', err);
    });
  return res;
};

export const deleteCardItem = async (cardUuid: string, itemUuid: string) => {
  //
  helperJustLog('### deleteCardItem ###');
  const res = await axiosInstance
    .delete(`${cardsUrl}/${cardUuid}${PATHS.ITEMS}/${itemUuid}`)
    .catch(err => {
      console.log('❌ deleteCardItem : ', err);
    });
  return res;
};

export const fetchAreaHasCards = async (areaUuid?: string) => {
  const res = await axiosInstance
    .get(`${areasUrl}/${areaUuid}${PATHS.CARDS}`)
    .catch(err => {
      console.log('❌ fetchAreaCards : ', err);
    });

  return res?.data?.data;
};

export const enabledAreaHasCardV2 = async (
  areaUuid: string,
  cardUuid: string,
) => {
  const res:any = await axiosInstance
    .post(`${areasUrl}/${areaUuid}${PATHS.CARDS}/${cardUuid}`)
    .catch(err => {
      console.log('❌ enabledAreaHasCardV2 : ', err);
    });

  return res;
};

export const disabledAreaHasCardV2 = async (
  areaUuid: string,
  cardUuid: string,
) => {
  const res:any = await axiosInstance
    .delete(
      `${areasUrl}/${areaUuid}${PATHS.CARDS}/${cardUuid}`,
    )
    .catch(err => {
      console.log('❌ disabledAreaHasCardV2 : ', err);
    });

  return res;
};
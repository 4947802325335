import {SVGProps} from 'react';

export const SunIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M7.78 4.936a.69.69 0 0 0 .49-.204.7.7 0 0 0 .203-.493V2.843a.7.7 0 0 0-.203-.493.69.69 0 0 0-1.183.493V4.24a.7.7 0 0 0 .203.493.69.69 0 0 0 .49.204ZM14.018 8.425h-1.386a.69.69 0 0 0-.49.205.7.7 0 0 0 .49 1.19h1.386a.69.69 0 0 0 .49-.204.7.7 0 0 0-.49-1.19ZM3.622 9.123a.7.7 0 0 0-.203-.493.69.69 0 0 0-.49-.205H1.543a.69.69 0 0 0-.49.205.7.7 0 0 0 .49 1.19h1.386a.69.69 0 0 0 .49-.204.7.7 0 0 0 .203-.493ZM3.774 4.239a.698.698 0 0 0-.991.028.708.708 0 0 0 .028.997l.998.97a.692.692 0 0 0 .506.196.69.69 0 0 0 .499-.217.7.7 0 0 0 0-.984l-1.04-.99ZM11.245 6.43a.69.69 0 0 0 .479-.196l.998-.97a.7.7 0 0 0-.45-1.178.69.69 0 0 0-.486.153l-.998.99a.7.7 0 0 0 0 .984c.12.128.283.205.457.217ZM7.78 13.31a.69.69 0 0 0-.49.204.7.7 0 0 0-.203.493v1.396a.7.7 0 0 0 .203.493.69.69 0 0 0 1.183-.493v-1.396a.7.7 0 0 0-.203-.493.69.69 0 0 0-.49-.204ZM11.751 12.012a.69.69 0 0 0-.98.017.7.7 0 0 0 .017.988l.998.99a.69.69 0 0 0 .977-.014.697.697 0 0 0 .152-.763.698.698 0 0 0-.152-.228l-1.012-.99ZM3.809 12.012l-.998.97a.699.699 0 0 0 0 .99.693.693 0 0 0 .499.21.69.69 0 0 0 .464-.175l.998-.97a.7.7 0 0 0-.47-1.2.691.691 0 0 0-.493.196v-.021ZM7.78 6.332a2.76 2.76 0 0 0-1.54.47 2.787 2.787 0 0 0-1.021 1.253 2.808 2.808 0 0 0 .6 3.042 2.755 2.755 0 0 0 3.021.605c.508-.212.94-.57 1.245-1.028a2.805 2.805 0 0 0-.345-3.525 2.763 2.763 0 0 0-1.96-.817Zm0 4.186a1.38 1.38 0 0 1-1.28-.861 1.404 1.404 0 0 1 .3-1.52 1.383 1.383 0 0 1 1.51-.303 1.388 1.388 0 0 1 .856 1.289 1.4 1.4 0 0 1-.406.987c-.26.261-.612.409-.98.409Z"
      fill="#F7C761"
    />
  </svg>
);

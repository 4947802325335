/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ConsumptionModeFilter} from '../../../../components/filters/ConsumptionModeFilter';
import {SalesTurnOverPieChart} from '../../../../components/organisms/SalesTurnOverPieChart';
import {ChannelIdFilter} from '../../../../components/filters/ChannelIdFilter';
import {useStatByConsumptionMode} from '../../../../hooks/useStatByConsumptionMode';
import {useStatByChannel} from '../../../../hooks/useStatByChannel';
import {
  helperGetChannelById,
  helperGetChannelNamebyId,
} from '../../../../helpers/helperChannel';
import {
  helperGetConsumptionNameById,
  helperGetConsumptionById,
} from '../../../../helpers/helperStats';
import {M_SCREEN_DESKTOP} from '../../../../constants/ResponsiveSize';

export const BlockTopRight = () => {
  const {data: consumptionData, isLoading} = useStatByConsumptionMode();
  const {data: channelsData, isLoading: isLoadingChannel} = useStatByChannel();

  const channels = channelsData?.map((channel: any) => {
    return {
      ...channel,
      color: helperGetChannelById(parseInt(channel['channelId'])),
      name: helperGetChannelNamebyId(parseInt(channel['channelId'])),
      value: channel?.sales,
      suffix: channel?.suffix || '€',
      orders: channel?.orders,
    };
  });

  const consumptions = consumptionData?.map(consum => {
    return {
      ...consum,
      color: helperGetConsumptionById(parseInt(consum['consumptionModeId'])),
      name: helperGetConsumptionNameById(parseInt(consum['consumptionModeId'])),
      value: consum?.sales,
      suffix: consum?.suffix || '€',
      orders: consum?.orders,
    };
  });

  return (
    <div css={styles.container}>
      <SalesTurnOverPieChart
        FilterComponent={ChannelIdFilter}
        data={consumptions || []}
        isLoading={isLoading}
        title="MODES_OF_CONSUMPTION"
      />

      <SalesTurnOverPieChart
        FilterComponent={ConsumptionModeFilter}
        data={channels || []}
        isLoading={isLoadingChannel}
        title="ORDER_CHANNELS"
      />
    </div>
  );
};

const styles = {
  container: css({
    width: '49%',
    display: 'flex',
    gap: '1rem',
    gridArea: 'sales_turnover',
    '& > div': {
      width: '49.5%!important',
    },
    [M_SCREEN_DESKTOP]: {
      flexDirection: 'column',
      width: '100%',
      '& > div': {
        width: '100%!important',
      },
    },
  }),
};

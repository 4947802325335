import {useQueryClient} from '@tanstack/react-query';
import {deleteSuggestionCategory} from '../../api/fetchSuggestion';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {deleteAdditionalCategoryModalAction} from '../../redux/modals';
import {updatedCategoryAction} from '../../redux/myOffersSection/categories';
import {DeleteModal} from './DeleteModal';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

export const DeleteAdditionalCategory = () => {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const updatedCategory = useAppSelector(
    s => s.myOffersSection.categories.updatedCategory,
  );

  const clientQuery = useQueryClient();

  const handleCancel = () => {
    dispatch(updatedCategoryAction(null));
    dispatch(deleteAdditionalCategoryModalAction(false));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const res = await deleteSuggestionCategory(String(updatedCategory?.uuid));

      if (res?.status === 204) {
        helperDisplayToast('Catégorie supprimée', true);
        await clientQuery.invalidateQueries(['fetchSuggestionCategories']);
      } else {
        helperDisplayToast('Impossible de supprimer la catégorie', false);
      }

      setIsLoading(false);
      handleCancel();
    } catch (error: any) {
      helperDisplayToast(
        error?.response?.data?.message || 'Une erreur est survenue',
        false,
      );
    }
  };

  if (!updatedCategory) {
    return null;
  }

  return (
    <DeleteModal
      title={t('DELETE_CATEGORY')}
      text={updatedCategory?.backofficeLabel || ''}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
};

import {SVGProps} from 'react';

export const CircleDollarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.089 3.466c.193.035.486.094.615.129a.583.583 0 0 1 .416.715.583.583 0 0 1-.715.416c-.114-.03-.51-.106-.638-.153-.358-.03-.674.018-.838.103-.185.082-.229.178-.24.237-.018.1 0 .135.005.147.006.014.03.05.106.102.179.123.46.211.85.337l.05.006c.354.108.828.249 1.183.51.196.14.38.334.495.597.117.267.14.56.088.865-.097.556-.466.937-.929 1.133-.14.036-.293.106-.448.135v.135a.6.6 0 0 1-.615.615c-.3 0-.563-.264-.563-.615v-.161a7.83 7.83 0 0 1-.873-.267c-.05-.017-.094-.032-.129-.07-.307-.076-.474-.407-.372-.715a.586.586 0 0 1 .741-.372c.059.02.123.04.188.064.278.094.592.2.767.229.375.058.665.02.844-.056.161-.067.217-.155.235-.258.02-.117.002-.17-.01-.193-.008-.026-.034-.067-.108-.12-.173-.126-.448-.217-.858-.343l-.065-.017c-.342-.103-.79-.238-1.13-.47a1.45 1.45 0 0 1-.507-.57 1.396 1.396 0 0 1-.1-.85c.094-.536.475-.905.911-1.105.12-.064.302-.114.466-.149v-.202c0-.299.264-.563.589-.563.325 0 .589.264.589.563v.21ZM1.406 6.094A6.093 6.093 0 0 1 7.5 0a6.093 6.093 0 0 1 6.094 6.094A6.093 6.093 0 0 1 7.5 12.187a6.093 6.093 0 0 1-6.094-6.093ZM7.5 10.78a4.686 4.686 0 0 0 4.688-4.687A4.686 4.686 0 0 0 7.5 1.406a4.686 4.686 0 0 0-4.688 4.688A4.686 4.686 0 0 0 7.5 10.78Zm-4.245.938h-1.38a.47.47 0 0 0-.469.469v.937c0 .258.21.469.469.469h11.25a.47.47 0 0 0 .469-.469v-.938a.47.47 0 0 0-.469-.468h-1.406a7.029 7.029 0 0 0 1.406-1.406c1.037 0 1.875.84 1.875 1.874v.938C15 14.159 14.16 15 13.125 15H1.875A1.876 1.876 0 0 1 0 13.125v-.938c0-1.034.84-1.874 1.874-1.874.4.533.874 1.004 1.38 1.406Z"
      fill={props.color}
    />
  </svg>
);

import {PayloadAction, createSlice} from '@reduxjs/toolkit';

export type SalesGraphSectionType = 'TURNOVER' | 'MEDIUM' | 'ORDERS';

interface ISlice {
  salesGraphSection: SalesGraphSectionType;
}

const initialState: ISlice = {
  salesGraphSection: 'TURNOVER',
};

const slice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    salesGraphSectionAction: (
      state,
      action: PayloadAction<SalesGraphSectionType>,
    ) => {
      state.salesGraphSection = action.payload;
    },
  },
});

export const {salesGraphSectionAction} = slice.actions;

export default slice.reducer;

import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {useAppDispatch} from '../../../../../hooks/useReduxHook';
import {addGroupModalAction} from '../../../../../redux/modals';

export const Center = () => {
  const dispatch = useAppDispatch();

  const handleOpenModalAddEvent = () => {
    dispatch(addGroupModalAction(true));
  };

  return (
    <>
      <ButtonCustom
        label={'AJOUTER UN GROUPE'}
        onClick={handleOpenModalAddEvent}
      />
    </>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {useTransaction} from '../../../../hooks/useTransaction';
import {Bottom} from './HeaderSections/Bottom';
import {columns} from './columns';
import {useEffect, useState} from 'react';
import {TotalPrice} from '../../../../components/atoms/TotalPrice';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {Center} from './HeaderSections/Center';

export const Movements = withRedirectAndNavigation(() => {
  //
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [rowCount, setRowCount] = useState(0);

  const offset = (page - 1) * limit;

  const {transations, isTransactionsLoading, totalTransactions, totalAmount} =
    useTransaction(offset, limit);

  useEffect(() => {
    setRowCount(oldRowCount =>
      totalTransactions !== undefined ? totalTransactions : oldRowCount,
    );
  }, [totalTransactions]);

  return (
    <div css={styles.container}>
      <Header
        centerSection={<Center limit={limit} />}
        bottomSection={<Bottom />}
      />
      <div css={styles.content}>
        <NewDataGrid
          columns={columns}
          rows={transations}
          loading={isTransactionsLoading}
          hasPagination
          page={page}
          setPage={setPage}
          totalRows={rowCount}
          rowPerPage={limit}
        />
        <TotalPrice price={totalAmount} />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: {
    padding: '2vh',
    height: '84%',
  },
};

/** @jsxImportSource @emotion/react */
import {deletePosTerminal} from '../../../../../api/fetchPosTerminals';
import {DataGridActions} from '../../../../../components/newDatagrid/DataGridActions';
import {helperConfirmDialog} from '../../../../../helpers/helperConfirmDialog';
import {helperDate} from '../../../../../helpers/helperDate';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {addPosTerminalModalAction} from '../../../../../redux/modals';
import {
  updateModeAction,
  updatedPosTerminalAction,
} from '../../../../../redux/myModulesSection/pos';

export const columns = [
  {
    field: 'label',
    headerName: 'Nom du terminal',
    flex: 1,
  },
  {
    field: 'created_at',
    headerName: "Date d'ajout",
    flex: 1,
    valueGetter(row: any) {
      return `Le ${helperDate(row.created_at, 'DD/MM/YYYY')} à ${helperDate(
        row.created_at,
        'HH:mm',
      )}`;
    },
  },
  {
    field: 'updated_at',
    headerName: 'Date de modification',
    flex: 1,
    valueGetter(row: any) {
      return `Le ${helperDate(row.updated_at, 'DD/MM/YYYY')} à ${helperDate(
        row.created_at,
        'HH:mm',
      )}`;
    },
  },
  {
    field: 'actions',
    headerName: '',
    align: 'right',
    flex: 0.5,
    renderCell: (row: any) => {
      const handleClickEdit = ({dispatch}: any) => {
        dispatch(addPosTerminalModalAction(true));
        dispatch(updatedPosTerminalAction(row));
        dispatch(updateModeAction(true));
      };

      const handleClickDelete = ({refreshing, brandId}: any) => {
        helperConfirmDialog({
          onSubmit: async () => {
            const res = await deletePosTerminal(brandId, row.uuid);
            if ([200, 204].includes(res.status)) {
              helperDisplayToast('Terminal supprimé', true);
              refreshing('getPosTerminals');
            } else {
              helperDisplayToast(
                res.data?.message || 'Impossible de supprimer le terminal',
                false,
              );
            }
          },
        });
      };

      return (
        <DataGridActions
          handleClickEdit={handleClickEdit}
          handleClickDelete={handleClickDelete}
        />
      );
    },
  },
];

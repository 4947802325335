/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {FilterButton} from '../../../../../components/atoms/FilterButton';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {ChannelIdFilter} from '../../../../../components/filters/ChannelIdFilter';
import {ConsumptionModeFilter} from '../../../../../components/filters/ConsumptionModeFilter';
import {DisabledFiltersButton} from '../../../../../components/atoms/DisabledFiltersButton';
import {
  resetFilterChannelsAction,
  resetFilterConsumptionModesAction,
  resetFilterDateAction,
} from '../../../../../redux/filter';
import {typeFilterDateAction} from '../../../../../redux/date';
import {helperShowResetDate} from '../../../../../helpers/helperFilter';
import {ParentBarCodesFilter} from '../../../../../components/filters/ParentBarCodesFilter';

export const StatsSalesHeaderBottom = ({
  showChannelFilter = true,
  marginTop = '1.5rem',
  showDateCompare = true,
  showParentBarCodeFilter = false,
}) => {
  const dates = useAppSelector(s => s.filter.dates);
  const channels = useAppSelector(s => s.filter.channels);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const compareDates = useAppSelector(s => s.filter.compareDates);
  const dispatch = useAppDispatch();

  const showResetButton =
    helperShowResetDate(dates.from, dates.to) ||
    channels?.length > 0 ||
    consumptionModes?.length > 0;

  const handleReset = () => {
    dispatch(resetFilterDateAction());
    dispatch(typeFilterDateAction('between'));
    dispatch(resetFilterChannelsAction());
    dispatch(resetFilterConsumptionModesAction());
  };

  return (
    <div css={[styles.container, {marginTop}]} className="flex">
      {showResetButton && <DisabledFiltersButton onclick={handleReset} />}
      <FilterButton
        dateShowingOptions={{
          showSortFilter: false,
          disabledFilterTypes: ['equal', 'before', 'after'],
          forStats: true,
        }}
        icon={'calendar'}
        valueType="dates"
        values={dates}
      />
      {showParentBarCodeFilter && <ParentBarCodesFilter />}

      {showChannelFilter && <ChannelIdFilter />}
      <ConsumptionModeFilter marginRight={0} />
      {showDateCompare && (
        <FilterButton
          icon={'calendar'}
          valueType="dateCompare"
          values={compareDates}
        />
      )}
    </div>
  );
};

const styles = {
  container: css({
    gap: '1vh',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {DownLoadIcon} from '../../assets/svgs/DownloadIcon';
import {THEME} from '../../styles/theme';
interface ITitleProps {
  title?: string;
  showIcon?: boolean;
  Icon?: any;
  onClick?: () => void;
  showBg?: boolean;
  padding?: string;
}

export const Title = ({
  title = 'Title',
  showIcon,
  Icon,
  onClick,
  showBg = true,
  padding = '0px 0px 15px 10px',
}: ITitleProps) => (
  <div css={styles.container}>
    <h1 className="title02" css={styles.title(padding)}>
      {title}
    </h1>
    {showIcon && (
      <div css={styles.icon(showBg)} onClick={onClick}>
        {!!Icon ? <Icon /> : <DownLoadIcon color="white" />}
      </div>
    )}
  </div>
);

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid #CCD6E0`,
  }),
  title: (padding: string) =>
    css({
      padding,
      color: 'var(--text-color)',
    }),
  icon: (showBg: boolean) =>
    css({
      width: 25,
      height: 20,
      backgroundColor: showBg ? THEME.colors.primary : 'transparent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
};

import {SVGProps} from 'react';

export const HistoryIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0781 0.5C10.9819 0.5 9.28125 2.01102 9.28125 3.875C9.28125 5.73898 10.9819 7.25 13.0781 7.25C15.1743 7.25 16.875 5.73828 16.875 3.875C16.875 2.01172 15.1743 0.5 13.0781 0.5ZM14.3438 4.25H13.0781C12.8461 4.25 12.6562 4.08125 12.6562 3.875V2.375C12.6562 2.16781 12.8461 2 13.0781 2C13.3102 2 13.5 2.16781 13.5 2.375V3.5H14.3438C14.5769 3.5 14.7656 3.66772 14.7656 3.875C14.7656 4.08228 14.5758 4.25 14.3438 4.25ZM6.75 6.5H2.33376L5.68767 3.92656C5.95107 3.72266 5.98008 3.36641 5.75332 3.13203C5.52393 2.89766 5.12314 2.87188 4.85947 3.07344L0.218848 6.63594C0.0799189 6.74375 0 6.89844 0 7.0625C0 7.22656 0.079919 7.38102 0.219164 7.48812L4.85979 11.0506C4.97812 11.143 5.12578 11.1875 5.27344 11.1875C5.4506 11.1875 5.62702 11.1216 5.75227 10.9927C5.9805 10.7576 5.95165 10.4023 5.68716 10.1996L2.33376 7.625H6.75C9.42627 7.625 11.6016 9.5593 11.6016 11.9375C11.6016 12.248 11.885 12.5 12.2344 12.5C12.5837 12.5 12.8672 12.248 12.8672 11.9375C12.8672 8.93984 10.1224 6.5 6.75 6.5Z"
      fill="white"
    />
  </svg>
);

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {CrossIcon} from '../../assets/svgs/CrossIcon';

export const CrossIconContainer = ({
  onClick,
  showAdd = false,
}: {
  onClick: () => void;
  showAdd?: boolean;
}) => {
  return (
    <div css={styles.iconContainer}>
      <div className="icon" css={[styles.icon({showAdd})]} onClick={onClick}>
        <CrossIcon color={THEME.colors.white} />
      </div>
    </div>
  );
};

const styles = {
  iconContainer: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  }),

  icon: ({showAdd}: {showAdd: boolean}) =>
    css({
      cursor: 'pointer',
      borderRadius: '50%',
      opacity: 0,
      height: 40,
      width: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: THEME.colors[showAdd ? 'green' : 'red'],
      transform: `rotate(${showAdd ? 0 : 45}deg)`,
      transition: '0.3s linear',
    }),
};

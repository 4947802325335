import {useAppSelector} from '../../hooks/useReduxHook';
import {CategoryModal} from './CategoryModal';
import {EventModal} from './EventModal';
//import {QrCodesListModal} from './QrCodesListModal';
import {AddUserModal} from './AddUserModal';
import {AddAreaModal} from './AddAreaModal';
import {QrCodesListModal} from './QrCodesListModal';
import {AddAreaLocationModal} from './AddAreaLocationModal';
import {ViewAreaLocationQRCodeModal} from './ViewAreaLocationQRCodeModal';
import {CreateGroupModal} from './CreateGoupModal';
import {AddAccountingModal} from './AddAccountingModal';
import {AddOptionModal} from './AddOptionModal';
// import {AddProductModal} from './AddProductModal';
import {AddCardModal} from './AddCardModal';
import {AddUsersGroupModal} from './AddUsersGroup';
import {DeleteUsersGroupModal} from './DeleteUsersGroupModal';
import {DeleteUserModal} from './DeleteUserModal';
import {AddLoyaltiesModal} from './AddLoyaltiesModal';
import {AddProductCategoryModal} from './AddProductCategoryModal';
import {AddRecipeModal} from './AddRecipeModal';
import {DeleteRecipeModal} from './DeleteRecipeModal';
import {AddMenuModal} from './AddMenuModal';
import {AddCompositionSubMenuModal} from './AddCompositionSubMenuModal';
import {AddSubMenuModal} from './AddSubMenuModal';
// import {EditSubMenuModal} from './EditSubMenuModal';
// import {EditSupplementModal} from './EditSupplementModal';
import {DeleteAreaLocationModal} from './DeleteAreaLocationModal';
import {DeleteAreaModal} from './DeleteAreaModal';
import {EditConsumptionModal} from './EditConsumptionModal';
import {EditAdditionalPriceModal} from './EditAdditionalPriceModal';
import {AddPrinterModal} from './AddPrinterModal';
import {AddPrinterConfigModal} from './AddConfigPrinterModal';
import {DeletePrinterModal} from './DeletePrinterModal';
import {DeletePrinterConfigModal} from './DeletePrinterConfigModal';
import {DeleteStaffGroupModal} from './DeleteStaffGroupModal';
import {AddAdditionalCategory} from './AddAdditionalCategory';
import {DeleteAdditionalCategory} from './DeleteAdditionalCategory';
import {AddVendorStructureModal} from './AddVendorStructureModal';
import {AddPosStructureModal} from './AddPosStructureModal';
import {AddPosUserModal} from './AddPosUserModal';
import {AddProductionAreaModal} from './AddProductionAreaModal';
import {DeleteProductionAreaModal} from './DeleteProductionAreaModal';
import {AddPosCategoryModal} from './AddPosCategoryModal';
import {AddPosCategoryModalOld} from './AddPosCategoryModalOld';
import {AddPosButtonModal} from './AddPosButtonModal';
import {EditLoyaltyModal} from './EditLoyaltyModal';
import {AddPriceRuleModal} from './AddPriceRuleModal';
import {AddPosTerminalModal} from './AddPosTerminalModal';
import {AddPosEntityModal} from './AddPosEntityModal';
import {AddExternalConnectionModal} from './AddExternalConnectionModal';
import {DeleteExternalConnectionModal} from './DeleteExternalConnectionModal';

export const ModalList = () => {
  const modals = useAppSelector(state => state.modals);
  return (
    <>
      {modals.addUserModal && <AddUserModal />}
      {modals.categoryModal && <CategoryModal />}
      {modals.addOptionModal && <AddOptionModal />}
      {modals.eventModal && <EventModal />}
      {modals.qrCodesListModal && <QrCodesListModal />}
      {modals.addAreaModal && <AddAreaModal />}
      {modals.addAreaLocationModal && <AddAreaLocationModal />}
      {modals.viewAreaLocationQRCodeModal && <ViewAreaLocationQRCodeModal />}
      {modals.createGroupModal && <CreateGroupModal />}
      {modals.addAccountingModal && <AddAccountingModal />}
      {modals.createCardModal && <AddCardModal />}
      {modals.addUsersGroupModal && <AddUsersGroupModal />}
      {modals.deleteUsersGroupModal && <DeleteUsersGroupModal />}
      {modals.deleteUserModal && <DeleteUserModal />}
      {modals.addCustomerLoyaltiesModal && <AddLoyaltiesModal />}
      {modals.addProductCategoryModal && <AddProductCategoryModal />}
      {modals.addRecipeModal && <AddRecipeModal />}
      {modals.deleteRecipeModal && <DeleteRecipeModal />}
      {modals.addMenuModal && <AddMenuModal />}
      {modals.addCompositionSubMenuModal && <AddCompositionSubMenuModal />}
      {modals.addSubMenuModal && <AddSubMenuModal />}
      {modals?.addPrinterModal && <AddPrinterModal />}
      {modals?.addPrinterConfigModal && <AddPrinterConfigModal />}
      {modals.addAdditionalCategoryModal && <AddAdditionalCategory />}
      {/* {modals.editSubMenuModal && <EditSubMenuModal />}
      {modals.editSupplementModal && <EditSupplementModal />} */}
      {modals.deleteAreaModal && <DeleteAreaModal />}
      {modals.deleteAreaLocationModal && <DeleteAreaLocationModal />}
      {modals.editConsumptionModal && <EditConsumptionModal />}
      {modals.editAdditionalPrice && <EditAdditionalPriceModal />}
      {modals.editLoyaltyModal && <EditLoyaltyModal />}
      {modals?.deletePrinterModal && <DeletePrinterModal />}
      {modals?.deletePrinterConfigModal && <DeletePrinterConfigModal />}
      {modals.deleteStaffGroupModal && <DeleteStaffGroupModal />}
      {modals?.deleteAdditionalCategoryModal && <DeleteAdditionalCategory />}
      {modals?.addVendorStructureModal && <AddVendorStructureModal />}
      {modals?.addPosStructureModal && <AddPosStructureModal />}
      {modals?.addPosUserModal && <AddPosUserModal />}
      {modals?.addProductionAreaModal && <AddProductionAreaModal />}
      {modals?.deleteProductionAreaModal && <DeleteProductionAreaModal />}
      {modals?.addPosCategoryModalOld && <AddPosCategoryModalOld />}
      {modals?.addPosCategoryModal && <AddPosCategoryModal />}
      {modals?.addPosButtonModal && <AddPosButtonModal />}
      {modals?.addPriceRuleModal && <AddPriceRuleModal />}
      {modals?.addPosTerminalModal && <AddPosTerminalModal />}
      {modals?.addPosEntityModal && <AddPosEntityModal />}
      {modals?.addExternalConnectionModal && <AddExternalConnectionModal />}
      {modals?.deleteExternalConnectionModal && (
        <DeleteExternalConnectionModal />
      )}
    </>
  );
};

import {useQuery} from '@tanstack/react-query';
import {fetchStaffUsers} from '../api/fetchStaff';
import {useAppSelector} from './useReduxHook';
import {useDebounce} from './useDebounce';
import {IFetchAdminUsersFiltersV2} from '@bill-app-types/b-types';

export const useStaffUsers = () => {
  const brand = useAppSelector(s => s.brand.brand);
  const isArchived = useAppSelector(s => s.filter.isArchived);
  const search = useAppSelector(s => s.filter.search);
  const orderBy = useAppSelector(s => s.filter.orderBy);
  const groups = useAppSelector(s => s.filter.groups);

  const debounceSearch = useDebounce(search, 500);

  const {data, isLoading, error, refetch} = useQuery(
    [`fetchStaffUsers`, brand?.id, isArchived, debounceSearch, orderBy, groups],
    () => {
      let filter: IFetchAdminUsersFiltersV2 = {
        companyIdGuid: brand?.id,
        search: debounceSearch,
        groupUuids: groups?.map(item => String(item.value)) || [],
        // orderBy: orderBy.filter(
        //   item =>
        //     ['firstname', 'lastname', 'createdAt', 'updatedAt'].includes(
        //       item.field,
        //     ) && item.sort,
        // ) as any,
      };

      if (isArchived !== null) {
        filter.isArchived = isArchived;
      }

      return fetchStaffUsers(filter);
    },
    {
      enabled: !!brand?.id,
    },
  );

  return {
    staffUsersData: data,
    staffUsersLoading: isLoading,
    staffUsersError: error,
    refetch,
  };
};

/** @jsxImportSource @emotion/react */
import {useContext, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {
  filterPointsForFreeAction,
  resetFilterPointsForFreeAction,
} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
import {FilterNumberContainer} from '../molecules/FilterNumberContainer';
import {useTranslation} from 'react-i18next';
import {StarIcon} from '../../assets/svgs/StarIcon';
import {THEME} from '../../styles/theme';
import store from '../../redux/storeConfig';

export const PointsFreeFilter = () => {
  const {t} = useTranslation();
  const {dispatch} = useContext<any>(UtilsContext);
  const {min, max} = useAppSelector(s => s.filter.pointsForFree);
  const typeFilter = useAppSelector(s => s.date.typeFilterDate);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [forceClose, setForceClose] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;

    if (name === 'min') {
      setMinValue(Number(value));
    } else {
      setMaxValue(Number(value));
    }
  };

  const handleResetFilterAmount = () => {
    setMinValue(0);
    setMaxValue(0);
    dispatch(resetFilterPointsForFreeAction());
  };

  const handleSubmit = () => {
    const sortFilterDate = store.getState().date.sortFilterDate;
    dispatch(
      filterPointsForFreeAction({
        min: typeFilter === 'inferior' ? 0 : minValue,
        max:
          typeFilter === 'equal'
            ? minValue
            : typeFilter === 'superior'
            ? 0
            : maxValue,
        sort: sortFilterDate,
      }),
    );

    setForceClose(true);

    setTimeout(() => {
      setForceClose(false);
    }, 100);
  };

  return (
    <FilterButton
      label={t('POINTS_FOR_FREE')}
      valueType="list"
      values={
        min === max
          ? [{name: min}]
          : [
              {
                name: min === 0 ? 0 : min,
              },
              {
                name: max === 0 ? 'X' : max,
              },
            ]
      }
      showResetButton={max !== null || min !== null}
      onReset={handleResetFilterAmount}
      hideFilterIcon={max !== null || min !== null}
      forceClose={forceClose}>
      <FilterNumberContainer
        min={minValue}
        max={maxValue}
        onChange={handleChange}
        label="POINTS_FOR_FREE"
        Icon={StarIcon}
        onSubmit={handleSubmit}
        iconColor={THEME.colors.yellow}
        width="17rem"
      />
    </FilterButton>
  );
};

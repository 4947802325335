import {REDIRECT_URL} from '../constants/global';

export const helperGenerateRedirectUrl = (url: string) => {
  const isRestaurant = url.includes('restaurant');
  const isCatalog = url.includes('catalog');
  const isOrders = url.includes('orders');
  const isTranslations = url.includes('translations');

  const lastPath = url.split('/').pop();

  if (isRestaurant) {
    return REDIRECT_URL.restaurant[
      lastPath as keyof typeof REDIRECT_URL.restaurant
    ];
  }

  if (isCatalog) {
    return REDIRECT_URL.catalog[lastPath as keyof typeof REDIRECT_URL.catalog];
  }

  if (isOrders) {
    return REDIRECT_URL.orders[lastPath as keyof typeof REDIRECT_URL.orders];
  }

  if (isTranslations) {
    return REDIRECT_URL.translations[
      lastPath as keyof typeof REDIRECT_URL.translations
    ];
  }

  return null;
};

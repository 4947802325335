/** @jsxImportSource @emotion/react */
import {ButtonCustom} from '../../../../../../components/atoms/ButtonCustom';
import {BRAND_AUTHORIZATION} from '../../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../../hooks/useBrandAccess';
import {useAppDispatch} from '../../../../../../hooks/useReduxHook';
import {createCardModalAction} from '../../../../../../redux/modals';

export const CardHeaderCenter = () => {
  const dispatch = useAppDispatch();
  const {brandHasAuthorization} = useBrandAccess();

  const handleOpenCreateCardModal = () => {
    dispatch(createCardModalAction(true));
  };
  return (
    <ButtonCustom
      label="Ajouter une carte"
      onClick={handleOpenCreateCardModal}
      disabled={!brandHasAuthorization(BRAND_AUTHORIZATION.PARENT)}
    />
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addVendorStructureModalAction} from '../../redux/modals';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useEffect, useState} from 'react';
import {SelectList} from '../molecules/SelectList';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {InputLabel} from '../atoms/InputLabel';
import {RequiredLabel} from '../atoms/RequiredLabel';
import {helperLabelTranslation} from '../../helpers/helperLabel';
import {useVendorStructure} from '../../hooks/useVendorStructure';
import {
  createVendorStructure,
  deleteVendorStructure,
  updateVendorStructure,
} from '../../api/fetchVendorStructure';
import {useTaxes} from '../../hooks/useTaxes';
import {updatedVendorStructureAction} from '../../redux/myAccountingSection/vendorStructures';

export const AddVendorStructureModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const clientQuery = useQueryClient();

  const updateMode = useAppSelector(
    state => state.myAccountingSection.vendorStructures.updateMode,
  );
  const updatedVendorStructure = useAppSelector(
    state => state.myAccountingSection.vendorStructures.updatedVendorStructure,
  );

  const {data} = useTaxes();

  const {vendorStructure: vendorStructures} = useVendorStructure();

  const [form, setForm] = useState({
    label: '',
    taxUuid: '',
    position: 1,
    parentCategoryUuid: null,
  });

  useEffect(() => {
    if (updateMode && updatedVendorStructure) {
      setForm({
        label: updatedVendorStructure.label,
        taxUuid: updatedVendorStructure?.tax?.uuid,
        position: updatedVendorStructure.position,
        parentCategoryUuid: updatedVendorStructure.parentCategoryUuid,
      });
    }
  }, [updateMode, updatedVendorStructure]);

  /* ON CANCEL */
  const handleOnCancel = () => {
    dispatch(updatedVendorStructureAction(null));
    dispatch(updatedVendorStructureAction(null));
    dispatch(addVendorStructureModalAction(false));
  };

  const handleCreateVendorStructure = async () => {
    if (form.label) {
      let res;
      const formData: any = {
        label: form.label,
        position: form.position,
        taxUuid: form.taxUuid || data[0]?.value,
      };

      if (form.parentCategoryUuid) {
        formData['parentCategoryUuid'] = form.parentCategoryUuid;
      }

      if (updateMode) {
        res = await updateVendorStructure(
          formData,
          updatedVendorStructure?.uuid,
        );
      } else {
        res = await createVendorStructure(formData);
      }

      if (res && [200, 204].includes(res?.status)) {
        handleOnCancel();
        helperDisplayToast(
          res?.data?.message || 'Structure marchande enregistrée',
          true,
        );
        clientQuery.invalidateQueries(['getVendorStructure']);
      } else {
        helperDisplayToast(
          res?.data?.message ||
            "Impossible d'enregistre la structure marchande",
          false,
        );
      }
    } else {
      helperDisplayToast('Tous les champs doivent être complétés', false);
    }
  };

  const handleOnSubmit = async (_: any, {setSubmitting}: any) => {
    await handleCreateVendorStructure();
    setSubmitting(false);
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;
    setForm({...form, [name]: value});
  };

  const handleOnRemove = async () => {
    const res = await deleteVendorStructure(updatedVendorStructure?.uuid);
    if (res && res.status === 204) {
      helperDisplayToast(
        res?.data?.message || 'Structure marchande supprimée',
        true,
      );
      handleOnCancel();
      clientQuery.invalidateQueries(['getVendorStructure']);
    } else {
      helperDisplayToast(
        res?.data?.message || 'Impossible de supprimer la structure marchande',
        false,
      );
    }
  };

  const newVendorStructures = updatedVendorStructure
    ? vendorStructures?.filter(
        item => item.uuid !== updatedVendorStructure.uuid,
      )
    : vendorStructures;

  return (
    <ModalWrapper
      title={t('Famille de taxe')}
      validateLabel={'Enregistrer'}
      saveLabel={t('REGISTER_AND_CONTINUE_CREATION_LATER')}
      cancelLabel={t('CANCEL')}
      onRemove={updateMode ? handleOnRemove : undefined}
      onCancel={handleOnCancel}
      onSubmit={handleOnSubmit}
      initialValues={form}
      titleMarginBottom={35}
      minWidth="40%">
      <InputLabel
        onChange={handleChange}
        label={'Nom de la structure'}
        name="label"
        required
        value={form.label}
      />
      <RequiredLabel label={'Structure parente'} required={true} />
      <select
        onChange={(e: any) =>
          setForm({...form, parentCategoryUuid: e.target.value})
        }
        value={!!form.parentCategoryUuid ? form.parentCategoryUuid : ''}
        css={styles.select}>
        <option value={undefined}>{t('NO_PARENT')}</option>;
        {newVendorStructures?.map((category: any) => {
          return <CatItem item={category} />;
        })}
      </select>

      <SelectList
        label={'Taxes'}
        name="taxUuid"
        list={data}
        value={form.taxUuid}
        onChange={handleChange}
        manageByFormik={false}
        required
      />
      <FieldsRequired />
    </ModalWrapper>
  );
};

const CatItem = ({item}: any) => {
  return (
    <>
      <option value={item.uuid}>{helperLabelTranslation(item)}</option>
      <SubCatItem item={item} />
    </>
  );
};

const SubCatItem = ({item}: any) => {
  if (item.child?.length === 0) return <></>;
  return (
    <>
      {item.child?.map((subCat: any) => {
        return <CatItem item={subCat} />;
      })}
    </>
  );
};

const styles = {
  select: css({
    width: '100%',
    height: 39,
    border: 'none',
    borderRadius: 2,
    backgroundColor: 'white',
    padding: '0 10px',
  }),
};

import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';

export const getReceipts = async (data: {
  search: string;
  from: Date;
  to: Date;
  offset: number;
  limit: number;
  channels: string;
  consumptionModes?: string;
  amountMin?: number | null;
  amountMax?: number | null;
  orderBy?: object[];
}) => {
  //
  const query = helperGenerateQuery(data);

  const res: any = await axiosInstance
    .get(`/pos/backoffice/pos-receipts${query}`, {
      params: {
        orderBy: JSON.stringify(data.orderBy),
      },
    })
    .catch(err => {
      console.log('❌ getReceipts : ', err);
    });
  return res?.data?.data;
};

export const getReceiptDetails = async (posReceiptUuid: string | number) => {
  const res: any = await axiosInstance
    .get(`/pos/backoffice/pos-receipts/${posReceiptUuid}`)
    .catch(err => {
      console.log('❌ getReceiptDetails : ', err);
    });
  return res?.data?.data;
};

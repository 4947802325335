/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Title} from '../../../../components/atoms/Title';
import {DataGridFooter} from '../../../../components/newDatagrid/DataGridFooter';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {helperFormatPrice} from '../../../../helpers/helperFormatPrice';
import {columnsGapOld} from './columns';
import {useGapPosOld} from '../../../../hooks/useGapPosOld';

export const SectionGapByPosTerminalOld = () => {
  const {gapPosLoading, gapPos} = useGapPosOld();

  const totalGap =
    gapPos?.reduce(
      (acc: number, curr) =>
        acc +
        curr.countingEvents?.reduce(
          (_acc: number, _curr) => _acc + _curr.delta,
          0,
        ),
      0,
    ) || 0;

  return (
    <div css={styles.container}>
      <Title title="Écarts de caisse" />
      <NewDataGrid
        columns={columnsGapOld}
        rows={gapPos || []}
        rowHeight={35}
        loading={gapPosLoading}
        minHeight={200}
        footer={
          <DataGridFooter
            columns={['TOTAL', `${helperFormatPrice(totalGap)}€`]}
            height={35}
          />
        }
        stickyFooter
      />
    </div>
  );
};

const styles = {
  container: css({
    height: '55%',
    '@media (max-width: 1024px)': {
      flex: 'none',
      marginBottom: '5%',
    },
  }),
};

import {SVGProps} from 'react';

export const EventsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.59216 8.5166L10.3324 8.74219C10.6125 8.77734 10.7246 9.08496 10.5203 9.26074L9.26453 10.3506L9.56116 11.8887C9.6073 12.1377 9.31396 12.3281 9.06348 12.2109L7.50781 11.4844L5.95215 12.2109C5.70166 12.3281 5.40833 12.1377 5.45447 11.8887L5.7511 10.3506L4.49536 9.26074C4.29102 9.08496 4.40308 8.77734 4.68323 8.74219L6.42346 8.5166L7.20129 7.11621C7.32654 6.89062 7.68909 6.89062 7.81433 7.11621L8.59216 8.5166ZM4.34375 0C4.7821 0 5.13477 0.314941 5.13477 0.703125V1.875H9.88086V0.703125C9.88086 0.314941 10.2335 0 10.6719 0C11.1102 0 11.4629 0.314941 11.4629 0.703125V1.875H12.7812C13.9447 1.875 14.8906 2.71436 14.8906 3.75V13.125C14.8906 14.1592 13.9447 15 12.7812 15H2.23438C1.06927 15 0.125 14.1592 0.125 13.125V3.75C0.125 2.71436 1.06927 1.875 2.23438 1.875H3.55273V0.703125C3.55273 0.314941 3.9054 0 4.34375 0ZM13.3086 5.625H1.70703V13.125C1.70703 13.3828 1.94302 13.5938 2.23438 13.5938H12.7812C13.0713 13.5938 13.3086 13.3828 13.3086 13.125V5.625Z"
      fill="white"
    />
  </svg>
);

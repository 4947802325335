import {toast} from 'react-toastify';
export const helperDisplayToast = (message: string, success = false) => {
  const option: any = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  if (success) {
    toast.success(message, option);
  } else {
    toast.error(message, option);
  }
};

import {ICardFetchReturn} from '@bill-app-types/b-types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ISlice {
  updatedCard: ICardFetchReturn | null;
  newTimings: any;
}

export type IUpdatedCardKey = keyof Pick<
  ICardFetchReturn,
  'label' | 'hasTimings'
>;

const initialState: ISlice = {
  updatedCard: null,
  newTimings: null,
};

const slice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    updatedCardAction(state, action) {
      state.updatedCard = action.payload;
    },
    updatedCardKeyAction(
      state,
      action: PayloadAction<{
        key: keyof Pick<ICardFetchReturn, 'label' | 'hasTimings'>;
        value: string | boolean;
      }>,
    ) {
      const {key, value} = action.payload;
      if (state.updatedCard) {
        if (key === 'hasTimings') {
          state.updatedCard[key] = Boolean(value);
        } else {
          state.updatedCard[key] = String(value);
        }
      }
    },
    newTimingsAction(state, action) {
      state.newTimings = action.payload;
    },
  },
});

export const {updatedCardAction, updatedCardKeyAction, newTimingsAction} =
  slice.actions;

export default slice.reducer;

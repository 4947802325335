import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  moduleSelected: any;
  subscriptionSelected: any;
  subscriptions: any;
}

const initialState: ISlice = {
  moduleSelected: null,
  subscriptionSelected: null,
  subscriptions: []
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: initialState,
  reducers: {
    subscriptionSelectedAction: (state, action) => {
      state.subscriptionSelected = action.payload;
    },
    moduleSelectedAction: (state, action) => {
      state.moduleSelected = action.payload;
    },
    subscriptionsAction: (state, action) => {
      state.subscriptions = action.payload;
    }
  },
});

export const {
  subscriptionSelectedAction,
  moduleSelectedAction,
  subscriptionsAction
} =
  subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import Right from './Right';
import Center from './Center';
import {HorizontalBar} from '../../../../../../../components/atoms/HorizontalBar';
import {BadgeCounter} from '../../../../../../../components/atoms/BadgeCounter';
import {THEME} from '../../../../../../../styles/theme';
import {useDispatch} from 'react-redux';
import {
  resetSelectedButtonsAction,
  selectedPosCategoryAction,
} from '../../../../../../../redux/myModulesSection/pos';
import {useAppSelector} from '../../../../../../../hooks/useReduxHook';
import {SortableElement} from 'react-sortable-hoc';

const SimpleCard = SortableElement(
  ({item, isSubCategory = false}: {item: any; isSubCategory?: boolean}) => {
    const dispatch = useDispatch();

    const selectedPosCategory = useAppSelector(
      s => s.myModulesSection.pos.selectedPosCategory,
    );

    const handleSelectCategory = () => {
      dispatch(selectedPosCategoryAction(item));
      dispatch(resetSelectedButtonsAction());
    };

    return (
      <div
        onClick={handleSelectCategory}
        css={[
          styles.container,
          {
            marginLeft: isSubCategory ? 10 : 0,
            borderColor:
              selectedPosCategory?.uuid === item?.uuid
                ? THEME.colors.primary
                : THEME.colors.lightGray,
          },
        ]}>
        <BadgeCounter count={item.position} />
        <HorizontalBar color={item.color} />
        <Center category={item} />
        <Right category={item} />
      </div>
    );
  },
);

export default SimpleCard;

const styles = {
  container: css({
    position: 'relative',
    backgroundColor: '#FFF',
    height: 61,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    border: `2px solid ${THEME.colors.lightGray}`,
    borderRadius: 2,
    cursor: 'pointer',
    userSelect: 'none',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../../../../../styles/theme';
import {TextVariant} from '../../../../../../components/atoms/TextVariant';
import {PillLabel} from '../../../../../../components/molecules/PillLabel';
import {NewDataGrid} from '../../../../../../components/newDatagrid/NewDataGrid';
import {OptionsColumns} from './OptionsColumns';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {useQuery} from '@tanstack/react-query';
import {getStatItemDetailCoursesChilds} from '../../../../../../api/fetchStats';
import {useParams} from 'react-router-dom';
import {helperJoinFilterValues} from '../../../../../../helpers/helperFilter';
import {M_SCREEN_DESKTOP} from '../../../../../../constants/ResponsiveSize';
import {useFlagBCatalog} from '../../../../../../hooks/useFlagBCatalog';

export const Options = () => {
  const dates = useAppSelector(s => s.filter.dates);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const channels = useAppSelector(s => s.filter.channels);
  const currentMode = useAppSelector(s => s.filter.currentMode);
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {id} = useParams();

  const {data} = useQuery(
    [
      'getStatItemDetailCoursesChilds',
      flagBCatalogEnabled,
      dates,
      consumptionModes,
      channels,
    ],
    () =>
      getStatItemDetailCoursesChilds(String(id), {
        from: dates.from,
        to: dates.to,
        consumptionModeIds: helperJoinFilterValues(consumptionModes, true),
        channelIds: helperJoinFilterValues(channels, true),
        forStats: !flagBCatalogEnabled,
      }),
    {
      enabled: !!id && flagBCatalogReady,
    },
  );

  const datas =
    (data &&
      data?.reduce((arr, item) => {
        const childs = item?.items.map(child => ({
          ...child,
          isItem: true,
          salesValue: child.sales[currentMode],
        }));
        return [...arr, item, ...childs];
      }, [] as any)) ||
    [];

  return (
    <div css={styles.container}>
      <div>
        <TextVariant
          text="VENTES OPTIONS,SOUS-OPTIONS"
          variant="textSemiBold"
        />
        <div className="flex" css={styles.pills}>
          <PillLabel
            text="OPTIONS & SOUS-OPTIONS"
            backgroundColor={THEME.colors.pink}
            variant="textSemiBold"
          />
          {/* <PillLabel
            text="RETRAITS"
            backgroundColor={THEME.colors.red}
            variant="textSemiBold"
          />
          <PillLabel
            text="AJOUTS"
            backgroundColor={THEME.colors.green}
            variant="textSemiBold"
          /> */}
        </div>
      </div>

      <div css={styles.optionsList}>
        <NewDataGrid
          rowHeight={37}
          columns={OptionsColumns}
          rows={datas}
          showBorderBottom
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    width: '50%',
    backgroundColor: THEME.colors.white,
    padding: '2vh',
    [M_SCREEN_DESKTOP]: {
      width: '100%',
    },
  }),
  pills: css({
    marginTop: '0.5rem',
    gap: '0.5rem',
  }),
  optionsList: css({
    height: '23rem',
    marginTop: '1.5rem',
  }),
};

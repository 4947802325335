import {useQuery} from '@tanstack/react-query';
import {getCustomerOrders} from '../api/fetchCustomers';
import {useAppSelector} from './useReduxHook';
import {useFlagBCatalog} from './useFlagBCatalog';

export const useCustomerOrders = (offset: number, limit: number) => {
  const selectedCustomer = useAppSelector(s => s.customer.selectedCustomer);
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();
  const {data, isLoading, error} = useQuery(
    ['getCustomerOrders', offset, limit, selectedCustomer?.uuid],
    () => getCustomerOrders({offset, limit}, selectedCustomer?.uuid),
    {
      enabled: !flagBCatalogEnabled && !!flagBCatalogReady,
    },
  );

  return {
    data: data?.responseData || [],
    totalOrders: data?.totalOrder,
    isLoading,
    error,
  };
};

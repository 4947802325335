import {createSlice} from '@reduxjs/toolkit';

interface IState {
  areaProductionSelected: any;
  areaProductionUpdated: any;
  showProducts: boolean;
  areaProductionsItems: any;
  productCategorySelected: any;
}

const initialState: IState = {
  areaProductionSelected: null,
  areaProductionUpdated: null,
  showProducts: false,
  productCategorySelected: null,
  areaProductionsItems: [],
};

const slice = createSlice({
  name: 'areas',
  initialState,
  reducers: {
    areaProductionSelectedAction: (state, action) => {
      state.areaProductionSelected = action.payload;
    },
    areaProductionUpdatedAction: (state, action) => {
      state.areaProductionUpdated = action.payload;
    },
    showAreaProductionProductsAction: (state, action) => {
      state.showProducts = action.payload;
    },
    productCategorySelectedAction: (state, action) => {
      state.productCategorySelected = action.payload;
    },
    areaProductionsItemsAction: (state, action) => {
      state.areaProductionsItems = action.payload;
    },
  },
});

export const {
  areaProductionSelectedAction,
  showAreaProductionProductsAction,
  areaProductionsItemsAction,
  productCategorySelectedAction,
  areaProductionUpdatedAction,
} = slice.actions;

export default slice.reducer;

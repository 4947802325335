/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';

export const FilterNumberInput = ({
  name,
  value,
  onChange,
  Icon,
  iconColor = THEME.colors.primary,
}: {
  name: string;
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  Icon?: React.FC<{color?: string}>;
  iconColor?: string;
}) => {
  return (
    <div css={styles.inputContainer}>
      {Icon && (
        <Icon color={value === 0 ? THEME.colors.lightGray : iconColor} />
      )}
      <input
        className="textSemiBold"
        css={[
          styles.input(iconColor),
          value === 0 && {color: THEME.colors.lightGray},
        ]}
        name={name}
        value={value}
        onChange={onChange}
        pattern="[0-9]"
      />
    </div>
  );
};

const styles = {
  inputContainer: css({
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${THEME.colors.bgLight}`,
    color: THEME.colors.primary,
    padding: 5,
    borderRadius: 2,
    width: '35%',
  }),
  input: (color: string) =>
    css({
      border: 'none',
      outline: 'none',
      width: '70%',
      marginLeft: 5,
      color: color || THEME.colors.primary,
    }),
};

import {useQuery} from '@tanstack/react-query';
import {getPaymentSettings} from '../api/fetchPaymentSettings';

export const usePaymentSettings = () => {
  const {
    data,
    isLoading,
    refetch,
  } = useQuery(['getPaymentSettings'], getPaymentSettings);

  return {
    data,
    refetch,
    isLoading,
  };
};

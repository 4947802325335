import { helperLabelTranslation } from "./helperLabel";

export const helperFormatSelectItem = (item: any) => {
  const label =
    item.name || helperLabelTranslation(item)

  return {
    label: label,
    value: item.uuid || item.id || item.value,
  };
};

import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../hooks/useReduxHook';

export const UtilsContext = React.createContext({});

export const UtilsProvider = ({children}: {children: ReactNode}) => {
  //
  const {t, i18n} = useTranslation();
  const dispatch = useAppDispatch();
  const store: any = {t, i18n, dispatch};

  return (
    <UtilsContext.Provider value={store}>{children}</UtilsContext.Provider>
  );
};

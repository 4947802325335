/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext, useEffect} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {sectionShowAction} from '../../redux/app';
import {THEME} from '../../styles/theme';

export const ToggleButtonSections = ({
  sections,
  defaultSection,
  width = 'none',
  localManagerState = false,
  onChange,
  marginBottom = '2vh',
}: {
  sections: string[];
  defaultSection: string;
  width?: string | number;
  localManagerState?: boolean;
  onChange?: (section: string) => void;
  marginBottom?: string | number;
}) => {
  //
  const {t, dispatch} = useContext<any>(UtilsContext);
  const sectionShow = useAppSelector(s => s.app.sectionShow);

  useEffect(() => {
    if (localManagerState) return;
    dispatch(sectionShowAction(defaultSection));
  }, [dispatch, defaultSection, localManagerState]);

  return (
    <div css={styles.container(marginBottom)}>
      {sections.map((section: any, index: number) => {
        const active =
          sectionShow === section ||
          (localManagerState && section === defaultSection);
        return (
          <button
            type="button"
            key={index}
            onClick={() => {
              if (localManagerState) {
                onChange && onChange(section);
                return;
              }
              dispatch(sectionShowAction(section));
            }}
            css={[styles.btn(width, active), active && styles.btnActive]}
            className="textMedium">
            {t(section)}
          </button>
        );
      })}
    </div>
  );
};

const styles = {
  container: (marginBottom: string | number) =>
    css({
      // width: '100%',
      marginBottom: marginBottom,
      display: 'flex',
      backgroundColor: 'white',
      padding: '0.2rem',
      overflowX: 'auto',
      height: 37,
    }),
  btn: (width: string | number, active: boolean) =>
    css({
      border: 'none',
      padding: '0 2vh',
      backgroundColor: 'white',
      color: '#A7A7AB',
      cursor: 'pointer',
      width,
      '&:hover': {
        backgroundColor: !active ? THEME.colors.bgLight : THEME.colors.primary,
        opacity: 0.6,
      },
    }),
  btnActive: css({
    backgroundColor: THEME.colors.primary,
    color: THEME.colors.white,
  }),
};

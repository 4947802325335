import {helperLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';
import {ISubscriptionCardFetchReturn} from '@bill-app-types/b-types';

export const getSubscriptions = async () => {
  //
  helperLog({message: 'getSubscriptions'});
  const res: any = await axiosInstance
    .get(`/bcore/protected/admin/subscription?language_id=1`)
    .catch(err => {
      helperLog({message: 'getSubscriptions', type: 'API_ERROR', data: err});
    });
  return res?.data?.data;
};

export interface ITimings {
  [key: number]: {
    start: number;
    end: number;
  }[];
}

export const updateSubscriptionTimings = async (
  subscriptionId: string,
  data: {
    timings: ITimings;
  },
) => {
  //
  const res = await axiosInstance
    .put<{
      status: number;
      message: string;
    }>(`/bcore/protected/admin/subscription/${subscriptionId}/timings`, data)
    .catch(err => {
      helperLog({
        message: 'updateSubscriptionTimings',
        type: 'API_ERROR',
        data: err,
      });
    });
  return res?.data;
};

export const activateSubscription = async (
  brandId: string,
  subscriptionId: string,
) => {
  //

  const res = await axiosInstance
    .put<{
      status: number;
      message: string;
    }>(
      `/bcore/protected/admin/${brandId}/subscription/${subscriptionId}/activate`,
    )
    .catch(err => {
      helperLog({
        message: 'activateSubscription',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res?.data;
};

export const deactivateSubscription = async (
  brandId: string,
  subscriptionId: string,
) => {
  const res = await axiosInstance
    .delete<{
      status: number;
      message: string;
    }>(
      `/bcore/protected/admin/${brandId}/subscription/${subscriptionId}/deactivate`,
    )
    .catch(err => {
      helperLog({
        message: 'deactivateSubscription',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res?.data;
};

export const getSubscriptionCards = async (subscriptionUuid: string) => {
  //
  helperLog({message: 'getSubscriptionCards'});
  const res = await axiosInstance
    .get<{
      data: ISubscriptionCardFetchReturn[];
    }>(`/bcatalog/backoffice/subscriptions/${subscriptionUuid}/cards`)
    .catch(err => {
      helperLog({
        message: 'getSubscriptionCards',
        type: 'API_ERROR',
        data: err,
      });
    });
  return res?.data?.data;
};

export const enableSubscriptionCard = async (
  subscriptionUuid: string,
  cardUuid: string,
) => {
  const res: any = await axiosInstance
    .post(`/bcatalog/backoffice/subscriptions/${subscriptionUuid}/cards`, {
      cardUuid,
    })
    .catch(err => {
      helperLog({
        message: 'enableSubscriptionCard',
        type: 'API_ERROR',
        data: err,
      });
    });
  return res;
};

export const disableSubscriptionCard = async (
  subscriptionUuid: string,
  cardUuid: string,
) => {
  const res: any = await axiosInstance
    .delete(
      `/bcatalog/backoffice/subscriptions/${subscriptionUuid}/cards/${cardUuid}`,
    )
    .catch(err => {
      helperLog({
        message: 'disableSubscriptionCard',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res;
};

export const getSubscriptionOrderSlots = async (subscription: string) => {
  const res = await axiosInstance
    .get(`/bcore/protected/admin/subscription/${subscription}/ordering-slot`)
    .catch(err => {
      helperLog({
        message: 'getSubscriptionOrderSlots',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res?.data?.data;
};

export const updateSubscriptionOrderSlots = async (
  subscriptionId: string,
  data: {
    timings: ITimings;
    maxOrder: number;
    offset: number;
    minutes: number;
  },
) => {
  const res = await axiosInstance
    .put(
      `/bcore/protected/admin/subscription/${subscriptionId}/ordering-slot`,
      data,
    )
    .catch(err => {
      helperLog({
        message: 'updateSubscriptionOrderSlots',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res?.data;
};

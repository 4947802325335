/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ButtonCustom} from '../../../components/atoms/ButtonCustom';
import {THEME} from '../../../styles/theme';
import {useTranslation} from 'react-i18next';
import {StarIcon} from '../../../assets/svgs/StarIcon';
import {useQuery} from '@tanstack/react-query';
import {useAppSelector} from '../../../hooks/useReduxHook';
import {getCustomerDetails} from '../../../api/fetchCustomers';
import {OrderStatusButtons} from './OrderStatusButtons';
import {UserTicketIcon} from '../../../assets/svgs/UserTicketIcon';
import {UserIcon} from '../../../assets/svgs/UserIcon';
import {MailIcon} from '../../../assets/svgs/MailIcon';
import {PhoneIcon} from '../../../assets/svgs/PhoneIcon';
import {helperDate} from '../../../helpers/helperDate';

export const OrderDetailsRightBlock = () => {
  const {t} = useTranslation();

  const selectedOrder = useAppSelector(s => s.order.selectedOrder);

  const {data} = useQuery(
    ['getCustomerDetails', selectedOrder],
    () =>
      selectedOrder ? getCustomerDetails(selectedOrder.customerUuid) : null,
    {
      enabled: !!selectedOrder?.customerUuid,
    },
  );

  return (
    <div css={styles.container}>
      <div css={styles.user}>
        {data && (
          <>
            <h2 className="title02">{`${data?.firstname} ${data?.lastname}`}</h2>

            <div css={styles.stars(!!data?.loyaltyBalance)}>
              <StarIcon />
              {`${data?.loyaltyBalance || 0} pts`}
            </div>

            <div>
              <p className="textSemiBold" css={styles.userInfos}>
                <div css={styles.icon}>
                  <UserIcon />
                </div>
                Création du compte
                <span className="textMedium" css={styles.infos}>
                  {`${helperDate(data?.createdAt, 'DD/MM/YYYY à HH:mm:ss')}`}
                </span>
              </p>
            </div>
            <div>
              <p className="textSemiBold" css={styles.userInfos}>
                <div css={styles.icon}>
                  <UserTicketIcon />
                </div>
                Dernière commande{' '}
                <span className="textMedium" css={styles.infos}>
                  {`${helperDate(
                    data?.lastOrderDate,
                    'DD/MM/YYYY à HH:mm:ss',
                  )}`}
                </span>
              </p>
            </div>
            <div>
              <p className="textSemiBold" css={styles.userInfos}>
                <div css={styles.icon}>
                  <MailIcon />
                </div>
                Mail
                <span className="textMedium" css={styles.infos}>
                  {data?.email}
                </span>
              </p>
            </div>
            <div>
              <p className="textSemiBold" css={styles.userInfos}>
                <div css={styles.icon}>
                  <PhoneIcon />
                </div>
                Téléphone
                <span className="textMedium" css={styles.infos}>
                  {data?.phone}
                </span>
              </p>
            </div>
          </>
        )}
      </div>

      <div css={styles.statusBlock}>
        <p css={styles.statusTitle}>Status de la commande</p>
        <OrderStatusButtons
          orderUuid={selectedOrder?.uuid}
          currentStatus={selectedOrder?.orderStatus}
        />
      </div>

      <div css={styles.footer}>
        <ButtonCustom
          label={t('SYNC_POS')}
          backgroundColor={THEME.colors.red}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    padding: '1.5% 2%',
    minHeight: '36vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '76%',
  }),
  user: css({
    minHeight: '45%',
  }),
  stars: (show = true) =>
    css({
      color: THEME.colors.yellow,
      display: 'flex',
      gap: 5,
      alignItems: 'center',
      marginBottom: '3%',
      opacity: show ? 1 : 0,
    }),
  userInfos: css({
    color: THEME.colors.gray2,
    marginBottom: 5,
    textTransform: 'uppercase',
    display: 'flex',
  }),
  icon: css({
    width: '2.5%',
  }),
  infos: css({
    color: THEME.colors.primary,
    marginLeft: 5,
    textTransform: 'lowercase',
    fontSize: 12,
  }),
  statusBlock: css({
    display: 'flex',
  }),
  statusTitle: css({
    textTransform: 'uppercase',
    marginRight: '2%',
    alignItems: 'center',
    display: 'flex',
  }),
  footer: css({
    width: '100%',
    textAlign: 'end',
  }),
};

import {SVGProps} from 'react';

export const HistoInvoiceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.75781 4.21875C9.18643 4.21875 9.53125 4.53223 9.53125 4.92188C9.53125 5.31152 9.18643 5.625 8.75781 5.625H4.11719C3.68857 5.625 3.34375 5.31152 3.34375 4.92188C3.34375 4.53223 3.68857 4.21875 4.11719 4.21875H8.75781ZM8.75781 9.375C9.18643 9.375 9.53125 9.68848 9.53125 10.0781C9.53125 10.4678 9.18643 10.7813 8.75781 10.7813H4.11719C3.68857 10.7813 3.34375 10.4678 3.34375 10.0781C3.34375 9.68848 3.68857 9.375 4.11719 9.375H8.75781ZM3.34375 7.5C3.34375 7.11035 3.68857 6.79688 4.11719 6.79688H8.75781C9.18643 6.79688 9.53125 7.11035 9.53125 7.5C9.53125 7.88965 9.18643 8.20313 8.75781 8.20313H4.11719C3.68857 8.20313 3.34375 7.88965 3.34375 7.5ZM2.82812 1.1833L4.13008 0.169277C4.42012 -0.0564258 4.84551 -0.0564258 5.13555 0.169277L6.4375 1.1833L7.73945 0.169277C8.02949 -0.0564258 8.45488 -0.0564258 8.74492 0.169277L10.0469 1.1833L11.3488 0.169277C11.5776 -0.00941602 11.8999 -0.0503906 12.1738 0.0643359C12.4478 0.179092 12.625 0.428613 12.625 0.703125V14.2969C12.625 14.5723 12.4478 14.8213 12.1738 14.9355C11.8999 15.0498 11.5776 15.0088 11.3488 14.8301L10.0469 13.8164L8.74492 14.8301C8.45488 15.0557 8.02949 15.0557 7.73945 14.8301L6.4375 13.8164L5.13555 14.8301C4.84551 15.0557 4.42012 15.0557 4.13008 14.8301L2.82812 13.8164L1.52682 14.8301C1.29736 15.0088 0.974775 15.0498 0.700205 14.9355C0.425828 14.8213 0.25 14.5723 0.25 14.2969V0.703125C0.25 0.428613 0.425828 0.179063 0.700205 0.0643359C0.974775 -0.0503906 1.29736 -0.00941602 1.52682 0.169277L2.82812 1.1833ZM2.32475 12.3574C2.61446 12.1318 3.04179 12.1318 3.3315 12.3574L4.63281 13.3711L5.93477 12.3574C6.2248 12.1318 6.6502 12.1318 6.94023 12.3574L8.24219 13.3711L9.54414 12.3574C9.83418 12.1318 10.2596 12.1318 10.5496 12.3574L11.0781 12.7676V2.23184L10.5496 2.64316C10.2596 2.86904 9.83418 2.86904 9.54414 2.64316L8.24219 1.6292L6.94023 2.64316C6.6502 2.86904 6.2248 2.86904 5.93477 2.64316L4.63281 1.6292L3.3315 2.64316C3.04179 2.86904 2.61446 2.86904 2.32475 2.64316L1.79688 2.23184V12.7676L2.32475 12.3574Z"
      fill={props.color}
    />
  </svg>
);

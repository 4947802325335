/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useEffect, useState} from 'react';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../../components/layouts/Header';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {useExercice} from '../../../../../hooks/useExercice';
import {columns} from './columns';
import {Bottom} from './HeaderSections/Bottom';
import {ErrorMessage} from '../../../../../components/atoms/ErrorMessage';

export const Days = withRedirectAndNavigation(() => {
  //
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [rowCount, setRowCount] = useState(0);

  const offset = (page - 1) * limit;

  const {data, isLoading, totalExercices, hasLocalCloudDifferentTotals} = useExercice(offset, limit);

  useEffect(() => {
    setRowCount(oldRowCount =>
      totalExercices !== undefined ? totalExercices : oldRowCount,
    );
  }, [totalExercices]);

  return (
    <div css={styles.container}>
      <Header bottomSection={<Bottom />} />
      {hasLocalCloudDifferentTotals && <ErrorMessage message='Attention ! Nous avons identifié que certaines données sont potentiellement manquantes suite à une erreur de synchronisation sur les résultats actuels' />}
      <div css={styles.content}>
        <NewDataGrid
          columns={columns}
          rows={data}
          loading={isLoading}
          hasPagination
          page={page}
          setPage={setPage}
          totalRows={rowCount}
          rowPerPage={limit}
        />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: {
    padding: '2vh',
    height: '84%',
  },
};

export const OnSpotIcon = () => (
  <svg
    width="9"
    height="11"
    viewBox="0 0 9 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.87886 4.01852C5.87886 4.87168 5.18784 5.56269 4.33468 5.56269C3.48152 5.56269 2.79051 4.87168 2.79051 4.01852C2.79051 3.16536 3.48152 2.47434 4.33468 2.47434C5.18784 2.47434 5.87886 3.16536 5.87886 4.01852ZM4.33468 3.40085C3.99303 3.40085 3.71701 3.67687 3.71701 4.01852C3.71701 4.36017 3.99303 4.63619 4.33468 4.63619C4.67633 4.63619 4.95235 4.36017 4.95235 4.01852C4.95235 3.67687 4.67633 3.40085 4.33468 3.40085ZM8.0407 4.01852C8.0407 5.70553 5.78235 8.70895 4.79214 9.94815C4.55473 10.2435 4.11464 10.2435 3.87722 9.94815C2.86965 8.70895 0.628662 5.70553 0.628662 4.01852C0.628662 1.97172 2.28788 0.3125 4.33468 0.3125C6.38071 0.3125 8.0407 1.97172 8.0407 4.01852ZM4.33468 1.239C2.80016 1.239 1.55517 2.484 1.55517 4.01852C1.55517 4.25787 1.64183 4.62847 1.85049 5.1226C2.05278 5.6013 2.33999 6.13018 2.67083 6.66485C3.21902 7.55661 3.85599 8.40205 4.33468 9.0313C4.81338 8.40205 5.45035 7.55661 5.99853 6.66485C6.3286 6.13018 6.6162 5.6013 6.81887 5.1226C7.02734 4.62847 7.1142 4.25787 7.1142 4.01852C7.1142 2.484 5.8692 1.239 4.33468 1.239Z"
      fill="white"
    />
  </svg>
);

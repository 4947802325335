/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Title} from '../../../../components/atoms/Title';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {Center} from './HeaderSections/Center';
import {Items} from './Items';
import {MODIFIER_TYPES, PRODUCT_TYPES} from '@bill-app-types/b-types';
import {Categories} from './Categories';
import {Suggestions} from './Suggestions';
import {Modifiers} from './Modifiers';
import {useTranslation} from 'react-i18next';
import {BKiosk} from './Bkiosk';
import {General} from './General';
import {BWebApp} from './BWebApp';
import {AreaServices} from './AreaServices';
import {useBrandAccess} from '../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../constants/frontPolicies';

export const Translations = withRedirectAndNavigation(() => {
  const {t} = useTranslation();
  const {brandHasAuthorization} = useBrandAccess();

  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} />
      <div css={styles.content}>
        <Title title="Traductions" />
        <div css={styles.body}>
          {brandHasAuthorization(BRAND_AUTHORIZATION.CHILD) && (
            <>
              <General />
              <BKiosk />
              <BWebApp />
              <AreaServices />
            </>
          )}

          <Items title={t('PRODUCTS')} type={PRODUCT_TYPES.COURSE} />
          <Items title={t('MENUS')} type={PRODUCT_TYPES.MENU} />
          <Items title={t('OPTIONS')} type={PRODUCT_TYPES.OPTION_ITEM} />
          <Modifiers title={t('SUBMENUS')} type={MODIFIER_TYPES.SUB_MENU} />
          <Modifiers
            title={t('CATEGORIES_OPTIONS')}
            type={MODIFIER_TYPES.OPTION}
          />
          <Categories title={t('CATEGORIES_PRODUCTS')} />
          <Suggestions title={t('CATEGORIES_SUGGESTIONS')} />
        </div>
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: css({
    padding: '2vh',
    display: 'flex',
    flexDirection: 'column',
    height: '87vh',
  }),
  body: css({
    flex: 1,
    padding: '1vh 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '1vh',
    overflowY: 'auto',
  }),
};

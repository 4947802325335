/** @jsxImportSource @emotion/react */
import InfiniteScroll from 'react-infinite-scroll-component';

export const InfiniteScrollCustom = ({
  items,
  fetchData,
  refresh,
  children,
  scrollableTarget,
  isLoading,
}: {
  items: any;
  fetchData: () => void;
  refresh: () => void;
  children?: React.ReactNode;
  scrollableTarget?: string;
  isLoading: boolean;
}) => {
  return (
    <InfiniteScroll
      dataLength={items?.length} //This is important field to render the next data
      next={fetchData}
      hasMore={isLoading}
      loader={<></>}
      scrollableTarget={scrollableTarget}
      // below props only if you need pull down functionality
      refreshFunction={refresh}
      pullDownToRefresh
      pullDownToRefreshThreshold={50}>
      {children}
    </InfiniteScroll>
  );
};

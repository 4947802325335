import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../hooks/useReduxHook';
import {addProductModalAction} from '../../redux/modals';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {useNavigate} from 'react-router-dom';
import {productPreRegisterAction} from '../../redux/myCatalogSection/products';
import {helperDisplayToast} from '../../helpers/helperToast';
import {createProduct} from '../../api/fetchProducts';
import {useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {InputLabel} from '../atoms/InputLabel';
import {useVendorStructure} from '../../hooks/useVendorStructure';
import {SelectList} from '../molecules/SelectList';
import {helperFormatSelectItem} from '../../helpers/helperFormatSelectItem';

export const AddProductModal = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clientQuery = useQueryClient();

  const {vendorStructure: vendorStructures} = useVendorStructure();

  const [form, setForm] = useState({
    label: '',
    vendorStructureUuid: '',
    price: 0,
  });

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;
    setForm({...form, [name]: value});
  };

  const onCancel = () => {
    dispatch(addProductModalAction(false));
  };

  const handleCreateProduct = async (isSave?: boolean) => {
    if (form.label) {
      const newData = {
        ...form,
        backofficeLabel: form.label,
        productionLabel: form.label,
        description: form.label,
        composition: form.label,
        loyaltyCost: 0,
        loyaltyGain: 0,
        isArchived: false,
      };
      const res = await createProduct({
        ...newData,
        price: Math.round((newData.price || 0) * 100),
      });
      if (res?.status === 200) {
        helperDisplayToast('Produit créé', true);
        onCancel();
        if (isSave) {
          clientQuery.invalidateQueries(['fetchProducts']);
        } else {
          dispatch(
            productPreRegisterAction({
              ...newData,
              uuid: res?.data?.data?.uuid,
            }),
          );
          navigate('/online/catalog/products/edit');
        }
      } else {
        helperDisplayToast(
          res?.data?.message || 'Impossible de créer le produit',
          false,
        );
      }
    } else {
      helperDisplayToast(
        'Tous les champs obligatoires doivent être complétés',
        false,
      );
    }
  };

  const handleSave = () => {
    handleCreateProduct(true);
  };

  const handleSubmit = () => {
    handleCreateProduct();
  };

  return (
    <ModalWrapper
      title={t('ADD_PRODUCT')}
      minWidth="37%"
      validateLabel={t('GO_ADD')}
      hideCancelButton
      onCancel={onCancel}
      onSubmit={handleSubmit}
      onSave={handleSave}
      saveLabel={t('REGISTER_AND_CONTINUE_CREATION_LATER')}
      btnSaveWidth={'60%'}
      btnValidateWidth={'38%'}
      initialValues={form}
      disabled={form.label === '' || form.vendorStructureUuid === ''}>
      <InputLabel
        label={t('PRODUCT_NAME')}
        name="label"
        value={form.label}
        onChange={handleChange}
        required
      />

      <SelectList
        label={t('VENDOR_STRUCTURE')}
        placeHolder={t('VENDOR_STRUCTURE')}
        name="vendorStructureUuid"
        value={form?.vendorStructureUuid}
        list={vendorStructures?.map(helperFormatSelectItem)}
        onChange={handleChange}
        manageByFormik={false}
        required
      />

      <InputLabel
        label={t('PRICE')}
        name="price"
        value={form.price.toString()}
        onChange={handleChange}
        required
      />
      <FieldsRequired />
    </ModalWrapper>
  );
};

/** @jsxImportSource @emotion/react */

import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {ButtonCustom} from '../../../../../../components/atoms/ButtonCustom';
import {UtilsContext} from '../../../../../../contexts/UtilsContext';
import {THEME} from '../../../../../../styles/theme';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useReduxHook';
import {updateCard} from '../../../../../../api/fetchCards';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../../../../../helpers/helperToast';
import {newTimingsAction} from '../../../../../../redux/myCatalogSection/cards';
import {BRAND_AUTHORIZATION} from '../../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../../hooks/useBrandAccess';

export const SelectedCardHeaderCenter = ({cardUuid}: {cardUuid: string}) => {
  const {t} = useContext<any>(UtilsContext);
  const dispatch = useAppDispatch();

  const {brandHasAuthorization} = useBrandAccess();

  const clientQuery = useQueryClient();

  const {updatedCard, newTimings} = useAppSelector(
    s => s.myCatalogSection.cards,
  );

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate('/online/catalog/cards');
  };

  const handleRegister = async () => {
    if (!updatedCard) return;

    const res = await updateCard(
      {
        label: updatedCard?.label,
        timings: updatedCard?.hasTimings
          ? !!newTimings
            ? newTimings
            : updatedCard?.timings
          : null,
      },
      cardUuid,
    );
    if (res?.status === 204) {
      helperDisplayToast('Carte modifiée', true);
      handleCancel();
      clientQuery.invalidateQueries(['fetchCards']);
      dispatch(newTimingsAction(null));
    } else {
      helperDisplayToast(
        res?.data?.message || 'Impossible de modifier la carte',
        false,
      );
    }
  };

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const isDisabled =
    !hasAuthorization ||
    !!(
      updatedCard?.hasTimings &&
      newTimings &&
      Object.values(newTimings).some((timing: any) => timing.length === 0)
    );

  return (
    <div
      className="flex"
      style={{
        gap: '10px',
      }}>
      <ButtonCustom
        label={t('CANCEL')}
        backgroundColor={THEME.colors.red}
        onClick={handleCancel}
      />
      <ButtonCustom
        label={t('REGISTER')}
        onClick={handleRegister}
        backgroundColor={THEME.colors.green}
        disabled={isDisabled}
      />
    </div>
  );
};

import {helperConvertToNumber} from '../../helpers/helperConvertToNumber';
import {THEME} from '../../styles/theme';
import {TextVariant, TextVariantType} from '../atoms/TextVariant';

interface IProps {
  value: number | string;
  secondValue?: number | string;
  color: string;
  suffix?: string;
  variant?: TextVariantType;
  secondValueColor?: string;
}

export const TendanceCompareValues = ({
  value,
  secondValue,
  color,
  suffix = '',
  variant = 'textSemiBold',
  secondValueColor,
}: IProps) => {
  const secondValueGreaterThenValue =
    Number(helperConvertToNumber(String(secondValue))) >
    Number(helperConvertToNumber(String(value)));

  const secondValueIsEqualValue = secondValue === value;

  return (
    <div className="flex">
      <TextVariant
        variant={variant}
        text={`${value} ${suffix}`}
        style={{
          color,
        }}
      />

      {secondValue !== undefined && (
        <>
          <TextVariant
            variant={variant}
            text={
              secondValueGreaterThenValue
                ? '↘'
                : secondValueIsEqualValue
                ? '→'
                : '↗'
            }
            style={{
              color: secondValueGreaterThenValue
                ? THEME.colors.red
                : secondValueIsEqualValue
                ? THEME.colors.orange
                : THEME.colors.green,
              margin: '0 0.5rem',
            }}
          />

          <TextVariant
            variant={variant}
            text={`${secondValue} ${suffix}`}
            style={{color: secondValueColor || THEME.colors.lightGray}}
          />
        </>
      )}
    </div>
  );
};

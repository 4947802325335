/** @jsxImportSource @emotion/react */
import {TextVariant} from '../atoms/TextVariant';
import {ToggleButton} from '../atoms/ToggleButton';

export const ToggleButtonLabel = ({
  label,
  marginBottom,
  marginTop,
  active,
  onToggle,
  labelPosition = 'right',
  disabled = false,
}: {
  label: string;
  marginBottom?: string | number;
  marginTop?: string | number;
  active?: boolean;
  onToggle?: () => void;
  labelPosition?: 'left' | 'right';
  disabled?: boolean;
}) => {
  return (
    <div
      className="flex-align-center"
      style={{
        marginBottom,
        marginTop,
      }}>
      <ToggleButton
        active={!!active}
        onToggle={onToggle}
        showBorder
        order={labelPosition === 'left' ? 2 : 1}
        disabled={disabled}
      />
      <TextVariant
        variant="textMedium"
        style={[
          {
            order: labelPosition === 'left' ? 1 : 2,
            marginLeft: labelPosition === 'left' ? 0 : '1rem',
            marginRight: labelPosition === 'left' ? '0.3rem' : 0,
          },
        ]}
        text={label}
      />
    </div>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useEffect, useState} from 'react';

export const EnvIndicator = () => {
  //
  const [toogle, setToogle] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setToogle(prevState => !prevState);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      css={[
        styles.container,
        {backgroundColor: toogle ? 'red' : '#fff'},
      ]}></div>
  );
};

const styles = {
  container: css({
    height: 40,
    width: 40,
    borderRadius: 20,
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: 'red',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {RecipeItem} from './RecipeItem';
import {useTranslation} from 'react-i18next';
import {Loader} from '../../../../components/atoms/Loader';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {IRecipeFetchReturn} from '@bill-app-types/b-types';

export const RecipesList = ({
  data,
  isLoading = false,
}: {
  data: IRecipeFetchReturn[];
  isLoading: boolean;
}) => {
  const {t} = useTranslation();

  return (
    <div>
      <div css={styles.container}>
        {data?.map((item, index: number) => {
          return <RecipeItem key={index} recipe={item} />;
        })}
      </div>
      {(isLoading || data?.length === 0) && (
        <div css={styles.no_data}>
          {isLoading && <Loader />}
          {data?.length === 0 && (
            <TextVariant text={t('NO_RESULT')} variant="textSemiBold" />
          )}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: '1rem',
    maxHeight: 'calc(100vh - 150px)',
    overflowY: 'auto',
  }),
  no_data: css({
    width: '100%',
    height: 'calc(100vh - 300px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
};

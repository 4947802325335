import {useTranslation} from 'react-i18next';
import {OrderByFilter} from '../../../../../components/filters/OrderByFilter';
import {SearchFilter} from '../../../../../components/filters/SearchFilter';

export const Bottom = () => {
  const {t} = useTranslation();
  const currentPath = window.location.href;
  const isPricesPage = currentPath.includes('/prices');

  return (
    <>
      {!isPricesPage && <SearchFilter marginRight={'1vh'} />}
      <OrderByFilter
        icon={'calendar'}
        label={t('DATE_ADDED')}
        field="createdAt"
        marginRight={'1vh'}
      />
      <OrderByFilter
        icon={'calendar'}
        label={t('MODIFICATION_DATE')}
        field="updatedAt"
      />
    </>
  );
};

import {IOrderResponse, ORDER_STATUS} from '@bill-app-types/b-types';
import {createSlice} from '@reduxjs/toolkit';

export interface IOrder extends IOrderResponse {
  orderStatus?: ORDER_STATUS;
}

interface ISlice {
  selectedOrder: IOrder | null | any;
}

const initialState: ISlice = {
  selectedOrder: null,
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    selectedOrderAction: (state, action) => {
      state.selectedOrder = action.payload;
    },
  },
});

export const {
  selectedOrderAction
} = slice.actions;

export default slice.reducer;

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {Loader} from './Loader';
import {TextVariant} from './TextVariant';

interface IButtonCustomProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  label?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  color?: string;
  backgroundColor?: string;
  borderRadius?: number;
  marginLeft?: string | number;
  marginRight?: string | number;
  marginTop?: string | number;
  marginBottom?: string | number;
  width?: string | number;
  height?: string | number;
  padding?: string;
  isLoading?: boolean;
  loadingSize?: number;
  textUpperCase?: boolean;
}

export const ButtonCustom = ({
  onClick,
  label = 'button',
  type = 'button',
  disabled = false,
  color = THEME.colors.white,
  backgroundColor = THEME.colors.primary,
  borderRadius = 2,
  marginLeft = 0,
  marginRight = 0,
  width = 'auto',
  height = 28,
  padding = '0 10px',
  isLoading = false,
  loadingSize = 20,
  textUpperCase = true,
  marginTop,
  marginBottom,
}: IButtonCustomProps) => (
  <button
    onClick={onClick}
    disabled={disabled || isLoading}
    type={type}
    css={styles.button({
      backgroundColor,
      borderRadius,
      marginLeft,
      marginRight,
      width,
      height,
      padding,
      marginTop,
      marginBottom,
      disabled
    }
    )}>
    <TextVariant
      text={label}
      variant="title03"
      style={styles.label(color, textUpperCase)}
    />

    {isLoading && (
      <div css={styles.loader}>
        <Loader size={loadingSize} />
      </div>
    )}
  </button>
);

interface IButtonStyle {
  backgroundColor: string;
  borderRadius: number;
  marginLeft: string | number;
  marginRight: string | number;
  width: string | number;
  height: string | number;
  padding: string;
  marginTop?: string | number;
  marginBottom?: string | number;
  disabled?: boolean;
}

const styles = {
  button: ({
    backgroundColor,
    borderRadius,
    marginLeft,
    marginRight,
    width,
    height,
    padding,
    marginTop,
    marginBottom,
    disabled,
  }: IButtonStyle) =>
    css({
      position: 'relative',
      backgroundColor,
      border: '1px solid' + backgroundColor,
      borderRadius,
      padding,
      height: height,
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      cursor: 'pointer',
      width,
      opacity: disabled ? 0.5 : 1,
    }),
  label: (color: string, textUpperCase: boolean) =>
    css({
      color,
      textTransform: textUpperCase ? 'uppercase' : 'none',
      whiteSpace: 'nowrap',
    }),
  loader: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
};

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ISlice {
  selectedLanguages: any[];
}

const initialState: ISlice = {
  selectedLanguages: [],
};

const slice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    initSelectedLanguagesAction(state, action: PayloadAction<string[]>) {
      state.selectedLanguages = action.payload;
    },
    selectedLanguagesAction(state, action: PayloadAction<string>) {
      const languageIndex = state.selectedLanguages.findIndex(
        item => item === action.payload,
      );
      if (languageIndex === -1) {
        state.selectedLanguages = [...state.selectedLanguages, action.payload];
      } else {
        state.selectedLanguages = state.selectedLanguages.filter(
          (item: any) => item !== action.payload,
        );
      }
    },
  },
});

export const {selectedLanguagesAction, initSelectedLanguagesAction} =
  slice.actions;

export default slice.reducer;

import {SVGProps} from 'react';

export const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.30943 8.19936L10.2295 0.476036C10.8805 -0.158679 11.9378 -0.158679 12.5889 0.476036L13.6149 1.47719C13.6956 1.55641 13.7659 1.64197 13.8284 1.73211C14.2555 2.36281 14.1852 3.22025 13.6149 3.77631L5.69303 11.4976C5.65917 11.5306 5.62532 11.5408 5.58886 11.5941C5.33886 11.8099 5.04199 11.947 4.71907 12.0613L1.58548 12.9601C1.3662 13.0236 1.12897 12.9652 0.967247 12.7849C0.805523 12.6503 0.745059 12.4193 0.80959 12.2035L1.73131 9.14897C1.83964 8.79096 2.03834 8.46342 2.30943 8.19936ZM2.93053 9.49174L2.33209 11.4773L4.36751 10.8933C4.51074 10.8451 4.68522 10.7562 4.81021 10.6369L10.7581 4.8351L9.12011 3.23803L3.19329 9.0601C3.17792 9.07533 3.16308 9.09057 3.14876 9.10834C3.04824 9.21752 2.9735 9.34955 2.93053 9.49174Z"
      fill="#61D295"
    />
  </svg>
);

/** @jsxImportSource @emotion/react */
import {useContext, useEffect, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {
  IObject,
  filterVendorStructureAction,
  resetFilterVendorStructureAction,
} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
import {FilterCheckboxs} from './FilterCheckboxs';
import {useVendorStructure} from '../../hooks/useVendorStructure';
import {helperLabelTranslation} from '../../helpers/helperLabel';

export const VendorStructureFilter = ({withBorder = false}) => {
  const {vendorStructure} = useVendorStructure();
  const vendorStructures = useAppSelector(
    state => state.filter.vendorStructure,
  );
  const {dispatch} = useContext<any>(UtilsContext);

  const [vendorStructuresSelected, setvendorStructuresSelected] = useState<
    IObject[]
  >([]);
  const [forceClose, setForceClose] = useState(false);

  const vendorStructuresList =
    vendorStructure?.map((vendorStructure: any) => ({
      name: helperLabelTranslation(vendorStructure),
      value: vendorStructure.id,
      position: vendorStructure.position,
      childs: (vendorStructure.subCategories || vendorStructure.child)?.map(
        (child: any) => ({
          name: helperLabelTranslation(child),
          value: child.uuid || child.id,
          position: child.position,
        }),
      ),
    })) || [];

  const handleResetFilterConsumptionMode = () => {
    dispatch(resetFilterVendorStructureAction());
  };

  const handleCheckedChange = (e: any) => {
    if (e.target.name === 'ALL') {
      const totalLength = vendorStructuresList?.reduce(
        (acc, item) => acc + (item.childs?.length || 0) + 1,
        0,
      );
      if (vendorStructuresSelected.length === totalLength) {
        setvendorStructuresSelected([]);
      } else {
        setvendorStructuresSelected(
          vendorStructuresList?.reduce(
            (acc, item) => [...acc, item, ...item.childs],
            [] as IObject[],
          ) || [],
        );
      }
    } else {
      const parent = vendorStructuresList.find(
        channel => channel.value === e.target.value,
      );
      if (
        !!vendorStructuresSelected.find(
          channel => channel.value === e.target.value,
        )
      ) {
        setvendorStructuresSelected(
          vendorStructuresSelected.filter(
            channel =>
              ![
                parent?.value,
                ...parent?.childs.map((child: any) => child.value),
              ].includes(channel.value),
          ),
        );
      } else {
        setvendorStructuresSelected([
          ...vendorStructuresSelected,
          {
            name: e.target.name,
            value: e.target.value,
          },
          ...(parent?.childs || []),
        ]);
      }
    }
  };

  const handleSubmit = () => {
    //
    dispatch(filterVendorStructureAction(vendorStructuresSelected));
    setForceClose(true);
  };

  useEffect(() => {
    setvendorStructuresSelected(vendorStructures);
    return () => {
      setForceClose(false);
    };
  }, [vendorStructures]);

  const list =
    [...vendorStructuresList].sort((a, b) => a.position - b.position) || [];
  console.log('list', list);

  return (
    <FilterButton
      marginRight={'1vh'}
      label={'Structure marchande'}
      valueType="list"
      values={vendorStructures}
      showResetButton={vendorStructures?.length > 0}
      withBorder={withBorder}
      onReset={handleResetFilterConsumptionMode}
      hideFilterIcon={vendorStructures?.length > 0}
      forceClose={forceClose}>
      <FilterCheckboxs
        title="Structure marchande"
        list={list}
        selectedValues={vendorStructuresSelected}
        onChange={handleCheckedChange}
        onSubmit={handleSubmit}
      />
    </FilterButton>
  );
};

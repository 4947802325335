import {GLOBAL_FRONT_ACCESSES} from '../constants/frontAccesses';
import {
  BRAND_AUTHORIZATION,
  GLOBAL_FRONT_POLICIES,
} from '../constants/frontPolicies';
import {DEFAULT_CONNECTED_SCREEN} from '../constants/global';
import store from '../redux/storeConfig';

const getSectionAccess = (frontPolicies: any, accessCode: string) => {
  return (
    frontPolicies.find((item: any) => item.code === accessCode)?.accessType || 0
  );
};

const getSectionPolicies = (frontPolicies: any, accessCode: string) => {
  return (
    frontPolicies.find((item: any) => item.code === accessCode)?.policies || []
  );
};

const getAccessPolicies = (code: any, newAccess: number) => {
  const policies = getSectionPolicies(GLOBAL_FRONT_POLICIES, code);
  let newPolicies: any = [];
  policies.forEach((element: any, index: number) => {
    if (index <= newAccess) {
      newPolicies = newPolicies.concat(element);
    }
  });
  return newPolicies;
};

export const getUserFrontPolicies = (userFrontPolicies: any) => {
  if (!userFrontPolicies) return [];
  const auth = store.getState().auth.auth;
  const brandType = auth?.isMainBrand ? 'PARENT' : 'CHILD';

  const brandTypeAccesses = GLOBAL_FRONT_ACCESSES.find(
    (item: any) => item.brandType === brandType,
  )?.accesses;

  let newPolicies: any = [];

  brandTypeAccesses?.forEach((brandAccess: any) => {
    const userAccess = getSectionAccess(userFrontPolicies, brandAccess.code);

    const newAccess = userAccess >= brandAccess.access ? userAccess : -1;

    newPolicies = [
      ...newPolicies,
      {
        ...brandAccess,
        policies:
          newAccess === -1
            ? []
            : getAccessPolicies(brandAccess.code, newAccess),
      },
    ];
  });
  return newPolicies;
};

export const userHasAuthorization = (code: string, policy: string) => {
  const frontPolicies = store.getState().auth.frontPolicies;
  const policies = getSectionPolicies(frontPolicies, code);
  return policies.includes(policy);
};

export const brandHasAuthorization = (
  userIsMainBrand: boolean,
  authorizedBrand: string,
) => {
  return (
    authorizedBrand === BRAND_AUTHORIZATION.ALL ||
    (authorizedBrand === BRAND_AUTHORIZATION.PARENT && userIsMainBrand) ||
    (authorizedBrand === BRAND_AUTHORIZATION.CHILD && !userIsMainBrand)
  );
};

export const brandHasAuthorizationForRouting = (
  authorizedBrand: string = BRAND_AUTHORIZATION.ALL,
) => {
  const auth = localStorage.getItem('persist:auth');
  if (auth) {
    const isMainBrand = JSON.parse(JSON.parse(auth).auth)?.isMainBrand;
    if (!brandHasAuthorization(isMainBrand, authorizedBrand)) {
      window.location.href = DEFAULT_CONNECTED_SCREEN;
    }
  }

  return true;
};

import {useQuery} from '@tanstack/react-query';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {useTranslations} from '../../../../hooks/useTranslations';
import {TranslationItem} from './TranslationItem';
import {fetchBkioskParameterTranslations} from '../../../../api/fetchTranslations';
import {useTranslation} from 'react-i18next';

export const BKiosk = () => {
  const {t} = useTranslation();

  const {isOpened, handleToggleIsOpened} = useTranslations();

  const languageSelected = useAppSelector(
    s => s.myTranslationsSection.translations.languageSelected,
  );

  const {data, isLoading} = useQuery(
    ['fetchBkioskParameterTranslations'],
    () => fetchBkioskParameterTranslations(),
    {
      enabled: isOpened && !!languageSelected,
    },
  );

  return (
    <TranslationItem
      title={t('PARAMETERS_BKIOSK')}
      items={data}
      isLoading={isLoading}
      isOpened={isOpened}
      onToggleIsOpened={handleToggleIsOpened}
      category={'Bkiosk'}
      showHeader={false}
      fields={['home_title', 'home_desc', 'slider_title', 'slider_desc']}
    />
  );
};

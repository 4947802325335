/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext, useEffect} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {sectionShowAction} from '../../redux/posCategories';
import {THEME} from '../../styles/theme';

export const ToggleButtonSectionsForCatalog = ({
  sections,
  defaultSection,
  width = 'none',
  localManagerState = false,
  onChange,
  marginBottom = '2vh',
  hideProductType = false,
}: {
  sections: string[];
  defaultSection: string;
  width?: string | number;
  localManagerState?: boolean;
  onChange?: (section: string) => void;
  marginBottom?: string | number;
  hideProductType?: boolean;
}) => {
  const {t, dispatch} = useContext<any>(UtilsContext);

  const sectionShow = useAppSelector(s => s.posCategories.sectionShow);

  useEffect(() => {
    if (localManagerState) return;
    dispatch(sectionShowAction(defaultSection));
  }, [dispatch, defaultSection, localManagerState]);

  if (hideProductType) {
    return null;
  }

  return (
    <div css={styles.container(marginBottom)}>
      {sections.map((section: any, index: number) => (
        <a
          key={index}
          onClick={() => {
            if (localManagerState) {
              onChange && onChange(section);
              return;
            }
            dispatch(sectionShowAction(section));
          }}
          css={[
            styles.btn(width),
            (sectionShow === section ||
              (localManagerState && section === defaultSection)) &&
              styles.btnActive,
          ]}
          className="textSemiBold">
          {t(section)}
        </a>
      ))}
    </div>
  );
};

const styles = {
  container: (marginBottom: string | number) =>
    css({
      // width: '100%',
      marginBottom: marginBottom,
      display: 'flex',
      backgroundColor: 'white',
      padding: '0.2rem',
      overflowX: 'auto',
    }),
  btn: (width: string | number) =>
    css({
      border: 'none',
      padding: '1vh 2vh',
      backgroundColor: 'white',
      color: '#A7A7AB',
      cursor: 'pointer',
      width,
      textAlign: 'center',
    }),
  btnActive: css({
    backgroundColor: THEME.colors.primary,
    color: THEME.colors.white,
  }),
};

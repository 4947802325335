import {DaysDetails} from './DaysDetails';
import {Days} from './DaysList';

export const DaysRouter = {
  path: 'days',
  children: [
    {
      path: '',
      element: <Days />,
    },
    {
      path: ':uuid',
      element: <DaysDetails />,
    },
  ],
};

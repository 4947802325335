/** @jsxImportSource @emotion/react */
import {useContext, useEffect, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {fetchPaymentMethods} from '../../api/fetchPaymentMethod';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {
  IObject,
  initFilterPaymentMethodsAction,
  resetFilterPaymentMethodsAction,
} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
// import {IPaymentsMethod} from '@bill-app-types/b-types';
import {FilterCheckboxs} from './FilterCheckboxs';

export const PaymentMethodFilter = () => {
  //
  const {t, dispatch} = useContext<any>(UtilsContext);
  const paymentMethods = useAppSelector(state => state.filter.paymentMethods);
  const [paymentMethodsSelected, setPaymentMethodsSelected] = useState<
    IObject[]
  >([]);
  const [forceClose, setForceClose] = useState(false);

  const {data} = useQuery(['fetchPaymentMethods'], () => fetchPaymentMethods());

  const paymentMethodsList =
    data?.map((item: any) => ({
      name: item.label,
      value: item?.method_id,
    })) || [];

  const handleCheckedChange = (e: any) => {
    if (e.target.name === 'ALL') {
      if (paymentMethodsSelected.length === paymentMethodsList.length) {
        setPaymentMethodsSelected([]);
      } else {
        setPaymentMethodsSelected(paymentMethodsList);
      }
    } else {
      if (!!paymentMethodsSelected.find(item => item.name === e.target.name)) {
        setPaymentMethodsSelected(
          paymentMethodsSelected.filter(
            channel => channel.name !== e.target.name,
          ),
        );
      } else {
        setPaymentMethodsSelected([
          ...paymentMethodsSelected,
          {
            name: e.target.name,
            value: e.target.value,
          },
        ]);
      }
    }
  };

  const handleResetFilterPaymentMethod = () => {
    dispatch(resetFilterPaymentMethodsAction());
  };

  const handleSubmit = () => {
    dispatch(initFilterPaymentMethodsAction(paymentMethodsSelected));
    setForceClose(true);
  };

  useEffect(() => {
    setPaymentMethodsSelected(paymentMethods);
    return () => {
      setForceClose(false);
    };
  }, [paymentMethods]);

  return (
    <FilterButton
      marginRight={'1vh'}
      valueType="list"
      values={paymentMethods}
      label={t('METHOD_OF_PAYMENT')}
      showResetButton={paymentMethods?.length > 0}
      forceClose={forceClose}
      hideFilterIcon={paymentMethods?.length > 0}
      onReset={handleResetFilterPaymentMethod}>
      <FilterCheckboxs
        title={t('METHOD_OF_PAYMENT')}
        list={paymentMethodsList}
        selectedValues={paymentMethodsSelected}
        onChange={handleCheckedChange}
        onSubmit={handleSubmit}
      />
    </FilterButton>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../../../components/atoms/TextVariant';
import {THEME} from '../../../../../../styles/theme';
import {MenuProductCard} from './MenuProductCard';
import {IStatItemDetailMenusChilds} from '../../../../../../api/fetchStats';

export const MenuCard = ({menu}: {menu: IStatItemDetailMenusChilds}) => {
  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <TextVariant
          variant="title02"
          text={menu.label}
          style={{textTransform: 'uppercase'}}
        />
      </div>
      <div css={styles.content}>
        {menu.items.map((item, index) => {
          return <MenuProductCard item={item} key={index} />;
        })}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    minWidth: '18.68rem',
  }),
  header: css({
    padding: '1rem',
    textAlign: 'center',
    backgroundColor: THEME.colors.bgLight,
    borderTopLeftRadius: '0.13rem',
    borderTopRightRadius: '0.13rem',
    border: `1px solid ${THEME.colors.lightGray}`,
  }),
  content: css({
    padding: '0.5rem',
    border: `1px solid ${THEME.colors.lightGray}`,
    borderBottomLeftRadius: '0.13rem',
    borderBottomRightRadius: '0.13rem',
    height: '20.81rem',
    overflowY: 'scroll',
  }),
};

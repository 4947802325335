/** @jsxImportSource @emotion/react */
import {useNavigate} from 'react-router-dom';
import {CopyIcon2} from '../../assets/svgs/CopyIcon';
import {EditIcon} from '../../assets/svgs/EditIcon';
import {TrashIcon} from '../../assets/svgs/TrashIcon';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {ActionButton} from '../atoms/ActionButton';
import {useQueryClient} from '@tanstack/react-query';
import {useBrandAccess} from '../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../constants/frontPolicies';
import {Loader} from '../atoms/Loader';
import {useState} from 'react';

type TFunc = {
  dispatch: any;
  refreshing: (reactQueryKey: string) => void;
  navigate?: any;
  brandId?: number | null;
};

export const ActionsContainer = ({
  showCopy = false,
  hideDelete = false,
  direction = 'column',
  ...props
}: {
  showCopy?: boolean;
  direction?: 'row' | 'column';
  handleClickEdit?: (data: TFunc) => void | Promise<void>;
  handleClickCopy?: (data: TFunc) => void;
  handleClickDelete?: (data: TFunc) => void;
  authorization?: {
    edit?: BRAND_AUTHORIZATION;
    copy?: BRAND_AUTHORIZATION;
    delete?: BRAND_AUTHORIZATION;
  } | null;
  navigate?: string;
  hideDelete?: boolean;
}) => {
  const {handleClickCopy, handleClickDelete, handleClickEdit, authorization} =
    props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clientQuery = useQueryClient();

  const [editLoading, setEditLoading] = useState(false);

  const onEdit = async () => {
    setEditLoading(true);
    if (handleClickEdit) {
      await handleClickEdit({dispatch, refreshing, navigate});
    }

    setEditLoading(false);
  };

  const {brandHasAuthorization} = useBrandAccess();
  const brandId = useAppSelector(s => s.brand.brand?.id);

  const refreshing = (reactQueryKey: string) => {
    clientQuery.invalidateQueries([reactQueryKey]);
  };

  const margin = direction === 'row' ? '0 0 0 2px' : 2;

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: direction,
      }}>
      {props.navigate ? (
        <ActionButton
          onClick={() => navigate(props.navigate as string)}
          Icon={EditIcon}
          margin={margin}
          disabled={
            authorization && authorization['edit']
              ? !brandHasAuthorization(authorization['edit'])
              : false
          }
        />
      ) : (
        <ActionButton
          onClick={onEdit}
          Icon={editLoading ? () => <Loader size={10} /> : EditIcon}
          margin={margin}
          disabled={
            authorization && authorization['edit']
              ? !brandHasAuthorization(authorization['edit'])
              : false
          }
        />
      )}
      {showCopy && (
        <ActionButton
          onClick={() =>
            handleClickCopy && handleClickCopy({dispatch, refreshing, brandId})
          }
          Icon={CopyIcon2}
          margin={margin}
          disabled={
            authorization && authorization['copy']
              ? !brandHasAuthorization(authorization['copy'])
              : false
          }
        />
      )}
      {!hideDelete && (
        <ActionButton
          onClick={() =>
            handleClickDelete &&
            handleClickDelete({dispatch, refreshing, brandId})
          }
          Icon={TrashIcon}
          margin={margin}
          disabled={
            authorization && authorization['delete']
              ? !brandHasAuthorization(authorization['delete'])
              : false
          }
        />
      )}
    </div>
  );
};

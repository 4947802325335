/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {Title} from '../../../../components/atoms/Title';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {useQuery} from '@tanstack/react-query';
import {fetchAreaProductions} from '../../../../api/fetchAreaProductions';
import {
  SortableListVerticalContainer,
  THandleDragAndDrop,
} from '../../../../components/organisms/SortableListVerticalContainer';
import {AreaCategoryItem} from './AreaCategoryItem';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {updateCategoriesPosition} from '../../../../api/fetchCategories';

export const RightBlock = () => {
  const {t} = useTranslation();

  const showProducts = useAppSelector(
    s => s.myRestaurantSection.areaProductions.showProducts,
  );
  const {data, refetch} = useQuery(
    ['fetchAreaProductions'],
    fetchAreaProductions,
  );

  const handleDragAndDrop: THandleDragAndDrop = async data => {
    const {items} = data;
    try {
      if (!items) return;

      const categoryUuids = items.map(item => item?.uuid);

      const res = await updateCategoriesPosition(categoryUuids);

      if (res?.status === 204) {
        refetch();
        helperDisplayToast(t('SUCCESS'), true);
      }
    } catch (error) {
      helperDisplayToast(t('ERROR'));
    }
  };

  return (
    <div
      css={[
        styles.container,
        {
          width: showProducts ? '48%' : '97%',
        },
      ]}>
      <Title title={t('PRODUCTION_AREA')} />
      <div css={styles.content}>
        {data && (
          <SortableListVerticalContainer
            // @ts-ignore
            data={data}
            ItemComponent={AreaCategoryItem}
            handleDragAndDrop={handleDragAndDrop}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    height: '100%',
  }),
  content: css({
    paddingTop: 10,
    overflow: 'auto',
    height: '100%',
  }),
  form: {
    gap: '1vh',
  },
};

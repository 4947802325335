/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {THEME} from '../../styles/theme';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {QrCodeCard} from '../molecules/QrCodeCard';
import {qrCodesListModalAction} from '../../redux/modals';
import {useTranslation} from 'react-i18next';
import {AbsoluteLoader} from '../layouts/AbsoluteLoader';
import {useAreas} from '../../hooks/useAreas';
import {useQuery} from '@tanstack/react-query';
import {fetchAllAreaLocations} from '../../api/fetchAreas';
import {useBrandsHierarchy} from '../../hooks/useBrandsHierarchy';
import {useState} from 'react';
import {helperDownloadQrCode} from '../../helpers/helperDownloadQrCode';
import {CSVLink} from 'react-csv';
import {helperGenerateQrCodeUrl} from '../../helpers/helperGenerateQrCodeUrl';

export const QrCodesListModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const brand = useAppSelector(state => state.brand.brand);
  const [isLoadingDownloadAllQrCode, setIsDownloadAllQrCode] = useState(false);

  const {areas, isLoading} = useAreas();

  const areasIds = areas?.map((area: any) => area.id);

  const {data: brandHierachy} = useBrandsHierarchy();

  const {data, isLoading: isAreaLocationLoading} = useQuery(
    ['fetchAllAreaLocations', areasIds],
    () => fetchAllAreaLocations(brand.id, areasIds),
    {
      enabled: brand.id && !!areas?.length,
    },
  );

  const onClose = () => {
    dispatch(qrCodesListModalAction(false));
  };

  const handleDownloadAllQrCode = async () => {
    try {
      setIsDownloadAllQrCode(true);

      let allQrCodes = document.querySelectorAll('.qr_code_card');

      if (!allQrCodes.length) return;

      for (let i = 0; i < allQrCodes.length; i++) {
        let qrCode = allQrCodes[i] as HTMLDivElement;
        let pictureName = qrCode.getAttribute('data-picture-name');

        if (!pictureName) continue;
        await helperDownloadQrCode(qrCode, pictureName);
      }

      setIsDownloadAllQrCode(false);
    } catch (error) {
      console.log(error);
    }
  };

  const areaLocations = data?.map((areaLocation: any) => ({
    ...areaLocation,
    area: areas.find((area: any) => area.id === areaLocation.area_id),
    qr_code_url: helperGenerateQrCodeUrl(brandHierachy, areaLocation),
  }));

  const csvData = [
    ['emplacement', 'URL'],
    ...(areaLocations?.map((areaLocation: any) => [
      areaLocation.label,
      areaLocation.qr_code_url,
    ]) || []),
  ];

  return (
    <ModalWrapper
      title="Liste des QRCodes"
      onCancel={onClose}
      maxWidth={'90%'}
      minWidth={'90%'}
      showBtns={false}>
      <div
        className="flex"
        style={{
          justifyContent: 'flex-end',
        }}>
        <CSVLink data={csvData} filename="export_des_emplacement_BILL.csv">
          <ButtonCustom label={t('EXPORT_AS_CSV')} marginRight={'1vh'} />
        </CSVLink>

        <ButtonCustom
          label={t('DOWNLOAD_ALL_QR_CODES')}
          onClick={handleDownloadAllQrCode}
          isLoading={isLoadingDownloadAllQrCode}
          backgroundColor={THEME.colors.green}
        />
      </div>

      <div css={styles.qrCodeList} className="flex">
        {(isLoading || isAreaLocationLoading) && <AbsoluteLoader />}

        {areaLocations?.map((areaLocation: any) => {
          return (
            <QrCodeCard key={areaLocation.id} areaLocation={areaLocation} />
          );
        })}
      </div>
    </ModalWrapper>
  );
};

const styles = {
  qrCodeList: css({
    position: 'relative',
    gap: 8,
    flexWrap: 'wrap',
    height: '70vh',
    overflowY: 'auto',
    marginTop: '2vh',
  }),
};

import {
  IFetchStructuresReturn,
  IFetchStructuresQueryParams,
  IFetchStructureDetailsResponse,
  ICreateStructurePayload,
  IUpdateStructurePayload,
} from '@bill-app-types/b-types';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {helperLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';

export const fetchStructures = async (query: IFetchStructuresQueryParams) => {
  helperLog({message: 'fetchStructures'});

  const q = helperGenerateQuery(query);

  const res = await axiosInstance
    .get<{
      data: IFetchStructuresReturn[];
    }>(`/bcatalog/backoffice/structures${q}`, {
      params: {
        type: JSON.stringify(query?.type),
        //orderBy: JSON.stringify(data?.orderBy),
      },
    })
    .catch(err => {
      helperLog({message: 'fetchStructures', type: 'API_ERROR', data: err});
    });

  return res?.data?.data;
};

export const getStructureDetails = async (uuid?: string) => {
  helperLog({message: 'getStructureDetails'});

  const res = await axiosInstance
    .get<{
      data: IFetchStructureDetailsResponse;
    }>(`/bcatalog/backoffice/structures/${uuid}`)
    .catch(err => {
      helperLog({message: 'getStructureDetails', type: 'API_ERROR', data: err});
    });

  return res?.data?.data;
};

export const createStructure = async (data: ICreateStructurePayload) => {
  helperLog({message: 'createStructure'});
  const res = await axiosInstance
    .post(`/bcatalog/backoffice/structures`, data)
    .catch(err => {
      helperLog({message: 'createStructure', type: 'API_ERROR', data: err});
    });

  return res;
};

export const updateStructure = async (
  structureUuid: string,
  data: IUpdateStructurePayload,
) => {
  helperLog({message: 'updateStructure'});
  const res = await axiosInstance
    .put(`/bcatalog/backoffice/structures/${structureUuid}`, data)
    .catch(err => {
      helperLog({message: 'updateStructure', type: 'API_ERROR', data: err});
    });

  return res;
};

export const deleteStructure = async (structureUuid: string) => {
  helperLog({message: 'deleteStructure'});
  const res = await axiosInstance
    .delete(`/bcatalog/backoffice/structures/${structureUuid}`)
    .catch(err => {
      helperLog({message: 'deleteStructure', type: 'API_ERROR', data: err});
    });

  return res;
};

export const duplicateStructure = async (structureUuid: string) => {
  helperLog({message: 'duplicateStructure'});
  const res = await axiosInstance
    .put(`/bcatalog/backoffice/structures/${structureUuid}/duplicate`)
    .catch(err => {
      helperLog({message: 'duplicateStructure', type: 'API_ERROR', data: err});
    });

  return res;
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../../components/layouts/Header';
import {HeaderTabs} from '../HeaderSections/HeaderTabs';
import {HeaderTopButtons} from '../HeaderSections/HeaderTopButtons';
import {Classification} from './Classification';
import {InfoGeneral} from './InfoGeneral';

export const GeneralSection = withRedirectAndNavigation(() => {
  return (
    <div css={styles.container}>
      <Header
        centerSection={<HeaderTopButtons />}
        bottomSection={<HeaderTabs />}
      />
      <div css={styles.content}>
        <InfoGeneral />
        <Classification />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1rem',
    overflowY: 'auto',
  }),
  content: css({
    padding: '2vh',
  }),
};

import {SVGProps} from 'react';

export const ModulesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.10714 5.79628V4.8439H8.03571V5.79628H9.10714ZM10 5.79628C10 6.23477 9.60045 6.58993 9.10714 6.58993H8.03571C7.54241 6.58993 7.14286 6.23477 7.14286 5.79628V4.8439C7.14286 4.4054 7.54241 4.05025 8.03571 4.05025H9.10714C9.60045 4.05025 10 4.4054 10 4.8439V5.79628ZM9.10714 1.98675V1.03437H8.03571V1.98675H9.10714ZM10 1.98675C10 2.42525 9.60045 2.7804 9.10714 2.7804H8.03571C7.54241 2.7804 7.14286 2.42525 7.14286 1.98675V1.03437C7.14286 0.595881 7.54241 0.240723 8.03571 0.240723H9.10714C9.60045 0.240723 10 0.595881 10 1.03437V1.98675ZM4.46429 5.79628H5.53571V4.8439H4.46429V5.79628ZM5.53571 6.58993H4.46429C3.97098 6.58993 3.57143 6.23477 3.57143 5.79628V4.8439C3.57143 4.4054 3.97098 4.05025 4.46429 4.05025H5.53571C6.02902 4.05025 6.42857 4.4054 6.42857 4.8439V5.79628C6.42857 6.23477 6.02902 6.58993 5.53571 6.58993ZM5.53571 1.98675V1.03437H4.46429V1.98675H5.53571ZM6.42857 1.98675C6.42857 2.42525 6.02902 2.7804 5.53571 2.7804H4.46429C3.97098 2.7804 3.57143 2.42525 3.57143 1.98675V1.03437C3.57143 0.595881 3.97098 0.240723 4.46429 0.240723H5.53571C6.02902 0.240723 6.42857 0.595881 6.42857 1.03437V1.98675ZM0.892857 5.79628H1.96429V4.8439H0.892857V5.79628ZM1.96429 6.58993H0.892857C0.399554 6.58993 0 6.23477 0 5.79628V4.8439C0 4.4054 0.399554 4.05025 0.892857 4.05025H1.96429C2.45759 4.05025 2.85714 4.4054 2.85714 4.8439V5.79628C2.85714 6.23477 2.45759 6.58993 1.96429 6.58993ZM1.96429 1.98675V1.03437H0.892857V1.98675H1.96429ZM2.85714 1.98675C2.85714 2.42525 2.45759 2.7804 1.96429 2.7804H0.892857C0.399554 2.7804 0 2.42525 0 1.98675V1.03437C0 0.595881 0.399554 0.240723 0.892857 0.240723H1.96429C2.45759 0.240723 2.85714 0.595881 2.85714 1.03437V1.98675Z"
      fill={props.color || 'white'}
    />
  </svg>
);

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext} from 'react';
import {login} from '../../../api/auth';
import {mq} from '../../../helpers/helperMediaQueries';
import {helperDisplayToast} from '../../../helpers/helperToast';
import {THEME} from '../../../styles/theme';
import {UtilsContext} from '../../../contexts/UtilsContext';
import {Link, useNavigate} from 'react-router-dom';
import {authAction, frontPoliciesAction} from '../../../redux/auth';
import {Formik} from 'formik';
import {getUserFrontPolicies} from '../../../helpers/helperFrontPolicies';
import {getBrandInfos} from '../../../api/fetchBrandInfos';
import {brandAction} from '../../../redux/brand';
import {CheckboxCustom} from '../../../components/atoms/CheckBoxCustom';
import {AuthLayout} from '../components/Layout/AuthLayout';
import {InputLabel} from '../components/InputLabel';
import {EyesIcon} from '../components/Icons/EyesIcon';
import {
  CHILD_DEFAULT_SCREEN,
  PARENT_DEFAULT_SCREEN,
} from '../../../constants/global';
import {sidebarDropdownOpenedAction} from '../../../redux/sidebar';

export const Login = () => {
  //
  const navigate = useNavigate();
  const {t, dispatch} = useContext<any>(UtilsContext);

  const handleSubmit = async (values: any, {setSubmitting}: any) => {
    try {
      const email = values.email;
      const password = values.password;

      if (!email || !password) {
        helperDisplayToast(t('PLEASE_CHECK_YOUR_EMAIL'));
        return;
      }

      const res = await login({
        email: email.toLowerCase(),
        password: password,
      });
      if (res.status === 200) {
        localStorage.setItem('auth_token', res?.data?.token);
        localStorage.setItem('auth_refresh_token', res?.data?.refreshToken);
        dispatch(authAction(res?.data));
        const brandInfos = await getBrandInfos(res?.data?.brandId);
        dispatch(brandAction(brandInfos));

        const userFrontPolicies = getUserFrontPolicies(
          res?.data?.frontPolicies,
        );
        dispatch(frontPoliciesAction(userFrontPolicies));
        setSubmitting(false);
        navigate(
          res?.data.isMainBrand ? PARENT_DEFAULT_SCREEN : CHILD_DEFAULT_SCREEN,
        );
        dispatch(
          sidebarDropdownOpenedAction(
            res?.data.isMainBrand ? 'Mon restaurant' : 'Tableau de bord',
          ),
        );
      } else {
        helperDisplayToast(t('PLEASE_CHECK_YOUR_EMAIL'));
      }
    } catch (error) {
      helperDisplayToast(t('PLEASE_CHECK_YOUR_EMAIL'));
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        rememberMe: false,
        showPassword: false,
      }}
      onSubmit={handleSubmit}>
      {({handleSubmit, handleChange, values, isSubmitting}) => {
        return (
          <AuthLayout
            animType="login"
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}>
            <InputLabel
              type="email"
              label={t('EMAIL')}
              name="email"
              placeholder={t('YOUR_EMAIL')}
              value={values.email}
              onChange={handleChange}
            />

            <InputLabel
              label={t('PASSWORD')}
              name="password"
              placeholder={t('YOUR_PASSWORD')}
              type={values.showPassword ? 'text' : 'password'}
              Icon={EyesIcon}
              showIcon
              value={values.password}
              onChange={handleChange}
              onClickIcon={() =>
                handleChange({
                  target: {
                    name: 'showPassword',
                    value: !values.showPassword,
                  },
                })
              }
            />

            <div className="flex-space-between" css={styles.formInputsFooter}>
              <div
                className="flex-align-center"
                onClick={() =>
                  handleChange({
                    target: {
                      name: 'rememberMe',
                      value: !values.rememberMe,
                    },
                  })
                }>
                <CheckboxCustom
                  marginRight={10}
                  checked={values.rememberMe}
                  setChecked={() =>
                    handleChange({
                      target: {
                        name: 'rememberMe',
                        value: !values.rememberMe,
                      },
                    })
                  }
                />
                {t('REMEMBER_ME')}
              </div>
              <Link to="/forget-password">{t('PASSWORD_FORGOTTEN')} ?</Link>
            </div>
          </AuthLayout>
        );
      }}
    </Formik>
  );
};

const styles = {
  form: mq({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: THEME.colors.bgLight,
    width: ['30%', '90%'],
    height: ['100%', '60%'],
    padding: '15px 25px',
    margin: 'auto',
  }),
  formInputsFooter: css(`
    width: 100%;
    margin: 1rem 0 2.5rem;

    p,a{
      font-size: 10px;
    }
    

    p{

      span{
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid #A7A7AB;
        margin-right: 5px;

        &.active {
          background: #A7A7AB;
        }
      }
      font-family: 'Montserrat-SemiBold', sans-serif;
      color:#A7A7AB;
    }


    a{
      text-decoration: underline;
    }
  `),
};

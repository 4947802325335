/** @jsxImportSource @emotion/react */
import {Link} from 'react-router-dom';
import {helperFormatPrice} from '../../../../helpers/helperFormatPrice';
import {THEME} from '../../../../styles/theme';

export const columns = [
  {
    field: 'paiment_method',
    headerName: 'Mode de paiement',
    flex: 1.5,
    valueGetter: (row: any) => {
      return row?.paymentMethod?.label;
    },
  },
  {
    field: 'code',
    headerName: 'Code',
    flex: 1,
    valueGetter: (row: any) => {
      return row?.paymentMethod?.type;
    },
  },
  {
    field: 'users',
    headerName: 'Utilisateur',
    flex: 1,
    valueGetter: (row: any) => {
      return row?.posUser?.username;
    },
  },
  {
    field: 'compte',
    headerName: 'N° de compte',

    flex: 1,
    cellClassName: 'account textSemiBold',
    valueGetter: (row: any) => {
      return row?.accountingManagement?.code;
    },
  },
  {
    field: 'shortCode',
    headerName: 'N° Commande Client',
    flex: 1,
    valueGetter: (row: any) => {
      return row?.order?.shortCode;
    },
  },

  {
    field: 'orderId',
    headerName: 'N° Commande Unique',
    flex: 1,
    valueGetter: (row: any) => {
      return row?.order.orderId;
    },
  },
  {
    field: 'posReceiptId',
    headerName: 'N° de ticket',
    flex: 1,
    renderCell: (row: any) => {
      const value = row.amount;

      return (
        <Link
          to={`/online/accounting/invoice-history?receiptId=${row.posReceipt.uuid}`}
          css={styles.posReceiptId(value)}
          className="textSemiBold">
          {row.posReceipt.posReceiptId}
        </Link>
      );
    },
  },
  {
    field: 'date',
    headerName: "Date d'opération",
    flex: 1,
  },
  {
    field: 'time',
    headerName: "Heure d'opération",
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Montant',
    flex: 1,
    renderCell: (row: any) => {
      const amount = row.amount;
      return (
        <span css={{color: amount > 0 ? THEME.colors.green : THEME.colors.red}}>
          {helperFormatPrice(amount)}€
        </span>
      );
    },
  },
];

const styles = {
  posReceiptId: (value: any) => ({
    color: value >= 0 ? 'var(--text-color)' : THEME.colors.red,
    textDecoration: value >= 0 ? 'none' : 'line-through',
  }),
};

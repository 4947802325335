/** @jsxImportSource @emotion/react */ import {
  useContext,
  useEffect,
} from 'react';
import {UtilsContext} from '../../../contexts/UtilsContext';
import {useAppSelector} from '../../../hooks/useReduxHook';
import {useInfiniteQuery} from '@tanstack/react-query';
import {showProductsAction} from '../../../redux/posCategories';
import {fetchProductsOld} from '../../../api/fetchProducts';
import {Header} from './Header';
import {InfiniteScrollCustom} from '../../atoms/InfiniteScrollCustom';
import {css} from '@emotion/react';
import {useDebounce} from '../../../hooks/useDebounce';
import {ClosedProductsContainer} from '../../molecules/ClosedProductsContainer';
import {fetchMenusOld} from '../../../api/fetchMenus';
import {ProductListOld} from '../../molecules/ProductListOld';

interface IProductsAndMenu {
  onClickProduct?: (product: any) => void;
  hiddenProducts?: boolean;
  handleIsDisabled?: any;
}

export const ProductsAndMenuCatalogOld = ({
  onClickProduct,
  hiddenProducts = true,
  handleIsDisabled,
}: IProductsAndMenu) => {
  const {dispatch} = useContext<any>(UtilsContext);
  const showProducts = useAppSelector(s => s.posCategories.showProducts);
  const sectionShow = useAppSelector(s => s.posCategories.sectionShow);
  const searchInput = useAppSelector(s => s.posCategories.searchInput);

  const debouncedSearchTerm = useDebounce(searchInput, 500);

  const handleOpenLeftBlock = () => {
    dispatch(showProductsAction(!showProducts));
  };

  const {
    data: products,
    refetch: refetchProduct,
    fetchNextPage: fetchProductNextPage,
    hasNextPage: hasNextProductPage,
    isLoading: productIsLoading,
  } = useInfiniteQuery(
    ['fetchProducts', debouncedSearchTerm],
    ({pageParam = 0}) =>
      fetchProductsOld({
        limit: 50,
        offset: pageParam,
        search: debouncedSearchTerm,
      }),
    {
      enabled: sectionShow === 'Produits',
      getNextPageParam: (lastPage, pages) => {
        const currentTotalProducts = pages?.reduce(
          (acc, page) => acc + (page.items?.length || 1),
          0,
        );

        if (Number(lastPage?.totalItems) === currentTotalProducts) {
          return undefined;
        }

        return currentTotalProducts + 1;
      },
    },
  );

  const {
    data: menus,
    refetch: refetchMenu,
    fetchNextPage: fetchMenuNextPage,
    hasNextPage: hasNextMenuPage,
    isLoading: menuIsLoading,
  } = useInfiniteQuery(
    ['fetchMenus', debouncedSearchTerm],
    ({pageParam = 0}) => {
      return fetchMenusOld({
        limit: 50,
        offset: pageParam,
        search: debouncedSearchTerm,
      });
    },

    {
      enabled: sectionShow === 'Menus',
      getNextPageParam: (lastPage, pages) => {
        const currentTotalProducts = pages?.reduce(
          (acc, page) => acc + (page.items?.length || 1),
          0,
        );

        if (Number(lastPage?.totalItems) === currentTotalProducts)
          return undefined;

        return currentTotalProducts + 1;
      },
    },
  );

  useEffect(() => {
    if (sectionShow === 'Menus') {
      refetchMenu();
    } else if (sectionShow === 'Produits') {
      refetchProduct();
    }
  }, [debouncedSearchTerm, refetchMenu, refetchProduct, sectionShow]);

  const data =
    (sectionShow === 'Produits' ? products : menus)?.pages.flatMap(
      data => data[sectionShow === 'Produits' ? 'courses' : 'menus'],
    ) || [];

  return (
    <>
      {!showProducts && hiddenProducts ? (
        <ClosedProductsContainer onClick={handleOpenLeftBlock} />
      ) : (
        <div css={styles.container}>
          <Header />
          <div css={styles.content} id="scrollableDiv">
            <InfiniteScrollCustom
              items={data || []}
              refresh={
                sectionShow === 'Produits' ? refetchProduct : refetchMenu
              }
              fetchData={
                sectionShow === 'Produits'
                  ? fetchProductNextPage
                  : fetchMenuNextPage
              }
              scrollableTarget="scrollableDiv"
              isLoading={
                sectionShow === 'Produits'
                  ? !!hasNextProductPage
                  : !!hasNextMenuPage
              }>
              <ProductListOld
                data={
                  data?.map((item: any) => ({
                    ...item,
                    type: sectionShow === 'Produits' ? 2 : 1,
                  })) || []
                }
                handleOnClick={onClickProduct}
                showProductType
                showAdd
                loading={productIsLoading || menuIsLoading}
                handleIsDisabled={handleIsDisabled}
              />
            </InfiniteScrollCustom>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  container: css({
    width: '49%',
    display: 'flex',
    flexDirection: 'column',
  }),
  content: css({
    paddingTop: 20,
    overflow: 'auto',
  }),
};

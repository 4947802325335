/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {useCustomerOrders} from '../../../../hooks/useCustomerOrders';
import {useEffect, useState} from 'react';
import {TicketHeader} from './TicketHeader';
import {CustomerTicket} from './CustomerTicket';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {THEME} from '../../../../styles/theme';
import {useDispatch} from 'react-redux';
import {selectedOrderAction} from '../../../../redux/customer';
import {TicketFooter} from './TicketFooter';
import {helperDate} from '../../../../helpers/helperDate';
import {
  helperFormatPrice,
  helperFormatPriceWithoutDivide,
} from '../../../../helpers/helperFormatPrice';
import {useCustomerOrdersV2} from '../../../../hooks/useCustomerOrdersV2';
import {useFlagBCatalog} from '../../../../hooks/useFlagBCatalog';

export const CustomerDetailsOrders = () => {
  //
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [rowCount, setRowCount] = useState(0);
  const offset = (page - 1) * limit;

  const {flagBCatalogEnabled} = useFlagBCatalog();

  const {
    data: ordersV1,
    isLoading: isLoadingV1,
    totalOrders: totalOrdersV1,
  } = useCustomerOrders(offset, limit);

  const {
    data: ordersV2,
    isLoading: isLoadingV2,
    totalOrders: totalOrdersV2,
  } = useCustomerOrdersV2(offset, limit);

  const data = flagBCatalogEnabled ? ordersV2 : ordersV1;
  const isLoading = flagBCatalogEnabled ? isLoadingV2 : isLoadingV1;
  const totalOrders = flagBCatalogEnabled ? totalOrdersV2 : totalOrdersV1;

  const selectedOrder = useAppSelector(s => s.customer.selectedOrder);

  useEffect(() => {
    setRowCount(oldRowCount =>
      totalOrders !== undefined ? totalOrders : oldRowCount,
    );
  }, [totalOrders]);

  useEffect(() => {
    return () => {
      dispatch(selectedOrderAction(null));
    };
  }, [dispatch]);

  const columns = [
    {
      field: 'created_at',
      headerName: '',
      flex: 1,
      valueGetter: (row: any) => {
        const date = row?.created_at ? row?.created_at * 1000 : row?.createdAt;
        return `${helperDate(new Date(date), 'Le DD/MM/YYYY à HH:mm')}`;
      },
    },
    {
      field: 'short_code',
      headerName: '',
      flex: 1,
      valueGetter: (row: any) => {
        return (
          <span style={{color: THEME.colors.primary}}>
            {row?.short_code || row?.shortCode}
          </span>
        );
      },
    },
    {
      field: 'order_id',
      headerName: '',
      flex: 1,
      valueGetter: (row: any) => {
        return row?.order_id || row?.publicId;
      },
    },
    {
      field: 'final_price',
      headerName: '',
      flex: 1,
      valueGetter: (row: any) => {
        const price = Number(row?.final_price || row?.totalPrice);
        return (
          <span style={{color: THEME.colors.primary}}>{`${
            row.totalPrice
              ? helperFormatPrice(price)
              : helperFormatPriceWithoutDivide(price)
          }€`}</span>
        );
      },
    },
  ];

  const handleSelectOrder = (order: any) => {
    if (
      (selectedOrder?.id && selectedOrder?.id === order?.id) ||
      (selectedOrder?.uuid && selectedOrder?.uuid === order?.uuid)
    ) {
      dispatch(selectedOrderAction(null));
    } else {
      dispatch(selectedOrderAction(order));
    }
  };

  return (
    <div className="flex-space-between" css={styles.container}>
      {selectedOrder && (
        <CustomerTicket
          TicketHeader={TicketHeader}
          TicketFooter={TicketFooter}
        />
      )}
      <div css={styles.rightBlock}>
        <NewDataGrid
          columns={columns}
          rows={data}
          loading={isLoading}
          rowHeight={35}
          hasPagination
          page={page}
          setPage={setPage}
          totalRows={rowCount}
          rowPerPage={limit}
          hideHeader
          onRowClick={handleSelectOrder}
          bordered
          selectedRow={selectedOrder}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    height: '90%',
    paddingTop: '1%',
  }),
  rightBlock: css({
    // width: '74%',
    height: '100%',
    paddingTop: '1%',
    flex: 1,
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
const version = require('../../../package.json').version;

interface IProps {
  color?: string;
}
export const Version = ({color = THEME.colors.bgDark}: IProps) => {
  const handleOpenChangelog = () => {
    window.open(`https://b-changelog.netlify.app/?app=bor&version=${version}`);
  };

  return (
    <a onClick={handleOpenChangelog} css={styles.version(color)}>
      {`Version ${version}`}
    </a>
  );
};

const styles = {
  version: (color: string) =>
    css({
      fontSize: '10px',
      fontFamily: 'Montserrat-Medium',
      color,
      textDecoration: 'underline',
      marginTop: '5px',
      cursor: 'pointer',
    }),
};

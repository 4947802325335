/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {CrossIcon, SmallCrossIcon} from '../../assets/svgs/CrossIcon';
import {THEME} from '../../styles/theme';
import {BadgeCounter} from './BadgeCounter';
import {TextVariant} from './TextVariant';
import {helperFormatPrice} from '../../helpers/helperFormatPrice';
import {helperLabelTranslation} from '../../helpers/helperLabel';
import {ImageCustom} from './ImageCustom';
import {CrossedOutEyes} from '../../assets/svgs/CrossedOutEyes';
import {HexaExclamation} from '../../assets/svgs/HexaExclamation';
import {ActionsContainer} from '../molecules/ActionsContainer';
import {EyeMediumIcon} from '../../assets/svgs/EyeIcon';
import {CheckCircleSmallIcon} from '../../assets/svgs/CheckCircleIcon';
import {ProductType} from './ProductType';

interface IProductCard {
  showProductType?: boolean;
  handleOnClick?: () => void;
  item: any;
  showCross?: boolean;
  showAdd?: boolean;
  draggable?: boolean;
  showActions?: boolean;
  showOverlay?: boolean;
  showPrice?: boolean;
  imageObjectFit?: 'contain' | 'cover' | 'fill';
  showAdditionalPrice?: boolean;
  specificImage?: string;
  handleClickAdditional?: any;
  disabled?: boolean;
  showStartPrice?: boolean;
  typeColor?: string;
  priceColor?: string;
  showPointerOnHover?: boolean;
  noTruncImage?: boolean;
}

export const ProductCard = ({
  showProductType = false,
  handleOnClick,
  item,
  showCross = false,
  showAdd = false,
  draggable = false,
  showActions = false,
  showOverlay = true,
  showPrice = true,
  showAdditionalPrice = false,
  imageObjectFit,
  specificImage,
  handleClickAdditional,
  disabled = false,
  typeColor,
  priceColor = THEME.colors.primary,
  showPointerOnHover = true,
  noTruncImage = false,
}: IProductCard) => {
  //
  return (
    <div
      onClick={handleOnClick}
      css={styles.card(showPointerOnHover)}
      className={draggable ? 'product-card--draggable' : ''}>
      <div css={styles.imgContainer}>
        <ImageCustom
          src={specificImage || item?.image}
          alt={item?.label}
          noTrunc={noTruncImage}
          styles={
            imageObjectFit && {
              objectFit: imageObjectFit,
            }
          }
        />
        {showActions && (
          <div css={styles.actions}>
            <ActionsContainer showCopy />
          </div>
        )}
        {showAdditionalPrice && (
          <div
            onClick={e =>
              handleClickAdditional && handleClickAdditional(item, e)
            }
            css={styles.startPrice}>
            <TextVariant
              text={`+${helperFormatPrice(item?.additionalPrice)}€`}
              variant="title03"
              style={styles.startPriceText}
            />
          </div>
        )}
      </div>
      <div
        css={[
          styles.footer,
          {
            marginTop: !showPrice ? 'auto' : '',
          },
        ]}>
        <TextVariant
          text={helperLabelTranslation(item)}
          variant="textRegular"
          style={styles.label}
        />

        {showPrice && (
          <div css={styles.price} className="flex-align-center">
            <div className="flex-center" css={styles.productIcons}>
              {item.isArchived && <CrossedOutEyes />}
              {item.isOutOfStock && <HexaExclamation />}
              {item.isParentArchived && (
                <CrossedOutEyes color={THEME.colors.orange} />
              )}
              {showAdditionalPrice && (
                <>
                  <SmallCrossIcon />
                  <EyeMediumIcon color="green" />
                  <CheckCircleSmallIcon />
                </>
              )}
            </div>
            <div className="flex-center">
              <TextVariant
                text={`${helperFormatPrice(item?.price || 0)} €`}
                variant="title03"
                style={{
                  color: priceColor,
                }}
              />
              {showProductType && (
                <ProductType type={item.type} bgColor={typeColor} />
              )}
            </div>
          </div>
        )}
      </div>
      {item.position && <BadgeCounter count={item.position} />}

      {(showAdd || showCross) && !disabled && (
        <div css={styles.iconContainer}>
          <div className="icon" css={[styles.icon({showAdd})]}>
            <CrossIcon color={THEME.colors.white} />
          </div>
        </div>
      )}
      {showOverlay && !disabled && (
        <div css={styles.overlay} className="overlay" />
      )}
      {disabled && <div css={styles.disabled} />}
    </div>
  );
};

const styles = {
  card: (showPointer: boolean = true) =>
    css({
      backgroundColor: THEME.colors.white,
      userSelect: 'none',
      border: '1px solid #A7A7AB',
      borderRadius: 2,
      aspectRatio: '1/1',
      position: 'relative',
      cursor: showPointer ? 'pointer' : 'default',
      display: 'flex',
      flexDirection: 'column',
      '&:hover': {
        '.icon': {
          opacity: 1,
          top: -10,
        },

        '.overlay': {
          visibility: 'visible',
        },
      },
    }),
  imgContainer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: '68%',
    width: '100%',
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
  }),
  label: css({
    color: THEME.colors.bgDark,
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginBottom: '0.2rem',
  }),
  price: css({
    color: THEME.colors.primary,
    justifyContent: 'space-between',
  }),
  footer: css({
    padding: 5,
    display: 'grid',
  }),
  productIcons: css({
    gap: '0.1rem',
  }),
  productType: css({
    backgroundColor: THEME.colors.primary,
    color: 'white',
    height: 14,
    width: 14,
    marginLeft: '2px',
    borderRadius: '50%',
    fontSize: 7,
    fontWeight: '700',
  }),
  iconContainer: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  }),
  icon: ({showAdd}: {showAdd: boolean}) =>
    css({
      cursor: 'pointer',
      borderRadius: '50%',
      opacity: 0,
      height: 40,
      width: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: THEME.colors[showAdd ? 'green' : 'red'],
      transform: `rotate(${showAdd ? 0 : 45}deg)`,
      transition: '0.3s linear',
    }),
  overlay: css({
    position: 'absolute',
    inset: 0,
    borderRadius: 2,
    backgroundColor: 'rgba(255,255,255,0.6)',
    visibility: 'hidden',
  }),
  disabled: css({
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    inset: 0,
  }),
  actions: css({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  startPrice: css({
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: THEME.colors.yellowDark,
    padding: '0.2rem 0.3rem',
    borderRadius: 2,
    cursor: 'pointer',
    zIndex: 99,
  }),
  startPriceText: css({
    color: THEME.colors.white,
    fontWeight: '700',
  }),
};

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/fr';
import timezone from 'dayjs/plugin/timezone';

dayjs.locale('fr');
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.extend(utc);
dayjs.locale('fr');

export const helperDate = (
  date: string | Date | null = new Date(),
  format: string = 'YYYY-MM-DD HH:mm:ss',
  utc: boolean = false,
  isStats: boolean = false,
) => {
  let dateFormat = utc
  ? dayjs(date).utc().format(format)
  : dayjs(date).format(format);
  
  if (isStats) {
    const tz = 'Europe/Paris';
    dateFormat = dayjs(date).tz(tz).format(format);
  }

  if (dateFormat === 'Invalid Date') return '';
  return dateFormat;
};

export const helperResetDate = (
  date: string | Date,
  option: {
    hour?: number;
    minute?: number;
    second?: number;
  } = {
    hour: 0,
    minute: 0,
    second: 0,
  },
) => {
  return dayjs(date)
    .hour(option.hour || 0)
    .minute(option.minute || 0)
    .second(option.second || 0);
};

export const helperSameDay = (prevDate?: Date, nextDate?: Date) => {
  if (!prevDate || !nextDate) return false;

  return (
    prevDate.getDate() === nextDate.getDate() &&
    prevDate.getMonth() === nextDate.getMonth() &&
    prevDate.getFullYear() === nextDate.getFullYear()
  );
};

export const helperSameHour = (prevDate?: Date, nextDate?: Date) => {
  if (!prevDate || !nextDate) return false;

  return (
    prevDate.getHours() === nextDate.getHours() &&
    prevDate.getMinutes() === nextDate.getMinutes()
  );
};

import { addPosEntityModalAction, addPosStructureModalAction, addPosTerminalModalAction, addPosUserModalAction } from "../redux/modals";

export const helperPosModule = (section: string) => {
  switch (section) {
    case 'TREES':
      return {
        section: 'Ajouter une arborescence pos',
        modalAction: addPosStructureModalAction,
      };
    case 'USERS':
      return {
        section: 'Ajouter un utilisateur',
        modalAction: addPosUserModalAction,
      };
    case 'TERMINALS':
      return {
        section: 'Ajouter un terminal',
        modalAction: addPosTerminalModalAction,
      };
    default:
      return {
        section: 'Ajouter une entité',
        modalAction: addPosEntityModalAction,
      };
  }
};
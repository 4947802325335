import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  updateMode: boolean;
  updatedAccount: any;
}

const initialState: ISlice = {
  updateMode: false,
  updatedAccount: null
};

const slice = createSlice({
  name: 'parameters',
  initialState,
  reducers: {
    updateModeAction(state, action) {
      state.updateMode = action.payload;
    },
    updatedAccountAction(state, action) {
      state.updatedAccount = action.payload;
    }
  },
});

export const {
  updateModeAction,
  updatedAccountAction
} = slice.actions;

export default slice.reducer;

import {toPng} from 'html-to-image';

export const helperDownloadQrCode = async (el: HTMLElement, name: string) => {
  const dataUrl = await toPng(el, {cacheBust: true});
  if (dataUrl) {
    const link = document.createElement('a');
    link.download = name;
    link.href = dataUrl;
    link.click();
    link.remove();
  }
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useQuery} from '@tanstack/react-query';
import {
  getBrandInfos,
  putBrandInfos,
  uploadBrandAsset,
} from '../../../../api/fetchBrandInfos';
import {Title} from '../../../../components/atoms/Title';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {CenterBlock} from './CenterBlock';
import {Footer} from './Footer';
import {Center} from './HeaderSections/Center';
import {LeftBlock} from './LeftBlock';
import {RightBlock} from './RightBlock';
import {useFormik} from 'formik';
import {useEffect} from 'react';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {helperDisplayToast} from '../../../../helpers/helperToast';

export const Informations = withRedirectAndNavigation(() => {
  //
  const auth = useAppSelector(state => state.auth.auth);

  const {data} = useQuery(['brandInfos'], () => getBrandInfos(auth?.brandId));

  const {
    setFieldValue,
    handleChange,
    setValues,
    handleSubmit,
    values,
    isSubmitting,
  } = useFormik({
    initialValues: data as any,
    // validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true);
      if (values.asset.icon?.name || values.asset.logo?.name) {
        const formData = new FormData();
        ['icon', 'logo'].forEach(key => {
          if (values.asset[key]?.name) {
            formData.append(key, values.asset[key]);
          }
        });

        await uploadBrandAsset(auth?.brandId, formData);
      }

      if (JSON.stringify(values) !== JSON.stringify(data)) {
        const {
          brand_label,
          address,
          language_id,
          ape,
          detail_color,
          email,
          description,
          zip,
          city,
          longitude,
          latitude,
          legal_entity,
          title,
          phone,
          siret,
          tva_code,
          company_label,
        } = values;

        const res = await putBrandInfos(auth?.brandId, {
          label: brand_label,
          language_id: language_id || 1,
          address,
          ape,
          detail_color,
          email,
          description,
          zip,
          city,
          longitude,
          latitude,
          legal_entity,
          title,
          phone,
          siret,
          tva_code,
          company_label,
          init_order_status: 3,
        });
        if (res?.status === 200) {
          helperDisplayToast('Informations modifiées', true);
        } else {
          helperDisplayToast(
            res?.data?.message || 'Impossible de modifier les informations',
            false,
          );
        }
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!data) return;
    setValues(data);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div css={styles.container}>
      <Header
        centerSection={
          <Center
            handleSubmit={() => handleSubmit()}
            isSubmitting={isSubmitting}
          />
        }
      />
      <div css={styles.content}>
        <Title title="Informations générales" />
        <div css={styles.main}>
          <LeftBlock formikValues={values} formikHandleChange={handleChange} />
          <CenterBlock
            formikValues={values}
            formikHandleChange={handleChange}
          />
          <RightBlock
            formikValues={values}
            formikHandleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        </div>
        <Footer formikValues={values} formikHandleChange={handleChange} />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: css({
    padding: '2vh',
    height: '87%',
  }),
  main: css(`
    display: flex;
    justify-content: space-evenly;
    height: 60%;
    @media (max-width: 1024px) {
      display: block;
      height: auto
    }
  `),
};

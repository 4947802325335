/* @jsxImportSource @emotion/react */
import {useTranslation} from 'react-i18next';
import {CardLargeIcon} from '../../../../../../../assets/svgs/CardIcon';
import {CartIcon} from '../../../../../../../assets/svgs/CartIcon';
import {TicketIcon} from '../../../../../../../assets/svgs/TicketIcon';
import {MetricBox} from '../../../../../../../components/molecules/MetricBox';
import {THEME} from '../../../../../../../styles/theme';
import {css} from '@emotion/react';
import {M_SCREEN_TABLET} from '../../../../../../../constants/ResponsiveSize';
import {IStatHourlyByDOW} from '../../../../../../../api/fetchStats';
import {helperFormatPriceRoundToString} from '../../../../../../../helpers/helperFormatPrice';
import {useAppSelector} from '../../../../../../../hooks/useReduxHook';

export const Metrics = ({
  weekdays,
}: {
  weekdays?: IStatHourlyByDOW['weekdays'];
}) => {
  const {t} = useTranslation();
  const isCompare = useAppSelector(s => s.filter.isCompare);
  const currentMetricMode = useAppSelector(s => s.filter.currentMetricMode);

  const totalSales =
    weekdays?.reduce((acc, item) => {
      return acc + item.sales.TTC[currentMetricMode];
    }, 0) || 0;

  const totalCompareSales = weekdays?.reduce((acc, item) => {
    return acc + item.compareSales?.TTC[currentMetricMode];
  }, 0);

  const totalOrders =
    weekdays?.reduce((acc, item) => {
      return acc + item.orders[currentMetricMode];
    }, 0) || 0;

  const totalCompareOrders = weekdays?.reduce((acc, item) => {
    return acc + item.compareOrders?.[currentMetricMode];
  }, 0);

  const totalAverage_cart =
    weekdays?.reduce((acc, item) => {
      return acc + item.average_cart;
    }, 0) || 0;

  const totalCompareAverage_cart = weekdays?.reduce((acc, item) => {
    return acc + item.compareAverageCart;
  }, 0);

  return (
    <div className="flex flex-center" css={styles.container}>
      <MetricBox
        Icon={CardLargeIcon}
        label={t('DAILY_TURNOVER')}
        value={helperFormatPriceRoundToString(totalSales)}
        secondValue={
          isCompare ? helperFormatPriceRoundToString(Number(totalCompareSales)) : undefined
        }
        borderColor={THEME.colors.primary}
        item="TURNOVER"
      />

      <MetricBox
        Icon={TicketIcon}
        label={t('NUMBER_OF_ORDERS')}
        value={totalOrders}
        secondValue={isCompare ? totalCompareOrders : undefined}
        borderColor={THEME.colors.yellowDark}
        item="ORDERS"
      />

      <MetricBox
        Icon={CartIcon}
        label={t('MEDIUM_BASKET')}
        value={helperFormatPriceRoundToString(totalAverage_cart)}
        secondValue={
          isCompare
            ? helperFormatPriceRoundToString(Number(totalCompareAverage_cart))
            : undefined
        }
        borderColor={THEME.colors.green}
        item="MEDIUM"
      />
    </div>
  );
};

const styles = {
  container: css({
    gap: '1rem',
    margin: '2rem 0',
    [M_SCREEN_TABLET]: {
      flexDirection: 'column',
    },
  }),
};

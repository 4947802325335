import {helperJustLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';

export const getPosUsers = async () => {
  //
  console.log('### getPosUsers ###');
  const res: any = await axiosInstance
    .get(`/buser/backoffice/pos-users`)
    .catch(err => {
      console.log('❌ getPosUsers : ', err);
    });
  return res?.data?.data;
};

export const createPosUser = async (body: object) => {
  //
  helperJustLog('### createPosUser ###');
  const res: any = await axiosInstance
    .post(`/buser/backoffice/pos-users`, body)
    .catch(err => {
      console.log('❌ createPosUser : ', err);
    });
  return res;
};

export const updatePosUser = async (uuid: string, body: object) => {
  //
  helperJustLog('### updatePosUser ###');
  const res: any = await axiosInstance
    .put(`/buser/backoffice/pos-users/${uuid}`, body)
    .catch(err => {
      console.log('❌ updatePosUser : ', err);
    });
  return res;
};

export const deletePosUser = async (uuid: string) => {
  //
  helperJustLog('### deletePosUser ###');
  const res: any = await axiosInstance
    .delete(`/buser/backoffice/pos-users/${uuid}`)
    .catch(err => {
      console.log('❌ deletePosUser : ', err);
    });
  return res;
};
/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {columnsProducts} from './columns';
import {Bottom} from './HeaderSections/Bottom';
import {Center} from './HeaderSections/Center';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {useQuery} from '@tanstack/react-query';
import {
  fetchProductDetails,
  fetchProducts,
} from '../../../../api/fetchProducts';
import {useEffect, useState} from 'react';
import {AddProductModal} from '../../../../components/modals/AddProductModal';
import {useNavigate} from 'react-router-dom';
import {productPreRegisterAction} from '../../../../redux/myCatalogSection/products';
import {useDebounce} from '../../../../hooks/useDebounce';
import {helperFormatPrice} from '../../../../helpers/helperFormatPrice';

export const Products = withRedirectAndNavigation(() => {
  const search = useAppSelector(s => s.filter.search);
  const orderBy = useAppSelector(s => s.filter.orderBy);
  const isArchived = useAppSelector(s => s.filter.isArchived);
  const addProductModal = useAppSelector(state => state.modals.addProductModal);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [limit] = useState(20);
  const offset = (page - 1) * limit;
  const debouncedSearchTerm = useDebounce(search, 500);

  const {data, isLoading} = useQuery(
    ['fetchProducts', debouncedSearchTerm, offset, limit, orderBy, isArchived],
    () =>
      fetchProducts({
        search: debouncedSearchTerm,
        offset,
        limit,
        orderBy: orderBy.filter(ord =>
          [
            'label',
            'price',
            'loyaltyCost',
            'loyaltyGain',
            'createdAt',
            'updatedAt',
          ].includes(ord.field),
        ),
        isArchived,
      }),
  );
  useEffect(() => {
    setRowCount(oldRowCount =>
      data?.totalItems !== undefined ? data?.totalItems : oldRowCount,
    );
  }, [data?.totalItems]);

  const dataItems = data?.items?.map((item: any) => {
    return {
      ...item,
      handleClickEdit: async () => {
        const data = await fetchProductDetails(item.uuid);
        if (data) {
          dispatch(
            productPreRegisterAction({
              ...data,
              price: helperFormatPrice(data.price, 'en'),
            }),
          );
          navigate('/online/catalog/products/edit');
        }
      },
    };
  });

  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} bottomSection={<Bottom />} />
      <div css={styles.content}>
        <NewDataGrid
          rows={dataItems}
          columns={columnsProducts}
          rowHeight={85}
          hasPagination
          page={page}
          loading={isLoading}
          setPage={setPage}
          totalRows={rowCount}
          rowPerPage={limit}
        />
      </div>
      {addProductModal && <AddProductModal />}
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: {
    padding: '2vh',
    height: '87%',
  },
};

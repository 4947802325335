import {PayloadAction, createSlice} from '@reduxjs/toolkit';

type PeriodType = 'day' | 'week' | 'month' | 'year';

interface ISlice {
  typeFilterDate:
    | null
    | 'equal'
    | 'between'
    | 'period'
    | 'before'
    | 'after'
    | 'inferior'
    | 'superior';
  sortFilterDate: null | 'ASC' | 'DESC';
  fromDate: Date | null;
  toDate: Date | null;
  fromTime: Date | null;
  toTime: Date | null;
  periodType: PeriodType;
}

const initialState: ISlice = {
  typeFilterDate: 'between',
  sortFilterDate: null,
  fromDate: new Date(),
  toDate: new Date(),
  fromTime: new Date(),
  toTime: new Date(),
  periodType: 'day',
};

const slice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    typeFilterDateAction: (state, action) => {
      state.typeFilterDate = action.payload;
    },
    sortFilterDateAction: (state, action) => {
      state.sortFilterDate = action.payload;
    },
    fromDateAction: (state, action) => {
      state.fromDate = action.payload;
    },
    toDateAction: (state, action) => {
      state.toDate = action.payload;
    },
    fromTimeAction: (state, action) => {
      state.fromTime = action.payload;
    },
    toTimeAction: (state, action) => {
      state.toTime = action.payload;
    },
    periodTypeAction: (state, action: PayloadAction<PeriodType>) => {
      state.periodType = action.payload;
    },
  },
});

export const {
  typeFilterDateAction,
  sortFilterDateAction,
  fromDateAction,
  toDateAction,
  fromTimeAction,
  toTimeAction,
  periodTypeAction,
} = slice.actions;

export default slice.reducer;

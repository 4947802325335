import {SVGProps} from 'react';

export const LogOutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8.375 8.125a.69.69 0 0 0 .472-.183.606.606 0 0 0 .195-.442V1.25a.606.606 0 0 0-.195-.442.69.69 0 0 0-.472-.183.69.69 0 0 0-.471.183.606.606 0 0 0-.196.442V7.5c0 .166.07.325.196.442a.69.69 0 0 0 .471.183Z"
      fill="#EC5174"
    />
    <path
      d="M11.435 1.944a.708.708 0 0 0-.51-.04.658.658 0 0 0-.39.308.59.59 0 0 0-.043.478c.054.158.172.29.33.366a5.155 5.155 0 0 1 2.405 2.368 4.713 4.713 0 0 1 .33 3.255 4.98 4.98 0 0 1-1.886 2.75c-.94.693-2.1 1.07-3.296 1.07a5.564 5.564 0 0 1-3.296-1.07 4.98 4.98 0 0 1-1.886-2.75 4.714 4.714 0 0 1 .33-3.255 5.155 5.155 0 0 1 2.405-2.368.663.663 0 0 0 .204-.152.616.616 0 0 0 .155-.457.597.597 0 0 0-.072-.235.635.635 0 0 0-.162-.19.682.682 0 0 0-.487-.146.702.702 0 0 0-.25.068c-1.328.643-2.387 1.685-3.007 2.96a5.892 5.892 0 0 0-.412 4.069c.353 1.36 1.183 2.571 2.358 3.437a6.954 6.954 0 0 0 4.12 1.336c1.494 0 2.945-.47 4.12-1.336 1.175-.866 2.005-2.076 2.358-3.437a5.892 5.892 0 0 0-.412-4.07c-.62-1.274-1.678-2.316-3.006-2.96Z"
      fill="#EC5174"
    />
  </svg>
);

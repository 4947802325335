/**@jsxImportSource @emotion/react */
import {ImageCustom} from '../atoms/ImageCustom';
import {ICustomLanguage} from '../../redux/myTranslationsSection/translations';
import {THEME} from '../../styles/theme';
import {css} from '@emotion/react';

export const TranslationLangImg = ({
  onClick,
  language,
  active,
  marginTop,
}: {
  onClick?: () => void;
  language: ICustomLanguage;
  active?: boolean;
  marginTop?: string;
}) => {
  return (
    <div onClick={onClick} css={styles.container(marginTop)}>
      <ImageCustom
        noTrunc
        src={language.icon_path}
        alt={language.label}
        styles={styles.img(!!active)}
      />
    </div>
  );
};

const styles = {
  container: (marginTop?: string) =>
    css({
      marginTop: marginTop,
    }),
  img: (active: boolean) => ({
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '2px solid',
    borderColor: active ? THEME.colors.primary : 'transparent',
  }),
};

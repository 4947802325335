import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  totalsByTVA: any;
  totalsByPaymentMethod: any;
  transactions: any;
}

const initialState: ISlice = {
  totalsByTVA: {
    amountByTva: [],
    totalTTC: 0,
    totalHT: 0,
    totalTVA: 0,
  },
  totalsByPaymentMethod: {
    totalsByPaymentMethod: [],
    totalAmount: 0,
  },
  transactions: {
    transactions: [],
    totalAmount: 0,
    totalTransactions: 0,
  },
};

const slice = createSlice({
  name: 'accounting',
  initialState,
  reducers: {
    totalsByTVAAction(state: any, action: any) {
      state.totalsByTVA = action.payload;
    },
    totalsByPaymentMethodAction(state: any, action: any) {
      state.totalsByPaymentMethod = action.payload;
    },
    transactionsAction(state: any, action: any) {
      state.transactions = action.payload;
    },
  },
});

export const {
  totalsByTVAAction,
  totalsByPaymentMethodAction,
  transactionsAction,
} = slice.actions;

export default slice.reducer;

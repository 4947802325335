import {withNavigation} from './withNavigation';
import {withRedirectionBO} from './withRedirectionBO';

export const withRedirectAndNavigation = (
  WrappedComponent: React.ComponentType,
) => {
  const ComponentWithRedirection = withRedirectionBO(WrappedComponent);
  const CombineHoc = withNavigation(ComponentWithRedirection);
  return CombineHoc;
};

import {useQuery} from '@tanstack/react-query';
import {
  getAllVendorStructure,
  getAllVendorStructureOld,
} from '../api/fetchVendorStructure';
import {useFlagBCatalog} from './useFlagBCatalog';
import {useAppSelector} from './useReduxHook';

export const useVendorStructure = () => {
  const brand = useAppSelector(s => s.brand.brand);
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {data: vendorStructure, isLoading} = useQuery(
    ['getVendorStructure', brand.id],
    () =>
      flagBCatalogEnabled
        ? getAllVendorStructure()
        : getAllVendorStructureOld(brand.id),
    {
      enabled: flagBCatalogReady,
    },
  );

  return {
    vendorStructure,
    isLoading,
  };
};

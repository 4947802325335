export const ChevronDownIcon = ({
  color,
  width = 14,
  height = 9,
}: {
  color?: string;
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66724 7.91211C7.03345 8.27832 7.62817 8.27832 7.99439 7.91211L13.6194 2.28711C13.9856 1.9209 13.9856 1.32617 13.6194 0.959961C13.2532 0.59375 12.6584 0.59375 12.2922 0.959961L7.32935 5.92285L2.36646 0.96289C2.00024 0.596679 1.40552 0.596679 1.03931 0.96289C0.673096 1.3291 0.673096 1.92383 1.03931 2.29004L6.66431 7.91504L6.66724 7.91211Z"
      fill={color || '#151A30'}
    />
  </svg>
);

import {SVGProps} from 'react';

export const MotoBikeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6.94.613c0-.255.207-.462.463-.462h.795c.458 0 .866.29 1.016.724l.156.448.982-.49a.625.625 0 0 1 .277-.066h.162c.17 0 .308.138.308.308v1.233c0 .169-.138.308-.308.308h-.162a.63.63 0 0 1-.277-.066l-.672-.335.572 1.64a3.07 3.07 0 0 1 2.39.874.463.463 0 0 1-.648.66 2.148 2.148 0 0 0-1.51-.617c-1.015 0-1.886.702-2.097 1.648-.039.163-.06.333-.06.508H5.555c0 1.02-.828 1.848-1.849 1.848a1.838 1.838 0 0 1-1.848-1.848h-.616a.616.616 0 0 1-.616-.616v-.616A2.464 2.464 0 0 1 3.09 3.232h1.848c.341 0 .617.275.617.616v1.54c0 .34.275.616.616.616h1.372a3.05 3.05 0 0 1 1.802-1.939L8.342 1.18a.153.153 0 0 0-.144-.104h-.795A.461.461 0 0 1 6.94.613ZM4.63 4.156H3.09c-.85 0-1.54.689-1.54 1.54v.308h3.21a1.528 1.528 0 0 1-.13-.616V4.156Zm0 2.772H2.782a.924.924 0 0 0 1.848 0Zm.462-5.237a.46.46 0 1 1 0 .925H2.936a.46.46 0 1 1 0-.925h2.156Zm3.543 5.237c0-1.038.81-1.848 1.848-1.848 1.02 0 1.848.81 1.848 1.848 0 1.02-.827 1.848-1.848 1.848a1.838 1.838 0 0 1-1.848-1.848Zm1.848.924a.924.924 0 1 0 0-1.849.924.924 0 0 0 0 1.85Z"
      fill="#fff"
    />
  </svg>
);

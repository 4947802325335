export const TakeAwayIcon = () => (
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none">
    <g clip-path="url(#clip0_8602_10821)">
      <path
        d="M4.12036 3.65067V2.72656C4.12036 1.53562 5.20547 0.570312 6.54614 0.570312C7.88682 0.570312 8.97192 1.53562 8.97192 2.72656V3.65067H10.3581C10.932 3.65067 11.3977 4.06459 11.3977 4.57478V8.57924C11.3977 9.59961 10.4664 10.4275 9.31846 10.4275H3.77382C2.62547 10.4275 1.69458 9.59961 1.69458 8.57924V4.57478C1.69458 4.06459 2.16003 3.65067 2.7342 3.65067H4.12036ZM5.15998 3.65067H7.9323V2.72656C7.9323 2.046 7.3107 1.49442 6.54614 1.49442C5.78159 1.49442 5.15998 2.046 5.15998 2.72656V3.65067ZM2.7342 4.57478V8.57924C2.7342 9.08943 3.19965 9.50335 3.77382 9.50335H9.31846C9.89242 9.50335 10.3581 9.08943 10.3581 8.57924V4.57478H8.97192V5.6529C8.97192 5.90896 8.74018 6.11496 8.45211 6.11496C8.16405 6.11496 7.9323 5.90896 7.9323 5.6529V4.57478H5.15998V5.6529C5.15998 5.90896 4.92823 6.11496 4.64017 6.11496C4.35211 6.11496 4.12036 5.90896 4.12036 5.6529V4.57478H2.7342Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_8602_10821">
        <rect
          x="0.955322"
          y="0.570312"
          width="11.0893"
          height="9.85714"
          rx="1.31429"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

import {useQuery} from '@tanstack/react-query';
import {TranslationItem} from './TranslationItem';
import {fetchSuggestionCategoriesTranslations} from '../../../../api/fetchTranslations';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {useTranslations} from '../../../../hooks/useTranslations';

export const Suggestions = ({title}: {title: string}) => {
  const {
    isOpened,
    handleToggleIsOpened,
    handleChangeSearch,
    handleToggleShowAll,
    showAll,
    debounceSearch,
  } = useTranslations();
  const languageSelected = useAppSelector(
    s => s.myTranslationsSection.translations.languageSelected,
  );
  const {data, isLoading} = useQuery(
    [
      'fetchSuggestionCategoriesTranslations',
      debounceSearch,
      !showAll ? languageSelected?.id : undefined,
      showAll,
    ],
    () =>
      fetchSuggestionCategoriesTranslations({
        search: debounceSearch,
        languageId:
          !showAll && languageSelected
            ? Number(languageSelected?.id)
            : undefined,
      }),
    {
      enabled: isOpened && !!languageSelected,
    },
  );

  return (
    <TranslationItem
      title={title}
      items={data || []}
      isLoading={isLoading}
      isOpened={isOpened}
      showAll={showAll}
      onToggleIsOpened={handleToggleIsOpened}
      onToggleShowAll={handleToggleShowAll}
      onChange={handleChangeSearch}
      category={'Suggestions'}
    />
  );
};

import {IImgUrlSet} from '@bill-app-types/b-types';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';

type ValuePercent = {
  value: number;
  percent: number;
};

export interface IGraphCoord {
  total: number;
  suffix: string;
  graph: {
    x: string;
    y: number;
  }[];
}
export interface IStatGlobal {
  orders: IGraphCoord;
  sales: IGraphCoord;
  averageCart: IGraphCoord;
  showOrders?: boolean;
}

export const getStatGlobal = async (data: {
  from: Date | null;
  to: Date | null;
  scale: string | null;
  channelIds?: string;
  consumptionModeIds?: string;
  forStats?: boolean;
}) => {
  const query = helperGenerateQuery(data);
  //
  console.log('### getStatGlobal ###');
  const res = await axiosInstance
    .get<{data: IStatGlobal}>(`/bstat/sales/global${query}`)
    .catch(err => {
      console.log('❌ getStatGlobal : ', err);
    });
  return res?.data.data;
};

export interface IITem {
  itemId: number;
  sales: {
    TTC: {
      total_price: number;
      total_percent: number;
    };
  };
  orders: ValuePercent;
  itemName: string;
}

export const getStatByItem = async (
  data: {
    from: Date | null;
    to: Date | null;
    channelIds?: string;
    consumptionModeIds?: string;
    vendorStructureIds?: number[];
    cardIds?: number[];
    limit?: number;
    forStats?: boolean;
    types: (string | number)[];
    parentBarcodes?: string[];
    search?: string;
  },
  signal?: AbortSignal,
) => {
  const query = helperGenerateQuery(data);
  //
  console.log('### getStatByItem ###');
  const res = await axiosInstance
    .get<{
      data: {
        itemId: number;
        sales: {
          TTC: {
            total_price: number;
            total_percent: number;
          };
        };
        orders: ValuePercent;
        itemName: string;
      };
    }>(`/bstat/sales/item${query}`, {
      signal,
    })
    .catch(err => {
      console.log('❌ getStatByItem : ', err);
    });
  return res?.data.data;
};

export interface IStatItemDetail {
  itemId: number;
  itemName: string;
  img_url_set: IImgUrlSet;
  imgUrl: string;
  type: string;
  sales: {
    TTC: {
      total_price: number;
      total_percent: number;
    };
    HT: {
      total_price: number;
    };
  };

  orders: number;
  consumptionModes: {
    id: number;
    sales: {
      TTC: {
        total_price: number;
        base_total_price: number;
      };
      HT: {
        total_price: number;
        base_total_price: number;
      };
      orders: number;
    };
  }[];
  channels: {
    id: number;
    sales: {
      TTC: {
        total_price: number;
        base_total_price: number;
      };
      HT: {
        total_price: number;
        base_total_price: number;
      };
      orders: number;
    };
  }[];
}

export const getStatItemDetail = async (itemId: string, query?: {}) => {
  //
  console.log('### getStatItemDetail ###');
  const q = helperGenerateQuery(query);

  const res = await axiosInstance
    .get<{
      data: IStatItemDetail;
    }>(`/bstat/sales/item/${itemId}/detail${q}`)
    .catch(err => {
      console.log('❌ getStatItemDetail : ', err);
    });
  return res?.data.data;
};

export interface IStatItemDetailGraph {
  orders: {
    x: string;
    y: number;
  }[];
  sales: {
    TTC: {
      x: string;
      y: number;
    }[];
    HT: {
      x: string;
      y: number;
    }[];
  };
}

export const getStatItemDetailGraph = async (itemId: string, query?: {}) => {
  //
  const q = helperGenerateQuery(query);

  console.log('### getStatItemDetail ###');
  const res = await axiosInstance
    .get<{
      data: IStatItemDetailGraph;
    }>(`/bstat/sales/item/${itemId}/graph${q}`)
    .catch(err => {
      console.log('❌ getStatItemDetailGraph : ', err);
    });
  return res?.data.data;
};

export interface IStatItemDetailParent {
  id: number;
  label: string;
  img_url_set: IImgUrlSet;
  imgUrl: string;
  sales: {
    TTC: number;
    HT: number;
    orders: number;
  };
  orders: number;
}

export const getStatItemDetailParent = async (itemId: string, query?: {}) => {
  //
  console.log('### getStatItemDetail ###');

  const q = helperGenerateQuery(query);

  const res = await axiosInstance
    .get<{
      data: {
        parentItems: IStatItemDetailParent[];
      };
    }>(`/bstat/sales/item/${itemId}/parent${q}`)
    .catch(err => {
      console.log('❌ getStatItemDetail : ', err);
    });
  return res?.data.data;
};

export interface IStatItemDetailChilds extends IStatItemDetailParent {
  id: number;
  label: string;

  items: IStatItemDetailParent[];
}

export const getStatItemDetailCoursesChilds = async (
  itemId: string,
  query?: {},
) => {
  //
  console.log('### getStatItemDetail ###');
  const q = helperGenerateQuery(query);

  const res = await axiosInstance
    .get<{
      data: IStatItemDetailChilds[];
    }>(`/bstat/sales/item/${itemId}/childs/course${q}`)
    .catch(err => {
      console.log('❌ getStatItemDetail : ', err);
    });
  return res?.data.data;
};

export interface IStatItemDetailMenusChilds {
  label: string;
  items: {
    barcode: string;
    label: string;
    img_url_set: IImgUrlSet;
    imgUrl: string;
    orders: number;
    sales: {
      TTC: number;
      HT: number;
    };
  }[];
  barcode: string;
}

export const getStatItemDetailMenusChilds = async (
  itemId: string,
  query?: {},
) => {
  console.log('### getStatItemDetail ###');
  const q = helperGenerateQuery(query);

  const res = await axiosInstance
    .get<{
      data: IStatItemDetailMenusChilds[];
    }>(`/bstat/sales/item/${itemId}/childs/menus${q}`)
    .catch(err => {
      console.log('❌ getStatItemDetail : ', err);
    });
  return res?.data.data;
};

interface IVendorStructure {
  label: string;
  vendorStructureId: string;
  orders: ValuePercent;
  sales: {
    TTC: {
      total_price: number;
      total_percent: number;
    };
    HT: {
      total_price: number;
      total_percent: number;
    };
  };
}

export const getStatByVendorStructure = async (data: {
  from: Date | null;
  to: Date | null;
  forStats?: boolean;
  limit?: number;
}) => {
  const query = helperGenerateQuery(data);
  //
  console.log('### getStatByVendorStructure ###');
  const res = await axiosInstance
    .get<{
      data: IVendorStructure[];
    }>(`/bstat/sales/vendor-structure${query}`)
    .catch(err => {
      console.log('❌ getStatByVendorStructure : ', err);
    });

  return res?.data.data;
};

export const getStatByCard = async (data: {
  from: Date | null;
  to: Date | null;
  forStats?: boolean;
  limit?: number;
}) => {
  const query = helperGenerateQuery(data);
  //
  console.log('### getStatByCard ###');
  const res = await axiosInstance
    .get<{
      data: IVendorStructure[];
    }>(`/bstat/sales/cards${query}`)
    .catch(err => {
      console.log('❌ getStatByCard : ', err);
    });

  return res?.data.data;
};

interface IStatCommon {
  sales: {
    TTC: {
      total_price: number;
    };
  };
  orders: {
    value: number;
  };
  percent_sales: {
    value: number;
    suffix: string;
  };
  percent_orders: {
    value: number;
    suffix: string;
  };
  name?: string;
  color?: string;
  value?: number;
  suffix?: string;
}

export interface IStatConsumption extends IStatCommon {
  consumptionModeIds: number;
}

export const getStatByConsumptionMode = async (data: {
  from: Date;
  to: Date;
  channelIds?: string;
  forStats?: boolean;
}) => {
  //
  const query = helperGenerateQuery(data);

  console.log('### getStatByComsumptionMode ###');
  const res = await axiosInstance
    .get<{data: any[]}>(`/bstat/sales/consumption-mode${query}`)
    .catch(err => {
      console.log('❌ getStatByComsumptionMode : ', err);
    });
  return res?.status === 200 ? res?.data?.data : [];
};

export interface IStatChannel extends IStatCommon {
  channelIds: number;
}

export const getStatByChannel = async (data: {
  from: Date;
  to: Date;
  consumptionModeIds?: string;
  forStats?: boolean;
}) => {
  //
  const query = helperGenerateQuery(data);

  console.log('### getStatByChannel ###');
  const res = await axiosInstance
    .get<{
      data: any[];
    }>(`/bstat/sales/channel${query}`)
    .catch(err => {
      console.log('❌ getStatByChannel : ', err);
    });
  return res?.status === 200 ? res?.data?.data : [];
};

type TIStatHourlyTaxtItem = {
  total: number;
  percent: number;
  average: number;
};

type TIStatHourlTax = {
  TTC: TIStatHourlyTaxtItem;
  HT: TIStatHourlyTaxtItem;
};

export interface IStatHourlyByDOW {
  heatmaps: {
    orders: {
      [key: number]: number;
    };
    sales: {
      TTC: {
        [key: number]: number;
      };
      HT: {
        [key: number]: number;
      };
    };
    average_sales: {
      TTC: {
        [key: number]: number;
      };
      HT: {
        [key: number]: number;
      };
    };
  };
  weekdays: {
    weekday: number;
    sales: TIStatHourlTax;
    orders: TIStatHourlyTaxtItem;
    average_cart: number;
    compareSales: TIStatHourlTax;
    compareAverageCart: number;
    compareOrders: TIStatHourlyTaxtItem;
    hours: {
      hour: number;
      sales: TIStatHourlTax;
      orders: TIStatHourlyTaxtItem;
      average_cart: number;
      compareSales: TIStatHourlTax;
      compareAverageCart: number;
      compareOrders: TIStatHourlyTaxtItem;
    }[];
  }[];
}

export const getStatHourlyByDOW = async (data: {}) => {
  const query = helperGenerateQuery(data);
  const res = await axiosInstance
    .get<{
      data: IStatHourlyByDOW;
    }>(`/bstat/sales/hourlyByDOW${query}`)
    .catch(err => {
      console.log('❌ getStatHourlyByDOW : ', err);
    });

  return res?.data?.data;
};

export const exportSalesGlobal = async (data: {
  from: string;
  to: string;
  channelIds?: string;
  consumptionModeIds?: string;
  scale: string;
}) => {
  //
  console.log('### exportSalesGlobal ###');
  const res: any = await axiosInstance
    .post(`/bstat/sales/global/excel`, data)
    .catch(err => {
      console.log('❌ exportSalesGlobal : ', err);
    });
  return res;
};

export const exportSalesRecap = async (data: {
  from: string;
  to: string;
  channelIds?: string;
  brandId: number;
  forStats?: boolean;
}) => {
  const query = helperGenerateQuery(data);
  //
  console.log('### exportSalesRecap ###');
  const res: any = await axiosInstance
    .post(`/bstat/sales/export${query}`)
    .catch(err => {
      console.log('❌ exportSalesRecap : ', err);
    });
  return res;
};

export const exportSaleByProduct = async (data: {
  from: Date;
  to: Date;
  forStats?: boolean;
}) => {
  //
  console.log('### exportSaleByProduct ###');
  const res: any = await axiosInstance
    .post(`/bstat/sales/product/export`, data)
    .catch(err => {
      console.log('❌ exportSaleByProduct : ', err);
    });
  return res;
};

export const exportSaleByMenu = async (data: {
  from: Date;
  to: Date;
  limit: number;
  forStats?: boolean;
}) => {
  //
  console.log('### exportSaleByMenu ###');
  const res: any = await axiosInstance
    .post(`/bstat/sales/menu/export`, data)
    .catch(err => {
      console.log('❌ exportSaleByMenu : ', err);
    });
  return res;
};

export const exportSaleByOption = async (data: {
  from: Date;
  to: Date;
  limit: number;
  forStats?: boolean;
}) => {
  //
  console.log('### exportSaleByOption ###');
  const res: any = await axiosInstance
    .post(`/bstat/sales/option/export`, data)
    .catch(err => {
      console.log('❌ exportSaleByOption : ', err);
    });
  return res;
};

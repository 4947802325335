import {SVGProps} from 'react';

export const CreditCardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="23"
    viewBox="0 0 21 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9043 9.65447H17.4043V9.15447V8.24795C17.4043 7.88993 17.2759 7.53787 17.0342 7.27118C16.791 7.00285 16.45 6.84143 16.0828 6.84143H4.58105C4.21378 6.84143 3.87281 7.00285 3.62963 7.27118C3.38794 7.53787 3.2595 7.88993 3.2595 8.24795V9.15447V9.65447H3.7595H16.9043ZM17.4551 6.66069L17.8255 6.32492L17.4551 6.66069C17.8296 7.07402 18.0474 7.64427 18.0474 8.24795V15.5001C18.0474 16.1038 17.8296 16.6741 17.4551 17.0874C17.0819 17.4991 16.5871 17.7197 16.0828 17.7197H4.58105C4.07676 17.7197 3.58187 17.4991 3.20877 17.0874C2.83418 16.6741 2.61639 16.1038 2.61639 15.5001V8.24795C2.61639 7.64427 2.83418 7.07402 3.20877 6.66069C3.58187 6.24899 4.07676 6.02838 4.58105 6.02838H16.0828C16.5871 6.02838 17.0819 6.24899 17.4551 6.66069ZM3.7595 10.4675H3.2595V10.9675V15.5001C3.2595 15.8582 3.38794 16.2102 3.62963 16.4769C3.87281 16.7452 4.21378 16.9067 4.58105 16.9067H16.0828C16.45 16.9067 16.791 16.7452 17.0342 16.4769C17.2759 16.2102 17.4043 15.8582 17.4043 15.5001V10.9675V10.4675H16.9043H3.7595Z"
      fill={props.color || "#3C6AF6"}
      stroke={props.color || "#3C6AF6"}
    />
    <path
      d="M9.51071 14.094H6.22451C6.15599 14.094 6.07904 14.0644 6.01407 13.9927C5.94763 13.9194 5.90295 13.8103 5.90295 13.6875C5.90295 13.5646 5.94763 13.4555 6.01407 13.3822C6.07904 13.3105 6.15599 13.2809 6.22451 13.2809H9.51071C9.57922 13.2809 9.65618 13.3105 9.72115 13.3822C9.78759 13.4555 9.83226 13.5646 9.83226 13.6875C9.83226 13.8103 9.78759 13.9194 9.72115 13.9927C9.65618 14.0644 9.57922 14.094 9.51071 14.094Z"
      fill={props.color || "#3C6AF6"}
      stroke={props.color || "#3C6AF6"}
    />
    <path
      d="M14.4401 14.094H12.797C12.7285 14.094 12.6515 14.0644 12.5866 13.9927C12.5201 13.9194 12.4755 13.8103 12.4755 13.6875C12.4755 13.5646 12.5201 13.4555 12.5866 13.3822C12.6515 13.3105 12.7285 13.2809 12.797 13.2809H14.4401C14.5086 13.2809 14.5856 13.3105 14.6506 13.3822C14.717 13.4555 14.7617 13.5646 14.7617 13.6875C14.7617 13.8103 14.717 13.9194 14.6506 13.9927C14.5856 14.0644 14.5086 14.094 14.4401 14.094Z"
      fill={props.color || "#3C6AF6"}
      stroke={props.color || "#3C6AF6"}
    />
  </svg>
);

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
// import {DashboardHeaderTabs} from '../HeaderSections/DashboardHeaderTabs';
import {BlockTop} from './BlockTop';
import {DashboardHeaderBottom} from '../HeaderSections/DashboardHeaderBottom';

export const Sales = withRedirectAndNavigation(() => {
  return (
    <div css={styles.container}>
      <Header
        // centerSection={<DashboardHeaderTabs />}
        bottomSection={<DashboardHeaderBottom />}
      />
      <div css={styles.content}>
        <BlockTop />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    height: '100vh',
    '@media (max-width: 1024px)': {
      height: 'auto',
    },
  }),
  content: css({
    width: '100%',
    padding: '1rem 2vh',
    overflowY: 'auto',
    '@media (max-width: 1024px)': {
      padding: '1rem 1vh',
    },
  }),
};

/** @jsxImportSource @emotion/react */
import {useContext, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {
  filterAmountAction,
  orderByAction,
  resetFilterAmountAction,
  resetOrderByAction,
} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
import {EuroIcon} from '../../assets/svgs/EuroIcon';
import {FilterNumberContainer} from '../molecules/FilterNumberContainer';
import {useTranslation} from 'react-i18next';
import store from '../../redux/storeConfig';

export const AmountMaxFilter = () => {
  const {t} = useTranslation();
  const {dispatch} = useContext<any>(UtilsContext);
  const {min, max, sort} = useAppSelector(s => s.filter.amount);
  const typeFilter = useAppSelector(s => s.date.typeFilterDate);
  const [amountMinValue, setAmountMinValue] = useState<number | null>(null);
  const [amountMaxValue, setAmountMaxValue] = useState<number | null>(null);
  const [forceClose, setForceClose] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    if (Number.isNaN(Number(value))) return;
    if (name === 'min') {
      setAmountMinValue(Number(value));
    } else {
      setAmountMaxValue(Number(value));
    }
  };

  const handleResetFilterAmount = () => {
    setAmountMinValue(null);
    setAmountMaxValue(null);
    dispatch(resetOrderByAction('amount'));
    dispatch(resetFilterAmountAction());
  };

  const handleSubmit = () => {
    const sortFilterDate = store.getState().date.sortFilterDate;
    const canSetValue = amountMinValue !== null || amountMaxValue !== null;
    const min = amountMinValue || 0;

    dispatch(
      filterAmountAction({
        min: canSetValue ? (typeFilter === 'inferior' ? 0 : min) : null,
        max: canSetValue
          ? typeFilter === 'equal'
            ? min
            : typeFilter === 'superior'
            ? 0
            : amountMaxValue
          : null,
        sort: sortFilterDate,
      }),
    );

    dispatch(
      orderByAction({
        field: 'amount',
        sort: sortFilterDate,
      }),
    );

    setForceClose(true);

    setTimeout(() => {
      setForceClose(false);
    }, 100);
  };

  const canReset = max !== null || min !== null || !!sort;

  return (
    <FilterButton
      label={t('AMOUNT')}
      valueType="list"
      sortFilter={sort}
      values={
        min === max
          ? [{name: min}]
          : [
              {
                name: min === 0 ? 0 : min,
              },
              {
                name: max === 0 ? 'X' : max,
              },
            ]
      }
      showResetButton={canReset}
      onReset={handleResetFilterAmount}
      hideFilterIcon={canReset}
      forceClose={forceClose}>
      <FilterNumberContainer
        min={Number(amountMinValue)}
        max={Number(amountMaxValue)}
        onChange={handleChange}
        label="AMOUNT"
        Icon={EuroIcon}
        onSubmit={handleSubmit}
      />
    </FilterButton>
  );
};

import {useQuery} from '@tanstack/react-query';
import {fetchSqsTotalByTva} from '../api/fetchSqsHistory';
import {useAppSelector} from './useReduxHook';

export const useSqsTva = () => {
  const dates = useAppSelector(s => s.filter.dates);
  const {data, isLoading} = useQuery(['fetchSqsTotalByTva', dates], () =>
    fetchSqsTotalByTva(dates),
  );

  return {
    data: data?.data,
    isLoading,
  };
};

/** @jsxImportSource @emotion/react */

import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addOptionModalAction} from '../../redux/modals';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {ToggleButtonLabel} from '../molecules/ToggleButtonLabel';
import {UploadButton} from '../atoms/UploadButton';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';
import {
  createCatalogOption,
  updateCatalogOption,
} from '../../api/fetchCatalogOptions';
import {
  updateModeAction,
  updatedOptionAction,
} from '../../redux/myCatalogSection/options';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {useEffect, useState} from 'react';
import {updateItemImage} from '../../api/fetchImage';
import {helperFormatPrice} from '../../helpers/helperFormatPrice';
import {useBrandAccess} from '../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../constants/frontPolicies';
import {ICreateOptionItemPayload} from '@bill-app-types/b-types';

export const AddOptionModal = () => {
  const initialValues = {
    img: '',
    label: '',
    price: 0,
  };
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();

  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const [form, setForm] = useState({
    isArchived: false,
    isGroupable: false,
  });
  const [image, setImage] = useState<any>(null);

  const updateMode = useAppSelector(
    state => state.myCatalogSection.options.updateMode,
  );
  const updatedOption = useAppSelector(
    state => state.myCatalogSection.options.updatedOption,
  );

  useEffect(() => {
    if (updateMode && updatedOption) {
      setForm({
        isArchived: updatedOption.isArchived,
        isGroupable: updatedOption.isGroupable,
      });
    }
  }, [updateMode, updatedOption]);

  const handleOnCancel = () => {
    dispatch(addOptionModalAction(false));
    dispatch(updatedOptionAction(null));
    dispatch(updateModeAction(false));
  };

  const handleSuccess = (res: {data: {message: string}}) => {
    handleOnCancel();
    helperDisplayToast(res?.data?.message || 'Option enregistrée', true);
  };

  const handleChangeImage = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const imageURL = e.target.result;
        setImage({url: imageURL, file});
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUpdateImage = async (uuid: string) => {
    const res = await updateItemImage(uuid, image?.file);
    if (res?.status !== 204) {
      helperDisplayToast("Impossible de modifier l'image", false);
    }
    clientQuery.invalidateQueries(['getCatalogOptions']);
  };

  const handleSubmit = async (
    data: ICreateOptionItemPayload,
    {setSubmitting, resetForm}: any,
  ) => {
    if (data.label && data.price >= 0) {
      if (updateMode) {
        const res = await updateCatalogOption(updatedOption.uuid, {
          ...data,
          backofficeLabel: data.label,
          productionLabel: data.label,
          isArchived: form.isArchived,
          isGroupable: form.isGroupable,
          price: Math.round((data.price || 0) * 100),
        });
        if (res && res.status === 204) {
          handleSuccess(res);
          resetForm();
          if (image) {
            handleUpdateImage(updatedOption.uuid);
          } else {
            clientQuery.invalidateQueries(['getCatalogOptions']);
          }
        } else {
          helperDisplayToast(
            res?.data?.message || "Impossible de modifier l'option",
            false,
          );
        }
      } else {
        const res = await createCatalogOption({
          ...data,
          backofficeLabel: data.label,
          productionLabel: data.label,
          isArchived: form.isArchived,
          isGroupable: form.isGroupable,
          price: Math.round((data.price || 0) * 100),
        });
        if (res && res.status === 200) {
          handleSuccess(res);
          resetForm();
          if (image) {
            handleUpdateImage(res?.data?.data?.uuid);
          } else {
            clientQuery.invalidateQueries(['getCatalogOptions']);
          }
        } else {
          helperDisplayToast(
            res?.data?.message || "Impossible de créer l'option",
            false,
          );
        }
      }
    } else {
      if (data.price < 0) {
        helperDisplayToast('Le prix doit être positif', false);
      } else {
        helperDisplayToast('Tous les champs doivent être complétés ', false);
      }
    }
  };

  return (
    <ModalWrapper
      title={updateMode ? "Modifier l'option" : 'Option'}
      minWidth="28%"
      validateLabel={t('REGISTER_OPTION')}
      cancelLabel={t(updateMode ? 'Annuler la modification' : 'CANCEL_CREATE')}
      onCancel={handleOnCancel}
      onSubmit={handleSubmit}
      initialValues={
        updateMode
          ? {
              ...updatedOption,
              price: helperFormatPrice(updatedOption.price, 'en'),
            }
          : initialValues
      }>
      <UploadButton
        style={{margin: 'auto', marginTop: 25}}
        width={194}
        x={650}
        y={650}
        handleChange={handleChangeImage}
        value={!!image ? image?.url : updatedOption?.image || undefined}
        disabled={!hasAuthorization}
        name="img"
      />
      <FieldCustomInput
        disabled={!hasAuthorization}
        label={t('OPTION_NAME')}
        name="label"
        required
      />
      <FieldCustomInput
        label={t('OPTION_PRICE')}
        name="price"
        required
        type="number"
      />
      <FieldsRequired />
      <ToggleButtonLabel
        label="Afficher sur les écrans master"
        marginBottom={'0.5rem'}
        active={updatedOption?.isArchived || form.isArchived}
        onToggle={() =>
          setForm({
            ...form,
            isArchived: !form.isArchived,
          })
        }
        disabled={!hasAuthorization}
      />
      <ToggleButtonLabel
        label="Grouper les éléments en production"
        active={updatedOption?.isGroupable || form.isGroupable}
        onToggle={() =>
          setForm({
            ...form,
            isGroupable: !form.isGroupable,
          })
        }
        disabled={!hasAuthorization}
      />
    </ModalWrapper>
  );
};

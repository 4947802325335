/** @jsxImportSource @emotion/react */
import {useTranslation} from 'react-i18next';
import {ColorPickerLabel} from '../atoms/ColorPickerLabel';
import {InputLabel} from '../atoms/InputLabel';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {SelectList} from '../molecules/SelectList';
import {ToggleButtonSections} from '../molecules/ToggleButtonSections';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {useEffect, useState} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {
  createAreaProduction,
  fetchAreaProductions,
  updateAreaProduction,
} from '../../api/fetchAreaProductions';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addProductionAreaModalAction} from '../../redux/modals';
import {
  areaProductionSelectedAction,
  areaProductionUpdatedAction,
} from '../../redux/myRestaurantSection/areaProductions';
import {helperLabelTranslation} from '../../helpers/helperLabel';
import {IProductionAreaGetReturn} from '@bill-app-types/b-types';

export const AddProductionAreaModal = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const [form, setForm] = useState({
    label: '',
    parentCategoryUuid: '',
    color: '',
    position: 0,
    isRecipe: false,
  });
  const [isSaving, setIsSaving] = useState(false);
  const areaProductionUpdated = useAppSelector(
    s => s.myRestaurantSection.areaProductions.areaProductionUpdated,
  );

  const clientQuery = useQueryClient();

  const {data} = useQuery(['fetchAreaProductions'], fetchAreaProductions);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const {name, value} = e.currentTarget;
    setForm({...form, [name]: value});
  };

  const handleCancel = (e?: any) => {
    dispatch(areaProductionUpdatedAction(null));
    dispatch(addProductionAreaModalAction(false));
  };

  const handleSaveAndContinueAfter = async () => {
    setIsSaving(true);

    await handleSubmit(null, {setSubmitting: () => {}});

    setIsSaving(false);
  };

  const handleSubmit = async (continueAfterSave: any, {setSubmitting}: any) => {
    setSubmitting(true);

    try {
      let res;

      const data = {
        ...form,
        position: Number(form.position),
      };

      if (areaProductionUpdated) {
        res = await updateAreaProduction(areaProductionUpdated.uuid, {
          ...data,
          parentCategoryUuid: data.parentCategoryUuid || null,
        });
      } else {
        if (
          data.parentCategoryUuid === '' ||
          data.parentCategoryUuid === null
        ) {
          delete (data as {parentCategoryUuid?: string | null})
            .parentCategoryUuid;
        }
        res = await createAreaProduction(data);
      }

      if ([200, 204].includes(res.status)) {
        if (res?.data?.data && continueAfterSave) {
          dispatch(
            areaProductionSelectedAction({
              uuid: res?.data?.data,
            }),
          );
        }
        helperDisplayToast('Zone de production enregistré', true);
        handleCancel();
        clientQuery.invalidateQueries(['fetchAreaProductions']);
      } else {
        helperDisplayToast(
          "Impossible d'enregistrer la zone de production",
          false,
        );
      }
    } catch (error) {
      helperDisplayToast('Une erreur est survenue', false);
    } finally {
      setSubmitting(false);
    }
  };

  const isDisabled = form?.label === '';

  useEffect(() => {
    if (areaProductionUpdated) {
      setForm({
        label: helperLabelTranslation(areaProductionUpdated),
        parentCategoryUuid: areaProductionUpdated.parentCategoryUuid || '',
        color: areaProductionUpdated.color || '',
        isRecipe: areaProductionUpdated.isRecipe || false,
        position: areaProductionUpdated.position ?? '',
      });
    }
  }, [areaProductionUpdated]);

  useEffect(() => {
    if (data?.length && !areaProductionUpdated) {
      setForm(_f => ({
        ..._f,
        position: data.length + 1,
      }));
    }
  }, [data, areaProductionUpdated]);

  const newData = areaProductionUpdated
    ? data?.filter(item => item.uuid !== areaProductionUpdated?.uuid)
    : data;

  return (
    <ModalWrapper
      title={t(
        !areaProductionUpdated
          ? 'ADD_A_PRODUCTION_AREA'
          : 'EDIT_A_PRODUCTION_AREA',
      )}
      minWidth={'50%'}
      saveLabel={t('SAVE_AND_CONTINUE_CREATION_LATER')}
      cancelLabel={t('CANCEL')}
      validateLabel={t(
        !areaProductionUpdated ? 'CONTINUE_CREATION' : 'SAVE_CHANGES',
      )}
      btnSaveWidth={'68%'}
      btnCancelWidth={'40%'}
      btnValidateWidth={areaProductionUpdated ? '58%' : '30%'}
      btnCancelBgColor={
        areaProductionUpdated ? THEME.colors.red : THEME.colors.primary
      }
      hideCancelButton={!areaProductionUpdated}
      onSubmit={handleSubmit}
      onSave={!areaProductionUpdated ? handleSaveAndContinueAfter : null}
      onCancel={handleCancel}
      onClose={handleCancel}
      isSaving={isSaving}
      disabled={isDisabled}>
      <div css={styles.container}>
        <InputLabel
          label={t('NAME_OF_PRODUCTION_AREA')}
          name="label"
          value={form.label}
          onChange={handleChange}
          required
        />
        <SelectList
          label={t('PARENT_PRODUCTION_AREA')}
          name="parentCategoryUuid"
          value={form.parentCategoryUuid}
          onChange={handleChange}
          placeHolder={t('PARENT_PRODUCTION_AREA')}
          list={
            newData
              ? [
                  {
                    label: t('NONE'),
                    value: '',
                  },
                  ...newData?.map((item: IProductionAreaGetReturn) => ({
                    label: helperLabelTranslation(item),
                    value: item.uuid,
                  })),
                ]
              : []
          }
          manageByFormik={false}
        />

        <ColorPickerLabel
          label={t('PRODUCTION_AREA_COLOR')}
          name="color"
          value={form.color}
          onChange={handleChange}
        />

        <div css={styles.toggles}>
          <ToggleButtonSections
            sections={['PRODUCTS', 'INGREDIENTS']}
            defaultSection={form.isRecipe ? 'INGREDIENTS' : 'PRODUCTS'}
            localManagerState
            onChange={(section: string) => {
              setForm(_f => ({
                ..._f,
                isRecipe: section === 'INGREDIENTS',
              }));
            }}
          />
        </div>

        <FieldsRequired />
      </div>
    </ModalWrapper>
  );
};

const styles = {
  container: css({
    paddingTop: '1rem',
    paddingBottom: '0.5rem',
  }),
  toggles: css({
    display: 'flex',
    justifyContent: 'flex-start',
  }),
};

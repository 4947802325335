/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../atoms/TextVariant';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {useTranslation} from 'react-i18next';
import {THEME} from '../../styles/theme';
import {CheckIcon} from '../../assets/svgs/CheckIcon';

export const FilterClick = ({
  title,
  selectedValue = null,
  selectedText = 'Par défaut',
  onSubmit,
  handleClickValue,
}: {
  title: string;
  selectedValue?: null | boolean;
  selectedText?: string;
  onSubmit?: () => void;
  handleClickValue: any;
}) => {
  const {t} = useTranslation();

  return (
    <div css={styles.container}>
      <div className="flex-space-between">
        <TextVariant text={title} variant="textSemiBold" />
        <div css={styles.sortSelectedContainer}>
          <div
            css={[
              styles.sortItem,
              {
                backgroundColor:
                  selectedValue === null ? '#EEF1F6' : THEME.colors.primary,
              },
            ]}
            className="flex-space-between"
            onClick={handleClickValue}>
            <TextVariant
              text={selectedText}
              variant="textSemiBold"
              style={{
                color: selectedValue === null ? '#A7A7AB' : '#fff',
              }}
            />
            {selectedValue === null ? '' : <CheckIcon />}
          </div>
        </div>
      </div>

      <ButtonCustom
        label={t('APPLY')}
        width={'100%'}
        marginTop={'0.5rem'}
        onClick={onSubmit}
      />
    </div>
  );
};

const styles = {
  container: css({
    minWidth: '14rem',
    maxHeight: '15rem',
    background: THEME.colors.white,
    top: '0',
    left: '0',
    padding: 12,
    borderRadius: 2,
  }),
  sortSelectedContainer: css({
    position: 'relative',
  }),
  sortItem: css({
    padding: '5px',
    gap: 5,
    height: 25,
    background: THEME.colors.bgLight,
    cursor: 'pointer',
    borderRadius: 2,
    userSelect: 'none',
  }),
};

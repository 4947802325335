/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../../atoms/TextVariant';
import {ArrowLeft} from '../../../assets/svgs/ArrowLeft';
import {SUGGESTION_TYPES} from '@bill-app-types/b-types';
import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHook';
import {selectedAdditionalListAction} from '../../../redux/myCatalogSection/products';
import {helperDisplayToast} from '../../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {
  deleteItemAdditionalSale,
  deleteItemDetailsAdditionalSale,
} from '../../../api/fetchItems';
import {BRAND_AUTHORIZATION} from '../../../constants/frontPolicies';
import {useBrandAccess} from '../../../hooks/useBrandAccess';
import {ProductList} from '../../molecules/ProductList';
import {THandleDragAndDrop} from '../SortableListVerticalContainer';
import {updateCategoriesPosition} from '../../../api/fetchCategories';

export const AdditionalSalesCard = ({
  title,
  data,
  handleOpen,
  dropDown,
  active,
  itemUuid,
  isLoading = false,
}: {
  title: string;
  data: any;
  handleOpen: any;
  dropDown: number;
  active: boolean;
  itemUuid: string;
  isLoading?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();

  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const selectedAdditionalType = useAppSelector(
    s => s.myCatalogSection.products.selectedAdditionalType,
  );

  useEffect(() => {
    if (active) {
      dispatch(selectedAdditionalListAction(data));
    }
  }, [active, data]);

  const handleDragAndDrop: THandleDragAndDrop = async ({items}) => {
    if (!hasAuthorization) {
      return;
    }

    const categoryUuids = items.map(item => item?.uuid);

    const res = await updateCategoriesPosition(categoryUuids);

    if (res?.status === 204) {
      helperDisplayToast(res?.data?.message || 'Position modifiée', true);
    } else {
      helperDisplayToast('Impossible de modifier la position', false);
    }
  };

  const handleRemoveAdditional = async (additional: any) => {
    if (!hasAuthorization) {
      return;
    }
    const res =
      selectedAdditionalType === SUGGESTION_TYPES.PRODUCT_DETAIL
        ? await deleteItemDetailsAdditionalSale(itemUuid, additional?.uuid)
        : await deleteItemAdditionalSale(itemUuid, additional?.uuid);

    const successMessage = res?.data?.message || 'Vente additionnelle retirée';
    const errorMessage = 'Impossible de retirer la vente additionnelle';

    if (res && res.status === 204) {
      helperDisplayToast(successMessage, true);
      clientQuery.invalidateQueries(
        selectedAdditionalType === SUGGESTION_TYPES.PRODUCT_DETAIL
          ? ['getItemDetailsAdditionalSales']
          : ['getItemAdditionalSales'],
      );
    } else {
      helperDisplayToast(errorMessage, false);
    }
  };

  return (
    <div css={styles.card}>
      <div
        className="flex-space-between"
        css={styles.cardHeader}
        onClick={() => handleOpen(dropDown)}>
        <TextVariant variant="textRegular" text={title} />
        <span css={styles.icon(active)}>
          <ArrowLeft color="black" />
        </span>
      </div>

      <div css={styles.dropdown(active)}>
        <div
          style={{
            overflow: 'hidden',
          }}>
          <ProductList
            bg="white"
            data={data || []}
            handleOnClick={handleRemoveAdditional}
            loading={isLoading}
            showPrice={false}
            specificImage="/images/cart.svg"
            showCross={hasAuthorization}
            draggable
            handleDragAndDrop={handleDragAndDrop}
            noTruncImage
            imageObjectFit="contain"
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  content: css({
    paddingTop: '1vh',
    height: '100%',
    overflow: 'auto',
  }),
  card: css({
    width: '100%',
    padding: '0 1rem',

    background: 'white',
    marginBottom: 10,
    cursor: 'pointer',
  }),
  icon: (isOpened: boolean) =>
    css({
      transform: `rotate(${isOpened ? '270deg' : '180deg'})`,
      transition: 'all 0.3s ease-in-out',
    }),

  cardHeader: css({
    height: 39,
  }),

  dropdown: (isOpened: boolean) =>
    css({
      display: 'grid',
      gridTemplateRows: isOpened ? '1fr' : '0fr',
      transition: 'all 0.3s ease-in-out',
      paddingBottom: '1rem',
    }),

  list: css({
    display: 'grid',
    padding: '1rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px,1fr))',
    gap: '0.5rem',
    overflow: 'hidden',
  }),
};

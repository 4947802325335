import {IStatItemDetailGraph} from '../api/fetchStats';
import {helperDate} from './helperDate';
import {helperFormatPrice} from './helperFormatPrice';

export const helperSalesData = (data: any, isEn: boolean = false) => {
  const graphsData =
    typeof data === 'object'
      ? Object.keys(data)
          .reduce((acc: any, key: any) => {
            return [
              ...acc,
              data[key].graph?.map((item: any) => ({
                ...item,
                [key]: item.y,
              })),
            ];
          }, [])
          .flat()
      : [];

  const axisX = graphsData
    .map((item: any) => item?.x)
    .reduce((acc: any, x: any) => {
      return acc.includes(x) ? acc : [...acc, x];
    }, []);

  const datas = axisX?.map((x: any) => {
    const orders = graphsData.find(
      (item: any) => item?.x === x && item?.orders,
    );
    const sales = graphsData.find((item: any) => item?.x === x && item?.sales);
    const averageCart = graphsData.find(
      (item: any) => item?.x === x && item?.averageCart,
    );

    const xDate = new Date(x);

    return {
      x: x.includes(':') ? x : helperDate(xDate, 'DD-MM-YYYY'),
      orders: orders ? orders.orders : 0,
      sales: sales
        ? isEn
          ? helperFormatPrice(sales.sales)
          : sales.sales / 100
        : 0,
      averageCart: averageCart
        ? isEn
          ? helperFormatPrice(averageCart.averageCart)
          : averageCart.averageCart / 100
        : 0,
      orderSuffix: data?.orders?.suffix,
      salesSuffix: data?.sales?.suffix,
      averageCartSuffix: data?.averageCart?.suffix,
    };
  });

  return datas;
};

export const helperCompareSalesData = (data: any, isEn: boolean = false) => {
  const graphsData =
    typeof data === 'object'
      ? Object.keys(data)
          .reduce((acc: any, key: any) => {
            return [
              ...acc,
              data[key].graph?.map((item: any) => ({
                ...item,
                [key]: item.y,
              })),
            ];
          }, [])
          .flat()
      : [];

  const axisX = graphsData
    .map((item: any) => item?.x)
    .reduce((acc: any, x: any) => {
      return acc.includes(x) ? acc : [...acc, x];
    }, []);

  const datas = axisX?.map((x: any) => {
    const orders = graphsData.find(
      (item: any) => item?.x === x && item?.orders,
    );
    const sales = graphsData.find((item: any) => item?.x === x && item?.sales);
    const averageCart = graphsData.find(
      (item: any) => item?.x === x && item?.averageCart,
    );

    const xDate = new Date(x);

    return {
      x: x.includes(':') ? x : helperDate(xDate, 'DD-MM-YYYY'),
      forCompareOrders: orders ? orders.orders : 0,
      forCompareSales: sales
        ? isEn
          ? helperFormatPrice(sales.sales)
          : sales.sales / 100
        : 0,
      forCompareAverageCart: averageCart
        ? isEn
          ? helperFormatPrice(averageCart.averageCart)
          : averageCart.averageCart / 100
        : 0,
      orderSuffix: data?.orders?.suffix,
      salesSuffix: data?.sales?.suffix,
      averageCartSuffix: data?.averageCart?.suffix,
    };
  });

  return datas;
};

export const helperCombinedCompareSalesData = (
  data: any,
  dataCompare: any,
  isEn = false,
) => {
  const datas = helperSalesData(data, isEn);
  const compareDatas = helperCompareSalesData(dataCompare, isEn);

  const combinedDataFormatted = datas.map((item: any, index: number) => {
    return {
      ...item,
      ...compareDatas[index],
      forCompareOrders: compareDatas[index]?.forCompareOrders || 0,
      forCompareSales: compareDatas[index]?.forCompareSales || 0,
      forCompareAverageCart: compareDatas[index]?.forCompareAverageCart || 0,
      x: compareDatas[index] ? `${item.x} - ${compareDatas[index]?.x}` : item.x,
    };
  });

  return combinedDataFormatted;
};

export const helperSalesItemData = (
  data: IStatItemDetailGraph,
  isEn: boolean = false,
  currentMode: 'TTC' | 'HT' = 'TTC',
) => {
  const graphsData =
    typeof data === 'object'
      ? Object.keys(data)
          .reduce(
            (acc, key) => {
              return [
                ...acc,
                key === 'orders'
                  ? data[key]?.map(item => ({
                      ...item,
                      [key]: item.y,
                    }))
                  : data[key as 'sales']?.[currentMode].map(item => ({
                      ...item,
                      [key]: item.y,
                    })),
              ];
            },
            [] as {
              x: string;
              y: number;
              orders?: number;
              sales?: number;
            }[][],
          )
          .flat()
      : [];

  const axisX = graphsData
    .map(item => item?.x)
    .reduce((acc, x) => {
      return acc.includes(x) ? acc : [...acc, x];
    }, [] as string[]);

  const datas = axisX?.map(x => {
    const orders = graphsData.find(item => item?.x === x && item?.orders);
    const sales = graphsData.find(item => item?.x === x && item?.sales);
    const xDate = new Date(x);

    return {
      x: x.includes(':') ? x : helperDate(xDate, 'DD-MM-YYYY'),
      orders: orders ? orders.orders : 0,
      sales: sales
        ? isEn
          ? helperFormatPrice(Number(sales.sales))
          : Number(sales?.sales) / 100
        : 0,
      orderSuffix: '',
      salesSuffix: '€',
    };
  });

  return datas;
};

import {DisabledFiltersButton} from '../../../../components/atoms/DisabledFiltersButton';
import {FilterButton} from '../../../../components/atoms/FilterButton';
import {ChannelIdFilter} from '../../../../components/filters/ChannelIdFilter';
import {ConsumptionModeFilter} from '../../../../components/filters/ConsumptionModeFilter';
import {SearchFilter} from '../../../../components/filters/SearchFilter';
import {SyncFilter} from '../../../../components/filters/SyncFilter';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {typeFilterDateAction} from '../../../../redux/date';
import {
  filterAction,
  resetFilterChannelsAction,
  resetFilterConsumptionModesAction,
  resetFilterDateAction,
  resetIsSyncPosAction,
} from '../../../../redux/filter';

export const Bottom = () => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(s => s.filter.dates);

  const handleReset = () => {
    dispatch(resetFilterDateAction());
    dispatch(typeFilterDateAction('between'));
    dispatch(resetFilterChannelsAction());
    dispatch(resetFilterConsumptionModesAction());
    dispatch(resetIsSyncPosAction());
    dispatch(filterAction({name: 'search', values: ''}));
  };

  return (
    <>
      <DisabledFiltersButton onclick={handleReset} />
      <SearchFilter marginRight={'1vh'} />
      <FilterButton
        icon={'calendar'}
        marginRight={'1vh'}
        valueType="dates"
        values={dates}
      />

      <ChannelIdFilter marginRight={'1vh'} />
      <ConsumptionModeFilter />
      <SyncFilter />
    </>
  );
};

import {useQuery} from '@tanstack/react-query';
import {fetchSqsTransactions} from '../api/fetchSqsHistory';
import {useAppSelector} from './useReduxHook';
import {useDebounce} from './useDebounce';

export const useSqsTransactions = () => {
  const search = useAppSelector(s => s.filter.search);
  const dates = useAppSelector(s => s.filter.dates);
  const {min, max} = useAppSelector(s => s.filter.amount);

  const debounceSearch = useDebounce(search, 500);

  const {data, isLoading} = useQuery(
    ['fetchSqsTransactions', dates, min, max, debounceSearch],
    () =>
      fetchSqsTransactions({
        from: dates.from,
        to: dates.to,
        amountFrom: min,
        amountTo: max,
        search: debounceSearch,
      }),
  );

  return {
    data: data?.data,
    isLoading,
  };
};

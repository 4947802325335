/** @jsxImportSource @emotion/react */
import {deletePosUser} from '../../../../../api/fetchPosUsers';
import {DataGridActions} from '../../../../../components/newDatagrid/DataGridActions';
import {helperConfirmDialog} from '../../../../../helpers/helperConfirmDialog';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {addPosUserModalAction} from '../../../../../redux/modals';
import {
  updateModeAction,
  updatedPosUserAction,
} from '../../../../../redux/myModulesSection/pos';

export const columns = [
  {
    field: 'username',
    headerName: 'Nom du compte',
    flex: 1,
  },
  {
    field: 'isManager',
    headerName: 'Manager',
    flex: 1,
    valueGetter: (params: any) => {
      return params.isManager ? 'OUI' : 'NON';
    },
  },
  {
    field: 'actions',
    headerName: '',
    align: 'right',
    flex: 0.5,
    renderCell: (row: any) => {
      const handleClickEdit = ({dispatch}: any) => {
        dispatch(addPosUserModalAction(true));
        dispatch(updatedPosUserAction(row));
        dispatch(updateModeAction(true));
      };

      const handleClickDelete = ({refreshing}: any) => {
        helperConfirmDialog({
          onSubmit: async () => {
            const res = await deletePosUser(row.uuid);
            if (res && res.status === 204) {
              helperDisplayToast('Utilisateur caisse supprimé', true);
              refreshing('getPosUsers');
            } else {
              helperDisplayToast(
                res.data?.message ||
                  "Impossible de supprimer l'utilisateur caisse",
                false,
              );
            }
          },
        });
      };

      return (
        <DataGridActions
          handleClickEdit={handleClickEdit}
          handleClickDelete={handleClickDelete}
        />
      );
    },
  },
];

import {SVGProps} from 'react';

export const ChartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none">
    <g clipPath="url(#clip0_7798_131747)">
      <path
        d="M8.2779 2.05294V13.8387H7.20647V2.05294H8.2779ZM7.20647 0.445801C6.3192 0.445801 5.59933 1.16567 5.59933 2.05294V13.8387C5.59933 14.7259 6.3192 15.4458 7.20647 15.4458H8.2779C9.16518 15.4458 9.88504 14.7259 9.88504 13.8387V2.05294C9.88504 1.16567 9.16518 0.445801 8.2779 0.445801H7.20647ZM2.92076 8.48151V13.8387H1.84933V8.48151H2.92076ZM1.84933 6.87437C0.962054 6.87437 0.242188 7.59424 0.242188 8.48151V13.8387C0.242188 14.7259 0.962054 15.4458 1.84933 15.4458H2.92076C3.80804 15.4458 4.5279 14.7259 4.5279 13.8387V8.48151C4.5279 7.59424 3.80804 6.87437 2.92076 6.87437H1.84933ZM12.5636 4.1958H13.635V13.8387H12.5636V4.1958ZM10.9565 4.1958V13.8387C10.9565 14.7259 11.6763 15.4458 12.5636 15.4458H13.635C14.5223 15.4458 15.2422 14.7259 15.2422 13.8387V4.1958C15.2422 3.30852 14.5223 2.58866 13.635 2.58866H12.5636C11.6763 2.58866 10.9565 3.30852 10.9565 4.1958Z"
        fill={props.color || '#A7A7AB'}
      />
    </g>
    <defs>
      <clipPath id="clip0_7798_131747">
        <rect
          width="15"
          height="17.1429"
          fill="white"
          transform="translate(0.242188 -0.625488)"
        />
      </clipPath>
    </defs>
  </svg>
);

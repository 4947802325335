/** @jsxImportSource @emotion/react */
import {ORDER_STATUS} from '@bill-app-types/b-types';
import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {THEME} from '../../../styles/theme';
import {updateOrder} from '../../../api/fetchOrders';
import {helperDisplayToast} from '../../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {selectedOrderAction} from '../../../redux/order';
import {useAppDispatch} from '../../../hooks/useReduxHook';
import {useState} from 'react';
import {Loader} from '../../../components/atoms/Loader';

interface IProps {
  currentStatus?: ORDER_STATUS;
  orderUuid?: string;
}

export const OrderStatusButtons = ({
  orderUuid,
  currentStatus = ORDER_STATUS.IN_PROGRESS,
}: IProps) => {
  //
  const {t} = useTranslation();
  const clientQuery = useQueryClient();
  const dispatch = useAppDispatch();

  const [statusLoading, setStatusLoading] = useState<ORDER_STATUS | null>(null);

  const ordersStatus = Object.entries(ORDER_STATUS)
    .slice(7)
    .map(item => ({
      label: item[0],
      value: item[1],
    }))
    ?.filter(item => item.value !== ORDER_STATUS.RECEIVED);

  const handleClickOrderStatus = async (status: ORDER_STATUS) => {
    if (orderUuid) {
      setStatusLoading(status);
      const res = await updateOrder(orderUuid, status);
      if ([200, 204].includes(res.status)) {
        dispatch(selectedOrderAction(null));
        helperDisplayToast('Statut de la commande modifié', true);
        clientQuery.invalidateQueries([`getOrders`]);
        setStatusLoading(null);
      } else {
        helperDisplayToast(
          'Impossible de modifier le statut de la commande',
          false,
        );
        setStatusLoading(null);
      }
    }
  };

  return (
    <div css={styles.container}>
      {ordersStatus?.map((item: any, index) => {
        const isAfter =
          index > 0 && ordersStatus[index - 1].value === currentStatus;

        return (
          <>
            {index !== 0 && item.value !== currentStatus && !isAfter && (
              <div css={styles.border}>
                <div css={styles.line} />
              </div>
            )}
            <button
              key={index}
              disabled={item.value === currentStatus || statusLoading !== null}
              onClick={() => handleClickOrderStatus(item.value)}
              className="flex-center textSemiBold"
              css={styles.btn(
                item.value === currentStatus,
                index === 0,
                index === ordersStatus.length - 1,
              )}>
              {t(item.label)}
              {statusLoading === item.value && (
                <div css={styles.loader}>
                  <Loader size={20} />
                </div>
              )}
            </button>
          </>
        );
      })}
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'center',
  }),
  btn: (
    active: boolean = false,
    isFirt: boolean = false,
    isLast: boolean = false,
  ) =>
    css({
      textTransform: 'uppercase',
      height: active ? 47 : 35,
      padding: active ? '0px 20px' : '0px 20px',
      backgroundColor: active ? THEME.colors.primary : THEME.colors.white,
      color: active ? THEME.colors.white : THEME.colors.gray2,
      borderRadius: active ? 5 : 0,
      borderTop: active ? 0 : '1px solid #EEF1F6',
      borderBottom: active ? 0 : '1px solid #EEF1F6',
      borderLeft: isFirt ? '1px solid #EEF1F6' : 0,
      borderRight: isLast ? '1px solid #EEF1F6' : 0,
      cursor: 'pointer',
      position: 'relative',
    }),
  border: css({
    width: 2,
    height: 35,
    borderTop: '1px solid #EEF1F6',
    borderBottom: '1px solid #EEF1F6',
    alignItems: 'center',
    display: 'flex',
  }),
  line: css({
    width: '100%',
    height: 15,
    backgroundColor: '#CCD6E0',
  }),
  loader: css({
    position: 'absolute',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Outlet, RouteObject, useParams} from 'react-router-dom';
import {withRedirectAndNavigation} from '../../../components/hoc/withRedirectAndNavigation';
import {ModuleList} from './List';
// TODO : lazy import for all sections
import BDriveSection from './BDriveSection';
import BKioskSection from './BKioskSection';
import BWebAppSection from './BWebAppSection';
import BPosSection from './BPosSection';
import {useSubscription} from '../../../hooks/useSubscription';
import PosPreview from './BPosSection/PosPreview';
import BLoyaltySection from './BLoyaltySection';
import BDeliverySection from './BDeliverySection';
import {BRAND_AUTHORIZATION} from '../../../constants/frontPolicies';
import {useBrandAccess} from '../../../hooks/useBrandAccess';
import {useFlagBCatalog} from '../../../hooks/useFlagBCatalog';
import {PARENT_DEFAULT_SCREEN} from '../../../constants/global';

export const MyModules = withRedirectAndNavigation(() => {
  const {pathname} = useParams();
  useSubscription();
  return (
    <div css={styles.container}>
      <div css={styles.content}>
        {pathname === 'bdrive' && <BDriveSection />}
        {pathname === 'bkiosk' && <BKioskSection />}
        {pathname === 'bwebapp' && <BWebAppSection />}
        {pathname === 'bpos' && <BPosSection />}
        {pathname === 'bloyalty' && <BLoyaltySection />}
        {pathname === 'bdelivery' && <BDeliverySection />}
      </div>
    </div>
  );
});

const Root = () => {
  const {brandHasAuthorization} = useBrandAccess();
  const isParent = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  if (isParent && flagBCatalogReady && !flagBCatalogEnabled) {
    window.location.href = PARENT_DEFAULT_SCREEN;
  }

  if (isParent && !flagBCatalogEnabled) {
    return null;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export const MyModulesRouter: RouteObject = {
  path: 'modules',

  element: <Root />,
  children: [
    {
      path: '',
      element: <ModuleList />,
    },
    {
      path: ':pathname',
      element: <MyModules />,
    },
    {
      path: 'bpos/tree/edit',
      element: <PosPreview />,
    },
  ],
};

const styles = {
  container: css({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '2vh',
    overflowY: 'auto',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }),
};

import {
  IFetchPriceRulesQueryParameters,
  IPriceRulesPutPayload,
  PriceRulesFetchResponse,
} from '@bill-app-types/b-types';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';

export type IPriceRule = PriceRulesFetchResponse & {uuid: string};

export const getPriceRules = async (data: IFetchPriceRulesQueryParameters) => {
  const query = helperGenerateQuery(data);

  const res = await axiosInstance
    .get<{
      data: IPriceRule[];
    }>(`bcatalog/backoffice/price-rules${query}`, {
      params: {
        orderBy: JSON.stringify(data?.orderBy),
        type: data?.type,
      },
    })
    .catch(err => {
      console.log('❌ getPriceRules : ', err);
    });

  return res?.data?.data;
};

export const createPriceRule = async (data: IPriceRulesPutPayload) => {
  const res = await axiosInstance
    .post(`bcatalog/backoffice/price-rules`, data)
    .catch(err => {
      console.log('❌ createPriceRule : ', err);
    });

  return res;
};

export const updatePriceRule = async (
  priceRuleUuid: string,
  data: Partial<IPriceRulesPutPayload>,
) => {
  const res = await axiosInstance.put(
    `bcatalog/backoffice/price-rules/${priceRuleUuid}`,
    data,
  );

  return res;
};

export const deletePriceRule = async (priceRuleUuid: string) => {
  const res = await axiosInstance.delete(
    `bcatalog/backoffice/price-rules/${priceRuleUuid}`,
  );

  return res;
};

/** @jsxImportSource @emotion/react  */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {ToggleButton} from '../../../../components/atoms/ToggleButton';
import {helperDate} from '../../../../helpers/helperDate';
import {THEME} from '../../../../styles/theme';
import {helperFormatPrice} from '../../../../helpers/helperFormatPrice';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {duplicateProduct, updateProduct} from '../../../../api/fetchProducts';
import {helperConfirmDialog} from '../../../../helpers/helperConfirmDialog';
import {deleteItem} from '../../../../api/fetchItems';
import {ImageCustom} from '../../../../components/atoms/ImageCustom';
import {BRAND_AUTHORIZATION} from '../../../../constants/frontPolicies';
import {DataGridActions} from '../../../../components/newDatagrid/DataGridActions';
import {helperLabelTranslation} from '../../../../helpers/helperLabel';

export const columnsProducts = [
  {
    field: 'icon',
    headerName: '',
    headerClassName: 'textRegular',
    renderCell: (row: any) => {
      return (
        <div
          className="flex-align-center"
          style={{
            width: '100%',
            marginLeft: 5,
          }}>
          <div css={styles.productImgContainer}>
            <ImageCustom src={row?.image} alt={row?.label} />
          </div>
        </div>
      );
    },
  },
  {
    field: 'product',
    headerName: 'Produit',
    flex: 1,
    align: 'center',
    headerClassName: 'textRegular',
    renderCell(row: any) {
      return (
        <div className="flex-col-center" css={styles.productLabel}>
          <p>{helperLabelTranslation(row)}</p>
          <p>{row.description}</p>
        </div>
      );
    },
  },
  {
    field: 'price',
    headerName: 'Prix',
    flex: 1,
    headerClassName: 'textRegular',
    renderCell(row: any) {
      return (
        <TextVariant
          text={`${helperFormatPrice(row.price)}€`}
          variant="textSemiBold"
        />
      );
    },
  },
  {
    field: 'visibility',
    headerName: 'Visibilité',
    flex: 1,
    headerClassName: 'textRegular',
    renderCell: (row: any) => {
      const handleToggleArchived = async () => {
        const res = await updateProduct(row.uuid, {
          isArchived: !row.isArchived,
        });
        if (res.status === 204) {
          helperDisplayToast('Visibilité modifiée', true);
        } else {
          helperDisplayToast(
            res.data?.message || 'Impossible de modifier la visibilité',
            false,
          );
        }
      };

      return (
        <ToggleButton
          active={!row.isArchived}
          onToggle={handleToggleArchived}
        />
      );
    },
  },

  {
    field: 'createdAt',
    headerName: "Date d'ajout",
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter(row: any) {
      return `Le ${helperDate(row.createdAt, 'DD/MM/YYYY')}`;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Date de modification',
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter(row: any) {
      return `Le ${helperDate(row.updatedAt, 'DD/MM/YYYY')}`;
    },
  },
  {
    field: 'actions',
    headerName: '',
    headerClassName: 'textRegular',
    headerAlign: 'right',
    align: 'right',
    renderCell: (row: any) => {
      const handleClickCopy = ({refreshing}: any) => {
        helperConfirmDialog({
          validateLabel: 'Dupliquer',
          onSubmit: async () => {
            const res = await duplicateProduct(row.uuid);
            if (res && res.status === 204) {
              helperDisplayToast('Produit dupliquée', true);
              refreshing('fetchProducts');
            } else {
              helperDisplayToast(
                res.data?.message || 'Impossible de dupliquer le Produit',
                false,
              );
            }
          },
        });
      };

      const handleClickDelete = ({refreshing}: any) => {
        helperConfirmDialog({
          onSubmit: async () => {
            const res = await deleteItem(row.uuid);
            if (res && res.status === 204) {
              helperDisplayToast('Produit supprimé', true);
              refreshing('fetchProducts');
            } else {
              helperDisplayToast(
                res.data?.message || 'Impossible de supprimer le produit',
                false,
              );
            }
          },
        });
      };

      return (
        <DataGridActions
          handleClickEdit={row.handleClickEdit}
          handleClickCopy={handleClickCopy}
          handleClickDelete={handleClickDelete}
          showCopy
          authorization={{
            edit: BRAND_AUTHORIZATION.ALL,
            copy: BRAND_AUTHORIZATION.PARENT,
            delete: BRAND_AUTHORIZATION.PARENT,
          }}
        />
      );
    },
  },
];

const styles = {
  productImgContainer: css({
    width: 68,
    height: 68,
    position: 'relative',
    // backgroundColor: THEME.colors.bgLight,
    borderRadius: 2,
    border: 'none',
  }),
  productImg: css({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 2,
  }),
  pictureIcon: css({
    position: 'absolute',
    inset: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  optionType: css({
    color: THEME.colors.primary,
  }),
  productLabel: css({
    width: '100%',
  }),
  star: css({
    backgroundColor: THEME.colors.yellow,
    width: 25,
    height: 25,
    borderRadius: 2,
    marginLeft: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};

import {ButtonCustom} from '../../../../../../components/atoms/ButtonCustom';
import {useTranslation} from 'react-i18next';
import {THEME} from '../../../../../../styles/theme';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {useState} from 'react';
import {updateArea} from '../../../../../../api/fetchAreas';
import {helperDisplayToast} from '../../../../../../helpers/helperToast';
import {helperJustLog} from '../../../../../../helpers/helperLog';

export const Center = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const brandId = useAppSelector(state => state.brand.brand.id);
  const areaSelected = useAppSelector(
    s => s.myRestaurantSection.areas.areaSelected,
  );

  /* HANDLE ON CANCEL */
  const handleOnCancel = () => {
    helperJustLog('handleOnCancel');
    navigate('/online/restaurant/service-areas');
  };

  /* HANDLE ON SUBMIT */
  const handleOnSubmit = async () => {
    if (!areaSelected) return;

    setLoading(true);

    const {id, ...data} = areaSelected;

    const res = await updateArea(brandId, id, data);

    if (res.status === 200) {
      helperDisplayToast(res.message, true);
    }

    setLoading(false);
  };

  return (
    <>
      <ButtonCustom
        label={t('CANCEL')}
        onClick={handleOnCancel}
        marginRight={'10px'}
        backgroundColor={THEME.colors.red}
      />
      <ButtonCustom
        label={t('SAVE')}
        onClick={handleOnSubmit}
        isLoading={loading}
        backgroundColor={THEME.colors.green}
      />
    </>
  );
};

/** @jsxImportSource @emotion/react */
import {PictogramCard} from '../../../../../components/atoms/PictogramCard';
import {Title} from '../../../../../components/atoms/Title';
import {css} from '@emotion/react';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {useContext} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {
  createItemPictogram,
  getCatalogPictograms,
} from '../../../../../api/fetchCatalogPictograms';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {useDebounce} from '../../../../../hooks/useDebounce';
import {SearchFilter} from '../../../../../components/filters/SearchFilter';
import {IPictogramAndAllergen} from '@bill-app-types/b-types';
import {helperDisplayToast} from '../../../../../helpers/helperToast';

export const CatalogOfPictograms = () => {
  const {t} = useContext<any>(UtilsContext);
  const clientQuery = useQueryClient();

  const productPreRegister = useAppSelector(
    s => s.myCatalogSection.products.productPreRegister,
  );
  const productPictograms = useAppSelector(
    s => s.myCatalogSection.products.productPictograms,
  );
  const search = useAppSelector(s => s.filter.search);

  const debouncedSearchTerm = useDebounce(search.trim(), 500);

  const {data: pictograms} = useQuery(
    ['getCatalogPictograms', debouncedSearchTerm],
    () => getCatalogPictograms({search: debouncedSearchTerm}),
  );

  const handleClickPictogram = async (pictogramUuid: string) => {
    if (!productPictograms.find((item: any) => item?.uuid === pictogramUuid)) {
      const res = await createItemPictogram(
        productPreRegister?.uuid,
        pictogramUuid,
      );
      if (res?.status === 204) {
        helperDisplayToast(res?.data?.message || 'Pictogramme ajouté', true);
        clientQuery.invalidateQueries([`getItemPictograms`]);
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible d'ajouter le pictogramme",
          false,
        );
      }
    }
  };

  return (
    <div>
      <Title title={t('CATALOG_OF_PICTOGRAMS')} />
      <div css={styles.content}>
        <SearchFilter width={'100%'} />
        <div className="flex-wrap" css={styles.pictograms}>
          {pictograms?.map((item: IPictogramAndAllergen, index: number) => (
            <PictogramCard
              pictogram={item}
              key={index}
              handleClick={handleClickPictogram}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  content: css({
    margin: '0.5rem 0',
    marginTop: '1rem',
  }),

  pictograms: css({
    gap: '1rem',
    maxHeight: '200px',
    overflowY: 'auto',
    margin: '0.8rem 0',
  }),
};

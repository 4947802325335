/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {useAppSelector} from '../../../hooks/useReduxHook';
import {useDebounce} from '../../../hooks/useDebounce';
import {fetchProducts} from '../../../api/fetchProducts';
import {ClosedProductsContainer} from '../../molecules/ClosedProductsContainer';
import {Title} from '../../atoms/Title';
import {CrossMediumIcon} from '../../../assets/svgs/CrossIcon';
import {InfiniteScrollCustom} from '../../atoms/InfiniteScrollCustom';
import {ProductList} from '../../molecules/ProductList';
import {SearchFilter} from '../../filters/SearchFilter';
import {
  fetchCategories,
  fetchCategoryProducts,
} from '../../../api/fetchCategories';
import {ICategoryFetchReturn} from '@bill-app-types/b-types';
import {THEME} from '../../../styles/theme';
import {AbsoluteLoader} from '../../layouts/AbsoluteLoader';

export const ProductsCatalogWithCategories = ({
  showProducts,
  onToggleShowProducts,
  onClickProduct,
  handleIsDisabled,
  productsChecked,
  hideCategories,
}: {
  showProducts: boolean;
  onToggleShowProducts: () => void;
  onClickProduct: (item: any) => void;
  handleIsDisabled?: (item: any) => boolean;
  productsChecked: any[];
  hideCategories?: boolean;
}) => {
  //
  const {t} = useTranslation();
  const search = useAppSelector(s => s.filter.search);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [categorySelected, setCategorySelected] =
    useState<ICategoryFetchReturn | null>({
      uuid: null,
      label: [{languageId: 1, content: 'Tous'}],
      subCategories: [],
    } as any);

  const {data, isLoading} = useQuery<ICategoryFetchReturn[]>(
    ['fetchCategories'],
    () => fetchCategories(),
  );

  const {data: products, isLoading: productsIsLoading} = useQuery(
    ['fetchCategoryProducts', categorySelected?.uuid, debouncedSearchTerm],
    () =>
      fetchCategoryProducts(
        String(categorySelected?.uuid),
        debouncedSearchTerm,
      ),
    {
      enabled: !!categorySelected?.uuid,
    },
  );

  const {
    data: productsInfinite,
    refetch,
    fetchNextPage,
    hasNextPage,
    isLoading: productsIsLoadingInfinite,
  } = useInfiniteQuery(
    ['fetchProducts', debouncedSearchTerm],
    ({pageParam = 0}) =>
      fetchProducts({
        limit: 50,
        offset: pageParam,
        search: debouncedSearchTerm,
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        const currentTotalProducts = pages?.reduce(
          (acc, page) => acc + (page.items?.length || 1),
          0,
        );
        if (Number(lastPage?.totalItems) === currentTotalProducts) {
          return undefined;
        }
        return currentTotalProducts + 1;
      },
      enabled: !!(categorySelected && !categorySelected?.uuid),
    },
  );

  const dataProductsInfinite =
    productsInfinite?.pages.flatMap(data => data.items) || [];

  const subCategories = useMemo(
    () =>
      data &&
      [
        {
          uuid: null,
          label: [{languageId: 1, content: 'Tous'}],
          subCategories: [],
        },
        ...data,
      ]
        ?.map(item => {
          return [item, ...item?.subCategories];
        })
        .flat(),
    [data],
  );

  const handleSelectCategory = (category: any) => {
    setCategorySelected(category);
  };

  return (
    <>
      {!showProducts && (
        <ClosedProductsContainer onClick={onToggleShowProducts} />
      )}
      {showProducts && (
        <div css={styles.container}>
          <div css={styles.header}>
            <Title title={t('PRODUCTS_CATALOGUE')} />
            <div css={styles.closeIcon} onClick={onToggleShowProducts}>
              <CrossMediumIcon />
            </div>
          </div>
          <div>
            <div css={styles.categories}>
              {!hideCategories &&
                !isLoading &&
                Number(subCategories?.length) > 0 &&
                subCategories?.map((item, index) => {
                  const label = item?.label?.find(
                    _item => _item.languageId === 1,
                  )?.content;

                  const active = item?.uuid === categorySelected?.uuid;

                  return (
                    <div
                      css={styles.categoryItem}
                      key={index}
                      onClick={() => handleSelectCategory(item)}>
                      <p
                        className="textMedium"
                        css={styles.categoryItemLabel(active)}>
                        {label}
                      </p>
                    </div>
                  );
                })}
            </div>
            <SearchFilter marginRight={0} width={'100%'} />
          </div>

          <div css={styles.content} id="scrollableDiv">
            <InfiniteScrollCustom
              items={
                (categorySelected && !categorySelected?.uuid
                  ? dataProductsInfinite
                  : products) || []
              }
              refresh={refetch}
              fetchData={fetchNextPage}
              scrollableTarget="scrollableDiv"
              isLoading={!!hasNextPage}>
              <ProductList
                data={
                  (categorySelected && !categorySelected?.uuid
                    ? dataProductsInfinite
                    : products) || []
                }
                handleOnClick={onClickProduct}
                showProductType
                showAdd
                loading={
                  categorySelected && !categorySelected?.uuid
                    ? productsIsLoadingInfinite
                    : productsIsLoading
                }
                handleIsDisabled={handleIsDisabled}
                productsChecked={productsChecked}
              />
            </InfiniteScrollCustom>

            {(categorySelected && !categorySelected?.uuid
              ? productsIsLoadingInfinite
              : productsIsLoading) && <AbsoluteLoader />}
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  container: css({
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  }),
  header: css({
    position: 'relative',
  }),
  closeIcon: css({
    position: 'absolute',
    right: '0',
    bottom: '0.5rem',
    cursor: 'pointer',
  }),
  content: css({
    position: 'relative',
    height: '100%',
    overflow: 'auto',
  }),

  categories: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.7rem',
    margin: '0.5rem 0',
    paddingBottom: '0.5rem',
    overflowX: 'auto',
  }),
  categoryItem: css({
    padding: '0.2rem',
    borderRadius: 2,
    backgroundColor: 'white',
    cursor: 'pointer',
    textAlign: 'center',
    height: '37px',
  }),
  categoryItemLabel: (isActive: boolean) =>
    css({
      color: isActive ? 'white' : THEME.colors.gray2,
      backgroundColor: isActive ? THEME.colors.primary : 'transparent',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 2,
      textWrap: 'nowrap',
      minWidth: '98px',
      padding: '0 0.5rem',
    }),
};

/**  @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {IStatItemDetail} from '../../api/fetchStats';
import {useAppSelector} from '../../hooks/useReduxHook';
import {ProductCard} from '../atoms/ProductCard';
import {TextVariant} from '../atoms/TextVariant';
import {helperFormatPrice} from '../../helpers/helperFormatPrice';
import {THEME} from '../../styles/theme';
import {ProductTypePrice} from './ProductTypePrice';
import {PillLabel} from './PillLabel';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {PRODUCT_TYPES} from '@bill-app-types/b-types';
import {M_SCREEN_TABLET} from '../../constants/ResponsiveSize';
import {useFlagBCatalog} from '../../hooks/useFlagBCatalog';
import {helperTruncImgUrl} from '../../helpers/helperTruncImgUrl';

interface IProps {
  item?: IStatItemDetail;
  showPriceByMenu?: boolean;
}

export const ProductDetailInfo = ({item, showPriceByMenu = false}: IProps) => {
  const currentMode = useAppSelector(s => s.filter.currentMode);
  const {t} = useTranslation();
  const {flagBCatalogEnabled} = useFlagBCatalog();

  const {pathname} = useLocation();
  const isProductDetailPage = pathname.includes('products');
  const isMenuDetailPage = pathname.includes('menus');

  return (
    <div css={styles.container}>
      <div css={styles.productCard}>
        <ProductCard
          item={{
            label: item?.itemName,
            image:
              flagBCatalogEnabled && item?.imgUrl
                ? helperTruncImgUrl(item?.imgUrl)
                : item?.img_url_set?.M,
            type:
              item?.type ||
              (isProductDetailPage
                ? PRODUCT_TYPES.COURSE
                : isMenuDetailPage
                ? PRODUCT_TYPES.MENU
                : PRODUCT_TYPES.OPTION),
          }}
          handleOnClick={() => {}}
          showProductType
          showActions={false}
          showStartPrice={false}
          showOverlay={false}
          showPointerOnHover={false}
          priceColor={THEME.colors.blueLight}
        />
      </div>

      <div css={styles.separator} />

      <div css={styles.caTtc}>
        <div className="flex" css={styles.pills}>
          <PillLabel
            text={`CA ${currentMode}`}
            backgroundColor={THEME.colors.primary}
          />
          <PillLabel
            text={t('NUMBER_OF_ORDERS')}
            backgroundColor={THEME.colors.yellowDark}
          />
        </div>

        <TextVariant
          text={
            helperFormatPrice(item?.sales?.[currentMode].total_price || 0) +
            ' €'
          }
          variant="bigTitle"
          style={styles.caTtcValue}
        />

        <TextVariant
          text={item?.orders || 0}
          variant="bigTitle"
          style={[styles.caTtcValue, {color: THEME.colors.yellowDark}]}
        />
        {showPriceByMenu && (
          <>
            <ProductTypePrice
              type={1}
              bgColor={THEME.colors.purple}
              price="0000.00"
            />
            <ProductTypePrice type={2} price="0000.00" />
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    minHeight: '13.337rem',
    width: '26rem',
    borderRadius: '0.13rem',
    [M_SCREEN_TABLET]: {
      width: '100%',
    },
  }),
  productCard: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(100px,1fr))',
    width: '30%',
  }),
  separator: css({
    width: '2px',
    height: '40%',
    backgroundColor: THEME.colors.lightGray,
    borderRadius: '0.13rem',
    margin: '0 1rem',
  }),
  pills: css({width: '100%', gap: '0.8rem', marginBottom: '1.6rem'}),
  caTtc: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  caTtcValue: css({
    fontSize: '2rem',
    fontWeight: '800',
    color: THEME.colors.primary,
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import React from 'react';
import {InputLabel} from '../atoms/InputLabel';
import {MiniButton} from '../atoms/MiniButton';
import {THEME} from '../../styles/theme';

export const DateItem = ({
  date,
  isLastIndex,
  handleChangeDate,
  handleBlurDate,
  onClickButton,
  showAddMoreButton = true,
  disabled = false,
}: {
  date: {start: string; end: string};
  isLastIndex: boolean;
  handleChangeDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlurDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickButton: () => void;
  showAddMoreButton?: boolean;
  disabled?: boolean;
}) => {
  return (
    <div className="flex-col-center">
      <div className="flex textMedium" css={styles.label}>
        de
        <InputLabel
          name="start"
          placeholder="de"
          type="time"
          marginBottom={0}
          value={date.start}
          onChange={handleChangeDate}
          onBlur={handleBlurDate}
          disabled={disabled}
          padding={'0px 5px'}
        />
      </div>
      <div className="flex textMedium" css={styles.label}>
        à
        <InputLabel
          name="end"
          placeholder="à"
          type="time"
          marginBottom={0}
          value={date.end}
          onChange={handleChangeDate}
          onBlur={handleBlurDate}
          disabled={disabled}
          padding={'0px 5px'}
        />
      </div>
      {showAddMoreButton && (
        <MiniButton
          isRemove={!isLastIndex}
          onClick={onClickButton}
          marginBottom={'0.5rem'}
          marginTop={'0.3rem'}
          disabled={disabled}
        />
      )}
    </div>
  );
};

const styles = {
  label: css({
    backgroundColor: THEME.colors.white,
    width: '100%',
    marginBottom: 3,
    borderRadius: 2,
    alignItems: 'center',
    paddingLeft: 10,
    color: THEME.colors.bgDark,
  }),
};

import {useQuery} from '@tanstack/react-query';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {useTranslations} from '../../../../hooks/useTranslations';
import {TranslationItem} from './TranslationItem';
import {fetchAreasTranslations} from '../../../../api/fetchTranslations';
import {useTranslation} from 'react-i18next';

export const AreaServices = () => {
  const {t} = useTranslation();
  const {isOpened, handleToggleIsOpened} = useTranslations();

  const languageSelected = useAppSelector(
    s => s.myTranslationsSection.translations.languageSelected,
  );

  const {data, isLoading} = useQuery(
    ['fetchAreasTranslations'],
    () => fetchAreasTranslations(),
    {
      enabled: isOpened && !!languageSelected,
    },
  );

  return (
    <TranslationItem
      title={t('AREA_SERVICES')}
      items={data}
      isLoading={isLoading}
      isOpened={isOpened}
      onToggleIsOpened={handleToggleIsOpened}
      category={'Areas'}
      showHeader={false}
      fields={['label', 'location_label']}
    />
  );
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {useContext} from 'react';
import {ColorPickerLabel} from '../../../../components/atoms/ColorPickerLabel';
import {FieldCustomInput} from '../../../../components/atoms/FieldCustomInput';
import {HeaderForm} from '../../../../components/atoms/HeaderForm';
import {UtilsContext} from '../../../../contexts/UtilsContext';

export const DriveSettings = ({
  formikValues,
  formikHandleChange,
}: {
  formikValues: any;
  formikHandleChange: (e: any) => void;
}) => {
  const {t} = useContext<any>(UtilsContext);

  return (
    <>
      <HeaderForm title={t('SETTINGS')} />
      <div className="flex" css={styles.form}>
        <div css={styles.formCol}>
          <FieldCustomInput label={t('REFRESH_TIME')} name="refreshTime" />
        </div>

        <div css={styles.formCol}>
          <ColorPickerLabel
            label={t('MARGIN_OF_INDICATOR')}
            inputStyle={styles.inputColor}
            name="marginOfIndicator"
            value={formikValues.marginOfIndicator}
            onChange={formikHandleChange}
          />
          <ColorPickerLabel
            inputStyle={styles.inputColor}
            name="marginOfIndicator2"
            value={formikValues.marginOfIndicator2}
            onChange={formikHandleChange}
          />
        </div>

        <div css={styles.formCol}>
          <FieldCustomInput
            label={t('LUNCHTIME_RUSH')}
            type="date"
            name="lunchtimeRushStart"
          />
          <FieldCustomInput type="date" name="lunchtimeRushEnd" />
        </div>

        <div css={styles.formCol}>
          <FieldCustomInput
            label={t('EVERING_RUSH')}
            type="date"
            name="everingRushStart"
          />
          <FieldCustomInput type="date" name="everingRushEnd" />
        </div>
      </div>
    </>
  );
};

const styles = {
  form: css({
    gap: '1rem',
    alignItems: 'flex-start',
  }),
  formCol: css({
    width: '25%',
  }),
  inputColor: css({
    height: 35,
    width: 35,
    border: 'none',
  }),
};

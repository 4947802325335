import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  vendorStructureSelected: any | null;
  subVendorStructureSelected: any | null;
  vendorStructureProducts: any[];
  updateMode: boolean;
  updatedVendorStructure: any;
}

const initialState: ISlice = {
  vendorStructureSelected: null,
  subVendorStructureSelected: null,
  vendorStructureProducts: [],
  updateMode: false,
  updatedVendorStructure: null,
};

const slice = createSlice({
  name: 'vendorStructures',
  initialState,
  reducers: {
    vendorStructureSelectedAction(state, action) {
      state.vendorStructureSelected =
        state.vendorStructureSelected?.uuid === action.payload.uuid
          ? null
          : action.payload;
    },
    subVendorStructureSelectedAction(state, action) {
      state.subVendorStructureSelected =
        state.subVendorStructureSelected?.uuid === action.payload.uuid
          ? null
          : action.payload;

      if (!state.subVendorStructureSelected) {
        state.vendorStructureProducts = [];
      }
    },
    vendorStructureProductsAction(state, action) {
      state.vendorStructureProducts = action.payload;
    },
    updateModeAction(state, action) {
      state.updateMode = action.payload;
    },
    updatedVendorStructureAction(state, action) {
      state.updatedVendorStructure = action.payload;
    },
  },
});

export const {
  vendorStructureSelectedAction,
  subVendorStructureSelectedAction,
  vendorStructureProductsAction,
  updateModeAction,
  updatedVendorStructureAction,
} = slice.actions;

export default slice.reducer;

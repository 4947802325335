/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../../components/layouts/Header';
import {columnsCardList} from './columns';
import {CardHeaderBottom} from './HeaderSections/CardHeaderBottom';
import {CardHeaderCenter} from './HeaderSections/CardHeaderCenter';
import {useCards} from '../../../../../hooks/useCards';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {useEffect, useState} from 'react';

export const CardList = withRedirectAndNavigation(() => {
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [limit] = useState(20);

  const offset = (page - 1) * limit;

  const {data, isLoading} = useCards({
    offset,
    limit,
  });

  useEffect(() => {
    setRowCount(oldRowCount =>
      data?.totalCards !== undefined ? data?.totalCards : oldRowCount,
    );
  }, [data?.totalCards]);

  return (
    <div css={styles.container}>
      <Header
        bottomSection={<CardHeaderBottom />}
        centerSection={<CardHeaderCenter />}
      />
      <div css={styles.content}>
        <NewDataGrid
          rows={data?.cards || []}
          columns={columnsCardList}
          rowHeight={85}
          loading={isLoading}
          hasPagination
          page={page}
          setPage={setPage}
          totalRows={rowCount}
          rowPerPage={limit}
        />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: {
    padding: '2vh',
    height: '87%',
  },
};

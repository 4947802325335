/** @jsxImportSource @emotion/react */
import {ButtonCustom} from '../atoms/ButtonCustom';
import {registerLocale} from 'react-datepicker';
import {css} from '@emotion/react';
import Fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import {useAppDispatch} from '../../hooks/useReduxHook';
import store from '../../redux/storeConfig';
import {filterComparesDateAction} from '../../redux/filter';
import {useTranslation} from 'react-i18next';
import {THEME} from '../../styles/theme';
import {DateAndHour} from '../atoms/DateAndHour';
import {helperFilterDate} from '../../helpers/helperFilterDate';

registerLocale('fr', Fr);

// TODO : feat/BFRONT-473-filtre-de-type-date https://bill-app.atlassian.net/browse/BFRONT-473?atlOrigin=eyJpIjoiYTVlNTk0ZTIzZDRjNDI0Y2FmZjg1NTMwYjlkYzA2YjAiLCJwIjoiaiJ9
//INFO : state => typeFilterDate : null equal between period after
//INFO : state => sortFilterDate : null asc desc
export const CompareDatesFilter = ({
  handleClose,
}: {
  handleClose: () => void;
  showSortFilter?: boolean;
  disabledFilterTypes?: string[];
}) => {
  //
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const handleSubmit = () => {
    //TODO : dispatch
    const fromDate = store.getState().date.fromDate;
    const fromTime = store.getState().date.fromTime;

    const filterFromDate = store.getState().filter.dates.from;
    const filterToDate = store.getState().filter.dates.to;

    const toDate = store.getState().date.toDate;
    const toTime = store.getState().date.toTime;

    const sortFilterDate = store.getState().date.sortFilterDate;
    const periodType = store.getState().date.periodType;
    const typeFilterDate = store.getState().date.typeFilterDate;

    const {from, to} = helperFilterDate({
      fromDate,
      fromTime,
      toDate,
      toTime,
      periodType,
      typeFilterDate,
      sortFilterDate,
      filterFromDate,
      filterToDate,
      forCompare: true,
    });

    dispatch(
      filterComparesDateAction({
        from,
        to,
      }),
    );

    handleClose();
  };

  return (
    <div css={styles.container}>
      <DateAndHour dateType={'from'} />

      <ButtonCustom
        label={t('APPLY')}
        width={'100%'}
        marginTop={'0.5rem'}
        onClick={handleSubmit}
      />
    </div>
  );
};

const styles = {
  container: css({
    minWidth: 240,
    maxWidth: 284,
    backgroundColor: THEME.colors.white,
    left: 0,
    top: 0,
    padding: 12,
    borderRadius: 2,
  }),
};

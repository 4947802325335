/** @jsxImportSource @emotion/react */ import {useEffect} from 'react';
import {useAppSelector} from '../../../hooks/useReduxHook';
import {useInfiniteQuery} from '@tanstack/react-query';
import {Header} from './Header';
import {InfiniteScrollCustom} from '../../atoms/InfiniteScrollCustom';
import {ProductList} from '../../molecules/ProductList';
import {css} from '@emotion/react';
import {THEME} from '../../../styles/theme';
import {useDebounce} from '../../../hooks/useDebounce';
import {fetchRecipes} from '../../../api/fetchRecipes';
import {IRecipeFetchReturn} from '@bill-app-types/b-types';

interface IRecipesCatalog {
  onClickRecipe?: (product: any) => void;
}

export const RecipesCatalog = ({onClickRecipe}: IRecipesCatalog) => {
  const search = useAppSelector(s => s.filter.search);

  const productRecipes = useAppSelector(
    s => s.myCatalogSection.products.productRecipes,
  );

  const debouncedSearchTerm = useDebounce(search, 500);

  const {data, refetch, fetchNextPage, hasNextPage, isLoading} =
    useInfiniteQuery(
      ['fetchRecipes', debouncedSearchTerm],
      ({pageParam = 0}) =>
        fetchRecipes({
          limit: 50,
          offset: pageParam,
          search: debouncedSearchTerm,
        }),
      {
        getNextPageParam: (lastPage, pages) => {
          const currentTotalRecipes = pages?.reduce(
            (acc, page) => acc + (page?.recipes?.length || 1),
            0,
          );

          if (Number(lastPage?.totalRecipes) === currentTotalRecipes) {
            return undefined;
          }

          return currentTotalRecipes + 1;
        },
      },
    );

  useEffect(() => {
    refetch();
  }, [debouncedSearchTerm, refetch]);

  const recipes = data?.pages.flatMap((data: any) => data.recipes) || [];

  const handleIsDisabled = (item: IRecipeFetchReturn) => {
    return (
      productRecipes.find(
        (recipe: any) => recipe?.recipe?.uuid === item.uuid,
      ) || false
    );
  };

  return (
    <div css={styles.container}>
      <Header />
      <div css={styles.content} id="scrollableDiv">
        <InfiniteScrollCustom
          items={recipes || []}
          refresh={refetch}
          fetchData={fetchNextPage}
          scrollableTarget="scrollableDiv"
          isLoading={!!hasNextPage}>
          <ProductList
            data={recipes}
            handleOnClick={onClickRecipe}
            showAdd
            showPrice={false}
            loading={isLoading}
            specificImage={'/images/recipe.svg'}
            handleIsDisabled={handleIsDisabled}
          />
        </InfiniteScrollCustom>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  }),
  content: css({
    paddingTop: 20,
    overflow: 'auto',
  }),
  closedContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }),
  closedBtn: css({
    backgroundColor: THEME.colors.primary,
    border: `1px solid ${THEME.colors.primary}`,
    width: 25,
    height: 25,
    borderRadius: 2,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  closedContent: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 2,
    width: 25,
    height: '100%',
  }),
};

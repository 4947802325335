import {combineReducers} from '@reduxjs/toolkit';
import options from './options';
import categories from './categories';
import cards from './cards';
import products from './products';
import recipes from './recipes';
import menus from './menus';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import vendorStructures from '../myAccountingSection/vendorStructures';

const persistProductsConfig = {
  key: 'catalogProducts',
  storage,
};

const persistMenusConfig = {
  key: 'catalogMenus',
  storage,
};

export const myCatalogSection = combineReducers({
  options,
  categories,
  products: persistReducer(persistProductsConfig, products),
  cards,
  recipes,
  menus: persistReducer(persistMenusConfig, menus),
  vendorStructures
});

import {SVGProps} from 'react';

export const FileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="23"
    viewBox="0 0 21 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2139 14.9222H8.24805C8.02854 14.9222 7.81803 15.0167 7.66282 15.1848C7.50761 15.353 7.42041 15.5811 7.42041 15.8189C7.42041 16.0568 7.50761 16.2849 7.66282 16.4531C7.81803 16.6212 8.02854 16.7157 8.24805 16.7157H13.2139C13.4334 16.7157 13.6439 16.6212 13.7991 16.4531C13.9543 16.2849 14.0415 16.0568 14.0415 15.8189C14.0415 15.5811 13.9543 15.353 13.7991 15.1848C13.6439 15.0167 13.4334 14.9222 13.2139 14.9222Z"
      fill={props.color || "#F7C761"}
    />
    <path
      d="M8.24805 13.1291H10.731C10.9505 13.1291 11.161 13.0346 11.3162 12.8664C11.4714 12.6982 11.5586 12.4701 11.5586 12.2323C11.5586 11.9944 11.4714 11.7663 11.3162 11.5982C11.161 11.43 10.9505 11.3355 10.731 11.3355H8.24805C8.02854 11.3355 7.81803 11.43 7.66282 11.5982C7.50761 11.7663 7.42041 11.9944 7.42041 12.2323C7.42041 12.4701 7.50761 12.6982 7.66282 12.8664C7.81803 13.0346 8.02854 13.1291 8.24805 13.1291Z"
      fill={props.color || "#F7C761"}
    />
    <path
      d="M17.1364 8.04421L12.634 2.66359C12.5567 2.57069 12.4622 2.49637 12.3567 2.4454C12.2512 2.39443 12.1371 2.36795 12.0216 2.36765H6.22813C5.95314 2.3641 5.68021 2.41927 5.42491 2.53002C5.1696 2.64077 4.93693 2.80492 4.74019 3.01311C4.54344 3.22129 4.38646 3.46943 4.27823 3.74336C4.16999 4.01728 4.11262 4.31163 4.10938 4.60958V18.0611C4.11262 18.3591 4.16999 18.6534 4.27823 18.9273C4.38646 19.2013 4.54344 19.4494 4.74019 19.6576C4.93693 19.8658 5.1696 20.0299 5.42491 20.1407C5.68021 20.2514 5.95314 20.3066 6.22813 20.3031H15.2328C15.5078 20.3066 15.7807 20.2514 16.0361 20.1407C16.2914 20.0299 16.524 19.8658 16.7208 19.6576C16.9175 19.4494 17.0745 19.2013 17.1827 18.9273C17.291 18.6534 17.3483 18.3591 17.3516 18.0611V8.64504C17.351 8.42279 17.2744 8.20867 17.1364 8.04421ZM12.3858 5.05796L14.6535 7.74827H12.9982C12.9115 7.7426 12.8268 7.71829 12.7489 7.67674C12.671 7.63519 12.6015 7.57725 12.5445 7.50629C12.4875 7.43534 12.4441 7.35278 12.4168 7.26346C12.3896 7.17413 12.379 7.07982 12.3858 6.98602V5.05796ZM15.2328 18.5095H6.22813C6.1705 18.5131 6.11278 18.5044 6.05828 18.4838C6.00377 18.4633 5.95355 18.4312 5.91049 18.3896C5.86743 18.3479 5.83239 18.2975 5.80736 18.2411C5.78233 18.1847 5.76782 18.1236 5.76465 18.0611V4.60958C5.76782 4.54713 5.78233 4.48597 5.80736 4.42961C5.83239 4.37325 5.86743 4.3228 5.91049 4.28114C5.95355 4.23949 6.00377 4.20745 6.05828 4.18687C6.11278 4.16628 6.1705 4.15756 6.22813 4.16119H10.7305V6.98602C10.7171 7.6466 10.9455 8.28609 11.3657 8.76492C11.7859 9.24374 12.3638 9.52303 12.9734 9.54181H15.6963V18.0611C15.6931 18.1236 15.6786 18.1847 15.6536 18.2411C15.6286 18.2975 15.5935 18.3479 15.5505 18.3896C15.5074 18.4312 15.4572 18.4633 15.4027 18.4838C15.3482 18.5044 15.2905 18.5131 15.2328 18.5095Z"
      fill={props.color || "#F7C761"}
    />
  </svg>
);

import {
  ICreateModifierHasItemPayload,
  ICreateOptionItemPayload,
  IFetchItemsReturn,
  IGetModifierDetailsResponse,
  IGetModifiersResponse,
  IModifierCreationPayload,
  IUpdateModifierHasItemPayload,
} from '@bill-app-types/b-types';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {helperJustLog, helperLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';

interface IOptionData {
  search?: string;
  optionType?: number | null;
  offset?: number;
  limit?: number;
  orderBy?: object[];
  isArchived?: boolean | null;
}

export const getCatalogOptionCategories = async (data: IOptionData) => {
  //
  helperJustLog('### getCatalogOptionCategories ###');
  const query = helperGenerateQuery(data);

  const res = await axiosInstance
    .get<{
      data: IGetModifiersResponse;
    }>(`/bcatalog/backoffice/modifiers/options${query}`, {
      params: {orderBy: data.orderBy, isArchived: data.isArchived},
    })
    .catch(err => {
      console.log('❌ getCatalogOptionCategories : ', err);
    });
  return res?.data?.data;
};

export const createCatalogOptionCategory = async (
  body: IModifierCreationPayload,
) => {
  //
  helperJustLog('### createCatalogOptionCategory ###');
  const res = await axiosInstance
    .post(`/bcatalog/backoffice/modifiers/options`, body)
    .catch(err => {
      console.log('❌ createCatalogOptionCategory : ', err);
    });
  return res;
};

export const duplicateCatalogOptionCategory = async (modifierUuid: string) => {
  //
  helperJustLog('### duplicateCatalogOptionCategory ###');
  const res = await axiosInstance
    .put(`/bcatalog/backoffice/modifiers/options/duplicate/${modifierUuid}`)
    .catch(err => {
      console.log('❌ duplicateCatalogOptionCategory : ', err);
    });
  return res;
};

export const getCatalogOptions = async (data: {
  limit: number;
  offset: number;
  search?: string;
  orderBy?: object[];
  isArchived?: boolean | null;
  isOutOfStock?: boolean | null;
}) => {
  //
  const query = helperGenerateQuery(data);

  helperJustLog('### getCatalogOptions ###');
  const res = await axiosInstance
    .get<{
      data: IFetchItemsReturn;
    }>(`/bcatalog/backoffice/option-items${query}`, {
      params: {orderBy: data.orderBy, isArchived: data.isArchived, isOutOfStock: data.isOutOfStock},
    })
    .catch(err => {
      console.log('❌ getCatalogOptions : ', err);
    });
  return res?.data?.data;
};

export const createCatalogOption = async (body: ICreateOptionItemPayload) => {
  //
  helperJustLog('### createCatalogOption ###');
  const res = await axiosInstance
    .post(`/bcatalog/backoffice/option-items`, body)
    .catch(err => {
      console.log('❌ createCatalogOption : ', err);
    });
  return res;
};

export const updateCatalogOption = async (
  optionItemUuid: string,
  body: Partial<ICreateOptionItemPayload> & {isOutOfStock?: boolean},
) => {
  //
  helperJustLog('### updateCatalogOption ###');
  const res = await axiosInstance
    .put(`/bcatalog/backoffice/option-items/${optionItemUuid}`, body)
    .catch(err => {
      console.log('❌ updateCatalogOption : ', err);
    });
  return res;
};

export const duplicateCatalogOption = async (optionItemUuid: string) => {
  //
  helperJustLog('### duplicateCatalogOption ###');
  const res = await axiosInstance
    .put(`/bcatalog/backoffice/option-items/${optionItemUuid}/duplicate`)
    .catch(err => {
      console.log('❌ duplicateCatalogOption : ', err);
    });
  return res;
};

export const getCatalogModifierDetails = async (modifierUuid: string) => {
  const res = await axiosInstance
    .get<{
      data: IGetModifierDetailsResponse;
    }>(`/bcatalog/backoffice/modifiers/options/${modifierUuid}`)
    .catch(err => {
      helperLog({
        message: 'getCatalogOptionDetails',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res?.data?.data;
};

export const createItemOptionItem = async (
  optionUuid: string,
  data: ICreateModifierHasItemPayload,
) => {
  //
  helperJustLog('### createItemOptionItem ###');

  const res = await axiosInstance
    .post(
      `/bcatalog/backoffice/modifiers/options/${optionUuid}/option-items`,
      data,
    )
    .catch(err => {
      console.log('❌ createItemOptionItem : ', err);
    });
  return res;
};

export const updateItemOptionItem = async (
  optionUuid: string,
  itemUuid: string,
  data: IUpdateModifierHasItemPayload,
) => {
  //
  helperJustLog('### updateItemOptionItem ###');

  const res = await axiosInstance
    .put(
      `/bcatalog/backoffice/modifiers/options/${optionUuid}/option-items/${itemUuid}`,
      data,
    )
    .catch(err => {
      console.log('❌ updateItemOptionItem : ', err);
    });
  return res;
};

export const deleteCatalogOptionCategory = async (modifierUuid: string) => {
  const res = await axiosInstance.delete(
    `/bcatalog/backoffice/modifiers/options/${modifierUuid}`,
  );

  return res;
};

export const deleteItemOptionItem = async (
  optionUuid: string,
  itemUuid: string,
) => {
  //
  helperJustLog('### deleteItemOptionItem ###');

  const res = await axiosInstance
    .delete(
      `/bcatalog/backoffice/modifiers/options/${optionUuid}/option-items/${itemUuid}`,
    )
    .catch(err => {
      console.log('❌ deleteItemOptionItem : ', err);
    });
  return res;
};

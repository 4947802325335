import {Users} from './Users';
import {UsersGroups} from './UsersGroups';

export const MyStaffRouter = {
  path: 'staff',
  children: [
    {
      path: 'users',
      element: <Users />,
    },
    {
      path: 'users-groups',
      element: <UsersGroups />,
    },
  ],
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../../styles/theme';
import {TextVariant} from '../../atoms/TextVariant';
import {Pill} from '../../atoms/Pill';
import {useTranslation} from 'react-i18next';
import {helperFormatPrice} from '../../../helpers/helperFormatPrice';
import {helperFormatNumber} from '../../../helpers/helperFormatNumber';

export const CustomTurnOverToolTip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
  label?: string;
}) => {
  //
  //FIXME : fix this {{object}} error
  const {t} = useTranslation();
  const item = payload[0]?.payload ? payload[0].payload : payload[0];
  const value =
    item?.sales?.TTC?.total_price ?? item?.value ?? item?.percent_sales ?? 0;
  const orders = item?.valueOrder ?? item?.orders?.value ?? item?.orders ?? 0;
  const sales =
    item?.sales?.TTC?.total_percent ??
    item?.percent_sales?.value ??
    item?.percent_sales ??
    0;

  if (active && payload && payload.length) {
    return (
      <div css={styles.container}>
        <TextVariant
          text={t(item.name || item.itemName)}
          variant="textSemiBold"
          style={{
            textTransform: 'uppercase',
            textAlign: 'center',
            margin: '0.2rem 0',
          }}
        />
        <div className="flex flex-align-center" css={styles.content}>
          <Pill backgroundColor={item.color} />
          <div>
            <TextVariant
              text={`${helperFormatPrice(value)} €`}
              variant="textSemiBold"
            />
            <TextVariant
              text={`${helperFormatNumber(orders)}`}
              variant="textSemiBold"
            />
            <TextVariant text={`${sales} %`} variant="textSemiBold" />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.bgDark,
    color: 'white',
    padding: '0.5rem',
    borderRadius: '5px',
    minWidth: '120px',
    zIndex: 999,
  }),
  content: css({
    marginTop: '0.2rem',
  }),
};

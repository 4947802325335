import {useQuery} from '@tanstack/react-query';
import {fetchPrinters} from '../api/fetchPrinters';

export const usePrinters = () => {
  const {data, isLoading, isError, error} = useQuery(['fetchPrinters'], () =>
    fetchPrinters(),
  );

  return {
    printers: data,
    isLoading,
    isError,
    error,
  };
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {helperFormatPrice} from '../../helpers/helperFormatPrice';
import {helperTaxType} from '../../helpers/helperTax';
import {ITEM_TYPES} from '../../constants/global';
import {TextVariant} from '../atoms/TextVariant';

interface IProps {
  quantity?: string;
  label: string;
  price?: string;
  pu?: string;
  tva?: number;
  paddingLeft?: string;
  color?: string;
}

export const OrderItem = ({
  item,
  isReceipt = false,
}: {
  item: any;
  isReceipt?: boolean;
}) => {
  const Row = ({
    quantity = '',
    label = '',
    price = '',
    pu = '',
    tva = 1,
    paddingLeft = '0%',
    color = 'var(--text-color)',
  }: IProps) => (
    <div css={styles.product}>
      <div css={[styles.col, {width: '10%'}]}>
        <TextVariant
          text={quantity}
          variant="textBold"
          style={{
            color,
          }}
        />
      </div>
      <div css={[styles.col, {width: '45%'}]}>
        <TextVariant
          text={label}
          variant="textBold"
          style={{
            paddingLeft,
            color,
          }}
        />
      </div>
      <div css={styles.col}>
        <TextVariant
          text={`${pu}€`}
          variant="textBold"
          style={{
            color,
          }}
        />
      </div>
      {isReceipt && (
        <div css={[styles.col, {width: '10%'}]}>
          <TextVariant
            text={tva}
            variant="textBold"
            style={{
              color,
            }}
          />
        </div>
      )}
      <div css={[styles.col, {width: '20%', textAlign: 'left'}]}>
        <TextVariant
          text={`${price}€`}
          variant="textBold"
          style={{
            color,
          }}
        />
      </div>
    </div>
  );

  const Product = ({options, isMenu}: {options: any; isMenu?: boolean}) =>
    //

    options?.map((option: any, index: number) => {
      const optionsItems =
        option.billItems || option.orderItems || option.option_items;

      return (
        <div key={index}>
          {/* <Row label={option.label} quantity="" price="" /> */}
          {optionsItems?.map((optionItem: any, idx: number) => {
            const pu = isReceipt
              ? helperFormatPrice(optionItem.unitPrice || 0)
              : helperFormatPrice(
                  optionItem.baseUnitPrice || optionItem.base_unit_price || 0,
                );
            const totalPrice = isReceipt
              ? helperFormatPrice(optionItem.totalPrice || 0)
              : helperFormatPrice(
                  optionItem.baseTotalPrice || optionItem.base_total_price || 0,
                );
            return (
              <Row
                label={optionItem.label}
                quantity={optionItem.quantity}
                tva={helperTaxType(optionItem.tax)}
                pu={pu}
                price={totalPrice}
                paddingLeft="5%"
                color="#A7A7AB"
              />
            );
          })}
        </div>
      );
    });

  const Menu = ({orderItems}: {orderItems: any}) =>
    orderItems.map((orderItem: any, index: number) => {
      const pu = isReceipt
        ? helperFormatPrice(orderItem.unitPrice || 0)
        : helperFormatPrice(
            orderItem.baseUnitPrice || orderItem.base_unit_price || 0,
          );
      const totalPrice = isReceipt
        ? helperFormatPrice(orderItem.totalPrice)
        : helperFormatPrice(
            orderItem.baseTotalPrice || orderItem.base_total_price || 0,
          );

      return (
        <div key={index}>
          <Row
            label={orderItem.label}
            quantity={orderItem.quantity}
            pu={pu}
            tva={helperTaxType(item.tax)}
            price={totalPrice}
          />
          <Product
            options={
              orderItem.billItems || orderItem.orderItems || orderItem.options
            }
            isMenu
          />
        </div>
      );
    });

  const isMenu =
    item.type === ITEM_TYPES.MENU || item.productType === ITEM_TYPES.MENU;

  const pu = isMenu
    ? helperFormatPrice(
        isReceipt ? item.unitPrice : item.intermediateUnitPrice || 0,
      )
    : helperFormatPrice(isReceipt ? item.unitPrice : item.baseUnitPrice || 0);
  const totalPrice = isMenu
    ? helperFormatPrice(
        isReceipt ? item.totalPrice : item.intermediateTotalPrice || 0,
      )
    : helperFormatPrice(isReceipt ? item.totalPrice : item.baseTotalPrice || 0);

  return (
    <div css={styles.orderItem}>
      <Row
        label={item.label}
        quantity={item.quantity}
        pu={pu}
        tva={helperTaxType(item.tax)}
        price={totalPrice}
      />
      {(item.type === ITEM_TYPES.MENU ||
        item.productType === ITEM_TYPES.MENU) && (
        <Menu
          orderItems={item.billItems || item.orderItems || item.product_list}
        />
      )}
      {(item.type === ITEM_TYPES.PRODUCT ||
        item.productType === ITEM_TYPES.PRODUCT) && (
        <Product options={item.billItems || item.orderItems || item.options} />
      )}
    </div>
  );
};

const styles = {
  orderItem: css({
    backgroundColor: 'var(--bg-color)',
    height: '100%',
    width: '40vh',
    borderBottom: '1px solid var(--bg-main-color)',
    paddingBottom: '8px',
  }),
  product: css({
    display: 'flex',
    padding: '8px 5px',
    justifyContent: 'space-between',
  }),
  col: css({
    width: '15%',
    padding: '0px 15px',
  }),
};

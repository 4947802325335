import {SVGProps} from 'react';

export const StarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.99725 0.333374C7.22086 0.333374 7.42503 0.453003 7.52225 0.641447L9.18961 3.86072L12.9157 4.37797C13.1344 4.40759 13.3143 4.55115 13.3848 4.74711C13.4528 4.94535 13.3969 5.16182 13.2413 5.30538L10.5386 7.81645L11.1778 11.3597C11.2143 11.5648 11.1243 11.7745 10.942 11.8952C10.7622 12.016 10.5021 12.0319 10.3271 11.934L6.99725 10.266L3.64795 11.934C3.47295 12.0319 3.23475 12.016 3.05246 11.8952C2.8726 11.7745 2.78267 11.5648 2.79725 11.3597L3.45593 7.81645L0.755829 5.30538C0.598816 5.16182 0.543156 4.94535 0.612184 4.74711C0.680968 4.55115 0.862531 4.40759 1.08104 4.37797L4.80489 3.86072L6.47225 0.641447C6.57191 0.45298 6.77364 0.333374 6.99725 0.333374ZM6.99725 2.13236L5.72121 4.599C5.63614 4.76078 5.47086 4.87472 5.28128 4.90206L2.40545 5.29854L4.49378 7.23767C4.62746 7.363 4.68823 7.54301 4.65663 7.71847L4.16566 10.4437L6.7226 9.16313C6.89517 9.07654 7.10177 9.07654 7.27191 9.16313L9.82885 10.4437L9.33788 7.71847C9.30628 7.54301 9.36947 7.363 9.50315 7.23767L11.591 5.29854L8.71566 4.90206C8.52364 4.87472 8.35836 4.76078 8.27572 4.599L6.99725 2.13236Z"
      fill={props.color || '#CFA615'}
    />
  </svg>
);

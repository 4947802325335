import {helperJustLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';

interface ITerminal {
  label: string;
}

export const getPosTerminals = async () => {
  //
  console.log('### getPosTerminals ###');
  const res: any = await axiosInstance
    .get(`/pos/backoffice/pos-terminals`)
    .catch(err => {
      console.log('❌ getPosTerminals : ', err);
    });
  return res?.data?.data;
};

export const createPosTerminal = async (brandId: number , data: ITerminal) => {
  //
  helperJustLog('### createPosTerminal ###');
  const res: any = await axiosInstance
    .post(`/pos/backoffice/${brandId}/pos-terminals`, data)
    .catch(err => {
      console.log('❌ createPosTerminal : ', err);
    });
  return res;
};

export const updatePosTerminal = async (brandId: number, posTerminalUuid: string, data: ITerminal) => {
  //
  helperJustLog('### updatePosTerminal ###');
  const res: any = await axiosInstance
    .put(`/pos/backoffice/${brandId}/pos-terminals/${posTerminalUuid}`, data)
    .catch(err => {
      console.log('❌ updatePosTerminal : ', err);
    });
  return res;
};

export const deletePosTerminal = async (brandId: number, posTerminalUuid: string) => {
  //
  helperJustLog('### deletePosTerminal ###');
  const res: any = await axiosInstance
    .delete(`/pos/backoffice/${brandId}/pos-terminals/${posTerminalUuid}`)
    .catch(err => {
      console.log('❌ deletePosTerminal : ', err);
    });
  return res;
};
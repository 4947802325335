/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';

const LeftBlock = () => {
  return (
    <div css={styles.container}>
      <div css={styles.large} />
      <div css={styles.medium} />
      <div css={styles.small} />
      <div css={styles.small} />
      <div css={styles.small} />
    </div>
  );
};

export default LeftBlock;

const styles = {
  container: css({
    width: '35%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingTop: '40px',
  }),
  small: css({
    backgroundColor: '#CCD6E0',
    opacity: 0.3,
    width: '90%',
    height: 24,
    borderRadius: 5,
    marginBottom: 5,
  }),
  medium: css({
    backgroundColor: '#CCD6E0',
    opacity: 0.3,
    width: '95%',
    height: 49,
    borderRadius: 5,
    marginBottom: 5,
  }),
  large: css({
    backgroundColor: '#CCD6E0',
    opacity: 0.3,
    width: '100%',
    height: 72,
    borderRadius: 5,
    marginBottom: 5,
  }),
};

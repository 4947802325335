import {SVGProps} from 'react';

export const PrinterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.14438 1.28844H9.59614L10.4797 2.1451V4.11537H11.6985V2.1451C11.6985 1.82379 11.5701 1.51561 11.3415 1.28844L10.4797 0.431784C10.2512 0.204545 9.94145 0.0769043 9.61899 0.0769043H3.14438C2.49413 0.0769043 1.94874 0.619068 1.94849 1.28794L1.94854 3.36472C1.94849 3.36472 1.94859 3.36472 1.94854 3.36472L1.94849 4.11537H3.16749L3.14438 1.28844ZM11.4954 4.92306H2.15161C1.1436 4.92306 0.323486 5.73832 0.323486 6.74037V9.56729C0.323486 9.90172 0.596436 10.1731 0.932861 10.1731H2.35474V12.1923C2.35474 12.6383 2.71858 13 3.16724 13H10.4797C10.9284 13 11.2922 12.6383 11.2922 12.1923V10.1731H12.7141C13.0505 10.1731 13.3235 9.90172 13.3235 9.56729V6.74037C13.3235 5.73832 12.5034 4.92306 11.4954 4.92306ZM10.0735 11.7884H3.57349V9.36537H10.0735V11.7884ZM12.1047 8.96152H11.2922C11.2922 8.51552 10.9284 8.15383 10.4797 8.15383H3.16724C2.71858 8.15383 2.35474 8.51552 2.35474 8.96152H1.54224V6.74037C1.54224 6.40644 1.81569 6.1346 2.15161 6.1346H11.4954C11.8313 6.1346 12.1047 6.40644 12.1047 6.74037V8.96152Z"
      fill="white"
    />
  </svg>
);

import {useQuery} from '@tanstack/react-query';
import {useAppSelector} from './useReduxHook';
import {useFlagBCatalog} from './useFlagBCatalog';
import {getOrdersByCustomer} from '../api/fetchOrders';

export const useCustomerOrdersV2 = (offset: number, limit: number) => {
  const selectedCustomer = useAppSelector(s => s.customer.selectedCustomer);
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {data, isLoading, error} = useQuery(
    ['getCustomerOrders', offset, limit, selectedCustomer?.uuid],
    () => getOrdersByCustomer({offset, limit}, selectedCustomer?.uuid),
    {
      enabled: flagBCatalogEnabled && !!flagBCatalogReady,
    },
  );

  return {
    data: data?.responseData || data?.orders || [],
    totalOrders: data?.totalOrder,
    isLoading,
    error,
  };
};

import {useQueryClient} from '@tanstack/react-query';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {addSubmenuProducts} from '../../../../../api/fetchSubmenus';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {ProductsCatalogWithCategories} from '../../../../../components/organisms/ProductsCatalogWithCategories';

export const BlockLeft = ({
  showProducts,
  onToggleShowProducts,
}: {
  showProducts: boolean;
  onToggleShowProducts: () => void;
}) => {
  const clientQuery = useQueryClient();
  const openedSubmenu = useAppSelector(
    s => s.myCatalogSection.menus.openedSubmenu,
  );
  const submenuProductList = useAppSelector(
    s => s.myCatalogSection.menus.submenuProductList,
  );

  const onClickProduct = async (item: any) => {
    if (!openedSubmenu) {
      helperDisplayToast('Aucun sous-menu sélectionné', false);
    } else {
      const productFinded = submenuProductList?.find(
        (p: any) => p.uuid === item.uuid,
      );

      if (!productFinded) {
        const res = await addSubmenuProducts(openedSubmenu, {
          itemUuid: item?.uuid,
          price: 0,
          position: 1,
        });
        if (res?.status === 204) {
          helperDisplayToast('Produit ajouté', true);
          clientQuery.invalidateQueries([
            `fetchSubmenuProducts-${openedSubmenu}`,
          ]);
        } else {
          helperDisplayToast(
            res?.data?.message || "Impossible d'ajouter le produit",
            false,
          );
        }
      }
    }
  };

  const handleIsDisabled = (item: any) => {
    return (
      (submenuProductList.find((product: any) => product?.uuid === item.uuid) ||
        false) &&
      !!openedSubmenu
    );
  };

  return (
    <ProductsCatalogWithCategories
      showProducts={showProducts}
      onClickProduct={onClickProduct}
      onToggleShowProducts={onToggleShowProducts}
      handleIsDisabled={handleIsDisabled}
      productsChecked={[]}
    />
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addProductCategoryModalAction} from '../../redux/modals';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from '@tanstack/react-query';
import {
  createCategory,
  deleteCategory,
  updateCategory,
} from '../../api/fetchCategories';
import {RequiredLabel} from '../atoms/RequiredLabel';
// import {Field} from 'formik';
// import {ToggleButtonLabel} from '../molecules/ToggleButtonLabel';
import {useEffect, useState} from 'react';
import {
  resetCategorySelectedAction,
  updateModeAction,
} from '../../redux/myCatalogSection/categories';
import {helperDisplayToast} from '../../helpers/helperToast';
import {helperLabelTranslation} from '../../helpers/helperLabel';
import {ICategoryFetchReturn, STRUCTURE_TYPES} from '@bill-app-types/b-types';
import {
  createExternalCategory,
  deleteExternalCategory,
  updateExternalCategory,
} from '../../api/fetchExternalCategories';
//import {categorySelectedAction} from '../../redux/posCategories';

export const AddProductCategoryModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const clientQuery = useQueryClient();

  const initialValues = {
    label: '',
    position: 1,
    showBill: false,
  };
  const [showBill, setShowBill] = useState(false);
  const [parentCategoryUuid, setParentCategoryUuid] = useState(null);

  const updateMode = useAppSelector(
    state => state.myCatalogSection.categories.updateMode,
  );
  const updatedCategory = useAppSelector(
    state => state.myCatalogSection.categories.updatedCategory,
  );
  const categorySelected = useAppSelector(
    state => state.myCatalogSection.categories.categorySelected,
  );

  const structureSelected = useAppSelector(
    state => state.myCatalogSection.categories.structureSelected,
  );

  const isExternalCategory =
    Number(structureSelected?.type) === STRUCTURE_TYPES.EXTERNAL;

  /* ON CANCEL */
  const handleOnCancel = () => {
    dispatch(addProductCategoryModalAction(false));
    dispatch(updateModeAction(false));
  };

  const handleInvalidateQueries = () => {
    clientQuery.invalidateQueries(['getStructureDetails']);
  };

  const handleCreateCategory = async (data: any, resetForm: any) => {
    let payloadData = {
      ...data,
      showBill,
      structureUuid: structureSelected?.uuid,
    };
    let res;

    if (parentCategoryUuid !== 'Aucun parent' && parentCategoryUuid) {
      payloadData.parentCategoryUuid = parentCategoryUuid;
    }

    if (isExternalCategory) {
      res = await createExternalCategory(payloadData);
    } else {
      res = await createCategory(payloadData);
    }

    if (res && [200, 204].includes(res?.status)) {
      resetForm();
      handleOnCancel();
      handleInvalidateQueries();
      helperDisplayToast(res?.data?.message || 'Catégorie créée', true);
    } else {
      helperDisplayToast(
        res?.data?.message || 'Impossible de créer la catégorie',
        false,
      );
    }
  };

  const handleUpdateCategory = async (data: any, resetForm: any) => {
    const payloadData = {
      ...data,
      showBill,
      parentCategoryUuid:
        parentCategoryUuid === 'Aucun parent' ? null : parentCategoryUuid,
    };

    let res;

    if (isExternalCategory) {
      res = await updateExternalCategory(payloadData, updatedCategory?.uuid);
    } else {
      res = await updateCategory(
        {
          ...data,
          showBill,
          parentCategoryUuid:
            parentCategoryUuid === 'Aucun parent' ? null : parentCategoryUuid,
        },
        updatedCategory?.uuid,
      );
    }

    if (res && [200, 204].includes(res?.status)) {
      resetForm();
      handleOnCancel();
      handleInvalidateQueries();
      helperDisplayToast(res?.data?.message || 'Catégorie modifiée', true);
    } else {
      helperDisplayToast(
        res?.data?.message || 'Impossible de modifier la catégorie',
        false,
      );
    }
  };

  /* HANDLE SUBMIT */
  const handleOnSubmit = async (
    data: {
      label: string;
      structureUuid: string | null;
      showBill: boolean;
      position: number;
    },
    {setSubmitting, resetForm}: any,
  ) => {
    if (data.label) {
      if (updateMode) {
        await handleUpdateCategory(data, resetForm);
      } else {
        await handleCreateCategory(data, resetForm);
      }
    } else {
      helperDisplayToast('Tous les champs doivent être complétés', false);
    }
  };

  const handleOnRemove = async () => {
    let res;

    if (isExternalCategory) {
      res = await deleteExternalCategory(updatedCategory?.uuid);
    } else {
      res = await deleteCategory(updatedCategory?.uuid);
    }

    if (res && res.status === 204) {
      helperDisplayToast(res?.data?.message || 'Catégory supprimée', true);
      handleOnCancel();
      if (
        categorySelected &&
        categorySelected?.uuid === updatedCategory?.uuid
      ) {
        dispatch(resetCategorySelectedAction());
      }
      handleInvalidateQueries();
    } else {
      helperDisplayToast(
        res?.data?.message || 'Impossible de supprimer la catégorie',
        false,
      );
    }
  };

  useEffect(() => {
    if (updatedCategory) {
      setParentCategoryUuid(updatedCategory?.parentCategoryUuid);
    }
  }, [updatedCategory]);

  const categories = structureSelected?.categories?.filter(
    item => item.uuid !== updatedCategory?.uuid,
  );

  return (
    <ModalWrapper
      initialValues={updateMode ? updatedCategory : initialValues}
      title={t('PRODUCTS_CATEGORY')}
      validateLabel={t('SAVE_CATEGORY')}
      cancelLabel={t('CANCEL')}
      onCancel={handleOnCancel}
      onRemove={updateMode ? handleOnRemove : undefined}
      onSubmit={handleOnSubmit}
      titleMarginBottom={35}
      minWidth={'25%'}>
      <FieldCustomInput
        label={t('CATEGORY_NAME')}
        placeholder={t('CATEGORY_NAME')}
        name="label"
        required
      />

      <RequiredLabel label={t('PARENT_CATEGORY')} required={true} />
      <select
        onChange={(e: any) => setParentCategoryUuid(e.target.value)}
        value={!!parentCategoryUuid ? parentCategoryUuid : ''}
        css={styles.select}>
        <option value={undefined}>{t('NO_PARENT')}</option>;
        {(categories || [])?.map((category: ICategoryFetchReturn) => {
          return <CatItem item={category} key={category.uuid} dontShowSub />;
        })}
      </select>

      {/* <ToggleButtonLabel
        label={t('SHOW_ON_APPS')}
        active={showBill}
        onToggle={() => setShowBill(!showBill)}
        marginTop={'1rem'}
      /> */}
    </ModalWrapper>
  );
};

const CatItem = ({item, dontShowSub}: {item: any; dontShowSub?: boolean}) => {
  return (
    <>
      <option value={item.uuid}>{helperLabelTranslation(item)}</option>
      {!dontShowSub && <SubCatItem item={item} />}
    </>
  );
};

const SubCatItem = ({item}: any) => {
  if (item.subCategories?.length === 0) return <></>;
  return (
    <>
      {item.subCategories?.map((subCat: any) => {
        return <CatItem item={subCat} key={subCat.uuid} />;
      })}
    </>
  );
};

const styles = {
  select: css({
    width: '100%',
    height: 39,
    border: 'none',
    borderRadius: 2,
    backgroundColor: 'white',
    padding: '0 10px',
  }),
};

import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../hooks/useReduxHook';
import {categoryModalAction} from '../../redux/modals';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {ModalWrapper} from '../atoms/ModalWrapper';

export const CategoryModal = () => {
  const initialValues = {
    name: '',
    backofficeName: '',
  };
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const onCancel = () => {
    dispatch(categoryModalAction(false));
  };

  const handleSubmit = (data: any, {setSubmitting}: any) => {
    setSubmitting(false);
  };
  return (
    <ModalWrapper
      title={t('ADDITIONAL_SALE_CATEGORY')}
      validateLabel={t('REGISTER_CATEGORY')}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      initialValues={initialValues}>
      <FieldCustomInput label={t('NAME')} required name="name" />
      <FieldCustomInput
        label={t('BACKOFFICE_DISPLAY_NAME')}
        name="backofficeName"
        required
      />
      <FieldsRequired />
    </ModalWrapper>
  );
};

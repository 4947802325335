/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {CheckIcon} from '../../assets/svgs/CheckIcon';

export const CheckboxCustom = ({
  checked = false,
  setChecked,
  marginRight = 0,
  type = 'square',
  name = 'checked',
  ...props
}: {
  checked?: boolean;
  setChecked?: any;
  marginRight?: number | string;
  type?: 'square' | 'rounded';
  name?: string;
}) => {
  return (
    <>
      {type === 'rounded' ? (
        <div css={styles.container(checked, marginRight)} onClick={setChecked}>
          <div css={styles.contain(checked)} />
        </div>
      ) : (
        <div
          css={styles.container(checked, marginRight, true)}
          onClick={setChecked}>
          {checked && <CheckIcon />}
        </div>
      )}
    </>
  );
};

const styles = {
  container: (
    checked: boolean,
    marginRight: number | string,
    isSquare?: boolean,
  ) =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 12,
      height: 12,
      borderRadius: isSquare ? 2 : 25,
      border: `2px solid ${checked ? THEME.colors.primary : '#A7A7AB'}`,

      marginRight,
      cursor: 'pointer',
      backgroundColor: checked ? THEME.colors.primary : 'white',
    }),
  contain: (checked: boolean) =>
    css({
      width: 6,
      height: 6,
      borderRadius: 25,
      display: checked ? 'block' : 'none',
      backgroundColor: THEME.colors.primary,
    }),
};

import {helperFormatPrice} from '../../../../helpers/helperFormatPrice';

export const columnsCancellationWaiter = [
  {
    field: 'username',
    headerName: 'Nom du Serveur',
    flex: 1,
  },
  {
    field: 'quantity',
    headerName: 'Quantité',
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Montant',
    flex: 1,
    valueGetter: (row: any) => {
      return helperFormatPrice(Math.abs(row.amount)) + ' €';
    },
  },
];

export const columnsCancellationReasons = [
  {
    field: 'reason',
    headerName: 'Motif',
    headerClassName: 'textSemiBold',
    flex: 1,
  },
  {
    field: 'quantity',
    headerName: 'Quantité',
    headerClassName: 'textSemiBold',
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Montant',
    headerClassName: 'textSemiBold',
    flex: 1,
    valueGetter: (params: any) => {
      return helperFormatPrice(Math.abs(params?.amount)) + ' €';
    },
  },
];

import {useQuery} from "@tanstack/react-query";
import {getTaxes} from "../api/fetchTaxes";

export const useTaxes = () => {
  const {data} = useQuery(['getTaxes'], getTaxes);

  const TAXES = data?.map((item: any) => ({
    label: `TVA ${item.amount/100} . TVA ${item.redAmount/100}`,
    value: item.uuid,
  }));

  return {
    data: TAXES,
  };
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {DropDownBodyAnim} from '../../../../../components/layouts/DropDownBodyAnim';
import {SearchFilter} from '../../../../../components/filters/SearchFilter';
import {TextVariant} from '../../../../../components/atoms/TextVariant';
import {THEME} from '../../../../../styles/theme';
import {TranslateInput} from './TranslateInput';
import {AbsoluteLoader} from '../../../../../components/layouts/AbsoluteLoader';
import {Fragment} from 'react';
import {helperTranslateField} from '../../../../../helpers/helperTranslateField';
import {ITranslationCommon} from '../../../../../api/fetchTranslations';

export const Body = ({
  items,
  isOpened,
  value,
  onChange,
  onToggleShowAll,
  showAll,
  showHeader = true,
  isLoading = false,
  category,
  categoryType,
  fields = ['label', 'description', 'composition'],
}: {
  items: ITranslationCommon[];
  isOpened: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onToggleShowAll?: (v: boolean) => void;
  showAll?: boolean;
  showHeader?: boolean;
  isLoading?: boolean;
  category?: string;
  categoryType?: number;
  fields?: string[];
}) => {
  return (
    <DropDownBodyAnim isOpened={isOpened}>
      <div css={styles.container}>
        {showHeader && (
          <div css={styles.header}>
            <div style={{flex: 1}}>
              <SearchFilter
                withBorder
                width={'100%'}
                onChange={onChange}
                value={value}
              />
            </div>
            <div css={styles.headerToggles}>
              <div
                css={styles.headerToggle(showAll)}
                onClick={() => onToggleShowAll?.(true)}>
                <TextVariant
                  variant="textSemiBold"
                  text="VOIR TOUS"
                  style={{
                    color: showAll ? 'white' : '#CCD6E0',
                  }}
                />
              </div>

              <div
                css={styles.headerToggle(!showAll, THEME.colors.red)}
                onClick={() => onToggleShowAll?.(false)}>
                <TextVariant
                  variant="textSemiBold"
                  text="TRADUCTIONS MANQUANTES"
                  style={{
                    color: !showAll ? 'white' : '#CCD6E0',
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <div css={styles.body}>
          {!isLoading &&
            items?.map(item => (
              <Fragment key={'' + item.uuid}>
                {fields.map(field => {
                  if (!item[field as keyof typeof item]) return null;
                  return (
                    <div css={styles.item}>
                      <TranslateInput
                        disabled
                        item={item}
                        label={helperTranslateField({
                          category,
                          categoryType,
                          field,
                        })}
                        labelType={field}
                      />
                      <TranslateInput
                        item={item}
                        category={category}
                        categoryType={categoryType}
                        labelType={field}
                      />
                    </div>
                  );
                })}
              </Fragment>
            ))}

          {isLoading && <AbsoluteLoader />}
        </div>

        {/* <div css={styles.footer}>
          <DatagridPagination
            page={1}
            totalRows={100}
            rowPerPage={20}
            setPage={() => {}}
            isLoading={false}
            paginationShowMore={false}
          />
        </div> */}
      </div>
    </DropDownBodyAnim>
  );
};

const styles = {
  container: css({
    padding: '2vh',
    paddingTop: '1vh',
  }),
  header: css({
    display: 'flex',
    alignItems: 'center',
    gap: '1vh',
  }),
  headerToggles: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.2vh',
    marginLeft: 'auto',
    border: '1px solid #CCD6E0',
    padding: '0.5vh 0.2vh',
    height: 28,
    borderRadius: 2,
  }),
  headerToggle: (active: boolean = false, bg?: string) =>
    css({
      backgroundColor: active ? bg || THEME.colors.primary : 'transparent',
      padding: '0.4vh 0.5vh',
      borderRadius: 2,
      cursor: 'pointer',
    }),

  body: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.3vh',
    minHeight: '10vh',
  }),

  item: css({display: 'flex', alignItems: 'center', gap: '2vh'}),

  footer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext} from 'react';
import {Title} from '../../../../../components/atoms/Title';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {ProductList} from '../../../../../components/molecules/ProductList';
import {useQuery} from '@tanstack/react-query';
import {deleteItemRecipe, getItemRecipes} from '../../../../../api/fetchItems';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {productRecipesAction} from '../../../../../redux/myCatalogSection/products';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';

export const Recipes = () => {
  const {t} = useContext<any>(UtilsContext);
  const dispatch = useAppDispatch();

  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const productPreRegister = useAppSelector(
    s => s.myCatalogSection.products.productPreRegister,
  );

  const {data, isLoading, refetch} = useQuery(
    ['getItemRecipes'],
    () => getItemRecipes(productPreRegister?.uuid),
    {
      enabled: !!productPreRegister?.uuid,
    },
  );

  if (data) {
    dispatch(productRecipesAction(data));
  }

  const handleClick = async (recipe: any) => {
    if (!hasAuthorization) {
      return;
    }
    const res = await deleteItemRecipe(
      productPreRegister?.uuid,
      recipe?.recipe?.uuid,
    );
    if (res?.status === 204) {
      helperDisplayToast(res?.data?.message || 'Ingrédient retiré', true);
      refetch();
    } else {
      helperDisplayToast(
        res?.data?.message || "Impossible de retirer l'ingrédient",
        false,
      );
    }
  };

  return (
    <div css={styles(hasAuthorization).container}>
      <Title title={t('PRODUCT_RECIPES')} />
      <div css={styles().content}>
        <ProductList
          bg="white"
          data={data?.map((item: any) => ({...item, ...item?.recipe})) || []}
          handleOnClick={handleClick}
          loading={isLoading}
          showPrice={false}
          specificImage="/images/recipe.svg"
          showCross={hasAuthorization}
        />
      </div>
    </div>
  );
};

const styles = (hasAuthorization: boolean = false) => ({
  container: css({
    width: hasAuthorization ? '50%' : '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  content: css({
    backgroundColor: 'white',
    padding: '10px 9%',
    marginTop: 10,
    width: '100%',
    height: '100%',
    overflow: 'auto',
  }),
});

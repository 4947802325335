/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ModuleCard} from '../../../../components/molecules/ModuleCard';
import {Title} from '../../../../components/atoms/Title';
import {useSubscription} from '../../../../hooks/useSubscription';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {useTranslation} from 'react-i18next';

export const ModuleList = withRedirectAndNavigation(() => {
  const {t} = useTranslation();
  const {data} = useSubscription();

  return (
    <div css={styles.container}>
      <Title title={t('MY_MODULES')} />
      <div css={styles.content}>
        {data?.map((module: any, index: number) => {
          return <ModuleCard key={index} module={module} />;
        })}
      </div>
    </div>
  );
});

const styles = {
  container: css({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '2vh',
  }),
  content: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(28rem, 1fr))',
    gap: '0.8rem',
    padding: '1rem 0',
  }),
};

import {SVGProps} from 'react';

export const TrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.24717 10.1562C4.24717 10.3797 4.05913 10.5625 3.82929 10.5625C3.59946 10.5625 3.41141 10.3797 3.41141 10.1562V4.875C3.41141 4.65156 3.59946 4.46875 3.82929 4.46875C4.05913 4.46875 4.24717 4.65156 4.24717 4.875V10.1562ZM6.33658 10.1562C6.33658 10.3797 6.14853 10.5625 5.9187 10.5625C5.68887 10.5625 5.50082 10.3797 5.50082 10.1562V4.875C5.50082 4.65156 5.68887 4.46875 5.9187 4.46875C6.14853 4.46875 6.33658 4.65156 6.33658 4.875V10.1562ZM8.42599 10.1562C8.42599 10.3797 8.23794 10.5625 8.00811 10.5625C7.77827 10.5625 7.59023 10.3797 7.59023 10.1562V4.875C7.59023 4.65156 7.77827 4.46875 8.00811 4.46875C8.23794 4.46875 8.42599 4.65156 8.42599 4.875V10.1562ZM8.36069 0.633242L9.31921 2.03125H11.1422C11.4896 2.03125 11.769 2.3042 11.769 2.64062C11.769 2.97832 11.4896 3.25 11.1422 3.25H10.9333V10.9688C10.9333 12.091 9.99827 13 8.84387 13H2.99353C1.83965 13 0.904122 12.091 0.904122 10.9688V3.25H0.695182C0.349123 3.25 0.0683594 2.97832 0.0683594 2.64062C0.0683594 2.3042 0.349123 2.03125 0.695182 2.03125H2.51871L3.47671 0.633242C3.74833 0.23758 4.20539 0 4.69378 0H7.14361C7.63201 0 8.08907 0.237605 8.36069 0.633242ZM4.02517 2.03125H7.81223L7.31599 1.30914C7.27681 1.25277 7.21152 1.21875 7.14361 1.21875H4.69378C4.62588 1.21875 4.53708 1.25277 4.52141 1.30914L4.02517 2.03125ZM2.15777 10.9688C2.15777 11.4182 2.53203 11.7812 2.99353 11.7812H8.84387C9.30615 11.7812 9.67963 11.4182 9.67963 10.9688V3.25H2.15777V10.9688Z"
      fill={props.color || '#EC5174'}
    />
  </svg>
);

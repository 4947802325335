export const BAppIcon = ({
  size,
  isDisabled = false,
  isActive = false,
}: {
  size: string;
  isDisabled?: boolean;
  isActive?: boolean;
}) => (
  <svg
    width={size === 'small' ? '34' : '54'}
    height={size === 'small' ? '15' : '24'}
    viewBox="0 0 54 24"
    fill="none">
    <path
      d="M8.1116 5.47911C7.20522 5.47911 6.33731 5.63369 5.53503 5.91367L7.35006 9.16101C7.59561 9.11021 7.85021 9.0821 8.11273 9.0821C10.1032 9.0821 11.7179 10.5988 11.7179 12.47C11.7179 14.3412 10.1043 15.8578 8.11273 15.8578C6.12118 15.8578 4.60375 14.4244 4.51662 12.6256L4.5087 12.6343V6.35472C4.5087 6.35472 4.50304 6.35797 4.49965 6.35905L4.5087 0.5H0.673828V19.4598H4.47815V18.569C5.55427 19.1354 6.7922 19.4598 8.1116 19.4598C12.2192 19.4598 15.5494 16.3302 15.5494 12.4689C15.5494 8.60754 12.2192 5.47911 8.1116 5.47911Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#151A30' : '#3C6AF6'}
    />
    <path
      d="M28.5251 9.78055V19.4123H25.8841V14.5845C25.8841 14.2105 25.8094 13.8635 25.6623 13.5414C25.514 13.2203 25.3092 12.936 25.0478 12.6917C24.7865 12.4474 24.4832 12.2571 24.1403 12.122C23.7975 11.9869 23.4376 11.9188 23.0619 11.9188C22.6863 11.9188 22.3095 11.9901 21.9734 12.1317C21.6373 12.2733 21.3386 12.4636 21.0761 12.7025C20.8135 12.9414 20.6087 13.2246 20.4616 13.5533C20.3134 13.8819 20.2398 14.2332 20.2398 14.6072C20.2398 14.9813 20.3134 15.3099 20.4616 15.632C20.6098 15.9542 20.8147 16.2374 21.0761 16.4828C21.3386 16.7282 21.6373 16.9217 21.9734 17.0633C22.3095 17.2049 22.6727 17.2762 23.0619 17.2762C23.4784 17.2762 23.8348 17.206 24.1313 17.0644H24.1516V17.1033L25.1599 18.8178L25.1395 18.8372C24.4809 19.3247 23.6673 19.5691 22.6998 19.5691C21.987 19.5691 21.3148 19.4404 20.6834 19.1831C20.052 18.9269 19.5009 18.5734 19.0302 18.1238C18.5595 17.6741 18.186 17.1487 17.9111 16.5444C17.635 15.9412 17.498 15.2991 17.498 14.6181C17.498 13.937 17.6361 13.2765 17.9111 12.6722C18.186 12.069 18.5595 11.5426 19.0302 11.0929C19.5009 10.6432 20.052 10.2875 20.6834 10.0238C21.3148 9.76001 21.987 9.62921 22.6998 9.62921C23.4127 9.62921 24.0102 9.75353 24.5341 10.0022C25.058 10.2497 25.5084 10.6097 25.8852 11.081V9.7838H28.5263L28.5251 9.78055Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M35.846 9.62457C36.5714 9.62457 37.2571 9.75645 37.9032 10.0191C38.5482 10.2829 39.1095 10.6418 39.5859 11.098C40.0634 11.5542 40.4402 12.0839 40.7152 12.6871C40.9901 13.2903 41.1282 13.9389 41.1282 14.6318C41.1282 15.3247 40.9901 15.9993 40.7152 16.6165C40.4391 17.2327 40.0634 17.7689 39.5859 18.224C39.1095 18.6802 38.5482 19.0401 37.9032 19.3028C37.2571 19.5655 36.5725 19.6974 35.846 19.6974C34.8514 19.6974 34.0242 19.4423 33.3657 18.9331V18.9137L34.3739 17.1538L34.3942 17.1732C34.7575 17.3278 35.1264 17.4046 35.5032 17.4046C35.88 17.4046 36.2625 17.3332 36.6121 17.1916C36.9618 17.05 37.2673 16.8522 37.5298 16.5992C37.7923 16.3473 37.9971 16.0533 38.1454 15.7171C38.2936 15.3809 38.3672 15.0188 38.3672 14.6307C38.3672 14.2426 38.2936 13.9032 38.1454 13.5735C37.9971 13.2438 37.7923 12.9562 37.5298 12.7098C37.2673 12.4633 36.9618 12.2698 36.6121 12.1282C36.2625 11.9866 35.8936 11.9141 35.5032 11.9141C35.1128 11.9141 34.7269 11.9855 34.3841 12.126C34.0412 12.2676 33.7425 12.4611 33.4867 12.7054C33.231 12.9497 33.0296 13.2362 32.8813 13.5638C32.7331 13.8913 32.6596 14.2415 32.6596 14.6156V23.2604H29.937V9.77916H32.6596V11.0764C33.386 10.1089 34.4474 9.62457 35.846 9.62457Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
    <path
      d="M48.0433 9.62457C48.7686 9.62457 49.4544 9.75645 50.1005 10.0191C50.7455 10.2829 51.3067 10.6418 51.7831 11.098C52.2606 11.5542 52.6375 12.0839 52.9124 12.6871C53.1874 13.2903 53.3254 13.9389 53.3254 14.6318C53.3254 15.3247 53.1874 15.9993 52.9124 16.6165C52.6363 17.2327 52.2606 17.7689 51.7831 18.224C51.3067 18.6802 50.7455 19.0401 50.1005 19.3028C49.4544 19.5655 48.7698 19.6974 48.0433 19.6974C47.0487 19.6974 46.2215 19.4423 45.5629 18.9331V18.9137L46.5711 17.1538L46.5915 17.1732C46.9547 17.3278 47.3236 17.4046 47.7004 17.4046C48.0773 17.4046 48.4597 17.3332 48.8094 17.1916C49.159 17.05 49.4646 16.8522 49.7271 16.5992C49.9896 16.3473 50.1944 16.0533 50.3426 15.7171C50.4909 15.3809 50.5644 15.0188 50.5644 14.6307C50.5644 14.2426 50.4909 13.9032 50.3426 13.5735C50.1944 13.2438 49.9896 12.9562 49.7271 12.7098C49.4646 12.4633 49.159 12.2698 48.8094 12.1282C48.4597 11.9866 48.0908 11.9141 47.7004 11.9141C47.3101 11.9141 46.9242 11.9855 46.5813 12.126C46.2385 12.2676 45.9397 12.4611 45.684 12.7054C45.4283 12.9497 45.2268 13.2362 45.0786 13.5638C44.9304 13.8924 44.8568 14.2415 44.8568 14.6156V23.2604H42.1343V9.77916H44.8568V11.0764C45.5833 10.1089 46.6447 9.62457 48.0433 9.62457Z"
      fill={isDisabled ? '#CCD6E0' : isActive ? '#fff' : '#151A30'}
    />
  </svg>
);

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addCompositionSubMenuModalAction} from '../../redux/modals';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {CheckboxCustom} from '../atoms/CheckBoxCustom';
import {SubMenuHeader} from '../../views/Online/MyCatalog/GestionMenu/SubMenus/SubMenuHeader';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {THEME} from '../../styles/theme';
import {useTranslation} from 'react-i18next';
import {useSubmenu} from '../../hooks/useSubmenu';
import {useEffect, useState} from 'react';
import {IModifierGetReturn} from '@bill-app-types/b-types';
import {DatagridPagination} from '../newDatagrid/DatagridPagination';
import {createItemModifier} from '../../api/fetchItems';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';

export const AddCompositionSubMenuModal = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();

  const menuPreRegister = useAppSelector(
    s => s.myCatalogSection.menus.menuPreRegister,
  );
  const subMenuList = useAppSelector(s => s.myCatalogSection.menus.submenuList);

  const [selectedSubmenus, setSelectedSubmenus] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [limit] = useState(20);

  const offset = (page - 1) * limit;

  const {data, isLoading} = useSubmenu({limit, offset});

  const handleClose = () => {
    dispatch(addCompositionSubMenuModalAction(false));
  };

  const handleClickSubmenu = (item: IModifierGetReturn) => {
    const submenuIndex = selectedSubmenus?.findIndex(
      (submenu: string) => submenu === item.uuid,
    );
    if (submenuIndex > -1) {
      setSelectedSubmenus(prevValue =>
        prevValue.filter((item, i) => i !== submenuIndex),
      );
    } else {
      setSelectedSubmenus([...selectedSubmenus, item.uuid]);
    }
  };

  useEffect(() => {
    setRowCount(oldRowCount =>
      data?.totalModifiers !== undefined ? data?.totalModifiers : oldRowCount,
    );
  }, [data?.totalModifiers]);

  const newData = data?.modifiers?.filter(
    (item: IModifierGetReturn) =>
      !subMenuList?.find(submenu => submenu.uuid === item.uuid),
  );

  const handleSubmit = async () => {
    const body = selectedSubmenus.map(item => ({
      modifierUuid: item,
      position: 1,
    }));
    const res = await createItemModifier(menuPreRegister.uuid, body);
    if (res?.status === 204) {
      handleClose();
      clientQuery.invalidateQueries(['getItemSubmenus']);
    } else {
      helperDisplayToast(
        res?.data?.message || "Impossible d'ajouter les sous-menus",
        false,
      );
    }
  };

  return (
    <ModalWrapper
      onCancel={handleClose}
      title={t('ADD_SUBMENU')}
      minWidth={'40%'}
      minHeight={'80vh'}
      maxHeight={'80vh'}
      showBtns={false}>
      <div css={styles.container}>
        <div css={styles.content}>
          {newData && newData.length === 0 && (
            <span css={styles.noData} className="textSemiBold">
              Aucune donnée
            </span>
          )}
          {newData?.map((item: IModifierGetReturn, index: number) => {
            return (
              <div
                onClick={() => handleClickSubmenu(item)}
                key={index}
                className="flex-center"
                css={styles.subMenuItem}>
                <CheckboxCustom
                  type="rounded"
                  checked={selectedSubmenus.includes(item.uuid)}
                />
                <SubMenuHeader disabled submenu={item} miniCard />
              </div>
            );
          })}
        </div>
        <DatagridPagination
          page={page}
          totalRows={rowCount}
          rowPerPage={limit}
          setPage={setPage}
          isLoading={isLoading}
          paginationShowMore={false}
        />
        <div className="flex-center" css={styles.btns}>
          <ButtonCustom
            label={t('CANCEL')}
            onClick={handleClose}
            backgroundColor={THEME.colors.red}
          />
          <ButtonCustom
            label={t('ADD_A_SUBMENU')}
            backgroundColor={THEME.colors.green}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '1rem',
  }),
  content: css({
    overflow: 'auto',
    height: '60vh',
  }),
  subMenuItem: css({
    gap: '1rem',
    marginBottom: 10,
  }),
  btns: css({
    gap: '0.5rem',
  }),
  noData: css({
    textAlign: 'center',
    display: 'block',
    paddingTop: '40%',
    backgroundColor: THEME.colors.white,
    height: '100%',
  }),
};

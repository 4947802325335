export const helperFormatAmountSort = (orderBy: any[], field: string) => {
  const findAmountSort = orderBy?.find(
    item => item.field === 'amount' && item.sort,
  )?.sort;

  return findAmountSort
    ? [
        {
          field,
          sort: findAmountSort,
        },
      ]
    : [];
};

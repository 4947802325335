export const NextIcon = () => {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.19604 6.04456C8.56226 6.41077 8.56226 7.00549 8.19604 7.3717L2.57104 12.9967C2.20483 13.3629 1.61011 13.3629 1.2439 12.9967C0.877685 12.6305 0.877685 12.0358 1.2439 11.6696L6.20679 6.70666L1.24683 1.74377C0.880615 1.37756 0.880615 0.782837 1.24683 0.416626C1.61304 0.0504147 2.20776 0.0504148 2.57397 0.416626L8.19897 6.04163L8.19604 6.04456Z"
        fill="#626262"
      />
    </svg>
  );
};

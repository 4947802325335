import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {useAppDispatch} from '../../../../../hooks/useReduxHook';
import {
  qrCodesListModalAction,
  addAreaModalAction,
} from '../../../../../redux/modals';
import {useTranslation} from 'react-i18next';

export const Center = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const handleOpenModalListQrCodes = () => {
    dispatch(qrCodesListModalAction(true));
  };

  const handleOpenModalAddArea = () => {
    dispatch(addAreaModalAction(true));
  };

  return (
    <>
      <ButtonCustom
        label={t('QR_CODES_LIST')}
        onClick={handleOpenModalListQrCodes}
        marginRight={'10px'}
      />
      <ButtonCustom label={t('ADD_AN_AREA')} onClick={handleOpenModalAddArea} />
    </>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {TextVariant} from './TextVariant';
import {MinusIcon} from '../../assets/svgs/MinusIcon';
import {ArrowUpIcon} from '../../assets/svgs/ArrowUp';
import {ArrowDownIcon} from '../../assets/svgs/ArrowDown';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {sortFilterDateAction} from '../../redux/date';

const LIST = [
  {
    label: 'Croisant',
    value: 'ASC',
  },
  {
    label: 'Décroissant',
    value: 'DESC',
  },
] as const;

export const SortFilterDate = ({
  label,
  showSortFilter = true,
}: {
  label: string;
  showSortFilter?: boolean;
}) => {
  //
  const dispatch = useAppDispatch();
  const sortFilterDate = useAppSelector(state => state.date.sortFilterDate);
  const sortDateSelected = LIST.find(item => item.value === sortFilterDate);

  const handleUpdateSort = () => {
    if (!sortFilterDate) {
      dispatch(sortFilterDateAction(LIST[0].value));
    } else if (sortFilterDate === 'ASC') {
      dispatch(sortFilterDateAction(LIST[1].value));
    } else {
      dispatch(sortFilterDateAction(null));
    }
  };

  return (
    <div className="flex-space-between">
      <TextVariant text={label} variant="textSemiBold" />
      <div css={styles.sortSelectedContainer}>
        {showSortFilter && (
          <div
            css={[
              styles.sortItem,
              sortFilterDate && {backgroundColor: THEME.colors.primary},
            ]}
            className="flex-space-between"
            onClick={handleUpdateSort}>
            <TextVariant
              text={sortDateSelected?.label || 'Par défaut'}
              variant="textSemiBold"
              style={{
                color: sortFilterDate ? '#fff' : '#A7A7AB',
              }}
            />
            {sortFilterDate ? (
              sortFilterDate === 'ASC' ? (
                <ArrowUpIcon />
              ) : (
                <ArrowDownIcon />
              )
            ) : (
              <MinusIcon color="#A7A7AB" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  sortSelectedContainer: css({
    position: 'relative',
  }),
  sortItem: css({
    minWidth: '7rem',
    padding: '5px',
    height: 25,
    background: THEME.colors.bgLight,
    cursor: 'pointer',
    borderRadius: 2,
    userSelect: 'none',
  }),
};

/** @jsxImportSource @emotion/react */
import {TextVariant} from '../atoms/TextVariant';
import {SalesPieChart} from './SalesPieChart';
import {NewDataGrid} from '../newDatagrid/NewDataGrid';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {IconSquare} from '../atoms/IconSquare';
import {CardLargeIcon} from '../../assets/svgs/CardIcon';
import {THEME} from '../../styles/theme';
import {TicketIcon} from '../../assets/svgs/TicketIcon';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {
  SalesGraphSectionType,
  salesGraphSectionAction,
} from '../../redux/myStatsSection/sales';
import {M_SCREEN_DESKTOP} from '../../constants/ResponsiveSize';

interface ISalesPieChartList {
  title: string;
  data: any;
  columns: any;
  Filters?: React.ReactNode;
  showHeader?: boolean;
  showHeaderFilters?: boolean;
  showPieChart?: boolean;
  marginTop?: number | string;
  handleExport?: () => void;
  isLoading?: boolean;
  hasPagination?: boolean;
  paginationShowMore?: boolean;
  totalLimit?: number;
  limit?: number;
  updateLimit?: (type: 'incr' | 'decr') => void;
}

export const SalesPieChartList = ({
  title,
  data,
  Filters,
  columns,
  showHeader = true,
  showHeaderFilters,
  showPieChart = true,
  marginTop,
  handleExport,
  isLoading = false,
  hasPagination = false,
  paginationShowMore = false,
  totalLimit,
  updateLimit,
  limit,
}: ISalesPieChartList) => {
  const salesGraphSection = useAppSelector(
    s => s.myStatsSection.sales.salesGraphSection,
  );
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const handleClickItem = (item: SalesGraphSectionType) => {
    dispatch(salesGraphSectionAction(item));
  };

  return (
    <div css={[styles.container, {marginTop}]}>
      {!!showHeader && (
        <div css={styles.header}>
          <TextVariant
            text={t(title)}
            variant="textMedium"
            style={{
              textTransform: 'uppercase',
            }}
          />

          <div className="flex-space-between" css={styles.headerFilters}>
            {showHeaderFilters && (
              <>
                <div className="flex" css={styles.headerFiltersIcons}>
                  <IconSquare
                    Icon={CardLargeIcon}
                    borderColor={THEME.colors.primary}
                    active={salesGraphSection === 'TURNOVER'}
                    onClick={() => handleClickItem('TURNOVER')}
                    borderRadius="2px"
                  />

                  <IconSquare
                    Icon={TicketIcon}
                    borderColor={THEME.colors.yellowDark}
                    active={salesGraphSection === 'ORDERS'}
                    onClick={() => handleClickItem('ORDERS')}
                    borderRadius="2px"
                  />
                </div>
              </>
            )}

            {!!Filters ? Filters : <div />}
          </div>

          <div css={styles.headerButtonExport}>
            {/* <ButtonCustom onClick={handleExport} label={t('EXPORT_DATA')} /> */}
          </div>
        </div>
      )}

      <div className="flex" css={styles.content}>
        {showPieChart && <SalesPieChart data={data} />}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 475,
            width: '100%',
          }}>
          <div style={{height: '98%', width: '100%'}}>
            <NewDataGrid
              columns={columns}
              rows={data}
              rowHeight={37}
              showBorderBottom
              loading={isLoading}
              hasPagination={hasPagination}
              paginationShowMore={paginationShowMore}
              updateLimit={updateLimit}
              totalLimit={totalLimit}
              limit={limit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
    backgroundColor: 'white',
    padding: '2vh',
    overflowX: 'auto',
  }),
  content: css({
    alignItems: 'flex-start',
    [M_SCREEN_DESKTOP]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
  header: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  headerFilters: css({
    position: 'relative',
    justifyContent: 'flex-end',
    [M_SCREEN_DESKTOP]: {
      flexDirection: 'column',
    },
  }),
  headerFiltersIcons: css({
    columnGap: '0.4rem',
    justifyContent: 'center',
    flex: 1,
    position: 'absolute',
    left: '50%',
    [M_SCREEN_DESKTOP]: {
      position: 'relative',
      left: 0,
      marginTop: '5%',
      marginBottom: '5%',
    },
  }),
  headerButtonExport: css({
    alignSelf: 'flex-end',
    marginTop: '1rem',
  }),
};

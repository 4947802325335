import {SVGProps} from 'react';

export const PaginationLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.648275 5.53653C0.393283 5.28154 0.393283 4.86744 0.648275 4.61245L4.56494 0.695779C4.81993 0.440787 5.23404 0.440787 5.48903 0.695779C5.74402 0.950769 5.74402 1.36488 5.48903 1.61987L2.03339 5.07551L5.48699 8.53115C5.74198 8.78614 5.74198 9.20025 5.48699 9.45524C5.232 9.71023 4.81789 9.71023 4.5629 9.45524L0.646235 5.53857L0.648275 5.53653Z"
      fill={props.color || '#CCD6E0'}
    />
  </svg>
);

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';

export const ButtonList = ({
  label,
  required = false,
  width = '100%',
  marginBottom = 10,
  list = [],
  selectedValues = [],
  handleClickButton,
}: {
  label?: string;
  required?: boolean;
  width?: number | string;
  marginBottom?: number | string;
  list: {
    label: string;
    value: string | number;
  }[];
  selectedValues: any;
  handleClickButton: (value: string | number) => void;
}) => {
  return (
    <div
      css={[
        styles.container(width),
        (marginBottom = 10),
        {
          marginBottom,
        },
      ]}>
      {label && (
        <p className="textLabel">
          {label}
          {required && <span css={styles.required}>*</span>}
        </p>
      )}
      <div className="flex" css={styles.list}>
        {list.map((item, index) => (
          <div
            className="title03"
            css={styles.button(selectedValues.includes(item.value))}
            key={index}
            onClick={() => handleClickButton(item.value)}>
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: (width: string | number) =>
    css({
      position: 'relative',
      width,
      marginBottom: 10,
    }),
  label: css({
    marginTop: 15,
    fontSize: 12,
    padding: '0 10px',
    color: THEME.colors.gray,
    marginBottom: 5,
    fontWeight: 600,
  }),
  required: css({
    color: THEME.colors.red,
    marginLeft: 5,
  }),
  list: css({
    gap: '0.5rem',
  }),
  button: (active: boolean = false) =>
    css({
      height: 34,
      backgroundColor: active ? THEME.colors.primary : THEME.colors.white,
      color: active ? THEME.colors.white : THEME.colors.gray,
      padding: '0 1rem',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      borderRadius: 2,
      cursor: 'pointer',
    }),
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {BadgeCounter} from '../../../../../components/atoms/BadgeCounter';
import {SmallOptionsOutlineIcon} from '../../../../../assets/svgs/OptionsOutline';
import {TextVariant} from '../../../../../components/atoms/TextVariant';
import {MiniButton} from '../../../../../components/atoms/MiniButton';
import {THEME} from '../../../../../styles/theme';
import {helperLabelTranslation} from '../../../../../helpers/helperLabel';
import {MinusIcon} from '../../../../../assets/svgs/MinusIcon';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {
  createItemModifier,
  deleteItemModifier,
} from '../../../../../api/fetchItems';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';

export const OptionCard = ({
  showBorder = false,
  showBadge = false,
  option,
  isRemove = false,
}: {
  showBorder?: boolean;
  showBadge?: boolean;
  option?: any;
  isRemove?: boolean;
}) => {
  const clientQuery = useQueryClient();
  const productPreRegister = useAppSelector(
    s => s.myCatalogSection.products.productPreRegister,
  );

  const {brandHasAuthorization} = useBrandAccess();

  const handleClick = async () => {
    if (isRemove) {
      const res = await deleteItemModifier(
        productPreRegister?.uuid,
        option.uuid,
      );
      if (res?.status === 204) {
        helperDisplayToast(
          res?.data?.message || "Catégorie d'option retirée",
          true,
        );
        clientQuery.invalidateQueries(['getItemOptionsCategories']);
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible de retirer la catégorie d'option",
          false,
        );
      }
    } else {
      const res = await createItemModifier(productPreRegister?.uuid, [
        {modifierUuid: option.uuid, position: 1},
      ]);
      if (res?.status === 204) {
        helperDisplayToast(
          res?.data?.message || "Catégorie d'option ajouté",
          true,
        );
        clientQuery.invalidateQueries(['getItemOptionsCategories']);
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible d'ajouter la catégorie d'option",
          false,
        );
      }
    }
  };

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <div
      css={[
        styles.optionCard,
        showBorder && {border: `1px solid ${THEME.colors.lightGray}`},
      ]}
      className="flex-space-between flex-align-center">
      {showBadge && <BadgeCounter count={option.position} />}
      <div className="flex-align-center">
        <SmallOptionsOutlineIcon />
        <TextVariant
          text={helperLabelTranslation(option)}
          variant="textSemiBold"
          style={styles.supplementOptionName}
        />
      </div>

      <div className="flex-align-center">
        <TextVariant
          text={`Choix ${option?.minimum}-${option?.maximum}`}
          variant="textRegular"
          style={styles.supplementOptionChoice}
        />

        <MiniButton
          isRemove={isRemove}
          Icon={MinusIcon}
          onClick={handleClick}
          size="large"
          defaultBgColor={THEME.colors.green}
          disabled={!hasAuthorization}
        />
      </div>
    </div>
  );
};

const styles = {
  optionCard: css({
    position: 'relative',
    width: '100%',
    height: '50px',
    backgroundColor: THEME.colors.white,
    padding: '0 0.5rem',
    marginBottom: '0.5rem',
    cursor: 'pointer',
  }),
  supplementOptionName: css({
    marginLeft: '1.5rem',
  }),
  supplementOptionChoice: css({
    marginRight: '0.5rem',
  }),
};

import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addPosButtonModalAction} from '../../redux/modals';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {UploadButton} from '../atoms/UploadButton';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useEffect, useState} from 'react';
import {
  selectedArticleForButtonAction,
  updateModeAction,
  updatedPosCategoryItemAction,
} from '../../redux/myModulesSection/pos';
// import {ColorPickerLabel} from '../atoms/ColorPickerLabel';
import {CATEGORY_HAS_ITEM_TYPES} from '@bill-app-types/b-types';
import {SelectList} from '../molecules/SelectList';
import {ProductsAndMenuCatalog} from '../organisms/ProductsAndMenuCatalog';
import {
  addPosCategoryItem,
  createPosCategory,
  updatePosCategory,
  updatePosCategoryItemImage,
} from '../../api/fetchPosCategories';
import {helperLabelTranslation} from '../../helpers/helperLabel';
import {InputLabel} from '../atoms/InputLabel';

export const AddPosButtonModal = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();
  const initialValues = {
    img: '',
  };

  const [form, setForm] = useState({
    type: CATEGORY_HAS_ITEM_TYPES.CATEGORY,
    color: '#000000',
    itemUuid: '',
    label: '',
  });

  const isArticle = form.type === CATEGORY_HAS_ITEM_TYPES.ITEM;

  const [image, setImage] = useState<any>(null);

  const updateMode = useAppSelector(
    state => state.myModulesSection.pos.updateMode,
  );
  const updatedPosCategoryItem = useAppSelector(
    state => state.myModulesSection.pos.updatedPosCategoryItem,
  );

  const selectedArticleForButton = useAppSelector(
    state => state.myModulesSection.pos.selectedArticleForButton,
  );

  const treePreRegister = useAppSelector(
    state => state.myModulesSection.pos.treePreRegister,
  );

  const selectedPosCategory = useAppSelector(
    state => state.myModulesSection.pos.selectedPosCategory,
  );

  const selectedButtons = useAppSelector(
    s => s.myModulesSection.pos.selectedButtons,
  );

  const lastButton = selectedButtons[selectedButtons.length - 1];
  useEffect(() => {
    if (selectedArticleForButton && isArticle) {
      setImage({url: selectedArticleForButton?.image, file: null});
      setForm({
        ...form,
        itemUuid: selectedArticleForButton?.uuid,
        label: selectedArticleForButton?.label,
      });
    }
  }, [selectedArticleForButton, isArticle]);

  useEffect(() => {
    if (updateMode && updatedPosCategoryItem) {
      setForm({
        ...form,
        color: updatedPosCategoryItem.color,
        label: updatedPosCategoryItem.backofficeLabel,
        type: !!updatedPosCategoryItem.type
          ? CATEGORY_HAS_ITEM_TYPES.ITEM
          : CATEGORY_HAS_ITEM_TYPES.CATEGORY,
      });
    }
  }, [updateMode, updatedPosCategoryItem]);

  const handleOnCancel = () => {
    dispatch(addPosButtonModalAction(false));
    dispatch(updatedPosCategoryItemAction(null));
    dispatch(updateModeAction(false));
  };

  const handleSuccess = (res: {data: {message: string}}) => {
    handleOnCancel();
    helperDisplayToast(res?.data?.message || 'Bouton enregistré', true);
  };

  const handleChangeImage = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const imageURL = e.target.result;
        setImage({url: imageURL, file});
      };

      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;
    setForm({...form, [name]: name === 'type' ? Number(value) : value});
  };

  const handleUpdateImage = async (itemUuid: string) => {
    const res = await updatePosCategoryItemImage(
      lastButton?.uuid || selectedPosCategory?.uuid,
      itemUuid,
      image?.file,
    );
    if (res?.status !== 204) {
      helperDisplayToast("Impossible de modifier l'image", false);
    }
    clientQuery.invalidateQueries([
      `fetchPosCategoryItems-${lastButton?.uuid || selectedPosCategory.uuid}`,
    ]);
  };

  const handleSubmit = async (
    data: {
      img: string;
    },
    {setSubmitting, resetForm}: any,
  ) => {
    if (form.label) {
      let res;
      let creationResult;
      if (updateMode) {
        res = await updatePosCategory(updatedPosCategoryItem.uuid, {
          label: form.label,
        });
      } else {
        if (!isArticle) {
          creationResult = await createPosCategory({
            label: form.label,
            structureUuid: treePreRegister.uuid,
            parentCategoryUuid: lastButton?.uuid || selectedPosCategory?.uuid,
            color: form.color,
            position: 1,
          });

          if (res && ![200, 204].includes(creationResult.status)) {
            return helperDisplayToast(
              'Impossible de créer la catégorie',
              false,
            );
          }
        }

        res = await addPosCategoryItem(
          lastButton?.uuid || selectedPosCategory.uuid,
          {
            itemUuid: isArticle
              ? selectedArticleForButton.uuid
              : creationResult?.data?.data,
            type: form.type,
            color: form.color,
            position: 1,
          },
        );
      }

      if (res && [200, 204].includes(res.status)) {
        handleSuccess(res);
        resetForm();
        if (image && !isArticle) {
          handleUpdateImage(
            updateMode
              ? updatedPosCategoryItem.uuid
              : creationResult?.data?.data,
          );
        } else {
          clientQuery.invalidateQueries([
            `fetchPosCategoryItems-${
              lastButton?.uuid || selectedPosCategory.uuid
            }`,
          ]);
        }
      } else {
        helperDisplayToast(
          res?.data?.message || 'Impossible de créer le bouton',
          false,
        );
      }
    } else {
      helperDisplayToast('Tous les champs doivent être complétés ', false);
    }
  };

  const handleOnClickProduct = (article: any) => {
    dispatch(selectedArticleForButtonAction(article));
  };

  const ITEM_TYPES = Object.entries(CATEGORY_HAS_ITEM_TYPES)
    .slice(2)
    .map(entry => ({
      label: t(entry[0]),
      value: Number(entry[1]),
    }));
  console.log('updatedPosCategoryItem');

  return (
    <ModalWrapper
      title={updateMode ? 'Modifier le bouton' : 'Ajouter un bouton'}
      minWidth="33%"
      validateLabel={'Enregistrer le bouton'}
      cancelLabel={t(updateMode ? 'Annuler la modification' : 'CANCEL_CREATE')}
      onCancel={handleOnCancel}
      onSubmit={handleSubmit}
      initialValues={updateMode ? updatedPosCategoryItem : initialValues}>
      <div
        style={{
          height: isArticle ? '72vh' : 'auto',
          width: isArticle ? '80vw' : 'auto',
        }}>
        <div
          className="flex"
          style={{
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
          }}>
          {isArticle && (
            <ProductsAndMenuCatalog
              width={'59%'}
              hiddenProducts={false}
              onClickProduct={handleOnClickProduct}
            />
          )}
          <div style={{width: isArticle ? '39%' : '100%'}}>
            <SelectList
              label={t('TYPE')}
              name="type"
              list={ITEM_TYPES}
              value={form.type}
              onChange={handleChange}
              manageByFormik={false}
              required
              disabled={updateMode}
            />
            <InputLabel
              onChange={handleChange}
              label={'Nom du bouton'}
              name="label"
              required
              value={form.label}
              disabled={isArticle}
            />
            {/* <ColorPickerLabel
            label={t('COLOR')}
            name="color"
            value={form.color}
            onChange={e => setForm({...form, color: e.currentTarget?.value})}
          /> */}
            <UploadButton
              style={{margin: 'auto', marginTop: 25}}
              width={194}
              x={650}
              y={650}
              handleChange={handleChangeImage}
              value={
                !!image
                  ? image?.url
                  : updatedPosCategoryItem?.image || undefined
              }
              name="img"
              disabled={isArticle}
            />
            <FieldsRequired />
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

const CatItem = ({item}: any) => {
  return (
    <>
      <option value={item.uuid}>{helperLabelTranslation(item)}</option>
      <SubCatItem item={item} />
    </>
  );
};

const SubCatItem = ({item}: any) => {
  if (item.subCategories?.length === 0) return <></>;
  return (
    <>
      {item.subCategories?.map((subCat: any) => {
        return <CatItem item={subCat} />;
      })}
    </>
  );
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {helperFormatPrice} from '../../helpers/helperFormatPrice';
import {TextVariant} from './TextVariant';

export const TotalPrice = ({price = 0}) => {
  return (
    <div css={styles.footer}>
      <TextVariant
        text={`${helperFormatPrice(price)}€`}
        variant="title03"
        style={{
          fontSize: 12,
        }}
      />
    </div>
  );
};

const styles = {
  footer: css(`
      width:max(200px,15%);
      display:block;
      align-self: flex-end;
      background-color: red;
      padding: 5px 20px;
      margin-left:auto;
      margin-bottom: 10px;
      text-align: center;
      background-color: #fff;
      border:1px solid #61D295;
      border-radius: 5px;
      color:#61D295;
     @media (max-width: 1024px) {
        width:50%;
    }
  `),
};

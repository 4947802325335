import {ICustomer} from '@bill-app-types/b-types';
import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  selectedCustomer: ICustomer | null;
  checkedCustomers: ICustomer[];
  multiSelection: boolean;
  selectedOrder: any | null;
}

const initialState: ISlice = {
  selectedCustomer: null,
  checkedCustomers: [],
  multiSelection: false,
  selectedOrder: null,
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    checkedCustomersAction: (state, action) => {
      const customerIndex = state.checkedCustomers.findIndex(
        item => item?.uuid === action.payload?.uuid,
      );
      if (customerIndex > -1) {
        state.checkedCustomers.splice(customerIndex, 1);
      } else {
        state.checkedCustomers = [...state.checkedCustomers, action.payload];
      }
    },
    resetCheckedCustomersAction: state => {
      state.checkedCustomers = [];
    },
    multiSelectionAction: (state, action) => {
      state.multiSelection = action.payload;
    },
    selectedCustomerAction: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    selectedOrderAction: (state, action) => {
      state.selectedOrder = action.payload;
    },
  },
});

export const {
  checkedCustomersAction,
  resetCheckedCustomersAction,
  multiSelectionAction,
  selectedCustomerAction,
  selectedOrderAction
} = slice.actions;

export default slice.reducer;

export const helperLabelTranslation = (item: any) => {
  if (!item) return '';

  const labels =
    item.backofficeLabel || item?.labelTranslationEntries || item?.label;

  const translation = Array.isArray(labels)
    ? labels?.find((label: any) => label.languageId === 1)?.content
    : labels;

  return translation || '';
};

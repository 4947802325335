import {SVGProps} from 'react';

export const MapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8467 1.44503C14.4668 1.00154 13.1719 0.789036 12.0381 0.800494C10.3184 0.824973 9.03223 1.26872 8.4375 1.52237C7.84277 1.26872 6.55957 0.825494 4.83984 0.801015C3.68555 0.783046 2.40762 1.00101 1.02832 1.44503C0.410742 1.64398 0 2.17159 0 2.75831V10.3651C0 10.7976 0.22248 11.1971 0.610547 11.4609C1.01279 11.7351 1.52871 11.8232 2.02266 11.7042C5.48262 10.8709 7.94063 12.3011 8.04316 12.3605C8.16211 12.4302 8.2998 12.4666 8.41113 12.4666C8.52246 12.4666 8.68564 12.4312 8.80459 12.3604C8.9076 12.2998 11.3707 10.8706 14.8251 11.7029C15.3208 11.8217 15.8358 11.7338 16.2378 11.4596C16.6523 11.1958 16.875 10.7974 16.875 10.3625V2.75831C16.875 2.17159 16.4385 1.64398 15.8467 1.44503ZM7.73438 10.8208C6.91816 10.5185 5.68828 10.1924 4.17773 10.1924C3.40459 10.1924 2.5582 10.2779 1.65586 10.4948C1.5583 10.5213 1.48945 10.4849 1.46484 10.4666C1.40625 10.4276 1.40625 10.3781 1.40625 10.3625V2.75831C1.40625 2.69849 1.45066 2.64031 1.51154 2.62075C2.71125 2.23534 3.84387 2.03846 4.81623 2.052C6.19043 2.07029 7.23047 2.40805 7.73438 2.60987V10.8208ZM15.4688 10.3625C15.4688 10.3779 15.4688 10.4255 15.4102 10.4658C15.3854 10.4825 15.3181 10.5196 15.2193 10.4947C12.5538 9.85409 10.3736 10.3637 9.14016 10.821V2.61008C9.64406 2.40742 10.6838 2.06997 12.0578 2.05096C13.0155 2.03753 14.1637 2.23448 15.3625 2.61971C15.4248 2.63851 15.4688 2.69841 15.4688 2.75831V10.3625Z"
      fill="white"
    />
  </svg>
);

export const LargeMapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="37"
    height="30"
    viewBox="0 0 37 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.3344 1.64485C31.4199 0.513947 28.6849 -0.0279283 26.2902 0.00129046C22.6579 0.0637123 19.9414 1.19528 18.6853 1.84207C17.4291 1.19528 14.7189 0.0650405 11.0866 0.00261862C8.64858 -0.0432017 5.94944 0.512619 3.0362 1.64485C1.7318 2.15219 0.864258 3.49758 0.864258 4.99371V24.391C0.864258 25.494 1.33416 26.5127 2.15381 27.1854C3.0034 27.8846 4.09308 28.1091 5.13635 27.8059C12.4442 25.6809 17.6358 29.3279 17.8524 29.4793C18.1036 29.657 18.3945 29.75 18.6296 29.75C18.8647 29.75 19.2094 29.6597 19.4606 29.4792C19.6782 29.3246 24.8806 25.6801 32.1767 27.8024C33.2237 28.1054 34.3115 27.8813 35.1605 27.1819C36.036 26.5093 36.5063 25.4933 36.5063 24.3843V4.99371C36.5063 3.49758 35.5843 2.15219 34.3344 1.64485ZM17.2002 25.5531C15.4763 24.7821 12.8786 23.9507 9.68814 23.9507C8.05517 23.9507 6.26749 24.1686 4.36163 24.7217C4.15558 24.7894 4.01016 24.6964 3.95819 24.65C3.83443 24.5504 3.83443 24.4242 3.83443 24.3843V4.99371C3.83443 4.84118 3.92824 4.69283 4.05682 4.64296C6.59075 3.66014 8.98297 3.15811 11.0367 3.19264C13.9392 3.23926 16.1359 4.10055 17.2002 4.6152V25.5531ZM33.5361 24.3843C33.5361 24.4238 33.5361 24.5452 33.4124 24.6479C33.3602 24.6905 33.218 24.7849 33.0092 24.7216C27.3795 23.088 22.7745 24.3874 20.1694 25.5536V4.61573C21.2337 4.09896 23.4298 3.23846 26.3319 3.18999C28.3547 3.15574 30.7797 3.65795 33.3118 4.6403C33.4433 4.68824 33.5361 4.84098 33.5361 4.99371V24.3843Z"
      fill="white"
    />
  </svg>
);

import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {useAppDispatch} from '../../../../../hooks/useReduxHook';
import {
  addPriceRuleModalAction,
  eventModalAction,
} from '../../../../../redux/modals';

export const Center = () => {
  const currentPath = window.location.href;
  const isPricesPage = currentPath.includes('/prices');
  const {brandHasAuthorization} = useBrandAccess();

  const dispatch = useAppDispatch();

  const handleOpenModalAddEvent = () => {
    if (!isPricesPage) {
      dispatch(eventModalAction(true));
    } else {
      dispatch(addPriceRuleModalAction(true));
    }
  };

  return (
    <>
      <ButtonCustom
        label={
          !isPricesPage ? 'AJOUTER UN EVENEMENT' : 'AJOUTER UNE REGLE DE PRIX'
        }
        onClick={handleOpenModalAddEvent}
        disabled={!brandHasAuthorization(BRAND_AUTHORIZATION.PARENT)}
      />
    </>
  );
};

export const LoopIcon = () => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.04167 9.20833C7.06671 9.20833 8.70833 7.56671 8.70833 5.54167C8.70833 3.51662 7.06671 1.875 5.04167 1.875C3.01662 1.875 1.375 3.51662 1.375 5.54167C1.375 7.56671 3.01662 9.20833 5.04167 9.20833Z"
      stroke="#CCD6E0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.62501 10.125L7.63126 8.13123"
      stroke="#CCD6E0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

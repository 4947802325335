/* eslint-disable react-hooks/exhaustive-deps */
import {ModalWrapper} from '../atoms/ModalWrapper';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addAreaLocationModalAction} from '../../redux/modals';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {useTranslation} from 'react-i18next';
import {InputLabel} from '../atoms/InputLabel';
import {useEffect, useState} from 'react';
import {createAreaLocation, updateAreaLocation} from '../../api/fetchAreas';
import {helperDisplayToast} from '../../helpers/helperToast';
import {areaLocationSelectedAction} from '../../redux/myRestaurantSection/areas';
import {useQueryClient} from '@tanstack/react-query';

export const AddAreaLocationModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const brand = useAppSelector(state => state.brand.brand);
  const areaSelected = useAppSelector(
    s => s.myRestaurantSection.areas.areaSelected,
  );
  const areaLocationSelected = useAppSelector(
    s => s.myRestaurantSection.areas.areaLocationSelected,
  );

  const clientQuery = useQueryClient();

  const initialValues = {
    label: '',
    language_id: 1,
    is_open: 1,
  };

  const [form, setForm] = useState(initialValues);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    if ((value && isNaN(Number(value))) || value.length > 6) {
      return;
    }

    setForm({
      ...form,
      [name]: value,
    });
  };

  /* ON CANCEL */
  const handleOnCancel = () => {
    dispatch(addAreaLocationModalAction(false));
    dispatch(areaLocationSelectedAction(null));
  };

  /* HANDLE SUBMIT */
  const handleOnSubmit = async (_: any, {setSubmitting}: any) => {
    if (form?.label?.length > 6 || form?.label.length < 3) {
      return;
    }

    let res;

    if (areaLocationSelected && areaSelected) {
      res = await updateAreaLocation(
        brand.id,
        areaSelected.id,
        areaLocationSelected.id,
        form,
      );
    } else if (areaSelected) {
      res = await createAreaLocation(brand.id, areaSelected?.id, form);
    }

    if (res.status === 200) {
      await clientQuery.invalidateQueries(['fetchAreaLocations']);
      helperDisplayToast(res.message, true);
      handleOnCancel();
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (areaLocationSelected) {
      setForm({
        ...form,
        label: areaLocationSelected.label,
      });
    } else {
      setForm(initialValues);
    }
  }, [areaLocationSelected]);

  const title = t(
    areaLocationSelected ? 'UPDATE_AN_AREA_LOCATION' : 'ADD_AN_AREA_LOCATION',
  );

  return (
    <ModalWrapper
      title={title}
      validateLabel={title}
      cancelLabel={t('CANCEL')}
      onCancel={handleOnCancel}
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      minWidth={'400px'}>
      <InputLabel
        label={t('AREA_LOCATION_CODE')}
        placeholder={t('AREA_LOCATION_CODE_PLACEHOLDER')}
        name="label"
        value={form.label}
        onChange={handleChange}
        required
      />
      <FieldsRequired />
    </ModalWrapper>
  );
};

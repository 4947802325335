import {useQuery} from '@tanstack/react-query';
import {fetchPrintingParameters} from '../../../../../api/fetchPrintingParameters';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {columnsConfigurations} from './columns';

export const Configurations = () => {
  const {data, isLoading} = useQuery(['fetchPrintingParameters'], () =>
    fetchPrintingParameters(),
  );

  const newData = data?.map((item: any) => {
    return {
      brand_has_printer_id: parseInt(item.brand_has_printer_id),
      id: item.id,
      printer_template_id: parseInt(item.printer_template_id),
      highlighted_prod_area_uuids: item.highlighted_prod_area_uuids,
      selected_channels: item.selected_channels,
      selected_consumption_modes: item.selected_consumption_modes,
      label: item.brand_has_printer.label,
      ip_address: item.brand_has_printer.ip_address,
    };
  });

  return (
    <NewDataGrid
      columns={columnsConfigurations}
      rows={newData}
      rowHeight={60}
      loading={isLoading}
    />
  );
};

/* @jsxImportSource @emotion/react */
import {ModalWrapper} from '../atoms/ModalWrapper';
import {deleteUsersGroupModalAction} from '../../redux/modals';
import {useContext} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {TextVariant} from '../atoms/TextVariant';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {THEME} from '../../styles/theme';
import {css} from '@emotion/react';

// TODO : Remove if not used
export const DeleteUsersGroupModal = () => {
  const {t, dispatch} = useContext<any>(UtilsContext);
  /* ON CANCEL */
  const handleOnCancel = () => {
    dispatch(deleteUsersGroupModalAction(false));
  };

  /* HANDLE SUBMIT */
  const handleOnSubmit = () => {
    // console.log('data', data);
  };

  return (
    <ModalWrapper title={t('DELETION')} showBtns={false} minWidth={'350px'} onCancel={handleOnCancel}>
      <div>
        <div css={styles.container}>
          <TextVariant
            variant="textSemiBold"
            text={t('DELETE_USER_GROUP_TEXT')}
            style={styles.deleteText}
          />

          <TextVariant
            variant="title02"
            text="Groupe utilisateur"
            style={styles.groupName}
          />
        </div>

        <div className="flex-center">
          <ButtonCustom
            label={t('CANCEL')}
            backgroundColor={THEME.colors.red}
            width="25%"
            onClick={handleOnCancel}
          />
          <ButtonCustom
            label={t('CONFIRM_DELETION')}
            backgroundColor={THEME.colors.green}
            width="75%"
            padding={'0'}
            type="submit"
            marginLeft={'10px'}
            onClick={handleOnSubmit}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

const styles = {
  container: css({
    padding: '20px 0',
  }),
  deleteText: css({
    textAlign: 'center',
    marginTop: '20px',
    whiteSpace: 'pre-line',
  }),
  groupName: css({
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  }),
};

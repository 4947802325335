/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {useQuery} from '@tanstack/react-query';
import {getPosUsers} from '../../../../../api/fetchPosUsers';
import {columns} from './columns';

export const UsersSection = () => {
  const {data, isLoading} = useQuery(['getPosUsers'], getPosUsers);

  return (
    <div css={styles.container}>
      <NewDataGrid
        rows={data}
        columns={columns}
        loading={isLoading}
        rowHeight={60}
      />
    </div>
  );
};

const styles = {
  container: css({
    height: '100%',
  }),
};

import {IAreaLocation} from '../api/fetchAreas';
import {env} from '../app.config';

export const helperGenerateQrCodeUrl = (
  brandHierachy: {
    brandSubdomain: string;
    companySubdomain: string;
  },
  areaLocation: IAreaLocation,
) => {
  return `${brandHierachy?.brandSubdomain}.${brandHierachy?.companySubdomain}.${
    env.SUBDOMAIN
  }/#/?space=${window.btoa(
    JSON.stringify({
      area_location: areaLocation.label,
    }),
  )}`;
};

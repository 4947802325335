/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {SortFilterDate} from '../atoms/SortFilterDate';
import {DateTypeFilter} from '../atoms/DateTypeFilter';
import {TextVariant} from '../atoms/TextVariant';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {FilterNumberInput} from '../atoms/FilterNumberInput';
import {useAppSelector} from '../../hooks/useReduxHook';
import {useTranslation} from 'react-i18next';

const LIST = [
  {
    label: 'Égal à',
    value: 'equal',
  },
  {
    label: 'Entre',
    value: 'between',
  },
  {
    label: 'Superieur à',
    value: 'superior',
  },
  {
    label: 'Inferieur à',
    value: 'inferior',
  },
];

interface IFilterNumberContainerProps {
  max: number;
  min: number;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  Icon?: React.FC<{color?: string}>;
  onSubmit: () => void;
  iconColor?: string;
  width?: string;
}

export const FilterNumberContainer = ({
  max,
  min,
  onChange,
  Icon,
  onSubmit,
  label = '',
  iconColor,
  width = '13rem',
}: IFilterNumberContainerProps) => {
  const typeFilter = useAppSelector(s => s.date.typeFilterDate);
  const {t} = useTranslation();

  return (
    <div css={styles.container(width)}>
      <SortFilterDate label={t(label)} />
      <DateTypeFilter list={LIST} />
      {typeFilter && LIST.map(el => el.value).includes(typeFilter) && (
        <div className="flex-align-center">
          <FilterNumberInput
            name={typeFilter === 'inferior' ? 'max' : 'min'}
            value={typeFilter === 'inferior' ? max : min}
            onChange={onChange}
            Icon={Icon}
            iconColor={iconColor}
          />
          {typeFilter === 'between' && (
            <>
              <TextVariant
                text="Et"
                variant="textSemiBold"
                style={{
                  margin: '0 1rem',
                }}
              />

              <FilterNumberInput
                name="max"
                value={max}
                onChange={onChange}
                Icon={Icon}
                iconColor={iconColor}
              />
            </>
          )}
        </div>
      )}
      <ButtonCustom
        label={t('APPLY')}
        width={'100%'}
        marginTop={'0.5rem'}
        onClick={onSubmit}
      />
    </div>
  );
};

const styles = {
  container: (width: string | number) =>
    css({
      backgroundColor: 'white',
      padding: 12,
      minWidth: width,
      top: 0,
      left: 0,
    }),
};

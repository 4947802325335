import { BRAND_AUTHORIZATION } from "../constants/frontPolicies";
import { useAppSelector } from "./useReduxHook";

export const useBrandAccess = () => {
  const auth = useAppSelector(s => s.auth.auth);
  const userIsMainBrand = auth?.isMainBrand;

  const brandHasAuthorization = (authorizedBrand: BRAND_AUTHORIZATION = BRAND_AUTHORIZATION.ALL) => {
    return (
      authorizedBrand === BRAND_AUTHORIZATION.ALL ||
      (authorizedBrand === BRAND_AUTHORIZATION.PARENT && userIsMainBrand) ||
      (authorizedBrand === BRAND_AUTHORIZATION.CHILD && !userIsMainBrand)
    );
  }

  return {
    brandHasAuthorization
  };
};

import {axiosInstance} from './axiosInstance';

export const fetchPrinters = async () => {
  const res = await axiosInstance.get('/bcore/protected/backoffice/printers');

  return res?.data?.data;
};

export const createPrinter = async (data: {
  label: string;
  ipAddress: string;
}) => {
  const res = await axiosInstance.post(
    '/bcore/protected/backoffice/printers',
    data,
  );

  return res;
};

export const updatePrinter = async (data: {
  label: string;
  ipAddress: string;
}, printerUuid: string) => {
  const res = await axiosInstance.put(
    `/bcore/protected/backoffice/printers/${printerUuid}`,
    data,
  );

  return res;
};

export const deletePrinter = async (printerUuid: string) => {
  const res = await axiosInstance.delete(
    `/bcore/protected/backoffice/printers/${printerUuid}`,
  );

  return res;
};

import {ToggleButton} from '../../../../components/atoms/ToggleButton';
import {helperDate} from '../../../../helpers/helperDate';
import {
  addGroupModalAction,
  deleteStaffGroupModalAction,
} from '../../../../redux/modals';
import {staffGroupSelectedAction} from '../../../../redux/myStaffSection/groups';
import {useQueryClient} from '@tanstack/react-query';
import {updateStaffGroup} from '../../../../api/fetchStaff';
import {DataGridActions} from '../../../../components/newDatagrid/DataGridActions';
import {IFetchAdminUserDetailsResponse} from '@bill-app-types/b-types';

export const columnsGroups = [
  {
    field: 'label',
    headerName: 'Groupe',
    headerClassName: 'textRegular',
    flex: 1,
  },
  {
    field: 'userCount',
    headerName: 'Utilisateurs',
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter(row: any) {
      return row?.userCount || '--';
    },
  },
  {
    field: 'isArchived',
    headerName: 'Actifs',
    headerClassName: 'textRegular',
    flex: 1,
    renderCell: (row: any) => {
      return <UserGroupToggle row={row} />;
    },
  },
  {
    field: 'createdAt',
    headerName: "Date d'ajout",
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter: (row: any) => {
      return `${helperDate(row.createdAt, 'Le DD/MM/YYYY à HH:mm')}`;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Date de modification',
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter: (row: any) => {
      return `${helperDate(row.updatedAt, 'Le DD/MM/YYYY à HH:mm')}`;
    },
  },
  {
    field: 'actions',
    headerName: '',
    flex: 0,
    renderCell: (row: any) => {
      const handleClickEdit = ({dispatch}: any) => {
        dispatch(staffGroupSelectedAction(row));
        dispatch(addGroupModalAction(true));
      };

      const handleClickDelete = ({dispatch}: any) => {
        dispatch(staffGroupSelectedAction(row));
        dispatch(deleteStaffGroupModalAction(true));
      };

      return (
        <DataGridActions
          handleClickEdit={handleClickEdit}
          handleClickDelete={handleClickDelete}
        />
      );
    },
  },
];

const UserGroupToggle = ({row}: {row: IFetchAdminUserDetailsResponse}) => {
  const clientQuery = useQueryClient();

  const handleToggle = async () => {
    const res = await updateStaffGroup(row.uuid, {
      isArchived: !row.isArchived,
    });

    if (res?.status === 204) {
      clientQuery.invalidateQueries(['fetchStaffGroups']);
    }
  };

  return <ToggleButton active={!row.isArchived} onToggle={handleToggle} />;
};

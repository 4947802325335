/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../atoms/TextVariant';
import {FilterCheckboxItem} from './FilterCheckboxItem';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {useTranslation} from 'react-i18next';
import {IObject} from '../../redux/filter';
import {THEME} from '../../styles/theme';

export const FilterCheckboxs = ({
  title,
  list,
  onChange,
  selectedValues,
  onSubmit,
  isCard,
  maxHeight,
}: {
  title: string;
  list: IObject[];
  onChange: (value: any) => void;
  selectedValues: any[];
  onSubmit?: () => void;
  isCard?: boolean;
  maxHeight?: string | number;
}) => {
  const {t} = useTranslation();

  const totalLength = list?.reduce(
    (acc, item) => acc + (item.childs?.length || 0) + 1,
    0,
  );

  return (
    <div css={styles.container}>
      <TextVariant
        text={title}
        variant="textSemiBold"
        style={{
          marginBottom: '0.5rem',
        }}
      />
      <div id="checkboxs" css={styles.checkboxs(maxHeight)}>
        {list.length > 0 && (
          <FilterCheckboxItem
            entry={{name: isCard ? 'ALL2' : 'ALL', value: 'all'}}
            checked={selectedValues.length === totalLength}
            handleCheckedChange={onChange}
          />
        )}

        {list.map((entry, index) => {
          const checked = !!selectedValues.find(
            item => item.value === entry.value,
          );

          return (
            <div key={index}>
              <FilterCheckboxItem
                entry={entry}
                checked={checked}
                handleCheckedChange={onChange}
                key={index}
                isCard={isCard}
              />
              {entry?.childs && entry?.childs?.length > 0 && (
                <div
                  style={{
                    marginLeft: '1rem',
                  }}>
                  {entry?.childs?.map((child, index) => {
                    const checked = !!selectedValues.find(
                      item => item.value === child.value,
                    );

                    return (
                      <FilterCheckboxItem
                        entry={child}
                        checked={checked}
                        handleCheckedChange={onChange}
                        key={index}
                        isCard={isCard}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>

      <ButtonCustom
        label={t('APPLY')}
        width={'100%'}
        marginTop={'0.5rem'}
        onClick={onSubmit}
      />
    </div>
  );
};

const styles = {
  container: css({
    minWidth: '11.5rem',
    maxWidth: 240,
    background: THEME.colors.white,
    top: '0',
    left: '0',
    padding: 12,
    borderRadius: 2,
  }),
  checkboxs: (maxHeight?: string | number) =>
    css({
      maxHeight,
      overflow: 'auto',
      width: '100%',
    }),
};

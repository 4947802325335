import {
  IFetchPeriodicalPricesQueryParams,
  IPeriodicalPricesPostPayload,
  IPeriodicalPricesPutPayload,
  PeriodicalPriceFetchResponse,
  PeriodicalPricesFetchResponse,
} from '@bill-app-types/b-types';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';
import {API_SERVICES, FOLDERS, PATHS} from '../constants/routes';

const baseUrl = `${API_SERVICES.BCATALOG}${FOLDERS.BACKOFFICE}${PATHS.PERIODICAL_PRICES}`

export const fetchPeriodicalPrices = async (
  data?: IFetchPeriodicalPricesQueryParams,
) => {
  const query = helperGenerateQuery(data);

  const res = await axiosInstance.get<{
    data: PeriodicalPricesFetchResponse[];
  }>(`${baseUrl}${query}`, {
    params: {
      orderBy: JSON.stringify(data?.orderBy),
    },
  });

  return res?.data?.data;
};

export const fetchPeriodicalPrice = async (periodicalPriceUuid: string): Promise<PeriodicalPriceFetchResponse> => {
  const res = await axiosInstance.get<{
    data: PeriodicalPriceFetchResponse;
  }>(`${baseUrl}/${periodicalPriceUuid}`);

  return res?.data?.data;
};

export const createPeriodicalPrice = async (
  data: IPeriodicalPricesPostPayload,
) => {
  const res = await axiosInstance.post(
    `${baseUrl}`,
      data,
    ).catch(err => {
      console.log('❌ createPeriodicalPrice : ', err);
    });
  return res;
};

export const updatePeriodicalPrice = async (
  periodicalPriceUuid: string,
  data: IPeriodicalPricesPutPayload,
) => {
  const res = await axiosInstance.put(
    `${baseUrl}/${periodicalPriceUuid}`,
      data,
    ).catch(err => {
      console.log('❌ updatePeriodicalPrice : ', err);
    });
  return res;
};

export const deletePeriodicalPrice = async (periodicalPriceUuid: string) => {
  const res = await axiosInstance
    .delete(
    `${baseUrl}/${periodicalPriceUuid}`,
    ).catch(err => {
      console.log('❌ deletePeriodicalPrice : ', err);
    });
  return res;
};

export const createPeriodicalPriceHasItem = async (
  periodicalPriceUuid: string,
  itemUuid: string,
) => {
  const res = await axiosInstance
    .post(`${baseUrl}/${periodicalPriceUuid}${PATHS.ITEMS}`, {
      itemUuid,
    })
    .catch(err => {
      console.log('❌ createPeriodicalPriceHasItem : ', err);
    });
  return res;
};

export const deletePeriodicalPriceHasItem = async (
  periodicalPriceUuid: string,
  itemUuid: string,
) => {
  const res = await axiosInstance
    .delete(
      `${baseUrl}/${periodicalPriceUuid}${PATHS.ITEMS}/${itemUuid}`,
    )
    .catch(err => {
      console.log('❌ deletePeriodicalPriceHasItem : ', err);
    });
  return res;
};
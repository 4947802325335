/* @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {EyeIcon} from '../../../../../assets/svgs/EyeIcon';
import {ActionButton} from '../../../../../components/atoms/ActionButton';
import {CompareValues} from '../../../../../components/molecules/CompareValues';
import {PillLabel} from '../../../../../components/molecules/PillLabel';
import {M_SCREEN_DESKTOP} from '../../../../../constants/ResponsiveSize';
import {THEME} from '../../../../../styles/theme';

export const MenusColumns = [
  {
    headerName: 'Menu',
    flex: 1,
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      return (
        <div className="flex" css={styles.menu}>
          <PillLabel
            text={row.itemName}
            variant="textSemiBold"
            backgroundColor={row.color}
            textTransform="capitalize"
          />
        </div>
      );
    },
  },
  {
    headerName: 'CA TTC',
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      return <CompareValues row={row} compareKey="sales" />;
    },
  },
  {
    headerName: 'NB VENTES',
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      return <CompareValues row={row} compareKey="orders" />;
    },
  },
  {
    flex: 0.2,
    headerName: '',
    renderCell: (row: any) => {
      const isOther = row.itemId === 'Autres';
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
          }}>
          {!isOther && (
            <ActionButton
              Icon={EyeIcon}
              margin={2}
              onClick={row.handleDetail}
            />
          )}
        </div>
      );
    },
  },
];

const styles = {
  menu: css({
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: '1rem',
    [M_SCREEN_DESKTOP]: {
      paddingLeft: '0.5rem',
    },
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../../../../../../styles/theme';
import {ActionButton} from '../../../../../../../components/atoms/ActionButton';
import {EditIcon} from '../../../../../../../assets/svgs/EditIcon';
import {TrashIcon} from '../../../../../../../assets/svgs/TrashIcon';
import {EyeIcon} from '../../../../../../../assets/svgs/EyeIcon';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../hooks/useReduxHook';
import {
  addAreaLocationModalAction,
  deleteAreaLocationModalAction,
  viewAreaLocationQRCodeModalAction,
} from '../../../../../../../redux/modals';
import {IAreaLocation} from '../../../../../../../api/fetchAreas';
import {areaLocationSelectedAction} from '../../../../../../../redux/myRestaurantSection/areas';

export const AreaLocation = ({areaLocation}: {areaLocation: IAreaLocation}) => {
  const areaSelected = useAppSelector(
    s => s.myRestaurantSection.areas.areaSelected,
  );
  const dispatch = useAppDispatch();

  /* TODO | HANDLE EDIT ON CLICK */
  const handleEditOnClick = () => {
    dispatch(areaLocationSelectedAction(areaLocation));
    dispatch(addAreaLocationModalAction(true));
  };

  /* HANDLE VIEW ON CLICK */
  const handleViewOnClick = () => {
    dispatch(areaLocationSelectedAction(areaLocation));
    dispatch(viewAreaLocationQRCodeModalAction(true));
  };

  /* TODO | HANDLE DELETE ON CLICK */
  const handleDeleteOnClick = () => {
    dispatch(areaLocationSelectedAction(areaLocation));
    dispatch(deleteAreaLocationModalAction(true));
    console.log('handleDeleteOnClick');
  };

  return (
    <div css={styles.areaLocation}>
      <div css={styles.areaLocationLabel}>
        {areaSelected?.location_label} :
        <span css={styles.areaLocationCode}>{areaLocation?.label}</span>
      </div>
      <div css={styles.areaLocationButtons}>
        <ActionButton Icon={EditIcon} onClick={handleEditOnClick} />
        <ActionButton Icon={EyeIcon} onClick={handleViewOnClick} />
        <ActionButton Icon={TrashIcon} onClick={handleDeleteOnClick} />
      </div>
    </div>
  );
};

const styles = {
  areaLocation: css({
    display: 'flex',
    width: '100%',
    height: '45px',
    backgroundColor: THEME.colors.white,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  }),
  areaLocationLabel: css({
    fontSize: '12px',
  }),
  areaLocationCode: css({
    fontWeight: 'bold',
    fontSize: '16px',
    marginLeft: '5px',
  }),
  areaLocationButtons: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyItems: 'center',
    gap: '5px',
  }),
};

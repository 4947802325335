/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useAppDispatch} from '../../../../../../hooks/useReduxHook';
import {TextVariant} from '../../../../../../components/atoms/TextVariant';
import {CloseIcon} from '../../../../../../assets/svgs/CloseIcon';
import {SqsReceipt} from '../../../../../../api/fetchSqsHistory';
import {selectedArchiveReceiptAction} from '../../../../../../redux/myAccountingSection/archive';

export const TicketHeader = ({
  receiptDetails,
}: {
  receiptDetails: SqsReceipt;
}) => {
  const dispatch = useAppDispatch();

  return (
    <div css={styles.header}>
      <div css={styles.headerContent}>
        <TextVariant
          variant="textSemiBold"
          text={`Ticket ${receiptDetails?.receipt_id}`}
        />
        <p
          css={styles.headerContentClose}
          id="ticketHeaderClose"
          onClick={() => dispatch(selectedArchiveReceiptAction(null))}
          className="flex">
          <CloseIcon />
        </p>
      </div>
    </div>
  );
};

const styles = {
  header: css({
    padding: 10,
    zIndex: 10,
    overflow: 'hidden',
  }),

  headerContent: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.2rem',
  }),

  headerContentClose: css({
    cursor: 'pointer',
    '@media print': {
      display: 'none',
    },
  }),
};

import {FilterButton} from '../../../../../components/atoms/FilterButton';
import {ChannelIdFilter} from '../../../../../components/filters/ChannelIdFilter';
import {ConsumptionModeFilter} from '../../../../../components/filters/ConsumptionModeFilter';
import {useAppSelector} from '../../../../../hooks/useReduxHook';

export const Bottom = () => {
  //
  const dates = useAppSelector(s => s.filter.dates);

  return (
    <>
      <FilterButton
        icon={'calendar'}
        marginRight={'1vh'}
        // width={'32vh'}
        valueType="dates"
        values={dates}
        hideFilterIcon
        dateShowingOptions={{
          showSortFilter: false,
          disabledFilterTypes: ['period'],
        }}
      />
      <ChannelIdFilter marginRight="1vh" />
      <ConsumptionModeFilter />
    </>
  );
};

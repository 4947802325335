import AnimData from './login.json';
import Lottie from 'react-lottie';

export const LoginAnim = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return <Lottie options={defaultOptions} height={508} width={600} />;
};

import {isDevelopment} from '../app.config';

export const helperLog = ({
  message = 'API_CALL',
  type,
  data,
}: {
  message: string;
  type?: string;
  data?: any;
}) => {
  if (type === 'API_CALL') {
    console.log(`### ${message} ###`);
  } else if (type === 'API_ERROR') {
    console.log(`❌ ${message} : ${data}`);
  }
};

export const helperJustLog = (log: any) => {
  if (!isDevelopment) return;
  console.log(log);
};

export const PhoneIcon = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.274 8.0312C9.82564 7.83983 9.3062 7.96559 8.99727 8.34287L8.08961 9.45283C6.83202 8.72288 5.7822 7.67306 5.05225 6.41547L6.15948 5.51054C6.53676 5.20161 6.66525 4.68217 6.47114 4.23381L5.15887 1.17184C4.95383 0.690676 4.43712 0.422754 3.92588 0.53211L0.863913 1.18825C0.360875 1.29487 0 1.74049 0 2.2572C0 8.57798 4.78979 13.7833 10.9383 14.4367C11.2063 14.4641 11.4742 14.4859 11.7476 14.4969H11.7503C11.9171 14.5023 12.0811 14.5078 12.2479 14.5078C12.7646 14.5078 13.2102 14.1469 13.3168 13.6439L13.973 10.5819C14.0823 10.0707 13.8144 9.55398 13.3332 9.34894L10.2713 8.03667L10.274 8.0312ZM12.0702 13.1928C6.17315 13.0971 1.40796 8.33193 1.31501 2.43491L4.02704 1.85258L5.20261 4.59742L4.22114 5.40119C3.72357 5.80854 3.59508 6.51662 3.91768 7.07434C4.76246 8.5315 5.97631 9.74536 7.43348 10.5901C7.99119 10.9127 8.69927 10.7842 9.10662 10.2867L9.91039 9.3052L12.6552 10.4808L12.0702 13.1928Z"
      fill="#A7A7AB"
    />
  </svg>
);

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';

import {Title} from '../../../../components/atoms/Title';
import {useQuery} from '@tanstack/react-query';
import {fetchSuggestionCategories} from '../../../../api/fetchSuggestion';
import {Categories} from './Categories';
import {AbsoluteLoader} from '../../../../components/layouts/AbsoluteLoader';
import {useState} from 'react';

export const RightBlock = () => {
  const [loading, setLoading] = useState(false);

  const {data} = useQuery(['fetchSuggestionCategories'], () =>
    fetchSuggestionCategories(),
  );

  return (
    <>
      <Title title="Catégories de ventes additionnelles" />
      <div css={styles.content}>
        <Categories categories={data || []} setLoading={setLoading} />
      </div>
      {loading && <AbsoluteLoader />}
    </>
  );
};

const styles = {
  content: css({
    paddingTop: 10,
    overflow: 'auto',
    height: '94%',
  }),
};

import axios from 'axios';
import {axiosInstance} from './axiosInstance';
import {env} from '../app.config';
import {cancelRefreshToken} from '../helpers/helperRefreshToken';
import {helperJustLog} from '../helpers/helperLog';



export const login = async (body: {
  email: string;
  password: string;
}) => {
  const res: any = await axiosInstance.post(`/buser/login/users`, body);
  return res?.data?.data;
};

export const renewToken = async () => {
  helperJustLog('### renewToken ###');

  const refreshToken = localStorage.getItem('auth_refresh_token');

  if (!refreshToken) {
    cancelRefreshToken();
  }

  const body = {
    refreshToken: refreshToken,
  };

  const res = await axiosInstance.post('/buser/refresh-token', body);
  const token = res?.data?.data?.newToken?.token;
  const newRefreshToken = res?.data?.data?.newRefreshToken;

  return [token, newRefreshToken];
};

export const swapToken = async (toBrandId: number) => {
  console.log('### swapToken ###');
  const res = await axiosInstance
    .post('/buser/backoffice/users/swap-token', {
      toBrandId,
    })
    .catch(err => {
      console.log('❌ swapToken : ', err);
    });
  return res;
};

export const refreshUserDetails = async (token: string) => {
  helperJustLog('### refreshUserDetails ###');
  const res = await axios
    .post(
      `${env.API.BASE_URL}/buser/backoffice/users/refresh-user-detail`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .catch(err => {
      console.log('❌ refreshUserDetails : ', err);
      return err.response;
    });
  return res;
};

export const postInitPassword = async (password: string, token: string) => {
  helperJustLog('### initPassword ###');
  const res = await axios
    .post(
      `${env.API.BASE_URL}/buser/backoffice/users/init-password`,
      {
        password
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch(err => {
      console.log('❌ initPassword : ', err);
      return err.response;
    });

  return res;
}
import {ModalWrapper} from '../atoms/ModalWrapper';
import {addAccountingModalAction} from '../../redux/modals';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {useContext} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {helperDisplayToast} from '../../helpers/helperToast';
import {
  createBrandAccountingManagement,
  updateBrandAccountingManagement,
} from '../../api/fetchBrandAccount';
import {useQueryClient} from '@tanstack/react-query';
import {useAppSelector} from '../../hooks/useReduxHook';
import {
  updateModeAction,
  updatedAccountAction,
} from '../../redux/myAccountingSection/parameters';

export const AddAccountingModal = () => {
  const {t, dispatch} = useContext<any>(UtilsContext);
  const clientQuery = useQueryClient();

  const updateMode = useAppSelector(
    state => state.myAccountingSection.parameters.updateMode,
  );
  const updatedAccount = useAppSelector(
    state => state.myAccountingSection.parameters.updatedAccount,
  );

  const initialValues = {
    label: '',
    code: null,
    description: '',
  };

  /* ON CANCEL */
  const handleOnCancel = () => {
    dispatch(addAccountingModalAction(false));
    dispatch(updatedAccountAction(null));
    dispatch(updateModeAction(false));
  };

  const handleSuccess = (res: {data: {message: string}}) => {
    handleOnCancel();
    clientQuery.invalidateQueries(['getBrandAccountingManagement']);
    helperDisplayToast('Compte comptable enregistré', true);
  };

  /* HANDLE SUBMIT */
  const handleOnSubmit = async (data: any, {resetForm}: any) => {
    if (data.label && data.code && data.description) {
      if (updateMode) {
        const res = await updateBrandAccountingManagement({
          id: updatedAccount.id,
          body: data,
        });
        if (res && res.status === 200) {
          resetForm();
          handleSuccess(res);
        } else {
          helperDisplayToast(
            'Impossible de modifier le compte comptable',
            false,
          );
        }
      } else {
        const res = await createBrandAccountingManagement(data);
        if (res.status === 200) {
          resetForm();
          handleSuccess(res);
        } else {
          helperDisplayToast("Impossible d'ajouter le compte comptable", false);
        }
      }
    } else {
      helperDisplayToast('Tous les champs doivent être complétés ', false);
    }
  };

  return (
    <ModalWrapper
      title={t('ACCOUNTING_ACCOUNT')}
      validateLabel={updateMode ? 'Modifier le compte' : t('SAVE_ACCOUNTING')}
      cancelLabel={t('CANCEL')}
      onCancel={handleOnCancel}
      onSubmit={handleOnSubmit}
      initialValues={updateMode ? updatedAccount : initialValues}
      minWidth={'400px'}>
      <FieldCustomInput label={t('ACCOUNT_NAME')} name="label" required />
      <FieldCustomInput label={t('ACCOUNT_CODE')} name="code" required />
      <FieldCustomInput label={'Desription'} name="description" required />
      <FieldsRequired />
    </ModalWrapper>
  );
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {ActionButton} from '../../../../../../../components/atoms/ActionButton';
import {EditIcon} from '../../../../../../../assets/svgs/EditIcon';
import {TrashIcon} from '../../../../../../../assets/svgs/TrashIcon';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../hooks/useReduxHook';
import {
  treePreRegisterAction,
  updateModeAction,
  updatedPosCategoryAction,
} from '../../../../../../../redux/myModulesSection/pos';
import {addPosCategoryModalAction} from '../../../../../../../redux/modals';
import {helperLabelTranslation} from '../../../../../../../helpers/helperLabel';
import {helperConfirmDialog} from '../../../../../../../helpers/helperConfirmDialog';
import {deletePosCategory} from '../../../../../../../api/fetchPosCategories';
import {helperDisplayToast} from '../../../../../../../helpers/helperToast';
import {getStructureDetails} from '../../../../../../../api/fetchStructures';
import {useTranslation} from 'react-i18next';
import {useBrandAccess} from '../../../../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../../../../constants/frontPolicies';

const Right = ({category}: {category: any}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {brandHasAuthorization} = useBrandAccess();
  const hasParentAuthorization = brandHasAuthorization(
    BRAND_AUTHORIZATION.PARENT,
  );

  const treePreRegister = useAppSelector(
    s => s.myModulesSection.pos.treePreRegister,
  );

  const handleEdit = () => {
    dispatch(
      updatedPosCategoryAction({
        ...category,
        label: helperLabelTranslation(category),
      }),
    );
    dispatch(updateModeAction(true));
    dispatch(addPosCategoryModalAction(true));
  };

  const handleDelete = () => {
    helperConfirmDialog({
      title: t('DELETE_CATEGORY'),
      text: helperLabelTranslation(category),
      onSubmit: async () => {
        const res = await deletePosCategory(category.uuid);
        if (res && res.status === 204) {
          helperDisplayToast('Catégorie supprimée', true);
          const data = await getStructureDetails(treePreRegister?.uuid);
          if (data) {
            dispatch(treePreRegisterAction(data));
          }
        } else {
          helperDisplayToast(
            res.data?.message || 'Impossible de supprimer la catégorie',
            false,
          );
        }
      },
    });
  };

  return (
    <div css={styles.container}>
      <ActionButton
        Icon={EditIcon}
        onClick={handleEdit}
        disabled={!hasParentAuthorization}
      />
      <ActionButton
        Icon={TrashIcon}
        onClick={handleDelete}
        disabled={!hasParentAuthorization}
      />
    </div>
  );
};

export default Right;

const styles = {
  container: css({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.25rem',
    gap: '0.25rem',
  }),
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {useContext} from 'react';
import {FieldCustomInput} from '../../../../components/atoms/FieldCustomInput';
import {HeaderForm} from '../../../../components/atoms/HeaderForm';
import {UtilsContext} from '../../../../contexts/UtilsContext';

export const DriveObjectives = () => {
  const {t} = useContext<any>(UtilsContext);

  return (
    <>
      <HeaderForm title={t('OBJECTIVES')} />
      <div className="flex" css={styles.form}>
        <div css={styles.formCol}>
          <FieldCustomInput
            label={t('NUMBER_OF_VEHICLES_PER_HOUR')}
            type="number"
            name="numberOfVehiclesPerHour"
          />
          <FieldCustomInput
            label={t('NUMBER_OF_VEHICLES_PER_DAY')}
            type="number"
            name="numberOfVehiclesPerDay"
          />
          <FieldCustomInput
            label={t('NUMBER_OF_VEHICLES_PER_WEEK')}
            type="number"
            name="numberOfVehiclesPerWeek"
          />
          <FieldCustomInput
            label={t('NUMBER_OF_VEHICLES_PER_MONTH')}
            type="number"
            name="numberOfVehiclesPerMonth"
          />
        </div>

        <div css={styles.formCol}>
          <FieldCustomInput label={t('ORDERING_TIME')} name="orderingTime" />
          <FieldCustomInput label={t('SERVICE_TIME')} name="serviceTime" />
          <FieldCustomInput
            label={t('TIME_TO_TAKE_PAYMENT')}
            name="timeToTakePayment"
          />
          <FieldCustomInput label={t('WAITING_TIME')} name="waitingTime" />
        </div>

        <div css={styles.formCol}>
          <FieldCustomInput
            label={t('WAITING_TIME_FOR_SHEDDING')}
            name="waitingTimeShedding"
          />
          <FieldCustomInput label="OEPE" name="oepe" />
          <FieldCustomInput label="TTL" name="ttl" />
        </div>
      </div>
    </>
  );
};

const styles = {
  form: css({
    gap: '1rem',
    alignItems: 'flex-start',
  }),
  formCol: css({
    width: '33%',
  }),
};

/** @jsxImportSource @emotion/react */
import {IFetchStructuresReturn} from '@bill-app-types/b-types';
import {
  deleteStructure,
  duplicateStructure,
  getStructureDetails,
} from '../../../../../api/fetchStructures';
import {DataGridActions} from '../../../../../components/newDatagrid/DataGridActions';
import {DataGridColumn} from '../../../../../components/newDatagrid/NewDataGrid';
import {helperConfirmDialog} from '../../../../../helpers/helperConfirmDialog';
import {helperDate} from '../../../../../helpers/helperDate';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {
  resetSelectedButtonsAction,
  selectedPosCategoryAction,
  treePreRegisterAction,
} from '../../../../../redux/myModulesSection/pos';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';

export const columns: DataGridColumn<IFetchStructuresReturn> = [
  {
    field: 'label',
    headerName: "Nom de l'arborescence",
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: "Date d'ajout",
    flex: 1,
    valueGetter: row => {
      return helperDate(row?.createdAt, 'Le DD/MM/YYYY à HH:mm');
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Date de modification',
    flex: 1,
    valueGetter: row => {
      return helperDate(row?.updatedAt, 'Le DD/MM/YYYY à HH:mm');
    },
  },
  {
    field: 'actions',
    headerName: '',
    align: 'right',
    flex: 0.5,
    renderCell: row => {
      const handleClickDelete = ({refreshing}: any) => {
        helperConfirmDialog({
          onSubmit: async () => {
            const res = await deleteStructure(row.uuid);
            if (res && res.status === 204) {
              helperDisplayToast('Arborescence supprimée', true);
              refreshing('fetchStructures');
            } else {
              helperDisplayToast(
                res?.data?.message || "Impossible de supprimer l'arborescence",
                false,
              );
            }
          },
        });
      };

      const handleClickCopy = ({refreshing}: any) => {
        helperConfirmDialog({
          validateLabel: 'Dupliquer',
          onSubmit: async () => {
            const res = await duplicateStructure(row.uuid);
            if (res && res.status === 200) {
              helperDisplayToast('Arborescence dupliquée', true);
              refreshing('fetchStructures');
            } else {
              helperDisplayToast(
                res?.data?.message || "Impossible de dupliquer l'arborescence",
                false,
              );
            }
          },
        });
      };

      const handleClickEdit = async ({dispatch, navigate}: any) => {
        const data = await getStructureDetails(row.uuid);
        if (data) {
          dispatch(treePreRegisterAction(data));
          dispatch(selectedPosCategoryAction(null));
          dispatch(resetSelectedButtonsAction());
          navigate('/online/modules/bpos/tree/edit');
        }
      };

      return (
        <DataGridActions
          handleClickEdit={handleClickEdit}
          handleClickCopy={handleClickCopy}
          handleClickDelete={handleClickDelete}
          showCopy
          authorization={{
            copy: BRAND_AUTHORIZATION.PARENT,
            delete: BRAND_AUTHORIZATION.PARENT,
          }}
        />
      );
    },
  },
];

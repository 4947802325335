/** @jsxImportSource @emotion/react  */
import {helperDate} from '../../../../../helpers/helperDate';
import {helperConfirmDialog} from '../../../../../helpers/helperConfirmDialog';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {
  deleteStructure,
  duplicateStructure,
} from '../../../../../api/fetchStructures';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {DataGridActions} from '../../../../../components/newDatagrid/DataGridActions';
import {IFetchStructuresReturn} from '@bill-app-types/b-types';
import {DataGridColumn} from '../../../../../components/newDatagrid/NewDataGrid';

export const columnsStructureList: DataGridColumn<IFetchStructuresReturn> = [
  {
    field: 'label',
    headerName: 'Arborescence',
    headerClassName: 'textRegular',
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Type',
    headerClassName: 'textRegular',
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: "Date d'ajout",
    headerClassName: 'textRegular',
    flex: 0.8,
    valueGetter: row => {
      return helperDate(row.createdAt, 'Le DD/MM/YYYY à HH:mm');
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Date de modification',
    headerClassName: 'textRegular',
    flex: 0.8,
    valueGetter: row => {
      return helperDate(row.updatedAt, 'Le DD/MM/YYYY à HH:mm');
    },
  },
  {
    field: 'actions',
    headerName: '',
    flex: 0.4,
    renderCell: row => {
      const handleClickCopy = ({refreshing}: any) => {
        helperConfirmDialog({
          validateLabel: 'Dupliquer',
          onSubmit: async () => {
            const res = await duplicateStructure(row.uuid);
            if (res && res.status === 200) {
              helperDisplayToast('Arborescence dupliquée', true);
              refreshing('fetchStructures');
            } else {
              helperDisplayToast(
                res?.data?.message || "Impossible de dupliquer l'arborescence",
                false,
              );
            }
          },
        });
      };

      const handleClickDelete = ({refreshing}: any) => {
        helperConfirmDialog({
          onSubmit: async () => {
            const res = await deleteStructure(row.uuid);
            if (res && res.status === 204) {
              helperDisplayToast('Arborescence supprimée', true);
              refreshing('fetchStructures');
            } else {
              helperDisplayToast(
                res?.data?.message || "Impossible de supprimer l'arborescence",
                false,
              );
            }
          },
        });
      };

      return (
        <DataGridActions
          handleClickCopy={handleClickCopy}
          handleClickDelete={handleClickDelete}
          refreshing={'fetchStructures'}
          navigate={`/online/catalog/tree/${row?.uuid}`}
          authorization={{
            edit: BRAND_AUTHORIZATION.ALL,
            copy: BRAND_AUTHORIZATION.PARENT,
            delete: BRAND_AUTHORIZATION.PARENT,
          }}
          showCopy
        />
      );
    },
  },
];

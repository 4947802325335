import {SortableElement} from 'react-sortable-hoc';
import {THandleDragAndDrop} from '../organisms/SortableListVerticalContainer';

export const SortableVerticalItem = SortableElement(
  ({
    item,
    ItemComponent,
    handleDragAndDrop,
    idx,
  }: {
    item: any;
    ItemComponent: any;
    handleDragAndDrop: THandleDragAndDrop;
    idx: number;
  }) => {
    return (
      <ItemComponent
        item={item}
        index={idx}
        handleDragAndDrop={handleDragAndDrop}
      />
    );
  },
);

import {SelectedStructure} from './SelectedStructure';
import {Structures} from './Structures';

export const MyStructureRouter = {
  path: 'tree',
  children: [
    {
      path: '',
      element: <Structures />,
    },
    {
      path: ':uuid',
      element: <SelectedStructure />,
    },
  ],
};

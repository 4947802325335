export const ArrowDownIcon = () => (
  <svg
    width="9"
    height="10"
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.93182 5.79296L4.72348 9.23046C4.63564 9.32225 4.51723 9.37499 4.39119 9.37499C4.26515 9.37499 4.14598 9.32249 4.05928 9.22972L0.850947 5.79221C0.676781 5.58788 0.68335 5.30858 0.866569 5.12889C1.04799 4.95007 1.33923 4.95679 1.51473 5.14476L3.93286 7.73632V1.09413C3.93286 0.835339 4.13815 0.625183 4.374 0.625183C4.60985 0.625183 4.84952 0.835339 4.84952 1.09413V7.73671L7.26723 5.14491C7.44209 4.95692 7.7334 4.9502 7.91539 5.12904C8.09987 5.30858 8.1056 5.58788 7.93182 5.79296Z"
      fill="white"
    />
  </svg>
);

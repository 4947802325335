import {useTranslation} from 'react-i18next';
import {HeaderTabsLinks} from '../../../../../components/molecules/HeaderTabsLinks';
import {filterAction} from '../../../../../redux/filter';
import {useAppDispatch} from '../../../../../hooks/useReduxHook';

export const Top = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const handleClick = () => {
    dispatch(
      filterAction({
        name: 'search',
        values: '',
      }),
    );
  };

  return (
    <div style={{marginTop: '1.65rem'}}>
      <HeaderTabsLinks
        data={[
          {
            label: t('EVENT'),
            link: '',
          },
          {
            label: t('PRICE_RULES'),
            link: '/prices',
          },
        ]}
        path="offers/price-rules"
        onClick={handleClick}
      />
    </div>
  );
};

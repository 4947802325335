/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Header} from './Header';
import {Body} from './Body';
import {ITranslationCommon} from '../../../../../api/fetchTranslations';

export const TranslationItem = (props: {
  title: string;
  items: ITranslationCommon[];
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onToggleShowAll?: (v: boolean) => void;
  showAll?: boolean;
  isLoading?: boolean;
  isOpened: boolean;
  onToggleIsOpened?: () => void;
  category?: string;
  categoryType?: number;
  showHeader?: boolean;
  fields?: string[];
}) => {
  const {title, isOpened, onToggleIsOpened} = props;

  return (
    <div css={styles.container}>
      <Header title={title} isOpened={isOpened} onClick={onToggleIsOpened} />
      <Body {...props} />
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
    backgroundColor: 'var(--bg-color)',
  }),
};

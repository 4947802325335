/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Title} from '../../../../../components/atoms/Title';
import {helperFormatPrice} from '../../../../../helpers/helperFormatPrice';
import {helperObjectToArray} from '../../../../../helpers/helperObjectToArray';
import {THEME} from '../../../../../styles/theme';
import {CHANNELS} from '@bill-app-types/b-types';
import {useTranslation} from 'react-i18next';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';

export const DaysCAByChannelSection = ({
  data,
  isLoading = false,
}: {
  data: any;
  isLoading: boolean;
}) => {
  const {t} = useTranslation();

  const columns = [
    {
      field: 'channel',
      headerName: 'Canal',
      headerClassName: 'textRegular',
      flex: 1,
      renderCell: (row: any) => {
        const channel = parseInt(row?.channel);
        return <span>{t(CHANNELS[channel])}</span>;
      },
    },
    {
      field: 'ht',
      headerName: 'Montant HT',
      headerClassName: 'textRegular',
      flex: 2,
      renderCell: (row: any) => {
        const amount = row.HT;
        return <span>{helperFormatPrice(amount)}€</span>;
      },
    },
    {
      field: 'tva',
      headerName: 'Montant TVA',
      headerClassName: 'textRegular',
      flex: 2,
      renderCell: (row: any) => {
        const amount = row.TVA;
        return <span>{helperFormatPrice(amount)}€</span>;
      },
    },
    {
      field: 'ttc',
      headerName: 'Montant TTC',
      headerClassName: 'textRegular',
      flex: 1,
      renderCell: (row: any) => {
        const amount = row.TTC;
        return (
          <span style={{color: THEME.colors.green}}>
            {helperFormatPrice(amount)}€
          </span>
        );
      },
    },
  ];

  return (
    <div css={styles.container}>
      <Title title={"Chiffre d'affaires par canal de commande"} />
      <NewDataGrid
        rows={data ? helperObjectToArray(data, 'channel') : []}
        columns={columns}
        rowHeight={35}
        loading={isLoading}
      />
    </div>
  );
};

const styles = {
  container: css({
    // flex: 2,
    paddingTop: '2vh',
    marginTop: '2vh',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {SquareButton} from '../../../../components/atoms/SquareButton';
import {PrinterIcon} from '../../../../assets/svgs/PrinterIcon';
import {SendIcon} from '../../../../assets/svgs/SendIcon';
import {SaveIcon} from '../../../../assets/svgs/SaveIcon';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {ExportDayDetailPDF} from '../../../../components/templates/ExportDayDetailPDF';

export const TicketFooter = ({data}: {data: any}) => {
  //
  const handlePrint = () => {
    window.print();
  };

  return (
    <div css={styles.footer} id="ticketFooter">
      <SquareButton onClick={handlePrint} marginRight={5}>
        <PrinterIcon />
      </SquareButton>
      <PDFDownloadLink document={<ExportDayDetailPDF data={data} />}>
        <SquareButton marginRight={5}>
          <SaveIcon />
        </SquareButton>
      </PDFDownloadLink>
      <SquareButton>
        <SendIcon />
      </SquareButton>
    </div>
  );
};

const styles = {
  footer: css({
    padding: 10,
    textAlign: 'end',
    height: '7%',
    '@media print': {
      display: 'none',
    },
  }),
};

import {useState} from 'react';
import {DeleteModal} from './DeleteModal';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {deletePrinterConfigModalAction} from '../../redux/modals';
import {useTranslation} from 'react-i18next';
import {configPrinterSelectedAction} from '../../redux/myRestaurantSection/printers';
import {deletePrintingParameter} from '../../api/fetchPrintingParameters';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';

export const DeletePrinterConfigModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const clientQuery = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const configPrinterSelected = useAppSelector(
    s => s.myRestaurantSection.printers.configPrinterSelected,
  );

  const handleCancel = () => {
    dispatch(configPrinterSelectedAction(null));
    dispatch(deletePrinterConfigModalAction(false));
  };
  const handleSubmit = async () => {
    if (configPrinterSelected) {
      try {
        const res = await deletePrintingParameter(configPrinterSelected?.id);
        if (res && res.status === 200) {
          clientQuery.invalidateQueries(['fetchPrintingParameters']);
          handleCancel();
        } else {
          helperDisplayToast('Impossible de supprimer la configuration', false);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (!configPrinterSelected) {
    return null;
  }

  return (
    <DeleteModal
      title={t('DELETE_PRINTER')}
      text={configPrinterSelected?.label || ''}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    />
  );
};

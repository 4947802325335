/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext} from 'react';
import {ButtonCustom} from '../../../../../../components/atoms/ButtonCustom';
import {ToggleButtonSections} from '../../../../../../components/molecules/ToggleButtonSections';
import {UtilsContext} from '../../../../../../contexts/UtilsContext';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {
  addOptionModalAction,
  optionCategoryModalAction,
} from '../../../../../../redux/modals';
import {BRAND_AUTHORIZATION} from '../../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../../hooks/useBrandAccess';

export const Center = () => {
  const {t, dispatch} = useContext<any>(UtilsContext);

  const showSection = useAppSelector(s => s.app.sectionShow);

  const {brandHasAuthorization} = useBrandAccess();

  const sections = ['OPTION_CATEGORY', 'OPTIONS'];

  const handleOpenAddOptionCategoryModal = () => {
    dispatch(optionCategoryModalAction(true));
  };

  const handleOpenAddOptionModal = () => {
    dispatch(addOptionModalAction(true));
  };

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <div css={styles.container} className="flex-space-between">
      <ToggleButtonSections
        sections={sections}
        defaultSection={'OPTION_CATEGORY'}
      />
      <div className="flex">
        {showSection === 'OPTION_CATEGORY' ? (
          <ButtonCustom
            label={t('ADD_AN_OPTION_CATEGORY')}
            onClick={handleOpenAddOptionCategoryModal}
            disabled={!hasAuthorization}
          />
        ) : (
          <ButtonCustom
            label={t('ADD_AN_OPTION')}
            onClick={handleOpenAddOptionModal}
            disabled={!hasAuthorization}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
    marginTop: '3vh',
    marginBottom: '2vh',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useQuery} from '@tanstack/react-query';
import {TicketContent} from '../../../../components/ticket/TicketContent';
import {Loader} from '../../../../components/atoms/Loader';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {THEME} from '../../../../styles/theme';
import {
  getOrderDetails,
  getOrderDetailsV2,
  getOrderLoyaltyGains,
} from '../../../../api/fetchOrders';
import {useFlagBCatalog} from '../../../../hooks/useFlagBCatalog';

export const CustomerTicket = ({
  TicketHeader,
  TicketFooter,
}: {
  TicketHeader?: any;
  TicketFooter?: any;
}) => {
  //
  const auth = useAppSelector(s => s.auth.auth);
  const selectedOrder = useAppSelector(s => s.customer.selectedOrder);
  const selectedCustomer = useAppSelector(s => s.customer.selectedCustomer);
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {data: orderV1, isLoading: isLoadingV1} = useQuery(
    ['getOrderDetails-V1', selectedOrder],
    () =>
      selectedOrder?.id
        ? getOrderDetails(auth?.brandId, selectedOrder?.id)
        : null,
    {
      enabled:
        !!selectedOrder?.id &&
        !!selectedCustomer &&
        !flagBCatalogEnabled &&
        !!flagBCatalogReady,
    },
  );

  const {data: orderV2, isLoading: isLoadingV2} = useQuery(
    ['getOrderDetails-V2', selectedOrder],
    () => (selectedOrder?.uuid ? getOrderDetailsV2(selectedOrder?.uuid) : null),
    {
      enabled:
        !!selectedOrder?.uuid &&
        !!selectedCustomer &&
        flagBCatalogEnabled &&
        !!flagBCatalogReady,
    },
  );

  const data = flagBCatalogEnabled ? orderV2 : orderV1;
  const isLoading = flagBCatalogEnabled ? isLoadingV2 : isLoadingV1;

  const {data: loyalty, isLoading: IsLoyaltyLoading} = useQuery(
    ['getOrderLoyaltyGains', selectedOrder],
    () =>
      selectedOrder
        ? getOrderLoyaltyGains(selectedCustomer?.uuid, selectedOrder?.uuid)
        : null,
    {
      enabled: !!selectedOrder && !!selectedCustomer,
    },
  );

  return (
    <div css={styles.ticket}>
      {isLoading || IsLoyaltyLoading ? (
        <div className="flex-center" css={styles.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {TicketHeader && <TicketHeader order={data} />}

          {/* Scroll part */}
          <TicketContent data={data} />
          {TicketFooter && <TicketFooter order={data} loyalty={loyalty} />}
        </>
      )}
    </div>
  );
};

const styles = {
  ticket: css({
    backgroundColor: 'var(--bg-color)',
    fontSize: '20px',
    height: '100%',
    width: '24%',
    color: 'var(--text-color)',
    alignSelf: 'flex-end',
    border: `1px solid ${THEME.colors.lightGray}`,
    marginRight: '1%',
    flexDirection: 'column',
    display: 'flex',
  }),
  loaderContainer: css({
    flex: 1,
    margin: 'auto',
    alignSelf: 'auto',
    flexDirection: 'column',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext} from 'react';
import {DownLoadIcon} from '../../assets/svgs/DownloadIcon';
import {PictureIcon} from '../../assets/svgs/PictureIcon';
import {THEME} from '../../styles/theme';
import {UtilsContext} from '../../contexts/UtilsContext';
import {TextVariant} from './TextVariant';

export const PictureResolution = ({
  height,
  width,
  Icon = PictureIcon,
}: {
  height: string | number;
  width: string | number;
  Icon?: any;
}) => {
  const {t} = useContext<any>(UtilsContext);

  return (
    <div className="flex-col-center">
      <Icon />
      <div css={styles.pictureResolution}>
        <TextVariant text={t('RECOMMANDED_RESOLUTION')} variant="title03" />
        <TextVariant text={`${width} x ${height}`} variant="title03" />
      </div>
      <DownLoadIcon />
    </div>
  );
};

const styles = {
  pictureResolution: css({
    marginTop: '10px',
    marginBottom: '15px',
    textAlign: 'center',
    color: THEME.colors.gray,
  }),
};

import {useTranslation} from 'react-i18next';
import {DeleteModal} from './DeleteModal';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {deleteRecipeModalAction} from '../../redux/modals';
import {recipeSelectedAction} from '../../redux/myCatalogSection/recipes';
import {useState} from 'react';
import {deleteRecipe} from '../../api/fetchRecipes';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';

export const DeleteRecipeModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);

  const clientQuery = useQueryClient();

  const recipeSelected = useAppSelector(
    s => s.myCatalogSection.recipes.recipeSelected,
  );

  const handleSubmit = async () => {
    setLoading(true);
    if (recipeSelected) {
      const res = await deleteRecipe(recipeSelected.uuid);

      if (res && [200, 204].includes(res.status)) {
        clientQuery.invalidateQueries(['fetchRecipes']);
        helperDisplayToast('Ingredient supprimée avec succès', true);
        handleCancel();
      } else {
        helperDisplayToast('Une erreur est survenue', false);
      }
    }
    setLoading(false);
  };

  const handleCancel = () => {
    dispatch(deleteRecipeModalAction(false));
    dispatch(recipeSelectedAction(null));
  };

  if (!recipeSelected) return null;

  return (
    <DeleteModal
      text={recipeSelected?.label}
      title={t('DELETE_RECIPE')}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      isLoading={loading}
    />
  );
};

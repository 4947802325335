import {SVGProps} from 'react';

export const SalesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.17174 6.96429C0.662864 6.96429 0.25 6.60603 0.25 6.16071C0.25 5.7154 0.662864 5.35714 1.17174 5.35714H2.4499C3.50683 2.25904 6.79822 0 10.6964 0H11.6182C12.129 0 12.5399 0.359933 12.5399 0.803571C12.5399 1.24721 12.129 1.60714 11.6182 1.60714H10.6964C7.83134 1.60714 5.38104 3.16071 4.36328 5.35714H10.3892C10.9 5.35714 11.3109 5.7154 11.3109 6.16071C11.3109 6.60603 10.9 6.96429 10.3892 6.96429H3.96386C3.94466 7.14174 3.93698 7.3192 3.93698 7.5C3.93698 7.6808 3.94466 7.85826 3.96386 8.03571H10.3892C10.9 8.03571 11.3109 8.39397 11.3109 8.83929C11.3109 9.2846 10.9 9.64286 10.3892 9.64286H4.36328C5.38104 11.8393 7.83134 13.3929 10.6964 13.3929H11.6182C12.129 13.3929 12.5399 13.7511 12.5399 14.1964C12.5399 14.6417 12.129 15 11.6182 15H10.6964C6.79822 15 3.50683 12.74 2.4499 9.64286H1.17174C0.662864 9.64286 0.25 9.2846 0.25 8.83929C0.25 8.39397 0.662864 8.03571 1.17174 8.03571H2.11499C2.10078 7.85826 2.09349 7.6808 2.09349 7.5C2.09349 7.3192 2.10078 7.14174 2.11499 6.96429H1.17174Z"
      fill={props.color}
    />
  </svg>
);

import {useContext} from 'react';
import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {getTotalsByTva} from '../api/fetchCompta';
import {UtilsContext} from '../contexts/UtilsContext';
import {helperJoinFilterValues} from '../helpers/helperFilter';
import {helperFormatPrice} from '../helpers/helperFormatPrice';
import {exportDataAction} from '../redux/exportPdfData';
import {useAppSelector} from './useReduxHook';

export const useTotalTva = () => {
  const dates = useAppSelector(s => s.filter.dates);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const channels = useAppSelector(s => s.filter.channels);
  const {dispatch} = useContext<any>(UtilsContext);

  const {data, isLoading, error} = useQuery(
    ['totalTva', dates, consumptionModes, channels],
    () =>
      getTotalsByTva({
        ...dates,
        consumptionModes: helperJoinFilterValues(consumptionModes, true),
        channels: helperJoinFilterValues(channels, true),
      }),
  );

  useEffect(() => {
    if (data && data.amountByTva) {
      dispatch(
        exportDataAction({
          name: 'totalTva',
          values: {
            ...data,
            amountByTva: data?.amountByTva.map((item: any) => ({
              tax: `Taux ${helperFormatPrice(item.tax.amount, 'fr-DE')}%`,
              totalHT: helperFormatPrice(item.totalHT, 'de-DE'),
              totalTVA: helperFormatPrice(item.totalTVA, 'de-DE'),
              totalTTC: helperFormatPrice(item.totalTTC, 'de-DE'),
            })),
          },
        }),
      );
    }
  }, [data, dispatch]);

  return {
    totalTva: data,
    totalTvaLoading: isLoading,
    totalTvaError: error,
  };
};

import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {THEME} from '../../../../../styles/theme';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {multiSelectionAction} from '../../../../../redux/customer';
import {addCustomerLoyaltiesModalAction} from '../../../../../redux/modals';
import {useCustomerFilter} from '../../../../../hooks/useCustomerFilter';
import {exportCustomersCsv} from '../../../../../api/fetchCustomers';
import {useState} from 'react';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {t} from 'i18next';
import {helperDownloadFile} from '../../../../../helpers/helperDownloadFile';

export const Center = () => {
  //
  const dispatch = useAppDispatch();

  const multiSelection = useAppSelector(s => s.customer.multiSelection);
  const checkedCustomers = useAppSelector(s => s.customer.checkedCustomers);
  const [loading, setLoading] = useState(false);

  const customerFilter = useCustomerFilter();

  const handleClickSelection = () => {
    dispatch(multiSelectionAction(!multiSelection));
  };

  const handleClickLoyalties = () => {
    dispatch(addCustomerLoyaltiesModalAction(true));
  };

  const handleClickExport = async () => {
    setLoading(true);
    const {customerActive, ...filter} = customerFilter;
    const res = await exportCustomersCsv({...filter, isActive: true});
    if (res.status === 200) {
      const data = await res.data;
      const filename = `export_customers`;
      helperDownloadFile({data, fileName: filename, type: 'csv'});
    } else helperDisplayToast(t('NO_DATA_TO_EXPORT'));

    setLoading(false);
  };

  return (
    <>
      {multiSelection && checkedCustomers?.length > 0 && (
        <ButtonCustom
          label={'Créditer des points de fidélité'}
          backgroundColor={THEME.colors.yellow}
          marginRight={'1vh'}
          onClick={handleClickLoyalties}
        />
      )}
      <ButtonCustom
        label={
          multiSelection
            ? 'Désactiver la multi-sélection'
            : 'Activer la multi-sélection'
        }
        backgroundColor={multiSelection ? THEME.colors.red : THEME.colors.green}
        marginRight={'1vh'}
        onClick={handleClickSelection}
      />
      <ButtonCustom
        label={'Export des données xlsx des clients actifs'}
        isLoading={loading}
        onClick={handleClickExport}
      />
    </>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../atoms/TextVariant';

export const TicketContentHeader = ({
  data,
  isReceipt = false,
}: {
  data: any;
  isReceipt?: boolean;
}) => {
  return (
    <div css={styles.headerList}>
      <div css={[styles.headCol, {width: '10%'}]}>
        <TextVariant text="Qté" variant="title03" style={styles.headerTitle} />
      </div>
      <div css={[styles.headCol, {width: '45%'}]}>
        <TextVariant
          text="Désignation"
          variant="title03"
          style={styles.headerTitle}
        />
      </div>
      <div css={styles.headCol}>
        <TextVariant text="P.U" variant="title03" style={styles.headerTitle} />
      </div>
      {isReceipt && (
        <div css={[styles.headCol, {width: '10%'}]}>
          <TextVariant
            text="TVA"
            variant="title03"
            style={styles.headerTitle}
          />
        </div>
      )}
      <div css={[styles.headCol, {width: '20%', textAlign: 'right'}]}>
        <TextVariant
          text="Total"
          variant="title03"
          style={styles.headerTitle}
        />
      </div>
    </div>
  );
};

const styles = {
  title: css({
    fontSize: 12,
    fontWeight: '600',
  }),

  subTitle: css({
    paddingLeft: 10,
    marginBottom: 5,
  }),

  footerInfos: css({
    padding: 20,
    textAlign: 'center',
  }),

  transaction: css({
    borderBottom: '1px solid var(--bg-main-color)',
    padding: '10px 10px 10px 20px',
    display: 'flex',
    justifyContent: 'space-between',

    '&:first-of-type': {
      borderTop: '1px solid var(--bg-main-color)',
    },
  }),
  sectionFlex: css({
    padding: '5px 10px 5px 20px',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  headCol: css({
    padding: '5px 15px',
    width: '15%',
  }),
  headerTitle: css({
    fontSize: 10,
    fontWeight: '700',
    color: '#A7A7AB',
  }),
  headerList: css({
    padding: '0px 5px',
    display: 'flex',
    borderBottom: '1px solid var(--bg-main-color)',
    justifyContent: 'space-between',
  }),
  scrollView: ({isReceipt = false}: {isReceipt?: boolean}) =>
    css({
      overflowY: 'scroll',
      overflowX: 'hidden',
      height: isReceipt ? '58%' : '65%',
      '@media print': {
        height: 'auto',
      },
    }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Center} from './HeaderSection/Center';
import {Header} from '../../../../../components/layouts/Header';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {useQuery} from '@tanstack/react-query';
import {fetchStructures} from '../../../../../api/fetchStructures';
import {columnsStructureList} from './columns';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {AddStructureModal} from '../../../../../components/modals/AddStructureModal';
import {STRUCTURE_TYPES} from '@bill-app-types/b-types';
import {useTranslation} from 'react-i18next';
import {Bottom} from './HeaderSection/Bottom';
import {useDebounce} from '../../../../../hooks/useDebounce';

export const Structures = withRedirectAndNavigation(() => {
  //
  const {t} = useTranslation();

  const addStructureModal = useAppSelector(s => s.modals.addStructureModal);
  const search = useAppSelector(s => s.filter.search);
  const orderBy = useAppSelector(s => s.filter.orderBy);

  const debouncedSearchTerm = useDebounce(search, 500);

  const {data, isLoading} = useQuery(
    ['fetchStructures', orderBy, debouncedSearchTerm],
    () =>
      fetchStructures({
        search: debouncedSearchTerm,
        type: [STRUCTURE_TYPES.FRONT, STRUCTURE_TYPES.EXTERNAL],
      }),
  );

  const newData = data?.map(item => ({
    ...item,
    type: t(
      item.type === STRUCTURE_TYPES.FRONT
        ? 'STRUCTURE_FRONT'
        : item.type === STRUCTURE_TYPES.EXTERNAL
        ? 'STRUCTURE_EXTERNAL'
        : '',
    ),
  }));

  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} bottomSection={<Bottom />} />
      <div css={styles.content}>
        <NewDataGrid
          rows={newData}
          columns={columnsStructureList}
          rowHeight={88}
          loading={isLoading}
        />
        {addStructureModal && <AddStructureModal />}
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: css({
    padding: '2vh',
    height: '87%',
    display: 'flex',
    justifyContent: 'space-between',
  }),
};

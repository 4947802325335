/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useNavigate, useParams} from 'react-router-dom';
import {ModuleHeader} from '../ModuleHeader';
import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {moduleSelectedAction} from '../../../../redux/myModulesSection/subscriptions';
import {ToggleButtonSections} from '../../../../components/molecules/ToggleButtonSections';
import {ButtonCustom} from '../../../../components/atoms/ButtonCustom';
import {TreesSection} from './TreesSection';
import {UsersSection} from './Users';
import {useBrandAccess} from '../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../constants/frontPolicies';
import {TerminalsSection} from './Terminals';
import {EntitiesSection} from './Entities';
import {helperPosModule} from '../../../../helpers/helperPosModule';
import {useFlagBCatalog} from '../../../../hooks/useFlagBCatalog';
import {PARENT_DEFAULT_SCREEN} from '../../../../constants/global';

const BPosSection = () => {
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {pathname} = useParams();
  const {brandHasAuthorization} = useBrandAccess();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const showSection = useAppSelector(s => s.app.sectionShow);
  const subscriptions = useAppSelector(
    s => s.myModulesSection.subscriptions.subscriptions,
  );

  const hasChildAuthorization = brandHasAuthorization(
    BRAND_AUTHORIZATION.CHILD,
  );

  const hasParentAuthorization = brandHasAuthorization(
    BRAND_AUTHORIZATION.PARENT,
  );

  useEffect(() => {
    if (pathname !== 'bpos') {
      navigate('/online/modules');
    } else {
      const module = subscriptions?.find((item: any) => item.code === 'b_pos');

      dispatch(moduleSelectedAction(module));
    }
  }, [pathname, navigate, subscriptions, dispatch]);

  const sections = hasChildAuthorization
    ? ['TREES', 'USERS', 'TERMINALS', 'ENTITIES']
    : ['TREES'];

  const sectionHelper = helperPosModule(showSection);

  if (sections.length === 1 && !flagBCatalogEnabled && flagBCatalogReady) {
    window.location.href = PARENT_DEFAULT_SCREEN;
    return null;
  }

  return (
    <>
      <ModuleHeader
        moduleActiveName={String(pathname)}
        hideButtons
        marginBottom={'2vh'}
      />
      <div css={styles.container}>
        <div css={styles.header} className="flex-space-between">
          <ToggleButtonSections
            sections={
              flagBCatalogEnabled
                ? sections
                : sections.filter(item => item !== 'TREES')
            }
            defaultSection={flagBCatalogEnabled ? 'TREES' : 'USERS'}
          />

          {((hasParentAuthorization && showSection === 'TREES') ||
            showSection !== 'TREES') && (
            <ButtonCustom
              label={sectionHelper?.section}
              onClick={() => dispatch(sectionHelper?.modalAction(true))}
            />
          )}
        </div>
        {flagBCatalogEnabled && showSection === 'TREES' && <TreesSection />}
        {showSection === 'USERS' && <UsersSection />}
        {showSection === 'TERMINALS' && <TerminalsSection />}
        {showSection === 'ENTITIES' && <EntitiesSection />}
      </div>
    </>
  );
};

export default BPosSection;

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    height: '79vh',
  }),
  header: css({
    padding: '2vh 0',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {SalesPieChartList} from '../../../../../components/organisms/SalesPieChartList';
import {DetailsByProductColumns} from '../DataColumns/DetailsByProductColumns';
import {exportSaleByProduct} from '../../../../../api/fetchStats';
import {useState} from 'react';
import {useStatItems} from '../../../../../hooks/useStatItems';
import {PRODUCT_TYPES} from '@bill-app-types/b-types';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {useNavigate} from 'react-router-dom';
import {CardFilter} from '../../../../../components/filters/CardFilter';
import {VendorStructureFilter} from '../../../../../components/filters/VendorStructureFilter';
import {SearchFilter} from '../../../../../components/filters/SearchFilter';
import {useSearch} from '../../../../../hooks/useSearch';
import {M_SCREEN_DESKTOP} from '../../../../../constants/ResponsiveSize';

export const DetailsByProducts = () => {
  const navigate = useNavigate();
  const compareDates = useAppSelector(s => s.filter.compareDates);
  const isCompare = useAppSelector(s => s.filter.isCompare);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const vendorStructures = useAppSelector(
    state => state.filter.vendorStructure,
  );
  const [limit, setLimit] = useState(10);
  const {search, handleChangeSearch, debounceValue} = useSearch();

  const {data, isLoading} = useStatItems({
    limit,
    consumptionModes,
    vendorStructures,
    types: [PRODUCT_TYPES.COURSE],
    search: debounceValue,
  });

  const {data: dataCompare} = useStatItems({
    limit,
    types: [PRODUCT_TYPES.COURSE],
    dates: compareDates,
    enabled: !!isCompare,
    consumptionModes,
    vendorStructures,
    search: debounceValue,
  });

  const dataWithCompare = data?.map(item => {
    const findItem = dataCompare?.find(_item => _item.itemId === item.itemId);

    return {
      ...item,
      forCompareOrders: isCompare
        ? findItem?.orders || {
            value: 0,
            percent: 0,
          }
        : null,
      forCompareSales: isCompare
        ? findItem?.sales || {
            TTC: {
              total_price: 0,
              total_percent: 0,
            },
          }
        : null,

      handleDetail: () => {
        navigate(`detail/${item.itemId}`);
      },
    };
  });

  const totalLimit = data?.length || 0;

  const handleExport = async () => {
    await exportSaleByProduct({
      from: new Date(),
      to: new Date(),
    });
  };

  const handleUpdateLimit = (type: 'incr' | 'decr') => {
    if (type === 'incr') {
      setLimit(_limit => _limit + 10);
    } else {
      setLimit(_limit => (_limit >= 20 ? _limit - 10 : 10));
    }
  };

  return (
    <SalesPieChartList
      data={dataWithCompare || []}
      title="DETAIL_BY_PRODUCTS"
      showPieChart={false}
      columns={DetailsByProductColumns}
      marginTop={'1.5rem'}
      handleExport={handleExport}
      isLoading={isLoading}
      Filters={
        <div css={styles.filters} className="flex">
          <CardFilter withBorder />
          <VendorStructureFilter withBorder />
          <SearchFilter
            onChange={handleChangeSearch}
            value={search}
            withBorder
          />
        </div>
      }
      hasPagination
      paginationShowMore
      totalLimit={totalLimit}
      limit={limit}
      updateLimit={handleUpdateLimit}
    />
  );
};

const styles = {
  filters: css({
    marginLeft: 'auto',
    flexWrap: 'wrap',
    [M_SCREEN_DESKTOP]: {
      marginTop: '1vh',
    },
  }),
};

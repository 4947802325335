/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useState} from 'react';
import {CustomerDetailsInfos} from './CustomerDetailsInfos';
import {CustomerDetailsOrders} from './CustomerDetailsOrders';
import {THEME} from '../../../../styles/theme';

export const CustomerDetails = () => {
  const [section, setSection] = useState('informations');

  return (
    <div css={styles.container}>
      <div className="flex-space-between textSemiBold">
        <button
          className="textSemiBold"
          onClick={() => setSection('informations')}
          css={styles.button({isActive: section === 'informations'})}>
          Informations
        </button>
        <button
          className="textSemiBold"
          onClick={() => setSection('orders')}
          css={styles.button({isActive: section === 'orders'})}>
          Commandes
        </button>
      </div>
      <>
        {section === 'informations' && <CustomerDetailsInfos />}
        {section === 'orders' && <CustomerDetailsOrders />}
      </>
    </div>
  );
};

const styles = {
  container: css({
    padding: '1.5% 2%',
    height: '47vh',
  }),
  button: ({isActive = false}: {isActive?: boolean}) =>
    css({
      backgroundColor: isActive ? THEME.colors.primary : THEME.colors.white,
      color: isActive ? THEME.colors.white : THEME.colors.gray,
      cursor: 'pointer',
      border: `1px solid ${
        isActive ? THEME.colors.primary : THEME.colors.bgLight
      }`,
      borderRadius: 2,
      height: 29,
      width: '49%',
    }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {addPosUserModalAction} from '../../redux/modals';
import {FieldsRequired} from '../atoms/FieldsRequired';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {ToggleButtonLabel} from '../molecules/ToggleButtonLabel';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';

import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {useEffect, useState} from 'react';
import {
  updateModeAction,
  updatedPosUserAction,
} from '../../redux/myModulesSection/pos';
import {createPosUser, updatePosUser} from '../../api/fetchPosUsers';
import {InputLabel} from '../atoms/InputLabel';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {helperGeneratePin} from '../../helpers/helperGeneratePin';

export const AddPosUserModal = () => {
  const initialValues = {
    username: '',
  };
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();

  const [form, setForm] = useState({
    pin: '',
    isManager: false,
  });

  const updateMode = useAppSelector(
    state => state.myModulesSection.pos.updateMode,
  );
  const updatedPosUser = useAppSelector(
    state => state.myModulesSection.pos.updatedPosUser,
  );

  useEffect(() => {
    if (updatedPosUser) {
      setForm({
        ...form,
        isManager: updatedPosUser.isManager,
      });
    }
  }, [updatedPosUser]);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;
    if (
      name === 'pin' &&
      (isNaN(Number(value)) || value.length > 4 || ['e', '-'].includes(value))
    ) {
      return;
    }
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleOnCancel = () => {
    dispatch(addPosUserModalAction(false));
    dispatch(updatedPosUserAction(null));
    dispatch(updateModeAction(false));
  };

  const handleSuccess = (res: {data: {message: string}}) => {
    handleOnCancel();
    helperDisplayToast(res?.data?.message || 'Utilisateur enregistré', true);
  };

  const handleSubmit = async (
    data: {
      username: string;
    },
    {setSubmitting, resetForm}: any,
  ) => {
    if (data.username && form.pin) {
      if (!form.pin || form.pin.length < 4)
        return helperDisplayToast(
          'Veuillez saisir un code pin de minimum 4 caractères',
          false,
        );
      let res;
      if (updateMode) {
        res = await updatePosUser(updatedPosUser.uuid, {
          ...data,
          username: data.username,
          pin: form.pin,
          isManager: form.isManager,
        });
      } else {
        res = await createPosUser({
          ...data,
          username: data.username,
          pin: form.pin,
          isManager: form.isManager,
        });
      }
      if (res && res.status === 204) {
        handleSuccess(res);
        resetForm();
        clientQuery.invalidateQueries(['getPosUsers']);
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible de créer l'utilsateur",
          false,
        );
      }
    } else {
      helperDisplayToast('Tous les champs doivent être complétés', false);
    }
  };

  const handleGeneratePin = () => {
    setForm({
      ...form,
      pin: helperGeneratePin(4),
    });
  };

  return (
    <ModalWrapper
      title={updateMode ? "Modifier l'utilisateur" : 'Ajouter un utilisateur'}
      minWidth="28%"
      validateLabel={"Enregistrer l'utilisateur"}
      cancelLabel={t(updateMode ? 'Annuler la modification' : 'CANCEL_CREATE')}
      onCancel={handleOnCancel}
      onSubmit={handleSubmit}
      initialValues={updateMode ? updatedPosUser : initialValues}>
      <FieldCustomInput
        label={"Nom de l'utilisateur"}
        name="username"
        required
      />
      <div className="flex" css={styles.pin}>
        <InputLabel
          label={'Code pin'}
          name="pin"
          onChange={handleChange}
          type="text"
          value={form.pin}
          required
        />
        <ButtonCustom
          onClick={handleGeneratePin}
          label="Générer un code"
          marginBottom={10}
        />
      </div>
      <FieldsRequired />
      <ToggleButtonLabel
        label="Accès manager"
        marginBottom={'0.5rem'}
        active={updatedPosUser?.isManager || form.isManager}
        onToggle={() =>
          setForm({
            ...form,
            isManager: !form.isManager,
          })
        }
      />
    </ModalWrapper>
  );
};

const styles = {
  pin: css({
    alignItems: 'flex-end',
    gap: 10,
  }),
};

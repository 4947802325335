/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';

export const HorizontalBar = ({color = 'red'}: {color?: string}) => {
  return <div css={styles.container(color)} />;
};

const styles = {
  container: (color: string) =>
    css({
      backgroundColor: color,
      width: 9,
      height: 'auto',
      borderRadius: '2px 0px 0px 2px',
    }),
};

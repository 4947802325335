import {ITimings} from '../api/fetchSubscription';
import {helperUnderTen} from './helperUnderTen';

interface ITime {
  start: string | null;
  end: string | null;
}

export const helperFormatSubscriptionTimings = (
  days: {
    name: string;
    dates: {id: number; start: string; end: string}[];
  }[],
) => {
  let timings: ITimings = {};

  for (let i = 0; i < days.length; i++) {
    if (days[i].dates.length > 0) {
      const dayIndex = i === 0 ? 6 : i - 1;

      if (days[dayIndex]) {
        timings[i] = days[dayIndex].dates
          .map(date => ({
            start: Number(date.start?.replace(':', '')),
            end: Number(date.end?.replace(':', '')),
          }))
          ?.filter(date => date.start && date.end);
      }
    }
  }

  return timings;
};

/* HELPER FORMAT TIME START AND END */
export const helperFormatTimeStartAndEnd = (time: {
  start: number;
  end: number;
}): ITime => {
  if (!time) {
    return {start: null, end: null};
  }

  const startTime = '' + time.start;
  const endTime = '' + time.end;

  const startTimeHour =
    startTime.length === 3
      ? startTime[0]
      : startTime.length <= 2
      ? '0'
      : startTime.slice(0, 2);
  const startTimeMinute = startTime.slice(-2);

  const placeEndHour =
    endTime.length === 3
      ? endTime[0]
      : endTime.length <= 2
      ? '0'
      : endTime.slice(0, 2);
  const endTimeMinute = endTime.slice(-2);

  return {
    start: formatTime(startTimeHour, startTimeMinute),
    end: formatTime(placeEndHour, endTimeMinute),
  };
};

/* FORMAT TIME */
const formatTime = (hour: string, minute: string): string => {
  return helperUnderTen(hour) + ':' + helperUnderTen(minute);
};

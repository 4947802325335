/** @jsxImportSource @emotion/react */
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import {css} from '@emotion/react';
import {Footer} from './Footer';
import {THEME} from '../../../styles/theme';
import {CustomGraphsTooltip} from './CustomGraphsToolTip';
import {
  helperCombinedCompareSalesData,
  helperSalesData,
} from '../../../helpers/helperSalesData';
import {IStatGlobal} from '../../../api/fetchStats';
import {useAppSelector} from '../../../hooks/useReduxHook';
import {PillLabel} from '../../molecules/PillLabel';
import {useTranslation} from 'react-i18next';

export const SalesGraphs = ({
  data,
  dataCompare,
}: {
  data: IStatGlobal | undefined;
  dataCompare?: IStatGlobal | undefined;
}) => {
  const isCompare = useAppSelector(s => s.filter.isCompare);
  const datas = helperSalesData(data);

  const combinedDataFormatted = isCompare
    ? helperCombinedCompareSalesData(data, dataCompare)
    : [];

  const salesGraphSection = useAppSelector(
    s => s.myStatsSection.sales.salesGraphSection,
  );
  const {t} = useTranslation();

  return (
    <>
      <div css={styles.pills} className="flex">
        {salesGraphSection === 'TURNOVER' && (
          <PillLabel
            text={t('TURNOVER')}
            backgroundColor={THEME.colors.primary}
          />
        )}
        {salesGraphSection === 'ORDERS' && (
          <PillLabel
            text={t('NUMBER_OF_ORDERS')}
            backgroundColor={THEME.colors.yellowDark}
          />
        )}
        {salesGraphSection === 'MEDIUM' && (
          <PillLabel
            text={t('MEDIUM_BASKET')}
            backgroundColor={THEME.colors.green}
          />
        )}
      </div>

      <div css={styles.container}>
        <div css={styles.content}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={
                isCompare
                  ? combinedDataFormatted?.sort(
                      (a: any, b: any) => b.x - a.x,
                    ) || []
                  : datas
              }
              margin={{
                top: 5,
                right: 30,
                bottom: 5,
              }}>
              <CartesianGrid strokeDasharray="3 3" stroke="#EEF1F6" />
              <XAxis
                dataKey="x"
                className="textSemiBold"
                fontSize={isCompare ? 7 : 9}
                color="#B4B4B4"
                fontWeight={'bold'}
                angle={-30}
                interval={0}
                tickMargin={15}
                height={40}
              />
              <YAxis
                className="textSemiBold"
                fontSize={10}
                color="#B4B4B4"
                fontWeight={'bold'}
              />
              <Tooltip
                content={props => (
                  <CustomGraphsTooltip {...props} mustBeFormated />
                )}
              />
              {salesGraphSection === 'TURNOVER' && (
                <Line
                  dataKey="sales"
                  stroke={THEME.colors.primary}
                  fill={THEME.colors.primary}
                  strokeWidth={2}
                  activeDot={{r: 8}}
                  type={'monotone'}
                />
              )}

              {salesGraphSection === 'TURNOVER' && (
                <Line
                  dataKey="forCompareSales"
                  stroke={THEME.colors.lightGray}
                  fill={THEME.colors.lightGray}
                  strokeWidth={2}
                  activeDot={{r: 8}}
                  type={'monotone'}
                />
              )}

              {salesGraphSection === 'ORDERS' && (
                <Line
                  dataKey="orders"
                  stroke={THEME.colors.yellowDark}
                  fill={THEME.colors.yellowDark}
                  strokeWidth={2}
                  activeDot={{r: 8}}
                  type={'monotone'}
                />
              )}

              {salesGraphSection === 'ORDERS' && (
                <Line
                  dataKey="forCompareOrders"
                  stroke={THEME.colors.lightGray}
                  fill={THEME.colors.lightGray}
                  strokeWidth={2}
                  activeDot={{r: 8}}
                  type={'monotone'}
                />
              )}

              {salesGraphSection === 'MEDIUM' && (
                <Line
                  dataKey="averageCart"
                  stroke={THEME.colors.green}
                  fill={THEME.colors.green}
                  strokeWidth={2}
                  activeDot={{r: 8}}
                  type={'monotone'}
                />
              )}

              {salesGraphSection === 'MEDIUM' && (
                <Line
                  dataKey="forCompareAverageCart"
                  stroke={THEME.colors.lightGray}
                  fill={THEME.colors.lightGray}
                  strokeWidth={2}
                  activeDot={{r: 8}}
                  type={'monotone'}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>

        <Footer data={data} dataCompare={dataCompare} />
      </div>
    </>
  );
};

const styles = {
  container: css({
    margin: '2rem 0',
  }),
  pills: css({
    marginLeft: '2vh',
  }),
  content: css({
    overflow: 'hidden',
    height: '20rem',
  }),
};

/** @jsxImportSource @emotion/react */
import {Title} from '../../../../components/atoms/Title';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {useStaffGroups} from '../../../../hooks/useStaffGroups';
import {columnsGroups} from './column';

export const List = () => {
  const {staffGroupsData} = useStaffGroups();

  return (
    <div css={styles.section}>
      <Title title="Groupes" />
      <div css={styles.content}>
        <NewDataGrid
          columns={columnsGroups}
          rows={staffGroupsData || []}
          rowHeight={60}
          checkBoxSelection={false}
        />
      </div>
    </div>
  );
};

const styles = {
  section: {
    width: '100%',
  },
  content: {
    overflow: 'auto',
    height: '100%',
  },
};

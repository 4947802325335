import {CONSUMPTION_MODE} from '@bill-app-types/b-types';
import {THEME} from '../styles/theme';
import {STATS_COLORS} from '../constants/global';

export const helperGetStatColorById = (index: CONSUMPTION_MODE) => {  
  return STATS_COLORS[index] || helperGenerateColor() || THEME.colors.primary;
};

export const helperGetConsumptionNameById = (
  consumptionModeId: CONSUMPTION_MODE,
) => {
  switch (consumptionModeId) {
    case 1:
      return 'SUR PLACE';
    case 2:
      return 'A EMPORTER';
    default:
      return 'LIVRAISON';
  }
};

export const helperGetConsumptionById = (id: number) => {
  switch (id) {
    case 1:
      return THEME.colors.green;
    case 2:
      return THEME.colors.orange;
    case 3:
    default:
      return THEME.colors.red;
  }
};

export const helperGenerateColor = () => {
  return `#${Math.floor(Math.random()*16777215).toString(16)}`;
}
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {BOfficeIcon} from './Icons/BOfficeIcon';
import {THEME} from '../../../styles/theme';
import {Version} from '../../../components/atoms/Version';

export const FormHeader = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const {t} = useTranslation();

  return (
    <div css={styles.formHeader}>
      <BOfficeIcon />
      <Version />

      <div css={styles.formHeaderTitle}>
        <h2>{t(title)}</h2>
        <p>{t(description)}</p>
      </div>
    </div>
  );
};

const styles = {
  formHeader: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  version: css({
    fontSize: '10px',
    fontFamily: 'Montserrat-Medium',
    color: THEME.colors.bgDark,
    textDecoration: 'underline',
    marginTop: '5px',
  }),
  formHeaderTitle: css(`
      text-align: center;
      margin: 1.8rem 0;
      h2 {
        font-size: 18px;
        font-family: "Montserrat-SemiBold", sans-serif;
        line-height: 1.6;
      }
  
      p {
        font-size: 12px;
        color: #626262;
      }
  
    `),
};

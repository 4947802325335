import {useContext} from 'react';
import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {THEME} from '../../../../../styles/theme';

export const Center = ({handleSubmit, isSubmitting}: any) => {
  const {t} = useContext<any>(UtilsContext);

  return (
    <>
      <ButtonCustom
        label={t('SAVE')}
        backgroundColor={THEME.colors.green}
        onClick={handleSubmit}
        isLoading={isSubmitting}
      />
    </>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useQuery} from '@tanstack/react-query';
import {getLanguages} from '../../../../api/fetchLanguages';
import {Title} from '../../../../components/atoms/Title';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {LanguageCard} from './LanguageCard';
import {getBrandLanguages} from '../../../../api/fetchBrandInfos';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {useEffect} from 'react';
import {initSelectedLanguagesAction} from '../../../../redux/languages';
import {useDispatch} from 'react-redux';

export const Languages = withRedirectAndNavigation(() => {
  //
  const dispatch = useDispatch();
  const brand = useAppSelector(s => s.brand.brand);
  const {data} = useQuery(['languages'], getLanguages);
  const {data: brandLanguages} = useQuery(
    ['getBrandLanguages', brand.id],
    () => getBrandLanguages(brand.id),
    {
      enabled: !!brand.id,
    },
  );

  useEffect(() => {
    if (brandLanguages) {
      dispatch(initSelectedLanguagesAction(brandLanguages));
    }
  }, [brandLanguages]);

  return (
    <div css={styles.container}>
      <Header />
      <div css={styles.content}>
        <Title title="Sélection des langues" />
        <div css={styles.contain}>
          {data?.map((language: any, index: number) => (
            <LanguageCard language={language} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: css({
    padding: '2vh',
    width: '80%',
    alignSelf: 'center',
    margin: 'auto',
  }),
  contain: css({
    display: 'flex',
  }),
};

/** @jsxImportSource @emotion/react */
import {CONSUMPTION_MODE} from '@bill-app-types/b-types';
import {useContext, useEffect, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {
  IObject,
  initFilterConsumptionModeAction,
  resetFilterConsumptionModesAction,
} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
import {FilterCheckboxs} from './FilterCheckboxs';

interface IProps {
  withBorder?: boolean;
  marginRight?: string | number;
}

export const ConsumptionModeFilter = ({
  withBorder = false,
  marginRight = '1vh',
}: IProps) => {
  const consumptionModes = useAppSelector(
    state => state.filter.consumptionModes,
  );
  const {dispatch} = useContext<any>(UtilsContext);

  const [consumptionModesSelected, setConsumptionModesSelected] = useState<
    IObject[]
  >([]);
  const [forceClose, setForceClose] = useState(false);

  const consumptionModesList = Object.entries(CONSUMPTION_MODE)
    .slice(3)
    .map(entry => ({
      name: entry[0],
      value: entry[1],
    }));

  const handleResetFilterConsumptionMode = () => {
    dispatch(resetFilterConsumptionModesAction());
  };

  const handleCheckedChange = (e: any) => {
    if (e.target.name === 'ALL') {
      if (consumptionModesSelected.length === consumptionModesList.length) {
        setConsumptionModesSelected([]);
      } else {
        setConsumptionModesSelected(consumptionModesList);
      }
    } else {
      if (
        !!consumptionModesSelected.find(
          channel => channel.name === e.target.name,
        )
      ) {
        setConsumptionModesSelected(
          consumptionModesSelected.filter(
            channel => channel.name !== e.target.name,
          ),
        );
      } else {
        setConsumptionModesSelected([
          ...consumptionModesSelected,
          {
            name: e.target.name,
            value: e.target.value,
          },
        ]);
      }
    }
  };

  const handleSubmit = () => {
    //
    dispatch(initFilterConsumptionModeAction(consumptionModesSelected));
    setForceClose(true);
  };

  useEffect(() => {
    setConsumptionModesSelected(consumptionModes);
    return () => {
      setForceClose(false);
    };
  }, [consumptionModes]);

  return (
    <FilterButton
      marginRight={marginRight}
      label={'Modes de consommation'}
      valueType="list"
      values={consumptionModes}
      showResetButton={consumptionModes?.length > 0}
      withBorder={withBorder}
      onReset={handleResetFilterConsumptionMode}
      hideFilterIcon={consumptionModes?.length > 0}
      forceClose={forceClose}>
      <FilterCheckboxs
        title="Modes de consommation"
        list={consumptionModesList}
        selectedValues={consumptionModesSelected}
        onChange={handleCheckedChange}
        onSubmit={handleSubmit}
      />
    </FilterButton>
  );
};

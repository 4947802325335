import {css, Global} from '@emotion/react';
import {THEME} from '../../styles/theme';

export const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: Montserrat-ExtraBold;
          src: url('/fonts/Montserrat-ExtraBold.ttf') format('truetype');
        }

        @font-face {
          font-family: Montserrat-Medium;
          src: url('/fonts/Montserrat-Medium.ttf') format('truetype');
        }

        @font-face {
          font-family: Montserrat-SemiBold;
          src: url('/fonts/Montserrat-SemiBold.ttf') format('truetype');
        }
        @font-face {
          font-family: Montserrat-Bold;
          src: url('/fonts/Montserrat-Bold.ttf') format('truetype');
        }
        @font-face {
          font-family: Montserrat-Regular;
          src: url('/fonts/Montserrat-Regular.ttf') format('truetype');
        }

        body {
          margin: 0;
          font-family: Montserrat-Regular, sans-serif;
          scrollbar-width: thin;
          scrollbar-color: auto;
        }
        *,
        ::before,
        ::after {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          outline: none;
        }

        a {
          text-decoration: none;
        }

        .bigTitle {
          font-size: 20px;
          font-weight: 900;
          font-family: Montserrat-ExtraBold, sans-serif;
        }

        .title01 {
        }

        .title02 {
          font-size: 18px;
          font-family: Montserrat-SemiBold, sans-serif;
        }
        .title03 {
          font-size: 12px;
          font-weight: 600;
          font-family: Montserrat-Medium, sans-serif;
        }
        .textBold {
          font-size: 10px;
          font-family: Montserrat-Bold, sans-serif;
        }
        .textSemiBold {
          font-size: 12px;
          font-weight: 600;
          font-family: Montserrat-SemiBold, sans-serif;
        }
        .textMedium {
          font-size: 14px;
          font-weight: 500;
          font-family: Montserrat-Medium, sans-serif;
        }
        .textMedium2 {
          font-size: 8px;
          font-weight: 600;
          font-family: Montserrat-SemiBold, sans-serif;
        }

        .textRegular {
          font-size: 12px;
          font-family: Montserrat-Medium, sans-serif;
        }

        .textLabel {
          display: block;
          font-size: 14px;
          padding: 0 10px;
          color: #626262;
          margin-bottom: 5px;
          font-weight: 500;
          margin-top: 15px;
          font-family: Montserrat-Medium, sans-serif;
        }

        .loader {
          display: block;
          margin: 0 auto;
          border: 3px solid white;
          border-radius: 50%;
          border-top: 3px solid transparent;
          width: 25px;
          height: 25px;
          animation: spin 1s linear infinite;
        }

        .flex {
          display: flex;
        }

        .flex-center {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .flex-wrap {
          display: flex;
          flex-wrap: wrap;
        }

        .flex-align-center {
          display: flex;
          align-items: center;
        }

        .flex-space-between {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .flex-space-around {
          display: flex;
          justify-content: space-around;
          align-items: center;
        }

        .flex-col-space-between {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        }

        .flex-col-space-around {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
        }

        .flex-col {
          display: flex;
          flex-direction: column;
        }

        .flex-col-center {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .flex-align-end {
          display: flex;
          align-items: flex-end;
        }

        .flex-justify-end {
          display: flex;
          justify-content: flex-end;
        }

        .hide-scroll-bar {
          scrollbar-width: none;
          scrollbar-width: none;
          -ms-overflow-style: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .full-height {
          height: 100%;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        ::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          background: ${THEME.colors.bgLight};
        }
        ::-webkit-scrollbar-track {
          background: ${THEME.colors.white};
        }
        ::-webkit-scrollbar-thumb {
          background: ${THEME.colors.primary};
          cursor: pointer;
        }

        :root {
          --bg-color: #fff;
          --bg-main-color: #eef1f6;
          --text-color: ${THEME.colors.bgDark};
          --sidebar-bg-color: ${THEME.colors.bgDark};
          --sidebar-color: ${THEME.colors.white};
        }

        [data-theme='dark'] {
          --bg-color: #222b45;
          --bg-main-color: #171b31;
          --text-color: #fff;
          --sidebar-bg-color: #222b45;
          --sidebar-color: ${THEME.colors.white};
        }

        .react-datepicker__time-list-item--selected,
        .react-datepicker__day--selected {
          background-color: ${THEME.colors.primary}!important;
        }
        .dialog-overlay {
          background: rgba(21, 26, 48, 0.4);
          duration: 0s;
        }
        .horizontal-slider {
          background: white;
          width: 100%;
          height: 15px;
          border-radius: 25px;
        }
        .example-thumb {
          background: white;
          width: 15px;
          height: 15px;
          font-size: 10px;
          font-size: 11px;
          font-weight: 600;
          font-family: Montserrat-SemiBold, sans-serif;
          border: 2px solid ${THEME.colors.primary};
          border-radius: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .example-track-1 {
          height: 15px;
          border-radius: 25px;
          background: ${THEME.colors.primary};
        }
        .d-none {
          display: none;
        }

        .d-block {
          display: block;
        }
        input: hover,
        select: hover,
        button:hover {
          opacity: 0.6;
        }
      `}
    />
  );
};

import {ICreateItemHasModifierPostionsPayload, IFetchItemHasModifiersResponse} from '@bill-app-types/b-types';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {helperLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';

interface ICreateItemSubmenu {
  modifierUuid: string;
  position: number;
}

interface ICreateItemAdditional {
  suggestionCategoryUuid: string;
  position: number;
}

export const deleteItem = async (itemUuid: string) => {
  //
  console.log('### deleteItem ###');
  const res: any = await axiosInstance
    .delete(`/bcatalog/backoffice/items/${itemUuid}`)
    .catch(err => {
      console.log('❌ deleteItem : ', err);
    });
  return res;
};

export const getItemRecipes = async (itemUuid: string) => {
  helperLog({message: 'getItemRecipes'});

  const res: any = await axiosInstance
    .get(`/bcatalog/backoffice/items/${itemUuid}/recipes`)
    .catch(err => {
      helperLog({message: 'getItemRecipes', type: 'API_ERROR', data: err});
    });

  return res?.data?.data;
};

export const createItemRecipe = async (
  itemUuid: string,
  recipeUuid: string,
) => {
  helperLog({message: 'createItemRecipe'});

  const res: any = await axiosInstance
    .post(`/bcatalog/backoffice/items/${itemUuid}/recipes`, {
      recipeUuid,
      quantity: 1,
    })
    .catch(err => {
      helperLog({message: 'createItemRecipe', type: 'API_ERROR', data: err});
    });

  return res;
};

export const deleteItemRecipe = async (
  itemUuid: string,
  recipeUuid: string,
) => {
  helperLog({message: 'deleteItemRecipe'});

  const res: any = await axiosInstance
    .delete(`/bcatalog/backoffice/items/${itemUuid}/recipes/${recipeUuid}`)
    .catch(err => {
      helperLog({message: 'deleteItemRecipe', type: 'API_ERROR', data: err});
    });

  return res;
};

export const getItemModifiers = async (
  itemUuid: string,
  data?: {
    optionTypes?: number[];
  },
) => {
  //
  console.log('### getItemModifiers ###');

  const query = helperGenerateQuery(data);

  const res = await axiosInstance
    .get<{
      data: IFetchItemHasModifiersResponse;
    }>(`/bcatalog/backoffice/items/${itemUuid}/modifiers${query}`)
    .catch(err => {
      console.log('❌ getItemModifiers : ', err);
    });
  return res?.data?.data;
};

export const getItemCards = async (itemUuid: string) => {
  //
  console.log('### getItemCards ###');

  const res: any = await axiosInstance
    .get(`/bcatalog/backoffice/items/${itemUuid}/cards`)
    .catch(err => {
      console.log('❌ getItemCards : ', err);
    });
  return res?.data?.data;
};

export const getItemCategories = async (itemUuid: string) => {
  //
  console.log('### getItemCategories ###');

  const res: any = await axiosInstance
    .get(`/bcatalog/backoffice/items/${itemUuid}/categories`)
    .catch(err => {
      console.log('❌ getItemCategories : ', err);
    });
  return res?.data?.data;
};

export const getItemPosCategories = async (itemUuid: string) => {
  //
  console.log('### getItemPosCategories ###');

  const res: any = await axiosInstance
    .get(`/bcatalog/backoffice/items/${itemUuid}/pos-categories`)
    .catch(err => {
      console.log('❌ getItemPosCategories : ', err);
    });
  return res?.data?.data;
};

export const createItemModifier = async (
  itemUuid: string,
  data: ICreateItemSubmenu[],
) => {
  //
  const res: any = await axiosInstance
    .post(`/bcatalog/backoffice/items/${itemUuid}/modifiers`, data)
    .catch(err => {
      console.log('❌ createItemModifiers : ', err);
    });
  return res;
};

export const updateItemModifier = async (
  itemUuid: string,
  itemUuids: ICreateItemHasModifierPostionsPayload,
) => {
  const res: any = await axiosInstance
    .put(`/bcatalog/backoffice/items/${itemUuid}/modifiers/positions`, itemUuids)
    .catch(err => {
      console.log('❌ deleteItemModifier : ', err);
    });
  return res;
};

export const deleteItemModifier = async (
  itemUuid: string,
  modifierUuid: string,
) => {
  //
  const res: any = await axiosInstance
    .delete(`/bcatalog/backoffice/items/${itemUuid}/modifiers/${modifierUuid}`)
    .catch(err => {
      console.log('❌ deleteItemModifier : ', err);
    });
  return res;
};

export const updateCatalogModifier = async (
  modifierUuid: string,
  data: any,
) => {
  //
  console.log('### updateCatalogModifier ###');
  const res: any = await axiosInstance
    .put(`/bcatalog/backoffice/modifiers/${modifierUuid}`, data)
    .catch(err => {
      console.log('❌ updateCatalogOptionCategory : ', err);
    });
  return res;
};

export const getItemAdditionalSales = async (itemUuid: string) => {
  //
  console.log('### getItemAdditionalSales ###');

  const res: any = await axiosInstance
    .get(
      `/bcatalog/backoffice/items/${itemUuid}/suggestion-categories/additional-sale`,
    )
    .catch(err => {
      console.log('❌ getItemAdditionalSales : ', err);
    });
  return res?.data?.data;
};

export const getItemDetailsAdditionalSales = async (itemUuid: string) => {
  //
  console.log('### getItemDetailsAdditionalSales ###');

  const res: any = await axiosInstance
    .get(
      `/bcatalog/backoffice/items/${itemUuid}/suggestion-categories/product-details`,
    )
    .catch(err => {
      console.log('❌ getItemDetailsAdditionalSales : ', err);
    });
  return res?.data?.data;
};

export const createItemAdditionalSale = async (
  itemUuid: string,
  data: ICreateItemAdditional,
) => {
  //
  const res: any = await axiosInstance
    .post(
      `/bcatalog/backoffice/items/${itemUuid}/suggestion-categories/additional-sale`,
      data,
    )
    .catch(err => {
      console.log('❌ createItemAdditionalSale : ', err);
    });
  return res;
};

export const createItemDetailsAdditionalSale = async (
  itemUuid: string,
  data: ICreateItemAdditional,
) => {
  //
  const res: any = await axiosInstance
    .post(
      `/bcatalog/backoffice/items/${itemUuid}/suggestion-categories/product-details`,
      data,
    )
    .catch(err => {
      console.log('❌ createItemDetailsAdditionalSale : ', err);
    });
  return res;
};

export const updateItemAdditionalSale = async (
  itemUuid: string,
  suggestionCategoryUuid: string,
  position: number,
) => {
  //
  const res: any = await axiosInstance
    .put(
      `/bcatalog/backoffice/items/${itemUuid}/suggestion-categories/additional-sale/${suggestionCategoryUuid}`,
      {
        position,
      },
    )
    .catch(err => {
      console.log('❌ updateItemAdditionalSale : ', err);
    });
  return res;
};

export const updateItemDetailsAdditionalSale = async (
  itemUuid: string,
  suggestionCategoryUuid: string,
  position: number,
) => {
  //
  const res: any = await axiosInstance
    .put(
      `/bcatalog/backoffice/items/${itemUuid}/suggestion-categories/product-details/${suggestionCategoryUuid}`,
      {
        position,
      },
    )
    .catch(err => {
      console.log('❌ updateItemDetailsAdditionalSale : ', err);
    });
  return res;
};

export const deleteItemAdditionalSale = async (
  itemUuid: string,
  suggestionCategoryUuid: string,
) => {
  //
  const res: any = await axiosInstance
    .delete(
      `/bcatalog/backoffice/items/${itemUuid}/suggestion-categories/additional-sale/${suggestionCategoryUuid}`,
    )
    .catch(err => {
      console.log('❌ deleteItemAdditionalSale : ', err);
    });
  return res;
};

export const deleteItemDetailsAdditionalSale = async (
  itemUuid: string,
  suggestionCategoryUuid: string,
) => {
  //
  const res: any = await axiosInstance
    .delete(
      `/bcatalog/backoffice/items/${itemUuid}/suggestion-categories/product-details/${suggestionCategoryUuid}`,
    )
    .catch(err => {
      console.log('❌ deleteItemDetailsAdditionalSale : ', err);
    });
  return res;
};

interface IItems {
  search?: string;
  offset?: number;
  limit?: number;
  orderBy?: object[];
  type?: number | null;
}

export const getItems = async (data: IItems) => {
  console.log('type', data.type);

  //
  console.log('### getItems ###');

  const query = helperGenerateQuery(data);

  const res: any = await axiosInstance
    .get(`/bcatalog/backoffice/items${query}`, {
      params: {orderBy: data.orderBy, type: data.type},
    })
    .catch(err => {
      console.log('❌ getItems : ', err);
    });
  return res?.data?.data;
};

export const updateItemLoyalty = async (
  itemUuid: string,
  body: {
    loyaltyCost: number;
    loyaltyGain: number;
  },
) => {
  //
  const res: any = await axiosInstance
    .put(`/bcatalog/backoffice/items/${itemUuid}/loyalty`, body)
    .catch(err => {
      console.log('❌ updateItemLoyalty : ', err);
    });
  return res;
};

export const updateItemsPosition = async (
  modifierUuid: string,
  items: {
    uuid: string;
    price?: number;
  }[],
) => {
  //
  const res = await axiosInstance
    .put(`/bcatalog/backoffice/modifiers/${modifierUuid}/items/positions`, {
      items,
    })
    .catch(err => {
      console.log('❌ updateItemsPosition : ', err);
    });
  return res;
};

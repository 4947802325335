import { helperLog } from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';

export const fetchCatalogVendorStructure = async () => {
  const res = await axiosInstance
    .get('bcatalog/backoffice/vendor-structures')
    .catch(err => {
      console.log('❌ fetchCatalogVendorStructure : ', err);
    });

  return res?.data?.data;
};

export const fetchVendorStructureProducts = async (vendorStructureUuid: string) => {
  helperLog({message: 'fetchVendorStructureProducts'});
  const res: any = await axiosInstance
    .get(`/bcatalog/backoffice/vendor-structures/${vendorStructureUuid}/items`)
    .catch(err => {
      helperLog({
        message: 'fetchVendorStructureProducts',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res?.data?.data;
};


export const addVendorStructureProduct = async (data: {
  vendorStructureUuid: string;
  itemUuid: string;
}) => {
  const res: any = await axiosInstance
    .put(
      `/bcatalog/backoffice/vendor-structures/${data.vendorStructureUuid}/items/${data.itemUuid}`,
    )
    .catch(err => {
      console.log('❌ addVendorStructureProduct : ', err);
    });
  return res;
};

export const deleteVendorStructureProduct = async (vendorStructureUuid: string, itemUuid: string) => {
  helperLog({message: 'deleteVendorStructureProduct'});
  const res: any = await axiosInstance
    .delete(`/bcatalog/backoffice/categories/${vendorStructureUuid}/items/${itemUuid}`, {
     data: {
      type: 1
     }
    })
    .catch(err => {
      helperLog({
        message: 'deleteVendorStructureProduct',
        type: 'API_ERROR',
        data: err,
      });
    });

  return res;
};

import {useQuery} from '@tanstack/react-query';
import {useAppSelector} from './useReduxHook';
import {useDebounce} from './useDebounce';
import {getOrders} from '../api/fetchOrders';
import {ORDER_STATUS} from '@bill-app-types/b-types';
import { helperJoinFilterValues } from '../helpers/helperFilter';

export const useOrders = ({offset, limit, orderStatus}: {offset?: number, limit?: number, orderStatus: ORDER_STATUS[]}) => {
  const search = useAppSelector(s => s.filter.search);
  const dates = useAppSelector(s => s.filter.dates);
  const isSyncPos = useAppSelector(s => s.filter.isSyncPos);
  const debouncedSearchTerm = useDebounce(search, 500);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const channels = useAppSelector(s => s.filter.channels);

  const {data, error, isLoading} = useQuery([`getOrders`, debouncedSearchTerm, offset, limit, orderStatus, dates, channels, consumptionModes, isSyncPos], () => getOrders({
    ...dates,
    search: debouncedSearchTerm,
    offset,
    limit,
    orderStatuses: orderStatus,
    isSyncPos,
    consumptionModes: helperJoinFilterValues(consumptionModes, true),
    channels: helperJoinFilterValues(channels, true),
  }));

  return {
    data: data?.orders?.map((item: any) => ({...item, isActive:true})) || [],
    totalOrders: data?.totalOrders,
    error,
    isLoading};
};

/** @jsxImportSource @emotion/react */
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {helperDate} from '../../../helpers/helperDate';
import {THEME} from '../../../styles/theme';
import {ArrayPdf} from '../Array';
import {Fragment} from 'react';
import {helperGenerateDayDetailData} from '../../../helpers/helperGenerateDayDetailData';

export const ExportDayDetailPDF = ({dates, brand, data}: any) => {
  const datas = helperGenerateDayDetailData(data);

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.header}>
          <View style={{flex: 1}}>
            <Image style={{width: 65}} src={require('../logo.png')} />
          </View>
          <View style={styles.infos}>
            <Image style={{width: 45}} src={brand?.asset.logo} />
            <Text style={styles.brand}>{brand?.brand_label}</Text>
            <Text style={styles.text}>Exercice comptable</Text>
            <Text style={styles.text}>
              Ouvert{' '}
              {`${helperDate(data?.openedAt, 'Le DD/MM/YYYY à HH:mm')} par ${
                data?.openedByUserPos?.username
              }`}
            </Text>
            <Text style={styles.text}>
              {!!data?.closedAt
                ? ` Fermé ${helperDate(
                    data?.closedAt,
                    'Le DD/MM/YYYY à HH:mm',
                  )} par ${data?.closedByUserPos?.username}`
                : 'Non fermé'}
            </Text>
          </View>
        </View>
        <View style={styles.contain}>
          {datas?.map((item, index: number) => (
            <Fragment key={index}>
              <Text style={styles.title}>{item?.title}</Text>
              <ArrayPdf
                header={item.header}
                rows={item?.rows}
                align="center"
                sizes={item.sizes || [4, 2, 2, 1]}
              />
            </Fragment>
          ))}
        </View>
        <View style={styles.footer} fixed>
          <View style={styles.footerBlock}>
            <Text style={styles.text}>
              Généré le {helperDate(new Date(), 'DD/MM/YYYY')} à{' '}
              {helperDate(new Date(), 'HH:mm')}
            </Text>
            <Text style={styles.text}>Société {brand?.company_label}</Text>
          </View>
          <View
            style={[
              styles.footerBlock,
              {alignItems: 'center', justifyContent: 'center'},
            ]}>
            <Image style={{width: 15}} src={require('../small_logo.png')} />
            <Text style={styles.copy}>© Copyright BILL</Text>
          </View>
          <View style={[styles.footerBlock, {textAlign: 'right'}]}>
            {/* <Text style={styles.text}>
                Utilisateur {brand?.firstname} {brand?.lastname}
              </Text> */}
            <Text
              style={styles.text}
              render={({pageNumber, totalPages}) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: THEME.colors.white,
    padding: '1% 7%',
    flex: 1,
  },
  header: {
    flexDirection: 'row',
  },
  contain: {
    flex: 1,
    paddingTop: '5%',
  },
  footer: {
    flexDirection: 'row',
    borderTop: 1,
    borderColor: THEME.colors.primary,
    paddingTop: 5,
    alignItems: 'flex-end',
  },
  footerBlock: {
    flex: 1,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  infos: {
    flex: 1,
    textAlign: 'right',
  },
  brand: {
    fontSize: 13.14,
    color: THEME.colors.primary,
  },
  title: {
    color: THEME.colors.bgDark,
    fontSize: 9,
    marginBottom: 5,
  },
  text: {
    color: THEME.colors.bgDark,
    fontSize: 7.63,
  },
  copy: {
    color: THEME.colors.primary,
    fontSize: 7.63,
  },
});

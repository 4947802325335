import {useCallback} from 'react';
import {useAppSelector} from './useReduxHook';
import {IGetCustomerFilters} from '@bill-app-types/b-types';

export const useCustomerFilter = () => {
  const nbOrder = useAppSelector(s => s.filter.numberOfOrders);
  const loyaltyBalance = useAppSelector(s => s.filter.pointsForFree);
  const totalSpent = useAppSelector(s => s.filter.amount);
  const createdAt = useAppSelector(s => s.filter.createdCustomerDates);
  const lastOrderDate = useAppSelector(s => s.filter.lastOrderDates);
  const customerActive = useAppSelector(s => s.filter.customerActive);
  const birthdate = useAppSelector(s => s.filter.birthdate);

  const orderByObject = {
    nbOrder,
    loyaltyBalance,
    totalSpent,
    createdAt,
    lastOrderDate,
    birthdate,
  };

  const getValue = useCallback((value: number | null) => {
    if (value === null) return undefined;
    return value;
  }, []);

  return {
    ordersFrom: getValue(nbOrder.min),
    ordersTo: getValue(nbOrder.max),
    loyaltyPointsFrom: getValue(loyaltyBalance.min),
    loyaltyPointsTo: getValue(loyaltyBalance.max),
    totalSpentFrom:
      totalSpent.min !== null
        ? Number(getValue(totalSpent.min)) * 100
        : undefined,
    totalSpentTo:
      totalSpent.max !== null
        ? Number(getValue(totalSpent.max)) * 100
        : undefined,
    creationDateFrom: createdAt.from,
    creationDateTo: createdAt.to,
    lastOrderDateFrom: lastOrderDate.from,
    lastOrderDateTo: lastOrderDate.to,
    customerActive,
    birthdateFrom: birthdate.from,
    birthdateTo: birthdate.to,
    orderBy:
      (Object.keys(orderByObject)
        ?.filter(key => !!orderByObject[key as keyof typeof orderByObject].sort)
        ?.map(item => {
          return {
            field: item,
            sort: orderByObject[item as keyof typeof orderByObject].sort,
          };
        }) as IGetCustomerFilters['orderBy']) || [],
  };
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {SectionPaymentMode} from './SectionPaymentMode';
import {SectionTva} from './SectionTva';

export const ArchiveVentilation = () => {
  return (
    <div css={styles.container}>
      <SectionPaymentMode />
      <SectionTva />
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1.5rem',
  }),
};

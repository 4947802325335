/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {helperFormatPrice} from '../../../../helpers/helperFormatPrice';
import {helperTaxType} from '../../../../helpers/helperTax';
import {ITEM_TYPES} from '../../../../constants/global';

interface IProps {
  quantity?: string;
  label: string;
  price?: string;
  pu?: string;
  tva?: number;
  paddingLeft?: string;
  color?: string;
}

export const OrderItem = ({item}: {item: any}) => {
  const Row = ({
    quantity = '',
    label = '',
    price = '',
    pu = '',
    tva = 1,
    paddingLeft = '0%',
    color = 'var(--text-color)',
  }: IProps) => (
    <div css={styles.product}>
      <div css={[styles.col, {width: '10%'}]}>
        <TextVariant
          text={quantity}
          variant="textBold"
          style={{
            color,
          }}
        />
      </div>
      <div css={[styles.col, {width: '45%'}]}>
        <TextVariant
          text={label}
          variant="textBold"
          style={{
            paddingLeft,
            color,
          }}
        />
      </div>
      <div css={styles.col}>
        {parseFloat(pu) > 0 && (
          <TextVariant
            text={`${pu}€`}
            variant="textBold"
            style={{
              color,
            }}
          />
        )}
      </div>
      <div css={[styles.col, {width: '20%', textAlign: 'right'}]}>
        {parseFloat(price) > 0 && (
          <TextVariant
            text={`${price}€`}
            variant="textBold"
            style={{
              color,
            }}
          />
        )}
      </div>
    </div>
  );

  const Product = ({
    options,
    showPrice = true,
  }: {
    options: any;
    showPrice?: boolean;
  }) => {
    return (
      <div>
        {options?.map((optionItem: any, idx: number) => {
          const pu = helperFormatPrice(optionItem.baseUnitPrice || 0);
          const totalPrice = helperFormatPrice(optionItem.baseTotalPrice || 0);
          return (
            <Row
              key={idx}
              label={optionItem.label}
              quantity={optionItem.quantity}
              tva={helperTaxType(optionItem.tax)}
              pu={showPrice ? pu : ''}
              price={showPrice ? totalPrice : ''}
              paddingLeft="5%"
              color="#A7A7AB"
            />
          );
        })}
      </div>
    );
  };

  const Menu = ({orderItems}: {orderItems: any}) =>
    orderItems.map((orderItem: any, index: number) => {
      return (
        <div key={index}>
          <Row
            label={orderItem.label}
            quantity={orderItem.quantity}
            tva={helperTaxType(item.tax)}
          />
          <Product options={orderItem.orderItems} showPrice={false} />
        </div>
      );
    });

  const pu = helperFormatPrice(
    (item.productType === ITEM_TYPES.MENU
      ? item.intermediateUnitPrice
      : item.baseUnitPrice) || 0,
  );
  const totalPrice = helperFormatPrice(
    (item.productType === ITEM_TYPES.MENU
      ? item.intermediateTotalPrice
      : item.baseTotalPrice) || 0,
  );

  return (
    <div css={styles.orderItem}>
      <Row
        label={item.label}
        quantity={item.quantity}
        pu={pu}
        tva={helperTaxType(item.tax)}
        price={totalPrice}
      />
      {item.productType === ITEM_TYPES.MENU && (
        <Menu orderItems={item.orderItems} />
      )}
      {item.productType === ITEM_TYPES.PRODUCT && (
        <Product options={item.orderItems} />
      )}
    </div>
  );
};

const styles = {
  orderItem: css({
    backgroundColor: 'var(--bg-color)',
    borderBottom: '1px solid var(--bg-main-color)',
    paddingBottom: '8px',
  }),
  product: css({
    display: 'flex',
    padding: '8px 5px',
    justifyContent: 'space-between',
  }),
  col: css({
    width: '15%',
    padding: '0px 15px',
  }),
};

import {TendanceCompareValues} from '../../../../../components/molecules/TendanceCompareValues';
import {THEME} from '../../../../../styles/theme';

export const GeneralSaleColumns = [
  {
    headerName: 'HEURE / DATE',
    flex: 1.5,
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      return <p className="textSemiBold">{row.x}</p>;
    },
  },
  {
    headerName: 'NOMBRE DE COMMANDES',
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      return (
        <TendanceCompareValues
          value={row.orders}
          secondValue={row.forCompareOrders}
          color={THEME.colors.green}
        />
      );
    },
  },
  {
    headerName: 'PANIER MOYEN',
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      return (
        <TendanceCompareValues
          value={row.averageCart}
          secondValue={row.forCompareAverageCart}
          color={THEME.colors.yellowDark}
          suffix={row.salesSuffix}
        />
      );
    },
  },
  {
    headerName: 'CA TTC',
    flex: 1.5,
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      return (
        <TendanceCompareValues
          value={row.sales}
          secondValue={row.forCompareSales}
          color={THEME.colors.primary}
          suffix={row.salesSuffix}
        />
      );
    },
  },
];

/** @jsxImportSource @emotion/react */
import {useTranslation} from 'react-i18next';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {addPrinterModalAction} from '../../redux/modals';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {createPrinter, updatePrinter} from '../../api/fetchPrinters';
import {printerSelectedAction} from '../../redux/myRestaurantSection/printers';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';

export const AddPrinterModal = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const printerSelected = useAppSelector(
    s => s.myRestaurantSection.printers.printerSelected,
  );

  const initialValues = {
    printerTypeUuid: '4e690ec1-5d71-4182-8508-3f441c8ad525',
    label: printerSelected?.label || '',
    ipAddress: printerSelected?.ip_address || '',
  };

  const clientQuery = useQueryClient();

  const handleCancel = () => {
    dispatch(printerSelectedAction(null));
    dispatch(addPrinterModalAction(false));
  };

  const handleSubmit = async (data: any, {setSubmitting}: any) => {
    try {
      // TODO dynamic create printer and update
      let res;

      if (printerSelected?.uuid) {
        // TODO update printer
        res = await updatePrinter(data, printerSelected?.uuid);
      } else {
        res = await createPrinter(data);
      }

      if (res && [200, 204].includes(res?.status)) {
        clientQuery.invalidateQueries(['fetchPrinters']);
        helperDisplayToast('Imprimante enregistrée', true);
        handleCancel();
      } else if (res?.data?.message) {
        helperDisplayToast(res?.data?.message, false);
      }

      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <ModalWrapper
      title={
        printerSelected
          ? t('UPDATE_NETWORK_PRINTER')
          : t('ADD_A_NETWORK_PRINTER')
      }
      cancelLabel={printerSelected ? t('CANCEL') : t('CANCEL_CREATE')}
      validateLabel={t('REGISTER_PRINTER')}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      initialValues={initialValues}>
      <div css={styles.container}>
        <FieldCustomInput
          label={t('PRINTER_NAME')}
          name="label"
          placeholder={t('NAME')}
          type="text"
        />

        <FieldCustomInput
          label={t('IP_ADDRESS')}
          name="ipAddress"
          placeholder={t('IP_ADDRESS')}
          type="text"
        />
      </div>
    </ModalWrapper>
  );
};

const styles = {
  container: {
    marginTop: '2rem',
  },
};

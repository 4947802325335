import {createSlice} from '@reduxjs/toolkit';
import {IAreaLocation} from '../../api/fetchAreas';

interface IState {
  areaSelected: {
    id: string;
    uuid: string;
    label: string;
    location_label: string;
    location_details: IAreaLocation[];
  } | null;
  areaLocationSelected: IAreaLocation | null;
}

const initialState: IState = {
  areaSelected: null,
  areaLocationSelected: null,
};

const slice = createSlice({
  name: 'areas',
  initialState,
  reducers: {
    areaSelectedAction: (state, action) => {
      state.areaSelected = action.payload;
    },
    updateAreaSelectedFormAction: (state, action) => {
      const {key, value} = action.payload;
      if (state.areaSelected) {
        state.areaSelected[key as keyof typeof state.areaSelected] = value;
      }
    },
    areaLocationSelectedAction: (state, action) => {
      state.areaLocationSelected = action.payload;
    },
  },
});

export const {
  areaSelectedAction,
  updateAreaSelectedFormAction,
  areaLocationSelectedAction,
} = slice.actions;

export default slice.reducer;

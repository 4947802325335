/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useNavigate, useParams} from 'react-router-dom';

import {ModuleHeader} from '../ModuleHeader';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {useEffect, useState} from 'react';
import {moduleSelectedAction} from '../../../../redux/myModulesSection/subscriptions';
import {FiltersBlock} from './FiltersBlock';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {columns} from './columns';
import {useItems} from '../../../../hooks/useItems';

const BLoyaltySection = () => {
  const {pathname} = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const subscriptions = useAppSelector(
    s => s.myModulesSection.subscriptions.subscriptions,
  );

  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [limit] = useState(20);

  const offset = (page - 1) * limit;

  const {data, isLoading} = useItems({
    offset,
    limit,
  });

  useEffect(() => {
    setRowCount(oldRowCount =>
      data?.totalItems !== undefined ? data?.totalItems : oldRowCount,
    );
  }, [data?.totalItems]);

  useEffect(() => {
    if (pathname !== 'bloyalty') {
      navigate('/online/modules');
    } else {
      const module = subscriptions?.find(
        (item: any) => item.code === 'b_loyalty',
      );
      dispatch(moduleSelectedAction(module));
    }
  }, [pathname, navigate, subscriptions]);

  if (!pathname) return null;

  return (
    <>
      <ModuleHeader
        moduleActiveName={String(pathname)}
        hideButtons
        marginBottom={'2vh'}
      />
      <div>
        <FiltersBlock />
        <div css={styles.container}>
          <NewDataGrid
            rows={data?.items}
            columns={columns}
            rowHeight={60}
            loading={isLoading}
            totalRows={rowCount}
            rowPerPage={limit}
            setPage={setPage}
            page={page}
            hasPagination
          />
        </div>
      </div>
    </>
  );
};

export default BLoyaltySection;

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    height: '84vh',
  }),
};

import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {DeleteModal} from './DeleteModal';
import {deleteExternalConnectionModalAction} from '../../redux/modals';
import {useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import { externalConnectionSetSelectedAction } from '../../redux/myModulesSection/externalConnections';
import { deleteExternalConnection, deleteExternalConnectionHasStructure } from '../../api/fetchExternalConnections';
import { helperDisplayToast } from '../../helpers/helperToast';

export const DeleteExternalConnectionModal = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const selectedExternalConnection = useAppSelector(s => s.myModulesSection.externalConnections.selected);

  if (!selectedExternalConnection) return null;


  const handleCancel = () => {
    dispatch(externalConnectionSetSelectedAction(null));
    dispatch(deleteExternalConnectionModalAction(false));
  };
  
  const handleSubmit = async () => {
    setIsLoading(true);

    const resCatalog = await deleteExternalConnectionHasStructure(
      selectedExternalConnection.uuid,
    );

    // Delete from bCatalog, if error is not cat-ext-001 (no association) cancel
    if (resCatalog.status !== 204 && (resCatalog.status === 401 && resCatalog.code !== 'cat-ext-001')) {
      helperDisplayToast(t('EXTERNAL_CONNECTION_DELETE_ERROR_ASSOCIATION'));
      setIsLoading(false);
      return handleCancel();
    }
    
    // Delete from bCore
    const resCore = await deleteExternalConnection(
      selectedExternalConnection?.id,
    );
      
    if (resCore.status === 200) {
      clientQuery.invalidateQueries(['fetchExternalConnections']);
      handleCancel();
      helperDisplayToast(t('EXTERNAL_CONNECTION_DELETE_SUCCESS'), true); 
    } else
      helperDisplayToast(t('EXTERNAL_CONNECTION_DELETE_ERROR'));

    setIsLoading(false);
  };

  return (
    <DeleteModal
      title={t('DELETE_EXTERNAL_CONNECTION')}
      isLoading={isLoading}
      text={`"${selectedExternalConnection.connection_label}"`}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};

/** @jsxImportSource @emotion/react */
import {HeaderTabsLinks} from '../../../../../components/molecules/HeaderTabsLinks';

const data = [
  {label: 'Généralités', link: ''},
  {label: 'Labels et allergènes', link: '/pictograms-and-allergens'},
  {label: 'Options', link: '/options'},
  {label: 'Ingrédients', link: '/product-recipes'},
  // {label: 'Règles de prix', link: '/rules-price'},
  {label: 'Ventes additionnelles', link: '/additional-sales'},
];

export const HeaderTabs = () => {
  return <HeaderTabsLinks data={data} path="products/edit" />;
};

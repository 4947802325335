import {ORDER_STATUS} from '@bill-app-types/b-types';
import {helperGenerateQuery} from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';
import {API_SERVICES, FOLDERS, PATHS} from '../constants/routes';

const orderUrl = `/border/backoffice/orders`;

export const getOrderDetails = async (brandId: number, orderId: number) => {
  //
  console.log('### getOrderDetails ###');
  const res: any = await axiosInstance
    .get(`${API_SERVICES.BCORE}${FOLDERS.ADMIN}/${brandId}${PATHS.ORDER}/${orderId}`)
    .catch(err => {
      console.log('❌ getOrderDetails : ', err);
    });
  return res?.data?.data;
};

export const getOrderDetailsV2 = async (orderUuid: string) => {
  //
  console.log('### getOrderDetailsV2 ###');
  const res: any = await axiosInstance
    .get(`${orderUrl}/${orderUuid}`)
    .catch(err => {
      console.log('❌ getOrderDetailsV2 : ', err);
    });
  return res?.data?.data;
};

export const getOrdersByCustomer = async (
  data: {
    offset: number;
    limit: number;
  },
  customerUuid?: string,
) => {
  //
  const query = helperGenerateQuery(data);

  console.log('### getOrdersByCustomer ###');
  const res: any = await axiosInstance
    .get(`${orderUrl}${PATHS.CUSTOMERS}/${customerUuid}${query}`)
    .catch(err => {
      console.log('❌ getOrdersByCustomer : ', err);
    });
  return res?.data?.data;
};

export const getOrderLoyaltyGains = async (
  customerUuid?: string,
  orderUuid?: string,
) => {
  //

  console.log('### getOrderLoyaltyGains ###');
  const res: any = await axiosInstance
    .get(
      `${API_SERVICES.BUSER}${FOLDERS.BACKOFFICE}${PATHS.CUSTOMERS}/${customerUuid}${PATHS.ORDERS}/${orderUuid}${PATHS.LOYALTY_GAINS}`,
    )
    .catch(err => {
      console.log('❌ getOrderLoyaltyGains : ', err);
    });
  return res?.data?.data;
};

interface IOrderData {
  search?: string;
  orderBy?: object[];
  offset?: number;
  limit?: number;
  paymentStatuses?: string;
  channels?: string;
  consumptionModes?: string;
  orderStatuses?: ORDER_STATUS[];
  from?: Date;
  to?: Date;
  isSyncPos: boolean | null;
}

export const getOrders = async (dataQuery: IOrderData) => {
  //
  const query = helperGenerateQuery(dataQuery);
  console.log('### getOrders ###');
  const res: any = await axiosInstance
    .get(`${orderUrl}${query}`)
    .catch(err => {
      console.log('❌ getOrders : ', err);
    });
  return res?.data?.data;
};

export const updateOrder = async (orderUuid: string, orderStatus: ORDER_STATUS) => {
  //
  console.log('### updateOrder ###');
  const res: any = await axiosInstance
    .put(`${orderUrl}/${orderUuid}`, {
      orderStatus
    })
    .catch(err => {
      console.log('❌ updateOrder : ', err);
    });
  return res;
};

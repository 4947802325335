/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {ProductCard} from './ProductCard';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

interface ISortableList {
  items: any;
  style?: any;
  bg?: string;
  showCross?: boolean;
  showAdd?: boolean;
  handleOnClick?: any;
  showProductType?: boolean;
  showAdditionalPrice?: boolean;
  handleClickAdditional?: any;
  specificImage?: string;
  showPrice?: boolean;
  noTruncImage?: boolean;
  imageObjectFit?: 'contain' | 'cover' | 'fill';
  showOverlay?: boolean;
  showActions?: boolean;
}

export const SortableList = SortableContainer(
  ({
    items,
    style,
    bg,
    showCross,
    showAdd,
    handleOnClick,
    showProductType,
    showAdditionalPrice = false,
    handleClickAdditional,
    showPrice,
    specificImage,
    noTruncImage,
    imageObjectFit,
    showActions,
    showOverlay,
  }: ISortableList) => {
    return (
      <div css={[styles.list, {backgroundColor: bg}, style]}>
        {items.map((value: any, index: number) => (
          //@ts-ignore
          <SortableItem
            key={`item-${value.uuid || value.id || value._id || index}`}
            index={index}
            // @ts-ignore
            value={{...value, position: index + 1}}
            showCross={showCross}
            showAdd={showAdd}
            handleOnClick={() => handleOnClick(value)}
            showProductType={showProductType}
            showAdditionalPrice={showAdditionalPrice}
            handleClickAdditional={handleClickAdditional}
            showPrice={showPrice}
            specificImage={specificImage}
            noTruncImage={noTruncImage}
            imageObjectFit={imageObjectFit}
            showOverlay={showOverlay}
            showActions={showActions}
          />
        ))}
      </div>
    );
  },
);

// ignore typescript to this line
//@ts-ignore
const SortableItem = SortableElement(
  ({
    value,
    showCross,
    showAdd,
    handleOnClick,
    showProductType,
    showAdditionalPrice,
    handleClickAdditional,
    showPrice,
    specificImage,
    noTruncImage,
    imageObjectFit,
    showActions,
    showOverlay,
  }: any) => (
    <ProductCard
      item={value}
      showCross={showCross}
      showAdd={showAdd}
      handleOnClick={handleOnClick}
      showProductType={showProductType}
      draggable={true}
      showAdditionalPrice={showAdditionalPrice}
      handleClickAdditional={handleClickAdditional}
      showPrice={showPrice}
      specificImage={specificImage}
      noTruncImage={noTruncImage}
      imageObjectFit={imageObjectFit}
      showActions={showActions}
      showOverlay={showOverlay}
    />
  ),
);

const styles = {
  list: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: '10px',
    paddingTop: '10px',
  }),
};

/**  @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {CumulPrice} from './CumulPrice';
import {IStatItemDetail} from '../../api/fetchStats';
import {useAppSelector} from '../../hooks/useReduxHook';
import {useTranslation} from 'react-i18next';
import {TextVariant} from '../atoms/TextVariant';
import {PillLabel} from './PillLabel';
import {THEME} from '../../styles/theme';
import {helperGetConsumptionNameById} from '../../helpers/helperStats';
import {M_SCREEN_TABLET} from '../../constants/ResponsiveSize';

export const ConsumptionModeDetail = ({
  consumptionModes,
}: {
  consumptionModes: IStatItemDetail['consumptionModes'];
}) => {
  const currentMode = useAppSelector(s => s.filter.currentMode);
  const {t} = useTranslation();
  return (
    <div css={styles.container}>
      <div>
        <TextVariant
          text={t('CONSUMPTION_MODE')}
          variant="textSemiBold"
          style={styles.title}
        />
        <PillLabel
          text={`CA ${currentMode}`}
          backgroundColor={THEME.colors.primary}
        />
      </div>

      {consumptionModes?.map((item, index) => {
        return (
          <CumulPrice
            label={helperGetConsumptionNameById(item?.id)}
            key={index}
            value={item.sales?.[currentMode].total_price}
            secondValue={item.sales.orders}
          />
        );
      })}
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    minHeight: '13.337rem',
    width: '26rem',
    borderRadius: '0.13rem',
    padding: '0.94rem',
    [M_SCREEN_TABLET]: {
      width: '100%',
    },
  }),
  title: css({marginBottom: '0.4rem'}),
};

/** @jsxImportSource @emotion/react */
import {useEffect} from 'react';
import {css} from '@emotion/react';
import {useQuery} from '@tanstack/react-query';
import {TicketContent} from '../../../../components/ticket/TicketContent';
import {getReceiptDetails} from '../../../../api/fetchReceipts';
import {Loader} from '../../../../components/atoms/Loader';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {selectedReceiptAction} from '../../../../redux/receipt';

export const ReceiptTicket = ({
  TicketHeader,
  TicketFooter,
}: {
  TicketHeader?: any;
  TicketFooter?: any;
}) => {
  //
  const dispatch = useAppDispatch();
  const selectedReceipt = useAppSelector(s => s.receipt.selectedReceipt);
  const brand = useAppSelector(s => s.brand.brand);

  const {data: receiptDetails, isLoading} = useQuery(
    ['receiptDetails', selectedReceipt?.uuid, brand?.id],
    () => (selectedReceipt ? getReceiptDetails(selectedReceipt?.uuid) : null),
    {
      enabled: !!selectedReceipt?.uuid,
    },
  );

  useEffect(() => {
    return () => {
      dispatch(selectedReceiptAction(null));
    };
  }, [dispatch, brand?.id]);

  useEffect(() => {
    if (!receiptDetails && !isLoading) {
      dispatch(selectedReceiptAction(null));
    }
  }, [dispatch, isLoading, receiptDetails]);

  return (
    <div css={styles.ticket} id="receiptTicket">
      {isLoading && (
        <div className="flex-center" css={styles.loaderContainer}>
          <Loader />
        </div>
      )}
      {!isLoading && (
        <>
          {TicketHeader && <TicketHeader receiptDetails={receiptDetails} />}

          {/* Scroll part */}
          <TicketContent data={receiptDetails} isReceipt />
        </>
      )}
      {TicketFooter && <TicketFooter data={receiptDetails} />}
    </div>
  );
};

const styles = {
  ticket: css({
    backgroundColor: 'var(--bg-color)',
    fontSize: '20px',
    height: '93.5%',
    marginLeft: 10,
    width: '40vh',
    color: 'var(--text-color)',
    alignSelf: 'flex-end',
  }),
  loaderContainer: css({
    flex: 1,
    height: '91%',
  }),
};

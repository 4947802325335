/* @jsxImportSource @emotion/react */
import {SalesByCategoriesColumns} from '../DataColumns/SalesByCategoriesColumns';
import {SalesPieChartList} from '../../../../../components/organisms/SalesPieChartList';
import {useStatCategory} from '../../../../../hooks/useStatCategory';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {helperJustLog} from '../../../../../helpers/helperLog';

export const SalesByCategories = () => {
  const compareDates = useAppSelector(s => s.filter.compareDates);
  const isCompare = useAppSelector(s => s.filter.isCompare);
  const dates = useAppSelector(s => s.filter.dates);

  const {data, isLoading} = useStatCategory({
    dates,
  });

  const {data: dataCompare} = useStatCategory({
    dates: compareDates,
    enabled: isCompare,
  });

  const dataWithCompare = data?.map((item, index) => {
    const findItem = dataCompare?.find(_item => {
      return _item.vendorStructureId === item.vendorStructureId;
    });

    return {
      ...item,
      forCompareOrders: findItem?.orders || {
        value: 0,
        percent: 0,
      },
      forCompareSales: findItem?.sales || {
        TTC: {
          total_price: 0,
          total_percent: 0,
        },
      },
    };
  });
  helperJustLog('----------' + data);
  return (
    <SalesPieChartList
      title="SALES_BY_CATEGORIES"
      showHeaderFilters={false}
      data={isCompare ? dataWithCompare : data}
      isLoading={isLoading}
      columns={SalesByCategoriesColumns}
      marginTop={'2rem'}
    />
  );
};

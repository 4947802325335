/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {ProductType} from '../atoms/ProductType';
import {TextVariant} from '../atoms/TextVariant';

export const ProductTypePrice = ({
  type,
  bgColor,
  price = '0000.00',
}: {
  type: number;
  bgColor?: string;
  price?: number | string;
}) => {
  return (
    <div css={styles.container}>
      <ProductType type={type} bgColor={bgColor} />
      <TextVariant
        text={price + ' €'}
        variant="textMedium"
        style={css({
          fontWeight: '500',
        })}
      />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.2rem',
  },
};

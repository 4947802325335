import {combineReducers} from 'redux';
import areas from './areas';
import printers from './printers';
import areaProductions from './areaProductions';

export const myRestaurantSection = combineReducers({
  // Reducers
  areas,
  printers,
  areaProductions,
});

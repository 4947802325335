/** @jsxImportSource @emotion/react */
import {useContext, useEffect, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {IObject, cardsAction, resetCardsAction} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
import {FilterCheckboxs} from './FilterCheckboxs';
import {fetchCards, fetchCardsOld} from '../../api/fetchCards';
import {useQuery} from '@tanstack/react-query';
import {useFlagBCatalog} from '../../hooks/useFlagBCatalog';

export const CardFilter = ({withBorder = false}) => {
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {data} = useQuery(
    ['fetchCards'],
    () => (flagBCatalogEnabled ? fetchCards() : fetchCardsOld()),
    {
      enabled: flagBCatalogReady,
    },
  );
  const cards = useAppSelector(state => state.filter.cards);
  const {dispatch} = useContext<any>(UtilsContext);

  const [cardsSelected, setCardsSelected] = useState<IObject[]>([]);
  const [forceClose, setForceClose] = useState(false);

  const cartList = (data?.cards || data || [])?.map(
    (card: {label: string; id: string; position: number}) => ({
      name: card.label,
      value: card.id,
      position: card.position,
    }),
  );

  const handleResetFilterConsumptionMode = () => {
    dispatch(resetCardsAction());
  };

  const handleCheckedChange = (e: any) => {
    if (e.target.name === 'ALL') {
      if (cardsSelected.length === cartList?.length) {
        setCardsSelected([]);
      } else {
        setCardsSelected(cartList);
      }
    } else {
      if (!!cardsSelected.find(channel => channel.name === e.target.name)) {
        setCardsSelected(
          cardsSelected.filter(channel => channel.name !== e.target.name),
        );
      } else {
        setCardsSelected([
          ...cardsSelected,
          {
            name: e.target.name,
            value: e.target.value,
          },
        ]);
      }
    }
  };

  const handleSubmit = () => {
    //
    dispatch(cardsAction(cardsSelected));
    setForceClose(true);
  };

  useEffect(() => {
    setCardsSelected(cards);
    return () => {
      setForceClose(false);
    };
  }, [cards]);

  return (
    <FilterButton
      marginRight={'1vh'}
      label={'Carte'}
      valueType="list"
      values={cards}
      showResetButton={cards?.length > 0}
      withBorder={withBorder}
      onReset={handleResetFilterConsumptionMode}
      hideFilterIcon={cards?.length > 0}
      forceClose={forceClose}>
      <FilterCheckboxs
        title="Carte"
        list={[...cartList].sort((a, b) => a.position - b.position)}
        selectedValues={cardsSelected}
        onChange={handleCheckedChange}
        onSubmit={handleSubmit}
      />
    </FilterButton>
  );
};

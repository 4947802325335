/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';

export const DatagridHeader = ({
  columns,
  checkBoxSelection = false,
}: {
  columns?: any;
  checkBoxSelection?: boolean;
}) => {
  return (
    <div className="flex" style={{paddingLeft: checkBoxSelection ? 26 : 0}}>
      {columns?.map((column: any, index: number) => (
        <div
          className="textRegular"
          key={index}
          css={styles.colHeading({
            flex: column?.flex,
            width: column?.width,
            color: column?.headerColor,
            textAlign: column?.textAlign,
          })}>
          {column?.headerName}
        </div>
      ))}
    </div>
  );
};

const styles = {
  colHeading: ({
    flex = 1,
    width = 'none',
    color = THEME.colors.gray,
    textAlign = 'center',
  }: {
    flex?: number | string;
    width?: number | string;
    color?: string;
    textAlign?: 'center' | 'left' | 'right' | 'justify' | 'initial' | 'inherit';
  }) =>
    css({
      flex,
      width,
      textAlign,
      lineHeight: '3rem',
      fontSize: 12,
      fontWeight: '500',
      color: color || THEME.colors.gray,
      '::first-letter': {
        textTransform: 'capitalize',
      },
    }),
};

import {SVGProps} from 'react';

export const UsersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.4375 8.4375C11.0587 8.4375 13.1836 6.54873 13.1836 4.21875C13.1836 1.88877 11.0587 0 8.4375 0C5.8166 0 3.69141 1.88877 3.69141 4.21875C3.69141 6.54873 5.81726 8.4375 8.4375 8.4375ZM8.4375 1.40625C10.1823 1.40625 11.6016 2.66777 11.6016 4.21875C11.6016 5.76943 10.1823 7.03125 8.4375 7.03125C6.69298 7.03125 5.27344 5.76943 5.27344 4.21875C5.27344 2.66777 6.69397 1.40625 8.4375 1.40625ZM11.5719 9.375H5.27344C2.36118 9.375 0 11.4735 0 14.0625C0 14.5802 0.472302 15 1.02502 15H15.8203C16.4027 15 16.8453 14.5802 16.8453 14.0625C16.875 11.4727 14.5151 9.375 11.5719 9.375ZM1.6196 13.5938C1.87626 12.0056 3.41719 10.7812 5.27476 10.7812H11.5732C13.4298 10.7812 14.9713 12.0056 15.2284 13.5938H1.6196Z"
      fill="white"
    />
  </svg>
);

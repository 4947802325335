import {Options} from './OptionList';
import {SelectedOptionCategory} from './SelectedOptionCategory';

export const MyOptionRouter = {
  path: 'options',
  children: [
    {
      path: '',
      element: <Options />,
    },
    {
      path: ':uuid',
      element: <SelectedOptionCategory />,
    },
  ],
};

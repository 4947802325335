/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../../../styles/theme';

export const TicketHeader = ({order}: {order: any}) => {
  return (
    <div css={styles.header} className="textSemiBold">
      Commande{' '}
      <span style={{color: THEME.colors.primary}}>
        {order?.short_code || order?.shortCode}
      </span>{' '}
      /{` ${order?.order_id || order?.publicId}`}
    </div>
  );
};

const styles = {
  header: css({
    padding: 10,
    textAlign: 'center',
  }),
};

import {helperLog} from '../helpers/helperLog';
import {helperDisplayToast} from '../helpers/helperToast';
import {axiosInstance} from './axiosInstance';

// OLD

export const fetchPosCategoriesOld = async () => {
  helperLog({message: 'fetchPosCategories'});
  const res: any = await axiosInstance
    .get(`/bcore/protected/admin/pos_category`)
    .catch(err => {
      helperLog({message: 'fetchPosCategories', type: 'API_ERROR', data: err});
    });

  return res?.data;
};

export const createPosCategoryOld = async (data: {
  label: string;
  parent_category?: string | null;
  color: string;
  position: number;
}) => {
  if (!data.parent_category) delete data.parent_category;

  helperLog({message: 'createPosCategory'});
  const res: any = await axiosInstance
    .post(`/bcore/protected/admin/pos_category`, data)
    .catch(err => {
      helperDisplayToast(err?.response?.data?.message || 'Error');
    });

  return res;
};

export const updatePosCategoryOld = async (
  data: {
    label: string;
    parent_category?: string | null;
    color: string;
    position: number;
  },
  id: number,
) => {
  if (!data.parent_category) delete data.parent_category;
  helperLog({message: 'createPosCategory'});
  const res: any = await axiosInstance
    .put(`/bcore/protected/admin/pos_category/${id}`, data)
    .catch(err => {
      helperDisplayToast(err?.response?.data?.message || 'Error');
    });

  return res;
};

export const deletePosCategoryOld = async (id: number) => {
  helperLog({message: 'deletePosCategory'});
  const res: any = await axiosInstance
    .delete(`/bcore/protected/admin/pos_category/${id}/delete`)
    .catch(err => {
      helperDisplayToast(err?.response?.data?.message || 'Error');
    });

  return res;
};

export const addPosCategoryProductOld = async (data: any) => {
  const res: any = await axiosInstance
    .post(
      `/bcore/protected/admin/pos_category/${data.category_id}/products/${data.product_id}`,
      {
        type: data.type || 1,
        position: 1,
      },
    )
    .catch(err => {
      console.log(err?.response?.data?.message || 'Error');
    });
  return res;
};

export const updatePosCategoryProductPositionOld = async (
  query: {
    pos_category_id: number;
    product_id: number;
  },
  data: {
    type: number;
    position: number;
  },
) => {
  const res: any = await axiosInstance
    .put(
      `/bcore/protected/admin/pos_category/${query.pos_category_id}/products/${query.product_id}`,
      data,
    )
    .catch(err => {
      helperDisplayToast(err?.response?.data?.message || 'Error');
    });
  return res;
};

export const deletePosCategoryProductOld = async (data: any) => {
  const res: any = await axiosInstance
    .delete(
      `/bcore/protected/admin/pos_category/${data.category_id}/products/${data.product_id}`,
    )
    .catch(err => {
      console.log(
        err?.response?.data?.message ||
          "Impossible de retirer l'item de la catégorie",
      );
    });
  return res;
};

export const fetchPosCategoryProductsOld = async (posId: string) => {
  const res: any = await axiosInstance.get(
    `/bcore/protected/admin/pos_category/${posId}/products`,
  );

  return res?.data?.data;
};

// CATALOG

export const fetchPosCategoryItems = async (posCategoryUuid: string) => {
  const res: any = await axiosInstance.get(
    `/bcatalog/backoffice/pos-categories/${posCategoryUuid}/items`,
  );

  return res?.data?.data;
};

export const createPosCategory = async (data: {
  label: string;
  parentCategoryUuid: string | null;
  structureUuid: string;
  color: string;
  position: number;
}) => {
  helperLog({message: 'createPosCategory'});
  const res: any = await axiosInstance
    .post(`/bcatalog/backoffice/pos-categories`, data)
    .catch(err => {
      helperDisplayToast(err?.response?.data?.message || 'Error');
    });

  return res;
};

export const deletePosCategory = async (posCategoryUuid: string) => {
  helperLog({message: 'deletePosCategory'});
  const res: any = await axiosInstance
    .delete(`/bcatalog/backoffice/pos-categories/${posCategoryUuid}`)
    .catch(err => {
      helperDisplayToast(err?.response?.data?.message || 'Error');
    });

  return res;
};

export const updatePosCategory = async (
  posCategoryUuid: string,
  data: {
    label?: string;
    parentCategoryUuid?: string | null;
    structureUuid?: string;
    color?: string;
    position?: number;
  },
) => {
  helperLog({message: 'updatePosCategory'});
  const res: any = await axiosInstance
    .put(`/bcatalog/backoffice/pos-categories/${posCategoryUuid}`, data)
    .catch(err => {
      helperDisplayToast(err?.response?.data?.message || 'Error');
    });

  return res;
};

export const addPosCategoryItem = async (
  categoryUuid: string,
  data: {
    itemUuid: string;
    type: number;
    color: string;
    position: number;
  },
) => {
  const res: any = await axiosInstance
    .post(`/bcatalog/backoffice/pos-categories/${categoryUuid}/items`, data)
    .catch(err => {
      console.log(err?.response?.data?.message || 'Error');
    });
  return res;
};

export const deletePosCategoryItem = async (
  categoryUuid: string,
  itemUuid: string,
  body?: {type?: number},
) => {
  const res: any = await axiosInstance
    .delete(
      `/bcatalog/backoffice/pos-categories/${categoryUuid}/items/${itemUuid}`,
      {
        data: body,
      },
    )
    .catch(err => {
      console.log(err?.response?.data?.message || 'Error');
    });
  return res;
};

export const updatePosCategoryItem = async (
  categoryUuid: string,
  itemUuid: string,
  data: {
    type?: number;
    label?: string;
    color?: string;
    position?: number;
  },
) => {
  const res: any = await axiosInstance
    .put(
      `/bcatalog/backoffice/pos-categories/${categoryUuid}/items/${itemUuid}`,
      data,
    )
    .catch(err => {
      console.log(err?.response?.data?.message || 'Error');
    });
  return res;
};

export const updatePosCategoryItemImage = async (
  categoryUuid: string,
  itemUuid: string,
  image: any,
) => {
  const formData = new FormData();
  formData.append('image', image);

  const res: any = await axiosInstance
    .put(
      `/bcatalog/backoffice/pos-categories/${categoryUuid}/items/${itemUuid}/image`,
      formData,
    )
    .catch(err => {
      console.log(err?.response?.data?.message || 'Error');
    });
  return res;
};

import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  receipts: object[];
  selectedReceipt: any | null;
}

const initialState: ISlice = {
  receipts: [],
  selectedReceipt: null,
};

const slice = createSlice({
  name: 'receipt',
  initialState,
  reducers: {
    receiptsAction(state, action) {
      state.receipts = action.payload;
    },
    selectedReceiptAction(state, action) {
      state.selectedReceipt = action.payload;
    },
  },
});

export const {receiptsAction, selectedReceiptAction} = slice.actions;

export default slice.reducer;

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {SubMenuList} from './SubMenuList';
import {ButtonCustom} from '../../../../../../components/atoms/ButtonCustom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useReduxHook';
import {addCompositionSubMenuModalAction} from '../../../../../../redux/modals';
import {useQuery} from '@tanstack/react-query';
import {
  getItemModifiers,
  updateItemModifier,
} from '../../../../../../api/fetchItems';
import {submenuListAction} from '../../../../../../redux/myCatalogSection/menus';
import {THEME} from '../../../../../../styles/theme';
import {BRAND_AUTHORIZATION} from '../../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../../hooks/useBrandAccess';
import {
  SortableListVerticalContainer,
  THandleDragAndDrop,
} from '../../../../../../components/organisms/SortableListVerticalContainer';
import {useEffect, useMemo} from 'react';
import {helperDisplayToast} from '../../../../../../helpers/helperToast';
import { ICreateItemHasModifierPostionsPayload } from '@bill-app-types/b-types';

export const MenuComposition = () => {
  const dispatch = useAppDispatch();

  const menuPreRegister = useAppSelector(
    s => s.myCatalogSection.menus.menuPreRegister,
  );
  const {brandHasAuthorization} = useBrandAccess();

  const handleOpenSubMenuModal = () => {
    dispatch(addCompositionSubMenuModalAction(true));
  };

  const {data} = useQuery(
    ['getItemSubmenus'],
    () => getItemModifiers(menuPreRegister.uuid),
    {
      enabled: !!menuPreRegister.uuid,
    },
  );

  const modifiers = useMemo(() => data?.modifiers, [data?.modifiers]);

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const handleDragAndDrop: THandleDragAndDrop = async ({items}) => {
    const itemUuids: ICreateItemHasModifierPostionsPayload = { modifierUuids: items.map(item => item.uuid) };
    const res = await updateItemModifier(
      menuPreRegister.uuid,
      itemUuids,
    );
    if (res.status === 204) {
      helperDisplayToast('Position modifiée', true);
    } else {
      helperDisplayToast(
        res.data?.message || 'Impossible de modifier la position',
        false,
      );
    }
  };

  useEffect(() => {
    const modifiers = data?.modifiers;

    if (modifiers) {
      dispatch(submenuListAction(modifiers));
    }
  }, [data?.modifiers]);

  return (
    <div>
      <div className="flex" css={styles.header}>
        <ButtonCustom
          label="Ajouter un sous-menu"
          onClick={handleOpenSubMenuModal}
          disabled={!hasAuthorization}
        />
      </div>
      {modifiers && modifiers.length === 0 && (
        <div
          style={{
            backgroundColor: THEME.colors.white,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '78vh',
          }}>
          <span className="textSemiBold">Aucune donnée</span>
        </div>
      )}

      {modifiers && modifiers.length > 0 && (
        <SortableListVerticalContainer
          data={modifiers}
          ItemComponent={SubMenuList}
          handleDragAndDrop={handleDragAndDrop}
        />
      )}
    </div>
  );
};

const styles = {
  header: css({
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  }),
};

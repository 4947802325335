/** @jsxImportSource @emotion/react */
import {useQuery} from '@tanstack/react-query';
import {getBrandAccountingManagement} from '../../../../api/fetchBrandAccount';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {columns} from './columns';

export const BanksAndAccountsSection = () => {
  const {data, isLoading} = useQuery(
    ['getBrandAccountingManagement'],
    getBrandAccountingManagement,
  );

  return (
    <div css={styles.content}>
      <NewDataGrid
        columns={columns}
        rows={data}
        loading={isLoading}
        rowHeight={60}
      />
    </div>
  );
};

const styles = {
  content: {
    padding: '2vh',
    height: '84%',
  },
};

export const workSpaceEnvironnement: string = 'preprod'; //'localhost' | 'development' | 'preprod' | 'production'
//
export const isDevelopment =
  workSpaceEnvironnement === 'development' ||
  workSpaceEnvironnement === 'preprod' ||
  workSpaceEnvironnement === 'localhost';

export const env = {
  API: {
    BASE_URL:
      workSpaceEnvironnement === 'production'
        ? 'https://api.bill-app.fr'
        : workSpaceEnvironnement === 'localhost'
        ? 'http://localhost:8000'
        : 'https://api.dev2.bill-app.fr',
  },
  BACK_OFFICE_V1:
    workSpaceEnvironnement === 'development'
      ? 'https://admin.dev.bill-app.fr'
      : workSpaceEnvironnement === 'preprod'
      ? 'https://admin.preprod.bill-app.fr'
      : 'https://admin.bill-app.fr',
  SUBDOMAIN: isDevelopment ? 'preprod.bill-app.fr' : 'web.bill-app.fr',
  UNLEASH_KEY: isDevelopment
    ? '*:development.63eac2e44f70ca05e521725b9a0430011ae14e536d2a09fcec31e960'
    : '*:production.38ba941931f93365d7993658093837cac8eb1cdc2854c458121ce25d',
};

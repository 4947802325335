/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../../../../styles/theme';
import {SquareButton2} from '../../../../../components/atoms/SquareButton';
import {EuroLargeIcon} from '../../../../../assets/svgs/EuroIcon';
import {TextVariant} from '../../../../../components/atoms/TextVariant';
import {PlusIcon} from '../../../../../assets/svgs/PlusIcon';
import {PRICE_RULE_TYPES} from '@bill-app-types/b-types';
import {useEffect, useRef, useState} from 'react';
import {InputLabel} from '../../../../../components/atoms/InputLabel';
import {CheckIcon} from '../../../../../assets/svgs/CheckIcon';
import {useQueryClient} from '@tanstack/react-query';
import {IPriceRule, updatePriceRule} from '../../../../../api/fetchPriceRules';
import {PercentIcon} from '../../../../../assets/svgs/PercentIcon';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {helperFormatPriceRuleAmount} from '../../../../../helpers/helperPriceRule';
import {EqualIcon} from '../../../../../assets/svgs/EqualIcon';
import {MinusIcon} from '../../../../../assets/svgs/MinusIcon';
import {helperFormatPrice} from '../../../../../helpers/helperFormatPrice';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';

export const ColumnAmount = ({row}: {row: IPriceRule}) => {
  const [showInput, setShowInput] = useState(false);
  const [value, setValue] = useState<string | number>(row?.amount);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const {brandHasAuthorization} = useBrandAccess();

  const clientQuery = useQueryClient();

  const handleToggleShowInput = () => {
    if (!brandHasAuthorization(BRAND_AUTHORIZATION.PARENT)) return;

    setShowInput(!showInput);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    const valueNumber = Number(value);
    const isNegative = row.amount < 0;

    setValue(helperFormatPriceRuleAmount(valueNumber, isNegative ? '-' : '+'));
  };

  const handleSubmit = async () => {
    try {
      if (!row.uuid || row.amount === Number(value)) {
        return;
      }

      setIsLoading(true);

      const res = await updatePriceRule(row.uuid, {
        amount: Number(value) * 100,
      });

      if (res.status === 204) {
        await clientQuery.invalidateQueries(['getPriceRules']);
        setShowInput(false);
        helperDisplayToast('Règle de prix mise à jour avec succès', true);
      }
    } catch (error) {
      helperDisplayToast('Une erreur est survenue', false);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    setValue(Number(helperFormatPrice(row?.amount, 'en')) || 0);
  }, [row]);

  return (
    <div css={styles.rulesPrice} className="flex">
      <SquareButton2
        isActive={true}
        Icon={
          row.type === PRICE_RULE_TYPES.EQUAL
            ? EqualIcon
            : row.amount > 0
            ? PlusIcon
            : MinusIcon
        }
      />
      <div css={styles.amount(showInput)} onClick={handleToggleShowInput}>
        <TextVariant
          variant="textSemiBold"
          text={helperFormatPrice(row?.amount, 'en')}
        />
      </div>

      <div
        css={styles.inputContainer(showInput)}
        onClick={handleToggleShowInput}>
        <InputLabel
          label=""
          name="amount"
          required
          value={value}
          onChange={handleChange}
          marginBottom={0}
          ref={inputRef}
          type="number"
        />
      </div>

      {showInput && (
        <div css={styles.btnCheck}>
          <SquareButton2
            isActive={true}
            onClick={handleSubmit}
            Icon={CheckIcon}
            disabled={isLoading}
            bgColor={
              row.amount !== Number(value) && !isLoading
                ? THEME.colors.green
                : THEME.colors.bgLight
            }
            color={'white'}
          />
        </div>
      )}

      <SquareButton2
        isActive={true}
        Icon={
          [PRICE_RULE_TYPES.ADDITION, PRICE_RULE_TYPES.EQUAL].includes(row.type)
            ? EuroLargeIcon
            : PercentIcon
        }
      />
    </div>
  );
};

const styles = {
  rulesPrice: css({
    position: 'relative',
    minWidth: 120,
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.7rem',
  }),
  amount: (showInput: boolean) =>
    css({
      flex: 1,
      opacity: showInput ? 0 : 1,
      position: showInput ? 'absolute' : 'relative',
      textAlign: 'center',
      transition: 'opacity 0.5s',
      '&:hover': {
        color: THEME.colors.primary,
      },
    }),

  inputContainer: (showInput: boolean) =>
    css({
      width: showInput ? '50%' : 0,
      opacity: showInput ? 1 : 0,
      position: showInput ? 'relative' : 'absolute',
      right: showInput ? 'unset' : 0,
      transition: 'width 0.5s, opacity 0.5s, right 0.4s',
      '& input': {
        backgroundColor: THEME.colors.bgLight,
        height: 30,
        textAlign: 'center',
      },
    }),

  btnCheck: css({
    transition: 'transform 0.2s',
    '&:active': {
      transform: 'scale(1.2)',
      transition: 'transform 0.2s',
    },
  }),
};

import {helperJustLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';

interface IEntity {
  name: string;
  channels: number[];
}

export const getPosEntities = async () => {
  //
  console.log('### getPosEntities ###');
  const res: any = await axiosInstance
    .get(`/pos/backoffice/pos-entities`)
    .catch(err => {
      console.log('❌ getPosEntities : ', err);
    });
  return res?.data?.data;
};

export const createPosEntity = async (brandId: number , data: IEntity) => {
  //
  helperJustLog('### createPosEntity ###');
  const res: any = await axiosInstance
    .post(`/pos/backoffice/${brandId}/pos-entities`, data)
    .catch(err => {
      console.log('❌ createPosEntity : ', err);
    });
  return res;
};

export const updatePosEntity = async (brandId: number, posEntityUuid: string, data: IEntity) => {
  //
  helperJustLog('### updatePosEntity ###');
  const res: any = await axiosInstance
    .put(`/pos/backoffice/${brandId}/pos-entities/${posEntityUuid}`, data)
    .catch(err => {
      console.log('❌ updatePosEntity : ', err);
    });
  return res;
};

export const deletePosEntity = async (brandId: number, posEntityUuid: string) => {
  //
  helperJustLog('### deletePosEntity ###');
  const res: any = await axiosInstance
    .delete(`/pos/backoffice/${brandId}/pos-entities/${posEntityUuid}`)
    .catch(err => {
      console.log('❌ deletePosEntity : ', err);
    });
  return res;
};

export const createPosEntityChannel = async (brandId: number , posEntityUuid: string, channelId: number) => {
  //
  helperJustLog('### createPosEntityChannel ###');
  const res: any = await axiosInstance
    .post(`/pos/backoffice/${brandId}/pos-entities/${posEntityUuid}/channels/${channelId}`)
    .catch(err => {
      console.log('❌ createPosEntityChannel : ', err);
    });
  return res;
};

export const deletePosEntityChannel = async (brandId: number , posEntityUuid: string, channelId: number) => {
  //
  helperJustLog('### deletePosEntityChannel ###');
  const res: any = await axiosInstance
    .delete(`/pos/backoffice/${brandId}/pos-entities/${posEntityUuid}/channels/${channelId}`)
    .catch(err => {
      console.log('❌ deletePosEntityChannel : ', err);
    });
  return res;
};
/** @jsxImportSource @emotion/react */
import {useEffect} from 'react';
import {useAppDispatch} from '../../../hooks/useReduxHook';
import {searchInputAction} from '../../../redux/posCategories';
import {FilterInput} from '../../atoms/FilterInput';
import {Title} from '../../atoms/Title';
import {ToggleButtonSectionsForCatalog} from '../../molecules/ToggleButtonSectionsForCatalog';

export const Header = () => {
  //TODO : discompose this component
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(searchInputAction(''));
    };
  }, []);

  return (
    <>
      <Title title="Catalogue de produits" />
      <div style={{paddingTop: 10}}>
        <ToggleButtonSectionsForCatalog
          sections={['Produits', 'Menus']}
          defaultSection={'Produits'}
          width={'100%'}
        />
        <FilterInput
          width={'100%'}
          name="search"
          marginRight={'1vh'}
          placeholder="Rechercher..."
          onChange={(e: any) => dispatch(searchInputAction(e.target.value))}
        />
      </div>
    </>
  );
};

export const helperTaxType = (tax: any) => {
  const parsedTax = Number(tax);
  let type = 1;
    if (parsedTax === 550) {
      type = 1;
    } else if (parsedTax === 1000) {
      type = 2;
    } else if (parsedTax === 2000) {
      type = 3;
    }
  return type;
};

export interface ITaxToObject {
  [key: string]: number | string;
}
export const helperTaxToArr = (taxStr: string) => {
  if (!taxStr) {
    return [];
  }

  let taxs: ITaxToObject[];

  taxs = taxStr
    // remove empty space and split by |
    .replace(/ /gi, '')
    .split('|')
    .map((el: string) => {
      let elem = el.trim().split(':');
      return {
        type: elem[0],
        value: Number(elem[1]),
      };
    });

  return taxs;
};

export const helperGenerateTaxs = (taxInclStr: string, taxExclStr: string) => {
  const taxIncl = helperTaxToArr(taxInclStr);
  const taxExcl = helperTaxToArr(taxExclStr);

  return taxIncl.map((tax: any, index: number) => {
    return {
      type: tax.type,
      value: Number(tax.value) - Number(taxExcl[index]?.value || 0),
    };
  });
};

export const helperGenerateSqsTaxs = (sqsReceiptsLineHistory: any[]) => {
  const taxs = [
    {type: 550, value: 0},
    {type: 1000, value: 0},
    {type: 2000, value: 0},
  ];

  for (let el of sqsReceiptsLineHistory) {
    const tax = Number(el.tva_rate);

    if (tax && Number(el.unit_price_ttc) !== 0) {
      if (tax === 550) {
        taxs[0].value += Number(el.amount_ttc);
      } else if (tax === 1000) {
        taxs[1].value += Number(el.amount_ttc);
      } else if (tax === 2000) {
        taxs[2].value += Number(el.amount_ttc);
      }
    }
  }

  return taxs?.filter(el => el.value !== 0);
};

export const helperTaxTypeTotal = (
  taxData: any,
  tax: number | string,
  type: string = 'taxTotal',
) => {
  return taxData[tax]?.reduce((accumulator: number, curValue: any) => {
    return accumulator + curValue[type];
  }, 0);
};

export const helperTaxTotal = (taxObject: ITaxToObject) => {
  let taxTotal: any = 0;
  for (let elem in taxObject) {
    taxTotal += taxObject[elem];
  }
  return taxTotal;
};

const helperCalculTva = (ttc: number, tax: number) => {
  const ttcRecalculated = ttc / 100;
  const taxReworked = 1 + tax / 10000;
  const ht = ttcRecalculated / taxReworked;
  const taxAmount = Number(ttcRecalculated - ht).toFixed(2);
  return Number(taxAmount);
};

const helperCalculTaxForProduct = (product: any, taxList: any) => {
  const totalPrice =
    (product.base_unit_price || product.baseUnitPrice) * product.quantity;

  // TODO temporary fix for product tax
  if (!taxList[product.tax]) {
    taxList[product.tax] = [];
  }

  if (
    (product.order_items && product?.order_items?.length) ||
    (product.billItems && product?.billItems?.length)
  ) {
    // WITH OPTION
    taxList[product.tax].push({
      tax: product.tax,
      unitPrice: product.base_unit_price || product.baseUnitPrice,
      totalPrice: totalPrice,
      quantity: product.quantity,
      taxTotal: helperCalculTva(totalPrice, product.tax),
    });

    if (product.type === 2) {
      const options = (product?.order_items || product?.billItems)
        .map((orderItem: any) => orderItem.order_items || orderItem.billItems)
        .flat();

      options.forEach((optionItem: any) => {
        const optTotalPrice =
          (optionItem?.base_unit_price || optionItem?.baseUnitPrice) *
          optionItem.quantity;
        taxList[optionItem.tax].push({
          tax: optionItem.tax,
          unitPrice: optionItem?.base_unit_price || optionItem?.baseUnitPrice,
          totalPrice: optTotalPrice,
          quantity: optionItem.quantity,
          taxTotal: helperCalculTva(optTotalPrice, optionItem.tax),
        });
      });
    }
  } else {
    // WITHOUT OPTIONS
    taxList[product.tax].push({
      tax: product.tax,
      unitPrice: product?.base_unit_price || product?.baseUnitPrice,
      totalPrice: totalPrice,
      quantity: product.quantity,
      taxTotal: helperCalculTva(totalPrice, product.tax),
    });
  }
};

export const taxArrayGenerator = (items: any) => {
  let taxList = {
    550: [],
    1000: [],
    2000: [],
  };
  if (items) {
    items.forEach((el: any) => {
      const orderItem = el;
      if (orderItem.type === 1 && orderItem.base_unit_price > 0) {
        helperCalculTaxForProduct(orderItem, taxList);
      }

      if (orderItem.type === 2) {
        helperCalculTaxForProduct(orderItem, taxList);
      } else {
        (orderItem.order_items || orderItem.billItems).forEach((el: any) => {
          const menuItem = el;
          helperCalculTaxForProduct(menuItem, taxList);
        });
      }
    });
  }

  return taxList;
};

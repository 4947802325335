import {Additional} from './AdditionalSale';
import {PriceRulesRouter} from './PriceRules';

export const MyOffers = {
  path: 'offers',
  children: [
    {
      path: 'additional',
      element: <Additional />,
    },
    PriceRulesRouter,
  ],
};

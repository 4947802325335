/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import {useContext, useEffect, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {filterDateScaleAction} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
import {FilterRadioButtons} from './FilterRadioButtons';
import {helperFindScales} from '../../helpers/helperScales';
import {SCALES_LABELS} from '../../constants/scales';

export const ScaleFilter = ({
  marginRight,
  withBorder = false,
}: {
  marginRight?: string;
  withBorder?: boolean;
}) => {
  const {dispatch} = useContext<any>(UtilsContext);

  const [selectedScale, setSelectedScale] = useState<any>({
    name: '',
    value: null,
  });
  const [forceClose, setForceClose] = useState(false);

  const dates = useAppSelector(s => s.filter.dates);
  const scale: any = useAppSelector(s => s.filter.scale);

  const diff = Math.abs(dates.from.getTime() - dates.to.getTime());

  const hours = Math.floor(diff / 1000 / 60 / 60);

  const scales = helperFindScales(hours).map(entry => ({
    name: SCALES_LABELS[entry],
    value: entry,
  }));

  const handleRadioChange = (e: any) => {
    const {name, value} = e.target;
    setSelectedScale({name, value});
  };

  const handleSubmit = () => {
    //
    dispatch(filterDateScaleAction(selectedScale.value));
    setForceClose(true);
  };

  useEffect(() => {
    if (scales && scales.length > 0) {
      if (scale === null) {
        dispatch(filterDateScaleAction(scales[0].value));
      }
    }
    return () => {
      setForceClose(false);
    };
  }, [scales, dispatch]);

  useEffect(() => {
    setSelectedScale({name: SCALES_LABELS[scale], value: scale});
  }, [scale]);

  return (
    <FilterButton
      marginRight={marginRight}
      label={'Voir par'}
      valueType="list"
      values={[{name: SCALES_LABELS[scale], value: scale}]}
      withBorder={withBorder}
      hideFilterIcon
      forceClose={forceClose}>
      <FilterRadioButtons
        title="Voir par"
        list={scales}
        selectedValue={selectedScale.value}
        onChange={handleRadioChange}
        onSubmit={handleSubmit}
      />
    </FilterButton>
  );
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {InputLabel} from '../../../../components/atoms/InputLabel';
import {TextAreaLabel} from '../../../../components/atoms/TextAreaLabel';

export const LeftBlock = ({
  formikValues,
  formikHandleChange,
}: {
  formikValues: any;
  formikHandleChange: (e: any) => void;
}) => {
  return (
    <div css={styles.container}>
      <InputLabel
        label="Nom de l'établissement"
        placeholder="Nom de l'établissement"
        value={formikValues?.brand_label}
        onChange={formikHandleChange}
        name={'brand_label'}
        required
      />
      <InputLabel
        label="Nom affichage client"
        placeholder="Nom affichage client"
        value={formikValues?.title}
        onChange={formikHandleChange}
        name={'title'}
        required
      />
      <TextAreaLabel
        label="Description"
        placeholder="Text"
        value={formikValues?.description}
        onChange={formikHandleChange}
        name={'description'}
        required
      />
    </div>
  );
};

const styles = {
  container: css(`
    width:33.33%;
    @media (max-width: 1024px) {
      width: 100%;
    }
  `),
};

import {PRINTER_TEMPLATES} from '@bill-app-types/b-types';
import {
  addPrinterConfigModalAction,
  deletePrinterConfigModalAction,
} from '../../../../../redux/modals';
import {configPrinterSelectedAction} from '../../../../../redux/myRestaurantSection/printers';
import {DataGridActions} from '../../../../../components/newDatagrid/DataGridActions';

export const columnsConfigurations = [
  {
    field: 'label',
    headerName: "Nom de l'imprimante",
    flex: 1,
  },
  {
    field: 'ip_address',
    headerName: 'Adresse IP',
    flex: 1,
  },
  {
    field: 'printer_template_id',
    headerName: 'Déclenchement',
    flex: 1,
    valueGetter: (row: any) => {
      const template = PRINTER_TEMPLATES.find(
        item => item.id === parseInt(row.printer_template_id),
      );
      return template?.label;
    },
  },
  {
    field: 'printer_type_id',
    headerName: 'Type de ticket',
    flex: 1,
    valueGetter: (row: any, t: any) => {
      const template = PRINTER_TEMPLATES.find(
        item => item.id === parseInt(row.printer_template_id),
      );
      return t(template?.type);
    },
  },
  {
    field: '',
    headerName: '',
    flex: 0,
    renderCell: (row: any) => {
      const handleClickDelete = ({dispatch}: any) => {
        dispatch(configPrinterSelectedAction(row));
        dispatch(deletePrinterConfigModalAction(true));
      };

      const handleClickEdit = ({dispatch}: any) => {
        dispatch(configPrinterSelectedAction(row));
        dispatch(addPrinterConfigModalAction(true));
      };

      return <DataGridActions {...{handleClickDelete, handleClickEdit}} />;
    },
  },
];

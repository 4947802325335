/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import {DateTimeInput} from '../atoms/DateTimeInput';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../styles/calendarCustom.css';
import {PrevIcon} from '../../assets/svgs/PrevIcon';
import {NextIcon} from '../../assets/svgs/NextIcon';
import {TextVariant} from './TextVariant';
import {
  helperDate,
  helperResetDate,
  helperSameHour,
} from '../../helpers/helperDate';
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {useDispatch} from 'react-redux';
import {
  fromDateAction,
  fromTimeAction,
  periodTypeAction,
  toTimeAction,
} from '../../redux/date';
import {useAppSelector} from '../../hooks/useReduxHook';
import {BASE_HOUR} from '../../constants/global';
import {useEffect} from 'react';

const LabelsList = [
  {label: 'Jour', value: 'day'},
  {label: 'Semaine', value: 'week'},
  {label: 'Mois', value: 'month'},
  {label: 'Année', value: 'year'},
] as const;

type PeriodLabelType = (typeof LabelsList)[number]['value'];

export const PeriodDate = () => {
  const dispatch = useDispatch();
  const labelSelected = useAppSelector(s => s.date.periodType);
  const date = useAppSelector(s => s.date.fromDate);
  const startTime = useAppSelector(s => s.date.fromTime);
  const endTime = useAppSelector(s => s.date.toTime);
  const dates = useAppSelector(s => s.filter.dates);

  useEffect(() => {
    if (endTime?.getHours() === 0) {
      dispatch(toTimeAction(BASE_HOUR));
    }
  }, [dispatch]);

  const handleSelectLabel = (label: PeriodLabelType) => {
    dispatch(periodTypeAction(label));
  };

  const handleChangeTime = (
    date: Date | null,
    type: 'from' | 'to' = 'from',
  ) => {
    if (type === 'from') {
      dispatch(fromTimeAction(date));
    } else {
      dispatch(toTimeAction(date));
    }
  };

  const handleChangeDate = (date: Date | null) => {
    dispatch(fromDateAction(date));
  };

  const calendarViewType =
    ['day', 'week'].includes(labelSelected) || !labelSelected
      ? 'month'
      : labelSelected === 'year'
      ? 'decade'
      : 'year';

  return (
    <div>
      <div css={styles.periodLabels}>
        {LabelsList.map((item, index) => {
          return (
            <div
              key={index}
              css={[
                styles.periodLabel,
                labelSelected === item.value && styles.periodLabelActive,
              ]}
              onClick={() => handleSelectLabel(item.value)}>
              <TextVariant text={item.label} variant="textSemiBold" />
            </div>
          );
        })}
      </div>

      <div css={styles.periodCalendars}>
        <Calendar
          prevLabel={<PrevIcon />}
          nextLabel={<NextIcon />}
          formatMonth={(locale, date) =>
            helperDate(date || new Date(), 'MMM') === 'juin'
              ? 'juin'
              : helperDate(date || new Date(), 'MMM').slice(0, 3)
          }
          formatShortWeekday={(locale, date) => helperDate(date, 'dd')}
          view={calendarViewType}
          onClickDecade={date => handleChangeDate(date)}
          onClickMonth={date => handleChangeDate(date)}
          onClickYear={date => handleChangeDate(date)}
          onClickDay={date => handleChangeDate(date)}
          value={
            labelSelected === 'week'
              ? [
                  date,
                  helperResetDate(date || new Date())
                    .add(+6, 'day')
                    .toDate(),
                ]
              : date
          }
        />
      </div>

      {!['month', 'year'].includes(labelSelected) && (
        <div css={styles.periodTimers}>
          <TextVariant
            text="Heure"
            variant="textSemiBold"
            style={{
              margin: '0.5rem 0',
            }}
          />
          <div className="flex-align-center">
            <TextVariant
              text="De"
              variant="textSemiBold"
              style={{
                marginRight: '0.5rem',
              }}
            />
            <DateTimeInput
              time={startTime}
              handleChangeTime={date => handleChangeTime(date)}
              isSameTime={helperSameHour(dates.from, startTime || new Date())}
            />
            <TextVariant
              text="A"
              variant="textSemiBold"
              style={{
                margin: '0 0.5rem',
              }}
            />
            <DateTimeInput
              time={endTime}
              handleChangeTime={date => handleChangeTime(date, 'to')}
              isSameTime={helperSameHour(dates.to, endTime || new Date())}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  periodLabels: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  periodLabel: css({
    backgroundColor: THEME.colors.bgLight,
    color: THEME.colors.primary,
    textTransform: 'uppercase',
    padding: '0.3rem 0.4rem',
    cursor: 'pointer',
    borderRadius: 2,
  }),
  periodLabelActive: css({
    backgroundColor: THEME.colors.primary,
    color: THEME.colors.white,
  }),
  periodCalendars: css({
    display: 'flex',
  }),
  periodTimers: css({
    display: 'flex',
    flexDirection: 'column',
  }),
};

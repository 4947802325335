/* @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {CompareValues} from '../../../../../components/molecules/CompareValues';
import {PillLabel} from '../../../../../components/molecules/PillLabel';
import {THEME} from '../../../../../styles/theme';
import {M_SCREEN_DESKTOP} from '../../../../../constants/ResponsiveSize';

export const TurnOverProductsColumns = [
  {
    headerName: 'PRODUIT',
    flex: 1,
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      return (
        <div className="flex" css={styles.product}>
          <PillLabel
            text={String(row.itemName || '').toLowerCase()}
            variant="textSemiBold"
            backgroundColor={row.color}
            textTransform="capitalize"
          />
        </div>
      );
    },
  },
  {
    headerName: 'CA TTC',
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      return <CompareValues row={row} compareKey="sales" />;
    },
  },
  {
    headerName: 'NB COMMANDES',
    headerColor: THEME.colors.lightGray,
    renderCell: (row: any) => {
      return <CompareValues row={row} compareKey="orders" />;
    },
  },
];

const styles = {
  product: css({
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: '1rem',
    [M_SCREEN_DESKTOP]: {
      paddingLeft: '0.5rem',
    },
  }),
};

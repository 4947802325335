import {combineReducers} from '@reduxjs/toolkit';
import users from './users';
import groups from './groups';

const myStaffSection = combineReducers({
  users,
  groups,
});

export default myStaffSection;

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TranslationLangImg} from '../../../../../components/molecules/TranslationLangImg';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {TextVariant} from '../../../../../components/atoms/TextVariant';
import {THEME} from '../../../../../styles/theme';
import {WarningCircle} from '../../../../../assets/svgs/WarningCircle';
import {useEffect, useRef, useState} from 'react';
import {
  ITranslationCommon,
  updateBcoreTranslation,
  updateTranslation,
} from '../../../../../api/fetchTranslations';
import {useTranslations} from '../../../../../hooks/useTranslations';

const TEXT_LENGTH = 500;

export const TranslateInput = ({
  disabled,
  item,
  labelType = 'label',
  category,
  categoryType,
  label,
}: {
  disabled?: boolean;
  item: ITranslationCommon;
  labelType?: any;
  category?: string;
  categoryType?: number;
  label?: string;
}) => {
  const [value, setValue] = useState('');
  const languageSelected = useAppSelector(
    s => s.myTranslationsSection.translations.languageSelected,
  );

  const timeRef = useRef<NodeJS.Timeout | null>(null);

  const {invalidQuery} = useTranslations();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timeRef.current) {
      clearTimeout(timeRef.current);
    }

    const {value} = e.target;

    setValue(value);

    timeRef.current = setTimeout(async () => {
      if (item[labelType]?.translationUuid) {
        const data = {
          languageId: Number(languageSelected?.id),
          content: value,
        };

        if (
          category &&
          ['Bkiosk', 'Areas', 'BWebApp', 'BGlobal'].includes(category)
        ) {
          await updateBcoreTranslation(item[labelType]?.translationUuid, data);
        } else {
          await updateTranslation(item[labelType]?.translationUuid, data);
        }

        if (category) {
          invalidQuery(category, categoryType);
        }
      }
    }, 300);
  };

  useEffect(() => {
    if (item) {
      const label =
        disabled && labelType === 'label'
          ? item.defaultLabel
          : item[labelType]?.content?.find(
              l => l.languageId === Number(disabled ? 1 : languageSelected?.id),
            )?.content;

      setValue((label || '') as string);
    }
  }, [item, disabled, labelType, languageSelected]);

  const valueEmpty = !value.trim();

  return (
    <div css={styles.container}>
      <TextVariant
        variant="textMedium"
        style={styles.label(!!disabled)}
        text={label || ''}
      />

      <div css={styles.content}>
        {!disabled && languageSelected && (
          <TranslationLangImg language={languageSelected} marginTop="0.4vh" />
        )}
        <div css={styles.inputContainer}>
          <input
            id="input"
            type="text"
            css={styles.input({
              disabled: !!disabled,
              empty: valueEmpty && !disabled,
            })}
            value={value}
            disabled={disabled}
            onChange={handleChange}
            maxLength={TEXT_LENGTH}
          />

          {valueEmpty && !disabled && (
            <div css={styles.warning}>
              <WarningCircle />
            </div>
          )}
          {!disabled && (
            <TextVariant
              variant="title03"
              text={`${value.length}/${TEXT_LENGTH}`}
              style={styles.textLength}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    flex: 1,
    backgroundColor: 'var(--bg-color)',
  }),
  label: (disabled: boolean) =>
    css({
      color: '#626262',
      display: 'inline-block',
      opacity: disabled ? 1 : 0,
      marginBottom: '0.5vh',
    }),
  content: css({
    display: 'flex',
    alignItems: 'center',
    gap: '1vh',
    width: '100%',
  }),
  inputContainer: css({
    width: '100%',
    position: 'relative',
    display: 'flex',
  }),
  input: ({
    disabled,
    empty,
  }: Partial<{disabled: boolean; empty: boolean}> = {}) =>
    css({
      height: '40px',
      flex: 1,
      border: '1px solid',
      borderColor: empty ? THEME.colors.red : '#CCD6E0',
      borderRadius: 2,
      paddingLeft: '1vh',
      paddingRight: disabled ? '1vh' : '6.5vh',
      color: 'var(--text-color)',
      backgroundColor: disabled ? 'transparent' : 'var(--bg-main-color)',

      '&:disabled': {
        backgroundColor: 'transparent',
      },
    }),
  warning: css({
    position: 'absolute',
    right: '1.5vh',
    top: '-1vh',
    color: THEME.colors.gray2,
  }),
  textLength: css({
    position: 'absolute',
    right: '1vh',
    top: '30%',
    color: THEME.colors.gray2,
  }),
};

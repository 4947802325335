/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {FormInputs} from '../FormInputs';
import {UploadBackgroundPicture} from '../UploadBackgroundPicture';
import {IModuleParameter} from '../../../../api/fetchModuleDetails';
import {Sliders} from './Sliders';
import {ScreenSavers} from './ScreenSavers';

export const BKioskForm = ({
  form,
  onChange,
}: {
  form: IModuleParameter;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}) => {
  return (
    <div className="flex" css={styles.form}>
      {/* FORM INPUTS */}
      <FormInputs values={form} onChange={onChange} />

      {/* BACKGROUND PICTURE 100X100 */}
      <UploadBackgroundPicture
        title="BACKGROUND_IMAGE"
        name="background"
        images={form}
        onChange={onChange}
      />

      <UploadBackgroundPicture
        title="BACKGROUND_IMAGE_CATALOG"
        name="content_background"
        images={form}
        onChange={onChange}
      />

      {/* SLIDER IMAGES 100X100 */}
      <div css={styles.slidersImages}>
        <ScreenSavers brandId={form?.brand_id} id={String(form.id)} />
        <Sliders brandId={form?.brand_id} id={String(form.id)} />
      </div>
    </div>
  );
};

const styles = {
  form: css({
    gap: 20,
    width: '100%',
    alignItems: 'strech',
  }),
  slidersImages: css({
    width: '35%',
    display: 'flex',
    flexDirection: 'column',
  }),
};

import {IStatHourlyByDOW} from '../api/fetchStats';
import {daysList} from '../views/Online/MyStats/Sales/General/data';
import { helperFormatPriceRoundToNumber } from './helperFormatPrice';

export const helperCombineDataDOW = (
  data: IStatHourlyByDOW,
  data2: IStatHourlyByDOW,
) => {
  const d = {
    ...data,
    weekdays:
      data?.weekdays?.map((w, i) => {
        return {
          ...w,
          compareSales: data2.weekdays[i].sales,
          compareAverageCart: data2.weekdays[i].average_cart,
          compareOrders: data2.weekdays[i].orders,
          hours: w.hours.map((h, j) => {
            return {
              ...h,
              compareSales: data2.weekdays[i].hours[j].sales,
              compareOrders: data2.weekdays[i].hours[j].orders,
              compareAverageCart: data2.weekdays[i].hours[j].average_cart,
            };
          }),
        };
      }) || [],
  };

  return d;
};

export const helperWeekDaysWithWeekName = (
  data: IStatHourlyByDOW['weekdays'],
) => {
  return data?.map((w, i) => {
    return {
      ...w,
      sales: {
        ...w.sales,
        TTC: {
          ...w.sales.TTC,
          total: helperFormatPriceRoundToNumber(w.sales.TTC.total),
          average: helperFormatPriceRoundToNumber(w.sales.TTC.average),
        },
        HT: {
          ...w.sales.HT,
          total: helperFormatPriceRoundToNumber(w.sales.HT.total),
          average: helperFormatPriceRoundToNumber(w.sales.HT.average),
        },
      },
      weekName: daysList[i],
    };
  });
};

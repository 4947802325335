/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import Bottom from './Bottom';
import Top from './Top';
import {THEME} from '../../../../../../../styles/theme';
import {useAppDispatch} from '../../../../../../../hooks/useReduxHook';
import {addSelectedButtonsAction} from '../../../../../../../redux/myModulesSection/pos';

const ProductCard = ({item}: {item: any}) => {
  const dispatch = useAppDispatch();

  const handleClickCard = () => {
    if (!!item.type) return;
    dispatch(addSelectedButtonsAction(item));
  };
  return (
    <div
      onClick={handleClickCard}
      className="button-card--draggable"
      css={styles.container}>
      <Top item={item} />
      <Bottom item={item} />
    </div>
  );
};

export default ProductCard;

const styles = {
  container: css({
    backgroundColor: '#D7D7D7',
    // maxWidth: 200,
    // minHeight: 200,
    aspectRatio: '1/1',
    // width: 200,
    border: `2px solid ${THEME.colors.lightGray}`,
    borderRadius: 2,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
  }),
};

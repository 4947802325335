/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {CartIcon} from '../../../../../assets/svgs/CartIcon';
import {CreditCardIcon} from '../../../../../assets/svgs/CreditCardIcon';
import {FileIcon} from '../../../../../assets/svgs/FileIcon';
import {helperDate} from '../../../../../helpers/helperDate';
import {helperFormatPrice} from '../../../../../helpers/helperFormatPrice';
import {THEME} from '../../../../../styles/theme';
import {ValuesBlock} from './ValuesBlock';

export const DaysDetailsInfos = ({data}: {data?: any}) => {

  const everageCart = data?.totalAmount?.TTC / data?.nbOrders

  return (
    <div css={styles.container} className="flex-space-between">
      <div css={styles.sectionLeft} className="flex">
        <div css={styles.firstBlock}>
          <h1 className="title02">Exercice comptable</h1>
        </div>
        <div css={styles.block}>
          <p css={styles.status}>Ouvert</p>
          <p className="textSemiBold">{`${helperDate(
            data?.openedAt,
            'Le DD/MM/YYYY à HH:mm',
          )} par ${data?.openedByUserPos?.username}`}</p>
        </div>
        <div css={styles.block}>
          <p css={styles.status}>Fermé</p>
          <p className="textSemiBold">
            {!!data?.closedAt
              ? `${helperDate(data?.closedAt, 'Le DD/MM/YYYY à HH:mm')} par ${
                  data?.closedByUserPos?.username
                }`
              : 'Non fermé'}
          </p>
        </div>
      </div>
      <div css={styles.sectionRight} className="flex">
        <ValuesBlock
          title={"Chiffre d'affaire"}
          value={`${helperFormatPrice(data?.totalAmount?.TTC)}€`}
          icon={<CreditCardIcon />}
          color={THEME.colors.primary}
        />
        <ValuesBlock
          title={'Commandes'}
          value={data?.nbOrders}
          icon={<FileIcon />}
          color={'#F7C761'}
        />
        <ValuesBlock
          title={'Panier moyen'}
          value={`${helperFormatPrice(everageCart || 0)}€`}
          icon={<CartIcon />}
          color={THEME.colors.green}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    height: '10vh',
    borderRadius: 2,
    marginLeft: '2vh',
    marginRight: '2vh',
    borderBottom: '5px solid' + THEME.colors.bgLight,
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
      justifyContent:'space-around',
      height: '12vh',
    },
  }),
  sectionLeft: css({
    width: '50%',
    '@media (max-width: 1024px)': {
      width: '100%',
      justifyContent: 'space-around',
    },
  }),
  sectionRight: css({
    width: '50%',
    justifyContent: 'flex-end',
    '@media (max-width: 1024px)': {
      width: '100%',
      justifyContent: 'space-around',
    },
  }),
  firstBlock: css({
    paddingLeft: '5%',
    alignItems: 'center',
    display: 'flex',
    '@media (max-width: 1024px)': {
      paddingLeft: 0
    }
  }),
  block: css({
    paddingLeft: '5%',
    '@media (max-width: 1024px)': {
      paddingLeft: 0
    }
  }),
  status: css({
    fontSize: 12,
    fontWeight: '500',
    color: THEME.colors.gray,
    marginBottom: 5,
  }),
};

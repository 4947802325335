import {IGetPaymentSettingsResponse, IUpdatePaymentSettingsPayload} from '@bill-app-types/b-types';
import {helperJustLog} from '../helpers/helperLog';
import {axiosInstance} from './axiosInstance';

const baseUrl = '/border/backoffice/payment-settings';

export const getPaymentSettings = async (): Promise<IGetPaymentSettingsResponse[]> => {
  //
  helperJustLog('### getPaymentSettings ###');
  const res = await axiosInstance
    .get<{ data: IGetPaymentSettingsResponse[] }>(`${baseUrl}`)
    .catch(err => {
      console.log('❌ getPaymentSettings : ', err);
    });
  return res?.data.data || [];
};

export const updatePaymentSetting = async (paymentSettingUuid: string, body: IUpdatePaymentSettingsPayload) => {
  //
  helperJustLog('### updatePaymentSetting ###');
  const res = await axiosInstance
    .put(`${baseUrl}/${paymentSettingUuid}`, body)
    .catch(err => {
      console.log('❌ updatePaymentSetting : ', err);
    });
  return res;
};
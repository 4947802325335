/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {InputLabel} from '../../../../components/atoms/InputLabel';
import {UploadButton} from '../../../../components/atoms/UploadButton';
import {SelectList} from '../../../../components/molecules/SelectList';
import {useLanguages} from '../../../../hooks/useLanguages';
import {useBrandAccess} from '../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../constants/frontPolicies';

export const RightBlock = ({
  formikValues,
  formikHandleChange,
  setFieldValue,
}: {
  formikValues: any;
  formikHandleChange: (e: any) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}) => {
  const {languages} = useLanguages();
  const {brandHasAuthorization} = useBrandAccess();
  return (
    <div css={styles.container}>
      <InputLabel
        label="Mail"
        placeholder="Mail"
        value={formikValues?.email}
        onChange={formikHandleChange}
        name="email"
        required
      />
      <InputLabel
        label="Téléphone fixe"
        placeholder="Téléphone fixe"
        value={formikValues?.phone}
        onChange={formikHandleChange}
        name="phone"
        required
      />

      <SelectList
        label="Langue"
        name="language_id"
        value={formikValues?.language_id || ''}
        placeHolder="Selectionner une langue"
        manageByFormik={false}
        onChange={formikHandleChange}
        list={languages?.map((language: any) => ({
          value: language.id,
          label: language.label,
        }))}
        required
      />
      {brandHasAuthorization(BRAND_AUTHORIZATION.PARENT) && (
        <div css={styles.section}>
          <UploadButton
            label="Icône de l'établissement"
            width={'49%'}
            value={formikValues?.asset?.icon}
            handleChange={(event: any) =>
              setFieldValue('asset.icon', event.target.files[0])
            }
            name="icon"
            required
          />
          <UploadButton
            label="Logo de l'établissement"
            width={'49%'}
            // name
            value={formikValues?.asset?.logo}
            handleChange={(event: any) => {
              setFieldValue('asset.logo', event.target.files[0]);
            }}
            name="logo"
            required
          />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: css(`
    width:33.33%;
    @media (max-width: 1024px) {
      width: 100%;
    }
  `),
  section: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
};

import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  updateMode: boolean;
  updatedPosUser: any;
  updatedPosTerminal: any;
  updatedPosEntity: any;
  updatedPosStructure: any;
  updatedPosCategory: any;
  updatedPosCategoryItem: any;
  treePreRegister: any;
  selectedPosCategory: any;
  selectedArticleForButton: any;
  selectedButtons: any;
}

const initialState: ISlice = {
  updateMode: false,
  updatedPosUser: null,
  updatedPosTerminal: null,
  updatedPosEntity: null,
  updatedPosStructure: null,
  updatedPosCategory: null,
  updatedPosCategoryItem: null,
  treePreRegister: null,
  selectedPosCategory: null,
  selectedArticleForButton: null,
  selectedButtons: [],
};

const subscriptionsSlice = createSlice({
  name: 'pos',
  initialState: initialState,
  reducers: {
    updateModeAction: (state, action) => {
      state.updateMode = action.payload;
    },
    updatedPosUserAction: (state, action) => {
      state.updatedPosUser = action.payload;
    },
    updatedPosStructureAction: (state, action) => {
      state.updatedPosStructure = action.payload;
    },
    treePreRegisterAction: (state, action) => {
      state.treePreRegister = action.payload;
    },
    selectedPosCategoryAction: (state, action) => {
      state.selectedPosCategory = action.payload;
    },
    updatedPosCategoryAction: (state, action) => {
      state.updatedPosCategory = action.payload;
    },
    updatedPosCategoryItemAction: (state, action) => {
      state.updatedPosCategoryItem = action.payload;
    },
    selectedArticleForButtonAction: (state, action) => {
      state.selectedArticleForButton = action.payload;
    },
    selectedButtonsAction: (state, action) => {
      state.selectedButtons = action.payload;
    },
    addSelectedButtonsAction: (state, action) => {
      state.selectedButtons = [...state.selectedButtons, action.payload];
    },
    resetSelectedButtonsAction: (state) => {
      state.selectedButtons = [];
    },
    updatedPosTerminalAction: (state, action) => {
      state.updatedPosTerminal = action.payload;
    },
    updatedPosEntityAction: (state, action) => {
      state.updatedPosEntity = action.payload;
    },
  },
});

export const {
  updateModeAction,
  updatedPosUserAction,
  updatedPosStructureAction,
  treePreRegisterAction,
  selectedPosCategoryAction,
  updatedPosCategoryAction,
  updatedPosCategoryItemAction,
  selectedArticleForButtonAction,
  selectedButtonsAction,
  addSelectedButtonsAction,
  resetSelectedButtonsAction,
  updatedPosTerminalAction,
  updatedPosEntityAction,
} =
  subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {RequiredLabel} from '../atoms/RequiredLabel';
import {FilterCheckboxItem} from '../filters/FilterCheckboxItem';
import {useState} from 'react';
import {ArrowLeft} from '../../assets/svgs/ArrowLeft';
import {TextVariant} from '../atoms/TextVariant';
import {useTranslation} from 'react-i18next';
import {AbsoluteLoader} from '../layouts/AbsoluteLoader';
import {THEME} from '../../styles/theme';

type TListItem = {
  value: string | number;
  label: string;
};

interface ISelectListProps {
  label?: string;
  required?: boolean;
  name?: string;
  onChange?: (e: any) => void;
  placeHolder?: string;
  value?: string | number;
  list?: TListItem[];
  isLoading?: boolean;
  listChecked?: TListItem[];
  disabled?: boolean;
}

export const SelectMultiList = ({
  label = '',
  required = false,
  list = [],
  listChecked = [],
  isLoading = false,
  onChange = () => {},
  placeHolder,
  disabled = false,
}: ISelectListProps) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <div css={styles.container}>
      <RequiredLabel label={label} required={required} />
      <div css={styles.selectContainer(disabled)}>
        <div
          css={styles.selectItem}
          onClick={() => !disabled && setOpen(!open)}>
          <TextVariant
            variant="textSemiBold"
            text={
              listChecked.length > 0
                ? listChecked.map(e => e.label).join(' • ')
                : t(placeHolder || 'SELECT')
            }
            style={styles.listValues}
          />
          <span css={[styles.arrow, open && styles.arrowOpen]}>
            <ArrowLeft color="black" />
          </span>
        </div>
        {list.length > 0 && (
          <div css={[styles.dropDown, open && styles.dropDownOpen]}>
            <div css={styles.selectList}>
              <div css={styles.selectListContent}>
                {list.map((item, index) => {
                  return (
                    <FilterCheckboxItem
                      key={index}
                      entry={item}
                      handleCheckedChange={onChange}
                      checked={!!listChecked.find(e => e.value === item.value)}
                    />
                  );
                })}
              </div>
              {isLoading && <AbsoluteLoader />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
  }),
  selectContainer: (disabled: boolean) =>
    css({
      backgroundColor: THEME.colors.white,
      paddingRight: '8px',
      borderRadius: 2,
      cursor: 'pointer',
      opacity: disabled ? 0.5 : 1,
    }),
  selectItem: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 39,
    padding: '0 0.5rem',
  }),
  arrow: css({
    transform: 'rotate(180deg)',
    transition: 'transform 0.3s ease-in-out',
  }),
  arrowOpen: css({
    transform: 'rotate(270deg)',
    transition: 'transform 0.3s ease-in-out',
  }),
  dropDown: css({
    display: 'grid',
    gridTemplateRows: '0fr',
    transition: 'all 0.3s ease-in-out',
  }),
  dropDownOpen: css({
    gridTemplateRows: '1fr',
    transition: 'all 0.3s ease-in-out',
  }),
  selectListContent: css({
    padding: '0.2rem 0.8rem',
    maxHeight: '300px',
    overflowY: 'auto',
  }),
  selectList: css({
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    border: 'none',
    borderRadius: 2,
    backgroundColor: 'white',
  }),
  listValues: css({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
};

import {PayloadAction, createSlice} from '@reduxjs/toolkit';

interface ISlice {
  currentTheme: string;
  sectionShow: string;
}

const initialState: ISlice = {
  currentTheme: 'dark',
  sectionShow: '',
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    currentThemeAction(state, action: PayloadAction<ISlice['currentTheme']>) {
      state.currentTheme = action.payload;
    },
    sectionShowAction(state, action: PayloadAction<ISlice['sectionShow']>) {
      state.sectionShow = action.payload;
    },
  },
});

export const {currentThemeAction, sectionShowAction} = slice.actions;

export default slice.reducer;

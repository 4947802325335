import {SVGProps} from 'react';

export const CartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="23"
    viewBox="0 0 21 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.8928 6.90139C17.7498 6.63291 17.545 6.40918 17.2984 6.25201C17.0518 6.09484 16.7718 6.00961 16.4858 6.00462H5.89202L5.41199 3.97792C5.36349 3.78228 5.25529 3.61019 5.105 3.48969C4.95472 3.36919 4.7712 3.30737 4.58435 3.31431H2.92908C2.70957 3.31431 2.49906 3.40879 2.34385 3.57697C2.18864 3.74514 2.10144 3.97324 2.10144 4.21108C2.10144 4.44892 2.18864 4.67701 2.34385 4.84519C2.49906 5.01337 2.70957 5.10785 2.92908 5.10785H3.95535L6.23963 14.3087C6.28813 14.5043 6.39633 14.6764 6.54662 14.7969C6.6969 14.9174 6.88042 14.9793 7.06727 14.9723H14.516C14.6688 14.9718 14.8186 14.9255 14.9486 14.8384C15.0786 14.7514 15.1838 14.627 15.2526 14.4791L17.9673 8.59628C18.0849 8.32906 18.1397 8.03443 18.1267 7.73869C18.1137 7.44294 18.0333 7.15529 17.8928 6.90139ZM14.0029 13.1788H7.69627L6.34722 7.79816H16.4858L14.0029 13.1788Z"
      fill={props.color || '#61D295'}
    />
    <path
      d="M6.65344 19.456C7.33908 19.456 7.8949 18.8537 7.8949 18.1108C7.8949 17.3679 7.33908 16.7657 6.65344 16.7657C5.96781 16.7657 5.41199 17.3679 5.41199 18.1108C5.41199 18.8537 5.96781 19.456 6.65344 19.456Z"
      fill={props.color || '#61D295'}
    />
    <path
      d="M14.9299 19.456C15.6156 19.456 16.1714 18.8537 16.1714 18.1108C16.1714 17.3679 15.6156 16.7657 14.9299 16.7657C14.2443 16.7657 13.6885 17.3679 13.6885 18.1108C13.6885 18.8537 14.2443 19.456 14.9299 19.456Z"
      fill={props.color || '#61D295'}
    />
  </svg>
);

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
// import {useContext} from 'react';
// import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
// import {UtilsContext} from '../../../../../contexts/UtilsContext';

export const Top = () => {
  // const {t} = useContext<any>(UtilsContext);

  return (
    <div css={styles.container}>
      {/* <ButtonCustom label={t('EXPORT_DATA')} /> */}
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
    padding: '1rem 0',
    display: 'flex',
    justifyContent: 'flex-end',
  }),
};

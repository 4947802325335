/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Tooltip} from '@mui/material';
import {ZoneIcon} from '../../../../assets/svgs/ZoneIcon';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {ToggleButton} from '../../../../components/atoms/ToggleButton';
import {THEME} from '../../../../styles/theme';
import {DataGridActions} from '../../../../components/newDatagrid/DataGridActions';

export const columnsZones = [
  {
    field: 'icon',
    headerName: '',
    headerClassName: 'textRegular',
    renderCell: () => {
      return (
        <div
          className="flex-align-center"
          style={{
            width: '100%',
            padding: '0 0.5rem',
          }}>
          <div css={styles.icon} className="flex-center">
            <ZoneIcon />
          </div>
        </div>
      );
    },
    flex: 0.5,
  },
  {
    field: 'label',
    headerName: 'Nom',
    headerClassName: 'textRegular',
    flex: 1,
  },
  {
    field: 'location_count',
    headerName: 'Emplacement',
    headerClassName: 'textRegular',
    flex: 1,
  },
  {
    field: 'carte',
    headerName: 'Cartes',
    headerClassName: 'textRegular',
    flex: 1,
    renderCell: (row: any) => {
      return (
        <Tooltip
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'white',
                boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.17)',
                '& .MuiTooltip-arrow': {
                  color: 'white',
                },
              },
            },
          }}
          title={<CustomTooltip card_details={row.card_details} />}>
          <div className="flex-center">
            <span
              css={[
                styles.pill,
                {
                  backgroundColor: 'transparent',
                  color: 'black',
                },
              ]}
              className="flex-center title03">
              1
            </span>
            <span
              css={[
                styles.pill,
                {
                  backgroundColor: THEME.colors.red,
                },
              ]}
              className="flex-center title03">
              2
            </span>
            <span
              css={[
                styles.pill,
                {
                  backgroundColor: THEME.colors.green,
                },
              ]}
              className="flex-center title03">
              3
            </span>
          </div>
        </Tooltip>
      );
    },
    // tooltip: 'Nombre de cartes',
  },
  {
    field: 'isActive',
    headerName: 'Visibilité',
    headerClassName: 'textRegular',
    flex: 1,
    renderCell: (row: any) => (
      <ToggleButton active={!row.archived} onToggle={row.onToggle} />
    ),
  },
  {
    field: 'created_at',
    headerName: "Date d'ajout",
    headerClassName: 'textRegular',
    flex: 1,
  },
  {
    field: 'updated_at',
    headerName: 'Date de modification',
    headerClassName: 'textRegular',
    flex: 1,
  },
  {
    field: 'actions',
    headerName: '',
    headerClassName: 'textRegular',
    flex: 0,
    renderCell: (row: any) => (
      <DataGridActions
        handleClickEdit={row.onEdit}
        handleClickDelete={row.onDelete}
      />
    ),
  },
];

const CustomTooltip = ({card_details}: {card_details: any[]}) => {
  return (
    <div css={styles.tooltip}>
      <TextVariant
        text="Cartes"
        variant="textSemiBold"
        style={{
          color: 'var(--text-color)',
        }}
      />
      <ul css={styles.tooltip__cartList}>
        {card_details?.map((card, index) => (
          <li
            key={index}
            css={styles.tooltip__cartList__item(
              card.color || THEME.colors.green,
            )}>
            <TextVariant text={card.label} variant="textSemiBold" />
          </li>
        ))}
      </ul>
    </div>
  );
};

const styles = {
  icon: css({
    width: 45,
    height: 45,
    backgroundColor: THEME.colors.bgLight,
    // borderRadius: 25,
    borderRadius: 4,
  }),
  pill: css({
    width: 20,
    height: 20,
    backgroundColor: THEME.colors.bgLight,
    borderRadius: 20,
    marginRight: '2px',
    color: 'white',
  }),
  tooltip: css({
    backgroundColor: 'white',
    borderRadius: '5px',
    maxHeight: '200px',
    width: '165px',
    padding: '10px 5px',
  }),
  tooltip__cartList: css({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    color: 'black',
    overflowY: 'auto',
    maxHeight: '180px',

    '& li': {
      display: 'flex',
      alignItems: 'center',
      padding: '3px 0',

      color: '#A7A7AB',

      '&:before': {
        content: '""',
        display: 'inline-block',
        width: 10,
        height: 10,
        borderRadius: 10,
        marginRight: 5,
      },
    },
  }),
  tooltip__cartList__item: (color: string) =>
    css({
      '&:before': {
        backgroundColor: color,
      },
    }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {Title} from '../../../../../components/atoms/Title';
import {RecursiveCategoriesDropdown} from './RecursiveCategoriesDropdown';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {InputLabel} from '../../../../../components/atoms/InputLabel';
import {SelectList} from '../../../../../components/molecules/SelectList';
import {ICategoryFetchReturn, STRUCTURE_TYPES} from '@bill-app-types/b-types';
import {structureSelectedAction} from '../../../../../redux/myCatalogSection/categories';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {
  SortableListVerticalContainer,
  THandleDragAndDrop,
} from '../../../../../components/organisms/SortableListVerticalContainer';
import {updateCategoriesPosition} from '../../../../../api/fetchCategories';
import {helperDisplayToast} from '../../../../../helpers/helperToast';

export const RightBlock = ({
  categories,
}: {
  categories: ICategoryFetchReturn[];
}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const {brandHasAuthorization} = useBrandAccess();

  const showProducts = useAppSelector(s => s.posCategories.showProducts);
  const structureSelected = useAppSelector(
    s => s.myCatalogSection.categories.structureSelected,
  );

  const handleChange = (e: any) => {
    const {name, value} = e.target;
    dispatch(
      structureSelectedAction({
        ...structureSelected,
        [name]: value,
      }),
    );
  };

  const TYPES = Object.entries(STRUCTURE_TYPES)
    .slice(6)
    .map(item => ({
      label: t('STRUCTURE_' + item[0]),
      value: item[1],
    }));

  const structureTypes = TYPES.filter((item: any) =>
    [1, 4].includes(item.value),
  );

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const handleDragAndDrop: THandleDragAndDrop = async ({items}) => {
    try {
      if (!items) {
        return;
      }

      const categoryUuids = items?.map(item => item?.uuid);

      const res = await updateCategoriesPosition(categoryUuids);

      if (res?.status === 204) {
        helperDisplayToast('Position modifiée', true);
      } else {
        helperDisplayToast(
          res?.data?.message || 'Impossible de modifier la position',
          false,
        );
      }
    } catch (error: any) {
      helperDisplayToast(error?.response?.data?.message || t('ERROR'), false);
    }
  };

  return (
    <div
      css={[
        styles.container,
        {
          width: showProducts ? '49%' : '97%',
        },
      ]}>
      <Title title={t('PRODUCTS_CATEGORIES')} />
      <div className="flex-space-between" css={styles.form}>
        <InputLabel
          value={structureSelected?.label}
          label="Nom de l'arborescence"
          name="label"
          marginBottom={0}
          onChange={handleChange}
          disabled={!hasAuthorization}
        />
        <SelectList
          value={structureSelected?.type}
          label="Type"
          name="type"
          manageByFormik={false}
          list={structureTypes}
          disabled={true}
        />
      </div>
      <div css={styles.content}>
        {categories && (
          <SortableListVerticalContainer
            data={categories}
            ItemComponent={RecursiveCategoriesDropdown}
            handleDragAndDrop={handleDragAndDrop}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    height: '87%',
  }),
  content: css({
    paddingTop: 10,
    overflow: 'auto',
    height: '95%',
  }),
  form: {
    gap: '1vh',
  },
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Title} from '../../../../../components/atoms/Title';
import {DataGridFooter} from '../../../../../components/newDatagrid/DataGridFooter';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {columnsTVA} from './columns';
import {helperFormatPrice} from '../../../../../helpers/helperFormatPrice';
import {useSqsTva} from '../../../../../hooks/useSqsTva';

export const SectionTva = () => {
  const {data, isLoading} = useSqsTva();

  const rows = data || [];

  return (
    <div css={styles.container}>
      <Title title="Ventilation par TVA" />
      <NewDataGrid
        columns={columnsTVA}
        rows={rows}
        rowHeight={35}
        loading={isLoading}
        minHeight={100}
        footer={
          <DataGridFooter
            columns={[
              'TOTAL',
              helperFormatPrice(
                rows?.reduce((t, row) => t + Number(row.amount_ht), 0),
              ),
              helperFormatPrice(
                rows?.reduce((t, row) => t + Number(row.amount_ttc), 0),
              ),

              `${rows?.reduce((t, row) => t + Number(row.total_receipts), 0)}`,
            ]}
            height={35}
          />
        }
        stickyFooter
      />
    </div>
  );
};

const styles = {
  container: css({
    flex: 1,
    height: '45%',
    '@media (max-width: 1024px)': {
      flex: 'none',
      marginBottom: '5%',
    },
  }),
};

/**  @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {FieldsRequired} from '../../../../../components/atoms/FieldsRequired';
import {InputLabel} from '../../../../../components/atoms/InputLabel';
import {Title} from '../../../../../components/atoms/Title';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {productPreRegisterChangeAction} from '../../../../../redux/myCatalogSection/products';
import {helperFormatSelectItem} from '../../../../../helpers/helperFormatSelectItem';
import {useTranslation} from 'react-i18next';
import {SelectMultiList} from '../../../../../components/molecules/SelectMultiList';
import {useItemCard} from '../../../../../hooks/useItemCard';
import {ICardItem} from '@bill-app-types/b-types';
import {createCardItem, deleteCardItem} from '../../../../../api/fetchCards';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useAreaProduction} from '../../../../../hooks/useAreaProduction';
import {SelectList} from '../../../../../components/molecules/SelectList';

export const Classification = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const clientQuery = useQueryClient();

  const {brandHasAuthorization} = useBrandAccess();

  const productPreRegister = useAppSelector(
    s => s.myCatalogSection.products.productPreRegister,
  );

  const {data: areaProductions} = useAreaProduction();

  const {data: itemCards} = useItemCard(productPreRegister?.uuid);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;

    dispatch(
      productPreRegisterChangeAction({
        name,
        value,
      }),
    );
  };

  const handleClickCard = async (item: any) => {
    const isCardItemExist = itemCards?.find(
      (card: ICardItem) => card.uuid === item.target.value,
    )?.active;
    const res = isCardItemExist
      ? await deleteCardItem(item.target.value, productPreRegister?.uuid)
      : await createCardItem({
          cardUuid: item.target.value,
          itemUuid: productPreRegister?.uuid,
        });

    const successMessage =
      res?.status === 204
        ? res?.data?.message || 'Cartes modifiées'
        : 'Impossible de modifier les cartes';
    const isSuccess = res?.status === 204;

    helperDisplayToast(successMessage, isSuccess);
    clientQuery.invalidateQueries(['getItemCards']);
  };

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <div css={styles.container}>
      <Title title="Classification" />
      <div css={styles.content}>
        <div css={styles.section}>
          <SelectList
            label={t('PRODUCTION_AREA')}
            placeHolder={t('PRODUCTION_AREA')}
            name="productionAreaUuid"
            value={productPreRegister?.productionAreaUuid}
            list={areaProductions?.map(helperFormatSelectItem)}
            onChange={handleChange}
            manageByFormik={false}
            disabled={!hasAuthorization}
          />

          <SelectMultiList
            label={t('CARDS')}
            onChange={handleClickCard}
            list={itemCards?.map(helperFormatSelectItem)}
            listChecked={itemCards
              ?.filter((card: any) => card.active)
              ?.map(helperFormatSelectItem)}
            disabled={!hasAuthorization}
          />
        </div>
        <div css={styles.section}>
          <InputLabel
            label={t('BACKOFFICE_DISPLAY_NAME')}
            value={productPreRegister?.backofficeLabel}
            name="backofficeLabel"
            onChange={handleChange}
            required
            disabled={!hasAuthorization}
          />
          <InputLabel
            label={t('PRODUCTION_DISPLAY_NAME')}
            required
            value={productPreRegister?.productionLabel}
            onChange={handleChange}
            name="productionLabel"
            disabled={!hasAuthorization}
          />
          <FieldsRequired />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    marginTop: '1.5rem',
  }),
  content: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  section: css({
    width: '49%',
  }),
};

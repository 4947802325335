import {useQuery} from '@tanstack/react-query';
import {getCustomers} from '../api/fetchCustomers';
import {useAppSelector} from './useReduxHook';
import {useDebounce} from './useDebounce';
import {useCustomerFilter} from './useCustomerFilter';

export const useCustomer = (offset: number, limit: number) => {
  const search = useAppSelector(s => s.filter.search);
  const {
    ordersFrom,
    ordersTo,
    loyaltyPointsFrom,
    lastOrderDateTo,
    creationDateFrom,
    creationDateTo,
    totalSpentTo,
    totalSpentFrom,
    loyaltyPointsTo,
    lastOrderDateFrom,
    customerActive,
    orderBy,
    birthdateFrom,
    birthdateTo,
  } = useCustomerFilter();

  const debouncedSearchTerm = useDebounce(search, 500);

  const {data, isLoading, error} = useQuery(
    [
      'getCustomers',
      debouncedSearchTerm,
      offset,
      limit,
      ordersFrom,
      ordersTo,
      loyaltyPointsFrom,
      loyaltyPointsTo,
      totalSpentFrom,
      totalSpentTo,
      creationDateFrom,
      creationDateTo,
      lastOrderDateFrom,
      lastOrderDateTo,
      customerActive,
      birthdateFrom,
      birthdateTo,
      orderBy,
    ],
    () =>
      getCustomers({
        search: debouncedSearchTerm,
        offset,
        limit,
        ordersFrom,
        ordersTo,
        loyaltyPointsFrom,
        loyaltyPointsTo,
        totalSpentFrom,
        totalSpentTo,
        creationDateFrom,
        creationDateTo,
        lastOrderDateFrom,
        lastOrderDateTo,
        birthdateFrom,
        birthdateTo,
        isActive: customerActive,
        orderBy,
      }),
  );

  const newData = data?.customers?.map((item: any) => ({
    ...item,
    isActive: item?.nbOrder > 0,
  }));

  return {
    data: newData,
    totalCustomers: data?.totalCustomers,
    isLoading,
    error,
  };
};

import {helperDate} from './helperDate';

export const helperGenerateQuery = (data: any, options?: any) => {
  let query = '?';
  for (const key in data) {
    const notNullValue = data[key] || data[key] === 0 || key === 'isActive';
    if (
      (data.hasOwnProperty(key) || notNullValue) &&
      (Array.isArray(data[key]) ? data[key].length : notNullValue) &&
      ![
        'type',
        'forStats',
        'orderBy',
        'isArchived',
        'isOutOfStock',
        // 'vendorStructureIds',
        // 'vendorStructureId',
        // 'cardIds',
      ].includes(key)
    ) {
      const value = ['from', 'to', 'createdAtStart', 'createdAtEnd'].includes(
        key,
      )
        ? helperDate(
            data[key],
            options?.dateFormat,
            data.forStats ? false : true,
            !!data.forStats,
          ) || null
        : Array.isArray(data[key])
        ? JSON.stringify(data[key])
        : data[key];

      const keyFix = key === 'type' ? 'types' : key;

      query += `${keyFix}=${
        keyFix === 'types'
          ? `${value}`
          : keyFix === 'search'
          ? value.toLocaleLowerCase()
          : value
      }&`;
    }
  }

  return query.slice(0, -1);
};

import {Title} from '../../../../../components/atoms/Title';
import {AllergenCard} from './AllergenCard';
import {useContext} from 'react';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {useQuery} from '@tanstack/react-query';
import {
  fetchAllergens,
  getItemAllergens,
} from '../../../../../api/fetchAllergens';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {IFetchAllergensReturn} from '@bill-app-types/b-types';

export const Allergens = () => {
  const {t} = useContext<any>(UtilsContext);
  const {data: allergens} = useQuery(['fetchAllergens'], fetchAllergens);

  const productPreRegister = useAppSelector(
    s => s.myCatalogSection.products.productPreRegister,
  );

  const {data: productAllergens} = useQuery(
    ['getItemAllergens'],
    () => getItemAllergens(productPreRegister?.uuid),
    {
      enabled: !!productPreRegister?.uuid,
    },
  );

  return (
    <div>
      <Title title={t('ALLERGENS')} />
      <div
        className="flex-wrap"
        style={{
          gap: '0.4rem',
          padding: '0.8rem 0',
        }}>
        {allergens?.map((allergen: IFetchAllergensReturn) => (
          <AllergenCard
            key={allergen.uuid}
            active={
              !!productAllergens?.find(item => item.uuid === allergen.uuid)
            }
            allergen={allergen}
            productUuid={productPreRegister?.uuid}
          />
        ))}
      </div>
    </div>
  );
};

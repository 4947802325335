/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../../components/layouts/Header';
import {HeaderTabs} from '../HeaderSections/HeaderTabs';
import {HeaderTopButtons} from '../HeaderSections/HeaderTopButtons';
import {AdditionalSalesCatalog} from '../../../../../components/organisms/AdditionalSalesCatalog';
import {AdditionalSales} from '../../../../../components/organisms/AdditionalSalesCatalog/AdditionalSales';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';

export const AdditionalSalesSection = withRedirectAndNavigation(() => {
  const productPreRegister = useAppSelector(
    s => s.myCatalogSection.products.productPreRegister,
  );

  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <div css={styles().container}>
      <Header
        centerSection={<HeaderTopButtons />}
        bottomSection={<HeaderTabs />}
      />
      <div css={styles(hasAuthorization).content}>
        {hasAuthorization && (
          <AdditionalSalesCatalog itemUuid={productPreRegister?.uuid} />
        )}
        <AdditionalSales itemUuid={productPreRegister?.uuid} />
      </div>
    </div>
  );
});

const styles = (hasAuthorization: boolean = false) => ({
  container: css({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  }),
  content: css({
    display: hasAuthorization ? 'grid' : 'block',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
    padding: '2vh',
  }),
});

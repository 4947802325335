import {SVGProps} from 'react';

export const InProgressIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.90813 0.205313C2.47851 -0.078998 3.17736 -0.0672773 3.73568 0.235786L14.5846 6.12828C15.1233 6.42297 15.4511 6.94203 15.4511 7.50128C15.4511 8.06052 15.1233 8.57958 14.5846 8.84413L3.73568 14.738C3.17736 15.0695 2.47851 15.0829 1.90813 14.7982C1.33764 14.5136 0.984375 13.9778 0.984375 13.3951V1.60744C0.984375 1.0261 1.33764 0.489958 1.90813 0.205313ZM2.79271 13.3951L13.6427 7.50128L2.79271 1.60744V13.3951Z"
      fill="white"
    />
  </svg>
);

import {InfosGeneral} from './InfosGeneral';
import {Classification} from './Classification';

export const MenuGeneral = () => {
  return (
    <div>
      <InfosGeneral />
      <Classification />
    </div>
  );
};

import {useQuery} from '@tanstack/react-query';
import {fetchSubmenus} from '../api/fetchSubmenus';

export const useSubmenu = ({offset, limit}: {offset: number, limit: number}) => {
  const {data, isLoading, error} = useQuery(['fetchSubmenus', offset, limit], () =>
    fetchSubmenus({
      offset,
      limit,
    }),
  );

  return {
    data,
    isLoading,
    error,
  };
};

// TODO : https://v5.reactrouter.com/web/example/query-parameters
// for gestion route and section
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {BookIcon} from '../../../../assets/svgs/sidebar/BookIcon';
import {CircleDollarIcon} from '../../../../assets/svgs/CircleDollarIcon';
import {DaysIcon} from '../../../../assets/svgs/sidebar/DaysIcon';
import {HistoInvoiceIcon} from '../../../../assets/svgs/sidebar/HistoInvoiceIcon';
// import {userHasAuthorization} from '../../../helpers/helperFrontPolicies';
import {SidebarLink} from '../../../atoms/SidebarLink';
import {CustomersIcon} from '../../../../assets/svgs/CustomersIcon';
import {SalesIcon} from '../../../../assets/svgs/SalesIcon';
import {InfosIcon} from '../../../../assets/svgs/sidebar/InfosIcon';
import {InProgressIcon} from '../../../../assets/svgs/sidebar/InProgressIcon';
import {ComingIcon} from '../../../../assets/svgs/sidebar/ComingIcon';
import {HistoryIcon} from '../../../../assets/svgs/sidebar/HistoryIcon';
import {NotCompletedIcon} from '../../../../assets/svgs/sidebar/NotCompletedIcon';
import {LanguagesIcon} from '../../../../assets/svgs/sidebar/LanguagesIcon';
import {TranslationsIcon} from '../../../../assets/svgs/sidebar/TranslationsIcon';
import {GroupsIcon} from '../../../../assets/svgs/sidebar/GroupsIcon';
import {UsersIcon} from '../../../../assets/svgs/sidebar/UsersIcon';
import {EventsIcon} from '../../../../assets/svgs/sidebar/EventsIcon';
import {LoyaltyIcon} from '../../../../assets/svgs/sidebar/LoyaltyIcon';
import {AdditionalIcon} from '../../../../assets/svgs/sidebar/AdditionalIcon';
import {ProductsIcon} from '../../../../assets/svgs/sidebar/ProductsIcon';
import {OptProdIcon} from '../../../../assets/svgs/sidebar/OptProdIcon';
import {GestMenuIcon} from '../../../../assets/svgs/sidebar/GestMenuIcon';
import {MapIcon} from '../../../../assets/svgs/MapIcon';
import {TreeIcon} from '../../../../assets/svgs/sidebar/TreeIcon';
import {ServiceAreaIcon} from '../../../../assets/svgs/sidebar/ServiceAreaIcon';
import {ProductionAreaIcon} from '../../../../assets/svgs/sidebar/ProductionAreaIcon';
import {PrinterIcon} from '../../../../assets/svgs/PrinterIcon';
import {ModulesIcon} from '../../../../assets/svgs/sidebar/ModulesIcon';
import {DashboardIcon} from '../../../../assets/svgs/sidebar/DashboardIcon';
import {SettingsSideBarIcon} from '../../../../assets/svgs/sidebar/SettingsSideBarIcon';
import {StatSalesIcon} from '../../../../assets/svgs/StatSalesIcon';
import {IngIcon} from '../../../../assets/svgs/sidebar/IngIcon';
import {BRAND_AUTHORIZATION} from '../../../../constants/frontPolicies';
import {ArchiveIcon} from '../../../../assets/svgs/ArchiveIcon';
import {useBrandAccess} from '../../../../hooks/useBrandAccess';
import {REDIRECT_URL} from '../../../../constants/global';

export const SideBarContentOld = () => {
  const {brandHasAuthorization} = useBrandAccess();
  const isParent = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <div css={styles.navBarContent}>
      <div>
        <SidebarLink
          label={'Tableau de bord'}
          url={'dashboard/sales'}
          IconRight={DashboardIcon}
          // redirect="/pages/dashboard"
          authorization={BRAND_AUTHORIZATION.CHILD}
        />
        <SidebarLink
          label="Mes statistiques"
          authorization={BRAND_AUTHORIZATION.CHILD}
          list={[
            {
              label: 'SALES',
              Icon: StatSalesIcon,
              url: '/online/stats/sales',
            },
            {
              label: 'CUSTOMERS',
              Icon: CustomersIcon,
              url: '/online/stats/customers',
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
          ]}
        />
        <SidebarLink
          label={'Ma comptabilité'}
          authorization={BRAND_AUTHORIZATION.CHILD}
          list={[
            {
              label: 'SALES_REPORT',
              Icon: SalesIcon,
              url: '/online/accounting/sales',
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
            {
              label: 'DAYS',
              Icon: DaysIcon,
              url: '/online/accounting/days',
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
            {
              label: 'DISCOUNTS_AND_GIFTS_JOURNAL',
              Icon: CircleDollarIcon,
              url: '/online/accounting/discounts-gifts-journal',
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
            {
              label: 'CANCELLATION_JOURNAL',
              Icon: CircleDollarIcon,
              url: '/online/accounting/cancelation-journal',
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
            {
              label: 'RECEIPT_HISTORY',
              Icon: HistoInvoiceIcon,
              url: '/online/accounting/invoice-history',
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
            {
              label: 'MOVEMENTS',
              Icon: CircleDollarIcon,
              url: '/online/accounting/movements',
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
            {
              label: 'Paramétrages',
              Icon: SettingsSideBarIcon,
              url: '/online/accounting/parameters',
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
            {
              label: 'Archive',
              Icon: ArchiveIcon,
              url: '/online/accounting/archive',
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
          ]}
        />
      </div>
      <div>
        {!isParent && (
          <SidebarLink
            label="Mes modules"
            url="modules"
            IconRight={ModulesIcon}
            // redirect="/pages/restaurant/modules"
            authorization={BRAND_AUTHORIZATION.CHILD}
          />
        )}
        <SidebarLink
          label={'Mon restaurant'}
          list={[
            {
              label: 'INFORMATIONS',
              Icon: InfosIcon,
              url: '/online/restaurant/informations',
              // redirect: '/pages/restaurant/informations',
            },
            {
              label: 'SERVICE_AREAS',
              Icon: ServiceAreaIcon,
              url: '/online/restaurant/service-areas',
              // redirect: '/pages/restaurant/area-management',
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
            {
              label: 'Points de production',
              Icon: ProductionAreaIcon,
              url: '/online/restaurant/production-areas',
              redirect: REDIRECT_URL.restaurant['production-areas'],
            },
            {
              label: 'Imprimantes réseau',
              Icon: PrinterIcon,
              url: '/online/restaurant/network-printers',
              // redirect: '/pages/restaurant/external-connections',
              // authorization: BRAND_AUTHORIZATION.CHILD,
            },
            {
              label: 'CATEGORIES_POS',
              Icon: BookIcon,
              url: '/online/restaurant/pos-categories',
            },
          ]}
        />
        <SidebarLink
          label={'Mon catalogue'}
          list={[
            {
              label: 'Arborescence',
              Icon: TreeIcon,
              url: '/online/catalog/tree',
              redirect: REDIRECT_URL.catalog.tree,
              authorization: BRAND_AUTHORIZATION.PARENT,
            },
            {
              label: 'PRODUCTS',
              Icon: ProductsIcon,
              url: '/online/catalog/products',
              redirect: REDIRECT_URL.catalog.products,
              authorization: BRAND_AUTHORIZATION.PARENT,
            },
            {
              label: 'RUPTURE_GESTION',
              Icon: ProductsIcon,
              url: '/online/catalog/rupture-gestion',
              authorization: BRAND_AUTHORIZATION.CHILD,
              justForChild: true,
            },
            {
              label: 'OPTIONS',
              Icon: OptProdIcon,
              url: '/online/catalog/options',
              redirect: REDIRECT_URL.catalog.options,
              authorization: BRAND_AUTHORIZATION.PARENT,
            },
            {
              label: 'GESTION_MENU',
              Icon: GestMenuIcon,
              url: '/online/catalog/menus',
              redirect: REDIRECT_URL.catalog.menus,
              authorization: BRAND_AUTHORIZATION.PARENT,
            },
            {
              label: 'CARDS',
              Icon: MapIcon,
              url: '/online/catalog/cards',
              redirect: REDIRECT_URL.catalog.cards,
              authorization: BRAND_AUTHORIZATION.PARENT,
            },
            {
              label: 'INGREDIENTS',
              Icon: IngIcon,
              url: '/online/catalog/recipes',
              redirect: REDIRECT_URL.catalog.recipes,
              authorization: BRAND_AUTHORIZATION.PARENT,
            },
          ]}
        />
        <SidebarLink
          label={'Mes commandes'}
          authorization={BRAND_AUTHORIZATION.CHILD}
          list={[
            {
              label: 'IN_PROGRESS',
              Icon: InProgressIcon,
              url: '/online/orders/in-progress',
              redirect: REDIRECT_URL.orders['in-progress'],
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
            {
              label: 'COMING',
              Icon: ComingIcon,
              url: '/online/orders/in-coming',
              redirect: REDIRECT_URL.orders['in-coming'],
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
            {
              label: 'HISTORY',
              Icon: HistoryIcon,
              url: '/online/orders/history',
              redirect: REDIRECT_URL.orders.history,
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
            {
              label: 'NOT_COMPLETED',
              Icon: NotCompletedIcon,
              url: '/online/orders/not-completed',
              redirect: REDIRECT_URL.orders['not-completed'],
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
          ]}
        />
        <SidebarLink
          label={'Mes offres'}
          list={[
            {
              label: 'Règles de prix',
              Icon: EventsIcon,
              url: '/online/offers/events',
              redirect: REDIRECT_URL.offers.events,
            },
            {
              label: 'Fidélité',
              Icon: LoyaltyIcon,
              url: '/online/offers/loyalty',
              redirect: REDIRECT_URL.offers.loyalty,
            },
            {
              label: 'Ventes additionnelles',
              Icon: AdditionalIcon,
              url: '/online/offers/additional',
              redirect: REDIRECT_URL.offers.additional,
            },
          ]}
        />
      </div>
      <div>
        <SidebarLink
          label={'Mon Staff'}
          list={[
            {
              label: 'Utilisateurs',
              Icon: UsersIcon,
              url: '/online/staff/users',
              // redirect: '/pages/staff/users',
            },
            {
              label: "Groupe d'utilisateurs",
              Icon: GroupsIcon,
              url: '/online/staff/users-groups',
              // redirect: '/pages/staff/users',
            },
          ]}
        />
        <SidebarLink
          label={'Mes traductions'}
          list={[
            {
              label: 'LANGUAGES',
              Icon: LanguagesIcon,
              url: '/online/translations/languages',
              redirect: REDIRECT_URL.translations.languages,
              authorization: BRAND_AUTHORIZATION.CHILD,
            },
            {
              label: 'TRANSLATIONS',
              Icon: TranslationsIcon,
              url: '/online/translations/translations',
              redirect: REDIRECT_URL.translations.translations,
            },
          ]}
        />
      </div>
    </div>
  );
};

const styles = {
  navBarContent: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '35px 0',
    overflowY: 'auto',
  }),
};

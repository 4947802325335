export const myArray = [
  [
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 900,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 200000,
      dataCompare: 2000,
    },
    {
      data: 90000,
      dataCompare: 2000,
    },
    {
      data: 100000,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 150000,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 300000,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
  ],
  [
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 10,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 1000,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
  ],
  [
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
  ],
  [
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
  ],
  [
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
  ],
  [
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
  ],
  [
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
    {
      data: 0,
      dataCompare: 2000,
    },
  ],
];

function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Iterate through each element of myArray and fill the "data" property with random values
for (let i = 0; i < myArray.length; i++) {
  for (let j = 0; j < myArray[i].length; j++) {
    myArray[i][j].data = getRandomNumber(0, 500000); // Change the range as needed
    myArray[i][j].dataCompare = getRandomNumber(0, 500000); // Change the range as needed
  }
}

export const daysList = [
  'Dimanche',
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
];

import {useQuery} from '@tanstack/react-query';
import {fetchPosReceiptCancelByUsers} from '../api/fetchPosReceiptCancel';
import {useAppSelector} from './useReduxHook';

export const usePosReceiptCancelByUsers = () => {
  const dates = useAppSelector(s => s.filter.dates);

  const {data, isLoading, error} = useQuery(
    ['fetchPosReceiptCancelByUsers', dates],
    () =>
      fetchPosReceiptCancelByUsers({
        ...dates,
      }),
  );

  return {
    data,
    isLoading,
    error,
  };
};

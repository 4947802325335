/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {ProductCardOld} from './ProductCardOld';

interface ISortableList {
  items: any;
  style?: any;
  bg?: string;
  showCross?: boolean;
  showAdd?: boolean;
  handleOnClick?: any;
  showProductType?: boolean;
}

export const SortableListOld = SortableContainer(
  ({
    items,
    style,
    bg,
    showCross,
    showAdd,
    handleOnClick,
    showProductType,
  }: ISortableList) => {
    return (
      <div css={[styles.list, {backgroundColor: bg}, style]}>
        {items.map((value: any, index: number) => (
          //@ts-ignore
          <SortableItem
            key={`item-${index}`}
            index={index}
            // @ts-ignore
            value={{...value, position: index + 1}}
            showCross={showCross}
            showAdd={showAdd}
            handleOnClick={() => handleOnClick(value)}
            showProductType={showProductType}
          />
        ))}
      </div>
    );
  },
);

// ignore typescript to this line
//@ts-ignore
const SortableItem = SortableElement(
  ({value, showCross, showAdd, handleOnClick, showProductType}: any) => (
    <ProductCardOld
      item={value}
      showCross={showCross}
      showAdd={showAdd}
      handleOnClick={handleOnClick}
      showProductType={showProductType}
      draggable={true}
    />
  ),
);

const styles = {
  list: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: '10px',
    paddingTop: '10px',
  }),
};

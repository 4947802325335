import {SVGProps} from 'react';
import { THEME } from '../../styles/theme';

export const BrandDropdownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 3 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M0.25 0.375L1.5 1.625L2.75 0.375" fill={THEME.colors.primary} />
  </svg>
);

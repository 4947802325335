import { helperGenerateQuery } from '../helpers/helperGenerateQuery';
import {axiosInstance} from './axiosInstance';

interface IOptionData {
  search: string;
}

export const getCatalogPictograms = async (data: IOptionData) => {
  //
  console.log('### getCatalogPictograms ###');
  const query = helperGenerateQuery(data);

  const res: any = await axiosInstance
    .get(`/bcatalog/backoffice/pictograms${query}`)
    .catch(err => {
      console.log('❌ getCatalogPictograms : ', err);
    });
  return res?.data?.data;
};

export const createCatalogPictogram = async (data: any) => {
  //
  console.log('### createCatalogPictogram ###');

  const formData = new FormData();
  formData.append('label', data.label);
  formData.append('image', data.image);

  const res: any = await axiosInstance
    .post(`/bcatalog/backoffice/pictograms`, formData)
    .catch(err => {
      console.log('❌ createCatalogPictogram : ', err);
    });
  return res;
};

export const getItemPictograms = async (itemUuid: string) => {
  //
  console.log('### getItemPictograms ###');

  const res: any = await axiosInstance
    .get(`/bcatalog/backoffice/items/${itemUuid}/pictograms`)
    .catch(err => {
      console.log('❌ getItemPictograms : ', err);
    });
  return res?.data?.data;
};

export const createItemPictogram = async (itemUuid: string, pictogramUuid: string) => {
  //
  console.log('### createItemPictogram ###');
  const res: any = await axiosInstance
    .post(`/bcatalog/backoffice/items/${itemUuid}/pictograms`, {pictogramUuid})
    .catch(err => {
      console.log('❌ createItemPictogram : ', err);
    });
  return res;
};

export const deleteItemPictogram = async (itemUuid: string, pictogramUuid: string) => {
  //
  console.log('### deleteItemPictogram ###');
  const res: any = await axiosInstance
    .delete(`/bcatalog/backoffice/items/${itemUuid}/pictograms/${pictogramUuid}`)
    .catch(err => {
      console.log('❌ deleteItemPictogram : ', err);
    });
  return res;
};
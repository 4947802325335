/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {ButtonCustom} from '../../../../../Offline/components/ButtonCustom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useReduxHook';
import {fetchPosCategoryItems} from '../../../../../../api/fetchPosCategories';
import {useQuery} from '@tanstack/react-query';
import {addPosButtonModalAction} from '../../../../../../redux/modals';
import {Loader} from '../../../../../Offline/components/Loader';
import {selectedArticleForButtonAction} from '../../../../../../redux/myModulesSection/pos';
import {Breadcrumb} from './Breadcrumb';
import {ProductList} from './ProductList';
import {helperDisplayToast} from '../../../../../../helpers/helperToast';
import {CATEGORY_HAS_ITEM_TYPES} from '@bill-app-types/b-types';
import {useBrandAccess} from '../../../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../../../constants/frontPolicies';
import {THandleDragAndDrop} from '../../../../../../components/organisms/SortableListVerticalContainer';
import {updateCategoryItemsPosition} from '../../../../../../api/fetchCategories';

const CenterBlock = () => {
  const dispatch = useAppDispatch();

  const selectedPosCategory = useAppSelector(
    s => s.myModulesSection.pos.selectedPosCategory,
  );
  const selectedButtons = useAppSelector(
    s => s.myModulesSection.pos.selectedButtons,
  );

  const {brandHasAuthorization} = useBrandAccess();
  const hasParentAuthorization = brandHasAuthorization(
    BRAND_AUTHORIZATION.PARENT,
  );

  const lastButton = selectedButtons[selectedButtons.length - 1];

  const {data, isLoading} = useQuery(
    [
      `fetchPosCategoryItems-${lastButton?.uuid || selectedPosCategory?.uuid}`,
      lastButton?.uuid,
      selectedPosCategory?.uuid,
    ],
    () => fetchPosCategoryItems(lastButton?.uuid || selectedPosCategory?.uuid),
    {
      enabled: !!lastButton || !!selectedPosCategory,
    },
  );

  const handleClickAddButton = () => {
    if (!hasParentAuthorization) return;

    dispatch(selectedArticleForButtonAction(null));
    dispatch(addPosButtonModalAction(true));
  };

  const handleDragAndDrop: THandleDragAndDrop = async ({items}) => {
    if (!hasParentAuthorization) {
      return;
    }

    const categoryItems = items.map(item => ({
      uuid: item.uuid,
      type:
        item.type !== null
          ? CATEGORY_HAS_ITEM_TYPES.ITEM
          : CATEGORY_HAS_ITEM_TYPES.CATEGORY,
      image: item?.image || null,
    }));

    const res = await updateCategoryItemsPosition(
      lastButton?.uuid || selectedPosCategory?.uuid,
      categoryItems,
    );
    if (res && [200, 204].includes(res?.status)) {
      helperDisplayToast('Position modifiée', true);
    } else {
      helperDisplayToast('Impossible de modifier la position', false);
    }
  };

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <Breadcrumb />
        <ButtonCustom
          marginBottom={12}
          label={'Ajouter un bouton'}
          onClick={handleClickAddButton}
          disabled={!selectedPosCategory || !hasParentAuthorization}
        />
      </div>
      <div css={styles.list}>
        {!!selectedPosCategory && isLoading && (
          <div css={styles.loader}>
            <Loader size={35} />
          </div>
        )}
        <ProductList data={data || []} handleDragAndDrop={handleDragAndDrop} />
      </div>
    </div>
  );
};

export default CenterBlock;

const styles = {
  container: css({
    width: '49%',
  }),
  header: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  list: css({
    height: 'calc(100% - 40px)',
    overflowY: 'auto',
  }),
  loader: css({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};

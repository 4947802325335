/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {Title} from '../../../../../components/atoms/Title';
import {OptionOfOptionCategory} from './OptionOfOptionCategory';
import {InputLabel} from '../../../../../components/atoms/InputLabel';
import {SelectList} from '../../../../../components/molecules/SelectList';
import {
  CONSUMPTION_MODE,
  OPTION_TYPES as OPTION_TYPES_LIST,
} from '../../../../../constants/global';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {ToggleButtonLabel} from '../../../../../components/molecules/ToggleButtonLabel';
import {
  updatedCategoryAction,
  updatedCategoryChangeAction,
} from '../../../../../redux/myCatalogSection/options';
import {SliderCustomLabel} from '../../../../../components/atoms/SliderCustomLabel';
import {MultiSelectCustom} from '../../../../../components/atoms/MultiSelectCustom';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {
  IGetModifierHasItemsDetailsResponse,
  OPTION_TYPES,
} from '@bill-app-types/b-types';

export const OptionCategoryContent = ({
  selectedItems,
}: {
  selectedItems?: IGetModifierHasItemsDetailsResponse[];
}) => {
  const dispatch = useAppDispatch();

  const categoryPreRegister = useAppSelector(
    s => s.myCatalogSection.options.updatedCategory,
  );

  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;

    dispatch(
      updatedCategoryChangeAction({
        name,
        value,
      }),
    );
  };

  const handleToggle = () => {
    dispatch(
      updatedCategoryChangeAction({
        name: 'allowMultiple',
        value: !categoryPreRegister?.allowMultiple,
      }),
    );
  };

  const handleSlide = (value: number[]) => {
    dispatch(
      updatedCategoryAction({
        ...categoryPreRegister,
        minimumValue: [
          OPTION_TYPES.SUPPLEMENT,
          OPTION_TYPES.WITHDRAWAL,
        ].includes(Number(categoryPreRegister?.optionType))
          ? 0
          : value[0],
        maximumValue: value[1],
      }),
    );
  };

  const handleSelectItem = (option: {label: string; value: number}) => {
    if (!categoryPreRegister?.hideOnConsumptionMode) {
      return;
    }

    const index = categoryPreRegister?.hideOnConsumptionMode.findIndex(
      item => item === option.value,
    );
    let newArray = [];
    if (index > -1) {
      newArray = [
        ...categoryPreRegister.hideOnConsumptionMode.filter(
          item => item !== option.value,
        ),
      ];
    } else {
      newArray = [...categoryPreRegister.hideOnConsumptionMode, option.value];
    }
    dispatch(
      updatedCategoryAction({
        ...categoryPreRegister,
        hideOnConsumptionMode: newArray,
      }),
    );
  };

  const handleClickBarcode = () => {
    navigator.clipboard.writeText(categoryPreRegister?.barcode || '');
  };

  return (
    <div css={styles(hasAuthorization).container}>
      <Title title="Catégorie d’options" />
      <div className="flex" css={styles().contain}>
        <div css={styles().section}>
          <SelectList
            label="Type de la catégorie"
            name="optionType"
            list={OPTION_TYPES_LIST}
            onChange={handleChange}
            value={Number(categoryPreRegister?.optionType)}
            manageByFormik={false}
            required
            disabled={!hasAuthorization}
          />
          {/* <InputLabel
            label="Nom d'affichage backoffice"
            value={helperLabelTranslation(categoryPreRegister)}
            name="label"
            onChange={handleChange}
            required
          /> */}
          <MultiSelectCustom
            options={CONSUMPTION_MODE}
            values={categoryPreRegister?.hideOnConsumptionMode}
            handleSelectItem={handleSelectItem}
            label="Ne pas afficher"
            disabled={!hasAuthorization}
          />
          <ToggleButtonLabel
            label="Choix multiple"
            active={categoryPreRegister?.allowMultiple}
            onToggle={handleToggle}
            disabled={!hasAuthorization}
          />
        </div>
        <div css={styles().section}>
          <InputLabel
            label="Nom de la catégorie d'options"
            value={categoryPreRegister?.label}
            name="label"
            onChange={handleChange}
            required
            disabled={!hasAuthorization}
          />
          <InputLabel
            label="Nom d’affichage backoffice"
            value={categoryPreRegister?.backofficeLabel}
            name="backofficeLabel"
            onChange={handleChange}
            required
            disabled={!hasAuthorization}
          />

          <InputLabel
            onClickIcon={handleClickBarcode}
            disabled
            name="barcode"
            value={categoryPreRegister?.barcode}
            label="Code Article"
            showIcon
          />

          {/* <InputLabel
            label="Nom d'affichage production"
            value={helperLabelTranslation(categoryPreRegister)}
            name="label"
            onChange={handleChange}
            required
            disabled={!hasAuthorization}
          /> */}
        </div>
      </div>
      <div>
        <SliderCustomLabel
          min={0}
          max={selectedItems?.length || 0}
          label="Options de la catégorie d’options"
          value={[
            [OPTION_TYPES.SUPPLEMENT, OPTION_TYPES.WITHDRAWAL].includes(
              Number(categoryPreRegister?.optionType),
            )
              ? null
              : categoryPreRegister?.minimumValue || 0,
            categoryPreRegister?.maximumValue || 0,
          ]}
          handleChange={handleSlide}
        />
      </div>
      <OptionOfOptionCategory selectedItems={selectedItems} />
    </div>
  );
};

const styles = (hasAuthorization: boolean = false) => ({
  container: css({
    width: hasAuthorization ? '49%' : '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  contain: css({
    justifyContent: 'space-between',
  }),
  section: css({
    width: '49%',
  }),
});

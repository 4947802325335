/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../../../../styles/theme';
import {TextVariant} from '../../../../../components/atoms/TextVariant';
import {ImageCustom} from '../../../../../components/atoms/ImageCustom';
import {
  createItemAllergen,
  deleteItemAllergen,
} from '../../../../../api/fetchAllergens';
import {helperLabelTranslation} from '../../../../../helpers/helperLabel';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {IFetchAllergensReturn} from '@bill-app-types/b-types';

export const AllergenCard = ({
  active = false,
  allergen,
  productUuid,
}: {
  active?: boolean;
  allergen: IFetchAllergensReturn;
  productUuid: string;
}) => {
  const clientQuery = useQueryClient();

  const {brandHasAuthorization} = useBrandAccess();

  const label = helperLabelTranslation(allergen);

  const handleClickAllergen = async () => {
    if (active) {
      const res = await deleteItemAllergen(productUuid, allergen.uuid);
      if (res?.status === 204) {
        helperDisplayToast(res?.data?.message || 'Allergène retiré', true);
        clientQuery.invalidateQueries(['getItemAllergens']);
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible de retirer l'allergène",
          false,
        );
      }
    } else {
      const res = await createItemAllergen(productUuid, allergen.uuid);
      if (res?.status === 204) {
        helperDisplayToast(res?.data?.message || 'Allergène ajouté', true);
        clientQuery.invalidateQueries(['getItemAllergens']);
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible d'ajouter l'allergène",
          false,
        );
      }
    }
  };

  return (
    <button
      disabled={!brandHasAuthorization(BRAND_AUTHORIZATION.PARENT)}
      onClick={handleClickAllergen}
      className="flex-col-center"
      css={[styles.allergenCard, active && styles.allergenCardActive]}>
      <ImageCustom
        src={allergen?.image}
        styles={{
          width: '35px',
          height: '25px',
          objectFit: 'contain',
          filter: active
            ? 'invert(40%) sepia(54%) saturate(5847%) hue-rotate(218deg) brightness(99%) contrast(95%)'
            : 'none',
        }}
        alt={label}
        noTrunc
      />
      <TextVariant
        text={label as string}
        variant="textSemiBold"
        style={styles.allergenLabel}
      />
    </button>
  );
};

const styles = {
  allergenCard: css({
    backgroundColor: 'white',
    width: '102px',
    height: '115px',
    borderRadius: '4px',
    cursor: 'pointer',
    border: `1px solid ${THEME.colors.white}`,
    color: THEME.colors.black,
  }),

  allergenCardActive: css({
    border: `1px solid ${THEME.colors.primary}`,
    color: THEME.colors.primary,
  }),

  img: css({
    width: '35px',
    height: '25px',
    objectFit: 'contain',
  }),

  imgActive: css({
    filter:
      'invert(40%) sepia(54%) saturate(5847%) hue-rotate(218deg) brightness(99%) contrast(95%)',
  }),

  allergenLabel: css({
    fontWeight: 500,
    marginTop: '0.6rem',
  }),
};

export const MailIcon = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8622_147092)">
      <g clip-path="url(#clip1_8622_147092)">
        <path
          d="M7.5 1.90625C4.13379 1.90625 1.40625 4.63379 1.40625 8C1.40625 11.3662 4.13379 14.0938 7.5 14.0938C7.88965 14.0938 8.20312 14.4072 8.20312 14.7969C8.20312 15.1865 7.88965 15.5 7.5 15.5C3.35742 15.5 0 12.1426 0 8C0 3.85742 3.35742 0.5 7.5 0.5C11.6426 0.5 15 3.85742 15 8V8.82031C15 10.3086 13.793 11.5156 12.3047 11.5156C11.3936 11.5156 10.585 11.0615 10.0986 10.3672C9.4541 11.0732 8.52832 11.5156 7.5 11.5156C5.55762 11.5156 3.98438 9.94238 3.98438 8C3.98438 6.05762 5.55762 4.48438 7.5 4.48438C8.34375 4.48438 9.11719 4.78027 9.7207 5.27539C9.84668 5.08203 10.0635 4.95312 10.3125 4.95312C10.7021 4.95312 11.0156 5.2666 11.0156 5.65625V8V8.82031C11.0156 9.53223 11.5928 10.1094 12.3047 10.1094C13.0166 10.1094 13.5938 9.53223 13.5938 8.82031V8C13.5938 4.63379 10.8662 1.90625 7.5 1.90625ZM9.60938 8C9.60938 7.44056 9.38714 6.90403 8.99155 6.50845C8.59597 6.11286 8.05944 5.89062 7.5 5.89062C6.94056 5.89062 6.40403 6.11286 6.00845 6.50845C5.61286 6.90403 5.39062 7.44056 5.39062 8C5.39062 8.55944 5.61286 9.09597 6.00845 9.49155C6.40403 9.88714 6.94056 10.1094 7.5 10.1094C8.05944 10.1094 8.59597 9.88714 8.99155 9.49155C9.38714 9.09597 9.60938 8.55944 9.60938 8Z"
          fill="#A7A7AB"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_8622_147092">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
      <clipPath id="clip1_8622_147092">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

import {useQuery} from '@tanstack/react-query';
import {getStatByItem} from '../api/fetchStats';
import {useAppSelector} from './useReduxHook';
import {helperJoinFilterValues} from '../helpers/helperFilter';
import {helperGetStatColorById} from '../helpers/helperStats';
import {IObject} from '../redux/filter';
import {useFlagBCatalog} from './useFlagBCatalog';

export const useStatItems = ({
  limit,
  types,
  dates,
  enabled,
  parentBarcodes,
  consumptionModes = [],
  vendorStructures = [],
  search,
}: {
  limit?: number;
  types: number[];
  dates?: {
    from: Date | null;
    to: Date | null;
  };
  enabled?: boolean;
  parentBarcodes?: string[];
  consumptionModes?: IObject[];
  vendorStructures?: IObject[];
  search?: string;
}) => {
  const datesFilter = useAppSelector(s => s.filter.dates);
  const channels = useAppSelector(s => s.filter.channels);
  const cards = useAppSelector(state => state.filter.cards);
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {data, isLoading, error} = useQuery(
    [
      'getStatByItem',
      flagBCatalogEnabled,
      dates,
      channels,
      consumptionModes,
      vendorStructures,
      cards,
      limit,
      types,
      datesFilter,
      parentBarcodes,
      search,
    ],
    ({signal}) =>
      getStatByItem(
        {
          from: (dates || datesFilter).from,
          to: (dates || datesFilter).to,
          channelIds: helperJoinFilterValues(channels, true),
          consumptionModeIds: helperJoinFilterValues(consumptionModes, true),
          vendorStructureIds: vendorStructures?.map((item: any) =>
            parseInt(item?.value),
          ),
          cardIds: cards?.map((item: any) => parseInt(item?.value)),
          forStats: !flagBCatalogEnabled,
          limit,
          types,
          parentBarcodes,
          search,
        },
        signal,
      ),
    {
      enabled: !!types && enabled && flagBCatalogReady,
    },
  );

  const stats =
    data && Array.isArray(data)
      ? data?.map((product, index: number) => ({
          ...product,
          color: helperGetStatColorById(index),
          value: product.sales?.TTC?.total_price,
          valueOrder: product.orders?.value,
        }))
      : [];

  return {
    data: stats,
    isLoading,
    error,
  };
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import { THEME } from '../../../../../styles/theme';

export const ValuesBlock = ({
  title,
  value,
  icon,
  color,
}: {
  title: string;
  value: string;
  icon: any;
  color: string;
}) => {
  return (
    <div css={styles.container} className='flex'>
      <div css={styles.icon(color)}>{icon}</div>
      <div css={styles.content}>
        <p css={styles.title} className='textBold'>{title}</p>
        <b css={styles.value} className='title02'>{value}</b>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    paddingRight: '5%',
  }),
  icon: (color: string) =>
    css({
      width: 37,
      height: 34,
      border: `1px solid ${color}`,
      borderRadius: 3.29,
      alignItems:'center',
      justifyContent:'center',
      display:'flex'
    }),
  content: css({
    // display:'block'
    paddingLeft: 10
  }),
  title: css({
    fontSize: 10,
    fontWeight: '700',
    color: THEME.colors.lightGray,
    textTransform:'uppercase'
  }),
  value: css({
    display:'block'
  }),
};

export const YellowStarIcon = ({color = '#CFA615'}: {color?: string}) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.38282 0.25C5.56251 0.25 5.72657 0.352539 5.80469 0.514063L7.14454 3.27344L10.1387 3.7168C10.3145 3.74219 10.459 3.86523 10.5156 4.0332C10.5703 4.20312 10.5254 4.38867 10.4004 4.51172L8.22852 6.66406L8.74219 9.70117C8.77149 9.87695 8.69923 10.0566 8.55274 10.1602C8.40821 10.2637 8.19923 10.2773 8.0586 10.1934L5.38282 8.76367L2.69141 10.1934C2.55079 10.2773 2.35938 10.2637 2.2129 10.1602C2.06837 10.0566 1.9961 9.87695 2.00782 9.70117L2.53712 6.66406L0.36739 4.51172C0.241218 4.38867 0.196492 4.20312 0.25196 4.0332C0.307234 3.86523 0.453132 3.74219 0.628718 3.7168L3.6211 3.27344L4.96094 0.514063C5.04102 0.35252 5.20313 0.25 5.38282 0.25ZM5.38282 1.79199L4.35743 3.90625C4.28907 4.04492 4.15626 4.14258 4.00391 4.16602L1.69298 4.50586L3.3711 6.16797C3.47852 6.27539 3.52735 6.42969 3.50196 6.58008L3.10743 8.91602L5.16212 7.81836C5.30079 7.74414 5.4668 7.74414 5.60352 7.81836L7.65821 8.91602L7.26368 6.58008C7.23829 6.42969 7.28907 6.27539 7.39649 6.16797L9.07423 4.50586L6.76368 4.16602C6.60938 4.14258 6.47657 4.04492 6.41016 3.90625L5.38282 1.79199Z"
        fill={color}
      />
    </svg>
  );
};

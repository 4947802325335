import {SortableContainer} from 'react-sortable-hoc';
import {SortableVerticalItem} from '../atoms/SortableVerticalItem';
import {THandleDragAndDrop} from '../organisms/SortableListVerticalContainer';

export const SortableVerticalList = SortableContainer(
  ({
    data,
    level = 0,
    ItemComponent,
    handleDragAndDrop,
  }: {
    data: unknown[];
    ItemComponent: any;
    level: number;
    handleDragAndDrop?: THandleDragAndDrop;
  }) => {
    return (
      <div style={{marginLeft: level === 0 ? 0 : 20}}>
        {data.map((item: any, index: number) => (
          <SortableVerticalItem
            key={item.uuid || item.id || index}
            index={index}
            // @ts-ignore
            item={item}
            ItemComponent={ItemComponent}
            idx={index}
            level={level}
            handleDragAndDrop={handleDragAndDrop}
          />
        ))}
      </div>
    );
  },
);

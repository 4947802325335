/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Title} from '../../../../../components/atoms/Title';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {helperFormatPrice} from '../../../../../helpers/helperFormatPrice';
import {THEME} from '../../../../../styles/theme';

interface ITotalCanceledOrders {
  HT?: number;
  TTC?: number;
  TVA?: number;
  nbOrders?: number;
}

export const DaysCancellationSection = ({
  data,
  isLoading = false,
}: {
  data?: ITotalCanceledOrders;
  isLoading?: boolean;
}) => {
  const columns = [
    {
      field: 'nbOrders',
      headerName: 'NB commande',
      headerClassName: 'textRegular',
      flex: 1,
      renderCell: (row: any) => {
        const orders = row?.nbOrders;
        return <span>{orders}</span>;
      },
    },
    {
      field: 'ht',
      headerName: 'Montant HT',
      headerClassName: 'textRegular',
      flex: 2,
      renderCell: (row: any) => {
        const amount = row.HT;
        return <span>{helperFormatPrice(amount)}€</span>;
      },
    },
    {
      field: 'tva',
      headerName: 'Montant TVA',
      headerClassName: 'textRegular',
      flex: 2,
      renderCell: (row: any) => {
        const amount = row.TVA;
        return <span>{helperFormatPrice(amount)}€</span>;
      },
    },
    {
      field: 'ttc',
      headerName: 'Montant TTC',
      headerClassName: 'textRegular',
      flex: 1,
      renderCell: (row: any) => {
        const amount = row.TTC;
        return (
          <span style={{color: THEME.colors.green}}>
            {helperFormatPrice(amount)}€
          </span>
        );
      },
    },
  ];

  return (
    <div css={styles.container}>
      <Title title={'Annulations'} />
      <NewDataGrid
        rows={data ? [data] : []}
        columns={columns}
        rowHeight={35}
        loading={isLoading}
      />
    </div>
  );
};

const styles = {
  container: css({
    // flex:1
    paddingTop: '2vh',
    marginTop: '2vh',
  }),
};

import {
  //AnyAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import app from './app';
import sidebar from './sidebar';
import auth from './auth';
import accounting from './accounting';
import filter from './filter';
import receipt from './receipt';
import exportPdfData from './exportPdfData';
import languages from './languages';
import modals from './modals';
import posCategories from './posCategories';
import policies from './policies';
import brand from './brand';
import date from './date';
import customer from './customer';
import {myCatalogSection} from './myCatalogSection';
import {myAccountingSection} from './myAccountingSection';
import {myRestaurantSection} from './myRestaurantSection';
import {myModulesSection} from './myModulesSection';
import myStatsSection from './myStatsSection';
import myStaffSection from './myStaffSection';
import {myOffersSection} from './myOffersSection';
import {myTranslationsSection} from './myTranslationsSection';
import createTransform from 'redux-persist/es/createTransform';
import order from './order';

const persistAuthConfig = {
  key: 'auth',
  storage,
};

const persistBrandConfig = {
  key: 'brand',
  storage,
};

const persistSidebarConfig = {
  key: 'sidebar',
  storage,
};

// preserve slice type after rehydrate
//

const persistFilterConfig = {
  key: 'filter',
  storage,
  transforms: [
    createTransform(JSON.stringify, toRehydrate =>
      JSON.parse(toRehydrate, (key, value) =>
        typeof value === 'string' &&
        value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
          ? new Date(value)
          : value,
      ),
    ),
  ],
};

const peristDateConfig = {
  key: 'date',
  storage,
  whitelist: ['typeFilterDate', 'sortFilterDate', 'periodType'],
};

const reducer = combineReducers({
  app,
  sidebar: persistReducer(persistSidebarConfig, sidebar),
  auth: persistReducer(persistAuthConfig, auth),
  brand: persistReducer(persistBrandConfig, brand),
  accounting,
  filter: persistReducer<any, any>(
    persistFilterConfig,
    filter,
  ) as typeof filter,
  receipt,
  exportPdfData,
  languages,
  modals,
  posCategories,
  policies,
  date: persistReducer(peristDateConfig, date),
  customer,
  myStatsSection,
  myCatalogSection,
  myAccountingSection,
  myRestaurantSection,
  myModulesSection,
  myStaffSection,
  myOffersSection,
  myTranslationsSection,
  order,
});

// ...
const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({serializableCheck: false}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
export default store;

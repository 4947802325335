/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useDateManage} from '../../hooks/useDateManage';
import {ModalWrapper} from '../atoms/ModalWrapper';
import {DaysContainer} from '../organisms/DaysContainer';
import {Title} from '../atoms/Title';
import {FilterCheckboxItem} from '../filters/FilterCheckboxItem';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {
  moduleSelectedAction,
  subscriptionSelectedAction,
} from '../../redux/myModulesSection/subscriptions';
import {editConsumptionModalAction} from '../../redux/modals';
import {DURATION_OF_SLOTS_LIST, INIT_DAYS} from '../../constants/global';
import {
  helperFormatSubscriptionTimings,
  helperFormatTimeStartAndEnd,
} from '../../helpers/helperSubscriptionTimings';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {
  ITimings,
  disableSubscriptionCard,
  enableSubscriptionCard,
  getSubscriptionCards,
  getSubscriptionOrderSlots,
  updateSubscriptionOrderSlots,
  updateSubscriptionTimings,
} from '../../api/fetchSubscription';
import {useEffect, useMemo, useState} from 'react';
import {AbsoluteLoader} from '../layouts/AbsoluteLoader';
import {helperDisplayToast} from '../../helpers/helperToast';
import {useTranslation} from 'react-i18next';
import {SelectList} from '../molecules/SelectList';
import {InputLabel} from '../atoms/InputLabel';
import {MODULES_ICONS} from '../../views/Online/MyModules/data';

export const EditConsumptionModal = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const moduleSelected = useAppSelector(
    s => s.myModulesSection.subscriptions.moduleSelected,
  );
  const subscriptionSelected = useAppSelector(
    s => s.myModulesSection.subscriptions.subscriptionSelected,
  );

  const [loading, setLoading] = useState(false);
  const [orderSlot, setOrderSlot] = useState<{
    maxOrder: number;
    offset: number;
    minutes: number;
  }>({
    maxOrder: 0,
    offset: 0,
    minutes: 0,
  });

  const [cardLoading, setCardLoading] = useState<boolean>(false);

  const clientQuery = useQueryClient();

  const {data: cards, refetch} = useQuery(
    ['getSubscriptionCards', subscriptionSelected?.uuid],
    () => getSubscriptionCards(subscriptionSelected?.uuid),
    {
      enabled:
        !!subscriptionSelected?.uuid && !!subscriptionSelected?.cards_enabled,
    },
  );
  const {data: orderSlotData} = useQuery(
    ['order-slot', subscriptionSelected?.id],
    () => getSubscriptionOrderSlots(subscriptionSelected?.id),
    {
      enabled:
        !!subscriptionSelected?.id &&
        !!subscriptionSelected?.ordering_slots_enabled,
    },
  );

  const initDays = useMemo(() => {
    return INIT_DAYS?.map((day, index: number) => {
      const dates = subscriptionSelected.timing[index === 6 ? 0 : index + 1];

      return {
        ...day,
        dates:
          dates.length > 0
            ? dates?.map(helperFormatTimeStartAndEnd)
            : day.dates,
      };
    });
  }, [subscriptionSelected?.timing]);

  const initSlotsTimingsDays = useMemo(() => {
    return INIT_DAYS?.map((day, index: number) => {
      const dates = orderSlotData?.timings[index === 6 ? 0 : index + 1];

      return {
        ...day,
        dates:
          dates?.length > 0
            ? dates?.map(helperFormatTimeStartAndEnd)
            : day.dates,
      };
    });
  }, [orderSlotData?.timings]);

  const {
    days,
    handleAddDay,
    handleChangeDate,
    handleRemoveDay,
    handleBlurDate,
  } = useDateManage(initDays);

  const {
    days: slotDays,
    handleAddDay: handleSlotAddDay,
    handleChangeDate: handleSlotChangeDate,
    handleRemoveDay: handleSlotRemoveDay,
    handleBlurDate: handleSlotBlurDate,
  } = useDateManage(initSlotsTimingsDays);

  const handleChangeOrderSlotData = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setOrderSlot({
      ...orderSlot,
      [e.target.name]: Number(e.target.value),
    });
  };

  const handleChangeCard = async (cardUuid: string) => {
    setCardLoading(true);

    const findCard = cards?.find(c => c.uuid === cardUuid);

    const res = findCard?.isActive
      ? await disableSubscriptionCard(subscriptionSelected?.uuid, cardUuid)
      : await enableSubscriptionCard(subscriptionSelected?.uuid, cardUuid);

    if ([200, 204].includes(res.status)) {
      refetch();
      helperDisplayToast('Carte modifiée', true);
    } else {
      helperDisplayToast('Impossible de modifier la carte', false);
    }

    setCardLoading(false);
  };

  const handleClose = () => {
    dispatch(moduleSelectedAction(null));
    dispatch(subscriptionSelectedAction(null));
    dispatch(editConsumptionModalAction(false));
  };

  const handleSubmit = async () => {
    let timings = {} as ITimings;
    let slotsTimings = {} as ITimings;

    if (subscriptionSelected?.timings_enabled) {
      timings = helperFormatSubscriptionTimings(days);
    }

    if (subscriptionSelected?.ordering_slots_enabled) {
      slotsTimings = helperFormatSubscriptionTimings(slotDays);
    }

    setLoading(true);

    let resSubscription;
    let resOrderSlot;
    let isSuccess = true;

    if (
      subscriptionSelected?.timings_enabled &&
      JSON.stringify(timings) !== JSON.stringify(subscriptionSelected?.timing)
    ) {
      resSubscription = await updateSubscriptionTimings(
        subscriptionSelected?.id,
        {
          timings,
        },
      );

      if (resSubscription?.status === 200) {
        helperDisplayToast(t('SUCCESS'), true);
      } else {
        helperDisplayToast(t('ERROR_UPDATE_SUBSCRIPTION_TIMINGS'), false);
        isSuccess = false;
      }
    }

    if (
      subscriptionSelected?.ordering_slots_enabled &&
      (JSON.stringify(slotsTimings) !==
        JSON.stringify(orderSlotData?.timings) ||
        orderSlotData.orderingSlot.max_order !== orderSlot.maxOrder ||
        orderSlotData.orderingSlot.offset !== orderSlot.offset ||
        orderSlotData.orderingSlot.minutes !== orderSlot.minutes)
    ) {
      resOrderSlot = await updateSubscriptionOrderSlots(
        subscriptionSelected?.id,
        {
          timings: slotsTimings,
          ...orderSlot,
        },
      );

      if (resOrderSlot?.status === 200) {
        helperDisplayToast(t('SUCCESS'), true);
      } else {
        helperDisplayToast(t('ERROR_UPDATE_ORDERSLOTS_TIMINGS'), false);
        isSuccess = false;
      }
    }

    if (resOrderSlot?.status === 200 || resSubscription?.status === 200) {
      await clientQuery.invalidateQueries(['subscriptions']);
    }

    if (isSuccess) {
      handleClose();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (orderSlotData) {
      setOrderSlot({
        maxOrder: orderSlotData?.orderingSlot.max_order,
        offset: orderSlotData?.orderingSlot.offset,
        minutes: orderSlotData?.orderingSlot.minutes,
      });
    }
  }, [orderSlotData]);
  const Icon =
    MODULES_ICONS[moduleSelected?.code as keyof typeof MODULES_ICONS];
  return (
    <ModalWrapper
      title={
        <div css={styles.title}>
          {Icon && <Icon />}
          <p>
            {` | ${
              subscriptionSelected?.consumption_mode?.label || 'CONSULTATION'
            }`}
          </p>
        </div>
      }
      cancelLabel={t('CANCEL')}
      validateLabel={t('SAVE')}
      minWidth={'85%'}
      maxWidth={'85%'}
      maxHeight={'98%'}
      onCancel={handleClose}
      showBtns={false}
      showMiniBtns={true}
      isLoading={loading}
      onSave={handleSubmit}>
      {!!subscriptionSelected?.timings_enabled && (
        <div css={styles.timingsContainer}>
          <Title title={t('ORDER_HOURS')} padding="0px 0px 15px 0px" />
          <div className="flex-space-between" css={styles.timings}>
            <DaysContainer
              days={days}
              handleAddDay={handleAddDay}
              handleChangeDate={handleChangeDate}
              handleRemoveDay={handleRemoveDay}
              handleBlurDate={handleBlurDate}
            />
          </div>
        </div>
      )}

      {!!subscriptionSelected?.cards_enabled && cards && (
        <div css={styles.cards}>
          <Title title={t('CARDS')} padding="0px 0px 15px 0px" />
          <div css={styles.cardsList}>
            {cards?.map((card, index) => {
              const findCard = cards.find(c => c.uuid === card.uuid);
              return (
                <FilterCheckboxItem
                  key={index}
                  entry={{
                    name: card.label,
                    value: card.uuid,
                  }}
                  handleCheckedChange={() => {
                    handleChangeCard(card.uuid);
                  }}
                  checked={!!findCard?.isActive}
                />
              );
            })}
          </div>
        </div>
      )}

      {!!subscriptionSelected?.ordering_slots_enabled && (
        <div css={styles.timingsContainer}>
          <Title title={t('WITHDRAWAL_SCHEDULE')} padding="0px 0px 15px 0px" />
          <div className="flex-space-between" css={styles.timings}>
            <DaysContainer
              days={slotDays}
              handleAddDay={handleSlotAddDay}
              handleChangeDate={handleSlotChangeDate}
              handleRemoveDay={handleSlotRemoveDay}
              handleBlurDate={handleSlotBlurDate}
            />
          </div>
        </div>
      )}

      {!!subscriptionSelected?.ordering_slots_enabled && (
        <div css={styles.timingsContainer}>
          <Title title={t('ORDER_SLOTS')} padding="0px 0px 15px 0px" />
          <div css={styles.ordering_slots}>
            <SelectList
              label={t('DURATION_OF_SLOTS')}
              list={DURATION_OF_SLOTS_LIST}
              name="offset"
              manageByFormik={false}
              value={orderSlot?.offset}
              onChange={handleChangeOrderSlotData}
            />

            <InputLabel
              label={t('ORDER_BY_SLOTS')}
              type="number"
              marginBottom={0}
              value={'' + orderSlot?.maxOrder}
              name="maxOrder"
              onChange={handleChangeOrderSlotData}
            />
            <InputLabel
              label={t('DELAY_BEFORE_NEXT_SLOT') + ' (min)'}
              type="number"
              marginBottom={0}
              value={'' + orderSlot?.minutes}
              name="minutes"
              onChange={handleChangeOrderSlotData}
            />
          </div>
        </div>
      )}
      {cardLoading && <AbsoluteLoader />}
    </ModalWrapper>
  );
};

const styles = {
  timingsContainer: css({
    marginTop: '2rem',
  }),
  timings: css({
    alignItems: 'flex-start',
    marginTop: '1rem',
  }),
  cards: css({
    marginTop: '2rem',
  }),
  cardsList: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '1rem',
    gap: '1rem',
    padding: '1rem 4rem',
  }),
  ordering_slots: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '3rem',
    padding: '0 6rem',
  }),
  title: css({
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  }),
};

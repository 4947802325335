/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';

export const RequiredLabel = ({required = false, label = ''}) => {
  return (
    <p className="textLabel">
      {label && label}
      {required && <span css={styles.required}>*</span>}
    </p>
  );
};

const styles = {
  required: css({
    color: THEME.colors.red,
    marginLeft: 5,
  }),
};

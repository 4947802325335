import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {columnsPrinters} from './columns';
import {usePrinters} from '../../../../../hooks/usePrinters';

export const Printers = () => {
  const {printers, isLoading} = usePrinters();

  return (
    <NewDataGrid
      columns={columnsPrinters}
      rows={printers}
      rowHeight={60}
      loading={isLoading}
    />
  );
};

import {createSlice} from '@reduxjs/toolkit';
import {PeriodicalPriceFetchResponse} from '@bill-app-types/b-types';
import {IPriceRule} from '../../api/fetchPriceRules';

interface IState {
  priceRuleSelected: IPriceRule | null;
  eventSelected: PeriodicalPriceFetchResponse | null;
}

const initialState: IState = {
  priceRuleSelected: null,
  eventSelected: null,
};

const slice = createSlice({
  name: 'priceRules',
  initialState,
  reducers: {
    priceRuleSelectedAction: (state, action) => {
      state.priceRuleSelected = action.payload;
    },
    eventSelectedAction: (state, action) => {
      state.eventSelected = action.payload;
    },
  },
});

export const {priceRuleSelectedAction, eventSelectedAction} = slice.actions;

export default slice.reducer;

/** @jsxImportSource @emotion/react */
import {ToggleButtonSections} from '../../../../../components/molecules/ToggleButtonSections';
import {css} from '@emotion/react';

export const Center = () => {
  return (
    <div css={styles.container}>
      <ToggleButtonSections
        sections={['MENUS', 'PRODUCTS', 'OPTIONS']}
        defaultSection="MENUS"
      />
    </div>
  );
};

const styles = {
  container: css({width: '100%', height: '100%', display: 'flex'}),
};

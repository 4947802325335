/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';

export const IconSquare = ({
  Icon,
  backgroundColor,
  borderColor,
  active,
  size = '2.5rem',
  borderRadius = '5px',
  onClick,
}: {
  Icon: React.FC<{color?: string}>;
  backgroundColor?: string;
  borderColor?: string;
  active?: boolean;
  size?: string;
  borderRadius?: string;
  onClick?: () => void;
}) => {
  return (
    <div
      className="flex-center"
      css={styles.container({
        backgroundColor: active ? borderColor : backgroundColor,
        borderColor,
        size,
        borderRadius,
      })}
      onClick={onClick}>
      <Icon color={active ? 'white' : borderColor} />
    </div>
  );
};

const styles = {
  container: ({
    backgroundColor,
    borderColor,
    size,
    borderRadius,
  }: {
    backgroundColor?: string;
    borderColor?: string;
    size?: string;
    borderRadius?: string;
  }) =>
    css({
      width: size,
      height: size,
      backgroundColor,
      border: borderColor ? '1px solid' : 'none',
      borderColor,
      borderRadius,
      cursor: 'pointer',
    }),
};

import {SVGProps} from 'react';

export const DownLoadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={11}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.531 8.82H1.558c-.315 0-.57.235-.57.525 0 .307.255.543.57.543H9.53c.315 0 .57-.235.57-.525 0-.29-.254-.542-.57-.542ZM8.167 3.947 6.114 5.954V.638c0-.29-.254-.525-.57-.525-.315 0-.569.234-.569.524v5.315L2.922 3.946a.585.585 0 0 0-.415-.166c-.14 0-.28.047-.39.143a.494.494 0 0 0-.025.74l3.038 2.97a.621.621 0 0 0 .829 0l3.037-2.97a.494.494 0 0 0-.024-.74c-.229-.2-.59-.208-.805.023Z"
      fill={props.color || '#626262'}
    />
  </svg>
);

export const ArrowDownLoadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#a)">
      <path
        d="M11.11 12.039H1c-.4 0-.723.31-.723.69 0 .406.324.717.723.717h10.11c.4 0 .723-.31.723-.691a.727.727 0 0 0-.722-.716ZM9.38 5.61 6.777 8.258v-7.01a.707.707 0 0 0-.722-.694c-.4 0-.722.31-.722.691v7.01L2.729 5.61a.728.728 0 0 0-.526-.219.735.735 0 0 0-.494.189.67.67 0 0 0-.031.976l3.851 3.916a.765.765 0 0 0 1.052 0l3.852-3.916a.67.67 0 0 0-.031-.976c-.29-.263-.748-.275-1.022.03Z"
        fill="#61D295"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(.277 -.367)"
          d="M0 0h11.556v14.733H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

import {useQuery} from '@tanstack/react-query';
import {getReceipts} from '../api/fetchReceipts';
import {helperDate} from '../helpers/helperDate';
import {helperJoinFilterValues} from '../helpers/helperFilter';
import {helperFormatPrice} from '../helpers/helperFormatPrice';
import {useAppSelector} from './useReduxHook';
import {useDebounce} from './useDebounce';
import {helperFormatAmountSort} from '../helpers/helperFormatAmountSort';

export const useReceipt = (offset: number, limit: number) => {
  const search = useAppSelector(s => s.filter.search);
  const dates = useAppSelector(s => s.filter.dates);
  const channels = useAppSelector(s => s.filter.channels);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const orderBy = useAppSelector(s => s.filter.orderBy);
  const {min, max} = useAppSelector(s => s.filter.amount);
  const brand = useAppSelector(s => s.brand.brand);
  const debouncedSearchTerm = useDebounce(search, 500);

  const {data, isLoading, error} = useQuery(
    [
      'receipts',
      debouncedSearchTerm,
      dates,
      channels,
      consumptionModes,
      max,
      min,
      orderBy,
      offset,
      limit,
      brand?.id,
    ],
    () =>
      getReceipts({
        search: debouncedSearchTerm,
        from: dates.from,
        to: dates.to,
        offset,
        limit,
        channels: helperJoinFilterValues(channels, true),
        consumptionModes: helperJoinFilterValues(consumptionModes, true),
        amountMax: max ? max * 100 : null,
        amountMin: min ? min * 100 : null,
        orderBy: helperFormatAmountSort(orderBy, 'amountInclTax'),
      }),
  );

  const receipts =
    data?.posReceipts?.map((item: any) => ({
      ...item,
      date: helperDate(item.createdAt, 'YYYY-MM-DD'),
      time: helperDate(item.createdAt, 'HH:mm:ss'),
      total: `${helperFormatPrice(item.amountInclTax)}€`,
    })) || [];

  return {
    totalAmount: data?.totalAmount,
    receipts,
    isReceiptsLoading: isLoading,
    receiptsError: error,
    totalReceipts: data?.totalPosReceipts,
    lastIndex: data?.lastIndex || 0,
  };
};

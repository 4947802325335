/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {BadgeCounter} from '../../../../../../../components/atoms/BadgeCounter';
import {EditIcon} from '../../../../../../../assets/svgs/EditIcon';
import {TrashIcon} from '../../../../../../../assets/svgs/TrashIcon';
import {THEME} from '../../../../../../../styles/theme';
import {ImageCustom} from '../../../../../../../components/atoms/ImageCustom';
import {helperConfirmDialog} from '../../../../../../../helpers/helperConfirmDialog';
import {deletePosCategoryItem} from '../../../../../../../api/fetchPosCategories';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../hooks/useReduxHook';
import {helperDisplayToast} from '../../../../../../../helpers/helperToast';
import {useQueryClient} from '@tanstack/react-query';
import {addPosButtonModalAction} from '../../../../../../../redux/modals';
import {
  updateModeAction,
  updatedPosCategoryItemAction,
} from '../../../../../../../redux/myModulesSection/pos';
import {helperLabelTranslation} from '../../../../../../../helpers/helperLabel';
import {useTranslation} from 'react-i18next';
import {CATEGORY_HAS_ITEM_TYPES} from '@bill-app-types/b-types';
import {useBrandAccess} from '../../../../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../../../../constants/frontPolicies';

const Top = ({item}: {item: any}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const clientQuery = useQueryClient();
  const selectedPosCategory = useAppSelector(
    state => state.myModulesSection.pos.selectedPosCategory,
  );
  const selectedButtons = useAppSelector(
    s => s.myModulesSection.pos.selectedButtons,
  );
  const {brandHasAuthorization} = useBrandAccess();
  const hasParentAuthorization = brandHasAuthorization(
    BRAND_AUTHORIZATION.PARENT,
  );

  const lastButton = selectedButtons[selectedButtons.length - 1];

  const handleEdit = (e: any) => {
    e.stopPropagation();
    dispatch(addPosButtonModalAction(true));
    dispatch(updateModeAction(true));
    dispatch(updatedPosCategoryItemAction(item));
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();

    helperConfirmDialog({
      title: t('DELETE_BUTTON'),
      text: helperLabelTranslation(item),
      onSubmit: async () => {
        const res = await deletePosCategoryItem(
          lastButton?.uuid || selectedPosCategory.uuid,
          item.uuid,
          {
            type:
              item.type === null
                ? CATEGORY_HAS_ITEM_TYPES.CATEGORY
                : CATEGORY_HAS_ITEM_TYPES.ITEM,
          },
        );
        if (res && [200, 204].includes(res.status)) {
          helperDisplayToast('Bouton supprimé', true);
          clientQuery.invalidateQueries([
            `fetchPosCategoryItems-${
              lastButton?.uuid || selectedPosCategory.uuid
            }`,
          ]);
        } else {
          helperDisplayToast(
            res.data?.message || 'Impossible de supprimer le bouton',
            false,
          );
        }
      },
    });
  };

  return (
    <div css={styles.container}>
      <BadgeCounter count={item.position} />

      <div css={styles.imgContainer}>
        <ImageCustom src={item.image} />
      </div>

      {hasParentAuthorization && (
        <div css={styles.btns}>
          <button
            disabled={!!item.type || !hasParentAuthorization}
            css={[styles.btn, {opacity: !!item.type ? 0.5 : 1}]}
            onClick={handleEdit}>
            <EditIcon />
          </button>
          <button
            css={styles.btn}
            onClick={handleDelete}
            disabled={!hasParentAuthorization}>
            <TrashIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default Top;

const styles = {
  container: css({
    position: 'relative',
    width: '100%',
    // height: 156,
    height: '70%',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  imgContainer: css({
    backgroundColor: THEME.colors.white,
    flex: 1,
    height: '100%',
  }),
  img: css({
    width: '100%',
    height: '100%',
  }),
  btns: css({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    width: 32,
    height: '100%',
    padding: '0.25rem 0.25rem 0rem 0.18rem;',
    gap: '0.4rem',
  }),
  btn: css({
    width: '100%',
    height: '48%',
    border: 'none',
    background: THEME.colors.bgLight,
    cursor: 'pointer',
  }),
};

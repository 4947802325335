/** @jsxImportSource @emotion/react */
// import {useTranslation} from 'react-i18next';
// import {OrderByFilter} from '../../../../../components/filters/OrderByFilter';
// import {SearchFilter} from '../../../../../components/filters/SearchFilter';
import {VisibilityFilter} from '../../../../../components/filters/VIsibilityFilter';
import {css} from '@emotion/react';

export const Bottom = () => {
  // const {t} = useTranslation();

  return (
    <div css={styles.container}>
      {/* <SearchFilter />
      <OrderByFilter label={t('USERS')} field="firstname" /> */}
      <VisibilityFilter values={['Inactif', 'Actif']} title="Actif" />
      {/* <OrderByFilter label={t('DATE_ADDED')} field="createdAt" />
      <OrderByFilter label={t('MODIFICATION_DATE')} field="updatedAt" /> */}
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    gap: '1vh',
  }),
};

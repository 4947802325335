/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import React, {useEffect, useState} from 'react';
import {THEME} from '../../../styles/theme';

const regexEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

export const InputLabel = ({
  required = false,
  placeholder = '',
  label = '',
  width = '100%',
  name = '',
  type = 'text',
  marginBottom = '0.94rem',
  onChange,
  showIcon = false,
  iconColor = 'black',
  Icon,
  value,
  iconTopPosition = '56%',
  onClickIcon,
  disabled = false,
  ...props
}: {
  required?: boolean;
  placeholder?: string;
  label?: string;
  width?: string | number;
  name?: string;
  type?: string;
  marginBottom?: number | string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showIcon?: boolean;
  Icon?: React.FC<{color: string}>;
  iconColor?: string;
  value?: string;
  iconTopPosition?: string | number;
  onClickIcon?: () => void;
  disabled?: boolean;
}) => {
  //

  const [styleInput, setStyleInput] = useState({} as any);

  useEffect(() => {
    if (!value) {
      setStyleInput({});
      return;
    }

    if (type === 'email' && !regexEmail.test(value)) {
      setStyleInput({
        border: `1px solid ${THEME.colors.red}`,
      });
    } else if (type === 'email' && regexEmail.test(value)) {
      setStyleInput({
        border: `1px solid ${THEME.colors.green}`,
      });
    }
  }, [value]);

  const handleFocusAndBlur = () => {
    if (!value) {
      setStyleInput({});
    }
  };

  return (
    <div
      css={[
        styles.container(width),
        {
          marginBottom,
        },
      ]}>
      {label && (
        <p className="textMedium" css={styles.label}>
          {label}
          {required && <span css={styles.required}>*</span>}
        </p>
      )}

      <input
        onFocus={handleFocusAndBlur}
        onBlur={handleFocusAndBlur}
        placeholder={placeholder}
        type={type}
        name={name}
        css={[
          styles.input,
          styles.placeholder,
          {
            ...styleInput,
          },
        ]}
        className="textMedium"
        onChange={onChange}
        disabled={disabled}
        {...props}
      />

      {showIcon && !!Icon && (
        <div css={[styles.icon, {top: iconTopPosition}]} onClick={onClickIcon}>
          <Icon color={iconColor} />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: (width: string | number) =>
    css({
      position: 'relative',
      width,
      marginBottom: 10,
    }),
  label: css({
    padding: '0 0.62rem',
    color: '#626262',
    marginBottom: '0.44rem',
    fontWeight: 600,
  }),
  required: css({
    color: '#EC5174',
    marginLeft: 5,
  }),
  input: css({
    width: '100%',
    border: 'none',
    height: '2.4375rem',
    boxSizing: 'border-box',
    padding: '0 0.62rem',
    borderRadius: 5,
    backgroundColor: '#EEF1F6',
  }),
  placeholder: css`
    &::placeholder {
      font-size: 12px;
      font-weight: 600;
      color: #151a30;
    }
  `,
  icon: css({
    position: 'absolute',
    right: 10,
    top: '50%',
    cursor: 'pointer',
  }),
};

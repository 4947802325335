/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {NavLink, useNavigate} from 'react-router-dom';
import {ArrowLeft} from '../../assets/svgs/ArrowLeft';
// import {DbIcon} from '../../assets/svgs/DbIcon';
import {THEME} from '../../styles/theme';
import {sidebarDropdownOpenedAction} from '../../redux/sidebar';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {TextVariant} from './TextVariant';
import {helperExternalUrl} from '../../helpers/helperExternalUrl';
import {BRAND_AUTHORIZATION} from '../../constants/frontPolicies';
import {useBrandAccess} from '../../hooks/useBrandAccess';

interface IProps {
  label: string;
  url?: string;
  list?: {
    label: string;
    Icon: any;
    url?: string;
    redirect?: string;
    authorization?: BRAND_AUTHORIZATION;
    disabled?: boolean;
    justForChild?: boolean;
  }[];
  redirect?: string;
  IconRight?: any;
  authorization?: BRAND_AUTHORIZATION;
}

export const SidebarLink = ({
  label,
  list,
  url,
  redirect,
  IconRight,
  authorization = BRAND_AUTHORIZATION.ALL,
}: IProps) => {
  const navigate = useNavigate();
  const sidebarDropdownOpened = useAppSelector(
    s => s.sidebar.sidebarDropdownOpened,
  );
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const isOpen = sidebarDropdownOpened === label;
  const sideBarOpen = useAppSelector(s => s.sidebar.isOpen);

  const {brandHasAuthorization} = useBrandAccess();
  // const currentTheme = useAppSelector((s ) => s.app.currentTheme);

  // const currentLinkHref = window.location.href;

  const handleClick = () => {
    if (sidebarDropdownOpened === label) {
      dispatch(sidebarDropdownOpenedAction(null));
    } else {
      dispatch(sidebarDropdownOpenedAction(label));
      if (list) {
        // Check for redirection
        if (!list[0].redirect) {
          if (brandHasAuthorization(list[0].authorization)) {
            navigate('' + list[0].url);
          }
        }
      }
    }
    if (redirect) {
      window.location.href = helperExternalUrl(redirect) || '';
    } else {
      if (url) {
        navigate('/online/' + url);
      }
    }
  };

  if (!brandHasAuthorization(authorization)) return null;

  return (
    <>
      <div
        css={styles.sideBarLink(isOpen && sideBarOpen)}
        onClick={handleClick}>
        {/* {!sideBarOpen && <DbIcon />} */}
        {sideBarOpen && (
          <>
            <TextVariant
              variant="title03"
              text={label}
              style={styles.sideBarLinkLabel(isOpen)}
            />

            {list && (
              <p css={styles.arrowRight(isOpen)}>
                <ArrowLeft color={isOpen ? THEME.colors.white : '#A7A7AB'} />
              </p>
            )}
            {IconRight && (
              <IconRight color={isOpen ? THEME.colors.white : '#A7A7AB'} />
            )}
          </>
        )}
      </div>
      {/* {sideBarOpen && isOpen && list && list?.length > 0 && ( */}
      {sideBarOpen && (
        <div css={styles.sideBarList(isOpen)}>
          {list?.map((item, index) => {
            if (!brandHasAuthorization(item.authorization) || item.disabled)
              return null;

            if (item.redirect) {
              //TMP for redirect

              return (
                <a
                  href={helperExternalUrl(item.redirect)}
                  key={index}
                  css={styles.sideBarListItem}
                  className={item.label === label ? 'active' : 'inactive'}>
                  <item.Icon
                    color={
                      item.label === label ? THEME.colors.white : '#A7A7AB'
                    }
                  />
                  <TextVariant variant="title03" text={t(item.label)} />
                </a>
              );
            }

            return (
              <NavLink
                className={({isActive}) => (isActive ? 'active' : 'inactive')}
                to={item.url || ''}
                key={index}
                css={styles.sideBarListItem}>
                <item.Icon color={'white'} />
                <TextVariant variant="title03" text={t(item.label)} />
              </NavLink>
            );
          })}
        </div>
      )}
      {/* )} */}
    </>
  );
};

const styles = {
  sideBarLink: (isOpen: boolean) =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      textTransform: 'uppercase',
      textDecoration: 'none',
      color: 'white',
      padding: '0 15px',
      height: '27px',
      fontWeight: '700',
      fontSize: '10px',
      background: isOpen ? THEME.colors.primary : 'transparent',
      overflowX: 'hidden',
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.6,
      },
    }),
  sideBarLinkLabel: (isOpen: boolean) =>
    css(`
    white-space: nowrap;
    color:${isOpen ? THEME.colors.white : '#A7A7AB'}
  `),
  arrowRight: (isOpen: boolean) =>
    css({
      transform: `rotate(${isOpen ? 270 : 180}deg)`,
      transitionDuration: '0.2s',
    }),
  sideBarList: (isOpen: boolean) =>
    css({
      maxHeight: `${isOpen ? '1000vh' : '0px'}`,
      overflow: 'hidden',
      transitionDuration: '.3s',
      display: 'flex',
      flexDirection: 'column',
    }),

  sideBarListItem: css(`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--sidebar-color);
    height:27px;
    margin-bottom:5px;
    padding-left:12px;

    &:hover {
      background: rgba(60, 106, 246, 0.4);
      color:white
    }

    p {
      margin-left: 10px;
      font-size: 10px;
      font-weight: 700;
    }

    &:first-of-type {
      margin-top: 5px;
    }

    &.active{
      background: rgba(60, 106, 246, 0.4);
      color:white
    }

  `),
};

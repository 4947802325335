import {useNavigate, useParams} from 'react-router-dom';
import {ButtonCustom} from '../../../../../../components/atoms/ButtonCustom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../hooks/useReduxHook';
import {
  addProductCategoryModalAction,
  addStructureModalAction,
} from '../../../../../../redux/modals';
import {structureSelectedAction} from '../../../../../../redux/myCatalogSection/categories';
import {helperDisplayToast} from '../../../../../../helpers/helperToast';
import {updateStructure} from '../../../../../../api/fetchStructures';
import {THEME} from '../../../../../../styles/theme';
import {BRAND_AUTHORIZATION} from '../../../../../../constants/frontPolicies';
import {useBrandAccess} from '../../../../../../hooks/useBrandAccess';

export const Center = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const {brandHasAuthorization} = useBrandAccess();

  const structureSelected = useAppSelector(
    s => s.myCatalogSection.categories.structureSelected,
  );

  const handleOpenModalAddStructureCategory = () => {
    dispatch(addStructureModalAction(true));
  };

  const handleOpenModalAddProductCategory = () => {
    dispatch(addProductCategoryModalAction(true));
  };

  const handleCancel = () => {
    navigate('/online/catalog/tree');
    dispatch(structureSelectedAction(null));
  };

  const handleSubmit = async () => {
    if (params.uuid) {
      if (structureSelected?.label && structureSelected?.type) {
        const res: any = await updateStructure(params.uuid, structureSelected);
        if (res?.status === 204) {
          helperDisplayToast('Arborescence modifiée', true);
          navigate('/online/catalog/tree');
        } else {
          helperDisplayToast(
            res?.data?.message || "Impossible de modifier l'arborescence",
            false,
          );
        }
      } else {
        helperDisplayToast('Tous les champs doivent être complétés ', false);
      }
    }
  };

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  return (
    <>
      {params.uuid ? (
        <>
          <ButtonCustom
            backgroundColor={THEME.colors.red}
            label={'Annuler'}
            onClick={handleCancel}
            marginRight={'1vh'}
          />
          <ButtonCustom
            backgroundColor={THEME.colors.green}
            label={'Enregistrer'}
            onClick={handleSubmit}
            marginRight={'1vh'}
            disabled={!hasAuthorization}
          />
          <ButtonCustom
            label={'Ajouter une catégorie de produit'}
            onClick={handleOpenModalAddProductCategory}
            disabled={!hasAuthorization}
          />
        </>
      ) : (
        <ButtonCustom
          label={'Ajouter une arborescence'}
          disabled={!hasAuthorization}
          onClick={handleOpenModalAddStructureCategory}
        />
      )}
    </>
  );
};

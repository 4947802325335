/** @jsxImportSource @emotion/react */

import {Formik} from 'formik';
import {BDriveIcon} from '../../../../assets/svgs/BDriveIcon';
import {ModuleDetails} from '../../../../components/molecules/ModuleDetails';
import {THEME} from '../../../../styles/theme';
import {DriveObjectives} from './DriveObjectives';
import {DriveSettings} from './DriveSettings';

const BDriveSection = () => {
  const initialValues = {
    refreshTime: '',
    lunchtimeRushStart: '',
    lunchtimeRushEnd: '',
    everingRushStart: '',
    everingRushEnd: '',
    marginOfIndicator: '',
    marginOfIndicator2: '',
    numberOfVehiclesPerHour: 0,
    numberOfVehiclesPerDay: 0,
    numberOfVehiclesPerWeek: 0,
    numberOfVehiclesPerMonth: 0,
    orderingTime: '',
    serviceTime: '',
    timeToTakePayment: '',
    waitingTime: '',
    waitingTimeShedding: '',
  };

  return (
    <div>
      <ModuleDetails
        module={{
          Icon: BDriveIcon,
          image:
            'https://s3-alpha-sig.figma.com/img/2d92/2ca7/9663b057b2a3b3961a7010f784b043de?Expires=1672012800&Signature=jJfRVLjZl-hj0a6~4XzqowLBC5swmaDFKFHePPF14B7eAaee0qOQspTTL6Z3qdR7MoEwVUNIrDTCnHIdIY~x3LATxc6P18YGliWlwpTYOLgVmy7OrFRMDuBORgvb6rSDZ3mA1vsHB5Fhek4ACPCCnCo5i6iTnISNjVfVorsUET-6tYrdkdvbeuKYi3X5w0wD2w9Nl36u598HMHTV3OnZd6b0qbS~TyHHcrrk83BKQBOPjESfCh3mODLolP3EE3ejU4GbwGMk6fGz-3SmkwfXFZ7fsMZpYUd842sOqxjAhopeKmn-NLa~FE3xujMBOWRI7leNRNLINJwvGOrv1bGKJA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
          title: 'Commander sur place ou emporter',
          options: [
            {
              name: 'Horaires',
              color: THEME.colors.primary,
            },
          ],
        }}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(false);
        }}>
        {({handleChange, values}) => {
          return (
            <>
              <DriveSettings
                formikValues={values}
                formikHandleChange={handleChange}
              />
              <DriveObjectives />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default BDriveSection;

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext, useEffect} from 'react';
import {Title} from '../../../../../components/atoms/Title';
import {DropDownOptions} from '../../../../../components/molecules/DropDownOptions';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {THEME} from '../../../../../styles/theme';
import {ICreateItemHasModifierPostionsPayload, OPTION_TYPES} from '@bill-app-types/b-types';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {
  selectedOptionListAction,
  selectedOptionTypeAction,
} from '../../../../../redux/myCatalogSection/products';
import {useQuery} from '@tanstack/react-query';
import {
  getItemModifiers,
  updateItemModifier,
} from '../../../../../api/fetchItems';
import {OptionsList} from './OptionsList';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {useBrandAccess} from '../../../../../hooks/useBrandAccess';
import {BRAND_AUTHORIZATION} from '../../../../../constants/frontPolicies';
import { THandleDragAndDrop } from '../../../../../components/organisms/SortableListVerticalContainer';

export const Options = () => {
  const {t} = useContext<any>(UtilsContext);
  const dispatch = useAppDispatch();

  const {brandHasAuthorization} = useBrandAccess();

  const hasAuthorization = brandHasAuthorization(BRAND_AUTHORIZATION.PARENT);

  const selectedOptionType = useAppSelector(
    s => s.myCatalogSection.products.selectedOptionType,
  );

  const productPreRegister = useAppSelector(
    s => s.myCatalogSection.products.productPreRegister,
  );

  const {data, isLoading} = useQuery(
    ['getItemOptionsCategories', selectedOptionType],
    () =>
      getItemModifiers(productPreRegister?.uuid, {
        optionTypes: [selectedOptionType],
      }),
    {
      enabled: !!productPreRegister?.uuid && !!selectedOptionType,
    },
  );
  const modifiers = data?.modifiers;

  const handleOpenDropDown = (value: number) => {
    if (selectedOptionType === value) {
      dispatch(selectedOptionTypeAction(null));
    } else {
      dispatch(selectedOptionTypeAction(value));
    }
    dispatch(selectedOptionListAction([]));
  };

  const handleDragAndDrop: THandleDragAndDrop = async ({items}) => {
    if (!hasAuthorization) return;
    const itemUuids: ICreateItemHasModifierPostionsPayload = { modifierUuids: items.map(item => item.uuid) };
    const res = await updateItemModifier(
      productPreRegister.uuid,
      itemUuids
    );
    if (res?.status === 204) {
      helperDisplayToast('Position modifiée', true);
    } else {
      helperDisplayToast(
        res?.data?.message || 'Impossible de modifier la position',
        false,
      );
    }
  };

  useEffect(() => {
    if (data?.modifiers) {
      dispatch(selectedOptionListAction(data?.modifiers));
    }
  }, [data]);

  return (
    <div>
      <Title title={t('OPTIONS')} />

      <div className="flex-col" css={styles.optionsContent}>
        <DropDownOptions
          dropDown={OPTION_TYPES.NORMAL}
          onOpen={handleOpenDropDown}
          active={selectedOptionType === OPTION_TYPES.NORMAL}
          title="Composition du produit">
          <div className="flex-col" css={styles.dropDownContent}>
            {modifiers && modifiers.length === 0 && !isLoading ? (
              <span style={{alignSelf: 'center'}} className="textSemiBold">
                Aucune donnée
              </span>
            ) : (
              selectedOptionType === OPTION_TYPES.NORMAL && (
                <OptionsList
                  data={modifiers || []}
                  handleDragAndDrop={handleDragAndDrop}
                />
              )
            )}
          </div>
        </DropDownOptions>

        <DropDownOptions
          dropDown={OPTION_TYPES.SUPPLEMENT}
          onOpen={handleOpenDropDown}
          active={selectedOptionType === OPTION_TYPES.SUPPLEMENT}
          headerBgColor={THEME.colors.green}
          title="Supplément du produit">
          <div className="flex-col" css={styles.dropDownContent}>
            {modifiers && modifiers.length === 0 && !isLoading ? (
              <span style={{alignSelf: 'center'}} className="textSemiBold">
                Aucune donnée
              </span>
            ) : (
              selectedOptionType === OPTION_TYPES.SUPPLEMENT && (
                <OptionsList
                  data={modifiers || []}
                  handleDragAndDrop={handleDragAndDrop}
                />
              )
            )}
          </div>
        </DropDownOptions>

        <DropDownOptions
          dropDown={OPTION_TYPES.WITHDRAWAL}
          onOpen={handleOpenDropDown}
          active={selectedOptionType === OPTION_TYPES.WITHDRAWAL}
          headerBgColor={THEME.colors.red}
          title="Retrait du produit">
          <div className="flex-col" css={styles.dropDownContent}>
            {modifiers && modifiers.length === 0 && !isLoading ? (
              <span style={{alignSelf: 'center'}} className="textSemiBold">
                Aucune donnée
              </span>
            ) : (
              selectedOptionType === OPTION_TYPES.WITHDRAWAL && (
                <OptionsList
                  data={modifiers || []}
                  handleDragAndDrop={handleDragAndDrop}
                />
              )
            )}
          </div>
        </DropDownOptions>
      </div>
    </div>
  );
};

const styles = {
  optionsContent: css({
    gap: '0.5rem',
    paddingTop: 10,
  }),
  dropDownContent: css({
    gap: '0.5rem',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../../../../../styles/theme';
import {TextVariant} from '../../../../../../components/atoms/TextVariant';
import {PillLabel} from '../../../../../../components/molecules/PillLabel';
import {MetricBox} from '../../../../../../components/molecules/MetricBox';
import {CardLargeIcon} from '../../../../../../assets/svgs/CardIcon';
import {TicketIcon} from '../../../../../../assets/svgs/TicketIcon';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@tanstack/react-query';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {useParams} from 'react-router-dom';
import {getStatItemDetailParent} from '../../../../../../api/fetchStats';
import {helperFormatPrice} from '../../../../../../helpers/helperFormatPrice';
import {helperJoinFilterValues} from '../../../../../../helpers/helperFilter';
import {ValueSeparatorPercent} from '../../../../../../components/atoms/ValueSeparatorPercent';
import {ProductCard} from '../../../../../../components/atoms/ProductCard';
import {PRODUCT_TYPES} from '@bill-app-types/b-types';
import {M_SCREEN_DESKTOP} from '../../../../../../constants/ResponsiveSize';
import {useFlagBCatalog} from '../../../../../../hooks/useFlagBCatalog';
import {helperTruncImgUrl} from '../../../../../../helpers/helperTruncImgUrl';

export const Footer = () => {
  const {t} = useTranslation();
  const dates = useAppSelector(s => s.filter.dates);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const channels = useAppSelector(s => s.filter.channels);
  const currentMode = useAppSelector(s => s.filter.currentMode);
  const {id} = useParams();
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {data} = useQuery(
    [
      'getStatItemDetailParent',
      flagBCatalogEnabled,
      id,
      dates,
      channels,
      consumptionModes,
    ],
    () =>
      getStatItemDetailParent(String(id), {
        ...dates,
        consumptionModeIds: helperJoinFilterValues(consumptionModes, true),
        channelIds: helperJoinFilterValues(channels, true),
        forStats: !flagBCatalogEnabled,
      }),
    {
      enabled: !!id && flagBCatalogReady,
    },
  );

  const total_ttc =
    data?.parentItems?.reduce((t, item: any) => t + item.sales.TTC.value, 0) ||
    0;

  const total_ht =
    data?.parentItems?.reduce((t, item: any) => t + item.sales.HT.value, 0) ||
    0;

  const total_orders =
    data?.parentItems?.reduce(
      (t, item: any) => t + item.sales.orders.value,
      0,
    ) || 0;

  return (
    <div css={styles.container}>
      <div css={styles.header} className="flex flex-space-between">
        <div>
          <TextVariant
            text="VENTES PRODUITS ASSOCIES PAR ETAPE"
            variant="textSemiBold"
          />
          <div className="flex" css={styles.pills}>
            <PillLabel
              text={`CA ${currentMode}`}
              backgroundColor={THEME.colors.primary}
              variant="textSemiBold"
            />
            <PillLabel
              text="NB DE COMMANDES"
              backgroundColor={THEME.colors.yellowDark}
              variant="textSemiBold"
            />
          </div>
        </div>

        <div css={styles.metrics} className="flex">
          <MetricBox
            label={`CA ${currentMode}`}
            Icon={CardLargeIcon}
            value={helperFormatPrice(
              currentMode === 'TTC' ? total_ttc : total_ht,
            )}
            item="TURNOVER"
            suffix={'€'}
          />

          <MetricBox
            label={t('NUMBER_OF_ORDERS')}
            Icon={TicketIcon}
            value={total_orders}
            item="ORDERS"
            borderColor={THEME.colors.yellowDark}
          />
        </div>
      </div>

      <div css={styles.products}>
        {data?.parentItems?.map((product: any, index) => {
          return (
            <div key={index} css={styles.product}>
              <ProductCard
                item={{
                  label: product.label,
                  image: flagBCatalogEnabled
                    ? helperTruncImgUrl(product.imgUrl, 'M')
                    : product.img_url_set?.M,
                  type: PRODUCT_TYPES.COURSE,
                }}
                handleOnClick={() => {}}
                showProductType
                showActions={false}
                showStartPrice={false}
                showOverlay={false}
                typeColor={THEME.colors.purple}
              />
              <div
                className="flex flex-col-center"
                style={{
                  marginTop: '0.5rem',
                }}>
                <ValueSeparatorPercent
                  value={product.sales.TTC.value}
                  percent={product.sales.TTC.percent}
                  color={THEME.colors.primary}
                  suffix="€"
                />
                <ValueSeparatorPercent
                  value={product.sales.orders.value}
                  percent={product.sales.TTC.percent}
                  color={THEME.colors.yellowDark}
                  formatValue={false}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    padding: '2vh',
    width: '100%',
  }),
  header: css({
    [M_SCREEN_DESKTOP]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  }),
  metrics: css({
    gap: '1rem',
    [M_SCREEN_DESKTOP]: {
      flexDirection: 'column',
      marginTop: '1rem',
    },
  }),
  pills: css({
    marginTop: '0.5rem',
    gap: '0.5rem',
  }),
  products: css({
    display: 'flex',
    overflowX: 'auto',
    gap: '1rem',
    marginTop: '2rem',
    padding: '2rem 0',
  }),
  product: css({
    minWidth: '150px',
    minHeight: '150px',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {CloseIcon} from '../../assets/svgs/CloseIcon';
import {THEME} from '../../styles/theme';

export const DisabledFiltersButton = ({onclick}: {onclick?: () => void}) => {
  const {t} = useTranslation();
  return (
    <button onClick={onclick} className="flex-center" css={styles.container}>
      <CloseIcon color="white" />
      <span
        style={{
          marginLeft: 5,
        }}>
        {t('DISABLED_FILTERS')}
      </span>
    </button>
  );
};

const styles = {
  container: css({
    border: 'none',
    backgroundColor: THEME.colors.red,
    color: THEME.colors.white,
    padding: '0 10px',
    height: 28,
    fontFamily: 'Montserrat-Bold',
    fontSize: 10,
    cursor: 'pointer',
    marginRight: 5,
  }),
};

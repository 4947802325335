/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../../../styles/theme';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {YellowStarIcon} from '../../../../assets/svgs/YellowStarIcon';
import {helperFormatPrice} from '../../../../helpers/helperFormatPrice';

export const TicketFooter = ({
  order,
  loyalty,
}: {
  order: any;
  loyalty?: number;
}) => {
  //  const selectedOrder = useAppSelector(s => s.customer.selectedOrder);

  return (
    <div>
      <div css={styles.sectionFlex}>
        <TextVariant
          text={'Points gagnés'}
          style={{color: THEME.colors.yellow}}
          variant="textSemiBold"
        />
        <div className="flex-center">
          <TextVariant
            text={`+ ${loyalty || order?.loyaltyGain || 0} `}
            style={{color: THEME.colors.yellow, marginRight: 3}}
            variant="textSemiBold"
          />
          <YellowStarIcon />
        </div>
      </div>
      <div css={styles.sectionFlex}>
        <TextVariant
          text={'Point dépensés'}
          style={{color: THEME.colors.yellow}}
          variant="textSemiBold"
        />
        <div className="flex-center">
          <TextVariant
            text={`- ${order?.paid_by_points || order?.paidByPoints || 0} `}
            style={{color: THEME.colors.yellow, marginRight: 3}}
            variant="textSemiBold"
          />
          <YellowStarIcon />
        </div>
      </div>

      <div css={styles.sectionFlex}>
        <TextVariant text={'Total TTC'} variant="textSemiBold" />
        <TextVariant
          text={`${
            order?.final_price || helperFormatPrice(order?.totalPrice) || 0
          }€`}
          variant="textSemiBold"
        />
      </div>
    </div>
  );
};

const styles = {
  header: css({
    padding: 10,
    textAlign: 'center',
  }),
  sectionFlex: css({
    padding: '5px 10px 5px 20px',
    display: 'flex',
    justifyContent: 'space-between',
  }),
};

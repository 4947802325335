export const SaveIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4161 1.56871C10.3348 1.48746 10.2383 1.42302 10.1321 1.37906C10.026 1.3351 9.91218 1.31248 9.79727 1.3125H2.57633C2.41035 1.31246 2.24599 1.34513 2.09264 1.40863C1.93929 1.47213 1.79995 1.56522 1.68259 1.68259C1.56522 1.79995 1.47213 1.93929 1.40863 2.09264C1.34513 2.24599 1.31246 2.41035 1.3125 2.57633V11.4237C1.31246 11.5897 1.34513 11.754 1.40863 11.9074C1.47213 12.0607 1.56522 12.2 1.68259 12.3174C1.79995 12.4348 1.93929 12.5279 2.09264 12.5914C2.24599 12.6549 2.41035 12.6875 2.57633 12.6875H11.4237C11.7585 12.6865 12.0794 12.553 12.3162 12.3162C12.553 12.0794 12.6865 11.7585 12.6875 11.4237V4.20273C12.6875 4.08782 12.6649 3.97403 12.6209 3.86786C12.577 3.76168 12.5125 3.66521 12.4313 3.58395L10.4161 1.56871ZM7 11.375C6.65388 11.375 6.31554 11.2724 6.02775 11.0801C5.73997 10.8878 5.51566 10.6145 5.38321 10.2947C5.25076 9.97493 5.2161 9.62306 5.28363 9.28359C5.35115 8.94413 5.51782 8.63231 5.76256 8.38756C6.0073 8.14282 6.31913 7.97615 6.65859 7.90863C6.99806 7.8411 7.34993 7.87576 7.6697 8.00821C7.98947 8.14066 8.26278 8.36497 8.45507 8.65275C8.64736 8.94054 8.75 9.27888 8.75 9.625C8.75029 9.85489 8.70522 10.0826 8.61738 10.295C8.52953 10.5075 8.40064 10.7005 8.23808 10.8631C8.07552 11.0256 7.88249 11.1545 7.67004 11.2424C7.45759 11.3302 7.22989 11.3753 7 11.375ZM8.3125 5.25H3.0625C2.94647 5.25 2.83519 5.20391 2.75314 5.12186C2.67109 5.03981 2.625 4.92853 2.625 4.8125V3.0625C2.625 2.94647 2.67109 2.83519 2.75314 2.75314C2.83519 2.67109 2.94647 2.625 3.0625 2.625H8.3125C8.42853 2.625 8.53981 2.67109 8.62186 2.75314C8.70391 2.83519 8.75 2.94647 8.75 3.0625V4.8125C8.75 4.92853 8.70391 5.03981 8.62186 5.12186C8.53981 5.20391 8.42853 5.25 8.3125 5.25Z"
      stroke="white"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

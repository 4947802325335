export const BurgerIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.85599 2.71083C7.71043 2.55014 7.53462 2.38757 7.31534 2.24012C7.31723 2.25146 7.31723 2.2647 7.31723 2.27793C7.31723 2.44428 7.18112 2.58039 7.01477 2.58039C6.84841 2.58039 6.7123 2.44428 6.7123 2.27793C6.7123 2.15505 6.78414 2.05108 6.88811 2.00193C6.51192 1.82991 6.04689 1.70703 5.46465 1.67868C5.48734 1.72216 5.50057 1.77131 5.50057 1.82424C5.50057 1.99059 5.36446 2.1267 5.19811 2.1267C5.03176 2.1267 4.89565 1.99059 4.89565 1.82424C4.89565 1.77131 4.90888 1.72216 4.93157 1.67868C4.35122 1.70703 3.88429 1.82991 3.50811 2.00193C3.61208 2.04919 3.68391 2.15505 3.68391 2.27793C3.68391 2.44428 3.54781 2.58039 3.38145 2.58039C3.2151 2.58039 3.07899 2.44428 3.07899 2.27793C3.07899 2.2647 3.07899 2.25335 3.08088 2.24012C2.8616 2.38757 2.6839 2.55014 2.54023 2.71083C2.29827 2.97926 2.1338 3.25904 2.02794 3.48777H8.36828C8.26053 3.25904 8.09795 2.97926 7.85599 2.71083ZM8.88435 4.39516H1.51565C1.2113 4.39516 0.965547 4.14941 0.965547 3.84506C0.965547 3.80914 0.969328 3.77511 0.976889 3.73919C1.07708 3.34221 1.85025 0.765625 5.2 0.765625C8.54975 0.765625 9.32292 3.34221 9.42311 3.73919C9.43256 3.77511 9.43445 3.80914 9.43445 3.84506C9.43445 4.14941 9.1887 4.39516 8.88435 4.39516ZM2.78031 8.32715H7.61969C8.01478 8.32715 8.35126 8.07384 8.47603 7.72223H1.92397C2.04874 8.07384 2.38522 8.32715 2.78031 8.32715ZM0.965547 7.41977C0.965547 7.08517 1.23587 6.81484 1.57047 6.81484H8.82953C9.16413 6.81484 9.43445 7.08517 9.43445 7.41977C9.43445 8.42167 8.62159 9.23453 7.61969 9.23453H2.78031C1.77841 9.23453 0.965547 8.42167 0.965547 7.41977ZM0.663086 5.605C0.663086 5.2704 0.93341 5.00008 1.26801 5.00008H9.13199C9.46659 5.00008 9.73691 5.2704 9.73691 5.605C9.73691 5.9396 9.46659 6.20992 9.13199 6.20992H1.26801C0.93341 6.20992 0.663086 5.9396 0.663086 5.605Z"
        fill="white"
      />
    </svg>
  );
};

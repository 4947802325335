/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {Options} from './Options';
import {Menus} from './Menus';
import {M_SCREEN_DESKTOP} from '../../../../../../constants/ResponsiveSize';

export const Footer = () => {
  return (
    <div css={styles.container}>
      <Options />
      <Menus />
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    [M_SCREEN_DESKTOP]: {
      flexDirection: 'column',
    },
  }),
};

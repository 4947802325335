/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {helperDisplayToast} from '../../../helpers/helperToast';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';
import {AuthLayout} from '../components/Layout/AuthLayout';
import {InputLabel} from '../components/InputLabel';
import {useTranslation} from 'react-i18next';

export const ForgetPassword = () => {
  const {t} = useTranslation();

  const handleSubmit = async (
    values: {email: string},
    {setSubmitting}: any,
  ) => {
    try {
      const email = values.email;

      if (!email) {
        helperDisplayToast(t('PLEASE_CHECK_YOUR_EMAIL'));
        setSubmitting(false);
        return;
      }

      setSubmitting(false);
    } catch (error) {
      helperDisplayToast(t('PLEASE_CHECK_YOUR_EMAIL'));
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={handleSubmit}>
      {({values, handleChange, isSubmitting, handleSubmit}) => (
        <AuthLayout
          animType="password"
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}>
          <InputLabel
            label={t('EMAIL')}
            name="email"
            placeholder={t('YOUR_EMAIL')}
            value={values.email}
            onChange={handleChange}
          />

          <div className="flex-justify-end" css={styles.formInputsFooter}>
            <Link to="/">{t('RETURN_TO_THE_CONNECTION')}</Link>
          </div>
        </AuthLayout>
      )}
    </Formik>
  );
};

const styles = {
  formInputsFooter: css(`
    width: 100%;
    margin: 1rem 0 2.5rem;

    p,a{
      font-size: 10px;
    }
    

    p{

      span{
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid #A7A7AB;
        margin-right: 5px;

        &.active {
          background: #A7A7AB;
        }
      }
      font-family: 'Montserrat-SemiBold', sans-serif;
      color:#A7A7AB;
    }


    a{
      text-decoration: underline;
    }
  `),
};

export const PercentIcon = ({color}: {color?: string}) => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9053_184414)">
      <path
        d="M14.7127 1.94489C15.0958 1.61291 15.0958 1.0761 14.7127 0.747659C14.3297 0.419215 13.7103 0.415684 13.3313 0.747659L0.287286 12.0454C-0.095762 12.3774 -0.095762 12.9142 0.287286 13.2426C0.670334 13.5711 1.28973 13.5746 1.6687 13.2426L14.7127 1.94489ZM4.24002 2.47463C4.24002 2.25202 4.18942 2.03158 4.09113 1.82591C3.99283 1.62024 3.84875 1.43337 3.66712 1.27595C3.48549 1.11854 3.26986 0.993673 3.03255 0.908482C2.79524 0.823291 2.54089 0.779444 2.28403 0.779444C2.02716 0.779444 1.77281 0.823291 1.5355 0.908482C1.29819 0.993673 1.08256 1.11854 0.900932 1.27595C0.719302 1.43337 0.575224 1.62024 0.476927 1.82591C0.378629 2.03158 0.328036 2.25202 0.328036 2.47463C0.328036 2.69725 0.378629 2.91769 0.476927 3.12336C0.575224 3.32903 0.719302 3.5159 0.900932 3.67332C1.08256 3.83073 1.29819 3.9556 1.5355 4.04079C1.77281 4.12598 2.02716 4.16983 2.28403 4.16983C2.54089 4.16983 2.79524 4.12598 3.03255 4.04079C3.26986 3.9556 3.48549 3.83073 3.66712 3.67332C3.84875 3.5159 3.99283 3.32903 4.09113 3.12336C4.18942 2.91769 4.24002 2.69725 4.24002 2.47463ZM14.672 11.5157C14.672 11.0661 14.4659 10.6349 14.0991 10.317C13.7322 9.99906 13.2347 9.82046 12.716 9.82046C12.1972 9.82046 11.6997 9.99906 11.3329 10.317C10.9661 10.6349 10.76 11.0661 10.76 11.5157C10.76 11.9652 10.9661 12.3964 11.3329 12.7143C11.6997 13.0322 12.1972 13.2108 12.716 13.2108C13.2347 13.2108 13.7322 13.0322 14.0991 12.7143C14.4659 12.3964 14.672 11.9652 14.672 11.5157Z"
        fill={color || '#3C6AF6'}
      />
    </g>
    <defs>
      <clipPath id="clip0_9053_184414">
        <rect y="0.5" width="15" height="13" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import {MyAccountingRouter} from '../views/Online/MyAccounting';
import {MyCatalogRouter} from '../views/Online/MyCatalog';
import {MyOffers} from '../views/Online/MyOffers';
import {MyRestaurantRouter} from '../views/Online/MyRestaurant';
import {MyTranslations} from '../views/Online/MyTranslations';
import {
  // MyModules,
  MyModulesRouter,
} from '../views/Online/MyModules';
import {MyOrdersRouter} from '../views/Online/MyOrders';
import {MyStaffRouter} from '../views/Online/MyStaff';
import {CreateProductRouter} from '../views/Online/MyCatalog/ProductCreate';
import {MyStatsRouter} from '../views/Online/MyStats';
import {DashboardRouter} from '../views/Online/Dashboard';
import {Outlet, RouteObject} from 'react-router-dom';
import {ButtonSynchro} from '../components/molecules/ButtonSynchro';
// import {brandHasAuthorizationForRouting} from '../helpers/helperFrontPolicies';
// import {BRAND_AUTHORIZATION} from '../constants/frontPolicies';
// import {Outlet} from 'react-router-dom';

export const OnlineRouter: RouteObject = {
  path: 'online',
  element: (
    <>
      <Outlet />
      <ButtonSynchro />
    </>
  ),
  children: [
    DashboardRouter,
    MyRestaurantRouter,
    MyModulesRouter,
    // {
    //   path: 'modules',
    //   element: <MyModules />,
    //   loader: () => brandHasAuthorizationForRouting(BRAND_AUTHORIZATION.CHILD),
    // },
    MyOrdersRouter,
    MyStatsRouter,
    MyAccountingRouter,
    MyOffers,
    MyCatalogRouter,
    CreateProductRouter,
    MyStaffRouter,
    MyTranslations,
  ],
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useTranslation} from 'react-i18next';
import {useDateManage} from '../../../hooks/useDateManage';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHook';
import {eventModalAction} from '../../../redux/modals';
import {FieldsRequired} from '../../atoms/FieldsRequired';
import {ModalWrapper} from '../../atoms/ModalWrapper';
import {DaysContainer} from '../../organisms/DaysContainer';
import {useEffect, useState} from 'react';
import {
  IFetchItemDetailsReturn,
  IPeriodicalPricesPostPayload,
} from '@bill-app-types/b-types';
import {SelectList} from '../../molecules/SelectList';
import {InputLabel} from '../../atoms/InputLabel';
import {usePriceRule} from '../../../hooks/usePriceRule';
import {helperFormatSelectItem} from '../../../helpers/helperFormatSelectItem';
import {
  helperFormatDays,
  helperFormatTimingsToDays,
} from '../../../helpers/helperFormatDays';
import {
  createPeriodicalPrice,
  createPeriodicalPriceHasItem,
  deletePeriodicalPriceHasItem,
  fetchPeriodicalPrice,
  updatePeriodicalPrice,
} from '../../../api/fetchPeriodicalPrices';
import {helperDisplayToast} from '../../../helpers/helperToast';
import {helperGenerateSlotLabel} from '../../../helpers/helperGenerateSlotLabel';
import {eventSelectedAction} from '../../../redux/myOffersSection/priceRules';
import {useQueryClient} from '@tanstack/react-query';
import {helperDeepCopyObject} from '../../../helpers/helperDeepCopyObject';
import {helperDate} from '../../../helpers/helperDate';
import {ProductsAndMenuCatalog} from '../../organisms/ProductsAndMenuCatalog';
import {ProductsAndMenuOfEvent} from './ProductsAndMenuOfEvent';

export const EventModal = () => {
  //
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<IPeriodicalPricesPostPayload>({
    label: '',
    priceRuleUuid: '',
    start: new Date(),
    end: new Date(),
    slotInformations: {
      label: '',
      timings: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      },
    },
  } as IPeriodicalPricesPostPayload);

  const eventSelected = useAppSelector(
    s => s.myOffersSection.priceRules.eventSelected,
  );

  const startDateValue = helperDate(form?.start, 'YYYY-MM-DD');
  const endDateValue = helperDate(form?.end, 'YYYY-MM-DD');

  const clientQuery = useQueryClient();

  const {data} = usePriceRule();

  const {
    days,
    handleAddDay,
    handleRemoveDay,
    handleChangeDate,
    handleReset,
    handleBlurDate,
    setDays,
  } = useDateManage();

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const {name, value} = e.currentTarget;
    setForm({...form, [name]: value});
  };

  const handleSave = async () => {
    setIsLoading(true);

    try {
      let res;

      if (startDateValue > endDateValue) {
        helperDisplayToast(t('START_DATE_MUST_BE_BEFORE_END_DATE'), false);
        return;
      }

      if (eventSelected?.uuid) {
        res = await updatePeriodicalPrice(eventSelected?.uuid, {
          ...form,
          slotInformations: {
            ...form.slotInformations,
            label: helperGenerateSlotLabel(),
          },
        });
      } else {
        res = await createPeriodicalPrice({
          ...form,
          slotInformations: {
            ...form.slotInformations,
            label: helperGenerateSlotLabel(),
          },
        });
      }

      if (res && res?.status === 204) {
        helperDisplayToast(
          t(eventSelected?.uuid ? 'EVENT_UPDATED' : 'EVENT_CREATED'),
          true,
        );
        clientQuery.invalidateQueries(['fetchPeriodicalPrices']);
        onCloseModal();
      }
    } catch (err) {
      helperDisplayToast(t('ERROR'), false);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseModal = () => {
    dispatch(eventModalAction(false));
    dispatch(eventSelectedAction(null));
    handleReset();
  };

  useEffect(() => {
    if (days) {
      const data = helperFormatDays(helperDeepCopyObject(days));
      setForm(prevForm => ({
        ...prevForm,
        slotInformations: {
          ...prevForm.slotInformations,
          timings: data,
        },
      }));
    }
  }, [days]);

  useEffect(() => {
    if (eventSelected?.timings) {
      const newArray = Array.from(helperDeepCopyObject(days));
      const newDays = helperFormatTimingsToDays(
        newArray,
        eventSelected?.timings,
      );
      setForm({
        label: eventSelected?.label,
        priceRuleUuid: eventSelected?.priceRule?.uuid || '',
        start: new Date(helperDate(eventSelected?.start, 'YYYY-MM-DD')),
        end: new Date(helperDate(eventSelected?.end, 'YYYY-MM-DD')),
        slotInformations: {
          label: helperGenerateSlotLabel(),
          timings: newDays,
        },
      });
      setDays(newDays);
    }
  }, [eventSelected]);

  useEffect(() => {
    if (data?.length) {
      setForm(prevForm => ({
        ...prevForm,
        priceRuleUuid: prevForm.priceRuleUuid || data[0].uuid,
      }));
    }
  }, [data]);

  const isDisabled =
    !form.label ||
    !form.priceRuleUuid ||
    !form.start ||
    !form.end ||
    !!(
      form.slotInformations.timings &&
      Object.values(form.slotInformations.timings).some(
        (timing: any) => timing.length === 0,
      )
    );

  const handleRefresh = async () => {
    if (!eventSelected) return;
    const res = await fetchPeriodicalPrice(eventSelected.uuid);
    if (res) {
      dispatch(eventSelectedAction(res));
    }
  };

  const handleAddItem = async (item: IFetchItemDetailsReturn) => {
    if (!eventSelected) return;
    const productFinded = eventSelected.items?.find(p => p.uuid === item.uuid);
    if (!productFinded) {
      const res = await createPeriodicalPriceHasItem(
        eventSelected?.uuid,
        item.uuid,
      );
      if (res?.status === 204) {
        helperDisplayToast('Item ajouté', true);
        handleRefresh();
      } else {
        helperDisplayToast(
          res?.data?.message || "Impossible d'ajouter l'item",
          false,
        );
      }
    }
  };

  const handleRemoveItem = async (item: IFetchItemDetailsReturn) => {
    if (!eventSelected) return;
    const res = await deletePeriodicalPriceHasItem(
      eventSelected?.uuid,
      item.uuid,
    );
    if (res?.status === 204) {
      helperDisplayToast('Item retiré', true);
      handleRefresh();
    } else {
      helperDisplayToast(
        res?.data?.message || "Impossible de retirer l'item",
        false,
      );
    }
  };

  const handleIsDisabled = (eventItem: IFetchItemDetailsReturn) => {
    return (
      eventSelected?.items?.find(item => item?.uuid === eventItem.uuid) || false
    );
  };

  return (
    <ModalWrapper
      title={t(eventSelected ? 'UPDATE_EVENT' : 'CREATE_AN_EVENT')}
      onSave={handleSave}
      showBtns={false}
      onCancel={onCloseModal}
      minWidth={'70%'}
      maxWidth={'90%'}
      maxHeight={'95%'}
      showMiniBtns
      cancelLabel={t(eventSelected ? 'CANCEL' : 'CANCEL_CREATE')}
      validateLabel={t(eventSelected ? 'SAVE' : 'REGISTER_EVENT')}
      disabled={isDisabled}
      isLoading={isLoading}>
      <InputLabel
        label={t('EVENT_NAME')}
        name="label"
        required
        onChange={handleChange}
        value={form.label}
      />
      <SelectList
        label={t('PRICE_RULE')}
        name="priceRuleUuid"
        value={form?.priceRuleUuid}
        required
        list={data?.map(item => helperFormatSelectItem(item))}
        onChange={handleChange}
        manageByFormik={false}
        placeHolder={t('SELECT_PRICE_RULE')}
      />
      <div className="flex-space-between">
        <InputLabel
          label={t('EVENT_START_DATE')}
          name="start"
          value={startDateValue}
          required
          width={'48%'}
          onChange={handleChange}
          type="date"
          max={endDateValue !== startDateValue ? endDateValue : undefined}
        />
        <InputLabel
          label={t('EVENT_END_DATE')}
          name="end"
          value={endDateValue}
          onChange={handleChange}
          required
          width={'48%'}
          type="date"
          min={startDateValue}
        />
      </div>

      <div
        className="flex-space-between"
        css={css({
          alignItems: 'flex-start',
        })}>
        <DaysContainer
          days={days}
          handleAddDay={handleAddDay}
          handleChangeDate={handleChangeDate}
          handleRemoveDay={handleRemoveDay}
          handleBlurDate={handleBlurDate}
        />
      </div>
      {eventSelected && (
        <div css={styles.catalogs}>
          <ProductsAndMenuCatalog
            onClickProduct={handleAddItem}
            hiddenProducts={false}
            handleIsDisabled={handleIsDisabled}
          />
          <ProductsAndMenuOfEvent
            data={eventSelected?.items}
            onClickItem={handleRemoveItem}
          />
        </div>
      )}
      <FieldsRequired />
    </ModalWrapper>
  );
};

const styles = {
  catalogs: css({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    marginTop: '2.5rem',
    height: '70vh',
  }),
};

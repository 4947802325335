import {
  ISuggestedItem,
  OPTION_TYPES,
  SUGGESTION_TYPES,
} from '@bill-app-types/b-types';
import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  updateMode: boolean;
  productPreRegister: any;
  productPreRegisterSelected: any;
  selectedOptionType: number;
  selectedOptionList: object[];
  productPictograms: object[];
  productRecipes: object[];
  selectedAdditionalType: number;
  selectedAdditionalList: ISuggestedItem[];
}

const initialState: ISlice = {
  updateMode: false,
  productPreRegister: null,
  productPreRegisterSelected: null,
  selectedOptionType: OPTION_TYPES.NORMAL,
  selectedOptionList: [],
  productPictograms: [],
  productRecipes: [],
  selectedAdditionalType: SUGGESTION_TYPES.PRODUCT_DETAIL,
  selectedAdditionalList: [],
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    updateModeAction(state, action) {
      state.updateMode = action.payload;
    },
    productPreRegisterAction(state, action) {
      state.productPreRegister = action.payload;
      state.productPreRegisterSelected = action.payload;
    },

    productPreRegisterChangeAction(state, action) {
      const {name, value} = action.payload;
      if (state.productPreRegister) {
        state.productPreRegister[name] = value;
      }
    },
    selectedOptionTypeAction(state, action) {
      state.selectedOptionType = action.payload;
    },
    selectedOptionListAction(state, action) {
      state.selectedOptionList = action.payload;
    },
    productPictogramsAction(state, action) {
      state.productPictograms = action.payload;
    },
    productRecipesAction(state, action) {
      state.productRecipes = action.payload;
    },
    selectedAdditionalTypeAction(state, action) {
      state.selectedAdditionalType = action.payload;
    },
    selectedAdditionalListAction(state, action) {
      state.selectedAdditionalList = action.payload;
    },
  },
});

export const {
  updateModeAction,
  productPreRegisterAction,
  productPreRegisterChangeAction,
  selectedOptionTypeAction,
  selectedOptionListAction,
  productPictogramsAction,
  productRecipesAction,
  selectedAdditionalTypeAction,
  selectedAdditionalListAction,
} = slice.actions;

export default slice.reducer;

/* @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../../components/atoms/TextVariant';
import {SalesGraphs} from '../../../../../components/organisms/SalesGraphs';
import {useTranslation} from 'react-i18next';
import {useStatGlobal} from '../../../../../hooks/useStatGlobal';
// import {ButtonCustom} from '../../../../../components/atoms/ButtonCustom';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {GeneralSaleColumns} from '../DataColumns/GeneralSaleColumns';
import {
  helperCombinedCompareSalesData,
  helperSalesData,
} from '../../../../../helpers/helperSalesData';
import {ScaleFilter} from '../../../../../components/filters/ScaleFilter';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
// import {exportSalesGlobal} from '../../../../../api/fetchStats';
// import {useAppSelector} from '../../../../../hooks/useReduxHook';
// import {helperDate} from '../../../../../helpers/helperDate';
// import {helperDisplayToast} from '../../../../../helpers/helperToast';

export const GeneralSales = () => {
  const {t} = useTranslation();

  const dates = useAppSelector(s => s.filter.dates);
  const comparesDates = useAppSelector(s => s.filter.compareDates);
  const isCompare = useAppSelector(s => s.filter.isCompare);
  // const channels = useAppSelector(s => s.filter.channels);
  // const consumptionModes = useAppSelector(s => s.filter.consumptionModes);

  const {data, isLoading} = useStatGlobal({
    dates,
  });

  const {data: dataCompare} = useStatGlobal({
    dates: comparesDates,
    isEnabled: !!isCompare,
  });

  // const handleExport = async () => {
  //   if (dates.scale) {
  //     const res = await exportSalesGlobal({
  //       from: helperDate(dates.from, 'YYYY-MM-DD HH:mm:ss', true),
  //       to: helperDate(dates.to, 'YYYY-MM-DD HH:mm:ss', true),
  //       scale: dates.scale,
  //     });
  //     if (res.status !== 200) {
  //       helperDisplayToast('Error', false);
  //     }
  //   }
  // };

  return (
    <div css={styles.container}>
      <div className="flex-space-between" css={styles.header}>
        <TextVariant
          text={t('SALES_CHART')}
          variant="textMedium"
          style={{
            marginBottom: '1rem',
            textTransform: 'uppercase',
          }}
        />
        <div className="flex">
          <ScaleFilter withBorder />
        </div>
      </div>

      <SalesGraphs data={data} dataCompare={dataCompare} />

      <div css={styles.list}>
        <div css={styles.listButtonExport}>
          {/* <ButtonCustom onClick={handleExport} label={t('EXPORT_DATA')} /> */}
        </div>
        <div css={styles.dataGrid}>
          <NewDataGrid
            columns={GeneralSaleColumns}
            rows={
              isCompare
                ? helperCombinedCompareSalesData(data, dataCompare, true)
                : helperSalesData(data, true)
            }
            loading={isLoading}
            rowHeight={37}
            showBorderBottom
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
    backgroundColor: 'white',
    overflow: 'hidden',
  }),
  header: css({
    alignItems: 'flex-start',
    marginBottom: '1rem',
    padding: '2vh',
  }),
  list: css({
    display: 'flex',
    flexDirection: 'column',
    height: 540,
  }),
  listButtonExport: css({
    alignSelf: 'flex-end',
  }),
  dataGrid: css({
    height: '90%',
    padding: '0px 2vh',
  }),
};

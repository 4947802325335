/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {Center} from './HeaderSections/Center';
import {Bottom} from './HeaderSections/Bottom';
import {List} from './List';

export const UsersGroups = withRedirectAndNavigation(() => {
  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} bottomSection={<Bottom />} />
      <div css={styles.content}>
        <List />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
  content: {
    padding: '2vh',
    height: '80%',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

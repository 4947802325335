import {SVGProps} from 'react';

export const ChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.08765 6.23071C0.721436 6.59692 0.721436 7.19165 1.08765 7.55786L6.71265 13.1829C7.07886 13.5491 7.67358 13.5491 8.03979 13.1829C8.40601 12.8167 8.40601 12.2219 8.03979 11.8557L3.0769 6.89282L8.03687 1.92993C8.40308 1.56372 8.40308 0.968994 8.03687 0.602783C7.67065 0.236572 7.07593 0.236572 6.70972 0.602783L1.08472 6.22778L1.08765 6.23071Z"
      fill="#626262"
    />
  </svg>
);

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {Bottom} from './HeaderSections/Bottom';
import {Center} from './HeaderSections/Center';
import {Top} from './HeaderSections/Top';
import Summary from './Summary';
//import History from './History';

const DiscountsAndGiftsJournal = withRedirectAndNavigation(() => {
  //
  return (
    <div css={styles.container}>
      <Header
        topSection={<Top />}
        centerSection={<Center />}
        bottomSection={<Bottom />}
      />
      {/* TODO: lazy import for Summary and History and show on condition */}
      {/* for History dynamise this with good data */}
      <Summary />
      {/* <History /> */}
    </div>
  );
});

const styles = {
  container: css({
    height: '100vh',
    paddingTop: '2rem',
    '@media (max-width: 1024px)': {
      height: 'auto',
    },
  }),
};

export default DiscountsAndGiftsJournal;

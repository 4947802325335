import {CancellationJournal} from './CancellationJournal';
import DiscountsAndGiftsJournal from './DiscountsAndGiftsJournal';
import {ReceiptHistory} from './ReceiptHistory';
import {Parameters} from './Parameters';
import {DaysRouter} from './Days/';
import {Movements} from './Movements';
import {Sales} from './Sales';
import {brandHasAuthorizationForRouting} from '../../../helpers/helperFrontPolicies';
import {BRAND_AUTHORIZATION} from '../../../constants/frontPolicies';
import {ArchiveRouter} from './Archive';

export const MyAccountingRouter = {
  path: 'accounting',
  loader: () => brandHasAuthorizationForRouting(BRAND_AUTHORIZATION.ALL),
  children: [
    {
      path: 'sales',
      loader: () => brandHasAuthorizationForRouting(BRAND_AUTHORIZATION.CHILD),
      element: <Sales />,
    },
    DaysRouter,
    {
      path: 'movements',
      element: <Movements />,
    },
    {
      path: 'invoice-history',
      element: <ReceiptHistory />,
    },
    {
      path: 'discounts-gifts-journal',
      element: <DiscountsAndGiftsJournal />,
    },
    {
      path: 'cancelation-journal',
      element: <CancellationJournal />,
    },
    {
      path: 'parameters',
      element: <Parameters />,
    },
    ArchiveRouter,
  ],
};

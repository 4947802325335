/**  @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

export const DropDownBodyAnim = ({
  isOpened = false,
  children,
}: {
  isOpened?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div css={styles.container(isOpened)}>
      <div css={styles.content}>{children}</div>
    </div>
  );
};

const styles = {
  container: (isOpened: boolean) =>
    css({
      display: 'grid',
      gridTemplateRows: isOpened ? '1fr' : '0fr',
      transition: 'all 0.3s ease-in-out',
    }),
  content: css({
    overflow: 'hidden ',
  }),
};

export const CrossedOutEyes = (props: {color?: string}) => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.8256 2.50991C3.65621 1.94839 4.72121 1.52083 5.99995 1.52083C7.51493 1.52083 8.72805 2.12043 9.61116 2.83266C10.4887 3.53904 11.0755 4.37074 11.353 4.96643C11.4149 5.09501 11.4149 5.23824 11.353 5.36682C11.1018 5.89253 10.5974 6.63309 9.85491 7.29389L11.8274 8.63502C12.0224 8.76849 12.058 9.01425 11.9043 9.18352C11.7505 9.35279 11.4674 9.38372 11.2724 9.25025L0.17242 1.69807C-0.0231782 1.56493 -0.0574716 1.31949 0.0958458 1.14967C0.249182 0.97988 0.53193 0.950111 0.727678 1.0832L2.8256 2.50991ZM3.55872 3.01007L4.42121 3.596C4.84308 3.277 5.3962 3.08331 5.99995 3.08331C7.32556 3.08331 8.39992 4.01592 8.39992 5.16663C8.39992 5.51168 8.3043 5.83557 8.13368 6.12202L9.14242 6.80887C9.77054 6.25386 10.2355 5.60771 10.4737 5.16663C10.2018 4.6637 9.71991 3.98663 8.99804 3.40395C8.22743 2.78384 7.22243 2.28742 5.99995 2.28742C5.05308 2.28742 4.22059 2.59064 3.55872 3.01007ZM7.40431 5.62561C7.46618 5.48238 7.49993 5.32776 7.49993 5.15198C7.49993 4.44723 6.82869 3.84991 5.99995 3.84991C5.98682 3.84991 5.97557 3.86456 5.94557 3.86456C5.98682 3.94756 5.99995 4.03545 5.99995 4.11032C5.99995 4.29099 5.95495 4.44723 5.8762 4.58558L7.40431 5.62561ZM7.58056 7.74636L8.36617 8.28346C7.68556 8.60247 6.89619 8.81243 5.99995 8.81243C4.48496 8.81243 3.27184 8.21348 2.38873 7.50059C1.51161 6.77957 0.925114 5.94787 0.646116 5.36682C0.584617 5.23824 0.584617 5.09501 0.646116 4.96643C0.824989 4.59372 1.13042 4.11195 1.55792 3.62856L2.26498 4.11195C1.91436 4.49118 1.68298 4.86715 1.52717 5.15198C1.78161 5.65491 2.27998 6.34663 3.00185 6.92931C3.77246 7.54942 4.77746 8.03119 5.99995 8.03119C6.57556 8.03119 7.10244 7.92377 7.58056 7.74636ZM3.59997 5.15198C3.59997 5.11943 3.60184 5.07386 3.60559 5.02828L4.65746 5.74768C4.85433 6.0911 5.22183 6.34826 5.66245 6.4215L6.71619 7.15554C6.48932 7.20274 6.24932 7.24994 5.98307 7.24994C4.67433 7.24994 3.58309 6.31734 3.58309 5.15198H3.59997Z"
      fill={props.color || '#EC5174'}
    />
  </svg>
);

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {useEffect} from 'react';
import {useInfiniteQuery} from '@tanstack/react-query';
//import {FilterInput} from '../../../../../components/atoms/FilterInput';
import {Title} from '../../../../../components/atoms/Title';
//import {useQuery} from '@tanstack/react-query';
import {useDebounce} from '../../../../../hooks/useDebounce';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {getCatalogOptionCategories} from '../../../../../api/fetchCatalogOptions';
import {OptionCard} from './OptionCard';
import {OPTION_TYPES} from '../../../../../constants/global';
import {SearchFilter} from '../../../../../components/filters/SearchFilter';
import {InfiniteScrollCustom} from '../../../../../components/atoms/InfiniteScrollCustom';

export const CatalogOfOptionCategories = () => {
  const search = useAppSelector(s => s.filter.search);
  const selectedOptionType = useAppSelector(
    s => s.myCatalogSection.products.selectedOptionType,
  );

  const selectedOptionList = useAppSelector(
    s => s.myCatalogSection.products.selectedOptionList,
  );

  const debouncedSearchTerm = useDebounce(search.trim(), 500);

  const {data, refetch, fetchNextPage, hasNextPage} = useInfiniteQuery(
    ['getCatalogOptionCategories', debouncedSearchTerm, selectedOptionType],
    ({pageParam = 0}) =>
      getCatalogOptionCategories({
        limit: 200,
        offset: pageParam,
        optionType: selectedOptionType,
        search: debouncedSearchTerm,
      }),
    {
      enabled: !!selectedOptionType,
      getNextPageParam: (lastPage, pages) => {
        const currentTotalModifiers = pages?.reduce(
          (acc, page) => acc + (page?.modifiers?.length || 1),
          0,
        );

        if (Number(lastPage?.totalModifiers) === currentTotalModifiers) {
          return undefined;
        }

        return currentTotalModifiers + 1;
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [debouncedSearchTerm, refetch]);

  const options = data?.pages.flatMap((data: any) => data.modifiers) || [];

  return (
    <div>
      <Title
        title={`Catalogue d'options ${
          !!selectedOptionType
            ? 'de ' +
              OPTION_TYPES.find(item => item.value === selectedOptionType)
                ?.label
            : ''
        }`}
      />
      <div css={styles.content}>
        <SearchFilter width={'100%'} />
        <div css={styles.options}>
          {selectedOptionType === null && (
            <span
              className="textSemiBold"
              style={{
                display: 'block',
                textAlign: 'center',
              }}>
              Aucun type de catégorie sélectionné
            </span>
          )}
          {!!selectedOptionType && (
            <InfiniteScrollCustom
              items={options || []}
              refresh={refetch}
              fetchData={fetchNextPage}
              scrollableTarget="scrollableDiv"
              isLoading={!!hasNextPage}>
              {options?.map((item: any, index: number) => (
                <OptionCard
                  isRemove={
                    !!selectedOptionList?.find(
                      (option: any) => option.uuid === item.uuid,
                    )
                  }
                  key={index}
                  option={item}
                />
              ))}
            </InfiniteScrollCustom>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  content: css({
    padding: 10,
    height: '78vh',
    display: 'flex',
    flexDirection: 'column',
  }),
  options: css({
    marginTop: '0.5rem',
    rowGap: '0.5rem',
    overflow: 'auto',
  }),
};

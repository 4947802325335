import {SVGProps} from 'react';

export const LanguagesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5577 0C15.348 0 15.1289 0.0395508 14.9203 0.124014C13.4144 0.735147 12.2981 0.942568 11.3673 0.942568C9.39111 0.942568 8.24249 0.00916992 5.96536 0.00887695C4.82399 0.00887695 3.39884 0.24334 1.44338 0.947842V0.703125C1.44141 0.314941 1.0871 0 0.650391 0C0.213684 0 -0.140625 0.314941 -0.140625 0.703125V14.2969C-0.140625 14.6865 0.213684 15 0.650391 15C1.0871 15 1.44141 14.6851 1.44141 14.2969V11.25C3.20768 10.551 4.60613 10.3181 5.81836 10.3181C8.24513 10.3181 9.93494 11.2491 12.364 11.2491C13.4157 11.2491 14.6059 11.0742 16.0258 10.5727C16.474 10.4268 16.7344 10.0898 16.7344 9.72949V0.900293C16.7344 0.325195 16.184 0 15.5577 0ZM15.1523 9.36914C14.1534 9.68613 13.2381 9.84082 12.364 9.84082C11.3489 9.84082 10.4422 9.63574 9.48208 9.41865C8.42706 9.18015 7.23131 8.90977 5.82034 8.90977C4.42452 8.90977 2.98619 9.17607 1.44338 9.72217V2.46504L2.03763 2.25114C3.59956 1.68864 4.88463 1.41501 5.96634 1.41501C6.89711 1.41512 7.58529 1.61162 8.38257 1.83923C9.21907 2.07794 10.1666 2.3487 11.368 2.3487C12.5357 2.3487 13.749 2.09979 15.155 1.56911V9.36914H15.1523Z"
      fill="white"
    />
  </svg>
);

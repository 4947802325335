export const ArrowUpIcon = () => {
  return (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.85103 4.20902L4.05936 0.771521C4.14673 0.677771 4.26514 0.625427 4.39118 0.625427C4.51722 0.625427 4.63639 0.677927 4.72309 0.770701L7.93142 4.2082C8.10568 4.39558 8.09911 4.69219 7.9159 4.87109C7.73448 5.04992 7.44325 5.0432 7.26775 4.85522L4.84951 2.26371V8.90629C4.84951 9.16508 4.64422 9.35746 4.40837 9.35746C4.17251 9.35746 3.93284 9.16605 3.93284 8.90629V2.26371L1.51514 4.85551C1.33982 5.04301 1.04859 5.04886 0.866556 4.87113C0.683337 4.69144 0.676768 4.39457 0.85103 4.20902Z"
        fill="white"
      />
    </svg>
  );
};

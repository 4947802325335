/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../../../../../styles/theme';
import {TextVariant} from '../../../../../../components/atoms/TextVariant';
import {PillLabel} from '../../../../../../components/molecules/PillLabel';
import {MetricBox} from '../../../../../../components/molecules/MetricBox';
import {CardLargeIcon} from '../../../../../../assets/svgs/CardIcon';
import {TicketIcon} from '../../../../../../assets/svgs/TicketIcon';
import {useTranslation} from 'react-i18next';
import {ProductCard} from '../../../../../../components/atoms/ProductCard';
import {ValueSeparatorPercent} from '../../../../../../components/atoms/ValueSeparatorPercent';
import {useAppSelector} from '../../../../../../hooks/useReduxHook';
import {useParams} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';
import {getStatItemDetailParent} from '../../../../../../api/fetchStats';
import {helperFormatPrice} from '../../../../../../helpers/helperFormatPrice';
import {helperJoinFilterValues} from '../../../../../../helpers/helperFilter';
import {M_SCREEN_DESKTOP} from '../../../../../../constants/ResponsiveSize';
import {useFlagBCatalog} from '../../../../../../hooks/useFlagBCatalog';
import {helperTruncImgUrl} from '../../../../../../helpers/helperTruncImgUrl';
import {PRODUCT_TYPES} from '@bill-app-types/b-types';

export const Menus = () => {
  const {t} = useTranslation();
  const dates = useAppSelector(s => s.filter.dates);
  const currentMode = useAppSelector(s => s.filter.currentMode);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const channels = useAppSelector(s => s.filter.channels);
  const salesGraphSection = useAppSelector(
    s => s.myStatsSection.sales.salesGraphSection,
  );

  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {id} = useParams();
  const {data} = useQuery(
    [
      'getStatItemDetailParent',
      flagBCatalogEnabled,
      id,
      dates,
      channels,
      consumptionModes,
    ],
    () =>
      getStatItemDetailParent(String(id), {
        ...dates,
        consumptionModeIds: helperJoinFilterValues(consumptionModes, true),
        channelIds: helperJoinFilterValues(channels, true),
        forStats: !flagBCatalogEnabled,
      }),
    {
      enabled: !!id && flagBCatalogReady,
    },
  );

  const total_orders =
    data?.parentItems?.reduce(
      (acc, curr: any) => acc + curr.sales.orders.value,
      0,
    ) || 0;

  const total_price =
    data?.parentItems?.reduce(
      (acc, curr: any) => acc + curr.sales[currentMode].value,
      0,
    ) || 0;

  const parentItems = data?.parentItems
    ?.map(item => item)
    .sort(
      (a, b) =>
        b.sales[salesGraphSection === 'ORDERS' ? 'orders' : currentMode] -
        a.sales[salesGraphSection === 'ORDERS' ? 'orders' : currentMode],
    );

  return (
    <div css={styles.container}>
      <div css={styles.header} className="flex flex-space-between">
        <div>
          <TextVariant text="VENTES MENU ASSOCIÉS" variant="textSemiBold" />
          <div className="flex" css={styles.pills}>
            <PillLabel
              text={`CA ${currentMode}`}
              backgroundColor={THEME.colors.primary}
              variant="textSemiBold"
            />
            <PillLabel
              text="NB DE COMMANDES"
              backgroundColor={THEME.colors.yellowDark}
              variant="textSemiBold"
            />
          </div>
        </div>

        <div css={styles.metrics} className="flex">
          <MetricBox
            label={`CA ${currentMode}`}
            Icon={CardLargeIcon}
            value={helperFormatPrice(total_price)}
            item="TURNOVER"
            suffix={'€'}
          />

          <MetricBox
            label={t('NUMBER_OF_ORDERS')}
            Icon={TicketIcon}
            value={total_orders}
            item="ORDERS"
            borderColor={THEME.colors.yellowDark}
          />
        </div>
      </div>

      <div css={styles.menus}>
        {parentItems?.map((product: any, index: number) => {
          return (
            <div key={index} css={styles.menu}>
              <ProductCard
                item={{
                  label: product.label,
                  image: flagBCatalogEnabled
                    ? helperTruncImgUrl(product.imgUrl, 'M')
                    : product.img_url_set?.M,
                  type: PRODUCT_TYPES.MENU,
                }}
                handleOnClick={() => {}}
                showProductType
                showActions={false}
                showStartPrice={false}
                showOverlay={false}
                typeColor={THEME.colors.purple}
              />
              <div
                className="flex flex-col-center"
                style={{
                  marginTop: '0.5rem',
                }}>
                {/* TODO : fix visual line */}
                <ValueSeparatorPercent
                  value={product.sales.TTC.value}
                  percent={product.sales.TTC.percent}
                  color={THEME.colors.primary}
                  suffix="€"
                />
                <ValueSeparatorPercent
                  value={product.sales.orders.value}
                  percent={product.sales.orders.percent}
                  color={THEME.colors.yellowDark}
                  formatValue={false}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    padding: '2vh',
    width: '50%',
    [M_SCREEN_DESKTOP]: {
      width: '100%',
    },
  }),
  header: css({
    [M_SCREEN_DESKTOP]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  }),
  metrics: css({
    gap: '1rem',
    [M_SCREEN_DESKTOP]: {
      flexDirection: 'column',
      marginTop: '1rem',
    },
  }),
  pills: css({
    marginTop: '0.5rem',
    gap: '0.5rem',
  }),
  menus: css({
    display: 'flex',
    overflowX: 'auto',
    gap: '1rem',
    marginTop: '3rem',
    padding: '3rem 0',
  }),
  menu: css({
    minWidth: '150px',
    maxWidth: '150px',
  }),
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {PictureResolution} from './PictureResolution';
import {RequiredLabel} from './RequiredLabel';
import {ImageCustom} from './ImageCustom';
import {PictureIcon, PictureSmallIcon} from '../../assets/svgs/PictureIcon';
import {ChangeEvent} from 'react';

export const UploadButton = ({
  required = false,
  placeholder = '',
  label = '',
  width = '100%',
  value = '',
  name = 'img',
  x = 200,
  y = 200,
  style,
  handleChange,
  disabled = false,
  aspectRatio = '1 / 1',
  smallIcon = false,
}: {
  required?: boolean;
  placeholder?: string;
  label?: string;
  width?: string | number;
  value?: string | File;
  name?: string;
  x?: number;
  y?: number;
  style?: any;
  handleChange?: any;
  disabled?: boolean;
  aspectRatio?: string;
  smallIcon?: boolean;
}) => {
  //

  return (
    <div css={[styles.container(width, disabled, aspectRatio), style]}>
      {label && <RequiredLabel label={label} required={required} />}
      <input
        disabled={disabled}
        placeholder={placeholder}
        id={name}
        hidden
        type="file"
        name={name}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleChange(event);
        }}
        accept="image/png, image/jpeg"
      />
      <label css={styles.btn} htmlFor={name}>
        <div css={styles.content} className="title03">
          {value ? (
            <ImageCustom
              src={
                typeof value !== 'string' ? URL.createObjectURL(value) : value
              }
              alt={name}
              isUpload
            />
          ) : (
            <PictureResolution
              Icon={smallIcon ? PictureSmallIcon : PictureIcon}
              width={x}
              height={y}
            />
          )}
        </div>
      </label>
    </div>
  );
};

const styles = {
  container: (width: string | number, disabled: boolean, aspectRatio: string) =>
    css({
      width,
      aspectRatio,
      marginBottom: 10,
      opacity: disabled ? 0.5 : 1,
    }),
  btn: css({
    backgroundColor: THEME.colors.white,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 2,
  }),
  required: css({
    color: THEME.colors.red,
    marginLeft: 5,
  }),
  content: css({
    textAlign: 'center',
    color: THEME.colors.gray,
    display: 'contents',
  }),
  description: css({
    marginTop: 10,
    marginBottom: 10,
  }),
  img: css({
    width: '100%',
    borderRadius: 2,
  }),
};

import {BRAND_AUTHORIZATION} from '../../../constants/frontPolicies';
import {brandHasAuthorizationForRouting} from '../../../helpers/helperFrontPolicies';
// import {Clients} from './Clients';
// import {Compta} from './Compta';
// import {Drive} from './Drive';
// import {MyDashboard} from './MyDashboard';
import {Sales} from './Sales';
// import {Services} from './Services';

export const DashboardRouter = {
  path: 'dashboard',
  loader: () => brandHasAuthorizationForRouting(BRAND_AUTHORIZATION.CHILD),
  children: [
    // {
    //   path: '',
    //   element: <MyDashboard />,
    // },
    {
      path: 'sales',
      element: <Sales />,
    },
    // {
    //   path: 'services',
    //   element: <Services />,
    // },
    // {
    //   path: 'compta',
    //   element: <Compta />,
    // },
    // {
    //   path: 'drive',
    //   element: <Drive />,
    // },
    // {
    //   path: 'clients',
    //   element: <Clients />,
    // },
  ],
};

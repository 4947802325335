import {useQuery} from '@tanstack/react-query';
import {getStatByChannel} from '../api/fetchStats';
import {useAppSelector} from './useReduxHook';
import {helperJoinFilterValues} from '../helpers/helperFilter';
import {useFlagBCatalog} from './useFlagBCatalog';

export const useStatByChannel = () => {
  const dates = useAppSelector(s => s.filter.dates);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const token = useAppSelector(s => s.auth?.auth?.token);
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {data, isLoading, error} = useQuery(
    [['getStatByChannel', flagBCatalogEnabled, dates, consumptionModes], dates, token],
    () =>
      getStatByChannel({
        from: dates.from,
        to: dates.to,
        consumptionModeIds: helperJoinFilterValues(consumptionModes, true),
        forStats: !flagBCatalogEnabled,
      }),
    {
      enabled: !!flagBCatalogReady,
    },
  );

  return {
    data: data || [],
    isLoading,
    error,
  };
};

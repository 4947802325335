/* @jsxImportSource @emotion/react */
import {ModalWrapper} from '../atoms/ModalWrapper';
import {addUsersGroupModalAction} from '../../redux/modals';
import {FieldCustomInput} from '../atoms/FieldCustomInput';
import {useContext} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {THEME} from '../../styles/theme';
import {css} from '@emotion/react';

// TODO : Remove if not used
export const AddUsersGroupModal = () => {
  const {t, dispatch} = useContext<any>(UtilsContext);

  const initialValues = '';

  /* ON CANCEL */
  const handleOnCancel = () => {
    dispatch(addUsersGroupModalAction(false));
  };

  /* HANDLE SUBMIT */
  const handleOnSubmit = () => {
    // console.log('data', data);
    // setSubmitting(false);
  };

  return (
    <ModalWrapper
      title={t('USERS_GROUP')}
      showBtns={false}
      initialValues={initialValues}
      onCancel={handleOnCancel}
      minWidth={'400px'}>
      <FieldCustomInput
        label={t('GROUP_NAME')}
        name="groupName"
        required
        placeholder={t('GROUP_NAME')}
      />
      <div className="flex-center" css={styles.btns}>
        <ButtonCustom
          label={t('CANCEL')}
          backgroundColor={THEME.colors.red}
          width="38%"
          onClick={handleOnCancel}
        />
        <ButtonCustom
          label={t('VALIDATE')}
          backgroundColor={THEME.colors.green}
          width="38%"
          padding={'0'}
          type="submit"
          marginLeft={'10px'}
          onClick={handleOnSubmit}
        />
      </div>
    </ModalWrapper>
  );
};

const styles = {
  btns: css({
    marginTop: 30,
  }),
};

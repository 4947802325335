/* @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {ConsumptionModeFilter} from '../../../../components/filters/ConsumptionModeFilter';
import {ChannelIdFilter} from '../../../../components/filters/ChannelIdFilter';
import {SalesGraphs} from '../../../../components/organisms/SalesGraphs';
import {useTranslation} from 'react-i18next';
import {useStatGlobal} from '../../../../hooks/useStatGlobal';
import {ScaleFilter} from '../../../../components/filters/ScaleFilter';
import {
  M_SCREEN_DESKTOP,
  M_SCREEN_TABLET,
} from '../../../../constants/ResponsiveSize';

export const BlockTopLeft = () => {
  const {t} = useTranslation();
  const {data} = useStatGlobal();

  return (
    <div css={styles.container}>
      <div className="flex-space-between" css={styles.header}>
        <TextVariant
          text={t('SALES_CHART')}
          variant="textMedium"
          style={{
            marginBottom: '1rem',
            textTransform: 'uppercase',
          }}
        />
        <div className="flex" css={styles.header_filters}>
          <ScaleFilter withBorder />
          <ChannelIdFilter withBorder />
          <ConsumptionModeFilter withBorder marginRight={0} />
        </div>
      </div>

      <SalesGraphs data={data} />
    </div>
  );
};

const styles = {
  container: css({
    width: '49%',
    backgroundColor: 'white',
    gridArea: 'sales_chart',
    [M_SCREEN_DESKTOP]: {
      width: '100%',
    },
  }),
  header: css({
    alignItems: 'flex-start',
    marginBottom: '1rem',
    padding: '2vh',
    paddingBottom: 0,
    [M_SCREEN_TABLET]: {
      flexDirection: 'column',
    },
  }),
  header_filters: css({
    marginLeft: '0.5rem',
    gap: '0.5rem',
    flexWrap: 'wrap',
    [M_SCREEN_TABLET]: {
      marginLeft: 0,
    },
  }),
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {useContext} from 'react';
import {PictureResolution} from '../../../components/atoms/PictureResolution';
import {UtilsContext} from '../../../contexts/UtilsContext';

export const UploadBackgroundPicture = ({
  onChange,
  images,
  name,
  title,
}: {
  images: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  title: string;
}) => {
  const {t} = useContext<any>(UtilsContext);

  return (
    <div css={styles.backgroundPicture}>
      <p className="textLabel">{t(title)}</p>

      <label
        htmlFor={name}
        css={styles.imageContainer100}
        className="flex-center">
        {images[name] && (
          <img
            src={
              images[name]?.name
                ? URL.createObjectURL(images[name])
                : images[name]
            }
            alt="background"
            css={styles.img}
          />
        )}
        {!images[name] && <PictureResolution height={100} width={100} />}
      </label>
      <input
        type="file"
        hidden
        id={name}
        name={name}
        accept="image/*"
        onChange={onChange}
      />
    </div>
  );
};

const styles = {
  backgroundPicture: css({
    width: '20%',
  }),

  img: css({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }),

  imageContainer100: css({
    width: '100%',
    height: 'min(94%,534px)',
    backgroundColor: 'white',
  }),
};

// import {useContext} from 'react';
import {SearchFilter} from '../../../../../../components/filters/SearchFilter';
// import {UtilsContext} from '../../../../../../contexts/UtilsContext';
// import {OrderByFilter} from '../../../../../../components/filters/OrderByFilter';

export const Bottom = () => {
  // const {t} = useContext<any>(UtilsContext);
  return (
    <>
      <SearchFilter marginRight={'1vh'} />
      {/* <OrderByFilter
        icon={'calendar'}
        label={t('DATE_ADDED')}
        field="createdAt"
        marginRight={'1vh'}
      />
      <OrderByFilter
        icon={'calendar'}
        label={t('MODIFICATION_DATE')}
        field="updatedAt"
      /> */}
    </>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {UsersIcon} from '../../../../assets/svgs/UsersIcon';
import {ToggleButton} from '../../../../components/atoms/ToggleButton';
import {helperDate} from '../../../../helpers/helperDate';
import {THEME} from '../../../../styles/theme';
import {DataGridActions} from '../../../../components/newDatagrid/DataGridActions';

export const columnsUsers = [
  {
    field: 'icon',
    headerName: '',
    headerClassName: 'textRegular',
    renderCell: () => (
      <div
        className="flex-align-center"
        style={{
          width: '100%',
        }}>
        <div css={styles.avatar} className="flex-center">
          <UsersIcon />
        </div>
      </div>
    ),
    flex: 0.4,
  },
  {
    field: 'lastname',
    headerName: 'Nom',
    headerClassName: 'textRegular',
    flex: 1,
  },
  {
    field: 'firstname',
    headerName: 'Prénom',
    headerClassName: 'textRegular',
    flex: 1,
  },
  {
    field: 'groupId',
    headerName: 'Groupe',
    headerClassName: 'textRegular',
    valueGetter: (row: any) => row.group?.label,
    flex: 1,
  },
  {
    field: 'isActive',
    headerName: 'Actif',
    headerClassName: 'textRegular',
    flex: 1,
    renderCell: (row: any) => (
      <ToggleButton active={!row.isArchived} onToggle={row.actions?.onToggle} />
    ),
  },
  {
    field: 'createdAt',
    headerName: "Date d'ajout",
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter: (row: any) => {
      return `Le ${helperDate(row.createdAt, 'DD/MM/YYYY')}`;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Date de modification',
    headerClassName: 'textRegular',
    flex: 1,
    valueGetter: (row: any) => {
      return `Le ${helperDate(row.updatedAt, 'DD/MM/YYYY')}`;
    },
  },
  {
    field: 'actions',
    headerName: '',
    flex: 0,
    renderCell: (row: any) => (
      <DataGridActions
        handleClickEdit={row.actions.onUpdate}
        handleClickDelete={row?.actions.onDelete}
      />
    ),
  },
];

const styles = {
  avatar: css({
    width: 45,
    height: 45,
    backgroundColor: THEME.colors.bgLight,
    borderRadius: 25,
  }),
};

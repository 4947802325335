/* @jsxImportSource @emotion/react */
import {ModalWrapper} from '../atoms/ModalWrapper';
import {deleteUserModalAction} from '../../redux/modals';
import {useContext, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {TextVariant} from '../atoms/TextVariant';
import {ButtonCustom} from '../atoms/ButtonCustom';
import {THEME} from '../../styles/theme';
import {css} from '@emotion/react';
import {UsersIcon} from '../../assets/svgs/UsersIcon';
import {useAppSelector} from '../../hooks/useReduxHook';
import {userSelectedAction} from '../../redux/myStaffSection/users';
import {deleteStaffUser} from '../../api/fetchStaff';
import {useQueryClient} from '@tanstack/react-query';
import {helperDisplayToast} from '../../helpers/helperToast';

export const DeleteUserModal = () => {
  const {t, dispatch} = useContext<any>(UtilsContext);

  const [loading, setLoading] = useState(false);
  const userSelected = useAppSelector(s => s.myStaffSection.users.userSelected);

  const clientQuery = useQueryClient();

  /* ON CANCEL */
  const handleOnCancel = () => {
    dispatch(userSelectedAction(null));
    dispatch(deleteUserModalAction(false));
  };

  /* HANDLE SUBMIT */
  const handleOnSubmit = async () => {
    if (!userSelected?.uuid) return;
    try {
      setLoading(true);
      const res = await deleteStaffUser(userSelected?.uuid);

      if (res?.status === 200 || res?.status === 204) {
        dispatch(userSelectedAction(null));
        dispatch(deleteUserModalAction(false));
        clientQuery.invalidateQueries(['fetchStaffUsers']);
        helperDisplayToast(t('USER_DELETED_SUCCESSFULLY'), true);
        setLoading(false);
      } else {
        helperDisplayToast(res?.data?.message, false);
        setLoading(false);
      }
    } catch (error: any) {
      helperDisplayToast(error?.message, false);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalWrapper
      title={t('DELETION')}
      onCancel={handleOnCancel}
      showBtns={false}
      minWidth={'350px'}>
      <div>
        <div css={styles.container}>
          <TextVariant
            variant="textSemiBold"
            text={t('DELETE_USER_TEXT')}
            style={styles.deleteText}
          />

          <div css={styles.userInfo}>
            <div css={styles.usersIcon}>
              <UsersIcon color={THEME.colors.lightGray} />
            </div>
            <TextVariant
              variant="title02"
              text={`${userSelected?.firstname} ${userSelected?.lastname}`}
              style={styles.userName}
            />
          </div>
        </div>

        <div className="flex-center">
          <ButtonCustom
            label={t('CANCEL')}
            backgroundColor={THEME.colors.red}
            width="25%"
            onClick={handleOnCancel}
          />
          <ButtonCustom
            label={t('CONFIRM_DELETION')}
            backgroundColor={THEME.colors.green}
            width="75%"
            padding={'0'}
            type="submit"
            marginLeft={'10px'}
            onClick={handleOnSubmit}
            isLoading={loading}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

const styles = {
  container: css({
    padding: '20px 0',
  }),
  deleteText: css({
    textAlign: 'center',
    marginTop: '20px',
    whiteSpace: 'pre-line',
  }),
  userInfo: css({
    marginTop: '20px',
  }),
  userName: css({
    textAlign: 'center',
    marginBottom: '20px',
    marginTop: '10px',
  }),

  usersIcon: css({
    width: 65,
    height: 65,
    borderRadius: '50%',
    backgroundColor: '#F2F6FA',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  }),
};

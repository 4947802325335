import {createSlice} from '@reduxjs/toolkit';

const inititialState: {
  auth: any;
  frontPolicies: any;
} = {
  auth: null,
  frontPolicies: [],
};

const slice = createSlice({
  name: 'auth',
  initialState: inititialState,
  reducers: {
    authAction(state, action) {
      state.auth = action.payload;
    },
    logoutAction(state) {
      state.auth = null;
      state.frontPolicies = []
    },
    frontPoliciesAction(state, action) {
      state.frontPolicies = action.payload;
    },
  },
});

export const {authAction, logoutAction, frontPoliciesAction} = slice.actions;

export default slice.reducer;

/** @jsxImportSource @emotion/react */
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {helperConvertToNumber} from '../../../../helpers/helperConvertToNumber';
import {THEME} from '../../../../styles/theme';

export const columns = [
  {
    field: 'posReceiptId',
    headerName: 'Numéro de ticket',
    flex: 1,
    renderCell: (row: any) => {
      const value = row.amountInclTax;
      return (
        <p css={styles.posReceiptId(value)} className="textSemiBold">
          {row.posReceiptId}
        </p>
      );
    },
  },
  {
    field: 'shortCode',
    headerName: 'N° Commande Client',
    flex: 1,
  },
  // {
  //   field: 'orderId',
  //   headerName: 'Commande',
  //   flex: 1,
  // },
  {
    field: 'posBillId',
    headerName: 'Note',
    flex: 1,
  },
  {
    field: 'posUser',
    headerName: 'Utilisateur',
    flex: 1,
    valueGetter: (row: any) => {
      return row?.posUser?.username;
    },
  },
  {
    field: 'date',
    headerName: "Date d'émission",
    flex: 1,
  },
  {
    field: 'time',
    headerName: 'Heure',
    flex: 1,
  },
  {
    field: 'total',
    headerName: 'Total TTC',
    flex: 1,
    renderCell: (row: any) => {
      const value = row?.total?.replace('€', '');

      return (
        <TextVariant
          text={`${value}€`}
          style={{
            color:
              helperConvertToNumber(value) >= 0
                ? THEME.colors.green
                : THEME.colors.red,
          }}
          variant="textSemiBold"
        />
      );
    },
  },
];

const styles = {
  posReceiptId: (value: any) => ({
    color: value >= 0 ? 'var(--text-color)' : THEME.colors.red,
    textDecoration: value >= 0 ? 'none' : 'line-through',
  }),
};

/** @jsxImportSource @emotion/react */

import {useNavigate, useParams} from 'react-router-dom';
import {ModuleHeader} from '../ModuleHeader';
// import {BDeliveryForm} from './BDeliveryForm';
import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useReduxHook';
import {useQuery} from '@tanstack/react-query';
import {AbsoluteLoader} from '../../../../components/layouts/AbsoluteLoader';
import {moduleSelectedAction} from '../../../../redux/myModulesSection/subscriptions';
import { TopBlock } from './topBlock';
import { DeliveryButtons } from './DeliveryButtons';
import { NewDataGrid } from '../../../../components/newDatagrid/NewDataGrid';
import { columns } from './columns';
import { fetchExternalConnections } from '../../../../api/fetchExternalConnections';

const BDeliverySection = () => {
  const dispatch = useAppDispatch();
  const {pathname} = useParams();

  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [limit] = useState(20);

  const brandId = useAppSelector(s => s.brand.brand?.id);
  const subscriptions = useAppSelector(
    s => s.myModulesSection.subscriptions.subscriptions,
  );
  const moduleSelected = useAppSelector(
    s => s.myModulesSection.subscriptions.moduleSelected,
  );

  const navigate = useNavigate();

  const {data, isLoading} = useQuery(
    ['fetchExternalConnections', brandId],
    () => fetchExternalConnections(),
  );

  useEffect(() => {
    if (!isLoading)
      setRowCount(data?.length || 0);
  }, [isLoading])

  const handleCancel = () => {
    navigate('/online/modules');
  };

  useEffect(() => {
    if (pathname !== 'bdelivery') {
      navigate('/online/modules');
    } else {
      const module = subscriptions?.find(
        (item: any) => item.code === 'b_connect',
      );
      dispatch(moduleSelectedAction(module));
    }
  }, [navigate, subscriptions]);

  if (!moduleSelected) return null;

  return (
    <>
      <ModuleHeader
        moduleActiveName={String(pathname)}
        onCancel={handleCancel}
        hideButtons={true}
      />

      <TopBlock />
      <DeliveryButtons />
      <NewDataGrid
        rows={data}
        columns={columns}
        rowHeight={60}
        loading={isLoading}
        totalRows={rowCount}
        rowPerPage={limit}
        setPage={setPage}
        page={page}
        hasPagination
      />

      {isLoading && <AbsoluteLoader />}
    </>
  );
};

export default BDeliverySection;

import {Field} from 'formik';
import {InputLabel} from './InputLabel';

export const FieldCustomInput = (props: {
  required?: boolean;
  placeholder?: string;
  label?: string;
  width?: string | number;
  name?: string;
  type?: string;
  disabled?: boolean;
  marginBottom?: number | string;
  iconLeft?: any;
}) => {
  //
  return (
    <Field name={props.name}>
      {({field, form, meta}: any) => <InputLabel {...props} {...field} />}
    </Field>
  );
};

/** @jsxImportSource @emotion/react */
import {Outlet, RouteObject} from 'react-router-dom';
import {ArchiveVentilation} from './Ventilation';
import {ArchiveMovements} from './Movements';
import {ArchiveReceipts} from './Receipts';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {HeaderTabsLinks} from '../../../../components/molecules/HeaderTabsLinks';
import {Filters} from './Filters';
import {css} from '@emotion/react';

const Root = withRedirectAndNavigation(() => {
  return (
    <>
      <Header
        centerSection={
          <HeaderTabsLinks
            data={[
              {label: 'Ventilation', link: ''},
              {label: 'Mouvements', link: '/movements'},
              {label: 'Tickets', link: '/receipts'},
            ]}
            path="accounting/archive"
            lengthToGetRootLink={3}
          />
        }
        bottomSection={<Filters />}
      />
      <div css={styles.container}>
        <Outlet />
      </div>
    </>
  );
});

export const ArchiveRouter: RouteObject = {
  path: 'archive',
  element: <Root />,
  children: [
    {
      path: '',
      element: <ArchiveVentilation />,
    },
    {
      path: 'movements',
      element: <ArchiveMovements />,
    },
    {
      path: 'receipts',
      element: <ArchiveReceipts />,
    },
  ],
};

const styles = {
  container: css({
    padding: '0 2vh',
    paddingTop: '2rem',
    height: '87vh',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../../components/layouts/Header';
import {StatsSalesHeaderTabs} from '../Headers/StatsSalesHeaderTabs';
import {StatsSalesHeaderBottom} from '../Headers/StatsSalesHeaderBottom';
import {SalesPieChartList} from '../../../../../components/organisms/SalesPieChartList';
import {MenusColumns} from '../DataColumns/MenusColumns';
import {exportSaleByMenu} from '../../../../../api/fetchStats';
import {helperGetStatColorById} from '../../../../../helpers/helperStats';
import {useState} from 'react';
import {useStatItems} from '../../../../../hooks/useStatItems';
import {PRODUCT_TYPES} from '@bill-app-types/b-types';
import {useAppSelector} from '../../../../../hooks/useReduxHook';
import {useNavigate} from 'react-router-dom';
import {useSearch} from '../../../../../hooks/useSearch';
import {SearchFilter} from '../../../../../components/filters/SearchFilter';
import {M_SCREEN_DESKTOP} from '../../../../../constants/ResponsiveSize';

export const Menus = withRedirectAndNavigation(() => {
  const dates = useAppSelector(s => s.filter.dates);
  const compareDates = useAppSelector(s => s.filter.compareDates);
  const isCompare = useAppSelector(s => s.filter.isCompare);
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);

  const [limit, setLimit] = useState(10);
  const {search, handleChangeSearch, debounceValue} = useSearch();
  const navigate = useNavigate();
  const {data: stats, isLoading} = useStatItems({
    limit,
    dates,
    types: [PRODUCT_TYPES.MENU],
    consumptionModes,
    search: debounceValue,
  });

  const {data: statsCompare} = useStatItems({
    limit,
    dates: compareDates,
    types: [PRODUCT_TYPES.MENU],
    consumptionModes,
    enabled: isCompare,
    search: debounceValue,
  });

  const data =
    stats && Array.isArray(stats)
      ? stats?.map((menu: any, index: number) => {
          const menuCompare = statsCompare?.find(
            (m: any) => m.itemId === menu.itemId,
          );

          return {
            ...menu,
            color: helperGetStatColorById(index),
            value: menu.sales?.TTC.total_price,
            valueOrder: menu.orders?.value,
            suffix: menu.sales?.suffix,
            forCompareOrders: isCompare
              ? menuCompare?.orders || {
                  value: 0,
                  percent: 0,
                }
              : null,
            forCompareSales: isCompare
              ? menuCompare?.sales || {
                  TTC: {
                    total_price: 0,
                    total_percent: 0,
                  },
                }
              : null,
            handleDetail: () => {
              navigate(`detail/${menu.itemId}`);
            },
          };
        })
      : [];

  const totalLimit = data?.length || 0;

  const handleExport = async () => {
    await exportSaleByMenu({
      from: new Date(),
      to: new Date(),
      limit: 5,
    });
  };

  const handleUpdateLimit = (type: 'incr' | 'decr') => {
    if (type === 'incr') {
      setLimit(_limit => _limit + 10);
    } else {
      setLimit(_limit => (_limit >= 20 ? _limit - 10 : 10));
    }
  };

  return (
    <section css={styles.container}>
      <Header
        centerSection={<StatsSalesHeaderTabs />}
        bottomSection={<StatsSalesHeaderBottom />}
      />
      <div css={styles.content}>
        <SalesPieChartList
          data={data}
          title="TURNOVER_MENU"
          Filters={
            <>
              <SearchFilter
                onChange={handleChangeSearch}
                value={search}
                withBorder
              />
            </>
          }
          showHeaderFilters
          columns={MenusColumns}
          handleExport={handleExport}
          isLoading={isLoading}
          hasPagination
          paginationShowMore
          totalLimit={totalLimit}
          limit={limit}
          updateLimit={handleUpdateLimit}
        />
      </div>
    </section>
  );
});

const styles = {
  container: css(`
        height:100vh;
        overflow-y:auto;
        @media (max-width: 1024px) {
          height: auto;
        }
      `),
  content: css({
    padding: '1.5rem 2vh',
    overflowY: 'auto',
    [M_SCREEN_DESKTOP]: {
      padding: '1rem 1vh',
    },
  }),
};

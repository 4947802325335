import { axiosInstance } from "./axiosInstance";

export const updateItemImage = async (itemUuid: string, image: any) => {
    //
    console.log('### updateItemImage ###');

    const formData = new FormData();
    formData.append('image', image);

    const res: any = await axiosInstance
      .put(`/bcatalog/backoffice/items/${itemUuid}/image`, formData)
      .catch(err => {
        console.log('❌ updateItemImage : ', err);
      });
    return res;
  };
import {logoutAction} from '../redux/auth';
import {brandAction} from '../redux/brand';
import store from '../redux/storeConfig';

export const cancelRefreshToken = () => {
  store.dispatch(logoutAction());
  store.dispatch(brandAction(null));
  localStorage.removeItem('auth_token');
  localStorage.removeItem('auth_refresh_token');
  window.location.href = '/';
}
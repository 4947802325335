import {axiosInstance} from './axiosInstance';
import {ICustomLanguage} from '../redux/myTranslationsSection/translations';

export const getLanguages = async () => {
  //
  console.log('### getLanguages ###');
  const res = await axiosInstance
    .get<{
      data: ICustomLanguage[];
    }>('/bcore/protected/global/language')
    .catch(err => {
      console.log('❌ getLanguages : ', err);
    });
  return res?.data?.data;
};

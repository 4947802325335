/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext} from 'react';
import {PictogramCard} from '../../../../../components/atoms/PictogramCard';
import {UtilsContext} from '../../../../../contexts/UtilsContext';
import {useQuery} from '@tanstack/react-query';
import {
  deleteItemPictogram,
  getItemPictograms,
} from '../../../../../api/fetchCatalogPictograms';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../hooks/useReduxHook';
import {IPictogramAndAllergen} from '@bill-app-types/b-types';
import {helperDisplayToast} from '../../../../../helpers/helperToast';
import {productPictogramsAction} from '../../../../../redux/myCatalogSection/products';

export const ProductPictograms = () => {
  const {t} = useContext<any>(UtilsContext);
  const dispatch = useAppDispatch();

  const productPreRegister = useAppSelector(
    s => s.myCatalogSection.products.productPreRegister,
  );

  const {data: pictograms, refetch} = useQuery(
    ['getItemPictograms'],
    () => getItemPictograms(productPreRegister?.uuid),
    {
      enabled: !!productPreRegister?.uuid,
    },
  );

  if (pictograms) {
    dispatch(productPictogramsAction(pictograms));
  }

  const handleClickPictogram = async (pictogramUuid: string) => {
    const res = await deleteItemPictogram(
      productPreRegister?.uuid,
      pictogramUuid,
    );
    if (res?.status === 204) {
      helperDisplayToast(res?.data?.message || 'Pictogramme retiré', true);
      refetch();
    } else {
      helperDisplayToast(
        res?.data?.message || 'Impossible de retirer le pictogramme',
        false,
      );
    }
  };

  return (
    <div css={styles.container}>
      <h3 className="textLabel">{t('PRODUCT_PICTOGRAMS')}</h3>
      <div className="flex-wrap" css={styles.pictograms}>
        {pictograms?.map((item: IPictogramAndAllergen, index: number) => (
          <PictogramCard
            key={index}
            pictogram={item}
            handleClick={handleClickPictogram}
            showBorder
          />
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    marginBottom: '1rem',
  }),
  pictograms: css({
    gap: '0.5rem',
    backgroundColor: 'white',
    padding: '2rem',
    height: '250px',
    overflowY: 'scroll',
  }),
};

import {BRAND_AUTHORIZATION} from '../../../constants/frontPolicies';
import {brandHasAuthorizationForRouting} from '../../../helpers/helperFrontPolicies';
import {Customers} from './Customers';
import {SalesRouter} from './Sales';

export const MyStatsRouter = {
  path: 'stats',
  children: [
    {
      path: 'customers',
      element: <Customers />,
      loader: () => brandHasAuthorizationForRouting(BRAND_AUTHORIZATION.CHILD),
    },
    SalesRouter,
  ],
};

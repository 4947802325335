/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant} from '../../../../../components/atoms/TextVariant';
import {ArrowLeft} from '../../../../../assets/svgs/ArrowLeft';
import {THEME} from '../../../../../styles/theme';

export const Header = ({
  title,
  isOpened,
  onClick,
}: {
  title: string;
  isOpened: boolean;
  onClick?: () => void;
}) => {
  return (
    <div css={styles.container(isOpened)} onClick={onClick}>
      <TextVariant variant="textSemiBold" text={title} />
      <span css={[styles.arrow, isOpened && styles.arrowOpen]}>
        <ArrowLeft color="black" />
      </span>
    </div>
  );
};

const styles = {
  container: (isOpened: boolean) =>
    css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1vh 2vh',
      '&:hover': {
        backgroundColor: !isOpened ? THEME.colors.bgLight : '',
        opacity: 0.6,
      },
    }),
  arrow: css({
    transform: 'rotate(180deg)',
    transition: 'transform 0.3s ease-in-out',
  }),
  arrowOpen: css({
    transform: 'rotate(270deg)',
    transition: 'transform 0.3s ease-in-out',
  }),
};

/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';

const BottomBlock = () => {
  return (
    <div css={styles.container}>
      <div css={[styles.small, styles.radius]} />
      <div css={[styles.medium, styles.radius]} />
      <div css={[styles.small, styles.radius]} />
      <div css={[styles.large, styles.radius]} />
      <div css={[styles.large, styles.radius]} />
      <div css={[styles.large, styles.radius]} />
      <div css={[styles.large, styles.radius]} />
      <div css={[styles.small, styles.radius]} />
      <div css={[styles.small, styles.radius]} />
    </div>
  );
};

export default BottomBlock;

const styles = {
  container: css({
    height: 40,
    display: 'flex',
    justifyContent: 'center',
  }),
  radius: css({
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  }),
  small: css({
    backgroundColor: '#CCD6E0',
    opacity: 0.3,
    height: '100%',
    width: 50,
    marginRight: 5,
  }),
  medium: css({
    backgroundColor: '#CCD6E0',
    opacity: 0.3,
    height: '100%',
    width: 47,
    marginRight: 5,
  }),
  large: css({
    backgroundColor: '#CCD6E0',
    opacity: 0.3,
    height: '100%',
    width: 167,
    marginRight: 5,
  }),
};

import {axiosInstance} from './axiosInstance';

export interface IArea {
  id: string;
  label: string;
  location_label: string;
  archived?: number;
}

export interface IAreaLocation {
  id: string;
  label: string;
  area_id: string;
  is_open: number;
  nb_customer: number;
  archived: number;
  flatware: number;
  updated_at: string;
  created_at: string;
}

export const createArea = async (
  brandId: string,
  data: {
    label: string;
    location_label: string;
  },
) => {
  const res = await axiosInstance
    .post(`bcore/protected/admin/${brandId}/area`, data)
    .catch(err => {
      console.log('❌ createArea : ', err);
    });
  return res?.data;
};

export const createAreaLocation = async (
  brandId: string,
  areaId: string,
  data: {
    label: string;
    is_open: number;
    language_id: number;
  },
) => {
  const res = await axiosInstance
    .post(`bcore/protected/admin/${brandId}/area-location/${areaId}`, data)
    .catch(err => {
      console.log('❌ createAreaLocation : ', err);
    });

  return res?.data;
};

export const updateArea = async (
  brandId: string,
  areaId: string,
  data: Partial<IArea>,
) => {
  const res = await axiosInstance
    .put(`bcore/protected/admin/${brandId}/area/${areaId}`, data)
    .catch(err => {
      console.log('❌ updateArea : ', err);
    });

  return res?.data;
};

export const updateAreaLocation = async (
  brandId: string,
  areaId: string,
  areaLocationId: string,
  data: Partial<IAreaLocation>,
) => {
  const res = await axiosInstance
    .put(
      `bcore/protected/admin/${brandId}/area-location/${areaId}/${areaLocationId}`,
      data,
    )
    .catch(err => {
      console.log('❌ updateAreaLocation : ', err);
    });

  return res?.data;
};

export const fetchAreas = async (
  brandId: string | number,
  query: {
    orderBy?: object[];
    search?: string;
    cardUuids?: string[];
    isArchived?: boolean | null;
  },
) => {
  const res = await axiosInstance
    .get(`bcore/protected/admin/${brandId}/area/all/0?language_id=1`, {
      params: {
        orderBy: JSON.stringify(query.orderBy),
        search: query.search?.toLocaleLowerCase(),
        cardUuids: JSON.stringify(query.cardUuids),
        isArchived: query.isArchived,
      },
    })
    .catch(err => {
      console.log('❌ fetchAreas : ', err);
    });

  return res?.data?.data;
};

export const fetchAllAreaLocations = async (
  brandId: string,
  areaIds: (string | number)[],
) => {
  let data: IAreaLocation[] = [];

  for (let areaId of areaIds) {
    const locations = await fetchAreaLocations(brandId, areaId as string);

    const areaLocations = locations?.map((areaLocation: any) => ({
      ...areaLocation,
      area_id: areaId,
    }));

    if (areaLocations) {
      data.push(...areaLocations);
    }
  }

  return data;
};

export const fetchAreaLocations = async (brandId: string, areaId: string) => {
  const res = await axiosInstance
    .get<{
      data: IAreaLocation[];
    }>(
      `bcore/protected/admin/${brandId}/area-location/${areaId}/all?language_id=1`,
    )
    .catch(err => {
      console.log('❌ fetchAreas : ', err);
    });

  return res?.data?.data;
};

export const fetchAreaCards = async (brandId: string, areaId: string) => {
  const res = await axiosInstance
    .get(`bcore/protected/admin/${brandId}/area/${areaId}/cards`)
    .catch(err => {
      console.log('❌ fetchAreaCards : ', err);
    });

  return res?.data?.data;
};

export const enabledAreaCard = async (
  brandId: string,
  areaId: string,
  cardId: string,
) => {
  const res = await axiosInstance
    .post(`bcore/protected/admin/${brandId}/area/${areaId}/cards/${cardId}`)
    .catch(err => {
      console.log('❌ enabledAreaCard : ', err);
    });

  return res;
};

export const disabledAreaCard = async (
  brandId: string,
  areaId: string,
  cardId: string,
) => {
  const res = await axiosInstance
    .delete(
      `bcore/protected/admin/${brandId}/area/${areaId}/cards/${cardId}/delete`,
    )
    .catch(err => {
      console.log('❌ disabledAreaCard : ', err);
    });

  return res;
};

export const deleteArea = async (brandId: string, areaId: string) => {
  const res = await axiosInstance
    .delete(`bcore/protected/admin/${brandId}/area/${areaId}/delete`)
    .catch(err => {
      console.log('❌ deleteArea : ', err);
    });

  return res?.data;
};

export const deleteAreaLocation = async (
  brandId: string,
  areaId: string,
  areaLocationId: string,
) => {
  const res = await axiosInstance
    .delete(
      `bcore/protected/admin/${brandId}/area-location/${areaId}/${areaLocationId}/delete`,
    )
    .catch(err => {
      console.log('❌ deleteAreaLocation : ', err);
    });

  return res?.data;
};

export const THEME = {
  colors: {
    primary: '#3C6AF6',
    bgDark: '#151A30',
    darkBlue: '#151A30',
    blueLight: '#3EA7FC',
    bgLight: '#EEF1F6',
    white: '#ffffff',
    green: '#61D295',
    red: '#EC5174',
    lightGray: '#CCD6E0',
    gray: '#626262',
    gray2: '#A7A7AB',
    black: '#000000',
    middleGray: '#D9D9D9',
    yellow: '#CFA615',
    yellowDark: '#F7C761',
    purple: '#9747FF',
    orange: '#E48630',
    pink: '#FFAFC1',
    magenta: '#E229B9',
  },
  gridBorder: '1px red solid',
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Title} from '../../../../components/atoms/Title';
import {ToggleButton} from '../../../../components/atoms/ToggleButton';
import {TextVariant} from '../../../../components/atoms/TextVariant';
import {THEME} from '../../../../styles/theme';
import {helperDisplayToast} from '../../../../helpers/helperToast';
import {usePaymentSettings} from '../../../../hooks/usePaymentSettings';
import {PaymentLine} from './PaymentLine';
import {updatePaymentSetting} from '../../../../api/fetchPaymentSettings';
import {IGetPaymentSettingsResponse} from '@bill-app-types/b-types';

export const PaymentsMethodsSectionV2 = () => {
  //
  const {data, refetch} = usePaymentSettings();

  const handleToggle = async (item: any) => {
    const res = await updatePaymentSetting(item.uuid, {
      isArchived: !item.isArchived,
    });
    if (res?.status === 204) {
      helperDisplayToast('Mode de règlement modifié', true);
    } else {
      helperDisplayToast(
        res?.data?.message || 'Impossible de modifier le mode de règlement',
        false,
      );
    }
    refetch();
  };

  return (
    <div css={styles.container}>
      <Title title="Configuration des modes de règlement" />
      {!!data ? (
        <div css={styles.form}>
          {data?.map((item: IGetPaymentSettingsResponse, index: number) => (
            <div key={index} css={styles.row}>
              <div className="flex">
                <TextVariant
                  variant="textSemiBold"
                  text={item?.paymentMethodProperties?.label}
                  style={styles.label}
                />
                <TextVariant
                  variant="textSemiBold"
                  text={`(${item?.paymentMethodProperties?.code})`}
                  style={styles.code}
                />
              </div>
              <div className="flex-align-center" css={styles.line}>
                <ToggleButton
                  active={!item.isArchived}
                  onToggle={() => handleToggle(item)}
                />
                <PaymentLine key={index} item={item} />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex-center textRegular" css={styles.noData}>
          Aucune donnée
        </div>
      )}
    </div>
  );
};

const styles = {
  container: css({
    padding: '2vh',
    width: '80%',
    height: '84%',
    alignSelf: 'center',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
  }),
  form: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1% 0',
    flexWrap: 'wrap',
  }),
  row: css({
    width: '49%',
  }),
  label: css({
    color: THEME.colors.gray,
    textTransform: 'lowercase',
    margin: '0.5rem 0',
    ':first-letter': {
      textTransform: 'uppercase',
    },
  }),
  code: css({
    color: THEME.colors.gray,
    textTransform: 'uppercase',
    margin: '0.5rem 0.25rem',
  }),
  line: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 3,
    paddingLeft: 10,
  }),
  noData: css({
    backgroundColor: '#f6f7f9',
    flex: 1,
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext} from 'react';
import {Title} from '../../../../components/atoms/Title';
import {NewDataGrid} from '../../../../components/newDatagrid/NewDataGrid';
import {UtilsContext} from '../../../../contexts/UtilsContext';
import {usePosReceiptCancelByUsers} from '../../../../hooks/usePosReceiptCancelByUsers';
import {columnsCancellationWaiter} from './columns';

export const CancellationsByWaiter = () => {
  const {t}: any = useContext(UtilsContext);

  const {data, isLoading} = usePosReceiptCancelByUsers();

  return (
    <div css={styles.container}>
      <Title title={t('CANCELLATION_BY_WAITER')} />
      <NewDataGrid
        columns={columnsCancellationWaiter}
        rows={data || []}
        loading={isLoading}
        rowHeight={35}
      />
    </div>
  );
};

const styles = {
  container: css({
    width: '49%',
    paddingTop: '2vh',
  }),
};

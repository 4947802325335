/** @jsxImportSource @emotion/react */
import {useTranslation} from 'react-i18next';
import {Title} from '../../atoms/Title';
import {css} from '@emotion/react';
import {AdditionalSalesCard} from './AdditionalSaleCard';
import {useQuery} from '@tanstack/react-query';
import {
  getItemAdditionalSales,
  getItemDetailsAdditionalSales,
} from '../../../api/fetchItems';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHook';
import {
  selectedAdditionalListAction,
  selectedAdditionalTypeAction,
} from '../../../redux/myCatalogSection/products';
import {SUGGESTION_TYPES} from '@bill-app-types/b-types';

export const AdditionalSales = ({itemUuid}: {itemUuid: string}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const selectedAdditionalType = useAppSelector(
    s => s.myCatalogSection.products.selectedAdditionalType,
  );

  const {data: itemAdditional, isLoading: isLoadingAdditional} = useQuery(
    ['getItemAdditionalSales'],
    () => getItemAdditionalSales(itemUuid),
    {
      enabled: !!itemUuid,
    },
  );
  const {data: itemDetailsAdditional, isLoading: isLoadingDetailsAdditional} =
    useQuery(
      ['getItemDetailsAdditionalSales'],
      () => getItemDetailsAdditionalSales(itemUuid),
      {
        enabled: !!itemUuid,
      },
    );

  const handleOpenDropDown = (value: number) => {
    if (selectedAdditionalType === value) {
      dispatch(selectedAdditionalTypeAction(null));
    } else {
      dispatch(selectedAdditionalTypeAction(value));
    }
    dispatch(selectedAdditionalListAction([]));
  };

  return (
    <div>
      <Title title={t('ADDITIONAL_SALES')} />

      <div css={styles.content}>
        <AdditionalSalesCard
          itemUuid={itemUuid}
          dropDown={SUGGESTION_TYPES.PRODUCT_DETAIL}
          title={t('PRODUCT_DETAIL')}
          data={itemDetailsAdditional}
          handleOpen={handleOpenDropDown}
          active={SUGGESTION_TYPES.PRODUCT_DETAIL === selectedAdditionalType}
          isLoading={isLoadingDetailsAdditional}
        />
        <AdditionalSalesCard
          itemUuid={itemUuid}
          dropDown={SUGGESTION_TYPES.ADDITIONAL_SALE}
          title={t('ADD_TO_CART')}
          data={itemAdditional}
          handleOpen={handleOpenDropDown}
          active={SUGGESTION_TYPES.ADDITIONAL_SALE === selectedAdditionalType}
          isLoading={isLoadingAdditional}
        />
      </div>
    </div>
  );
};

const styles = {
  content: css({
    paddingTop: 10,
    height: '100%',
    overflow: 'auto',
  }),
};

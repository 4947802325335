/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import React from 'react';

export const Header = ({
  topSection,
  centerSection,
  bottomSection,
}: {
  topSection?: React.ReactNode;
  centerSection?: React.ReactNode;
  bottomSection?: React.ReactNode;
}) => {
  return (
    <div css={styles.container}>
      <div css={styles.topSection}>{topSection}</div>
      <div css={styles.centerSection}>{centerSection}</div>
      <div css={styles.bottomSection}>{bottomSection}</div>
    </div>
  );
};

const styles = {
  container: css(` 
    min-height: 13vh;
    padding: 0vh 2vh;

    @media (max-width: 1024px) {
      padding: 0 1vh;
    }

    @media print {
      display: none;
    }

   
  `),
  topSection: css({
    height: '4vh',
  }),
  centerSection: css({
    height: '5vh',
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
  }),
  bottomSection: css(`
    min-height: 4vh;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    
    @media (max-width: 1024px) {
      
      margin-top: 1vh;
      
      & > div {
        display: none;
      }
      
      & > div:first-of-type {
        display: flex;
        flex-wrap: wrap;
      }
    }
  `),
};

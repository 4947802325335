import {SVGProps} from 'react';

export const PictureIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={67}
    height={53}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M20.414 10.9c-3.406 0-6.167 2.476-6.167 5.53 0 3.055 2.76 5.532 6.167 5.532 3.405 0 6.166-2.477 6.166-5.531 0-3.055-2.762-5.532-6.166-5.532ZM58.326.758H8.993C4.567.759.885 4.06.885 8.134v36.875c0 4.073 3.68 7.375 8.106 7.375h49.334c4.541 0 8.222-3.302 8.222-7.375V8.134c.001-4.074-3.57-7.375-8.221-7.375Zm2.055 43.478L42.806 22.815c-.32-.53-1.053-.853-1.837-.853-.785 0-1.518.319-1.954.85L25.32 39.417l-4.765-5.312c-.442-.493-1.148-.784-1.898-.784s-1.455.291-1.898.784l-9.703 10.81c0-.003 0 .004 0 0L7.051 8.134c0-1.017.922-1.844 2.056-1.844H58.44c1.134 0 2.056.827 2.056 1.844v36.103h-.115Z"
      fill="#CCD6E0"
    />
  </svg>
);

export const PictureSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={39}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12.04 5.969c-1.934 0-3.5 1.427-3.5 3.187 0 1.76 1.566 3.188 3.5 3.188 1.932 0 3.5-1.427 3.5-3.188 0-1.76-1.569-3.187-3.5-3.187ZM33.556.125h-28C3.045.125.955 2.028.955 4.375v21.25c0 2.348 2.09 4.25 4.601 4.25h28c2.578 0 4.667-1.902 4.667-4.25V4.375c0-2.347-2.027-4.25-4.666-4.25Zm1.166 25.055-9.975-12.345c-.182-.305-.598-.491-1.042-.491-.446 0-.862.184-1.11.49l-7.772 9.569-2.705-3.061c-.25-.285-.651-.453-1.077-.453-.426 0-.826.168-1.077.453l-5.507 6.23c0-.003 0 .001 0 0L4.455 4.374c0-.586.523-1.063 1.167-1.063h28c.643 0 1.166.477 1.166 1.063V25.18h-.065Z"
      fill="#CCD6E0"
    />
  </svg>
);

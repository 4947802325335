/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import React, {ComponentPropsWithoutRef, forwardRef} from 'react';
import {CopyIcon} from '../../assets/svgs/CopyIcon';

type IProps = {
  label?: string;
  width?: string | number;
  marginBottom?: number | string;
  showIcon?: boolean;
  iconLeft?: any;
  Icon?: React.FC<{color: string}>;
  iconColor?: string;
  iconTopPosition?: string | number;
  onClickIcon?: () => void;
  padding?: string;
} & ComponentPropsWithoutRef<'input'>;

export const InputLabel = forwardRef<HTMLInputElement, IProps>(
  (
    {
      required = false,
      placeholder = '',
      label = '',
      width = '100%',
      name = '',
      type = 'text',
      marginBottom = 10,
      onChange,
      showIcon = false,
      iconColor = 'black',
      iconLeft,
      Icon = CopyIcon,
      iconTopPosition = '50%',
      onClickIcon,
      disabled = false,
      padding = '0 10px',
      ...props
    },
    ref,
  ) => {
    //
    return (
      <div
        css={[
          styles.container(width),
          {
            marginBottom,
          },
        ]}>
        {label && (
          <p className="textLabel">
            {label}
            {required && <span css={styles.required}>*</span>}
          </p>
        )}
        <div className="flex" css={styles.contain}>
          {iconLeft && (
            <div css={styles.iconLeft} style={{paddingLeft: 10}}>
              {iconLeft}
            </div>
          )}
          <input
            placeholder={placeholder}
            type={type}
            name={name}
            css={[styles.input(disabled, padding), styles.placeholder]}
            className="textMedium"
            onChange={onChange}
            disabled={disabled}
            ref={ref}
            {...props}
          />
        </div>

        {showIcon && (
          <div
            css={[styles.iconRight, {top: iconTopPosition}]}
            onClick={onClickIcon}>
            <Icon color={iconColor} />
          </div>
        )}
      </div>
    );
  },
);

const styles = {
  container: (width: string | number) =>
    css({
      position: 'relative',
      width,
      marginBottom: 10,
    }),
  contain: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 2,
    alignItems: 'center',
  }),
  label: css({
    marginTop: 15,
    fontSize: 12,
    padding: '0 10px',
    color: THEME.colors.gray,
    marginBottom: 5,
    fontWeight: 600,
  }),
  required: css({
    color: THEME.colors.red,
    marginLeft: 5,
  }),
  input: (disabled: boolean, padding: string) =>
    css({
      width: '100%',
      border: 'none',
      height: 39,
      boxSizing: 'border-box',
      padding,
      borderRadius: 2,
      backgroundColor: THEME.colors.white,
      opacity: disabled ? 0.5 : 1,
    }),
  placeholder: css`
    &::placeholder {
      font-size: 12px;
      font-weight: 600;
      color: ${THEME.colors.gray2};
    }
  `,
  iconLeft: css({}),
  iconRight: css({
    position: 'absolute',
    right: 10,
    top: '50%',
    cursor: 'pointer',
  }),
};

export const helperFormatPrice = (price: number, lang = 'fr') => {
  if (!price) return '0';
  return (price / 100).toLocaleString(lang, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const helperFormatPriceWithoutDivide = (price: number, lang = 'fr') => {
  if (!price) return '0';
  return price.toLocaleString(lang, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const helperFormatPriceRoundToNumber = (price: number) => {
  if (!price) return 0;
  return Math.round(price / 100);
};

export const helperFormatPriceRoundToString = (price: number, lang = 'fr') => {
  if (!price) return '0';
  return (price / 100).toLocaleString(lang, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

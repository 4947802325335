/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
//import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {DiscountsByReasons} from './DiscountsByReasons';
import {DiscountsByWaiter} from './DiscountsByWaiter';
import {GiftsByReasons} from './GiftsByReasons';
import {GiftsByWaiter} from './GiftsByWaiter';

const Summary = () => {
  return (
    <div css={styles.content}>
      <div css={styles.section}>
        <GiftsByWaiter />
        <DiscountsByWaiter />
      </div>
      <div css={styles.section}>
        <GiftsByReasons />
        <DiscountsByReasons />
      </div>
    </div>
  );
};

const styles = {
  content: css({
    padding: '0px 2vh',
    display: 'flex',
    justifyContent: 'space-between',
    height: '82%',
    '@media (max-width: 1024px)': {
      height: 'auto',
      display: 'block',
    },
  }),
  section: css({
    width: '49%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  }),
};

export default Summary;

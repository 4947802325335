/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useQuery} from '@tanstack/react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {getExerciceDetails} from '../../../../../api/fetchCompta';
import {withRedirectAndNavigation} from '../../../../../components/hoc/withRedirectAndNavigation';
import {DaysCAByChannelSection} from './DaysCAByChannelSection';
import {DaysCAByConsumptionSection} from './DaysCAByConsumptionSection';
import {DaysCAByPaymentSection} from './DaysCAByPaymentSection';
import {DaysCAByTVASection} from './DaysCAByTVASection';
import {DaysCancellationSection} from './DaysCancellationSection';
import {DaysCASection} from './DaysCASection';
import {DaysDetailsInfos} from './DaysDetailsInfos';
import {DaysDiscountSection} from './DaysDiscountSection';
import {DaysGiftSection} from './DaysGiftSection';
import {Header} from './Header';
import {ErrorMessage} from '../../../../../components/atoms/ErrorMessage';

export const DaysDetails = withRedirectAndNavigation(() => {
  const navigate = useNavigate();

  const params = useParams();

  if (!params.uuid) navigate('/online/accounting/days');

  const {data, isLoading} = useQuery(['getExerciceDetails'], () =>
    getExerciceDetails(params?.uuid),
  );

  return (
    <div css={styles.container}>
      <Header data={data} />
      {data?.hasLocalCloudDifferentTotals && 
        <ErrorMessage message='Attention ! Nous avons identifié que certaines données sont potentiellement manquantes suite à une erreur de synchronisation sur les résultats actuels' />
      }
      <DaysDetailsInfos data={data} />
      <div css={styles.content}>
        <DaysCASection data={data?.totalAmount} isLoading={isLoading} />
        <DaysCAByTVASection
          data={data?.totalAndTotalByTva}
          isLoading={isLoading}
        />
        <DaysCAByPaymentSection
          data={data?.totalsByPaymentMethod}
          isLoading={isLoading}
        />

        <DaysCAByChannelSection
          data={data?.totalsByChannel}
          isLoading={isLoading}
        />

        <DaysCAByConsumptionSection
          data={data?.totalsByConsumptionMode}
          isLoading={isLoading}
        />

        <DaysCancellationSection
          data={data?.totalsCanceledOrders}
          isLoading={isLoading}
        />
        <DaysGiftSection
          data={data?.totalsOfferedOrders}
          isLoading={isLoading}
        />
        <DaysDiscountSection
          data={data?.totalsDiscountOrders}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
    overflow: 'hidden',
  }),
  content: css({
    padding: '2vh',
    height: '83vh',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflow: 'overlay',
    '@media (max-width: 1024px)': {
      height: '81vh',
    },
  }),
};

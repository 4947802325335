import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import * as translationEN from '../assets/lang/en/translation.json';
import * as translationFR from '../assets/lang/fr/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources,
  lng: 'fr',
  interpolation: {
    escapeValue: false,
  },
});
export default i18n;

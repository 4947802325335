import { IModifierGetReturn } from '@bill-app-types/b-types';
import {createSlice} from '@reduxjs/toolkit';

interface ISlice {
  updateMode: boolean;
  menuPreRegister: any;
  updatedSubmenu: any;
  openedSubmenu: null | string;
  updatedCourse: any;
  submenuList: IModifierGetReturn[];
  submenuProductList: any;
}

const initialState: ISlice = {
  updateMode: false,
  menuPreRegister: null,
  updatedSubmenu: null,
  openedSubmenu: null,
  updatedCourse: null,
  submenuList: [],
  submenuProductList: []
};

const slice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    updateModeAction(state, action) {
      state.updateMode = action.payload;
    },
    menuPreRegisterAction(state, action) {
      state.menuPreRegister = action.payload;
    },
    menuPreRegisterChangeAction(state, action) {
      const {name, value} = action.payload;
      if (state.menuPreRegister) {
        state.menuPreRegister[name] = value;
      }
    },
    updatedSubmenuAction(state, action){
      state.updatedSubmenu = action.payload;
    },
    openedSubmenuAction(state, action){
      state.openedSubmenu = action.payload;
    },
    updatedCourseAction(state, action){
      state.updatedCourse = action.payload;
    },
    submenuListAction(state, action){
      state.submenuList = action.payload;
    },
    submenuProductListAction(state, action){
      state.submenuProductList = action.payload;
    }
  },
});

export const {
  updateModeAction,
  menuPreRegisterAction,
  menuPreRegisterChangeAction,
  updatedSubmenuAction,
  openedSubmenuAction,
  updatedCourseAction,
  submenuListAction,
  submenuProductListAction
} = slice.actions;

export default slice.reducer;

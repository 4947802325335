import {useQuery} from '@tanstack/react-query';
import {getLanguages} from '../api/fetchLanguages';

export const useLanguages = () => {
  const {data, isLoading} = useQuery(['languages'], getLanguages);
  return {
    languages: data,
    isLoading,
  };
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {NewDataGrid} from '../../../../../components/newDatagrid/NewDataGrid';
import {useQuery} from '@tanstack/react-query';
import {fetchStructures} from '../../../../../api/fetchStructures';
import {STRUCTURE_TYPES} from '@bill-app-types/b-types';
import {columns} from './columns';

export const TreesSection = () => {
  const {data, isLoading} = useQuery(['fetchStructures'], () =>
    fetchStructures({type: [STRUCTURE_TYPES.STAFF]}),
  );

  return (
    <div css={styles.container}>
      <NewDataGrid
        rows={data || []}
        columns={columns}
        loading={isLoading}
        rowHeight={88}
      />
    </div>
  );
};

const styles = {
  container: css({
    height: '100%',
  }),
};

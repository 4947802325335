/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useNavigate} from 'react-router-dom';
import {EyeIcon} from '../../assets/svgs/EyeIcon';
import {ActionButton} from '../atoms/ActionButton';

export const DataGridEye = ({path}: {path?: any}) => {
  const navigate = useNavigate();
  return (
    <div css={styles.actions}>
      <ActionButton Icon={EyeIcon} onClick={() => navigate(path)} />
    </div>
  );
};

const styles = {
  actions: css({
    marginLeft: 'auto',
    padding: '0.8vh 0',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {ProductCard} from '../atoms/ProductCard';
import {useEffect, useState} from 'react';
import {arrayMoveImmutable} from 'array-move';
import {SortableList} from '../atoms/SortableList';
import {THandleDragAndDrop} from '../organisms/SortableListVerticalContainer';
import {IFetchItemDetailsReturn} from '@bill-app-types/b-types';

interface IProps {
  bg?: string;
  showProductType?: boolean;
  handleOnClick?: any;
  data?: any;
  style?: any;
  draggable?: boolean;
  showCross?: boolean;
  showAdd?: boolean;
  showPrice?: boolean;
  loading?: boolean;
  handleDragAndDrop?: THandleDragAndDrop;
  specificImage?: string;
  handleIsDisabled?: any;
  productsChecked?: any[];
  handleClickAdditional?: any;
  showAdditionalPrice?: boolean;
  noTruncImage?: boolean;
  imageObjectFit?: 'contain' | 'cover' | 'fill';
  showOverlay?: boolean;
  showActions?: boolean;
}

export const ProductList = ({
  bg = THEME.colors.bgLight,
  showProductType = false,
  handleOnClick,
  data,
  style,
  draggable,
  showCross = false,
  showAdd = false,
  showPrice = true,
  loading = false,
  handleDragAndDrop,
  specificImage,
  handleIsDisabled,
  productsChecked,
  handleClickAdditional,
  showAdditionalPrice = false,
  noTruncImage = false,
  imageObjectFit,
  showActions,
  showOverlay,
}: IProps) => {
  const [copyData, setCopyData] = useState<IFetchItemDetailsReturn[]>([
    ...data,
  ]);

  const onSortEnd = async ({oldIndex, newIndex}: any) => {
    if (!copyData[oldIndex] || !handleDragAndDrop) return;

    const items = arrayMoveImmutable(copyData, oldIndex, newIndex);

    setCopyData(items);

    // reset all draggable
    const draggablesItems = document.querySelectorAll(
      'body > .product-card--draggable',
    );

    if (draggablesItems.length > 0) {
      draggablesItems.forEach(item => {
        item.remove();
      });
    }

    handleDragAndDrop({
      items: items?.map(item =>
        !!item.price ? {uuid: item.uuid, price: item.price} : {uuid: item.uuid},
      ),
    });
  };

  useEffect(() => {
    if (data) {
      setCopyData([...data]);
    }
  }, [data]);

  const checkIfIsDisabled = (item: any) => {
    if (handleIsDisabled) {
      return handleIsDisabled(item);
    }
    return false;
  };

  if (!loading && data && data?.length === 0) {
    return <div className="flex-center textRegular">Aucune donnée</div>;
  }

  if (draggable) {
    const onSortMove = () => {
      // force to fix bug when drag and drop
      const draggablesItems = document.querySelectorAll(
        'body > .product-card--draggable',
      ) as NodeListOf<HTMLDivElement>;

      if (draggablesItems.length > 1) {
        for (let i = 0; i < draggablesItems.length; i++) {
          if (i !== draggablesItems.length - 1) {
            draggablesItems[i].remove();
          }
        }
        draggablesItems[draggablesItems.length - 1].style.opacity = '1';
        draggablesItems[draggablesItems.length - 1].style.visibility =
          'visible';
      }
    };

    return (
      <SortableList
        //@ts-ignore
        items={copyData}
        onSortEnd={onSortEnd}
        onSortMove={onSortMove}
        axis="xy"
        bg={bg}
        style={style}
        showCross={showCross}
        showAdd={showAdd}
        handleOnClick={handleOnClick}
        distance={1}
        showProductType={showProductType}
        productsChecked={productsChecked}
        showAdditionalPrice={showAdditionalPrice}
        handleClickAdditional={handleClickAdditional}
        specificImage={specificImage}
        showPrice={showPrice}
        handleIsDisabled={handleIsDisabled}
        noTruncImage={noTruncImage}
        imageObjectFit={imageObjectFit}
        showOverlay={showOverlay}
        showActions={showActions}
      />
    );
  }

  return (
    <div css={[styles.list, {backgroundColor: bg}, style]}>
      {data?.map((item: any) => {
        const isDisabled = checkIfIsDisabled(item);
        const isChecked = productsChecked?.find(
          (product: any) =>
            product.uuid === item.uuid ||
            (product.id && product.id === item.id),
        );

        return (
          <ProductCard
            item={item}
            handleOnClick={() => handleOnClick && handleOnClick(item)}
            key={item.id || item.uuid}
            showProductType={showProductType}
            showCross={productsChecked?.length ? true : showCross}
            showPrice={showPrice}
            showAdd={productsChecked?.length ? !isChecked : showAdd}
            specificImage={specificImage}
            disabled={isDisabled}
            noTruncImage={noTruncImage}
            imageObjectFit={imageObjectFit}
            showOverlay={showOverlay}
            showActions={showActions}
          />
        );
      })}
    </div>
  );
};

const styles = {
  list: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: '10px',
    padding: '10px',
    overflowX: 'hidden',
  }),
};

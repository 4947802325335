/**  @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {useAppSelector} from '../../../hooks/useReduxHook';
import {useParams} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';
import {getStatItemDetailGraph} from '../../../api/fetchStats';
import {Header} from './Header';
import {helperSalesItemData} from '../../../helpers/helperSalesData';
import {CustomGraphsTooltip} from '../SalesGraphs/CustomGraphsToolTip';
import {THEME} from '../../../styles/theme';
import {helperJoinFilterValues} from '../../../helpers/helperFilter';
import {useFlagBCatalog} from '../../../hooks/useFlagBCatalog';

export const SalesDetailGraphs = ({forProduct}: {forProduct?: boolean}) => {
  const dates = useAppSelector(s => s.filter.dates);
  const scale = useAppSelector(s => s.filter.scale);
  const currentMode = useAppSelector(s => s.filter.currentMode);
  const salesGraphSection = useAppSelector(
    s => s.myStatsSection.sales.salesGraphSection,
  );
  const consumptionModes = useAppSelector(s => s.filter.consumptionModes);
  const channels = useAppSelector(s => s.filter.channels);
  const {flagBCatalogEnabled, flagBCatalogReady} = useFlagBCatalog();

  const {id} = useParams();
  const {data} = useQuery(
    [
      'getStatItemDetailGraph',
      flagBCatalogEnabled,
      id,
      dates,
      scale,
      channels,
      consumptionModes,
    ],
    () =>
      getStatItemDetailGraph(String(id), {
        ...dates,
        scale,
        forStats: !flagBCatalogEnabled,
        consumptionModeIds: helperJoinFilterValues(consumptionModes, true),
        channelIds: helperJoinFilterValues(channels, true),
      }),
    {
      enabled: !!id && flagBCatalogReady,
    },
  );

  return (
    <div css={styles.container}>
      <Header data={data} forProduct={forProduct} />
      <div css={styles.graph}>
        <div css={styles.content}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={data ? helperSalesItemData(data, false, currentMode) : []}
              margin={{
                top: 5,
                right: 30,
                bottom: 5,
              }}>
              <CartesianGrid strokeDasharray="3 3" stroke="#EEF1F6" />
              <XAxis
                dataKey="x"
                className="textSemiBold"
                fontSize={10}
                color="#B4B4B4"
                fontWeight={'bold'}
                angle={-20}
                interval={0}
                tickMargin={15}
                height={40}
              />
              <YAxis
                className="textSemiBold"
                fontSize={10}
                color="#B4B4B4"
                fontWeight={'bold'}
              />
              <Tooltip
                content={props => (
                  <CustomGraphsTooltip {...props} mustBeFormated />
                )}
              />

              {salesGraphSection === 'ORDERS' && (
                <Line
                  dataKey="orders"
                  stroke={THEME.colors.yellowDark}
                  fill={THEME.colors.yellowDark}
                  strokeWidth={2}
                  activeDot={{r: 8}}
                  type={'monotone'}
                />
              )}

              {salesGraphSection !== 'ORDERS' && (
                <Line
                  dataKey="sales"
                  stroke={THEME.colors.primary}
                  fill={THEME.colors.primary}
                  strokeWidth={2}
                  activeDot={{r: 8}}
                  type={'monotone'}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    height: '28rem',
    backgroundColor: THEME.colors.white,
    margin: '1rem 0',
  }),
  graph: css({flex: 1, marginTop: '1rem'}),
  content: css({
    width: '100%',
    overflow: 'hidden',
    height: '96%',
  }),
};

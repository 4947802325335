import {helperFormatPrice} from '../../../../../helpers/helperFormatPrice';

export const columnsOffersWaiter = [
  {
    field: 'username',
    headerName: 'Nom du Serveur',
    flex: 2,
  },
  {
    field: 'quantity',
    headerName: 'Quantité',
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Montant',
    flex: 2,
    valueGetter: (row: any) => helperFormatPrice(row.amount) + ' €',
  },
];

export const columnsOffersReasons = [
  {
    field: 'reason',
    headerName: 'Motif',
    flex: 3,
  },
  {
    field: 'quantity',
    headerName: 'Quantité',
    flex: 2,
  },
  {
    field: 'amount',
    headerName: 'Montant',
    flex: 2,
    valueGetter: (row: any) => helperFormatPrice(row.amount) + ' €',
  },
];

export const columnsDiscountsWaiter = [
  {
    field: 'username',
    headerName: 'Nom du Serveur',
    flex: 3,
  },
  {
    field: 'quantity',
    headerName: 'Quantité',
    flex: 2,
  },
  {
    field: 'amount',
    headerName: 'Montant',
    flex: 2,
    valueGetter: (row: any) => helperFormatPrice(row.amount) + ' €',
  },
];

export const columnsDiscountsReasons = [
  {
    field: 'reason',
    headerName: 'Motif',
    flex: 3,
  },
  {
    field: 'quantity',
    headerName: 'Quantité',
    flex: 2,
  },
  {
    field: 'amount',
    headerName: 'Montant',
    flex: 2,
    valueGetter: (row: any) => helperFormatPrice(row.amount) + ' €',
  },
];

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {TextVariant, TextVariantType} from '../atoms/TextVariant';
import {Pill} from '../atoms/Pill';

interface IPillLabelProps {
  text: string;
  variant?: TextVariantType;
  backgroundColor?: string;
  marginRight?: string | number;
  marginLeft?: string | number;
  size?: string | number;
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
}

export const PillLabel = ({
  text,
  variant = 'textMedium2',
  backgroundColor = '#F2F2F2',
  marginRight,
  marginLeft,
  size,
  textTransform = 'uppercase',
}: IPillLabelProps) => {
  return (
    <div
      className="flex flex-align-center"
      css={styles.container({marginLeft, marginRight})}>
      <Pill backgroundColor={backgroundColor} size={size} />
      <TextVariant
        text={text}
        variant={variant}
        style={{
          color: '#A7A7AB',
          textTransform,
        }}
      />
    </div>
  );
};

const styles = {
  container: ({
    marginLeft,
    marginRight,
  }: {
    marginRight?: string | number;
    marginLeft?: string | number;
  }) =>
    css({
      marginLeft,
      marginRight,
    }),
};

/** @jsxImportSource @emotion/react */
import {useContext, useEffect, useState} from 'react';
import {UtilsContext} from '../../contexts/UtilsContext';
import {useAppSelector} from '../../hooks/useReduxHook';
import {filterAction, resetIsOutOfStockAction} from '../../redux/filter';
import {FilterButton} from '../atoms/FilterButton';
import {FilterClick} from './FilterClick';
import {useTranslation} from 'react-i18next';

export const OutOfStockFilter = ({marginRight}: {marginRight?: string}) => {
  const {t} = useTranslation();
  const isOutOfStock = useAppSelector(state => state.filter.isOutOfStock);
  const {dispatch} = useContext<any>(UtilsContext);

  const [selectedIsOutOfStock, setSelectedIsOutOfStock] = useState<
    null | boolean
  >(null);
  const [forceClose, setForceClose] = useState(false);

  const handleClickValue = () => {
    if (selectedIsOutOfStock === null) {
      setSelectedIsOutOfStock(true);
    } else if (selectedIsOutOfStock) {
      setSelectedIsOutOfStock(false);
    } else {
      setSelectedIsOutOfStock(null);
    }
  };

  const handleResetFilter = () => {
    dispatch(resetIsOutOfStockAction());
  };

  const handleSubmit = () => {
    dispatch(
      filterAction({name: 'isOutOfStock', values: selectedIsOutOfStock}),
    );
    setForceClose(true);
  };

  useEffect(() => {
    setSelectedIsOutOfStock(isOutOfStock);
    return () => {
      setForceClose(false);
    };
  }, [isOutOfStock]);

  return (
    <FilterButton
      marginRight={marginRight}
      label={t('AVAILABILITY')}
      valueType="value"
      values={
        isOutOfStock === null
          ? ''
          : isOutOfStock
          ? 'Disponible'
          : 'Non disponible'
      }
      showResetButton={isOutOfStock !== null}
      onReset={handleResetFilter}
      hideFilterIcon={!!isOutOfStock}
      forceClose={forceClose}>
      <FilterClick
        title={t('AVAILABILITY')}
        selectedValue={selectedIsOutOfStock}
        selectedText={
          selectedIsOutOfStock === null
            ? 'Voir tout'
            : selectedIsOutOfStock
            ? 'Disponible'
            : 'Non disponible'
        }
        onSubmit={handleSubmit}
        handleClickValue={handleClickValue}
      />
    </FilterButton>
  );
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {THEME} from '../../styles/theme';
import {
  SalesGraphSectionType,
  salesGraphSectionAction,
} from '../../redux/myStatsSection/sales';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHook';
import {IconSquare} from '../atoms/IconSquare';
import {TextVariant} from '../atoms/TextVariant';
import {M_SCREEN_TABLET} from '../../constants/ResponsiveSize';
import {TendanceCompareValues} from './TendanceCompareValues';

interface IProps {
  Icon: React.FC<{color?: string}>;
  label: string;
  value?: number | string;
  secondValue?: number | string;
  backgroundColor?: string;
  borderColor?: string;
  suffix?: string;
  item?: SalesGraphSectionType;
}

export const MetricBox = ({
  Icon,
  label,
  value = 0,
  secondValue,
  backgroundColor,
  borderColor = THEME.colors.primary,
  suffix,
  item,
}: IProps) => {
  const salesGraphSection = useAppSelector(
    s => s.myStatsSection.sales.salesGraphSection,
  );
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (item) {
      dispatch(salesGraphSectionAction(item));
    }
  };

  return (
    <div
      className="flex flex-align-center"
      css={styles.metricBox}
      onClick={handleClick}>
      <IconSquare
        Icon={Icon}
        active={item === salesGraphSection}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
      />
      <div css={styles.footerTexts}>
        <TextVariant
          variant="title03"
          text={label}
          style={{
            textTransform: 'uppercase',
            color: '#A7A7AB',
          }}
        />

        <TendanceCompareValues
          value={value}
          secondValue={secondValue}
          color={borderColor}
          suffix={suffix}
          variant="title02"
        />
      </div>
    </div>
  );
};

const styles = {
  metricBox: css({
    cursor: 'pointer',
    [M_SCREEN_TABLET]: {
      width: '100%',
    },
  }),

  footerTexts: css({
    marginLeft: '1rem',
  }),
};

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {withRedirectAndNavigation} from '../../../../components/hoc/withRedirectAndNavigation';
import {Header} from '../../../../components/layouts/Header';
import {Center} from './HeaderSections/Center';
import {Bottom} from './HeaderSections/Bottom';
import {Menus} from './Menus';
import {useAppSelector} from '../../../../hooks/useReduxHook';
import {SubMenus} from './SubMenus';

export const GestionMenu = withRedirectAndNavigation(() => {
  const sectionShow = useAppSelector(s => s.app.sectionShow);
  return (
    <div css={styles.container}>
      <Header centerSection={<Center />} bottomSection={<Bottom />} />
      {sectionShow === 'Menus' && <Menus />}
      {sectionShow !== 'Menus' && <SubMenus />}
    </div>
  );
});

const styles = {
  container: css({
    fontSize: '20px',
    height: '100vh',
  }),
};
